import { IOfferLetterTemplate } from 'modules/offerLetter/store/templates/models';
import {
    ICreateOfferLetterTemplate,
    IOfferLetterTemplateParam,
    IUpdateOfferLetterTemplate, UpdateOfferLetterTemplateModalStatePayload,
} from 'modules/settings/submodules/offerLetters/store/models';
import { createActions, createSingleAction } from 'store/utils';

export const SET_CREATE_OFFER_LETTER_TEMPLATE_MODAL_STATE = 'settings/SET_CREATE_OFFER_LETTER_TEMPLATE_MODAL_STATE';
export const setCreateOfferLetterTemplateModalState = createSingleAction<
boolean,
typeof SET_CREATE_OFFER_LETTER_TEMPLATE_MODAL_STATE
>(
    SET_CREATE_OFFER_LETTER_TEMPLATE_MODAL_STATE,
);

export const SET_UPDATE_OFFER_LETTER_TEMPLATE_MODAL_STATE = 'settings/SET_UPDATE_OFFER_LETTER_TEMPLATE_MODAL_STATE';
export const setUpdateOfferLetterTemplateModalState = createSingleAction<
UpdateOfferLetterTemplateModalStatePayload,
typeof SET_UPDATE_OFFER_LETTER_TEMPLATE_MODAL_STATE
>(
    SET_UPDATE_OFFER_LETTER_TEMPLATE_MODAL_STATE,
);

export const CREATE_OFFER_LETTER_TEMPLATE = 'settings/CREATE_OFFER_LETTER_TEMPLATE';
export const CREATE_OFFER_LETTER_TEMPLATE_SUCCESS = 'settings/CREATE_OFFER_LETTER_TEMPLATE_SUCCESS';
export const CREATE_OFFER_LETTER_TEMPLATE_ERROR = 'settings/CREATE_OFFER_LETTER_TEMPLATE_ERROR';

export const createOfferLetterTemplate = createActions<
ICreateOfferLetterTemplate,
void,
any,
typeof CREATE_OFFER_LETTER_TEMPLATE,
typeof CREATE_OFFER_LETTER_TEMPLATE_SUCCESS,
typeof CREATE_OFFER_LETTER_TEMPLATE_ERROR
>(
    CREATE_OFFER_LETTER_TEMPLATE,
    CREATE_OFFER_LETTER_TEMPLATE_SUCCESS,
    CREATE_OFFER_LETTER_TEMPLATE_ERROR,
);

export const UPDATE_OFFER_LETTER_TEMPLATE = 'settings/UPDATE_OFFER_LETTER_TEMPLATE';
export const UPDATE_OFFER_LETTER_TEMPLATE_SUCCESS = 'settings/UPDATE_OFFER_LETTER_TEMPLATE_SUCCESS';
export const UPDATE_OFFER_LETTER_TEMPLATE_ERROR = 'settings/UPDATE_OFFER_LETTER_TEMPLATE_ERROR';

export const updateOfferLetterTemplate = createActions<
{
    id: string;
    data: IUpdateOfferLetterTemplate;
},
IOfferLetterTemplate,
any,
typeof UPDATE_OFFER_LETTER_TEMPLATE,
typeof UPDATE_OFFER_LETTER_TEMPLATE_SUCCESS,
typeof UPDATE_OFFER_LETTER_TEMPLATE_ERROR
>(
    UPDATE_OFFER_LETTER_TEMPLATE,
    UPDATE_OFFER_LETTER_TEMPLATE_SUCCESS,
    UPDATE_OFFER_LETTER_TEMPLATE_ERROR,
);

export const UPLOAD_OFFER_LETTER_TEMPLATE = 'settings/UPLOAD_OFFER_LETTER_TEMPLATE';
export const UPLOAD_OFFER_LETTER_TEMPLATE_SUCCESS = 'settings/UPLOAD_OFFER_LETTER_TEMPLATE_SUCCESS';
export const UPLOAD_OFFER_LETTER_TEMPLATE_ERROR = 'settings/UPLOAD_OFFER_LETTER_TEMPLATE_ERROR';

export const uploadOfferLetterTemplate = createActions<
File,
string,
any,
typeof UPLOAD_OFFER_LETTER_TEMPLATE,
typeof UPLOAD_OFFER_LETTER_TEMPLATE_SUCCESS,
typeof UPLOAD_OFFER_LETTER_TEMPLATE_ERROR
>(
    UPLOAD_OFFER_LETTER_TEMPLATE,
    UPLOAD_OFFER_LETTER_TEMPLATE_SUCCESS,
    UPLOAD_OFFER_LETTER_TEMPLATE_ERROR,
);

export const GET_OFFER_LETTER_TEMPLATE_PARAMS = 'settings/GET_OFFER_LETTER_TEMPLATE_PARAMS';
export const GET_OFFER_LETTER_TEMPLATE_PARAMS_SUCCESS = 'settings/GET_OFFER_LETTER_TEMPLATE_PARAMS_SUCCESS';
export const GET_OFFER_LETTER_TEMPLATE_PARAMS_ERROR = 'settings/GET_OFFER_LETTER_TEMPLATE_PARAMS_ERROR';

export const getOfferLetterTemplateParams = createActions<
void,
IOfferLetterTemplateParam[],
any,
typeof GET_OFFER_LETTER_TEMPLATE_PARAMS,
typeof GET_OFFER_LETTER_TEMPLATE_PARAMS_SUCCESS,
typeof GET_OFFER_LETTER_TEMPLATE_PARAMS_ERROR
>(
    GET_OFFER_LETTER_TEMPLATE_PARAMS,
    GET_OFFER_LETTER_TEMPLATE_PARAMS_SUCCESS,
    GET_OFFER_LETTER_TEMPLATE_PARAMS_ERROR,
);
