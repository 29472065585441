import { IStore } from 'store/configureStore';

export const isProfileLoading = (state: IStore) => {
    return state.modules.profile.isLoading; 
};

export const selectUserProfile = (state: IStore) => {
    return state.modules.profile.userProfile; 
};

export const selectUserAvatar = (state: IStore) => {
    return state.modules.profile.userAvatar; 
};
