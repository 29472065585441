import React, { useCallback } from 'react';
import FormAutocomplete from 'shared/components/autocomplete/FormAutocomplete';
import { IEntity } from 'shared/models/Entity';
import SearchIcon from '@material-ui/icons/Search';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { debounce } from 'ts-debounce';
import { useAutocompleteWithSearchStyles } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/styles';
import { textInputDebounceTimeout } from 'utils/constants';

interface IPureAutocompleteWithSearchProps<T extends IEntity> extends IFormFieldProps {
    useIdValue?: boolean;
    getText: (item: T) => string;
    onSearch: (search: string) => void;
    results: T[];
    isLoading?: boolean;
    minLettersSearch?: number;
    placeholder?: string;
    inputType?: React.InputHTMLAttributes<unknown>['type'];
}

export function PureEntityAutocompleteWithSearch<T extends IEntity>({
    useIdValue = false,
    isLoading,
    onSearch,
    results,
    minLettersSearch = 2,
    ...props
}: IPureAutocompleteWithSearchProps<T>) {
    const classes = useAutocompleteWithSearchStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onInputChange = useCallback(debounce((_: any, value: string) => {
        if (value && value.length >= minLettersSearch) {
            onSearch(value);
        }
    }, textInputDebounceTimeout), [minLettersSearch, onSearch]);

    return (
        <FormAutocomplete
            getKey={useIdValue ? (item: IEntity) => item.id : undefined }
            options={results}
            onInputChange={onInputChange}
            isLoading={isLoading}
            popupIcon={<SearchIcon color="secondary"/>}
            freeSolo
            endAdornment={<SearchIcon color="secondary"/>}
            classes={{
                inputRoot: classes.inputRoot,
                endAdornment: classes.endAdornment,
            }}
            {...props}
        />
    );
}
