import { createSelector } from 'reselect';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { selectIsUserImpersonate } from 'store/components/auth/selectors';
import { IStore } from 'store/configureStore';
import { IClientConfiguration, IConfigurationExternalLinks, ISheetGroupKey } from 'store/entities/clients/clientsModel';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { selectActivities } from 'store/entities/configuration/configurationSelectors';

export const selectConfigurationByClientIds = (state: IStore) => state.clients.configurationByClientId;
export const selectCurrentClientConfiguration = createSelector(
    selectCurrentClientId,
    selectConfigurationByClientIds,
    (id, configurationByIds): IClientConfiguration | null => configurationByIds[id] || null,
);
/**
 * Use custom field configuration
 */
export const selectIsCustomFieldsApplied = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.custom_fields_hierarchy?.enabled || false,
);
export const selectIsTravelExpensesEnabledForClient = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.travel_expenses?.enabled || false,
);
export const selectScaEnabled = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.SCA?.enabled || false,
);
export const selectZohoAnalyticsPermalink = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.zoho_analytics_dashboard_permalink || null,
);
export const selectIsPayRangesEnabledForClient = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.pay_ranges?.enabled || false,
);
export const selectIsEafApprovalEnabledForClient = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.eaf_approver_page_enabled || false,
);
export const selectAllowFutureDayEntry = createSelector(
    selectCurrentClientConfiguration,
    configuration => {
        const configValue = configuration?.allow_future_day_entry;
        return configValue !== undefined ? configValue : true;
    },
);
export const selectDisallowPastDayEntry = createSelector(
    selectCurrentClientConfiguration,
    configuration => {
        const configValue = configuration?.time_tracking?.lock_past_dates;
        return configValue !== undefined ? configValue : false;
    },
);
export const selectAllowTimeEntryWithoutEndTime = createSelector(
    selectCurrentClientConfiguration,
    configuration => {
        const configValue = configuration?.allow_time_entry_without_end_time;
        return configValue !== undefined ? configValue : false;
    },
);
export const selectExpenseReceiptRequiredDollars = createSelector(
    selectCurrentClientConfiguration,
    configuration => {
        return configuration?.expense_receipt_required_dollars || 0;
    },
);

export const selectIsImpersonationEnabledForClient = createSelector(
    selectCurrentClientConfiguration,
    configuration => Boolean(configuration?.impersonation?.enabled),
);
export const selectImpersonationEnabled = createSelector(
    selectIsUserImpersonate,
    selectIsImpersonationEnabledForClient,
    function isImpersonationEnabledSelector(
        isUserImpersonatedAlready: boolean,
        isImpersonationEnabledForClient: boolean,
    ): boolean {
        return !isUserImpersonatedAlready && isImpersonationEnabledForClient;
    },
);
export const selectClientMileageRate = (clientId: string) => (state: IStore) => {
    const configurationByClientsIds = selectConfigurationByClientIds(state);
    return configurationByClientsIds[clientId]?.mileage_rate;
};
export const selectSheetGroupingEnabled = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.ui_sheet_grouping?.enabled || false,
);
export const selectClientApproverSheetGrouping = createSelector(
    selectCurrentClientConfiguration,
    (configuration): Array<keyof ISheetGroupKey> =>
        configuration?.ui_sheet_grouping?.approval_grouping_keys
        || ['period_start', 'period_end', 'status.id'] as Array<keyof ISheetGroupKey>,
);

export const selectClientExternalLinks = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.external_links || null,
);

export const selectEnabledClientExternalLinks = createSelector(
    selectClientExternalLinks,
    (externalLinks: IConfigurationExternalLinks[] | null) => {
        return (externalLinks || []).filter(item => Boolean(item.enabled));
    },
);

export const selectClientCreditCardPortalLink = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.credit_card_portal_link || null,
);

export const selectClientCreditCardPortalPageEnabled = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.credit_card_page_config?.enabled || false,
);

export const selectClientCreditCardPortalPageCustomFieldId = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.credit_card_page_config?.custom_field_department_id,
);

export const selectClientCreditCardPortalPageRegionalDirectorRoleId = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.credit_card_page_config?.client_role_for_regional_director_display_id,
);

export const selectClientCreditCardPortalPageRegionalDirectorLimitFields = createSelector(
    selectCurrentClientConfiguration,
    configuration => {
        return configuration?.credit_card_page_config?.client_regional_dir_match_limitation_field_names ?? ['Region'];
    },
);

export const selectClientHideSubassignments = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.hide_subassignments || false,
);
export const selectClientEafRetroEnabled = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.eaf_retro?.enabled || false,
);

const selectClientHasActivityTypes = (activity_types: QuantityType[]) => (activities: IActivity[]): boolean => {
    return Boolean(
        activities.find(activity => {
            return activity.is_active && activity_types.includes(activity.data_type);
        }),
    );
};

export const selectIsClientHasMealBreaks = createSelector(
    selectActivities,
    selectClientHasActivityTypes([QuantityType.TIME_IN_OUT_MEAL_BREAK]),
);
export const selectIsClientHasTimeInOut = createSelector(
    selectActivities,
    selectClientHasActivityTypes([
        QuantityType.TIME_IN_OUT_MEAL_BREAK,
        QuantityType.TIME_IN_OUT_BREAK,
        QuantityType.TIME_IN_OUT,
        QuantityType.TIME_BREAK,
    ]),
);
export const selectIsClientHasBreaks = createSelector(
    selectActivities,
    selectClientHasActivityTypes([
        QuantityType.TIME_IN_OUT_MEAL_BREAK,
        QuantityType.TIME_IN_OUT_BREAK,
        QuantityType.TIME_BREAK,
    ]),
);

export const selectMissingSheetsGenerationEnabled = createSelector(
    selectCurrentClientConfiguration,
    configuration => configuration?.missing_sheets_generation_enabled || false,
);
