import { uniq, flatten, difference } from 'lodash-es';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { IBulkEditSubAssignmentForm } from './EditSubAssignmentForm/types';

export interface IPreparedCustomFieldsData {
    fieldValueIds?: Array<string> | undefined,
    allCheckedFieldIds?: Array<string> | undefined,
}

export function prepareCustomFieldsPayload(
    formValues: Partial<IBulkEditSubAssignmentForm>,
    allCustomFieldValues: ICustomFieldValue[],
): IPreparedCustomFieldsData {
    const {
        // @ts-ignore
        customFieldValue = {},
        customFieldValues = {},
        customFieldValuesAllFields = {},
    } = formValues;

    const allCheckedFieldIds = (
        Object
            .entries(customFieldValuesAllFields)
            .filter(([, isChecked]) => isChecked === true)
            .map(([fieldId]) => fieldId)
            .filter(Boolean)
    );

    const customFieldValuesToExclude = (
        allCustomFieldValues
            .filter(item => allCheckedFieldIds.includes(item.custom_field_id))
            .map(item => item.id)
            .filter(Boolean)
    );

    const fieldValueIds = difference(
        [
            ...uniq(flatten(Object.values(customFieldValues))),
            ...Object.values(customFieldValue),
        ],
        customFieldValuesToExclude,
    ).filter(Boolean);

    return {
        // @ts-ignore
        fieldValueIds,
        allCheckedFieldIds,
    };
}
