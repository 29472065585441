import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { pluralize } from 'shared/utils/formatters/pluralize';

export const timePaymentFormatter = (hours?: string | null, money?: string | null) => {
    if (!hours || !parseFloat(hours || '')) {
        return '$0';
    }

    return `${formatDecimalHoursStringAsHoursAndMinutes(hours)} | ${formatDollars(money)}`;
};

export const formatFiles = (files?: number | null) => {
    return files ? `${files} ${pluralize('file', files)}` : '';
};
