import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PureEntityAutocompleteWithSearch } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/PureEntityAutocompleteWithSearch';
import { selectAutocompleteStateByKey } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/store/selectors';
import { IEntity } from 'shared/models/Entity';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { ActionCreatorKnownArgs } from 'store/utils';
import { v4 as uuidv4 } from 'uuid';

interface IEntityAutocompleteWithSearchProps<T extends IEntity> extends IFormFieldProps {
    additionalFilter?: any;
    useIdValue?: boolean;
    getText: (item: T) => string;
    entitiesByIds: Record<string, T>;
    minLettersSearch?: number;
    searchAction: ActionCreatorKnownArgs<{
        searchKey: string;
        request: any;
    }, any>;
    initialSearch?: any;
    placeholder?: string;
    inputType?: React.InputHTMLAttributes<unknown>['type'];
}

export function EntityAutocompleteWithSearch<T extends IEntity>({
    entitiesByIds,
    searchAction,
    initialSearch,
    additionalFilter = {},
    ...props
}: IEntityAutocompleteWithSearchProps<T>) {
    const dispatch = useDispatch();
    const searchKeyRef = useRef(`entityAutocompleteWithSearch-${uuidv4()}`);
    const { isLoading, results: searchResultIds } = useSelector(selectAutocompleteStateByKey(searchKeyRef.current));
    const results = searchResultIds.map(id => entitiesByIds[id]);

    useEffect(() => {
        if (initialSearch) {
            dispatch(searchAction({
                searchKey: searchKeyRef.current,
                request: initialSearch,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = useCallback((search: string) => {
        dispatch(searchAction({
            searchKey: searchKeyRef.current,
            request: {
                ...additionalFilter,
                search,
            },
        }));
    }, [dispatch, additionalFilter, searchKeyRef, searchAction]);

    return (
        <PureEntityAutocompleteWithSearch
            {...props}
            onSearch={handleSearch}
            results={results}
            isLoading={isLoading}
        />
    );
}
