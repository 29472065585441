export enum LocalStorageKey {
    ACCESS_TOKEN = 'access_token',
    REFRESH_TOKEN = 'refresh_token',
    IMPERSONATE_ORIGINAL_ACCESS_TOKEN = 'impersonate_original_access_token',
    IMPERSONATE_ORIGINAL_REFRESH_TOKEN = 'impersonate_original_refresh_token',
    FORCE_LOGOUT = 'force_logout',
}

export class AuthenticationError extends Error {
    details: any;
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(message: string, params?: any) {
        super(message);
        this.details = params;
    }
}

export interface IAuthData {
    access_token: string;
    refresh_token: string;
    id_token: string;
}

export interface IWithUserInfo {
    user_id: string;
}

export const authRequestTimeout = 30000;
