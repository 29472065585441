import React, { useEffect, useState } from 'react';
import { EditClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/EditClientLogo';
import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';
import { useDispatch } from 'react-redux';
import MainContent from 'shared/components/common/MainContent';
import { TextFilter } from 'shared/components/filters/TextFilter';
import { Box } from '@material-ui/core';
import { ClientsTable } from 'modules/settings/submodules/clients/components/ClientsTable/ClientsTable';
import { setClientId } from 'store/entities/clients/clientsAction';
import { useStyles } from './styles';
import { NewClientButton } from 'modules/settings/submodules/clients/components/NewClientButton/NewClientButton';

export const ClientListPage = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(setClientId(null));
    }, [dispatch]);

    return (
        <>
            <CommonSettingsSideMenu/>
            <MainContent whiteBackground>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    className={classes.controls}
                >
                    <NewClientButton/>
                    <TextFilter onFilterValueChanged={setSearch}/>
                </Box>
                <ClientsTable search={search}/>
                <EditClientLogo/>
            </MainContent>
        </>
    );
};
