import React from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import AuthWrapper from 'shared/components/auth/AuthWrapper';
import { useStyles } from './AuthStyles';
import { routes } from '../../routes';
import { AuthFooter } from './components/AuthFooter';
import { Logo } from './components/Logo';
import { Maintaince } from '../alerts/Maintaince';

const forgotPasswordLink = `${process.env.REACT_APP_KEYCLOACK_URL}/auth/realms/headway/login-actions/reset-credentials?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}`;

export default function ResetPasswordFailed() {
    const classes = useStyles();

    return (
        <AuthWrapper>
            <Maintaince/>
            <Paper className={classes.block}>
                <Box className={classes.logoContainer}>
                    <Logo/>
                </Box>
                <Box mt={3} textAlign="center">
                    <Typography>Reset Password Failed</Typography>
                </Box>
                <Box mt={3} textAlign="center">
                    <p className={classes.failedMessage}>Password reset link is invalid or expired.</p>
                    <p>
                        <span>Click on </span>
                        <a href={forgotPasswordLink}>&quot;Forgot Password?&quot;</a>
                        <span> to get a new link.</span>
                    </p>
                </Box>
            </Paper>
            <AuthFooter label="Try to login again or register new account" route={routes.AUTH.RESET_FAILED}/>
        </AuthWrapper>
    );
}
