import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { TableScrollPanel } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/components/TableScrollPanel';
import { useTableScroll } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/hooks/useTableScroll';
import usePayrollSheetsTableStyles
    from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/styles/usePayrollSheetsTableStyles';

import { useDispatch } from 'react-redux';
import { SyncMessage } from 'shared/components/toolbar/SyncMessage';

import { clearSyncing } from 'store/entities/appConfig/actions';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';

import { PayrollSheetTable } from '../PayrollSheetTable/PayrollSheetTable';
import { useSwitchableTableScrollClasses } from './hooks/useSwitchableTableScrollClasses';
import { PayrollSheetsPageHeading } from './components/PayrollSheetsPageHeading';
import { FilterSection } from './components/FilterSection';
import { MobilePayrollFooter } from './MobilePayrollFooter';

export const PayrollSheetsTablePage: React.FC = () => {
    const classes = usePayrollSheetsTableStyles();
    const classesProps = useSwitchableTableScrollClasses();
    const { setTableRef, handleClickScrollRight, handleClickScrollLeft, isScrollable } = useTableScroll();

    const dispatch = useDispatch();
    useEffect(() => {
        const onUnmount = () => {
            dispatch(clearSyncing(SyncingModels.ApprovalSheet));
        };
        return onUnmount;
    }, [dispatch]);

    return (
        <>
            <Box p={2}>
                <PayrollSheetsPageHeading/>

                <FilterSection alignItems="flex-end">
                    <SyncMessage
                        syncingKey={SyncingModels.PayrollApprovalSheet}
                        classes={{ wrapper: classes.syncMessageWrapper }}
                    />
                </FilterSection>
            </Box>

            <Box
                className={classes.flexTableWrapper}
            >
                <PayrollSheetTable
                    setTableRef={setTableRef}
                    {...classesProps}
                />
                {isScrollable && (
                    <TableScrollPanel
                        handleClickScrollLeft={handleClickScrollLeft}
                        handleClickScrollRight={handleClickScrollRight}
                    />
                )}
            </Box>

            <MobilePayrollFooter/>
        </>
    );
};
