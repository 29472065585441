import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreator } from 'redux';
import { IStore } from 'store/configureStore';
import { ActionCreatorKnownArgs } from 'store/utils';

type TabSelector<TabType extends string = string> = (store: IStore) => TabType;

type SetActiveTabAction<TabType extends string = string> = (
    ActionCreator<TabType> |
    ActionCreatorKnownArgs<TabType, any>
);

/**
 * Intended to use it with the HeaderTabs component
 * @param activeTabSelector - selector which retrieves an active tab from a store
 * @param setActiveTabAction - action creator which can set the new active tab state in the store
 */
export function useTabManagement<TabType extends string = string>(
    activeTabSelector: TabSelector<TabType>,
    setActiveTabAction: SetActiveTabAction<TabType>,
) {
    const dispatch = useDispatch();
    const activeTab = useSelector(activeTabSelector);

    const setActiveTab = useCallback((value: string) => {
        const tabStatus: TabType = value as TabType;
        dispatch(setActiveTabAction(tabStatus));
    }, [dispatch, setActiveTabAction]);

    return { activeTab, setActiveTab };
}
