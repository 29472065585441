import { makeStyles } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

export const useStyles = makeStyles(theme => ({
    dashboardContainer: {
        flexWrap: 'wrap',
        padding: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    gridWrapper: {
        overflow: 'hidden',
    },
    CurrentBox: {
        background: 'transparent',
        minWidth: theme.spacing(31),
        padding: theme.spacing(2, 1),
        height: theme.spacing(31),
        boxShadow: '0px 1px 12px rgba(0,0,0,0.2)',
        fontFamily: 'Roboto',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        border: 'none',
        '&:focus, &.active': {
            border: 'none',
            outline: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 0,
            height: theme.spacing(20),
        },
    },
    dashItemButton: {
        cursor: 'pointer',
    },
    Icon: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
            '& > svg': {
                fontSize: '14px !important',
            },
        },
    },
    Length: {
        fontSize: 60,
        fontWeight: 'bold',
        textDecoration: 'none solid rgb(0, 155, 229)',
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
        },
    },
    Title: {
        maxWidth: theme.spacing(21),
        fontSize: 12,
        color: '#666666',
        textDecoration: 'none solid rgb(102, 102, 102)',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
        },
    },
    Label: {
        maxWidth: theme.spacing(21),
        fontSize: '11px',
        color: '#666666',
        textDecoration: 'none solid rgb(102, 102, 102)',
        letterSpacing: '1px',
        textAlign: 'center',
        paddingTop: theme.spacing(0.5),
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: theme.spacing(2),
            width: '100%',
            alignItems: 'flex-end',
        },
    },
    input: {
        width: theme.spacing(30),
        display: 'flex',
        background: colors.white,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));
