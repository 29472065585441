import { ActionsReturnTypes, createActions, createSingleAction, RequestType, createRequestActions } from 'store/utils';
import { ISheet, ISheetLog } from 'shared/models/sheet/Sheet';

export const REMOVE_ADDED_ENTRY = 'REMOVE_ADDED_ENTRY';
export const removeLastAddedEntryAction = createSingleAction<void, typeof REMOVE_ADDED_ENTRY>(REMOVE_ADDED_ENTRY);

export const QUICK_REMOVE_ENTRY = 'QUICK_REMOVE_ENTRY';
export const QUICK_REMOVE_ENTRY_SUCCESS = 'QUICK_REMOVE_ENTRY_SUCCESS';
export const QUICK_REMOVE_ENTRY_ERROR = 'QUICK_REMOVE_ENTRY_ERROR';

export const quickRemoveEntry = createActions<string, string, any,
    typeof QUICK_REMOVE_ENTRY, typeof QUICK_REMOVE_ENTRY_SUCCESS, typeof QUICK_REMOVE_ENTRY_ERROR
>(
    QUICK_REMOVE_ENTRY, QUICK_REMOVE_ENTRY_SUCCESS, QUICK_REMOVE_ENTRY_ERROR,
);

export const GET_SIMPLE_SHEETS = 'GET_SIMPLE_SHEETS';
export const GET_SIMPLE_SHEETS_SUCCESS = 'GET_SIMPLE_SHEETS_SUCCESS';
export const GET_SIMPLE_SHEETS_ERROR = 'GET_SIMPLE_SHEETS_ERROR';

export const getSimpleSheets = createActions<void, Array<ISheet>, any,
    typeof GET_SIMPLE_SHEETS, typeof GET_SIMPLE_SHEETS_SUCCESS, typeof GET_SIMPLE_SHEETS_ERROR
>(
    GET_SIMPLE_SHEETS, GET_SIMPLE_SHEETS_SUCCESS, GET_SIMPLE_SHEETS_ERROR,
);

export const getSheetLogs = createRequestActions<
{
    timeSheetIds?: string[],
    expenseSheetIds?: string[],
},
ISheetLog[]
>(
    RequestType.Get,
    'SHEET_LOGS',
    'test',
);

export type SheetActions =
    | ActionsReturnTypes<typeof quickRemoveEntry>
    | ReturnType<typeof removeLastAddedEntryAction>
    | ActionsReturnTypes<typeof getSimpleSheets>
    | ActionsReturnTypes<typeof getSheetLogs>;
