import { selectIsLookupSidebarOpen } from 'modules/offerLetter/components/lookup/data/selectors';
import { useSidebarStyles } from 'modules/offerLetter/components/lookup/useSidebarStyles';
import { setLookupSidebarOpen } from 'modules/offerLetter/store/actions';
import React, { useEffect, useState } from 'react';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import SidebarCommon from 'shared/components/sidebars/Sidebar/SidebarCommon';
import LookupFormWrapper from 'modules/offerLetter/components/lookup/forms/LookupFormWrapper';
import LookupResultsMobile from 'modules/offerLetter/components/lookup/LookupResultsMobile';

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    formField: {
        margin: theme.spacing(2, 2, 0),
    },
    formButton: {
        width: '100%',
        position: 'absolute',
        padding: theme.spacing(2),
        bottom: 0,
    },
}));

const LookupMobile = () => {
    const classes = useStyles();
    const sidebarClasses = useSidebarStyles();
    const open = useSelector(selectIsLookupSidebarOpen);
    const dispatch = useDispatch();
    const [isOpenResultSidebar, setIsOpenResultSidebar] = useState(false);
    const title = 'Employee look up';
    const onClose = () => dispatch(setLookupSidebarOpen(false));
    useEffect(() => {
        setIsOpenResultSidebar(false);
    }, [open, setIsOpenResultSidebar]);

    return (
        <SidebarCommon
            open={open}
            title={title}
            onBack={onClose}
            titleClasses={{
                root: sidebarClasses.sidebarTitle,
            }}
        >
            <LookupFormWrapper
                classes={{
                    form: classes.form,
                    input: classes.formField,
                    button: classes.formButton,
                }}
                onSubmit={() => setIsOpenResultSidebar(true)}
            />
            <LookupResultsMobile
                open={isOpenResultSidebar}
                onBack={() => setIsOpenResultSidebar(false)}
                onClose={onClose}
            />
        </SidebarCommon>
    );
};

export default LookupMobile;
