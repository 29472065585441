import { selectEmployeeActionFormState } from 'modules/employeeActionsForm/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import {
    selectAssignments,
    selectAssignmentsById,
    selectSubassignmentsByIds,
} from 'store/entities/configuration/configurationSelectors';

const selectEndAssignmentState = (state: IStore) => selectEmployeeActionFormState(state).endAssignment;
export const selectEndAssignmentId = (state: IStore) => selectEndAssignmentState(state).detailId;
export const selectEndAssignment = createSelector(
    selectEndAssignmentId,
    selectAssignmentsById,
    (assignmentId, assignmentsByIds) => assignmentsByIds[assignmentId],
);
export const selectRelatedSubassignments = createSelector(
    selectEndAssignmentId,
    selectSubassignmentsByIds,
    (assignmentId, subassignmentsByIds): ISubassignment[] => {
        // @ts-ignore
        return Object.values(subassignmentsByIds)
            // @ts-ignore
            .filter(subassignment => subassignment.assignment_id === assignmentId);
    },
);

export const selectRelatedAssignments = createSelector(
    selectEndAssignment,
    selectAssignments,
    (assignmentCurrent, assignments): IAssignment[] => {
        return assignments
            // @ts-ignore
            .filter(assignment => assignment?.user_id === assignmentCurrent?.user_id
                // @ts-ignore
                && assignment?.client_id === assignmentCurrent?.client_id
                // @ts-ignore
                && assignment?.id !== assignmentCurrent?.id) as IAssignment[];
    },
);

const selectMaxEntriesDateByAssignmentId = (state: IStore) =>
    selectEndAssignmentState(state).maxEntryDateByAssignmentId;
export const selectAssignmentMaxEntryDate = createSelector(
    selectEndAssignmentId,
    selectMaxEntriesDateByAssignmentId,
    (assignmentId, maxDateByAssignmentId) => maxDateByAssignmentId[assignmentId],
);
