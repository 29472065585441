import { useEffect, useMemo, useRef } from 'react';
import { IAddressComponents } from 'shared/components/formSpecialFields/addressAutocompleteField/models';
import { debounce } from 'ts-debounce';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompleteService = google.maps.places.AutocompleteService;
type AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

export const useAddressPrediction = (timeout = 150) => {
    const autocompleteSessionTokenRef = useRef<AutocompleteSessionToken | null>(null);
    const autoCompleteServiceRef = useRef<AutocompleteService | null>(null);

    useEffect(() => {
        if (window.google?.maps?.places) {
            autoCompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
            autocompleteSessionTokenRef.current = new window.google.maps.places.AutocompleteSessionToken();
        }
    }, []);

    return useMemo(() =>
        debounce(
            (search: string, setPredictions: (results: AutocompletePrediction[]) => void) => {
                if (autoCompleteServiceRef.current && autocompleteSessionTokenRef.current && search) {
                    autoCompleteServiceRef.current.getPlacePredictions(
                        {
                            input: search,
                            sessionToken: autocompleteSessionTokenRef.current,
                            types: ['address'],
                        },
                        (results: google.maps.places.AutocompletePrediction[]) => {
                            setPredictions(results || []);
                        },
                    );
                } else {
                    setPredictions([]);
                }
            },
            timeout,
        ), [timeout]);
};

const componentsFormMapping: {
    input: string;
    output: keyof IAddressComponents;
    inputField?: 'long_name' | 'short_name';
}[] = [
    {
        input: 'street_number',
        output: 'street_number',
    },
    {
        input: 'route',
        output: 'street',
    },
    {
        input: 'locality',
        output: 'city',
    },
    {
        input: 'administrative_area_level_1',
        output: 'state',
    },
    {
        input: 'administrative_area_level_1',
        output: 'stateCode',
        inputField: 'short_name',
    },
    {
        input: 'administrative_area_level_2',
        output: 'county',
    },
    {
        input: 'country',
        output: 'country',
    },
    {
        input: 'postal_code',
        output: 'zip_code',
    },
];

export const getAddressComponents = (components: GeocoderAddressComponent[]): IAddressComponents => {
    const address: IAddressComponents = {
        street_number: '',
        street: '',
        country: '',
        county: '',
        state: '',
        stateCode: '',
        city: '',
        zip_code: '',
    };
    componentsFormMapping.forEach(addressMapping => {
        const addressComponent = components.find(
            component => component?.types && component?.types[0] === addressMapping.input,
        );
        if (addressComponent) {
            address[addressMapping.output] = addressMapping.inputField
                ? addressComponent[addressMapping.inputField] : addressComponent.long_name;
        }
    });
    return address;
};
