import { moment } from 'utils/momentExtensions';

export interface IPeriod {
    start_date: string,
    end_date?: string | null,
}

export const isDayInPeriod = (period: IPeriod) =>
    (day: moment.Moment): boolean => day.isSameOrAfter(period.start_date, 'day')
        && (!period.end_date || day.isSameOrBefore(period.end_date, 'day'));
