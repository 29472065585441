import baseApi from 'shared/utils/baseApi';
import { gwClientExtensionsService, jobNumberEntity } from 'store/entities/configuration/configurationApi';
import { IJobNumberRequest } from 'store/entities/configuration/configurationModel';
import {
    IAssignUserJobNumberRequest,
    ICreateJobNumberRequest,
    IJobNumberPageResponse,
    IUpdateJobNumberRequest,
} from 'modules/settings/submodules/clients/jobNumber/store/models';
import { IJobNumberBackend } from 'shared/models/JobNumber';
import { withClientId } from 'store/utils/withClientId';

export const jobNumbersApi = {
    async getJobNumbers(request: IJobNumberRequest): Promise<IJobNumberPageResponse> {
        const { data } = await baseApi.get<IJobNumberPageResponse>(
            `/${gwClientExtensionsService}/${jobNumberEntity}`,
            withClientId(request),
        );
        return data;
    },
    async createJobNumber(request: ICreateJobNumberRequest): Promise<IJobNumberBackend> {
        const { data } = await baseApi.create<ICreateJobNumberRequest, IJobNumberBackend>(
            gwClientExtensionsService,
            jobNumberEntity,
            withClientId(request),
        );
        return data;
    },
    async assignUserToJobNumber(request: IAssignUserJobNumberRequest): Promise<string> {
        const { data } = await baseApi.patch<{ user_id: string }, { status: string}>(
            `/${gwClientExtensionsService}/${jobNumberEntity}/${request.job_number_id}/assign_user`,
            {
                user_id: request.user_id,
            },
        );
        return data.status;
    },
    async updateJobNumber(id: string, request: IUpdateJobNumberRequest): Promise<IJobNumberBackend> {
        const { data } = await baseApi.put<IUpdateJobNumberRequest, IJobNumberBackend>(
            `/${gwClientExtensionsService}/${jobNumberEntity}/${id}`,
            request,
        );
        return data;
    },
};
