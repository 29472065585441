import { createScope, setCreateScopeModalState, setEditScopeId, updateScope } from 'modules/settings/submodules/clients/scopes/store/actions';
import { manageScopesApi } from 'modules/settings/submodules/clients/scopes/store/api';
import { deleteScopes, getScopes } from 'store/entities/scopes/actions';
import { getUsers } from 'store/entities/users/actions';
import { selectUsersById } from 'store/entities/users/selectors';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { difference } from 'lodash-es';
import { getCustomFieldValues } from 'store/entities/customFields/actions';

function* createScopeSaga({ payload }: ReturnType<typeof createScope.init>) {
    const scope = yield* call(manageScopesApi.createScope, payload);
    yield put(createScope.success());
    yield put(getScopes.success([scope]));
    yield put(setCreateScopeModalState(false));
}

function* createScopeWatcher() {
    yield* takeLatest(
        createScope.initType,
        withBackendErrorHandler(
            createScopeSaga,
            createScope.error,
            'Unable to create Scope',
        ),
    );
}

function* updateScopeSaga({ payload }: ReturnType<typeof updateScope.init>) {
    const { id, data } = payload;
    const scope = yield* call(manageScopesApi.updateScope, id, data);
    yield put(updateScope.success());
    yield put(getScopes.success([scope]));
    yield put(setEditScopeId(null));
}

function* updateScopeWatcher() {
    yield* takeLatest(
        updateScope.initType,
        withBackendErrorHandler(
            updateScopeSaga,
            updateScope.error,
            'Unable to update Scope',
        ),
    );
}

function* deleteScopeSaga(
    { payload: id }: ReturnType<typeof deleteScopes.init>,
) {
    yield* call(manageScopesApi.deleteScope, id);
    yield put(deleteScopes.success(id));
}

function* deleteScopesWatcher() {
    yield* takeLatest(
        deleteScopes.initType,
        withBackendErrorHandler(
            deleteScopeSaga,
            deleteScopes.error,
            'Unable to delete Scope',
        ),
    );
}

function* loadScopesLinkedEntitiesSaga(
    { payload }: ReturnType<typeof getScopes.success>,
) {
    const usersById = yield select(selectUsersById);
    const userIds = Object.keys(usersById);

    const scopesUserIds = payload.flatMap(scope => scope.user_ids);

    const notLoadedUserIds = difference(scopesUserIds, userIds).filter(Boolean);

    if (notLoadedUserIds.length) {
        yield put(getUsers.init({ ids: notLoadedUserIds.join(',') }));
    }

    const customFieldValueIds = payload.flatMap(scope => scope.custom_field_value_ids);
    if (customFieldValueIds.length) {
        yield put(getCustomFieldValues.init({ custom_field_value_ids: customFieldValueIds }));
    }
}

function* loadScopesLinkedEntitiesWatcher() {
    yield* takeLatest(
        getScopes.successType,
        loadScopesLinkedEntitiesSaga,
    );
}

export const scopeSagas = [
    createScopeWatcher,
    updateScopeWatcher,
    deleteScopesWatcher,
    loadScopesLinkedEntitiesWatcher,
];
