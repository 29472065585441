import React, { useState } from 'react';
import {
    FormControl,
    FormHelperText,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useField } from 'formik';
import PasswordStrengthIndicator from 'shared/components/formSpecialFields/password/PasswordStrengthIndicator';
import { IFormFieldProps } from 'shared/components/formFields/models';

export interface IPasswordFieldProps extends IFormFieldProps {
    helperText?: string;
    showStrengthIndicator?: boolean;
    autoComplete?: string;
}

const PasswordField = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
    helperText,
    showStrengthIndicator = false,
    ...props
}: IPasswordFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const [field, meta, helper] = useField(name);

    const errorMessage = meta.touched && meta.error;
    return (
        <FormControl
            error={Boolean(errorMessage)}
            className={className}
            variant="outlined"
            key={id}
        >
            {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
            <OutlinedInput
                {...props}
                {...field}
                endAdornment={(
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            size="small"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <Visibility fontSize="inherit"/> : <VisibilityOff fontSize="inherit"/>}
                        </IconButton>
                    </InputAdornment>
                )}
                type={showPassword ? 'text' : 'password'}
                onBlur={() => helper.setTouched(true)}
                disabled={disabled}
                id={id}
                name={name}
                label={label}
            />
            {showStrengthIndicator && (
                <PasswordStrengthIndicator password={field.value}/>
            )}
            <FormHelperText>{errorMessage || helperText}</FormHelperText>
        </FormControl>
    );
};

export default PasswordField;
