import React from 'react';
import { Box } from '@material-ui/core';
import {
    getDealTypes,
} from 'store/entities/configuration/configurationAction';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { DealTypeTable } from './components/DealTypeTable';
import { CreateDealType } from './components/CreateDealType';

export function DealTypePage() {
    useWithClientEffect(dispatch => {
        dispatch(getDealTypes.init());
    });

    return (
        <>
            <Box>
                <CreateDealType/>
            </Box>
            <DealTypeTable/>
        </>
    );
}
