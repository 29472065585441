import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const usePdfViewStyles = makeHighPriorityStyles((theme: Theme) => ({
    ownPdfRoot: {
        position: 'relative',
        height: '100%',
        borderLeft: `${theme.spacing(0.5)}px solid ${colors.darkGray}`,
        borderRight: `${theme.spacing(0.5)}px solid ${colors.darkGray}`,
    },
    ownPdfContent: {
        overflow: 'scroll',
        height: `calc(100% - ${theme.spacing(5)}px - ${2 * theme.spacing(0.5)}px)`,

        // TODO: it needs to be removed this hack. But react-pdf library doesn't have option for it
        '& > .react-pdf__message--loading': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            height: '100%',
        },
    },
    ownPdfLoader: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        flexBasis: '100%',
    },
    wrapper: {
        overflowY: 'hidden',
        width: '100%',
        boxSizing: 'border-box',
    },
    fontColor: {
        color: colors.white,
    },
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        padding: theme.spacing(0.5, 2),
        backgroundColor: colors.darkGray,
        height: theme.spacing(5),
    },
    linkStyles: {
        '&, &:visited, &:hover': {
            color: colors.white,
        },
    },
}));
