import { isContainSubstring } from 'shared/utils/helpers/isContainSubstring';
import { selectAllControllingOrgs } from 'store/entities/configuration/configurationSelectors';
import { selectControllingOrgFilter } from 'modules/settings/submodules/clients/controllingOrg/components/Filter/store/selector';
import { createSelector } from 'reselect';
import { formatBNumber } from 'shared/utils/formatters/bNumber';

export const selectFilteredControllingOrgs = (
    createSelector(
        selectAllControllingOrgs,
        selectControllingOrgFilter,
        (controllingOrgs, controllingOrgFilter) => {
            if (!controllingOrgFilter){
                return controllingOrgs;
            }
            return controllingOrgs.filter(org => {
                return isContainSubstring(org.name, controllingOrgFilter)
                    || isContainSubstring(formatBNumber(org.b_number), controllingOrgFilter);
            });
        },
    )
);
