import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { getClientTheme } from 'shared/components/theme/extendClientTheme';
import { useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectConfigurationThemeById } from 'store/entities/clients/selectors/themeSelectors';
import baseTheme from '../../../baseTheme';

interface IClientThemeProvider {
    children: React.ReactNode
}

export function ClientThemeProvider({ children }: IClientThemeProvider) {
    const clientId = useSelector(selectCurrentClientId) || '';
    const clientConfiguration = useSelector(selectConfigurationThemeById(clientId));
    const clientColor = clientConfiguration?.theme?.primary_color;
    const theme = clientColor ? getClientTheme(clientColor) : baseTheme;
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
