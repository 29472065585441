import { ICalculationGroupSidebarActionsProps } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/ICalculationGroupSidebarActionsProps';
import { PayrollSheetStatuses } from 'modules/payrollProcessorHub/store/model';
import React from 'react';
import { CalcGroupApprovedActions } from './actionGroups/CalcGroupApprovedActions';
import { CalcGroupMissingActions } from './actionGroups/CalcGroupMissingActions';
import { CalcGroupSubmittedActions } from './actionGroups/CalcGroupSubmittedActions';

export const CalculationGroupSidebarActions: React.FC<ICalculationGroupSidebarActionsProps> = ({
    calculationGroup,
}) => {
    switch (calculationGroup?.payroll_status) {
        case PayrollSheetStatuses.APPROVED: return <CalcGroupApprovedActions calculationGroup={calculationGroup}/>;
        case PayrollSheetStatuses.SUBMITTED: return <CalcGroupSubmittedActions calculationGroup={calculationGroup}/>;
        case PayrollSheetStatuses.MISSING: return <CalcGroupMissingActions calculationGroup={calculationGroup}/>;
        default: {
            return null;
        }
    }
};
