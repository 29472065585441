import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { PreInitializeCreationReportV2 } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreationReportV2/PreInitializeCreationReportV2';
import { CreationPostInitializeReportV2 } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreationReportV2/PostCreationReportV2';
import { BulkCreateSubassignmentSteps, setBulkCreateSubassignmentStep, setOpenBulkCreateSubassignmentUploadModal } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/actions';
import { selectCreationStep, selectIsLoadingCsvSample, selectIsLoadingPreInitialize, selectIsOpenUploadCreationModal } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/selectors';
import {
    bulkUploadCreateSubAssignmentAction,
    bulkUploadCreateSubAssignmentPreInitializeAction, getCreateSubAssignmentCsvSampleAction,
    getUploadPreInitializeCreateSubAssignmentPdfAction,
    uploadCreateSubAssignmentAndDownloadFailedCsvAction,
} from 'modules/subassignmentManagement/store/actions';
import { BulkUploadCreateSubassignmentForm } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/BulkUploadCreateSubassignmentForm';
import { ModalDialog } from 'shared/components/modals/ModalDialog';

const stepTitleMapping: Record<BulkCreateSubassignmentSteps, string> = {
    [BulkCreateSubassignmentSteps.Form]: '1. Bulk Create - Upload File',
    [BulkCreateSubassignmentSteps.Verify]: '2. Verify',
    [BulkCreateSubassignmentSteps.PostReport]: '3. Report',
};

export const BulkCreateSubassignmentUploadFile = () => {
    const dispatch = useDispatch();
    const isOpened = useSelector(selectIsOpenUploadCreationModal);
    const step = useSelector(selectCreationStep);
    const isLoading = useSelector(selectIsLoadingPreInitialize);
    const isLoadingCsvSample = useSelector(selectIsLoadingCsvSample);
    const [creationPayload, setCreationPayload] = useState<File | null>(null);

    const onClose = useCallback(() => {
        dispatch(setOpenBulkCreateSubassignmentUploadModal(false));
    }, [dispatch]);
    const onBack = useCallback(() => {
        dispatch(setBulkCreateSubassignmentStep(BulkCreateSubassignmentSteps.Form));
    }, [dispatch]);
    const handleSubmitPreInitializeReport = useCallback((file: File) => {
        setCreationPayload(file);
        dispatch(bulkUploadCreateSubAssignmentPreInitializeAction.init(file));
    }, [dispatch]);
    const handleCreation = useCallback(() => {
        // @ts-ignore
        dispatch(bulkUploadCreateSubAssignmentAction.init(creationPayload));
    }, [dispatch, creationPayload]);
    const handleDownloadPdf = useCallback(() => {
        // @ts-ignore
        dispatch(getUploadPreInitializeCreateSubAssignmentPdfAction.init(creationPayload));
    }, [dispatch, creationPayload]);
    const handleDownloadFailedCsv = useCallback(() => {
        // @ts-ignore
        dispatch(uploadCreateSubAssignmentAndDownloadFailedCsvAction.init(creationPayload));
    }, [dispatch, creationPayload]);
    const handleDownloadCsvSample = useCallback(() => {
        dispatch(getCreateSubAssignmentCsvSampleAction.init());
    }, [dispatch]);

    return (
        <ModalDialog
            title={stepTitleMapping[step]}
            isOpened={isOpened}
            onClose={onClose}
            colored
            modalProps={{
                showCloseIcon: true,
            }}
        >
            <Box>
                {step === BulkCreateSubassignmentSteps.Form && (
                    <BulkUploadCreateSubassignmentForm
                        onSubmit={handleSubmitPreInitializeReport}
                        isLoading={isLoading}
                        isLoadingSample={isLoadingCsvSample}
                        onDownloadSample={handleDownloadCsvSample}
                    />
                )}
                {step === BulkCreateSubassignmentSteps.Verify && (
                    <PreInitializeCreationReportV2
                        onBack={onBack}
                        onNextClick={handleCreation}
                        onDownloadReportPdfClick={handleDownloadPdf}
                        onDownloadFailedCsvClick={handleDownloadFailedCsv}
                    />
                )}
                {step === BulkCreateSubassignmentSteps.PostReport && (
                    <CreationPostInitializeReportV2 mainButtonHandler={onClose}/>
                )}
            </Box>
        </ModalDialog>
    );
};
