import React, { useCallback, useMemo } from 'react';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { useSelector } from 'react-redux';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import FilterSelect from 'shared/components/selects/FilterSelect';
import {
    selectActivitiesById,
    selectAssignmentsById,
    selectTasksById,
} from 'store/entities/configuration/configurationSelectors';

type setEntity = (newValue: string) => void;

interface ISheetDetailFiltersProps {
    entries: Array<IEntry>;
    entryType: EntryType;
    assignment: string;
    setAssignment: setEntity;
    activity: string;
    setActivity: setEntity;
    task: string;
    setTask: setEntity;
}

function useFilters<Entity>(
    getValue: (entry: IEntry) => Entity,
    entries: Array<IEntry>,
    entryType: EntryType,
): Set<Entity> {
    return useMemo(() => {
        const values: Array<Entity | null> = entries.map(
            entry => entry.entry_type === entryType ? getValue(entry) : null,
        );
        return new Set<Entity>(values.filter(value => !!value) as Array<Entity>);
    }, [getValue, entries, entryType]);
}

export default function SheetDetailFilters({
    entries,
    entryType,
    assignment,
    setAssignment,
    activity,
    setActivity,
    task,
    setTask,

}: ISheetDetailFiltersProps) {
    const classes = useSheetDetailStyles();

    const assignmentsById = useSelector(selectAssignmentsById);
    const activitiesById = useSelector(selectActivitiesById);
    const tasksById = useSelector(selectTasksById);

    const getAssignment = useCallback((entry: IEntry) => assignmentsById[entry.assignment_id], [assignmentsById]);
    const getActivity = useCallback((entry: IEntry) => activitiesById[entry.activity_id], [activitiesById]);
    const getTask = useCallback((entry: IEntry) => tasksById[entry.task_id], [tasksById]);

    const displayedAssignments = useFilters(getAssignment, entries, entryType);
    const displayedActivities = useFilters(getActivity, entries, entryType);
    const displayedTasks = useFilters(getTask, entries, entryType);

    return (
        <>
            <FilterSelect label="All Activities"
                value={activity}
                onChange={setActivity}
                customClasses={classes.activitiesFilter}
                values={Array.from(displayedActivities)}
            />
            <FilterSelect label="All Proj + Assign."
                value={assignment}
                onChange={setAssignment}
                customClasses={classes.assignmentFilter}
                values={Array.from(displayedAssignments)}
            />
            <FilterSelect label="All Tasks"
                value={task}
                onChange={setTask}
                customClasses={classes.taskFilter}
                values={Array.from(displayedTasks)}
            />
        </>
    );
}
