import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';
import { getRowHeightLine } from 'shared/components/table/GridTable/GridTableStyles';

const smallCellWidth = 48;
const mediumCellWidth = 88;
const detailsArea = 'Details';

export const useCcpModalStyle = makeStyles((theme: Theme) =>
    createStyles({
        ContentWrapper: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        DateLabel: {
            fontFamily: `Roboto`,
            fontSize: `16px`,
            textDecoration: `none solid rgb(0, 0, 0)`,
            marginBottom: theme.spacing(2),
        },
        DateWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '375px',
        },
        DatePickerRow: {
            alignItems: 'baseline',
            display: 'grid',
            gridTemplateColumns: 'auto 200px',
            marginBottom: theme.spacing(2),
            '& input': {
                backgroundColor: colors.white,
            },
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        ButtonWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        SecondaryButton: {
            marginLeft: 8,
            textDecoration: 'underline',
        },
        modalPaper: {
            minWidth: '200px',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
        },
        title: {
            display: 'flex',
            position: 'relative',
            fontSize: 20,
            '& p': {
                fontWeight: 700,
            },
        },
        modalContentNoScroll: {
            overflowX: 'hidden',
            padding: 0,
            margin: 0,
        },
    }));

export const useCcpMainStyles = makeHighPriorityStyles((theme: Theme) => {
    const timePadding = theme.spacing(2, 8);
    const entityPaddingSmall = theme.spacing(2);

    return ({
        buttonWrapper: {
            width: '100%',
            display: 'flex',

            '& button': {
                margin: 0,
                marginRight: theme.spacing(1),
            },
        },
        attachmentInput: {
            width: '100%',
        },
        timesheetInfoItem: {
            display: 'flex',
            alignItems: 'baseline',
            padding: 0,
            lineHeight: `${theme.spacing(2.5)}px`,
            '& > *': {
                color: colors.black,
                fontSize: 14,
                letterSpacing: 'normal',
                maxWidth: '100%',

                [theme.breakpoints.up('sm')]: {
                    '& + *': {
                        marginLeft: theme.spacing(0.5),
                    },
                },
            },

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
        idsInfoItem: {
            display: 'block',
            '& > *': {
                [theme.breakpoints.up('sm')]: {
                    '& + *': {
                        marginLeft: 0,
                    },
                },
            },
        },
        inlineItem: {
            display: 'inline',
        },
        title: {
            whiteSpace: 'nowrap',
        },
        listItemInline: {
            display: 'inline-flex',
            paddingLeft: '5px',
        },
        listWrapper: {
            gridArea: detailsArea,
            width: '100%',
            overflowX: 'hidden',
            padding: 0,
            marginBottom: '15px',
        },
        label: {
            letterSpacing: 2,
            textTransform: 'uppercase',
        },
        ccpLabel: {
            fontWeight: 500,
        },
        entryDataEdit: {
            padding: timePadding,

            [theme.breakpoints.down('xs')]: {
                padding: entityPaddingSmall,
            },
        },
        sidebarForm: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        sidebarTableContainer: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowX: 'hidden',
        },
        sidebarBottomActionsContainer: {
            display: 'flex',
            selfAligh: 'bottom',
        },
        sidebarBottomUploadContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            maxHeight: 23,
            minHeight: 23,
            paddingTop: '3px',
            '& .MuiOutlinedInput-input': {
                maxHeight: 23,
                minHeight: 23,
                paddingTop: '3px',
            },
        },
        inputRoot: {
            '&&': {
                flexDirection: 'row',
                height: 'unset',
            },
            '& fieldset': {
                borderWidth: 0,
            },
            //TODO: I can't find another way replace focus styles. Need to find the solution without !important
            '&.Mui-focused fieldset': {
                borderWidth: '0 !important',
                boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px inset, rgba(202, 202, 202) 0px 0px 5px 0px',
            },
            '& input': {
                minHeight: 'auto',
                padding: 0,
            },
        },
        inputInline: {
            maxHeight: 23,
            minHeight: 23,
        },
        inputSplit: {
            maxHeight: 23,
            minHeight: 23,
            marginTop: -8,
            '& .MuiOutlinedInput-input': {
                maxHeight: 23,
                minHeight: 23,
                marginTop: -8,
            },
        },
        inputStandard: {
            minHeight: theme.spacing(5),
            backgroundColor: colors.white,
        },
    });
});

export const useCcpTableStyles = makeHighPriorityStyles((theme: Theme) => {
    const paddingTopBottom = 1;
    const paddingTopBottomInPx = theme.spacing(paddingTopBottom);
    return ({
        paper: {
            borderRadius: 0,
        },
        headCell: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(paddingTopBottom, 0, paddingTopBottom, 2),
            border: 'none !important',
            lineHeight: `${getRowHeightLine(paddingTopBottomInPx, 0)}px !important`,
            backgroundColor: `${ colors.lightGray } !important`,
        },
        headCellWhite: {
            '&&': {
                position: 'sticky',
                background: colors.white,
                zIndex: 1,
                top: '40px',
            },
        },
        checkbox: {
            justifyContent: 'flex-start',
            '& svg': {
                fontSize: 21,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: `${theme.spacing(1)}px !important`,
            },
        },
        checkboxChecked: {
            color: colors.green,
        },
        iconCell: {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
        },
        headerCheckboxWrapper: {
            margin: theme.spacing(-1, 0),
        },
        detailsIcon: {
            justifyContent: 'center',
            '& svg': {
                fill: theme.palette.primary.main,
                fontSize: 16,
            },
        },
        actionsIcon: {
            '& svg': {
                fontSize: 18,
            },
        },
        enlargedActionsIconCell: {
            display: 'block',
            margin: 'auto',
            '& svg': {
                fill: theme.palette.primary.main,
                fontSize: 22,
            },
        },
        bodyCell: {
            fontSize: '12px !important',
        },
        displayCenterCell: {
            justifyContent: 'center',
        },
        smallHeadCell: {
            width: smallCellWidth,
        },
        mediumHeadCell: {
            width: mediumCellWidth,
        },
        toolbar: {
            backgroundColor: colors.white,
            padding: theme.spacing(1.6, 0),
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `2px solid ${ colors.darkGray }`,
        },
        toolbarLine: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: colors.lightGray,
            padding: theme.spacing(0, 2),
            position: 'sticky',
            minHeight: '40px',
            top: 0,
            zIndex: 2,
        },
        headerStyle: {
            backgroundColor: theme.palette.primary.main,
        },
        header: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        mainContainer: {
            display: 'flex',
            flexFlow: 'column',
            maxHeight: theme.spacing(50),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3, 10),
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        tableMain: {
            position: 'relative',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',

            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(0.5),
                },
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
                minHeight: theme.spacing(8),
                borderRadius: theme.spacing(0.8),
                backgroundClip: 'padding-box',
                border: `1px solid ${ fade(colors.white, 0.5) }`,
                backgroundColor: fade(colors.black, 0.5),
            },
        },
        tableContainer: {
            height: '100%',
            width: '100%',
            overflow: 'unset',

            [theme.breakpoints.up('md')]: {
                position: 'relative',
            },
            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(0.5),
                },
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
                minHeight: theme.spacing(8),
                borderRadius: theme.spacing(1),
                backgroundClip: 'padding-box',
                border: `1px solid ${ fade(colors.white, 0.5) }`,
                backgroundColor: fade(colors.black, 0.5),
            },
        },
        alignCenter: {
            textAlign: 'center',
        },
        lastCell: {
            paddingRight: theme.spacing(2),
        },
        tableBorder: {
            border: `1px solid ${ colors.lightGray }`,
        },
        pullRight: {
            justifyContent: 'flex-end !important',
        },
        statusSelect: {
            '& .MuiOutlinedInput-input': {
                minHeight: 22,
            },
        },
        warningRow: {
            backgroundColor: fade(theme.palette.warning.light, 0.3),
        },
        readyRow: {
            backgroundColor: fade(colors.lightGreen, 0.3),
        },
        maxHeightTable: {
            position: 'relative',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',

            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(0.5),
                },
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
                minHeight: theme.spacing(8),
                borderRadius: theme.spacing(0.8),
                backgroundClip: 'padding-box',
                border: `1px solid ${ fade(colors.white, 0.5) }`,
                backgroundColor: fade(colors.black, 0.5),
            },
            maxHeight: `calc(100vh - 290px)`,
            minHeight: `calc(100vh - 290px)`,
        },
        maxHeightTableReduced: {
            position: 'relative',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',

            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(0.5),
                },
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
                minHeight: theme.spacing(8),
                borderRadius: theme.spacing(0.8),
                backgroundClip: 'padding-box',
                border: `1px solid ${ fade(colors.white, 0.5) }`,
                backgroundColor: fade(colors.black, 0.5),
            },
            maxHeight: `calc(100vh - 360px)`,
            minHeight: `calc(100vh - 360px)`,
        },
        maxHeightTableExtended: {
            position: 'relative',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',

            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(0.5),
                },
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
                minHeight: theme.spacing(8),
                borderRadius: theme.spacing(0.8),
                backgroundClip: 'padding-box',
                border: `1px solid ${ fade(colors.white, 0.5) }`,
                backgroundColor: fade(colors.black, 0.5),
            },
            maxHeight: `calc(100vh - 255px)`,
            minHeight: `calc(100vh - 255px)`,
        },
        actionsContainer: {
            marginTop: '15px',
        },
        actionsContainerShort: {
            marginTop: '5px',
        },
        totalsContainer: {
            minHeight: '21px',
            position: 'relative',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        enlargedActionsIconCellSecondary: {
            display: 'block',
            margin: 'auto',
            '& svg': {
                fill: theme.palette.secondary.main,
                fontSize: 22,
            },
            cursor: 'pointer',
        },
        enlargedActionsIconCellSecondaryButton: {
            display: 'block',
            margin: 'auto',
            '& svg': {
                fill: theme.palette.secondary.main,
                fontSize: 22,
            },
            cursor: 'pointer',
            padding: '0px',
        },
        amountBodyCell: {
            '&&': {
                justifyContent: 'flex-end',
                paddingRight: theme.spacing(2),
                textAlign: 'right',
            },
        },
        outlinedCellContent: {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: colors.lightGray,
            maxHeight: '22px',
            width: '100%',
            '&:hover': {
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: colors.black,
            },
        },
        focusedAmount: {
            maxHeight: '22px',
            outline: `1px solid ${colors.primary}`,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: colors.primary,
        },
    });
});
