import React, { FC, PropsWithChildren } from 'react';
import Toolbar from '@material-ui/core/Toolbar';

interface IToolbarEntryProps {
    classes?: any;
    children?: any;
}

const ToolbarEntry: FC<IToolbarEntryProps> = ({ classes, children }: PropsWithChildren<IToolbarEntryProps>) => {
    return (
        <Toolbar classes={classes}>
            {children}
        </Toolbar>
    );
};

export default ToolbarEntry;
