import React from 'react';
import clsx from 'clsx';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { TimeAndMoneyCell } from '../components/cells/abstract/TimeAndMoneyCell';
import { TableClasses } from '../model';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type DoubleTimeHoursCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export function makeDoubleTimeHoursCell(tableClasses: TableClasses) {
    return function DoubleTimeHoursCell({
        className,
        time_dt_hours,
        time_dt_dollars,
    }: DoubleTimeHoursCellProps) {
        return (
            <TimeAndMoneyCell
                className={clsx(className, tableClasses.pullRight)}
                time={time_dt_hours}
                money={time_dt_dollars}
            />
        );
    };
}
