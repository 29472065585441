import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import DealFormWrapper from 'modules/settings/submodules/clients/deals/components/DealForm/DealFormWrapper';
import {
    selectEditDeal,
    selectEditDealId,
    selectIsDealUpdating,
} from 'modules/settings/submodules/clients/deals/components/EditDeal/store/selectors';
import { IDealFormValues } from 'modules/settings/submodules/clients/deals/components/DealForm/model';
import { setEditDealId, updateDeal } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/actions';
import { IUpdateDealPayloadData } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/models';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { getJobNumbers } from 'store/entities/configuration/configurationAction';

export default function EditDealModal() {
    const modalClasses = useColoredFormModalStyles();
    const editDealId = useSelector(selectEditDealId) || '';
    const isLoading = useSelector(selectIsDealUpdating);
    const editDeal = useSelector(selectEditDeal);
    const editDealNumber = editDeal?.deal_number;
    const dispatch = useDispatch();
    useEffect(() => {
        if (editDealNumber){
            dispatch(getJobNumbers.init({
                deal_number: editDealNumber,
            }));
        }
    }, [dispatch, editDealNumber]);
    const onSave = useCallback((formValues: IDealFormValues) => {
        const { deal_type_id, continueJobNumberCreation, segment_id, ...valuesCleared } = formValues;
        const data = {
            ...valuesCleared,
            segment_id: segment_id || null,
            type_id: deal_type_id,
            continueJobNumberCreation,
        } as IUpdateDealPayloadData;

        dispatch(updateDeal.init({
            id: editDealId,
            data,
        }));
    }, [dispatch, editDealId]);
    const onClose = useCallback(() => {
        dispatch(setEditDealId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit Deal"
            isOpened={Boolean(editDealId)}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <DealFormWrapper
                    submitTitle="Save"
                    submitAndGoToJobNumberTitle="Save & Add Job Number"
                    onSubmit={onSave}
                    onCancel={onClose}
                    isLoading={isLoading}
                    deal={editDeal}
                    isEdit
                />
            </Box>
        </ModalDialog>
    );
}
