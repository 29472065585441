import { useField } from 'formik';
import React, { useEffect, useMemo } from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { useDispatch, useSelector } from 'react-redux';
import { IUserInfo } from 'shared/models/User';
import { searchUsers } from 'store/entities/users/actions';
import { selectSearchResultUsers, selectUsersById } from 'store/entities/users/selectors';
import { IFormSelect } from './model';
import { IGetUserRequest } from 'store/entities/users/model';
import { IFormFieldProps } from '../formFields/models';

interface IUserSelectProps extends IFormFieldProps, IFormSelect {
    additionalFilter?: Partial<IGetUserRequest>;
    useIdValue?: boolean;
    multiple?: boolean;
}

const getText = (user: IUserInfo) => `${user.first_name} ${user.last_name}`;

export default function UserSelect({
    useIdValue = false,
    additionalFilter = {},
    ...props
}: IUserSelectProps) {
    const dispatch = useDispatch();
    const searchKey = [
        'selectFilterKey',
        ...Object.values(additionalFilter),
    ].join('_');
    const usersByIds = useSelector(selectUsersById);
    const users = useSelector(selectSearchResultUsers(searchKey));
    const [field] = useField(props.name);

    const filterKey = JSON.stringify(additionalFilter);
    useEffect(() => {
        if (users.length === 0) {
            dispatch(searchUsers.init({
                searchKey,
                request: additionalFilter,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterKey, dispatch, searchKey]);

    const options = useMemo(() => {
        if (!props.multiple) {
            return users;
        }
        const selectedUsers = useIdValue ? field.value.map(id => usersByIds[id]).filter(Boolean) : field.value;
        const filteredUsersIds = users.map(user => user.id);
        return [...users, ...selectedUsers.filter(user => !filteredUsersIds.includes(user.id))]
            .filter(Boolean)
            .sort((first, second) => getText(first).localeCompare(getText(second)));
    }, [field.value, props.multiple, useIdValue, users, usersByIds]);

    return (
        <FormSelect
            {...props}
            getKey={(user: IUserInfo) => user.id}
            getText={getText}
            options={options}
            useIdValue={useIdValue}
        />
    );
}
