import { QuantityType, TEntryData } from 'shared/models/sheet/Sheet';
import { timeParser } from 'shared/components/textField/time/timeParser';
import { parseTimeUnitsFromString } from 'shared/models/DateTime';

export const parseData = (value: string, quantityType: QuantityType): TEntryData => {
    if (quantityType === QuantityType.TIME) {
        const parsedTime = timeParser(value);
        const { hours, minutes } = parseTimeUnitsFromString(parsedTime);
        return {
            entry_type: QuantityType.TIME,
            hours: hours,
            minutes: minutes,
        };
    } else {
        return {
            entry_type: QuantityType.MONEY,
            dollars: value,
        };
    }
};
