import { setEditEntryId } from 'modules/timeAndExpense/components/EditEntry/store/actions';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import { IWithUserInfo } from 'shared/models/Authentication';
import { IEntity } from 'shared/models/Entity';
import {
    EntryType, IEntryCommonBackend, IExpenseSheetBackend, ISheetCommonBackend, ITimeSheetBackend, ITypedEntry,
} from 'shared/models/sheet/Sheet';
import { IUserSelfInfo, Permission } from 'store/components/auth/authModels';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IStore } from 'store/configureStore';
import { ICreateEntryParams, IEntryCreate, OmitEntryFields } from 'store/entities/timesheet/models/Entry';
import { statusSlugToName } from 'store/entities/timesheet/models/Status';
import { ActionCreatorKnownArgs } from 'store/utils';
import { all, call, put, select, take } from 'typed-redux-saga';
import { getMoreTravelExpensesAction } from 'modules/travelExpenses/components/TravelExpensesTable/actions';
import { selectExpenseSheetStatusById, selectTimeSheetStatusById } from '../selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { v4 as uuidv4 } from 'uuid';
import { IUpdateTemporaryEntry } from '../actions/commonActions';
import { decreaseSyncing, increaseSyncing } from '../../appConfig/actions';
import { SyncingModels } from '../../appConfig/syncing/models';
import { loadTimeSheetsWithEntries } from '../actions/timeActions';
import { loadExpenseSheetsWithEntries } from '../actions/expenseActions';
import { expenseApi } from '../api/expenseApi';
import { timeApi } from '../api/timeApi';

export function* sagaSelectUserId() {
    const { id } = (yield* select(selectCurrentUser)) as IUserSelfInfo;
    return id;
}

export type SelectEntriesById = (state: IStore) => Record<string, IEntryCommonBackend>;

export function* fetchSheets(sheetIds: Array<string>, entry: EntryType) {
    const filteredSheetIds = sheetIds.filter(Boolean);
    const payload = {
        purpose: Permission.SubmitSheets,
        request: { sheet_ids: filteredSheetIds },
    };
    if (entry === EntryType.TIME) {
        yield* put(loadTimeSheetsWithEntries.init(payload));
        yield* take([loadTimeSheetsWithEntries.successType, loadTimeSheetsWithEntries.errorType]);
    } else {
        yield* all([
            put(loadExpenseSheetsWithEntries.init({
                ...payload,
                request: { ...payload.request, is_travel: false },
            })),
            put(getMoreTravelExpensesAction.init({ sheet_ids: filteredSheetIds })),
        ]);
        yield* take([loadExpenseSheetsWithEntries.successType, loadExpenseSheetsWithEntries.errorType]);
    }
}

function* createTemporarySheet<
    CreateEntryPayload extends IEntryCreate & Omit<ITypedEntry, OmitEntryFields>
>(
    entryType: EntryType,
    temporarySheetId: string,
    sheetProps: Pick<CreateEntryPayload & IWithUserInfo, 'user_id' | 'period_start' | 'period_end' | 'status_id'>,
) {
    let status = entryType === EntryType.TIME
        ? yield* select(selectTimeSheetStatusById(sheetProps.status_id))
        : yield* select(selectExpenseSheetStatusById(sheetProps.status_id));
    if (!status) {
        const activeStatus = yield select(selectTimeAndExpenseActiveStatus);
        const slug = Object.entries(statusSlugToName).find(([_, name]) => name === activeStatus)?.[0];
        status = {
            id: uuidv4(),
            name: activeStatus,
            slug,
        };
    }
    const clientId = yield* select(selectCurrentClientId);
    return {
        id: temporarySheetId,
        status,
        user_id: sheetProps.user_id,
        client_id: clientId,
        total_hours: '0.00',
        total_minutes: 0,
        total_dollars: '0.00',
        approved_level: 0,
        job_number_id: null,
        period_start: sheetProps.period_start,
        period_end: sheetProps.period_end,
        area_id: null,
        sheet_group_id: '',
        notes: [],
    } as unknown as ISheetCommonBackend;
}

export function createAddTemporaryEntrySaga<CreateEntryPayload
extends IEntryCreate & Omit<ITypedEntry, OmitEntryFields>,
    Entry extends IEntryCommonBackend,
    Sheet extends ISheetCommonBackend>(
    entryType: EntryType,
    temporarySheetId: string,
    temporaryEntryId: string,
    createEntryAction: ActionCreatorKnownArgs<Entry, { type: string; payload: Entry }>,
    createSheetAction: ActionCreatorKnownArgs<Sheet[], { type: string; payload: Sheet[] }>,
): (action: any) => Generator {
    return function* (action: { payload: CreateEntryPayload & ICreateEntryParams }) {
        const {
            project_id,
            user_id,
            period_start,
            period_end,
            status_id,
            ...restPayload
        } = action.payload;

        const temporarySheet = yield* createTemporarySheet<CreateEntryPayload>(entryType, temporarySheetId, {
            user_id,
            period_start,
            period_end,
            status_id,
        });
        const temporaryEntry: Entry = {
            ...restPayload,
            entry_type: entryType,
            id: temporaryEntryId,
            sheet_id: temporarySheet.id,
        } as unknown as Entry;
        yield put(createSheetAction([temporarySheet as Sheet]));
        yield put(createEntryAction(temporaryEntry));
    };
}

export function* loadSheet(
    sheetId: string,
    entryType: EntryType,
) {
    if (entryType === EntryType.TIME) {
        const sheet = yield* call(timeApi.getSheetById, sheetId);
        yield* put(loadTimeSheetsWithEntries.success([sheet as ITimeSheetBackend]));
    } else {
        const sheet = yield* call(expenseApi.getSheetById, sheetId);
        yield* put(loadExpenseSheetsWithEntries.success([sheet as IExpenseSheetBackend]));
    }
}

export function createAddEntrySaga<
    CreateEntryPayload,
    Entry extends IEntryCommonBackend,
    Sheet extends ISheetCommonBackend
>(
    entryType: EntryType,
    createEntryApi: (entryToCreate: CreateEntryPayload & IWithUserInfo, params: ICreateEntryParams) => Promise<Entry>,
    successAction: ActionCreatorKnownArgs<
    IUpdateTemporaryEntry<Entry>,
    { type: string; payload: IUpdateTemporaryEntry<Entry> }
    >,
    createEntryAction: ActionCreatorKnownArgs<Entry, { type: string; payload: Entry }>,
    createSheetAction: ActionCreatorKnownArgs<Sheet[], { type: string; payload: Sheet[] }>,
    removeSheetAction: ActionCreatorKnownArgs<string, { type: string; payload: string }>,
    removeEntryAction: ActionCreatorKnownArgs<string, { type: string; payload: string }>,
): (action: any) => Generator {
    return function* (action: { payload: CreateEntryPayload & ICreateEntryParams }){
        yield* put(increaseSyncing(SyncingModels.EditableEmployeeSheet));
        const user_id = yield* sagaSelectUserId();
        const { status_id, ...entry } = action.payload;
        const entryRequest = {
            user_id,
            ...entry,
        } as unknown as CreateEntryPayload & IWithUserInfo;
        const params = { status_id };
        const temporarySheetId = uuidv4();
        const temporaryEntryId = uuidv4();
        yield* call(createAddTemporaryEntrySaga(
            entryType,
            temporarySheetId,
            temporaryEntryId,
            createEntryAction,
            createSheetAction,
        ), { payload: action.payload });
        try {
            let createdEntry;
            try {
                createdEntry = yield* call(createEntryApi, entryRequest, params);
            } catch (e) {
                yield* put(removeEntryAction(temporaryEntryId));
                yield* put(removeSheetAction(temporarySheetId));
                throw e;
            }
            if (createdEntry) {
                yield* call(loadSheet, createdEntry.sheet_id, entryType);
                yield* put(successAction({
                    temporaryId: temporaryEntryId,
                    entry: createdEntry,
                }));
            }
            yield* put(removeSheetAction(temporarySheetId));

            yield* put(decreaseSyncing(SyncingModels.EditableEmployeeSheet));
        } catch (e) {
            yield* put(decreaseSyncing(SyncingModels.EditableEmployeeSheet));
            throw e;
        }
    };
}

export function createUpdateEntrySaga<UpdateActionPayload, Entry extends IEntryCommonBackend>(
    entryType: EntryType,
    updateEntryApi: (id: string, entryToCreate: UpdateActionPayload & IWithUserInfo) => Promise<Entry>,
    successAction: ActionCreatorKnownArgs<Entry, { type: string; payload: Entry }>,
    selectEntriesById: SelectEntriesById,
    removeSheetAction: ActionCreatorKnownArgs<string, { type: string; payload: string }>,
): (action: any) => Generator {
    return function* ({ payload: entry }: { payload: UpdateActionPayload & IEntity }) {
        yield* put(increaseSyncing(SyncingModels.EditableEmployeeSheet));
        const user_id = yield* sagaSelectUserId();
        const { id, ...entryWithoutId } = entry;
        const entries = yield* select(selectEntriesById);
        const entryInStore = entries[id];
        const { sheet_id: originalSheetId } = entryInStore;
        const otherEntriesInSheet = Object.values(entries)
            .filter(storeEntry => storeEntry.id !== id && storeEntry.sheet_id === originalSheetId);

        const entryToUpdate: UpdateActionPayload & IWithUserInfo = {
            user_id,
            // We need to cast to unknown because & IEntity and Omit doesn't equal to original type
            ...entryWithoutId as unknown as UpdateActionPayload,
        };
        try {
            const updatedEntry = yield* call(updateEntryApi, id, entryToUpdate);

            if (otherEntriesInSheet.length === 0 && updatedEntry.sheet_id !== originalSheetId) {
                yield* put(removeSheetAction(originalSheetId));
            }
            yield all([
                call(fetchSheets, [updatedEntry.sheet_id, originalSheetId], entryType),
                put(successAction(updatedEntry)),
                put(setEditEntryId(null)),
            ]);
            yield* put(decreaseSyncing(SyncingModels.EditableEmployeeSheet));
        } catch (e) {
            yield* put(decreaseSyncing(SyncingModels.EditableEmployeeSheet));
            throw e;
        }
    };
}

export function createDeleteEntrySaga(
    entryType: EntryType,
    removeEntryApi: (entryId: string) => void,
    successAction: ActionCreatorKnownArgs<string, { type: string; payload: string }>,
    selectEntriesById: SelectEntriesById,
    removeSheetAction: ActionCreatorKnownArgs<string, { type: string; payload: string }>,
): (action: any) => Generator {
    return function* ({ payload: entryId }: { payload: string }) {
        yield* put(increaseSyncing(SyncingModels.EditableEmployeeSheet));
        const entries = yield* select(selectEntriesById);
        const entryInStore = entries[entryId];
        const { sheet_id: originalSheetId } = entryInStore;
        const otherEntriesInSheet = Object.values(entries)
            .filter(entry => entry.id !== entryId && entry.sheet_id === originalSheetId);
        try {
            yield* call(removeEntryApi, entryId);

            if (otherEntriesInSheet.length === 0) {
                yield* put(removeSheetAction(originalSheetId));
            }

            yield all([
                call(fetchSheets, [originalSheetId], entryType),
                put(successAction(entryId)),
                put(setEditEntryId(null)),
            ]);
            yield* put(decreaseSyncing(SyncingModels.EditableEmployeeSheet));
        } catch (e) {
            yield* put(decreaseSyncing(SyncingModels.EditableEmployeeSheet));
            throw e;
        }
    };
}
