import { IStore } from 'store/configureStore';

export const selectPhysicalDemandsState = (state: IStore) => {
    return state.modules.settings.clientsSettings.clients.physicalDemandsTemplates;
};

/*
 * Create
 */
export const selectIsCreatePhysicalDemandsTemplateModalOpened = (state: IStore) =>
    selectPhysicalDemandsState(state).isOpenCreatePhysicalDemandsModal;
export const selectIsPhysicalDemandsTemplateCreating = (state: IStore) =>
    selectPhysicalDemandsState(state).isPhysicalDemandsCreating;

/*
 * Update
 */
export const selectEditPhysicalDemandsTemplateId = (state: IStore) =>
    selectPhysicalDemandsState(state).editPhysicalDemandsId;
export const selectIsPhysicalDemandsTemplateUpdating = (state: IStore) =>
    selectPhysicalDemandsState(state).isPhysicalDemandsUpdating;
