import { createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { IUpdateSubmittingOrgPayload } from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/models';

export const SET_EDIT_SUBMITTING_ORG_ID = 'SET_EDIT_SUBMITTING_ORG_ID';
export const setEditSubmittingOrgId = createSingleAction<
string | null, typeof SET_EDIT_SUBMITTING_ORG_ID>(SET_EDIT_SUBMITTING_ORG_ID);

export const updateSubmittingOrg = createRequestActions<IUpdateSubmittingOrgPayload, ISubmittingOrg>(
    RequestType.Update,
    'SUBMITTING_ORG',
    'SETTINGS/');
