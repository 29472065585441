import { uuidRegExpPattern } from 'shared/constants/regExpPatterns';
import { getRequestDetails } from 'shared/utils/logging/requestModel';

export const getIgnoredUrlsByErrorCode = (statusCode: number) => {
    switch (statusCode) {
        case 400:
            return [
                /\/auth\/.*\/token/, //400 is ok for checking token some API can responded without auth
            ];
        case 404:
            return [
                /\/client-users\/avatar/, //404 is ok when a user has no avatar
                new RegExp(`/clients/${uuidRegExpPattern}/employee_profiles/mine`, 'i'), // by default user has no profile
                /\/pay_range\/by_values/, // if pay range was not found by given position+location it's ok

                // this is a standard situation where PTO couldn't be found – we shouldn't log it
                new RegExp(`/clients/${uuidRegExpPattern}/users/${uuidRegExpPattern}/pto`, 'i'),
            ];
        case 410:
            return [
                /\/client-users\/email\/confirmation/, //410 is ok - user tried to confirm email twice
            ];
        default:
            return [];
    }
};

export const shouldIgnoreRequestError = (error: any) => {
    const statusCode = error.response?.status;
    if (statusCode && statusCode >= 400 && statusCode !== 401) {
        if (statusCode === 404 && error.config?.method === 'delete') {
            return true;
        }

        const requestDetails = getRequestDetails(error);
        const ignoredUrlByCode = getIgnoredUrlsByErrorCode(statusCode);
        return ignoredUrlByCode
            .some(urlToIgnore => urlToIgnore.test(requestDetails?.request?.responseURL));
    }
    return false;
};
