import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import { setHeaderContent } from 'modules/home/header/Header';
import PayrollSheetStatusTabs
    from 'modules/payrollProcessorHub/components/PayrollSheetStatusTabs/PayrollSheetStatusTabs';
import { selectPayrollFilter } from 'modules/payrollProcessorHub/store/selectors';
import StatusBadge from 'modules/timeAndExpense/components/EditSheet/StatusBadge';
import { EntryType } from 'shared/models/sheet/Sheet';
import { EditSheetView } from 'modules/timeAndExpense/components/EditSheet/EditSheetView';
import { clearSyncing } from 'store/entities/appConfig/actions';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { SheetsEntryTypes, StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllEntries, selectSheet, selectTemporarySheets } from 'store/entities/timesheet/selectors';
import { isDateInPayPeriod } from '../../../../shared/models/Dates';

export interface IEditPayrollSheetPageContentProps {
    timeSheetIds: string[];
    expenseSheetIds: string[];
    onClose: () => void;
    openConfirmation?: () => void;
    isLoading: boolean;
    showAllEntriesForPeriod?: IPayPeriod;
    statusName?: StatusNames;
}

const usePayrollHeader = () => {
    const dispatch = useDispatch();
    const classes = useSheetsInProgressStyles();
    const { status } = useSelector(selectPayrollFilter);
    useEffect(() => {
        setHeaderContent(
            <Box className={classes.headerContentWrapper}>
                {status && (<StatusBadge status={status}/>)}
                <PayrollSheetStatusTabs/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
            dispatch(clearSyncing(SyncingModels.EditableEmployeeSheet));
        };
    }, [status, dispatch, classes.headerContentWrapper]);
};

export const EditPayrollSheetPageContent = ({
    timeSheetIds: timeSheetIdsProps,
    expenseSheetIds: expenseSheetIdsProps,
    onClose,
    openConfirmation,
    statusName = StatusNames.APPROVED,
    ...props
}: IEditPayrollSheetPageContentProps) => {
    const dispatch = useDispatch();
    // Add sheets without group for current period
    const temporarySheets = useSelector(selectTemporarySheets);
    const sheet = useSelector(selectSheet(timeSheetIdsProps[0] || expenseSheetIdsProps[0]));
    const temporarySheetsForCurrentPeriod = useMemo(() => temporarySheets.filter(temporarySheet => (
        temporarySheet.period_start === sheet?.period_start && temporarySheet.period_end === sheet?.period_end
    )), [temporarySheets, sheet]);

    const timeSheetIds = useMemo(() => ([
        ...timeSheetIdsProps,
        ...temporarySheetsForCurrentPeriod.reduce((acc, { id, entry_type }) => {
            if (entry_type === EntryType.TIME) {
                acc.push(id);
            }
            return acc;
        }, new Array<string>()),
    ]), [timeSheetIdsProps, temporarySheetsForCurrentPeriod]);

    const expenseSheetIds = useMemo(() => ([
        ...expenseSheetIdsProps,
        ...temporarySheetsForCurrentPeriod.reduce((acc, { id, entry_type }) => {
            if (entry_type === EntryType.EXPENSE) {
                acc.push(id);
            }
            return acc;
        }, new Array<string>()),
    ]), [expenseSheetIdsProps, temporarySheetsForCurrentPeriod]);

    const entries = useSelector(selectAllEntries);
    const filteredEntries = useMemo(() => {
        return entries.filter(
            entry => {
                const payPeriod = {
                    period_end: sheet?.period_end,
                    period_start: sheet?.period_start,
                } as IPayPeriod;
                return entry.assignment_id === sheet?.assignment_id
                    && isDateInPayPeriod(payPeriod, entry.entry_date)
                    && (entry.entry_type === sheet.entry_type);
            },
        );
    }, [entries, sheet]);

    const onSheetSubmit = useCallback(() => {
        if (sheet?.status?.name !== StatusNames.APPROVED) {
            const sheetsEntryTypes: SheetsEntryTypes = {};
            const sheetIdsTime = filteredEntries.filter(x => x.entry_type === EntryType.TIME).map(x => x.sheet_id);
            const sheetIdsExp = filteredEntries.filter(x => x.entry_type === EntryType.EXPENSE).map(x => x.sheet_id);
            sheetIdsTime.forEach(id => {
                sheetsEntryTypes[id] = EntryType.TIME;
            });
            sheetIdsExp.forEach(id => {
                sheetsEntryTypes[id] = EntryType.EXPENSE;
            });
            dispatch(updateSheetsStatus.init({
                statusName: StatusNames.SUBMITTED,
                sheetsEntryTypes,
            }));
        }
        onClose();
    }, [dispatch, sheet, onClose, filteredEntries]);

    usePayrollHeader();

    return (
        <EditSheetView
            isPayroll
            onSheetSubmit={onSheetSubmit}
            onClose={openConfirmation ? openConfirmation : onClose}
            timeSheetIds={timeSheetIds.length ? timeSheetIds : null}
            expenseSheetIds={expenseSheetIds.length ? expenseSheetIds : null}
            statusName={statusName}
            {...props}
        />
    );
};
