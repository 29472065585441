/* eslint-disable react/display-name */
import { useIsEmployeeSheetGroupingEnabled } from 'modules/timeAndExpense/store/hooks';
import React, { useCallback, useEffect, useMemo, memo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useStoredValue } from 'shared/utils/hooks/useStoredValue';
import { Permission } from 'store/components/auth/authModels';
import { getSheetsDetails } from 'store/components/sheetDetails/sheetDetailsActions';
import { EditSheetView } from 'modules/timeAndExpense/components/EditSheet/EditSheetView';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import {
    selectCaliforniaSubmissionWithPeriod,
    selectSheet,
    selectTemporarySheets,
    selectWorkingSheets,
} from 'store/entities/timesheet/selectors';
import { setCurrentPayPeriod } from '../../../../timeAndExpense/components/WorkingPage/store/actions';

interface IEditSheetAfterSubmitProps {
    sheetId?: string;
    sheetIds?: string[];
    entryType: EntryType;
    onClose: () => void;
    showAllEntriesForPeriod?: IPayPeriod;
    statusName?: StatusNames;
}

const EditSheetAfterSubmit = memo(({
    sheetId,
    sheetIds: propsSheetIds,
    entryType,
    onClose,
    showAllEntriesForPeriod,
    statusName,
}: IEditSheetAfterSubmitProps) => {
    // Add sheets without group for current period
    const temporarySheets = useSelector(selectTemporarySheets, shallowEqual);
    const workingSheets = useSelector(selectWorkingSheets(entryType), shallowEqual);
    const sheet = useSelector(selectSheet(propsSheetIds ? propsSheetIds[0] : sheetId));
    const temporarySheetsForCurrentPeriod = useMemo(() => temporarySheets.filter(temporarySheet => (
        temporarySheet.period_start === sheet?.period_start && temporarySheet.period_end === sheet?.period_end
    )), [temporarySheets, sheet]);
    const workingSheetsForCurrentPeriod = useMemo(() => workingSheets.filter(workingSheet => (
        workingSheet.period_start === sheet?.period_start && workingSheet.period_end === sheet?.period_end
    )), [workingSheets, sheet]);
    const californiaSubmissionState = useSelector(selectCaliforniaSubmissionWithPeriod);

    const sheetIds = useStoredValue([
        ...sheetId ? [sheetId] : propsSheetIds,
        ...temporarySheetsForCurrentPeriod.map(({ id }) => id),
        ...workingSheetsForCurrentPeriod.map(({ id }) => id),
    ]);

    const dispatch = useDispatch();
    const enableGrouping = useIsEmployeeSheetGroupingEnabled();

    useEffect(() => {
        if (!showAllEntriesForPeriod?.period_end) {
            dispatch(getSheetsDetails.init({ sheetIds, entryType, permission: Permission.SubmitSheets }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsSheetIds, showAllEntriesForPeriod]);
    useEffect(() => {
        if (sheet) {
            dispatch(setCurrentPayPeriod({
                period_start: sheet.period_start,
                period_end: sheet.period_end,
            } as IPayPeriod));
        }
    }, [sheet, dispatch]);
    useEffect(() => {
        if (showAllEntriesForPeriod) {
            const payload = {
                purpose: Permission.SubmitSheets,
                request: {
                    weekend: showAllEntriesForPeriod.period_end,
                },
            };
            if (entryType === EntryType.TIME) {
                dispatch(loadTimeSheetsWithEntries.init(payload));
            } else {
                dispatch(loadExpenseSheetsWithEntries.init(payload));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAllEntriesForPeriod?.period_end]);

    const onUpdateSheetStatus = useCallback(() => {
        const californiaSubmissionValue = californiaSubmissionState
            && californiaSubmissionState.payPeriod
            && sheet?.period_start === californiaSubmissionState.payPeriod.period_start
            && sheet?.period_end === californiaSubmissionState.payPeriod.period_end
            ? californiaSubmissionState.submissionState : null;
        const sheetsEntryTypes = sheetIds.reduce((mem, id) => {
            return {
                ...mem,
                [id]: entryType,
            };
        }, {});
        dispatch(updateSheetsStatus.init({
            statusName: StatusNames.SUBMITTED,
            sheetsEntryTypes,
            californiaSubmission: californiaSubmissionValue,
        }));
        onClose();
    }, [californiaSubmissionState, sheet?.period_start, sheet?.period_end, sheetIds, dispatch, onClose, entryType]);

    return (
        <EditSheetView
            onClose={onClose}
            onSheetSubmit={onUpdateSheetStatus}
            timeSheetIds={entryType === EntryType.TIME ? sheetIds : undefined}
            expenseSheetIds={entryType === EntryType.EXPENSE ? sheetIds : undefined}
            showAllEntriesForPeriod={showAllEntriesForPeriod}
            disableApprovedEntries={enableGrouping}
            statusName={statusName}
        />
    );
});

export default EditSheetAfterSubmit;
