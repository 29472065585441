import { makeStyles } from '@material-ui/core/styles';

export const useHierarchyTableStyles = makeStyles(theme => ({
    bodyCell: {
        height: theme.spacing(5),
    },
    hierarchyTable: {
        minWidth: '60%',
    },
    tableRow: {
        cursor: 'grab',
        border: '1px solid transparent',
    },
    bodyContainer: {
        minHeight: theme.spacing(7),
    },
    noBorder: {
        borderBottom: 'none !important',
    },
}));
