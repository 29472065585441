import {
    initialLoadApprovalSheets,
    loadMoreApprovalSheets,
    resetApprovalSheets,
    setSheetApprovalTableSort,
} from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/actions';
import { AnyAction, combineReducers } from 'redux';
import { Order, SortingOrder } from 'shared/models/Order';
import { EntryType } from 'shared/models/sheet/Sheet';
import {
    setManagerSubmittedSheetsFilters,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsActions';
import { setSheetGridStatus } from 'store/entities/appConfig/actions';
import { ISheetGroup } from 'store/entities/timesheet/models/SheetApi';
import { defaultInfinityScrollState, IInfinityScrollState, singleValueReducer } from 'store/reducerUtils';

function table(
    state: IInfinityScrollState<ISheetGroup<string>>,
    action: AnyAction,
): IInfinityScrollState<ISheetGroup<string>> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case resetApprovalSheets.action:
            return defaultInfinityScrollState;
        case setSheetGridStatus.action:
        case setManagerSubmittedSheetsFilters.action:
        case initialLoadApprovalSheets.action:
        case setSheetApprovalTableSort.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case loadMoreApprovalSheets.initType:
            return {
                ...state,
                isLoading: true,
            };
        case loadMoreApprovalSheets.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case loadMoreApprovalSheets.successType:
            return {
                total: action.payload.total_items,
                items: [...state.items, ...action.payload.items],
                isLoading: false,
                cursor: action.payload.next_cursor,
            };
        default:
            return state;
    }
}

const entryType = singleValueReducer(initialLoadApprovalSheets.action, EntryType.TIME);

const sheetApprovalTableSort = singleValueReducer<SortingOrder>(
    setSheetApprovalTableSort.action,
    { pay_period: Order.desc },
);

export const approvalTable = combineReducers({
    table,
    entryType,
    sheetApprovalTableSort,
});
