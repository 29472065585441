import React, { useMemo } from 'react';
import { maxDollarsExpenseValue } from 'shared/models/validationSchemes/expenseEntry';
import NumberField, { IFormNumberInputProps } from './NumberField';
import { InputAdornment } from '@material-ui/core';

interface IMoneyFieldProps extends IFormNumberInputProps {
    withDollar?: boolean
}

export default function MoneyField({
    withDollar = false,
    InputProps,
    ...props
}: IMoneyFieldProps) {
    const dollarPrefix = useMemo(() => <InputAdornment position="start">$</InputAdornment>, []);
    return (
        <NumberField
            {...props}
            InputProps={{
                ...InputProps,
                ...withDollar ? { startAdornment: dollarPrefix } : {},
            }}
            inputProps={{
                decimalScale: 2,
                thousandSeparator: true,
                allowNegative: false,
                fixedDecimalScale: true,
            }}
            min={0}
            max={maxDollarsExpenseValue}
        />
    );
}
