import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from 'modules/settings/submodules/employees/store/actions';
import { selectIsPasswordResetting } from 'modules/settings/submodules/employees/store/selectors';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { getUserName } from 'shared/utils/converters/user';
import { useModal } from 'shared/utils/hooks/useModal';
import { selectUserById } from 'store/entities/users/selectors';

interface IResetPasswordButtonProps {
    employeeId: string;
    className?: string;
}

export const ResetPasswordButton = ({ employeeId, className }: IResetPasswordButtonProps) => {
    const dispatch = useDispatch();
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const isLoading = useSelector(selectIsPasswordResetting);
    const user = useSelector(selectUserById(employeeId));

    const handleConfirmation = useCallback(() => {
        dispatch(resetPassword.init(employeeId));
        onModalClose();
    }, [dispatch, employeeId, onModalClose]);
    return (
        <>
            <ButtonWithLoader
                variant="text"
                color="default"
                isLoading={isLoading}
                onClick={onModalOpen}
                className={className}
            >
                Reset Employee Password
            </ButtonWithLoader>
            <ConfirmationDialog
                open={isModalOpened}
                onConfirm={handleConfirmation}
                onCancel={onModalClose}
                confirmText="Reset password"
            >
                Are you sure you want to reset {getUserName(user)}{`'`}s password?
            </ConfirmationDialog>
        </>
    );
};
