import { useCallback, useEffect, useMemo, useState } from 'react';
import { getScaZones, getStatesByScaZone } from 'store/entities/scaZone/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import { INamedEntity } from 'shared/models/Entity';
import { IScaOptions } from 'shared/components/formSpecialFields/scaZone/model';
import { ICounty, IScaZone, IStateUS } from 'store/entities/scaZone/models';
import { scaZonesByAssignmentStateKey } from 'store/entities/scaZone/reducer';
import { isEmpty } from 'lodash-es';

interface IScaSearch {
    stateSearch?: string;
    countySearch?: string;
}

export function useScaZone(
    parentScaZoneId?: number,
    assignmentId?: string,
    { stateSearch, countySearch }: IScaSearch = {},
    initialStateId: string | null = null,
    initialCountryId: string | null = null,
) {
    const dispatch = useDispatch();
    const [stateId, setStateId] = useState<string | null>(initialStateId);
    const [countyId, setCountyId] = useState<string | null>(initialCountryId);
    const {
        statesById,
        scaZonesById,
        scaZonesByAssignmentState,
        statesLoading,
        scaZonesLoading,
        statesByScaZones,
    } = useSelector((storeState: IStore) => storeState.scaZone);
    const availableStates = useMemo(() => {
        if (parentScaZoneId) {
            const stateIds = statesByScaZones[parentScaZoneId] || [];
            return stateIds.map((id: string) => statesById[id]).filter(Boolean);
        } else {
            return Object.values(statesById);
        }
    }, [statesById, statesByScaZones, parentScaZoneId]);

    const stateValue: IStateUS | undefined = useMemo(
        () => stateId ? statesById[stateId] : undefined, [statesById, stateId],
    );

    const scaZoneValue: IScaZone | undefined = useMemo(
        () => countyId
            ? Object.values(scaZonesById as Record<string, IScaZone>).find(
                (scaZone: IScaZone) => scaZone.counties.some(county => county.id === countyId),
            )
            : undefined,
        [scaZonesById, countyId],
    );

    const countyValue: ICounty | undefined = scaZoneValue ? scaZoneValue.counties.find(
        county => county.id === countyId,
    ) : undefined;

    const onSetStateId = useCallback((option: string | null) => {
        setStateId(option);
        setCountyId(null);
    }, [setStateId, setCountyId]);

    const onSetCountyId = useCallback((option: string | null) => {
        setCountyId(option);
    }, [setCountyId]);

    useEffect(() => {
        dispatch(getStatesByScaZone.init({ parentScaZoneId }));
    }, [dispatch, parentScaZoneId]);

    useEffect(() => {
        if (assignmentId && stateId) {
            dispatch(getScaZones.init({ assignmentId, stateId, parentScaZoneId }));
        }
    }, [dispatch, assignmentId, stateId, parentScaZoneId]);

    const statesList: Array<INamedEntity> = useMemo(() => {
        if (!assignmentId || isEmpty(availableStates)) {
            return [];
        }

        const loweredSearch = stateSearch ? stateSearch.toLowerCase() : '';

        return availableStates.reduce((acc: Array<INamedEntity>, stateInfo: IStateUS) => {
            if (stateSearch && !(
                stateInfo.state_name.toLowerCase().startsWith(loweredSearch)
                || stateInfo.state_shortname.toLowerCase().startsWith(loweredSearch))
            ) {
                return acc;
            }

            return acc.concat([{
                id: stateInfo.id,
                name: stateInfo.state_name,
            }]);
        }, []);
    }, [availableStates, assignmentId, stateSearch]);

    const countiesList: Array<INamedEntity> = useMemo(() => {
        if (!assignmentId || !stateId) {
            return [];
        }

        const rawList = scaZonesByAssignmentState[scaZonesByAssignmentStateKey(assignmentId, stateId)];

        if (! rawList) {
            return [];
        }

        const loweredSearch = countySearch ? countySearch.toLowerCase() : '';

        return rawList.reduce((acc: Array<INamedEntity>, scaZoneBEId: number) => {
            const { counties, id } = scaZonesById[scaZoneBEId];

            if (counties.length === 0){
                return acc;
            }

            const filteredCounties = loweredSearch ? counties.filter(
                (county: ICounty) => county.county_name.toLowerCase().startsWith(loweredSearch)
                || id.toString().toLowerCase().startsWith(loweredSearch),
            ) : counties;

            return acc.concat(filteredCounties.map((county: ICounty) => ({
                id: county.id,
                name: `${county.county_name} - ${id}`,
            })));

        }, []);
    }, [assignmentId, stateId, scaZonesByAssignmentState, scaZonesById, countySearch],
    );

    const optionsList: IScaOptions = useMemo(() => ({
        states: {
            title: 'State',
            setValue: onSetStateId,
            values: statesList,
        },
        counties: {
            title: 'County/Location',
            setValue: onSetCountyId,
            values: countiesList,
        },
    }), [statesList, countiesList, onSetStateId, onSetCountyId]);

    return {
        optionsList,
        stateValue,
        scaZoneValue,
        countyValue,
        onSetStateId,
        onSetCountyId,
        statesLoading,
        scaZonesLoading,
    };
}
