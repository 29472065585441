import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const border = '1px solid';

export const useEntryEditStyles = makeHighPriorityStyles((theme: Theme) => {
    const dayPickerChangeIconPadding = theme.spacing(3);
    const timePadding = theme.spacing(2, 8);
    const entityPaddingSmall = theme.spacing(2);

    return {
        paper: {
            [theme.breakpoints.down('xs')]: {
                backgroundColor: colors.whiteSecondary,
            },
        },
        dayPickerIconLeft: {
            paddingRight: dayPickerChangeIconPadding,
        },
        dayPickerIconRight: {
            paddingLeft: dayPickerChangeIconPadding,
        },
        dayPickerContent: {
            alignSelf: 'normal',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderLeft: border,
            borderRight: border,
            fontWeight: 'bold',
        },
        timesheetInfoItem: {
            padding: 0,
            '& > *': {
                lineHeight: 1.4,
                '&:not(:first-child)': {
                    marginLeft: theme.spacing(0.5),
                    letterSpacing: -1,
                },
                fontSize: '14px',
                letterSpacing: 'normal',
            },
        },
        inputsWrapper: {
            [theme.breakpoints.down('xs')]: {
                padding: 0,
            },
        },
        formInput: {
            width: '100%',
            backgroundColor: colors.white,
            fontSize: '14px',
            [theme.breakpoints.down('xs')]: {
                '& > *:not(p)': {
                    height: theme.spacing(8),
                },
            },
        },
        formInputWithLabel: {
            width: theme.spacing(36),
            backgroundColor: colors.white,
            fontSize: '14px',
            [theme.breakpoints.down('xs')]: {
                '& > *:not(p)': {
                    height: theme.spacing(8),
                },
            },
        },
        button: {
            minWidth: 160,
            minHeight: theme.spacing(5),
        },
        buttonLabel: {
            textTransform: 'uppercase',
        },
        notesInputWrapper: {
            display: 'flex',
            margin: theme.spacing(2, 2),
        },
        notesInput: {
            minHeight: theme.spacing(12),
            backgroundColor: colors.white,
            fontSize: 14,
        },
        expenseDataEdit: {
            maxWidth: `calc(50% - 8px)`,
            '&+&': {
                marginLeft: theme.spacing(2),
            },
        },
        entityEdit: {
            backgroundColor: colors.secondary,
            color: colors.white,
            margin: theme.spacing(2, 0),

            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(2),
            },
        },
        entryDataEdit: {
            padding: timePadding,

            [theme.breakpoints.down('xs')]: {
                padding: entityPaddingSmall,
            },
        },
        quantityLabel: {
            color: 'white',
        },
        quantityLabelFilled: {
            '&.Mui-error': {
                color: 'white',
            },
        },
        timesheetInfo: {
            padding: theme.spacing(1, 2),
        },
        timeInputsHeader: {
            textTransform: 'uppercase',
            fontSize: 16,
        },
        timeInputs: {
            display: 'flex',
            justifyContent: 'flex-start',
            padding: theme.spacing(2, 0),

            '& input[type=time]::-webkit-calendar-picker-indicator': {
                filter: 'invert(1)', // unfortunately only this decision is working (color doesn't work)
            },
        },
        timeInput: {
            flexBasis: 160,
        },
        timeInputIcon: {
            color: colors.white,
        },
        timeInputWithMargin: {
            marginLeft: theme.spacing(2),
        },
        timeOptionButton: {
            color: theme.palette.secondary.main,
            minWidth: 120,
        },
        footer: {
            display: 'flex',
            flexShrink: 0,
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(2),

            '& > *:not(:first-child)': {
                marginLeft: theme.spacing(1),
            },

            [theme.breakpoints.up('sm')]: {
                marginTop: 'auto',
            },
        },
        form: {
            marginBottom: theme.spacing(2),
        },
        zipCodeWrapper: {
            marginTop: theme.spacing(2),
        },
        zipCode: {
            width: '100%',
        },
        completesWrapper: {
            width: '100%',
        },
        breakTime: {
            backgroundColor: colors.white,
        },
        breakInOutTime: {
            minWidth: 'auto',
            '& + &': {
                marginLeft: theme.spacing(2),
            },
        },
    };
});
