import React from 'react';
import { useField } from 'formik';
import { IFormFieldProps } from 'shared/components/formFields/models';
import NumberInput from 'shared/components/formFields/NumberInput';

const PhoneField = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
}: IFormFieldProps) => {
    const [field, meta, helper] = useField(name);

    const handleChange = (value: string) => {
        helper.setValue(value);
    };
    return (
        <NumberInput
            id={id}
            name={name}
            label={label}
            initialValue={field.value}
            className={className}
            inputProps={{
                format: '(###) ###-####',
                mask: '_',
                // allowEmptyFormatting: true,
            }}
            disabled={disabled}
            error={meta.error}
            touched={meta.touched}
            setTouched={helper.setTouched}
            onChange={handleChange}
        />
    );
};

export default PhoneField;
