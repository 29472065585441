import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const gridAreas = {
    hideSidebar: 'hideSidebar',
    actions: 'actions',
    userActions: 'userActions',
    title: 'title',
};

export const useHeaderStyles = makeHighPriorityStyles((theme: Theme) => ({
    headerNewRoot: {
        display: 'grid',
        gridTemplateColumns: `minmax(max-content, 15%) 1fr ${theme.spacing(12)}px`,
        gridTemplateAreas: `"${gridAreas.title} . ${gridAreas.userActions}"`,
        color: theme.palette.text.primary,
        backgroundColor: colors.white,
        height: theme.spacing(8),

        [theme.breakpoints.up('md')]: {
            borderBottom: `1px solid ${colors.gray}`,
            padding: theme.spacing(1, 4),
        },

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            height: 'auto',
        },
    },
    leftSideWrapper: {
        gridArea: gridAreas.title,
        maxHeight: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.primary.main,
            color: colors.white,
            padding: theme.spacing(1),
        },
    },
    titleWrapper: {
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },

        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    desktopPageHeader: {
        '&&': {
            margin: 0,
        },
    },
    mobilePageHeader: {
        '&&': {
            margin: theme.spacing(0, 0.5, 0, 2),
            textTransform: 'none',
            lineHeight: 1.5,
        },
    },
    rightSideWrapper: {
        gridArea: gridAreas.userActions,
        alignSelf: 'flex-end',
        justifySelf: 'center',
        padding: 0,
        '& > * + *': {
            marginLeft: theme.spacing(1),
        },
    },
    Avatar: {
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    titleContainer: {
        gridArea: gridAreas.title,
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
            alignSelf: 'end',
        },

        [theme.breakpoints.down('xs')]: {
            alignSelf: 'center',
        },
    },
    homeHeader: {
        marginBottom: theme.spacing(1.5),
    },
    Title: {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.fontWeightBold,

        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },
    },
    actions: {
        gridArea: gridAreas.actions,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
    },
    hideSidebar: {
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            color: 'white',
            margin: theme.spacing(1.5, 0, 0, -4),
            background: colors.black,
            height: theme.spacing(4),
            width: theme.spacing(3),
            borderRadius: 0,
            boxShadow: theme.shadows[3],

            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
            }),

            '&:hover': {
                background: colors.black,
                width: theme.spacing(4),
            },
        },
    },
    avatarColor: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
        fontSize: 'large',
    },
}));
