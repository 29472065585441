import { Nullable } from '../../../../../types/types';

export enum ChangeAssignmentStartDateFormProps {
    AssignmentId = 'assignmentId',
    CurrentStartDate = 'currentStartDate',
    PrismStartDate = 'prismStartDate',
    NewStartDate = 'newStartDate',
    ShouldApplyToSubassignments = 'shouldApplyToSubassignments',
}

export interface IChangeAssignmentStartDateFormValues {
    [ChangeAssignmentStartDateFormProps.AssignmentId]: Nullable<string>;
    [ChangeAssignmentStartDateFormProps.CurrentStartDate]: Nullable<string>;
    [ChangeAssignmentStartDateFormProps.PrismStartDate]: Nullable<string>;
    [ChangeAssignmentStartDateFormProps.NewStartDate]: Nullable<string>;
    [ChangeAssignmentStartDateFormProps.ShouldApplyToSubassignments]: boolean;
}
