import {
    FinishOnboardingButtonV2,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/FinishOnboardingConfirmation/FinishOnboardingButtonV2';

import { finishOnboarding } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import { selectOnboardingIdFinishing } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { selectOfferLetter } from 'modules/offerLetter/store/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IFinishOnboardingButtonProps {
    offerLetterId: string;
}

export const FinishOnboardingButton = ({ offerLetterId }: IFinishOnboardingButtonProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectOnboardingIdFinishing);
    const onConfirm = useCallback(() => {
        dispatch(finishOnboarding.init(offerLetterId));
    }, [dispatch, offerLetterId]);
    const offerLetter = useSelector(selectOfferLetter(offerLetterId));
    const disableOfferLetterPendingI9Status = false;

    if (disableOfferLetterPendingI9Status || offerLetter?.status?.slug === OfferLetterStatusSlug.Accepted) {
        return null;
    }

    return (
        <FinishOnboardingButtonV2
            isLoading={isLoading}
            onConfirm={onConfirm}
        />
    );
};
