/**
 * Format phone as a (999) 999-9999 or +1 (999) 999-9999
 * @param phoneString like '9999999999', '+19999999999'
 */
export const formatPhoneNumber = (phoneString?: string): string => {
    const cleaned = ('' + phoneString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';
};
