import { useSelector } from 'react-redux';
import { selectCcpImportState, selectCcpPostImportState } from './selectors';
import { useCcpReportStyle } from '../CcpReportStyle';
import { ICcpReportOverviewItem } from '../BulkCcpImportReport';

export function useBulkPreCreateCcpOverviewItems(): ICcpReportOverviewItem[] {
    const preInitializeReportState = useSelector(selectCcpImportState);
    const classes = useCcpReportStyle();

    const numberOfFailed = preInitializeReportState?.number_of_transactions_failed;
    const numberOfTransactions = preInitializeReportState?.number_of_transactions;

    return [
        {
            label: '# of transactions being created',
            values: [String(numberOfTransactions - numberOfFailed)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# of transactions that will fail',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}

export function useBulkImportCreateCcpOverviewItems(): ICcpReportOverviewItem[] {
    const importReportState = useSelector(selectCcpPostImportState);
    const classes = useCcpReportStyle();

    const numberOfFailed = importReportState?.number_of_transactions_failed;
    const numberOfTransactions = importReportState?.number_of_transactions;

    return [
        {
            label: '# of transactions created',
            values: [String(numberOfTransactions - numberOfFailed)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# of transactions that skipped',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}

export function useBulkPostImportCreateCcpOverviewItems(): ICcpReportOverviewItem[] {
    const importReportState = useSelector(selectCcpPostImportState);
    const classes = useCcpReportStyle();

    const numberOfFailed = importReportState?.number_of_transactions_failed;
    const numberOfTransactions = importReportState?.number_of_transactions;

    return [
        {
            label: '# of transactions created',
            values: [String(numberOfTransactions)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# of transactions that skipped',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}

export function usePrecreateCcpHasFailed(): boolean {
    const preInitializeReportState = useSelector(selectCcpImportState);
    const numberOfFailed = preInitializeReportState?.number_of_transactions_failed;
    return numberOfFailed !== 0;
}
