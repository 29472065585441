import moment from 'moment/moment';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { Nullable } from '../../../types/types';
import { IAssignment } from 'store/entities/configuration/configurationModel';

export function isAssignmentActive(assignment: IAssignment): boolean {
    if (!assignment.end_date) {
        return true;
    }
    return moment().isBefore(assignment.end_date);
}

export function getFormattedAssignmentHireDate(assignment: Nullable<IAssignment>): string {
    return assignment?.hire_date ? formatShortDate(assignment.hire_date) : '';
}
