import { fade } from '@material-ui/core/styles';
import React, { MouseEvent } from 'react';
import { IconButton, IconButtonProps, useTheme } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

import useActionIconStyles from './ActionIconStyles';
import { SvgIconComponent } from '@material-ui/icons';

interface IActionIconProps extends IconButtonProps {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    Icon: SvgIconComponent;
    htmlColor?: string;
}

export default function ActionIcon({ onClick, Icon, htmlColor, disabled, ...props }: IActionIconProps) {
    const initialClasses = useActionIconStyles();
    const theme = useTheme();
    const colorOrDefault = htmlColor || theme.palette.primary.main;
    const color = disabled ? fade(colors.gray, 0.5) : colorOrDefault;
    return (
        <IconButton
            {...props}
            onClick={onClick}
            classes={{ root: initialClasses.button }}
            disabled={disabled}
        >
            <Icon
                className={`${initialClasses.actionIcon}`}
                htmlColor={color}
            />
        </IconButton>
    );
}
