import { ActionsReturnTypes, createRequestActions, RequestType } from 'store/utils';
import { IExpenseEntrySearchResponse } from 'store/entities/timesheet/api/expenseApi';

const moduleActionPrefix = 'travelExpense';

export interface IGetMoreTravelExpensesPayload{
    sheet_ids?: string[],
}

export const getMoreTravelExpensesAction = createRequestActions<
IGetMoreTravelExpensesPayload | void, IExpenseEntrySearchResponse>(
    RequestType.Get,
    'MORE_TRAVEL_EXPENSES',
    moduleActionPrefix,
);

export type TravelExpenseActions =
    | ActionsReturnTypes<typeof getMoreTravelExpensesAction>
