import React from 'react';
import { Box, ListItem, Typography } from '@material-ui/core';
import { ISheetInfoItemProps, SheetInfoItem } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';

export const SheetIdsInfo = ({ data }: ISheetInfoItemProps) => {
    const threshold = 2;
    const ids = data.value.split(',');
    const classes = useSheetDetailStyles();

    if (ids.length <= threshold) {
        return <SheetInfoItem data={data}/>;
    }

    return (
        <ListItem classes={{ root: `${classes.timesheetInfoItem} ${classes.idsInfoItem}` }}>
            <Typography variant="subtitle2" className={classes.title}>
                {data.title}:
            </Typography>
            {ids.map(id => (
                <Box key={id}>
                    <Typography variant="body2">
                        {id}
                    </Typography>
                </Box>
            ))}
        </ListItem>
    );
};
