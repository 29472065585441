import React from 'react';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import RecallButton from 'shared/components/sheetsSubmitted/filterAndActionControls/RecallButton';
import { useDisallowPastDayEntry } from '../../../../../../store/entities/clients/hooks';

export default function SheetsSubmittedActions({ typedSheets, ...props }: IAvailableActionsProps) {
    const disallowPastDayEntry = useDisallowPastDayEntry();
    return (
        <>
            {!disallowPastDayEntry && (
                <RecallButton sheetsEntryTypes={typedSheets} {...props}/>
            )}
        </>
    );
}
