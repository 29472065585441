import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';
import { IAuthSuccess, IPasswordAuthRequest, Permission, SearchParams } from './authModels';

export const AUTHENTICATION_TOKEN_REQUESTED = 'AUTHENTICATION_TOKEN_REQUESTED';
export const AUTHENTICATION_TOKEN_SUCCEEDED = 'AUTHENTICATION_TOKEN_SUCCEEDED';
export const AUTHENTICATION_TOKEN_FAILED = 'AUTHENTICATION_TOKEN_FAILED';

export const AUTHENTICATION_PASSWORD_REQUESTED = 'AUTHENTICATION_PASSWORD_REQUESTED';
export const AUTHENTICATION_PASSWORD_SUCCEEDED = 'AUTHENTICATION_PASSWORD_SUCCEEDED';
export const AUTHENTICATION_PASSWORD_FAILED = 'AUTHENTICATION_PASSWORD_FAILED';

export const AUTHENTICATION_TOKEN_UPDATE = 'AUTHENTICATION_TOKEN_UPDATE';
export const AUTHENTICATION_TOKEN_UPDATE_SUCCESS = 'AUTHENTICATION_TOKEN_UPDATE_SUCCESS';
export const AUTHENTICATION_TOKEN_UPDATE_ERROR = 'AUTHENTICATION_TOKEN_UPDATE_ERROR';

export const AUTHENTICATION_FORGET_TOKENS_REQUESTED = 'AUTHENTICATION_FORGET_TOKENS_REQUESTED';
export const AUTHENTICATION_FORGET_TOKENS_SUCCEEDED = 'AUTHENTICATION_FORGET_TOKENS_SUCCEEDED';
export const AUTHENTICATION_FORGET_TOKENS_FAILED = 'AUTHENTICATION_FORGET_TOKENS_FAILED';

export const AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_REQUESTED = 'AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_REQUESTED';
export const AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_SUCCEEDED = 'AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_SUCCEEDED';
export const AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_FAILED = 'AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_FAILED';

export const authByToken = createActions<void, IAuthSuccess, any,
    typeof AUTHENTICATION_TOKEN_REQUESTED,
    typeof AUTHENTICATION_TOKEN_SUCCEEDED,
    typeof AUTHENTICATION_TOKEN_FAILED
>(
    AUTHENTICATION_TOKEN_REQUESTED,
    AUTHENTICATION_TOKEN_SUCCEEDED,
    AUTHENTICATION_TOKEN_FAILED,
);

export const authInitial = createActions<void, IAuthSuccess, any,
    typeof AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_REQUESTED,
    typeof AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_SUCCEEDED,
    typeof AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_FAILED
>(
    AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_REQUESTED,
    AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_SUCCEEDED,
    AUTHENTICATION_INITIAL_AUTH_BY_TOKEN_FAILED,
);

export const authByPassword = createActions<IPasswordAuthRequest, IAuthSuccess, any,
    typeof AUTHENTICATION_PASSWORD_REQUESTED,
    typeof AUTHENTICATION_PASSWORD_SUCCEEDED,
    typeof AUTHENTICATION_PASSWORD_FAILED
>(
    AUTHENTICATION_PASSWORD_REQUESTED,
    AUTHENTICATION_PASSWORD_SUCCEEDED,
    AUTHENTICATION_PASSWORD_FAILED,
);

export const authTokenUpdate = createActions<void, IAuthSuccess, any,
    typeof AUTHENTICATION_TOKEN_UPDATE, typeof AUTHENTICATION_TOKEN_UPDATE_SUCCESS,
    typeof AUTHENTICATION_TOKEN_UPDATE_ERROR
>(
    AUTHENTICATION_TOKEN_UPDATE, AUTHENTICATION_TOKEN_UPDATE_SUCCESS, AUTHENTICATION_TOKEN_UPDATE_ERROR,
);

const LOGOUT = 'LOGOUT';
export const logout = createSingleAction<void, typeof LOGOUT>(LOGOUT);

const KEYCLOAK_LOGOUT = 'KEYCLOAK_LOGOUT';
export const keycloakLogout = createSingleAction<boolean, typeof KEYCLOAK_LOGOUT>(KEYCLOAK_LOGOUT);

const RESET_STATE = 'RESET_STATE';
export const resetState = createSingleAction<void, typeof RESET_STATE>(RESET_STATE);

const CACHE_AUTH_EMAIL = 'auth/CACHE_EMAIL';
export const cacheAuthEmail = createSingleAction<string, typeof CACHE_AUTH_EMAIL>(CACHE_AUTH_EMAIL);

export const SET_CLIENT_USER_PERMISSIONS = 'SET_CLIENT_USER_PERMISSIONS';
export const setClientUserPermission = createSingleAction<Permission[],
    typeof SET_CLIENT_USER_PERMISSIONS>(SET_CLIENT_USER_PERMISSIONS);

interface ILoginAsUserPayload {
    userId: string;
    clientId?: string;
}

const LOGIN_AS_USER = 'auth/LOGIN_AS_USER';
const LOGIN_AS_USER_SUCCESS = 'auth/LOGIN_AS_USER_SUCCESS';
const LOGIN_AS_USER_ERROR = 'auth/LOGIN_AS_USER_ERROR';
export const loginAsUser = createActions<
ILoginAsUserPayload,
void,
any,
typeof LOGIN_AS_USER,
typeof LOGIN_AS_USER_SUCCESS,
typeof LOGIN_AS_USER_ERROR
>(
    LOGIN_AS_USER,
    LOGIN_AS_USER_SUCCESS,
    LOGIN_AS_USER_ERROR,
);

const IMPERSONATE_SWITCH_BACK = 'auth/IMPERSONATE_SWITCH_BACK';
const IMPERSONATE_SWITCH_BACK_SUCCESS = 'auth/IMPERSONATE_SWITCH_BACK_SUCCESS';
const IMPERSONATE_SWITCH_BACK_ERROR = 'auth/IMPERSONATE_SWITCH_BACK_ERROR';
export const impersonateSwitchBack = createActions<
void,
void,
any,
typeof IMPERSONATE_SWITCH_BACK,
typeof IMPERSONATE_SWITCH_BACK_SUCCESS,
typeof IMPERSONATE_SWITCH_BACK_ERROR
>(
    IMPERSONATE_SWITCH_BACK,
    IMPERSONATE_SWITCH_BACK_SUCCESS,
    IMPERSONATE_SWITCH_BACK_ERROR,
);

export const KEYCLOAK_AUTHENTICATION = 'KEYCLOAK_AUTHENTICATION';
export const KEYCLOAK_AUTHENTICATION_SUCCESS = 'KEYCLOAK_AUTHENTICATION_SUCCESS';
export const KEYCLOAK_AUTHENTICATION_ERROR = 'KEYCLOAK_AUTHENTICATION_ERROR';

export const keycloakAuthentication = createActions<void, IAuthSuccess, any,
    typeof KEYCLOAK_AUTHENTICATION, typeof KEYCLOAK_AUTHENTICATION_SUCCESS, typeof KEYCLOAK_AUTHENTICATION_ERROR
>(
    KEYCLOAK_AUTHENTICATION, KEYCLOAK_AUTHENTICATION_SUCCESS, KEYCLOAK_AUTHENTICATION_ERROR,
);

export const KEYCLOAK_LOGIN = 'KEYCLOAK_LOGIN';
export const keycloakLogin = createSingleAction<void, typeof KEYCLOAK_LOGIN>(KEYCLOAK_LOGIN);

const REGISTRATION_REDIRECT = 'auth/REGISTRATION_REDIRECT';
export const registrationRedirect = createSingleAction<Record<string, SearchParams>, typeof REGISTRATION_REDIRECT>(
    REGISTRATION_REDIRECT,
);

export type AuthAction = ActionsReturnTypes<typeof authByToken>
| ActionsReturnTypes<typeof authByPassword>
| ActionsReturnTypes<typeof authTokenUpdate>
| ActionsReturnTypes<typeof loginAsUser>
| ActionsReturnTypes<typeof keycloakAuthentication>
| ActionsReturnTypes<typeof authInitial>
| ReturnType<typeof setClientUserPermission>
| ReturnType<typeof logout>
| ReturnType<typeof keycloakLogout>;
