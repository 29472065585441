import {
    changeCcpTransaction,
    getCcpTransactions,
    initialLoadCcpTransactionsPage,
    setCcpEditMode,
    submitCcpTransactionAction,
    triggerEditModeSave,
} from '../../../store/actions';
import { call, put, takeEvery, select, takeLatest } from 'typed-redux-saga';
import { ccpApi } from '../../api/ccpApi';
import { ITransactionChange } from '../../../models/ccpModel';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { addNewCcpAttachment, removeCcpAttachment } from './actions';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { selectCcpTransactionsById } from '../../CcpTransactionsTable/store/selectors';
import { ICcpAttachment, ICcpTransaction } from '../../../models/CcpTransaction';
import { selectCcpEditMode } from 'modules/ccp/store/selectors';
import { selectTransactionCodes } from '../../CcpCreate/store/selectors';
import { selectCcpDirtyTransactions, selectCcpUnsavedChildTransactionsById } from './selectors';

function* changeCcpTransactionsSaga({ payload }: { payload: ITransactionChange }) {
    const transactionsById = yield* select(selectCcpTransactionsById);
    const transaction = transactionsById[payload.id];
    const isEditMode = yield* select(selectCcpEditMode);

    let result;
    if (!isEditMode) {
        result = yield* call(ccpApi.patchTransaction, payload);
    } else {
        const ccpTransactionCodesById = yield* select(selectTransactionCodes);
        const transaction_code = ccpTransactionCodesById[payload.transaction_code];
        result = {
            ...transaction,
            ...payload,
            transaction_code_id: payload.transaction_code,
            transaction_code: transaction_code,
            is_dirty: true,
            is_unsaved_split: false,
        };
    }
    yield* put(getCcpTransactions.success([result]));
    yield* put(changeCcpTransaction.success(result));
}

export function* changeTransactionsWatcher() {
    // @ts-ignore
    yield takeLatest(changeCcpTransaction.initType,
        withBackendErrorHandler(
            changeCcpTransactionsSaga,
            changeCcpTransaction.error,
            'Unable to update Transaction.',
        ),
    );
}

function* triggerEditModeSaveSaga() {
    const dirtyTransactions = yield* select(selectCcpDirtyTransactions);
    const unsavedChildTransactionsById = yield* select(selectCcpUnsavedChildTransactionsById);

    if (dirtyTransactions.length === 0) {
        // @ts-ignore
        yield put(triggerEditModeSave.success([]));
        yield* put(setCcpEditMode(false));
        return;
    } else {
        try {
            const reqData = dirtyTransactions.map(item => {
                return {
                    transaction_id: item.id,
                    department_id: item.department_id,
                    transaction_code_id: item.transaction_code_id,
                    note: item.note,
                    attachments: item.attachments.map(trItem => {
                        return {
                            filename: trItem.filename,
                            id: trItem.id,
                            mimetype: trItem.mimetype,
                            url: trItem.url,
                        };
                    }),
                    split_items: (unsavedChildTransactionsById[item.id] || []).map(
                        // @ts-ignore
                        (trItem: ICcpTransaction) => {
                            return {
                                transaction_code_id: trItem.transaction_code_id,
                                department_id: trItem.department_id,
                                amount: trItem.amount,
                                note: trItem.note,
                            };
                        },
                    ),
                };
            });
            const status = yield* call(ccpApi.putMultipleTransactions, reqData);

            if (status === 204) {
                yield put(setGlobalToast({
                    severity: IModalSeverity.Success,
                    title: 'Transactions were successfully updated.',
                    autoHideDuration: 5000,
                }));
            }
        } catch (e) {
            if (e?.response?.status !== 400) {
                throw e;
            }
        }
    }
    yield put(triggerEditModeSave.error());
    yield put(setCcpEditMode(false));
    yield put(initialLoadCcpTransactionsPage());
}

export function* triggerEditModeSaveWatcher() {
    yield takeLatest(triggerEditModeSave.initType,
        withBackendErrorHandler(
            triggerEditModeSaveSaga,
            triggerEditModeSave.error,
            'Unable to save Transactions.',
        ),
    );
}

function* deleteCcpAttachmentSaga(action: ReturnType<typeof removeCcpAttachment.init>) {
    const isEditMode = yield* select(selectCcpEditMode);
    const payload = action.payload as ICcpAttachment;
    const transactions = yield* select(selectCcpTransactionsById);
    const transactionCurrent = transactions[payload.transaction_id];
    if (!isEditMode) {
        yield* call(ccpApi.deleteCcpEntryAttachment, payload);
    } else {
        transactionCurrent.is_dirty = true;
    }
    transactionCurrent.attachments = transactionCurrent.attachments.filter((x: ICcpAttachment) => x.id !== payload.id);
    yield put(getCcpTransactions.success([transactionCurrent]));
    yield put(removeCcpAttachment.success(payload.id));
}

export function* deleteCcpAttachmentWatcher(){
    yield takeEvery(removeCcpAttachment.initType, deleteCcpAttachmentSaga);
}

function* addNewCcpEntryAttachmentSaga(action: ReturnType<typeof addNewCcpAttachment.init>) {
    const isEditMode = yield* select(selectCcpEditMode);
    // @ts-ignore
    const relatedEntityId = action.payload.related_entity_id;
    // @ts-ignore
    const attachmentData: ICcpAttachment = {
        ...action.payload,
        related_entity_id: undefined,
    };
    const transactionsById = yield* select(selectCcpTransactionsById);
    const currentTransaction = transactionsById[relatedEntityId];
    let newAttachment: ICcpAttachment;
    if (!isEditMode) {
        newAttachment = yield* call(ccpApi.createCcpEntryAttachment, relatedEntityId, attachmentData);
    } else {
        currentTransaction.is_dirty = true;
        newAttachment = {
            ...attachmentData,
            transaction_id: relatedEntityId,
        } as ICcpAttachment;
    }
    currentTransaction.attachments.push(newAttachment);
    yield put(getCcpTransactions.success([currentTransaction]));
    yield* put(addNewCcpAttachment.success(newAttachment));
}

export function* addNewCcpEntryAttachmentWatcher(){
    yield takeEvery(addNewCcpAttachment.initType, addNewCcpEntryAttachmentSaga);
}

function* submitUnreconciledItemsSaga() {
    try {
        const transactionsUpdated = yield* call(ccpApi.submitUnreconciledTransactions);
        yield put(initialLoadCcpTransactionsPage());
        yield* put(submitCcpTransactionAction.success(transactionsUpdated));
    } catch (e) {
        if (e?.response?.status === 400) {
            yield* put(setGlobalToast({ severity: IModalSeverity.Error, title: 'Transactions were not submitted' }));
            return;
        } else {
            throw e;
        }
    }
}

export function* submitUnreconciledItemsWatcher(){
    yield takeEvery(submitCcpTransactionAction.initType, submitUnreconciledItemsSaga);
}
