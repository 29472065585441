import { Theme } from '@material-ui/core/styles';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useSpecificSubmittedStyles = makeHighPriorityStyles((theme: Theme) => ({
    pdfIcon: {
        justifyContent: 'center',
    },
    amountCell: {
        justifyContent: 'flex-end',
    },
    ApprovalTable: {
        borderLeft: `1px solid ${colors.lightGray}`,
        borderRight: `1px solid ${colors.lightGray}`,
        [theme.breakpoints.down('xs')]: {
            border: 0,
        },
    },
    quantity: {
        textAlign: 'right',
        paddingRight: theme.spacing(2),
    },
    continueOnboarding: {
        width: 20,
        height: 20,
    },
    rootCheckBox: {
        paddingLeft: `0px !important`,
    },
    checkboxSubmittedStyle: {
        justifyContent: 'flex-start',
        '& svg': {
            fontSize: 21,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: `0px !important`,
        },
    },
}));

export const useSubmittedTableStyles = () => ({
    ...useDefaultTableStyles(),
    ...useSpecificSubmittedStyles(),
});
