import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { useSelector } from 'react-redux';
import { selectJobNumbersById, selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import { maxBy } from 'lodash-es';

export function useMaxLevelApproverIdBySheetId(
    calculationGroup: ISheetCalculationBatch,
): Record<string, string | null> {
    const jobNumbersByIds = useSelector(selectJobNumbersById);
    const subassignmentsByIds = useSelector(selectSubassignmentsByIds);

    const allCalculations = [...calculationGroup.time_calculations, ...calculationGroup.expense_calculations];

    return allCalculations.reduce((mem, calculation) => {
        let approverId = null;
        if (calculation.job_number_id) {
            // @ts-ignore
            approverId = jobNumbersByIds[calculation.job_number_id]?.manager_id;
        } else {
            const managers = subassignmentsByIds[calculation.subassignment_id]?.managers || [];
            const maxLevelAssignmentManagerInfo = maxBy(managers, 'manager_level');
            // @ts-ignore
            approverId = maxLevelAssignmentManagerInfo?.user_id;
        }
        return {
            ...mem,
            [calculation.sheet_id]: approverId,
        };
    }, {});
}
