import {
    constants,
} from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/constants/constants';
import { useEntriesByDay } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/entriesByDay';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { useUserDepartmentsList } from 'modules/employmentInfo/store/department/utils';
import { ICreateCustomFieldHierarchyNode } from 'modules/settings/submodules/components/HierarchyPage/store/models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { addCommonFields, CommonEntryShapeType, DefaultShapeType } from 'shared/models/validationSchemes/sheetCommon';
import { useTotalTimeEntriesByDay } from 'shared/models/validationSchemes/utils/totalTimeEntriesByDay';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { selectAllowTimeEntryWithoutEndTime } from 'store/entities/clients/selectors/configurationSelectors';
import { selectCurrentClientInputsConfiguration } from 'store/entities/clients/selectors/fieldSelectors';
import { selectIsJobNumberFieldsApplied } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { IActivity, IProjectWithAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectSubassignmentsByUserId } from 'store/entities/configuration/configurationSelectors';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import {
    selectActionableCustomFieldNodes,
    selectCustomFieldValuesByIds,
    selectOrderedCustomFieldIds,
} from 'store/entities/customFields/selectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { EntryValidation } from 'store/entities/timesheet/models/validation';
import { selectTimeEntries, selectTimeSheetsByIds } from 'store/entities/timesheet/selectors';
import { moment } from 'utils/momentExtensions';
import * as yup from 'yup';
import { ITimeInputValue } from '../../components/formFields/utils';
import { ITimeEntryFormValues } from '../../components/forms/entries/TimeEntryModel';
import { showField } from '../../components/forms/utils';
import {
    BACKEND_DATE_TIME_FORMAT_WITHOUT_TZ,
    getTimeUnitsFromTimeInOut,
    getMinutesByTimeUnits,
    getNormalizedDateTime,
} from '../DateTime';
import { IJobNumber, TimesheetSettings } from '../JobNumber';
import {
    IFileEntryData,
    IInOutBreakDataBackend,
    IInOutBreakEntryData,
    IInOutMealBreakEntryData,
    ITimeEntry,
    QuantityType,
} from '../sheet/Sheet';
import { ValidationMessages } from '../Validation';

export const checkNoIntersections = (
    value: IInOutBreakEntryData | IInOutMealBreakEntryData,
    day: string,
    timeEntriesByDay: Record<string, ITimeEntry[]>,
): boolean => {
    const intersectionEntryTypes = [
        QuantityType.TIME_IN_OUT_BREAK,
        QuantityType.TIME_IN_OUT_MEAL_BREAK,
    ];
    const dayEntries = timeEntriesByDay[day] || [];
    const entriesToCheck = dayEntries.map(entry => entry.data)
        .filter(data => intersectionEntryTypes.includes(data.entry_type)) as IInOutBreakDataBackend[];
    if (
        value
        && intersectionEntryTypes.includes(value.entry_type)
        && entriesToCheck.length > 0
    ) {
        const entryDay = moment(day);
        const prevDay = entryDay.subtract(1, 'days');
        const isToDstSwitch = !prevDay.isDST() && entryDay.isDST();
        const isFromDstSwitch = prevDay.isDST() && !entryDay.isDST();
        const additionalHours = isFromDstSwitch ? -1 : (isToDstSwitch ? 1 : 0);
        const newEntryRange = moment.range(
            entryDay.clone().add(moment.duration(value.timeIn)).add(additionalHours, 'hours'),
            entryDay.clone().add(moment.duration(value.timeOut)).add(additionalHours, 'hours'),
        );
        return !entriesToCheck.some(data => {
            const existEntryRange = moment.range(
                moment(data.time_in, BACKEND_DATE_TIME_FORMAT_WITHOUT_TZ),
                moment(data.time_out, BACKEND_DATE_TIME_FORMAT_WITHOUT_TZ),
            );

            return newEntryRange.intersect(existEntryRange);
        });
    }
    return true;
};

export const getTimeInTimeOutValidation = (
    schema: yup.ObjectSchema,
    emptyTimeOutAllowed = false,
    nullable = false,
    quantityTypes: Array<QuantityType> = [],
) => {
    const timeInOutTypes = quantityTypes ? quantityTypes : [QuantityType.TIME_IN_OUT, QuantityType.TIME_BREAK];
    let timeOutSchema = yup.string().nullable();
    if (!emptyTimeOutAllowed) {
        timeOutSchema = timeOutSchema.required(ValidationMessages.REQUIRED);
    }
    return schema
        .shape({
            entry_type: yup.string().required(ValidationMessages.REQUIRED)
                .matches(new RegExp(`(${timeInOutTypes.join('|')})`)),
            timeIn: yup.string().nullable().required(ValidationMessages.REQUIRED),
            timeOut: timeOutSchema,
        })
        .nullable(nullable)
        .required(ValidationMessages.REQUIRED);
};

const getTimeDefaultValidation = (schema: yup.ObjectSchema, totalMinutesByDay: Record<string, number> = {}) => (
    schema
        .shape({
            entry_type: yup.string().required(ValidationMessages.REQUIRED)
                .matches(new RegExp(`(${QuantityType.TIME})`)),
        })
        .nullable()
        .required(ValidationMessages.REQUIRED)
        .test({
            name: 'timeNotEmpty',
            test: (value: ITimeInputValue | null): boolean => {
                return !!value?.hours || !!value?.minutes;
            },
            message: EntryValidation.NotEmpty,
            exclusive: true,
        })
        .test({
            name: 'totalEntryTimesLessDay',
            params: { totalMinutesByDay },
            test: function (value: ITimeInputValue | null): boolean {
                if (!value) {
                    return true;
                }
                const { hours, minutes } = value;
                if (hours === undefined && minutes === undefined) {
                    return true;
                }
                const totalMinutes = totalMinutesByDay[this.parent.entry_date] || 0;
                return hours * constants.minutesInHour + minutes
                    <= constants.hoursInDay * constants.minutesInHour - totalMinutes;
            },
            message: EntryValidation.Exceed,
            exclusive: true,
        })
);

const getTimeInOutBreakValidation = (
    schema: yup.ObjectSchema,
    entriesByDays: Record<string, ITimeEntry[]> = {},
    emptyTimeOutAllowed = false,
) => (
    getTimeInTimeOutValidation(
        schema,
        emptyTimeOutAllowed,
        true,
        [QuantityType.TIME_IN_OUT_BREAK],
    )
        .test({
            name: 'BreakLessTime',
            test: function (value: IInOutBreakEntryData | null): boolean {
                if (!value) {
                    return true;
                }
                const { hoursBreak, minutesBreak, timeIn, timeOut } = value;
                if (timeOut === null || (hoursBreak === undefined && minutesBreak === undefined)) {
                    return true;
                }
                return getMinutesByTimeUnits({ hours: hoursBreak, minutes: minutesBreak })
                    < getMinutesByTimeUnits(getTimeUnitsFromTimeInOut(timeIn, timeOut));
            },
            message: ValidationMessages.BREAK_LESS_THAN_TIME_IN_OUT,
            exclusive: true,
        })
        .test({
            name: 'NoIntersections',
            test: function (value: IInOutBreakEntryData | null): boolean {
                if (!value) {
                    return true;
                }
                return checkNoIntersections(value, this.parent.entry_date, entriesByDays);
            },
            message: ValidationMessages.NO_INTERSECTIONS,
            exclusive: true,
        })
);

export const getTimeInOutMealBreakValidation = (
    schema: yup.ObjectSchema,
    entriesByDays: Record<string, ITimeEntry[]> = {},
    emptyTimeOutAllowed = false,
) => (
    getTimeInTimeOutValidation(
        schema,
        emptyTimeOutAllowed,
        true,
        [QuantityType.TIME_IN_OUT_MEAL_BREAK],
    )
        .test({
            name: 'BreakLessTime',
            test: function (value: IInOutMealBreakEntryData | null): boolean {
                const { timeIn = null, timeOut = null, breakTimeIn = null, breakTimeOut = null } = value ? value : {};
                if (!timeIn || !timeOut || !breakTimeIn || !breakTimeOut) {
                    return true;
                }
                return getMinutesByTimeUnits(getTimeUnitsFromTimeInOut(breakTimeIn, breakTimeOut))
                    < getMinutesByTimeUnits(getTimeUnitsFromTimeInOut(timeIn, timeOut));
            },
            message: ValidationMessages.BREAK_LESS_THAN_TIME_IN_OUT,
            exclusive: true,
        })
        .test(
            'Components Required',
            ValidationMessages.REQUIRED,
            // @ts-ignore
            function (value: IInOutMealBreakEntryData | null, context: yup.TestContext) {
                const { timeIn = null, timeOut = null, breakTimeIn = null, breakTimeOut = null } = value ? value : {};
                if (!timeIn || !timeOut) {
                    return true;
                }

                if (breakTimeIn && !breakTimeOut) {
                    return context.createError({
                        path: `${context.path}.breakTimeOut`,
                        message: ValidationMessages.REQUIRED,
                    });
                }

                if (breakTimeOut && !breakTimeIn) {
                    return context.createError({
                        path: `${context.path}.breakTimeIn`,
                        message: ValidationMessages.REQUIRED,
                    });
                }

                return context;
            },
        )
        .test(
            'BreakTimeBounds',
            ValidationMessages.BREAK_TIME_BOUNDS,
            function (value: IInOutMealBreakEntryData | null, context: yup.TestContext) {
                const { timeIn = null, timeOut = null, breakTimeIn = null, breakTimeOut = null } = value ? value : {};
                if (!timeIn || !timeOut || !breakTimeIn || !breakTimeOut) {
                    return true;
                }

                const start = getNormalizedDateTime(timeIn);
                const end = getNormalizedDateTime(timeOut, timeIn);

                if (!getNormalizedDateTime(breakTimeIn, timeIn).isBetween(start, end, 'm', '[]')) {
                    return context.createError({
                        path: `${context.path}.breakTimeIn`,
                        message: ValidationMessages.BREAK_TIME_BOUNDS,
                    });
                }
                if (!getNormalizedDateTime(breakTimeOut, timeIn).isBetween(start, end, 'm', '[]')) {
                    return context.createError({
                        path: `${context.path}.breakTimeOut`,
                        message: ValidationMessages.BREAK_TIME_BOUNDS,
                    });
                }

                return context;
            },
        )
        .test({
            name: 'NoIntersections',
            test: function (value: IInOutMealBreakEntryData | null): boolean {
                if (!value) {
                    return true;
                }
                return checkNoIntersections(value, this.parent.entry_date, entriesByDays);
            },
            message: ValidationMessages.NO_INTERSECTIONS,
            exclusive: true,
        })
);

const getPerFilesValidation = (schema: yup.ObjectSchema) => (
    schema
        .shape({
            entry_type: yup.string().required(ValidationMessages.REQUIRED)
                .matches(new RegExp(`${QuantityType.FILE}`)),
        })
        .nullable()
        .required(ValidationMessages.REQUIRED)
        .test({
            name: 'FileNotEmpty',
            test: (value: IFileEntryData | null): boolean => {
                return !!value?.files;
            },
            message: EntryValidation.NotEmpty,
            exclusive: true,
        })
);

type TimeEntryShapeType = CommonEntryShapeType & Partial<Record<keyof ITimeEntryFormValues, DefaultShapeType>>

function getTimeDataValidation(
    schema: yup.ObjectSchema,
    activity: IActivity | undefined,
    totalMinutesByDay: Record<string, number> = {},
    entriesByDays: Record<string, ITimeEntry[]> = {},
    emptyTimeOutAllowed = false,
) {
    switch (activity?.data_type) {
        case QuantityType.TIME_IN_OUT:
        case QuantityType.TIME_BREAK:
            return getTimeInTimeOutValidation(schema, emptyTimeOutAllowed);
        case QuantityType.TIME_IN_OUT_BREAK:
            return getTimeInOutBreakValidation(schema, entriesByDays, emptyTimeOutAllowed);
        case QuantityType.TIME_IN_OUT_MEAL_BREAK:
            return getTimeInOutMealBreakValidation(schema, entriesByDays, emptyTimeOutAllowed);
        default:
            return getTimeDefaultValidation(schema, totalMinutesByDay);
    }
}

export function createTimeEntryValidationSchema(
    fields: InputFields,
    totalMinutesByDay: Record<string, number> = {},
    departments: IDepartment[] = [],
    entriesByDays: Record<string, ITimeEntry[]> = {},
    useCustomFields = false,
    customFieldsIds: string[] = [],
    userSubassignments: ISubassignment[] = [],
    customFieldValuesByIds: Record<string, ICustomFieldValue> = {},
    actionableCustomFields: ICreateCustomFieldHierarchyNode[] = [],
    emptyTimeOutAllowed = false,
) {
    const shape: TimeEntryShapeType = addCommonFields(
        {},
        fields,
        departments,
        useCustomFields,
        customFieldsIds,
        userSubassignments,
        customFieldValuesByIds,
        actionableCustomFields,
    );

    if (showField(fields, EntrySlug.JobNumber)) {
        shape.data = yup.object()
            .nullable()
            .required(ValidationMessages.REQUIRED)
            .when(
                ['jobNumber', 'activity'],
                (
                    jobNumber: IJobNumber | undefined,
                    activity: IActivity | undefined,
                    schema: yup.ObjectSchema,
                ) => {
                    if (jobNumber?.timesheet_setting === TimesheetSettings.PerFile) {
                        return getPerFilesValidation(schema);
                    }
                    return getTimeDataValidation(
                        schema, activity, totalMinutesByDay, entriesByDays, emptyTimeOutAllowed,
                    );
                });
    } else {
        shape.data = yup.object()
            .nullable()
            .required(ValidationMessages.REQUIRED)
            .when(
                'activity',
                (activity: IActivity | undefined, schema: yup.ObjectSchema) => {
                    return getTimeDataValidation(
                        schema, activity, totalMinutesByDay, entriesByDays, emptyTimeOutAllowed,
                    );
                },
            );
    }

    shape.scaZone = yup.object().nullable().when(
        ['projectAssignment'],
        {
            is: (projectAssignment: IProjectWithAssignment | null) => projectAssignment?.sca_zone_id,
            then: yup.object().required('Required'),
        },
    );

    return yup.object().shape(shape);
}

export function useTimeEntryValidationSchema(
    statusName?: StatusNames,
    userId?: string,
    ignoreEntryId?: string,
) {
    const hasJobNumberField = useSelector(selectIsJobNumberFieldsApplied);
    const timeEntries = useSelector(selectTimeEntries);
    const timeSheetsById = useSelector(selectTimeSheetsByIds);
    const filteredTimeEntries = useMemo(() => {
        return timeEntries.filter(entry => entry.id !== ignoreEntryId
            && (timeSheetsById[entry.sheet_id]?.user_id === userId || !userId));
    }, [timeEntries, ignoreEntryId, timeSheetsById, userId]);
    const totalMinutesByDay = useTotalTimeEntriesByDay(filteredTimeEntries);
    const timeEntriesByDays = useEntriesByDay(filteredTimeEntries) as Record<string, ITimeEntry[]>;
    const inputsConfiguration = useSelector(selectCurrentClientInputsConfiguration);
    const departments = useUserDepartmentsList(userId);
    const customFieldsIds = useSelector(selectOrderedCustomFieldIds);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const userSubassignments = useSelector(selectSubassignmentsByUserId(userId));
    const actionableCustomFields = useSelector(selectActionableCustomFieldNodes);
    const emptyTimeOutAllowed = (
        useSelector(selectAllowTimeEntryWithoutEndTime)
        && statusName !== StatusNames.APPROVED
    );

    return useMemo(
        () => createTimeEntryValidationSchema(
            inputsConfiguration?.time || {} as InputFields,
            totalMinutesByDay || {},
            departments,
            timeEntriesByDays,
            !hasJobNumberField,
            customFieldsIds,
            userSubassignments,
            customFieldValuesByIds,
            actionableCustomFields,
            emptyTimeOutAllowed,
        ), [
            totalMinutesByDay,
            inputsConfiguration,
            departments,
            timeEntriesByDays,
            customFieldsIds,
            userSubassignments,
            customFieldValuesByIds,
            hasJobNumberField,
            actionableCustomFields,
            emptyTimeOutAllowed,
        ],
    );
}
