import {
    resolvePto,
    resolvePtoMaxUseAvailable,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/detailItemValueResolvers';
import { SheetInfoItemTitle } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/model/SheetInfoItemTitle';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IPaidTimeOffResponse } from 'shared/models/User';

/**
 * Checks if PTO can be added and if so - pushes to the list
 */
export const tryAddPtoItem = (
    pto: IPaidTimeOffResponse | null,
    type: EntryType,
    sheetDetailsList: ISheetInfoItemData[],
) => {
    if (pto && type === EntryType.TIME) {
        sheetDetailsList.push({
            title: SheetInfoItemTitle.AvailablePtoHours,
            value: resolvePto(pto),
        });
    }
};

export const tryAddPtoItemBasedMaxUseAvailable = (
    pto: IPaidTimeOffResponse | null,
    type: EntryType,
    sheetDetailsList: ISheetInfoItemData[],
) => {
    if (pto && type === EntryType.TIME) {
        sheetDetailsList.push({
            title: SheetInfoItemTitle.AvailablePtoHours,
            value: resolvePtoMaxUseAvailable(pto),
        });
    }
};
