import { getStatusByPriority } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/useGetDisplaySheetStatus';
import { createSelector } from 'reselect';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { filterEntryByKeys } from 'shared/utils/helpers/workingSheetsFilters';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { selectWorkingSheetsFilters } from 'store/components/workingSheets/workingSheetsSelectors';
import { IStore } from 'store/configureStore';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllEntriesWithSheet, selectExpensesSheets, selectPayPeriodsIsLoading, selectTimeSheets, selectIsSheetStatusUpdating } from 'store/entities/timesheet/selectors';
import { isDateInPayPeriod } from 'shared/models/Dates';

export const selectWorkingPageState = (store: IStore) => store.modules.timeAndExpense.workingPage;
export const selectCurrentPayPeriod = (store: IStore) => selectWorkingPageState(store).currentPayPeriod;
export const selectWorkingEntryTypeFilter = (store: IStore) => selectWorkingPageState(store).entryTypeFilter;
export const selectWorkingSheetsLoadingByPayPeriods = (store: IStore) =>
    selectWorkingPageState(store).isSheetsLoadingByPayPeriods;

/**
 * Select entries for current pay period
 */
export const selectPayPeriodWorkingEntries = createSelector(
    selectCurrentPayPeriod,
    selectAllEntriesWithSheet,
    selectCurrentUser,
    (payPeriod, entries, user) => {
        if (!payPeriod) {
            return [];
        }
        return entries.filter(entry => {
            return isDateInPayPeriod(payPeriod, entry.entry_date)
                && entry.sheet?.status?.name === StatusNames.WORKING
                && entry?.sheet?.user_id === user?.id;
        });
    },
);

/**
 * Select filtered entries
 */
export const selectWorkingFilteredEntries = createSelector(
    selectPayPeriodWorkingEntries,
    selectWorkingEntryTypeFilter,
    selectWorkingSheetsFilters,
    (entries, entryTypeFilter, filters) => {
        return entries.filter(entry => {
            if (entryTypeFilter && entry.entry_type !== entryTypeFilter) {
                return false;
            }

            if (!filterEntryByKeys(
                filters, entry, ['assignment_id', 'activity_id', 'task_id', 'location_id', 'position_id', 'job_number_id'])
            ) {
                return false;
            }

            const filterCustomFieldValues = Object.values(filters.customFieldValues);
            const notEmptyFilterCustomFieldValues = filterCustomFieldValues.filter(Boolean);
            if (notEmptyFilterCustomFieldValues.length > 0) {
                if (notEmptyFilterCustomFieldValues.some(filterValue => {
                    const entryCustomFieldValueIds = entry.custom_field_value_ids || [];
                    // @ts-ignore
                    return !entryCustomFieldValueIds.includes(filterValue);
                })) {
                    return false;
                }
            }

            // @ts-ignore
            if (filters.area_id && entry.sheet.area_id.toString() !== filters.area_id) {
                return false;
            }

            return true;
        });
    },
);

/**
 * Select timesheet ids for current pay period
 */
export const selectPayPeriodTimeSheetsIds = createSelector(
    selectPayPeriodWorkingEntries,
    entries => entries.reduce((mem, entry) => {
        if (entry.entry_type === EntryType.TIME) {
            mem.add(entry.sheet_id);
        }
        return mem;
    }, new Set<string>()),
);

/**
 * Limit areas
 */
export interface IAreaIdsByType {
    [EntryType.EXPENSE]: Array<number>;
    [EntryType.TIME]: Array<number>;
}

function getRecalledAreaIds(sheets: ISheet[], payPeriod?: IPayPeriod) {
    return sheets.filter(sheet => sheet.period_end === payPeriod?.period_end
        && sheet.status?.name === StatusNames.RECALLED).map(sheet => sheet.area_id);
}

export const selectLimitWorkingSheets = createSelector(
    selectTimeSheets,
    selectExpensesSheets,
    selectCurrentPayPeriod,
    (timeSheets, expenseSheets, payPeriod): IAreaIdsByType => ({
        // @ts-ignore
        [EntryType.TIME]: getRecalledAreaIds(timeSheets, payPeriod),
        // @ts-ignore
        [EntryType.EXPENSE]: getRecalledAreaIds(expenseSheets, payPeriod),
    }),
);

/**
 * Select sheet statuses for this pay period
 */

const getPayPeriodSheetStatuses = (sheets: ISheet[], payPeriod?: IPayPeriod) => sheets
    .filter(sheet => sheet.period_end === payPeriod?.period_end)
    .map(sheet => sheet?.status?.name);

export const selectPayPeriodSheetStatusesByType = createSelector(
    selectTimeSheets,
    selectExpensesSheets,
    selectCurrentPayPeriod,
    (timeSheets, expenseSheets, payPeriod): Record<EntryType, StatusNames[]> => ({
        [EntryType.TIME]: getPayPeriodSheetStatuses(timeSheets, payPeriod),
        [EntryType.EXPENSE]: getPayPeriodSheetStatuses(expenseSheets, payPeriod),
    }),
);

/**
 * Custom empty text for working page
 */
export const selectWorkingEmptyText = createSelector(
    selectPayPeriodSheetStatusesByType,
    payPeriodSheetStatusesByType => {
        const filteredTimeStatus = getStatusByPriority(payPeriodSheetStatusesByType[EntryType.TIME]);
        const filteredExpenseStatus = getStatusByPriority(payPeriodSheetStatusesByType[EntryType.EXPENSE]);
        //TODO: properly handle possible case when several sheets available for this pay period.
        if (filteredTimeStatus === filteredExpenseStatus && filteredTimeStatus !== null) { // both not null and the same
            return `Sheets of this week are ${filteredTimeStatus.toLowerCase()}`;
        }

        if (filteredTimeStatus && filteredExpenseStatus) { // both not null but different
            return `Time sheet of this week is ${filteredTimeStatus.toLowerCase()}. Expense sheet is ${filteredExpenseStatus.toLowerCase()}.`;
        }

        return '';
    },
);

/**
 * Loading state by pay periods
 */
export const selectIsPayPeriodSheetsLoading = createSelector(
    selectCurrentPayPeriod,
    selectPayPeriodsIsLoading,
    selectWorkingSheetsLoadingByPayPeriods,
    selectIsSheetStatusUpdating,
    (currentPayPeriod, payPeriodIsLoading, loadingByPayPeriod, isStatusUpdating) => {
        return payPeriodIsLoading || (loadingByPayPeriod[currentPayPeriod?.period_end] !== false) || isStatusUpdating;
    },
);
