import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { combineReducers } from 'redux';
import {
    assignUserToJobNumber,
} from 'modules/settings/submodules/clients/jobNumber/store/actions';
import {
    searchJobNumbersEmployees,
    setAssignUserModalJobNumberId,
} from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/actions';

const isUpdating = isLoadingReducer(assignUserToJobNumber);
const assignToJobNumberId = singleValueReducer(setAssignUserModalJobNumberId.action, null);
const userSearchResult = singleValueReducer(searchJobNumbersEmployees.successType, []);
const isSearching = isLoadingReducer(searchJobNumbersEmployees);

export const jobNumberAssignUser = combineReducers({
    isUpdating,
    assignToJobNumberId,
    userSearchResult,
    isSearching,
});
