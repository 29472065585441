import { IDepartment } from 'modules/employmentInfo/models/Department';
import { useUserDepartmentsList } from 'modules/employmentInfo/store/department/utils';
import { ICreateCustomFieldHierarchyNode } from 'modules/settings/submodules/components/HierarchyPage/store/models';
import { useMemo } from 'react';
import { selectCurrentClientInputsConfiguration } from 'store/entities/clients/selectors/fieldSelectors';
import { selectIsJobNumberFieldsApplied } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { selectOrderedCustomFieldIds, selectCustomFieldValuesByIds, selectActionableCustomFieldNodes } from 'store/entities/customFields/selectors';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { IActivity, ISubassignment } from 'store/entities/configuration/configurationModel';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { IAmountEntryData, IMilesEntryData, IOdometerEntryData, QuantityType } from '../sheet/Sheet';
import { ValidationMessages } from '../Validation';
import { addCommonFields, CommonEntryShapeType, DefaultShapeType } from 'shared/models/validationSchemes/sheetCommon';
import { IExpenseEntryFormValues } from '../../components/forms/entries/ExpenseEntryModel';
import { showField } from '../../components/forms/utils';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { selectSubassignmentsByUserId } from 'store/entities/configuration/configurationSelectors';

export const maxDollarsExpenseValue = 10000;
export const maxMilesValue = 10000;

type ExpenseEntryShapeType = CommonEntryShapeType & Partial<Record<keyof IExpenseEntryFormValues, DefaultShapeType>>

export function generateExpenseSchema(
    fields: InputFields,
    departments: IDepartment[] = [],
    applyCustomFieldValidation = false,
    customFieldsIds: string[] = [],
    userSubassignments: ISubassignment[] = [],
    customFieldValuesByIds: Record<string, ICustomFieldValue> = {},
    actionableCustomFields: ICreateCustomFieldHierarchyNode[] = [],
) {
    const shape: ExpenseEntryShapeType = addCommonFields(
        {},
        fields,
        departments,
        applyCustomFieldValidation,
        customFieldsIds,
        userSubassignments,
        customFieldValuesByIds,
        actionableCustomFields,
    );
    shape.data = yup.object()
        .nullable()
        .required(ValidationMessages.REQUIRED)
        .when(
            'activity',
            (activity: IActivity | undefined, schema: yup.ObjectSchema) => {
                switch (activity?.data_type) {
                    case QuantityType.ODOMETER:
                        return schema
                            .shape({
                                miles_start: yup.number()
                                    .min(0, ValidationMessages.REQUIRED_MILES)
                                    .required(ValidationMessages.REQUIRED),
                                miles_end: yup.number()
                                    .min(0, ValidationMessages.REQUIRED_MILES)
                                    .required(ValidationMessages.REQUIRED)
                                    .test({
                                        name: 'startOdoShouldBeLessThanEndOdo',
                                        test: function (value: IOdometerEntryData | null): boolean {
                                            return value ? this.parent.miles_start < value : false;
                                        },
                                        message: ValidationMessages.ODOMETER_INVALID,
                                        exclusive: true,
                                    }),
                            });
                    case QuantityType.MILES:
                        return schema
                            .test({
                                name: 'milesRequired',
                                test: (value: IMilesEntryData | null): boolean => {
                                    return value?.miles ? value?.miles > 0 : false;
                                },
                                message: ValidationMessages.REQUIRED_MILES,
                                exclusive: true,
                            })
                            .test({
                                name: 'milesLessMax',
                                test: (value: IMilesEntryData | null): boolean => {
                                    return value?.miles ? value?.miles <= maxMilesValue : true;
                                },
                                message: ValidationMessages.OVERFLOW_MILES,
                                exclusive: true,
                            });
                    default:
                        return schema
                            .test({
                                name: 'dollarsRequired',
                                test: (value: IAmountEntryData | null): boolean => {
                                    return value?.dollars ? parseFloat(value?.dollars) > 0 : false;
                                },
                                message: ValidationMessages.REQUIRED_EXPENSE,
                                exclusive: true,
                            })
                            .test({
                                name: 'dollarsLessMax',
                                test: (value: IAmountEntryData | null): boolean => {
                                    return value?.dollars
                                        ? parseFloat(value?.dollars) <= maxDollarsExpenseValue : true;
                                },
                                message: ValidationMessages.OVERFLOW_EXPENSE,
                                exclusive: true,
                            });
                }
            },
        );

    if (showField(fields, EntrySlug.ZipCode)) {
        shape.zipCode = yup.object()
            .nullable()
            .when('activity.show_zip_code', {
                is: true,
                then: yup.object().required(ValidationMessages.REQUIRED),
                otherwise: yup.object(),
            });
    }

    return yup.object().shape(shape);
}

export function useExpenseSchema(userId?: string) {
    const hasJobNumber = useSelector(selectIsJobNumberFieldsApplied);
    const inputsConfiguration = useSelector(selectCurrentClientInputsConfiguration);
    const departments = useUserDepartmentsList(userId);
    const customFieldsIds = useSelector(selectOrderedCustomFieldIds);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const userSubassignments = useSelector(selectSubassignmentsByUserId(userId));
    const actionableCustomFields = useSelector(selectActionableCustomFieldNodes);

    return useMemo(
        () => generateExpenseSchema(
            inputsConfiguration?.expense || {} as InputFields,
            departments,
            !hasJobNumber,
            customFieldsIds,
            userSubassignments,
            customFieldValuesByIds,
            actionableCustomFields,
        ), [
            inputsConfiguration,
            departments,
            customFieldsIds,
            customFieldValuesByIds,
            userSubassignments,
            hasJobNumber,
            actionableCustomFields,
        ],
    );
}
