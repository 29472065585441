import { IDashboardMenuItem } from 'modules/settings/submodules/components/DashboardLink/model';
import { settingsRoutes } from '../../../routes';
import { Permission } from 'store/components/auth/authModels';

export const useGlobalAssignmentSetupSubModules = (): IDashboardMenuItem[] => {
    return [
        {
            title: 'Default Hierarchy',
            description: 'Manage Default Hierarchy.',
            link: settingsRoutes.ASSIGNMENT_SETUP.DEFAULT_HIERARCHY,
            permission: Permission.ManageCustomFields,
        },
        {
            title: 'Custom Fields',
            description: 'Manage and add custom fields.',
            link: settingsRoutes.ASSIGNMENT_SETUP.CUSTOM_FIELDS,
            permission: Permission.ManageCustomFields,
        },
    ];
};
