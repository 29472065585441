import React, { Component } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent, Theme,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import AuthWrapper from 'shared/components/auth/AuthWrapper';
import { logger } from 'shared/utils/logging/logger';

const styles = (theme: Theme) => ({
    cardActions: {
        padding: 0,
        margin: theme.spacing(3, 0, -1),
    },
});

interface IErrorBoundaryProps extends WithStyles<typeof styles> {
    children: React.ReactElement;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, info: any) {
        logger.critical(error, info);
    }

    render() {
        const { classes } = this.props;

        if (this.state.hasError) {
            return (
                <AuthWrapper>
                    <Card>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="subtitle1"
                            >
                                Something went wrong...
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                gutterBottom
                                component="div"
                            >
                                We are working on fixing the problem. Please, try again later.
                            </Typography>
                            <CardActions disableSpacing className={classes.cardActions}>
                                <Button
                                    size="small"
                                    color="primary"
                                    href="/"
                                >
                                    Go to Home page
                                </Button>
                            </CardActions>
                        </CardContent>
                    </Card>
                </AuthWrapper>
            );
        }

        return <>{this.props.children}</>;
    }
}

export default withStyles(styles)(ErrorBoundary);
