import React from 'react';
import { useSelector } from 'react-redux';
import { ITaskProps, TaskPure } from 'shared/components/selects/Task';
import { selectTasks } from 'store/entities/configuration/configurationSelectors';

export function TaskSelect(props: ITaskProps) {
    const tasks = useSelector(selectTasks);
    return (
        <>
            {tasks.length > 1 && (
                <TaskPure
                    // @ts-ignore
                    tasks={tasks}
                    {...props}
                />
            )}
        </>
    );
}
