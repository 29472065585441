import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IEntity } from 'shared/models/Entity';
import { IStore } from 'store/configureStore';
import { ActionCreatorKnownArgs } from 'store/utils';

export type CheckedItems = Record<string, boolean>;
export type OnCheck = (checked: boolean, id: string) => void;

export type CheckBoxCellActions = {
    onCheck: OnCheck;
    checkedAll: boolean;
    onCheckAll: (checked: boolean) => void;
    setCheckedItems: (checked: CheckedItems) => void;
    checkedItems: CheckedItems;
}

export function useCheckedItemsWithoutState<ItemType extends IEntity>(
    items: Array<ItemType>,
    checkedItems: CheckedItems,
    setCheckedItems: (items: CheckedItems) => void,
): CheckBoxCellActions {
    const checkedAll = useMemo(
        () => items.length > 0 && items.every((item: ItemType) => checkedItems[item.id]),
        [items, checkedItems],
    );

    const onCheck: OnCheck = useCallback(
        (checked: boolean, sheetId: string) => {
            setCheckedItems({
                ...checkedItems,
                [sheetId]: checked,
            });
        },
        [setCheckedItems, checkedItems],
    );

    const onCheckAll = useCallback((checked: boolean) => {
        const checkedAllItems: CheckedItems = {};
        items.forEach(item => {
            checkedAllItems[item.id] = checked;
        });

        setCheckedItems(checkedAllItems);
    }, [items, setCheckedItems]);

    useEffect(() => {
        const itemsIds = items.map(item => item.id);
        const results = Object.entries(checkedItems).reduce((acc, [itemId, checked]) => {
            return itemsIds.includes(itemId) ? {
                ...acc,
                [itemId]: checked,
            } : acc;
        }, {});

        if (Object.keys(results).length !== Object.keys(checkedItems).length) {
            setCheckedItems(results);
        }
    }, [items, checkedItems, setCheckedItems]);

    return useMemo(() => {
        return {
            checkedItems,
            onCheck,
            checkedAll,
            onCheckAll,
            setCheckedItems,
        };
    }, [checkedAll, checkedItems, onCheck, onCheckAll, setCheckedItems]);
}

export function useCheckedItems<ItemType extends IEntity>(items: Array<ItemType>): CheckBoxCellActions {
    const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
    return useCheckedItemsWithoutState(items, checkedItems, setCheckedItems);
}

export function useCheckedItemsStore<ItemType extends IEntity>(
    items: Array<ItemType>,
    selector: (store: IStore) => CheckedItems,
    action: ActionCreatorKnownArgs<CheckedItems, { type: any; payload: CheckedItems }>,
): CheckBoxCellActions {
    const checkedItems = useSelector(selector);
    const dispatch = useDispatch();
    const setCheckedItems = useCallback((newCheckedValues: CheckedItems) => {
        dispatch(action(newCheckedValues));
    }, [dispatch, action]);
    return useCheckedItemsWithoutState(items, checkedItems, setCheckedItems);
}
