import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { selectOfferLetterRows } from 'modules/offerLetter/components/OfferLetterTable/selectors';
import { setCheckedOfferLetterRow } from 'modules/offerLetter/store/actions';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { selectCheckedOfferLetterRowsState } from 'modules/offerLetter/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { CheckBoxCellPure, CheckBoxTitlePure, ICheckBoxTitlePureProps } from 'shared/components/table/Cells/checkBoxHelper';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { useCheckedItemsStore } from 'shared/utils/hooks/useCheckedItems';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { IOfferLetterRow } from './cells';
import { OfferLetterCellKey } from './OfferLetterCellKey';

const CheckboxOfferHeaderCell = separateLogicDecorator<{}, ICheckBoxTitlePureProps>(
    () => {
        const tableClasses = useDefaultTableStyles();
        const rows = useSelector(selectOfferLetterRows);
        const checkedItemsActions = useCheckedItemsStore<IOfferLetterRow>(
            rows.filter(row => row.offerLetter.status.slug !== OfferLetterStatusSlug.RejectedPayRate),
            selectCheckedOfferLetterRowsState,
            setCheckedOfferLetterRow,
        );
        return {
            classes: tableClasses,
            checkedAll: checkedItemsActions.checkedAll,
            onCheckAll: checkedItemsActions.onCheckAll,
        };
    },
)(CheckBoxTitlePure);

export const checkBoxCellInfo: ICellInfo<IOfferLetterRow> = {
    key: OfferLetterCellKey.CheckBoxPayRangeApproval,
    title: '',
    width: '55px',
    render: function CheckBoxCell(row: IOfferLetterRow) {
        const tableClasses = useDefaultTableStyles();
        const rows = useSelector(selectOfferLetterRows);
        const checkedItemsActions = useCheckedItemsStore<IOfferLetterRow>(
            rows.filter(offerRow => offerRow.offerLetter.status.slug !== OfferLetterStatusSlug.RejectedPayRate),
            selectCheckedOfferLetterRowsState,
            setCheckedOfferLetterRow,
        );

        if (row.offerLetter.status.slug === OfferLetterStatusSlug.RejectedPayRate) {
            return (
                <Box className={row.className}/>
            );
        }
        return (
            <CheckBoxCellPure
                rowId={row.offerLetter.id}
                checkedItems={checkedItemsActions.checkedItems}
                onCheck={checkedItemsActions.onCheck}
                rowClassName={row.className}
                classes={tableClasses}
            />
        );
    },
    renderTitle: function CheckBoxHeaderCell() {
        const classes = useDefaultTableStyles();
        return (
            <Box
                className={clsx(classes.iconCell, classes.checkbox)}
                mb={-1}
                mt={-1}
            >
                <CheckboxOfferHeaderCell/>
            </Box>
        );
    },
};

export const payRangeCellInfo: ICellInfo<IOfferLetterRow> = {
    key: OfferLetterCellKey.PayRange,
    title: 'Pay range',
    width: '120px',
    render: function PayRangeCell(row: IOfferLetterRow) {
        const payRange = row.payRange;
        if (!payRange) {
            return (
                <Box className={row.className}>
                    <Skeleton width="90px" height="14px"/>
                </Box>
            );
        }

        const [min, max] = payRange;
        const value = `${formatDollars(min)} – ${formatDollars(max)}`;

        return (
            <PlainText
                className={row.className}
                value={value}
            />
        );
    },
};
