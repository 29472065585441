import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { decreaseSyncing, increaseSyncing, setGlobalToast } from 'store/entities/appConfig/actions';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import {
    getClientUserRoles,
    getPaidTimeOff,
    getSpecialUsersAction,
    getUserAuthStats,
    getUsers,
    loadClientRoles,
    loadGlobalRoles,
    lookupUsers,
    searchUsers,
    UsersActions,
} from 'store/entities/users/actions';
import { usersApi } from 'store/entities/users/api';
import { UserType } from 'store/entities/users/model';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeEvery, takeLatest } from 'typed-redux-saga';

const getUsersWatcher = getLoadEntitiesByRequestSagaWatcher(
    getUsers,
    usersApi.getUsersWithoutPagination,
    'users',
    true,
);

function* getSpecialUsersSaga(action: ReturnType<typeof getSpecialUsersAction.init>) {
    const request = {
        ...action.payload,
        user_type: UserType.Manager,
    };
    const getUsersResponse = yield* call(usersApi.getUsers, request);
    yield put(getSpecialUsersAction.success({
        request,
        result: getUsersResponse.users,
    }));
}

export function* getSpecialUsersWatcher() {
    yield takeEvery(getSpecialUsersAction.initType, withBackendErrorHandler(getSpecialUsersSaga, getSpecialUsersAction.error, 'Unable to load users'));
}

function* searchUsersSaga(action: ReturnType<typeof searchUsers.init>) {
    const { searchKey, request } = action.payload;
    try {
        const getUsersResponse = yield* call(usersApi.getUsers, request);

        const userIds = getUsersResponse.users.map(user => user.id);
        yield put(getUsers.success(getUsersResponse.users));
        yield put(searchUsers.success({
            searchKey,
            userIds,
        }));
    } catch (error) {
        yield put(searchUsers.error({
            searchKey,
            error,
        }));
        const message = 'Unable to find users';
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: message,
            autoHideDuration: autoHideDefaultDuration * 2,
        }));
        logErrorWithCustomMessage(error, message);
    }
}

export function* searchUsersWatcher() {
    yield takeLatest(searchUsers.initType, searchUsersSaga);
}

function* getGlobalRolesSaga() {
    const result = yield* call(usersApi.getGlobalRoles);
    yield put(loadGlobalRoles.success(result));
}

export function* getGlobalRolesWatcher() {
    yield takeLatest(loadGlobalRoles.initType, withBackendErrorHandler(getGlobalRolesSaga, loadGlobalRoles.error, 'Unable to load global roles'));
}

function* getClientRolesSaga({ payload }: ReturnType<typeof loadClientRoles.init>) {
    const result = yield* call(usersApi.getClientRoles, payload || {});
    yield put(loadClientRoles.success(result));
}

export function* getClientRolesWatcher() {
    yield takeLatest(loadClientRoles.initType, withBackendErrorHandler(getClientRolesSaga, loadClientRoles.error, 'Unable to load clients roles'));
}

function* getPaidTimeOffSaga({ payload }: ReturnType<typeof getPaidTimeOff.init>) {
    try {
        const paidTimeOffData = yield* call(usersApi.getPaidTimeOff, payload);
        yield put(getPaidTimeOff.success({
            ...payload,
            paidTimeOffData,
        }));
    } catch (error) {
        if (error.response?.status === 404) {
            // Skip toast for 404 status
            yield put(getPaidTimeOff.error(error));
        } else {
            throw error;
        }
    }
}

export function* getPaidTimeOffSagaWatcher() {
    yield* takeLatest(
        getPaidTimeOff.initType,
        withBackendErrorHandler(
            getPaidTimeOffSaga,
            getPaidTimeOff.error,
            'Unable to get PTO.',
        ),
    );
}

function* usersSyncingSaga(action: UsersActions) {
    if (action.type.endsWith('SUCCESS') || action.type.endsWith('ERROR')) {
        yield put(decreaseSyncing(SyncingModels.Users));
    } else {
        yield put(increaseSyncing(SyncingModels.Users));
    }
}

export function* usersSyncingSagaWatcher() {
    yield* takeEvery([
        ...getSpecialUsersAction.allTypes,
        ...lookupUsers.allTypes,
        ...getUsers.allTypes,
    ], usersSyncingSaga);
}

const getClientUserRolesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getClientUserRoles,
    usersApi.getClientUserRoles,
    'user roles',
);

const getUserAuthStatsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getUserAuthStats,
    usersApi.getUserAuthStats,
    'user authentication details',
);

export default [
    getSpecialUsersWatcher,
    searchUsersWatcher,
    getGlobalRolesWatcher,
    getClientRolesWatcher,
    getUsersWatcher,
    getPaidTimeOffSagaWatcher,
    usersSyncingSagaWatcher,
    getClientUserRolesWatcher,
    getUserAuthStatsWatcher,
];
