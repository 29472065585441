import React from 'react';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { Button, Typography, Box } from '@material-ui/core';

interface ISubassignmentSelectionWarningProps {
    open: boolean;
    onClose: () => void;
    countMultipleSubassignments: number;
}

export const SubassignmentSelectionWarning = ({
    countMultipleSubassignments,
    open,
    onClose,
}: ISubassignmentSelectionWarningProps) => {
    const modalClasses = useColoredFormModalStyles();
    return (
        <ModalDialog
            title={`${countMultipleSubassignments} ${pluralize('Employee', countMultipleSubassignments)} selected has more than one sub-assignment`}
            isOpened={open}
            onClose={onClose}
            modalProps={{
                showCloseIcon: true,
                customClasses: modalClasses,
            }}
        >
            <Box>
                <Typography>
                    The employee with more than one sub-assignments is highlighted in green.
                </Typography>
                <Typography>
                    Please specify with sub-assignments you want to edit for this employee
                    and then click {'"'}Bulk Edit{'"'} again.
                </Typography>
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        </ModalDialog>
    );
};
