export const ALL_STATUS_SLUG = 'all';

export enum EcubStatus {
    Pending = 'pending',
    Sent = 'sent',
}

export type EcubStatusSlug = EcubStatus | typeof ALL_STATUS_SLUG;

export const EcubStatusDisplayName: Record<EcubStatusSlug, string> = {
    [EcubStatus.Pending]: 'Pending',
    [EcubStatus.Sent]: 'Sent',
    [ALL_STATUS_SLUG]: 'All',
};

export interface IEcubItem {
    name: string;
    timestamp: string;
    is_sent: boolean;
    is_paused: boolean;
    able_to_send_automatically: boolean;
}

export interface IEcubItemsRequest {
    status?: EcubStatus;
}

export interface IEcubItemsResponse {
    items: IEcubItem[];
    total_items: number;
}

export interface IEcubPayload {
    filename: string;
}

export interface IEcubPausePayload extends IEcubPayload {
    is_paused: boolean;
}
