import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getPrismSSOLink } from 'modules/offerLetter/store/actions';
import {
    selectIsSSOLikLoading,
    selectSSOLink,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { navigateIframedHost } from '../offerLetter/helpers/navigateIframedHost';
import { routes } from '../../shared/routes';

export const EmployeePortal = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const [iframeLoading, setIframeLoading] = useState(true);
    const [cookiesEnabled, setCookiesEnabled] = useState(true);
    const isSSOLinkLoading = useSelector(selectIsSSOLikLoading);
    const isLoading = iframeLoading || isSSOLinkLoading;
    const link = useSelector(selectSSOLink);
    useTitleUpdate('Employee Portal');

    useEffect(() => {
        dispatch(getPrismSSOLink.init(clientId || ''));
    }, [dispatch, clientId]);

    useEffect(() => {
        setCookiesEnabled(true);
    }, [setCookiesEnabled]);

    const onLoad = (evt: React.SyntheticEvent) => {
        navigateIframedHost(routes.HOME)(evt);
        setIframeLoading(false);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
        >
            {cookiesEnabled ? (
                <>
                    {isLoading && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            height="100%"
                            flexGrow={1}
                        >
                            <CircularProgress/>
                        </Box>
                    )}
                    {link && (
                        <iframe
                            src={link}
                            title="Employee Portal"
                            frameBorder="0"
                            height={isLoading ? 0 : '100%'}
                            onLoad={onLoad}
                        />
                    )}
                </>
            ) : (
                <Box m={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Alert severity="error">
                        Cookies are required for view Employee Portal. <br/>
                        Please enable the third party cookie in your browser and reload the page.
                    </Alert>
                </Box>
            )}
        </Box>
    );
};
