import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useTotalStyles = makeHighPriorityStyles(() => ({
    value: {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: 'normal',
        textAlign: 'right',
    },
    label: {
        fontSize: 12,
        lineHeight: 'normal',
        textAlign: 'right',
        letterSpacing: 'normal',
        fontWeight: 300,
        whiteSpace: 'nowrap',
    },
    green: {
        color: colors.green,
    },
    blue: {
        color: colors.blue,
    },
    orange: {
        color: colors.orange,
    },
    black: {
        color: colors.black,
        textAlign: 'center',
    },
}));
