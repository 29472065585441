import clsx from 'clsx';
import React from 'react';
import { Box } from '@material-ui/core';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import { RejectEafButton } from 'modules/eafApproval/components/Rejection/RejectEafButton';
import { ApproveEafButton } from 'modules/eafApproval/components/Approve/ApproveEafButton';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';

// @ts-ignore
export const EafApprovalGridCellActions = ({ className, id }: EafApprovalCellProps) => {
    const classes = useSubmittedTableStyles();
    return (
        <Box className={clsx(className, classes.iconCell, classes.actionsIcon)}>
            <RejectEafButton eafIds={[id]} compact/>
            <ApproveEafButton eafIds={[id]} compact/>
        </Box>
    );
};
