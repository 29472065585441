import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import DottedDivider from 'shared/components/divider/DottedDivider';
import useTotalStyles from './TotalStyles';

interface ITotalProps {
    classes?: string;
    total: string;
    label: string;
}

const Total: FC<ITotalProps> = ({ total, label }: ITotalProps) => {
    const initialClasses = useTotalStyles();
    return (
        <Box className={initialClasses.container}>
            <DottedDivider
                height={23}
                customClasses={initialClasses.divider}
            />
            <div className={initialClasses.total}>
                {total}
            </div>
            <div className={initialClasses.label}>
                {label}
            </div>
        </Box>
    );
};

export default Total;
