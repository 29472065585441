import React, { useCallback, useEffect } from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import {
    formBasicSpacing,
    useSettingsFormStyles,
} from 'modules/settings/submodules/components/sharedStyles/formStyles';

import { IDealFormValues } from 'modules/settings/submodules/clients/deals/components/DealForm/model';
import { ControllingOrgSelect } from 'modules/settings/submodules/clients/submittingOrg/components/ControllingOrgSelect/ControllingOrgSelect';
import { LabelWithValue } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/components/LabelWithValue';
import { SubmittingOrgSelect } from 'modules/settings/submodules/clients/deals/components/DealForm/components/SubmittingOrgSelect/SubmittingOrgSelect';
import { CostCenterSelect } from 'modules/settings/submodules/clients/deals/components/DealForm/components/CostCenterSelect/CostCenterSelect';
import { InvoiceRecipientSelect } from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/InvoiceRecipientSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceRecipients } from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/actions';
import {
    selectSubmittingOrgById,
    selectSubmittingOrgLocationById,
} from 'store/entities/configuration/configurationSelectors';
import { selectInvoiceRecipientNumber } from 'modules/settings/submodules/clients/deals/components/DealForm/store/invoiceRecipientNumberSelector';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { DealSegmentSelect } from 'modules/settings/submodules/clients/deals/components/DealForm/components/DealSegmentSelect/DealSegmentSelect';
import { DealTypeSelect } from '../DealTypeSelect';
import { selectDealJobNumbers } from 'modules/settings/submodules/clients/deals/components/DealForm/store/selectors';
import { useAssociatedJobNumbersStyles } from './associatedJobNumbersStyles';

export interface IDealFormProps extends FormikProps<IDealFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    isEdit?: boolean;
    dealNumber?: string;
    submitTitle: string;
    submitAndGoToJobNumberTitle: string;
    onCancel?: () => void;
}

export function DealForm({
    handleSubmit,
    submitTitle,
    submitAndGoToJobNumberTitle,
    values,
    dealNumber,
    isLoading = false,
    isDisabled = false,
    isEdit = false,
    setFieldValue,
}: IDealFormProps) {

    const classes = useSettingsFormStyles();
    const { controlling_org_id, submitting_org_id, invoice_recipient_id } = values;
    const dealJobNumbers = useSelector(selectDealJobNumbers(dealNumber));
    const dealJobNumbersString = dealJobNumbers
        .map(jobNumber => jobNumber.job_number)
        .sort((jn0, jn1) => jn0.localeCompare(jn1))
        .join(' ');
    const associatedJobNumbersClasses = useAssociatedJobNumbersStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!controlling_org_id || !submitting_org_id){
            dispatch(getInvoiceRecipients.success([]));
            return;
        }
        dispatch(getInvoiceRecipients.init({
            controllingOrgId: controlling_org_id,
            submittingOrgId: submitting_org_id,
        }));
    }, [dispatch, controlling_org_id, submitting_org_id]);

    const submittingOrg = useSelector(selectSubmittingOrgById(submitting_org_id));
    const submittingOrgLocation = useSelector(selectSubmittingOrgLocationById(submittingOrg?.location_id));

    const recipientNumber = useSelector(selectInvoiceRecipientNumber(invoice_recipient_id));

    const onSubmit = useCallback(() => {
        setFieldValue('continueJobNumberCreation', false);
        handleSubmit();
    }, [setFieldValue, handleSubmit]);

    const onSubmitAndGoToJobNumber = useCallback(() => {
        setFieldValue('continueJobNumberCreation', true);
        handleSubmit();
    }, [setFieldValue, handleSubmit]);

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
            <Box className={classes.formBlockLine}>
                <Box width="50%">
                    <DealTypeSelect
                        name="deal_type_id"
                        label="Deal Type"
                        useIdValue
                        className={classes.input}
                    />
                </Box>
                <Box width="50%">
                    <LabelWithValue label="Deal Number" value={dealNumber || '-'}
                        className={classes.labelWithValue}/>
                </Box>
            </Box>
            <Box className={classes.formBlockLine}>
                <Box width="50%">
                    <ControllingOrgSelect
                        name="controlling_org_id"
                        label="--"
                        outerLabel="Controlling Org"
                        className={classes.input}/>
                </Box>
                <Box width="50%">
                    <LabelWithValue label="Region" value={submittingOrgLocation?.hub}
                        className={classes.labelWithValue}/>
                </Box>
            </Box>
            <Box className={classes.formBlockLine}>
                <Box width="50%">
                    <SubmittingOrgSelect
                        name="submitting_org_id"
                        outerLabel="Submitting Org"
                        label="--"
                        controllingOrgId={controlling_org_id}
                        className={classes.input}/>
                </Box>
                <Box width="50%">
                    <LabelWithValue label="Location" value={submittingOrgLocation?.value}
                        className={classes.labelWithValue}/>
                </Box>
            </Box>
            <Box className={classes.formBlockLine}>
                <Box width="50%">
                    <InvoiceRecipientSelect
                        name="invoice_recipient_id"
                        outerLabel="Invoice Recipient"
                        label="--"
                        className={classes.input}/>
                </Box>
                <Box width="50%">
                    <LabelWithValue label="Invoice Recipient Org #" value={recipientNumber}
                        className={classes.labelWithValue}/>
                </Box>
            </Box>
            <Box className={classes.formBlockLine}>
                <Box width="50%">
                    <CostCenterSelect
                        name="cost_center_id"
                        outerLabel="Cost Center"
                        label="--"
                        className={classes.input}/>
                </Box>
                {
                    isEdit && (
                        <Box width="50%">
                            <LabelWithValue
                                label="Associated Job Numbers"
                                value={dealJobNumbersString}
                                className={clsx(classes.labelWithValue, associatedJobNumbersClasses.root)}
                                classes={associatedJobNumbersClasses}
                            />
                        </Box>
                    )
                }
            </Box>
            <Box className={classes.formBlockLine}>
                <Box width="50%">
                    <DealSegmentSelect
                        name="segment_id"
                        outerLabel="Deal Segment"
                        useIdValue
                        label="--"
                        className={classes.input}/>
                </Box>
            </Box>
            <Box m={formBasicSpacing} mt={2}>
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    onClick={onSubmit}
                >
                    {submitTitle}
                </ButtonWithLoader>
                <ButtonWithLoader
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    onClick={onSubmitAndGoToJobNumber}
                >
                    {submitAndGoToJobNumberTitle}
                </ButtonWithLoader>
            </Box>
        </form>
    );
}
