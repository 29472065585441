import React, { ChangeEvent, Ref } from 'react';
import { useField } from 'formik';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    Tooltip,
} from '@material-ui/core';
import { IFormFieldProps } from '../formFields/models';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { IFormSelect } from './model';

export interface IFormRadioButtonGroupProps<T> extends IFormFieldProps, IFormSelect {
    options: Array<T>;
    getKey: (item: T) => string;
    getText: (item: T) => string;
    onChanged?: (item: T) => void;
    inputRef?: Ref<HTMLDivElement>;
}

export default function FormRadioButtonGroup({
    name,
    outerLabel,
    options,
    className,
    getKey,
    getText,
    onChanged,
    useIdValue = false,
    title = '',
    id = name,
}: IFormRadioButtonGroupProps<any>,
) {
    const formHelperTextClasses = useFormHelperTextStyles();
    const [field, meta, helpers] = useField(name);
    const hasError = Boolean(meta.error && meta.touched);

    const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        const { value: changedValue = '' } = event.target;
        const objectValue = options.find(i => getKey(i) === changedValue) || null;
        const newValue = useIdValue ? changedValue || null : objectValue;
        helpers.setValue(newValue);
        helpers.setTouched(true);
        if (onChanged) {
            onChanged(objectValue);
        }
    };

    const onBlur = () => {
        helpers.setTouched(false);
    };

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            <Tooltip title={title}>
                <>
                    {outerLabel && (
                        <label
                            htmlFor={id}
                            className={formHelperTextClasses.outerLabel}
                        >
                            {outerLabel}
                        </label>
                    )}
                    <RadioGroup
                        {...field}
                        id={id}
                        value={!useIdValue && field.value ? getKey(field.value) : field.value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                    >
                        {options?.map(item => (
                            <FormControlLabel
                                control={<Radio size="small"/>}
                                value={getKey(item)}
                                key={getKey(item)}
                                label={getText(item)}
                            />
                        ))}
                    </RadioGroup>
                </>
            </Tooltip>
            {hasError && (
                <FormHelperText classes={formHelperTextClasses}>
                    {meta.error}
                </FormHelperText>
            )}
        </FormControl>
    );
}
