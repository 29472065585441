import { colors } from 'shared/styles/constants';
import { iosTransparent } from 'shared/utils/helpers/iosTransparent';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStickyCellsStyles = makeHighPriorityStyles({
    stickyCheckbox: {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: `${iosTransparent(colors.white)} !important`,
        backgroundImage: `
            linear-gradient(
                to right,
                ${new Array(4).fill(colors.white).join(', ')},
                ${iosTransparent(colors.white)}
            ) !important
        `,
    },
    stickyCheckboxHeader: {
        position: 'sticky',
        left: 0,
        zIndex: '2 !important' as unknown as number,
        backgroundColor: `${iosTransparent(colors.lightGray)} !important`,
        backgroundImage: `
            linear-gradient(
                to right,
                ${new Array(4).fill(colors.lightGray).join(', ')},
                ${iosTransparent(colors.lightGray)}
            )
        `,
    },
    totalPayStickyHeader: {
        position: 'sticky',
        right: '70px',
        zIndex: 1,
        background: `
            linear-gradient(
                to left,
                ${new Array(4).fill(colors.lightGray).join(', ')},
                ${iosTransparent(colors.lightGray)}
            ) !important
        `,
    },
    totalPayStickyCell: {
        position: 'sticky',
        right: '70px',
        zIndex: 1,
        background: `
            linear-gradient(
                to left,
                ${new Array(4).fill(colors.white).join(', ')},
                ${iosTransparent(colors.white)}
            )
        `,
    },
    detailsStickyHeader: {
        position: 'sticky',
        right: 0,
        zIndex: 1,
        backgroundColor: `${colors.lightGray} !important`,
    },
    detailsStickyCell: {
        position: 'sticky',
        right: 0,
        zIndex: 1,
        backgroundColor: colors.white,
    },
});
