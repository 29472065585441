import React from 'react';
import { ListItemIcon } from '@material-ui/core';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import clsx from 'clsx';

interface IMenuIconClient {
    icon_url: string;
}

export const MenuIconClient: React.FC<IMenuIconClient> = ({ icon_url }: IMenuIconClient) => {
    const classes = useMenuStyles();
    return (
        <ListItemIcon className={clsx(classes.itemIcon, classes.tenantNameItem)}>
            <img alt="" src={icon_url}/>
        </ListItemIcon>
    );
};
