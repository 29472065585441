import { combineReducers } from 'redux';
import {
    addUserAvatar,
    getUserAvatar,
    getUserProfile, ProfileActions,
} from './profileActions';
import { IAvatar, IUserProfileInfo } from './profileModel';
import { isLoadingReducer } from 'store/reducerUtils';

const isLoading = isLoadingReducer(getUserProfile);
const defaultState = {
    avatar: {
        id: '',
        url: '',
        mimetype: '',
        filename: '',
    },
};

function userProfile(
    state: IUserProfileInfo | null = null,
    action: ProfileActions,
): IUserProfileInfo | null {
    switch (action.type) {
        case getUserProfile.successType:
            return action.payload;
        default:
            return state;
    }
}
export function userAvatar(
    state: IAvatar = defaultState.avatar,
    action: ProfileActions,
): IAvatar {
    switch (action.type) {
        case addUserAvatar.successType:
        case getUserAvatar.successType:
            return action.payload;
        default:
            return state;
    }
}
export const profile = combineReducers({
    isLoading,   
    userProfile,
    userAvatar,
});
