import {
    checkEmployeeOnboardingStatus,
    getMyEmployeeProfile,
    getMyEmployeeProfiles,
    setOnboardingStep,
    updateEmployeeProfile,
    viewOfferLetter,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import { IOfferLetterFormValues } from 'modules/offerLetter/components/OfferLetterForm/model';
import {
    IEmployeeProfilesRequest,
    IGetOfferLetterParams,
    IOfferLetter,
    IOfferLetterFilters,
    IOfferLetterPagePayload, IRejectPayRangePayload,
    IUpdateOfferRequest,
    OfferLetterStatusSlug,
} from 'modules/offerLetter/store/model';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { SortingOrder } from 'shared/models/Order';
import { CheckedItems } from 'shared/utils/hooks/useCheckedItems';
import {
    ActionsReturnTypes,
    createActions,
    createRequestActions,
    createSingleAction,
    IErrorActionPayload,
    RequestType,
} from 'store/utils';
import { IOfferLetterRescissionFormValues } from '../components/OfferRescission/OfferLetterRescissionContent';
import { IUserInfo } from '../../../shared/models/User';

const SET_OFFER_LETTER_FILTERS = 'SET_OFFER_LETTER_FILTERS';
export const setOfferLetterFilters = createSingleAction<IOfferLetterFilters, typeof SET_OFFER_LETTER_FILTERS>(
    SET_OFFER_LETTER_FILTERS,
);

const INITIAL_LOAD_OFFER_LETTERS = 'offer_letter/INITIAL_LOAD_OFFER_LETTERS';
export const initialLoadOfferLetters = createSingleAction<void, typeof INITIAL_LOAD_OFFER_LETTERS>(
    INITIAL_LOAD_OFFER_LETTERS,
);

export const getMoreOfferLetterAction = createRequestActions<void, IOfferLetterPagePayload>(
    RequestType.Get,
    'MORE_OFFER_LETTERS',
    'offer_letter',
);

export const getOfferLetters = createRequestActions<IGetOfferLetterParams, Array<IOfferLetter>>(
    RequestType.Get,
    'OFFER_LETTERS',
    'offer_letter',
);

export const getMyOfferLetters = createRequestActions<void, Array<IOfferLetter>>(
    RequestType.Get,
    'MY_OFFER_LETTERS',
    'offer_letter',
);

export const RESET_EMPLOYEE_LOOKUP = 'RESET_EMPLOYEE_LOOKUP';
export const resetEmployeeLookup = createSingleAction<void, typeof RESET_EMPLOYEE_LOOKUP>(RESET_EMPLOYEE_LOOKUP);

export const createOffer = createRequestActions<IOfferLetterFormValues, IOfferLetter>(
    RequestType.Create,
    'OFFER_LETTER',
    'offer',
);

export const SET_CREATE_SIDEBAR_OPENED = 'offer/SET_CREATE_SIDEBAR_OPENED';
export const setCreateSidebarOpened = createSingleAction<boolean, typeof SET_CREATE_SIDEBAR_OPENED>(
    SET_CREATE_SIDEBAR_OPENED,
);

export const CREATE_OFFER_FOR_USER = 'offer/CREATE_OFFER_FOR_USER';
export const createOfferForUser = createSingleAction<string, typeof CREATE_OFFER_FOR_USER>(
    CREATE_OFFER_FOR_USER,
);

export const CREATE_OFFER_FOR_COMBINED_USER = 'offer/CREATE_OFFER_FOR_COMBINED_USER';
export const createOfferForCombinedUser = createSingleAction<IUserInfo, typeof CREATE_OFFER_FOR_COMBINED_USER>(
    CREATE_OFFER_FOR_COMBINED_USER,
);

export const SET_LOOKUP_SIDEBAR_OPEN = 'offer/SET_LOOKUP_SIDEBAR_OPEN';
export const setLookupSidebarOpen = createSingleAction<boolean, typeof SET_LOOKUP_SIDEBAR_OPEN>(
    SET_LOOKUP_SIDEBAR_OPEN,
);

export const editOffer = createRequestActions<
{
    id: string;
    values: IOfferLetterFormValues;
},
IOfferLetter>(
    RequestType.Update,
    'OFFER_LETTER/BY_FORM',
    'offer',
);

const RESCIND_OFFER = 'offer/RESCIND_OFFER';
const RESCIND_OFFER_SUCCESS = 'offer/RESCIND_OFFER_SUCCESS';
const RESCIND_OFFER_ERROR = 'offer/RESCIND_OFFER_ERROR';
export const rescindOffer = createActions<
{
    id: string;
    values: IOfferLetterRescissionFormValues;
},
IOfferLetter,
any,
typeof RESCIND_OFFER, typeof RESCIND_OFFER_SUCCESS, typeof RESCIND_OFFER_ERROR
>(
    RESCIND_OFFER,
    RESCIND_OFFER_SUCCESS,
    RESCIND_OFFER_ERROR,
);

export const SET_EDIT_SIDEBAR_OPENED = 'offer/SET_EDIT_SIDEBAR_OPENED';
export const setEditSidebarOpened = createSingleAction<boolean, typeof SET_EDIT_SIDEBAR_OPENED>(
    SET_EDIT_SIDEBAR_OPENED,
);

export const EDIT_OFFER_FOR_USER = 'offer/EDIT_OFFER_FOR_USER';
export const editOfferForUser = createSingleAction<string, typeof EDIT_OFFER_FOR_USER>(
    EDIT_OFFER_FOR_USER,
);

export const getOfferLetter = createRequestActions<string, IOfferLetter>(
    RequestType.Get,
    'OFFER_LETTER',
    'offer',
);

const GET_OWN_OFFER_LETTERS = 'GET_OWN_OFFER_LETTERS';
const GET_OWN_OFFER_LETTERS_SUCCESS = 'GET_OWN_OFFER_LETTERS_SUCCESS';
const GET_OWN_OFFER_LETTERS_ERROR = 'GET_OWN_OFFER_LETTERS_ERROR';

export const getOwnOfferLetters = createActions<string, IOfferLetter, any,
    typeof GET_OWN_OFFER_LETTERS, typeof GET_OWN_OFFER_LETTERS_SUCCESS, typeof GET_OWN_OFFER_LETTERS_ERROR
>(
    GET_OWN_OFFER_LETTERS, GET_OWN_OFFER_LETTERS_SUCCESS, GET_OWN_OFFER_LETTERS_ERROR,
);

export const updateOfferLetter = createRequestActions<
{
    id: string;
    values: Partial<IUpdateOfferRequest>;
},
IOfferLetter
>(
    RequestType.Update,
    'OFFER_LETTER',
    'offer',
);

export const updateOfferLetterStatus = createRequestActions<
{
    id: string;
    status: OfferLetterStatusSlug;
    rejectionReason?: string;
},
IOfferLetter
>(
    RequestType.Update,
    'OFFER_LETTER_STATUS',
    'offer',
);

export const getEmployeeProfiles = createRequestActions<IEmployeeProfilesRequest, IEmployeeProfile[]>(
    RequestType.Get,
    'EMPLOYEE_PROFILES',
    'offer_letter',
);

export const getPrismSSOLink = createRequestActions<string, string[]>(
    RequestType.Get,
    'PRISM_SSO_LINK',
);

const CHECK_OFFER_LETTER_PDF = 'offer-letter/CHECK_OFFER_LETTER_PDF';
const CHECK_OFFER_LETTER_PDF_SUCCESS = 'offer-letter/CHECK_OFFER_LETTER_PDF_SUCCESS';
const CHECK_OFFER_LETTER_PDF_ERROR = 'offer-letter/CHECK_OFFER_LETTER_PDF_ERROR';

interface ICheckOfferLetterPdf {
    id: string;
}
interface ICheckOfferLetterPdfInit extends ICheckOfferLetterPdf {
    hasGlobalToaster?: boolean;
}
interface ICheckOfferLetterError extends IErrorActionPayload {
    id: string;
}
export const checkOfferLetterPdf = createActions<
ICheckOfferLetterPdfInit,
ICheckOfferLetterPdf,
ICheckOfferLetterError,
typeof CHECK_OFFER_LETTER_PDF,
typeof CHECK_OFFER_LETTER_PDF_SUCCESS,
typeof CHECK_OFFER_LETTER_PDF_ERROR
>(
    CHECK_OFFER_LETTER_PDF, CHECK_OFFER_LETTER_PDF_SUCCESS, CHECK_OFFER_LETTER_PDF_ERROR,
);

export const checkEndOfAssignmentPdfStatus = createRequestActions<ICheckOfferLetterPdfInit, ICheckOfferLetterPdf>(
    RequestType.Get,
    'END_OF_ASSIGNMENT_PDF_STATUS',
    'offer-letter',
);

export const approveOfferPayRange = createRequestActions<string[], void>(
    RequestType.PATCH,
    'APPROVE_OFFER_PAY_RANGE',
    'offer',
);

export const rejectOfferPayRange = createRequestActions<IRejectPayRangePayload[], void>(
    RequestType.PATCH,
    'REJECT_OFFER_PAY_RANGE',
    'offer',
);

const SET_CHECKED_OFFER_LETTER_ROW = 'SET_CHECKED_OFFER_LETTER_ROW';
export const setCheckedOfferLetterRow = createSingleAction<CheckedItems, typeof SET_CHECKED_OFFER_LETTER_ROW>(
    SET_CHECKED_OFFER_LETTER_ROW,
);

const SET_OFFER_LETTER_TABLE_SORT = 'offer/SET_OFFER_LETTER_TABLE_SORT';
export const setOfferLetterTableSort = createSingleAction<SortingOrder, typeof SET_OFFER_LETTER_TABLE_SORT>(
    SET_OFFER_LETTER_TABLE_SORT,
);

export type OfferLetterActions =
    | ActionsReturnTypes<typeof getOfferLetters>
    | ActionsReturnTypes<typeof getMoreOfferLetterAction>
    | ActionsReturnTypes<typeof createOffer>
    | ActionsReturnTypes<typeof editOffer>
    | ActionsReturnTypes<typeof rescindOffer>
    | ActionsReturnTypes<typeof getOfferLetter>
    | ActionsReturnTypes<typeof getMyOfferLetters>
    | ActionsReturnTypes<typeof getOwnOfferLetters>
    | ActionsReturnTypes<typeof updateOfferLetter>
    | ActionsReturnTypes<typeof updateOfferLetterStatus>
    | ActionsReturnTypes<typeof updateEmployeeProfile>
    | ActionsReturnTypes<typeof getMyEmployeeProfile>
    | ActionsReturnTypes<typeof getPrismSSOLink>
    | ActionsReturnTypes<typeof checkOfferLetterPdf>
    | ActionsReturnTypes<typeof getMyEmployeeProfiles>
    | ActionsReturnTypes<typeof checkEmployeeOnboardingStatus>
    | ReturnType<typeof initialLoadOfferLetters>
    | ReturnType<typeof setOnboardingStep>
    | ReturnType<typeof resetEmployeeLookup>
    | ReturnType<typeof setLookupSidebarOpen>
    | ReturnType<typeof setCreateSidebarOpened>
    | ReturnType<typeof createOfferForUser>
    | ReturnType<typeof createOfferForCombinedUser>
    | ReturnType<typeof setEditSidebarOpened>
    | ReturnType<typeof editOfferForUser>
    | ReturnType<typeof viewOfferLetter>
    | ReturnType<typeof setOfferLetterFilters>;
