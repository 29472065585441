import { call, put, select, takeLatest } from 'typed-redux-saga';
import { selectIsManager } from 'store/components/auth/selectors';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { getHomeDashboardStats, SET_HOME_FILTER } from 'modules/home/store/actions';
import { homeDashboardApi } from 'modules/home/store/api';
import { selectHomeFilter } from 'modules/home/store/selectors';

export function* getDashboardStatsSaga() {
    const isManager = yield select(selectIsManager);
    const filter = yield select(selectHomeFilter);
    const stats = yield* call(
        homeDashboardApi.getDashboardStats,
        isManager ? 'manager' : 'employee',
        {
            client_id: filter.clientId,
        },
    );
    yield put(getHomeDashboardStats.success(stats));
}

function* getHomeDashboardStatsWatcher() {
    yield takeLatest(
        getHomeDashboardStats.initType,
        withBackendErrorHandler(
            getDashboardStatsSaga,
            getHomeDashboardStats.error,
            'Unable to get user statistics.',
        ),
    );
}

function* setHomeFilterSaga() {
    yield put(getHomeDashboardStats.init());
}

function* setHomeFilterWatcher() {
    yield takeLatest(SET_HOME_FILTER, setHomeFilterSaga);
}

export default [
    getHomeDashboardStatsWatcher,
    setHomeFilterWatcher,
];
