export interface ICustomFieldFormValues {
    name: string;
    description: string;
    mapping: string | null;
    clients: string[];
    all_clients: boolean;
}

export const defaultFormValues: ICustomFieldFormValues = {
    name: '',
    description: '',
    mapping: null,
    clients: [],
    all_clients: false,
};
