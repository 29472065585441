import { ICcpSplitItem } from '../../../models/ccpModel';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { selectSplitOpenedTransactionId } from './selectors';
import { ccpApi } from '../../api/ccpApi';
import { editModeUpdateSplitTransactions, getCcpTransactions, initialLoadCcpTransactionsPage, splitCcpTransaction } from '../../../store/actions';
import { withBackendErrorHandler } from '../../../../../store/utils/sagas/withBackendErrorHandler';
import { setOpenSplitCcpSidebar } from './actions';
import { selectCcpEditMode } from 'modules/ccp/store/selectors';
import { selectCcpTransactionsById } from '../../CcpTransactionsTable/store/selectors';
import { selectTransactionCodes } from '../../CcpCreate/store/selectors';
import { v4 as uuidv4 } from 'uuid';
import { selectCcpChildTransactionsById } from '../../CcpEdit/store/selectors';

function* splitCcpTransactionSaga({ payload }: { payload: ICcpSplitItem[] }) {
    const isEditMode = yield* select(selectCcpEditMode);
    const currentTransactionId = yield* select(selectSplitOpenedTransactionId);
    const transactions = yield* select(selectCcpTransactionsById);
    const ccpTransactionCodesById = yield* select(selectTransactionCodes);
    const preparedData = payload.map(x => {
        return {
            transaction_code_id: Array.isArray(x.transaction_code_id)
                ? (x.transaction_code_id.length > 0 ? x.transaction_code_id[0] : null)
                : x.transaction_code_id,
            department_id: Array.isArray(x.department_id)
                ? (x.department_id.length > 0 ? x.department_id[0] : null)
                : x.department_id,
            amount: x.amount,
            note: x.note,
            parent_transaction_id: currentTransactionId,
        } as ICcpSplitItem;
    });
    const transactionCurrent = transactions[currentTransactionId];
    let result;

    if (!isEditMode) {
        const response = yield* call(ccpApi.putSplitItems, preparedData);
        result = response.map(item => {
            return {
                ...item,
                is_dirty: false,
            };
        });
        yield put(getCcpTransactions.success(result));
        yield* put(setOpenSplitCcpSidebar(''));
        yield* put(splitCcpTransaction.success(result));
        yield put(initialLoadCcpTransactionsPage());
    } else {
        const ccpChildTransactionsById = yield* select(selectCcpChildTransactionsById);
        const splittedTransactions = preparedData.map(
            item => {
                return {
                    id: uuidv4(),
                    purchase_date: transactionCurrent.purchase_date,
                    merchant_name: transactionCurrent.merchant_name,
                    merchant_desc: transactionCurrent.merchant_desc,
                    transaction_type_id: transactionCurrent.transaction_type_id,
                    transaction_type_name: transactionCurrent.transaction_type_name,
                    gl_code: transactionCurrent.gl_code,
                    invoice_batch_id: transactionCurrent.invoice_batch_id,
                    transaction_code: ccpTransactionCodesById[item.transaction_code_id].name,
                    transaction_code_id: item.transaction_code_id,
                    department_id: item.department_id,
                    amount: item.amount,
                    note: item.note,
                    notes: null,
                    user_id: transactionCurrent.user_id,
                    attachments: [],
                    transaction_status_id: transactionCurrent.transaction_status_id,
                    parent_transaction_id: transactionCurrent.id,
                    is_dirty: true,
                    is_unsaved_split: true,
                };
            },
        );
        result = [
            transactionCurrent,
            ...splittedTransactions,
        ];
        transactionCurrent.is_dirty = true;
        transactionCurrent.transaction_code_id = null;
        transactionCurrent.transaction_code = null;
        transactionCurrent.department_id = null;
        transactionCurrent.notes = null;
        const previousChildrenIds = (ccpChildTransactionsById[transactionCurrent.id] || []).map(
            // @ts-ignore
            item => item.id,
        );
        const splitUpdate = {
            parent_id: transactionCurrent.id,
            removed_ids: previousChildrenIds,
            added_ids: splittedTransactions.map(item => item.id),
        };
        yield put(getCcpTransactions.success(result));
        yield put(editModeUpdateSplitTransactions(splitUpdate));
        yield* put(setOpenSplitCcpSidebar(''));
        yield* put(splitCcpTransaction.success(result));
    }
}

export function* splitTransactionsWatcher() {
    // @ts-ignore
    yield takeLatest(splitCcpTransaction.initType,
        withBackendErrorHandler(
            splitCcpTransactionSaga,
            splitCcpTransaction.error,
            'Unable to submit split Transaction.',
        ),
    );
}
