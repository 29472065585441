import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';

export interface IDateCellProps {
    dateString: string;
    className?: string;
    onClick?: () => void;
}

export const DateCell: React.FC<IDateCellProps> = ({
    dateString,
    className,
    onClick,
}: IDateCellProps) => {
    const date = formatShortDate(dateString);
    return (
        <PlainText
            value={date}
            className={className}
            onClick={onClick}
        />
    );
};
