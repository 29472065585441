import {
    expenseApprovalInfinityTableSelectors, getTimeAndExpenseSheetsSelectors,
    timeApprovalInfinityTableSelectors,
} from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/selectors';
import { EntryType } from 'shared/models/sheet/Sheet';

export const approvalTableSelectorsByType = (sheetType: EntryType) => {
    const map: Record<EntryType, ReturnType<typeof getTimeAndExpenseSheetsSelectors>> = {
        [EntryType.TIME]: timeApprovalInfinityTableSelectors,
        [EntryType.EXPENSE]: expenseApprovalInfinityTableSelectors,
    };
    return map[sheetType];
};
