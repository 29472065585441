import { employeeManagementFilterDefaultValue } from 'modules/employmentManagement/components/store/model';
import { assignmentInfoCard } from 'modules/subassignmentManagement/components/AssignmentInfo/store/reducer';
import { endSubassignments } from 'modules/subassignmentManagement/components/BulkEndAssignment/store/reducers';
import { compensationChange } from 'modules/subassignmentManagement/components/CompensationChange/store/reducers';
import { createSubassignments } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/reducers';
import { promotionDemotion } from 'modules/subassignmentManagement/components/PromotionDemotion/store/reducers';
import { subassignmentTable } from 'modules/subassignmentManagement/components/SubassignmentTable/store/reducers';
import { setSubassignmentFilters } from 'modules/subassignmentManagement/store/actions';
import { combineReducers } from 'redux';
import { singleValueReducer } from 'store/reducerUtils';
import { editSubassignments } from 'modules/subassignmentManagement/components/EditSubAssignment/store/reducers';

const subassignmentFilter = singleValueReducer(setSubassignmentFilters.action, employeeManagementFilterDefaultValue);

export const subassignmentManagement = combineReducers({
    subassignmentFilter,
    assignmentInfoCard,
    subassignmentTable,
    createSubassignments,
    editSubassignments,
    compensationChange,
    promotionDemotion,
    endSubassignments,
});
