import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import React from 'react';
import { Box } from '@material-ui/core';
import ApproveButton from './buttons/ApproveButton';
import RejectButton from './buttons/RejectButton';
import { ISheet, EntryType } from 'shared/models/sheet/Sheet';
import Time from './buttons/TimeIcon';
import Expense from './buttons/ExpenseIcon';
import useFilterAndActionControlStyles from './FilterAndActionControlsStyles';
import { StatusNames } from 'store/entities/timesheet/models/Status';

import { useSelector } from 'react-redux';
import { IUserInfo } from 'shared/models/User';

import SheetFilterButton from 'shared/components/filters/FilterButtonAndForm/SheetFilterButton';
import { setManagerSubmittedSheetsFilters } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsActions';
import {
    selectManagerSubmittedSheetsFilters,
    selectManagerSubmittedSheetsFiltersConfiguration,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';

interface IFilterAndActionControlsProps {
    sheetType: EntryType;
    showActionControls: boolean;
    sheets: ISheet[];
    linkedUsers: IUserInfo[];
    showFilters?: boolean;
    title?: string;
    withInfinityTable?: boolean;
}

export default function FilterAndActionMobile({
    sheetType, showActionControls, sheets, linkedUsers, showFilters = true, title, withInfinityTable,
}: IFilterAndActionControlsProps) {
    const classes = useFilterAndActionControlStyles();
    const activeStatus = useSelector(selectTimeAndExpenseActiveStatus);

    return (
        <>
            <Box className={classes.rootContainer}>
                {showActionControls && activeStatus === StatusNames.SUBMITTED ? (
                    <Box className={classes.centerContainer}>
                        <RejectButton
                            sheets={sheets}
                            users={linkedUsers}
                            sheetType={sheetType}
                        />
                        <ApproveButton
                            sheets={sheets}
                            sheetType={sheetType}
                        />
                    </Box>
                ) : (
                    <>
                        <Box className={classes.leftContainer}>
                            {sheetType === EntryType.TIME ? <><Time/></> : <><Expense/></>}
                            <Box>
                                <h3>
                                    {title ? title : `All ${(activeStatus === StatusNames.ALL) ? '' : activeStatus } ${sheetType === EntryType.TIME ? 'Timesheets' : 'Expenses'}`}
                                </h3>
                            </Box>
                        </Box>
                        {showFilters && (
                            <Box className={classes.rightContainer}>
                                <SheetFilterButton
                                    overrideClassName={classes.actionButton}
                                    // @ts-ignore
                                    submitFilters={setManagerSubmittedSheetsFilters}
                                    selectFiltersConfig={selectManagerSubmittedSheetsFiltersConfiguration}
                                    selectFilters={selectManagerSubmittedSheetsFilters}
                                    withInfinityTable={withInfinityTable}
                                />
                            </Box>
                        )}
                    </>
                )
                }
            </Box>
            {showFilters && (
                <FiltersChips
                    className={classes.filtersChipsMobile}
                    selector={selectManagerSubmittedSheetsFilters}
                    // @ts-ignore
                    action={setManagerSubmittedSheetsFilters}
                />
            )}
        </>
    );
}
