import { setEditSheetGroup } from 'modules/sheetApproval/store/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { EntryType, IEntry, ISheet } from 'shared/models/sheet/Sheet';
import { sheetStatusMessage } from 'shared/utils/formatters/sheetStatus';
import { SET_TENANT_SUCCESS, setTenantSuccess } from 'store/entities/clients/clientsAction';
import { clearExpenseSheetsByPayPeriod, getExpenseStatuses } from 'store/entities/timesheet/actions/expenseActions';
import { getSimpleSheets } from 'store/entities/timesheet/actions/sheetState';
import { getStatuses, submitSheets, updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { clearEmptyTimesheetsByPayPeriod, getTimeStatuses } from 'store/entities/timesheet/actions/timeActions';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';
import { timeApi } from 'store/entities/timesheet/api/timeApi';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    all, call, put, select,
    take, takeLatest,
} from 'typed-redux-saga';
import { decreaseSyncing, increaseSyncing, setGlobalToast } from '../../appConfig/actions';
import { ISheetStatusChange, StatusNames } from '../models/Status';
import {
    selectAllEntries,
    selectAllSheets, selectCaliforniaSubmissionWithPeriod,
    selectExpenseSheetStatusByName,
    selectTimeSheetStatusByName,
} from '../selectors';
import { createExpenseSheetApprovalSaga, updateExpenseSheetsStatusesSaga } from './expenseSagas';
import { createTimeSheetApprovalSaga, updateTimeSheetsStatusesSaga } from './timeSagas';
import { SyncingModels } from '../../appConfig/syncing/models';
import { addedCaliforniaSubmissionAction } from '../actions/californiaSubmission';

export function* fetchStatusesSaga({ payload: clientId }: ReturnType<typeof setTenantSuccess>) {
    if (!clientId) {
        return;
    }
    const [timeStatuses, expenseStatuses] = yield* all([
        call(timeApi.getAvailableStatuses, { client_id: clientId }),
        call(expenseApi.getAvailableStatuses, { client_id: clientId }),
    ]);
    yield* put(getTimeStatuses.success(timeStatuses));
    yield* put(getExpenseStatuses.success(expenseStatuses));
}

export function* fetchStatusesWatcher() {
    yield takeLatest(
        [
            SET_TENANT_SUCCESS,
            getStatuses.initType,
        ],
        withBackendErrorHandler(
            fetchStatusesSaga,
            getTimeStatuses.error,
            'Unable to load sheet statuses',
        ),
    );
}

function* changeSheetStatusSaga({
    payload: { statusName, sheetsEntryTypes, notes, californiaSubmission },
}: ReturnType<typeof updateSheetsStatus.init>) {
    const newTimeStatus = yield* select(selectTimeSheetStatusByName(statusName));
    const newExpenseStatus = yield* select(selectExpenseSheetStatusByName(statusName));

    const timeSheetsIds: string[] = [];
    const expenseSheetsIds: string[] = [];

    Object.entries(sheetsEntryTypes).forEach(([id, entryType]) => {
        if (entryType === EntryType.EXPENSE) {
            expenseSheetsIds.push(id);
        }
        if (entryType === EntryType.TIME) {
            timeSheetsIds.push(id);
        }
    });

    const flags = {
        time: false,
        expense: false,
    };

    if (!newTimeStatus || !newExpenseStatus) {
        const errorSheets = [];
        if (!newTimeStatus && timeSheetsIds.length > 0) {
            // @ts-ignore
            errorSheets.push('timesheet');
        }
        if (!newExpenseStatus && expenseSheetsIds.length > 0) {
            // @ts-ignore
            errorSheets.push('expense sheet');
        }
        // If we don't have needed status for sheet type then we throw exception
        if (errorSheets.length > 0) {
            yield* put(updateSheetsStatus.error('Appropriate status was not found'));
            yield* put(setGlobalToast({
                title: `There is no needed status for ${errorSheets.join(' and ')}`,
                severity: IModalSeverity.Error,
            }));
            return;
        }
    }

    const updateSagas = [];
    if (expenseSheetsIds.length) {
        if (newExpenseStatus.name === StatusNames.APPROVED){
            // @ts-ignore
            updateSagas.push(call(createExpenseSheetApprovalSaga, expenseSheetsIds));
        } else {  //reject keeps the old logic
            // @ts-ignore
            updateSagas.push(call(updateExpenseSheetsStatusesSaga, expenseSheetsIds, newExpenseStatus, notes));
        }
        flags.expense = true;
    }
    if (timeSheetsIds.length) {
        if (newTimeStatus.name === StatusNames.APPROVED){
            // @ts-ignore
            updateSagas.push(call(createTimeSheetApprovalSaga, timeSheetsIds));
        } else {  //reject keeps the old logic
            // @ts-ignore
            updateSagas.push(call(updateTimeSheetsStatusesSaga,
                timeSheetsIds,
                newTimeStatus,
                notes,
                californiaSubmission));
        }
        flags.time = true;
    }

    const amount = Object.values(sheetsEntryTypes).length;

    yield put(increaseSyncing(SyncingModels.EditableEmployeeSheet));
    yield put(increaseSyncing(SyncingModels.ApprovalSheet));
    try {
        yield* all(updateSagas);

        yield* put(setGlobalToast({
            title: sheetStatusMessage(amount, flags, statusName.toLowerCase()),
            severity: IModalSeverity.Success,
        }));
        yield put(updateSheetsStatus.success());
    } catch (e) {
        yield* put(setGlobalToast({
            title: sheetStatusMessage(amount, flags, statusName.toLowerCase(), false),
            severity: IModalSeverity.Error,
        }));
        yield put(updateSheetsStatus.error(e));
    }

    yield put(setEditSheetGroup(null));
    yield put(decreaseSyncing(SyncingModels.ApprovalSheet));
    yield put(decreaseSyncing(SyncingModels.EditableEmployeeSheet));
}

export function* updateSheetsStatusWatcher() {
    yield takeLatest(updateSheetsStatus.initType, changeSheetStatusSaga);
}

const sheetNotEmpty = (sheet: ISheet, entries: IEntry[]): boolean => {
    return Boolean(entries.find(entry => entry.sheet_id === sheet.id)) && Boolean(
        sheet.total_minutes || parseFloat(sheet.total_dollars),
    );
};

export function* submitSheetsSaga(action: ReturnType<typeof submitSheets>) {
    const { entryTypes, payPeriod } = action.payload;
    const sheets = yield* select(selectAllSheets);
    const entries = yield* select(selectAllEntries);
    const californiaSubmissionState = yield* select(selectCaliforniaSubmissionWithPeriod);
    const californiaSubmissionValue = californiaSubmissionState
        && californiaSubmissionState.payPeriod
        && payPeriod.period_start === californiaSubmissionState.payPeriod.period_start
        && payPeriod.period_end === californiaSubmissionState.payPeriod.period_end
        ? californiaSubmissionState.submissionState : null;

    const submitSheetsInfo: ISheetStatusChange = {
        statusName: StatusNames.SUBMITTED,
        californiaSubmission: californiaSubmissionValue,
        sheetsEntryTypes: sheets.reduce((acc, sheet) => {
            if (sheet.period_end === payPeriod.period_end
                && sheet.status.name === StatusNames.WORKING
                && entryTypes.includes(sheet.entry_type)
                && sheetNotEmpty(sheet, entries)
            ) {
                return {
                    ...acc,
                    [sheet.id]: sheet.entry_type,
                };
            }
            return acc;
        }, {}),
    };

    const typesToUpdate = Object.values(submitSheetsInfo.sheetsEntryTypes);
    if (typesToUpdate.length === 0) {
        yield put(setGlobalToast({ title: 'No sheets to submit', severity: IModalSeverity.Warning }));
        return;
    }

    /**
     * We need to delete all empty the sheets for the pay period
     * because after submitting on the backend all the empty lists will be deleted.
     */
    if (typesToUpdate.includes(EntryType.TIME)) {
        yield put(clearEmptyTimesheetsByPayPeriod(payPeriod));
    }
    if (typesToUpdate.includes(EntryType.EXPENSE)) {
        yield put(clearExpenseSheetsByPayPeriod(payPeriod));
    }

    yield put(updateSheetsStatus.init(submitSheetsInfo));

    const resultAction = yield take([updateSheetsStatus.successType, updateSheetsStatus.errorType]);
    if (resultAction.type === updateSheetsStatus.errorType) {
        yield put(getSimpleSheets.init());
    }
    yield put(addedCaliforniaSubmissionAction({}));
}

export function* submitSheetsWatcher() {
    yield takeLatest(submitSheets.action, submitSheetsSaga);
}

export default [
    fetchStatusesWatcher,
    updateSheetsStatusWatcher,
    submitSheetsWatcher,
];
