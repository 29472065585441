import { HumanResourcesRoutes } from 'modules/humanResources/HumanResourcesRoutes';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EmailConfirmation from 'shared/components/auth/EmailConfirmation';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import ErrorBoundary from 'shared/components/errorBoundary/ErrorBoundary';
import { BackspaceListener } from 'shared/components/listeners/BackspaceListener';
import { InactivityListener } from 'shared/components/listeners/InactivityListener';
import { GlobalToast } from 'shared/components/toasts/GlobalToast';
import { routes } from 'shared/routes';
import { logger } from 'shared/utils/logging/logger';
import Home from './modules/home/Home';
import Client from 'modules/clients/Client';
import Login from 'shared/components/auth/Login';
import ResetPassword from 'shared/components/auth/ResetPassword';
import NotFound from 'shared/components/notFound/NotFound';
import { IStore } from 'store/configureStore';
import { authInitial } from 'store/components/auth/authActions';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { optimizely } from 'utils/optimizely';
import HomeDashboardPage from 'modules/home/dashboard/HomeDashboardPage';
import OfferPdfOnly from 'modules/offerLetter/OfferLetterEmployee/OfferPdfOnly';
import { EndOfAssignmentPdfOnly } from 'modules/offerLetter/OfferLetterEmployee/EndOfAssignmentPdfOnly';
import { SettingsRoutes } from 'modules/settings/SettingsRoutes';
import { Permission } from 'store/components/auth/authModels';
import { OfferLetterEmployeeRoutes } from 'modules/offerLetter/OfferLetterEmployeeRoutes';
import UserProfile from 'modules/profile/UserProfile';
import EnterPassword from './shared/components/auth/EnterPassword';
import ResetPasswordFailed from './shared/components/auth/ResetPasswordFailed';
import { HeaderTags } from 'HeaderTags';
import { ClientThemeProvider } from 'shared/components/theme/ClientThemeProvider';
import { useCodeRefresher } from 'utils/codeRefresher';
import { StatusPageWidget } from './shared/components/StatusPage/StatusPage';
import Appcues from './shared/services/Appcues/Appcues';
import { PayrollProcessorHubRoutes } from 'modules/payrollProcessorHub/PayrollProcessorHubRoutes';
import { setOptimizelyReady } from 'store/entities/appConfig/actions';
import { KeycloakDisabledRoute } from 'shared/components/auth/KeycloakDisabledRoute';
import { RegistrationRoute } from 'shared/components/auth/RegistrationRoute';
import { history } from 'shared/utils/logging/sentry';
import { ServiceDisabledPage } from './serviceDisabledPage';

const App: React.FC = () => {
    const auth = useSelector((state: IStore) => state.auth);

    const dispatch = useDispatch();
    useCodeRefresher();

    const location = useLocation();
    useEffect(() => {
        Appcues.view();
    }, [location.pathname]);

    useEffect(() => {
        dispatch(authInitial.init());
        optimizely.onReady().finally(() => {
            dispatch(setOptimizelyReady(true));
        });
    }, [dispatch]);

    useEffect(() => {
        logger.setUserId(auth?.user?.id);
    }, [auth?.user?.id]);

    const enableFullScreenErrorPage = false;

    if (enableFullScreenErrorPage) {
        return (
            <ServiceDisabledPage/>
        );
    }

    return (
        <OptimizelyProvider
            optimizely={optimizely}
            user={{
                id: auth.user ? auth.user.id : 'empty',
            }}
        >
            <HeaderTags title="Headway Connect"/>
            <ErrorBoundary>
                <ClientThemeProvider>
                    <Switch>
                        <PrivateRoute
                            path={routes.HOME}
                            component={Home}
                            componentProps={{ sectionTitle: `${auth.user?.firstName} ${auth.user?.lastName}`,
                                content: <HomeDashboardPage/> }}
                            exact
                        />
                        <PrivateRoute
                            path={routes.EMPLOYEE_OFFER_LETTER.ROOT}
                            component={Home}
                            componentProps={{ content: <OfferLetterEmployeeRoutes/> }}
                        />
                        <PrivateRoute
                            path={routes.CLIENT.ROOT}
                            component={Client}
                        />
                        <PrivateRoute
                            path={routes.USER_PROFILE}
                            component={Home}
                            componentProps={{
                                sectionTitle: `${auth.user?.firstName} ${auth.user?.lastName}`,
                                content: <UserProfile/>,
                            }}
                            blockImpersonate
                        />
                        <KeycloakDisabledRoute
                            path={routes.AUTH.LOGIN}
                            component={Login}
                        />
                        <RegistrationRoute path={routes.AUTH.REGISTRATION}/>
                        <KeycloakDisabledRoute
                            path={routes.AUTH.RESET_PASSWORD}
                            component={ResetPassword}
                        />
                        <Route
                            path={routes.AUTH.RESET_FAILED}
                            component={ResetPasswordFailed}
                        />
                        <Route
                            path={routes.AUTH.NEW_PASSWORD}
                            component={EnterPassword}
                        />
                        <Route
                            path={routes.AUTH.EMAIL_CONFIRMATION}
                            component={EmailConfirmation}
                        />
                        <PrivateRoute component={EndOfAssignmentPdfOnly} path={routes.END_ASSIGNMENT_PDF_ONLY_VIEW}/>
                        <PrivateRoute component={OfferPdfOnly} path={routes.OFFER_PDF_ONLY_VIEW}/>
                        <PrivateRoute
                            path={routes.PAYROLL_PROCESSOR_HUB.ROOT}
                            component={Home}
                            permission={Permission.PPHReadAccess}
                            componentProps={{
                                flex: true,
                                sectionTitle: 'Payroll',
                                content: <PayrollProcessorHubRoutes/>,
                            }}
                        />
                        <PrivateRoute
                            path={routes.SETTINGS}
                            component={SettingsRoutes}
                            anyPermissionFrom={[
                                Permission.modifyClientSettings,
                                Permission.ManageManagerUsers,
                                Permission.ManageEmployeeUsers,
                                Permission.ManageOfferLetterTemplates,
                                Permission.ManagePaySettings,
                            ]}
                        />
                        <PrivateRoute
                            path={routes.HUMAN_RESOURCES.ROOT}
                            component={Home}
                            componentProps={{
                                sectionTitle: 'Human Resources',
                                content: <HumanResourcesRoutes/>,
                            }}
                        />
                        <Route component={NotFound} history={history}/>
                    </Switch>
                </ClientThemeProvider>
            </ErrorBoundary>
            <GlobalToast/>
            <BackspaceListener/>
            <InactivityListener/>
            <StatusPageWidget/>
        </OptimizelyProvider>
    );
};

export default hot(module)(App);
