import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useSelector } from 'react-redux';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectBackgroundCheckTemplatesSortedByName } from 'store/entities/configuration/configurationSelectors';
import { IBackgroundCheckTemplate } from 'store/entities/configuration/configurationModel';
import { OfferLetterTemplatedTextField } from './OfferLetterTemplatedTextField';

interface IOfferLetterBackgroundChecksProps extends IFormFieldProps {
    forceValidate?: () => void;
}

interface IOfferLetterBackgroundChecksStoreProps {
    templates?: IBackgroundCheckTemplate[];
}

export function OfferLetterBackgroundChecksPure({
    templates,
    ...props
}: IOfferLetterBackgroundChecksStoreProps & IOfferLetterBackgroundChecksProps) {
    return (
        <OfferLetterTemplatedTextField
            labelTemplate="Select Drug Screen & Background Check"
            templates={templates || []}
            {...props}
        />
    );
}

export const OfferLetterBackgroundChecks = separateLogicDecorator<
IOfferLetterBackgroundChecksProps,
IOfferLetterBackgroundChecksStoreProps
>(() => ({
    templates: useSelector(selectBackgroundCheckTemplatesSortedByName),
}))(OfferLetterBackgroundChecksPure);
