import { useIsUserHasSheetApprovePermission } from 'modules/sheetApproval/store/hooks';
import React, { FC, useCallback } from 'react';
import { Button, useMediaQuery, Theme } from '@material-ui/core';
import useActionButtonStyles from '../FilterAndActionControlsStyles';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { useModal } from 'shared/utils/hooks/useModal';
import SheetApproval from 'shared/components/modals/SheetApproval/SheetApproval';
import { isMobileBreakpoint } from 'shared/styles/constants';

interface IApproveButtonProps {
    sheetType: EntryType;
    customClasses?: string;
    sheets: Array<ISheet>;
    onAction?: () => void;
}

const ApproveButton: FC<IApproveButtonProps> = ({ sheets, sheetType, onAction }: IApproveButtonProps) => {
    const classes = useActionButtonStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMobileBreakpoint), { noSsr: true });

    const { isModalOpened, onModalOpen, onModalClose } = useModal();

    const onApproveClick = useCallback(() => {
        onModalOpen();
    }, [onModalOpen]);

    const hasPermission = useIsUserHasSheetApprovePermission();
    if (!hasPermission) {
        return null;
    }

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                classes={{ root: classes.buttonApprove, label: classes.buttonTextStyle }}
                onClick={onApproveClick}
            >
                Approve
            </Button>
            <SheetApproval title={'Approve' + (isMobile ? ' ' : ' Submitted ' ) + (sheetType === EntryType.TIME ? 'Timesheets' : isMobile ? 'Expenses' : ' Expense Sheets')}
                sheetType={sheetType}
                isOpened={isModalOpened}
                onAction={onAction}
                onClose={onModalClose} sheets={sheets}
            />
        </>
    );
};

export default ApproveButton;
