import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { ChevronLeft, Close } from '@material-ui/icons';
import clsx from 'clsx';

export type Anchor = 'top' | 'left' | 'bottom' | 'right' ;

export interface ISidebarCommonProps {
    title: string;
    onBack?: () => void;
    onClose?: () => void;
    titleClasses?: Partial<{
        root: string;
        text: string;
        icon: string;
    }>;
    white?: boolean;
    compact?: boolean;
}

export default function SidebarHeader({
    title,
    onBack,
    onClose,
    titleClasses = {},
    white = false,
    compact = false,
}: ISidebarCommonProps) {
    const classes = useSidebarStyles();

    return (
        <div
            className={clsx(
                classes.header,
                classes.sidebarElement,
                titleClasses?.root ?? '',
                {
                    [classes.whiteTitle]: white,
                    [classes.compact]: compact,
                },
            )}
        >
            {onBack
                ? (
                    <IconButton
                        edge="start"
                        color="inherit"
                        classes={{
                            root: clsx(classes.chevronIcon, titleClasses?.icon ?? ''),
                            edgeStart: classes.edgeStart,
                        }}
                        onClick={onBack}
                    >
                        <ChevronLeft/>
                    </IconButton>
                ) : <></>
            }
            <Typography className={clsx(titleClasses?.text ?? '', classes.titleText)} variant="h5">
                {title}
            </Typography>
            {onClose
                ? (
                    <IconButton
                        edge="end"
                        color="inherit"
                        classes={{ root: classes.closeIcon }}
                        onClick={onClose}
                    >
                        <Close/>
                    </IconButton>
                ) : <></>
            }
        </div>
    );
}
