import React from 'react';
import MainContent from 'shared/components/common/MainContent';

export default function NotPermitted() {
    return (
        <MainContent>
            <h1>You have no permission to view this page</h1>
        </MainContent>
    );
}
