import {
    resolvePtoMaxUseAvailable,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/detailItemValueResolvers';
import { SheetGroup } from 'modules/timeAndExpense/store/model';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { formatUtcWithEstZone, getFormattedPayPeriod } from 'shared/models/Dates';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getUserName, getLastFirstName } from 'shared/utils/converters/user';
import { formatApprovals } from 'shared/utils/formatters/formatApprovals';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { rejectedReasonFormatter } from 'shared/utils/formatters/rejectedReasonFormatter';
import { logger } from 'shared/utils/logging/logger';

import { DetailSlugs, IDetailConfiguration, SheetDetailSlug } from 'store/entities/clients/clientsModel';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectAssignmentsById, selectDealsById, selectSubmittingOrgsById } from 'store/entities/configuration/configurationSelectors';
import { selectCustomFieldsByIds, selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { getGroupedCustomFieldDisplayValues } from 'store/entities/customFields/utils';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useUserPto } from 'store/entities/users/hooks';
import { uniq } from 'lodash-es';
import { selectUsersById } from 'store/entities/users/selectors';
import { formatEditedOn } from 'shared/utils/formatters/sheetDetail';
import { selectSheetLogsById } from 'store/entities/timesheet/selectors';
import { useEffect } from 'react';
import { getSheetLogs } from 'store/entities/timesheet/actions/sheetState';

export const useSheetGroupDetailAdditionalData = (group?: SheetGroup | null) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const timeSheetIds = [];
        const expenseSheetIds = [];
        group?.sheets.forEach(sheet => {
            switch (sheet.entry_type) {
                case (EntryType.TIME): {
                    // @ts-ignore
                    timeSheetIds.push(sheet.id);
                    break;
                }
                case (EntryType.EXPENSE): {
                    // @ts-ignore
                    expenseSheetIds.push(sheet.id);
                    break;
                }
            }
        });
        if (timeSheetIds.length || expenseSheetIds.length) {
            dispatch(getSheetLogs.init({ timeSheetIds, expenseSheetIds } ));
        }
    // eslint-disable-next-line
    }, [group?.id]);
};

export const useSheetGroupDetail = (
    group: SheetGroup | null,
    sheetType: EntryType,
    configuration: IDetailConfiguration<DetailSlugs>[],
    showPTO?: boolean,
): ISheetInfoItemData[] => {
    const activeStatus = useSelector(selectTimeAndExpenseActiveStatus);
    const showReason = activeStatus === StatusNames.REJECTED;
    const allNotes = group?.sheets.map(sheet => sheet.notes).flat();
    const notes = rejectedReasonFormatter(allNotes)[0];
    const usersByIds = useSelector(selectUsersById);
    const dealNumbersById = useSelector(selectDealsById);
    const submittingOrgsById = useSelector(selectSubmittingOrgsById);
    const pto = useUserPto(group?.employee?.id, group?.sheets[0]?.client_id);
    const hasJobNumber = configuration.some(item => item.slug === SheetDetailSlug.JobNumber);
    const latestSubmittedTime = group?.sheets
        .map(sheet => sheet.submitted_at)
        .sort()
        .reverse()
        .find(Boolean);

    const sheetIds = group?.sheets.map(sheet => sheet?.id).filter(Boolean) || [];

    const dataViewDictionary: Record<SheetDetailSlug, () => string | null> = {
        [SheetDetailSlug.SheetId]: () => sheetIds.join(', ') || '',
        [SheetDetailSlug.PayPeriod]: () => group ? getFormattedPayPeriod(group.payPeriod) : null,
        [SheetDetailSlug.Area]: () => null,
        [SheetDetailSlug.Position]: () => null,
        [SheetDetailSlug.Location]: () => null,
        [SheetDetailSlug.Department]: () => null,
        [SheetDetailSlug.Approver]: () => null,
        [SheetDetailSlug.Employee]: () => getLastFirstName(group?.employee),
        [SheetDetailSlug.PayRate]: () => null,
        [SheetDetailSlug.Notes]: () => notes?.notes || '',
        [SheetDetailSlug.BatchId]: () => {
            return uniq(
                group?.sheets
                    .map(sheet => sheet?.prism_batch_id)
                    .filter(Boolean),
            ).join(', ') || '';
        },
        [SheetDetailSlug.DeliveryOrder]: () => null,
        [SheetDetailSlug.JobsiteLocation]: () => {
            return uniq(group?.jobNumbers
                .map(jn => submittingOrgsById[dealNumbersById[jn?.deal_id]?.submitting_org_id]?.client_site_name)
                .filter(Boolean))
                .join(', ');
        },
        // @ts-ignore
        [SheetDetailSlug.JobNumber]: () => {
            return group?.jobNumbersString;
        },
        [SheetDetailSlug.DealNumber]: () => uniq(group?.jobNumbers
            .map(jobNumber => dealNumbersById[jobNumber?.deal_id]?.deal_number)
            .filter(Boolean))
            .join(', '),
    };
    const detailConfiguration = configuration.map(config => {
        let value = '';
        if (dataViewDictionary[config.slug]) {
            // @ts-ignore
            value = dataViewDictionary[config.slug]();
        } else {
            logger.error(new Error(`Sheet detail slug not found: ${config.slug}`));
        }
        return {
            title: config.placeholder,
            value,
        };
    });
    const customFieldsByIds = useSelector(selectCustomFieldsByIds);
    const customFieldsValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const assignmentsByIds = useSelector(selectAssignmentsById);
    if (hasJobNumber) {
        const groupAssignments = uniq((group?.sheets || []).map(sheet => sheet.assignment_id))
            .map(assignmentId => assignmentsByIds[assignmentId]).filter(Boolean);
        const assignmentCustomFieldValues = groupAssignments
            .map((subassignment: ISubassignment) => subassignment.custom_field_value_ids).flat()
            .map(valueId => customFieldsValuesByIds[valueId])
            .filter(Boolean);
        const displayValues = getGroupedCustomFieldDisplayValues(
            assignmentCustomFieldValues.map(value => value.id),
            [],
            customFieldsValuesByIds,
        );
        const customFieldsItems = Object.entries(displayValues).map(([fieldId, fieldValues]) => {
            return {
                title: customFieldsByIds[fieldId]?.name,
                value: fieldValues.join(', '),
            };
        });
        detailConfiguration.push(...customFieldsItems);
    } else {
        const customFieldsItems = Object.keys(group?.customFieldValues || {}).map(customFieldId => {
            return {
                title: customFieldsByIds[customFieldId]?.name,
                value: group?.getCustomFieldValuesAsStringById(customFieldId),
            };
        });
        // @ts-ignore
        detailConfiguration.push(...customFieldsItems);
    }

    if (group) {
        detailConfiguration.push({
            title: `Approving ${pluralize('Manager', group.approvers.length)}`,
            value: group.approvers.map(approver => getUserName(approver)).join(', '),
        });
        detailConfiguration.push({
            title: 'Approved Timestamp',
            value: formatApprovals(group.approvals, usersByIds),
        });
    }

    showReason && detailConfiguration.push({
        title: 'Rejection Reason',
        // @ts-ignore
        value: dataViewDictionary[SheetDetailSlug.Notes](),
    });
    showPTO && pto && sheetType === EntryType.TIME && detailConfiguration.push({
        title: 'Available PTO hours',
        value: resolvePtoMaxUseAvailable(pto),
    });
    latestSubmittedTime && detailConfiguration.push({
        title: 'Submitted on',
        value: formatUtcWithEstZone(latestSubmittedTime),
    });

    const sheetLogsBySheetId = useSelector(selectSheetLogsById);
    const userIds = group?.sheets.map(sheet => sheet.user_id) || [];
    const sheetLogs = sheetIds.map(id => sheetLogsBySheetId[id] || [])
        .flat()
        .filter(log => !(userIds.includes(log.actorId)));

    sheetLogs.length && detailConfiguration.push({
        title: 'Edited on',
        value: formatEditedOn(usersByIds, sheetLogs),
    });

    return detailConfiguration;
};
