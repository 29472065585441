import { Nullable } from '../../../../../../types/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAssignmentCustomFieldMapping } from 'store/entities/configuration/configurationSelectors';
import { ICustomFieldValue, IPositionCustomField } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByFieldId } from 'store/entities/customFields/selectors';

function onlyWithAvailablePayRange(position: Nullable<ICustomFieldValue>): boolean {
    const positionData = position?.data as IPositionCustomField;
    return Boolean(positionData?.pay_range_available);
}

export function usePositionsWithAvailablePayRange(): ICustomFieldValue[] {
    const customFieldMapping = useSelector(selectAssignmentCustomFieldMapping);
    const positionSelector = selectCustomFieldValuesByFieldId(customFieldMapping?.position_value_id || '');
    const allPositions: ICustomFieldValue[] = useSelector(positionSelector);

    return useMemo(() => allPositions.filter(onlyWithAvailablePayRange), [allPositions]);
}
