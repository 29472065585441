export enum PayType {
    Hourly = 'Hourly',
    Salaried = 'Salaried',
}

export enum PayRateType {
    PER_HOUR = 'per hour',
    PER_WEEK = 'per week',
    BI_WEEKLY = 'bi-weekly',
    SEMI_MONTHLY = 'semi-monthly',
    MONTHLY = 'monthly',
    DAILY = 'daily',
}
