import { settingsRoutes } from 'modules/settings/routes';
import { EcubReport } from 'modules/settings/submodules/clients/reports/Ecub/EcubReport';
import { LatestPayRatesReport } from 'modules/settings/submodules/clients/reports/LatestPayRates/LatestPayRatesReport';
import {
    ZohoAnalyticsPermalinkSetupPage,
} from 'modules/settings/submodules/clients/reports/zoho/ZohoAnalyticsPermalinkSetupPage';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { Permission } from 'store/components/auth/authModels';

export const ReportRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path={settingsRoutes.CLIENTS.REPORTS.ZOHO}
                component={ZohoAnalyticsPermalinkSetupPage}
                exact
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.REPORTS.LATEST_PAY_RATES}
                component={LatestPayRatesReport}
                exact
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.REPORTS.ECUB}
                component={EcubReport}
                permission={Permission.ManageEcub}
                exact
            />
            <Redirect
                path={settingsRoutes.CLIENTS.REPORTS.ROOT}
                to={settingsRoutes.CLIENTS.REPORTS.ZOHO}
            />
        </Switch>
    );
};
