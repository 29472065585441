import baseApi from 'shared/utils/baseApi';
import { clientConfigurationsService } from 'store/entities/configuration/configurationApi';
import {
    ICreateCustomFieldValueRequest,
    IUpdateCustomFieldValueRequest,
} from 'modules/settings/submodules/clients/customFieldValues/store/models';
import { ICustomFieldValue, IProjectClass } from 'store/entities/customFields/model';

export const customFieldValuesApi = {
    async createCustomFieldValue(
        clientId: string,
        request: ICreateCustomFieldValueRequest,
    ): Promise<ICustomFieldValue> {
        const { data } = await baseApi.create<ICreateCustomFieldValueRequest, ICustomFieldValue>(
            clientConfigurationsService,
            `clients/${clientId}/custom_field_values`,
            request,
        );
        return data;
    },
    async updateCustomFieldValue(
        clientId: string,
        valueId: string,
        request: IUpdateCustomFieldValueRequest,
    ): Promise<ICustomFieldValue> {
        const { data } = await baseApi.patch<IUpdateCustomFieldValueRequest, ICustomFieldValue>(
            `/${clientConfigurationsService}/clients/${clientId}/custom_field_values/${valueId}`,
            request,
        );
        return data;
    },
    async deleteCustomFieldValue(clientId: string, valueId: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${clientConfigurationsService}/clients/${clientId}/custom_field_values`, valueId,
        );
        return data.status;
    },
    async getProjectClasses(): Promise<IProjectClass[]> {
        const { data } = await baseApi.get<{ project_classes: IProjectClass[] }>(
            `/prismhr/clients/${baseApi.clientId}/prism_project_classes`,
        );
        return data.project_classes;
    },
};
