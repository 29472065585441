import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { omit } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import {
    IClientLinksConfigurationFormValues,
    LinksConfigurationForm,
    IClientConfigurationLink,
    getLinksFormItem,
} from 'modules/settings/submodules/clients/appConfiguration/components/LinksConfigurationForm';
import { selectCurrentClientId, selectIsClientConfigurationUpdating } from 'store/entities/clients/selectors/clientsSelectors';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { selectClientExternalLinks } from 'store/entities/clients/selectors/configurationSelectors';
import { updateClientConfiguration } from 'store/entities/clients/clientsAction';

const validationSchema = yup.object<IClientLinksConfigurationFormValues>({
    externalLinks: yup.array().of(
        yup.object<IClientConfigurationLink>().shape(
            {
                id: yup.string().required(),
                name: yup.string().trim().required(ValidationMessages.REQUIRED),
                link: yup.string().trim().required(ValidationMessages.REQUIRED),
                enabled: yup.boolean().required(),
            },
        ),
    ).required(),
});

export function LinksConfigurationPage() {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const externalLinks = useSelector(selectClientExternalLinks);
    const isUpdating = useSelector(selectIsClientConfigurationUpdating);
    const initialState = useMemo(() => {
        const defaultLinks = [getLinksFormItem({})];
        const links = (externalLinks && externalLinks.length > 0 ? externalLinks : defaultLinks)
            .map(link => getLinksFormItem(link));
        return {
            externalLinks: links,
        };
    }, [externalLinks]);

    const handleUpdate = useCallback((values: IClientLinksConfigurationFormValues) => {
        dispatch(updateClientConfiguration.init({
            clientId,
            external_links: values.externalLinks.map(item => omit(item, ['id'])),
        }));
    }, [clientId, dispatch]);

    return (
        <Formik
            initialValues={initialState}
            onSubmit={handleUpdate}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {props => (
                <LinksConfigurationForm isLoading={isUpdating} {...props}/>
            )}
        </Formik>
    );
}
