import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { selectAttachmentObjectUrlById } from 'shared/components/attachments/store/selectors';
import { HeadwayLoader } from 'shared/components/loader/HeadwayLoader';
import { IAttachmentPreviewProps } from 'shared/components/attachments/model';
import { useUploadStyles } from './styles';
import clsx from 'clsx';

export const AttachmentPreviewImage = ({
    attachment,
    className,
}: IAttachmentPreviewProps) => {
    const classes = useUploadStyles();
    const objectUrl = useSelector(selectAttachmentObjectUrlById(attachment.id));

    if (!objectUrl) {
        return (
            <Box mt={3}>
                <HeadwayLoader/>
            </Box>
        );
    }

    return (
        <img
            className={clsx(classes.img, classes.previewImg, className)}
            src={objectUrl}
            alt={attachment.filename}
        />
    );
};
