import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { PaySettingsForm } from 'modules/settings/submodules/clients/paySettings/components/PaySettingsForm';
import { useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import { selectPaySettingsFormatted } from 'modules/settings/submodules/clients/paySettings/store/selectors';
import { selectIsPaySettingsLoading } from 'store/entities/clients/selectors/timeAndPaySelectors';

export const PaySettingsFormWrapper = () => {

    const paySettings = useSelector(selectPaySettingsFormatted);
    const isLoading = useSelector(selectIsPaySettingsLoading);

    const initialValues = {
        pay_group_code: paySettings?.prism_group_id || '',
        pay_group_description: paySettings?.prism_group_description || '',
        period_starts: paySettings?.pay_period_start_day || '',
        period_ends: paySettings?.pay_period_end_day || '',
        day_work_week_ends: paySettings?.week_end_on_day || '',
        pay_day: paySettings?.pay_day || '',
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const emptyOnSubmit = useCallback( () => {}, []);

    if (isLoading) {
        return (
            <Box ml={3}>
                <CircularProgress/>
            </Box>
        );
    }

    return (

        <Formik
            key="PaySettingsForm"
            initialValues={initialValues}
            onSubmit={emptyOnSubmit}
            validateOnBlur={false}
            enableReinitialize
        >
            {props => (
                <PaySettingsForm
                    {...props}
                />
            )}
        </Formik>
    );
};
