import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import {
    bulkDeleteSubAssignmentPreInitializeAction,
    bulkEditSubAssignmentAction,
    bulkEditSubAssignmentPreInitializeAction,
    initialLoadSubassignments,
    bulkDeleteSubAssignmentAction,
    editSubAssignmentAction,
    bulkEditSubAssignmentPreInitializeActionV2,
    bulkEditSubAssignmentActionV2, getBulkEditSubAssignmentPreInitializePdfReport,
} from 'modules/subassignmentManagement/store/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { backendDateFormat } from 'shared/models/Dates';
import { ItemsById } from 'shared/models/ItemsById';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { getSubassignments } from 'store/entities/configuration/configurationAction';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import { downloadFileSaga } from 'store/utils/sagas/downloadFileSaga';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { subassignmentsApi } from 'modules/subassignmentManagement/store/api';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';

import {
    IBulkDeleteSubassignmentsRequest,
    IBulkEditSubassignmentsRequest, IBulkEditSubassignmentsResponse, IDeleteSubassignmentRequestModel,
    IEditSubassignmentRequestModel, ISubAssignmentBulkDeletePayload,
    ISubAssignmentBulkEditPayload,
} from 'modules/subassignmentManagement/store/models';

import {
    setBulkEditSubassignmentStep, setEditSubassignmentId,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import moment from 'moment';

function generateSubassignmentsToEdit(
    clientId: string | null,
    payload: ISubAssignmentBulkEditPayload,
): IEditSubassignmentRequestModel[] {
    return payload.subassignmentsIds.map(subassignmentsId => {
        return {
            id: subassignmentsId,
            client_id: clientId || '',
            start_date: payload.startDate,
            end_date: payload.endDate || null,
            override_rate_value: payload.overrideRate.toString(),
            custom_field_value_ids: payload.customFieldValueIds,
            all_values_custom_field_ids: payload.allValuesCustomFieldIds,
            managers: payload.managers,
        };
    });
}

function* getSubassignmentPreEditReportSaga(
    { payload }: ReturnType<typeof bulkEditSubAssignmentPreInitializeAction.init>,
) {
    const clientId: string | null = yield select(selectCurrentClientId);
    const subassignments: IEditSubassignmentRequestModel[] = generateSubassignmentsToEdit(clientId, payload);
    const request: IBulkEditSubassignmentsRequest = {
        subassignments,
    };
    const response: IBulkEditSubassignmentsResponse = yield call(
        subassignmentsApi.querySubassignmentPreEditReport,
        request,
    );
    yield put(bulkEditSubAssignmentPreInitializeAction.success(response));
}

function* getSubassignmentPreEditReportWatcher() {
    yield takeLatest(
        bulkEditSubAssignmentPreInitializeAction.initType,
        withBackendErrorHandler(
            getSubassignmentPreEditReportSaga,
            bulkEditSubAssignmentPreInitializeAction.error,
            'Unable to get edit pre-initialization report',
        ),
    );
}

function generateSubassignmentsToDelete(
    clientId: string,
    payload: ISubAssignmentBulkDeletePayload,
): IDeleteSubassignmentRequestModel[] {
    return payload.subassignmentsIds.map(subassignmentsId => {
        return {
            id: subassignmentsId,
            client_id: clientId || '',
        } as IDeleteSubassignmentRequestModel;
    });
}

function* getSubassignmentPreDeleteReportSaga(
    { payload }: ReturnType<typeof bulkDeleteSubAssignmentPreInitializeAction.init>,
) {
    const clientId = yield select(selectCurrentClientId);
    const subassignments: IDeleteSubassignmentRequestModel[] = generateSubassignmentsToDelete(clientId, payload);
    const request: IBulkDeleteSubassignmentsRequest = {
        subassignments,
    };
    const response = yield call(subassignmentsApi.querySubassignmentPreDeleteReport, request);
    yield put(bulkDeleteSubAssignmentPreInitializeAction.success(response));
}

function* getSubassignmentPreDeleteReportWatcher() {
    yield takeLatest(
        bulkDeleteSubAssignmentPreInitializeAction.initType,
        withBackendErrorHandler(
            getSubassignmentPreDeleteReportSaga,
            bulkDeleteSubAssignmentPreInitializeAction.error,
            'Unable to get delete pre-initialization report',
        ),
    );
}

function* bulkEditSubassignmentsSaga(
    { payload }: ReturnType<typeof bulkEditSubAssignmentAction.init>,
) {
    const clientId: string | null = yield select(selectCurrentClientId);
    const subassignments: IEditSubassignmentRequestModel[] = generateSubassignmentsToEdit(clientId, payload);
    const response: IBulkEditSubassignmentsResponse = yield call(
        subassignmentsApi.bulkEditSubassignments,
        { subassignments },
    );
    yield put(bulkEditSubAssignmentAction.success(response));
    yield put(setBulkEditSubassignmentStep(BulkEditSubassignmentSteps.EditPostReport));
    yield put(initialLoadSubassignments());
}

function* bulkEditSubassignmentsWatcher() {
    yield takeLatest(
        bulkEditSubAssignmentAction.initType,
        withBackendErrorHandler(
            bulkEditSubassignmentsSaga,
            bulkEditSubAssignmentAction.error,
            'Unable to edit sub-assignments',
        ),
    );
}

function* bulkDeleteSubassignmentsSaga(
    { payload }: ReturnType<typeof bulkDeleteSubAssignmentAction.init>,
) {
    const clientId = yield select(selectCurrentClientId);
    const subassignments = generateSubassignmentsToDelete(clientId, payload);
    const response = yield call(subassignmentsApi.bulkDeleteSubassignments, { subassignments });
    yield put(bulkDeleteSubAssignmentAction.success(response));
    yield put(setBulkEditSubassignmentStep(BulkEditSubassignmentSteps.DeletePostReport));
    yield put(initialLoadSubassignments());
}

function* bulkDeleteSubassignmentsWatcher() {
    yield takeLatest(
        bulkDeleteSubAssignmentAction.initType,
        withBackendErrorHandler(
            bulkDeleteSubassignmentsSaga,
            bulkDeleteSubAssignmentAction.error,
            'Unable to delete sub-assignments',
        ),
    );
}

function* editSubassignmentsSaga(
    { payload }: ReturnType<typeof editSubAssignmentAction.init>,
) {
    const subassignmentsByIds: ItemsById<ISubassignment> = yield select(selectSubassignmentsByIds);
    const initialSubassignment = subassignmentsByIds[payload.id];

    const response: IBulkEditSubassignmentsResponse = yield call(
        subassignmentsApi.bulkEditSubassignments,
        { subassignments: [payload] },
    );
    const updateSubassignmentResult = response.subassignments.find((item: { id: string; }) => item.id === payload.id);

    if (!updateSubassignmentResult || updateSubassignmentResult?.failed_reason) {
        const title = updateSubassignmentResult?.failed_reason || 'Failed to get updated subassignment';
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title,
        }));
        // @ts-ignore
        yield put(editSubAssignmentAction.error(new Error(title)));
        return;
    }

    const updatedSubassignment: ISubassignment = {
        ...initialSubassignment,
        custom_field_value_ids: updateSubassignmentResult.new_custom_field_value_ids || [],
        all_values_custom_field_ids: updateSubassignmentResult.new_all_values_custom_field_ids || [],
        start_date: updateSubassignmentResult.new_start_date,
        end_date: updateSubassignmentResult.new_end_date,
        override_rate_value: updateSubassignmentResult.new_override_rate_value,
        updated_at: moment().format(backendDateFormat),
        managers: updateSubassignmentResult.new_managers,
    };
    yield put(getSubassignments.success([updatedSubassignment]));
    yield put(editSubAssignmentAction.success());
    yield put(setEditSubassignmentId(null));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Subassignment was successfully updated.',
    }));
}

function* editSubassignmentWatcher() {
    yield takeLatest(
        editSubAssignmentAction.initType,
        withBackendErrorHandler(
            editSubassignmentsSaga,
            editSubAssignmentAction.error,
            'Unable to edit sub-assignment',
        ),
    );
}

function* bulkEditSubassignmentsPreInitializeReportV2Saga(
    { payload }: ReturnType<typeof bulkEditSubAssignmentPreInitializeActionV2.init>,
) {
    const response = yield call(subassignmentsApi.bulkEditSubassignmentsQueryReportV2, payload);
    yield put(bulkEditSubAssignmentPreInitializeActionV2.success(response));
}

function* bulkEditSubassignmentPreInitializeReportV2Watcher() {
    yield takeLatest(
        bulkEditSubAssignmentPreInitializeActionV2.initType,
        withBackendErrorHandler(
            bulkEditSubassignmentsPreInitializeReportV2Saga,
            bulkEditSubAssignmentPreInitializeActionV2.error,
            'Unable to get pre-initialize report',
        ),
    );
}

function* getBulkEditSubassignmentsReportPdfSaga(
    { payload }: ReturnType<typeof getBulkEditSubAssignmentPreInitializePdfReport.init>,
) {
    const result = yield call(subassignmentsApi.bulkEditSubassignmentsQueryReportPdfV2, payload);
    yield call(downloadFileSaga, result, `Edit Report ${moment().format(`${backendDateFormat}-HH.mm.ss`)}.pdf`);
    yield put(getBulkEditSubAssignmentPreInitializePdfReport.success());
}

function* getBulkEditSubassignmentsReportPdfWatcher() {
    yield takeLatest(
        getBulkEditSubAssignmentPreInitializePdfReport.initType,
        withBackendErrorHandler(
            getBulkEditSubassignmentsReportPdfSaga,
            getBulkEditSubAssignmentPreInitializePdfReport.error,
            'Unable to download update report',
        ),
    );
}

function* bulkEditSubassignmentsV2Saga(
    { payload }: ReturnType<typeof bulkEditSubAssignmentActionV2.init>,
) {
    const response = yield call(subassignmentsApi.bulkEditSubassignmentsV2, payload);
    yield put(bulkEditSubAssignmentActionV2.success(response));
    yield put(initialLoadSubassignments());
}

function* bulkEditSubassignmentV2Watcher() {
    yield takeLatest(
        bulkEditSubAssignmentActionV2.initType,
        withBackendErrorHandler(
            bulkEditSubassignmentsV2Saga,
            bulkEditSubAssignmentActionV2.error,
            'Unable to edit sub-assignments',
        ),
    );
}

export const editSubassignmentsSagas = [
    getSubassignmentPreEditReportWatcher,
    getSubassignmentPreDeleteReportWatcher,
    bulkEditSubassignmentsWatcher,
    bulkDeleteSubassignmentsWatcher,
    editSubassignmentWatcher,
    bulkEditSubassignmentPreInitializeReportV2Watcher,
    getBulkEditSubassignmentsReportPdfWatcher,
    bulkEditSubassignmentV2Watcher,
];
