import { curry, groupBy, orderBy } from 'lodash-es';
import { ISheetGroupRow, SheetGroup } from 'modules/timeAndExpense/store/model';
import { createSelector } from 'reselect';
import { ItemsById } from 'shared/models/ItemsById';
import { IJobNumber } from 'shared/models/JobNumber';
import { EntryType, IEntry, ISheet } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { customFieldsValuesBySheetIdSelector } from 'shared/utils/hooks/customFieldsWithValuesHooks';
import { CheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { IUserSelfInfo } from 'store/components/auth/authModels';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsModel';
import { selectEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { IStore } from 'store/configureStore';
import {
    ApproversFromFields,
    ISheetGroupKey,
    ITimeAndPayClientConfiguration,
} from 'store/entities/clients/clientsModel';
import { selectClientApproverSheetGrouping } from 'store/entities/clients/selectors/configurationSelectors';
import { selectClientTimeAndPayConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { IAssignment, IManagerInfo, ISubassignment } from 'store/entities/configuration/configurationModel';
import {
    selectAssignmentsById,
    selectJobNumbersById,
    selectSubassignmentsByIds,
} from 'store/entities/configuration/configurationSelectors';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { sheetIsMissing } from 'store/entities/timesheet/helpers';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { SheetsEntryTypes, StatusNames } from 'store/entities/timesheet/models/Status';
import {
    selectAllEntries,
    selectCalculationsByTimesheetId,
    selectExpensesSheets,
    selectTimeSheets,
} from 'store/entities/timesheet/selectors';
import { selectUsersById } from 'store/entities/users/selectors';

export const selectTimeAndExpenseState = (state: IStore) => state.modules.timeAndExpense;
export const selectTimeAndExpenseActiveStatus = (state: IStore) => state.appConfig.sheetGridStatus;

function getFilteredSheetsByStatusAndType(
    sheets: Array<ISheet>,
    activeStatus: StatusNames,
    user: IUserSelfInfo,
    filters: Partial<IEmployeeSubmittedSheetsFilters>,
    assignmentsById: ItemsById<IAssignment>,
    customFieldsValuesBySheetId: Record<string, ICustomFieldValue[]>,
): Array<ISheet> {
    const filteredSheets = sheets
        .filter(sheet => sheet.user_id === user.id)
        .filter(
            sheet => activeStatus === StatusNames.ALL ? true : sheet.status?.name === activeStatus,
        );

    return isNotEmpty(filters) ? filteredSheets.filter(sheet => {
        const assignment = assignmentsById[sheet.assignment_id ?? ''];
        const sheetCustomFieldValues = customFieldsValuesBySheetId[sheet.id];
        const sheetValuesIds = sheetCustomFieldValues.map(value => value.id);

        if (assignment && filters.position_id && filters.position_id !== assignment.position_id) {
            return false;
        }

        if (assignment && filters.location_id && filters.location_id !== assignment.location_id) {
            return false;
        }

        if (filters.job_number_id && filters.job_number_id !== sheet.job_number_id) {
            return false;
        }

        const filterCustomFieldValues = Object.values(filters.customFieldValues || {});
        const notEmptyFilterCustomFieldValues = filterCustomFieldValues.filter(Boolean);
        if (notEmptyFilterCustomFieldValues.length > 0){
            if (notEmptyFilterCustomFieldValues.some(filterValue => {
                return !sheetValuesIds.includes(filterValue);
            })){
                return false;
            }
        }

        return true;
    }) : filteredSheets;
}

export const getApproversBySheetId = (
    sheets: ISheet[],
    assignmentsById: ItemsById<IAssignment>,
    subassignmentsById: ItemsById<ISubassignment>,
    jobNumbersById: ItemsById<IJobNumber>,
    timeAndPayConfiguration: ITimeAndPayClientConfiguration,
): Record<string, string[]> => {
    const approverFrom = timeAndPayConfiguration?.approversFrom || ApproversFromFields.FromAssignment;
    return sheets.reduce((mem, sheet) => {
        const assignment = assignmentsById[sheet.assignment_id || ''];
        const subassignment = subassignmentsById[sheet.subassignment_id || ''];
        const jobNumber = jobNumbersById[sheet.job_number_id || ''];
        const managers = orderBy(
            subassignment?.managers || assignment?.managers || [],
            manager => manager.manager_level,
        );
        const jobNumberManagers = jobNumber?.manager_id ? [jobNumber.manager_id] : [];
        let approverIds = approverFrom === ApproversFromFields.FromAssignment
            ? managers.map((manager: IManagerInfo) => manager.user_id)
            : jobNumberManagers;
        if (sheetIsMissing(sheet)) {
            approverIds = [];
        }
        return {
            ...mem,
            [sheet.id]: approverIds,
        };
    }, {} as Record<string, string[]>);
};

export const groupedSheetsSelector = (
    sheets: ISheet[],
    groupingKeys: Array<keyof ISheetGroupKey>,
    usersById: ItemsById<IUserInfo>,
    jobNumbersById: ItemsById<IJobNumber>,
    calculationsByTimesheetId: Record<string, ITimesheetCalculation>,
    approversBySheetId: Record<string, string[]>,
    entries: IEntry[],
    customFieldValuesByIds: ItemsById<ICustomFieldValue>,
): SheetGroup[] => {
    const groupingKeyEnhancer = (sheet: ISheet) => {
        return {
            'approvers': approversBySheetId[sheet.id],
        };
    };
    const getKey = curry(SheetGroup.getSheetGroupingKey)(groupingKeys, groupingKeyEnhancer);
    const rawGroups = groupBy(sheets, getKey);

    return Object.entries(rawGroups).map(([groupKey, groupSheets]) => new SheetGroup(
        groupKey,
        groupSheets,
        {
            jobNumbersById,
            usersById,
            calculationsByTimesheetId,
            approversBySheetId,
            entries,
            customFieldValuesByIds,
        },
    ));
};

export const getSheetRows = (
    sheets: Array<ISheet>,
    subassignmentsById: ItemsById<ISubassignment>,
    jobNumbersById: ItemsById<IJobNumber>,
    usersById: ItemsById<IUserInfo>,
    calculationsByTimesheetId: Record<string, ITimesheetCalculation>,
    timeAndPayConfiguration: ITimeAndPayClientConfiguration,
) => {
    const approverFrom = timeAndPayConfiguration?.approversFrom || ApproversFromFields.FromAssignment;
    return sheets.map(sheet => {
        const subassignment = subassignmentsById[sheet.subassignment_id || ''];
        const jobNumber = jobNumbersById[sheet.job_number_id || ''];
        const jobNumberManagers = jobNumber?.manager_id ? [jobNumber.manager_id] : [];
        const approverIds: string[] = approverFrom === ApproversFromFields.FromAssignment
            ? subassignment?.managers?.map((manager: IManagerInfo) => manager.user_id) ?? []
            : jobNumberManagers;
        const approvers = sheetIsMissing(sheet) ? []
            : approverIds.map(approverId => usersById[approverId]) || [];
        // Calculation required only for time sheets for define reg/ot/dt
        const calculation = calculationsByTimesheetId[sheet.id];
        const user = usersById[sheet.user_id || ''];
        return {
            id: sheet.id,
            sheet,
            approvers,
            jobNumber,
            user,
            calculation,
        };
    });
};

export const groupedSheetRowsSelector = (groups: SheetGroup[]): ISheetGroupRow[] => {
    return groups.map(group => ({ id: group.id, group }));
};

export const getCheckedSheets = (
    checkedSheets: CheckedItems,
    checkedGroups: CheckedItems,
    groups: SheetGroup[],
): string[] => {
    const checkedSheetIds = Object.entries(checkedSheets).reduce((mem: string[], [key, value]: [string, boolean]) => {
        if (value) {
            mem.push(key);
        }
        return mem;
    }, [] as string[]);
    const checkedGroupSheetIds = Object.entries(checkedGroups).reduce(
        (mem: string[], [key, value]: [string, boolean]) => {
            if (value) {
                const checkedGroup = groups.find(group => group.id === key);
                if (checkedGroup) {
                    return mem.concat(checkedGroup.sheets.map(sheet => sheet.id));
                }
            }
            return mem;
        },
        [] as string[],
    );
    return [
        ...checkedSheetIds,
        ...checkedGroupSheetIds,
    ];
};

const getTimeAndExpenseSheetsSelectors = (
    baseSheetSelector: (state: IStore) => ISheet[],
    selectCheckedSingleSheets: (state: IStore) => CheckedItems,
    selectCheckedGroupedSheets: (state: IStore) => CheckedItems,
) => {
    const selectFilteredSheets = createSelector(
        baseSheetSelector,
        selectTimeAndExpenseActiveStatus,
        selectCurrentUser,
        selectEmployeeSubmittedSheetsFilters,
        selectAssignmentsById,
        customFieldsValuesBySheetIdSelector,
        getFilteredSheetsByStatusAndType,
    );
    const selectTableRows = createSelector(
        selectFilteredSheets,
        selectSubassignmentsByIds,
        selectJobNumbersById,
        selectUsersById,
        selectCalculationsByTimesheetId,
        selectClientTimeAndPayConfiguration,
        getSheetRows,
    );
    const selectApproversBySheetId = createSelector(
        selectFilteredSheets,
        selectAssignmentsById,
        selectSubassignmentsByIds,
        selectJobNumbersById,
        selectClientTimeAndPayConfiguration,
        getApproversBySheetId,
    );
    const selectGroupedSheets = createSelector(
        selectFilteredSheets,
        selectClientApproverSheetGrouping,
        selectUsersById,
        selectJobNumbersById,
        selectCalculationsByTimesheetId,
        selectApproversBySheetId,
        selectAllEntries,
        selectCustomFieldValuesByIds,
        groupedSheetsSelector,
    );
    const selectGroupedSheetRows = createSelector(
        selectGroupedSheets,
        groupedSheetRowsSelector,
    );
    const selectCheckedSheets = createSelector(
        selectCheckedSingleSheets,
        selectCheckedGroupedSheets,
        selectGroupedSheets,
        getCheckedSheets,
    );
    return {
        selectFilteredSheets,
        selectTableRows,
        selectApproversBySheetId,
        selectGroupedSheets,
        selectGroupedSheetRows,
        selectCheckedSingleSheets,
        selectCheckedGroupedSheets,
        selectCheckedSheets,
    };
};

export const selectCheckedTimeSheets = (state: IStore) => selectTimeAndExpenseState(state).timeSheetsChecked;
export const selectCheckedExpenseSheets = (state: IStore) => selectTimeAndExpenseState(state).expenseSheetsChecked;
export const selectCheckedTimeSheetGroups = (state: IStore) => selectTimeAndExpenseState(state).timeSheetGroupsChecked;
export const selectCheckedExpenseSheetGroups = (state: IStore) =>
    selectTimeAndExpenseState(state).expenseSheetGroupsChecked;

export const timeSheetsSelectors = getTimeAndExpenseSheetsSelectors(
    selectTimeSheets,
    selectCheckedTimeSheets,
    selectCheckedTimeSheetGroups,
);
export const expenseSheetsSelectors = getTimeAndExpenseSheetsSelectors(
    selectExpensesSheets,
    selectCheckedExpenseSheets,
    selectCheckedExpenseSheetGroups,
);
export const selectCheckedSheetWithTypes = createSelector(
    timeSheetsSelectors.selectCheckedSheets,
    expenseSheetsSelectors.selectCheckedSheets,
    (timeChecked, expenseChecked): SheetsEntryTypes => {
        const getChecked = (entryType: EntryType) => (mem: SheetsEntryTypes, id: string) => {
            mem[id] = entryType;
            return mem;
        };
        const time = timeChecked.reduce(getChecked(EntryType.TIME), {});
        const expense = expenseChecked.reduce(getChecked(EntryType.EXPENSE), {});
        return {
            ...time,
            ...expense,
        };
    },
);
