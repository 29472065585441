import React from 'react';
import { useSelector } from 'react-redux';
import { ISubassignmentTableProps } from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import {
    selectExpandAllSubassignment,
    selectExpandedGroupId,
    selectSubassignmentsGroupedRows, subassignmentTableStateSelectors,
} from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import {
    selectEditSubassignment,
} from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/selectors';
import {
    SubassignmentVirtualInfinityTablePure,
} from 'modules/subassignmentManagement/components/SubassignmentTable/SubassignmentVirtualInfinityTablePure';
import { useCells } from 'modules/subassignmentManagement/components/SubassignmentTable/cells';

export const SubassignmentTable = (props: ISubassignmentTableProps) => {
    const groups = useSelector(selectSubassignmentsGroupedRows);
    const cells = useCells(groups);
    const selectedSubassignment = useSelector(selectEditSubassignment);
    const expandedGroupId = useSelector(selectExpandedGroupId);
    const expandAll = useSelector(selectExpandAllSubassignment);
    const totalCount = useSelector(subassignmentTableStateSelectors.selectTotalItems) || 0;

    const commonProps = {
        groups,
        cells,
        selectedSubassignment,
        expandedGroupId,
        expandAll,
        totalCount,
        ...props,
    };
    return (
        <SubassignmentVirtualInfinityTablePure
            {...commonProps}
        />
    );
};
