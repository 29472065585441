import { IScaZoneFull, TimeEntryData } from 'shared/models/sheet/Sheet';
import { ICommonEntryFormValues, defaultValues as commonDefaultValues } from './EntryCommonFields';

export interface ITimeEntryFormValues extends ICommonEntryFormValues {
    data: TimeEntryData | null;
    notes: string;
    completes?: number;
    scaZone: IScaZoneFull | null;
}

export const defaultValues: ITimeEntryFormValues = {
    ...commonDefaultValues,
    data: null,
    notes: '',
    completes: undefined,
    scaZone: null,
};
