import { useMemo } from 'react';
import { ICustomFieldValue, ISCACustomField } from 'store/entities/customFields/model';
import { sortBy, uniq } from 'lodash-es';

function getCountiesByState(customFieldValuesData: ISCACustomField[]) {
    return customFieldValuesData.reduce<Record<string, string[]>>((mem, item) => {
        const state = item.state as string;
        const counties = item.counties || [];
        if (!mem[state]) {
            return {
                ...mem,
                [state]: counties,
            };
        }
        return {
            ...mem,
            [state]: sortBy(uniq([...mem[state], ...counties]), value => value),
        };
    }, {});
}

export function useScaHelperData(customFieldValues: Array<ICustomFieldValue>) {
    return useMemo(() => {
        const customFieldValuesData = customFieldValues.map(item => item.data as ISCACustomField);
        const states = sortBy(uniq(customFieldValuesData.map(item => item.state as string)), item => item);
        const countiesByState = getCountiesByState(customFieldValuesData);
        return { states, countiesByState };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customFieldValues.length]);
}

export function scaFieldValueByStateAndCounty(
    state: string | null,
    county: string | null,
    customFieldValues: ICustomFieldValue[],
) {
    return (
        customFieldValues
            .filter(item => (item?.data as ISCACustomField)?.state === state)
            // @ts-ignore
            .filter(item => (item?.data as ISCACustomField)?.counties?.includes(county))
    );
}
