import { combineReducers } from 'redux';
import { travelExpenseFilter } from 'modules/travelExpenses/components/TravelExpenseFilter/reducer';
import { travelExpenseTable } from 'modules/travelExpenses/components/TravelExpensesTable/reducer';
import { deleteItemByIdReducer, isLoadingReducer, itemsByIdReducer } from 'store/reducerUtils';
import { deleteTravelExpense, getTravelExpenses, getTravelExpenseSheets } from 'modules/travelExpenses/actions';
import { IExpenseEntryBackend, ISheetCommonBackend } from 'shared/models/sheet/Sheet';
import { editTravelExpense } from 'modules/travelExpenses/components/Edit/store/reducer';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const travelExpensesById = extendReducer(
    itemsByIdReducer<IExpenseEntryBackend, IExpenseEntryBackend>(getTravelExpenses),
    deleteItemByIdReducer<IExpenseEntryBackend>(deleteTravelExpense),
);

const travelExpenseSheetsById = itemsByIdReducer<ISheetCommonBackend, ISheetCommonBackend>(getTravelExpenseSheets);

const travelExpensesAreLoading = isLoadingReducer(getTravelExpenses);

export const travelExpenses = combineReducers({
    travelExpenseFilter,
    travelExpenseTable,
    travelExpensesById,
    travelExpenseSheetsById,
    editTravelExpense,
    travelExpensesAreLoading,
});
