import { findUniqFilteredItems } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/findUniqFilteredItems';
import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { GroupRejectionCard } from 'modules/payrollProcessorHub/components/SheetStatusAction/GroupRejectionCard';

import { useRejectNotes } from 'modules/payrollProcessorHub/components/SheetStatusAction/hooks/useRejectNotes';
import { changeCalculationGroupStatus } from 'modules/payrollProcessorHub/store/actions';
import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { selectGroupedCalculationsRowsByIds } from 'modules/payrollProcessorHub/store/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { StatusNames } from 'store/entities/timesheet/models/Status';

import { RejectGroupsConfirmationFooter } from './RejectGroupsConfirmationFooter';

type IndexedRows = Record<string, IPayrollSheetGroupedRow>;
interface IRejectSheetGroupBatchProps {
    calculationGroups: ISheetCalculationBatch[];
    onCancel: () => void;
}

export const RejectSheetGroupBatch = ({
    calculationGroups,
    onCancel,
}: IRejectSheetGroupBatchProps) => {
    const dispatch = useDispatch();
    const rowsByIds = useSelector<any, IndexedRows>(selectGroupedCalculationsRowsByIds);
    const { notesById, onNoteChange } = useRejectNotes();
    const selectedRows = calculationGroups.map(group => rowsByIds[group.id]);
    const sidebarClasses = useSidebarStyles();

    const onReject = useCallback(() => {
        dispatch(changeCalculationGroupStatus.init({
            statusName: StatusNames.REJECTED,
            groups: calculationGroups.map(calculationGroup => ({
                note: notesById[calculationGroup.id],
                expenseSheetIds: findUniqFilteredItems(calculationGroup.expense_calculations, 'sheet_id') as string[],
                timeSheetIds: findUniqFilteredItems(calculationGroup.time_calculations, 'sheet_id') as string[],
                clientId: calculationGroup.client_id,
            })),
        }));
        onCancel();
    }, [calculationGroups, dispatch, notesById, onCancel]);

    return (
        <>
            <form>
                <section className={sidebarClasses.sidebarElement}>
                    {selectedRows.map(row => (
                        <GroupRejectionCard
                            key={row.id}
                            row={row}
                            note={notesById[row.id]}
                            onNoteChange={onNoteChange}
                        />
                    ))}
                </section>
            </form>
            <RejectGroupsConfirmationFooter
                onConfirmRejection={onReject}
                onCancel={onCancel}
            />
        </>
    );
};
