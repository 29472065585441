import React, { useCallback } from 'react';
import { Button, Box } from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useFilterContentStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import { ButtonWithModal } from 'shared/components/filters/FilterButtonAndForm/ButtonWithModal';
import { useModal } from 'shared/utils/hooks/useModal';
import { IStore } from 'store/configureStore';
import { ActionCreatorKnownArgs } from 'store/utils';

interface IStateFilterButtonWithFormProps<IFormValues> {
    children: React.ReactNode;
    filterSelector: (state: IStore) => IFormValues;
    setFilterAction: ActionCreatorKnownArgs<IFormValues, { type: string; payload: IFormValues }>;
}

export function StateFilterButtonWithForm<IFormValues>({
    children,
    filterSelector,
    setFilterAction,
}: IStateFilterButtonWithFormProps<IFormValues>) {
    const classes = useFilterContentStyles();
    const dispatch = useDispatch();

    const filter = useSelector(filterSelector);
    const { isModalOpened, onModalClose, onModalOpen } = useModal();
    const handleFilterSubmit = useCallback((values: IFormValues) => {
        dispatch(setFilterAction(values));
        onModalClose();
    }, [dispatch, setFilterAction, onModalClose]);

    return (
        <ButtonWithModal
            isOpened={isModalOpened}
            onClose={onModalClose}
            onOpen={onModalOpen}
        >
            <Formik
                initialValues={filter}
                onSubmit={handleFilterSubmit}
                validateOnBlur={false}
            >
                {props => (
                    // eslint-disable-next-line react/prop-types
                    <form onSubmit={props.handleSubmit}>
                        <Box
                            className={classes.paper}
                            display="flex"
                            flexDirection="column"
                        >
                            {children}
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                Apply
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </ButtonWithModal>
    );
}
