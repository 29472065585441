import { IScopeModifyRequest } from 'modules/settings/submodules/clients/scopes/store/models';
import baseApi from 'shared/utils/baseApi';
import { clientConfigurationsService } from 'store/entities/configuration/configurationApi';
import { IScope } from 'store/entities/scopes/models';

const scopesRoute = 'scopes';

export const manageScopesApi = {
    async createScope(requestPayload: IScopeModifyRequest): Promise<IScope> {
        const { data } = await baseApi.create<IScopeModifyRequest, IScope>(
            clientConfigurationsService,
            scopesRoute,
            requestPayload,
        );
        return data;
    },
    async updateScope(scopeId: string, requestPayload: IScopeModifyRequest): Promise<IScope> {
        const { data } = await baseApi.patch<IScopeModifyRequest, IScope>(
            `/${clientConfigurationsService}/${scopesRoute}/${scopeId}`,
            requestPayload,
        );
        return data;
    },
    async deleteScope(scopeId: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${clientConfigurationsService}/${scopesRoute}`, scopeId,
        );
        return data.status;
    },
};
