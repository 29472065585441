import { useSettingsFormStyles } from '../../../settings/submodules/components/sharedStyles/formStyles';
import React, { useCallback, useState } from 'react';
import { IAttachment } from 'shared/models/Attachments';
import { v4 as uuidv4 } from 'uuid';
import { Box, FormControl, Typography } from '@material-ui/core';
import FileInput from '../../../../shared/components/attachments/FileInput';
import ButtonWithLoader from '../../../../shared/components/buttons/ButtonWithLoader';

interface IBulkUploadingCcpTransactionFormProps {
    isLoading: boolean;
    onSubmit: (file: File) => void;
}

export const BulkUploadCcpTransactionsForm = ({
    isLoading,
    onSubmit,
}: IBulkUploadingCcpTransactionFormProps) => {
    const classes = useSettingsFormStyles();
    const [attachments, setAttachments] = useState<IAttachment[]>([]);

    const onAttachmentChange = useCallback((files: File[]) => {
        const file = files[0];
        if (file) {
            setAttachments([{
                id: uuidv4(),
                filename: file.name,
                url: '',
                mimetype: file.type,
                // @ts-ignore
                file,
            }]);
        }
    }, []);
    const onAttachmentRemove = useCallback(() => {
        setAttachments([]);
    }, []);
    const handleSubmit = useCallback(() => {
        // @ts-ignore
        onSubmit(attachments[0].file);
    }, [attachments, onSubmit]);

    return (
        <Box>
            <Typography>Upload the file (.CSV)</Typography>
            <Box width="50%" ml={-2}>
                <FormControl
                    className={classes.input}
                    variant="outlined"
                >
                    <FileInput
                        attachments={attachments}
                        onAttachmentChange={onAttachmentChange}
                        onAttachmentRemove={onAttachmentRemove}
                        multiple={false}
                        isLoading={isLoading}
                        maxSizeMb={15}
                        fileTypes=".csv"
                        rejectMessage={null}
                    />
                </FormControl>
            </Box>
            <Box
                mt={7}
                display="flex"
                justifyContent="space-between"
            >
                <ButtonWithLoader
                    variant="contained"
                    color="secondary"
                    disabled={isLoading || !attachments[0]}
                    isLoading={isLoading}
                    onClick={handleSubmit}
                >
                    Next
                </ButtonWithLoader>
            </Box>
        </Box>
    );
};
