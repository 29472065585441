import React from 'react';
import { useSelector } from 'react-redux';
import { IBackgroundCheckTemplate } from 'store/entities/configuration/configurationModel';
import {
    selectBackgroundCheckTemplates,
    selectIsBackgroundCheckTemplatesLoading,
} from 'store/entities/configuration/configurationSelectors';
import {
    TemplatesTable,
    TemplateTableEntityName,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/TemplatesTable';
import { removeBackgroundCheckTemplate } from 'store/entities/configuration/configurationAction';
import { setEditBackgroundCheckTemplateId } from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/action';

interface IBackgroundCheckTemplateRow extends IBackgroundCheckTemplate {
    className?: string;
}

export const BackgroundChecksTemplateTable = () => {
    const items = useSelector(selectBackgroundCheckTemplates) as IBackgroundCheckTemplateRow[];
    const isLoading = useSelector(selectIsBackgroundCheckTemplatesLoading);

    return (
        <TemplatesTable
            templates={items}
            isLoading={isLoading}
            deleteAction={removeBackgroundCheckTemplate.init}
            editAction={setEditBackgroundCheckTemplateId}
            entityName={TemplateTableEntityName.BackgroundChecks}
        />
    );
};
