import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { useMobileInfoStyles } from './MobileInfoStyles';
import clsx from 'clsx';

interface IMobileListCellProps {
    className?: string;
    listClassName?: string;
    title?: string;
    items: Array<string>;
    action?: () => void;
}

export default function MobileListCell({ className, title, items, listClassName, action }: IMobileListCellProps) {
    const initialClasses = useMobileInfoStyles();
    return (
        <div className={className} onClick={action ? action : undefined}>
            <List className={clsx(initialClasses.listWrapper, listClassName)}>
                {title && (
                    <ListItem className={initialClasses.listItem}>
                        <Typography className={initialClasses.userNameText} variant="subtitle2">{title}</Typography>
                    </ListItem>
                )}
                {items.map(item => (
                    <ListItem key={item} className={initialClasses.listItem}>
                        <Typography className={initialClasses.captionText} variant="caption">
                            {item}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}
