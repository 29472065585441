import { selectCurrentUserApprovalsLevelBySubassignmentId } from 'modules/sheetApproval/store/selectors';
import { getTimesheetCalculations } from 'store/entities/timesheet/actions/calculations';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import {
    addTimeEntry,
    loadTimeSheets,
    loadTimeSheetsWithEntries,
    patchEntriesDateSpecificBySheetId,
    removeTimeEntry, removeTimeSheet,
    setTimeSheetPatchingById,
    updateTemporaryTimeEntry,
    updateTimeEntry,
} from 'store/entities/timesheet/actions/timeActions';
import { ITimeEntryPatchEntriesDateSpecificBySheetIdRequest, timeApi } from 'store/entities/timesheet/api/timeApi';
import {
    createAddEntrySaga,
    createDeleteEntrySaga,
    createUpdateEntrySaga,
} from 'store/entities/timesheet/sagas/utils';
import { withErrorHandler } from 'store/utils/sagas/withErrorHandler';
import { EntryType, IStatus, ITimeSheetBackend } from 'shared/models/sheet/Sheet';
import { IUpdateSheetsStatus, IUpdateSheetStatus, StatusNames } from '../models/Status';
import { selectTimeEntriesByIds, selectTimeSheetsByIds, selectTimeSheetStatusByName } from 'store/entities/timesheet/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectClientApproversFrom } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { selectApproversCountBySheets } from 'store/entities/configuration/configurationSelectors';
import { setGlobalToast } from '../../appConfig/actions';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { isEmpty } from 'lodash-es';
import { tryApproveSheet } from 'store/entities/timesheet/helpers';

const addTimeEntrySaga = createAddEntrySaga(
    EntryType.TIME,
    timeApi.createEntry,
    updateTemporaryTimeEntry,
    addTimeEntry.success,
    loadTimeSheets.success,
    removeTimeSheet,
    removeTimeEntry.success,
);

function* addTimeEntryWatcher() {
    yield takeEvery(
        addTimeEntry.initType,
        withBackendErrorHandler(
            addTimeEntrySaga,
            addTimeEntry.error,
            'Cannot create time entry',
        ),
    );
}

const updateTimeEntrySaga = createUpdateEntrySaga(
    EntryType.TIME,
    timeApi.updateEntry,
    updateTimeEntry.success,
    selectTimeEntriesByIds,
    removeTimeSheet,
);

function* updateTimeEntryWatcher() {
    yield* takeEvery(
        updateTimeEntry.initType,
        withBackendErrorHandler(
            updateTimeEntrySaga,
            updateTimeEntry.error,
            'Cannot update time entry',
        ),
    );
}

const removeTimeEntrySaga = createDeleteEntrySaga(
    EntryType.TIME,
    timeApi.deleteEntry,
    removeTimeEntry.success,
    selectTimeEntriesByIds,
    removeTimeSheet,
);

function* removeTimeEntryWatcher() {
    yield* takeEvery(
        removeTimeEntry.initType,
        withBackendErrorHandler(
            removeTimeEntrySaga,
            removeTimeEntry.error,
            'Cannot delete time entry',
        ),
    );
}

function* loadTimeSheetsSaga({ payload }: ReturnType<typeof loadTimeSheetsWithEntries.init>) {
    const { purpose, request } = payload;
    const sheets = yield* call(timeApi.getSheetListByPurpose, purpose, request || {});
    yield* put(loadTimeSheetsWithEntries.success(sheets));
}

function* loadTimeSheetsWatcher() {
    yield takeEvery(loadTimeSheetsWithEntries.initType, withErrorHandler(
        loadTimeSheetsSaga,
        loadTimeSheetsWithEntries.error,
        'Time sheets were not loaded',
    ));
}

function* loadSimplifiedTimeSheetsSaga({ payload }: ReturnType<typeof loadTimeSheets.init>) {
    const { purpose, request } = payload;
    const sheets = yield* call(timeApi.getSimplifiedSheetListByPurpose, purpose, request || {});
    yield* put(loadTimeSheets.success(sheets));
}

function* loadSimplifiedTimeSheetsWatcher() {
    yield takeLatest(loadTimeSheets.initType, withErrorHandler(
        loadSimplifiedTimeSheetsSaga,
        loadTimeSheets.error,
        'Time sheets were not loaded',
    ));
}

function* patchEntriesDateSpecificBySheetIdSaga({
    payload: {
        sheetId,
        date,
        isPerDiem,
    },
}: ReturnType<typeof patchEntriesDateSpecificBySheetId.init>) {
    try {
        yield* put(setTimeSheetPatchingById({ sheetId, state: true }));
        const params: ITimeEntryPatchEntriesDateSpecificBySheetIdRequest = {
            is_per_diem: isPerDiem,
            entry_date: date,
        };
        const sheet = yield* call(timeApi.patchEntriesDateSpecificBySheetId, sheetId, params);
        yield* put(patchEntriesDateSpecificBySheetId.success([sheet]));
        yield* put(setTimeSheetPatchingById({ sheetId, state: false }));
    } catch (error) {
        yield put(setTimeSheetPatchingById({ sheetId, state: false }));
        const message = 'Unable to find users';
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: message,
            autoHideDuration: autoHideDefaultDuration * 2,
        }));
        logErrorWithCustomMessage(error, message);
    }
}

function* patchEntriesDateSpecificBySheetIdSagaWatcher() {
    yield* takeLatest(patchEntriesDateSpecificBySheetId.initType, patchEntriesDateSpecificBySheetIdSaga);
}

//the following function  is used to reject only since november 2020, approve is made with create approval api.
export function* updateTimeSheetsStatusesSaga(sheetsIds: string[],
    status: IStatus,
    notes?: Record<string, string>,
    californiaStatus?: boolean) {
    const payload: IUpdateSheetsStatus = {
        sheets: sheetsIds.map(id => {
            const sheet: IUpdateSheetStatus = {
                id,
                status_id: status.id,
            };

            if (notes){
                sheet.notes = notes[id];
            }

            if (californiaStatus === true || californiaStatus === false) {
                sheet.california_submission_attestation = californiaStatus;
            }

            return sheet;
        }),
    };
    const updatedSheets = yield* call(
        timeApi.updateSheetsStatuses,
        payload,
    );
    yield* put(loadTimeSheetsWithEntries.success(updatedSheets));
}

export function* createTimeSheetApprovalSaga(sheetsIds: string[]) {
    yield* call(
        timeApi.createSheetApprovals,
        sheetsIds,
    );

    const sheetsById = yield* select(selectTimeSheetsByIds);
    const clientId = yield* select(selectCurrentClientId);
    const currentUserApprovalLevelsBySubassignments = yield* select(selectCurrentUserApprovalsLevelBySubassignmentId);
    const approversFrom = yield* select(selectClientApproversFrom);
    const approversCountBySheets = yield* select(selectApproversCountBySheets(sheetsIds));
    const approvedStatus = yield* select(selectTimeSheetStatusByName(StatusNames.APPROVED));
    const updatedSheets = sheetsIds.map(sheetId => {
        const sheet: ITimeSheetBackend = {
            ...sheetsById[sheetId],
            entries: [],
        };
        return tryApproveSheet(
            sheet,
            approversFrom,
            currentUserApprovalLevelsBySubassignments,
            approversCountBySheets,
            clientId,
            approvedStatus,
        );
    });
    yield* put(loadTimeSheetsWithEntries.success(updatedSheets));
}

function* loadSheetsPayrollEntitiesForLinkedSaga({ payload }: ReturnType<typeof loadTimeSheets.success>) {
    const sheetIds = payload.map(sheet => sheet.id).filter(Boolean);
    if (!isEmpty(sheetIds)) {
        yield put(getTimesheetCalculations.init({
            sheet_ids: sheetIds,
        }));
    }
}

function* loadSheetsPayrollEntitiesForLinkedSagaWatcher() {
    yield takeEvery(
        [
            loadTimeSheets.successType,
            loadTimeSheetsWithEntries.successType,
        ],
        loadSheetsPayrollEntitiesForLinkedSaga,
    );
}

export default [
    addTimeEntryWatcher,
    updateTimeEntryWatcher,
    removeTimeEntryWatcher,
    loadTimeSheetsWatcher,
    loadSimplifiedTimeSheetsWatcher,
    patchEntriesDateSpecificBySheetIdSagaWatcher,
    loadSheetsPayrollEntitiesForLinkedSagaWatcher,
];
