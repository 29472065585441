import React, { useCallback, useState } from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import FormField from 'shared/components/formFields/FormField';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { AddressFormBlock } from 'modules/settings/submodules/components/AddressFormBlock/AddressFormBlock';
import { ISubmittingOrgFormValues } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgForm/model';
import { FormButtonBlock } from 'modules/settings/common/components/FormButtonBlock/FormButtonBlock';
import { SubmittingOrgLocationSelect } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgLocationSelect/SubmittingOrgLocationSelect';
import { ISubmittingOrgLocation } from 'shared/models/SubmittingOrgLocation';
import { useSelector } from 'react-redux';
import { BNumberStartAdornment } from 'shared/utils/formatters/bNumber';
import { selectSubmittingOrgLocationById } from 'store/entities/configuration/configurationSelectors';
import { ControllingOrgSelect } from 'modules/settings/submodules/clients/submittingOrg/components/ControllingOrgSelect/ControllingOrgSelect';
import { StatusSelect } from 'modules/settings/common/components/StatusSelect/StatusSelect';
import { GenworthBranchesSelect } from 'modules/settings/submodules/clients/submittingOrg/components/GenworthBranchesSelect/GenworthBranchesSelect';
import { LabelWithValue } from '../../../jobNumber/components/JobNumberForm/components/LabelWithValue';

export interface ISubmittingOrgFormProps extends FormikProps<ISubmittingOrgFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onCancel?: () => void;
}

export function SubmittingOrgForm({
    handleSubmit,
    setFieldValue,
    submitTitle,
    onCancel,
    values,
    isLoading = false,
    isDisabled = false,
}: ISubmittingOrgFormProps) {

    const classes = useSettingsFormStyles();
    const { location_id } = values;

    const locationFromStore = useSelector(selectSubmittingOrgLocationById(location_id));

    const [location, setLocation] = useState(locationFromStore as null | ISubmittingOrgLocation);

    const onLocationChanged = useCallback((newLocation: ISubmittingOrgLocation) => {
        setLocation(newLocation);
    }, [setLocation]);

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
            <Box className={classes.formBlockLine}>
                <Box width="60%">
                    <FormField
                        name="client_site_name"
                        label="Client site name"
                        className={classes.input}
                    />
                </Box>
                <Box width="40%" ml={2}>
                    <StatusSelect name="status"
                        label="--"
                        outerLabel="Status"
                        className={classes.input}/>

                </Box>
            </Box>

            <Box className={classes.formBlockLine}>
                <Box width="60%">
                    <ControllingOrgSelect name="controlling_org_id"
                        label="--"
                        outerLabel="Controlling org"
                        className={classes.input}/>
                </Box>
                <Box width="40%" ml={2}>
                    <FormField
                        name="submitting_org_number"
                        label="Submitting org #"
                        className={classes.input}
                        startAdornment={BNumberStartAdornment}
                    />
                </Box>
            </Box>

            <AddressFormBlock
                title={'Submitting Org Address'}
                setFieldValue={setFieldValue}
                hideAddress2
                hideCounty
            />

            <Box className={classes.formBlockLine}>
                <Box width="60%">
                    <SubmittingOrgLocationSelect name="location_id"
                        label="--"
                        outerLabel="Location"
                        onChanged={onLocationChanged}
                        className={classes.input}/>
                </Box>
                <Box width="40%">
                    <LabelWithValue
                        label="Region hub"
                        value={location?.hub || ''}
                        className={classes.labelWithValue}
                    />
                </Box>
            </Box>

            <Box className={classes.formBlockLine}>
                <Box width="60%">
                    <GenworthBranchesSelect name="branch_id"
                        label="--"
                        outerLabel="Genworth Branch"
                        className={classes.input}
                    />
                </Box>
            </Box>

            <FormButtonBlock submitTitle={submitTitle}
                onCancel={onCancel}
                isLoading={isLoading}
                isDisabled={isDisabled}
            />
        </form>
    );
}
