import React, { useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import { AssignmentInfoBlock } from '../../AssignmentInfo/AssignmentInfoBlock';
import { IInfoItemData } from '../../AssignmentInfo/EditableInfoBlock/model';
import { selectSubassignmentInfo } from './store/selectors';
import { setOpenPromotionDemotion } from '../../PromotionDemotion/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CustomFieldType } from 'store/entities/customFields/model';
import { setOpenCompensationChange } from '../../CompensationChange/store/actions';
import { SubassignmentInfoLabel } from './store/model';

export const SubassignmentDetails = () => {
    const subassignmentInfo = useSelector(selectSubassignmentInfo);
    const dispatch = useDispatch();

    const getHandleEdit = useCallback((info: IInfoItemData) => {
        if (info.additionalData?.customFieldType === CustomFieldType.Position) {
            return () => {
                dispatch(setOpenPromotionDemotion(true));
            };
        }
        if (info.label === SubassignmentInfoLabel.PayRate) {
            return () => {
                dispatch(setOpenCompensationChange(true));
            };
        }
        return null;
    }, [dispatch]);

    return (
        <Box mb={3}>
            <Grid container spacing={2}>
                {subassignmentInfo.map(infoBlock => (
                    <Grid
                        item
                        key={infoBlock.label}
                        sm={6}
                        md={4}
                    >
                        <AssignmentInfoBlock
                            {...infoBlock}
                            onEdit={getHandleEdit(infoBlock)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
