import { EafRejectionSidebar } from 'modules/eafApproval/components/Rejection/EafRejectionSidebar';
import { setEafRejectionIds } from 'modules/eafApproval/store/actions';
import { selectRejectionEafIds } from 'modules/eafApproval/store/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';

export const EafRejectionStateSidebar = separateLogicDecorator(() => {
    const dispatch = useDispatch();
    const eafIds = useSelector(selectRejectionEafIds);
    const onClose = useCallback(() => {
        dispatch(setEafRejectionIds([]));
    }, [dispatch]);

    return {
        isOpened: eafIds.length > 0,
        onClose,
        eafIds,
    };
})(EafRejectionSidebar);
