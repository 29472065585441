import { makeStyles } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useCellIconStyles = makeStyles(() => ({
    root: {
        padding: 0,
    },
}));

export const useCellSelectStyles = makeHighPriorityStyles(() => ({
    select: {
        minHeight: '23px',
    },
}));
