import { ActionsReturnTypes, createActions } from 'store/utils';
import { IZipCode } from 'store/entities/zipCode/model';

const GET_ZIP_CODES = 'GET_ZIP_CODES';
const GET_ZIP_CODES_SUCCESS = 'GET_ZIP_CODES_SUCCESS';
const GET_ZIP_CODES_ERROR = 'GET_ZIP_CODES_ERROR';

export const getZipCodes = createActions<string, Array<IZipCode>, any,
    typeof GET_ZIP_CODES, typeof GET_ZIP_CODES_SUCCESS, typeof GET_ZIP_CODES_ERROR
>(
    GET_ZIP_CODES, GET_ZIP_CODES_SUCCESS, GET_ZIP_CODES_ERROR,
);

export type ZipCodeAction = ActionsReturnTypes<typeof getZipCodes>;
