import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const useMobileInfoStyles = makeHighPriorityStyles((theme: Theme) => ({
    listWrapper: {
        padding: 0,
        width: '100%',
    },
    listItem: {
        padding: theme.spacing(0),
    },
    userNameText: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '24px',
    },
    captionText: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 'normal',
        color: theme.palette.text.primary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
