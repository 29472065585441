import { useReduxModal } from 'shared/utils/hooks/useModal';
import { selectIsCreateJobNumberModalOpen } from 'modules/settings/submodules/clients/jobNumber/components/CreateJobNumber/store/selectors';
import { setCreateJobNumberModalState } from 'modules/settings/submodules/clients/jobNumber/store/actions';
import CreateJobNumberModal
    from 'modules/settings/submodules/clients/jobNumber/components/CreateJobNumber/CreateJobNumberModal';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRedirectToJobNumberDeal } from 'modules/settings/submodules/clients/deals/components/DealForm/store/selectors';
import { setRedirectToJobNumberWithDealNumber } from 'modules/settings/submodules/clients/deals/components/DealForm/store/actions';
import { setEditDealId } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/actions';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export default function CreateDealJobNumberModal() {
    const classes = useColoredFormModalStyles();
    const {
        isModalOpened,
        closeModal,
    } = useReduxModal(selectIsCreateJobNumberModalOpen, setCreateJobNumberModalState);

    const dispatch = useDispatch();
    const redirectToJobNumberDeal = useSelector(selectRedirectToJobNumberDeal);
    const onCloseHandler = useCallback(() => {
        if (redirectToJobNumberDeal){
            dispatch(setEditDealId(redirectToJobNumberDeal?.id));
            dispatch(setRedirectToJobNumberWithDealNumber(null));
        }
        closeModal();
    }, [dispatch, redirectToJobNumberDeal, closeModal]);

    return (
        <CreateJobNumberModal
            title="Create New Job Number"
            isOpened={isModalOpened}
            onClose={onCloseHandler}
            customClasses={classes}
            deal={redirectToJobNumberDeal}
            showCloseIcon
        />
    );
}
