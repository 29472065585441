import { approvalTable } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/reducers';
import {
    setEditSheetGroup,
    setGroupedSheetsApprovalId,
    setGroupedSheetsCheckedItems,
    setGroupedSheetsRejectId,
    setSheetGroupedDetailId,
    setSheetGroupedSidebarId,
} from 'modules/sheetApproval/store/actions';
import { combineReducers } from 'redux';
import { singleValueReducer } from 'store/reducerUtils';

const checkedSheetGroups = singleValueReducer(setGroupedSheetsCheckedItems.action, {});
const detailSheetGroupId = singleValueReducer(setSheetGroupedDetailId.action, null);
const sidebarSheetGroupId = singleValueReducer(setSheetGroupedSidebarId.action, null);
const approveSheetGroupId = singleValueReducer(setGroupedSheetsApprovalId.action, null);
const rejectSheetGroupId = singleValueReducer(setGroupedSheetsRejectId.action, null);
const editSheetGroup = singleValueReducer(setEditSheetGroup.action, null);

export const sheetApproval = combineReducers({
    checkedSheetGroups,
    detailSheetGroupId,
    sidebarSheetGroupId,
    approveSheetGroupId,
    rejectSheetGroupId,
    approvalTable,
    editSheetGroup,
});
