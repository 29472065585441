import React, { MouseEvent, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useApprovalTableStyles } from '../../TableApprovalStyles';
import { IconButton, fade } from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { useHover } from '../hover';
import clsx from 'clsx';

interface IRejectProps {
    onClick: () => void;
}

export default function Reject({ onClick }: IRejectProps) {
    const initialClasses = useApprovalTableStyles();
    const { onMouseEnter, onMouseLeave } = useHover();

    const onHover = useCallback((event: MouseEvent) => {
        const rowId = event.currentTarget.closest('[role="row"]')?.id;
        onMouseEnter(fade(colors.red, 0.2), rowId ?? '');
    }, [onMouseEnter]);

    const onHoverRemove = useCallback((event: MouseEvent) => {
        const rowId = event.currentTarget.closest('[role="row"]')?.id;
        onMouseLeave(rowId ?? '');
    }, [onMouseLeave]);
    
    return (
        <IconButton onMouseEnter={onHover}
            onMouseLeave={onHoverRemove} onClick={onClick}>
            <CloseIcon
                className={clsx(initialClasses.actionIcon, initialClasses.rejectIcon)}
                onMouseEnter={onHover}
                onMouseLeave={onHoverRemove}>
            </CloseIcon>     
        </IconButton>
    );
}
