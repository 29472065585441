import Decimal from 'decimal.js';
import { IOdometerValue } from 'shared/utils/counters/odometer';
import { moneyToFormattedString } from 'shared/models/Money';
import { dollarCounter } from 'shared/utils/counters/dollarCounter';

export const calculateMiles = (odometer?: IOdometerValue | null) => {
    const miles = odometer ? odometer?.miles_end - odometer?.miles_start : 0;
    return miles > 0 ? miles : 0;
};
export const getFormattedOdometer = (odometer: IOdometerValue | null, mileageRate: Decimal) => {
    return moneyToFormattedString(mileageRate.times(calculateMiles(odometer)).toNumber());
};

export const getFormattedMiles = (miles: number, mileageRate: Decimal) => {
    return dollarCounter(Math.round(mileageRate.times(miles).times(100).toNumber()));
};

export const getOdometerFromValues = (odometer?: IOdometerValue) => {
    return odometer ? odometer?.miles_end - odometer?.miles_start : 0;
};
