import { selectCurrentPayPeriod } from 'modules/timeAndExpense/components/WorkingPage/store/selectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import UpperButton from 'shared/components/buttons/UpperButton';

import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSubmitSheetStyles } from 'shared/components/modals/SubmitSheets/SubmitSheetsStyles';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { useModal } from 'shared/utils/hooks/useModal';
import { useIsMobile } from 'shared/utils/hooks/media';
import { selectClientIsGovernmentContract } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonEntryFormValues } from '../../../../modules/timeAndExpense/components/AddEntry/store/selectors';
import { selectCustomFieldValuesByIds } from '../../../../store/entities/customFields/selectors';
import {
    selectDealById,
    selectSubmittingOrgById,
} from '../../../../store/entities/configuration/configurationSelectors';
import { CustomFieldType } from '../../../../store/entities/customFields/model';
import { useScopes } from '../../../../modules/timeAndExpense/store/hooks';
import { useFilteredActivities } from '../../forms/entries/helpers/commonEntryFormHelpers';
import { addedCaliforniaSubmissionAction } from '../../../../store/entities/timesheet/actions/californiaSubmission';

interface ISubmitSheetsProps {
    entryTypes?: Array<EntryType>;
    submitAction?: (entryTypes: Array<EntryType>) => void;
    status?: StatusNames;
    isPayroll?: boolean;
    isLoading?: boolean;
}

export default function SubmitSheets({
    entryTypes,
    submitAction,
    status,
    isPayroll = false,
    isLoading = false,
}: ISubmitSheetsProps) {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const classes = useSubmitSheetStyles();
    const entryGridClasses = useSheetsInProgressStyles();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();

    const payPeriod = useSelector(selectCurrentPayPeriod);
    const showGovernmentalConfirmation = useSelector(selectClientIsGovernmentContract);

    const [isAgreed, setAgreed] = useState(false);
    const [isCaliforniaAgreed, setCaliforniaAgreed] = useState<boolean | undefined>(undefined);
    const [isGovernmentalConfirmationAgreed, setGovernmentalConfirmationAgreed] = useState(false);
    const [time, setTime] = useState(false);
    const [expense, setExpense] = useState(false);

    const commonFields = useSelector(selectCommonEntryFormValues);
    const valuesByIds = useSelector(selectCustomFieldValuesByIds);
    const deal = useSelector(selectDealById(commonFields?.jobNumber?.deal_id));
    const submittingOrg = useSelector(selectSubmittingOrgById(deal?.submitting_org_id));
    const scopes = useScopes(commonFields.customFieldValues);
    const filteredActivities = useFilteredActivities(
        EntryType.TIME,
        commonFields.jobNumber,
        scopes,
    );

    useEffect(() => {
        setCaliforniaAgreed(undefined);
    }, [setCaliforniaAgreed, payPeriod]);

    const handleSetCalifornia = useCallback((newValue: boolean) => {
        setCaliforniaAgreed(newValue);
        dispatch(addedCaliforniaSubmissionAction({
            payPeriod: payPeriod,
            submissionState: newValue,
        }));
    }, [dispatch, setCaliforniaAgreed, payPeriod]);

    const shouldShowCalifornia = useMemo(() => {
        const CaliforniaStateCode = 'CA';
        if (!valuesByIds) {
            return false;
        }

        const locations = Object.values(valuesByIds)
            .map(valueById => {
                // @ts-ignore
                return valueById?.data;
            })
            .filter(valueData => valueData?.headway_connect_field === CustomFieldType.Location);
        if (locations.find(data => data.state_code === CaliforniaStateCode)
            || (submittingOrg?.state === CaliforniaStateCode)) {
            if (filteredActivities.find(x => x.sheet_type === EntryType.TIME
                && x.data_type === QuantityType.TIME_IN_OUT_MEAL_BREAK) && !isPayroll) {
                return true;
            }
        }
        return false;
    }, [filteredActivities, submittingOrg?.state, valuesByIds, isPayroll]);

    const onAgreedChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setAgreed(target.checked);
    }, [setAgreed]);

    const onGovernmentalConfirmationChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setGovernmentalConfirmationAgreed(target.checked);
    }, [setGovernmentalConfirmationAgreed]);

    const onTimeChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setTime(target.checked);
    }, [setTime]);

    const onExpenseChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setExpense(target.checked);
    }, [setExpense]);

    let title: string;
    let sheetsText: string;
    const actionText = status === StatusNames.REJECTED ? 'Resubmit' : 'Submit';
    const approveActionText = isPayroll ? '' : ' for Approval';

    if (entryTypes) {
        const titleParts = [];
        const sheetsParts = [];

        if (entryTypes.includes(EntryType.TIME)){
            // @ts-ignore
            titleParts.push('Timesheet');
            // @ts-ignore
            sheetsParts.push('timesheets');
        }
        if (entryTypes.includes(EntryType.EXPENSE)){
            // @ts-ignore
            titleParts.push('Expenses');
            // @ts-ignore
            sheetsParts.push('expense sheets');
        }
        title = `${actionText} ${titleParts.join(' & ')}${approveActionText}`;
        sheetsText = sheetsParts.join(' and ');
    } else {
        title = `${actionText} Timesheet & Expenses${approveActionText}`;
        sheetsText = 'timesheets and expense sheets';
    }

    const onSubmit = useCallback(() => {
        const entriesStatuses = { time, expense };
        const entryTypesLocal: Array<EntryType> = [EntryType.TIME, EntryType.EXPENSE]
            .filter(entry => entriesStatuses[entry]);

        submitAction && submitAction(entryTypesLocal);

    }, [time, expense, submitAction]);

    const onSubmitConfirm = useCallback(() => {
        onSubmit();
        onModalClose();
    }, [onSubmit, onModalClose]);

    const onActionButtonClick = useCallback(() => {
        if (isPayroll) {
            onSubmit();
        } else {
            onModalOpen();
        }
    }, [isPayroll, onSubmit, onModalOpen]);

    return (
        <>
            <UpperButton
                label={`${actionText}${!isMobile ? approveActionText : ''}`}
                onClick={onActionButtonClick}
                customClasses={{ upperButtonClass: entryGridClasses.actionButton }}
                disabled={!submitAction}
                isLoading={isLoading}
            />
            {isPayroll && (
                <Box ml={4}>
                    <WarningAlertWithIcon>
                        Please note that all sheet changes are saved immediately after entries update.
                    </WarningAlertWithIcon>
                </Box>
            )}
            <ModalDialog
                isOpened={isModalOpened}
                onClose={onModalClose}
                title={title}
                modalProps={{ customClasses: { content: classes.paper, title: classes.title } }}
                sidebarProps={{ anchor: 'bottom' }}
            >
                <Box ml={3} mr={3}>
                    <WarningAlertWithIcon>
                        Pay Period:
                        {' '}{formatShortDate(payPeriod?.period_start)} - {formatShortDate(payPeriod?.period_end)}
                    </WarningAlertWithIcon>
                </Box>
                <Box className={classes.content}>
                    <FormControlLabel
                        control={(
                            <Checkbox className={classes.warningCheckbox} color="primary"
                                onChange={onAgreedChange} checked={isAgreed}/>
                        )}
                        label="I certify that this report is true and accurately reflects time
                        and expenses actually worked or incurred."
                        classes={{
                            root: classes.warningWrapper,
                            label: clsx(classes.warningText, classes.typography),
                        }}
                    />
                    <Box mt={2}>
                        {showGovernmentalConfirmation && (
                            <FormControlLabel
                                control={(
                                    <Checkbox className={classes.warningCheckbox}
                                        color="primary"
                                        onChange={onGovernmentalConfirmationChange}
                                        checked={isGovernmentalConfirmationAgreed}/>
                                )}
                                label={`By signing this form, I understand that the expenses included herein are actual expenses
                            incurred during performance of the referenced contract and any misrepresentation may subject myself
                            to criminal penalties if I knowingly present a false, fictitious, or fraudulent travel claim under
                            18 U.S.C. 287 and 1001.`}
                                classes={{
                                    root: classes.warningWrapper,
                                    label: clsx(classes.warningText, classes.typography),
                                }}
                            />
                        ) }
                    </Box>

                    {!entryTypes && (
                        <>
                            <Typography
                                className={clsx(classes.typography, classes.warning)}
                                data-testid="modal-request-test-about-approve"
                            >
                                Please select the {sheetsText} you would like to submit for manager approval.
                            </Typography>
                            <Box display="flex" flexDirection="column"
                                mt={2} ml={4}
                            >
                                <FormControlLabel control={(
                                    <Checkbox color="primary" onChange={onTimeChange}
                                        checked={time}/>
                                )}
                                label="submit timesheet"/>

                                <FormControlLabel control={(
                                    <Checkbox color="primary" onChange={onExpenseChange}
                                        checked={expense}/>
                                )}
                                label="submit expenses"/>
                            </Box>
                        </>
                    )}
                    <Box mt={2}>
                        {shouldShowCalifornia && (
                            <>
                                <Typography className={clsx(classes.typography, classes.innerTitle)}>
                                    California Break Attestation:
                                </Typography>
                                <Typography className={clsx(classes.typography, classes.warning)}>
                                    I certify that I was authorized and permitted to take all the rest periods and
                                    was provided with all of the meal periods to which I was entitled in accordance with
                                    the Meal and Rest Period Policy (California Employee Handbook Addendum).
                                    I understand that if I was not permitted to take a required rest or meal period,
                                    I will reach out to my Headway representative, immediately.
                                </Typography>
                                <Box display="flex"
                                    flexDirection="column"
                                    mt={2} ml={4}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox color="primary" onChange={() => handleSetCalifornia(true)}
                                                checked={isCaliforniaAgreed === true}
                                            />
                                        )}
                                        label={`Yes`}
                                    />
                                    <FormControlLabel
                                        control={(
                                            <Checkbox color="primary"
                                                onChange={() => handleSetCalifornia(false)}
                                                checked={isCaliforniaAgreed === false}
                                            />
                                        )}
                                        label={`No`}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                    <Typography className={clsx(classes.typography, classes.warning)}>
                        Are you sure the sheets are complete for the week?
                    </Typography>
                    <Box mt={4}>
                        <UpperButton label="Submit" onClick={onSubmitConfirm}
                            disabled={!isAgreed
                            || !(entryTypes || time || expense )
                            || (showGovernmentalConfirmation && !isGovernmentalConfirmationAgreed)
                            || (shouldShowCalifornia && !(isCaliforniaAgreed === true || isCaliforniaAgreed === false))}
                            customClasses={{ upperButtonClass: entryGridClasses.actionButton }}
                        />
                        <Button onClick={onModalClose} color="primary"
                            className={clsx(entryGridClasses.secondaryButton, entryGridClasses.actionButton)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </ModalDialog>
        </>
    );
}
