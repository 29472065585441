import { groupBy } from 'lodash-es';
import {
    getSheetLogs,
    REMOVE_ADDED_ENTRY,
    SheetActions,
} from 'store/entities/timesheet/actions/sheetState';
import { EntryType, ISheetLog } from 'shared/models/sheet/Sheet';
import { addExpenseEntry, ExpenseSheetActions } from 'store/entities/timesheet/actions/expenseActions';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { addTimeEntry, TimeSheetActions } from 'store/entities/timesheet/actions/timeActions';
import { getClientPayPeriod, getSheetsPayPeriod } from 'store/entities/timesheet/actions/sheets';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';
import { ISheetCaliforniaSubmissionPeriod } from '../models/SheetApprovals';
import { addedCaliforniaSubmissionAction, CaliforniaSheetActions } from '../actions/californiaSubmission';

export type ILastAddedEntryState = {
    id: string;
    type: EntryType;
} | null;

const defaultState = {
    lastAddedEntry: null as ILastAddedEntryState,
    sheetLogsById: {},
    sheetCaliforniaSubmission: {} as ISheetCaliforniaSubmissionPeriod,
};

export function lastAddedEntry(
    state: ILastAddedEntryState = defaultState.lastAddedEntry,
    action: SheetActions | ExpenseSheetActions | TimeSheetActions,
): ILastAddedEntryState {
    switch (action.type) {
        case addExpenseEntry.successType:
            return {
                id: action.payload.id,
                type: EntryType.EXPENSE,
            };
        case addTimeEntry.successType:
            return {
                id: action.payload.id,
                type: EntryType.TIME,
            };
        case REMOVE_ADDED_ENTRY:
            return null;
        default:
            return state;
    }
}

export const payPeriods = extendReducer(
    singleValueReducer(getSheetsPayPeriod.successType, []),
    singleValueReducer(getClientPayPeriod.successType, []),
);
export const payPeriodsIsLoading = extendReducer(
    isLoadingReducer(getSheetsPayPeriod),
    isLoadingReducer(getClientPayPeriod),
);
export const isSheetStatusUpdating = isLoadingReducer(updateSheetsStatus);

export function sheetLogsById(
    state: Record<string, ISheetLog[]> = defaultState.sheetLogsById,
    action: SheetActions,
): Record<string, ISheetLog[]> {
    switch (action.type) {
        case getSheetLogs.successType: {
            return {
                ...state,
                ...groupBy(action.payload, item => item.sheetId),
            };
        }
        default:
            return state;
    }
}

export function sheetCaliforniaSubmission(
    state: ISheetCaliforniaSubmissionPeriod = defaultState.sheetCaliforniaSubmission,
    action: CaliforniaSheetActions,
): ISheetCaliforniaSubmissionPeriod {
    switch (action.type) {
        case addedCaliforniaSubmissionAction.action: {
            return action.payload;
        }
        default:
            return state;
    }
}

export const sheetsReducers = {
    lastAddedEntry,
    payPeriods,
    payPeriodsIsLoading,
    isSheetStatusUpdating,
    sheetLogsById,
    sheetCaliforniaSubmission,
};
