import clsx from 'clsx';
import React from 'react';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Box } from '@material-ui/core';

export interface IMainContentProps {
    children: React.ReactNode;
    whiteBackground?: boolean;
}

const useStyles = makeHighPriorityStyles(() => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box',
    },
    whiteBackground: {
        backgroundColor: colors.white,
    },
}));

export default function MainContainer({ children, whiteBackground = false }: IMainContentProps) {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.main, whiteBackground && classes.whiteBackground)}>
            {children}
        </Box>
    );
}
