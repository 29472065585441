import React from 'react';
import { useSelector } from 'react-redux';
import { EntityAutocompleteWithSearch } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/EntityAutocompleteWithSearch';
import { autocompleteSearchJobNumber } from 'shared/components/autocomplete/jobNumbers/actions';
import { IFormSelect } from 'shared/components/selects/model';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IJobNumber } from 'shared/models/JobNumber';
import { IJobNumberRequest } from 'store/entities/configuration/configurationModel';
import { selectJobNumbersById } from 'store/entities/configuration/configurationSelectors';

export interface IJobNumberAutocompleteWithSearchProps extends IFormFieldProps, IFormSelect {
    additionalFilter?: Partial<IJobNumberRequest>;
    initialSearch?: Partial<IJobNumberRequest>;
    minLettersSearch?: number;
    placeholder?: string;
}

export const JobNumberAutocompleteWithSearch = ({
    minLettersSearch = 0,
    ...props
}: IJobNumberAutocompleteWithSearchProps) => {
    const jobNumberByIds = useSelector(selectJobNumbersById);

    return (
        <EntityAutocompleteWithSearch
            {...props}
            inputType="number"
            entitiesByIds={jobNumberByIds}
            searchAction={autocompleteSearchJobNumber}
            getText={(jobNumber: IJobNumber) => jobNumber.job_number}
            minLettersSearch={minLettersSearch}
        />
    );
};
