import React, { useEffect } from 'react';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import { PaySettingsFormWrapper } from 'modules/settings/submodules/clients/paySettings/components/PaySettingsFormWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getPaySettings } from 'store/entities/clients/clientsAction';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { Box } from '@material-ui/core';
import { SyncPaySettingsButton } from 'modules/settings/submodules/clients/paySettings/components/SyncPaySettings/SyncPaySettingsButton';

export const PaySettingsPage = () => {
    const clientId = useSelector(selectCurrentClientId);
    const dispatch = useDispatch();
    useEffect(() => {
        if (clientId) {
            dispatch(getPaySettings.init(clientId));
        }
    }, [dispatch, clientId]);

    return (
        <>
            <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
            >
                <WarningAlertWithIcon>
                    Pay setting changes will apply to future timesheets only.
                </WarningAlertWithIcon>
                <Box>
                    <SyncPaySettingsButton/>
                </Box>
            </Box>
            <PaySettingsFormWrapper/>
        </>
    );
};
