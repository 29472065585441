import { uniq } from 'lodash-es';
import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';

export function getSheetCalculationBatchCalculations(batch?: ISheetCalculationBatch): Array<ICalculation> {
    const calculations: Array<ICalculation> = [
        ...(batch?.time_calculations || []),
        ...(batch?.expense_calculations || []),
    ];
    return calculations.filter(Boolean);
}

export function getSheetCalculationBatchTimeSheetIds(batch?: ISheetCalculationBatch): Array<string> {
    const ids = batch?.time_calculations?.map(item => item.sheet_id) || [];
    return uniq(ids);
}

export function getSheetCalculationBatchExpenseSheetIds(batch?: ISheetCalculationBatch): Array<string> {
    const ids = batch?.expense_calculations?.map(item => item.sheet_id) || [];
    return uniq(ids);
}

export function getSheetCalculationBatchPayPeriodEnds(batch?: ISheetCalculationBatch): Array<string> {
    return (
        getSheetCalculationBatchCalculations(batch)
            .map(i => i.pay_period_ends_at)
            .filter(Boolean)
    ) as Array<string>;
}
