import React from 'react';
import { PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { UserProfileTab } from 'modules/profile/store/profileModel';
import { IMenuContext, TMenuState } from 'shared/models/Global';
import { StatusNames } from 'store/entities/timesheet/models/Status';

const menuContext: IMenuContext = {
    menuState: {
        currentPage: '',
        currentHeader: '',
        currentMobileHeader: '',
        tabs: {
            timeEntryPage: StatusNames.WORKING,
            payroll: PayrollSheetTabs.APPROVED,
            userProfile: UserProfileTab.Profile,
        },
    },
    // Placeholder function that will be set later in order
    // to avoid Typescript requiring checking its presence
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setMenuState: (o: TMenuState) => void o,
};

export const MenuContext = React.createContext(menuContext);
