import { IGetClientPayCodesRequest, IPayCode } from 'modules/settings/submodules/clients/payCodes/store/model';
import baseApi from 'shared/utils/baseApi';
import {
    IClientStatuses,
    IClientStatusesBackend, ICreateActivityRequest,
    ICreateClientRequest,
    ICreatePositionWorkLocationAssociationRequest,
    ICreateWorkLocationRequest,
    IPositionWorkLocationAssociation,
    IUpdateClientRequest,
    IUpdateWorkLocationRequest,
} from 'modules/settings/submodules/clients/store/models';
import { convertFromBackendToLocation, ILocation, ILocationBackend } from 'shared/models/Location';
import { IUpdateLogoRequest, IUpdateLogoResponse } from 'modules/settings/submodules/clients/components/EditClientLogo/store/models';
import { IClientInfo } from 'store/entities/clients/clientsModel';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { withClientId } from 'store/utils/withClientId';

const clientUsersServiceUrl = 'client-users';
const clientConfigurationsServiceUrl = 'client-configurations';
const payrollServiceUrl = 'payroll';

export const api = {
    async createClient(client: ICreateClientRequest): Promise<IClientInfo> {
        const { data } = await baseApi.create<ICreateClientRequest, IClientInfo>(
            'client-configurations',
            'coordinator/clients',
            client,
        );

        return data;
    },
    async updateClient(clientId: string, client: IUpdateClientRequest): Promise<IClientInfo> {
        const { data } = await baseApi.patch<IUpdateClientRequest, IClientInfo>(
            `${clientUsersServiceUrl}/clients/${clientId}`,
            client,
        );

        return data;
    },
    async getClientStatuses(): Promise<IClientStatuses[]> {
        const { data } = await baseApi.get<IClientStatusesBackend>(
            `${clientUsersServiceUrl}/client_statuses`,
        );

        return data?.client_statuses;
    },
    async getPayCodes(request: IGetClientPayCodesRequest = {}): Promise<IPayCode[]> {
        const { data } = await baseApi.get<{ pay_codes: IPayCode[] }>(
            `${payrollServiceUrl}/client_pay_codes`, withClientId(request),
        );

        return data?.pay_codes;
    },
    async createClientWorkLocation(location: ICreateWorkLocationRequest): Promise<ILocation> {
        const { data } = await baseApi.create<ICreateWorkLocationRequest, ILocation>(
            clientUsersServiceUrl,
            'locations',
            location,
        );

        return data;
    },
    async updateClientWorkLocation(locationId: string, location: IUpdateWorkLocationRequest): Promise<ILocation> {
        const { data } = await baseApi.patch<IUpdateWorkLocationRequest, ILocation>(
            `${clientUsersServiceUrl}/locations/${locationId}`,
            location,
        );

        return data;
    },
    async deleteClientWorkLocation(locationId: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${clientUsersServiceUrl}/locations`,
            locationId,
        );

        return data.status;
    },
    async getPositionLocationAssociations(): Promise<IPositionWorkLocationAssociation[]> {
        const { data } = await baseApi.get<{ positions_locations: IPositionWorkLocationAssociation[]}>(
            `${clientUsersServiceUrl}/positions-locations`,
            withClientId({}),
        );

        return data.positions_locations;
    },
    async createPositionLocationAssociation(
        association: ICreatePositionWorkLocationAssociationRequest,
    ): Promise<IPositionWorkLocationAssociation> {
        const { data } = await baseApi.create<
        ICreatePositionWorkLocationAssociationRequest, IPositionWorkLocationAssociation>(
            clientUsersServiceUrl,
            'positions-locations',
            association,
        );

        return data;
    },
    async deletePositionLocationAssociation(associationId: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${clientUsersServiceUrl}/positions-locations`,
            associationId,
        );

        return data.status;
    },
    async createActivity(
        activity: ICreateActivityRequest,
    ): Promise<IActivity> {
        const { data } = await baseApi.create<ICreateActivityRequest, IActivity>(
            clientConfigurationsServiceUrl,
            'activities',
            activity,
        );

        return data;
    },
    async deleteActivity(activityId: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${clientConfigurationsServiceUrl}/activities`,
            activityId,
        );

        return data.status;
    },
    async syncWorkLocations(): Promise<ILocation[]> {
        const { data } = await baseApi.post<{}, { locations: Array<ILocationBackend> }>(
            `${clientUsersServiceUrl}/locations/sync_with_prism?client_id=${baseApi.clientId}`, {},
        );
        return data.locations.map((location: ILocationBackend) => convertFromBackendToLocation(location));
    },
    async updateClientLogo(clientId: string, request: IUpdateLogoRequest): Promise<IUpdateLogoResponse> {
        // Send request with file form data
        const icon = await fetch(request.icon).then(data => data.blob());
        const formData = new FormData();
        formData.append('icon', icon);
        const { data } = await baseApi.put<FormData, IUpdateLogoResponse>(
            `${clientConfigurationsServiceUrl}/clients/${clientId}/icon`,
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            },
        );
        return data;
    },
};
