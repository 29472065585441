import { OverrideWorkGeoCode } from 'modules/settings/submodules/clients/store/models';
import { IEntity } from 'shared/models/Entity';

export interface ILocationBackend extends IEntity {
    name: string;
    positions: IEntity[];
    deleted: boolean;
    deleted_at: string | null;
    geo_code: string;
    client_id: string;
    prism_location_id: string;
    address: string;
    city: string;
    county: string;
    state_code: string;
    zip_code: string;
    override_work_geo_code: OverrideWorkGeoCode;
}

export interface ILocation extends IEntity {
    name: string;
    positions?: string[];
    deleted: boolean;
    deleted_at: string | null;
    geo_code: string;
    client_id: string;
    prism_location_id: string;
    address: string;
    address2?: string;
    city: string;
    county: string;
    state_code: string;
    zip_code: string;
    override_work_geo_code: OverrideWorkGeoCode;
}

export const convertFromBackendToLocation = (location: ILocationBackend): ILocation => ({
    ...location,
    positions: location?.positions?.map(position => position.id),
});
