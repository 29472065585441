import { createSingleAction } from 'store/utils';
import { IManagerSubmittedSheetsFilter } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsModel';

export const SET_MANAGER_SUBMITTED_SHEETS_FILTERS = 'SET_MANAGER_SUBMITTED_SHEETS_FILTERS';

export const setManagerSubmittedSheetsFilters = createSingleAction<
Partial<IManagerSubmittedSheetsFilter>,
    typeof SET_MANAGER_SUBMITTED_SHEETS_FILTERS
>(
    SET_MANAGER_SUBMITTED_SHEETS_FILTERS,
);
