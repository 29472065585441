import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    sidebarTitle: {
        borderBottom: `1px solid ${colors.darkGray}`,
        backgroundColor: `${colors.white} !important`,
        color: `${colors.black} !important`,
        height: theme.spacing(8),
        justifyContent: 'flex-start !important',
        '& h5': {
            padding: 0,
            fontSize: 20,
            textTransform: 'uppercase',
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    readAllButton: {
        alignSelf: 'flex-end',
        fontSize: 12,
        textDecoration: 'underline',
        textTransform: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    notificationContainer: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${colors.lightGray}`,
        cursor: 'pointer',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '&:hover a': {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
        },
        '& a:hover': {
            textDecoration: 'none',
        },
    },
    notificationTitle: {
        display: 'inline',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: theme.typography.fontWeightBold,
    },
    notificationBody: {
        display: 'block',
        fontSize: 12,
        lineHeight: '14px',
        fontWeight: theme.typography.fontWeightRegular,
    },
    notificationTime: {
        display: 'block',
        fontSize: 11,
        lineHeight: '12px',
        fontWeight: theme.typography.fontWeightMedium,
    },
    unreadBadgeRoot: {
        display: 'block',
        width: '100%',
        paddingRight: theme.spacing(2),
        boxSizing: 'border-box',
    },
    unreadBadge: {
        backgroundColor: colors.blue,
        top: theme.spacing(1),
    },
}));
