import { IStore } from 'store/configureStore';
import { selectUserById } from 'store/entities/users/selectors';

export const selectCreateSidebarOpened = (state: IStore) => state.modules.offerLetter.offerCreate.createSidebarOpened;
export const selectCreatedOffer = (state: IStore) => state.modules.offerLetter.offerCreate.createdOffer;
export const selectOfferIsCreating = (state: IStore) => state.modules.offerLetter.offerCreate.isOfferCreating;
export const selectOfferUser = (state: IStore) => {
    const userRaw = state.modules.offerLetter.offerCreate.selectedCombinedUser;
    const userId = state.modules.offerLetter.offerCreate.selectedUser;
    return userRaw ? userRaw : (userId ? selectUserById(userId)(state) : userId);
};
