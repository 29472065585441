import { IStore } from 'store/configureStore';
import { selectControllingOrgState } from 'modules/settings/submodules/clients/controllingOrg/store/selectors';

const selectEditControllingOrgState = (state: IStore) => {
    const controllingOrgState = selectControllingOrgState(state);
    return controllingOrgState.editControllingOrg;
};
export const selectEditControllingOrgId = (state: IStore) => {
    const editControllingOrgState = selectEditControllingOrgState(state);
    return editControllingOrgState.controllingOrgId;
};
export const selectIsControllingOrgUpdating = (state: IStore) => {
    const editControllingOrgState = selectEditControllingOrgState(state);
    return editControllingOrgState.isUpdating;
};
