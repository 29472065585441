import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useButtonStyles = makeHighPriorityStyles(() => ({
    redOutlined: {
        color: colors.red,
        borderColor: colors.red,

        '&:hover': {
            color: colors.white,
            backgroundColor: colors.red,
            borderColor: colors.red,
        },
    },
    link: {
        color: colors.blue,
        textDecoration: 'underline',

        '&:hover': {
            textDecoration: 'none',
        },
    },
}));
