import React from 'react';
import { Time12HoursKeyboardInput } from '../../inputs/Time12HoursKeyboardInput/Time12HoursKeyboardInput';
import { TextFieldProps } from '@material-ui/core/TextField';

function InOutTimeFieldInput({
    label,
    name,
    value,
    className,
    inputProps,
    onChange,
    onFocus,
    onBlur,
    error,
    ...rest
}: TextFieldProps) {

    return (
        <Time12HoursKeyboardInput
            {...rest}
            name={name}
            label={label}
            value={value}
            variant="outlined"
            className={className}
            inputProps={{
                step: 60,
                ...inputProps,
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            error={error}
        />
    );
}

export default InOutTimeFieldInput;
