import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    modalTitle: {
        backgroundColor: theme.palette.primary.main,
        textAlign: 'left',
        color: colors.white,
        paddingRight: theme.spacing(6),
        '& button': {
            color: 'inherit',
        },
        '& p': {
            fontSize: theme.typography.pxToRem(20),
            width: '100%',
        },
        '& > h4': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
        },
    },
    modalPaper: {
        width: '70%',
        maxWidth: `${theme.spacing(96)}px !important`,
    },
    sidebarTitle: {
        backgroundColor: colors.white,
        color: colors.blackSecondary,
        borderBottom: `1px solid ${colors.black}`,
        '& h5': {
            textTransform: 'none',
            textAlign: 'left',
            padding: 0,
            width: '100%',
        },
    },
    input: {
        width: '100%',
        maxWidth: theme.spacing(55),
        '& select': {
            height: theme.spacing(7),
            fontSize: theme.typography.pxToRem(16),
        },
    },
    form: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
        '& + &': {
            marginLeft: theme.spacing(2),
        },
    },
}));
