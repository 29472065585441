import React from 'react';
import { CcpTotalsBlock } from './CcpTotalsBlock';
import { useSelector } from 'react-redux';
import { selectCcpSplitAmountTotal } from './store/selectors';

export const CcpTotalsBlockWrapper = () => {
    const currentTotal = useSelector(selectCcpSplitAmountTotal);
    return (
        <CcpTotalsBlock
            balance={currentTotal}
        />
    );
};
