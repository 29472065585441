import React, { FC } from 'react';
import { Paper } from '@material-ui/core';

import useAddEntryControlsStyles from './AddEntryControlsStyles';

interface IAddEntryControlsProps {
    children?: any;
    customClasses?: string;
}

const AddEntryControls: FC<IAddEntryControlsProps> = ({ children, customClasses }: IAddEntryControlsProps) => {
    const classes = useAddEntryControlsStyles();
    return (
        <Paper square classes={{ root: `${classes.paper} ${customClasses}` }}>
            {children}
        </Paper>
    );
};

export default AddEntryControls;
