import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import ActionButton from 'shared/components/buttons/ActionButton';
import { setEafRejectionIds } from 'modules/eafApproval/store/actions';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useApprovalTableStyles } from 'shared/components/table/TableApprovalStyles';
import { useIsUserHasApprovePermissionByEafId } from 'modules/eafApproval/store/hooks';

export interface IRejectEafButtonProps {
    eafIds: string[];
    compact?: boolean;
    isLoading?: boolean
}

export const RejectEafButton = ({ eafIds, compact = false, isLoading = false }: IRejectEafButtonProps) => {
    const dispatch = useDispatch();
    const handleOpenEafConfirmation = useCallback(() => {
        dispatch(setEafRejectionIds(eafIds));
    }, [dispatch, eafIds]);

    const userHasPermission = useIsUserHasApprovePermissionByEafId(eafIds[0]);
    const initialClasses = useApprovalTableStyles();

    if (!userHasPermission) {
        return null;
    }

    if (compact) {
        return (
            <IconButton onClick={handleOpenEafConfirmation}>
                <CloseIcon className={clsx(initialClasses.actionIcon, initialClasses.rejectIcon)}/>
            </IconButton>
        );
    }

    return (
        <ActionButton
            variant="outlined"
            color="primary"
            customType="danger"
            onClick={handleOpenEafConfirmation}
            isLoading={isLoading}
            disabled={isLoading}
        >
            Reject
        </ActionButton>
    );
};
