import { appInsightLogger } from 'shared/utils/logging/appInsight';
import { sentryLogger } from 'shared/utils/logging/sentry';
import { consoleLogger } from './consoleLogger';

export interface ILogger {
    critical: (error: any, params?: any) => void;
    error: (error: any, params?: any) => void;
    warning: (error: Error, params?: any) => void;
    info: (error: any, params?: any) => void;
    setUserId: (userId: string | undefined) => void;
}

export interface ICustomError {
    error: Error;
    customMessage: string;
}

class Logger implements ILogger {
    loggers: ILogger[] = [];

    constructor(loggers: ILogger[]) {
        this.loggers = loggers;
    }

    critical(error: any, params?: any) {
        this.loggers.forEach(providerLogger => providerLogger.critical(error, params));
    }
    error(error: Error, params?: any) {
        this.loggers.forEach(providerLogger => providerLogger.error(error, params));
    }
    warning(error: any, params?: any) {
        this.loggers.forEach(providerLogger => providerLogger.warning(error, params));
    }
    info(error: any, params?: any) {
        this.loggers.forEach(providerLogger => providerLogger.info(error, params));
    }
    setUserId(userId: string | undefined) {
        this.loggers.forEach(providerLogger => providerLogger.setUserId(userId));
    }
}

export const logger = new Logger([
    appInsightLogger,
    sentryLogger,
    ...(process.env.NODE_ENV === 'development' ? [consoleLogger] : []),
]);

export function logErrorWithCustomMessage(error: Error, customMessage = '') {
    error.message = (customMessage + ' ' + error.message).trim();
    logger.error(error, { error, customMessage });
}
