/* eslint-disable react/prop-types */
import clsx from 'clsx';
import { usePositionsWithAvailablePayRange } from 'modules/settings/submodules/clients/payRanges/hooks/usePositionsWithAvailablePayRange';
import React, { useCallback, useRef } from 'react';
import { Formik } from 'formik';
import { LocationFieldStateSelect } from 'modules/settings/submodules/clients/payRanges/components/payRangesFilter/LocationFieldStateSelect';
import { useFilterContentStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import FilterSVG from 'shared/components/icons/FilterSVG';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Popover } from '@material-ui/core';
import { selectPayRangesFilter } from 'modules/settings/submodules/clients/payRanges/store/selectors';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { useModal } from 'shared/utils/hooks/useModal';
import useActionButtonStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import { setPayRangesFilter } from 'modules/settings/submodules/clients/payRanges/store/actions';
import { IPayRangeFilter } from 'modules/settings/submodules/clients/payRanges/store/models';
import { selectAssignmentCustomFieldMapping } from 'store/entities/configuration/configurationSelectors';

export default function PayRangesFilter() {
    const dispatch = useDispatch();

    const actualFilter = useSelector(selectPayRangesFilter);
    const customFieldMapping = useSelector(selectAssignmentCustomFieldMapping);

    const classes = useFilterContentStyles();
    const buttonClasses = useActionButtonStyles();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();
    const buttonRef = useRef(null);

    const applyFilter = useCallback((values: IPayRangeFilter) => {
        dispatch(setPayRangesFilter(values));
        onModalClose();
    }, [dispatch, onModalClose]);

    const payRangePositions = usePositionsWithAvailablePayRange();

    return (
        <>
            <Button
                className={buttonClasses.default}
                ref={buttonRef}
                onClick={onModalOpen}
            >
                <FilterSVG/>
            </Button>
            <Popover
                open={isModalOpened}
                onClose={onModalClose}
                anchorEl={buttonRef.current}
            >
                <Formik
                    initialValues={actualFilter}
                    onSubmit={applyFilter}
                    validateOnBlur={false}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <Box
                                className={classes.paper}
                                display="flex"
                                flexDirection="column"
                            >
                                <CustomFieldValuesSelect
                                    customFieldId={customFieldMapping.location_value_id}
                                    name="locationValueId"
                                    useIdValue
                                    useLabel
                                    className={clsx(classes.selectField, classes.field)}
                                />
                                <LocationFieldStateSelect
                                    customFieldId={customFieldMapping.location_value_id}
                                    locationId={props.values.locationValueId}
                                    name="stateCode"
                                    useIdValue
                                    className={clsx(classes.selectField, classes.field)}
                                />
                                <CustomFieldValuesSelect
                                    customFieldId={customFieldMapping.position_value_id}
                                    name="positionValueId"
                                    useIdValue
                                    useLabel
                                    className={clsx(classes.selectField, classes.field)}
                                    valuesToShow={payRangePositions}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Apply
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Popover>
        </>
    );
}
