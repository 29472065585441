import React from 'react';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { IFormFieldProps } from 'shared/components/formFields/models';
import MoneyDataField from './MoneyDataField';
import OdometerDataField from './OdometerDataField';
import MilesDataField from 'shared/components/formSpecialFields/expenseData/MilesDataField';

interface IExpenseDataField extends IFormFieldProps {
    assignmentId?: string;
    activity?: IActivity | null;
    inputProps?: any;
}

export default function ExpenseDataField({
    label,
    activity,
    inputProps,
    ...props
}: IExpenseDataField) {
    let dataField: JSX.Element;

    switch (activity?.data_type) {
        case QuantityType.ODOMETER:
            dataField = (
                <OdometerDataField
                    label={label}
                    {...inputProps}
                    {...props}
                />
            );
            break;
        case QuantityType.MILES:
            dataField = (
                <MilesDataField
                    label="Miles"
                    {...inputProps}
                    {...props}
                />
            );
            break;
        default:
            dataField = (
                <MoneyDataField
                    {...inputProps}
                    {...props}
                    label="$"
                />
            );
            break;
    }

    return dataField;
}
