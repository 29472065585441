import { selectIsSidebarOpen } from 'store/entities/appConfig/appConfigSelectors';
import { setSidebarOpenedAction } from 'store/entities/appConfig/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { isSmallScreenBreakpoint } from 'shared/styles/constants';

export function useSidebarState() {
    const isSmallScreen = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(isSmallScreenBreakpoint), { noSsr: true },
    );

    const isSidebarOpened = useSelector(selectIsSidebarOpen);

    const dispatch = useDispatch();
    const setSidebarOpened = useCallback((isSidebarOpenedNewState: boolean) => {
        dispatch(setSidebarOpenedAction(isSidebarOpenedNewState));
    }, [dispatch]);

    useEffect(() => {
        if (isSmallScreen) {
            setSidebarOpened(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isSidebarOpened,
        setSidebarOpened,
    };
}
