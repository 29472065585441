import { IZohoPermalinkFormValues } from 'modules/settings/submodules/clients/reports/zoho/model';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientConfiguration } from 'store/entities/clients/clientsAction';
import {
    selectCurrentClientId,
    selectIsClientConfigurationUpdating,

} from 'store/entities/clients/selectors/clientsSelectors';
import { selectZohoAnalyticsPermalink } from 'store/entities/clients/selectors/configurationSelectors';

export function useZohoAnalyticsSetupFormHelper() {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const zohoAnalyticsPermalink = useSelector(selectZohoAnalyticsPermalink);
    const isUpdating = useSelector(selectIsClientConfigurationUpdating);
    const initialState = useMemo(() => ({
        zohoAnalyticsPermalink,
    }), [zohoAnalyticsPermalink]);

    const handleUpdatePermalink = useCallback((values: IZohoPermalinkFormValues) => {
        dispatch(updateClientConfiguration.init({
            clientId,
            zoho_analytics_dashboard_permalink: values.zohoAnalyticsPermalink,
        }));
    }, [clientId, dispatch]);

    return {
        zohoAnalyticsPermalink,
        isUpdating,
        initialState,
        handleUpdatePermalink,
    };
}
