import React from 'react';
import { Formik, FormikProps } from 'formik';
import { IOfferLetterTemplateEditForm } from 'modules/settings/submodules/offerLetters/model';
import { EditOfferLetterModalForm } from './EditOfferLetterModalForm';
import { useOfferLetterTemplateUpdateHandler } from '../../hooks/useOfferLetterTemplateUpdateHandler';
import { useOfferLetterTemplateEditInitialState } from '../../hooks/useOfferLetterTemplateEditInitialState';
import { useOfferLetterTemplateUpdateValidation } from '../../hooks/useOfferLetterTemplateUpdateValidation';

interface IEditOfferLetterModalBodyProps {
    closeDialog: () => void;
    offerLetterTemplateId: string | null;
}

export const EditOfferLetterModalBody = ({
    closeDialog,
    offerLetterTemplateId,
}: IEditOfferLetterModalBodyProps) => {
    const initialState = useOfferLetterTemplateEditInitialState(offerLetterTemplateId);
    const handleSubmit = useOfferLetterTemplateUpdateHandler(offerLetterTemplateId, closeDialog);
    const validation = useOfferLetterTemplateUpdateValidation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialState}
            onSubmit={handleSubmit}
            {...validation}
        >
            {(formikProps: FormikProps<IOfferLetterTemplateEditForm>) => (
                <EditOfferLetterModalForm closeDialog={closeDialog} {...formikProps}/>
            )}
        </Formik>
    );
};
