/* eslint-disable react/display-name */
import React from 'react';
import { SheetGroup } from 'modules/timeAndExpense/store/model';
import { TotalBlock } from 'shared/components/sidebars/SheetDetail/TotalBlock';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';

interface ISheetGroupTotalBlockProps {
    group: SheetGroup;
    entries: IEntry[];
    entryType: EntryType;
}

export function SheetGroupTotalBlock({
    group,
    entries,
    entryType,
}: ISheetGroupTotalBlockProps) {
    return (
        <TotalBlock
            entryType={entryType}
            entries={entries}
            totalReg={group.regularHours}
            totalOvertime={group.overtimeHours}
            totalDoubletime={group.doubletimeHours}
            totalHolidays={group.holidayHours}
            totalPto={group.ptoHours}
            isGrouped
        />
    );
}
