import React from 'react';
import { ListItem, Typography, Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { CheckPermissionAndFeature } from 'shared/components/CheckPermissionAndFeature/CheckPermissionAndFeature';
import { useStyles } from './styles';
import { IDashboardMenuItem } from 'modules/settings/submodules/components/DashboardLink/model';

export const DashboardLink = ({ title, description, link, onClick, ...checkAccessProps }: IDashboardMenuItem) => {
    const classes = useStyles();

    return (
        <CheckPermissionAndFeature {...checkAccessProps}>
            <ListItem
                button
                component={link ? NavLink : Box}
                className={classes.link}
                onClick={onClick}
                to={link}
            >
                <Typography className={classes.heading}>{title}</Typography>
                <Typography variant="body2">{description}</Typography>
            </ListItem>
        </CheckPermissionAndFeature>
    );
};
