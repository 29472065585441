import React from 'react';
import { Box } from '@material-ui/core';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import useFilterAndActionControlsStyles
    from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import Total, { TotalTypes } from 'shared/components/toolbar/total/Total';
import { sheetsTotalBreakTime, sheetsTotalTimeInMinutes } from 'shared/utils/counters/timeCounter';
import { sheetsTotalDollars } from 'shared/utils/counters/dollarCounter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import {
    selectEmployeeSubmittedSheetsFilters,
    selectEmployeeSubmittedSheetsFiltersConfiguration,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { setEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import SheetFilterButton from 'shared/components/filters/FilterButtonAndForm/SheetFilterButton';

interface IMobileFiltersAndAmountProps {
    sheets: Array<ISheet>;
    sheetType: EntryType;
    showFilters?: boolean;
}

export default function MobileFiltersAndAmount(
    { sheetType, sheets, showFilters }: IMobileFiltersAndAmountProps,
) {
    const classes = useFilterAndActionControlsStyles();

    const totalHours = formatMinutes(sheetsTotalTimeInMinutes(sheets));
    const totalBreaks = sheetsTotalBreakTime(sheets);
    const totalAmount = sheetsTotalDollars(sheets);

    return (
        <Box className={classes.mobileRoot}>
            {(showFilters ?? true) && (
                <SheetFilterButton
                    overrideClassName={classes.actionButton}
                    selectFilters={selectEmployeeSubmittedSheetsFilters}
                    selectFiltersConfig={selectEmployeeSubmittedSheetsFiltersConfiguration}
                    submitFilters={setEmployeeSubmittedSheetsFilters}
                />
            )}
            {sheetType === EntryType.TIME ? (
                <Box className={classes.mobileTotalRoot}>
                    <Total value={totalHours} type={TotalTypes.Time}/>
                    {totalBreaks > 0 && (
                        <Total
                            value={formatMinutes(totalBreaks)}
                            type={TotalTypes.Break}
                            classes={{ root: classes.mobileTotal }}
                        />
                    )}
                </Box>
            ) : (
                <Total value={totalAmount.toString()} type={TotalTypes.Expense}/>
            )}
        </Box>
    );
}
