import React, { useCallback, useEffect, useMemo } from 'react';

import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import PlainText from 'shared/components/table/Cells/PlainText';

import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useIsMobile } from 'shared/utils/hooks/media';
import {
    clearUsersAndFilter,
    getMoreUsersActions,
} from 'modules/settings/submodules/platformUsers/store/actions';
import {
    selectUserTableHasMoreUsers,
    selectUsers, selectUsersLoading,
} from 'modules/settings/submodules/platformUsers/store/selectors';
import { IUserRow, useUserCellDictionary } from 'modules/settings/submodules/components/SharedTableRows/UsersRows';
import { useStyles } from 'modules/settings/submodules/platformUsers/components/UserListPage/UsersTable/styles';
import { PlatformUsersPaginationCounter } from 'modules/settings/submodules/platformUsers/components/UserListPage/UsersTable/PlatformUsersPaginationCounter';
import { EditUserButton } from 'modules/settings/submodules/platformUsers/components/EditUser/EditUserButton';
import { UpdateUserStatus } from 'modules/settings/submodules/platformUsers/components/UserListPage/UsersTable/UpdateUserStatus';
import { LoginAsUserButton } from 'modules/settings/common/components/LoginAsUserButton';

export const UsersTable = () => {
    const tableClasses = useDefaultTableStyles();
    const users = useSelector(selectUsers);
    const isLoading = useSelector(selectUsersLoading);
    const hasMoreRows = useSelector(selectUserTableHasMoreUsers);
    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearUsersAndFilter());
        dispatch(getMoreUsersActions.init());
    }, [dispatch]);

    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows){
            dispatch(getMoreUsersActions.init());
        }
    }, [dispatch, hasMoreRows]);

    const isMobile = useIsMobile();

    const userCellsDictionary = useUserCellDictionary();

    const userCells = useMemo(() => [
        userCellsDictionary.id,
        userCellsDictionary.name,
        userCellsDictionary.email,
        {
            key: 'jobTitle',
            title: 'Job Title',
            render: function JobTitleCell({ job_title, className }: IUserRow) {
                return (
                    <PlainText className={className} value={job_title}/>
                );
            },
        },
        {
            key: 'role',
            title: 'Role',
            render: function RoleCell({ global_roles, className }: IUserRow) {
                return (
                    <PlainText className={className} value={`${global_roles.map(r => r.name).join(', ')}` || '--'}/>
                );
            },
        },
        userCellsDictionary.created,
        userCellsDictionary.updated,
        {
            key: 'status',
            title: 'status',
            render: function StatusCell({ id, status, className }: IUserRow) {
                return (
                    <Box className={className}>
                        <UpdateUserStatus
                            id={id}
                            status={status}
                            className={tableClasses.statusSelect}
                        />
                    </Box>
                );
            },
        },
        userCellsDictionary.state,
        {
            key: 'actions',
            title: '',
            width: '54px',
            render: function ActionCell({ className, id }: IUserRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <LoginAsUserButton userId={id}/>
                        <EditUserButton userId={id}/>
                    </div>
                );
            },
        },
    ], [tableClasses, userCellsDictionary]);

    return (
        <div className={classes.usersTable}>
            <InfinityGridTable
                rowData={users}
                getKey={row => row.id}
                cells={userCells}
                isLoading={isLoading}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                hideHeader={isMobile}
                stickyHeader
                onLoadMore={onLoadMoreRows}
                hasMore={hasMoreRows}
            />
            <PlatformUsersPaginationCounter/>
        </div>
    );
};
