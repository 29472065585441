import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { exportCustomFieldValues, queryCustomFieldValues } from 'store/entities/customFields/actions';
import {
    selectCustomField,
    selectCustomFieldPrismField,
    selectExplicitSyncCustomFieldType, selectIsLoadingExportCustomFieldValues,
    selectIsReadonlyCustomFieldType,
} from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { getProjectClasses, setCustomFieldId } from 'modules/settings/submodules/clients/customFieldValues/store/actions';
import { CustomFieldValuesTable } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuesTable/CustomFieldValuesTable';
import { CreateCustomFieldValueButton } from 'modules/settings/submodules/clients/customFieldValues/components/CreateCustomFieldValue/CreateCustomFieldValueButton';
import { EditCustomFieldValueModal } from 'modules/settings/submodules/clients/customFieldValues/components/EditCustomFieldValue/EditCustomFieldValueModal';
import { SyncCustomFieldValueButton } from '../SyncCustomFieldValue/SyncCustomFieldValueButton';
import { selectCurrentClient, selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import ButtonWithLoader from '../../../../../../../shared/components/buttons/ButtonWithLoader';
import useActionButtonStyles
    from '../../../../../../clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import { Print } from '@material-ui/icons';
import { useStyles } from '../../../styles';

export const CustomFieldValuePage = () => {
    const buttonClasses = useActionButtonStyles();
    const classes = useStyles();
    const { customFieldId } = useParams<{ customFieldId: string }>();
    const currentClientId = useSelector(selectCurrentClientId);
    const client = useSelector(selectCurrentClient);
    const customField = useSelector(selectCustomField);
    const isPrintLoading = useSelector(selectIsLoadingExportCustomFieldValues);
    const dispatch = useDispatch();
    const onPrintClick = useCallback(() => {
        dispatch(exportCustomFieldValues.init({
            client_id: client?.id,
            custom_field_id: customField?.id,
            filename: `${customField?.name}_${client?.name}.xlsx`,
        }));
    }, [dispatch, client, customField]);

    useWithClientEffect((dispatchWithClient, clientId) => {
        dispatchWithClient(setCustomFieldId(customFieldId));
        dispatchWithClient(queryCustomFieldValues.init({
            client_id: clientId, custom_field_id: customFieldId,
        }));
        dispatchWithClient(getProjectClasses.init());
    }, [customFieldId]);
    const isReadOnlyType = useSelector(selectIsReadonlyCustomFieldType);
    const isExplicitlySyncType = useSelector(selectExplicitSyncCustomFieldType);
    const prismFieldName = useSelector(selectCustomFieldPrismField);

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                className={classes.controls}
            >
                <Box>
                    {!isReadOnlyType && (
                        <Box mb={2}>
                            <CreateCustomFieldValueButton/>
                        </Box>
                    )}
                    {isExplicitlySyncType && prismFieldName && (
                        <Box mb={2}>
                            <SyncCustomFieldValueButton
                                clientId={currentClientId}
                                fieldPrismId={prismFieldName ?? ''}
                                fieldId={customFieldId ?? ''}
                            />
                        </Box>
                    )}
                </Box>
                <Box>
                    <ButtonWithLoader
                        classes={{ root: buttonClasses.default }}
                        onClick={() => onPrintClick() }
                        disabled={isPrintLoading}
                        isLoading={isPrintLoading}
                    >
                        <Print/>
                    </ButtonWithLoader>
                </Box>
            </Box>
            <CustomFieldValuesTable/>
            <EditCustomFieldValueModal/>
        </>
    );
};
