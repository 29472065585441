import {
    DeleteClientButton,
} from 'modules/settings/submodules/clients/components/DeleteClientButton/DeleteClientButton';
import { useEditClientFormStyles } from 'modules/settings/submodules/clients/components/EditClient/styles';
import { selectIsClientUpdating } from 'modules/settings/submodules/clients/selectors';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormWrapper from 'modules/settings/submodules/clients/components/ClientEditForm/FormWrapper';
import { IClientData } from 'modules/settings/submodules/clients/store/models';
import { updateClient } from 'modules/settings/submodules/clients/store/actions';
import { FormMode } from 'shared/models/FormMode';
import { browserHistory } from 'shared/utils/browserHistory';
import { selectClientById, selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export const ClientEditPage = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const client = useSelector(selectClientById(clientId || ''));
    const isLoading = useSelector(selectIsClientUpdating);
    const onCancel = useCallback(() => {
        browserHistory.goBack();
    }, []);
    const formClasses = {
        ...useEditClientFormStyles(),
    };

    const onSave = useCallback((values: IClientData) => {
        if (clientId) {
            dispatch(updateClient.init({ client_id: clientId, ...values }));
        }
    }, [dispatch, clientId]);

    return (
        <>
            {clientId && (
                <FormWrapper
                    client={client}
                    onCancel={onCancel}
                    onSubmit={onSave}
                    submitTitle={'Update'}
                    mode={FormMode.Edit}
                    isLoading={isLoading}
                    customClasses={formClasses}
                    // @ts-ignore
                    additionalButtons={<DeleteClientButton clientId={clientId}/>}
                />
            )}
        </>
    );
};
