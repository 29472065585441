import React from 'react';
import { useIsEmployeeSheetGroupingEnabled } from 'modules/timeAndExpense/store/hooks';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import { ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { SheetsSubmittedGroupedView } from 'modules/timeAndExpense/components/SheetsSubmittedView/SheetsSubmittedGroupedView';
import { SheetsSubmittedSingleSheetView } from 'modules/timeAndExpense/components/SheetsSubmittedView/SheetsSubmittedSingleSheetView';

interface ISheetsSubmittedViewProps {
    availableActions?: (props: IAvailableActionsProps) => JSX.Element;
    onDetailsClick?: (sheetInfo: ISheetClickInfo) => void;
    showFilters?: boolean,
}

export default function SheetsSubmittedView(props: ISheetsSubmittedViewProps) {
    const enableGrouping = useIsEmployeeSheetGroupingEnabled();

    return enableGrouping ? (
        <SheetsSubmittedGroupedView {...props}/>
    ) : (
        <SheetsSubmittedSingleSheetView {...props}/>
    );
}
