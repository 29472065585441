import React from 'react';
import {
    FormControlLabel,
    Checkbox, FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';
import { IFormFieldProps as IBasicFormFieldProps } from './models';

const CheckboxField = ({
    name,
    label,
    id = name,
    className,
    children,
    disabled = false,
}: ICheckboxFieldProps) => {
    const [field, meta, helper] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        helper.setValue(event.target.checked);
    };

    const errorMessage = meta.touched && meta.error;

    return (
        <>
            <FormControlLabel
                id={id}
                className={className}
                control={(
                    <Checkbox
                        {...field}
                        color="primary"
                        checked={field.value}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                )}
                label={label || children}
            />
            {errorMessage && (
                <FormHelperText error>{errorMessage}</FormHelperText>
            )}
        </>
    );
};

export default CheckboxField;

export interface ICheckboxFieldProps extends IBasicFormFieldProps {
    name: string;
    label?: string;
    children?: React.ReactNode;
}
