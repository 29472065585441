import React, { useEffect } from 'react';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EmployeeSheetDetails } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/EmployeeSheetDetails';
import { EntryType } from 'shared/models/sheet/Sheet';
import { browserHistory } from 'shared/utils/browserHistory';
import { setSheetGridStatus } from 'store/entities/appConfig/actions';
import { selectTypedSheet } from 'store/entities/timesheet/selectors';

export default function EmployeeSheetDetailPage() {
    const { sheetId, sheetType } = useParams<{ sheetId: string; sheetType: EntryType }>();
    const sheet = useSelector(selectTypedSheet(sheetId, sheetType));

    const onModalClose = () => {
        browserHistory.push('../..');
    };

    const dispatch = useDispatch();
    const activeStatus = useSelector(selectTimeAndExpenseActiveStatus);
    useEffect(() => {
        if (sheet?.status?.name && sheet?.status?.name !== activeStatus) {
            dispatch(setSheetGridStatus(sheet?.status?.name));
        }
    }, [sheet, activeStatus, dispatch]);

    return (
        <EmployeeSheetDetails
            isOpened
            onClose={onModalClose}
            sheetId={sheetId}
            entryType={sheetType}
        />
    );
}
