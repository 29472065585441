import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { IAssignmentsMetaDataFormValue } from 'modules/settings/submodules/clients/assignmentsReportData/components/AssignmentsMetaDataForm/model';
import { validationSchema } from 'modules/settings/submodules/clients/assignmentsReportData/components/AssignmentsMetaDataForm/validationSchema';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import FormField from 'shared/components/formFields/FormField';
import { IAssignmentReportData } from 'modules/settings/submodules/clients/assignmentsReportData/store/model';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { UserType } from 'store/entities/users/model';

interface IAssignmentsMetaDataFormProps {
    onSubmit: (values: IAssignmentsMetaDataFormValue) => void;
    isLoading: boolean;
    selectUser?: boolean;
    reportMetaData?: IAssignmentReportData;
}

export const AssignmentsMetaDataForm = ({
    onSubmit,
    isLoading,
    reportMetaData,
    selectUser = false,
}: IAssignmentsMetaDataFormProps) => {
    const classes = useSettingsFormStyles();
    const clientId = useSelector(selectCurrentClientId);

    const formikData = useFormik<IAssignmentsMetaDataFormValue>({
        initialValues: {
            uwNumber: reportMetaData?.uw_number || '',
            ssoId: reportMetaData?.sso_id || '',
            userId: reportMetaData?.user_id || null,
        },
        validationSchema,
        onSubmit,
    });
    const { handleSubmit } = formikData;

    return (
        <FormikProvider value={formikData}>
            <form onSubmit={handleSubmit}>
                {selectUser && (
                    <UserAutocompleteWithSearch
                        additionalFilter={{
                            user_type: UserType.Employee,
                            client_id: clientId || undefined,
                        }}
                        name="userId"
                        outerLabel="Employee"
                        useIdValue
                        className={classes.input}
                    />
                )}
                <FormField
                    name="uwNumber"
                    label="UW Number"
                    type="number"
                    className={classes.input}
                />
                <FormField
                    name="ssoId"
                    label="SSO ID"
                    type="number"
                    className={classes.input}
                />
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    isLoading={isLoading}
                    data-testid="submit_button"
                >
                    Save
                </ButtonWithLoader>
            </form>
        </FormikProvider>
    );
};
