import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getSheetStatusByTab, IPayrollPayPeriod, PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { selectPayrollPayPeriods, selectPayrollPayPeriodsLoading } from 'modules/payrollProcessorHub/store/selectors';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { shortDateFormat } from 'shared/models/Dates';
import { orderBy } from 'lodash-es';

interface IPayrollPayPeriodSelectProps extends IFormFieldProps, IFormSelect {
    status: PayrollSheetTabs;
}

export default function PayrollPayPeriodSelect(props: IPayrollPayPeriodSelectProps) {
    const payPeriods = useSelector(selectPayrollPayPeriods);
    const payPeriodsLoading = useSelector(selectPayrollPayPeriodsLoading);
    const { status: payrollTab } = props;
    const status = getSheetStatusByTab(payrollTab);
    const options = orderBy(
        payPeriods.filter((period: IPayrollPayPeriod) => {
            return !status || period.payroll_statuses?.includes(status);
        }).map((period: IPayrollPayPeriod) => ({
            key: period.pay_period_end,
            text: moment(period.pay_period_end).format(shortDateFormat),
        })),
        ['key'],
        ['desc'],
    );

    return (
        <FormSelect
            getKey={item => item.key}
            getText={item => item.text}
            label="Pay Period End"
            options={options}
            isLoading={payPeriodsLoading}
            {...props}
        />
    );
}
