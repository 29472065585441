import { IStore } from 'store/configureStore';

export const selectClientSettingsState = (state: IStore) => {
    return state.modules.settings.clientsSettings.clients;
};
export const selectIsClientUpdating = (state: IStore) => {
    return selectClientSettingsState(state).isClientUpdating;
};
export const selectSyncWorkLocationsInProgress = (state: IStore) => {
    return selectClientSettingsState(state).isSyncWorkLocationsInProgress;
};
export const selectIsClientDeleting = (state: IStore) => {
    return selectClientSettingsState(state).isClientDeleting;
};
