import React from 'react';
import { IPayrollSheetGroupedRow, IPayrollSheetRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import PlainText from 'shared/components/table/Cells/PlainText';
import { PayType } from 'shared/models/PayType';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';

interface IPayTypeCellPureProps {
    className?: string;
    title?: PayType;
}

const PayTypeCellPure = ({
    className,
    title,
}: IPayTypeCellPureProps) => {
    const value = title ? String(title)[0] : '';
    return (
        <PlainText
            className={className}
            value={value}
            title={title}
        />
    );
};

export const PayTypeCell = separateLogicDecorator<IPayrollSheetRow, IPayTypeCellPureProps>(({
    id,
    time_is_salary,
    time_sheet_id,
    expense_sheet_id,
    assignment,
    assignment_id,
    ...props
}) => {
    let title;
    if (time_sheet_id) {
        if (time_is_salary) {
            title = PayType.Salaried;
        } else {
            title = PayType.Hourly;
        }
    } else if (assignment) {
        if (assignment.pay_type === PayType.Salaried) {
            title = PayType.Salaried;
        } else {
            title = PayType.Hourly;
        }
    } else {
        title = PayType.Hourly;
    }
    return {
        ...props,
        title,
    };
})(PayTypeCellPure);

export const PayTypeGroupedCell = separateLogicDecorator<IPayrollSheetGroupedRow, IPayTypeCellPureProps>(({
    time_is_salary,
    ...props
}) => {
    let title;
    if (time_is_salary) {
        title = PayType.Salaried;
    } else {
        title = PayType.Hourly;
    }
    return {
        ...props,
        title,
    };
})(PayTypeCellPure);
