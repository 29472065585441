import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import useActionButtonStyles from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import { useModal } from 'shared/utils/hooks/useModal';
import { RecallSidebar } from 'shared/components/sheetsSubmitted/filterAndActionControls/RecallSidebar';
import { SheetsEntryTypes } from 'store/entities/timesheet/models/Status';

interface IRecallButtonProps {
    sheetsEntryTypes: SheetsEntryTypes;
    onAction?: () => void;
    variant?: 'text' | 'outlined' | 'contained';
}

export default function RecallButton({ sheetsEntryTypes, onAction, variant }: IRecallButtonProps) {
    const classes = useActionButtonStyles();
    const { isModalOpened, onModalOpen, onModalClose } = useModal();

    const onRejectClick = useCallback(() => {
        onModalOpen();
    }, [onModalOpen]);

    return (
        <>
            <Button color="primary"
                variant={variant ?? 'outlined'}
                size="small"
                classes={{
                    root: classes.actionButtonNoBackground,
                    label: classes.buttonTextStyle,
                    outlined: classes.actionButtonOutlined,
                }}
                onClick={onRejectClick}
            >
                Recall
            </Button>

            {isModalOpened && (
                <RecallSidebar onClose={onModalClose} onAction={onAction}
                    sheetsEntryTypes={sheetsEntryTypes}
                />
            )}
        </>
    );
}
