import { uniq } from 'lodash-es';
import { createSelector } from 'reselect';
import { selectEditReportV2State, selectEditSubAssignmentState } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { IBulkUpdateV2UpdateChangeSchema } from 'modules/subassignmentManagement/store/models';

export const selectBulkEditV2Rows = createSelector(
    selectEditReportV2State,
    state => {
        return state.updates;
    },
);

export const selectBulkPayloadV2 = createSelector(
    selectEditSubAssignmentState,
    state => {
        return state.editPayloadV2;
    },
);

const editsFieldTitleMap = {
    'managers': 'Approving Manager',
    'pay_rate': 'Compensation Change',
    'position': 'Position',
    'start_date': 'Start Date',
    'end_date': 'End Date',
};

export const selectBulkEditV2RowsChanges = createSelector(
    selectBulkEditV2Rows,
    rows => {
        return uniq(
            rows.map(row => row.changes.map(
                (change: IBulkUpdateV2UpdateChangeSchema) => editsFieldTitleMap[change.field] || change.field),
            ).flat(),
        ).join(', ');
    },
);
