import React from 'react';
import HomeDashboard from 'modules/home/dashboard/HomeDashboard';

const HomeDashboardPage = () => {
    return (
        <HomeDashboard/>
    );
};

export default HomeDashboardPage;
