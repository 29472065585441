import React, { useCallback, useMemo, useState } from 'react';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { selectIsStatusUpdating } from 'modules/settings/submodules/employees/store/selectors';
import { useLatestEmployeeOffersByStatus } from 'modules/settings/submodules/employees/components/EditEmployee/hooks';
import { SelectClientWithConfirmation } from 'modules/settings/submodules/employees/components/EditEmployee/SelectClientWithConfirmation';
import { overrideOfferLetterStatus } from 'modules/settings/submodules/employees/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { getUserName } from 'shared/utils/converters/user';
import { selectUserById } from 'store/entities/users/selectors';

interface IAcceptOfferButtonProps {
    userId: string;
}

const confirmationText = `
This will set their offer letter status to from outstanding to accepted even if they have not completed onboarding.
To continue, select the client of the offer letter you want to bypass.
`;

export const AcceptOfferButton = ({ userId }: IAcceptOfferButtonProps) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const user = useSelector(selectUserById(userId));
    const isLoading = useSelector(selectIsStatusUpdating);

    const handleOpen = useCallback(() => setOpen(true), []);
    const handleClose = useCallback(() => setOpen(false), []);
    const offerLetters = useLatestEmployeeOffersByStatus(
        user?.email,
        [OfferLetterStatusSlug.PendingManagerApproval, OfferLetterStatusSlug.Outstanding],
    );
    const clientIds = useMemo(() => {
        return offerLetters.map(offer => offer.client_id);
    }, [offerLetters]);
    const handleSubmit = useCallback(clientId => {
        const offerLetterId = offerLetters.find(offer => offer.client_id === clientId)?.id;
        dispatch(overrideOfferLetterStatus.init({
            // @ts-ignore
            id: offerLetterId,
            status: OfferLetterStatusSlug.Accepted,
        }));
        handleClose();
    }, [dispatch, handleClose, offerLetters]);

    const confirmationTitle = `Are you sure you want to bypass ${getUserName(user)}'s onboarding status in Headway Connect?`;

    return (
        <>
            <ButtonWithLoader
                variant="contained"
                color="secondary"
                onClick={handleOpen}
                disabled={clientIds.length === 0}
                isLoading={isLoading}
            >
                Override
            </ButtonWithLoader>
            <SelectClientWithConfirmation
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                modalTitle="Select and Set Offer Letter Status to Accepted"
                confirmationTitle={confirmationTitle}
                confirmationText={confirmationText}
                allowedClientIds={clientIds}
            />
        </>
    );
};
