import React, { useCallback, useEffect, useState } from 'react';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { IntervalInputProps } from 'shared/components/formSpecialFields/intervalField/IntervalDataField';
import { Theme } from '@material-ui/core';
import { Time12HoursKeyboardPicker } from 'shared/components/inputs/Time12HoursKeyboardInput/Time12HoursKeyboardPicker';
import TimeInOutQuickEditInput from './TimeInOutQuickEditInput';
import clsx from 'clsx';
import { shortenText } from 'shared/utils/helpers/shortenText';
import { colors } from 'shared/styles/constants';

const useInOutTimeFieldStyles = makeHighPriorityStyles((theme: Theme) => ({
    helpTextNoWrapped: {
        whiteSpace: 'nowrap',
    },
    input: {
        border: 'none',
        padding: 0,
        minHeight: 23,
        width: theme.spacing(9),
    },
    inputFocused: {
        width: theme.spacing(11),
        borderBottom: `2px solid ${colors.primary}`,
    },
    inputUnFocused: {
        paddingRight: 0,
    },
}));

function TimeInOutQuickEditPicker({
    name,
    value,
    helpers,
    meta,
    onChange,
    onBlur,
}: IntervalInputProps<string>) {
    const classes = useInOutTimeFieldStyles();
    const { setTouched } = helpers || {};
    const { touched, error, initialValue } = meta || {};

    // Hack to prevent showing browser placeholders & icons before entering
    const [isFocussed, setFocussed] = useState(false);
    const [localValue, setValue] = useState<string>(`${initialValue || value}`);

    const focusHandler = useCallback(() => {
        setFocussed(true);
    }, []);

    useEffect(() => {
        if (value) {
            setValue(`${value}`);
        }
    }, [value]);

    const blurHandler = useCallback((e: any) => {
        if (setTouched) {
            setTouched(true);
        }
        setTimeout(() => {
            setFocussed(false);
        }, 200);
        if (typeof onBlur === 'function') {
            onBlur(e);
        }
    }, [setTouched, onBlur]);

    const changeHandler = useCallback((changedValue: string) => {
        setValue(changedValue);
        if (onChange) {
            onChange(changedValue);
        }
    }, [onChange]);
    const errorMessage = typeof error === 'string' ? touched && error : undefined;
    const shortenErrorMessage = shortenText(errorMessage || '', 16);
    return (
        <Time12HoursKeyboardPicker
            name={name}
            value={localValue ? `${localValue}` : null}
            InputFieldComponent={TimeInOutQuickEditInput}
            isFocussed={isFocussed}
            error={Boolean(shortenErrorMessage)}
            helperText={shortenErrorMessage}
            onChange={changeHandler}
            onBlur={blurHandler}
            onFocus={focusHandler}
            InputProps={{
                classes: {
                    root: clsx(classes.input, {
                        [classes.inputUnFocused]: !isFocussed,
                        [classes.inputFocused]: isFocussed,
                    }),
                    notchedOutline: classes.input,
                    input: classes.input,
                },
            }}
        />
    );
}

export default TimeInOutQuickEditPicker;
