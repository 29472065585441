import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const useFilterStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        margin: theme.spacing(2),
    },
    input: {
        width: 240,
        display: 'flex',
        '& + &': {
            marginTop: theme.spacing(2),
        },
    },
    button: {
        width: 'auto',
        marginTop: theme.spacing(2),
        maxHeight: theme.spacing(5),
    },
    checkbox: {
        '& .MuiFormControlLabel-label': {
            fontSize: theme.typography.pxToRem(14),
        },
    },
}));
