import { getActivityIdsByScopes } from 'modules/timeAndExpense/store/hooks';
import { useMemo } from 'react';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IJobNumber } from 'shared/models/JobNumber';
import { IActivity, ActivityTag } from 'store/entities/configuration/configurationModel';
import { useSelector } from 'react-redux';
import { selectActivitiesByIdWithoutDeleted } from 'store/entities/configuration/configurationSelectors';
import { pick } from 'lodash-es';
import { IScope } from 'store/entities/scopes/models';

export const useJobNumberActivities = (
    entryType: EntryType | undefined,
    jobNumber: IJobNumber | null,
) => {
    return useMemo(() => {
        if (entryType === EntryType.TIME && jobNumber) {
            return jobNumber.time_activities?.map(timeActivity => timeActivity.activity_id);
        }
        return undefined;
    }, [jobNumber, entryType]);
};

export const useFilteredActivities = (
    entryType: EntryType | undefined,
    jobNumber: IJobNumber | null,
    scopes: IScope[] = [],
) => {
    const activitiesById = useSelector(selectActivitiesByIdWithoutDeleted);
    const jobNumberActivities = useJobNumberActivities(entryType, jobNumber);
    const filteredActivities = useMemo(
        () => {
            const hasScopes = scopes.length > 0;
            const activitiesIdsByScopes = getActivityIdsByScopes(scopes);
            const allActivities = (() => {
                let result: IActivity[] = Object.values(activitiesById);
                if (jobNumberActivities){
                    result = Object.values(pick(activitiesById, jobNumberActivities));
                }
                result = result.filter((activity: IActivity) => {
                    return activity.is_active && !activity.tags?.includes(ActivityTag.Travel);
                });
                if (hasScopes) {
                    result = result.filter((activity: IActivity) => {
                        return activitiesIdsByScopes.includes(activity.id);
                    });
                }
                return result;
            })();
            return allActivities.filter((activity: IActivity) => (!entryType || activity.sheet_type === entryType));
        },
        [entryType, activitiesById, jobNumberActivities, scopes],
    );
    return filteredActivities;
};
