import { combineReducers } from 'redux';

import { deleteItemByIdReducer, isLoadingReducer, itemsByIdReducer } from 'store/reducerUtils';

import { extendReducer } from 'store/utils/reducers/extendReducer';

import { getOfferLetterTemplates, removeOfferLetterTemplate } from './actions';
import { IOfferLetterTemplate } from './models';

const getOfferLetterTemplatesLoading = isLoadingReducer(getOfferLetterTemplates);
const removeOfferLetterTemplateLoading = isLoadingReducer(removeOfferLetterTemplate);

export const offerLetterTemplatesById = extendReducer(
    itemsByIdReducer<IOfferLetterTemplate, IOfferLetterTemplate>(getOfferLetterTemplates),
    deleteItemByIdReducer<IOfferLetterTemplate>(removeOfferLetterTemplate),
);

export const offerLetterTemplate = combineReducers({
    getOfferLetterTemplatesLoading,
    offerLetterTemplatesById,
    removeOfferLetterTemplateLoading,
});
