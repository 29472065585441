import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { IFormFieldProps } from '../formFields/models';
import { IFormSelect } from './model';
import {
    selectPayPeriods,
    selectPayPeriodsBySheets,
    selectPayPeriodsIsLoading,
} from 'store/entities/timesheet/selectors';
import { getFormattedPayPeriod } from 'shared/models/Dates';

interface IPayPeriodProps extends IFormFieldProps, IFormSelect {
    allPeriods?: boolean;
}

export const PayPeriod = ({
    allPeriods,
    ...props
}: IPayPeriodProps) => {
    const periodSelector = allPeriods ? selectPayPeriods : selectPayPeriodsBySheets;
    const payPeriods = useSelector(periodSelector);
    const isPayPeriodsLoading = useSelector(selectPayPeriodsIsLoading);

    return (
        <FormSelect
            getKey={(period: IPayPeriod) => getFormattedPayPeriod(period)}
            getText={(period: IPayPeriod) => getFormattedPayPeriod(period)}
            options={payPeriods}
            isLoading={isPayPeriodsLoading}
            {...props}
        />
    );
};
