import React from 'react';
import { Typography } from '@material-ui/core';
import { useEntryDetailsStyles } from 'shared/components/table/tableEntryCells/EntryDetailsStyles';
import clsx from 'clsx';
import { getMobileItemsRowByConfig } from 'shared/components/table/utils';
import { getScaOrZip } from 'shared/utils/converters/entry';
import { AccessTime, AttachMoney } from '@material-ui/icons';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { colors } from 'shared/styles/constants';
import { activityGridView } from 'shared/utils/formatters/activityFormatter';
import { IEntryRow } from 'shared/components/table/EntriesTable/model';
import { renderTaskInfo } from 'store/entities/configuration/configurationModel';
import { EntryColumnSlug, IColumnConfiguration } from 'store/entities/clients/clientsModel';

interface IEntryDetailsProps extends IEntryRow {
    iconClass?: string;
    showIcon?: boolean;
    configuration: Array<IColumnConfiguration<EntryColumnSlug>>;
}

export default function EntryDetails({
    entry,
    task,
    activity,
    assignment,
    showIcon,
    iconClass = '',
    className = '',
    configuration,
}: IEntryDetailsProps) {
    const classes = useEntryDetailsStyles();
    const longInfoClass = clsx(classes.longInfo, showIcon ? classes.longInfoEditable : '');

    const firstRow = getMobileItemsRowByConfig(
        [
            {
                slug: EntryColumnSlug.Task,
                getText: () => task ? renderTaskInfo(task) : '',
            },
        ],
        configuration,
    );
    const secondRow = getMobileItemsRowByConfig(
        [
            {
                slug: EntryColumnSlug.Activity,
                getText: () => activityGridView(entry, activity),
            },
            {
                slug: EntryColumnSlug.ZipCode,
                getText: () => getScaOrZip(entry) || '',
            },
        ],
        configuration,
        ' - ',
    );
    const thirdRow = getMobileItemsRowByConfig(
        [
            {
                slug: EntryColumnSlug.Assignment,
                getText: () => assignment?.description || '',
            },
            {
                slug: EntryColumnSlug.Notes,
                getText: () => entry.notes || '',
            },
        ],
        configuration,
    );

    return (
        <div className={clsx(className, classes.tableCell)}>
            {showIcon && (
                <>
                    {entry.entry_type === EntryType.TIME ? (
                        <AccessTime
                            className={iconClass}
                            htmlColor={entry.data.entry_type === QuantityType.TIME_BREAK ? colors.orange : colors.blue}
                        />
                    ) : (
                        <AttachMoney className={iconClass} htmlColor={colors.green}/>
                    )}
                </>
            )}
            <div className={classes.content}>
                {firstRow && (
                    <Typography className={clsx(classes.details, longInfoClass)} variant="caption">
                        {firstRow}
                    </Typography>
                )}
                {secondRow && (
                    <Typography className={clsx(classes.activityInfo, longInfoClass)}>
                        {secondRow}
                    </Typography>
                )}
                {thirdRow && (
                    <Typography className={clsx(classes.details, longInfoClass)} variant="caption">
                        {thirdRow}
                    </Typography>
                )}
            </div>
        </div>
    );
}
