import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useSubAssignmentBulkCreateEditFormStyles = makeHighPriorityStyles(theme => ({
    input: {
        display: 'flex',
    },
    counter: {
        color: theme.palette.primary.main,
        fontSize: 24,
    },
    counterBox: {
        fontWeight: 'bold',
    },
    button: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    buttonRed: {
        backgroundColor: colors.red,
    },
}));
