import React, { useCallback } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormMultiselect from 'shared/components/selects/FormMultiselect';
import { IFormSelect } from 'shared/components/selects/model';
import { IClient } from 'store/entities/clients/clientsModel';
import { selectAllClientsSortedAlphabetically } from 'store/entities/clients/selectors/clientsSelectors';

interface IClientMultiselectProps extends IFormFieldProps, IFormSelect {
    allClientsFieldName: string;
}

export const ClientMultiselect = (props: IClientMultiselectProps) => {
    const clients = useSelector(selectAllClientsSortedAlphabetically);

    const [allClientsField,, allClientsHelper] = useField(props.allClientsFieldName);
    const onSetSelectedAll = useCallback((value: boolean) => {
        allClientsHelper.setValue(value);
    }, [allClientsHelper]);

    return (
        <FormMultiselect
            getKey={(item: IClient) => item?.id}
            getText={(item: IClient) => item?.name}
            options={clients}
            useSelectAll
            disableSmartSelectAll
            onSetSelectedAll={onSetSelectedAll}
            initialAllSelected={allClientsField.value}
            {...props}
        />
    );
};
