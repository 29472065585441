import React from 'react';
import clsx from 'clsx';
import { Form } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { useFormWithSectionsStyles } from 'modules/settings/common/components/FormWithSections/styles';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import DayRangePickerField from 'shared/components/formFields/DayRangePickerField/DayRangePickerField';

interface ILatestPayRatesReportFormProps {
    isLoading: boolean;
}

export const LatestPayRatesReportForm = ({ isLoading }: ILatestPayRatesReportFormProps) => {
    const defaultClasses = useSettingsFormStyles();
    const customClasses = useFormWithSectionsStyles();
    const classes = {
        ...defaultClasses,
        ...customClasses,
    };
    return (
        <Form>
            <Box>
                <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>
                    Latest Pay Rates
                </Typography>
                <Typography>
                    Select date range and run report for the latest subassignment pay rates.
                </Typography>
                <Box
                    mt={2}
                    mb={3}
                    display="flex"
                    justifyContent="stretch"
                    width="100%"
                >
                    <DayRangePickerField
                        startDateName="endDateGte"
                        endDateName="startDateLte"
                    />
                </Box>
                <ButtonWithLoader
                    isLoading={isLoading}
                    disabled={isLoading}
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    Run report
                </ButtonWithLoader>
            </Box>
        </Form>
    );
};
