import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setEndAssignmentId } from 'modules/employeeActionsForm/components/EndAssignment/store/action';
import { selectDetailAssignment } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { isAssignmentActive } from 'shared/utils/helpers/assingmentsHelper';
import { IAssignment } from 'store/entities/configuration/configurationModel';
import { EndAssignmentSidebar } from './EndAssignmentSidebar';

export const EndAssignmentButton = () => {
    const dispatch = useDispatch();
    const assignment = useSelector(selectDetailAssignment);

    const openEndSidebar = useCallback(() => {
        if (assignment?.id) {
            dispatch(setEndAssignmentId(assignment.id));
        }
    }, [dispatch, assignment]);

    const isActiveAssignment = Boolean(assignment) && isAssignmentActive(assignment as IAssignment);

    return (
        <>
            {isActiveAssignment && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={openEndSidebar}>
                    End Assignment(s)
                </Button>
            )}
            <EndAssignmentSidebar
                sideBarTitle={'End Assignment'}
            />
        </>
    );
};
