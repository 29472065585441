import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export interface ITableContainerCssProps {
    gridTemplateColumns: string;
    cellAmount: number;
    rowAmount: number;
    rowWidth?: string;
}

export const getRowHeightLine = (
    paddingTopBottomInPx: number,
    borderSizeBottomInPx: number,
    borderSizeTopInPx: number | undefined = undefined) => {

    const rowHeight = 40;

    if (borderSizeTopInPx === undefined) {
        return rowHeight - 2 * (borderSizeBottomInPx + paddingTopBottomInPx);
    }

    return rowHeight - 2 * paddingTopBottomInPx - borderSizeBottomInPx - borderSizeTopInPx;
};

// @ts-ignore
export const useGridTableStyles = makeHighPriorityStyles((theme: Theme) => {
    const borderSize = 1;
    const headerBorders = `${borderSize}px solid ${colors.darkGray}`;
    const paddingTopBottom = 1;
    const paddingTopBottomInPx = theme.spacing(paddingTopBottom);

    return {
        tableContainer: {
            position: 'relative',
            backgroundColor: colors.white,
            borderRight: `1px solid ${colors.lightGray}`,
            borderLeft: `1px solid ${colors.lightGray}`,
        },
        virtualTableContainer: {
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            scrollBehavior: 'smooth',
        },
        dropRowsZone: (props: ITableContainerCssProps) => ({
            minHeight: theme.spacing(props.rowAmount * 3.5 + 20),
        }),
        headerRow: {
            background: `${colors.lightGray} !important`,
        },
        rowContainer: (props: ITableContainerCssProps) => ({
            width: props.rowWidth || '100%',
            display: 'grid',
            gridTemplateColumns: props.gridTemplateColumns,
            [`& > :nth-child(${props.cellAmount}n)`]: {
                paddingRight: theme.spacing(2),
            },
        }),
        skeletonContainer: (props: ITableContainerCssProps) => ({
            width: props.rowWidth || '100%',
            minWidth: '100%',
        }),
        highlightedRow: {
            backgroundColor: `${colors.lightGreen} !important`,
        },
        draggingRow: {
            border: 'none',
            '& > *': {
                border: `${colors.lightGray} 1px dashed !important`,
            },
        },
        pseudoRow: {
            height: '1px',
            pointerEvents: 'none',
        },
        highlightedPseudoRow: {
            height: theme.spacing(7),
            borderBottom: `1px solid ${colors.lightGray}`,
            marginBottom: -theme.spacing(7),
        },
        hidden: {
            visibility: 'hidden',
            height: '0',
        },
        activeLastPseudoRow: {
            height: theme.spacing(7),
            visibility: 'visible',
        },
        headerCell: {
            fontSize: 10,
            lineHeight: `${getRowHeightLine(paddingTopBottomInPx, borderSize)}px`,
            letterSpacing: 1,
            color: theme.palette.text.primary,
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down('sm') && theme.breakpoints.up('xs')]: {
                paddingLeft: `8px`,
            },
            textTransform: 'uppercase',
            borderTop: headerBorders,
            borderBottom: headerBorders,
            alignItems: 'flex-end',
        },
        groupHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        headerCellSticky: {
            backgroundColor: 'inherit',
            // @ts-ignore
            zIndex: '15 !important',
            position: 'sticky',
            top: 0,
        },
        bodyCell: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            padding: `${theme.spacing(paddingTopBottom, 0, paddingTopBottom, 2)}`,
            paddingLeft: `${theme.spacing(2)}px !important`,
            borderBottom: `${borderSize}px solid ${colors.lightGray}`,
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            lineHeight: `${getRowHeightLine(paddingTopBottomInPx, borderSize, 0)}px`,
            minHeight: `${getRowHeightLine(paddingTopBottomInPx, borderSize, 0)}px`,

            [theme.breakpoints.down('sm') && theme.breakpoints.up('xs')]: {
                paddingLeft: `8px !important`,
                padding: `${theme.spacing(paddingTopBottom, 0, paddingTopBottom, 1)}`,
            },

            '&:hover': {
                whiteSpace: 'unset',
            },
            '&:hover button': {
                whiteSpace: 'nowrap',
            },
        },
    };
});

export const useSkeletonStyles = makeHighPriorityStyles((theme: Theme) => ({
    skeletonWrapper: {
        borderWidth: `0 2px 2px 2px`,
        borderStyle: 'solid',
        borderColor: colors.lightGray,
        marginBottom: theme.spacing(2),
    },
    skeletonRows: {
        padding: theme.spacing(1, 2),
    },
    skeletonHeader: {
        backgroundColor: colors.lightGray,
    },
    skeletonLine: {
        '& + &': {
            marginTop: theme.spacing(1),
        },
        backgroundColor: colors.lightGray,
    },
}));
