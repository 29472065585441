import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPaidTimeOffResponse } from 'shared/models/User';
import { getPaidTimeOff } from 'store/entities/users/actions';
import { selectPaidTimeOff } from 'store/entities/users/selectors';

export const useUserPto = (userId?: string, clientId?: string): IPaidTimeOffResponse | null => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (userId && clientId){
            dispatch(getPaidTimeOff.init({
                client_id: clientId,
                user_id: userId,
            }));
        }
    }, [userId, clientId, dispatch]);

    const pto = useSelector(selectPaidTimeOff(clientId || '', userId || ''));
    return pto || null;
};
