import { Nullable } from 'types/types';
import { IExpenseCalculationEntry, ITimeCalculationEntry, PayrollSheetStatuses } from 'modules/payrollProcessorHub/store/model';

export enum CalculationSheetType {
    Time = 'time',
    Expense = 'expense',
}

export interface ICalculation {
    pay_period_ends_at: string | null;
    assignment_id: string;
    subassignment_id: string | null;
    client_id: string;
    payroll_status: PayrollSheetStatuses;
    sheet_id : string;
    status_id: string;
    status_slug: string | null;
    prism_batch_number: string | null;
    job_number_id: string | null;
    job_number: string | null;
    job_number_user_type_name: string | null;
    deal_number: string | null;
    deal_number_id: string | null;
    user_id: string | null;
    position_id: string | null;
    approved_level: number | null;
    latest_approver_user_id: string | null;
    updated_at: string | null;
    sheet_updated_at: string | null;
    sheet_submitted_at: string | null;
    total_dollars: string;
    period_type: string;
    updated_by: string | null;
}

export interface ITimesheetCalculation extends ICalculation {
    sheet_type: CalculationSheetType.Time;
    rt_dollars: string | null;
    ot_dollars: string | null;
    dt_dollars: string | null;
    pto_dollars: string | null;
    files_dollars: string | null;
    rt_hours: string | null;
    ot_hours: string | null;
    dt_hours: string | null;
    pto_hours: string | null;
    psl_hours: string | null;
    files: number | null;
    holiday_hours: string | null;
    entries: ITimeCalculationEntry[];
    period_type: string;
    is_holiday?: Nullable<boolean>;
}

export interface IExpenseSheetCalculation extends ICalculation {
    sheet_type: CalculationSheetType.Expense;
    total_miles: number | null;
    entries: IExpenseCalculationEntry[];
}

export interface ISheetCalculationRequest {
    sheet_ids?: string[];
}

export type MixedCalculations = ITimesheetCalculation | IExpenseSheetCalculation;

export interface ISheetCalculationResponse {
    calculations: MixedCalculations[];
}
