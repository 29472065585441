import baseApi from 'shared/utils/baseApi';
import {
    ITemplate,
    IWorkingConditionsTemplate,
} from 'store/entities/configuration/configurationModel';
import {
    ICreateTemplatePayload,
    ITemplateFormValues,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';
import { withClientId } from 'store/utils/withClientId';

const usersPath = 'client-users';
const usersAdditionalPath = `${usersPath}/additional`;
const workingConditionsPath = 'working_conditions';

export const workingConditionsApi = {
    async createWorkingConditions(request: ICreateTemplatePayload): Promise<ITemplate> {
        const { data } = await baseApi.post<any, ITemplate>(
            `/${usersAdditionalPath}/${workingConditionsPath}`, withClientId(request),
        );
        return data;
    },
    async updateWorkingConditions(
        id: string,
        request: ITemplateFormValues,
    ): Promise<IWorkingConditionsTemplate> {
        const { data } = await baseApi.patch<ITemplateFormValues, IWorkingConditionsTemplate>(
            `/${usersAdditionalPath}/${workingConditionsPath}/${id}`, request,
        );
        return data;
    },
    async deleteWorkingConditions(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${usersAdditionalPath}/${workingConditionsPath}`, id,
        );
        return data.status;
    },
};
