import { selectAssignmentById } from 'store/entities/configuration/configurationSelectors';
import { put, select, take, takeLatest } from 'typed-redux-saga';
import { setDetailAssignmentId } from 'modules/subassignmentManagement/store/actions';
import { getOfferLetters } from 'modules/offerLetter/store/actions';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { getUsers } from 'store/entities/users/actions';
import { selectEmployeeProfileById, selectOfferLetters } from 'modules/offerLetter/store/selectors';

function* getAssignmentInfoSaga({ payload: assignmentId }: ReturnType<typeof setDetailAssignmentId>) {
    const clientId = yield select(selectCurrentClientId);
    const assignment = yield select(selectAssignmentById(assignmentId || ''));
    const employeeProfile = yield select(selectEmployeeProfileById(assignment?.employee_profile?.id));

    const offerLettersByIds = yield select(selectOfferLetters);
    const userOffers = Object.values(offerLettersByIds)
        // @ts-ignore
        .filter(offer => offer.user_id === assignment?.user_id
            // @ts-ignore
            && offer.client_id === clientId);

    if (employeeProfile?.prism_employee_id && clientId && userOffers.length === 0) {
        yield put(getOfferLetters.init({
            prism_employee_id: employeeProfile?.prism_employee_id,
        }));

        const offerLetterAction = yield take([getOfferLetters.successType, getOfferLetters.errorType]);
        if (offerLetterAction.type === getOfferLetters.successType) {
            const creatorIds = offerLetterAction.payload.map(offer => offer.creator_id);
            if (creatorIds.length > 0) {
                yield put(getUsers.init({ ids: creatorIds.join(',') }));
            }
        }
    }
}

function* getAssignmentInfoWatcher() {
    yield takeLatest(setDetailAssignmentId.action, getAssignmentInfoSaga);
}

export default [
    getAssignmentInfoWatcher,
];
