import React from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import FormField from 'shared/components/formFields/FormField';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { ICostCenterFormValues } from 'modules/settings/submodules/clients/costCenters/components/CostCenterForm/model';
import { FormButtonBlock } from 'modules/settings/common/components/FormButtonBlock/FormButtonBlock';

export interface ICostCenterFormProps extends FormikProps<ICostCenterFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onCancel?: () => void;
}

export function CostCenterForm({
    handleSubmit,
    submitTitle,
    onCancel,
    isLoading = false,
    isDisabled = false,
}: ICostCenterFormProps) {
    const classes = useSettingsFormStyles();

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
            <Box className={classes.formBlock}>
                <Box width="50%">
                    <FormField
                        name="number"
                        type="number"
                        label="Number"
                        className={classes.input}
                    />
                </Box>
                <Box>
                    <FormField
                        name="description"
                        label="Description"
                        className={classes.input}
                    />
                </Box>
            </Box>

            <FormButtonBlock
                submitTitle={submitTitle}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onCancel={onCancel}
            />
        </form>
    );
}
