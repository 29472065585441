import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography?.pxToRem(16),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'block',
    },
    subheader: {
        color: theme.palette.primary.main,
        fontSize: '24px',
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        lineHeight: '32px',
        margin: theme.spacing(2, 0, 1),
        padding: 0,
    },
}));
