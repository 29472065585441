import React, { ReactChildren } from 'react';
import { Box, Button, ButtonProps, CircularProgress } from '@material-ui/core';

export interface IButtonWithLoaderProps extends ButtonProps {
    children: string | JSX.Element | ReactChildren;
    isLoading?: boolean;
}

export default function ButtonWithLoader({
    children,
    isLoading = false,
    disabled,
    ...props
}: IButtonWithLoaderProps) {
    return (
        <Button
            disabled={isLoading || disabled}
            {...props}
        >
            {children}
            {isLoading && (
                <Box ml={1} mb={-0.5}>
                    <CircularProgress color="inherit" size={16}/>
                </Box>
            )}
        </Button>
    );
}
