export function shortenText(text: string, maxLength: number, ending = '...') {
    if (!text) {
        return text;
    }
    if (text.length <= maxLength){
        return text;
    }

    const result = text.substr(0, maxLength) + ending;
    return result;
}
