import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useOfferLetterHeaderStyles = makeHighPriorityStyles((theme: Theme) => ({
    headerWrapper: {
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
            borderBottom: `1px solid ${colors.gray}`,
        },
    },
    filterButton: {
        backgroundColor: colors.white,
        minWidth: theme.spacing(3),
        border: `1px solid ${colors.lightGray}`,
        marginLeft: theme.spacing(2),
        padding: theme.spacing(0.5),

        '& > *:first-child': {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
    },
    search: {
        backgroundColor: colors.white,
        [theme.breakpoints.up('md')]: {
            minWidth: theme.spacing(40),
        },
    },
    filterRoot: {
        marginTop: theme.spacing(1),
        backgroundColor: colors.white,
        height: '100%',
        border: `2px solid ${colors.lightGray}`,
        padding: theme.spacing(2, 3),
        minWidth: theme.spacing(30),

        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    filterApplyButton: {
        alignSelf: 'flex-start',
    },
}));
