import React from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useActionButtonStyles
    from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import { EntryType } from 'shared/models/sheet/Sheet';
import { setResubmitSheet } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import { isEmpty } from 'lodash-es';

export interface IEditAndResubmitButtonProps {
    sheetIds?: string[];
    entryType?: EntryType;
    variant?: 'text' | 'outlined' | 'contained';
    onClose?: () => void;
}

export default function EditAndResubmitButton({ sheetIds, entryType, onClose, variant = 'outlined' }: IEditAndResubmitButtonProps) {
    const classes = useActionButtonStyles();
    const dispatch = useDispatch();

    const editSheets = () => {
        if (sheetIds && entryType) {
            dispatch(setResubmitSheet({
                sheetIds,
                entryType,
            }));
            onClose && onClose();
        }
    };

    return (
        <Button
            color="primary"
            variant={variant}
            size="small"
            disabled={isEmpty(sheetIds)}
            classes={{
                root: classes.actionButtonNoBackground,
                label: classes.buttonTextStyle,
                outlined: classes.actionButtonOutlined,
            }}
            onClick={editSheets}
        >
            edit & resubmit
        </Button>
    );
}
