import { isEmpty } from 'lodash-es';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ApproveEafButton } from 'modules/eafApproval/components/Approve/ApproveEafButton';
import { EafApprovalGridCellStatus } from 'modules/eafApproval/components/cells/EafApprovalGridCellStatus';
import EafInfo from 'modules/eafApproval/components/EafDetail/EafInfo';
import { RejectEafButton } from 'modules/eafApproval/components/Rejection/RejectEafButton';
import { setEafDetailId } from 'modules/eafApproval/store/actions';
import { selectDetailEaf, selectIsLoadingEafDetails } from 'modules/eafApproval/store/selectors';
import { EafStatusSlug } from 'modules/employeeActionsForm/store/models';
import { AttachmentsPreview } from 'shared/components/attachments/AttachmentsPreview';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { getUserName } from 'shared/utils/converters/user';
import { selectUserById } from 'store/entities/users/selectors';

export const EafDetailSidebar = () => {
    const eaf = useSelector(selectDetailEaf);
    const isLoading = useSelector(selectIsLoadingEafDetails);
    const dispatch = useDispatch();
    const classes = useSheetDetailStyles();

    const handleClose = () => {
        dispatch(setEafDetailId(null));
    };
    const employee = useSelector(selectUserById(eaf?.employee_user_id));

    return (
        <Sidebar
            title={employee ? getUserName(employee) : ''}
            isOpened={Boolean(eaf)}
            onClose={handleClose}
            whiteTitle
        >
            {eaf && (
                <Box className={classes.infoSection} component="section">
                    <EafInfo eaf={eaf} isLoading={isLoading}/>
                    <Box className={classes.statusWrapper}>
                        <EafApprovalGridCellStatus
                            {...eaf}
                            className={classes.statusDisplay}
                        />
                    </Box>
                    {!isEmpty(eaf.attachments) && (
                        <Box display="flex" className={classes.attachmentsContainer}>
                            <AttachmentsPreview
                                attachments={eaf.attachments}
                            />
                        </Box>
                    )}
                    {eaf.status.slug === EafStatusSlug.PendingApproval && (
                        <Box display="flex" className={classes.actionAndTotalsContainer}>
                            <Box className={classes.buttonWrapper}>
                                <ApproveEafButton eafIds={[eaf.id]}/>
                                <RejectEafButton eafIds={[eaf.id]}/>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Sidebar>
    );
};
