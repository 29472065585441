import { AnyAction, combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { BulkCreateCcpTransactionsSteps, SetBulkCcpStep, setOpenBulkCreateCcpUploadModal } from './actions';
import {
    bulkUploadCcpTransactionAction,
    bulkUploadCreateCcpTransactionPreInitializeAction,
} from '../../../store/actions';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const isOpenBulkCcpCreationUploadModal = singleValueReducer(setOpenBulkCreateCcpUploadModal.action, false);

const isLoadingCcpPreinitialize = extendReducer(
    isLoadingReducer(bulkUploadCreateCcpTransactionPreInitializeAction),
);

const isLoadingCcpImport = extendReducer(
    isLoadingReducer(bulkUploadCcpTransactionAction),
);

const report = extendReducer(
    singleValueReducer(bulkUploadCreateCcpTransactionPreInitializeAction.successType, null),
);

const postReport = extendReducer(
    singleValueReducer(bulkUploadCcpTransactionAction.successType, null),
);

const ccpBulkStep = (
    state = BulkCreateCcpTransactionsSteps.Form,
    action: AnyAction,
): BulkCreateCcpTransactionsSteps => {
    switch (action.type) {
        case SetBulkCcpStep.action:
            return action.payload;
        case setOpenBulkCreateCcpUploadModal.action:
            return BulkCreateCcpTransactionsSteps.Form;
        case bulkUploadCreateCcpTransactionPreInitializeAction.successType:
            return BulkCreateCcpTransactionsSteps.Verify;
        case bulkUploadCcpTransactionAction.successType:
            return BulkCreateCcpTransactionsSteps.PostReport;
        default:
            return state;
    }
};

export const createCcpTransactions = combineReducers({
    ccpBulkStep,
    isLoadingCcpPreinitialize,
    isOpenBulkCcpCreationUploadModal,
    report,
    isLoadingCcpImport,
    postReport,
});
