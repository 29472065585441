import { ITravelExpenseFilters } from 'modules/travelExpenses/components/TravelExpenseFilter/model';
import { setTravelExpenseFilter } from 'modules/travelExpenses/components/TravelExpenseFilter/action';
import { AnyAction } from 'redux';

export const travelExpenseFilterDefaultValues: ITravelExpenseFilters = {
    dealTypeId: null,
    employeeId: null,
    expenseTypeId: null,
};

export function travelExpenseFilter(
    state: ITravelExpenseFilters = travelExpenseFilterDefaultValues,
    action: AnyAction,
): ITravelExpenseFilters {
    switch (action.type) {
        case setTravelExpenseFilter.action:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
