/* eslint-disable react/display-name */
import { useFormikContext } from 'formik';
import React from 'react';
import { Box, Grid } from '@material-ui/core';
import CheckboxField from 'shared/components/formFields/CheckboxField';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { Permission } from 'store/components/auth/authModels';
import { SpecialUserAutocomplete } from 'shared/components/autocomplete/SpecialUserAutocomplete';

interface ISubassignmentApproversProps {
    inputClassName?: string;
    containerClassName?: string;
    isBulkEdit?: boolean;
    isLoading?: boolean;
    numberOfApprovers: number;
}

const AssignTo = ({ id, label }: { id: string, label: string }) => {
    const formHelperTextClasses = useFormHelperTextStyles();
    return (
        <label
            className={formHelperTextClasses.outerLabel}
            htmlFor={id}
        >
            { label }
        </label>
    );
};

const renderApprover = (inputClassName?: string, disabled = false) => (
    sequenceNumber: number,
    index: number,
    sequence: Array<number>,
) => {
    const label = (
        sequence.length > 1
            ? `Approving Manager #${sequenceNumber + 1}`
            : 'Approving Manager'
    );
    const id = `approver_${sequenceNumber}`;

    // eslint/display-name
    const GridCellAutocomplete = (
        <Grid
            key={id}
            item
            md={6}
            xs={12}
        >
            <SpecialUserAutocomplete
                disabled={disabled}
                feature="purpose"
                id={id}
                featureValue={Permission.ApproveSheets}
                name={`approvers[${sequenceNumber}]`}
                className={inputClassName}
                Label={<AssignTo label={label} id={id}/>}
            />
        </Grid>
    );

    return GridCellAutocomplete;
};

export const SubassignmentApprovers: React.FC<ISubassignmentApproversProps> = ({
    numberOfApprovers,
    inputClassName,
    containerClassName,
    isBulkEdit = false,
    isLoading = false,
}: ISubassignmentApproversProps) => {
    const sequence = Array.from(Array(numberOfApprovers).keys());
    const { values } = useFormikContext();
    const showApprovers = !isBulkEdit || values.modifyApprovers === true;

    const checkBox = isBulkEdit ? (
        <CheckboxField
            disabled={isLoading}
            name="modifyApprovers"
            label="Modify approvers"
        />
    ) : undefined;

    return (
        <Box mt={1}>
            {checkBox}

            {showApprovers && (
                <Grid
                    container
                    spacing={2}
                    className={containerClassName}
                >
                    { sequence.map(renderApprover(inputClassName, isLoading)) }
                </Grid>
            )}
        </Box>
    );
};
