import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';

import { AnyAction } from 'redux';
import { IStore } from 'store/configureStore';

export interface ISyncButtonProps {
    syncAction: (payload: void) => AnyAction;
    isLoadingSelector: (state: IStore) => boolean;
    titleEntity: string;
}

export const SyncButton = ({ titleEntity, syncAction, isLoadingSelector }: ISyncButtonProps) => {
    const dispatch = useDispatch();
    const [isOpenConfirmation, setOpenConfirmation] = useState(false);
    const isLoading = useSelector(isLoadingSelector);

    const onConfirm = useCallback(() => {
        dispatch(syncAction());
        setOpenConfirmation(false);
    }, [dispatch, syncAction, setOpenConfirmation]);

    return (
        <>
            <ButtonWithLoader
                variant="contained"
                size="small"
                color="primary"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => setOpenConfirmation(true)}>
                {`Sync ${titleEntity}`}
            </ButtonWithLoader>
            <ConfirmationDialog
                open={isOpenConfirmation}
                onConfirm={onConfirm}
                onCancel={() => setOpenConfirmation(false)}
                confirmText="Sync"
            >
                Are you sure you want to sync { titleEntity } with Prism?
            </ConfirmationDialog>
        </>
    );
};
