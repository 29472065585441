import { usePayrollReportStyle } from 'modules/payrollProcessorHub/components/PayrollModal/reports/PayrollStyle';
import { IReportOverviewItem } from 'modules/payrollProcessorHub/store/model';
import { selectEditReportV2State } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { useSelector } from 'react-redux';

export function useBulkPreEditOverviewItems(): IReportOverviewItem[] {
    const preInitializeReportState = useSelector(selectEditReportV2State);
    const classes = usePayrollReportStyle();

    const numberOfFailed = preInitializeReportState?.number_of_subassignments_failed;
    const numberOfSubassignments = preInitializeReportState?.number_of_subassignments;

    return [
        {
            label: '# of edits to the subassignments that will pass',
            values: [String(numberOfSubassignments - numberOfFailed)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# of edits to the subassignments that will fail',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}

export function useBulkPostEditOverviewItems(): IReportOverviewItem[] {
    const preInitializeReportState = useSelector(selectEditReportV2State);
    const classes = usePayrollReportStyle();

    const numberOfFailed = preInitializeReportState?.number_of_subassignments_failed;
    const numberOfSubassignments = preInitializeReportState?.number_of_subassignments;

    return [
        {
            label: '# subassignments updated',
            values: [String(numberOfSubassignments - numberOfFailed)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# failed to update subassignments',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}
