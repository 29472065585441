import React from 'react';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOpenCcpEditNoteSidebar, selectOpenedTransactionId } from './store/selectors';
import { useStyles } from '../../../offerLetter/components/OfferLetterForm/styles';
import { setOpenNoteEditCcpSidebar } from './store/actions';
import { Box } from '@material-ui/core';
import ActionButton from 'shared/components/buttons/ActionButton';
import { CcpReconciledID, CcpUnreconciledID, ITransactionChange } from '../../models/ccpModel';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectCcpTransactionsById } from '../CcpTransactionsTable/store/selectors';
import { ICcpTransaction } from '../../models/CcpTransaction';
import { changeCcpTransaction } from '../../store/actions';
import { Form, Formik } from 'formik';
import FormField from 'shared/components/formFields/FormField';
import { useCcpMainStyles } from '../../styles';
import { selectIsUserHasPermission } from '../../../../store/components/auth/selectors';
import { Permission } from '../../../../store/components/auth/authModels';
import { selectCcpEditMode } from 'modules/ccp/store/selectors';

export const NoteEditorSidebar = () => {
    const isOpened = useSelector(selectIsOpenCcpEditNoteSidebar);
    const openedId = useSelector(selectOpenedTransactionId);
    const transactions = useSelector(selectCcpTransactionsById);
    const clientId = useSelector(selectCurrentClientId);
    const transactionItem = transactions[openedId] as ICcpTransaction;
    const classes = useStyles();
    const additionalClasses = useCcpMainStyles();

    const hasEditPermissionUnreconsiled = useSelector(selectIsUserHasPermission(Permission.CanEditCcpUnreconciled));
    const hasEditPermissionReconciled = useSelector(selectIsUserHasPermission(Permission.CanEditCcpReconciled));
    const isEditMode = useSelector(selectCcpEditMode);
    const ableToEdit = (transaction_status_id: string): boolean => {
        return (hasEditPermissionUnreconsiled && transaction_status_id === CcpUnreconciledID)
            || (
                hasEditPermissionReconciled
                && transaction_status_id === CcpReconciledID
                && isEditMode
            );
    };
    const canEdit = transactionItem ? ableToEdit(transactionItem.transaction_status_id) : false;

    const dispatch = useDispatch();
    const isLoading = false;

    const handleClose = () => {
        dispatch(setOpenNoteEditCcpSidebar(''));
    };

    const handleSubmit = (values: ICcpTransaction) => {
        const current_change: ITransactionChange = {
            id: values.id,
            transaction_code: values.transaction_code_id,
            department_id: values.department_id,
            client_id: clientId,
            note: values.note,
        };

        dispatch(changeCcpTransaction.init(current_change));
        dispatch(setOpenNoteEditCcpSidebar(''));
    };

    return (
        <Sidebar
            title="ADD NOTES"
            isOpened={Boolean(isOpened)}
            onClose={handleClose}
            whiteTitle
        >
            <Formik
                initialValues={ transactionItem }
                onSubmit={handleSubmit}
            >
                <Form
                    className={classes.form}
                >
                    <FormField
                        name="note"
                        multiline
                        rows={8}
                        rowsMax={16}
                        className={classes.input}
                        value={transactionItem?.note}
                        autoComplete="off"
                        disabled={!canEdit}
                    />
                    {canEdit && (
                        <Box component="section"
                            padding="18px"
                        >
                            <Box display="flex">
                                <Box className={additionalClasses.buttonWrapper}>
                                    <ActionButton
                                        variant="contained"
                                        color="primary"
                                        customType="success"
                                        type="submit"
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Add
                                    </ActionButton>
                                    <ActionButton
                                        variant="outlined"
                                        color="primary"
                                        customType="danger"
                                        onClick={handleClose}
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </ActionButton>
                                </Box>
                            </Box>
                        </Box>
                    )
                    }
                </Form>
            </Formik>
        </Sidebar>);
};
