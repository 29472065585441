import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { formBasicSpacing, useStyles } from '../styles';
import { FormikProps } from 'formik';
import { IOfferLetterFormValues } from '../model';
import { IMultiStepFormStepProps, MultiStepFormValues } from 'shared/components/forms/multiStep/MultiStepForm';
import { OfferLetterBackgroundChecks } from '../../CreateOffer/components/OfferLetterBackgroundChecks';
import { OfferLetterWorkingConditions } from '../../CreateOffer/components/OfferLetterWorkingConditions';
import { OfferLetterPhysicalDemands } from '../../CreateOffer/components/OfferLetterPhysicalDemands';
import { MultipleApproversFormFields } from 'modules/offerLetter/components/OfferLetterForm/steps/OfferLetterFormStepAssignment/MultipleApproversFormFields';

export const OFFER_LETTER_FORM_STEP_ASSIGNMENT = 'OFFER_LETTER_FORM_STEP_ASSIGNMENT';

export function OfferLetterFormStepAssignment({
    step,
    forceValidate,
}: FormikProps<MultiStepFormValues<IOfferLetterFormValues>> & IMultiStepFormStepProps) {
    const classes = useStyles();

    return (
        <Box className={classes.form}>
            <Box
                m={formBasicSpacing}
                mt={2.5}
                mb={0}
            >
                <Typography variant="subtitle2">
                    {`${step + 1}.  Create Offer - Assignment Details`}
                </Typography>
            </Box>

            <OfferLetterBackgroundChecks name="backgroundChecks" className={classes.field}
                forceValidate={forceValidate}/>
            <OfferLetterWorkingConditions name="workingConditions" className={classes.field}
                forceValidate={forceValidate}/>
            <OfferLetterPhysicalDemands name="physicalDemands" className={classes.field}
                forceValidate={forceValidate}/>

            <MultipleApproversFormFields/>
        </Box>
    );
}
