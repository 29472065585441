import { combineReducers } from 'redux';
import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import {
    createWorkingConditionsTemplate,
    setCreateWorkingConditionsTemplateModalState, setEditWorkingConditionsTemplateId,
    updateWorkingConditionsTemplate,
} from 'modules/settings/submodules/clients/workingConditions/store/actions';

const isOpenCreateWorkingConditionsModal = isOpenModalReducer(setCreateWorkingConditionsTemplateModalState.action);
const isWorkingConditionsCreating = isLoadingReducer(createWorkingConditionsTemplate);
const isWorkingConditionsUpdating = isLoadingReducer(updateWorkingConditionsTemplate);

function editWorkingConditionsId(
    state: string | null,
    action: ReturnType<typeof setEditWorkingConditionsTemplateId>,
): string | null {
    state = state || null;
    switch (action.type) {
        case setEditWorkingConditionsTemplateId.action:
            return action.payload;
        default:
            return state;
    }
}

export const workingConditionsTemplates = combineReducers({
    isOpenCreateWorkingConditionsModal,
    isWorkingConditionsCreating,
    editWorkingConditionsId,
    isWorkingConditionsUpdating,
});
