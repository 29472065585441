import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { GetRowId, ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';

import { Draggable } from 'react-beautiful-dnd';

export interface IGridTableRowProps<RowData> {
    row: RowData;
    getRowId?: GetRowId<RowData>;
    isPseudoRow?: (row: RowData) => boolean;
    /**
     * @deprecated
     */
    rowsLength?: number;
    cells: Array<ICellInfo<RowData>>;
    classes: ReturnType<typeof useGridTableStyles>;
    bodyCellClassName?: string;
    /**
     * @deprecated
     */
    droppableRows?: boolean;
    /**
     * @deprecated
     */
    currentDragDestinationIndex?: number;
    /**
     * @deprecated
     */
    currentDraggableId?: string | null;
    index: number;
    getRowClasses?: (row: RowData) => ClassValue[];
    customRowClassName?: string;
    style?: any;
}

export type IGridCellProps<RowData> = RowData & {
    className?: string;
};

function GridTableRow<RowData>({
    row, getRowId, isPseudoRow, cells, classes, droppableRows, getRowClasses,
    currentDragDestinationIndex, currentDraggableId, index, rowsLength, bodyCellClassName = '',
    customRowClassName, style,
}: IGridTableRowProps<RowData>) {
    // Do not delete div wrapper, or modify it's role because some logic depends on it
    const rowCells = cells.map(({ key, render: Component, cellClassName }) => (
        <Component className={clsx(classes.bodyCell, bodyCellClassName, cellClassName)}
            key={key}
            {...row}
        />
    ));

    if (droppableRows && getRowId && getRowId(row)){
        const isHighlighted = (currentDragDestinationIndex || -1) > -1
            && index + 1 === currentDragDestinationIndex;

        const isPseudo = isPseudoRow && isPseudoRow(row);

        return (
            <Draggable draggableId={getRowId ? getRowId(row) : ''}
                index={index || 0}>
                {(provided, snapshot) => {
                    const isLastPseudoRow = isPseudo && rowsLength && (index === rowsLength - 1);
                    const classesToUse = clsx(
                        classes.rowContainer,
                        customRowClassName,
                        isHighlighted ? classes.highlightedRow : '',
                        isPseudo ? classes.pseudoRow : '',
                        isPseudo && (currentDragDestinationIndex || -1) < 0 ? classes.hidden : '',
                        isPseudoRow && isHighlighted ? classes.highlightedPseudoRow : '',
                        snapshot.isDragging ? classes.draggingRow : '',
                        currentDraggableId && isLastPseudoRow ? classes.activeLastPseudoRow : '',
                    );

                    return (
                        <div className={ classesToUse }
                            role="row"
                            id={ getRowId ? getRowId(row) : undefined }
                            data-testid={ getRowId ? getRowId(row) : undefined }
                            ref={ provided.innerRef }
                            { ...provided.draggableProps }
                            { ...provided.dragHandleProps }
                        >   {
                                !isPseudo
                                    ? snapshot.isDragging
                                        ? rowCells[0]
                                        : rowCells
                                    : null
                            }
                        </div>
                    );
                }}
            </Draggable>
        );
    }
    return (
        <div
            style={style}
            className={clsx(
                customRowClassName,
                classes.rowContainer,
                ...(getRowClasses ? getRowClasses(row) : []),
            )}
            role="row"
            id={getRowId ? getRowId(row) : undefined}
            data-testid={getRowId ? getRowId(row) : undefined}
        >
            {rowCells}
        </div>
    );
}

export const rowPropsComparator = (prevProps: any, nextProps: any) => {
    return Object.entries(prevProps).every(([name, value]) => {
        if (name !== 'row') {
            return nextProps[name as keyof typeof prevProps] === value;
        } else {
            const nextRowValues = Object.values(nextProps.row as Array<any>);
            return Object.values(prevProps.row as Array<any>).every(
                (item, index) => item === nextRowValues[index],
            );
        }
    });
};

export default React.memo(GridTableRow, rowPropsComparator) as typeof GridTableRow;
