import { IAvatarUpload } from '../store/profileModel';

export async function readAvatarContent(file: File) {
    return new Promise<IAvatarUpload>((accept, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => accept({
            previewUrl: URL.createObjectURL(file),
            filename: file.name,
            mimetype: file.type,
            body: reader.result === null ? '' : reader.result.toString(),
            entry_id: '',
        });

        reader.onerror = () => reject();
    });
}
