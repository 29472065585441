import { autocompleteSearchDeal } from 'shared/components/autocomplete/deals/actions';
import { autocompleteSearchSagaWrapper } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/store/utils';
import { getDeals } from 'store/entities/configuration/configurationAction';
import { configurationApi } from 'store/entities/configuration/configurationApi';
import { call, put, takeLatest } from 'typed-redux-saga';

function* searchDealSaga({ payload }: ReturnType<typeof autocompleteSearchDeal>) {
    const dealsResponse = yield* call(configurationApi.getDeals, payload.request);
    yield put(getDeals.success(dealsResponse.deals));
    return dealsResponse.deals.map(deal => deal.id);
}

export function* dealSearchSagaWatcher() {
    yield takeLatest(autocompleteSearchDeal.action, autocompleteSearchSagaWrapper(searchDealSaga));
}
