import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik/dist/types';
import validationScheme, { IDealTypePayCodeArBucketForm } from '../model/formModel';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import PayCodeSelect from 'modules/settings/common/components/PayCodeSelect';
import { DealTypeSelect } from 'modules/settings/submodules/clients/deals/components/DealTypeSelect';
import { ArBucketSelect } from './ArBucketSelect';
import { associateDealTypePayCodeArBucket } from '../store/actions';
import { selectIsDealTypePayCodeArBucketsAssociationInProgress } from '../store/selectors';

export const CreateDealTypePayCodeArBucketAssociation = () => {
    const classes = useInlineFormStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsDealTypePayCodeArBucketsAssociationInProgress);
    const onSubmitForm = (
        { arBucket, payCode, dealType }: Partial<IDealTypePayCodeArBucketForm>,
        { resetForm }: FormikHelpers<Partial<IDealTypePayCodeArBucketForm>>,
    ) => {
        if (arBucket && payCode && dealType) {
            dispatch(associateDealTypePayCodeArBucket.init({ arBucket, payCode, dealType }));
            resetForm();
        }
    };

    const formikData = useFormik<Partial<IDealTypePayCodeArBucketForm>>({
        initialValues: {
            arBucket: '',
            payCode: undefined,
            dealType: undefined,
        },
        validationSchema: validationScheme,
        onSubmit: onSubmitForm,
    });
    const { handleSubmit } = formikData;

    return (
        <FormikProvider value={formikData}>
            <form
                onSubmit={handleSubmit}
                className={classes.form}
            >
                <PayCodeSelect
                    name="payCode"
                    label="Pay Code"
                    className={classes.input}
                />
                <DealTypeSelect
                    name="dealType"
                    label="Deal Type"
                    className={classes.input}
                />
                <ArBucketSelect
                    name="arBucket"
                    label="AR Bucket"
                    className={classes.input}
                />
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    isLoading={isLoading}
                    data-testid="submit_button"
                >
                    Associate
                </ButtonWithLoader>
            </form>
        </FormikProvider>
    );
};
