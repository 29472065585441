import React, { useCallback } from 'react';
import { ScopeForm } from 'modules/settings/submodules/clients/scopes/components/ScopeForm/ScopeForm';
import { setEditScopeId, updateScope } from 'modules/settings/submodules/clients/scopes/store/actions';
import { IScopeModifyRequest } from 'modules/settings/submodules/clients/scopes/store/models';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { selectEditScope, selectScopeIsUpdating } from 'modules/settings/submodules/clients/scopes/store/selector';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { ScopeType } from 'store/entities/scopes/models';

export default function EditScopeModal() {
    const dispatch = useDispatch();
    const classes = useColoredFormModalStyles();
    const { type } = useParams<{ type: ScopeType }>();

    const isLoading = useSelector(selectScopeIsUpdating);
    const scope = useSelector(selectEditScope);
    const onSave = useCallback((values: IScopeModifyRequest) => {
        dispatch(updateScope.init({
            id: scope.id,
            data: values,
        }));
    }, [dispatch, scope]);
    const onClose = useCallback(() => {
        dispatch(setEditScopeId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit Scope"
            isOpened={Boolean(scope)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <ScopeForm
                onSubmit={onSave}
                isLoading={isLoading}
                type={type}
                scope={scope}
                buttonText="Save"
            />
        </ModalDialog>
    );
}
