import React, { useState } from 'react';
import { FormikProps } from 'formik';
import FormField from 'shared/components/formFields/FormField';
import { useStyles } from 'modules/profile/styles';
import { IUserAccountFormValues } from 'modules/profile/store/profileModel';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PasswordField from 'shared/components/formSpecialFields/password/PasswordField';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';

export interface IUserAccountFormProps extends FormikProps<IUserAccountFormValues> {
    helpText: string;
    hasError: boolean;
    isSubmitting: boolean;
    onChange: () => void;
}

export function UserAccountForm({
    handleSubmit,
    isSubmitting,
    onChange,
    helpText,
    hasError,
}: IUserAccountFormProps) {
    const classes = useStyles();

    const [isChangePassword, setIsChangePassword] = useState(false);

    function onCancel(){
        setIsChangePassword(!isChangePassword);
        onChange();
    }

    return (
        <form onSubmit={handleSubmit} 
            className={clsx(classes.form, classes.flexColumn)}
            autoComplete="off"
            onChange={onChange}>
            <section className={classes.componentBlock}>
                <FormField
                    name="email"
                    label="Email"
                    className={classes.input}
                    disabled
                />
                <Typography variant="body2" className={classes.passwordLink}
                    onClick={() => setIsChangePassword(!isChangePassword)}>
                    Change Password
                </Typography>
            </section>
            {isChangePassword
            && (
                <>
                    <section className={classes.componentBlock}>
                        <PasswordField
                            name="current_password"
                            label="Current Password"
                            className={classes.input}
                            disabled={isSubmitting}
                        />
                        <Box className={classes.confirmBlock}>
                            <PasswordField
                                name="new_password"
                                label="New Password"
                                autoComplete="new-password"
                                className={classes.input}
                                helperText="Passwords must be at least 8 characters 
                                and include an upper case, number and special character."
                                showStrengthIndicator
                                disabled={isSubmitting}
                            />

                            <PasswordField
                                name="passwordConfirmation"
                                label="Confirm Password"
                                className={clsx(classes.input, classes.confirmpwd)}
                                disabled={isSubmitting}
                            />
                        </Box>
                    </section>
                    {helpText && (
                        <FormHelperText
                            className={classes.userMessage}
                            error={hasError}
                        >
                            {helpText}
                        </FormHelperText>
                    )}  
                    <section
                        className={classes.footer}>
                        <ButtonWithLoader
                            isLoading={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={clsx(classes.button, classes.green)}
                        >
                            Save
                        </ButtonWithLoader>                        
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={onCancel}
                        >
                    Cancel
                        </Button>
                    </section>
  
                </>
            )}
        </form>
    );
}
