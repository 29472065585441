import React from 'react';
import { IFormFieldProps } from '../formFields/models';
import { IFormSelect } from './model';
import FormAutocomplete from '../autocomplete/FormAutocomplete';
import { ICustomFieldValue } from '../../../store/entities/customFields/model';

interface ICustomFieldNamedAutocompleteProps extends IFormFieldProps, IFormSelect {
    customFields?: ICustomFieldValue[];
    isLoading?: boolean;
}

export default function CustomFieldNamedAutocomplete({ customFields, ...props }:
ICustomFieldNamedAutocompleteProps) {
    return (
        <FormAutocomplete
            getKey={(customField: ICustomFieldValue) => customField.id}
            getText={(customField: ICustomFieldValue) => customField.data.name}
            options={customFields || []}
            {...props}
        />
    );
}
