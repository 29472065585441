import React from 'react';
import { Formik } from 'formik';
import { defaultOfferLetterTemplateFormValues, IOfferLetterTemplateFormValues } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/model';
import { OfferLetterTemplateForm } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/OfferLetterTemplateForm';
import { OfferLetterTemplateValidationSchema } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/OfferLetterTemplateValidationSchema';

export interface IUserFormWrapperProps {
    onSubmit: (values: IOfferLetterTemplateFormValues) => void;
    submitTitle: string;
    isLoading: boolean;
}

const OfferLetterTemplateFormWrapper = ({
    onSubmit,
    submitTitle,
    isLoading,
}: IUserFormWrapperProps) => {
    return (
        <Formik
            initialValues={defaultOfferLetterTemplateFormValues}
            validationSchema={OfferLetterTemplateValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <OfferLetterTemplateForm
                    {...props}
                    isLoading={isLoading}
                    submitTitle={submitTitle}
                />
            )}
        </Formik>
    );
};

export default OfferLetterTemplateFormWrapper;
