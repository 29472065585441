import { combineReducers } from 'redux';
import { isLoadingReducer, itemsByIdReducer } from 'store/reducerUtils';
import { IPayCode } from 'modules/settings/submodules/clients/payCodes/store/model';
import { getClientPayCodes } from 'modules/settings/submodules/clients/store/actions';

const isLoading = isLoadingReducer(getClientPayCodes);
const payCodesById = itemsByIdReducer<IPayCode, IPayCode>(getClientPayCodes);

export const payCodes = combineReducers({
    isLoading,
    payCodesById,
});
