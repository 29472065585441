import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { EntryType } from 'shared/models/sheet/Sheet';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';
import { addCustomFieldsSheetInfoItems } from '../helpers/addCustomFieldsSheetInfoItems';
import { useCalcGroupCustomFieldsWithValueBatch } from '../store/hooks';

export function useCustomFieldsItemsBatch(
    type: EntryType,
    calculationGroup: ISheetCalculationBatch,
    items: Array<ISheetInfoItemData>,
): Array<ISheetInfoItemData> {
    const sheetCustomFieldsWithValues = useCalcGroupCustomFieldsWithValueBatch(calculationGroup, type);
    const calculations: Array<ICalculation> = (
        type === EntryType.TIME
            ? calculationGroup.time_calculations
            : calculationGroup.expense_calculations
    );

    const jobNumberUserTypeNames = (
        calculations
            .map(calculation => calculation.job_number_user_type_name)
            .filter(Boolean)
    );

    return addCustomFieldsSheetInfoItems(
        sheetCustomFieldsWithValues,
        jobNumberUserTypeNames.length > 0,
        items,
    );
}
