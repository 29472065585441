import { Divider, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import { VerticalMenuItems } from 'shared/components/verticalMenu/VerticalMenuItems';
import { VerticalMenuSearch } from 'shared/components/verticalMenu/VerticalMenuSearch';
import { IMenuCategory } from 'shared/models/Global';
import { isContainSubstring } from 'shared/utils/helpers/isContainSubstring';
import { Permission } from 'store/components/auth/authModels';

interface IVerticalMenuCategoryProps {
    category: IMenuCategory,
    index: number,
    permissions: Permission[],
    isMinimized: boolean,
    onSetSidebarOpen: (value: boolean) => void,
}

export const VerticalMenuCategory = ({
    category,
    index,
    permissions,
    isMinimized,
    onSetSidebarOpen,
}: IVerticalMenuCategoryProps) => {
    const classes = useMenuStyles();
    const { children, title, permission, subSearch, subSearchPlaceholder } = category;
    const [search, setSearch] = useState('');
    if (permission && !permissions.includes(permission)) {
        return null;
    }
    const menuItems = children.filter(menuItem => isContainSubstring(menuItem.title, search));

    return (
        <>
            {index > 0 && <Divider/>}
            {!isMinimized && title && <Typography variant="h6" className={classes.subtitle}>{title}</Typography>}
            {subSearch && (
                <VerticalMenuSearch
                    onSetSidebarOpen={onSetSidebarOpen}
                    isMinimized={isMinimized}
                    search={search}
                    onSearchUpdate={setSearch}
                    subSearchPlaceholder={subSearchPlaceholder}
                />
            )}
            <VerticalMenuItems menuItems={menuItems} isMinimized={isMinimized}/>
        </>
    );
};
