import { Parser, FieldInfo } from 'json2csv';
import { IActionsCreatorCommon } from 'store/utils';
import { downloadFileSaga } from 'store/utils/sagas/downloadFileSaga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';

export interface ICsvExportItem extends Record<string, any> {}

export function getCsvFile<IDictionary extends ICsvExportItem>(
    items: IDictionary[],
    fields: (keyof IDictionary | FieldInfo<IDictionary>)[],
) {
    const opts = {
        fields,
        withBOM: true,
        excelString: true,
        eol: '\r\n',
    };
    // @ts-ignore
    const parser = new Parser(opts);
    return parser.parse(items);
}

type PrepareCsvFn<RequestType, ReturnType> =
| ((request: RequestType) => Generator<any, ReturnType>)
| ((request: RequestType) => ReturnType);

export function generateGetPrepareExportSaga<ActionRequestPayload>(
    action: IActionsCreatorCommon<ActionRequestPayload, void, any, any, any, any>,
    getItemsSaga: (request: ActionRequestPayload) => Generator<any, ICsvExportItem[]>,
    getFieldsSaga: PrepareCsvFn<ActionRequestPayload, FieldInfo<ICsvExportItem>[]>,
    getFilenameSaga: PrepareCsvFn<ActionRequestPayload, string>,
) {
    return function* getPrepareExportSaga({ payload }: ReturnType<typeof action.init>) {
        const items = yield* call(getItemsSaga, payload);
        const fields = yield* call(getFieldsSaga, payload);
        const filename = yield* call(getFilenameSaga, payload);
        const csvFile = getCsvFile(items, fields);
        yield call(downloadFileSaga, csvFile, filename);
        yield put(action.success());
    };
}

export function getDownloadCsvSagaWatcher<ActionRequestPayload>(
    action: IActionsCreatorCommon<ActionRequestPayload, void, any, any, any, any>,
    getItemsSaga: (request: ActionRequestPayload) => Generator<any, ICsvExportItem[]>,
    getFieldsSaga: PrepareCsvFn<ActionRequestPayload, FieldInfo<ICsvExportItem>[]>,
    getFilenameSaga: PrepareCsvFn<ActionRequestPayload, string>,
) {
    const getPrepareExportSaga = generateGetPrepareExportSaga(
        action,
        getItemsSaga,
        getFieldsSaga,
        getFilenameSaga,
    );

    return function* getDownloadCsvSagaWatcherInner() {
        yield takeLatest(
            action.initType,
            withBackendErrorHandler(
                getPrepareExportSaga,
                action.error,
                `Unable to export`,
            ),
        );
    };
}
