import {
    AvailableTableConfiguration,
    IColumnConfiguration,
    SheetColumnSlug,
} from 'store/entities/clients/clientsModel';
import { useSelector } from 'react-redux';
import { selectTableConfiguration } from 'store/entities/clients/selectors/fieldSelectors';
import { selectIsJobNumberFieldsApplied } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { CustomFieldType } from 'store/entities/customFields/model';
import { selectDisplayedOnTableCustomFields } from 'store/entities/customFields/selectors';
import { useMemo } from 'react';

export const useExtendedConfiguration = (
    option: AvailableTableConfiguration,
): IColumnConfiguration<SheetColumnSlug>[] => {
    const configuration = useSelector(selectTableConfiguration(option)) as IColumnConfiguration<SheetColumnSlug>[];
    const isJobNumberFieldsApplied = useSelector(selectIsJobNumberFieldsApplied);
    const tableCustomFields = useSelector(selectDisplayedOnTableCustomFields);

    return useMemo(() => {
        if (!isJobNumberFieldsApplied) {
            const { prefixColumns, postfixColumns } = configuration.reduce((filtered, column) => {
                if ([SheetColumnSlug.Employee, SheetColumnSlug.PayPeriod].includes(column.slug)) {
                    filtered.prefixColumns.push(column);
                    return filtered;
                }
                filtered.postfixColumns.push(column);
                return filtered;
            }, {
                prefixColumns: new Array<IColumnConfiguration<SheetColumnSlug>>(),
                postfixColumns: new Array<IColumnConfiguration<SheetColumnSlug>>(),
            });

            return [
                ...prefixColumns,
                ...tableCustomFields.map(customField => {
                    return {
                        slug: customField.headway_connect_field?.key === CustomFieldType.SCA
                            ? SheetColumnSlug.SCA : SheetColumnSlug.CustomField,
                        placeholder: customField.name,
                        additionalData: {
                            customFieldId: customField.id,
                        },
                    };
                }),
                ...postfixColumns,
            ];
        }

        return [...configuration];
    }, [configuration, tableCustomFields, isJobNumberFieldsApplied]);
};
