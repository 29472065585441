import { EntryType, IEntry } from 'shared/models/sheet/Sheet';

export function getScaOrZip(entry: IEntry) {
    if (entry.entry_type === EntryType.TIME) {
        return entry.sca_zone
            ? `${entry.sca_zone.state_short_name} - ${entry.sca_zone.county_name} - ${entry.sca_zone.sca_zone_id}`
            : '';
    }
    return entry.zip_code ? `${entry.zip_code?.state_short_name} ${entry.zip_code?.zip_code}` : '';
}
