import { getDurationFromEntryData, getBreakDurationFromEntryData } from 'shared/models/DateTime';
import { EntryType, IEntry, QuantityType, TimeEntryDataBackend } from 'shared/models/sheet/Sheet';

const getTimeEntryDuration = (entryData: TimeEntryDataBackend) => {
    switch (entryData.entry_type) {
        case QuantityType.TIME_BREAK:
            return getBreakDurationFromEntryData(entryData);
        default:
            return getDurationFromEntryData(entryData);
    }
};

export const getHasEmptyEntries = (entries: IEntry[]) => {
    return entries.some(entry => {
        return (
            entry.entry_type === EntryType.TIME
            && getTimeEntryDuration(entry.data as TimeEntryDataBackend) === null
        );
    });
};
