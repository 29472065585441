import {
    approveEafSagaWatcher,
    getEafApprovalItemsRequestSagaWatcher,
    loadEafDetailSagaWatcher,
    patchEafItemsRequestSagaWatcher,
    rejectEafSagaWatcher,
} from 'modules/eafApproval/store/sagas/api-sagas';

export default [
    getEafApprovalItemsRequestSagaWatcher,
    patchEafItemsRequestSagaWatcher,
    approveEafSagaWatcher,
    rejectEafSagaWatcher,
    loadEafDetailSagaWatcher,
];
