import React, { FC, useCallback } from 'react';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useTimeEntryMobileModalTabsStyles } from './TimeEntryMobileModalTabsStyles';
import { setSheetGridStatus } from 'store/entities/appConfig/actions';
import { ButtonBase } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllSheets } from 'store/entities/timesheet/selectors';
import WarningIcon from '@material-ui/icons/Warning';

interface ITimeEntryMobileModalTabsProps {
    onClose: () => void;
}

export const TimeEntryMobileModalTabs: FC<ITimeEntryMobileModalTabsProps> = ({
    onClose,
}: ITimeEntryMobileModalTabsProps) => {
    const classes = useTimeEntryMobileModalTabsStyles();

    const sheets = useSelector(selectAllSheets);
    const hasRecalled = sheets.some(sheet => sheet.status?.name === StatusNames.RECALLED);

    const dispatch = useDispatch();
    const onClick = useCallback((value: StatusNames) => () => {
        dispatch(setSheetGridStatus(value));
        onClose();
    }, [dispatch, onClose]);

    return (
        <div>
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.WORKING)}>Working</ButtonBase>
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.SUBMITTED)}>Submitted</ButtonBase>
            {hasRecalled && (
                <ButtonBase className={classes.status} onClick={onClick(StatusNames.RECALLED)}>Recalled
                    <WarningIcon className={classes.warningClass}/>
                </ButtonBase>
            )}
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.REJECTED)}>Rejected</ButtonBase>
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.APPROVED)}>Approved</ButtonBase>
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.ALL)}>All</ButtonBase>
        </div>
    );
};
