import React from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import FormField from 'shared/components/formFields/FormField';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { AddressFormBlock } from 'modules/settings/submodules/components/AddressFormBlock/AddressFormBlock';
import { IControllingOrgFormValues } from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgForm/model';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { FormButtonBlock } from 'modules/settings/common/components/FormButtonBlock/FormButtonBlock';
import { BNumberStartAdornment } from 'shared/utils/formatters/bNumber';

export interface IControllingOrgFormProps extends FormikProps<IControllingOrgFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onCancel?: () => void;
}

export function ControllingOrgForm({
    handleSubmit,
    setFieldValue,
    submitTitle,
    onCancel,
    isLoading = false,
    isDisabled = false,
}: IControllingOrgFormProps) {

    const classes = useSettingsFormStyles();

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
            <Box className={classes.formBlock}>
                <Box width="100%">
                    <FormField
                        name="name"
                        label="Controlling org name"
                        className={classes.input}
                    />
                </Box>
                <Box width="calc(50% - 16px)" pl={2}>
                    <FormFieldWithSeparateLabel
                        name="b_number"
                        outerLabel="B number"
                        startAdornment={BNumberStartAdornment}
                        labelClassName={classes.inlineLabel}
                    />
                </Box>
            </Box>

            <AddressFormBlock
                title={'Controlling Org Address'}
                setFieldValue={setFieldValue}
                hideAddress2
                hideCounty
                eachFieldOnNewLine
            />

            <FormButtonBlock submitTitle={submitTitle}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onCancel={onCancel}
            />
        </form>
    );
}
