import {
    addCcpSplitItem,
    CcpSplitActions, CcpSplitAmountActions, changeSplitAmountTotal,
    changeSplitItemNote,
    removeCcpSplitItem,
    setOpenSplitCcpSidebar,
} from './actions';
import { isLoadingReducer, singleValueReducer } from '../../../../../store/reducerUtils';
import { combineReducers } from 'redux';
import { ICcpSplitItem } from '../../../models/ccpModel';
import moment from 'moment';
import { splitCcpTransaction } from '../../../store/actions';

const splitSideBarSelectedTransactionId = singleValueReducer(setOpenSplitCcpSidebar.action, false);

export interface ICcpSplitTableState<Item> {
    items: Item[];
    isLoading: boolean;
}

export const defaultSplitState = {
    items: [],
    isLoading: false,
};

export function ccpSplitTable(
    state: ICcpSplitTableState<ICcpSplitItem>,
    action: CcpSplitActions,
) : ICcpSplitTableState<ICcpSplitItem> {
    state = state || defaultSplitState;
    switch (action.type) {
        case addCcpSplitItem.action:
            state.items.push({ id: String(moment().unix()), amount: '0.00' } as ICcpSplitItem);
            return state;
        case removeCcpSplitItem.action:
            state.items = state.items.filter(x => x.id !== action.payload);
            return state;
        case changeSplitItemNote.action:
            state.items = state.items.map(x => {
                const payload = action.payload as ICcpSplitItem;
                if (x.id === payload.id) {
                    x.note = payload.note;
                    x.department_id = payload.department_id;
                    x.transaction_code_id = payload.transaction_code_id;
                    x.amount = payload.amount;
                    return x;
                } else {
                    return x;
                }
            });
            return state;
        case setOpenSplitCcpSidebar.action:
            return {
                items: [
                    { id: String(moment().unix()), amount: '0.00' } as ICcpSplitItem,
                    { id: String(moment().unix() + 1), amount: '0.00' } as ICcpSplitItem,
                ],
                isLoading: false,
            } as ICcpSplitTableState<ICcpSplitItem>;
        default:
            return state;
    }
}

export function ccpSplitAmountTotal(
    state: number | undefined,
    action: CcpSplitAmountActions,
) : number | undefined {
    state = state || 0;
    switch (action.type) {
        case changeSplitAmountTotal.action:
            return action.payload;
        default:
            return state;
    }
}

export const isCcpSplitSubmitting = isLoadingReducer(splitCcpTransaction, false);

export const splitCcpTransactions = combineReducers({
    splitSideBarSelectedTransactionId,
    ccpSplitTable,
    isCcpSplitSubmitting,
    ccpSplitAmountTotal,
});
