import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import TimeAndExpensePage from 'modules/clients/content/TimeAndExpensePage/TimeAndExpensePage';
import { ClientDashboard } from 'modules/dashboard/ClientDashboard';
import { EmployeePortal } from 'modules/employeePortal/EmployeePortal';
import Employees from 'modules/employees/Employees';
import EmploymentInfo from 'modules/employmentInfo/EmploymentInfo';
import { EmploymentManagementRoutes } from 'modules/employmentManagement/EmploymentManagementRoutes';
import Evaluations from 'modules/evaluations/Evaluations';

import Onboarding from 'modules/onboarding/Onboarding';
import { ReportsPage } from 'modules/reports/ReportsPage';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { TravelExpenses } from 'modules/travelExpenses/TravelExpenses';
import { UsersModuleRoutes } from 'modules/users/UsersModuleRoutes';

import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { routes } from 'shared/routes';
import { Permission } from 'store/components/auth/authModels';
import {
    selectImpersonationEnabled,
    selectZohoAnalyticsPermalink,
} from 'store/entities/clients/selectors/configurationSelectors';
import { getActivities } from 'store/entities/configuration/configurationAction';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { CcpRoutes } from '../../ccp/CcpRoutes';

import OfferLetterModule from '../../offerLetter/OfferLetterModule';
import ExpenseApproval from './expenseApproval/ExpenseApproval';
import TimeApproval from './timeApproval/TimeApproval';

const useStyles = makeHighPriorityStyles({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});

const Content: React.FC = () => {
    const classes = useStyles();
    const isImpersotationEnabled = useSelector(selectImpersonationEnabled);
    const zohoPermalink = useSelector(selectZohoAnalyticsPermalink);
    const hasPermalink = Boolean(zohoPermalink);
    useWithClientEffect((dispatch => {
        dispatch(getActivities.init());
    }));

    return (
        <div className={classes.container}>
            <Switch>
                <PrivateRoute
                    path={routes.CLIENT.DASHBOARD}
                    component={ClientDashboard}
                    exact
                />

                {hasPermalink && (
                    <PrivateRoute
                        component={ReportsPage}
                        path={routes.CLIENT.REPORTS}
                        permission={Permission.ViewReports}
                        exact
                    />
                )}

                <PrivateRoute
                    path={routes.CLIENT.EMPLOYEES}
                    component={Employees}
                    permission={Permission.SubmitSheets}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.EMPLOYMENT_INFO}
                    component={EmploymentInfo}
                    permission={Permission.SubmitSheets}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.OFFER_LETTER.ROOT}
                    component={OfferLetterModule}
                    anyPermissionFrom={[
                        Permission.ViewClientOffersOwn,
                        Permission.ViewClientOffersAll,
                    ]}
                />
                <PrivateRoute
                    path={routes.CLIENT.ONBOARDING}
                    component={Onboarding}
                    permission={Permission.SubmitSheets}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.EVALUATIONS}
                    component={Evaluations}
                    permission={Permission.SubmitSheets}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.TIME_AND_EXPENSE.ROOT}
                    component={TimeAndExpensePage}
                    waitOptimizely
                />
                <PrivateRoute
                    path={routes.CLIENT.TIME_APPROVAL.ROOT}
                    component={TimeApproval}
                    anyPermissionFrom={[Permission.ViewSheetApprovalsOwn, Permission.ViewSheetApprovalsAll]}
                    waitOptimizely
                />
                <PrivateRoute
                    path={routes.CLIENT.EXPENSE_APPROVAL.ROOT}
                    component={ExpenseApproval}
                    anyPermissionFrom={[Permission.ViewSheetApprovalsOwn, Permission.ViewSheetApprovalsAll]}
                    waitOptimizely
                />
                <PrivateRoute
                    path={routes.CLIENT.TRAVEL_EXPENSES.ROOT}
                    component={TravelExpenses}
                    anyPermissionFrom={[
                        Permission.ManageTravelExpenses,
                        Permission.ApproveSheets,
                    ]}
                />
                <PrivateRoute
                    path={routes.CLIENT.EMPLOYEE_PORTAL}
                    component={EmployeePortal}
                    permission={Permission.ViewMyOffers}
                    blockImpersonate
                />
                <PrivateRoute
                    path={routes.CLIENT.EMPLOYMENT_MANAGEMENT.ROOT}
                    component={EmploymentManagementRoutes}
                    anyPermissionFrom={[
                        Permission.ManageEaf,
                        Permission.ManageSubassignments,
                    ]}
                    componentProps={{
                        sectionTitle: 'Employment Management',
                    }}
                />
                <PrivateRoute
                    path={routes.CLIENT.CCP.ROOT}
                    component={CcpRoutes}
                    componentProps={{
                        sectionTitle: 'Credit Card Portal',
                    }}
                />
                {isImpersotationEnabled && (
                    <PrivateRoute
                        path={routes.CLIENT.USERS.ROOT}
                        component={UsersModuleRoutes}
                        permission={Permission.Impersonate}
                        componentProps={{
                            sectionTitle: 'Users',
                        }}
                    />
                )}
                <Redirect
                    // Temporary redirect for support BE employee link
                    exact
                    path="/rti/offer-letter/:id"
                    to={routes.EMPLOYEE_OFFER_LETTER.DETAIL}
                />
                <Redirect exact from={routes.CLIENT.ROOT}
                    to={routes.CLIENT.DASHBOARD}/>
            </Switch>
        </div>
    );
};

export default Content;
