import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { Ref, useCallback, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { difference } from 'lodash-es';
import { useField, useFormikContext } from 'formik';
import {
    Checkbox, Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { inputParams } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { IFormFieldProps } from '../formFields/models';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useStyles = makeHighPriorityStyles(() => ({
    popupIndicator: {
        marginRight: -10,
    },
    listbox: {
        overflowX: 'hidden !important',
    },
}));

// @ts-ignore
interface ICustomAutocompleteProps<T> extends IFormFieldProps, Omit<AutocompleteProps<T>, 'renderInput'> {
    options: Array<T>;
    getKey: (item: T) => string;
    getText: (item: T) => string;
    inputRef?: Ref<HTMLDivElement>;
    tooltip?: string;
    placeholder?: string;
    Label?: React.ReactNode;
    isLoading?: boolean;
    endAdornment?: React.ReactNode;
    maxChips?: number;
    useSelectAll?: boolean;
    disableSmartSelectAll?: boolean;
    onSetSelectedAll?: (value: boolean) => void;
    initialAllSelected?: boolean;
    customSelectAllOption?: T;
}

const selectAllOption = {
    key: 'all',
    text: 'All',
};

export default function FormMultipleAutocomplete({
    name,
    label,
    options,
    className,
    getKey,
    getText,
    placeholder,
    disabled = false,
    id = name,
    tooltip = '',
    isLoading = false,
    onChanged,
    endAdornment,
    outerLabel,
    onSetSelectedAll,
    initialAllSelected,
    useIdValue,
    maxChips = 2,
    useSelectAll = false,
    disableSmartSelectAll = false,
    customSelectAllOption = selectAllOption,
    ...props
}: ICustomAutocompleteProps<any>,
) {
    const formHelperTextClasses = useFormHelperTextStyles();
    const classes = useStyles();
    const formikContext = useFormikContext<any>();
    const [field, meta, helpers] = useField(name);

    const errorMessage = meta.error || (formikContext?.errors || {})[name];
    const hasError = Boolean(errorMessage && meta.touched);

    const optionsIds = useMemo(() => {
        return options.map(option => getKey(option));
    }, [options, getKey]);
    const initialSelectedInnerIds = useMemo(() => {
        return (field.value || []).map((value: any) => useIdValue ? value : getKey(value));
    }, [field.value, getKey, useIdValue]);
    const initialAllSelectedInner = useMemo(() => {
        if (initialAllSelected !== undefined || disableSmartSelectAll) {
            return Boolean(initialAllSelected);
        }
        return difference(optionsIds, initialSelectedInnerIds).length === 0;
    }, [initialAllSelected, disableSmartSelectAll, optionsIds, initialSelectedInnerIds]);

    const [allSelected, setAllSelectedValue] = useState(initialAllSelectedInner);
    const setAllSelected = useCallback((value: boolean) => {
        setAllSelectedValue(value);
        if (onSetSelectedAll) {
            onSetSelectedAll(value);
        }
    }, [onSetSelectedAll, setAllSelectedValue]);

    const initialInnerValues = useMemo(() => {
        return [
            ...(allSelected ? [customSelectAllOption] : []),
            ...options.filter(option => initialSelectedInnerIds.includes(getKey(option))),
        ];
    }, [allSelected, customSelectAllOption, options, initialSelectedInnerIds, getKey]);
    const [innerValue, setInnerValue] = useState(initialInnerValues);

    const onChange = useCallback(
        (_, changedValues: any[]) => {
            let newInnerValueIds = changedValues.map(option => getKey(option));
            let allOptionChecked = newInnerValueIds.includes(selectAllOption.key);
            if (useSelectAll) {
                const optionIds = options.map(option => getKey(option));
                if (allOptionChecked && !allSelected) {
                    // Check all option & all items
                    setAllSelected(true);
                    newInnerValueIds = optionIds;
                } else if (allSelected && !allOptionChecked) {
                    // Uncheck all option & all items
                    setAllSelected(false);
                    newInnerValueIds = [];
                } else if (allSelected && allOptionChecked && optionIds.length + 1 !== newInnerValueIds.length) {
                    // Unchecked some item, so we need to uncheck 'all' option
                    setAllSelected(false);
                    allOptionChecked = false;
                } else if (
                    !disableSmartSelectAll
                    && !allSelected
                    && !allOptionChecked
                    && optionIds.length + 1 === newInnerValueIds.length
                ) {
                    // Checked all options exclude all
                    setAllSelected(true);
                    allOptionChecked = true;
                }
            }

            const objectFieldValues = options.filter(option => newInnerValueIds.indexOf(getKey(option)) > -1);
            const newFieldValue = objectFieldValues.map(option => useIdValue ? getKey(option) : option);
            setInnerValue([
                ...(allOptionChecked ? [customSelectAllOption] : []),
                ...objectFieldValues,
            ]);
            helpers.setValue(newFieldValue);
            helpers.setTouched(true);
            if (onChanged) {
                onChanged(objectFieldValues);
            }
        },
        [
            useSelectAll,
            options,
            helpers,
            onChanged,
            allSelected,
            getKey,
            setAllSelected,
            useIdValue,
            disableSmartSelectAll,
            customSelectAllOption,
        ],
    );

    const onBlur = () => {
        helpers.setTouched(false);
    };

    const renderInput = useCallback((inputProps: any) => (
        <TextField
            error={hasError && meta.error}
            {...inputProps}
            label={label}
            variant="outlined"
            placeholder={placeholder}
            inputProps={{
                ...inputParams,
                ...inputProps.inputProps,
            }}
            InputProps={{
                ...inputProps.InputProps,
                endAdornment: (
                    <>
                        {isLoading ? <CircularProgress color="primary" size={16}/> : null}
                        {inputProps.InputProps.endAdornment}
                        {endAdornment}
                    </>
                ),
            }}
        />
    ), [endAdornment, hasError, isLoading, label, meta.error, placeholder]);

    const renderOption = useCallback((option, { selected }) => (
        <React.Fragment>
            <Checkbox
                style={{ marginRight: 8 }}
                checked={selected || allSelected}
            />
            {getText(option)}
        </React.Fragment>
    ), [getText, allSelected]);

    const renderTags = useCallback((value, getTagProps) => {
        const chipsToShow = [...value].splice(0, maxChips);
        const more = value.length - maxChips;
        return (
            <>
                {allSelected ? (
                    <Chip
                        label="All"
                        size="medium"
                    />
                ) : (
                    <>
                        {chipsToShow.map((option, index) => (
                            <Chip
                                key={getText(option)}
                                label={getText(option)}
                                size="medium"
                                {...getTagProps({ index })}
                            />
                        ))}
                        {more > 0 && `+${more}`}
                    </>
                )}
            </>
        );
    }, [allSelected, getText, maxChips]);

    const innerOptions = useMemo(
        () => [...(useSelectAll ? [customSelectAllOption] : []), ...options],
        [options, useSelectAll, customSelectAllOption],
    );

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            {outerLabel && (
                <label
                    htmlFor={id}
                    className={formHelperTextClasses.outerLabel}
                >
                    {outerLabel}
                </label>
            )}
            <Tooltip title={tooltip}>
                <Autocomplete
                    renderInput={renderInput}
                    renderOption={renderOption}
                    {...props}
                    limitTags={maxChips}
                    options={innerOptions}
                    value={innerValue}
                    disabled={disabled}
                    id={id}
                    onChange={onChange}
                    onBlur={onBlur}
                    multiple
                    openOnFocus
                    getOptionLabel={getText}
                    renderTags={renderTags}
                    popupIcon={<ArrowDropDownIcon fontSize="small"/>}
                    classes={{
                        popupIndicator: classes.popupIndicator,
                        listbox: classes.listbox,
                    }}
                />
            </Tooltip>
            {hasError && (
                <FormHelperText classes={formHelperTextClasses}>
                    {meta.error}
                </FormHelperText>
            )}
        </FormControl>
    );
}
