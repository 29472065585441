import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { IPrismMappingField } from 'store/entities/customFields/model';
import { selectCustomFieldsMappings, selectIsLoadingCustomFieldMapping } from 'store/entities/customFields/selectors';

interface IMappingFieldSelectorProps extends IFormFieldProps, IFormSelect {}

export const MappingFieldSelector = (props: IMappingFieldSelectorProps) => {
    const availableMappings = useSelector(selectCustomFieldsMappings);
    const isAvailableMappingsLoading = useSelector(selectIsLoadingCustomFieldMapping);

    return (
        <FormSelect
            getKey={(role: IPrismMappingField) => role.key}
            getText={(role: IPrismMappingField) => role.name}
            options={availableMappings}
            isLoading={isAvailableMappingsLoading}
            {...props}
        />
    );
};
