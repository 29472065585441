import { DateBackend } from 'shared/models/Dates';
import { ISheetCommonBackend, IStatus, ITimeEntry, ITimeSheetBackend } from 'shared/models/sheet/Sheet';
import { ITimeEntryCreate, ITimeEntryUpdate } from 'store/entities/timesheet/models/Entry';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { ILoadSheetsPayload } from 'store/entities/timesheet/models/SheetApi';
import { ActionsReturnTypes, createActions, createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { IUpdateTemporaryEntry } from './commonActions';

/**
 * LOAD_TIME_SHEETS
 */

export const loadTimeSheetsWithEntries = createRequestActions<ILoadSheetsPayload, ITimeSheetBackend[]>(
    RequestType.Get,
    'TIME_SHEETS_WITH_ENTRIES',
    'time_sheets',
);

export const loadTimeSheets = createRequestActions<ILoadSheetsPayload, ISheetCommonBackend[]>(
    RequestType.Get,
    'TIME_SHEETS',
    'time_sheets',
);

export const REMOVE_TIME_SHEET = 'timesheet/REMOVE_TIME_SHEET';
export const removeTimeSheet = createSingleAction<
string,
typeof REMOVE_TIME_SHEET
>(
    REMOVE_TIME_SHEET,
);

/**
 * UPDATE_TIME_SHEETS
 */

const UPDATE_TIME_SHEETS = 'time_sheets/UPDATE';
const UPDATE_TIME_SHEETS_SUCCESS = 'time_sheets/UPDATE_SUCCESS';
const UPDATE_TIME_SHEETS_ERROR = 'time_sheets/UPDATE_ERROR';

export const updateTimeSheets = createActions<
boolean,
Array<ITimeSheetBackend>,
any,
    typeof UPDATE_TIME_SHEETS,
    typeof UPDATE_TIME_SHEETS_SUCCESS,
    typeof UPDATE_TIME_SHEETS_ERROR
>(
    UPDATE_TIME_SHEETS,
    UPDATE_TIME_SHEETS_SUCCESS,
    UPDATE_TIME_SHEETS_ERROR,
);

/**
 * CLEAR_EMPTY_TIME_SHEETS_BY_PAY_PERIOD
 */

export const CLEAR_EMPTY_TIME_SHEETS_BY_PAY_PERIOD = 'timesheet/CLEAR_EMPTY_TIME_SHEETS_BY_PAY_PERIOD';
export const clearEmptyTimesheetsByPayPeriod = createSingleAction<
IPayPeriod,
typeof CLEAR_EMPTY_TIME_SHEETS_BY_PAY_PERIOD
>(
    CLEAR_EMPTY_TIME_SHEETS_BY_PAY_PERIOD,
);

/**
 * UPDATE_TIME_ENTRY
 */

const UPDATE_TIME_ENTRY = 'time_entry/UPDATE';
const UPDATE_TIME_ENTRY_SUCCESS = 'time_entry/UPDATE_SUCCESS';
const UPDATE_TIME_ENTRY_ERROR = 'time_entry/UPDATE_ERROR';

export const updateTimeEntry = createActions<
ITimeEntryUpdate,
ITimeEntry,
any,
    typeof UPDATE_TIME_ENTRY,
    typeof UPDATE_TIME_ENTRY_SUCCESS,
    typeof UPDATE_TIME_ENTRY_ERROR
>(
    UPDATE_TIME_ENTRY,
    UPDATE_TIME_ENTRY_SUCCESS,
    UPDATE_TIME_ENTRY_ERROR,
);

export const UPDATE_TEMPORARY_TIME_ENTRY = 'timesheet/UPDATE_TEMPORARY_TIME_ENTRY';
export const updateTemporaryTimeEntry = createSingleAction<
IUpdateTemporaryEntry<ITimeEntry>,
typeof UPDATE_TEMPORARY_TIME_ENTRY
>(
    UPDATE_TEMPORARY_TIME_ENTRY,
);

/**
 * ADD_TIME_ENTRY
 */

const ADD_TIME_ENTRY = 'time_entry/ADD';
const ADD_TIME_ENTRY_SUCCESS = 'time_entry/ADD_SUCCESS';
const ADD_TIME_ENTRY_ERROR = 'time_entry/ADD_ERROR';

export const addTimeEntry = createActions<
ITimeEntryCreate,
ITimeEntry,
any,
    typeof ADD_TIME_ENTRY,
    typeof ADD_TIME_ENTRY_SUCCESS,
    typeof ADD_TIME_ENTRY_ERROR
>(
    ADD_TIME_ENTRY,
    ADD_TIME_ENTRY_SUCCESS,
    ADD_TIME_ENTRY_ERROR,
);

/**
 * REMOVE_TIME_ENTRY
 */

const REMOVE_TIME_ENTRY = 'time_entry/REMOVE';
const REMOVE_TIME_ENTRY_SUCCESS = 'time_entry/REMOVE_SUCCESS';
const REMOVE_TIME_ENTRY_ERROR = 'time_entry/REMOVE_ERROR';

export const removeTimeEntry = createActions<
string,
string,
any,
    typeof REMOVE_TIME_ENTRY,
    typeof REMOVE_TIME_ENTRY_SUCCESS,
    typeof REMOVE_TIME_ENTRY_ERROR
>(
    REMOVE_TIME_ENTRY,
    REMOVE_TIME_ENTRY_SUCCESS,
    REMOVE_TIME_ENTRY_ERROR,
);

/**
 * GET_STATUSES
 */

const GET_TIME_STATUSES = 'GET_TIME_STATUSES';
const GET_TIME_STATUSES_SUCCESS = 'GET_TIME_STATUSES_SUCCESS';
const GET_TIME_STATUSES_ERROR = 'GET_TIME_STATUSES_ERROR';

export const getTimeStatuses = createActions<void, Array<IStatus>, any,
typeof GET_TIME_STATUSES,
typeof GET_TIME_STATUSES_SUCCESS,
typeof GET_TIME_STATUSES_ERROR
>(
    GET_TIME_STATUSES,
    GET_TIME_STATUSES_SUCCESS,
    GET_TIME_STATUSES_ERROR,
);

export interface ITimeEntryPatchEntriesDateSpecificBySheetIdParams {
    sheetId: string,
    date: DateBackend,
    isPerDiem: boolean,
}
export const patchEntriesDateSpecificBySheetId = createRequestActions<
ITimeEntryPatchEntriesDateSpecificBySheetIdParams,
Array<ITimeSheetBackend>
>(RequestType.PATCH, 'TIME_ENTRIES_DATE_SPECIFIC_BY_SHEET', 'time/');

export interface ITimeSheetPatchingById {
    sheetId: string;
    state: boolean;
}
export const SET_TIME_SHEET_IS_PATCHING = 'timesheet/SET_TIME_SHEET_IS_PATCHING';
export const setTimeSheetPatchingById = createSingleAction<
ITimeSheetPatchingById,
typeof SET_TIME_SHEET_IS_PATCHING
>(
    SET_TIME_SHEET_IS_PATCHING,
);

export type TimeSheetActions =
    | ActionsReturnTypes<typeof loadTimeSheetsWithEntries>
    | ActionsReturnTypes<typeof loadTimeSheets>
    | ActionsReturnTypes<typeof updateTimeSheets>
    | ActionsReturnTypes<typeof addTimeEntry>
    | ActionsReturnTypes<typeof removeTimeEntry>
    | ActionsReturnTypes<typeof updateTimeEntry>
    | ActionsReturnTypes<typeof getTimeStatuses>
    | ReturnType<typeof clearEmptyTimesheetsByPayPeriod>
    | ReturnType<typeof setTimeSheetPatchingById>
    | ReturnType<typeof updateTemporaryTimeEntry>;
