import {
    IOfferLetterTemplate,
    IOfferLetterTemplateBackend,
    mapOfferLetterTemplateFromBackend,
} from 'modules/offerLetter/store/templates/models';
import {
    ICreateOfferLetterTemplate,
    IOfferLetterTemplateParam,
    IUpdateOfferLetterTemplate,
} from 'modules/settings/submodules/offerLetters/store/models';

import baseApi from 'shared/utils/baseApi';

interface IUploadTemplateResponse {
    filename: string;
}
interface IGetTemplateParamsResponse {
    template_params: IOfferLetterTemplateParam[];
}

export const offerLetterTemplateApi = {
    async createTemplate(request: ICreateOfferLetterTemplate): Promise<IOfferLetterTemplate> {
        const { data } = await baseApi.create<ICreateOfferLetterTemplate, IOfferLetterTemplateBackend>(
            'documents',
            'offer_letters/templates',
            request,
        );
        return mapOfferLetterTemplateFromBackend(data);
    },
    async uploadTemplate(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('template', file);
        const { data } = await baseApi.post<FormData, IUploadTemplateResponse>(
            'documents/offer_letters/templates/upload',
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            },
        );
        return data.filename;
    },
    async updateTemplate(templateId: string, update: IUpdateOfferLetterTemplate): Promise<IOfferLetterTemplate> {
        const { data } = await baseApi.patch<IUpdateOfferLetterTemplate, IOfferLetterTemplateBackend>(
            `documents/offer_letters/templates/${templateId}`,
            update,
        );
        return mapOfferLetterTemplateFromBackend(data);
    },
    async getTemplateParams(): Promise<IOfferLetterTemplateParam[]> {
        const { data } = await baseApi.get<IGetTemplateParamsResponse>(
            'documents/offer_letters/template_params',
        );
        return data.template_params;
    },
};
