import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import React from 'react';
import {
    LinearProgress,
} from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { getPasswordStrength } from 'shared/utils/counters/getPasswordStrength';

export interface IPasswordStrengthIndicatorProps {
    password: string;
}

const useStyles = makeHighPriorityStyles(() => ({
    green: {
        '& .MuiLinearProgress-bar': {
            backgroundColor: colors.green,
        },
    },
    orange: {
        '& .MuiLinearProgress-bar': {
            backgroundColor: colors.orange,
        },
    },
    red: {
        '& .MuiLinearProgress-bar': {
            backgroundColor: colors.error,
        },
    },
}));

const PasswordStrengthIndicator = ({
    password,
}: IPasswordStrengthIndicatorProps) => {
    const goodThreshold = 80;
    const weakThreshold = 40;
    const classes = useStyles();
    const score = getPasswordStrength(password);
    let currentClass = classes.red;
    let title = 'Bad password';
    if (score > weakThreshold) {
        currentClass = classes.orange;
        title = 'Weak password';
    }
    if (score > goodThreshold) {
        currentClass = classes.green;
        title = 'Good password';
    }

    return (
        <LinearProgress
            title={title}
            className={currentClass}
            variant="determinate"
            value={score}
        />
    );
};

export default PasswordStrengthIndicator;
