export interface IPaginationState {
    page: number;
    page_size?: number;
    total_items: number;
}

export interface IPaginationResponse<Items> extends IPaginationState {
    items: Items[];
}

export const initialPaginationState = {
    page: 1,
    page_size: 50,
    total_items: 0,
};
