import { combineReducers } from 'redux';
import { clientsSettings } from '../submodules/clients/store/reducer';
import { platformUsers } from '../submodules/platformUsers/store/reducer';
import { offerLetterTemplate } from 'modules/settings/submodules/offerLetters/store/reducers';
import { customFields } from 'modules/settings/submodules/customFields/store/reducers';
import { employees } from 'modules/settings/submodules/employees/store/reducers';

export const settings = combineReducers({
    clientsSettings,
    platformUsers,
    offerLetterTemplate,
    customFields,
    employees,
});
