import React from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import { ArrowForwardIos } from '@material-ui/icons';
import { Box, Button, Checkbox } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { colors } from 'shared/styles/constants';
import { getLastFirstName } from 'shared/utils/converters/user';
import { selectHighlightAssignmentsIds } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { isAssignmentActive } from 'shared/utils/helpers/assingmentsHelper';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { selectEmployeeProfilesByIds } from 'modules/offerLetter/store/selectors';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { selectUserById } from 'store/entities/users/selectors';

export interface IAssignmentTableGroupHeaderBaseProps {
    assignmentId: string;
    isOpened: boolean;
    onOpen: () => void;
    hasChildren?: boolean;
    style?: any;
}

interface IAssignmentTableGroupHeaderProps extends IAssignmentTableGroupHeaderBaseProps {
    useCheckbox?: boolean;
    groupChecked?: boolean;
    onCheckGroup?: () => void;
}

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        minHeight: theme.spacing(5),
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        transition: 'background-color 0.3s',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${ colors.lightGray }`,
    },
    detailButton: {
        fontSize: 14,
        marginRight: theme.spacing(-1),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        flexGrow: 1,
    },
    headerContent: {
        display: 'flex',
        flexGrow: 1,
        marginLeft: theme.spacing(1),
        padding: theme.spacing(1, 0),
        cursor: 'pointer',
    },
    containerActive: {
        background: colors.lightGreen,
    },
    expandButtonIcon: {
        transition: 'transform 0.15s',
        transform: 'rotate(0) translateX(0)',
    },
    expandButtonIconActive: {
        transform: 'rotate(-180deg) translateX(2px)',
    },
}));

export const AssignmentTableGroupHeader = ({
    assignmentId,
    isOpened,
    onOpen,
    style,
    groupChecked,
    onCheckGroup,
    useCheckbox = false,
    hasChildren = false,
}: IAssignmentTableGroupHeaderProps) => {
    const classes = useStyles();
    const tableClasses = useDefaultTableStyles();

    const assignment = useSelector(selectAssignmentsById)[assignmentId];
    const user = useSelector(selectUserById(assignment?.user_id));
    const employeeProfile = useSelector(selectEmployeeProfilesByIds)[assignment?.employee_profile?.id || ''];

    const arrowIconClassName = clsx(classes.expandButtonIcon, {
        [classes.expandButtonIconActive]: isOpened,
    });

    const highlightedIds = useSelector(selectHighlightAssignmentsIds);
    const highlight = highlightedIds.includes(assignmentId);

    return (
        <Box
            style={style}
            className={clsx(classes.container, { [classes.containerActive]: isOpened || highlight })}
        >
            <Box className={classes.row}>
                {useCheckbox && (
                    <>
                        {isAssignmentActive(assignment) ? (
                            <Checkbox
                                color="primary"
                                checked={groupChecked}
                                onChange={onCheckGroup}
                                classes={{ root: tableClasses.checkbox }}
                            />
                        ) : (
                            <Box
                                display="flex"
                                width="39px"
                                className={tableClasses.checkbox}
                            />
                        )}
                    </>
                )}
                <Box
                    onClick={onOpen}
                    fontWeight={isOpened ? 'fontWeightMedium' : undefined}
                    className={classes.headerContent}
                >
                    {user && getLastFirstName(user)}
                    {' '}
                    {employeeProfile?.prism_employee_id}
                </Box>
            </Box>

            {hasChildren && (
                <Button
                    onClick={onOpen}
                    color="secondary"
                    className={classes.detailButton}
                    data-testid="subassignment-detail-info-button"
                >
                    <ArrowForwardIos
                        className={arrowIconClassName}
                        fontSize="inherit"
                    />
                </Button>
            )}
        </Box>
    );
};
