import { Box } from '@material-ui/core';
import { createPayRangeValidationSchema } from 'modules/settings/submodules/clients/payRanges/components/payRangeCreationForm/createPayRangeValidationSchema';
import { usePositionsWithAvailablePayRange } from 'modules/settings/submodules/clients/payRanges/hooks/usePositionsWithAvailablePayRange';
import { createPayRangeAction } from 'modules/settings/submodules/clients/payRanges/store/actions';
import { selectIsCreatingPayRange } from 'modules/settings/submodules/clients/payRanges/store/selectors';
import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import NumberField from 'shared/components/formFields/NumberField';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { selectAssignmentCustomFieldMapping } from 'store/entities/configuration/configurationSelectors';

interface IPayRangeFormValues {
    positionValueId: string | null,
    locationValueId: string | null,
    min: string,
    max: string,
}

export const PayRangeCreationForm = () => {
    const dispatch = useDispatch();
    const classes = useInlineFormStyles();
    const onSubmit = useCallback((values: IPayRangeFormValues) => {
        dispatch(createPayRangeAction.init({
            position_value_id: values.positionValueId as string,
            location_value_id: values.locationValueId as string,
            min_value: values.min,
            max_value: values.max,
        }));
    }, [dispatch]);
    const formikData = useFormik<IPayRangeFormValues>({
        initialValues: {
            positionValueId: null,
            locationValueId: null,
            min: '',
            max: '',
        },
        validationSchema: createPayRangeValidationSchema,
        onSubmit,
    });
    const { handleSubmit, values } = formikData;
    const customFieldMapping = useSelector(selectAssignmentCustomFieldMapping);
    const isLoading = useSelector(selectIsCreatingPayRange);

    const payRangePositions = usePositionsWithAvailablePayRange();

    return (
        <FormikProvider value={formikData}>
            <form
                onSubmit={handleSubmit}
                className={classes.form}
            >
                <CustomFieldValuesSelect
                    customFieldId={customFieldMapping.location_value_id}
                    name="locationValueId"
                    useIdValue
                    className={classes.input}
                />
                <CustomFieldValuesSelect
                    customFieldId={customFieldMapping.position_value_id}
                    name="positionValueId"
                    useIdValue
                    className={classes.input}
                    valuesToShow={payRangePositions}
                />
                <NumberField
                    outerLabel="Pay Range"
                    name="min"
                    min={0}
                    className={classes.extraShortInput}
                />
                <Box mt={4.5}>-</Box>
                <Box
                    mt={2.1}
                >
                    <NumberField
                        name="max"
                        className={classes.extraShortInput}
                        min={+values.min || 0}
                    />
                </Box>
                <Box
                    mt={2}
                >
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        isLoading={isLoading}
                        data-testid="submit_button"
                    >
                        Associate
                    </ButtonWithLoader>
                </Box>
            </form>
        </FormikProvider>
    );
};
