import {
    ICreatePayRangePayload,
    IPayRangeFilter, IPayRangeResponse,
    IUpdatePayRangePayload,
} from 'modules/settings/submodules/clients/payRanges/store/models';
import { getPayRangeByValue } from 'store/entities/configuration/configurationAction';
import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';

const moduleActionPrefix = 'settings/pay-ranges';

const INITIAL_LOAD_LIST = `${moduleActionPrefix}/INITIAL_LOAD_LIST`;
export const initialLoadPayRanges = createSingleAction<void, typeof INITIAL_LOAD_LIST>(
    INITIAL_LOAD_LIST,
);

export const getMorePayRangesAction = createRequestActions<void, IPayRangeResponse>(
    RequestType.Get,
    'MORE_PAY_RANGES',
    moduleActionPrefix,
);

const SET_PAY_RANGES_FILTER = `${moduleActionPrefix}/SET_PAY_RANGES_FILTER`;
export const setPayRangesFilter = createSingleAction<IPayRangeFilter, typeof SET_PAY_RANGES_FILTER>(
    SET_PAY_RANGES_FILTER,
);

export const createPayRangeAction = createRequestActions<ICreatePayRangePayload, void>(
    RequestType.Create,
    'PAY_RANGE',
    moduleActionPrefix,
);

export const updatePayRangeAction = createRequestActions<IUpdatePayRangePayload, void>(
    RequestType.Update,
    'PAY_RANGE',
    moduleActionPrefix,
);

export type PayRangesActions =
    | ReturnType<typeof initialLoadPayRanges>
    | ReturnType<typeof setPayRangesFilter>
    | ActionsReturnTypes<typeof getMorePayRangesAction>
    | ActionsReturnTypes<typeof createPayRangeAction>
    | ActionsReturnTypes<typeof updatePayRangeAction>
    | ActionsReturnTypes<typeof getPayRangeByValue>
