import { orderBy } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUserHasPermission } from '../../../../store/components/auth/selectors';
import { Permission } from '../../../../store/components/auth/authModels';
import { CcpReconciledID, CcpUnreconciledID } from '../../models/ccpModel';
import { selectCcpEditMode } from 'modules/ccp/store/selectors';
import { AttachmentInput } from 'shared/components/formFields/AttachmentInput/AttachmentInput';
import { AttachmentsPreview } from 'shared/components/attachments/AttachmentsPreview';
import { addNewCcpAttachment, removeCcpAttachment, setCcpFileRead, updateCcpAttachments } from './store/actions';
import { ICcpTransaction } from 'modules/ccp/models/CcpTransaction';
import { IAttachment } from 'shared/models/Attachments';
import { Form, Formik } from 'formik';
import { useCcpMainStyles } from 'modules/ccp/styles';

interface IEntryFilesUploaderProps {
    entry: ICcpTransaction;
}

export default function CcpFilesUploader(
    {
        entry,
    }: IEntryFilesUploaderProps,
) {
    const classes = useCcpMainStyles();
    const dispatch = useDispatch();

    const hasEditPermissionUnreconsiled = useSelector(selectIsUserHasPermission(Permission.CanEditCcpUnreconciled));
    const hasEditPermissionReconciled = useSelector(selectIsUserHasPermission(Permission.CanEditCcpReconciled));
    const isEditMode = useSelector(selectCcpEditMode);
    const ableToEdit = (transaction_status_id: string): boolean => {
        return (hasEditPermissionUnreconsiled && transaction_status_id === CcpUnreconciledID)
            || (
                hasEditPermissionReconciled
                && transaction_status_id === CcpReconciledID
                && isEditMode
            );
    };
    const canEdit = entry ? ableToEdit(entry.transaction_status_id) : false;

    const onAttachmentChange = useCallback(
        (attachments: IAttachment[]) => {
            if (!canEdit) {
                return;
            }
            dispatch(setCcpFileRead(true));
            attachments.forEach(attachment => {
                const currentIds = entry.attachments.map(item => item.id);
                if (!currentIds.includes(attachment.id)) {
                    dispatch(addNewCcpAttachment.init({
                        ...attachment,
                        // @ts-ignore
                        related_entity_id: entry.id,
                    }));
                }
            });
            dispatch(setCcpFileRead(false));
        }, [dispatch, entry, canEdit]);

    const onAttachmentRemove = useCallback(
        (attachment: IAttachment) => {
            if (!canEdit) {
                return;
            }
            dispatch(removeCcpAttachment.init({
                ...attachment,
                transaction_id: entry.id,
                related_entity_id: entry.id,
            }));
            dispatch(updateCcpAttachments({
                attachmentId: attachment.id,
                entryId: entry.id,
            }));
        }, [dispatch, entry, canEdit]);

    const orderedAttachments = useMemo(() => {
        if (!entry) {
            return [];
        }
        //prefer to display images
        const orderedRaw = orderBy(
            (entry?.attachments || []).filter(x => x.transaction_id === entry.id),
            attachment => {
                if (attachment.mimetype !== 'application/pdf') {
                    return 0;
                }
                return 1;
            },
        );
        return [...new Set(orderedRaw)];
    }, [entry]);

    return (
        <Formik
            initialValues={ { attachments: [...orderedAttachments] } }
            onSubmit={() => undefined}
        >
            <Form>
                {orderedAttachments.length > 0 && !canEdit && (
                    <AttachmentsPreview
                        attachments={orderedAttachments}
                        showModalFromCount={ 0 }
                    />
                )}
                {canEdit && (
                    <AttachmentInput
                        name="attachments"
                        relatedEntityId={entry.id}
                        relatedEntityType="ccp_receipt"
                        text="Upload an attachment"
                        note="a file up to 10MB"
                        multiple
                        maxSizeMb={10}
                        rejectMessage={null}
                        className={classes.attachmentInput}
                        onAttachmentChange={onAttachmentChange}
                        onAttachmentRemove={onAttachmentRemove}
                        showModalFromCount={0}
                    />
                )
                }
            </Form>
        </Formik>
    );
}
