import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { getApproversValidationSchemaObject } from 'modules/subassignmentManagement/hooks/getApproversValidationSchemaObject';
import { IBulkCreateSubAssignmentForm } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/types';
import { useSubassignmentCustomFieldsValidationSchema } from 'modules/subassignmentManagement/components/EditSubAssignment/hooks/useSubassignmentCustomFieldsValidationSchema';
import { datesSchema } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSubAssignmentForm/validationsSchema';

export const useCreateSubAssignmentValidationSchema = (numberOfApprovers = 0) => {
    const customFieldValues = useSubassignmentCustomFieldsValidationSchema(true);
    const customFieldValue = useSubassignmentCustomFieldsValidationSchema(false);

    const approversSchema = getApproversValidationSchemaObject(false, numberOfApprovers);

    // @ts-ignore
    return yup.object().shape<IBulkCreateSubAssignmentForm>({
        overrideRate: yup.number().nullable().required(ValidationMessages.REQUIRED),
        customFieldValues,
        customFieldValue,
    }).concat(approversSchema)
        .concat(datesSchema);
};
