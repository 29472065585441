import React from 'react';
import { useField } from 'formik';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import { shortDateFormat } from 'shared/models/Dates';
import { IDateComponentProps } from '../types';

export const EndDateComponent = ({ classes }: IDateComponentProps) => {
    const [startDate] = useField<string>('startDate');
    const startDateValue = startDate.value;

    return (
        <DayPickerField
            name="endDate"
            format={shortDateFormat}
            placeholder={shortDateFormat}
            outerLabel="End Date"
            className={classes.input}
            withKeyboard
            minDate={startDateValue}
        />
    );
};
