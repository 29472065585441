import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import { getFieldValueName } from 'store/entities/customFields/helpers';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { ActionCreatorKnownArgs } from 'store/utils';
import React, { useCallback } from 'react';
import { IEntity, INamedEntity } from 'shared/models/Entity';
import { getAreaText } from 'shared/components/selects/utils';
import {
    selectActivitiesById, selectAreasById,
    selectAssignmentsById, selectJobNumbersById, selectLocationsById, selectPositionsById,
    selectTasksById,
} from 'store/entities/configuration/configurationSelectors';
import EntityFilterChip from 'shared/components/filters/EntityFilterChip';
import { Box } from '@material-ui/core';
import { FiltersNames, IFiltersAllOptions } from 'shared/models/Filters';
import { selectUsersById } from 'store/entities/users/selectors';
import { getLastFirstName } from 'shared/utils/converters/user';
import { getFormattedPayPeriod } from 'shared/models/Dates';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import { IJobNumber } from 'shared/models/JobNumber';
import { set } from 'lodash-es';

interface IEntityWithDescription extends IEntity {
    description: string;
}

const getTextBase = (entry: IEntityWithDescription) => entry.description;
const getTextNamed = (entry: INamedEntity) => entry.name;

interface IFilterChipsProps {
    className?: string;
    selector: (state: IStore) => Partial<Record<FiltersNames, string>>;
    action: ActionCreatorKnownArgs<
    Partial<Record<FiltersNames, string>>,
    { type: string; payload: Partial<Record<FiltersNames, string>> }
    >;
}

export default function FiltersChips({ selector, action, className = '' }: IFilterChipsProps) {
    const dispatch = useDispatch();
    const filters = useSelector(selector);

    const onDelete = useCallback((name: keyof IFiltersAllOptions) => {
        const newFilter = set({ ...filters }, name, '');
        dispatch(action(newFilter));
    }, [dispatch, action, filters]);

    const positionsById = useSelector(selectPositionsById);
    const usersById = useSelector(selectUsersById);
    const locationsById = useSelector(selectLocationsById);
    const areasById = useSelector(selectAreasById);
    const assignmentsById = useSelector(selectAssignmentsById);
    const activitiesById = useSelector(selectActivitiesById);
    const jobNumbersById = useSelector(selectJobNumbersById);
    const tasksById = useSelector(selectTasksById);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);

    const hasFilters = isNotEmpty(filters);

    return hasFilters ? (
        <Box className={className} data-testid={FiltersTestIds.ChipsWrapper}>
            <EntityFilterChip
                values={locationsById}
                name={FiltersNames.Location}
                id={filters.location_id}
                onDelete={onDelete}
                getText={getTextNamed}
            />

            <EntityFilterChip
                values={positionsById}
                name={FiltersNames.Position}
                id={filters.position_id}
                onDelete={onDelete}
                getText={getTextNamed}
            />

            <EntityFilterChip
                values={areasById}
                name={FiltersNames.Area}
                id={filters.area_id?.toString()}
                onDelete={onDelete}
                getText={getAreaText}
            />

            <EntityFilterChip
                values={assignmentsById}
                name={FiltersNames.Assignment}
                id={filters.assignment_id}
                onDelete={onDelete}
                getText={getTextBase}
            />

            <EntityFilterChip
                values={tasksById}
                name={FiltersNames.Task}
                id={filters.task_id}
                onDelete={onDelete}
                getText={getTextBase}
            />

            <EntityFilterChip
                values={jobNumbersById}
                name={FiltersNames.JobNumber}
                id={filters.job_number_id}
                onDelete={onDelete}
                getText={(entry: IJobNumber) => String(entry.job_number)}
            />

            <EntityFilterChip
                values={activitiesById}
                name={FiltersNames.Activity}
                id={filters.activity_id}
                onDelete={onDelete}
                getText={getTextBase}
            />

            <EntityFilterChip
                values={usersById}
                name={FiltersNames.User}
                id={filters.user_id}
                onDelete={onDelete}
                getText={getLastFirstName}
            />

            {filters.pay_period && (
                <EntityFilterChip
                    values={{ [filters.pay_period]: filters.pay_period }}
                    name={FiltersNames.PayPeriod}
                    id={filters.pay_period}
                    onDelete={onDelete}
                    // @ts-ignore
                    getText={getFormattedPayPeriod}
                />
            )}

            {Object.keys(filters?.customFieldValues || {}).map(fieldId => (
                <EntityFilterChip
                    key={fieldId}
                    values={customFieldValuesByIds}
                    name={`customFieldValues.${fieldId}`}
                    // @ts-ignore
                    id={filters.customFieldValues[fieldId]}
                    onDelete={onDelete}
                    getText={getFieldValueName}
                />
            ))}
        </Box>
    ) : null;
}
