import { updateActivityStatusWatcher } from 'modules/settings/submodules/clients/activities/components/ClientActivitiesTable/components/UpdateStatusSelect/store/sagas';
import { createActivity } from 'modules/settings/submodules/clients/store/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import { api } from 'modules/settings/submodules/clients/store/api';
import { deleteActivity, getActivities } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';

export function* createActivitySaga(
    action: ReturnType<typeof createActivity.init>,
) {
    const activity = yield* call(api.createActivity, action.payload);
    yield put(createActivity.success(activity));
    yield put(getActivities.success([activity]));
}

function* createActivityWatcher() {
    yield* takeLatest(
        createActivity.initType,
        withBackendErrorHandler(
            createActivitySaga,
            createActivity.error,
            'Unable to create activity.',
        ),
    );
}

export function* deleteActivitySaga(
    action: ReturnType<typeof deleteActivity.init>,
) {
    yield* call(api.deleteActivity, action.payload);
    yield put(deleteActivity.success(action.payload));
}

function* deleteActivityWatcher() {
    yield* takeLatest(
        deleteActivity.initType,
        withBackendErrorHandler(
            deleteActivitySaga,
            deleteActivity.error,
            'Unable to delete activity.',
        ),
    );
}

export const activitySagas = [
    updateActivityStatusWatcher,
    createActivityWatcher,
    deleteActivityWatcher,
];
