import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { Box } from '@material-ui/core';
import { timePaymentFormatter } from 'shared/utils/formatters/timePaymentFormatter';
import { Nullable } from '../../../../../../../types/types';

export interface ITimeAndMoneyCellProps {
    className?: string;
    timeClassName?: string;
    moneyClassName?: string;
    time?: Nullable<string>,
    money?: Nullable<string>,
    title?: string,
}

export const TimeAndMoneyCell: React.FC<ITimeAndMoneyCellProps> = ({
    time,
    money,
    className,
    title,
}: ITimeAndMoneyCellProps) => {
    if (!time || !parseFloat(time || '')) {
        return (
            <PlainText
                title={title}
                value="$0"
                className={className}
            />
        );
    }
    return (
        <Box className={className} title={title}>
            <PlainText value={timePaymentFormatter(time, money)}/>
        </Box>
    );
};
