export enum SheetInfoItemTitle {
    Position = 'Position',
    Client = 'Client',
    PrismBatchId = 'Prism Batch ID',
    TimesheetId = 'Timesheet ID',
    ExpenseSheetId = 'Expense Sheet ID',
    PayType = 'Pay Type',
    PayPeriod = 'Pay Period',
    Location = 'Location',
    DealNumber = 'Deal #',
    JobNumber = 'Job #',
    Department = 'Department',
    AvailablePtoHours = 'Available PTO hours',
    Submitted = 'Submitted on',
}
