import { useEffect } from 'react';
import moment from 'moment';

const localStorageTimeVersionKey = 'AppTimeVersion';
const defaultTimerMinutes = 30;
const defaultUpdateTime = '6:00';

/**
 * Forced code update for users that not reload pages for a long time.
 * Reload page on start next working day.
 */
export const useCodeRefresher = () => {
    useEffect(() => {
        localStorage.setItem(localStorageTimeVersionKey, moment().format());
        const timerMinutes = 30 as number || defaultTimerMinutes;
        const updateTime = '6:00' as string || defaultUpdateTime;
        const versionChecker = setInterval(() => {
            const usedTimeVersion = localStorage.getItem(localStorageTimeVersionKey);
            const expectedUpdateTime = moment().startOf('day').add(moment.duration(updateTime));
            const isYesterdayVersionUsed = moment().isAfter(moment(usedTimeVersion), 'day');
            const isUpdateTimeHasCome = moment().isAfter(expectedUpdateTime, 'minutes');

            if (isYesterdayVersionUsed && isUpdateTimeHasCome) {
                window.location.reload();
            }
        }, timerMinutes * 60_000);
        return () => {
            if (versionChecker) {
                clearInterval(versionChecker);
            }
        };
    }, []);
};
