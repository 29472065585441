import { EafApprovalPage } from 'modules/eafApproval/EafApprovalPage';
import { AssignmentManagementPage } from 'modules/subassignmentManagement/AssignmentManagementPage';
import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { routes } from 'shared/routes';
import { Permission } from 'store/components/auth/authModels';

export const EmploymentManagementRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path={routes.CLIENT.EMPLOYMENT_MANAGEMENT.ASSIGNMENT_MANAGEMENT}
                component={AssignmentManagementPage}
                permission={Permission.ManageSubassignments}
            />
            <PrivateRoute
                path={routes.CLIENT.EMPLOYMENT_MANAGEMENT.EAF_APPROVAL}
                component={EafApprovalPage}
                permission={Permission.ManageSubassignments}
            />
            <PrivateRoute
                path={routes.CLIENT.EMPLOYMENT_MANAGEMENT.ROOT}
                component={AssignmentManagementPage}
                permission={Permission.ManageSubassignments}
            />
        </Switch>
    );
};
