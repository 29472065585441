import React, { useCallback, useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Nullable } from 'types/types';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { useSheetRejectStyles } from 'shared/components/sidebars/SheetReject/styles';
import { Form, Formik } from 'formik';
import { EafRejectionCard, IEafRejectionCardProps } from 'modules/eafApproval/components/Rejection/EafRejectionCard';
import { eafTableSelectors } from 'modules/eafApproval/store/selectors';
import { EafStatusSlug, EafType, eafTypeDisplayValues, IEmployeeActionForm } from 'modules/employeeActionsForm/store/models';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { selectUsersById } from 'store/entities/users/selectors';
import { rejectEaf } from 'modules/eafApproval/store/actions';

export interface IEafRejectionSidebarProps {
    isOpened: boolean;
    onClose: () => void;
    eafIds: Array<string>;
    isLoading?: boolean;
}

export const EafRejectionSidebar = ({
    isOpened,
    onClose,
    eafIds,
    isLoading = false,
}: IEafRejectionSidebarProps) => {
    const usersById = useSelector(selectUsersById);
    const eafs = useSelector(eafTableSelectors.selectItems);
    const dispatch = useDispatch();
    const items = useSelector(eafTableSelectors.selectItems);
    const classes = useSheetRejectStyles();
    const confirmButtonClasses = { root: classes.buttonSave, label: classes.buttonTextStyle };
    const cancelButtonClasses = { root: classes.buttonCancel, label: classes.buttonTextStyle };

    const rejectionCards = useMemo<Array<IEafRejectionCardProps>>(() => {
        const targetItems = (
            eafIds
                .map(id => items.find(item => item.id === id))
                .filter(Boolean)
        ) as Array<IEmployeeActionForm>;

        return targetItems.map(item => ({
            eafId: item.id,
            type: item.employee_action_form_data.employee_action_form_type,
            reasonFieldName: `reason_${item.id}`,
            employee: usersById[item.employee_user_id || ''],
        }));
    }, [eafIds, items, usersById]);

    const initialValues = useMemo<Record<string, Nullable<string>>>(() => {
        const entries = rejectionCards.map(card => [card.reasonFieldName, null]);
        return Object.fromEntries(entries);
    }, [rejectionCards]);

    const validate = useCallback((values: Record<string, Nullable<string>>) => {
        return rejectionCards.reduce((errors, card) => {
            if (!values[card.reasonFieldName]) {
                return { ...errors, [card.reasonFieldName]: 'Reason is required' };
            }
            return errors;
        }, {});
    }, [rejectionCards]);

    const handleSubmit = useCallback((values: Record<string, Nullable<string>>) => {
        dispatch(rejectEaf.init({
            // @ts-ignore
            employee_action_forms: eafIds.map(id => ({
                status_slug: EafStatusSlug.ApprovalRejected,
                approval_rejection_reason: values[`reason_${id}`],
                employee_action_form_id: id,
            })),
        }));
        onClose();
    }, [dispatch, eafIds, onClose]);

    const eafTypes = useMemo(() => {
        const uniqEafTypes = new Set<EafType>();
        eafs.filter(eaf => eafIds.includes(eaf.id)).forEach(eaf => {
            uniqEafTypes.add(eaf.employee_action_form_data.employee_action_form_type);
        });
        return [...uniqEafTypes].map(type => eafTypeDisplayValues[type]).join(', ');
    }, [eafIds, eafs]);

    return (
        <Sidebar
            isOpened={isOpened}
            onClose={onClose}
            anchor="right"
            title="Reject EAF"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validate={validate}
            >
                <Form>
                    <Box p={4}>
                        <Typography>
                            You are <i><b>rejecting</b></i> {pluralize('this', eafIds.length, 'these')} {eafTypes} {pluralize('request', eafIds.length)}.
                            Please enter the reason why you are rejecting it below.
                        </Typography>

                        {rejectionCards.map(card => (
                            <EafRejectionCard key={card.reasonFieldName} {...card}/>
                        ))}
                    </Box>

                    <footer className={classes.footer}>
                        <ButtonWithLoader
                            type="submit"
                            variant="contained"
                            color="primary"
                            classes={confirmButtonClasses}
                            isLoading={isLoading}
                        >
                            Confirm
                        </ButtonWithLoader>
                        <Button
                            onClick={onClose}
                            variant="contained"
                            classes={cancelButtonClasses}
                        >
                            Cancel
                        </Button>
                    </footer>
                </Form>
            </Formik>
        </Sidebar>
    );
};
