import React, { useMemo } from 'react';
import StatusCell from 'shared/components/table/Cells/StatusCell';
import { useStatusStyles } from 'shared/components/table/Cells/StatusStyles';
import { ISheet } from 'shared/models/sheet/Sheet';
import { sheetIsMissing } from 'store/entities/timesheet/helpers';
import { missingStatusName, StatusNames } from 'store/entities/timesheet/models/Status';

interface ISheetStatusProps {
    className?: string;
    sheet: ISheet;
}

export default function SheetStatus({ className, sheet }: ISheetStatusProps){
    const classes = useStatusStyles();

    const statusToColor: Record<string, string> = useMemo(() => ({
        [StatusNames.SUBMITTED]: classes.blue,
        [StatusNames.REJECTED]: classes.red,
        [StatusNames.APPROVED]: classes.lightGreen,
        default: classes.orange,
    }), [classes]);

    const colorsClassName = statusToColor[sheet?.status?.name ?? ''] || statusToColor.default;
    const statusText = sheetIsMissing(sheet) ? missingStatusName : sheet?.status?.name ?? '';

    return (
        <StatusCell className={className} text={statusText}
            statusClassName={colorsClassName}
        />
    );
}
