import { useSelector } from 'react-redux';
import { selectDepartmentById } from 'modules/employmentInfo/store/department/selectors';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { useUserPto } from 'store/entities/users/hooks';
import {
    selectAssignmentById,
    selectLocationById,
    selectPositionById,
} from 'store/entities/configuration/configurationSelectors';

export function useSheetGroupAdditionalData(sheetGroup: IGroupedSheetCalculation) {
    const position = useSelector(selectPositionById(sheetGroup?.position_id || ''));
    const assignment = useSelector(selectAssignmentById(sheetGroup?.assignment_id));
    const location = useSelector(selectLocationById(assignment?.location_id || ''));
    const department = useSelector(selectDepartmentById(assignment?.department_id || ''));
    const clientId = sheetGroup?.client_id || '';
    const client = useSelector(selectAllClientsById)[clientId];
    const pto = useUserPto(sheetGroup?.user_id || '', sheetGroup?.client_id || '');
    return { position, location, department, client, pto };
}
