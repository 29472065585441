import { EntryType } from 'shared/models/sheet/Sheet';
import { Box, Button, Theme } from '@material-ui/core';
import React from 'react';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';
import clsx from 'clsx';

const useEntryTypeSelectStyles = makeHighPriorityStyles((theme: Theme) => ({
    buttonsWrapper: {
        border: `1px solid ${colors.secondary}`,
        background: colors.white,
    },
    button: {
        width: '50%',
        minHeight: theme.spacing(5),

        '&, &:hover': {
            border: 'none',
        },
    },
    primaryButton: {
        '&&': {
            backgroundColor: colors.secondary,
        },
    },
    outlinedButton: {
        '&&': {
            color: colors.secondary,
        },
    },
    submitButtonLabel: {
        textTransform: 'uppercase',
    },
}));

interface IEntryTypeSelectProps {
    value: EntryType;
    onChange: (newValue: EntryType) => void;
    className?: string;
    allowedOptions?: EntryType[];
}

export default function EntryTypeSelect({
    value,
    onChange,
    className = '',
    allowedOptions = [EntryType.TIME, EntryType.EXPENSE],
}: IEntryTypeSelectProps) {
    const classes = useEntryTypeSelectStyles();

    return (
        <Box className={clsx(classes.buttonsWrapper, className)}>
            <Button color="primary" disabled={!allowedOptions.includes(EntryType.TIME)}
                classes={{
                    root: classes.button, containedPrimary: classes.primaryButton,
                    outlinedPrimary: classes.outlinedButton,
                }}
                variant={value === EntryType.TIME ? 'contained' : 'outlined'}
                onClick={onChange.bind(null, EntryType.TIME)}
            >
                Time
            </Button>
            <Button color="primary" disabled={!allowedOptions.includes(EntryType.EXPENSE)}
                classes={{
                    root: classes.button, containedPrimary: classes.primaryButton,
                    outlinedPrimary: classes.outlinedButton,
                }}
                variant={value === EntryType.EXPENSE ? 'contained' : 'outlined'}
                onClick={onChange.bind(null, EntryType.EXPENSE)}
            >
                Expense
            </Button>
        </Box>
    );
}
