import { useMaxLevelApproverIdBySheetId } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/hooks/useMaxLevelApproverIdBySheetId';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sumBy } from 'lodash-es';
import {
    IPayrollTimeExpenseRow,
    IPayrollExpenseRowGroup,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/common/SheetEntryTable';
import {
    IExpenseCalculationEntry,
    IGroupedSheetCalculation, ISheetCalculationBatch,
    ITimeCalculationEntry,
} from 'modules/payrollProcessorHub/store/model';
import { selectPayCodesById } from 'modules/settings/submodules/clients/payCodes/store/selectors';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getProjectAssignmentByProjectAndAssignmentIds } from 'shared/utils/helpers/entries';
import { getTotalHours } from 'shared/utils/helpers/hoursCalculations';
import { selectActivitiesById, selectAssignmentsById, selectProjectsWithAssignments, selectTasksById } from 'store/entities/configuration/configurationSelectors';
import { selectUsersById } from 'store/entities/users/selectors';

const usePayrollExpenseRowGroupedByDay = (
    entries: ITimeCalculationEntry[] | IExpenseCalculationEntry[],
    type: EntryType,
): Record<string, IPayrollTimeExpenseRow[]> => {
    const paycodesById = useSelector(selectPayCodesById);
    const activitiesById = useSelector(selectActivitiesById);
    const assignmentsByIds = useSelector(selectAssignmentsById);
    const projectAssignments = useSelector(selectProjectsWithAssignments);
    const tasksById = useSelector(selectTasksById);

    return useMemo(() => {
        const entriesByDay: Record<string, IPayrollTimeExpenseRow[]> = {};
        entries.forEach((entry: ITimeCalculationEntry | IExpenseCalculationEntry) => {
            const activity = activitiesById[entry?.activity_id];
            const projectAssignment = getProjectAssignmentByProjectAndAssignmentIds(
                projectAssignments,
                entry.project_id,
                entry.assignment_id,
            );
            // @ts-ignore
            (entriesByDay[entry.entry_date] = (entriesByDay[entry.entry_date] || [])).push({
                ...entry,
                activity,
                payCode: paycodesById[entry?.client_pay_code_id],
                hours: type === EntryType.TIME ? (entry as ITimeCalculationEntry).hours?.toString() : '',
                amount: type === EntryType.EXPENSE ? (entry as IExpenseCalculationEntry).total_dollars?.toString() : '',
                projectAssignment,
                task: tasksById[entry?.task_id],
                assignment: assignmentsByIds[entry?.assignment_id],
            });
        });
        return entriesByDay;
    }, [entries, activitiesById, projectAssignments, paycodesById, type, tasksById, assignmentsByIds]);
};

const calculateTotalValuesByDays = (
    entriesByDay: Record<string, IPayrollTimeExpenseRow[]>,
): IPayrollExpenseRowGroup[] => {
    const result: IPayrollExpenseRowGroup[] = [];
    Object.keys(entriesByDay).sort().forEach(date => {
        const items = entriesByDay[date];
        const entryDate = moment(date);
        result.push({
            items,
            entryDate,
            totalHours: getTotalHours(items.map(item => item.hours)),
            totalAmount: sumBy(items, (item: IPayrollTimeExpenseRow) => parseFloat(item.amount)).toFixed(2).toString(),
        });
    });
    return result;
};

const useGroupedEntriesWithTotals = (
    entries: ITimeCalculationEntry[] | IExpenseCalculationEntry[],
    type: EntryType,
): IPayrollExpenseRowGroup[] => {
    const entriesByDay = usePayrollExpenseRowGroupedByDay(entries, type);

    return useMemo(() => {
        return calculateTotalValuesByDays(entriesByDay);
    }, [entriesByDay]);
};

export const useGroupedPayrollEntries = (
    sheetGroup: IGroupedSheetCalculation,
    type: EntryType,
): IPayrollExpenseRowGroup[] => {
    return useGroupedEntriesWithTotals(
        type === EntryType.TIME ? sheetGroup.time_entries : sheetGroup.expense_entries,
        type,
    );
};

export const useGroupedPayrollCalculationEntries = (
    calculationGroup: ISheetCalculationBatch,
    type: EntryType,
): IPayrollExpenseRowGroup[] => {
    const approverIdsBySheetIds = useMaxLevelApproverIdBySheetId(calculationGroup);
    const usersByIds = useSelector(selectUsersById);
    const entries = (
        type === EntryType.TIME ? calculationGroup.time_calculations : calculationGroup.expense_calculations
        // @ts-ignore
    ).map(calculation => calculation.entries.map(entry => {
        return {
            ...entry,
            jobNumber: calculation.job_number,
            jobNumberId: calculation.job_number_id,
            // @ts-ignore
            approver: usersByIds[approverIdsBySheetIds[calculation.sheet_id]],
        };
    })).flat();
    return useGroupedEntriesWithTotals(
        entries,
        type,
    );
};
