import baseApi from 'shared/utils/baseApi';
import {
    IFieldsConfigurationWrapper,
    IPatchAppSettingRequest,
} from 'store/entities/clients/clientsModel';

const servicePath = 'client-configurations';
const modulePath = 'clients';

export const configurationApi = {
    async updateAppSettings(
        clientId: string,
        request: Partial<IPatchAppSettingRequest>,
    ): Promise<IFieldsConfigurationWrapper> {
        const { data } = await baseApi.patch<Partial<IPatchAppSettingRequest>, IFieldsConfigurationWrapper>(
            `${servicePath}/${modulePath}/${clientId}/configuration`, request,
        );
        return data;
    },
};
