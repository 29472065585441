import { call, put, select, takeLatest, take } from 'typed-redux-saga';
import { assignmentsReportDataSelectors } from 'modules/settings/submodules/clients/assignmentsReportData/components/AssignmentsReportDataList/store/selectors';
import { assignmentReportDataApi } from 'modules/settings/submodules/clients/assignmentsReportData/store/api';
import { IAssignmentReportDataRequest } from 'modules/settings/submodules/clients/assignmentsReportData/store/model';
import { getUsers } from 'store/entities/users/actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    getMoreAssignmentReportDataAction,
    initialLoadAssignmentReportData,
    setEditAssignmentEditDataId,
    updateAssignmentReportMetaDataAction,
    createAssignmentReportMetaDataAction,
    setOpenAssignmentMetaDataCreateModal,
} from 'modules/settings/submodules/clients/assignmentsReportData/store/actions';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { withClientId } from '../../../../../../store/utils/withClientId';

export function* getMoreAssignmentReportDataSaga() {
    const range = yield* select(assignmentsReportDataSelectors.selectNextRange);
    const clientId = yield* select(selectCurrentClientId);
    const request: IAssignmentReportDataRequest = {
        range: range,
        filters: JSON.stringify({ 'client_id': { 'eq': clientId } }),
    };
    const response = yield* call(assignmentReportDataApi.getAssignmentReportsData, request);

    if (response.user_meta.length) {
        yield put(getUsers.init({
            ids: response.user_meta.map(data => data.user_id).join(','),
        }));
        yield take([getUsers.successType, getUsers.errorType]);
    }
    yield put(getMoreAssignmentReportDataAction.success(response));
}

function* getMoreAssignmentReportDataSagaWatcher() {
    yield* takeLatest(
        [
            getMoreAssignmentReportDataAction.initType,
            initialLoadAssignmentReportData.action,
            createAssignmentReportMetaDataAction.successType,
        ],
        withBackendErrorHandler(
            getMoreAssignmentReportDataSaga,
            getMoreAssignmentReportDataAction.error,
            'Unable to get assignment metadata.',
        ),
    );
}

function* updateAssignmentReportMetaDataSaga({
    payload,
}: ReturnType<typeof updateAssignmentReportMetaDataAction.init>) {
    const { id, request } = payload;
    const updated = yield call(assignmentReportDataApi.updateAssignmentReportMetaData, id, request);
    yield put(setEditAssignmentEditDataId(null));
    yield put(updateAssignmentReportMetaDataAction.success(updated));
}

function* updateAssignmentMetaDataWatcher() {
    yield* takeLatest(
        updateAssignmentReportMetaDataAction.initType,
        withBackendErrorHandler(
            updateAssignmentReportMetaDataSaga,
            updateAssignmentReportMetaDataAction.error,
            'Unable to update assignment report metadata',
        ),
    );
}

function* createAssignmentReportMetaDataSaga({
    payload,
}: ReturnType<typeof createAssignmentReportMetaDataAction.init>) {
    const created = yield call(assignmentReportDataApi.createAssignmentReportMetaData, withClientId(payload));
    yield put(setOpenAssignmentMetaDataCreateModal(false));
    yield put(createAssignmentReportMetaDataAction.success(created));
}

function* createAssignmentMetaDataWatcher() {
    yield* takeLatest(
        createAssignmentReportMetaDataAction.initType,
        withBackendErrorHandler(
            createAssignmentReportMetaDataSaga,
            createAssignmentReportMetaDataAction.error,
            'Unable to create assignment report metadata',
        ),
    );
}

export const assignmentsReportDataSagas = [
    getMoreAssignmentReportDataSagaWatcher,
    updateAssignmentMetaDataWatcher,
    createAssignmentMetaDataWatcher,
];
