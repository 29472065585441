import clsx from 'clsx';
import React, { useCallback } from 'react';
import { DeleteConfirmationModal } from 'modules/settings/submodules/components/Modals/DeleteConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { useButtonStyles } from 'shared/components/buttons/buttonStyles';
import { useModal } from 'shared/utils/hooks/useModal';
import { IStore } from 'store/configureStore';
import { ActionCreatorKnownArgs } from 'store/utils';
import { Button } from '@material-ui/core';

interface IDeleteButtonWithConfirmationProps {
    buttonText: string;
    modalTitle: string;
    modalButtonTitle: string;
    className?: string;
    isLoadingSelector: (state: IStore) => boolean;
    action: ActionCreatorKnownArgs<any, any>;
    actionPayload: any;
    children: React.ReactChildren | React.ReactChild | React.ReactChild[];
}

export const DeleteButtonWithConfirmation = ({
    buttonText,
    modalTitle,
    modalButtonTitle,
    isLoadingSelector,
    className,
    action,
    actionPayload,
    children,
}: IDeleteButtonWithConfirmationProps) => {
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const isLoading = useSelector(isLoadingSelector);
    const buttonStyles = useButtonStyles();

    const dispatch = useDispatch();
    const handleConfirm = useCallback(() => {
        dispatch(action(actionPayload));
    }, [action, actionPayload, dispatch]);

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                className={clsx(buttonStyles.redOutlined, className)}
                onClick={onModalOpen}
                disabled={isLoading}

            >
                {buttonText}
            </Button>
            <DeleteConfirmationModal
                isOpened={isModalOpened}
                onClose={onModalClose}
                isLoading={isLoading}
                modalTitle={modalTitle}
                buttonTitle={modalButtonTitle}
                onConfirm={handleConfirm}
            >
                {children}
            </DeleteConfirmationModal>
        </>
    );
};
