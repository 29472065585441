import React from 'react';
import AccountContent from './AccountContent';
import ProfileContent from './ProfileContent';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const UserProfileDesktopView: React.FC = () => {
    const classes = useStyles();

    return (
        <Box>
            <Grid
                item
                sm={9}
                xs={12}
                className={classes.block}
            >
                <Box className={classes.card}>
                    <Typography className={classes.heading}>Profile  
                    </Typography>
                    <ProfileContent/>
                </Box>   

            </Grid> 
            <Grid
                item
                sm={9}
                xs={12}
                className={classes.block}
            >
                <Box className={classes.card}>
                    <Typography className={classes.heading}>Account</Typography>
                    <AccountContent/>
                </Box>   
            </Grid> 
        </Box> 
    );
};

export default UserProfileDesktopView;
