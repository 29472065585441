import {
    useDetailSheetGroup,
} from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/hooks/useDetailSheetGroup';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import SheetRejectedView from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/SheetRejectedView';
import { setSheetGroupedDetailId } from 'modules/sheetApproval/store/actions';
import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';
import { ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { selectEmployeeSubmittedEditSheet } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { selectTypedSheet } from 'store/entities/timesheet/selectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import SheetsRecalledView from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsRecalledView';
import { useOpenSheetDetail } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/useOpenSheetDetail';
import { useDisallowPastDayEntry } from '../../../../../store/entities/clients/hooks';

export default function SheetsSubmittedAllPage() {
    const openSheetDetail = useOpenSheetDetail();
    const editSheetInfo = useSelector(selectEmployeeSubmittedEditSheet);
    const disallowPastDayEntry = useDisallowPastDayEntry();
    const [selectedSheetInfo, setSelectedSheetInfo] = useState<ISheetClickInfo | null>(null);

    const detailGroup = useDetailSheetGroup();
    const dispatch = useDispatch();
    const onCloseRecalled = useCallback(() => dispatch(setSheetGroupedDetailId(null)), [dispatch]);

    const sheet = useSelector((state: IStore) => selectedSheetInfo
        ? selectTypedSheet(selectedSheetInfo.sheetId, selectedSheetInfo.entryType)(state)
        : null,
    );
    useEffect(() => {
        if (selectedSheetInfo && sheet && (sheet.status.name !== StatusNames.RECALLED || disallowPastDayEntry)) {
            openSheetDetail(selectedSheetInfo);
            setSelectedSheetInfo(null);
        }
    }, [openSheetDetail, selectedSheetInfo, sheet, disallowPastDayEntry]);
    const handleSheetClick = (clickInfo: ISheetClickInfo) => {
        setSelectedSheetInfo(clickInfo);
    };

    if (editSheetInfo) {
        return (
            <SheetRejectedView
                sheetIds={editSheetInfo.sheetIds}
                entryType={editSheetInfo.entryType}
                sheetGroup={detailGroup}
            />
        );
    }
    if (detailGroup && detailGroup?.status?.name === StatusNames.RECALLED && !disallowPastDayEntry) {
        return (
            <SheetsRecalledView
                sheetGroup={detailGroup}
                entryType={detailGroup.type}
                resetSheet={onCloseRecalled}
            />
        );
    }
    if (sheet && sheet.status?.name === StatusNames.RECALLED && !disallowPastDayEntry) {
        return (
            <SheetsRecalledView
                sheetId={sheet.id}
                entryType={sheet.entry_type}
                resetSheet={setSelectedSheetInfo}
            />
        );
    }
    return (
        <SheetsSubmittedView
            onDetailsClick={handleSheetClick}
        />
    );
}
