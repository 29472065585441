import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectDealsState } from 'modules/settings/submodules/clients/deals/store/selectors';
import { selectDealsById } from 'store/entities/configuration/configurationSelectors';

const selectEditDealState = (state: IStore) => {
    const dealsState = selectDealsState(state);
    return dealsState.editDeal;
};
export const selectEditDealId = (state: IStore) => selectEditDealState(state).dealId;
export const selectEditDeal = createSelector(
    selectEditDealId,
    selectDealsById,
    (id, dealsByIds) => dealsByIds[id],
);
export const selectIsDealUpdating = (state: IStore) => {
    const editDealState = selectEditDealState(state);
    return editDealState.isUpdating;
};
