import { orderBy } from 'lodash-es';
import { IClientRoleRow } from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/model';
import {
    selectClientRoleFilter,
    selectClientRoleSort,
} from 'modules/settings/submodules/platformUsers/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isContainSubstring } from 'shared/utils/helpers/isContainSubstring';
import { GlobalRoles } from 'store/entities/users/model';
import { selectClientRolesById } from 'store/entities/users/selectors';

export const useClientRolesRows = (): IClientRoleRow[] => {
    const clientRolesById = useSelector(selectClientRolesById);
    const sortState = useSelector(selectClientRoleSort);
    const filter = useSelector(selectClientRoleFilter);

    return useMemo(() => {
        const sortKey = Object.keys(sortState)[0];
        const sortOrder = sortState[sortKey];
        let roles = Object.values(clientRolesById).filter(role => role.name !== GlobalRoles.Employee);
        if (filter) {
            roles = roles.filter(role => {
                if (filter.client_id && !(role.all_clients || role.client_ids.includes(filter.client_id))) {
                    return false;
                }
                if (filter.search && !(
                    isContainSubstring(role.name, filter.search)
                    || isContainSubstring(role?.description || '', filter.search)
                )) {
                    return false;
                }
                return true;
            });
        }
        if (sortKey && sortOrder) {
            roles = orderBy(roles, [sortKey], [sortOrder]);
        }
        return roles;
    }, [clientRolesById, sortState, filter]);
};
