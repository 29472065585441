import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from 'store/entities/appConfig/actions';

const useTitleUpdate = (desktopHeader: string,
    mobileHeader: string = desktopHeader,
    tabHeader: string = desktopHeader) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeaderTitle({ mobileHeader, desktopHeader, tabHeader }));
    }, [dispatch, mobileHeader, desktopHeader, tabHeader]);
};

export default useTitleUpdate;
