import { IJobNumberFormValues } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/model';
import { DealTypeBillingType, IDealType } from 'shared/models/DealType';
import { FeeType, TimesheetSettings } from 'shared/models/JobNumber';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { FTE_MAX_VALUE } from 'utils/constants';

const moreThanZeroMessage = 'Should be more than 0';

const dailyBillingInfoRequiredCondition = (dealType?: IDealType) =>
    dealType?.billing_type === DealTypeBillingType.Daily;

const perFileBillingInfoRequiredCondition = (dealType?: IDealType) =>
    dealType?.billing_type === DealTypeBillingType.PerFile;

const jobNumbersValuesSchema: Record<keyof IJobNumberFormValues, yup.Schema<any>> = {
    jobNumber: yup.number(),
    deal: yup.object().nullable().required(ValidationMessages.REQUIRED),
    dealType: yup.object(),
    jobDescription: yup.string().required(ValidationMessages.REQUIRED),
    startDate: yup.string().required(ValidationMessages.REQUIRED),
    endDate: yup.string(),
    managerId: yup.string().nullable().required(ValidationMessages.REQUIRED),
    userTypeId: yup.string().nullable().required(ValidationMessages.REQUIRED),
    jobNumberGroup: yup.string().nullable(),
    uwSystem: yup.string().required(ValidationMessages.REQUIRED),
    fte: yup.number()
        .required(ValidationMessages.REQUIRED)
        .max(FTE_MAX_VALUE)
        .label('FTE'),
    timesheetSettings: yup.string().required(ValidationMessages.REQUIRED),

    billingInfo: yup.string().when(['dealType'], {
        is: dailyBillingInfoRequiredCondition,
        then: yup.string().required(ValidationMessages.REQUIRED),
    }),
    max: yup.number().nullable().when(['dealType'], {
        is: dailyBillingInfoRequiredCondition,
        then: yup.number().when('billingInfo', {
            is: FeeType.Daily,
            then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
        }),
    }),
    min: yup.number().nullable().when(['dealType'], {
        is: dailyBillingInfoRequiredCondition,
        then: yup.number().when('billingInfo', {
            is: FeeType.Daily,
            then: yup.number()
                .min(0, moreThanZeroMessage)
                .required(ValidationMessages.REQUIRED)
                .when(
                    'max',
                    (max: number, schema: yup.NumberSchema) => schema.max(max, 'Min should be less than max'),
                ),
        }),
    }),
    miCredit: yup.number().nullable().when(['dealType'], {
        is: dailyBillingInfoRequiredCondition,
        then: yup.number().when('billingInfo', {
            is: FeeType.Daily,
            then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
        }),
    }),
    blendedAmount: yup.number().nullable().when(['dealType'], {
        is: dailyBillingInfoRequiredCondition,
        then: yup.number().when('billingInfo', {
            is: FeeType.Blended,
            then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
        }),
    }),
    billingFileRate: yup.number().nullable().when('dealType', {
        is: perFileBillingInfoRequiredCondition,
        then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
    }),
    billingPerDiemRate: yup.number().nullable().when('dealType', {
        is: dailyBillingInfoRequiredCondition,
        then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
    }),

    payrollIsPerDiem: yup.boolean(),
    payrollHourlyRate: yup.number().nullable().when('timesheetSettings', {
        is: TimesheetSettings.Hourly,
        then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
    }),
    payrollFileRate: yup.number().nullable().when('timesheetSettings', {
        is: TimesheetSettings.PerFile,
        then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
    }),
    payrollPerDiemRate: yup.number().nullable().when('payrollIsPerDiem', {
        is: true,
        then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
    }),
    timeActivities: yup.array().min(1, 'Please select at least one activity'),

    reasonForClose: yup.string().nullable(),
    closeOnDate: yup.string().nullable(),
};

export const jobNumbersValidationSchema = yup.object().shape(jobNumbersValuesSchema);
