import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PrintButton } from 'shared/components/buttons/PrintButton';

interface IActionPrintButtonProps {
    customClasses?: string;
    disabled?: boolean;
    action: any;
}

export const ActionPrintButton = ({
    action,
    ...props
}: IActionPrintButtonProps) => {
    const dispatch = useDispatch();
    const handleClick = useCallback(() => {
        dispatch(action());
    }, [dispatch, action]);
    return (
        <PrintButton
            onClick={handleClick}
            {...props}
        />
    );
};
