import { IStore } from 'store/configureStore';
import createSelectorSortedByNameFromById from 'store/utils/selectors/createSelectorSortedByNameFromById';
import { IDepartment } from '../../models/Department';
import { employmentState } from '../selectors';

const departmentsState = (state: IStore) => employmentState(state).departments;
export const selectDepartmentsById = (state: IStore) => departmentsState(state).departmentsById;
export const selectDepartmentById = (id = '') =>
    (state: IStore): IDepartment => selectDepartmentsById(state)[id];
export const selectDepartmentsIsLoading = (state: IStore) => departmentsState(state).departmentsIsLoading;
export const selectDepartmentsSortedByName = createSelectorSortedByNameFromById<IDepartment>(selectDepartmentsById);
