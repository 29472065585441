import { useMemo } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { ValidationMessages } from 'shared/models/Validation';
import { selectCustomFieldsList } from 'store/entities/customFields/selectors';

export const useCustomFieldValidationSchema = (excludeId?: string) => {
    const existCustomFields = useSelector(selectCustomFieldsList);
    return useMemo(() => yup.object().shape({
        name: yup.string().trim().required(ValidationMessages.REQUIRED)
            .test({
                name: 'customFieldUnique',
                test: function (value: string): boolean {
                    const existField = existCustomFields.find(
                        field => field.name === value
                            && field.prism_field?.key === this.parent.mapping
                            && field.id !== excludeId,
                    );
                    return !existField;
                },
                message: 'A combination of name and mapped field already exists. Please reuse an existing custom field.',
            }),
        description: yup.string().trim().required(ValidationMessages.REQUIRED),
        mapping: yup.string().nullable().required(ValidationMessages.REQUIRED),
        clients: yup.array().of(yup.string()).when('all_clients', {
            is: false,
            then: yup.array().min(1, 'Please select at least one client'),
        }),
    }), [existCustomFields, excludeId]);
};
