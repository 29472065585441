import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useFiltersSidebarStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        backgroundColor: colors.white,
        color: theme.palette.text.primary,
        height: theme.spacing(8),
        borderBottom: `1px solid ${colors.gray}`,
    },
    text: {
        fontSize: 20,
        fontWeight: 500,
    },
}));

export const useFilterContentStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        height: '100%',
    },
    paper: {
        backgroundColor: colors.white,
        border: `1px solid ${colors.lightGray}`,
        boxSizing: 'border-box',
        padding: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            height: '100%',
            paddingTop: theme.spacing(5),
        },
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(33),
        },
    },
    formInputsWrapper: {
        height: `calc(100% - ${theme.spacing(7)}px)`,

        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    selectField: {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            '& select': {
                height: theme.spacing(8),
            },
        },
    },
    field: {
        marginBottom: theme.spacing(2),
    },
    applyButtonRoot: {
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            maxWidth: theme.spacing(12),
        },
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2, -2, -2, -2),
            width: `calc(100% + ${theme.spacing(4)}px)`,
            height: theme.spacing(7),
        },
    },
    applyButtonLabel: {
        textTransform: 'uppercase',
    },
}));
