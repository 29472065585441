import React from 'react';
import { useSelector } from 'react-redux';
import FormAutocomplete from 'shared/components/autocomplete/FormAutocomplete';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import { Permission } from 'store/components/auth/authModels';
import { PrismUserType, UserFeature } from 'store/entities/users/model';
import { selectUsersByFeature } from 'store/entities/users/selectors';
import { IFormFieldProps } from '../formFields/models';

interface IPositionProps extends IFormFieldProps {
    feature: UserFeature;
    featureValue: Permission | PrismUserType;
    useIdValue?: boolean;
    Label?: React.ReactNode;
}

export const SpecialUserAutocomplete = ({
    feature,
    featureValue,
    useIdValue = false,
    Label,
    ...props
}: IPositionProps) => {
    const users = useSelector(selectUsersByFeature(feature, featureValue));

    return (
        <FormAutocomplete
            Label={Label}
            getText={(user: IUserInfo) => getUserName(user)}
            getKey={useIdValue ? (user: IUserInfo) => user.id : undefined }
            options={users}
            {...props}
        />
    );
};
