import { selectSheetGroupDetailId, selectSheetGroupSidebarId } from 'modules/sheetApproval/store/selectors';
import { expenseSheetsSelectors, timeSheetsSelectors } from 'modules/timeAndExpense/store/selectors';
import { createSelector } from 'reselect';

export const selectEmployeeDetailSheetGroup = createSelector(
    selectSheetGroupDetailId,
    timeSheetsSelectors.selectGroupedSheets,
    expenseSheetsSelectors.selectGroupedSheets,
    (groupId, timeGroups, expenseGroups) => {
        return [...timeGroups, ...expenseGroups].find(group => group.id === groupId);
    },
);

export const selectEmployeeSidebarSheetGroup = createSelector(
    selectSheetGroupSidebarId,
    timeSheetsSelectors.selectGroupedSheets,
    expenseSheetsSelectors.selectGroupedSheets,
    (groupId, timeGroups, expenseGroups) => {
        return [...timeGroups, ...expenseGroups].find(group => group.id === groupId);
    },
);
