import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { GridTableGroup, ITableGroup } from 'shared/components/table/GridTable/GridTableGroup';
import { GridTableRows, IGridTableRowsProps } from 'shared/components/table/GridTable/GridTableRows';
import GridTableSkeleton from 'shared/components/table/GridTable/GridTableSkeleton';
import { ITableContainerCssProps, useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';
import GridHeader from 'shared/components/table/GridTable/GridHeader';
import { Droppable } from 'react-beautiful-dnd';
import { Box } from '@material-ui/core';
import { ITableGroupHeaderProps } from 'shared/components/table/VirtualizedGridTable/model';
import { ICellInfo } from './GridTableModel';

export interface IGridTableProps<RowData> extends Omit<IGridTableRowsProps<RowData>, 'classes' | 'rows'>{
    className?: string;
    headingRowClassName?: string;
    bodyRowClassName?: string;
    headerCellClassName?: string;
    groups?: ITableGroup<RowData>[];
    rowData: RowData[];
    isLoading?: boolean;
    hideHeader?: boolean;
    stickyHeader?: boolean;
    droppableId?: string;
    renderGroupHeader?: (props: ITableGroupHeaderProps<RowData>) => React.ReactNode;
    calcRowWidth?: (cells: Array<ICellInfo<RowData>>) => string;
    BodyComponent?: React.ComponentType<IGridTableRowsProps<RowData>>
    setTableRef?: (instance: HTMLDivElement | null) => void;
    displayDefaultGroupExpandButton?: boolean;
    calculateIsGroupExpanded?: (group: ITableGroup<RowData>) => boolean;
}

export default function GridTable<RowData>({
    rowData,
    groups,
    className = '',
    isLoading,
    headerCellClassName,
    hideHeader,
    stickyHeader,
    droppableId,
    renderGroupHeader,
    calcRowWidth,
    BodyComponent,
    headingRowClassName,
    bodyRowClassName,
    setTableRef,
    displayDefaultGroupExpandButton = true,
    calculateIsGroupExpanded,
    ...rowsProps
}: IGridTableProps<RowData>) {
    const { cells, droppableRows, currentDragDestinationIndex } = rowsProps;
    const [openedItem, setOpenedItem] = useState<string | null>(null);

    const classesProps: ITableContainerCssProps = useMemo(() => ({
        gridTemplateColumns: cells.map(cell => cell.width || 'minmax(0, 1fr)').join(' '),
        cellAmount: cells.length,
        rowAmount: rowData.length,
        rowWidth: calcRowWidth ? calcRowWidth(cells) : undefined,
    }), [cells, rowData, calcRowWidth]);
    const classes = useGridTableStyles(classesProps);

    const renderRows = (rows: RowData[]) => (
        <GridTableRows
            classes={classes}
            rows={rows}
            customRowClassName={bodyRowClassName}
            {...rowsProps}
        />
    );
    const renderGroup = (groupsToRender: ITableGroup<RowData>[]) => groupsToRender.map(group => {
        const isOpen = (
            typeof calculateIsGroupExpanded === 'function'
                ? calculateIsGroupExpanded(group)
                : group.id === openedItem
        );
        return (
            <GridTableGroup
                key={group.id}
                group={group}
                rows={group.rows}
                renderGroupHeader={renderGroupHeader}
                classes={classes}
                isOpen={isOpen}
                onOpen={() => setOpenedItem(group.id)}
                displayExpandButton={displayDefaultGroupExpandButton}
                {...rowsProps}
            />
        );
    });

    const tableBody = groups ? renderGroup(groups) : renderRows(rowData);

    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const highlightHeader = isDraggingOver
        && !(currentDragDestinationIndex && currentDragDestinationIndex > -1); // not over row

    if (isLoading) {
        return <GridTableSkeleton hasHeader/>;
    }

    const tableClasses = clsx(className, classes.tableContainer);

    return (
        <div
            className={tableClasses}
            role="table"
            data-test="table"
            ref={setTableRef}
        >
            {!hideHeader && (
                <GridHeader
                    cells={cells}
                    classes={classes}
                    headerCellClassName={headerCellClassName}
                    rowsData={rowData}
                    stickyHeader={stickyHeader}
                    highlight={highlightHeader}
                    customHeaderRowClassName={headingRowClassName}
                />
            )}
            {(() => {
                if (BodyComponent) {
                    return (
                        <BodyComponent
                            rows={rowData}
                            {...rowsProps}
                            classes={classes}
                        />
                    );
                }
                if (droppableRows) {
                    return (
                        <Droppable droppableId={droppableId || 'no_id'}>
                            {(provided, snapshot) => {
                                setIsDraggingOver(snapshot.isDraggingOver);
                                return (
                                    <Box
                                        {...provided.droppableProps}
                                        {...{ ref: provided.innerRef } as any}
                                        className={classes.dropRowsZone}
                                    >
                                        {tableBody}
                                        {provided.placeholder}
                                    </Box>
                                );
                            }}
                        </Droppable>
                    );
                }
                return tableBody;
            })()}
        </div>
    );
}
