import { IFormFieldProps } from 'shared/components/formFields/models';

import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';

import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { NamedEntitySelect } from 'shared/components/selects/NamedEntitySelect';
import {
    IInvoiceRecipientSelectOption,
} from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/models';
import {
    selectInvoiceRecipientsSelectOptions, selectIsInvoiceRecipientsLoading,
} from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/selectors';

interface IInvoiceRecipientSelectProps extends IFormFieldProps, IFormSelect {
}

interface IInvoiceRecipientPureSelectProps {
    options: IInvoiceRecipientSelectOption[];
    disabled: boolean;
    useIdValue: boolean;
    isLoading: boolean;
}

export const InvoiceRecipientSelect = separateLogicDecorator<IInvoiceRecipientSelectProps,
IInvoiceRecipientPureSelectProps>(
    () => ({
        options: useSelector(selectInvoiceRecipientsSelectOptions),
        useIdValue: true,
        disabled: useSelector(selectIsInvoiceRecipientsLoading),
        isLoading: useSelector(selectIsInvoiceRecipientsLoading),
    }),
)(NamedEntitySelect);
