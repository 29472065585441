import React from 'react';
import { Box } from '@material-ui/core';
import { DealsTable } from 'modules/settings/submodules/clients/deals/components/DealsTable/DealsTable';
import {
    getControllingOrgs,
    getDealTypes,
    getCostCenters,
    getSubmittingOrgs,
    getSubmittingOrgLocations,
    getUserTypesAction,
    getActivities,
    getDealSegments,
} from 'store/entities/configuration/configurationAction';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { DealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/DealFilter';
import { CreateDealButton } from 'modules/settings/submodules/clients/deals/components/CreateDeal/CreateDealButton';
import { getSpecialUsersAction } from 'store/entities/users/actions';
import { Permission } from 'store/components/auth/authModels';
import CreateDealJobNumberModal
    from 'modules/settings/submodules/clients/deals/components/CreateDealJobNumberModal/CreateDealJobNumberModal';
import { AssignUserModal } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/AssignUserModal';
import EditDealModal from 'modules/settings/submodules/clients/deals/components/EditDeal/EditDealModal';

export function DealsPage() {
    useWithClientEffect((dispatch, clientId) => {
        dispatch(getDealTypes.init());
        dispatch(getDealSegments.init());
        dispatch(getControllingOrgs.init());
        dispatch(getSubmittingOrgs.init());
        dispatch(getSubmittingOrgLocations.init());
        dispatch(getCostCenters.init());

        //for Job Number:
        dispatch(getUserTypesAction.init());
        dispatch(getActivities.init());
        dispatch(getSpecialUsersAction.init({
            purpose: Permission.ApproveSheets,
            client_id: clientId,
        }));
    });

    return (
        <>
            <Box display="flex" justifyContent="space-between"
                mb={2}>
                <CreateDealButton/>
                <DealFilter/>
            </Box>
            <DealsTable/>
            <CreateDealJobNumberModal/>
            <AssignUserModal/>
            <EditDealModal/>
        </>
    );
}
