import { useSelector } from 'react-redux';
import { selectDealTypeById } from 'store/entities/configuration/configurationSelectors';
import { IDeal } from 'shared/models/Deal';

export const useIsOnlyDefaultActivities = (deal?: IDeal | null) => {
    const dealTypeId = deal?.type_id;
    const dealType = useSelector(selectDealTypeById(dealTypeId));
    const dealTypeHasOnlyDefaultActivities = dealType?.use_default_activity ?? false;
    return dealTypeHasOnlyDefaultActivities;
};
