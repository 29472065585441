import { EafType, IEafReason } from 'modules/employeeActionsForm/store/models';
import { selectEafReasons } from 'modules/employeeActionsForm/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { IFormFieldProps } from 'shared/components/formFields/models';

interface IEnumSelectProps extends IFormFieldProps, IFormSelect {
    type: EafType | null;
}

export default function ReasonSelect({
    type,
    ...props
}: IEnumSelectProps) {
    const options = useSelector(selectEafReasons)
        .filter(reason => !type || reason.employee_action_form_types.includes(type));

    return (
        <FormSelect
            {...props}
            getKey={(value: IEafReason) => value.key}
            getText={(value: IEafReason) => value.text}
            options={options}
            isNullable
            resetIfValueNotAvailable
        />
    );
}
