import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/selectors/clientsSelectors';
import { EditTravelExpenseSidebarContent } from 'modules/travelExpenses/components/Edit/EditTravelExpenseSidebarContent';
import { selectIsEditTravelExpenseSidebarOpen } from 'modules/travelExpenses/components/Edit/store/selectors';
import { setEditTravelExpenseId } from 'modules/travelExpenses/components/Edit/store/actions';
import EditEntrySave from 'shared/components/sidebars/EntryEdit/EditEntrySave';

export function EditTravelExpenseSidebar() {
    const editEntryClasses = useEntryEditStyles();
    const clientId = useSelector(selectCurrentClientId);
    const configuration = useSelector(selectFieldConfiguration(clientId || ''));
    const isSidebarOpened = useSelector(selectIsEditTravelExpenseSidebarOpen);
    const dispatch = useDispatch();
    const onCloseHandler = useCallback(() => {
        dispatch(setEditTravelExpenseId(null));
    }, [dispatch]);
    return (
        <Sidebar title={'EDIT TRAVEL EXPENSE'}
            anchor={'right'}
            onClose={onCloseHandler}
            customClasses={editEntryClasses.paper}
            isOpened={isSidebarOpened}
        >
            <>
                {configuration && (
                    <EditTravelExpenseSidebarContent
                        SubmitBlock={EditEntrySave}
                        onClose={onCloseHandler}
                        inputs={configuration.inputs.expense}
                    />
                )}

            </>
        </Sidebar>
    );
}
