import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getDeals } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { setEditDealId, updateDeal } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/actions';
import { api } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/api';
import { selectInvoiceRecipientById } from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/selectors';
import { IUpdateDealRequest } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/models';
import { setCreateJobNumberModalState } from 'modules/settings/submodules/clients/jobNumber/store/actions';
import { setRedirectToJobNumberWithDealNumber } from '../../DealForm/store/actions';

function* updateDealSaga(action: ReturnType<typeof updateDeal.init>) {
    const { id, data } = action.payload;

    const { invoice_recipient_id, continueJobNumberCreation, ...clearedData } = data;

    const recipient = yield* select(selectInvoiceRecipientById(invoice_recipient_id));

    const request = {
        ...clearedData,
        recipient: {
            recipient_id: invoice_recipient_id,
            recipient_type: recipient?.recipient_type,
        },
    } as IUpdateDealRequest;

    const result = yield* call(api.updateDeal, id, request);
    yield put(updateDeal.success(result));
    yield put(getDeals.success([result]));
    yield put(setEditDealId(null));

    if (continueJobNumberCreation){
        yield put(setRedirectToJobNumberWithDealNumber(result.deal_number));
        yield put(setCreateJobNumberModalState(true));
    }
}

export function* updateDealWatcher() {
    yield* takeLatest(
        updateDeal.initType,
        withBackendErrorHandler(
            updateDealSaga,
            updateDeal.error,
            'Unable to update Deal.',
        ),
    );
}
