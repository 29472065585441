import baseApi from 'shared/utils/baseApi';
import {
    dealEntity,
    gwClientExtensionsService,
} from 'store/entities/configuration/configurationApi';
import { ICreateDealRequest } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/models';
import { IDeal } from 'shared/models/Deal';

export const api = {
    async createDeal(deal: ICreateDealRequest): Promise<IDeal> {
        const { data } = await baseApi.create<ICreateDealRequest, IDeal>(
            gwClientExtensionsService,
            dealEntity,
            deal,
        );

        return data;
    },
};
