import {
    IEcubItemsRequest,
    IEcubItemsResponse, IEcubPausePayload,
    IEcubPayload,
} from 'modules/settings/submodules/clients/reports/Ecub/store/models';
import baseApi from 'shared/utils/baseApi';

export const gwClientExtensionsService = 'gw-clientextensions';

export const ecubApi = {
    async getEcubItems(payload?: IEcubItemsRequest): Promise<IEcubItemsResponse> {
        const { data } = await baseApi.get<IEcubItemsResponse>(
            `/${gwClientExtensionsService}/ecub`,
            payload,
        );
        return data;
    },
    async downloadEcub(
        payload: IEcubPayload,
    ): Promise<BlobPart> {
        const { data } = await baseApi.post<IEcubPayload, BlobPart>(
            `/${gwClientExtensionsService}/ecub/download`,
            payload,
            {
                responseType: 'blob',
            },
        );
        return data;
    },
    async sendEcub(
        payload: IEcubPayload,
    ): Promise<string> {
        const { data } = await baseApi.post<IEcubPayload, { status: string }>(
            `/${gwClientExtensionsService}/ecub/send`,
            payload,
        );
        return data.status;
    },
    async pauseEcub(
        payload: IEcubPausePayload,
    ): Promise<IEcubPausePayload> {
        await baseApi.post<IEcubPayload, { status: string }>(
            `/${gwClientExtensionsService}/ecub/pause`,
            payload,
        );
        return payload;
    },
};
