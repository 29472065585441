import { Box, ListItem, Typography } from '@material-ui/core';
import React from 'react';
import { useCcpMainStyles } from '../../styles';

export interface ICcpListInfoItemProps {
    name: string;
    data: string;
}

export const TransactionPropertyListItem = ({ name, data }: ICcpListInfoItemProps) => {
    const classes = useCcpMainStyles();
    return (
        <ListItem classes={{ root: `${classes.timesheetInfoItem} ${classes.idsInfoItem}` }}>
            <Box className={classes.inlineItem}>
                <Box className={classes.listItemInline}>
                    <Typography variant="subtitle2" className={classes.title}>
                        {name}
                    </Typography>
                </Box>
                <Box className={classes.listItemInline}>
                    <Typography variant="body2">
                        {data}
                    </Typography>
                </Box>
            </Box>
        </ListItem>
    );
};
