import { IPayrollSheetGroupedRow } from '../model';
import { IUserInfo } from 'shared/models/User';
import { useSelector } from 'react-redux';
import { selectUsersById } from 'store/entities/users/selectors';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';
import { getLatestEditorValue } from '../../../store/helpers';

export const usePayrollSheetGroupEditedBy = (row: IPayrollSheetGroupedRow): IUserInfo | null => {
    const usersByIds = useSelector(selectUsersById);

    const allCalculations = [...row.time_calculations, ...row.expense_calculations] as Array<ICalculation>;
    const latestEditor = getLatestEditorValue(allCalculations);

    return latestEditor ? usersByIds[latestEditor] : null;
};
