import { useSelector } from 'react-redux';
import { selectAllEntries, selectAllSheets, selectSheet, selectTypedEntries } from 'store/entities/timesheet/selectors';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useMemo } from 'react';
import {
    HierarchyNodeOperation, ICustomField,
    ICustomFieldHierarchyNode,
    ICustomFieldValue,
} from 'store/entities/customFields/model';
import {
    selectCustomFieldNodeByFieldId, selectCustomFieldsByIds,
    selectCustomFieldsHierarchyNodesList, selectCustomFieldValuesByIds,
} from 'store/entities/customFields/selectors';
import { ICustomFieldWithValues } from 'modules/settings/submodules/clients/customFieldValues/store/models';
import { createSelector } from 'reselect';
import { ItemsById } from 'shared/models/ItemsById';

const getSheetEntriesCustomFieldsWithValues = (
    entries: { custom_field_value_ids?: string[] }[],
    clientId: string,

    customFieldsHierarchyNodesList: ICustomFieldHierarchyNode[],
    customFieldsById: ItemsById<ICustomField>,
    customFieldValuesByIds: ItemsById<ICustomFieldValue>,
) => {
    const sheetCustomFieldsValuesIds: string[] = entries.flatMap(entry => entry.custom_field_value_ids || []);
    const sheetCustomFieldsValuesIdsUnique = [...new Set(sheetCustomFieldsValuesIds)];
    const sheetCustomFieldsValues: ICustomFieldValue[] = sheetCustomFieldsValuesIdsUnique.map(fieldValueId => {
        return customFieldValuesByIds[fieldValueId];
    }).filter(customFieldValue => Boolean(customFieldValue));

    const clientNodes = customFieldsHierarchyNodesList.filter(node => node.client_id === clientId);
    const clientCustomFields = clientNodes.map(node => customFieldsById[node.custom_field_id])
        .filter(customField => Boolean(customField));

    return clientCustomFields.map(customField => {
        const customFieldValues = sheetCustomFieldsValues.filter(value => {
            return value.custom_field_id === customField.id;
        }).sort((value1, value2) => {
            return value1.data.name.localeCompare(value2.data.name);
        });
        return {
            custom_field: customField,
            custom_field_values: customFieldValues,
        } as ICustomFieldWithValues;
    });
};

export const useSheetEntriesCustomFieldsWithValues = (
    entries: { custom_field_value_ids?: string[] }[],
    clientId: string) => {
    const customFieldsHierarchyNodesList: ICustomFieldHierarchyNode[] = useSelector(
        selectCustomFieldsHierarchyNodesList);
    const customFieldsById: Record<string, ICustomField> = useSelector(selectCustomFieldsByIds);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    return useMemo(() => {
        return getSheetEntriesCustomFieldsWithValues(
            entries,
            clientId,

            customFieldsHierarchyNodesList,
            customFieldsById,
            customFieldValuesByIds,
        );
    }, [customFieldsHierarchyNodesList, customFieldsById, customFieldValuesByIds, entries, clientId]);
};

export const useSheetEntriesCustomFieldsWithValuesById = (
    entries: { custom_field_value_ids?: string[] }[],
    clientId: string) => {
    const sheetEntriesCustomFieldsWithValues = useSheetEntriesCustomFieldsWithValues(entries, clientId);
    return useMemo(() => {
        return sheetEntriesCustomFieldsWithValues.reduce((acc, item) => ({
            ...acc,
            [item.custom_field.id]: item.custom_field_values,
        }), {}) as Record<string, ICustomFieldValue[]>;
    }, [sheetEntriesCustomFieldsWithValues]);
};

export const customFieldsWithValuesBySheetIdSelector = createSelector(
    selectAllSheets,
    selectAllEntries,
    selectCustomFieldsHierarchyNodesList,
    selectCustomFieldsByIds,
    selectCustomFieldValuesByIds,
    (
        allSheets,
        allEntries,
        hierarchyNodes,
        customFieldsById,
        customFieldValuesByIds) => {
        return allSheets.reduce((acc, sheet) => {
            const sheetEntries = allEntries.filter(
                entry => sheet && entry.sheet_id === sheet.id,
            );
            const customFieldsWithValues = getSheetEntriesCustomFieldsWithValues(sheetEntries, sheet.client_id,
                hierarchyNodes, customFieldsById, customFieldValuesByIds);
            return ({
                ...acc,
                [sheet.id]: customFieldsWithValues,
            });
        }, {}) as Record<string, ICustomFieldWithValues[]>;
    },
);

export const customFieldsValuesBySheetIdSelector = createSelector(
    customFieldsWithValuesBySheetIdSelector,
    customFieldsWithValuesBySheetId => {
        const result: Record<string, ICustomFieldValue[]> = {};
        Object.entries(customFieldsWithValuesBySheetId).forEach(([sheetId, fieldsWithValues]) => {
            result[sheetId] = fieldsWithValues.flatMap(fieldWithValue => fieldWithValue.custom_field_values);
        });
        return result;
    },
);

export const useSheetCustomFieldsWithValues = (sheetId: string) => {
    const sheet = useSelector(selectSheet(sheetId));
    const entries = useSelector(selectTypedEntries(sheet?.entry_type || EntryType.TIME)).filter(
        entry => sheet && entry.sheet_id === sheet.id,
    );
    return useSheetEntriesCustomFieldsWithValues(entries, sheet?.client_id || '');
};

export const useSheetNonHiddenCustomFieldsWithValues = (sheetId: string) => {
    const nodesByFieldId = useSelector(selectCustomFieldNodeByFieldId);
    const sheetCustomFieldsWithValues = useSheetCustomFieldsWithValues(sheetId);
    return useMemo(() => {
        return sheetCustomFieldsWithValues.filter(fieldWithValues => {
            const node = nodesByFieldId[fieldWithValues?.custom_field?.id || ''];
            return node && node.operation !== HierarchyNodeOperation.Hidden;
        });
    }, [sheetCustomFieldsWithValues, nodesByFieldId]);
};

export const useSheetCustomFieldsWithValuesById = (sheetId: string) => {
    const sheetCustomFieldsWithValues = useSheetCustomFieldsWithValues(sheetId);
    return useMemo(() => {
        return sheetCustomFieldsWithValues.reduce((acc, item) => ({
            ...acc,
            [item.custom_field.id]: item.custom_field_values,
        }), {}) as Record<string, ICustomFieldValue[]>;
    }, [sheetCustomFieldsWithValues]);
};

export const useSheetCustomFieldValues = (sheetId: string, customFieldId: string) => {
    const sheetCustomFieldsWithValuesById = useSheetCustomFieldsWithValuesById(sheetId);
    return useMemo(() => {
        return sheetCustomFieldsWithValuesById[customFieldId] || [];
    }, [sheetCustomFieldsWithValuesById, customFieldId]);
};

export const useSheetCustomFieldValuesPlain = (sheetId: string) => {
    const sheetCustomFieldsWithValues = useSheetCustomFieldsWithValues(sheetId);
    return useMemo(() => {
        return sheetCustomFieldsWithValues.flatMap(fieldWithValues => fieldWithValues.custom_field_values);
    }, [sheetCustomFieldsWithValues]);
};

export const useSheetCustomFieldValuesIdsPlain = (sheetId: string) => {
    const sheetCustomFieldValuesPlain = useSheetCustomFieldValuesPlain(sheetId);
    return useMemo(() => {
        return sheetCustomFieldValuesPlain.map(value => value.id);
    }, [sheetCustomFieldValuesPlain]);
};
