import React, { useEffect } from 'react';

export function StatusPageWidget() {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = process.env.REACT_APP_STATUS_PAGE_IFRAME as string;
        script.id = 'statuspage-script';
        document.body.appendChild(script);
    }, []);

    return (
        <div/>
    );
}
