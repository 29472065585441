import React, { useCallback, useState } from 'react';
import { useSheetApprovalStyles } from './styles';
import { Button, Typography } from '@material-ui/core';
import { EntryType } from 'shared/models/sheet/Sheet';

interface ISheetApprovalContentProps {
    count: number;
    sheetType: EntryType;
    onClose: () => void;
    onConfirm: () => void;
}

export default function SheetApprovalContent({ count, sheetType, onClose, onConfirm }: ISheetApprovalContentProps) {
    const classes = useSheetApprovalStyles();
    const [clicked, setClicked] = useState(false);

    const onConfirmHandler = useCallback(() => {
        if (!clicked) {
            onConfirm();
            setClicked(true);
        }
    }, [onConfirm, clicked, setClicked]);

    return (
        <>
            <div className={classes.modal}>
                <section className={classes.content}>
                    <Typography display="inline" variant="body2"> A total of {count} {sheetType === EntryType.TIME ? 'timesheets' : 'expense sheets'} have been selected for approval.</Typography>
                </section>
                <footer className={classes.footer}>
                    <Button onClick={onConfirmHandler}
                        classes={{ root: classes.buttonApprove, label: classes.buttonTextStyle }}
                        variant="contained"
                        size="large"
                        color="primary">
            Confirm
                    </Button>
                    <Button onClick={onClose} classes={{ root: classes.buttonReject, label: classes.buttonTextStyle }}
                        variant="contained"
                        size="large">
            Cancel
                    </Button>
                </footer>
            </div>
        </>
    );
}
