import { makeStyles, fade } from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { formBasicSpacing } from 'modules/offerLetter/components/OfferLetterForm/styles';

const fadeModifier = 0.8;

export const useStyles = makeStyles(theme => ({
    block: {
        padding: theme.spacing(4, 0, 0, 4),
    },
    card: {
        padding: theme.spacing(2),
        border: `1px solid ${colors.lightGray}`,
        minHeight: theme.spacing(21),
        boxSizing: 'border-box',
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            background: colors.white,
        },
    },
    heading: {
        fontSize: theme.typography?.pxToRem(16),
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        paddingTop: theme.spacing(1),
        width: theme.spacing(10),
    },
    input: {
        margin: theme.spacing(formBasicSpacing, formBasicSpacing, 0),
        display: 'flex',
        flexGrow: 0.5,
        maxWidth: '330px',
    },
    form: {
        width: '100%',
        display: 'flex',
        maxWidth: '850px',
        paddingLeft: theme.spacing(7),
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3, 3, 0),
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            flexDirection: 'column',
        },
    },
    green: {
        '& .Mui-checked svg': {
            color: colors.green,
        },
        '&[class*="MuiButton-root"]:hover': {
            backgroundColor: colors.green,
        },
    },
    button: {
        marginTop: theme.spacing(2),
        minWidth: 100,
    },
    footer: {
        display: 'flex',
        alignSelf: 'flex-end',
        justifyContent: 'space-evenly',
        padding: theme.spacing(2),
        '& > *:not(:first-child)': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 'auto',
            float: 'right',
            marginRight: theme.spacing(3.5),
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',  
        },
    },
    componentBlock: {
        width: '100%',
    },
    contactInfo: {
        fontSize: theme.typography?.pxToRem(12),
        color: colors.gray,
        fontStyle: 'italic',
        padding: theme.spacing(2.5, 0, 0, 2.5),
        maxWidth: 350,
    },
    passwordLink: {
        color: colors.blue,
        fontSize: theme.typography?.pxToRem(12),
        margin: theme.spacing(2.5, 0, 0, 2.5),
        fontWeight: theme.typography.fontWeightMedium,
        cursor: 'pointer',
        width: 'fit-content',
    },
    flexRow: {
        flexDirection: 'row',
    },
    flexColumn: {
        flexDirection: 'column',
    },
    avatarBlock: {
        margin: theme.spacing(2 * formBasicSpacing, 2 * formBasicSpacing, 4 * formBasicSpacing, 6 * formBasicSpacing),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,          
        },
    },
    avatar: {
        height: theme.spacing(15),
        width: theme.spacing(15),
        fontSize: 'xx-large !important',
        backgroundColor: fade(theme.palette.primary.main, 2),
        color: fade(theme.palette.getContrastText(theme.palette.primary.main), 2),
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: fade(theme.palette.primary.main, fadeModifier),
            color: fade(theme.palette.getContrastText(theme.palette.primary.main), fadeModifier),
        },   
    },
    uploadBox: {
        height: theme.spacing(15),
        width: theme.spacing(15),
        paddingBottom: theme.spacing(formBasicSpacing),
        '&:focus': {
            outlineColor: colors.white,
        },   
    },
    avatarTitle: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(0.5),
        fontSize: 12,
        '&:hover': {
            cursor: 'pointer',
        }, 
    },
    confirmBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: 'auto',
            alignSelf: 'center',  
        },
    },
    confirmpwd: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(5),
            width: '100%',
        },
    },
    tabs: { 
        width: '100%',
    },
    tabsIndicator: {
        display: 'none',
    },
    tabsFlexContainer: {
        height: '100%',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    tab: {
        opacity: 1,
        fontSize: 14,
        minHeight: 'unset',
        height: theme.spacing(6),
        color: colors.darkGray,
        width: '50%',
        margin: 0,
        textTransform: 'uppercase',
        borderBottom: `2px solid transparent`,
    },
    tabActive: {
        color: theme.palette.primary.main,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    pullRight: {
        float: 'right',
    },
    userMessage: {
        '&&': {
            ...theme.typography.body2,
            color: colors.green,
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
                margin: theme.spacing(-3),
                padding: theme.spacing(3),
                marginTop: theme.spacing(1),
                boxShadow: 'rgba(0,0,0,0.35) 0px -1px 1px',
            },
        },
        '&.Mui-error': {
            color: colors.red,
        },
    },
}));
