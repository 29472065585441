import React, { useCallback, useEffect, useMemo } from 'react';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { approvalTableSelectorsByType } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/helper';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import {
    initialLoadApprovalSheets,
    loadMoreApprovalSheets,
    resetApprovalSheets,
} from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/actions';
import {
    approvalTableSelectors,
} from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/selectors';
import {
    useApprovalGroupedSheetCells,
} from 'modules/timeAndExpense/components/GroupedSheetsTable/useApprovalGroupedSheetCells';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useIsMobile } from 'shared/utils/hooks/media';
import { clearSyncing } from 'store/entities/appConfig/actions';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { selectSheetStatusesIsLoading } from 'store/entities/timesheet/selectors';
import { StatusNames } from '../../../../store/entities/timesheet/models/Status';

interface ISheetApprovalInfinityTableProps {
    sheetType: EntryType;
}

export const SheetApprovalInfinityTable = ({ sheetType }: ISheetApprovalInfinityTableProps) => {
    const isMobile = useIsMobile();

    const activeStatus = useSelector(selectTimeAndExpenseActiveStatus);
    const statusesIsLoading = useSelector(selectSheetStatusesIsLoading);

    const tableClasses = useSubmittedTableStyles();

    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(clearSyncing(SyncingModels.ApprovalSheet));
            // @ts-ignore
            dispatch(resetApprovalSheets());
        };
    }, [dispatch]);
    useWithClientEffect((dispatchWithClient, clientId) => {
        if (!statusesIsLoading && clientId) {
            dispatchWithClient(initialLoadApprovalSheets(sheetType));
        }
    }, [sheetType, activeStatus, statusesIsLoading]);
    const onLoadMore = useCallback(() => {
        dispatch(loadMoreApprovalSheets.init());
    }, [dispatch]);
    const cellClass = useMemo(() => {
        return activeStatus === StatusNames.SUBMITTED ? tableClasses.slimBodyCell : tableClasses.bodyCell;
    }, [activeStatus, tableClasses.bodyCell, tableClasses.slimBodyCell]);

    const tableSelectors = approvalTableSelectorsByType(sheetType);
    const isLoading = useSelector(approvalTableSelectors.isLoading);
    const hasMore = useSelector(approvalTableSelectors.selectHasMore);
    const rows = useSelector(tableSelectors.selectGroupedSheetRows);
    const cells = useApprovalGroupedSheetCells(sheetType, rows);

    return (
        <InfinityGridTable
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={cellClass}
            hideHeader={isMobile}
            stickyHeader
            isLoading={isLoading}
            cells={cells}
            rowData={rows}
            getKey={row => row.group.id}
            getRowId={row => row.group.id}
            hasMore={hasMore}
            onLoadMore={onLoadMore}
        />
    );
};
