import { ecubReport } from 'modules/settings/submodules/clients/reports/Ecub/store/reducers';
import { getLatestSubassignmentsPayRatesReport } from 'modules/settings/submodules/clients/reports/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';

const latestSubassignmentsPayRatesReportIsLoading = isLoadingReducer(getLatestSubassignmentsPayRatesReport);

export const reports = combineReducers({
    latestSubassignmentsPayRatesReportIsLoading,
    ecubReport,
});
