import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useGroupedSheetDetailStyles = makeHighPriorityStyles({
    typeSwitch: {
        width: '100%',
    },
    typeSwitchButton: {
        width: '50%',
    },
    actions: {
        '& * + *': {
            marginLeft: 8,
        },
    },
    editButton: {
        textDecoration: 'none',
        color: 'inherit',
    },
});
