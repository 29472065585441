import React from 'react';
import { Button, Hidden, ButtonProps } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

export const BackButton = (props: ButtonProps) => {
    return (
        <Button
            variant="outlined"
            color="primary"
            startIcon={<ChevronLeft/>}
            {...props}
        >
            <Hidden xsDown>
                Back
            </Hidden>
        </Button>
    );
};
