import { ISheetsSubmittedViewPureProps, SheetsSubmittedViewPure } from 'modules/timeAndExpense/components/SheetsSubmittedView/SheetsSubmittedViewPure';
import { expenseSheetsSelectors, selectCheckedSheetWithTypes, timeSheetsSelectors } from 'modules/timeAndExpense/store/selectors';
import { useSelector } from 'react-redux';
import { IEntity } from 'shared/models/Entity';
import { EntryType, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { useCells } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectSheetsIsLoading } from 'store/entities/timesheet/selectors';

interface ISheetsSubmittedSingleSheetViewProps {
    onDetailsClick?: (sheetInfo: ISheetClickInfo) => void;
}

interface IRow extends IEntity {}

export const SheetsSubmittedSingleSheetView = separateLogicDecorator<
ISheetsSubmittedSingleSheetViewProps,
ISheetsSubmittedViewPureProps<IRow>
>(({ onDetailsClick }) => {
    const timeRows = useSelector(timeSheetsSelectors.selectTableRows);
    const expenseRows = useSelector(expenseSheetsSelectors.selectTableRows);
    const isLoading = useSelector(selectSheetsIsLoading);
    const timeCells = useCells(
        EntryType.TIME, onDetailsClick,
    );
    const expenseCells = useCells(
        EntryType.EXPENSE, onDetailsClick,
    );
    const sheetsEntryTypes = useSelector(selectCheckedSheetWithTypes);

    return {
        timeRows,
        expenseRows,
        timeCells,
        expenseCells,
        isLoading,
        sheetsEntryTypes,
    };
})(SheetsSubmittedViewPure);
