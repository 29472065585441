import { IEmployeeType } from '../../models/EmployeeType';
import baseApi from 'shared/utils/baseApi';

interface IGetEmploymentTypes {
    employment_types: IEmployeeType[];
}

export const employeeTypeApi = {
    async getAll(): Promise<IEmployeeType[]> {
        const { data } = await baseApi.get<IGetEmploymentTypes>('/client-users/employment_types');
        return data.employment_types;
    },
};
