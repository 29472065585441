import { IStore } from 'store/configureStore';
import { createSelector } from 'reselect';
import { allCcpSlug, CcpStatusSlug, invoicedCcpSlug } from '../models/ccpModel';

export const selectCcpTransactionsState = (state: IStore) => {
    return state.modules.ccpTransactions;
};

type CcpTransactionsState = ReturnType<typeof selectCcpTransactionsState>;

export const selectCcpTransactionsTable = (state: IStore) => {
    return selectCcpTransactionsState(state).ccpTransactionsTable;
};

export const selectCcpTransactionsTableTotalItems = (state: IStore) => {
    return selectCcpTransactionsTable(state).total;
};

export const selectCcpTransactionsActiveTab = createSelector(
    selectCcpTransactionsState,
    function ccpApprovalActiveTabSelector(state: CcpTransactionsState): CcpStatusSlug
    | typeof allCcpSlug | typeof invoicedCcpSlug {
        return state.ccpTransactionActiveTab;
    },
);

export const selectCcpEditMode = (state: IStore) =>
    selectCcpTransactionsState(state).ccpEditMode;

export const selectCcpActiveTabSkip = createSelector(
    selectCcpTransactionsState,
    function ccpActiveTabSkip(state: CcpTransactionsState): number {
        return state.ccpCurrentTabSkip;
    },
);

export const selectCcpIsLoadingReconciledCsvReport = (state: IStore) =>
    selectCcpTransactionsState(state).isLoadingReconciledReport;

export const selectCcpIsLoadingUnreconciledCsvReport = (state: IStore) =>
    selectCcpTransactionsState(state).isLoadingUnreconciledReport;

export const selectCcpIsLoadingInvoicesCsvReport = (state: IStore) =>
    selectCcpTransactionsState(state).isLoadingExportCcpInvoiceReport;

export const selectCcpIsLoadingReminder = (state: IStore) =>
    selectCcpTransactionsState(state).isLoadingCcpReminder;

export const selectIsLoadingEditModeSave = (state: IStore) =>
    selectCcpTransactionsState(state).isLoadingEditModeSave;
