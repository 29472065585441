import { Button, ButtonGroup, ButtonProps } from '@material-ui/core';
import { useGroupedSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/styles';
import React from 'react';
import { EntryType } from 'shared/models/sheet/Sheet';

interface IEntryTypeTabSwitcherProps {
    onTimeClick: () => void;
    onExpenseClick: () => void;
    disabled?: boolean;
    activeTab: EntryType;
}

type ButtonVariant = ButtonProps['variant'];

const BUTTON_VARIANTS: Array<ButtonVariant> = [
    'contained',
    'outlined',
];

function resolveButtonVariants(activeTab: EntryType): [ButtonVariant, ButtonVariant] {
    if (activeTab === EntryType.TIME) {
        return [...BUTTON_VARIANTS] as [ButtonVariant, ButtonVariant];
    }
    return [...BUTTON_VARIANTS].reverse() as [ButtonVariant, ButtonVariant];
}

export const EntryTypeTabSwitcher: React.FC<IEntryTypeTabSwitcherProps> = ({
    disabled,
    onTimeClick,
    onExpenseClick,
    activeTab,
}: IEntryTypeTabSwitcherProps) => {
    const classes = useGroupedSheetDetailStyles();
    const [timeButtonVariant, expenseButtonVariant] = resolveButtonVariants(activeTab);
    return (
        <ButtonGroup
            variant="contained"
            color="secondary"
            disabled={disabled}
            className={classes.typeSwitch}
        >
            <Button
                onClick={onTimeClick}
                variant={timeButtonVariant}
                className={classes.typeSwitchButton}
            >
                Time
            </Button>

            <Button
                onClick={onExpenseClick}
                variant={expenseButtonVariant}
                className={classes.typeSwitchButton}
            >
                Expense
            </Button>
        </ButtonGroup>
    );
};
