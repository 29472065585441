import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import {
    EafStatusSlug,
    eafTypeDisplayValues,
} from 'modules/employeeActionsForm/store/models';
import { useEafChangeString } from 'modules/subassignmentManagement/components/EafHistory/useEafChangeString';
import React from 'react';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStyles = makeHighPriorityStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        justifyContent: 'center',
    },
    primaryInfo: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        lineHeight: '1.3em',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%',
    },
    secondaryInfo: {
        color: theme.palette.text.secondary,
        fontSize: '0.8em',
        lineHeight: '1em',
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
    },
    statusLabel: {
        fontStyle: 'italic',
        marginLeft: theme.spacing(.5),
    },
}));

export const EafGridCellTypeDetails = ({
    className,
    onClick,
    ...eaf
}: EafApprovalCellProps) => {
    const { employee_action_form_data, status } = eaf;
    const classes = useStyles();
    const { employee_action_form_type } = employee_action_form_data;
    const eafType = eafTypeDisplayValues[employee_action_form_type];
    const update = useEafChangeString(eaf);
    return (
        <Box className={clsx(className, classes.container)} onClick={onClick}>
            <Box className={classes.primaryInfo}>
                {eafType}
                {status.slug === EafStatusSlug.PendingApproval && (
                    <Box color="warning.main" className={classes.statusLabel}>pending</Box>
                )}
                {status.slug === EafStatusSlug.ApprovalRejected && (
                    <Box color="error.main" className={classes.statusLabel}>rejected</Box>
                )}
            </Box>
            {update && (
                <Box className={classes.secondaryInfo}>
                    {update}
                </Box>
            )}
        </Box>
    );
};
