import React from 'react';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { Box, Button, Grid } from '@material-ui/core';
import { useEafFormStyles } from 'modules/subassignmentManagement/components/EafFormShared/useEafFormStyles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import FormField from 'shared/components/formFields/FormField';
import FormRadioButtonGroup from 'shared/components/selects/FormRadioButtonGroup';
import { shortDateFormat } from 'shared/models/Dates';
import { Status } from 'shared/models/Status';
import { IEmployeeActionFormDataModel, IEndAssignmentFormProps } from 'modules/employeeActionsForm/components/EndAssignment/EndAssignmentForm';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';
import { useEndAssignmentValidationSchema } from 'modules/employeeActionsForm/components/EndAssignment/validationSchema';
import { EafType, eafTypeDisplayValues } from 'modules/employeeActionsForm/store/models';
import CheckboxField from 'shared/components/formFields/CheckboxField';

export const BulkEndAssignmentForm = ({
    isLoading,
    onCancel,
    assignment,
    initialValues,
    onSubmit,
}: IEndAssignmentFormProps) => {
    const classes = useEafFormStyles();
    const formClasses = sidebarFormStyles();

    const validationSchema = useEndAssignmentValidationSchema(true, false);
    const formikData = useFormik<IEmployeeActionFormDataModel>({
        initialValues,
        validationSchema,
        validateOnBlur: false,
        onSubmit,
    });

    return (
        <FormikProvider value={formikData}>
            <form
                onSubmit={formikData.handleSubmit}
                className={formClasses.form}
            >
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <EnumFormSelect
                            outerLabel="Type*"
                            name="employee_action_form_type"
                            values={EafType}
                            displayValues={eafTypeDisplayValues}
                            allowedValues={[EafType.AssignmentEnded, EafType.Resignation]}
                            className={formClasses.input}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <label htmlFor="eligibleForRehire" className={formClasses.inlineInputLabel}>
                            Eligible for rehire?*
                        </label>
                        <EnumFormSelect
                            id="eligibleForRehire"
                            name="eligibleForRehire"
                            values={Status}
                            className={clsx(formClasses.input, classes.inlineRadio)}
                            displayValues={{
                                [Status.active]: 'Yes',
                                [Status.inactive]: 'No',
                            }}
                            InputComponent={FormRadioButtonGroup}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <FormField
                            outerLabel="Reason"
                            id="employee_action_form_reason_text"
                            name="employee_action_form_reason_text"
                            className={formClasses.input}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <DayPickerField
                            outerLabel="Effective Date*"
                            id="effective_date"
                            name="effective_date"
                            format={shortDateFormat}
                            className={formClasses.input}
                            disableToolbar
                            withKeyboard
                            maxDate={assignment?.end_date || undefined}
                        />
                    </Grid>
                </Grid>

                <Box className={classes.row}>
                    <Box>
                        <CheckboxField name="do_not_send_employee_email">
                            Do not send email
                        </CheckboxField>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    alignItems="baseline"
                >
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={formClasses.button}
                        isLoading={isLoading}
                        data-testid="submit_button"
                    >
                        Submit
                    </ButtonWithLoader>
                    <Button
                        disabled={isLoading}
                        onClick={onCancel}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Box>
            </form>
        </FormikProvider>
    );
};
