import { HierarchyType, ICustomFieldValue } from 'store/entities/customFields/model';
import { ICustomFiledFiltration } from './getFiltrationByHierarchy';
import { getHierarchyRelations } from 'store/entities/customFields/utils';
import { isEmpty, difference } from 'lodash-es';

function getCustomFieldValueTitle(customFieldValue: ICustomFieldValue): string {
    return (customFieldValue?.data?.name || '').trim();
}

function compareByTitle(valueA: ICustomFieldValue, valueB: ICustomFieldValue): number {
    const titleA = getCustomFieldValueTitle(valueA);
    const titleB = getCustomFieldValueTitle(valueB);
    return titleA.localeCompare(titleB);
}

/**
 * Form allow multiple values for fields so we need normalize field value
 * @param value
 */
const normalizeFormValue = (value: string | string[] | null): string[] => {
    if (!value) {
        return [];
    }
    if (Array.isArray(value)) {
        return value;
    }
    return [value];
};

/**
 * Create filter by hierarchy & ordered selected values that placed before current field
 *
 * @param hierarchyType
 * @param filtrationData selected values grouped by parents & siblings
 */
const createFilter = (
    hierarchyType: HierarchyType,
    filtrationData: ICustomFiledFiltration,
) => (value: ICustomFieldValue) => {
    const {
        parent_custom_field_value_ids = [],
        all_parent_custom_field_ids = [],
        sibling_custom_field_value_ids = [],
        all_values_custom_field_ids = [],
    } = getHierarchyRelations(value, hierarchyType);

    const fitByParentNode = isEmpty(filtrationData.parentNodeValues)
        || Object.keys(filtrationData.parentNodeValues).every(fieldId => {
            if (all_parent_custom_field_ids.includes(fieldId)) {
                return true;
            }
            const selectedFormValue = normalizeFormValue(filtrationData.parentNodeValues[fieldId]);
            return difference(selectedFormValue, parent_custom_field_value_ids).length === 0;
        });

    const fitBySiblingNodes = isEmpty(filtrationData.siblingsNodesValues)
        || Object.keys(filtrationData.siblingsNodesValues).every(fieldId => {
            if (all_values_custom_field_ids.includes(fieldId)) {
                return true;
            }
            const selectedFormValue = normalizeFormValue(filtrationData.siblingsNodesValues[fieldId]);
            return difference(selectedFormValue, sibling_custom_field_value_ids).length === 0;
        });

    return fitByParentNode && fitBySiblingNodes;
};

export function filterByHierarchy(
    customFieldValues: ICustomFieldValue[],
    hierarchyType: HierarchyType,
    filtrationData: ICustomFiledFiltration,
): ICustomFieldValue[] {
    const orderedCustomFieldValues = [...customFieldValues].sort(compareByTitle);
    const onlyAvailableByHierarchy = createFilter(hierarchyType, filtrationData);
    return orderedCustomFieldValues.filter(onlyAvailableByHierarchy);
}
