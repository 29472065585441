import React, { useCallback, useMemo } from 'react';
import { Box, Button } from '@material-ui/core';
import { Form, Formik } from 'formik';
import FormField from '../../../../shared/components/formFields/FormField';
import { useCcpMainStyles, useCcpModalStyle } from '../../styles';
import DayPickerField from '../../../../shared/components/formFields/DayPickerField';
import { shortDateFormat } from '../../../../shared/models/Dates';
import { useDispatch, useSelector } from 'react-redux';
import { selectCcpCheckedTransactions } from './store/selectors';
import { selectCcpTransactionsById } from '../CcpTransactionsTable/store/selectors';
import { dollarCounter } from '../../../../shared/utils/counters/dollarCounter';
import ActionButton from '../../../../shared/components/buttons/ActionButton';
import { useSheetRejectStyles } from '../../../../shared/components/sidebars/SheetReject/styles';
import { submitReconciledInvoiceWithCsvResult } from '../../store/actions';
import { selectCurrentClientId } from '../../../../store/entities/clients/selectors/clientsSelectors';
import { ICcpSubmitReconciledRequest } from '../../models/ccpModel';

interface ICcpInvoiceFormProps {
    isLoading: boolean;
    onClose: () => void;
}

export const SubmitCcpInvoiceForm = ({
    isLoading,
    onClose,
}: ICcpInvoiceFormProps) => {
    const classes = useCcpModalStyle();
    const rootClasses = useCcpMainStyles();
    const classesRejectSheets = useSheetRejectStyles();
    const cancelButtonClasses = { root: classesRejectSheets.buttonCancel, label: classesRejectSheets.buttonTextStyle };
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);

    const onSubmit = useCallback(data => {
        const payload = {
            client_id: clientId,
            invoice_number: data.invoice_number,
            invoice_date: data.invoice_date_prism,
            transactions: data.itemsIds.map((x: string) => {
                return {
                    transaction_id: x,
                };
            }),
        } as ICcpSubmitReconciledRequest;
        dispatch(submitReconciledInvoiceWithCsvResult.init(payload));
    }, [dispatch, clientId]);

    const selectedItems = useSelector(selectCcpCheckedTransactions);
    const transactionsById = useSelector(selectCcpTransactionsById);

    const selectedItemsList = useMemo(() => {
        const results = Object.keys(selectedItems).filter(selectedItem => {
            if (!selectedItem.startsWith('_') && !!selectedItems[selectedItem]) {
                return selectedItem;
            }
        });
        return results;
    }, [selectedItems]);

    const amountCents = useMemo(() => {
        const totalCents = selectedItemsList && selectedItemsList.length > 0
            ? selectedItemsList.map(id => transactionsById[id]?.amount * 100)
                .reduce((accumulated, current) => accumulated + current) : 0;
        const dollarTotal = dollarCounter(totalCents);
        return dollarTotal;
    }, [selectedItemsList, transactionsById]);

    const initialValues = {
        itemsIds: selectedItemsList,
        invoice_number: '',
        invoice_date_prism: '',
    };

    return (
        <Box className={classes.ContentWrapper}>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={true}
            >
                <Form>
                    <Box width={'200px'} overflow={'hide'}>
                        <Box fontSize={'14px'} fontWeight={700}
                            marginTop={'15px'}>
                            Prism Invoice Number
                        </Box>
                        <FormField
                            name="invoice_number"
                            className={rootClasses.inputStandard}
                            autoComplete="off"
                            disabled={isLoading}
                            value={initialValues.invoice_number}
                        />
                        <Box fontSize={'14px'} fontWeight={700}
                            marginTop={'15px'}>
                            Invoice Date
                        </Box>
                        <Box className={classes.DateWrapper}>
                            <Box className={classes.DatePickerRow}>
                                <DayPickerField
                                    name={'invoice_date_prism'}
                                    format={shortDateFormat}
                                    placeholder={shortDateFormat}
                                    variant="inline"
                                    disabled={isLoading}
                                    value={initialValues.invoice_date_prism}
                                />
                            </Box>
                        </Box>
                        <Box fontSize={'14px'} fontWeight={700}
                            marginTop={'35px'}>
                            Total Amount in Batch {amountCents}
                        </Box>
                        <Box marginTop={'35px'}>
                            <ActionButton
                                variant="contained"
                                color="primary"
                                customType="success"
                                type="submit"
                                disabled={isLoading}
                            >
                                SAVE
                            </ActionButton>
                            <Button
                                onClick={onClose}
                                variant="contained"
                                classes={cancelButtonClasses}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Form>
            </Formik>
        </Box>
    );
};
