import React from 'react';
import clsx from 'clsx';
import { routes } from 'shared/routes';
import { Button } from '@material-ui/core';
import { IOfferLetter } from 'modules/offerLetter/store/model';
import { generatePath, Link } from 'react-router-dom';
import useActionButtonStyles from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';

interface IEditAndResendButtonProps {
    offerLetter: IOfferLetter;
}

export const RescindButton = ({ offerLetter }: IEditAndResendButtonProps) => {
    const actionButtonClasses = useActionButtonStyles();

    return (
        <Link
            to={generatePath(routes.CLIENT.OFFER_LETTER.RESCIND, {
                id: offerLetter.id,
                client_id: offerLetter.client_id,
            })}
            className={actionButtonClasses.buttonTextStyle}
        >
            <Button
                color="primary"
                variant="outlined"
                classes={{
                    root: clsx(actionButtonClasses.actionButton, actionButtonClasses.secondaryButton),
                    label: actionButtonClasses.buttonTextStyle,
                }}
            >
                Rescind
            </Button>
        </Link>
    );
};
