import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useTotalHoursStyles = makeHighPriorityStyles((theme: Theme) => ({
    totalWrapper: {
        display: 'flex',
        alignItems: 'baseline',
    },
    totalNum: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    totalNumLabel: {
        fontSize: 11,
        paddingLeft: theme.spacing(0.5),
    },
    totalSubtext: {
        textTransform: 'uppercase',
        fontSize: 10,
    },
}));

export default useTotalHoursStyles;
