import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';
import { Theme } from '@material-ui/core/styles';

const useAddEntryControlsStyles = makeHighPriorityStyles((theme: Theme) => ({
    paper: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
        border: `1px solid ${colors.gray}`,
    },
}));

export default useAddEntryControlsStyles;
