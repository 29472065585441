import React from 'react';
import { Box } from '@material-ui/core';
import {
    getDealTypeArBuckets,
    getDealTypePayCodeArBuckets,
    getDealTypes,
} from 'store/entities/configuration/configurationAction';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { PayCodeArBucketTable } from './components/PayCodeArBucketTable';
import { CreateDealTypePayCodeArBucketAssociation } from './components/CreateDealTypePayCodeArBucketAssociation';

export function PayCodeArBucketPage() {
    useWithClientEffect(dispatch => {
        dispatch(getDealTypePayCodeArBuckets.init());
        dispatch(getDealTypes.init());
        dispatch(getDealTypeArBuckets.init());
    });

    return (
        <>
            <Box>
                <CreateDealTypePayCodeArBucketAssociation/>
            </Box>
            <PayCodeArBucketTable/>
        </>
    );
}
