import { loadAttachments } from 'shared/components/attachments/store/actions';
import { selectAttachmentObjectUrlById } from 'shared/components/attachments/store/selectors';
import { attachmentApi } from 'shared/components/attachments/store/api';
import { loadExpenseEntryAttachment } from 'store/entities/timesheet/actions/entryAttachments';
import { withErrorHandler } from 'store/utils/sagas/withErrorHandler';
import { call, put, select, takeEvery } from 'typed-redux-saga';

function* loadAttachmentSaga(
    { payload: id }: ReturnType<typeof loadExpenseEntryAttachment.init>,
) {
    const existAttachment = yield select(selectAttachmentObjectUrlById(id));
    if (existAttachment) {
        return;
    }
    const attachmentBlob = yield* call(attachmentApi.downloadAttachment, id);
    const objectUrl = window.URL.createObjectURL(attachmentBlob);
    yield* put(loadAttachments.success([
        {
            id,
            objectUrl,
        },
    ]));
}

function* loadAttachmentWatcher() {
    yield takeEvery(loadAttachments.initType, withErrorHandler(
        loadAttachmentSaga,
        loadAttachments.error,
        'Attachment is not loaded',
    ));
}

export default [
    loadAttachmentWatcher,
];
