import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getScaZones, getStates, getStatesByScaZone } from 'store/entities/scaZone/actions';
import { scaZoneApi } from 'store/entities/scaZone/api';

function* getScaZonesSaga({
    payload: { assignmentId, stateId, parentScaZoneId },
}: ReturnType<typeof getScaZones.init>) {
    const scaZones = yield* call(scaZoneApi.getScaZones, parentScaZoneId, stateId);

    yield* put(getScaZones.success({ assignmentId, stateId, parentScaZoneId, scaZones }));
}

export function* getScaZonesWatcher() {
    yield* takeLatest(getScaZones.initType, getScaZonesSaga);
}

export const getStatesWatcher = getLoadEntitiesByRequestSagaWatcher(getStates, scaZoneApi.getStates, 'states');

function* getStatesByScaZoneSaga({ payload }: ReturnType<typeof getStatesByScaZone.init>) {
    const states = yield* call(scaZoneApi.getStates, {
        parent_sca_zone_id: payload.parentScaZoneId,
    });

    yield* put(getStates.success(states));
    yield* put(getStatesByScaZone.success({
        ...payload,
        stateIds: states.map(state => state.id),
    }));
}

export function* getStatesByScaZonesWatcher() {
    yield* takeLatest(getStatesByScaZone.initType, getStatesByScaZoneSaga);
}
