import { IDeal } from 'shared/models/Deal';
import { IDealType } from 'shared/models/DealType';
import { IEntity } from 'shared/models/Entity';
import {
    FeeType, JobNumberGroup,
    ReasonForClose,
    TimesheetSettings,
    UWSystem,
} from 'shared/models/JobNumber';

export interface IJobNumberFormValues {
    jobNumber?: number;
    deal?: IDeal | null;
    dealType: IDealType | null;
    jobDescription: string;
    startDate: string;
    endDate?: string;
    managerId?: string;
    userTypeId?: string;
    jobNumberGroup?: JobNumberGroup | null;

    uwSystem: UWSystem;
    fte: number;
    timesheetSettings: TimesheetSettings;
    timeActivities: IEntity[];

    billingInfo?: FeeType;
    max: string | null;
    min: string | null;
    miCredit: string | null;
    blendedAmount: string | null;
    billingFileRate: string | null;
    billingPerDiemRate: string | null;

    payrollIsPerDiem: boolean;
    payrollHourlyRate: string | null;
    payrollFileRate: string | null;
    payrollPerDiemRate: string | null;

    reasonForClose?: ReasonForClose;
    closeOnDate?: string;
}

export const defaultJobNumberFormValues: IJobNumberFormValues = {
    deal: null,
    dealType: null,
    billingInfo: undefined,
    fte: 0,
    payrollIsPerDiem: false,
    jobDescription: '',
    managerId: '',
    userTypeId: '',
    startDate: '',
    endDate: '',
    timeActivities: [],
    uwSystem: UWSystem.Enact,
    max: null,
    min: null,
    miCredit: null,
    blendedAmount: null,
    billingFileRate: null,
    billingPerDiemRate: null,
    payrollPerDiemRate: null,
    payrollFileRate: null,
    payrollHourlyRate: null,
    timesheetSettings: TimesheetSettings.Hourly,
};
