import React from 'react';
import clsx from 'clsx';
import FormField from 'shared/components/formFields/FormField';
import useOutlinedTextFieldStyled from 'shared/components/textField/CustomOutlinedTextFieldStyles';
import { Typography } from '@material-ui/core';
import { EafType, eafTypeDisplayValues } from 'modules/employeeActionsForm/store/models';
import { useSheetRejectStyles } from 'shared/components/sidebars/SheetReject/styles';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { IUserInfo } from 'shared/models/User';
import { inputParams } from 'shared/styles/constants';
import { getLastFirstName } from 'shared/utils/converters/user';

export interface IEafRejectionCardProps {
    eafId: string;
    employee: IUserInfo;
    type: EafType;
    reasonFieldName: string;
}

export const EafRejectionCard = ({
    employee,
    type,
    reasonFieldName,
    eafId,
}: IEafRejectionCardProps) => {
    const classes = useSheetRejectStyles();
    const sidebarClasses = useSidebarStyles();
    const inputClasses = useOutlinedTextFieldStyled();
    const sectionRootClassName = clsx(sidebarClasses.sidebarElement, classes.rejectionCard);

    return (
        <section key={eafId} className={sectionRootClassName}>
            <Typography variant="h6">
                {getLastFirstName(employee)}
            </Typography>

            <section>
                <Typography>
                    {eafTypeDisplayValues[type]}
                </Typography>
            </section>

            <section>
                <FormField
                    name={reasonFieldName}
                    inputProps={{
                        className: inputClasses.input,
                        ...inputParams,
                    }}
                    placeholder="Reason for rejection"
                    autoComplete="off"
                />
            </section>
        </section>
    );
};
