import { IStore } from '../../configureStore';
import { SyncingModels } from './syncing/models';

const selectAppConfigState = (state: IStore) => state.appConfig;

const selectUiSyncingState = (state: IStore) => selectAppConfigState(state).uiSyncing;

/**
 * Select status of UI syncing according to the entered module
 * @param module
 */
export const selectUiSyncingByModule = (module: SyncingModels) =>
    (state: IStore) => selectUiSyncingState(state)[module] || false;
