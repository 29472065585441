import React from 'react';
import OfferLetterRescissionContent from './components/OfferRescission/OfferLetterRescissionContent';
import OfferLetterPanel, { IOfferLetterPanelRoute } from './OfferLetterPanel';

const OfferLetterRescission: React.FC<IOfferLetterPanelRoute> = ({
    match: { params: { id } },
}: IOfferLetterPanelRoute) => {
    const getTitle = () => 'Rescind offer letter';
    return (
        <OfferLetterPanel
            id={id}
            getTitle={getTitle}
            renderSidebar={({ offerLetter, onClose }) => (
                <OfferLetterRescissionContent
                    id={offerLetter.id}
                    onCancel={onClose}
                    offerLetter={offerLetter}
                />
            )}
        />
    );
};

export default OfferLetterRescission;
