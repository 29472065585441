import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useSubmitSheetStyles = makeHighPriorityStyles((theme: Theme) => ({
    paper: {
        width: theme.spacing(60),
        backgroundColor: colors.whiteSecondary,
    },
    title: {
        '&& *': {
            fontSize: 18,
            textTransform: 'uppercase',
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    innerTitle: {
        '&& *': {
            fontSize: 18,
            textTransform: 'uppercase',
            fontWeight: 500,
        },
    },
    content: {
        position: 'relative',
        margin: theme.spacing(2, 0, 5),
        padding: theme.spacing(0, 10, 0, 11),
    },
    warningWrapper: {
        margin: 0,
        position: 'relative',
    },
    warningCheckbox: {
        top: 0,
        padding: 0,
        position: 'absolute',
        left: theme.spacing(-6),
    },
    warningText: {
        fontSize: 14,
    },
    typography: {
        lineHeight: 1.25,
    },
    warning: {
        marginTop: theme.spacing(2),
        fontSize: 16,
    },
    checkItems: {
        fontSize: 16,
    },
    checkOption: {
        display: 'flex',
        alignItems: 'center',
    },
}));
