import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { routes } from 'shared/routes';
import { TerminationRequests } from 'modules/humanResources/components/TerminationRequests/TerminationRequests';
import { Permission } from 'store/components/auth/authModels';

export const HumanResourcesRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path={routes.HUMAN_RESOURCES.TERMINATION_REQUESTS}
                component={TerminationRequests}
                permission={Permission.ManageEaf}
                waitOptimizely
            />
            <Redirect
                path={routes.HUMAN_RESOURCES.ROOT}
                to={routes.HUMAN_RESOURCES.TERMINATION_REQUESTS}
            />
        </Switch>
    );
};
