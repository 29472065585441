import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { createCustomFieldValue, setCreateCustomFieldValueModalState } from 'modules/settings/submodules/clients/customFieldValues/store/actions';

const isOpen = singleValueReducer(setCreateCustomFieldValueModalState.action, false);
const isCreating = isLoadingReducer(createCustomFieldValue);

export const customFieldValueCreate = combineReducers({
    isOpen,
    isCreating,
});
