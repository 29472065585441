import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { BulkCreateWithSameValues } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/BulkCreateWithSameValues';
import { PreInitializeCreationReportV2 } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreationReportV2/PreInitializeCreationReportV2';
import { CreationPostInitializeReportV2 } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreationReportV2/PostCreationReportV2';
import { SelectCreationMode } from 'modules/subassignmentManagement/components/CreateSubAssignment/SelectCreationMode/SelectCreationMode';
import { BulkCreateSubassignmentSteps, setBulkCreateSubassignmentStep, setOpenBulkCreateSubassignmentModal } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/actions';
import { CreationMode } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/model';
import { selectCreationStep, selectIsOpenModal } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/selectors';
import { BulkCreateWithDifferentValues } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/BulkCreateWithDifferentValues';
import { bulkCreateSubAssignmentActionV2, bulkCreateSubAssignmentPreInitializeActionV2, downloadFailedCsvCreateSubAssignmentAction, getPreInitializeCreateSubAssignmentPdfAction } from 'modules/subassignmentManagement/store/actions';
import { IBulkCreateSubassignmentsRequest } from 'modules/subassignmentManagement/store/models';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { ISubassignmentBase } from 'store/entities/configuration/configurationModel';

const stepTitleMapping: Record<BulkCreateSubassignmentSteps, string> = {
    [BulkCreateSubassignmentSteps.Form]: '1. Bulk Create - Manual Entry',
    [BulkCreateSubassignmentSteps.Verify]: '2. Verify',
    [BulkCreateSubassignmentSteps.PostReport]: '3. Report',
};

export const BulkCreateSubAssignmentModalV2 = () => {
    const dispatch = useDispatch();
    const isOpened = useSelector(selectIsOpenModal);
    const step = useSelector(selectCreationStep);
    const [creationMode, setCreationMode] = useState<CreationMode | null>(null);
    const [creationPayload, setCreationPayload] = useState<IBulkCreateSubassignmentsRequest | null>(null);

    const onClose = useCallback(() => {
        dispatch(setOpenBulkCreateSubassignmentModal(false));
    }, [dispatch]);
    const handleSubmitPreInitializeReport = useCallback((subassignments: ISubassignmentBase[]) => {
        const payload = { subassignments };
        setCreationPayload(payload);
        dispatch(bulkCreateSubAssignmentPreInitializeActionV2.init(payload));
    }, [dispatch]);
    const handleCreation = useCallback(() => {
        // @ts-ignore
        dispatch(bulkCreateSubAssignmentActionV2.init(creationPayload));
    }, [dispatch, creationPayload]);
    const handleDownloadPdf = useCallback(() => {
        // @ts-ignore
        dispatch(getPreInitializeCreateSubAssignmentPdfAction.init(creationPayload));
    }, [dispatch, creationPayload]);
    const handleDownloadFailedCsv = useCallback(() => {
        // @ts-ignore
        dispatch(downloadFailedCsvCreateSubAssignmentAction.init(creationPayload));
    }, [dispatch, creationPayload]);
    const onBack = useCallback(() => {
        dispatch(setBulkCreateSubassignmentStep(BulkCreateSubassignmentSteps.Form));
    }, [dispatch]);

    useEffect(() => {
        setCreationMode(null);
    }, [isOpened, setCreationMode]);

    return (
        <ModalDialog
            title={stepTitleMapping[step]}
            isOpened={isOpened}
            onClose={onClose}
            colored
            modalProps={{
                showCloseIcon: true,
            }}
        >
            <Box>
                {!creationMode ? (
                    <SelectCreationMode setCreationMode={setCreationMode}/>
                ) : (
                    <>
                        {step === BulkCreateSubassignmentSteps.Form && creationMode === CreationMode.SameValues && (
                            <BulkCreateWithSameValues onSubmit={handleSubmitPreInitializeReport}/>
                        )}
                        {step === BulkCreateSubassignmentSteps.Form
                            && creationMode === CreationMode.DifferentValues && (
                            <BulkCreateWithDifferentValues onSubmit={handleSubmitPreInitializeReport}/>
                        )}
                        {step === BulkCreateSubassignmentSteps.Verify && (
                            <PreInitializeCreationReportV2
                                onBack={onBack}
                                onNextClick={handleCreation}
                                onDownloadReportPdfClick={handleDownloadPdf}
                                onDownloadFailedCsvClick={handleDownloadFailedCsv}
                            />
                        )}
                        {step === BulkCreateSubassignmentSteps.PostReport && (
                            <CreationPostInitializeReportV2 mainButtonHandler={onClose}/>
                        )}
                    </>
                )}
            </Box>
        </ModalDialog>
    );
};
