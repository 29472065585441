import { createSingleAction } from 'store/utils';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';

const SET_COMMON_ENTRY_FORM_VALUES = 'WorkingTab/SET_COMMON_ENTRY_FORM_VALUES';
export const setCommonEntryFormValues = createSingleAction<ICommonEntryFormValues,
    typeof SET_COMMON_ENTRY_FORM_VALUES>(
    SET_COMMON_ENTRY_FORM_VALUES,
);

const CLEAR_SELECTOR_VALUE = 'WorkingTab/CLEAR_SELECTOR_VALUE';
export const clearSelectorValue = createSingleAction<string,
    typeof CLEAR_SELECTOR_VALUE>(
    CLEAR_SELECTOR_VALUE,
);
