import { AllowedDisplayStatus } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/useGetDisplaySheetStatus';
import React from 'react';
import clsx from 'clsx';
import { Chip, Grid, Typography } from '@material-ui/core';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import useSubmitStatusStyles from './SubmitStatusStyles';

interface ISubmitStatusProps {
    status: AllowedDisplayStatus;
    dueDate: string;
    customClasses?: string;
    labelClass?: string;
}

export default function SubmitStatus({ status, dueDate, customClasses = '', labelClass = '' }: ISubmitStatusProps) {
    const classes = useSubmitStatusStyles();

    const statusToColor: Partial<Record<AllowedDisplayStatus, string>> = {
        [StatusNames.SUBMITTED]: classes.blue,
        [StatusNames.REJECTED]: classes.red,
        [StatusNames.APPROVED]: classes.lightGreen,
    };

    return (
        <Grid container alignItems="center"
            className={customClasses}
        >
            <Chip size="small" variant="outlined"
                label={status} className={clsx(classes.status, statusToColor[status], labelClass)}
            />
            <Typography className={classes.dueDate}>
                Due on {dueDate}
            </Typography>
        </Grid>
    );
}
