import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IDeal } from 'shared/models/Deal';
import { IJobNumber, IJobNumberUserType } from 'shared/models/JobNumber';
import { IUserInfo } from 'shared/models/User';
import { selectDealsById, selectJobNumbersById, selectJobNumbersUserTypesById } from 'store/entities/configuration/configurationSelectors';
import { selectUsersById } from 'store/entities/users/selectors';
import { jobNumbersTableStateSelectors } from 'modules/settings/submodules/clients/jobNumber/store/selectors';

export interface IJobNumberRow extends IJobNumber {
    employee?: IUserInfo;
    manager?: IUserInfo;
    deal?: IDeal;
    userType?: IJobNumberUserType;
    className?: string;
}

export const useJobNumberRows = (): IJobNumberRow[] => {
    const displayJobNumberIds = useSelector(jobNumbersTableStateSelectors.selectItems);
    const jobNumbersById = useSelector(selectJobNumbersById);
    const userInfoById = useSelector(selectUsersById);
    const dealsById = useSelector(selectDealsById);
    const userTypesById = useSelector(selectJobNumbersUserTypesById);

    return useMemo(() => {
        return displayJobNumberIds.map(jobNumberId => {
            const jobNumber = jobNumbersById[jobNumberId];
            if (!jobNumber) {
                return null;
            }
            return {
                ...jobNumber,
                employee: userInfoById[jobNumber.user_id],
                manager: userInfoById[jobNumber.manager_id],
                deal: dealsById[jobNumber.deal_id],
                userType: userTypesById[jobNumber.user_type_id || ''],
            };
        }).filter(row => row) as IJobNumberRow[];
    }, [dealsById, displayJobNumberIds, jobNumbersById, userInfoById, userTypesById]);
};
