import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { formBasicSpacing, useStyles } from '../styles';
import { IOfferLetterFormValues } from '../model';
import { FormikProps } from 'formik';
import { OfferLetterOfferForm } from './OfferLetterOfferForm';
import { MultiStepFormValues } from 'shared/components/forms/multiStep/MultiStepForm';

export const OFFER_LETTER_FORM_STEP_OFFER = 'OFFER_LETTER_FORM_STEP_OFFER';

export function OfferLetterFormStepOffer({
    ...formProps
}: FormikProps<MultiStepFormValues<IOfferLetterFormValues>>) {
    const classes = useStyles();

    return (
        <Box className={classes.form}>
            <Box
                m={formBasicSpacing}
                mt={2.5}
                mb={0}
            >
                <Typography variant="subtitle2">
                    2. Create offer
                </Typography>
            </Box>
            <OfferLetterOfferForm {...formProps}/>
        </Box>
    );
}
