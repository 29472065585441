import React, { useCallback } from 'react';
import { useField } from 'formik';
import { timeCounter } from 'shared/utils/counters/timeCounter';
import { timeFormatter } from 'shared/components/textField/time/timeFormatter';
import { IFormFieldProps } from 'shared/components/formFields/models';
import NumberInput from 'shared/components/formFields/NumberInput';
import { timeParserToInputValue } from 'shared/components/formFields/utils';
import { QuantityType } from 'shared/models/sheet/Sheet';

interface ITimeFieldProps extends IFormFieldProps {
    minutesField: string;
    hoursField: string;
    entryType: QuantityType;
    hideError: boolean;
    dataTestId?: string;
}

const TimeField = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
    minutesField = 'minutes',
    hoursField = 'hours',
    entryType = QuantityType.TIME,
    hideError = false,
    dataTestId,
}: ITimeFieldProps) => {
    const [field, meta, helper] = useField(name);

    const initialValue = (field.value?.[minutesField] || field.value?.[hoursField])
        ? timeCounter(field.value?.[minutesField] || 0, field.value?.[hoursField] || 0)
        : '';

    const setTime = useCallback((hours: number, minutes: number) => {
        helper.setValue({
            ...(entryType === QuantityType.TIME_IN_OUT_BREAK && field.value ? field.value : {}),
            entry_type: entryType,
            [hoursField]: hours,
            [minutesField]: minutes,
        });
    }, [entryType, field.value, helper, hoursField, minutesField]);

    const handleChange = useCallback((
        value: string,
        setValue?: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const parsedTime = timeParserToInputValue(value);
        if (parsedTime) {
            const { hours, minutes } = parsedTime;
            setTime(hours, minutes);
            const minutesLeadingZero = minutes < 10 ? 0 : '';
            const inputValue = `${hours}:${minutesLeadingZero}${minutes}`;
            setValue && setValue(inputValue);
        } else {
            setValue && setValue('');
        }
    }, [setTime]);

    const onFocus = useCallback((
        event: React.FocusEvent<HTMLInputElement>,
        value: string,
        setValue: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const parsedTime = timeParserToInputValue(value);
        if (!parsedTime) {
            setValue('');
        }
    }, []);

    return (
        <NumberInput
            key={initialValue}
            id={id}
            name={name}
            label={label}
            initialValue={initialValue}
            className={className}
            inputProps={{
                format: timeFormatter,
                'data-testid': dataTestId,
            }}
            disabled={disabled}
            error={hideError ? null : meta.error}
            touched={meta.touched}
            setTouched={helper.setTouched}
            onChange={handleChange}
            onFocus={onFocus}
        />
    );
};

export default TimeField;
