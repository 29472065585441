import { searchEntity } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/store/actions';
import { ActionsReturnTypes } from 'store/utils';

export interface ISearchResult {
    isLoading: boolean;
    results: string[];
}

export const defaultSearchState: ISearchResult = {
    isLoading: false,
    results: [],
};

export function entitiesAutocompleteSearch(
    state: Record<string, ISearchResult> = {},
    action: ActionsReturnTypes<typeof searchEntity>,
): Record<string, ISearchResult> {
    switch (action.type) {
        case searchEntity.initType: {
            // @ts-ignore
            const { searchKey } = action.payload;
            return {
                ...state,
                [searchKey]: {
                    ...defaultSearchState,
                    ...state[searchKey],
                    isLoading: true,
                },
            };
        }
        case searchEntity.successType: {
            // @ts-ignore
            const { searchKey, results } = action.payload;
            return {
                ...state,
                [searchKey]: {
                    results,
                    isLoading: false,
                },
            };
        }
        case searchEntity.errorType: {
            // @ts-ignore
            const { searchKey } = action.payload;
            return {
                ...state,
                [searchKey]: defaultSearchState,
            };
        }
        default:
            return state;
    }
}
