import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';
import {
    CcpStatusSlug,
    IBulkCreateCcpTransactionsResponse,
    ICcpInvoicesRequest,
    ICcpInvoicesResponse,
    ICcpSplitItem,
    ICcpSubmitReconciledRequest, ICcpTableFilters,
    ICcpTransactionRequest,
    ICcpTransactionsResponse,
    ICcpUpdateSplitTransactions,
    ITransactionChange,
    ITransactionCode,
} from 'modules/ccp/models/ccpModel';
import { ICcpInvoiceBatch, ICcpTransaction } from '../models/CcpTransaction';
import { CheckedItems } from 'shared/utils/hooks/useCheckedItems';

const moduleActionPrefix = 'ccp';

const INITIAL_LOAD_LIST = `${moduleActionPrefix}/INITIAL_LOAD_LIST`;
export const initialLoadCcpTransactionsPage = createSingleAction<void, typeof INITIAL_LOAD_LIST>(
    INITIAL_LOAD_LIST,
);

const TRIGGER_LOAD_LIST = `${moduleActionPrefix}/TRIGGER_LOAD_LIST`;
export const triggerLoadCcpTransactionsPage = createSingleAction<void, typeof TRIGGER_LOAD_LIST>(
    TRIGGER_LOAD_LIST,
);

const SET_CCP_FILTER = `${moduleActionPrefix}/SET_CCP_FILTER`;
export const setCcpFilter = createSingleAction<
Partial<ICcpTableFilters>,
    typeof SET_CCP_FILTER
>(
    SET_CCP_FILTER,
);

export const currentCcpListSkip = createSingleAction<number>(`${moduleActionPrefix}/SET_CCP_TRANSACTIONS_ACTIVE_TAB_OFFSET`);

export const setCcpTransactionsActiveTab = createSingleAction<CcpStatusSlug>(`${moduleActionPrefix}/SET_CCP_TRANSACTIONS_ACTIVE_TAB`);

export const resetCcpTransactionsItems = createSingleAction<void>(
    `${moduleActionPrefix}/RESET_CCP_TRANSACTIONS_ITEMS`,
);

const RESET_CCP_AVAILABLE_USER_IDS = `${moduleActionPrefix}/RESET_CCP_AVAILABLE_USER_IDS`;
export const updateCcpAvailableUserIdsList = createSingleAction<string[]>(RESET_CCP_AVAILABLE_USER_IDS);

export const changeCcpTransaction = createRequestActions<ITransactionChange, ITransactionCode>(
    RequestType.PATCH,
    'CHANGE_CCP_TRANSACTION_ITEM',
    moduleActionPrefix,
);

export const splitCcpTransaction = createRequestActions<ICcpSplitItem[], ICcpTransaction[]>(
    RequestType.Create,
    'SPLIT_CCP_TRANSACTION_ITEM',
    moduleActionPrefix,
);

export const getTransactionCodes = createRequestActions<void, ITransactionCode[]>(
    RequestType.Get,
    'TRANSACTION_CODE',
);

export const getCsvReconciled = createRequestActions<void, void>(
    RequestType.Get,
    'RECONCILED_TRANSACTIONS_CSV',
);

export const getCsvUnreconciled = createRequestActions<void, void>(
    RequestType.Get,
    'UNRECONCILED_TRANSACTIONS_CSV',
);

export const submitReconciledInvoiceWithCsvResult = createRequestActions<ICcpSubmitReconciledRequest, void>(
    RequestType.Update,
    'RECONCILED_TRANSACTIONS__INVOICE_CSV',
);

export const sendReminder = createRequestActions<void, void, void>(
    RequestType.Update,
    'SEND_REMINDER',
);

export const getCsvInvoicedItem = createRequestActions<string, void>(
    RequestType.Get,
    'INVOICED_TRANSACTIONS_CSV',
);

const SET_CCP_CHECKED_ITEMS = `${moduleActionPrefix}/SET_CCP_CHECKED_ITEMS`;
export const setCcpCheckedItems = createSingleAction<CheckedItems, typeof SET_CCP_CHECKED_ITEMS>(
    SET_CCP_CHECKED_ITEMS,
);

export const getMoreCcpTransactionsAction = createRequestActions<void, ICcpTransactionsResponse>(
    RequestType.Get,
    'MORE_CCPTRANSACTIONS',
    moduleActionPrefix,
);

export const getMoreCcpInvoicesAction = createRequestActions<void, ICcpInvoicesResponse>(
    RequestType.Get,
    'MORE_CCPINVOICES',
    moduleActionPrefix,
);

export const getCcpTransactions = createRequestActions<ICcpTransactionRequest, ICcpTransaction[]>(
    RequestType.Get,
    'CCPTRANSACTIONS');

export const getCcpInvoices = createRequestActions<ICcpInvoicesRequest, ICcpInvoiceBatch[]>(
    RequestType.Get,
    'CCPINVOICES');

export const bulkUploadCreateCcpTransactionPreInitializeAction = createRequestActions<
File,
IBulkCreateCcpTransactionsResponse
>(
    RequestType.Create,
    'BULK_UPLOAD_CCP',
    moduleActionPrefix,
);

export const bulkUploadCcpTransactionAction = createRequestActions<
File,
IBulkCreateCcpTransactionsResponse
>(
    RequestType.Create,
    'BULK_IMPORT_UPLOAD_CCP',
    moduleActionPrefix,
);

export const submitCcpTransactionAction = createRequestActions<void, ICcpTransaction[]>(
    RequestType.Create,
    'SUBMIT_UNRECONCILED_CCP',
    moduleActionPrefix,
);

export const triggerEditModeSave = createRequestActions<void, void>(
    RequestType.PATCH,
    'EDIT_MODE_SAVE',
    moduleActionPrefix,
);

const EDIT_MODE_SPLIT = `${moduleActionPrefix}/EDIT_MODE_SPLIT`;
export const editModeUpdateSplitTransactions = createSingleAction<ICcpUpdateSplitTransactions, typeof EDIT_MODE_SPLIT>(
    EDIT_MODE_SPLIT,
);

export type CcpTransactionsActions =
    | ReturnType<typeof setCcpTransactionsActiveTab>
    | ReturnType<typeof currentCcpListSkip>
    | ReturnType<typeof resetCcpTransactionsItems>
    | ReturnType<typeof initialLoadCcpTransactionsPage>
    | ReturnType<typeof setCcpFilter>
    | ReturnType<typeof editModeUpdateSplitTransactions>
    | ActionsReturnTypes<typeof triggerEditModeSave>
    | ActionsReturnTypes<typeof changeCcpTransaction>
    | ActionsReturnTypes<typeof getMoreCcpTransactionsAction>
    | ActionsReturnTypes<typeof getMoreCcpInvoicesAction>
    | ActionsReturnTypes<typeof bulkUploadCreateCcpTransactionPreInitializeAction>
    | ActionsReturnTypes<typeof submitCcpTransactionAction>
    | ActionsReturnTypes<typeof splitCcpTransaction>

export const setCcpEditMode = createSingleAction<boolean>(`${moduleActionPrefix}/SET_CCP_EDIT_MODE`);
