import React from 'react';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { IEntity } from 'shared/models/Entity';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useTimeAndExpensePageContent } from 'modules/clients/content/TimeAndExpensePage/TimeAndExpenseHeaderContent';
import { useIsMobile } from 'shared/utils/hooks/media';
import SheetsSubmittedMobileView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedMobileView';
import SheetsSubmittedDesktopView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedDesktopView';

export interface ISheetsSubmittedViewPureProps<Row> {
    timeCells: Array<ICellInfo<Row>>;
    expenseCells: Array<ICellInfo<Row>>;
    sheetsEntryTypes?: Record<string, EntryType>;
    timeRows: Array<Row>;
    expenseRows: Array<Row>;
    isLoading: boolean;
    showFilters?: boolean;
    availableActions?: (props: IAvailableActionsProps) => JSX.Element;
}

export function SheetsSubmittedViewPure<Row extends IEntity>({
    showFilters = true,
    ...props
}: ISheetsSubmittedViewPureProps<Row>) {
    const isMobile = useIsMobile();
    useTimeAndExpensePageContent();

    return isMobile ? (
        <SheetsSubmittedMobileView {...props}/>
    ) : (
        <SheetsSubmittedDesktopView {...props} showFilters={showFilters}/>
    );
}
