import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import {
    ButtonBase,
    FormControl,
    FormHelperText,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import { createScaZoneFull, IOptionsConfig } from 'shared/components/formSpecialFields/scaZone/model';
import { useScaZone } from 'shared/components/formSpecialFields/scaZone/hooks';
import { useScaZoneStyles } from 'shared/components/formSpecialFields/scaZone/scaZoneStyles';
import { useModal } from 'shared/utils/hooks/useModal';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { IScaZoneFull } from 'shared/models/sheet/Sheet';
import ScaZoneInput from './ScaZoneInput';

interface IScaZoneProps {
    customClasses?: string;
    label: string;
    assignmentId?: string;
    parentScaZoneId?: number;
    onChange: (scaZone: IScaZoneFull | null) => void;
    error?: string;
}

export default function ScaZoneCommon({
    label,
    assignmentId,
    parentScaZoneId,
    onChange,
    error,
    customClasses = '',
}: IScaZoneProps) {
    const classes = useScaZoneStyles();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();
    const [hasFocus, setHasFocus] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const {
        optionsList, stateValue, scaZoneValue, onSetStateId,
        onSetCountyId, statesLoading, scaZonesLoading, countyValue,
    } = useScaZone(parentScaZoneId, assignmentId, {
        stateSearch: inputValue,
        countySearch: inputValue,
    });

    const onChangeRef = useRef(onChange);

    const handleOnFocus = useCallback(() => {
        onModalOpen();
        setHasFocus(true);
    }, [onModalOpen, setHasFocus]);

    useEffect(() => {
        if (!stateValue && !hasFocus) {
            onModalClose();
        }
    }, [hasFocus, onModalClose, stateValue]);

    useEffect(() => {
        setInputValue('');
    }, [stateValue, scaZoneValue, setInputValue]);

    useEffect(() => {
        onChangeRef.current(createScaZoneFull(scaZoneValue, stateValue, countyValue));
    }, [scaZoneValue, countyValue, stateValue, onChangeRef]);

    const options: IOptionsConfig | undefined = useMemo(() => {
        if (!stateValue) {
            return optionsList.states;
        } else if (!scaZoneValue) {
            return optionsList.counties;
        } else {
            return undefined;
        }
    }, [optionsList, stateValue, scaZoneValue]);

    const resetPreviousValue = useMemo(() => {
        if (!stateValue) {
            return undefined;
        } else if (!countyValue) {
            return onSetStateId;
        }
        return onSetCountyId;
    }, [countyValue, stateValue, onSetStateId, onSetCountyId]);

    const onInput = useCallback((value: string) => {
        setInputValue(value);
    }, [setInputValue]);

    const onBlur = useCallback(() => {
        setHasFocus(false);
    }, [setHasFocus]);

    const startAdornment = useMemo(
        () => stateValue ? (
            <InputAdornment position="start" className={classes.inputAdornment}>
                {stateValue.state_shortname}-{scaZoneValue && countyValue
                    ? `${scaZoneValue.id}`
                    : ''
                }
            </InputAdornment>
        ) : undefined,
        [stateValue, scaZoneValue, classes, countyValue],
    );

    const fixedError = useMemo(() => scaZoneValue ? undefined : error, [error, scaZoneValue]);

    return isModalOpened ? (
        <ScaZoneInput
            value={inputValue}
            onChange={onInput}
            onBlur={onBlur}
            startAdornment={startAdornment}
            label={label}
            options={options}
            customClasses={customClasses}
            resetPreviousValue={resetPreviousValue}
            error={error}
            loading={statesLoading || scaZonesLoading}
        />
    ) : (
        <FormControl className={clsx(customClasses, classes.wrapper)} error={!!fixedError}>
            <ButtonBase role="button" className={classes.addButton}
                onFocus={handleOnFocus} onClick={onModalOpen}>
                <AddCircleOutlineOutlined fontSize="small"/>
                <Typography variant="caption" className={classes.title}>SCA Zone</Typography>
            </ButtonBase>
            {fixedError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}
