import React, { useCallback } from 'react';
import { isEmpty } from 'lodash-es';
import { Box } from '@material-ui/core';
import { setEafApprovalFilter } from 'modules/eafApproval/store/actions';
import { IEafApprovalFilter } from 'modules/eafApproval/store/model/types';
import { EafType, eafTypeDisplayValues } from 'modules/employeeActionsForm/store/models';
import { selectEafApprovalGridFilter } from 'modules/eafApproval/store/selectors';
import FilterChip from 'shared/components/filters/FilterChip';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import { getUserName } from 'shared/utils/converters/user';
import { selectUsersById } from 'store/entities/users/selectors';

export function EafApprovalFilterChips() {
    const dispatch = useDispatch();
    const filter = useSelector(selectEafApprovalGridFilter);
    const usersByIds = useSelector(selectUsersById);

    const onDelete = useCallback((name: keyof IEafApprovalFilter) => {
        dispatch(setEafApprovalFilter({
            ...filter,
            [name]: undefined,
        }));
    }, [dispatch, filter]);

    const hasFilters = !isEmpty(Object.values(filter).filter(Boolean));

    return hasFilters ? (
        <Box
            mb={2}
            data-testid={FiltersTestIds.ChipsWrapper}
        >
            {filter.employeeUserId && (
                <FilterChip
                    name="employeeUserId"
                    label={getUserName(usersByIds[filter.employeeUserId])}
                    onDelete={onDelete}
                />
            )}

            {filter.type && (
                <FilterChip
                    name="type"
                    label={eafTypeDisplayValues[filter.type as EafType]}
                    onDelete={onDelete}
                />
            )}
        </Box>
    ) : null;
}
