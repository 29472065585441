import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useOfferPdfOnlyStyles = makeHighPriorityStyles({
    wrapper: {
        height: '100vh',

        '& > *': {
            height: '100%',
        },
    },
});
