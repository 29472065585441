/* eslint-disable @typescript-eslint/no-empty-function */
import { useDispatch, useSelector } from 'react-redux';
import { selectCcpFilter } from './store/selectors';
import React, { useCallback } from 'react';
import { ICcpTableFilters } from '../../models/ccpModel';
import { setCcpFilter } from '../../store/actions';
import { CcpFilterForm } from './CcpFilterForm';
import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import { selectCcpEditMode } from 'modules/ccp/store/selectors';

export const CcpFilter = () => {
    const dispatch = useDispatch();
    const initialValues = useSelector(selectCcpFilter);
    const isEditMode = useSelector(selectCcpEditMode);

    const onChange = useCallback((values:Partial<ICcpTableFilters>) => {
        dispatch(setCcpFilter(values));
    }, [dispatch]);

    return (
        <Box>
            <Formik
                initialValues={initialValues}
                onSubmit={() => {}}
                validateOnBlur={false}
            >
                {props => (
                    <CcpFilterForm
                        {...props}
                        onChange={onChange}
                        actualFilterValues={initialValues}
                        disabled={isEditMode}
                    />
                )}
            </Formik>
        </Box>
    );
};
