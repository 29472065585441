import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { IExpenseEntry, TExpenseEntryData } from 'shared/models/sheet/Sheet';
import { selectDepartmentsById } from 'modules/employmentInfo/store/department/selectors';
import { useSplitCustomFieldValueIdsByCustomFiledId } from 'store/entities/customFields/utils';
import { IExpenseEntryUpdate } from 'store/entities/timesheet/models/Entry';
import { selectSheet, selectTempEntryAttachments } from 'store/entities/timesheet/selectors';
import {
    selectActivity,
    selectJobNumbersById,
    selectLocationsById,
    selectPositionsById,
    selectProjectsWithAssignments,
} from 'store/entities/configuration/configurationSelectors';
import { useExpenseSchema } from 'shared/models/validationSchemes/expenseEntry';
import { IExpenseEntryFormValues } from 'shared/components/forms/entries/ExpenseEntryModel';
import { ExpenseEntrySidebarForm } from 'shared/components/forms/entries/ExpenseEntrySidebarForm';
import { removeExpenseEntry, updateExpenseEntry } from 'store/entities/timesheet/actions/expenseActions';
import { TSubmitBlock } from './models';
import { useEntryEditStyles } from './styles';
import { getProjectAssignmentByProjectAndAssignmentIds, useGetCommonEntryModel } from 'shared/utils/helpers/entries';
import { IWithInputFields } from '../../forms/utils';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

interface IEditExpenseEntryProps extends IWithInputFields {
    entry: IExpenseEntry;
    supervisorId?: string;
    userId: string;
    SubmitBlock: TSubmitBlock;
    payPeriod: IPayPeriod;
}

export default function EditExpenseEntry({
    entry,
    supervisorId,
    SubmitBlock,
    inputs,
    userId,
    payPeriod,
}: IEditExpenseEntryProps) {
    const classes = useEntryEditStyles();
    const dispatch = useDispatch();
    const entryId = entry.id;
    const areaId = useSelector(selectSheet(entry.sheet_id))?.area_id;
    const projectAssignments = useSelector(selectProjectsWithAssignments);
    const activity = useSelector(selectActivity(entry.activity_id));
    const attachments = useSelector(selectTempEntryAttachments);
    const positionsById = useSelector(selectPositionsById);
    const locationsById = useSelector(selectLocationsById);
    const departmentsById = useSelector(selectDepartmentsById);
    const jobNumbersById = useSelector(selectJobNumbersById);
    const initialCustomFieldValues = useSplitCustomFieldValueIdsByCustomFiledId(entry.custom_field_value_ids);
    const getCommonEntryModel = useGetCommonEntryModel(userId);

    const initialValues: IExpenseEntryFormValues = {
        projectAssignment: getProjectAssignmentByProjectAndAssignmentIds(
            projectAssignments,
            entry.project_id,
            entry.assignment_id,
        ),
        taskId: entry.task_id,
        activity: activity,
        entry_date: entry.entry_date,
        data: entry.data as TExpenseEntryData,
        notes: entry.notes || '',
        zipCode: entry.zip_code,
        position: positionsById[entry.position_id ?? ''] || null,
        location: locationsById[entry.location_id ?? ''] || null,
        department: departmentsById[entry.department_id ?? ''],
        jobNumber: jobNumbersById[entry.job_number_id || ''],
        customFieldValues: initialCustomFieldValues,
    };

    const schema = useExpenseSchema(userId);

    const onSubmit = useCallback((
        values: IExpenseEntryFormValues,
        formikActions: FormikHelpers<IExpenseEntryFormValues>,
    ) => {
        const baseEntryModel = getCommonEntryModel(values);
        if (baseEntryModel && values.data) {
            const updatedEntry: IExpenseEntryUpdate = {
                ...baseEntryModel,
                id: entryId,
                data: values.data,
                zip_code: values.zipCode,
                sheet_entry_attachments: attachments,
                user_id: userId,
            };
            dispatch(updateExpenseEntry.init(updatedEntry));
        }
        formikActions.setSubmitting(false);
    }, [attachments, dispatch, entryId, getCommonEntryModel, userId]);

    const onDelete = useCallback(() => {
        dispatch(removeExpenseEntry.init(entryId));
    }, [dispatch, entryId]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <>
                    <ExpenseEntrySidebarForm
                        {...props}
                        supervisorId={supervisorId}
                        // @ts-ignore
                        areaId={areaId}
                        sheetId={entry.sheet_id}
                        entryId={entryId}
                        inputs={inputs}
                        userId={userId}
                        payPeriod={payPeriod}
                    />
                    <footer className={classes.footer}>
                        <SubmitBlock
                            onSubmit={props.submitForm}
                            onDelete={onDelete}
                        />
                    </footer>
                </>
            )}
        </Formik>
    );
}
