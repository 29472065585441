import { createSingleAction } from 'store/utils';
import { IGlobalModal } from 'shared/components/toasts/modal';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { THoverState } from 'shared/components/table/tableApprovalCells/hover';
import { IHeaderTitle } from 'store/entities/appConfig/appConfigModel';
import { SyncingModels } from './syncing/models';

export const SET_SIDEBAR_OPENED = 'SET_SIDEBAR_OPENED';
export const setSidebarOpenedAction = createSingleAction<boolean, typeof SET_SIDEBAR_OPENED>(SET_SIDEBAR_OPENED);
export type appSidebarActionsType = ReturnType<typeof setSidebarOpenedAction>;

export const SET_GLOBAL_TOAST = 'SET_GLOBAL_TOAST';
export const setGlobalToast = createSingleAction<IGlobalModal | null, typeof SET_GLOBAL_TOAST>(SET_GLOBAL_TOAST);
export type setGlobalToastActionType = ReturnType<typeof setGlobalToast>;

export const INCREASE_SYNCING = 'UI/INCREASE_SYNCING';
export const increaseSyncing = createSingleAction<SyncingModels, typeof INCREASE_SYNCING>(INCREASE_SYNCING);
export const DECREASE_SYNCING = 'UI/DECREASE_SYNCING';
export const decreaseSyncing = createSingleAction<SyncingModels, typeof DECREASE_SYNCING>(DECREASE_SYNCING);
export const CLEAR_SYNCING = 'UI/CLEAR_SYNCING';
export const clearSyncing = createSingleAction<SyncingModels, typeof CLEAR_SYNCING>(CLEAR_SYNCING);

export const SET_SHEET_GRID_STATUS = 'SET_SHEET_GRID_STATUS';
export const setSheetGridStatus = createSingleAction<StatusNames, typeof SET_SHEET_GRID_STATUS>(SET_SHEET_GRID_STATUS);

export const SET_GRID_HOVER_STATUS = 'SET_GRID_HOVER_STATUS';
export const setGridHoverStatus = createSingleAction<THoverState, typeof SET_GRID_HOVER_STATUS>(SET_GRID_HOVER_STATUS);
export type setGridHoverStatusActionType = ReturnType<typeof setGridHoverStatus>;

export const SET_HEADER_TITLE = 'SET_HEADER_TITLE';
export const setHeaderTitle = createSingleAction<string | IHeaderTitle, typeof SET_HEADER_TITLE>(SET_HEADER_TITLE);

export const SET_OPTIMIZELY_READY = 'SET_OPTIMIZELY_READY';
export const setOptimizelyReady = createSingleAction<boolean, typeof SET_OPTIMIZELY_READY>(SET_OPTIMIZELY_READY);
