import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { setCreateScopeModalState } from 'modules/settings/submodules/clients/scopes/store/actions';
import CreateScopeModal from 'modules/settings/submodules/clients/scopes/components/CreateScope/CreateScopeModal';

export const CreateScopeButton = () => {
    const dispatch = useDispatch();

    const openModal = useCallback(() => {
        dispatch(setCreateScopeModalState(true));
    }, [dispatch]);
    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + add scope
            </Button>
            <CreateScopeModal/>
        </>
    );
};
