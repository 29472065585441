import { IStore } from 'store/configureStore';
import { selectDealsState } from 'modules/settings/submodules/clients/deals/store/selectors';

const selectCreateDeal = (state: IStore) => {
    const dealsState = selectDealsState(state);
    return dealsState.createDeal;
};

export const selectIsDealModalOpen = (state: IStore) => {
    const createDealState = selectCreateDeal(state);
    return createDealState.isCreateDealModalOpen;
};

export const selectIsDealCreating = (state: IStore) => {
    const createDealState = selectCreateDeal(state);
    return createDealState.isCreating;
};
