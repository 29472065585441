import React from 'react';
import Sidebar, { ISidebarProps } from 'shared/components/sidebars/Sidebar/Sidebar';
import { Hidden } from '@material-ui/core';
import Modal, { IModalProps } from 'shared/components/modals/Modal';
import { useColoredFormModalStyles } from './ModalStyles';

interface IModalDialogProps {
    isOpened: boolean;
    colored?: boolean;
    title: string;
    children: React.ReactNode;
    onClose: () => void;
    desktopPaperClassName?: string;
    modalProps?: Partial<IModalProps>;
    sidebarProps?: Partial<ISidebarProps>;
}

export function ModalDialog({
    isOpened, onClose, title, children, modalProps = {}, sidebarProps = {}, colored = false,
}: IModalDialogProps) {
    const modalClasses = useColoredFormModalStyles();
    const modifiedModalProps = {
        ...modalProps,
        ...colored ? {
            customClasses: modalClasses,
        } : {},
    };
    return (
        <>
            <Hidden xsDown>
                <Modal title={title}
                    isOpened={isOpened} onClose={onClose}
                    {...modifiedModalProps}
                >
                    {children}
                </Modal>
            </Hidden>
            <Hidden smUp>
                <Sidebar title={title}
                    isOpened={isOpened}
                    onClose={onClose}
                    {...sidebarProps}
                >
                    {children}
                </Sidebar>
            </Hidden>
        </>
    );
}
