import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { getMoreCcpTransactionsAction, getTransactionCodes } from 'modules/ccp/store/actions';
import React, { useCallback, useEffect } from 'react';
import { CcpTransactionsTable } from 'modules/ccp/components/CcpTransactionsTable/CcpTransactionsTable';
import { Box } from '@material-ui/core';
import MainContent from 'shared/components/common/MainContent';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import CcpTransactionsTableHeader from './components/CcpTransactionsTable/CcpTransactionsTableHeader';
import {
    selectCcpTransactionsActiveTab,
    selectCcpTransactionsTableTotalItems,
} from './store/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BulkCreateCcpUploadFile } from './components/CcpCreate/BulkImportCcpTransactionsModal';
import { selectIsUserHasPermission } from '../../store/components/auth/selectors';
import { Permission } from '../../store/components/auth/authModels';
import { NoteEditorSidebar } from './components/CcpEdit/NoteEditorSidebar';
import { CcpReceiptEditorSidebar } from './components/CcpEdit/ReceiptEditorSidebar';
import ActionButton from '../../shared/components/buttons/ActionButton';
import { useCcpTableStyles } from './styles';
import { SubmitCcpTransactionsModal } from './components/CcpEdit/SubmitCcpTransactionsModal';
import { setConfirmSubmitCcpModal, setOpenSubmitCcpModal } from './components/CcpEdit/store/actions';
import { getUserCustomFieldRestrictions } from '../../store/entities/scopes/actions';
import { ScopeType } from '../../store/entities/scopes/models';
import { CcpStatusSlug, invoicedCcpSlug } from './models/ccpModel';
import { setHeaderContent } from '../home/header/Header';
import CcpTabs from './CcpTabs';
import { CcpInvoicesTable } from './components/CcpInvoices/CcpInvoicesTable';
import { SubmitCcpInvoiceModal } from './components/CcpInvoices/SubmitCcpInvoiceModal';
import { CcpReconciledSummaryBlock } from './components/CcpTransactionsTable/CcpReconciledSummaryBlock';
import CcpInvoicesTableHeader from './components/CcpInvoices/CcpInvoicesHeader';
import {
    selectClientCreditCardPortalPageCustomFieldId,
    selectClientCreditCardPortalPageRegionalDirectorRoleId,
} from 'store/entities/clients/selectors/configurationSelectors';
import { queryCustomFieldValues } from 'store/entities/customFields/actions';
import { CcpSplitSidebar } from './components/CcpSplit/CcpSplitSidebar';
import { getUsers } from '../../store/entities/users/actions';
import { selectCurrentClientId } from '../../store/entities/clients/selectors/clientsSelectors';

export function CcpPage() {
    const customFieldId = useSelector(selectClientCreditCardPortalPageCustomFieldId);
    useWithClientEffect((dispatch, clientId) => {
        dispatch(getMoreCcpTransactionsAction.init());
        dispatch(queryCustomFieldValues.init({ client_id: clientId, custom_field_id: customFieldId }));
    });
    const tableTotalAmount = useSelector(selectCcpTransactionsTableTotalItems, shallowEqual);
    const selectedTab = useSelector(selectCcpTransactionsActiveTab, shallowEqual);
    const hasImportPermission = useSelector(selectIsUserHasPermission(Permission.CanImportCcp), shallowEqual);
    const clientId = useSelector(selectCurrentClientId);
    const regionalManagerRoleId = useSelector(selectClientCreditCardPortalPageRegionalDirectorRoleId,
        shallowEqual);
    const styles = useCcpTableStyles();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUsers.init({ client_id: clientId, client_role_id: regionalManagerRoleId }));
    }, [dispatch, clientId, regionalManagerRoleId]);
    useEffect(() => {
        dispatch(getUserCustomFieldRestrictions.init({
            scope_types: [ScopeType.SubassignmentManagement],
        }));
        dispatch(getTransactionCodes.init());
    }, [dispatch]);

    useEffect(() => {
        setHeaderContent(
            <Box display="flex" alignItems="flex-end">
                <CcpTabs/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, []);

    const startSubmit = useCallback(() => {
        dispatch(setOpenSubmitCcpModal(true));
        dispatch(setConfirmSubmitCcpModal(false));
    }, [dispatch]);

    useTitleUpdate('Credit Card Portal');

    return (
        <>
            <Box>
                <MainContent>
                    {
                        hasImportPermission && (
                            <BulkCreateCcpUploadFile/>
                        )
                    }
                    <Box>
                        <Box
                            position="relative"
                            flex="1"
                        >
                            {selectedTab !== invoicedCcpSlug && (
                                <CcpTransactionsTableHeader amount={tableTotalAmount}/>
                            )
                            }
                            {selectedTab === invoicedCcpSlug && (
                                <CcpInvoicesTableHeader/>
                            )
                            }
                            <Box>
                                {selectedTab !== invoicedCcpSlug && (
                                    <CcpTransactionsTable/>
                                )}
                                {selectedTab === invoicedCcpSlug && (
                                    <CcpInvoicesTable/>
                                )}
                            </Box>
                        </Box>
                        <div className={styles.actionsContainerShort}>
                            {selectedTab === CcpStatusSlug.Unreconciled && (
                                <ActionButton
                                    variant="contained"
                                    color="primary"
                                    customType="success"
                                    onClick={startSubmit}
                                >
                                    SUBMIT COMPLETED TRANSACTIONS
                                </ActionButton>
                            )
                            }
                            {selectedTab === CcpStatusSlug.Reconciled && (
                                <CcpReconciledSummaryBlock/>
                            )
                            }
                        </div>
                        <NoteEditorSidebar/>
                        <CcpReceiptEditorSidebar/>
                        <SubmitCcpTransactionsModal/>
                        <SubmitCcpInvoiceModal/>
                        <CcpSplitSidebar/>
                    </Box>
                </MainContent>
            </Box>
        </>
    );
}
