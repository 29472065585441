import React from 'react';
import { settingsRoutes } from 'modules/settings/routes';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { Permission } from 'store/components/auth/authModels';
import { GlobalAssignmentSetupDashboard } from 'modules/settings/submodules/clients/globalAssignmentSetup/submodules/Dashboard/GlobalAssignmentSetupDashboard';
import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';
import MainContent from 'shared/components/common/MainContent';
import { DefaultHierarchyPage } from 'modules/settings/submodules/defaultHierarchy/DefaultHierachyPage';
import { CustomFieldsListPage } from 'modules/settings/submodules/customFields/components/CustomFieldsListPage/CustomFieldsListPage';

export const GlobalAssignmentSetupRoutes = () => {
    return (
        <>
            <CommonSettingsSideMenu/>
            <MainContent whiteBackground>
                <Switch>
                    <PrivateRoute
                        path={settingsRoutes.ASSIGNMENT_SETUP.DEFAULT_HIERARCHY}
                        component={DefaultHierarchyPage}
                        permission={Permission.ManageCustomFields}
                    />
                    <PrivateRoute
                        path={settingsRoutes.ASSIGNMENT_SETUP.CUSTOM_FIELDS}
                        component={CustomFieldsListPage}
                        permission={Permission.ManageCustomFields}
                    />
                    <PrivateRoute
                        path={settingsRoutes.ASSIGNMENT_SETUP.ROOT}
                        component={GlobalAssignmentSetupDashboard}
                    />
                </Switch>
            </MainContent>

        </>
    );
};
