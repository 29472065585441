import {
    useDetailSheetGroup,
} from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/hooks/useDetailSheetGroup';
import React, { useCallback, useState } from 'react';
import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';
import SheetsRecalledView from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsRecalledView';
import { setSheetGroupedDetailId } from 'modules/sheetApproval/store/actions';
import { useDispatch } from 'react-redux';
import { ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { useDisallowPastDayEntry } from '../../../../../store/entities/clients/hooks';

export default function SheetsRecalledPage() {
    const [sheetClickInfo, setSheetClickInfo] = useState<ISheetClickInfo | null>(null);
    const recalledGroup = useDetailSheetGroup();
    const disallowPastDayEntry = useDisallowPastDayEntry();
    const dispatch = useDispatch();
    const onClose = useCallback(() => dispatch(setSheetGroupedDetailId(null)), [dispatch]);
    if (sheetClickInfo && !disallowPastDayEntry) {
        return (
            <SheetsRecalledView
                sheetId={sheetClickInfo.sheetId}
                entryType={sheetClickInfo.entryType}
                resetSheet={setSheetClickInfo}
            />
        );
    }
    if (recalledGroup && !disallowPastDayEntry) {
        return (
            <SheetsRecalledView
                sheetGroup={recalledGroup}
                entryType={recalledGroup.type}
                resetSheet={onClose}
            />
        );
    }
    return (
        <SheetsSubmittedView
            onDetailsClick={setSheetClickInfo}
        />
    );
}
