import React from 'react';
import { Formik, FormikConfig, FormikProps } from 'formik';
import { INewPasswordFormValues } from './models';
import PasswordField from '../../formSpecialFields/password/PasswordField';
import { Box, FormHelperText } from '@material-ui/core';
import ButtonWithLoader from '../../buttons/ButtonWithLoader';
import * as yup from 'yup';
import { passwordValidationSchema } from '../../../models/validationSchemes/passwordSchema';
import { ValidationMessages } from '../../../models/Validation';
import { useStyles } from '../AuthStyles';

const newPasswordValidationSchema = yup.object().shape({
    password: passwordValidationSchema,
    passwordConfirmation: yup.string().required(ValidationMessages.REQUIRED)
        .test({
            name: 'confirmationShouldBeEqualToPassword',
            test: function (value: string): boolean {
                return value === this.parent.password;
            },
            message: 'Password and password confirmation should be equal',
        }),
});

interface IFormikConfigValues {
    password: string;
    passwordConfirmation: string;
}

interface IEnterNewPasswordFormProps {
    helpText: string;
    hasError: boolean;
    isSubmitting: boolean;
    onSubmit: FormikConfig<IFormikConfigValues>['onSubmit'];
    onChange: () => void;
}

export function EnterNewPasswordForm({
    isSubmitting,
    onSubmit,
    onChange,
    helpText,
    hasError,
}: IEnterNewPasswordFormProps) {
    const classes = useStyles();
    const initialValues: IFormikConfigValues = {
        password: '',
        passwordConfirmation: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={newPasswordValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {(props: FormikProps<INewPasswordFormValues>) => (
                <form
                    onSubmit={props.handleSubmit}
                    className={classes.form}
                    autoComplete="off"
                    onChange={onChange}
                >
                    <PasswordField
                        name="password"
                        label="New password"
                        autoComplete="new-password"
                        className={classes.input}
                        helperText="Passwords must be at least 8 characters and include an upper case, number and special character."
                        showStrengthIndicator
                        disabled={isSubmitting}
                    />

                    <PasswordField
                        name="passwordConfirmation"
                        label="Confirm new password"
                        className={classes.input}
                        disabled={isSubmitting}
                    />
                    <Box
                        mt={4}
                        mb={2}
                        display="flex"
                        justifyContent="center"
                    >
                        <ButtonWithLoader
                            isLoading={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                        >
                            Reset password
                        </ButtonWithLoader>
                    </Box>
                    {helpText && (
                        <FormHelperText
                            className={classes.userMessage}
                            error={hasError}
                        >
                            {helpText}
                        </FormHelperText>
                    )}
                </form>
            )}
        </Formik>
    );
}
