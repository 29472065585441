import { createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { IDeal } from 'shared/models/Deal';
import { ICreateDealPayload } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/models';

export const createDeal = createRequestActions<ICreateDealPayload, IDeal>(
    RequestType.Create,
    'DEAL',
    'settings/');

const SET_CREATE_DEAL_MODAL_STATE = 'settings/SET_CREATE_DEAL_MODAL_STATE';
export const setCreateDealModalState = createSingleAction<boolean,
    typeof SET_CREATE_DEAL_MODAL_STATE>(
    SET_CREATE_DEAL_MODAL_STATE,
);
