import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash-es';
import { useStyles } from 'shared/components/dashboard/styles';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { IDashboardFilters } from 'shared/models/Dashboard';

export interface IDashboardFilterFormProps extends FormikProps<IDashboardFilters> {
    onChange: (value: Partial<IDashboardFilters>) => void;
    actualFilterValues: IDashboardFilters;
}

export const DashboardFilterForm = ({
    actualFilterValues,
    handleSubmit,
    onChange,
    values,
}: IDashboardFilterFormProps) => {
    const classes = useStyles();

    useEffect(() => {
        if (!isEqual(values, actualFilterValues)) {
            onChange({
                clientId: values.clientId || undefined,
            });
        }
    }, [actualFilterValues, values, onChange]);

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <ClientSelect
                name="clientId"
                label="Client"
                useIdValue
                className={classes.input}
            />
        </form>
    );
};
