import { EntryValidation } from 'store/entities/timesheet/models/validation';
import { IError } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/validations/IError';
import { constants } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/constants/constants';

export const timeEntryValidation = (
    hours: number,
    minutes: number,
    entryDate: string,
    time: string,
    totalMinutes: number,
): IError | null => {

    const trimmedTime = time.trim();
    if (trimmedTime.length !== constants.lengthFor0_00Format
        && trimmedTime.length !== constants.lengthFor00_00Format) {
        return {
            field: 'time',
            type: 'required',
            text: EntryValidation.Required,
        };
    }

    if (hours === 0 && minutes === 0) {
        return {
            field: 'time',
            type: 'notEmpty',
            text: EntryValidation.NotEmpty,
        };
    }

    if (hours * constants.minutesInHour + minutes > constants.hoursInDay * constants.minutesInHour - totalMinutes) {
        return {
            field: 'time',
            type: 'exceed',
            text: EntryValidation.Exceed,
        };
    }

    return null;
};
