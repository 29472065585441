import React from 'react';
import { Switch } from '@material-ui/core';
import { useField } from 'formik';

interface IFormSwitchFieldProps {
    name: string;
}

export function FormSwitchField({ name }: IFormSwitchFieldProps) {
    const [field] = useField<boolean>(name);
    const { value, onChange } = field;

    return (
        <Switch
            name={name}
            checked={value}
            onChange={onChange}
            color="primary"
        />
    );
}
