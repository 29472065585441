import { createCustomField, setCreateCustomFieldModalState } from 'modules/settings/submodules/customFields/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const isOpen = singleValueReducer(setCreateCustomFieldModalState.action, false);
const isCreating = isLoadingReducer(createCustomField);

export const customFieldCreate = combineReducers({
    isOpen,
    isCreating,
});
