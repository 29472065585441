import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectCustomFieldsByIds } from 'store/entities/customFields/selectors';

const selectEditCustomFieldState = (state: IStore) => state.modules.settings.customFields.editCustomField;

export const selectIsCustomFieldUpdating = (state: IStore) => selectEditCustomFieldState(state).isUpdating;
export const selectEditCustomFieldId = (state: IStore) => selectEditCustomFieldState(state).editId;
export const selectEditedCustomField = createSelector(
    selectCustomFieldsByIds,
    selectEditCustomFieldId,
    (fieldsByIds, editId) => {
        return fieldsByIds[editId || ''];
    },
);
