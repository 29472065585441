import { SheetIdsInfo } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/SheetIdsInfo';
import React from 'react';
import { List } from '@material-ui/core';

import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { SheetInfoItem } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useSheetGroupItemsBatch } from '../../../hooks/useSheetGroupItemsBatch';

interface ICalculationGroupDetailByTypeProps {
    type: EntryType;
    calculationGroup: ISheetCalculationBatch;
}

export const CalculationGroupDetailByType = ({
    type,
    calculationGroup,
}: ICalculationGroupDetailByTypeProps) => {
    const sheetDetailClasses = useSheetDetailStyles();
    const calcGroupDetailsList = useSheetGroupItemsBatch(calculationGroup, type);

    return (
        <List className={sheetDetailClasses.listWrapper}>
            {calcGroupDetailsList.map(item => {
                const key = `${type}_${item.title}_${item.value}`;
                if (item.value?.split(',').length > 2) {
                    /**
                     * For some reason we want to render sheet ids with special template
                     * and some other filed with id list
                     */
                    return <SheetIdsInfo key={key} data={item}/>;
                }
                return <SheetInfoItem key={key} data={item}/>;
            })}
        </List>
    );
};
