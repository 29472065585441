import {
    bulkCreateSubAssignmentAction,
    bulkCreateSubAssignmentActionV2,
    bulkCreateSubAssignmentPreInitializeAction,
    bulkCreateSubAssignmentPreInitializeActionV2,
    bulkUploadCreateSubAssignmentAction,
    bulkUploadCreateSubAssignmentPreInitializeAction,
    downloadFailedCsvCreateSubAssignmentAction,
    getCreateSubAssignmentCsvSampleAction,
    getPreInitializeCreateSubAssignmentPdfAction,
    getUploadPreInitializeCreateSubAssignmentPdfAction,
    initialLoadSubassignments,
    uploadCreateSubAssignmentAndDownloadFailedCsvAction,
} from 'modules/subassignmentManagement/store/actions';
import moment from 'moment';
import { backendDateFormat } from 'shared/models/Dates';
import baseApi from 'shared/utils/baseApi';
import { getDownloadFileByRequestSagaWatcher } from 'store/utils/sagas/getDownloadFileByRequestSagaWatcher';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { call, put, takeLatest } from 'typed-redux-saga';
import { subassignmentsApi } from 'modules/subassignmentManagement/store/api';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    BulkCreateSubassignmentSteps,
    setBulkCreateSubassignmentStep,
} from 'modules/subassignmentManagement/components/CreateSubAssignment/store/actions';

const fileTimestampFormat = `${backendDateFormat}-HH.mm.ss`;

function* getSubassignmentPreCreateReportSaga(
    { payload }: ReturnType<typeof bulkCreateSubAssignmentPreInitializeAction.init>,
) {
    const response = yield call(subassignmentsApi.querySubassignmentPreCreationReport, payload);
    yield put(bulkCreateSubAssignmentPreInitializeAction.success(response));
}

function* getSubassignmentPreCreateReportWatcher() {
    yield takeLatest(
        bulkCreateSubAssignmentPreInitializeAction.initType,
        withBackendErrorHandler(
            getSubassignmentPreCreateReportSaga,
            bulkCreateSubAssignmentPreInitializeAction.error,
            'Unable to get create pre-initialization report',
        ),
    );
}

function* bulkCreateSubassignmentsSaga(
    { payload }: ReturnType<typeof bulkCreateSubAssignmentAction.init>,
) {
    const response = yield call(subassignmentsApi.bulkCreateSubassignments, payload);
    yield put(bulkCreateSubAssignmentAction.success(response));
    yield put(setBulkCreateSubassignmentStep(BulkCreateSubassignmentSteps.PostReport));
    yield put(initialLoadSubassignments());
}

function* bulkCreateSubassignmentsWatcher() {
    yield takeLatest(
        bulkCreateSubAssignmentAction.initType,
        withBackendErrorHandler(
            bulkCreateSubassignmentsSaga,
            bulkCreateSubAssignmentAction.error,
            'Unable to create sub-assignments',
        ),
    );
}

const getSubassignmentPreCreateReportV2Watcher = getLoadEntitiesByRequestSagaWatcher(
    bulkCreateSubAssignmentPreInitializeActionV2,
    subassignmentsApi.bulkCreateSubassignmentsV2QueryReportV2,
    'pre-initialization report',
);

function* bulkCreateSubassignmentsV2Saga(
    { payload }: ReturnType<typeof bulkCreateSubAssignmentActionV2.init>,
) {
    const response = yield call(subassignmentsApi.bulkCreateSubassignmentsV2, payload);
    yield put(bulkCreateSubAssignmentActionV2.success(response));
    yield put(initialLoadSubassignments());
}

function* bulkCreateSubassignmentsV2Watcher() {
    yield takeLatest(
        bulkCreateSubAssignmentActionV2.initType,
        withBackendErrorHandler(
            bulkCreateSubassignmentsV2Saga,
            bulkCreateSubAssignmentActionV2.error,
            'Unable to create sub-assignments',
        ),
    );
}
const getCreationPdfReportSagaWatcher = getDownloadFileByRequestSagaWatcher(
    getPreInitializeCreateSubAssignmentPdfAction,
    subassignmentsApi.bulkCreateSubassignmentsV2QueryReportV2Pdf,
    () => {
        return `Creation Report ${moment().format(fileTimestampFormat)}.pdf`;
    },
);
const getFailedCreationCsvReportSagaWatcher = getDownloadFileByRequestSagaWatcher(
    downloadFailedCsvCreateSubAssignmentAction,
    subassignmentsApi.bulkCreateSubassignmentsQueryFailedCsv,
    () => {
        return `Failed Report ${moment().format(fileTimestampFormat)}.csv`;
    },
);

//CSV related
const getCsvSampleFileSagaWatcher = getDownloadFileByRequestSagaWatcher(
    getCreateSubAssignmentCsvSampleAction,
    subassignmentsApi.downloadCreationCsvSample,
    () => {
        return `ImportSample-${baseApi.clientId}.csv`;
    },
);
const uploadSubassignmentPreCreateReportV2Watcher = getLoadEntitiesByRequestSagaWatcher(
    bulkUploadCreateSubAssignmentPreInitializeAction,
    subassignmentsApi.uploadCsvBulkCreateSubassignmentsQueryReport,
    'pre-initialization report',
);
const getUploadCreationPdfReportSagaWatcher = getDownloadFileByRequestSagaWatcher(
    getUploadPreInitializeCreateSubAssignmentPdfAction,
    subassignmentsApi.uploadCsvBulkCreateSubassignmentsQueryReportPdf,
    () => {
        return `Creation Report ${moment().format(fileTimestampFormat)}.pdf`;
    },
);
const getUploadFailedCreationCsvReportSagaWatcher = getDownloadFileByRequestSagaWatcher(
    uploadCreateSubAssignmentAndDownloadFailedCsvAction,
    subassignmentsApi.uploadCsvBulkCreateSubassignmentsQueryFailedCsv,
    () => {
        return `Failed Report ${moment().format(fileTimestampFormat)}.csv`;
    },
);

function* uploadCsvCreateSubassignmentsV2Saga(
    { payload }: ReturnType<typeof bulkUploadCreateSubAssignmentAction.init>,
) {
    const response = yield call(subassignmentsApi.uploadCsvBulkCreateSubassignments, payload);
    yield put(bulkUploadCreateSubAssignmentAction.success(response));
    yield put(initialLoadSubassignments());
}

function* uploadCsvCreateSubassignmentsV2Watcher() {
    yield takeLatest(
        bulkUploadCreateSubAssignmentAction.initType,
        withBackendErrorHandler(
            uploadCsvCreateSubassignmentsV2Saga,
            bulkUploadCreateSubAssignmentAction.error,
            'Unable to create sub-assignments',
        ),
    );
}

export const createSubassignmentsSagas = [
    getSubassignmentPreCreateReportWatcher,
    bulkCreateSubassignmentsWatcher,
    getSubassignmentPreCreateReportV2Watcher,
    bulkCreateSubassignmentsV2Watcher,
    getCreationPdfReportSagaWatcher,
    getFailedCreationCsvReportSagaWatcher,
    getCsvSampleFileSagaWatcher,
    uploadSubassignmentPreCreateReportV2Watcher,
    getUploadCreationPdfReportSagaWatcher,
    getUploadFailedCreationCsvReportSagaWatcher,
    uploadCsvCreateSubassignmentsV2Watcher,
];
