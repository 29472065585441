import { combineReducers } from 'redux';
import { ccpTransactionsTable, filter } from 'modules/ccp/components/CcpTransactionsTable/store/reducers';
import { isLoadingReducer, itemsByIdReducer, resetStateReducer, singleValueReducer } from '../../../store/reducerUtils';
import { ICcpTransaction } from '../models/CcpTransaction';
import {
    currentCcpListSkip,
    getCcpTransactions, getCsvInvoicedItem,
    getCsvReconciled,
    getCsvUnreconciled,
    getTransactionCodes,
    resetCcpTransactionsItems,
    sendReminder,
    setCcpEditMode,
    setCcpTransactionsActiveTab,
    triggerEditModeSave,
} from './actions';
import { extendReducer } from 'store/utils/reducers/extendReducer';
import { ccpDefaultTabStatusSlug, ITransactionCode } from '../models/ccpModel';
import { createCcpTransactions } from '../components/CcpCreate/store/reducers';
import { editCcpTransactions } from '../components/CcpEdit/store/reducers';
import { splitCcpTransactions } from '../components/CcpSplit/store/reducers';
import { ccpInvoices } from '../components/CcpInvoices/store/reducers';

const ccpTransactionsById = itemsByIdReducer<ICcpTransaction, ICcpTransaction>(getCcpTransactions);
const ccpTransactionsIsIsLoading = isLoadingReducer(getCcpTransactions);

const ccpTransactionCodesById = itemsByIdReducer<ITransactionCode, ITransactionCode>(getTransactionCodes);
const isLoadingTransactionCodes = isLoadingReducer(getTransactionCodes);
const isLoadingEditModeSave = isLoadingReducer(triggerEditModeSave);

const isLoadingReconciledReport = isLoadingReducer(getCsvReconciled);
const isLoadingUnreconciledReport = isLoadingReducer(getCsvUnreconciled);
const isLoadingExportCcpInvoiceReport = isLoadingReducer(getCsvInvoicedItem);
const isLoadingCcpReminder = isLoadingReducer(sendReminder);

const ccpTransactionActiveTab = extendReducer(
    singleValueReducer(setCcpTransactionsActiveTab.action, ccpDefaultTabStatusSlug),
    resetStateReducer(resetCcpTransactionsItems.action, ccpDefaultTabStatusSlug),
);

const ccpEditMode = singleValueReducer(setCcpEditMode.action, false);

const ccpCurrentTabSkip = singleValueReducer(currentCcpListSkip.action, 0);

export const ccpTransactions = combineReducers({
    ccpEditMode,
    ccpTransactionActiveTab,
    ccpTransactionsTable,
    filter,
    createCcpTransactions,
    editCcpTransactions,
    splitCcpTransactions,
    ccpTransactionsById,
    ccpTransactionsIsIsLoading,
    ccpTransactionCodesById,
    isLoadingTransactionCodes,
    ccpCurrentTabSkip,
    ccpInvoices,
    isLoadingReconciledReport,
    isLoadingUnreconciledReport,
    isLoadingExportCcpInvoiceReport,
    isLoadingCcpReminder,
    isLoadingEditModeSave,
},
);
