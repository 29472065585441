import { fade, Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const useSubmitStatusStyles = makeHighPriorityStyles((theme: Theme) => ({
    status: {
        borderColor: colors.darkGray,
        backgroundColor: colors.lightGray,
        borderRadius: 4,
        height: theme.spacing(2),
        color: colors.darkGray,
        fontSize: 12,
    },
    dueDate: {
        margin: theme.spacing(0, 1),
        color: colors.darkGray,
        fontSize: 12,
    },
    blue: {
        backgroundColor: `${fade(colors.blue, 0.3)} !important`,
    },
    red: {
        backgroundColor: `${fade(colors.red, 0.3)} !important`,
    },
    lightGreen: {
        backgroundColor: `${colors.lightGreen} !important`,
    },
}));

export default useSubmitStatusStyles;
