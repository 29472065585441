import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBulkEditSubassignmentStep } from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import { bulkEditSubAssignmentActionV2, getBulkEditSubAssignmentPreInitializePdfReport } from 'modules/subassignmentManagement/store/actions';
import { BulkEditReportV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/BulkEditReportV2';
import { usePayrollReportStyle } from 'modules/payrollProcessorHub/components/PayrollModal/reports/PayrollStyle';
import ReportOverview from 'modules/payrollProcessorHub/components/PayrollModal/reports/ReportOverview';
import { useBulkPreEndOverviewItems } from 'modules/subassignmentManagement/components/BulkEndAssignment/hooks';
import { selectBulkEndDataOverviewItems } from 'modules/subassignmentManagement/components/BulkEndAssignment/store/selectors';
import { selectBulkPayloadV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/selectors';
import { getBulkEndReportCells } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/cells';
import { selectIsBulkBeingUpdated, selectIsLoadingEditPreInitializePdf } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { PrintButton } from 'shared/components/buttons/PrintButton';

export const BulkEndPreInitializeV2Report = () => {
    const dispatch = useDispatch();

    const onBack = useCallback(() => {
        dispatch(setBulkEditSubassignmentStep(BulkEditSubassignmentSteps.Form));
    }, [dispatch]);
    const bulkPayload = useSelector(selectBulkPayloadV2);
    const handleAction = useCallback(() => {
        dispatch(bulkEditSubAssignmentActionV2.init(bulkPayload));
    }, [dispatch, bulkPayload]);
    const handleDownload = useCallback(() => {
        dispatch(getBulkEditSubAssignmentPreInitializePdfReport.init(bulkPayload));
    }, [dispatch, bulkPayload]);

    const overviewItems = useBulkPreEndOverviewItems();
    const dataOverviewItems = useSelector(selectBulkEndDataOverviewItems);

    const isLoading = useSelector(selectIsBulkBeingUpdated);
    const isLoadingPdf = useSelector(selectIsLoadingEditPreInitializePdf);

    const overviewClasses = usePayrollReportStyle();
    const additionalOverview = (
        <>
            <ReportOverview
                blocks={dataOverviewItems}
                classes={
                    {
                        column: overviewClasses.SummaryInfoColumn,
                        row: overviewClasses.SummaryInfoRow,
                    }
                }
            />
            <PrintButton
                onClick={handleDownload}
                disabled={isLoadingPdf}
            />
        </>
    );

    return (
        <BulkEditReportV2
            onBack={onBack}
            handleAction={handleAction}
            isLoading={isLoading}
            title="Pre-Initialize Roll Up"
            buttonTitle="Update"
            overviewItems={overviewItems}
            additionalOverview={additionalOverview}
            getCells={getBulkEndReportCells}
        />
    );
};
