import {
    IClientRoleManagePayload,
    IClientUserAssociationRequest,
    IClientUserAssociationResponse,
} from 'modules/settings/submodules/platformUsers/store/models';
import { IStatusResponse } from 'shared/models/Response';
import baseApi from 'shared/utils/baseApi';
import {
    IClientRole,
    ICreatePlatformUser,
    IGlobalRoleBackend,
    IUpdatePlatformUserRequest,
    IUserInfo,
} from 'shared/models/User';
import { IClientUserAssociation, ICreateClientUserAssociation } from 'store/entities/users/model';
import { normalizeRolePermissions } from 'store/entities/users/utils';

export const api = {
    async createPlatformUser(user: ICreatePlatformUser): Promise<IUserInfo> {
        const { data } = await baseApi.create<ICreatePlatformUser, IUserInfo>(
            'client-users',
            'users',
            user,
        );

        return data;
    },
    async updatePlatformUser(id: string, user: IUpdatePlatformUserRequest): Promise<IUserInfo> {
        const { data } = await baseApi.updateById<IUpdatePlatformUserRequest, IUserInfo>(
            'client-users',
            'users',
            id,
            user,
        );

        return data;
    },
    async updateUser(id: string, user: IUpdatePlatformUserRequest): Promise<IUserInfo> {
        const { data } = await baseApi.patch<IUpdatePlatformUserRequest, IUserInfo>(
            `client-users/users/${id}`,
            user,
        );

        return data;
    },

    async createClientUserAssociation(request: ICreateClientUserAssociation): Promise<IClientUserAssociation> {
        const { data } = await baseApi.create<ICreateClientUserAssociation, IClientUserAssociation>(
            'client-users', 'client_user_roles', request,
        );
        return data;
    },
    async getClientUserRoles(request: IClientUserAssociationRequest): Promise<IClientUserAssociationResponse> {
        const requestProcess = baseApi.post<IClientUserAssociationRequest, IClientUserAssociationResponse>(
            '/client-users/get_client_user_roles',
            request,
        );
        const { data } = await requestProcess;
        return data;
    },
    async deleteClientUserAssociation(id: string): Promise<string> {
        const { data } = await baseApi.delete<IStatusResponse>('/client-users/client_user_roles', id);
        return data.status;
    },

    async createClientRole(request: IClientRoleManagePayload): Promise<IClientRole> {
        const { data } = await baseApi.create<IClientRoleManagePayload, IGlobalRoleBackend>(
            'client-users', 'client_role', request,
        );
        return normalizeRolePermissions(data) as IClientRole;
    },
    async updateClientRole(id: string, updateData: IClientRoleManagePayload): Promise<IClientRole> {
        const { data } = await baseApi.patch<IClientRoleManagePayload, IGlobalRoleBackend>(
            `client-users/client_role/${id}`,
            updateData,
        );

        return normalizeRolePermissions(data) as IClientRole;
    },
    async deleteClientRole(id: string): Promise<string> {
        const { data } = await baseApi.delete<IStatusResponse>('client-users/client_roles', id);
        return data.status;
    },
};
