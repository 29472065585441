import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { Box, Button, Collapse, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SpecialUserAutocomplete } from 'shared/components/autocomplete/SpecialUserAutocomplete';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import { IEmployeeManagementFilter } from 'modules/employmentManagement/components/store/model';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { shortDateFormat } from 'shared/models/Dates';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { ICustomFieldHierarchyNode } from 'store/entities/customFields/model';
import { ScopeAction } from 'store/entities/scopes/models';
import { UserType } from 'store/entities/users/model';
import { useSubassignmentFilterFormStyles } from 'modules/employmentManagement/components/EmployeeFilterForm/styles';

interface IEmployeeFilterFormProps extends FormikProps<IEmployeeManagementFilter> {
    isLoading: boolean;
    onReset: () => void;
    hierarchy: ICustomFieldHierarchyNode[];
    useAdvancedSearch: boolean;
}

export const EmployeeFilterForm = ({
    handleSubmit,
    isLoading,
    onReset,
    hierarchy,
    useAdvancedSearch,
}: IEmployeeFilterFormProps) => {
    const classes = useSubassignmentFilterFormStyles();
    const clientId = useSelector(selectCurrentClientId);
    const [isExpanded, setExpanded] = useState(false);

    const {
        baseSearchCustomFieldIds,
        customFieldsIdsForAdvancedSearch,
    } = useMemo((): {
        baseSearchCustomFieldIds: string[],
        customFieldsIdsForAdvancedSearch: string[],
    } => {
        const customFieldIds = hierarchy.map(node => node.custom_field_id);
        if (!useAdvancedSearch) {
            return {
                baseSearchCustomFieldIds: customFieldIds,
                customFieldsIdsForAdvancedSearch: [],
            };
        }
        const baseSearch = hierarchy
            .filter(node => node.parent_id === null)
            .map(node => node.custom_field_id)
            .slice(0, 2);
        return {
            baseSearchCustomFieldIds: baseSearch,
            customFieldsIdsForAdvancedSearch: customFieldIds.filter(id => !baseSearch.includes(id)),
        };
    }, [hierarchy, useAdvancedSearch]);

    const renderCustomField = (fieldId: string) => (
        <Grid
            item
            sm={3}
            key={fieldId}
        >
            <CustomFieldValuesSelect
                customFieldId={fieldId}
                name={`custom_fields[${fieldId}]`}
                className={classes.input}
                useIdValue
                scopeActionFilter={ScopeAction.Assignment}
            />
        </Grid>
    );

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
                <Grid item sm={3}>
                    <UserAutocompleteWithSearch
                        additionalFilter={{
                            user_type: UserType.Employee,
                            client_id: clientId || undefined,
                        }}
                        name="employee_user_id"
                        outerLabel="Search Employee"
                        useIdValue
                        className={classes.input}
                    />
                </Grid>
                <Grid item sm={3}>
                    <DayPickerField
                        name="start_date"
                        format={shortDateFormat}
                        placeholder={shortDateFormat}
                        outerLabel="Start Date"
                        className={classes.input}
                        withKeyboard
                    />
                </Grid>
                <Grid item sm={3}>
                    <DayPickerField
                        name="end_date"
                        format={shortDateFormat}
                        placeholder={shortDateFormat}
                        outerLabel="End Date"
                        className={classes.input}
                        withKeyboard
                    />
                </Grid>
                <Grid item sm={3}>
                    <SpecialUserAutocomplete
                        name="approver_user_id"
                        feature="purpose"
                        featureValue={Permission.ApproveSheets}
                        outerLabel="Approver"
                        className={classes.input}
                        useIdValue
                    />
                </Grid>
                {baseSearchCustomFieldIds.map(fieldId => renderCustomField(fieldId))}
            </Grid>
            {customFieldsIdsForAdvancedSearch.length > 0 && (
                <Box className={classes.linkButtonContainer}>
                    <span
                        className={classes.linkButton}
                        onClick={() => setExpanded(!isExpanded)}
                    >
                        {isExpanded ? 'Close Advanced Search' : 'Advanced Search'}
                    </span>
                </Box>
            )}
            <Collapse in={isExpanded}>
                <Box mt={6}>
                    <Grid container spacing={2}>
                        {customFieldsIdsForAdvancedSearch.map(id => renderCustomField(id))}
                    </Grid>
                </Box>
            </Collapse>
            <Box className={clsx(
                classes.submitButtons,
                {
                    [classes.submitButtonsExpanded]: isExpanded || baseSearchCustomFieldIds.length === 0,
                },
            )}>
                <Box mr={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onReset}
                        disabled={isLoading}
                        className={classes.showAllButton}
                    >
                        Show All
                    </Button>
                </Box>
                <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Search
                </Button>
            </Box>
        </form>
    );
};
