import React from 'react';
import EditOfferSidebar from './components/EditOffer/EditOfferSidebar';
import OfferLetterPanel, { IOfferLetterPanelRoute } from './OfferLetterPanel';

const OfferLetterEdit: React.FC<IOfferLetterPanelRoute> = ({
    match: { params: { id } },
}: IOfferLetterPanelRoute) => {
    const getTitle = (name: string) => name ? `Edit offer - ${name}` : 'Edit offer';
    return (
        <OfferLetterPanel
            id={id}
            getTitle={getTitle}
            renderSidebar={({ offerLetter, onClose }) => (
                <EditOfferSidebar
                    onCancel={onClose}
                    offerLetter={offerLetter}
                />
            )}
        />
    );
};

export default OfferLetterEdit;
