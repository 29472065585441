import React from 'react';
import { Box } from '@material-ui/core';
import { getEafReasons } from 'modules/employeeActionsForm/store/actions';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { EafApprovalHeadline } from 'modules/eafApproval/components/EafApprovalHeadline';
import { useEafApprovalTabs } from 'modules/eafApproval/hooks/useEafApprovalTabs';
import { EafApprovalGrid } from 'modules/eafApproval/components/EafApprovalGrid';
import { EafDetailSidebar } from 'modules/eafApproval/components/EafDetail/EafDetailSidebar';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { ApproveEafConfirmation } from 'modules/eafApproval/components/Approve/ApproveEafConfirmation';
import { EafRejectionStateSidebar } from 'modules/eafApproval/components/Rejection/EafRejectionStateSidebar';
import { resetEafApprovalItems, setEafApprovalFilter } from 'modules/eafApproval/store/actions';
import { useEafApprovalGridCells } from 'modules/eafApproval/hooks/useEafApprovalGridCells';
import { useEafApprovalTypes } from 'modules/eafApproval/store/hooks';
import { EafPageTitle } from './store/model/types';

export const EafApprovalPage: React.FC = () => {
    useTitleUpdate(EafPageTitle);
    useEafApprovalTabs();
    const eafApprovalTypes = useEafApprovalTypes();

    useWithClientEffect((dispatch, clientId) => {
        dispatch(getEafReasons.init());
        dispatch(setEafApprovalFilter({
            clientId,
            defaultTypes: eafApprovalTypes,
        }));
        return function cleanUp() {
            dispatch(resetEafApprovalItems());
        };
    }, [eafApprovalTypes]);

    const cells = useEafApprovalGridCells();

    return (
        <Box p={4}>
            <EafApprovalHeadline/>

            <EafApprovalGrid cells={cells}/>
            <ApproveEafConfirmation/>
            <EafRejectionStateSidebar/>
            <EafDetailSidebar/>
        </Box>
    );
};
