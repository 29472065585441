import { ActionsReturnTypes, createActions } from 'store/utils';
import { IDepartment, IDepartmentRequest } from '../../models/Department';

const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
const GET_DEPARTMENTS_ERROR = 'GET_DEPARTMENTS_ERROR';

export const getDepartments = createActions<
void | IDepartmentRequest,
Array<IDepartment>,
any,
typeof GET_DEPARTMENTS,
typeof GET_DEPARTMENT_SUCCESS,
typeof GET_DEPARTMENTS_ERROR
>(
    GET_DEPARTMENTS, GET_DEPARTMENT_SUCCESS, GET_DEPARTMENTS_ERROR,
);

const REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT';
const REMOVE_DEPARTMENT_SUCCESS = 'REMOVE_DEPARTMENT_SUCCESS';
const REMOVE_DEPARTMENT_ERROR = 'REMOVE_DEPARTMENT_ERROR';

export const deleteDepartment = createActions<
string,
string,
any,
typeof REMOVE_DEPARTMENT,
typeof REMOVE_DEPARTMENT_SUCCESS,
typeof REMOVE_DEPARTMENT_ERROR
>(
    REMOVE_DEPARTMENT,
    REMOVE_DEPARTMENT_SUCCESS,
    REMOVE_DEPARTMENT_ERROR,
);

export type DepartmentActions =
    | ActionsReturnTypes<typeof getDepartments>
    | ActionsReturnTypes<typeof deleteDepartment>
