import React from 'react';
import { useParams } from 'react-router';
import { Box } from '@material-ui/core';
import { baseUrl } from 'shared/utils/baseUrl';
import OfferLetterPdf from '../components/OfferLetterPdf';
import { useOfferPdfOnlyStyles } from 'modules/offerLetter/OfferLetterEmployee/components/useOfferPdfOnlyStyles';
import { DefaultPdfFallback } from './components/DefaultPdfFallback';

const basePath = `${baseUrl}/documents/offer_letters`;

export default function OfferPdfOnly() {
    const { id } = useParams<{ id: string }>();
    const classes = useOfferPdfOnlyStyles();

    return (
        <Box className={classes.wrapper}>
            <OfferLetterPdf
                id={id}
                filePath={`${basePath}/${id}`}
                title="Offer Letter"
                errorRender={<DefaultPdfFallback/>}
            />
        </Box>
    );
}
