import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { ISyncEmployeePrismProfilePayload } from 'modules/settings/submodules/employees/store/model';
import { IUpdateUserPayload, IUserInfo } from 'shared/models/User';
import { IClient } from 'store/entities/clients/clientsModel';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

const SET_EDIT_EMPLOYEE_ID = 'settings/employees/SET_EDIT_EMPLOYEE_ID';
export const setEditEmployeeId = createSingleAction<
string | null,
typeof SET_EDIT_EMPLOYEE_ID
>(
    SET_EDIT_EMPLOYEE_ID,
);

export const updateEmployee = createRequestActions<IUpdateUserPayload, IUserInfo>(
    RequestType.Update,
    'EMPLOYEE',
    'settings',
);

export const overrideOfferLetterStatus = createRequestActions<
{
    id: string;
    status: OfferLetterStatusSlug;
},
void
>(
    RequestType.Update,
    'OFFER_LETTER_STATUS_OVERRIDE',
    'settings',
);

export const deleteEmployee = createRequestActions<string, string>(
    RequestType.Delete,
    'EMPLOYEE',
    'settings',
);

export const resetPassword = createRequestActions<string, void>(
    RequestType.Update,
    'USER_PASSWORD',
    'settings',
);

export const getEmployeeClients = createRequestActions<string, IClient[]>(
    RequestType.Get,
    'EMPLOYEE_CLIENTS',
    'settings',
);

export const syncEmployeeProfilePrism = createRequestActions<ISyncEmployeePrismProfilePayload, void>(
    RequestType.Update,
    'EMPLOYEE_PRISM_PROFILE',
    'settings',
);
