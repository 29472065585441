export enum BulkEditSubassignmentSteps {
    Form,
    EditVerify,
    EditPostReport,
    DeleteVerify,
    DeletePostReport,
}

export const editStepTitle: Record<BulkEditSubassignmentSteps, string> = {
    [BulkEditSubassignmentSteps.Form]: 'Bulk edit sub-assignments',
    [BulkEditSubassignmentSteps.EditVerify]: 'Verify',
    [BulkEditSubassignmentSteps.EditPostReport]: 'Report',
    [BulkEditSubassignmentSteps.DeleteVerify]: 'Verify',
    [BulkEditSubassignmentSteps.DeletePostReport]: 'Report',
};

export const baseStepNumber: Record<BulkEditSubassignmentSteps, number> = {
    [BulkEditSubassignmentSteps.Form]: 1,
    [BulkEditSubassignmentSteps.EditVerify]: 2,
    [BulkEditSubassignmentSteps.EditPostReport]: 3,
    [BulkEditSubassignmentSteps.DeleteVerify]: 2,
    [BulkEditSubassignmentSteps.DeletePostReport]: 3,
};
