import React from 'react';

export interface IIntervalDataFieldNoteProps {
    value: string;
}

function IntervalDataFieldNote({ value }: IIntervalDataFieldNoteProps) {
    return (
        <p>{value}</p>
    );
}

export default IntervalDataFieldNote;
