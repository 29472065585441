import { getZipCodes } from 'store/entities/zipCode/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import { zipCodeApi } from 'store/entities/zipCode/api';

function* getZipCodesSaga({ payload: startsWith }: ReturnType<typeof getZipCodes.init>) {
    const zipCodes = yield* call(zipCodeApi.getZipCodes, startsWith);

    yield* put(getZipCodes.success(zipCodes));
}

export function* getZipCodesWatcher() {
    yield* takeLatest(getZipCodes.initType, getZipCodesSaga);
}
