import baseApi from 'shared/utils/baseApi';
import { IWithClientId } from 'shared/models/Api';
import { IPayPeriod, IPayPeriodResponse } from 'store/entities/timesheet/models/PayPeriod';
import { withClientId } from 'store/utils/withClientId';

interface ISheetApiGetPayPeriods {
    employeeId?: string
}

export const sheetsApi = {
    async getSheetsPayPeriods(params: ISheetApiGetPayPeriods): Promise<IPayPeriod[]> {
        const response = await baseApi
            .post<IWithClientId, IPayPeriodResponse>(
            '/sheets/coordinator/sheets/periods/query',
            withClientId({ employee_id: params.employeeId }),
        );
        return response?.data?.periods;
    },
    async getClientPayPeriods(): Promise<IPayPeriod[]> {
        const response = await baseApi.get<IPayPeriodResponse>(
            '/time/pay_periods',
            withClientId({}),
        );
        return response?.data?.periods;
    },
};
