import { isString } from 'lodash-es';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { confirmEmail } from 'store/components/emailConfirmation/actions';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { usersApi } from 'store/entities/users/api';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getAuthApi } from 'shared/utils/authApi';
import { ToastMessagesCodes } from 'shared/models/ToastMessagesModel';

function* emailConfirmationSaga(action: ReturnType<typeof confirmEmail.init>) {
    const authApi = getAuthApi();
    let messageCode: ToastMessagesCodes | undefined = undefined;

    try {
        yield* call(usersApi.emailConfirmation, action.payload);
        yield put(confirmEmail.success());
        yield* put(setGlobalToast({
            severity: IModalSeverity.Success,
            title: 'Your email has been confirmed.',
            ignoreSidebar: true,
        }));
        messageCode = ToastMessagesCodes.EmailConfirmed;
    } catch (e) {
        yield put(confirmEmail.error(e));
        const errorText = e?.response?.data?.error?.message;
        const errorMessage = isString(errorText) ? errorText : 'Unable to confirm you email. Try again.';
        yield* put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
            ignoreSidebar: true,
        }));
        messageCode = ToastMessagesCodes.EmailConfirmFailed;
    } finally {
        yield call([authApi, authApi.login], messageCode);
    }
}

export function* emailConfirmationWatcher() {
    yield takeLatest(confirmEmail.initType, emailConfirmationSaga);
}

export default [
    emailConfirmationWatcher,
];
