import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { inlineEditPayRangeValidationSchema } from 'modules/settings/submodules/clients/payRanges/components/payRangeCreationForm/createPayRangeValidationSchema';
import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { updatePayRangeAction } from 'modules/settings/submodules/clients/payRanges/store/actions';
import { IPayRangeRow } from 'modules/settings/submodules/clients/payRanges/components/payRangesTable/model';
import { useDispatch } from 'react-redux';
import NumberField from 'shared/components/formFields/NumberField';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { useQuickEditStyles } from 'shared/components/table/tableEntryCells/QuickEditStyles';
import { colors } from 'shared/styles/constants';

interface IPayRangeEditFormValues {
    min: number;
    max: number;
}

export const InlinePayRangeEditCell = ({ id, className, min_value, max_value }: ICellProps<IPayRangeRow>) => {
    const dispatch = useDispatch();
    const classes = useQuickEditStyles();

    const onSubmit = useCallback(values => {
        if (min_value !== values.min || max_value !== values.max) {
            dispatch(updatePayRangeAction.init({
                id,
                data: {
                    min_value: values.min,
                    max_value: values.max,
                },
            }));
        }
    }, [dispatch, id, max_value, min_value]);

    const formikData = useFormik<IPayRangeEditFormValues>({
        initialValues: {
            min: min_value,
            max: max_value,
        },
        validationSchema: inlineEditPayRangeValidationSchema,
        onSubmit,
        enableReinitialize: true,
    });
    const { handleSubmit, values, submitForm, validateForm } = formikData;

    const validateAndSubmitForm = useCallback(() => {
        // without that the default formik validation uses the previous values:
        // This is the recommended technic since the programmatic submitting formik form is buggy.
        // It is discussed here https://github.com/formium/formik/issues/1580.
        // todo: remove extra validation when the formik issue is resolved.
        validateForm(values).then(() => {
            return submitForm();
        });
    }, [validateForm, values, submitForm]);

    return (
        <Box display="flex" className={className}>
            <FormikProvider value={formikData}>
                <form
                    onSubmit={handleSubmit}
                >
                    <Box
                        display="flex"
                        style={{
                            border: `1px solid ${colors.gray}`,
                        }}
                    >
                        <Box
                            maxWidth="56px"
                            display="flex"
                            alignItems="center"
                            mr={0.5}
                            ml={0.5}
                        >
                            <NumberField
                                name="min"
                                min={0}
                                InputProps={{
                                    startAdornment: '$',
                                }}
                                inputClassName={classes.inputRootNoShadow}
                                className={clsx(classes.inputRoot, classes.inputRootNoShadow)}
                                onBlur={validateAndSubmitForm}
                            />
                        </Box>
                        -
                        <Box
                            maxWidth="56px"
                            ml={0.5}
                            display="flex"
                            alignItems="center"
                        >
                            <NumberField
                                name="max"
                                min={values.min || 0}
                                InputProps={{
                                    startAdornment: '$',
                                }}
                                inputClassName={classes.inputRootNoShadow}
                                className={clsx(classes.inputRoot, classes.inputRootNoShadow)}
                                onBlur={validateAndSubmitForm}
                            />
                        </Box>
                    </Box>
                </form>
            </FormikProvider>
        </Box>
    );
};
