import { IStore } from 'store/configureStore';

const payCodeArBucketsAssociationState = (state: IStore) => {
    return state.modules.settings.clientsSettings.clients.payCodeArBucket;
};

export const selectIsDealTypePayCodeArBucketsAssociationInProgress = (state: IStore) => {
    const payCodeArBucketsAssociation = payCodeArBucketsAssociationState(state);
    return payCodeArBucketsAssociation.isAssociationInProgress;
};
