import React from 'react';
import { Box } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';

const useStatusBadgeStyles = makeHighPriorityStyles(() => ({
    BadgeWrapper: {
        width: `110px`,
        border: `1px solid #666666`,
        borderRadius: `4px`,
        fontSize: `12px`,
        textAlign: `center`,
    },
}));

const getColor = (status: any) => {
    switch (status) {
        case `Approved`:
            return `#e5f6d3`;
        case `Missing`:
            return `#e89c30`;
        default:
            return `#fff`;
    }
};

const StatusBadge = ({ status }: {status: PayrollSheetTabs}) => {
    const classes = useStatusBadgeStyles();
    return (
        <Box className={classes.BadgeWrapper} style={{ backgroundColor: getColor(status) }}>
            {status} - Edit
        </Box>
    );
};

export default StatusBadge;
