/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { SettingsInfinityTablePure } from 'modules/settings/common/components/SettingsTablePure/SettingsInfinityTablePure';
import { assignmentsReportDataSelectors, IAssignmentReportDataRow, selectAssignmentReportDataRows } from 'modules/settings/submodules/clients/assignmentsReportData/components/AssignmentsReportDataList/store/selectors';
import { getMoreAssignmentReportDataAction, initialLoadAssignmentReportData, setEditAssignmentEditDataId } from 'modules/settings/submodules/clients/assignmentsReportData/store/actions';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { getUserName } from 'shared/utils/converters/user';

export const AssignmentsReportDataList = () => {
    const tableClasses = useDefaultTableStyles();
    const cells = [
        {
            key: 'employee',
            title: 'employee',
            render: ({ className, employee }: IAssignmentReportDataRow) => (
                <PlainText className={className} value={getUserName(employee)}/>
            ),
        },
        {
            key: 'uw_number',
            title: 'uw number',
            render: ({ className, uw_number }: IAssignmentReportDataRow) => (
                <PlainText className={className} value={uw_number}/>
            ),
        },
        {
            key: 'sso_id',
            title: 'SSO ID',
            render: ({ className, sso_id }: IAssignmentReportDataRow) => (
                <PlainText className={className} value={sso_id}/>
            ),
        },
        {
            key: 'actions',
            title: '',
            width: '54px',
            render: function DetailsCell({ className, id }: IAssignmentReportDataRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <EditItemButton id={id} editAction={setEditAssignmentEditDataId}/>
                    </div>
                );
            },
        },
    ];

    return (
        <SettingsInfinityTablePure
            infinityTableSelector={assignmentsReportDataSelectors}
            rowsSelector={selectAssignmentReportDataRows}
            initialLoadAction={initialLoadAssignmentReportData}
            getMoreAction={getMoreAssignmentReportDataAction.init}
            cells={cells}
        />
    );
};
