import React from 'react';
import { useModal } from 'shared/utils/hooks/useModal';
import { IconButton } from '@material-ui/core';
import ReceiptSVG from 'shared/components/icons/ReceiptSVG';
import EntryAttachmentsPreview from 'shared/components/attachments/EntryAttachmentsPreview';
import { IAttachment } from 'shared/models/Attachments';

interface IReceiptCellCommonProps {
    onDelete?: (file: IAttachment) => void;
    files: IAttachment[];
}

export const ReceiptCellCommon = ({
    files,
    onDelete,
}: IReceiptCellCommonProps) => {
    const {
        isModalOpened: isPreviewOpen,
        onModalOpen: onPreviewOpen,
        onModalClose: onPreviewClose,
    } = useModal();

    return (
        <>
            {files.length > 0 && (
                <>
                    <IconButton onClick={onPreviewOpen}>
                        <ReceiptSVG/>
                    </IconButton>
                    {isPreviewOpen && (
                        <EntryAttachmentsPreview
                            open={isPreviewOpen}
                            onClose={onPreviewClose}
                            files={files}
                            onDelete={onDelete}
                        />
                    )}
                </>
            )}
        </>
    );
};
