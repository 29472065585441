import { useDispatch, useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { Box } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { usePayrollReportStyle } from './PayrollStyle';
import { selectUsersById } from 'store/entities/users/selectors';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import GridTable from 'shared/components/table/GridTable/GridTable';
import {
    IReportOverviewItem,
    ISheetGroupIdWithClient, ISheetsForPrePayroll,
    PrePayrollReportOverview,
} from 'modules/payrollProcessorHub/store/model';
import ReportOverview from './ReportOverview';
import { selectIsPreInitializeReportLoading, selectPrePayrollReport } from '../../../store/selectors';
import { getPrePayrollReport } from '../../../store/actions';
import { fillPayDateInSheets, PayDates } from '../PrePayrollModal';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import {
    clientCell, deductionPeriodCell,
    employeeCell, expenseSheetCell, expenseTotalMilesCell, failedReasonCell,
    payPeriodCell, timeFilesAndDollarsCell, timeHlHoursTimeHlDollarsCell,
    timeOtHoursTimeOtDollarsCell,
    timeRtHoursTimeRtDollarsCell, timeSalariedCell, totalDollarsCell,
} from './cells';

export interface IPayrollInitializeSummaryReportProps {
    groupIds: ISheetGroupIdWithClient[];
    payDatesValue: PayDates;
    separateTimeExpense: boolean;
}

const PrePayrollReport = ({
    groupIds,
    payDatesValue,
    separateTimeExpense,
}: IPayrollInitializeSummaryReportProps) => {
    const classes = usePayrollReportStyle();
    const tableClasses = useDefaultTableStyles();

    const isLoading = useSelector(selectIsPreInitializeReportLoading);
    const allClients = useSelector(selectAllClientsById);
    const allUsers = useSelector(selectUsersById);
    const report = useSelector(selectPrePayrollReport);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPrePayrollReport.init({
            sheets: fillPayDateInSheets(groupIds, payDatesValue),
            separateTimeExpense: separateTimeExpense,
        }));
    }, [payDatesValue, groupIds, dispatch, separateTimeExpense]);

    const optionalTimeFilesAndDollarsCell = Number(report?.timeFiles) > 0
        ? [timeFilesAndDollarsCell(tableClasses)]
        : [];
    const optionalTimeSalariedAndDollarsCell = Number(report?.timeSalariedHours) > 0
        ? [timeSalariedCell(tableClasses)]
        : [];
    const optionalTimeHolidaysAndDollarsCell = Number(report?.timeHolidayHours) > 0
        ? [timeHlHoursTimeHlDollarsCell(tableClasses)]
        : [];

    const cells = [
        employeeCell(allUsers),
        payPeriodCell(),
        deductionPeriodCell(),
        clientCell(allClients),
        ...optionalTimeSalariedAndDollarsCell,
        timeRtHoursTimeRtDollarsCell(tableClasses),
        timeOtHoursTimeOtDollarsCell(tableClasses),
        ...optionalTimeHolidaysAndDollarsCell,
        ...optionalTimeFilesAndDollarsCell,
        expenseSheetCell(tableClasses),
        expenseTotalMilesCell(tableClasses),
        totalDollarsCell(tableClasses),
        failedReasonCell(tableClasses),
    ];

    const reportBlocks: IReportOverviewItem[][] | null = useMemo(() => {
        if (!report) {
            return null;
        }
        const {
            numberOfBatches,
            numberOfPayrollRecords,
            numberOfEmployees,
            numberOfEntries,
            numberOfPayrollRecordsFailed,
            timeRtHours,
            timeRtDollars,
            timeOtHours,
            timeOtDollars,
            expenseTotalDollars,
            totalDollars,
        } = report;
        return [
            [
                {
                    label: PrePayrollReportOverview.NumberOfBatchesToCreate,
                    values: [numberOfBatches],
                },
                {
                    label: PrePayrollReportOverview.NumberOfPayrollToCreate,
                    values: [numberOfPayrollRecords],
                },
                {
                    label: PrePayrollReportOverview.NumberOfEmployeeToProcess,
                    values: [numberOfEmployees],
                },
                {
                    label: PrePayrollReportOverview.NumberOfTimeAndExpenseToProcess,
                    values: [numberOfEntries],
                },
                ...(Number(numberOfPayrollRecordsFailed) !== 0 ? [{
                    label: PrePayrollReportOverview.NumberOfPayrollFailed,
                    values: [numberOfPayrollRecordsFailed],
                    className: classes.SummaryInfoValueError,
                }] : []),
            ],
            [
                {
                    label: PrePayrollReportOverview.TotalRegularTimeAndPay,
                    values: [timeRtHours, formatDollars(timeRtDollars)],
                },
                {
                    label: PrePayrollReportOverview.TotalOvertimeTimeAndPay,
                    values: [timeOtHours, formatDollars(timeOtDollars)],
                },
                {
                    label: PrePayrollReportOverview.TotalExpenses,
                    values: [formatDollars(expenseTotalDollars)],
                },
                {
                    label: PrePayrollReportOverview.TotalPay,
                    values: [formatDollars(totalDollars)],
                },
            ],
        ];
    }, [report, classes]);

    return (
        <Box className={classes.SummaryWrapper}>
            {reportBlocks && (
                <Box className={classes.SummaryInfo}>
                    <ReportOverview
                        blocks={reportBlocks}
                        classes={
                            {
                                column: classes.SummaryInfoColumn,
                                row: classes.SummaryInfoRow,
                                value: classes.SummaryInfoValue,
                            }
                        }
                    />
                </Box>
            )}
            <Box className={classes.SummaryTable}>
                <GridTable<ISheetsForPrePayroll>
                    getKey={row => row.timeSheetId}
                    cells={cells}
                    isLoading={isLoading}
                    rowData={report ? report.sheetsForPayroll : []}
                    stickyHeader
                    headerCellClassName={tableClasses.headCell}
                    bodyCellClassName={tableClasses.bodyCell}
                />
            </Box>
        </Box>
    );
};

export default PrePayrollReport;
