import { useCallback, useState } from 'react';

type ToggleManagerIsCheckedHandler = (id: string) => void;
type IsManagerCheckedFunction = (id: string) => boolean;

export function useManagerListForUnlock() {
    const [checkedMangersIds, setCheckedManagersIds] = useState<string[]>([]);

    const toggleManagerIsChecked = useCallback<ToggleManagerIsCheckedHandler>(id => {
        setCheckedManagersIds(checkedIds => {
            if (checkedIds.includes(id)) {
                return checkedIds.filter(checkedId => checkedId !== id);
            }
            return [...checkedIds, id];
        });
    }, []);

    const isManagerChecked = useCallback<IsManagerCheckedFunction>(
        id => checkedMangersIds.includes(id),
        [checkedMangersIds],
    );

    return {
        toggleManagerIsChecked,
        isManagerChecked,
        checkedMangersIds,
    };
}
