/* eslint-disable react/prop-types */
import React from 'react';
import { Formik } from 'formik';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import * as yup from 'yup';
import { Box, Typography } from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import FormField from 'shared/components/formFields/FormField';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

const errorMessage = `Please type 'delete'`;
const validationSchema = yup.object({
    deleteConfirmation: yup.string()
        .required(errorMessage)
        .lowercase()
        .trim()
        .matches(/^delete$/, errorMessage),
});

const useStyles = makeHighPriorityStyles(theme => ({
    paper: {
        maxWidth: '740px !important',
        minWidth: '400px',
    },
    content: {
        padding: `${theme.spacing(1)}px !important`,
    },
    input: {
        width: '100%',
        margin: theme.spacing(0.5, 0, 2),
    },
    button: {
        width: '100%',
        color: colors.red,
        borderColor: colors.red,
        fontWeight: theme.typography.fontWeightMedium,
        letterSpacing: '1px',

        '&:hover': {
            color: colors.white,
            backgroundColor: colors.red,
        },
    },
}));

interface IDeleteConfirmationModalProps {
    modalTitle: string;
    buttonTitle: string;
    isOpened: boolean;
    isLoading: boolean;
    onConfirm: () => void;
    onClose: () => void;
    children: React.ReactChildren | React.ReactChild | React.ReactChild[];
}

export const DeleteConfirmationModal = ({
    modalTitle,
    buttonTitle,
    isOpened,
    isLoading,
    onClose,
    onConfirm,
    children,
}: IDeleteConfirmationModalProps) => {
    const modalClasses = useColoredFormModalStyles();
    const classes = useStyles();

    return (
        <ModalDialog
            title={modalTitle}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={{
                customClasses: {
                    ...modalClasses,
                    ...classes,
                },
                showCloseIcon: true,
            }}
        >
            <Box m={2}>
                {children}

                <Typography gutterBottom>
                    Please type <b>delete</b> to confirm.
                </Typography>
                <Formik
                    initialValues={{
                        finished: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onConfirm}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <FormField
                                name="deleteConfirmation"
                                className={classes.input}
                            />
                            <ButtonWithLoader
                                type="submit"
                                variant="outlined"
                                className={classes.button}
                                disabled={isLoading}
                                isLoading={isLoading}
                            >
                                {buttonTitle}
                            </ButtonWithLoader>
                        </form>
                    )}
                </Formik>
            </Box>
        </ModalDialog>
    );
};
