import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useTimeEntryMobileModalTabsStyles = makeHighPriorityStyles((theme: Theme) => ({
    status: {
        width: '100%',
        minHeight: theme.spacing(6),
        textTransform: 'uppercase',
        borderBottom: '1px solid',
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    warningClass: {
        fill: colors.orange,
        marginBottom: '2px !important',
        marginLeft: '5px',
        fontSize: 16,
    },
}));
