import { Box, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { routes } from '../../../routes';
import React from 'react';
import { useStyles } from '../AuthStyles';
import { Link } from 'react-router-dom';

interface IAuthFooterProps {
    label: string;
    route: string;
}

const linksMap = {
    [routes.AUTH.LOGIN]: [routes.AUTH.REGISTRATION],
    [routes.AUTH.REGISTRATION]: [routes.AUTH.LOGIN],
    [routes.AUTH.RESET_PASSWORD]: [routes.AUTH.LOGIN, routes.AUTH.REGISTRATION],
    [routes.AUTH.NEW_PASSWORD]: [routes.AUTH.LOGIN, routes.AUTH.REGISTRATION],
    [routes.AUTH.RESET_FAILED]: [routes.AUTH.LOGIN, routes.AUTH.REGISTRATION],
};

const linkLabelsMap = {
    [routes.AUTH.LOGIN]: 'Login',
    [routes.AUTH.REGISTRATION]: 'Register',
};

export function AuthFooter({ label, route }: IAuthFooterProps) {
    const classes = useStyles();
    const links = linksMap[route] || [];

    return (
        <Paper className={classes.block}>
            <Box mt={-1}>
                <Typography align="center">{label}</Typography>
            </Box>
            <Box
                mt={2}
                mb={-2}
                display="flex"
                justifyContent="center"
            >
                {links.map(link => (
                    <Link
                        className={classes.linkLabel}
                        key={link}
                        to={link}
                        color="secondary"
                    >
                        <Button color="secondary">{linkLabelsMap[link]}</Button>
                    </Link>
                ))}
            </Box>
        </Paper>
    );
}
