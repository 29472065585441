import { useMemo } from 'react';
import { useOverflownTableStyles } from '../styles/useOverflownTableStyles';

export function useSwitchableTableScrollClasses() {
    const overflownTableStyles = useOverflownTableStyles();

    return useMemo(() => {
        return {
            tableClassName: overflownTableStyles.overflownTable,
            headingRowClassName: overflownTableStyles.headingScrollableRow,
            bodyRowClassName: overflownTableStyles.bodyScrollableRow,
        };
    }, [
        overflownTableStyles.bodyScrollableRow,
        overflownTableStyles.headingScrollableRow,
        overflownTableStyles.overflownTable,
    ]);
}
