import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { passwordValidationSchema } from 'shared/models/validationSchemes/passwordSchema';

export const passwordChangeValidationSchema = yup.object().shape({
    current_password: yup.string().required(ValidationMessages.REQUIRED),
    new_password: passwordValidationSchema,
    passwordConfirmation: yup.string().required(ValidationMessages.REQUIRED)
        .test({
            name: 'confirmationShouldBeEqualToPassword',
            test: function (value: string): boolean {
                return value === this.parent.new_password;
            },
            message: 'Password and password confirmation should be equal',
        }),
});
