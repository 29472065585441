import { routes } from '../../shared/routes';
import PrivateRoute from '../../shared/components/auth/PrivateRoute';
import { CcpPage } from './CcpPage';
import React from 'react';
import { Switch } from 'react-router-dom';

export const CcpRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path={routes.CLIENT.CCP.ROOT}
                component={CcpPage}
            />
        </Switch>
    );
};
