import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { IBaseCommonEntryFieldsProps } from 'shared/components/forms/entries/CommonEntryInlineFields';
import { addTimeEntry } from 'store/entities/timesheet/actions/timeActions';
import { ITimeEntryCreate, transformTimeDataToBackend } from 'store/entities/timesheet/models/Entry';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { selectSheet, selectTimeStatusIdByName } from 'store/entities/timesheet/selectors';
import { useTimeEntryValidationSchema } from 'shared/models/validationSchemes/timeEntry';
import { defaultValues, ITimeEntryFormValues } from 'shared/components/forms/entries/TimeEntryModel';
import { AddTimeEntryInlineForm } from 'shared/components/forms/entries/AddTimeEntryInlineForm';
import { InputFields } from 'store/entities/clients/clientsModel';
import { useGetCommonEntryModel, useTimeEntryDefaultValues } from 'shared/utils/helpers/entries';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';
import { StatusNames } from 'store/entities/timesheet/models/Status';

interface IAddTimeEntryProps extends IBaseCommonEntryFieldsProps {
    payPeriod: IPayPeriod;
    customClasses?: Record<'paper', string>;
    sheetId?: string;
    statusName?: StatusNames;
    inputs: InputFields;
    userId: string;
    disabled?: boolean;
    onChangeCommonValues: (values: ICommonEntryFormValues) => void;
}

export default function AddTimeEntry({
    payPeriod,
    sheetId,
    statusName,
    inputs,
    disabled = false,
    ...formProps
}: IAddTimeEntryProps) {
    const dispatch = useDispatch();
    const areaId = useSelector(selectSheet(sheetId))?.area_id;
    const statusId = useSelector(selectTimeStatusIdByName(statusName || ''));

    const { userId } = formProps;
    const schema = useTimeEntryValidationSchema(statusName, userId);
    const initialValues = useTimeEntryDefaultValues(userId);
    const defaultTask = inputs.task && inputs.task.default_value;
    const getCommonEntryModel = useGetCommonEntryModel(userId, defaultTask);

    const onSubmit = useCallback((values: ITimeEntryFormValues, formikActions: FormikHelpers<ITimeEntryFormValues>) => {
        const baseEntryModel = getCommonEntryModel(values);
        if (baseEntryModel && values.data) {
            const entry: ITimeEntryCreate = {
                ...baseEntryModel,
                data: transformTimeDataToBackend(values.data, values.entry_date),
                // @ts-ignore
                status_id: statusId,
                sca_zone: values.scaZone,
                // @ts-ignore
                user_id: userId,
                period_start: payPeriod.period_start,
                // @ts-ignore
                period_end: payPeriod.period_end,
            };
            dispatch(addTimeEntry.init(entry));
            formikActions.setFieldValue('data', defaultValues.data);
            formikActions.setFieldValue('notes', defaultValues.notes);
            formikActions.setFieldValue('completes', defaultValues.completes);
            formikActions.setTouched({
                data: false,
                notes: false,
                completes: false,
            }, false);
        }
        formikActions.setSubmitting(false);
    }, [dispatch, getCommonEntryModel, payPeriod, statusId, userId]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            /* we need to re-render entry form after update initial values shape (custom field values shape)
            It is required for Formik touched behavior on form submission */
            key={`initialValuesKey-${Object.keys(initialValues?.customFieldValues || {}).join('-')}`}
        >
            {props => (
                <AddTimeEntryInlineForm
                    {...formProps}
                    {...props}
                    payPeriod={payPeriod}
                    // @ts-ignore
                    areaId={areaId}
                    inputs={inputs}
                    disabled={disabled}
                />
            )}
        </Formik>
    );
}
