import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const ValidationsSchema = yup.object().shape({
    name: yup.string().trim().required(ValidationMessages.REQUIRED),
    employer_id: yup.string().trim().required(ValidationMessages.REQUIRED),
    cost_center: yup.string().trim().required(ValidationMessages.REQUIRED),
    status: yup.object().nullable().required(ValidationMessages.REQUIRED),
    status_date: yup.string().trim().required(ValidationMessages.REQUIRED),
    service_type: yup.string().trim().required(ValidationMessages.REQUIRED),
    payroll_rep_user: yup.object().nullable().required(ValidationMessages.REQUIRED),
    manager: yup.object().nullable().required(ValidationMessages.REQUIRED),
    address: yup.string().trim().required(ValidationMessages.REQUIRED),
    address2: yup.string()
        .trim()
        .matches(/^((?!#).)*$/, 'Please remove special characters'),
    city: yup.string().trim().required(ValidationMessages.REQUIRED),
    state: yup.string().trim().required(ValidationMessages.REQUIRED),
    county: yup.string().trim().required(ValidationMessages.REQUIRED),
    zip_code: yup.string().trim().required(ValidationMessages.REQUIRED),
    contact_name: yup.string().nullable().when(['contact_title', 'contact_email', 'contact_phone'],
        (contact_title: string, contact_email: string, contact_phone: string) => {
            if ( contact_title || contact_email || contact_phone) {
                return yup.string().nullable().required('Contact name can not be empty'); 
            }
            return yup.string().nullable();
        }),
    contact_email: yup.string().nullable().email('Please enter valid email'),
    contact_phone: yup.string().nullable().min(10, 'Please enter valid phone'),
    prism_corporation_type: yup.string().required(ValidationMessages.REQUIRED),
});
