import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { INamedEntity } from 'shared/models/Entity';

export interface INamedEntitySelectProps<T extends INamedEntity> extends IFormFieldProps, IFormSelect {
    options: T[];
}

export function NamedEntitySelect<T extends INamedEntity>(props: INamedEntitySelectProps<T>) {
    return (
        <FormSelect
            getKey={(client: INamedEntity) => client.id}
            getText={(client: INamedEntity) => client.name}
            {...props}
        />
    );
}
