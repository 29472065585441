import { IClientData } from 'modules/settings/submodules/clients/store/models';

export const defaultEditClientValues: IClientData = {
    name: '',
    employer_id: '000002',
    cost_center: 'Headway',
    status: null,
    status_date: '',
    service_type: 'Professional Employer',
    manager: null,
    payroll_rep_user: null,
    address: '',
    address2: '',
    city: '',
    state: '',
    county: '',
    zip_code: '',
    contact_name: null,
    contact_title: null,
    contact_email: null,
    contact_phone: null,
    prism_corporation_type: '',
};
