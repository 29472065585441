import { IClientRole, IUserInfo } from 'shared/models/User';
import { IClient } from 'store/entities/clients/clientsModel';

export interface IUserClientAssociationFormValues {
    client: IClient | null;
    user: IUserInfo | null;
    role: IClientRole | null;
}

export const defaultUserClientAssociationValues: IUserClientAssociationFormValues = {
    client: null,
    user: null,
    role: null,
};
