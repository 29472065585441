import { ISheetsStats, IStats } from 'modules/home/store/model';
import { selectUserStats } from 'modules/home/store/selectors';
import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { selectOfferLetterFilters } from 'modules/offerLetter/store/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AnyAction } from 'redux';
import { BlockSVG, ClockSVG, DescriptionSVG, MoneySVG, WarningSVG } from 'shared/components/dashboard/DashboardIcons';
import { IDashboardCardData } from 'shared/models/Dashboard';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { selectIsAdmin, selectIsManager } from 'store/components/auth/selectors';
import { setSheetGridStatus } from 'store/entities/appConfig/actions';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { SheetStatusSlug, StatusNames } from 'store/entities/timesheet/models/Status';

export const useHomeTitle = () => {
    const isManager = useSelector(selectIsManager);
    const isAdmin = useSelector(selectIsAdmin);

    const tabHeader = 'Headway Connect';
    let title = 'Manager Dashboard';
    if (!isManager && !isAdmin) {
        title = 'Employee Dashboard';
    }
    useTitleUpdate(title, title, tabHeader);
};

const getStatsByStatus = (allStats: IStats[], status: string): IStats | undefined => {
    return allStats.find(stats => stats.status === status);
};

export const useUserDashboardData = (isManager: boolean): IDashboardCardData[] => {
    const userStats = useSelector(selectUserStats);

    const outstandingOffers = getStatsByStatus(userStats.offer_letters, OfferLetterStatusSlug.Outstanding);
    const submittedSheets = getStatsByStatus(userStats.sheets, 'submitted') as ISheetsStats;
    const workingSheets = getStatsByStatus(userStats.sheets, 'working') as ISheetsStats;
    const rejectedSheets = getStatsByStatus(userStats.sheets, 'rejected') as ISheetsStats;
    const recalledSheets = getStatsByStatus(userStats.sheets, 'recalled') as ISheetsStats;
    const missingSheets = getStatsByStatus(userStats.sheets, 'missing') as ISheetsStats;

    const clientId = useSelector(selectCurrentClientId);
    const dispatch = useDispatch();
    const getClickHandler = useCallback((basePath: string, action?: AnyAction) => {
        if (!clientId) {
            return undefined;
        }
        return () => {
            if (action) {
                dispatch(action);
            }
            browserHistory.push(generatePath(basePath, { client_id: clientId }));
        };
    }, [clientId, dispatch]);

    const offerFilter = useSelector(selectOfferLetterFilters);

    return isManager ? [
        {
            color: `#813772`,
            backgroundColor: `rgba(129, 55, 114, 0.3)`,
            count: outstandingOffers?.total || 0,
            title: `outstanding offer letters`,
            Icon: DescriptionSVG,
            callback: getClickHandler(
                routes.CLIENT.OFFER_LETTER.ROOT,
                setOfferLetterFilters({
                    ...offerFilter,
                    statusSlug: OfferLetterStatusSlug.Outstanding,
                }),
            ),
        },
        {
            color: `#2db67d`,
            backgroundColor: `rgba(45, 182, 125, 0.3)`,
            count: submittedSheets?.expense_total || 0,
            title: `Expense sheets waiting for approval`,
            Icon: MoneySVG,
            callback: getClickHandler(
                routes.CLIENT.EXPENSE_APPROVAL.ROOT,
                setSheetGridStatus(StatusNames.SUBMITTED),
            ),
        },
        {
            color: `#009be5`,
            backgroundColor: `rgba(0, 155, 229, 0.3)`,
            count: submittedSheets?.time_total || 0,
            title: `Timesheets waiting for approval`,
            Icon: ClockSVG,
            callback: getClickHandler(
                routes.CLIENT.TIME_APPROVAL.ROOT,
                setSheetGridStatus(StatusNames.SUBMITTED),
            ),
        }, {
            color: `#e89c30`,
            backgroundColor: `rgba(232, 156, 48, 0.3)`,
            count: missingSheets?.total || 0,
            title: `missing time & expense sheets`,
            Icon: WarningSVG,
        },
    ] : [
        {
            color: `#009be5`,
            backgroundColor: `rgba(0, 155, 229, 0.3)`,
            count: outstandingOffers?.total || 0,
            title: `outstanding offer letters`,
            Icon: DescriptionSVG,
            callback: getClickHandler(
                routes.EMPLOYEE_OFFER_LETTER.ROOT,
            ),
        },
        {
            color: `#813772`,
            backgroundColor: `rgba(129, 55, 114, 0.3)`,
            count: workingSheets?.time_total || 0,
            title: `Working Timesheets`,
            Icon: ClockSVG,
            callback: getClickHandler(
                routes.CLIENT.TIME_AND_EXPENSE.ROOT,
            ),
        },
        {
            color: `#2db67d`,
            backgroundColor: `rgba(45, 182, 125, 0.3)`,
            count: workingSheets?.expense_total || 0,
            title: `Working expense sheets`,
            Icon: MoneySVG,
            callback: getClickHandler(
                routes.CLIENT.TIME_AND_EXPENSE.ROOT,
            ),
        },
        {
            color: `#b82601`,
            backgroundColor: `rgba(184, 38, 1, 0.3)`,
            count: rejectedSheets?.total || 0,
            title: `rejected time & expense sheets`,
            Icon: BlockSVG,
            callback: getClickHandler(
                `${routes.CLIENT.TIME_AND_EXPENSE.ROOT}?status=${SheetStatusSlug.REJECTED}`,
            ),
        },
        {
            color: `#e89c30`,
            backgroundColor: `rgba(184, 38, 1, 0.3)`,
            count: recalledSheets?.total || 0,
            title: `recalled time & expense sheets`,
            Icon: WarningSVG,
            callback: getClickHandler(
                `${routes.CLIENT.TIME_AND_EXPENSE.ROOT}?status=${SheetStatusSlug.RECALLED}`,
            ),
        },
    ];
};
