import { EntryType } from 'shared/models/sheet/Sheet';

export const setTextEmptyGrid = (hasEntries: boolean, sheetType?: EntryType) => {
    const postfix = hasEntries ? '' : ' reported yet';

    switch (sheetType) {
        case EntryType.EXPENSE:
            return `No expenses${postfix}.`;
        case EntryType.TIME:
            return `No time${postfix}.`;
        default:
            return `No time or expenses${postfix}.`;
    }
};
