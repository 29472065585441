import {
    loadEcubReportItems,
    pauseEcubReport,
    setEcubReportActiveTab,
} from 'modules/settings/submodules/clients/reports/Ecub/store/actions';
import {
    EcubStatus,
    EcubStatusSlug,
    IEcubItemsResponse,
} from 'modules/settings/submodules/clients/reports/Ecub/store/models';
import { combineReducers } from 'redux';
import { isLoadingReducer, resetStateReducer, singleValueReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const activeTab = singleValueReducer<EcubStatusSlug>(setEcubReportActiveTab.action, EcubStatus.Pending);
const isLoading = isLoadingReducer(loadEcubReportItems);
const table = extendReducer(
    singleValueReducer(loadEcubReportItems.successType, null),
    resetStateReducer(setEcubReportActiveTab.action, null),
    (state: IEcubItemsResponse | null, action: ReturnType<typeof pauseEcubReport.success>) => {
        switch (action.type) {
            case pauseEcubReport.successType:
                if (!state) {
                    return state;
                }
                return {
                    ...state,
                    items: state.items.map(item => {
                        if (item.name === action.payload.filename) {
                            return {
                                ...item,
                                is_paused: action.payload.is_paused,
                            };
                        }
                        return item;
                    }),
                };
            default:
                return state;
        }
    },
);
const isPauseInProgress = isLoadingReducer(pauseEcubReport);

export const ecubReport = combineReducers({
    activeTab,
    isLoading,
    isPauseInProgress,
    table,
});
