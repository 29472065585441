import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( {
    pdfWrapper: {
        height: '100%',
        minHeight: 600,
        position: 'relative',
        display: 'flex',
        '& embed': {
            height: 'auto !important',
        },
    },
});
