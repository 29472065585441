import React, { useCallback } from 'react';
import {
    resolveLocationBatch,
    resolvePositionBatch,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/detailItemValueResolvers';
import {
    usePayrollSheetGroupLocations,
    usePayrollSheetGroupPositions,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/usePayrollSheetGroupCustomFields';
import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { RejectCard } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectCard';
import { getLastFirstName } from 'shared/utils/converters/user';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { getTotalHours } from 'shared/utils/helpers/hoursCalculations';

interface IGroupRejectionCardProps {
    row: IPayrollSheetGroupedRow,
    note: string,
    onNoteChange: (id: string, note: string) => void,
}

export const GroupRejectionCard = ({
    row,
    note,
    onNoteChange,
}: IGroupRejectionCardProps) => {
    const positions = usePayrollSheetGroupPositions(row);
    const locations = usePayrollSheetGroupLocations(row);

    const employeeFullName = getLastFirstName(row.employee);
    // @ts-ignore
    const employeePosition = resolvePositionBatch(row, positions);
    const employeeLocation = resolveLocationBatch(locations);
    const payPeriod = getPayPeriodByStartEnd(row.pay_period_starts_at, row.pay_period_ends_at);
    const totalHours = getTotalHours([
        row.time_ot_hours,
        row.time_rt_hours,
        row.time_dt_hours,
    ]);
    const handleChange = useCallback(
        ({ target }) => onNoteChange(row.id, target.value),
        [onNoteChange, row.id],
    );
    return (
        <RejectCard
            key={row.id}
            onChange={handleChange}
            totalDollars={row.total_dollars}
            employeeFullName={employeeFullName}
            employeePosition={employeePosition}
            employeeLocation={employeeLocation}
            payPeriod={payPeriod}
            totalHours={totalHours}
            inputValue={note}
        />
    );
};
