import React from 'react';
import { Box } from '@material-ui/core';

export const FormSectionFieldWrapper = ({ children }: { children: React.ReactNode }) => (
    <Box display="flex">
        <Box width="60%">
            {children}
        </Box>
    </Box>
);
