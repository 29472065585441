import { uniq } from 'lodash-es';
import { defaultInfinityScrollState, IInfinityScrollState } from 'store/reducerUtils';
import {
    CcpTransactionsActions,
    editModeUpdateSplitTransactions,
    getMoreCcpTransactionsAction,
    initialLoadCcpTransactionsPage,
    setCcpFilter,
    setCcpTransactionsActiveTab,
} from 'modules/ccp/store/actions';
import { ICcpTableFilters, ICcpTransactionsResponse, ICcpUpdateSplitTransactions } from 'modules/ccp/models/ccpModel';

export const defaultCcpFilter: ICcpTableFilters = {
    user_id: null,
    department_id: null,
    submitted_date: null,
    regional_manager_id: null,
};

export function ccpTransactionsTable(
    state: IInfinityScrollState<string>,
    action: CcpTransactionsActions,
): IInfinityScrollState<string> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setCcpTransactionsActiveTab.action:
        case initialLoadCcpTransactionsPage.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreCcpTransactionsAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case editModeUpdateSplitTransactions.action: {
            const payload = action.payload as ICcpUpdateSplitTransactions;
            const diff = payload.added_ids.length - payload.removed_ids.length;
            // @ts-ignore
            const newTotal = parseInt(state.total || 0) + diff;
            const newItems = [...state.items].filter(
                item => !(payload.removed_ids.includes(item)),
            );
            // @ts-ignore
            action.payload.added_ids.map((item: string) => {
                const idx = newItems.findIndex(trId => {
                    return trId === payload.parent_id;
                });
                newItems.splice(idx + 1, 0, item);
            });
            return {
                total: newTotal,
                items: newItems,
                isLoading: false,
            };
        }
        case getMoreCcpTransactionsAction.successType: {
            const payload = action.payload as ICcpTransactionsResponse;
            // new pages can contain duplicates of parent
            const newItems = uniq([
                ...state.items,
                ...payload.items.map(entry => entry.id),
            ]);
            return {
                total: payload.total_items,
                items: newItems,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}

export function filter(
    state: ICcpTableFilters = defaultCcpFilter,
    action: CcpTransactionsActions,
): ICcpTableFilters {
    switch (action.type) {
        case setCcpFilter.action:
            return {
                ...state,
                // @ts-ignore
                ...action.payload,
            };
        default:
            return state;
    }
}
