import { useCcpTabs } from './hooks/useCcpTabs';
import { HeaderTabs, IHeaderTab } from 'shared/components/common/headerTabs/HeaderTabs';
import { useDispatch, useSelector } from 'react-redux';
import { useTabManagement } from '../../shared/hooks/useTabManagement';
import { CcpStatusSlug } from './models/ccpModel';
import { selectCcpTransactionsActiveTab } from './store/selectors';
import { setCcpTransactionsActiveTab } from './store/actions';
import { selectIsUserHasPermission } from '../../store/components/auth/selectors';
import { Permission } from '../../store/components/auth/authModels';
import React, { useCallback } from 'react';
import { Hidden, Box, Button } from '@material-ui/core';
import { useHeaderCreateButtonStyles } from '../../shared/styles/headerCreateButtonStyles';
import { setOpenBulkCreateCcpUploadModal } from './components/CcpCreate/store/actions';

const CcpTabs = () => {
    const dispatch = useDispatch();
    const hasReconciledPermission = useSelector(selectIsUserHasPermission(Permission.ViewCcpReconciled));
    const hasUnreconciledPermission = useSelector(selectIsUserHasPermission(Permission.ViewCcpUnreconciled));
    const hasImportPermission = useSelector(selectIsUserHasPermission(Permission.CanImportCcp));
    const hasCanWorkWithCcpInvoicesPermission = useSelector(
        selectIsUserHasPermission(Permission.CanWorkWithCcpInvoices));
    const tabs = useCcpTabs(hasReconciledPermission,
        hasUnreconciledPermission,
        hasCanWorkWithCcpInvoicesPermission) as IHeaderTab[];
    const classes = useHeaderCreateButtonStyles();

    const handleCreateUploadClick = useCallback(() => {
        dispatch(setOpenBulkCreateCcpUploadModal(true));
    }, [dispatch]);

    const { activeTab, setActiveTab } = useTabManagement<CcpStatusSlug>(
        // @ts-ignore
        selectCcpTransactionsActiveTab,
        setCcpTransactionsActiveTab,
    );

    return (
        <>
            <HeaderTabs
                tabs={tabs}
                mobileHeader="Trasactions Status"
                onChange={setActiveTab}
                value={activeTab}/>
            <Hidden xsDown>
                <Box
                    display="flex"
                    height="100%"
                    mr={2}
                    flexDirection="column"
                    justifyContent="flex-end"
                >
                    { hasImportPermission && (
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            classes={{
                                root: classes.createButton,
                                label: classes.createButtonLabel,
                            }}
                            onClick={handleCreateUploadClick}
                        >
                            + Import Report
                        </Button> )
                    }
                </Box>
            </Hidden>
        </>
    );
};

export default CcpTabs;
