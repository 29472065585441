import baseApi from 'shared/utils/baseApi';
import { ISheetCalculationRequest, ISheetCalculationResponse } from 'store/entities/timesheet/models/Calculation';

export const payrollApi = {
    async getSheetCalculations(
        params: ISheetCalculationRequest = {},
    ): Promise<ISheetCalculationResponse> {
        const response = await baseApi
            .post<ISheetCalculationRequest, ISheetCalculationResponse>(
            'payroll/calculations/query',
            params,
        );
        return response?.data;
    },
};
