import React, { useCallback } from 'react';
import { ISheetGroupIdWithClient } from 'modules/payrollProcessorHub/store/model';
import { Button } from '@material-ui/core';
import { useModal } from 'shared/utils/hooks/useModal';
import PrePayrollModal from '../PayrollModal/PrePayrollModal';
import Modal from 'shared/components/modals/Modal';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { clearPayrollReports, loadGroupedSheets } from '../../store/actions';
import { selectPostPayrollReport } from '../../store/selectors';
import PostPayrollModal from '../PayrollModal/PostPayrollModal';
import { browserHistory } from 'shared/utils/browserHistory';
import { routes } from 'shared/routes';

export interface IPayrollButtonProps {
    groupIds: ISheetGroupIdWithClient[];
}

const usePayrollModalStyles = makeHighPriorityStyles(() => ({
    modalPaper: {
        minWidth: 1000,
    },
}));

export const PayrollButton = ({ groupIds }: IPayrollButtonProps) => {
    const { isModalOpened, onModalClose, onModalOpen } = useModal();
    const classes = usePayrollModalStyles();
    const dispatch = useDispatch();
    const onCloseModalHandler = useCallback(() => {
        dispatch(clearPayrollReports());
        onModalClose();
    }, [dispatch, onModalClose]);

    const onClosePostPayrollModal = useCallback(() => {
        dispatch(loadGroupedSheets());
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
        onCloseModalHandler();
    }, [dispatch, onCloseModalHandler]);

    const postPayrollReport = useSelector(selectPostPayrollReport);
    const modalName = postPayrollReport ? 'Payroll Post-processing Roll Up' : 'Create Payroll Batch';

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={onModalOpen}
            >
                Initialize payroll
            </Button>
            <Modal
                title={modalName}
                isOpened={isModalOpened}
                onClose={onCloseModalHandler}
                showCloseIcon={true}
                customClasses={{
                    paper: classes.modalPaper,
                }}
            >
                {postPayrollReport
                    ? (
                        <PostPayrollModal close={onClosePostPayrollModal}/>
                    )
                    : (
                        <PrePayrollModal close={onCloseModalHandler} groupIds={groupIds}/>
                    )}
            </Modal>
        </>
    );
};
