import { setEndAssignmentId } from 'modules/employeeActionsForm/components/EndAssignment/store/action';
import {
    createEmployeeActionForm,
    getAssignmentMaxEntryDate,
    getEafReasons,
} from 'modules/employeeActionsForm/store/actions';
import { eafApi } from 'modules/employeeActionsForm/store/api';
import {
    EafStatusSlug,
    EafType,
    eafTypeDisplayValues,
    IEmployeeActionForm,
} from 'modules/employeeActionsForm/store/models';
import { setDisplayAssignmentStartDateChangeDialog } from 'modules/subassignmentManagement/components/AssignmentStartDateChange/store/actions';
import { setOpenCompensationChange } from 'modules/subassignmentManagement/components/CompensationChange/store/actions';
import { setEditSubassignmentId } from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import { setOpenPromotionDemotion } from 'modules/subassignmentManagement/components/PromotionDemotion/store/actions';
import { setDetailAssignmentId } from 'modules/subassignmentManagement/store/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import {
    getAssignments,
    getSubassignments,
} from 'store/entities/configuration/configurationAction';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, take, takeLatest } from 'typed-redux-saga';

const getReasonsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getEafReasons,
    eafApi.getEafReasons,
    'reason',
);

function* updateAssignmentAndSubassignments(assignmentId: string) {
    const ids = [assignmentId];
    yield put(getAssignments.init({ ids }));
    yield put(getSubassignments.init({ assignment_ids: ids }));
    yield take(getSubassignments.finallyTypes);
}

function* showEafCreationSuccessMessage(eaf: IEmployeeActionForm) {
    const isEafNeedApproval = eaf.status.slug === EafStatusSlug.PendingApproval;
    const displayType = eafTypeDisplayValues[eaf.employee_action_form_data.employee_action_form_type];
    const userMessage = isEafNeedApproval
        ? `${displayType} sent to the Regional Manager for approval. Once approved, it will automatically be applied.`
        : `${displayType} was successfully applied.`;
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: userMessage,
        autoHideDuration: null,
    }));
}

function* handleSuccessCompensationChange(eaf: IEmployeeActionForm) {
    yield* showEafCreationSuccessMessage(eaf);
    yield put(setOpenCompensationChange(false));
}

function* handlePromotionDemotionEaf(eaf: IEmployeeActionForm) {
    yield* showEafCreationSuccessMessage(eaf);
    yield put(setOpenPromotionDemotion(false));
}

function* handleStartDateChangeEaf() {
    yield put(setDisplayAssignmentStartDateChangeDialog(false));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Start date of the assignment has been successfully updated',
    }));
}

export function* createdEmployeeActionFormSaga({ payload }: ReturnType<typeof createEmployeeActionForm.init>) {
    const newEaf = yield call(eafApi.createEmployeeActionForm, payload);
    yield* updateAssignmentAndSubassignments(newEaf.assignment_id);
    switch (newEaf.employee_action_form_data.employee_action_form_type) {
        case EafType.CompensationChange:
            yield* handleSuccessCompensationChange(newEaf);
            break;
        case EafType.AssignmentEnded:
            yield put(setEndAssignmentId(null));
            yield put(setGlobalToast({
                severity: IModalSeverity.Success,
                title: 'Assignment successfully ended',
            }));
            break;
        case EafType.Promotion:
        case EafType.Demotion:
            yield* handlePromotionDemotionEaf(newEaf);
            break;
        case EafType.StartDateChange:
            yield* handleStartDateChangeEaf();
            break;
        case EafType.Termination:
            yield put(setGlobalToast({
                severity: IModalSeverity.Success,
                title: 'The termination request was sent to HR for approval.',
            }));
            break;
        default:
            break;
    }
    yield put(createEmployeeActionForm.success(newEaf));
    yield put(setDetailAssignmentId(null));
    yield put(setEditSubassignmentId(null));
}

function* createEmployeeActionFormWatcher() {
    yield takeLatest(createEmployeeActionForm.initType, withBackendErrorHandler(
        createdEmployeeActionFormSaga,
        createEmployeeActionForm.error,
        'Unable to create employee action forms',
    ));
}

function* getAssignmentMaxEntryDateSaga({ payload }: ReturnType<typeof getAssignmentMaxEntryDate.init>) {
    const assignmentLastEntry = yield call(eafApi.getAssignmentMaxEntryDate, payload);
    yield put(getAssignmentMaxEntryDate.success({
        ...assignmentLastEntry,
        assignmentId: payload,
    }));
}

function* getAssignmentMaxEntryDateWatcher() {
    yield takeLatest(getAssignmentMaxEntryDate.initType, withBackendErrorHandler(
        getAssignmentMaxEntryDateSaga,
        getAssignmentMaxEntryDate.error,
        'Unable to fetch assignment max entry date',
    ));
}

export default [
    getReasonsWatcher,
    createEmployeeActionFormWatcher,
    getAssignmentMaxEntryDateWatcher,
];
