import { INamedEntity } from 'shared/models/Entity';

export enum Status {
    active = 'active',
    inactive = 'inactive',
}

export interface IStatusEntity<TStatusSlug extends string = string,
    TName extends string = string> extends INamedEntity<TName> {
    slug: TStatusSlug;
}
