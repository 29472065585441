import React from 'react';
import { Typography } from '@material-ui/core';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { useSheetRejectStyles } from './styles';
import clsx from 'clsx';
import { EntryType } from 'shared/models/sheet/Sheet';

interface ISheetRejectLabelProps {
    sheetType: EntryType;
    count: number;
}  
    
export default function SheetRejectLabel({ count, sheetType }: ISheetRejectLabelProps) {
    const classes = useSheetRejectStyles();
    const sidebarClasses = useSidebarStyles();
    return (
        <section className={clsx(sidebarClasses.sidebarElement, classes.sheetInfo )}>
            <Typography display="inline" variant="body2">
                A total of {count} {sheetType === EntryType.TIME ? 'timesheets' : 'expense sheets'} have been selected for rejection.
                Please enter the reason why you are rejecting for each {sheetType} sheet below.
            </Typography>
        </section>
    );
}
