import React from 'react';
import clsx from 'clsx';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { TableClasses } from '../model';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type ExpensesCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export function makeExpensesCell(tableClasses: TableClasses) {
    const ExpensesCell = ({
        className,
        expense_total_dollars,
    }: ExpensesCellProps) => (
        <PlainText
            className={clsx(className, tableClasses.pullRight)}
            value={formatDollars(expense_total_dollars)}
        />
    );

    return ExpensesCell;
}
