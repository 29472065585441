import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { IDashboardProps } from 'shared/models/Dashboard';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useStyles } from './styles';
import { DashboardItem } from 'shared/components/dashboard/DashboardItem';

const Dashboard = ({ data, isLoading = false }: IDashboardProps) => {
    const classes = useStyles();
    const isMobile = useIsMobile();
    return (
        <Box className={classes.gridWrapper}>
            <Grid
                container
                spacing={isMobile ? 3 : 4}
                className={classes.dashboardContainer}
            >
                {data.map(itemData => (
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md="auto"
                        key={`${itemData.title}-dashboard`}
                    >
                        <DashboardItem
                            {...itemData}
                            isLoading={isLoading}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Dashboard;
