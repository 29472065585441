import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from '../formFields/models';
import { ITask, renderTaskInfo } from 'store/entities/configuration/configurationModel';
import { IFormSelect } from './model';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectTasksToSubmit } from 'store/components/addEntry/addEntrySelectors';

export interface ITaskProps extends IFormFieldProps, IFormSelect {
    assignmentId?: string;
    projectId?: string;
}

interface ITaskStateProps {
    tasks: ITask[];
}

export function TaskPure({ tasks, ...props }: ITaskStateProps & ITaskProps ) {
    return (
        <FormSelect
            getKey={(task: ITask) => task.id}
            getText={(task: ITask) => renderTaskInfo(task)}
            options={tasks}
            {...props}
        />
    );
}

export const Task = separateLogicDecorator<ITaskProps, ITaskStateProps>(({
    assignmentId,
    projectId,
}) => {
    const tasks = useSelector(selectTasksToSubmit);
    return {
        tasks: tasks.filter(task => assignmentId ? task.project_id === projectId : true),
    };
})(TaskPure);
