/* eslint-disable react/display-name */
import { AutocompleteRenderGroupParams } from '@material-ui/lab';
import React, { useMemo } from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const LISTBOX_PADDING = 8;

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return (
        <div
            ref={ref}
            {...props}
            {...outerProps}
        />
    );
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current !== null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
}

export const renderGroup = (params: AutocompleteRenderGroupParams) => [
    params.group ? (
        <ListSubheader key={params.key} component="div">
            {params.group}
        </ListSubheader>
    ) : null,
    params.children,
];

export const useVirtualizedListBoxContainer = (
    itemSize: number,
) => {
    return useMemo(() => {
        return React.forwardRef<HTMLDivElement>((props, ref) => {
            // eslint-disable-next-line react/prop-types
            const { children, ...other } = props;
            const itemData = React.Children.toArray(children);
            const itemCount = itemData.length;

            const getChildSize = (child: React.ReactNode) => {
                if (React.isValidElement(child) && child.type === ListSubheader) {
                    return 48;
                }

                return itemSize;
            };

            const getHeight = () => {
                if (itemCount > 8) {
                    return 8 * itemSize;
                }
                return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
            };

            const gridRef = useResetCache(itemCount);

            return (
                <div ref={ref}>
                    <OuterElementContext.Provider value={other}>
                        <VariableSizeList
                            itemData={itemData}
                            height={getHeight() + 2 * LISTBOX_PADDING}
                            width="100%"
                            ref={gridRef}
                            outerElementType={OuterElementType}
                            innerElementType="ul"
                            itemSize={index => getChildSize(itemData[index])}
                            overscanCount={5}
                            itemCount={itemCount}
                        >
                            {renderRow}
                        </VariableSizeList>
                    </OuterElementContext.Provider>
                </div>
            );
        });
    }, [itemSize]);
};
