import React from 'react';
import { SvgIcon } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

export interface IColor {
    color: string;
}

export function ThumbUpSVG({ color }: IColor) {
    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color}
            component="svg"
        >
            <path
                d="M21,9 L14.69,9 L15.64,4.43 L15.67,4.11 C15.67,3.7 15.5,3.32 15.23,3.05 L14.17,2 L7.59,8.59 C7.22,8.95 7,9.45 7,10 L7,20 C7,21.1 7.9,22 9,22 L18,22 C18.83,22 19.54,21.5 19.84,20.78 L22.86,13.73 C22.95,13.5 23,13.26 23,13 L23,11 C23,9.9 22.1,9 21,9 Z M21,13 L18,20 L9,20 L9,10 L13.34,5.66 L12.23,11 L21,11 L21,13 Z M1,10 L5,10 L5,22 L1,22 L1,10 Z"
            />
        </SvgIcon>
    );
}

export function WarningSVG({ color }: IColor) {
    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color}
            component="svg"
        >
            <path
                d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
            />
        </SvgIcon>
    );
}

export function CheckSVG({ color }: IColor) {
    return (
        <CheckIcon component="svg" htmlColor={color}/>
    );
}

export function AssignmentLateSVG({ color }: IColor) {
    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color}
            component="svg"
        >
            <path
                d="M11,16 L13,16 L13,18 L11,18 L11,16 Z M11,8 L13,8 L13,14 L11,14 L11,8 Z M19,4 L14.82,4 C14.4,2.84 13.3,2 12,2 C10.7,2 9.6,2.84 9.18,4 L5,4 C4.86,4 4.73,4.01 4.6,4.04 C4.21,4.12 3.86,4.32 3.59,4.59 C3.41,4.77 3.26,4.99 3.16,5.23 C3.06,5.46 3,5.72 3,6 L3,20 C3,20.27 3.06,20.54 3.16,20.78 C3.26,21.02 3.41,21.23 3.59,21.42 C3.86,21.69 4.21,21.89 4.6,21.97 C4.73,21.99 4.86,22 5,22 L19,22 C20.1,22 21,21.1 21,20 L21,6 C21,4.9 20.1,4 19,4 Z M12,3.75 C12.41,3.75 12.75,4.09 12.75,4.5 C12.75,4.91 12.41,5.25 12,5.25 C11.59,5.25 11.25,4.91 11.25,4.5 C11.25,4.09 11.59,3.75 12,3.75 Z M19,20 L5,20 L5,6 L19,6 L19,20 Z"
            />
        </SvgIcon>
    );
}

export function DescriptionSVG({ color }: IColor) {
    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color}
            component="svg"
        >
            <path
                d="M8,16 L16,16 L16,18 L8,18 L8,16 Z M8,12 L16,12 L16,14 L8,14 L8,12 Z M14,2 L6,2 C4.9,2 4,2.9 4,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z M18,20 L6,20 L6,4 L13,4 L13,9 L18,9 L18,20 Z"
            />
        </SvgIcon>
    );
}

export function MoneySVG({ color }: IColor) {
    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color}
            component="svg"
        >
            <path
                d="M12.39,10.9 C10.12,10.31 9.39,9.7 9.39,8.75 C9.39,7.66 10.4,6.9 12.09,6.9 C13.87,6.9 14.53,7.75 14.59,9 L16.8,9 C16.73,7.28 15.68,5.7 13.59,5.19 L13.59,3 L10.59,3 L10.59,5.16 C8.65,5.58 7.09,6.84 7.09,8.77 C7.09,11.08 9,12.23 11.79,12.9 C14.29,13.5 14.79,14.38 14.79,15.31 C14.79,16 14.3,17.1 12.09,17.1 C10.03,17.1 9.22,16.18 9.11,15 L6.91,15 C7.03,17.19 8.67,18.42 10.59,18.83 L10.59,21 L13.59,21 L13.59,18.85 C15.54,18.48 17.09,17.35 17.09,15.3 C17.09,12.46 14.66,11.49 12.39,10.9 Z"
            />
        </SvgIcon>
    );
}

export function ClockSVG({ color }: IColor) {
    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color}
            component="svg"
        >
            <path
                d="M11.99,2 C6.47,2 2,6.48 2,12 C2,17.52 6.47,22 11.99,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 11.99,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 Z M12.5,7 L11,7 L11,13 L16.25,16.15 L17,14.92 L12.5,12.25 L12.5,7 Z"
            />
        </SvgIcon>
    );
}

export function BlockSVG({ color }: IColor) {
    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color}
            component="svg"
        >
            <path
                d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,10.15 4.63,8.45 5.69,7.1 L16.9,18.31 C15.55,19.37 13.85,20 12,20 Z M18.31,16.9 L7.1,5.69 C8.45,4.63 10.15,4 12,4 C16.42,4 20,7.58 20,12 C20,13.85 19.37,15.55 18.31,16.9 Z"
            />
        </SvgIcon>
    );
}
