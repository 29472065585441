import { selectOfferLetter } from 'modules/offerLetter/store/selectors';
import { routes } from 'shared/routes';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { finishOnboarding } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import { updateOfferLetterStatus } from 'modules/offerLetter/store/actions';
import { offerLetterApi } from 'modules/offerLetter/store/api';
import { IUpdateOfferRequest, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { browserHistory } from 'shared/utils/browserHistory';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';

function* finishOnboardingSaga({ payload: offerLetterId }: ReturnType<typeof finishOnboarding.init>) {
    const offerLetter = yield select(selectOfferLetter(offerLetterId));
    if (offerLetter?.status?.slug === OfferLetterStatusSlug.Outstanding) {
        const params: Partial<IUpdateOfferRequest> = {
            status: OfferLetterStatusSlug.PendingManagerApproval,
        };

        const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, offerLetterId, params);
        yield put(updateOfferLetterStatus.success(updatedOfferLetter));
    }
    yield put(finishOnboarding.success());
    browserHistory.push(routes.EMPLOYEE_OFFER_LETTER.ROOT);
}

function* finishOnboardingWatcher() {
    yield takeLatest(
        finishOnboarding.initType,
        withBackendErrorHandler(
            finishOnboardingSaga,
            finishOnboarding.error,
            'Unable to update offer letter',
        ),
    );
}

export default [
    finishOnboardingWatcher,
];
