import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { FormMode } from 'shared/models/FormMode';
import { selectClientHasCompatibleHierarchy, selectCustomField, selectCustomFieldId, selectCustomFieldRelations } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { selectIsCreateCustomFieldValueModalOpened, selectIsCreatingCustomFieldValue } from 'modules/settings/submodules/clients/customFieldValues/components/CreateCustomFieldValue/store/selectors';
import { CustomFieldValueFormWrapper } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/CustomFieldValueFormWrapper';
import { ICustomFieldValueFormValues } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/model';
import { createCustomFieldValue, setCreateCustomFieldValueModalState } from 'modules/settings/submodules/clients/customFieldValues/store/actions';
import { getHierarchyRelationsByFormValues } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/utils';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const CreateCustomFieldValueModal = () => {
    const classes = useColoredFormModalStyles();
    const dispatch = useDispatch();

    const isOpen = useSelector(selectIsCreateCustomFieldValueModalOpened);
    const isLoading = useSelector(selectIsCreatingCustomFieldValue);
    const customFieldId = useSelector(selectCustomFieldId);
    const customField = useSelector(selectCustomField);
    const isHierarchiesAreCompatible = useSelector(selectClientHasCompatibleHierarchy);
    const relations = useSelector(selectCustomFieldRelations);

    const onSave = useCallback((values: ICustomFieldValueFormValues) => {
        // @ts-ignore
        dispatch(createCustomFieldValue.init({
            custom_field_id: customFieldId,
            ...getHierarchyRelationsByFormValues(
                values,
                relations,
                isHierarchiesAreCompatible,
                true,
            ),
            data: values.data,
        }));
    }, [dispatch, customFieldId, isHierarchiesAreCompatible, relations]);
    const onClose = useCallback(() => {
        dispatch(setCreateCustomFieldValueModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title={`Create New ${customField?.name || 'Custom Field'} Value`}
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box m={-3}>
                <CustomFieldValueFormWrapper
                    onSubmit={onSave}
                    submitTitle="Create"
                    isLoading={isLoading}
                    mode={FormMode.New}
                    customFieldId={customFieldId}
                />
            </Box>
        </ModalDialog>
    );
};
