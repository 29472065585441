import { createSelector } from 'reselect';
import { selectTravelExpensesById } from 'modules/travelExpenses/selectors';
import { selectExpenseEntriesByIds } from 'store/entities/timesheet/selectors';

export const selectExpenseEntryIncludingTravelsByIds = createSelector(
    selectExpenseEntriesByIds,
    selectTravelExpensesById,
    (entriesByIds, travelExpensesByIds) => {
        return {
            ...travelExpensesByIds,
            ...entriesByIds,
        };
    },
);
