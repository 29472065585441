import baseApi from 'shared/utils/baseApi';
import {
    IFieldsConfigurationWrapper,
    IThemeConfigurationWrapper,
    IPayrollInfo, IPaySettings, IClientConfigurationBackend,
} from 'store/entities/clients/clientsModel';

const configurationPath = '/client-configurations';

export const clientsApi = {
    async getFieldsConfiguration(clientId: string): Promise<IFieldsConfigurationWrapper> {
        const { data } = await baseApi.get<IFieldsConfigurationWrapper>(
            `${configurationPath}/clients/${clientId}/configuration`,
        );

        return data;
    },
    async updateClientConfiguration(
        clientId: string,
        request: Partial<IClientConfigurationBackend>,
    ): Promise<IFieldsConfigurationWrapper> {
        const response = await baseApi.patch<Partial<IClientConfigurationBackend>, IFieldsConfigurationWrapper>(
            `${configurationPath}/clients/${clientId}/configuration`,
            request,
        );
        return response.data;
    },
    async getPaySettings(clientId: string): Promise<IPaySettings> {
        const { data } = await baseApi.get<IPaySettings>(
            `${configurationPath}/clients/${clientId}/pay_settings`,
        );
        return data;
    },
    async getConfigurationTheme(): Promise<Array<IThemeConfigurationWrapper>> {
        const { data } = await baseApi.get<{configurations: Array<IThemeConfigurationWrapper>}>(
            `${configurationPath}/clients/configuration/theme`,
        );

        return data.configurations;
    },
    async startPayroll({ client_id, pay_date }: IPayrollInfo): Promise<any> {
        const request = {
            client_id,
            pay_date,
        };
        await baseApi.create<IPayrollInfo, any>(
            'payroll', 'weekly',
            request,
        );
    },
    async deleteClient(clientId: string): Promise<any> {
        await baseApi.delete<any>(
            'client-users/clients/',
            clientId,
        );
        return clientId;
    },
};
