import { AxiosError } from 'axios';

export interface IRequestModel extends Pick<AxiosError, 'request'> {
    response: {
        status?: number;
        statusText?: string;
        data: any;
    };
}

export function getRequestDetails({ request, response }: AxiosError): IRequestModel {
    const responsePayload = { status: response?.status, statusText: response?.statusText, data: response?.data };
    return { request, response: responsePayload };
}
