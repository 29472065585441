import { LinksConfigurationPage } from 'modules/settings/submodules/clients/appConfiguration/LinksConfigurationPage';
import React from 'react';
import { Switch } from 'react-router-dom';
import { settingsRoutes } from 'modules/settings/routes';
import { AppConfigurationPage } from 'modules/settings/submodules/clients/appConfiguration/AppConfigurationPage';
import PrivateRoute from 'shared/components/auth/PrivateRoute';

export function ConfigurationRoutes() {
    return (
        <Switch>
            <PrivateRoute
                path={settingsRoutes.CLIENTS.APP_CONFIGURATION.LINKS}
                component={LinksConfigurationPage}
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.APP_CONFIGURATION.ROOT}
                component={AppConfigurationPage}
            />
        </Switch>
    );
}
