import React from 'react';
import { Formik } from 'formik';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import {
    defaultControllingOrgFormValues,
    IControllingOrgFormValues,
} from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgForm/model';
import { ControllingOrgValidationSchema } from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgForm/ValidationsSchema';
import { ControllingOrgForm } from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgForm/ControllingOrgForm';

export interface IControllingOrgFormWrapperProps {
    onSubmit: (values: IControllingOrgFormValues) => void;
    onCancel?: () => void;
    submitTitle: string;
    isLoading: boolean;
    controllingOrg?: IControllingOrg;
}

const ControllingOrgFormWrapper = ({
    onSubmit,
    onCancel,
    submitTitle,
    isLoading,
    controllingOrg,
}: IControllingOrgFormWrapperProps) => {

    const initialFormValues = {
        name: controllingOrg?.name || defaultControllingOrgFormValues.name,
        b_number: controllingOrg?.b_number || defaultControllingOrgFormValues.b_number,
        address: controllingOrg?.address || defaultControllingOrgFormValues.address,
        city: controllingOrg?.city || defaultControllingOrgFormValues.city,
        state: controllingOrg?.state || defaultControllingOrgFormValues.state,
        zip_code: controllingOrg?.zip || defaultControllingOrgFormValues.zip_code,
        county: '',
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={ControllingOrgValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <ControllingOrgForm
                    {...props}
                    isLoading={isLoading}
                    onCancel={onCancel}
                    submitTitle={submitTitle}
                />
            )}
        </Formik>
    );
};

export default ControllingOrgFormWrapper;
