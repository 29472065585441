import { combineReducers } from 'redux';
import { editSubmittingOrg } from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/reducer';
import { submittingOrgFilter } from 'modules/settings/submodules/clients/submittingOrg/components/Filter/store/reducer';
import { isSubmittingOrgStatusUpdating } from 'modules/settings/submodules/clients/submittingOrg/components/UpdateStatusSelect/store/reducer';
import { createSubmittingOrg } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/reducer';

export const submittingOrg = combineReducers({
    editSubmittingOrg,
    submittingOrgFilter,
    isSubmittingOrgStatusUpdating,
    createSubmittingOrg,
});
