import React from 'react';
import { Box } from '@material-ui/core';
import { PayrollBatchButton } from 'modules/payrollProcessorHub/components/PayrollAction/PayrollBatchButton';
import { useGroupedSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/styles';
import { EditGroupButton } from 'modules/payrollProcessorHub/components/PayrollAction/EditGroupButton';
import { UnlockGroupButton } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/UnlockGroupButton';
import { ICalculationGroupSidebarActionsProps } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/ICalculationGroupSidebarActionsProps';

export const CalcGroupApprovedActions = ({ calculationGroup }: ICalculationGroupSidebarActionsProps) => {
    const classes = useGroupedSheetDetailStyles();
    const hideEditOrUnlock = (
        calculationGroup.expense_calculations.length === 0
        && calculationGroup.time_calculations.every(item => item.is_holiday)
    );

    return (
        <Box className={classes.actions}>
            {!hideEditOrUnlock && (
                <>
                    <EditGroupButton calculationGroup={calculationGroup}/>
                    <UnlockGroupButton calculationGroup={calculationGroup}/>
                </>
            )}
            <PayrollBatchButton calculationGroups={[calculationGroup]}/>
        </Box>
    );
};
