import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from '../formFields/models';
import { IFormSelect } from './model';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { useSelector } from 'react-redux';
import {
    selectDepartmentsIsLoading,
    selectDepartmentsSortedByName,
} from 'modules/employmentInfo/store/department/selectors';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';

interface IDepartmentProps extends IFormFieldProps, IFormSelect {

}
interface IDepartmentStoreProps {
    departments?: IDepartment[];
    isLoading?: boolean;
}

export const DepartmentPure = ({ departments, ...props }: IDepartmentProps & IDepartmentStoreProps) => {
    return (
        <FormSelect
            getKey={(department: IDepartment) => department.id}
            getText={(department: IDepartment) => department.name}
            options={departments || []}
            {...props}
        />
    );
};

export const Department = separateLogicDecorator<IDepartmentProps, IDepartmentStoreProps>(() => ({
    departments: useSelector(selectDepartmentsSortedByName),
    isLoading: useSelector(selectDepartmentsIsLoading),
}))(DepartmentPure);
