import { PositionWithPayRateCell } from 'modules/eafApproval/components/cells/common/PositionWithPayRateCell';
import {
    EafType,
    IEafCompensationChangeData,
    IEafPromotionDemotion,
} from 'modules/employeeActionsForm/store/models';
import React from 'react';
import { Box } from '@material-ui/core';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import PlainText from 'shared/components/table/Cells/PlainText';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';

export const EafApprovalGridCellOriginalValue: React.FC<EafApprovalCellProps> = ({
    className,
    employee_action_form_data: data,
}: EafApprovalCellProps) => {
    if (data.employee_action_form_type === EafType.CompensationChange) {
        const { old_base_pay_rate } = data as IEafCompensationChangeData;
        return (
            <PlainText
                value={formatDollars(old_base_pay_rate)}
                className={className}
            />
        );
    }

    if (
        data.employee_action_form_type === EafType.Promotion
        || data.employee_action_form_type === EafType.Demotion
    ) {
        const { old_position_custom_field_value_id, old_base_pay_rate } = data as IEafPromotionDemotion;
        return (
            <PositionWithPayRateCell
                payRate={old_base_pay_rate}
                positionCustomFieldValueId={old_position_custom_field_value_id}
                className={className}
            />
        );
    }

    return (
        <Box className={className}>–</Box>
    );
};
