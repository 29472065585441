export interface IOfferLetterTemplateFormValues {
    name: string;
    filename: string;
    client_ids: Array<string>;
    all_clients: boolean,
}

export const defaultOfferLetterTemplateFormValues: IOfferLetterTemplateFormValues = {
    name: '',
    filename: '',
    client_ids: [],
    all_clients: true,
};
