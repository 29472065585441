import { Box, Grid } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import React, { useEffect } from 'react';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import FormRadioButtonGroup from 'shared/components/selects/FormRadioButtonGroup';
import { FeeType, FeeTypeDisplayValues } from 'shared/models/JobNumber';
import NumberField from 'shared/components/formFields/NumberField';

interface IDailyBillingInfoFormSection {
    feeType?: FeeType;
    path: string;
    titleElement: JSX.Element;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const DailyBillingInfoFormSection = ({
    feeType,
    path,
    titleElement,
    setFieldValue,
}: IDailyBillingInfoFormSection) => {
    const classes = useSettingsFormStyles();
    useEffect(() => {
        if (!feeType) {
            setFieldValue('billingInfo', FeeType.Daily);
        }
    }, [feeType, setFieldValue]);
    return (
        <Box className={classes.formBlockCard} style={{ minHeight: 208 }}>
            {titleElement}
            <Box m={2} ml={0}>
                <EnumFormSelect
                    name="billingInfo"
                    values={FeeType}
                    className={classes.smallInput}
                    displayValues={FeeTypeDisplayValues}
                    InputComponent={FormRadioButtonGroup}
                />
            </Box>
            <Grid container spacing={2}>
                {feeType === FeeType.Blended ? (
                    <>
                        <Grid item xs={6}>
                            <NumberField
                                name="blendedAmount"
                                outerLabel="$ amount"
                                className={classes.smallInput}
                                inputProps={{
                                    allowNegative: false,
                                }}
                                min={0}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={4}>
                            <NumberField
                                name="max"
                                outerLabel="Max"
                                className={classes.smallInput}
                                inputProps={{
                                    allowNegative: false,
                                }}
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberField
                                name="min"
                                outerLabel="Min"
                                className={classes.smallInput}
                                inputProps={{
                                    allowNegative: false,
                                }}
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberField
                                name="miCredit"
                                outerLabel="MICredit"
                                className={classes.smallInput}
                                inputProps={{
                                    allowNegative: false,
                                }}
                                min={0}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <NumberField
                        name={`${path}PerDiemRate`}
                        outerLabel="Per diem overtime rate"
                        className={classes.smallInput}
                        inputProps={{
                            allowNegative: false,
                        }}
                        min={0}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
