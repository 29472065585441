import { setEditSubassignmentId } from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import { editSubAssignmentAction } from 'modules/subassignmentManagement/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const editIdReducer = singleValueReducer(setEditSubassignmentId.action, null);

export const editSingleSubassignment = combineReducers({
    editId: editIdReducer,
    isLoading: isLoadingReducer(editSubAssignmentAction),
});
