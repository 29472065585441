import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IAttachmentsPreviewProps } from 'shared/components/attachments/EntryAttachmentsPreview';
import { AttachmentPreviewBase } from 'shared/components/attachments/AttachmentPreviewBase';
import { useUploadStyles } from './styles';
import clsx from 'clsx';

export const AttachmentsPreviewSecure = ({
    open,
    onClose,
    onDelete,
    files,
}: IAttachmentsPreviewProps) => {
    const classes = useUploadStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [maxSteps, setMaxStep] = React.useState(files.length);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep >= maxSteps - 1 ? 0 : prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1);
    };

    const onFileDelete = useMemo(() => {
        if (!onDelete) {
            return undefined;
        }
        return () => {
            files.length === 1 && onClose();
            onDelete(files[activeStep]);
            activeStep === (maxSteps - 1) && setActiveStep(activeStep - 1);
            setMaxStep(files.length - 1);
        };
    }, [activeStep, files, maxSteps, onClose, onDelete]);

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={onClose}
                fullScreen disableBackdropClick
                classes={{ paper: classes.paper }}
            >
                <div className={classes.dialog}>
                    <div className={classes.stepContainer}>
                        <Button className = {clsx(classes.iconButton, classes.pullLeft)}
                            onClick={handleBack}
                            color="inherit"
                        >
                            <KeyboardArrowLeft className={classes.stepActions}/>
                        </Button>
                    </div>

                    <div className={classes.middleContainer}>
                        <Paper
                            square
                            elevation={0}
                            className={classes.header}
                        >
                            <Typography>
                                {activeStep + 1} / {maxSteps}
                            </Typography>
                        </Paper>
                        <AttachmentPreviewBase
                            onDelete={onFileDelete}
                            attachment={files[activeStep]}
                        />
                        <Button
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            <CloseIcon className={classes.closeAction}/>
                        </Button>
                    </div>
                    <div className={classes.stepContainer}>
                        <Button className = {clsx(classes.iconButton, classes.pullRight)}
                            onClick={handleNext}
                            color="inherit"
                        >
                            <KeyboardArrowRight className={classes.stepActions}/>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
