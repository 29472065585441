/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import {
    IPayCode,
    PayCodeClassDisplayValue,
    PayCodeTypeDisplayValue,
} from 'modules/settings/submodules/clients/payCodes/store/model';
import { selectIsPayCodesLoading, selectPayCodes } from 'modules/settings/submodules/clients/payCodes/store/selectors';

export interface IPayCodeRow extends IPayCode {
    className?: string;
}

export const PayCodesTable = () => {
    const tableClasses = useDefaultTableStyles();
    const payCodes = useSelector(selectPayCodes);
    const isLoading = useSelector(selectIsPayCodesLoading);

    const paycodeCells = [
        {
            key: 'code',
            title: 'pay code',
            render: ({ prism_paycode_id, className }: IPayCodeRow) => (
                <PlainText className={className} value={prism_paycode_id }/>
            ),
        },
        {
            key: 'description',
            title: 'description',
            render: ({ description, className }: IPayCodeRow) => (
                <PlainText className={className} value={description}/>
            ),
        },
        {
            key: 'payType',
            title: 'pay type',
            render: ({ pay_type, className }: IPayCodeRow) => (
                <PlainText className={className} value={PayCodeTypeDisplayValue[pay_type]}/>
            ),
        },
        {
            key: 'payClass',
            title: 'pay class',
            render: ({ pay_class, className }: IPayCodeRow) => (
                <PlainText className={className} value={PayCodeClassDisplayValue[pay_class]}/>
            ),
        },
    ];

    return (
        <GridTable
            rowData={payCodes}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={paycode => paycode.id}
            cells={paycodeCells}
            isLoading={isLoading}
            stickyHeader
        />
    );
};
