import React from 'react';
import { useSelector } from 'react-redux';
import { ActionPrintButton } from 'shared/components/buttons/ActionPrintButton';
import { EntryType } from 'shared/models/sheet/Sheet';
import { approvalTableSelectors } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/selectors';
import { exportCsvExpenseApproval, exportCsvTimeApproval } from 'modules/sheetApproval/store/actions';

interface IApprovalExportCsvPureProps {
    sheetType: EntryType,
}

export const ApprovalExportCsvPure = ({
    sheetType,
}: IApprovalExportCsvPureProps) => {
    const isLoading = useSelector(approvalTableSelectors.isLoading);

    return (
        <ActionPrintButton
            action={sheetType === EntryType.TIME ? exportCsvTimeApproval.init : exportCsvExpenseApproval.init }
            disabled={isLoading}
        />
    );
};
