import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useClientSubModulesDashboardMenu } from 'modules/settings/submodules/clients/utils/useClientSubModulesMenu';
import { noGeoCodeWarningText } from 'modules/settings/submodules/clients/clientDetails/NoGeocodeWarning';
import { selectCurrentClientHasNoGeocode } from 'modules/settings/submodules/clients/store/selectors';
import { SubmodulesDashboard } from 'modules/settings/common/components/SubmodulesDashboard';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';

export const ClientSettingsDashboard = () => {
    const items = useClientSubModulesDashboardMenu();
    const currentClientHasNoGeocode = useSelector(selectCurrentClientHasNoGeocode);
    return (
        <Box width="100%">
            {
                currentClientHasNoGeocode && (
                    <Box display="flex" mb={2}>
                        <WarningAlertWithIcon>
                            { noGeoCodeWarningText }
                        </WarningAlertWithIcon>
                    </Box>
                )
            }
            <SubmodulesDashboard items={items}/>
        </Box>
    );
};
