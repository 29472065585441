import { addCustomFieldsSheetInfoItems } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/addCustomFieldsSheetInfoItems';
import {
    useSheetGroupCustomFieldsWithValues,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/store/hooks';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { EntryType } from 'shared/models/sheet/Sheet';

export function useCustomFieldsItems(
    type: EntryType,
    sheetGroup: IGroupedSheetCalculation,
    sheetDetailsList: ISheetInfoItemData[],
) {
    const sheetCustomFieldsWithValues = useSheetGroupCustomFieldsWithValues(sheetGroup, type);
    return addCustomFieldsSheetInfoItems(
        sheetCustomFieldsWithValues,
        Boolean(sheetGroup.job_number_user_type_name),
        sheetDetailsList,
    );
}
