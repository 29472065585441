import { FormikProps } from 'formik';
import { CcpStatusSlug, ICcpTableFilters, invoicedCcpSlug } from '../../models/ccpModel';
import { useHeaderFormStyles } from '../../../payrollProcessorHub/components/PayrollFilter/styles';
import React, { useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'ts-debounce';
import { defaultCcpFilter } from './store/reducers';
import UserStaticSelect from 'shared/components/selects/UserStaticSelect';
import { allUserIdsAvailable } from '../CcpEdit/store/selectors';
import { useSelector } from 'react-redux';
import { shortDateFormat } from 'shared/models/Dates';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import { selectCcpTransactionsActiveTab } from '../../store/selectors';
import { selectUsersByClientRoleId } from '../../../../store/entities/users/selectors';
import {
    selectClientCreditCardPortalPageRegionalDirectorRoleId,
} from '../../../../store/entities/clients/selectors/configurationSelectors';

export interface ICcpFilterFormProps extends FormikProps<ICcpTableFilters> {
    onChange: (value: Partial<ICcpTableFilters>) => void;
    actualFilterValues: ICcpTableFilters;
    disabled: boolean,
}

export const CcpFilterForm = ({
    actualFilterValues,
    handleSubmit,
    onChange,
    values,
    disabled,
}: ICcpFilterFormProps) => {
    const classes = useHeaderFormStyles();

    const ccpUserIds = useSelector(allUserIdsAvailable);
    const regionalManagerRoleId = useSelector(selectClientCreditCardPortalPageRegionalDirectorRoleId);
    const regionalManagerUserIDsById = useSelector(selectUsersByClientRoleId);
    const regionalManagersUserIdsList = useMemo(() => {
        return regionalManagerUserIDsById[regionalManagerRoleId ?? ''];
    }, [regionalManagerUserIDsById, regionalManagerRoleId]);
    const selectedTab = useSelector(selectCcpTransactionsActiveTab);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFilterChange = useCallback(debounce(
        (newValues: ICcpTableFilters) => {
            const submitted_date = newValues.submitted_date === '' ? null : newValues.submitted_date;
            onChange({
                user_id: newValues.user_id || defaultCcpFilter.user_id,
                department_id: newValues.department_id || defaultCcpFilter.department_id,
                submitted_date: submitted_date || defaultCcpFilter.submitted_date,
                regional_manager_id: newValues.regional_manager_id || defaultCcpFilter.regional_manager_id,
            });
        },
        300,
    ), [onChange]);

    useEffect(() => {
        const submitted_date = values.submitted_date === '' ? null : values.submitted_date;
        if (values.user_id !== actualFilterValues.user_id
            || values.department_id !== actualFilterValues.department_id
            || submitted_date !== actualFilterValues.submitted_date
            || values.regional_manager_id !== actualFilterValues.regional_manager_id) {
            onFilterChange(values);
        }
    }, [actualFilterValues, values, onChange, onFilterChange]);
    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            { selectedTab !== invoicedCcpSlug && (
                <UserStaticSelect
                    name="user_id"
                    label="Area Manager"
                    useIdValue
                    className={classes.input}
                    userIds={ccpUserIds}
                    disabled={disabled}
                />
            )}
            { selectedTab !== invoicedCcpSlug && (
                <UserStaticSelect
                    name="regional_manager_id"
                    label="Regional Manager"
                    useIdValue
                    className={classes.input}
                    userIds={regionalManagersUserIdsList}
                    disabled={disabled}
                />
            )
            }
            { ((selectedTab === CcpStatusSlug.Reconciled)
            || (selectedTab === invoicedCcpSlug)) && (
                <DayPickerField
                    name={'submitted_date'}
                    format={shortDateFormat}
                    placeholder={shortDateFormat}
                    variant="inline"
                    disabled={disabled}
                    withKeyboard={true}
                />
            )
            }
        </form>
    );
};
