import {
    IPostPayroll,
    IPostPayrollResponse,
    IPrePayroll,
    IPrePayrollResponse,
    ISheetsForPrePayroll,
    ISheetsForPrePayrollResponse,
} from './model';

const payrollSheetMapper = (sheet: ISheetsForPrePayrollResponse): ISheetsForPrePayroll => ({
    clientId: sheet.client_id,
    employeeUserId: sheet.employee_user_id,
    expenseSheetId: sheet.expense_sheet_id,
    expenseTotalDollars: sheet.expense_total_dollars,
    expenseTotalMiles: sheet.expense_total_miles,
    payPeriod: sheet.pay_period,
    positionId: sheet.position_id,
    timeDtDollars: sheet.time_dt_dollars,
    timeDtHours: sheet.time_dt_hours,
    timeOtDollars: sheet.time_ot_dollars,
    timeOtHours: sheet.time_ot_hours,
    timeRtDollars: sheet.time_rt_dollars,
    timeRtHours: sheet.time_rt_hours,
    timeFiles: sheet.time_files,
    timeFilesDollars: sheet.time_files_dollars,
    timeSheetId: sheet.time_sheet_id,
    totalDollars: sheet.total_dollars,
    timeSalariedHours: sheet.time_salaried_hours,
    timeSalariedDollars: sheet.time_salaried_dollars,
    deductPeriod: sheet.deduct_period,
    timeHolidayHours: sheet.time_holiday_hours,
    timeHolidayDollars: sheet.time_holiday_dollars,
    failedReason: sheet.failed_reason,
});

const payrollBaseResponseMapper = (response: IPrePayrollResponse) => ({
    expenseTotalDollars: response.expense_total_dollars,
    expenseTotalMiles: response.expense_total_miles,
    numberOfBatches: `${response.number_of_batches}`,
    numberOfEmployees: `${response.number_of_employees}`,
    numberOfEntries: `${response.number_of_entries}`,
    numberOfPayrollRecords: `${response.number_of_payroll_records}`,
    timeHolidayDollars: response.time_holiday_dollars,
    timeHolidayHours: response.time_holiday_hours,
    timeDtDollars: response.time_dt_dollars,
    timeDtHours: response.time_dt_hours,
    timeOtDollars: response.time_ot_dollars,
    timeOtHours: response.time_ot_hours,
    timeRtDollars: response.time_rt_dollars,
    timeRtHours: response.time_rt_hours,
    timeFiles: response.time_files,
    timeFilesDollars: response.time_files_dollars,
    totalDollars: response.total_dollars,
    timeSalariedHours: response.time_salaried_hours,
    timeSalariedDollars: response.time_salaried_dollars,
    numberOfBatchesFailed: `${response.number_of_batches_failed}`,
    numberOfPayrollRecordsFailed: `${response.number_of_payroll_records_failed}`,
    numberOfEmployeesFailed: `${response.number_of_employees_failed}`,
    numberOfEntriesFailed: `${response.number_of_entries_failed}`,
});

export const prePayrollResponseMapper = (response: IPrePayrollResponse): IPrePayroll => ({
    ...payrollBaseResponseMapper(response),
    sheetsForPayroll: response.sheets_for_payroll.map(payrollSheetMapper),
});

export const postPayrollResponseMapper = (response: IPostPayrollResponse): IPostPayroll => ({
    ...payrollBaseResponseMapper(response),
    sheetsForPayroll: response.sheets_for_payroll.map(payrollSheetMapper),
});
