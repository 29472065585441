import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { Box } from '@material-ui/core';
import { IGridTableRowProps } from 'shared/components/table/GridTable/GridTableRow';
import { VirtualizedGridTableRow } from 'shared/components/table/VirtualizedGridTable/VirtualizedGridTableRow';
import { debounce } from 'ts-debounce';
import clsx, { ClassValue } from 'clsx';
import InfiniteLoader from 'react-window-infinite-loader';
import GridHeader from 'shared/components/table/GridTable/GridHeader';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { ITableContainerCssProps, useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';
import { useVirtualTableRowsHelper } from 'shared/components/table/VirtualizedGridTable/useVirtualTableRowsHelper';

export interface IVirtualGridTableProps<RowData> {
    rowData: RowData[];
    cells: Array<ICellInfo<RowData>>;

    hasMore: boolean;
    totalCount: number;
    onLoadMore: () => void;
    isLoading?: boolean;

    getKey: (row: RowData) => string;
    getRowHeightByIndex?: (index: number) => number;

    className?: string;

    hideHeader?: boolean;
    headerHeight?: number;
    loadersOffset?: number; // Count of skeleton rows
    calcRowWidth?: (cells: Array<ICellInfo<RowData>>) => string;

    headingRowClassName?: string;
    headerCellClassName?: string;
    bodyRowClassName?: string;
    bodyCellClassName?: string;
    getRowClasses?: (row: RowData) => ClassValue[];

    RenderRowComponent?: (props: IGridTableRowProps<RowData>) => React.ReactElement;
}

const defaultGetRowHeightByIndex = () => 40;

export function VirtualInfinityGridTable<RowData>({
    onLoadMore,
    hasMore,
    rowData,
    cells,
    calcRowWidth,
    getKey,
    hideHeader,
    className,
    totalCount,
    isLoading,
    headingRowClassName,
    headerCellClassName,
    bodyRowClassName,
    bodyCellClassName,
    headerHeight = 40,
    getRowHeightByIndex = defaultGetRowHeightByIndex,
    loadersOffset = 3,
    getRowClasses,
    RenderRowComponent,
}: IVirtualGridTableProps<RowData>) {
    const {
        getRowByIndex,
        dataLength,
        getIsRowLoaded,
    } = useVirtualTableRowsHelper(rowData);

    const infiniteLoaderRef = useRef(null);
    const tableRowsCount = (hasMore || isLoading) ? dataLength + loadersOffset : dataLength;

    const classesProps: ITableContainerCssProps = useMemo(() => ({
        gridTemplateColumns: cells.map(cell => cell.width || '1fr').join(' '),
        cellAmount: cells.length,
        rowAmount: rowData.length,
        rowWidth: calcRowWidth ? calcRowWidth(cells) : undefined,
    }), [cells, rowData, calcRowWidth]);
    const classes = useGridTableStyles(classesProps);

    const RowsWithHeader = useMemo(() => {
        return forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
            // eslint-disable-next-line react/prop-types
            function Inner({ children, style, ...rest }, ref) {
                return (
                    <div
                        {...rest}
                        ref={ref}
                        style={{
                            ...style,
                            // @ts-ignore
                            // eslint-disable-next-line react/prop-types
                            height: `${parseFloat(style.height) + headerHeight}px`,
                        }}
                    >
                        {!hideHeader && (
                            <GridHeader
                                cells={cells}
                                classes={classes}
                                rowsData={rowData}
                                stickyHeader
                                customHeaderRowClassName={headingRowClassName}
                                headerCellClassName={headerCellClassName}
                            />
                        )}
                        {children}
                    </div>
                );
            },
        );
    }, [cells, classes, headerCellClassName, headerHeight, headingRowClassName, hideHeader, rowData]);

    const tableClasses = clsx(className, classes.tableContainer, classes.virtualTableContainer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadMoreItems = useCallback(debounce(() => {
        onLoadMore();
    }, 500), [onLoadMore]);

    return (
        <div
            className={tableClasses}
            role="table"
            data-test="table"
        >
            <Box
                display="flex"
                flexGrow={1}
                data-testid="list-container"
                flexDirection="column"
                height="100%"
                width="100%"
            >
                <AutoSizer disableWidth>
                    {({ height }) => (
                        <InfiniteLoader
                            ref={infiniteLoaderRef}
                            isItemLoaded={getIsRowLoaded}
                            itemCount={totalCount}
                            loadMoreItems={loadMoreItems}
                            minimumBatchSize={20}
                            threshold={5}
                        >
                            {({ onItemsRendered, ref }) => (
                                <VariableSizeList
                                    innerElementType={RowsWithHeader}
                                    height={height}
                                    itemCount={tableRowsCount}
                                    itemSize={getRowHeightByIndex}
                                    ref={ref}
                                    onItemsRendered={onItemsRendered}
                                    width="100%"
                                    overscanCount={5}
                                >
                                    {({ index, style }) => (
                                        <VirtualizedGridTableRow
                                            index={index}
                                            style={style}
                                            classesProps={classesProps}
                                            getRowByIndex={getRowByIndex}
                                            getRowId={getKey}
                                            headerHeight={headerHeight}
                                            bodyRowClassName={bodyRowClassName}
                                            bodyCellClassName={bodyCellClassName}
                                            cells={cells}
                                            getRowClasses={getRowClasses}
                                            RenderRowComponent={RenderRowComponent}
                                        />
                                    )}
                                </VariableSizeList>
                            )}
                        </InfiniteLoader>
                    )}
                </AutoSizer>
            </Box>
        </div>
    );
}
