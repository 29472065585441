import React, { useCallback, useEffect, useState } from 'react';
import { IntervalInputProps } from '../intervalField/IntervalDataField';
import {
    Time12HoursKeyboardPicker,
    Time12HoursKeyboardPickerStyles,
} from '../../inputs/Time12HoursKeyboardInput/Time12HoursKeyboardPicker';
import InOutTimeFieldInput from './InOutTimeFieldInput';

interface IInOutTimeFieldPickerClasses {
    picker: Time12HoursKeyboardPickerStyles
}

interface IInOutTimeFieldPickerProps {
    classes?: Partial<IInOutTimeFieldPickerClasses>;
    keepIcon?: boolean;
}

function InOutTimeFieldPickerPure({
    label,
    name,
    value,
    className,
    helpers,
    meta,
    inputProps,
    onChange,
    classes,
    disabled = false,
    keepIcon = false,
    onFocus,
    onBlur,
}: IntervalInputProps<string> & IInOutTimeFieldPickerProps) {
    const { setTouched } = helpers || {};
    const { touched, error, initialValue } = meta || {};

    // Hack to prevent showing browser placeholders & icons before entering
    const [isFocussed, setFocussed] = useState(Boolean(initialValue || value));
    const [localValue, setValue] = useState(initialValue || value);

    // Hack! Making delay provide availability to click on input adornment
    const blurOnEmptyValue = useCallback((currentVal: string) => {
        !currentVal && setTimeout(() => {
            setFocussed(false);
        }, 200);
    }, [setFocussed]);

    useEffect(() => {
        const newValue = !value ? '' : value;
        setValue(newValue);
        blurOnEmptyValue(newValue as string);
    }, [value, blurOnEmptyValue]);

    const focusHandler = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        setFocussed(true);
        onFocus && onFocus(event);
    }, [onFocus]);

    const blurHandler = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (setTouched) {
            setTouched(true);
        }
        blurOnEmptyValue(localValue as string);
        onBlur && onBlur(event);
    }, [setTouched, localValue, onBlur, blurOnEmptyValue]);

    const changeHandler = useCallback((changedValue: string) => {
        setValue(changedValue);
        // Set focussed state after selection time with wizard
        changedValue && setFocussed(true);
        if (onChange) {
            onChange(changedValue);
        }
    }, [onChange]);
    const errorMessage = typeof error === 'string' && touched ? error : undefined;

    return (
        <Time12HoursKeyboardPicker
            name={name}
            label={label}
            disabled={disabled}
            className={className}
            inputProps={inputProps}
            value={localValue ? `${localValue}` : null}
            InputFieldComponent={InOutTimeFieldInput}
            isFocussed={isFocussed}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            onChange={changeHandler}
            onBlur={blurHandler as () => void}
            onFocus={focusHandler as () => void}
            keepIcon={keepIcon}
            classes={{ iconButton: classes?.picker?.iconButton }}
        />
    );
}

export function inOutWithPredefinedPropsComponent(classes?: Partial<IInOutTimeFieldPickerClasses>, keepIcon?: boolean) {
    function InOutTimeFieldPickerClassed(props: IntervalInputProps<string>) {
        return (
            <InOutTimeFieldPickerPure classes={classes} keepIcon={keepIcon}
                {...props}/>
        );
    }
    return InOutTimeFieldPickerClassed;
}

export const InOutTimeFieldPicker = inOutWithPredefinedPropsComponent();
