import React, { useCallback } from 'react';
import { EafApprovalCell } from 'modules/eafApproval/store/model/types';
import { IEmployeeActionForm } from 'modules/employeeActionsForm/store/models';
import { loadMoreEafApproval } from 'modules/eafApproval/store/actions';
import {
    eafTableSelectors,
    selectEafApprovalActiveTab,
    selectEafApprovalItemsByTabFiltered,
} from 'modules/eafApproval/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { IStore } from 'store/configureStore';

interface IEafApprovalGridProps {
    cells: EafApprovalCell[];
    rowsSelector?: (state: IStore) => IEmployeeActionForm[];
    bodyRowClassName?: string;
}

export const EafApprovalGrid = ({
    cells,
    rowsSelector = selectEafApprovalItemsByTabFiltered,
    bodyRowClassName,
}: IEafApprovalGridProps) => {
    const dispatch = useDispatch();
    const tableClasses = useDefaultTableStyles();
    const rowData = useSelector(rowsSelector);
    const activeTab = useSelector(selectEafApprovalActiveTab);
    const hasMore = useSelector(eafTableSelectors.selectHasMore);
    const isLoading = useSelector(eafTableSelectors.isLoading);

    const handleLoadMore = useCallback(() => dispatch(loadMoreEafApproval.init()), [dispatch]);

    return (
        <InfinityGridTable
            key={activeTab}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            bodyRowClassName={bodyRowClassName}
            rowData={rowData}
            getKey={item => item.id}
            cells={cells}
            onLoadMore={handleLoadMore}
            isLoading={isLoading}
            hasMore={hasMore}
        />
    );
};
