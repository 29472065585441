import { call, put, takeLatest } from 'typed-redux-saga';
import { getSubmittingOrgs } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { setSubmittingOrgStatus } from 'modules/settings/submodules/clients/submittingOrg/components/UpdateStatusSelect/store/actions';
import { api } from 'modules/settings/submodules/clients/submittingOrg/components/UpdateStatusSelect/store/api';
import { Status } from 'shared/models/Status';

function* updateSubmittingOrgStatusSaga(action: ReturnType<typeof setSubmittingOrgStatus.init>) {
    const { id, status } = action.payload;
    const isActive = status !== Status.inactive;
    const result = yield* call(api.updateSubmittingOrgStatus, id, isActive);
    yield put(setSubmittingOrgStatus.success(result));
    yield put(getSubmittingOrgs.success([result]));
}

export function* updateSubmittingOrgStatusWatcher() {
    yield* takeLatest(
        setSubmittingOrgStatus.initType,
        withBackendErrorHandler(
            updateSubmittingOrgStatusSaga,
            setSubmittingOrgStatus.error,
            'Unable to update Submitting Org Status.',
        ),
    );
}
