import {
    EafApprovalStatusDisplayName,
    ALL_EAF_STATUSES_SLUG,
    EAF_APPROVAL_GRID_PAGE_SIZE,
} from 'modules/eafApproval/store/model/data';
import { IEafApprovalFilter } from 'modules/eafApproval/store/model/types';
import { EafStatusSlug, IEmployeeActionForm } from 'modules/employeeActionsForm/store/models';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { IInfinityScrollState } from 'store/reducerUtils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';

const selectEafApprovalState = (state: IStore) => state.modules.eafApproval;

type EafApprovalState = ReturnType<typeof selectEafApprovalState>;

export const selectEafApprovalActiveTab = createSelector(
    selectEafApprovalState,
    function eafApprovalActiveTabSelector(state: EafApprovalState): EafStatusSlug | typeof ALL_EAF_STATUSES_SLUG {
        return state.eafApprovalActiveTab;
    },
);

export const selectEafApprovalActiveTabDisplay = createSelector(
    selectEafApprovalActiveTab,
    function (activeTab: EafStatusSlug | typeof ALL_EAF_STATUSES_SLUG): string {
        return EafApprovalStatusDisplayName[activeTab];
    },
);

export const selectEafApprovalCheckedItemsIds = createSelector(
    selectEafApprovalState,
    function (state: EafApprovalState): string[] {
        return state.eafApprovalCheckedItemsIds;
    },
);

export const selectShowBulkApproveRejectButtons = createSelector(
    selectEafApprovalCheckedItemsIds,
    function (ids: Array<string>): boolean {
        return ids?.length > 0;
    },
);

export const selectEafApprovalGridFilter = createSelector(
    selectEafApprovalState,
    state => state.filter,
);

export const selectEafApprovalGridOrder = createSelector(
    selectEafApprovalState,
    state => state.order,
);

export const selectEafTableState = createSelector(
    selectEafApprovalState,
    function infinityScrollStateSelector(state: EafApprovalState): IInfinityScrollState<IEmployeeActionForm> {
        return state.table;
    },
);

export const eafTableSelectors = getInfinityScrollSelectors(
    selectEafTableState,
    EAF_APPROVAL_GRID_PAGE_SIZE,
    true,
);

export const selectEafApprovalItemsByTab = createSelector(
    eafTableSelectors.selectItems,
    selectEafApprovalActiveTab,
    function (items: Array<IEmployeeActionForm>, activeTab: EafStatusSlug | 'all'): Array<IEmployeeActionForm> {
        if (activeTab === 'all') {
            return items;
        }
        return items.filter(item => item.status?.slug === activeTab);
    },
);

export const selectEafApprovalItemsByTabFiltered = createSelector(
    selectEafApprovalItemsByTab,
    selectEafApprovalGridFilter,
    function (items: Array<IEmployeeActionForm>, filter: IEafApprovalFilter): Array<IEmployeeActionForm> {
        let result: Array<IEmployeeActionForm> = items;

        if (filter.type) {
            result = result.filter(item => item.employee_action_form_data?.employee_action_form_type === filter.type);
        }

        if (filter.employeeUserId) {
            result = result.filter(item => item.employee_user_id === filter.employeeUserId);
        }

        return result;
    },
);

export const selectApproveEafIds = createSelector(
    selectEafApprovalState,
    state => state.approveIds,
);

export const selectRejectionEafIds = createSelector(
    selectEafApprovalState,
    state => state.rejectionIds,
);

export const selectDetailEaf = createSelector(
    selectEafApprovalState,
    eafTableSelectors.selectItems,
    (state, eafs) => eafs.find(eaf => eaf.id === state.detailEafId),
);

export const selectEafById = (id: string) => (state: IStore) => {
    const eafs = eafTableSelectors.selectItems(state);
    return eafs.find(eaf => eaf.id === id);
};

export const selectIsLoadingEafDetails = (state: IStore) => selectEafApprovalState(state).isLoadingDetails;

export const selectEafPendingItemsCount = createSelector(
    selectEafApprovalState,
    state => state.pendingCount,
);
