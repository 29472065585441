import { ISyncEmployeePrismProfile } from 'modules/settings/submodules/employees/store/model';
import baseApi from 'shared/utils/baseApi';
import { IClient } from 'store/entities/clients/clientsModel';

export const employeesApi = {
    async getEmployeeClients(userId: string): Promise<IClient[]> {
        const { data } = await baseApi.get<{ clients: IClient[] }>(
            `/client-users/employee/${userId}/clients`,
        );
        return data.clients;
    },
    async syncEmployeeProfile(userId: string, request: ISyncEmployeePrismProfile): Promise<string> {
        const { data } = await baseApi.post<ISyncEmployeePrismProfile, { status: string }>(
            `/client-users/employee/${userId}/sync_with_prism`, request,
        );
        return data.status;
    },
};
