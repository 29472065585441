import {
    IEafApprovalItemsRequest,
    IEafApprovalItemsResponse,
    IPatchEmployeeActionFormRequest, IPatchEmployeeActionFormsResponse,
} from 'modules/eafApproval/store/model/types';
import { HeadwayServices } from 'shared/models/Services';
import baseApi from 'shared/utils/baseApi';

class EafApprovalApi {
    private static EmployeeActionFormsRoute = 'employee_action_forms';

    public async getEafApprovalItems(request: IEafApprovalItemsRequest): Promise<IEafApprovalItemsResponse> {
        const url = `${HeadwayServices.ClientUsers}/${EafApprovalApi.EmployeeActionFormsRoute}/search`;
        const response = await baseApi.post<IEafApprovalItemsRequest, IEafApprovalItemsResponse>(
            url,
            request,
        );
        return response.data;
    }

    public async patchEmployeeActionForms(
        request: IPatchEmployeeActionFormRequest,
    ): Promise<IPatchEmployeeActionFormsResponse> {
        const url = `${HeadwayServices.ClientUsers}/${EafApprovalApi.EmployeeActionFormsRoute}`;
        const response = await baseApi.patch<IPatchEmployeeActionFormRequest, IPatchEmployeeActionFormsResponse>(
            url,
            request,
        );

        return response.data;
    }

}

export const eafApprovalApi = new EafApprovalApi();
