import { Box } from '@material-ui/core';
import React from 'react';
import { CreateTravelExpenseButton } from 'modules/travelExpenses/components/Create/CreateTravelExpenseButton/CreateTravelExpenseButton';

export const TravelExpensesHeader = () => {
    return (
        <Box display="flex"
            alignItems="flex-end"
            flexDirection="row-reverse"
            mr={2}>
            <CreateTravelExpenseButton/>
        </Box>
    );
};
