import React from 'react';
import {
    Box,
    Button, Card, CardActions,
    CardContent, Typography, withStyles, WithStyles,
} from '@material-ui/core';
import AuthWrapper from 'shared/components/auth/AuthWrapper';
import { Link } from 'react-router-dom';

const styles = () => ({
    noUnderline: {
        textDecoration: 'none',
    },
});

interface IPdfFileError extends WithStyles<typeof styles> {
    title: string;
    message: string;
    link: string;
    linkLabel: string;
    simple?: boolean;
    className?: string;
}

function PdfFileError({
    title,
    message,
    link,
    linkLabel,
    simple = false,
    classes,
    className,
}: IPdfFileError) {
    if (simple) {
        return (
            <Box className={className}>
                <Typography gutterBottom variant="subtitle1">{title}</Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                    component="div"
                >
                    {message}
                </Typography>
                <Link to={link} className={classes.noUnderline}>
                    <Button size="small" color="primary">{linkLabel}</Button>
                </Link>
            </Box>
        );
    }
    return (
        <AuthWrapper>
            <Card>
                <CardContent>
                    <Typography gutterBottom variant="subtitle1">{title}</Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                        component="div"
                    >
                        {message}
                    </Typography>
                    <CardActions disableSpacing>
                        <Link to={link} className={classes.noUnderline}>
                            <Button size="small" color="primary">{linkLabel}</Button>
                        </Link>
                    </CardActions>
                </CardContent>
            </Card>
        </AuthWrapper>
    );
}

export default React.memo(withStyles(styles)(PdfFileError));
