import { IOfferLetterRow } from 'modules/offerLetter/components/OfferLetterTable/cells/cells';
import { offerLettersTableStateSelectors, selectOfferLetterPdfIsAvailable, selectOfferLetterPdfIsLoading, selectOfferLetters } from 'modules/offerLetter/store/selectors';
import { createSelector } from 'reselect';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IPayRange } from 'store/entities/configuration/configurationModel';
import { selectPayRangesByIds } from 'store/entities/configuration/configurationSelectors';
import { selectCustomFieldValuesByIds, selectOrderedOfferLetterCustomFields } from 'store/entities/customFields/selectors';

export const selectOfferLetterRows = createSelector(
    offerLettersTableStateSelectors.selectItems,
    selectOfferLetters,
    selectCurrentUser,
    selectOfferLetterPdfIsLoading,
    selectOfferLetterPdfIsAvailable,
    selectOrderedOfferLetterCustomFields,
    selectCustomFieldValuesByIds,
    selectPayRangesByIds,
    (
        itemIds,
        offerLettersById,
        currentUser,
        isPdfCheckingById,
        isPdfAvailableById,
        offerCustomFields,
        customFieldValuesByIds,
        payRangesByIds: Record<string, IPayRange>,
    ): IOfferLetterRow[] => {
        return itemIds.map(id => {
            const offerLetter = offerLettersById[id];
            let payRange: undefined | [number, number];
            const payRangeItem = payRangesByIds[offerLetter?.pay_range_id || ''];
            if (payRangeItem) {
                payRange = [payRangeItem.min_value, payRangeItem.max_value];
            }

            return {
                id,
                offerLetter,
                isPdfLoading: isPdfCheckingById[id],
                isPdfAvailable: isPdfAvailableById[id],
                positionDisplayValue: offerLetter.position?.name
                    || customFieldValuesByIds[offerLetter.position_value_id]?.data?.name,
                locationDisplayValue: offerLetter.location?.name
                    || customFieldValuesByIds[offerLetter.location_value_id]?.data?.name,
                departmentDisplayValue: offerLetter.department?.name
                    || customFieldValuesByIds[offerLetter.department_value_id]?.data?.name,
                payRange,
            } as IOfferLetterRow;
        }).filter(row => {
            return row?.offerLetter && row.offerLetter.email.toLowerCase() !== (currentUser?.email ?? '').toLowerCase();
        });
    },
);
