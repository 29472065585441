import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { OfferLetterPayRanges } from 'modules/offerLetter/components/OfferLetterForm/components/OfferLetterPayRanges/OfferLetterPayRanges';
import { PayTypeSelect } from 'modules/offerLetter/components/OfferLetterForm/steps/PayTypeSelect';
import {
    selectOfferLetterFormFieldsMapping,
    selectPayRateTypeByPaySettings,
} from 'modules/offerLetter/store/selectors';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import FormField from 'shared/components/formFields/FormField';
import { MultiStepFormValues } from 'shared/components/forms/multiStep/MultiStepForm';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { EmployeeType } from 'shared/components/selects/EmployeeType';
import { monthTextDateFormat } from 'shared/models/Dates';
import { PayRateType, PayType } from 'shared/models/PayType';
import { useIsPayRangesAppliedForClient } from 'store/entities/clients/hooks';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { HierarchyType } from 'store/entities/customFields/model';
import { selectOfferLetterHierarchy } from 'store/entities/customFields/selectors';
import { getUserCustomFieldRestrictions } from 'store/entities/scopes/actions';
import { ScopeAction, ScopeType } from 'store/entities/scopes/models';
import { OfferLetterTemplateSelect } from '../../CreateOffer/components/OfferLetterTemplateSelect';
import { OfferLetterPayRateField } from '../components/OfferLetterPayRateField';
import { defaultOfferLetterValues, IOfferLetterFormValues } from '../model';
import { useStyles } from '../styles';
import {
    selectClientTimeAndPayConfiguration,
} from 'store/entities/clients/selectors/timeAndPaySelectors';

export function OfferLetterOfferForm({
    values,
    setFieldValue,
}: FormikProps<MultiStepFormValues<IOfferLetterFormValues>>) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const { position, positionValueId, locationId, payRate, payType } = values;
    const hierarchy = useSelector(selectOfferLetterHierarchy);
    const clientPayRateType = useSelector(selectPayRateTypeByPaySettings);
    const customFieldMapping = useSelector(selectOfferLetterFormFieldsMapping);
    const customFieldValues = Object.keys(customFieldMapping).reduce((mem, key) => ({
        ...mem,
        [key]: values[customFieldMapping[key]],
    }), {});
    const appSettings = useSelector(selectClientTimeAndPayConfiguration);
    const isExternalEmployeeIdEnabled = useMemo(() =>
        appSettings?.enableOfferLetterEmployeeNumber ?? false, [appSettings]);

    const payRangesEnabled = useIsPayRangesAppliedForClient();

    useEffect(() => {
        if (clientId) {
            const scopesRequestData = {
                client_ids: [clientId],
                scope_types: [ScopeType.SubassignmentManagement],
            };
            dispatch(getUserCustomFieldRestrictions.init(scopesRequestData));
        }
    }, [clientId, dispatch]);

    useEffect(() => {
        if (position) {
            setFieldValue('payRate', payRate || position.pay_rate_value || defaultOfferLetterValues.payRate);
            setFieldValue('payRateType', position.pay_rate_type || defaultOfferLetterValues.payRateType);
        }
        if (locationId) {
            setFieldValue('locationId', locationId || defaultOfferLetterValues.locationId);
        }
        if (payType === PayType.Hourly) {
            setFieldValue('payRateType', PayRateType.PER_HOUR);
        }
        if (payType === PayType.Salaried) {
            setFieldValue('payRateType', clientPayRateType);
        }
    }, [position, locationId, setFieldValue, payRate, payType, clientPayRateType]);

    return (
        <>
            {isExternalEmployeeIdEnabled && (
                <FormField
                    name="employee_number"
                    label="External Employee ID"
                    className={classes.field}
                />
            )}
            {hierarchy.map(node => (
                <CustomFieldValuesSelect
                    key={node.custom_field_id}
                    customFieldId={node.custom_field_id}
                    name={customFieldMapping[node.custom_field_id]}
                    className={classes.field}
                    useIdValue
                    useLabel
                    hierarchy={hierarchy}
                    customFieldFormValues={customFieldValues}
                    hierarchyType={HierarchyType.OfferLetter}
                    scopeActionFilter={ScopeAction.OfferLetters}
                />
            ))}

            <Box display="flex">
                <EmployeeType
                    name="employeeType"
                    label="Employee Type"
                    className={clsx(classes.field, classes.firstInputBlock)}
                    useIdValue
                />
                <FormField
                    name="hours"
                    label="Range Of Hours"
                    inputProps={{ maxLength: 10 }}
                    className={clsx(classes.field, classes.secondInputBlock)}
                />
            </Box>

            <PayTypeSelect
                positionId={positionValueId}
                name="payType"
                label="Pay Type"
                className={classes.field}
            />

            <DayPickerField
                name="startDate"
                label="Start Date"
                className={classes.field}
                format={monthTextDateFormat}
                disableToolbar
            />

            <OfferLetterTemplateSelect
                name="template"
                label="Offer Letter Template"
                className={classes.field}
                useIdValue
            />

            <OfferLetterPayRateField values={values}/>

            <OfferLetterPayRanges enabled={payRangesEnabled}/>
        </>
    );
}
