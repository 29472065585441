import { useMemo } from 'react';
import { EafApprovalGridCellCreationDate } from 'modules/eafApproval/components/cells/EafApprovalGridCellCreationDate';
import {
    EafApprovalGridCellEffectiveDate,
} from 'modules/eafApproval/components/cells/EafApprovalGridCellEffectiveDate';
import { EafApprovalCellTitleMap } from 'modules/eafApproval/store/model/data';
import { EafApprovalCell, EafApprovalGridCellKey } from 'modules/eafApproval/store/model/types';
import {
    EafGridCellSubassignment,
} from 'modules/subassignmentManagement/components/EafHistory/cells/EafGridCellSubassignment';
import {
    EafGridCellTypeDetails,
} from 'modules/subassignmentManagement/components/EafHistory/cells/EafGridCellTypeDetails';
import {
    withOpenEafDetailCell,
} from 'modules/subassignmentManagement/components/EafHistory/cells/withOpenEafDetailCell';

export const useEafHistoryGridCells = (): Array<EafApprovalCell> => {

    return useMemo(() => ([
        {
            key: EafApprovalGridCellKey.Subassignment,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Subassignment],
            render: withOpenEafDetailCell(EafGridCellSubassignment),
        },
        {
            key: EafApprovalGridCellKey.ChangeType,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.ChangeType],
            render: withOpenEafDetailCell(EafGridCellTypeDetails),
        },
        {
            key: EafApprovalGridCellKey.CreationDate,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.CreationDate],
            render: withOpenEafDetailCell(EafApprovalGridCellCreationDate),
            width: '100px',
        },
        {
            key: EafApprovalGridCellKey.EffectiveDate,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.EffectiveDate],
            render: withOpenEafDetailCell(EafApprovalGridCellEffectiveDate),
            width: '130px',
        },
    ]), []);
};
