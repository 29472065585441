import { ICreateEaf, IEafReason, IEmployeeActionForm, IGetMaxEntryDatePayload, IGroupedEafPagePayload } from 'modules/employeeActionsForm/store/models';
import { IEmployeeManagementFilter } from 'modules/employmentManagement/components/store/model';
import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';

export const employeeActionsFormPrefix = 'eaf';

const SET_EAF_FILTER = `${employeeActionsFormPrefix}/SET_EAF_FILTER`;
export const setEafAssignmentsFilters = createSingleAction<IEmployeeManagementFilter, typeof SET_EAF_FILTER>(
    SET_EAF_FILTER,
);

const INITIAL_LOAD_ASSIGNMENTS = `{employeeActionsFormPrefix}/INITIAL_LOAD_ASSIGNMENTS`;
export const initialLoadEafAssignments = createSingleAction<void, typeof INITIAL_LOAD_ASSIGNMENTS>(
    INITIAL_LOAD_ASSIGNMENTS,
);

export const getMoreEafAssignments = createRequestActions<void, IGroupedEafPagePayload>(
    RequestType.Get,
    'MORE_ASSIGNMENTS',
    employeeActionsFormPrefix,
);

export const getEafReasons = createRequestActions<void, IEafReason[]>(
    RequestType.Get,
    'REASONS',
    employeeActionsFormPrefix,
);

export const getAssignmentMaxEntryDate = createRequestActions<string, IGetMaxEntryDatePayload>(
    RequestType.Get,
    'ASSIGNMENT_MAX_ENTRY_DATE',
    employeeActionsFormPrefix,
);

export const createEmployeeActionForm = createRequestActions<ICreateEaf, IEmployeeActionForm>(
    RequestType.Create,
    'EMPLOYEE_ACTION_FORM',
    employeeActionsFormPrefix,
);

export type EafActionsType =
    | ActionsReturnTypes<typeof getMoreEafAssignments>
    | ActionsReturnTypes<typeof getAssignmentMaxEntryDate>
    | ReturnType<typeof setEafAssignmentsFilters>
    | ReturnType<typeof initialLoadEafAssignments>;
