import {
    donwloadEcubReport,
    loadEcubReportItems, pauseEcubReport,
    sendEcubReport,
} from 'modules/settings/submodules/clients/reports/Ecub/store/actions';
import { ecubApi } from 'modules/settings/submodules/clients/reports/Ecub/store/api';
import { getLatestSubassignmentsPayRatesReport } from 'modules/settings/submodules/clients/reports/store/actions';
import { reportsApi } from 'modules/settings/submodules/clients/reports/store/api';
import moment from 'moment/moment';
import { backendDateFormat } from 'shared/models/Dates';
import { IClientInfo } from 'store/entities/clients/clientsModel';
import { selectCurrentClient } from 'store/entities/clients/selectors/clientsSelectors';
import {
    getDownloadFileWithNameByRequestSagaWatcher,
} from 'store/utils/sagas/getDownloadFileByRequestSagaWatcher';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { requestSagaWatcher } from 'store/utils/sagas/requestSagaWatcher';
import { select } from 'typed-redux-saga';

const fileTimestampFormat = `${backendDateFormat}-HH.mm.ss`;
const getLatestSubassignmentPayRatesCsvReportSagaWatcher = getDownloadFileWithNameByRequestSagaWatcher(
    getLatestSubassignmentsPayRatesReport,
    reportsApi.downloadLatestSubassignmentPayRatesReportCsv,
    function * () {
        const client = yield select(selectCurrentClient);
        return `Latest pay rates ${(client as unknown as IClientInfo)?.name} ${moment().format(fileTimestampFormat)}.csv`;
    },
);

const loadEcubReportsSagaWatcher = getLoadEntitiesByRequestSagaWatcher(
    loadEcubReportItems,
    ecubApi.getEcubItems,
    'eCub items',
);
const downloadEcubSagaWatcher = getDownloadFileWithNameByRequestSagaWatcher(
    donwloadEcubReport,
    ecubApi.downloadEcub,
    function ({ filename }) {
        return filename.replace('.txt', '.xml');
    },
);
const sendEcubReportSagaWatcher = requestSagaWatcher(
    sendEcubReport,
    ecubApi.sendEcub,
    'Unable to send eCub report',
);
const pauseEcubReportSagaWatcher = requestSagaWatcher(
    pauseEcubReport,
    ecubApi.pauseEcub,
    'Unable to pause eCub report',
);

export const reportsSagas = [
    getLatestSubassignmentPayRatesCsvReportSagaWatcher,
    loadEcubReportsSagaWatcher,
    downloadEcubSagaWatcher,
    sendEcubReportSagaWatcher,
    pauseEcubReportSagaWatcher,
];
