import { combineReducers } from 'redux';
import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import {
    createPhysicalDemandsTemplate,
    setCreatePhysicalDemandsTemplateModalState, setEditPhysicalDemandsTemplateId, updatePhysicalDemandsTemplate,
} from 'modules/settings/submodules/clients/physicalDemands/store/action';

const isOpenCreatePhysicalDemandsModal = isOpenModalReducer(setCreatePhysicalDemandsTemplateModalState.action);
const isPhysicalDemandsCreating = isLoadingReducer(createPhysicalDemandsTemplate);
const isPhysicalDemandsUpdating = isLoadingReducer(updatePhysicalDemandsTemplate);

function editPhysicalDemandsId(
    state: string | null,
    action: ReturnType<typeof setEditPhysicalDemandsTemplateId>,
): string | null {
    state = state || null;
    switch (action.type) {
        case setEditPhysicalDemandsTemplateId.action:
            return action.payload;
        default:
            return state;
    }
}

export const physicalDemandsTemplates = combineReducers({
    isOpenCreatePhysicalDemandsModal,
    isPhysicalDemandsCreating,
    editPhysicalDemandsId,
    isPhysicalDemandsUpdating,
});
