import { IStore } from 'store/configureStore';
import { ItemsById } from 'shared/models/ItemsById';
import { ITravelExpense } from 'modules/travelExpenses/model';
import { ISheetCommonBackend } from 'shared/models/sheet/Sheet';
export const selectTravelExpensesState = (state: IStore) => state.modules.travelExpenses;

export const selectTravelExpensesById = (state: IStore) => {
    return selectTravelExpensesState(state).travelExpensesById as ItemsById<ITravelExpense>;
};
export const selectTravelExpensesAreLoading = (state: IStore) => {
    return selectTravelExpensesState(state).travelExpensesAreLoading;
};
export const selectTravelExpenseSheetsById = (state: IStore) => {
    return selectTravelExpensesState(state).travelExpenseSheetsById as ItemsById<ISheetCommonBackend>;
};
