import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { ApproveEafButton } from 'modules/eafApproval/components/Approve/ApproveEafButton';
import { EafApprovalFilterChips } from 'modules/eafApproval/components/EafApprovalFilterChips';
import { RejectEafButton } from 'modules/eafApproval/components/Rejection/RejectEafButton';
import {
    eafTableSelectors,
    selectEafApprovalActiveTabDisplay, selectEafApprovalCheckedItemsIds,
} from 'modules/eafApproval/store/selectors';
import { EafApprovalGridFilter } from 'modules/eafApproval/components/EafApprovalGridFilter';
import { SyncMessage } from 'shared/components/toolbar/SyncMessage';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useStyles = makeHighPriorityStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',

        '&>*+*': {
            marginLeft: theme.spacing(1),
        },
    },
}));

export const EafApprovalHeadline: React.FC = () => {
    const classes = useStyles();
    const isLoading = useSelector(eafTableSelectors.isLoading);
    const headlineTitle = useSelector(selectEafApprovalActiveTabDisplay);
    const totalItemsCount = useSelector(eafTableSelectors.selectTotalItems) || 0;
    const foundItemsDisplay = !isLoading && totalItemsCount > 0 ? `(${totalItemsCount})` : '';
    const selectedIds = useSelector(selectEafApprovalCheckedItemsIds);

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h4">
                    {headlineTitle} {foundItemsDisplay}
                </Typography>

                <Box className={classes.container}>
                    <Box>
                        <SyncMessage syncingKey={SyncingModels.EafApproval}/>
                    </Box>
                    {selectedIds.length > 0 && (
                        <>
                            <ApproveEafButton eafIds={selectedIds}/>
                            <RejectEafButton eafIds={selectedIds}/>
                        </>
                    )}

                    <EafApprovalGridFilter/>
                </Box>
            </Box>
            <EafApprovalFilterChips/>
        </>
    );
};
