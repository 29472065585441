import React from 'react';
import { useSelector } from 'react-redux';
import { IReportOverviewItem } from 'modules/payrollProcessorHub/store/model';
import { CommonPrePostInitializeReportPure } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/CommonPrePostInitializeReport';
import { selectBulkEditV2Rows } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/selectors';
import { getBulkEditReportV2Cells } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/cells';
import { IBulkEditReportRow } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/models';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';

interface IBulkEditReportV2Props {
    onBack?: () => void;
    title: string;
    handleAction: () => void;
    buttonTitle?: string;
    isLoading?: boolean;
    overviewItems: IReportOverviewItem[],
    additionalOverview?: React.ReactNode,
    getCells?: () => ICellInfo<IBulkEditReportRow>[],
}

export function BulkEditReportV2({
    title = 'Pre-Initialize Roll Up',
    buttonTitle = 'Update',
    onBack,
    handleAction,
    isLoading,
    overviewItems,
    additionalOverview,
    getCells = getBulkEditReportV2Cells,
} : IBulkEditReportV2Props){
    const cells = getCells();
    const rows = useSelector(selectBulkEditV2Rows);
    return (
        <CommonPrePostInitializeReportPure
            title={title}
            buttonTitle={buttonTitle}
            buttonHandler={handleAction}
            isButtonLoading={isLoading}
            backButtonHandler={onBack}
            hideBackButton={!onBack}
            overviewItems={overviewItems}
            rows={rows}
            cells={cells}
            getRowId={row => row.employee}
            additionalOverview={additionalOverview}
        />
    );
}
