import { v4 as uuidv4 } from 'uuid';
import {
    selectCustomFieldsList,
    selectIsLoadingCustomFields,
} from 'store/entities/customFields/selectors';
import { createSelector } from 'reselect';
import { hierarchyRowsSelector } from 'modules/settings/submodules/components/HierarchyPage/HierarchyTable/store/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { ICustomFieldHierarchyRow } from 'modules/settings/submodules/customFields/store/models';
import { HierarchyNodeOperation, HierarchyType } from 'store/entities/customFields/model';

export const availableCustomFieldsSelector = createSelector(
    selectCustomFieldsList,
    hierarchyRowsSelector,
    selectCurrentClientId,
    (allCustomFields, hierarchyRows, clientId) => {
        return allCustomFields.filter(field => {
            return !hierarchyRows.some(row => row.customField?.id === field.id)
                && (field.all_clients || (clientId && field.client_ids.includes(clientId)));
        });
    },
);

export const availableHierarchyRowsSelector = createSelector(
    availableCustomFieldsSelector,
    selectCurrentClientId,
    (availableCustomFields, clientId) => availableCustomFields.map<ICustomFieldHierarchyRow>(field => ({
        customField: field,
        // @ts-ignore
        hierarchyNode: {
            id: uuidv4(),
            parent_id: null,
            client_id: clientId,
            custom_field_id: field.id,
            display_on_grid: false,
            siblings_order: 0,
            operation: HierarchyNodeOperation.Actionable,
            hierarchy_type: HierarchyType.Assignment,
        },
        currentLevel: 0,
        isSortingPseudoRow: false,
        tempId: 0,
        id: field.id,
        depth: 0,
    })),
);

export const isAvailableCustomFieldsLoadingSelector = createSelector(
    selectIsLoadingCustomFields,
    isLoading => {
        return isLoading;
    },
);
