import { isEmpty } from 'lodash-es';
import { selectPayPeriodSheetStatusesByType } from 'modules/timeAndExpense/components/WorkingPage/store/selectors';
import { useSelector } from 'react-redux';
import { EntryType } from 'shared/models/sheet/Sheet';
import { StatusNames } from 'store/entities/timesheet/models/Status';

const notSubmitted = 'Not Submitted';
export type AllowedDisplayStatus = StatusNames | typeof notSubmitted;

const sheetStatusRate: AllowedDisplayStatus[] = [
    StatusNames.APPROVED_CLOSED,
    StatusNames.APPROVED,
    StatusNames.AWAITING_FINAL_APPROVAL,
    StatusNames.SUBMITTED,
    StatusNames.REJECTED,
    StatusNames.RECALLED,
    StatusNames.WORKING,
    notSubmitted,
];

export const getStatusByPriority = (statuses: AllowedDisplayStatus[]): AllowedDisplayStatus => {
    if (isEmpty(statuses)) {
        return notSubmitted;
    }
    return statuses
        .map(statusName => statusName === StatusNames.WORKING ? notSubmitted : statusName)
        .reduce((mem: AllowedDisplayStatus, statusName: AllowedDisplayStatus) => {
            if (!mem) {
                return statusName;
            }
            return sheetStatusRate.indexOf(mem) >= sheetStatusRate.indexOf(statusName) ? mem : statusName;
        }, StatusNames.APPROVED_CLOSED);
};

export const useGetDisplaySheetStatus = (): StatusNames | typeof notSubmitted => {
    const payPeriodSheetStatusesByType = useSelector(selectPayPeriodSheetStatusesByType);
    return getStatusByPriority([
        getStatusByPriority(payPeriodSheetStatusesByType[EntryType.TIME]),
        getStatusByPriority(payPeriodSheetStatusesByType[EntryType.EXPENSE]),
    ]);
};
