import {
    EntryType,
    ISheet,
    ISheetCommonBackend,
    IStatus,
    ITimeEntry,
    ITimeSheet,
} from 'shared/models/sheet/Sheet';
import { ApproversFromFields } from 'store/entities/clients/clientsModel';
import { uniqBy } from 'lodash-es';
import { logger } from 'shared/utils/logging/logger';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { ITimesheetCalculation } from './models/Calculation';
import moment from 'moment';
import { getHasEmptyEntries } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/emptyEntries';

export function tryApproveSheet<TSheet extends ISheetCommonBackend>(
    sheet: TSheet,
    approversFrom: ApproversFromFields | undefined,
    currentUserApprovalLevelsBySubassignments: Record<string, number>,
    approversCountBySheets: {[p: string]: number},
    clientId: string,
    approvedStatus: IStatus,
){
    const currentUserApprovalLevel = approversFrom === ApproversFromFields.FromJobNumber ? 1
        : sheet.subassignment_id ? currentUserApprovalLevelsBySubassignments[sheet.subassignment_id] || 0 : 0;

    const approversCount = approversCountBySheets[sheet.id];

    const updatedSheet = {
        ...sheet,
    };

    updatedSheet.approved_level = currentUserApprovalLevel;
    if (updatedSheet.approved_level >= approversCount){
        updatedSheet.status = approvedStatus;
    }
    return updatedSheet;
}

export function toSheetGroupId(timeSheet: ITimeSheet) {
    if (!timeSheet?.sheet_group_id) {
        logger.warning('updateSheetCalculationUtils. Time sheet group isn\'t defined', {
            timeSheet: timeSheet,
        });
    }
    return timeSheet?.sheet_group_id || '';
}

export function isCalculationOutdated(
    calculation: ITimesheetCalculation,
    sheet: ITimeSheet,
    sheetEntries: Array<ITimeEntry>,
): boolean {
    // entries with empty timeout aren't present in calculation
    const completeSheetEntries = sheetEntries.filter(entry => !getHasEmptyEntries([entry]));
    const calcEntries = uniqBy(calculation.entries, 'sheet_entry_id')
        // holiday entries don't have sheet_entry_id
        .filter(entry => Boolean(entry.sheet_entry_id));
    if (completeSheetEntries.length !== calcEntries.length) {
        return true;
    }
    const calculationUpdateDate = calculation.updated_at;
    const sheetUpdateDate = sheet.updated_at;
    if (moment(calculationUpdateDate).isBefore(sheetUpdateDate)) {
        return true;
    }
    return (
        sheetEntries
            .filter(item => Boolean(item.updated_at))
            .some(item => moment(calculationUpdateDate).isBefore(item.updated_at))
    );
}

export const sheetIsMissing = (sheet?: ISheet) => sheet?.entry_type === EntryType.TIME && sheet?.total_minutes === 0
    && sheet?.status.name === StatusNames.WORKING;
