import { IStore } from 'store/configureStore';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export const selectEmployeeSubmittedSheetsFilters = (state: IStore) => state.components.employeeSubmittedSheets.filters;

export const selectEmployeeSubmittedSheetsFiltersIsNotEmpty = (state: IStore) => {
    const filters = selectEmployeeSubmittedSheetsFilters(state);
    const customFieldsValuesSelected = Object.values(filters.customFieldValues).filter(Boolean);
    return filters.location_id || filters.position_id || customFieldsValuesSelected.length > 0;
};

export const selectEmployeeSubmittedSheetsFiltersConfiguration = (state: IStore) => {
    const clientId = selectCurrentClientId(state) ?? '';
    return state.clients.fieldsConfigurationByClientId[clientId]?.filters?.employee_sheets;
};
export const selectEmployeeSubmittedEditSheet = (state: IStore) => state.components.employeeSubmittedSheets.editSheet;
