import { CalculationGroupDetail } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/CalculationGroupDetail';
import { usePayrollSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/PayrollSheetDetailSidebar';
import { getGroupSheetApprovals, setPayrollDetailCalculationGroupId } from 'modules/payrollProcessorHub/store/actions';
import { getUniqValues } from 'modules/payrollProcessorHub/store/helpers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { selectDetailCalculationGroup } from 'modules/payrollProcessorHub/store/selectors';
import { getLastFirstName } from 'shared/utils/converters/user';
import { Permission } from 'store/components/auth/authModels';
import { getActivities, getJobNumbers } from 'store/entities/configuration/configurationAction';
import { getUsers } from 'store/entities/users/actions';
import { selectUserById } from 'store/entities/users/selectors';
import { getClientPayCodes } from 'modules/settings/submodules/clients/store/actions';
import { getCustomFieldsHierarchyNodes, getCustomFieldValues } from 'store/entities/customFields/actions';
import { getClientFieldsConfiguration } from 'store/entities/clients/clientsAction';
import { getSheetLogs } from 'store/entities/timesheet/actions/sheetState';

export const PayrollCalculationDetailGroupedSidebar = () => {
    const dispatch = useDispatch();

    const detailClasses = useSheetDetailStyles();
    const classes = usePayrollSheetDetailStyles(true);

    const calculationGroup = useSelector(selectDetailCalculationGroup);
    const user = useSelector(selectUserById(calculationGroup?.user_id || ''));

    const onClose = () => {
        dispatch(setPayrollDetailCalculationGroupId(null));
    };
    useEffect(() => {
        if (calculationGroup) {
            if (calculationGroup.client_id) {
                dispatch(getClientPayCodes.init({
                    client_id: calculationGroup.client_id,
                }));
                dispatch(getActivities.init({
                    client_id: calculationGroup.client_id,
                }));
                const calculations = calculationGroup.time_calculations.concat(
                    // @ts-ignore
                    calculationGroup.expense_calculations,
                );
                // @ts-ignore
                const entries = calculations.reduce((mem, calc) => [...mem, ...calc.entries], []);
                const customFieldValueIds = [... new Set(
                    entries.map(
                        // @ts-ignore
                        entry => entry.custom_field_value_ids,
                        // @ts-ignore
                    ).reduce((mem, entryCustomFieldValueIds) => {
                        return [...mem, ...entryCustomFieldValueIds];
                    }, []),
                )];
                dispatch(getCustomFieldValues.init({
                    // @ts-ignore
                    custom_field_value_ids: customFieldValueIds,
                }));
                dispatch(getCustomFieldsHierarchyNodes.init({
                    client_id: calculationGroup.client_id,
                    cfvs: false,
                }));
                dispatch(getUsers.init({
                    client_id: calculationGroup.client_id,
                    purpose: Permission.ApproveSheets,
                }));
                const jobNumberIds = getUniqValues([...calculationGroup.time_calculations, ...calculationGroup.expense_calculations], 'job_number_id');
                if (jobNumberIds.length) {
                    dispatch(getJobNumbers.init({
                        client_id: calculationGroup.client_id,
                        ids: jobNumberIds,
                    }));
                }
                dispatch(getClientFieldsConfiguration.init(calculationGroup.client_id));
                dispatch(getGroupSheetApprovals.init(calculationGroup.id));

                const timeSheetIds = [...new Set(
                    calculationGroup.time_calculations
                        .map(item => item.sheet_id)
                        .filter(Boolean),
                )];
                const expenseSheetIds = [...new Set(
                    calculationGroup.expense_calculations
                        .map(item => item.sheet_id)
                        .filter(Boolean),
                )];
                if (timeSheetIds.length || expenseSheetIds.length) {
                    dispatch(getSheetLogs.init({ timeSheetIds, expenseSheetIds } ));
                }
            }
        }
    }, [dispatch, calculationGroup]);

    return (
        <Sidebar
            isOpened={Boolean(calculationGroup)}
            title={getLastFirstName(user)}
            customClasses={classes.paper}
            titleClasses={{
                root: detailClasses.paperTitle,
                text: detailClasses.paperTitleText,
                icon: detailClasses.paperTitleIcon,
            }}
            onClose={onClose}
        >
            {calculationGroup ? (
                <CalculationGroupDetail calculationGroup={calculationGroup}/>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    pt={5}
                >
                    <CircularProgress color="primary"/>
                </Box>
            )}
        </Sidebar>
    );
};
