import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const usePayrollReportStyle = makeStyles((theme: Theme) =>
    createStyles({
        SummaryWrapper: {
            backgroundColor: colors.white,
            marginBottom: theme.spacing(2),
        },
        SummaryTable: {
            paddingTop: theme.spacing(4),
        },
        SummaryInfo: {
            display: 'flex',
            flexDirection: 'row',
            padding: theme.spacing(2),
        },
        SummaryInfoColumn: {
            paddingLeft: theme.spacing(4),
            '&:first-child': {
                paddingLeft: theme.spacing(0),
            },
        },
        SummaryInfoRow: {
            paddingTop: theme.spacing(1),
            '&:first-child': {
                paddingTop: theme.spacing(0),
            },
        },
        SummaryInfoValue: {
            fontWeight: 'bold',
        },
        SummaryInfoValueValid: {
            color: colors.green,
        },
        SummaryInfoValueError: {
            color: colors.red,
        },
    }));
