import React, { useEffect } from 'react';
import clsx from 'clsx';
import { ThemeProvider, useTheme, useMediaQuery } from '@material-ui/core';
import { useSidebarState } from 'store/entities/appConfig/utils';
import { isMediumScreenBreakpoint } from 'shared/styles/constants';
import VerticalMenu from 'shared/components/verticalMenu/VerticalMenu';
import { setClientId } from 'store/entities/clients/clientsAction';
import { getClientTheme } from 'shared/components/theme/extendClientTheme';
import { selectConfigurationThemeById } from 'store/entities/clients/selectors/themeSelectors';
import Content from './content/Content';
import { useDispatch, useSelector } from 'react-redux';
import Header from 'modules/home/header/Header';
import { useHomeStyles } from 'modules/home/HomeStyles';
import { useParams } from 'react-router-dom';
import SplashPage from 'shared/components/SplashPage/SplashPage';
import { selectAvailableClients } from 'store/entities/clients/selectors/clientsSelectors';
import { IThemeConfigurationWrapper } from 'store/entities/clients/clientsModel';
import { Maintaince } from 'shared/components/alerts/Maintaince';
import { useClientMenu } from 'modules/clients/hooks/useClientMenu';

export default function Client() {
    const classes = useHomeStyles();
    const clients = useSelector(selectAvailableClients);
    const { client_id: clientId } = useParams<{ client_id: string }>();
    const displayName = clients[clientId]?.name;

    const clientConfiguration:
    IThemeConfigurationWrapper | undefined = useSelector(selectConfigurationThemeById(clientId));

    const { isSidebarOpened } = useSidebarState();
    const isMenuTemporary = useMediaQuery(useTheme().breakpoints.down(isMediumScreenBreakpoint));
    const menuItems = useClientMenu();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            if (!process.env.REACT_APP_CLIENT_ID) {
                dispatch(setClientId(null));
            }
        };
    }, [dispatch]);

    useEffect(() => {
        if (clientId) {
            dispatch(setClientId(clientId));
        }
    }, [dispatch, clientId]);

    const contentClass = !isMenuTemporary ? isSidebarOpened ? classes.largeShift : classes.smallShift : '';

    const clientTheme = getClientTheme(clientConfiguration?.theme?.primary_color);

    return (
        <ThemeProvider theme={clientTheme}>
            <div
                data-testid="headway-ahead"
                className={clsx('headway-ahead', classes.root)}
            >
                <VerticalMenu
                    variant={isMenuTemporary ? 'temporary' : 'persistent'}
                    menu={menuItems}
                />
                <div className={clsx(classes.app, classes.appShifted, contentClass )}>
                    <Header/>
                    {displayName ? (
                        <main className={classes.main}>
                            <Maintaince/>
                            <Content/>
                        </main>
                    ) : (
                        <SplashPage/>
                    )}
                </div>
            </div>
        </ThemeProvider>
    );
}
