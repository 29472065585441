import React from 'react';
import { useDrag, useDrop, useIsClosestDragging } from 'react-sortly';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { HIERARCHY_LEVEL_MARGIN } from './HierarchyTable/HierarchyTable';

interface IDraggableItemRenderProps {
    children: React.ReactNode;
    classes: Partial<Record<'root' | 'inner' | 'inDragging', string>>;
    depth: number;
    disabled?: boolean;
}

interface IDraggableItemRenderStyles {
    muted: boolean;
    depth: number;
}
const useStyles = makeStyles<Theme, IDraggableItemRenderStyles>(theme => ({
    root: ({ muted }) => ({
        zIndex: Number(!!muted),
        ...muted ? {
            border: `1px dashed ${theme.palette.primary.main}`,
        } : {},
    }),
    inner: ({ depth, muted }) => ({
        marginLeft: theme.spacing(depth * HIERARCHY_LEVEL_MARGIN),
        opacity: muted ? 0.8 : 1,
    }),
    disabled: {
        cursor: 'not-allowed',
    },
}));

export function DraggableItemRender({ children, classes, depth, disabled = false }: IDraggableItemRenderProps) {
    const [{ isDragging }, drag] = useDrag({
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const [, drop] = useDrop();
    const baseClasses = useStyles({
        muted: useIsClosestDragging() || isDragging,
        depth,
    });

    return (
        <div
            ref={ref => drop(ref)}
            className={clsx(baseClasses.root, classes.root, { [baseClasses.disabled]: disabled })}
        >
            <div
                ref={disabled ? undefined : drag}
                className={clsx(baseClasses.inner, classes.inner, { [baseClasses.disabled]: disabled })}
            >
                {children}
            </div>
        </div>
    );
}
