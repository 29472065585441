import React from 'react';
import { Box } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import FormField from 'shared/components/formFields/FormField';
import { FormMode } from 'shared/models/FormMode';

export interface INameCodeFormDataSectionProps {
    mode: FormMode;
    path?: string;
    codeLabel?: string;
    useDescription?: boolean;
    disabled?: boolean;
    extensionFields?: React.ReactNode;
}

export function NameCodeFormDataSection({
    mode,
    extensionFields,
    path = '',
    codeLabel = 'Code',
    useDescription = false,
    disabled = false,
}: INameCodeFormDataSectionProps) {
    const classes = useSettingsFormStyles();

    return (
        <Box className={classes.formBlock}>
            <Box display="flex">
                <Box width="60%">
                    <FormField
                        name={`${path}name`}
                        label="Name"
                        className={classes.input}
                        disabled={disabled}
                    />
                </Box>
            </Box>
            <Box display="flex">
                <Box width="60%">
                    <FormField
                        name={`${path}code`}
                        label={codeLabel}
                        disabled={disabled || mode === FormMode.Edit}
                        className={classes.input}
                    />
                </Box>
            </Box>
            {useDescription && (
                <Box display="flex">
                    <FormField
                        name={`${path}description`}
                        label="Description"
                        className={classes.input}
                        disabled={disabled}
                    />
                </Box>
            )}
            {extensionFields}
        </Box>
    );
}
