import { IconButton } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { CheckPermissionAndFeature } from 'shared/components/CheckPermissionAndFeature/CheckPermissionAndFeature';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { loginAsUser } from 'store/components/auth/authActions';
import { Permission } from 'store/components/auth/authModels';

interface IEditButtonProps {
    userId: string;
    clientId?: string;
}

export const LoginAsUserButton = ({ userId, clientId }: IEditButtonProps) => {
    const dispatch = useDispatch();
    const onLogin = useCallback(() => {
        dispatch(loginAsUser.init({
            userId,
            clientId,
        }));
    }, [dispatch, userId, clientId]);

    return (
        <CheckPermissionAndFeature
            permission={Permission.Impersonate}
        >
            <IconButton
                onClick={onLogin}
                color="primary"
                data-testid="details-button"
                title="Login as user"
            >
                <ExitToApp/>
            </IconButton>
        </CheckPermissionAndFeature>
    );
};
