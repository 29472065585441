import { setEditCostCenterId } from 'modules/settings/submodules/clients/costCenters/components/EditCostCenter/store/actions';
import { createCostCenter, updateCostCenter } from 'modules/settings/submodules/clients/costCenters/store/actions';
import { getCostCenters } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';
import { api } from 'modules/settings/submodules/clients/costCenters/store/api';

function* createCostCenterSaga({ payload }: ReturnType<typeof createCostCenter.init>) {
    const result = yield* call(api.createCostCenter, payload);
    yield put(createCostCenter.success(result));
    yield put(getCostCenters.success([result]));
}

export function* createCostCenterWatcher() {
    yield* takeLatest(
        createCostCenter.initType,
        withBackendErrorHandler(
            createCostCenterSaga,
            createCostCenter.error,
            'Unable to create Cost Center.',
        ),
    );
}

function* updateCostCenterSaga(action: ReturnType<typeof updateCostCenter.init>) {
    const { id, data } = action.payload;
    const result = yield* call(api.updateCostCenter, id, data);
    yield put(updateCostCenter.success(result));
    yield put(getCostCenters.success([result]));
    yield put(setEditCostCenterId(null));
}

export function* updateCostCenterWatcher() {
    yield* takeLatest(
        updateCostCenter.initType,
        withBackendErrorHandler(
            updateCostCenterSaga,
            updateCostCenter.error,
            'Unable to update Cost Center.',
        ),
    );
}

export const costCenterSagas = [
    createCostCenterWatcher,
    updateCostCenterWatcher,
];
