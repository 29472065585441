import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectWorkingConditionById,
} from 'store/entities/configuration/configurationSelectors';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import {
    selectEditWorkingConditionsTemplateId,
    selectIsWorkingConditionsTemplateUpdating,
} from 'modules/settings/submodules/clients/workingConditions/store/selectors';
import { ITemplateFormValues } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';
import TemplateFormWrapper
    from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/TemplateFormWrapper';
import {
    setEditWorkingConditionsTemplateId,
    updateWorkingConditionsTemplate,
} from 'modules/settings/submodules/clients/workingConditions/store/actions';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export default function EditWorkingConditionsModal() {
    const dispatch = useDispatch();
    const id = useSelector(selectEditWorkingConditionsTemplateId);
    const template = useSelector(selectWorkingConditionById(id));
    const isLoading = useSelector(selectIsWorkingConditionsTemplateUpdating);

    const onSave = useCallback((data: ITemplateFormValues) => {
        dispatch(updateWorkingConditionsTemplate.init({
            id,
            data,
        }));
    }, [dispatch, id]);

    const classes = useColoredFormModalStyles();
    const onClose = useCallback(() => {
        dispatch(setEditWorkingConditionsTemplateId(null));
    }, [dispatch]);
    return (
        <ModalDialog
            title="Edit Working Conditions Template"
            isOpened={Boolean(id)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <TemplateFormWrapper
                    onSubmit={onSave}
                    submitTitle="Update"
                    templateLabel="Working Conditions"
                    isLoading={isLoading}
                    template={template}
                />
            </Box>
        </ModalDialog>
    );
}
