import React, { useCallback, useMemo } from 'react';
import { Moment } from 'moment';
import { isDayInPeriod } from 'shared/utils/helpers/isDayInPeriod';
import { useAllowFutureDayEntry, useDisallowPastDayEntry } from 'store/entities/clients/hooks';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { moment } from 'utils/momentExtensions';
import clsx from 'clsx';
import { Box, Button, Hidden, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { useEntryEditStyles } from './styles';
import { backendDateFormat } from 'shared/models/Dates';
import { useDayPickerStyles } from 'shared/components/sidebars/EntryEdit/DayPickerStyles';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

const outputFormat = 'dddd, MMM D, YYYY';
const mobileDayFormat = 'ddd';
type Direction = 'future' | 'past';

interface IChangeParams {
    direction?: Direction;
    chosenDate?: Moment;
}

interface IDayPickerProps {
    value?: string;
    onChange: (value: string) => void;
    payPeriod: IPayPeriod;
    subassignments?: ISubassignment[] | null;
}

export default function DayPicker({ value, onChange, payPeriod, subassignments }: IDayPickerProps) {
    const classes = useEntryEditStyles();
    const sidebarClasses = useSidebarStyles();
    const dayPickerClasses = useDayPickerStyles();

    const date = useMemo(() => moment(value), [value]);
    const allowFutureDayEntry = useAllowFutureDayEntry();
    const disallowPastDayEntry = useDisallowPastDayEntry();
    const datesAvailable = useMemo(() => {
        const startDate = disallowPastDayEntry ? moment().toDate() : moment(payPeriod?.period_start);
        const payPeriodRange = moment.range(startDate, moment(payPeriod?.period_end));
        return [...payPeriodRange.by('day')].filter(day => {
            let filtered = true;
            if (subassignments) {
                filtered = filtered && subassignments.some(subassignment => isDayInPeriod(subassignment)(day));
            }
            if (!allowFutureDayEntry) {
                filtered = filtered && moment().isSameOrAfter(day);
            }
            return filtered;
        });
    }, [allowFutureDayEntry, payPeriod, subassignments, disallowPastDayEntry]);
    const noDatesAvailable = datesAvailable.length === 0;

    const onDateChange = useCallback(({ direction, chosenDate }: IChangeParams) => {
        let newDate: Moment | null = null;
        if (direction) {
            newDate = direction === 'future'
                ? date.clone().add(1, 'day')
                : date.clone().subtract(1, 'day');
        } else if (chosenDate) {
            newDate = chosenDate.clone();
        }

        if (newDate) {
            onChange(newDate.format(backendDateFormat));
        }
    }, [date, onChange]);

    const isActive = useCallback((dateToCheck: moment.Moment) => dateToCheck.day() === date.day(), [date]);

    return (
        <section className={clsx(dayPickerClasses.container, sidebarClasses.sidebarElement)}>
            <>
                <Hidden xsDown>
                    <IconButton
                        color="primary"
                        onClick={() => onDateChange({ direction: 'past' })}
                        disabled={noDatesAvailable || date.isSame(datesAvailable[0], 'day')}
                        classes={{ root: clsx(sidebarClasses.iconNoPadding, dayPickerClasses.controlButton) }}
                    >
                        <ChevronLeft className={clsx(sidebarClasses.largeIcon, classes.dayPickerIconLeft)}/>
                    </IconButton>
                    <Typography className={classes.dayPickerContent} variant="body1">
                        {date.format(outputFormat)}
                    </Typography>
                    <IconButton
                        color="primary"
                        onClick={() => onDateChange({ direction: 'future' })}
                        disabled={noDatesAvailable || date.isSame(datesAvailable[datesAvailable.length - 1], 'day')}
                        classes={{ root: clsx(sidebarClasses.iconNoPadding, dayPickerClasses.controlButton) }}
                    >
                        <ChevronRight className={clsx(sidebarClasses.largeIcon, classes.dayPickerIconRight)}/>
                    </IconButton>
                </Hidden>
                <Hidden smUp>
                    {datesAvailable.map(availableDate => (
                        <Box key={availableDate.format()} className={dayPickerClasses.dayContainer}>
                            <Typography className={dayPickerClasses.dayOfWeek}>
                                {availableDate.format(mobileDayFormat)}
                            </Typography>
                            <Button
                                disableRipple
                                onClick={() => onDateChange({ chosenDate: availableDate })}
                                className={clsx(dayPickerClasses.dayOfMonth, isActive(availableDate) ? 'active' : '')}
                            >
                                {availableDate.format('D')}
                            </Button>
                        </Box>
                    ))}
                </Hidden>
            </>
        </section>
    );
}
