import { TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { useCurrentClientMileageRate } from 'shared/models/Miles';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { IFormFieldProps } from 'shared/components/formFields/models';
import NumberInput from 'shared/components/formFields/NumberInput';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { calculateMiles, getFormattedOdometer } from './utils';

export interface IOdometerDataFieldProps extends IFormFieldProps {
    showOdometerDollarsInput?: boolean;
    showNote?: boolean;
}

const milesStartKey = 'miles_start';
const milesEndKey = 'miles_end';
const getOdometerFieldError = (
    fieldKey: string,
    error: Record<string, string> | string | undefined,
): string | undefined => {
    if (!error || typeof error === 'string') {
        return error;
    }
    return error[fieldKey];
};

const OdometerDataField = ({
    name,
    className,
    disabled = false,
    showOdometerDollarsInput = false,
    showNote = false,
}: IOdometerDataFieldProps) => {
    const [, metaMilesStart, helperMilesStart] = useField(`${name}.${milesStartKey}`);
    const [, metaMilesEnd, helperMilesEnd] = useField(`${name}.${milesEndKey}`);
    const [field, meta, helper] = useField(name);
    const helperRef = useRef(helper);
    const [startOdo, setStartOdo] = useState<number | null>(field.value?.miles_start);
    const [endOdo, setEndOdo] = useState<number | null>(field.value?.miles_end);

    useEffect(() => {
        helperRef.current.setValue({
            entry_type: QuantityType.ODOMETER,
            miles_start: startOdo,
            miles_end: endOdo,
        });
    }, [startOdo, endOdo, helperRef]);

    const setMilesStartTouched = (value: boolean) => {
        helper.setTouched(value);
        helperMilesStart.setTouched(value);
        helperMilesEnd.setTouched(metaMilesEnd.touched);
    };

    const setMilesEndTouched = (value: boolean) => {
        helper.setTouched(value);
        helperMilesEnd.setTouched(value);
        helperMilesStart.setTouched(metaMilesStart.touched);
    };

    const mileageRate = useCurrentClientMileageRate();
    const miles = calculateMiles(field.value);
    const dollars = getFormattedOdometer(field.value, mileageRate);

    return (
        <>
            <NumberInput
                name={milesStartKey}
                label="Start ODO"
                initialValue={field.value?.miles_start}
                className={className}
                inputProps={{
                    decimalScale: 0,
                    thousandSeparator: true,
                    allowNegative: false,
                    fixedDecimalScale: true,
                    isNumericString: true,
                }}
                disabled={disabled}
                error={getOdometerFieldError(milesStartKey, meta.error)}
                touched={metaMilesStart.touched || meta.touched}
                setTouched={setMilesStartTouched}
                // @ts-ignore
                onChange={setStartOdo}
            />
            <NumberInput
                name={milesEndKey}
                label="End ODO"
                initialValue={field.value?.miles_end}
                className={className}
                inputProps={{
                    decimalScale: 0,
                    thousandSeparator: true,
                    allowNegative: false,
                    fixedDecimalScale: true,
                    isNumericString: true,
                }}
                disabled={disabled}
                error={getOdometerFieldError(milesEndKey, meta.error)}
                touched={metaMilesEnd.touched}
                setTouched={setMilesEndTouched}
                // @ts-ignore
                onChange={setEndOdo}
            />
            {showOdometerDollarsInput && (
                <TextField
                    className={className}
                    value={dollars || ''}
                    disabled
                    label="$"
                    variant="outlined"
                />
            )}
            {showNote && miles > 0 && (
                <p>{miles} mile{miles === 1 ? '' : 's'} = {formatDollars(dollars)}</p>
            )}
        </>
    );
};

export default OdometerDataField;
