import React from 'react';
import { useMediaQuery, Box, Theme } from '@material-ui/core';
import {
    selectAvailableClientsSortedAlphabetically,
} from 'store/entities/clients/selectors/clientsSelectors';
import VerticalMenu from 'shared/components/verticalMenu/VerticalMenu';
import Header from './header/Header';
import clsx from 'clsx';
import { IStore } from 'store/configureStore';
import { useHomeMenu } from 'modules/home/menu/verticalMenu';
import { useSidebarState } from 'store/entities/appConfig/utils';
import { isMediumScreenBreakpoint } from 'shared/styles/constants';
import { useHomeStyles } from 'modules/home/HomeStyles';
import { useSelector } from 'react-redux';
import { Maintaince } from '../../shared/components/alerts/Maintaince';
import { IClientInfo } from 'store/entities/clients/clientsModel';

interface IHomeProps {
    content: JSX.Element;
    sectionTitle?: string;
    flex?: boolean;
}

export default function Home({ content, sectionTitle, flex }: IHomeProps) {
    const classes = useHomeStyles();
    const { isSidebarOpened } = useSidebarState();
    const isMenuTemporary = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMediumScreenBreakpoint));
    const clients = useSelector(selectAvailableClientsSortedAlphabetically) as IClientInfo[];
    const clientsThemes = useSelector((state: IStore) => state.clients.configurationThemeByClientId);
    const menu = useHomeMenu({
        clients,
        clientsThemes,
    });

    const contentClass = !isMenuTemporary ? isSidebarOpened ? classes.largeShift : classes.smallShift : '';

    return (
        <div
            data-testid="headway-ahead"
            className={clsx('headway-ahead', classes.root)}
        >
            <VerticalMenu
                variant={isMenuTemporary ? 'temporary' : 'persistent'}
                menu={menu}
            />
            <div className={clsx(classes.app, classes.appShifted, contentClass )}>
                <Box component="header">
                    <Header title={sectionTitle}/>
                </Box>
                <Box
                    component="main"
                    className={classes.main}
                    display={flex ? 'flex' : 'block'}
                >
                    <Maintaince/>
                    {content}
                </Box>
            </div>
        </div>
    );
}
