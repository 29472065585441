import React from 'react';
import { Box, Collapse } from '@material-ui/core';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';

const COLLAPSE_TIMEOUT_MS = 300;
const MESSAGE = `
    There are some fields you cannot edit since
    the employee has an active/working timesheet
    for this assignment. If you need to make any
    changes, please contact the Help Desk
`;

export interface ISubAssignmentIsActiveWarningProps {
    show?: boolean;
}

export const SubAssignmentIsActiveWarning: React.FC<ISubAssignmentIsActiveWarningProps> = ({ show = false }) => (
    <Collapse in={show} timeout={COLLAPSE_TIMEOUT_MS}>
        <Box mb={4}>
            <WarningAlertWithIcon>
                {MESSAGE}
            </WarningAlertWithIcon>
        </Box>
    </Collapse>
);
