import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useConfirmationDialogCustomClasses = makeHighPriorityStyles({
    paper: {},
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        '& > h2': {
            textTransform: 'none',
        },
    },
    content: {},
    actions: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    confirmButton: {
        marginRight: '10px',
    },
    cancelButton: {},
});
