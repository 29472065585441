import { Nullable } from 'types/types';
import { orderBy } from 'lodash-es';
import {
    EafType,
    IEafApproverChangeData,
    IEafCompensationChangeData, IEafEndDateChangeData,
    IEafPromotionDemotion, IEafStartDateChangeData,
    IEafTransferLocationData,
    IEmployeeActionForm,
} from 'modules/employeeActionsForm/store/models';
import { useSelector } from 'react-redux';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { IManagerInfo } from 'store/entities/configuration/configurationModel';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { selectUsersById } from 'store/entities/users/selectors';

export const useEafChangeString = (eaf: IEmployeeActionForm): string => {
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const usersByIds = useSelector(selectUsersById);
    const { employee_action_form_data: eafData } = eaf;

    switch (eafData.employee_action_form_type) {
        case EafType.CompensationChange: {
            const oldRate = formatDollars((eafData as IEafCompensationChangeData).old_base_pay_rate);
            const newRate = formatDollars((eafData as IEafCompensationChangeData).new_base_pay_rate);
            return `${oldRate} to ${newRate}`;
        }
        case EafType.Promotion:
        case EafType.Demotion: {
            const {
                old_position_custom_field_value_id: oldPositionId,
                new_position_custom_field_value_id: newPositionId,
            } = eafData as IEafPromotionDemotion;
            const oldPosition: Nullable<ICustomFieldValue> = customFieldValuesByIds[oldPositionId];
            const newPosition: Nullable<ICustomFieldValue> = customFieldValuesByIds[newPositionId];
            return `${oldPosition?.data?.name} to ${newPosition?.data?.name}`;
        }
        case EafType.StartDateChange: {
            const {
                old_start_date: oldStartDate,
                new_start_date: newStartDate,
            } = eafData as IEafStartDateChangeData;
            return `${oldStartDate ? formatShortDate(oldStartDate) : ''} to ${formatShortDate(newStartDate)}`;
        }
        case EafType.EndDateChange: {
            const {
                old_end_date: oldEndDate,
                new_end_date: newEndDate,
            } = eafData as IEafEndDateChangeData;
            const fromValue = oldEndDate ? formatShortDate(oldEndDate) : 'Empty';
            const toValue = newEndDate ? formatShortDate(newEndDate) : 'Empty';
            return `${fromValue} to ${toValue}`;
        }
        case EafType.TransferLocation: {
            const {
                old_location_custom_field_value_id: oldId,
                new_location_custom_field_value_id: newId,
            } = eafData as IEafTransferLocationData;
            const oldValue: Nullable<ICustomFieldValue> = customFieldValuesByIds[oldId];
            const newValue: Nullable<ICustomFieldValue> = customFieldValuesByIds[newId];
            return `${oldValue?.data?.name} to ${newValue?.data?.name}`;
        }
        case EafType.ApproverChange: {
            const {
                old_managers: oldManagers,
                new_managers: newManagers,
            } = eafData as IEafApproverChangeData;
            const oldValue = getApproverNames(oldManagers, usersByIds);
            const newValue = getApproverNames(newManagers, usersByIds);
            return `${oldValue} to ${newValue}`;
        }
        default:
            return '';
    }
};

export function getApproverNames(managers: IManagerInfo[], usersByIds: Record<string, IUserInfo>): string {
    return orderBy(managers, 'manager_level', 'asc')
        .map(manager => getUserName(usersByIds[manager.user_id]))
        .join(', ');
}
