import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const useSheetsSubmittedViewStyles = makeHighPriorityStyles((theme: Theme) => ({
    mainContainer: {
        display: 'flex',
        flexFlow: 'column',
        maxHeight: theme.spacing(50),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 10, 0),
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    entryTypeSwitcher: {
        marginTop: theme.spacing(2),
    },
    filtersWrapper: {
        margin: theme.spacing(1, 1, 0),
    },
}));
