import { Box } from '@material-ui/core';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { setUpdateOfferLetterTemplateModalState } from 'modules/settings/submodules/offerLetters/store/action';
import {
    selectDeletingOfferLetterTemplateIds,
    selectUpdatingOfferLetterTemplateIds,
} from 'modules/settings/submodules/offerLetters/store/selectors';
import moment from 'moment';
import React from 'react';

import { IOfferLetterTemplateWithClient } from 'modules/offerLetter/store/templates/models';
import { useSelector } from 'react-redux';

import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { shortDateFormat } from 'shared/models/Dates';
import clsx from 'clsx';
import { removeOfferLetterTemplate } from 'modules/offerLetter/store/templates/actions';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { UpdateOfferLetterTemplateStatus } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateList/UpdateOfferLetterTemplateStatus';
import PreviewOfferLetterTemplate from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateList/PreviewOfferLetter';
import { Permission } from 'store/components/auth/authModels';

export interface IOfferLetterTemplateRow extends IOfferLetterTemplateWithClient {
    className?: string;
}

export const useOfferLetterTemplateCell = (classes: Record<string, string>): ICellInfo<IOfferLetterTemplateRow>[] => ([
    {
        key: 'name',
        title: 'Name',
        width: '2.2fr',
        render: function NameCell({ name, className }: IOfferLetterTemplateRow) {
            return (
                <PlainText className={className} value={name}/>
            );
        },
    },
    {
        key: 'created',
        title: 'created',
        render: function CreatedCell({ createdAt, className }: IOfferLetterTemplateRow){
            return (
                <PlainText className={className} value={moment(createdAt).format(shortDateFormat)}/>
            );
        },
    },
    {
        key: 'status',
        title: 'status',
        render: function StatusCell({ id, status, className }: IOfferLetterTemplateRow) {
            return (
                <Box className={className}>
                    <UpdateOfferLetterTemplateStatus
                        id={id}
                        status={status}
                        className={classes.statusSelect}
                    />
                </Box>
            );
        },
    },
    {
        key: 'preview',
        title: '',
        width: '100px',
        render: function PreviewCell({ className, id }: IOfferLetterTemplateRow) {
            return (
                <div className={className}>
                    <PreviewOfferLetterTemplate id={id}/>
                </div>
            );
        },
    },
    {
        key: 'clients',
        title: 'Clients',
        render: function ClientsCell({ className, clients, all_clients }: IOfferLetterTemplateRow){
            let value = 'All';
            if (!all_clients) {
                value = clients.map(client => client.name).join(', ');
            }
            return (
                <PlainText className={className} value={value}/>
            );
        },
    },
    {
        key: 'actions',
        title: '',
        width: '100px',
        render: function ActionsCell({ id, className }: IOfferLetterTemplateRow) {
            const updatingOfferLetterTemplateIds = useSelector(selectUpdatingOfferLetterTemplateIds);
            const deletingOfferLetterTemplateIds = useSelector(selectDeletingOfferLetterTemplateIds);
            const disabled = updatingOfferLetterTemplateIds.includes(id) || deletingOfferLetterTemplateIds.includes(id);

            return (
                <div className={clsx(className, classes.iconCell)}>
                    <DeleteItemButton
                        confirmation="Are you sure you want to delete offer letter template?"
                        id={id}
                        deleteAction={removeOfferLetterTemplate.init}
                        disabled={disabled}
                        permission={Permission.DeleteOfferLetterTemplates}
                    />

                    <EditItemButton
                        id={id}
                        fontSize="small"
                        title="Edit offer template"
                        editAction={setUpdateOfferLetterTemplateModalState}
                        disabled={disabled}
                    />
                </div>
            );
        },
    },
]);
