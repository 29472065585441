import { IError } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/validations/IError';

export const expenseEntryValidation = (dollars: number): IError | null => {

    if (dollars === 0 || isNaN(dollars)) {
        return {
            field: 'dollars',
            type: 'required',
            text: 'An expense amount must be greater than zero.',
        };
    }

    return null;
};

export const expenseEntryValidationString = (dollars: string): IError | null => {
    if (parseFloat(dollars) === 0) {
        return {
            field: 'dollars',
            type: 'required',
            text: 'An expense amount must be greater than zero.',
        };
    }

    return null;
};
