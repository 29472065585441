import { ActionsReturnTypes, createActions, createRequestActions, createSingleAction, RequestType } from 'store/utils';
import {
    IAttachmentObjectURL,
    IFileUpload,
    IUpdateEntryAttach,
} from 'shared/models/Attachments';
import { ICcpAttachment } from '../../../models/CcpTransaction';

const SET_OPEN_NOTE_EDIT_CCP_SIDEBAR = 'ccp/SET_OPEN_NOTE_EDIT_CCP_SIDEBAR';
export const setOpenNoteEditCcpSidebar = createSingleAction<
string, typeof SET_OPEN_NOTE_EDIT_CCP_SIDEBAR>(SET_OPEN_NOTE_EDIT_CCP_SIDEBAR);

const SET_OPEN_RECEIPT_EDIT_CCP_SIDEBAR = 'ccp/SET_OPEN_RECEIPT_EDIT_CCP_SIDEBAR';
export const setOpenReceiptEditCcpSidebar = createSingleAction<
string, typeof SET_OPEN_RECEIPT_EDIT_CCP_SIDEBAR>(SET_OPEN_RECEIPT_EDIT_CCP_SIDEBAR);

const LOAD_CCP_ENTRY_ATTACHMENTS = 'ccp/LOAD_CCP_ENTRY_ATTACHMENTS';
export const loadCcpEntryAttachments = createSingleAction<
string[], typeof LOAD_CCP_ENTRY_ATTACHMENTS>(LOAD_CCP_ENTRY_ATTACHMENTS);

const ADD_NEW_CCP_ATTACHMENT = 'ccp/ADD_NEW_CCP_ATTACHMENT';
const ADD_NEW_CCP_ATTACHMENT_SUCCESS = 'ccp/ADD_NEW_CCP_ATTACHMENT_SUCCESS';
const ADD_NEW_CCP_ATTACHMENT_ERROR = 'ccp/ADD_NEW_CCP_ATTACHMENT_ERROR';

export const addNewCcpAttachment = createActions<IFileUpload, ICcpAttachment, any,
typeof ADD_NEW_CCP_ATTACHMENT, typeof ADD_NEW_CCP_ATTACHMENT_SUCCESS, typeof ADD_NEW_CCP_ATTACHMENT_ERROR
>(
    ADD_NEW_CCP_ATTACHMENT, ADD_NEW_CCP_ATTACHMENT_SUCCESS, ADD_NEW_CCP_ATTACHMENT_ERROR,
);

export const REMOVE_CCP_ATTACHMENT = 'ccp/REMOVE_CCP_ATTACHMENT';
export const REMOVE_CCP_ATTACHMENT_SUCCESS = 'ccp/REMOVE_CCP_ATTACHMENT_SUCCESS';
export const REMOVE_CCP_ATTACHMENT_ERROR = 'ccp/REMOVE_CCP_ATTACHMENT_ERROR';

export const removeCcpAttachment = createActions<ICcpAttachment, string, any,
typeof REMOVE_CCP_ATTACHMENT, typeof REMOVE_CCP_ATTACHMENT_SUCCESS, typeof REMOVE_CCP_ATTACHMENT_ERROR
>(
    REMOVE_CCP_ATTACHMENT, REMOVE_CCP_ATTACHMENT_SUCCESS, REMOVE_CCP_ATTACHMENT_ERROR,
);

export const SET_CCP_FILE_READ = 'ccp/SET_CCP_FILE_READ';
export const setCcpFileRead = createSingleAction<boolean, typeof SET_CCP_FILE_READ>(SET_CCP_FILE_READ);

export const UPDATE_CCP_ATTACHMENTS = 'ccp/SET_UPDATE_ATTACH';
export const updateCcpAttachments = createSingleAction<IUpdateEntryAttach,
typeof UPDATE_CCP_ATTACHMENTS>(UPDATE_CCP_ATTACHMENTS);

export const loadCcpAttachment = createRequestActions<string, IAttachmentObjectURL>(
    RequestType.Get,
    'CCP_ATTACHMENT_URL',
    'ccp',
);

export enum SubmitCcpTransactionsSteps {
    Confirm,
    Results,
}

export const SET_OPEN_SUBMIT_CCP_MODAL = 'ccp/SET_OPEN_SUBMIT_CCP_TRANSACTIONS_MODAL';
export const setOpenSubmitCcpModal = createSingleAction<
boolean, typeof SET_OPEN_SUBMIT_CCP_MODAL>(SET_OPEN_SUBMIT_CCP_MODAL);

const SET_CONFIRM_SUBMIT_CCP_MODAL = 'ccp/SET_CONFIRM_SUBMIT_CCP_TRANSACTIONS_MODAL';
export const setConfirmSubmitCcpModal = createSingleAction<
boolean, typeof SET_CONFIRM_SUBMIT_CCP_MODAL>(SET_CONFIRM_SUBMIT_CCP_MODAL);

const SET_SUBMIT_CCP_TRANSACTIONS_STEP = 'ccp/SET_SUBMIT_CCP_TRANSACTIONS_STEP';
export const setSubmitCcpStep = createSingleAction<
SubmitCcpTransactionsSteps, typeof SET_SUBMIT_CCP_TRANSACTIONS_STEP>(SET_SUBMIT_CCP_TRANSACTIONS_STEP);

export type CcpAttachmentActions = ActionsReturnTypes<typeof addNewCcpAttachment>
| ActionsReturnTypes<typeof removeCcpAttachment>
| ReturnType<typeof setCcpFileRead>
| ReturnType<typeof updateCcpAttachments>
| ReturnType<typeof setConfirmSubmitCcpModal>
| ReturnType<typeof setOpenSubmitCcpModal>;
