import {
    defaultInfinityScrollState,
    IInfinityScrollState, isLoadingReducer,
    itemsByIdReducer,
    singleValueReducer,
} from '../../../../../store/reducerUtils';
import {
    CcpTransactionsActions,
    getCcpInvoices,
    getMoreCcpInvoicesAction, setCcpCheckedItems, submitReconciledInvoiceWithCsvResult,
} from '../../../store/actions';
import { ICcpInvoicesResponse } from '../../../models/ccpModel';
import { initialLoadCcpInvoicesPage, setOpenCcpSubmitInvoicesModal } from './actions';
import { ICcpInvoiceBatch } from '../../../models/CcpTransaction';
import { combineReducers } from 'redux';

export function ccpInvoicesTable(
    state: IInfinityScrollState<string>,
    action: CcpTransactionsActions,
): IInfinityScrollState<string> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case initialLoadCcpInvoicesPage.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getCcpInvoices.successType:
            return {
                ...state,
                isLoading: true,
            };
        case getMoreCcpInvoicesAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreCcpInvoicesAction.successType: {
            const payload = action.payload as ICcpInvoicesResponse;
            const newItems = [
                ...state.items,
                ...payload.items.map(entry => entry.id),
            ];
            return {
                total: payload.total_items,
                items: newItems,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}

const checkedCcpItems = singleValueReducer(setCcpCheckedItems.action, {});

const ccpTransactionsById = itemsByIdReducer<ICcpInvoiceBatch, ICcpInvoiceBatch>(getCcpInvoices);

const isOpenCcpSubmitInvoiceModal = singleValueReducer(setOpenCcpSubmitInvoicesModal.action, false);

const isSubmittingInvoice = isLoadingReducer(submitReconciledInvoiceWithCsvResult, false);

export const ccpInvoices = combineReducers({
    isSubmittingInvoice,
    ccpInvoicesTable,
    ccpTransactionsById,
    checkedCcpItems,
    isOpenCcpSubmitInvoiceModal,
},
);
