export function horizontalScrollBy(domElement: HTMLElement, x: number) {
    if (!isFinite(x)) {
        return;
    }
    if (domElement.scrollBy) {
        domElement.scrollBy(x, 0);
    } else {
        domElement.scrollLeft += x;
    }
}
