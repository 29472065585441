import React from 'react';
import { useSelector } from 'react-redux';
import { SyncMessagePure } from 'shared/components/toolbar/SyncMessagePure';
import { selectUiSyncingByModule } from 'store/entities/appConfig/selectors';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';

interface ISyncMessage {
    syncingKey: SyncingModels;
    classes?: Partial<{
        wrapper: string;
    }>;
}

export const SyncMessage = ({ syncingKey, classes }: ISyncMessage) => {
    const syncingAmount = useSelector(selectUiSyncingByModule(syncingKey));

    if (syncingAmount > 0) {
        return (<SyncMessagePure classes={classes}/>);
    }
    return null;
};
