import { IGlobalRole, IGlobalRoleBackend, IUserInfo } from 'shared/models/User';
import { keyBy } from 'lodash-es';

export const getUsersById = (list: IUserInfo[]) => {
    return keyBy(list, user => user.identity_id);
};

export const getWildcardMask = (name: string) => `%${name}%`;

export const normalizeRolePermissions = (role: IGlobalRoleBackend): IGlobalRole => {
    return {
        ...role,
        permissions: role.permissions.map(permission => permission.id),
    };
};
