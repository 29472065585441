import React, { useCallback, useContext } from 'react';
import { Checkbox } from '@material-ui/core';
import useCheckBoxStyles from 'shared/components/table/tableApprovalCells/CheckBox/CheckBoxStyles';
import { useDisplayOnGridCheckBoxStyles } from 'modules/settings/submodules/components/HierarchyPage/HierarchyTable/components/DisplayOnGridCheckbox/styles';
import { HierarchyContext } from 'modules/settings/submodules/components/HierarchyPage/HierarchyListsContext';

interface IDisplayOnGridCheckBoxProps {
    isChecked: boolean;
    id: string;
    disabled?: boolean;
}

export default function DisplayOnGridCheckBox({ isChecked, id, disabled }: IDisplayOnGridCheckBoxProps) {
    const initialClasses = useCheckBoxStyles();
    const classes = useDisplayOnGridCheckBoxStyles();
    const { hierarchyList, setHierarchyList } = useContext(HierarchyContext);

    const handleCheckChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setHierarchyList(hierarchyList.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    hierarchyNode: {
                        ...item.hierarchyNode,
                        display_on_grid: newValue,
                    },
                };
            }
            return item;
        }));
    }, [id, hierarchyList, setHierarchyList]);

    return (
        <Checkbox
            checked={isChecked}
            onChange={handleCheckChange}
            disabled={disabled}
            color="default"
            size={'small'}
            classes={{ checked: initialClasses.root, root: classes.root }}
        />
    );
}
