import clsx from 'clsx';
import { FormikProps } from 'formik';
import React, { useEffect, useMemo, useRef } from 'react';
import { Box, ThemeProvider } from '@material-ui/core';
import { IWithInputFields, showField, useEffectWithSkipInitialChange } from 'shared/components/forms/utils';
import ScaZoneSeparated from 'shared/components/formSpecialFields/scaZone/ScaZoneSeparated';
import { BreakInOutTimeField } from 'shared/components/formSpecialFields/TimeEntryField/BreakInOutTimeField';
import TimeEntryField from 'shared/components/formSpecialFields/TimeEntryField/TimeEntryField';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import FormField from 'shared/components/formFields/FormField';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { createSidebarDarkTheme } from 'shared/components/sidebars/EntryEdit/sidebarDarkSectionTheme';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { selectConfigurationThemeById } from 'store/entities/clients/selectors/themeSelectors';
import { CommonEntrySidebarFields } from './CommonEntrySidebarFields';
import { ICommonEntryForm } from './interfaces';
import { defaultValues, ITimeEntryFormValues } from './TimeEntryModel';
import { EntrySlug } from 'store/entities/clients/clientsModel';
import { useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import CompletesQuantity from 'shared/components/formFields/CompletesQuantity';
import TimeEntryEditField from '../../formSpecialFields/TimeEntryField/TimeEntryEditField';
import { PREDEFINED_TIME_BREAKS, PREDEFINED_TIME_IN_OUT } from 'store/entities/timesheet/models/Entry';
import { TimesheetSettings } from 'shared/models/JobNumber';
import TimeField from 'shared/components/formSpecialFields/TimeEntryField/TimeField';
import TimeEntryFileField from 'shared/components/formSpecialFields/TimeEntryField/TimeEntryFileField';
import { useJobNumberActivities } from 'shared/components/forms/entries/helpers/commonEntryFormHelpers';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

export interface ITimeEntryFormProps extends FormikProps<ITimeEntryFormValues>, ICommonEntryForm, IWithInputFields {
    payPeriod: IPayPeriod;
}

export function TimeEntrySidebarForm({
    supervisorId,
    areaId,
    setFieldValue,
    handleSubmit,
    sheetId,
    inputs,
    userId,
    payPeriod,
    ...props
}: ITimeEntryFormProps) {
    const classes = useEntryEditStyles();
    const sidebarStyles = useSidebarStyles();
    const {
        projectAssignment, activity, notes, jobNumber,
    } = props.values;
    const assignmentId = projectAssignment?.assignment?.id;
    const clientId = useSelector(selectCurrentClientId);
    const configuration = useSelector(selectConfigurationThemeById(clientId ?? ''));
    const darkTheme = useMemo(() => {
        return createSidebarDarkTheme(configuration?.theme?.primary_color);
    }, [configuration?.theme?.primary_color]);

    useEffectWithSkipInitialChange(() => {
        setFieldValue('scaZone', defaultValues.scaZone);
    }, [projectAssignment?.sca_zone_id, setFieldValue]);

    const jobNumberActivities = useJobNumberActivities(EntryType.TIME, jobNumber);
    useEffect(() => {
        if (jobNumber && !jobNumberActivities?.includes(activity?.id || '')){
            setFieldValue('activity', defaultValues.activity);
        }
    }, [jobNumber, jobNumberActivities, activity, setFieldValue]);

    const { current: predefinedTimeInOut } = useRef(PREDEFINED_TIME_IN_OUT);
    const { current: predefinedTimeBreaks } = useRef(PREDEFINED_TIME_BREAKS);

    const quantityType = useMemo(() => {
        if (jobNumber) {
            return jobNumber.timesheet_setting === TimesheetSettings.PerFile
                ? QuantityType.FILE
                : activity?.data_type;
        }
        return activity?.data_type;
    }, [activity, jobNumber]);

    const activityNotSelectedYet = !activity;

    const isDataFieldVisibleByOtherFields = useMemo(() => {
        if ( activityNotSelectedYet ) {
            return false;
        }
        if (showField(inputs, EntrySlug.JobNumber)) {
            return !!jobNumber;
        }
        return true;
    }, [inputs, jobNumber, activityNotSelectedYet]);

    return (
        <form
            onSubmit={handleSubmit}
            className={classes.form}
            autoComplete="off"
        >
            <CommonEntrySidebarFields
                values={props.values}
                setFieldValue={setFieldValue}
                supervisorId={supervisorId}
                areaId={areaId}
                sheetId={sheetId}
                userId={userId}
                entryType={EntryType.TIME}
                inputs={inputs}
                payPeriod={payPeriod}
            />

            {isDataFieldVisibleByOtherFields && (
                <section className={clsx(classes.entryDataEdit, classes.entityEdit)}>
                    <ThemeProvider theme={darkTheme}>
                        {(() => {
                            switch (quantityType) {
                                case QuantityType.TIME_IN_OUT:
                                case QuantityType.TIME_IN_OUT_BREAK:
                                case QuantityType.TIME_IN_OUT_MEAL_BREAK:
                                    return (
                                        <TimeEntryEditField
                                            name="data"
                                            options={predefinedTimeInOut}
                                            entryType={quantityType}
                                        />
                                    );
                                case QuantityType.TIME_BREAK:
                                    return (
                                        <TimeEntryEditField
                                            name="data"
                                            options={predefinedTimeBreaks}
                                            entryType={quantityType}
                                        />
                                    );
                                case QuantityType.FILE:
                                    return (
                                        <TimeEntryFileField
                                            name="data"
                                            label="Total files"
                                        />
                                    );
                                default:
                                    return (
                                        <TimeEntryField name="data" label="time"/>
                                    );
                            }
                        })()}
                    </ThemeProvider>
                </section>
            )}

            {quantityType === QuantityType.TIME_IN_OUT_BREAK && (
                <Box
                    mt={2}
                    className={sidebarStyles.sidebarElement}
                >
                    <TimeField
                        name="data"
                        label="Break time"
                        minutesField="minutesBreak"
                        hoursField="hoursBreak"
                        entryType={QuantityType.TIME_IN_OUT_BREAK}
                        className={classes.breakTime}
                        hideError
                    />
                </Box>
            )}

            {quantityType === QuantityType.TIME_IN_OUT_MEAL_BREAK && (
                <Box
                    mt={2}
                    className={sidebarStyles.sidebarElement}
                >
                    <BreakInOutTimeField
                        name="data"
                        entryType={quantityType}
                        classes={{ inputs: classes.breakInOutTime }}
                        className={classes.breakInOutTime}
                        label="Total"
                    />
                </Box>
            )}

            {showField(inputs, EntrySlug.Notes) && (
                <>
                    <FormField
                        name="notes"
                        label={activityHasCompletes(activity) ? 'Case Numbers' : inputs.notes.placeholder}
                        multiline
                        className={classes.notesInputWrapper}
                        classes={{ multiline: classes.notesInput }}
                    />

                    {activityHasCompletes(activity) && (
                        <Box
                            mt={2}
                            className={sidebarStyles.sidebarElement}
                        >
                            <CompletesQuantity
                                name="completes"
                                label="Completes"
                                key={notes}
                                notes={notes}
                            />
                        </Box>
                    )}
                </>
            )}

            {projectAssignment?.sca_zone_id && (
                <section className={sidebarStyles.sidebarElement}>
                    <ScaZoneSeparated
                        name="scaZone"
                        label="SCA Zone"
                        parentScaZoneId={projectAssignment?.sca_zone_id}
                        assignmentId={assignmentId}
                    />
                </section>
            )}
        </form>
    );
}
