import { getInvoiceRecipientsWatcher } from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/sagas';
import { createDealWatcher } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/sagas';
import { getMoreDealsSagaWatcher } from 'modules/settings/submodules/clients/deals/components/DealsTable/store/sagas';
import { updateDealWatcher } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/sagas';
import { updateDealStatusWatcher } from 'modules/settings/submodules/clients/deals/components/UpdateStatusSelect/store/sagas';
import { createDealTypeWatcher } from 'modules/settings/submodules/clients/dealType/store/sagas';

export const dealSagas = [
    createDealWatcher,
    updateDealWatcher,
    updateDealStatusWatcher,
    createDealTypeWatcher,
    getInvoiceRecipientsWatcher,
    getMoreDealsSagaWatcher,
];
