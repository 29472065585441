import { isEmpty, orderBy } from 'lodash-es';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { ICreateCustomFieldHierarchyNode } from 'modules/settings/submodules/components/HierarchyPage/store/models';
import { isDateInPeriod } from 'shared/models/Dates';
import { ValidationMessages } from 'shared/models/Validation';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { HierarchyNodeOperation, ICustomFieldValue } from 'store/entities/customFields/model';
import * as yup from 'yup';
import { ICommonEntryFormValues } from '../../components/forms/entries/EntryCommonFields';
import { showField } from '../../components/forms/utils';

export type DefaultShapeType = yup.ObjectSchema<any> | yup.StringSchema | yup.Lazy

export type CommonEntryShapeType = Partial<Record<keyof ICommonEntryFormValues, DefaultShapeType>>

export function getSubassignmentByFormValues(
    customFieldValues: Record<string, string>,
    entryDate: string,
    userSubassignments: ISubassignment[],
    customFieldValuesByIds: Record<string, ICustomFieldValue>,
): ISubassignment | null {
    const selectedCustomFieldValues = Object.values(customFieldValues).filter(Boolean);
    let filteredSubassgnment = userSubassignments.filter(
        subassignment => isDateInPeriod(
            subassignment.start_date,
            subassignment.end_date,
            entryDate,
        ),
    );
    filteredSubassgnment = orderBy(
        filteredSubassgnment,
        subassignment => [...subassignment.custom_field_value_ids, ...subassignment.all_values_custom_field_ids].length,
    );

    return filteredSubassgnment.find(
        subassignment => selectedCustomFieldValues.every(customFieldValueId => {
            const customFieldValue = customFieldValuesByIds[customFieldValueId];
            return !customFieldValues[customFieldValue?.custom_field_id]
                // @ts-ignore
                || subassignment.custom_field_value_ids.includes(customFieldValueId)
                // @ts-ignore
                || subassignment.all_values_custom_field_ids.includes(customFieldValue?.custom_field_id);
        }),
    ) || null;
}

export function getSubassignmentFieldIds(
    subassignment: ISubassignment | null,
    customFieldValuesByIds: Record<string, ICustomFieldValue>,
): string[] {
    if (!subassignment) {
        return [];
    }
    return [
        ...((subassignment?.custom_field_value_ids || []).map(
            id => customFieldValuesByIds[id]?.custom_field_id,
        ).filter(Boolean)),
        ...(subassignment?.all_values_custom_field_ids || []),
    ];
}

function getCustomFieldValidationSchemaBySubassignment(
    customFieldValues: Record<string, string>,
    entryDate: string,
    customFieldsIds: string[],
    userSubassignments: ISubassignment[],
    customFieldValuesByIds: Record<string, ICustomFieldValue>,
    actionableCustomFields: ICreateCustomFieldHierarchyNode[],
) {
    /**
     * For supporting optional department validation schema based on subassignment field values
     */
    const selectedSubassignmentCustomFieldIds = getSubassignmentFieldIds(
        getSubassignmentByFormValues(
            customFieldValues,
            entryDate,
            userSubassignments,
            customFieldValuesByIds,
        ),
        customFieldValuesByIds,
    );

    const subassignmentCustomFieldsIds = customFieldsIds.filter(customFieldId => {
        const isActionable = actionableCustomFields.find(
            node => node.custom_field_id === customFieldId,
        )?.operation === HierarchyNodeOperation.Actionable;
        return (isEmpty(selectedSubassignmentCustomFieldIds)
                || selectedSubassignmentCustomFieldIds.includes(customFieldId))
            && isActionable;
    });
    return yup.object().shape(
        subassignmentCustomFieldsIds.reduce((mem, fieldId) => {
            return {
                ...mem,
                [fieldId]: yup.string().nullable().required(ValidationMessages.REQUIRED),
            };
        }, {}),
    );
}

export function addCommonFields(
    shape: CommonEntryShapeType,
    fields: InputFields,
    userDepartments: IDepartment[],
    applyCustomFieldValidation: boolean,
    customFieldsIds: string[],
    userSubassignments: ISubassignment[],
    customFieldValuesByIds: Record<string, ICustomFieldValue>,
    actionableCustomFields: ICreateCustomFieldHierarchyNode[],
): CommonEntryShapeType {
    shape.entry_date = yup.string().required(ValidationMessages.REQUIRED);

    if (showField(fields, EntrySlug.Assignment)) {
        shape.projectAssignment = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Task)) {
        shape.taskId = yup.string().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.JobNumber)) {
        shape.jobNumber = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Activity)) {
        shape.activity = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Position)) {
        shape.position = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Location)) {
        shape.location = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Department) && userDepartments.length > 1) {
        shape.department = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Notes)) {
        shape.notes = yup.string().max(300, ValidationMessages.NOTES_LENGTH);
    }

    //TODO: Since all clients used custom fields param applyCustomFieldValidation can be omitted in future
    if (applyCustomFieldValidation && !showField(fields, EntrySlug.JobNumber)) {
        shape.customFieldValues = yup.lazy(
            (customFieldValues: Record<string, string>): yup.ObjectSchema<Record<string, string>> => {
                return yup.object().when(
                    'entry_date',
                    (entryDate: string, schema: yup.ObjectSchema<Record<string, string>>) => {
                        if (!entryDate) {
                            return schema.nullable();
                        }
                        return getCustomFieldValidationSchemaBySubassignment(
                            customFieldValues,
                            entryDate,
                            customFieldsIds,
                            userSubassignments,
                            customFieldValuesByIds,
                            actionableCustomFields,
                        );
                    },
                ) as yup.ObjectSchema<Record<string, string>>;
            },
        );
    }

    return shape;
}
