import baseApi from 'shared/utils/baseApi';
import { IImpersonateResponse } from 'store/components/auth/authModels';

export const api = {
    async getImpersonateUserToken(userId: string, clientId?: string): Promise<IImpersonateResponse> {
        const query = clientId ? `?client_id=${clientId}` : '';
        const response = await baseApi
            .post<{}, IImpersonateResponse>(`/client-users/users/${userId}/impersonate${query}`, {});
        return response.data;
    },
};
