import { useField } from 'formik';
import React from 'react';
import NumberInput from 'shared/components/formFields/NumberInput';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { Typography, FormHelperText, Box } from '@material-ui/core';
import { QuantityType } from 'shared/models/sheet/Sheet';

interface ITimeEntryFileFieldProps extends IFormFieldProps {}

export default function TimeEntryFileField({
    name,
    label,
}: ITimeEntryFileFieldProps) {
    const classes = useEntryEditStyles();
    const [field, meta, helper] = useField(name);

    const error = meta.touched ? meta.error : undefined;

    return (
        <>
            <Typography
                classes={{ root: classes.timeInputsHeader }}
                color="primary"
                variant="h6"
            >
                {label}
            </Typography>
            <div className={classes.timeInputs}>
                <NumberInput
                    initialValue={field.value?.files}
                    setTouched={helper.setTouched}
                    onChange={files => helper.setValue({
                        files: parseInt(files, 10) || 0,
                        entry_type: QuantityType.FILE,
                    })}
                    className={classes.timeInput}
                    name="data"
                    min={0}
                    max={100}
                />
            </div>
            {error && (
                <Box mb={2} mt={-1}>
                    <FormHelperText error>{error}</FormHelperText>
                </Box>
            )}
        </>
    );
}
