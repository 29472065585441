import clsx from 'clsx';
import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { useDispatch } from 'react-redux';
import { useIsUserHasApprovePermissionByEafId } from 'modules/eafApproval/store/hooks';
import { setEafApproveIds } from 'modules/eafApproval/store/actions';
import ActionButton from 'shared/components/buttons/ActionButton';
import { useApprovalTableStyles } from 'shared/components/table/TableApprovalStyles';

export interface IApproveEafButtonProps {
    eafIds: string[];
    compact?: boolean;
    isLoading?: boolean
}

export const ApproveEafButton = ({ eafIds, compact = false, isLoading = false }: IApproveEafButtonProps) => {
    const dispatch = useDispatch();
    const handleOpenEafConfirmation = useCallback(() => {
        dispatch(setEafApproveIds(eafIds));
    }, [dispatch, eafIds]);

    const initialClasses = useApprovalTableStyles();
    const userHasPermission = useIsUserHasApprovePermissionByEafId(eafIds[0]);

    if (!userHasPermission) {
        return null;
    }

    if (compact) {
        return (
            <IconButton onClick={handleOpenEafConfirmation}>
                <DoneIcon className={clsx(initialClasses.actionIcon, initialClasses.approveIcon)}/>
            </IconButton>
        );
    }

    return (
        <ActionButton
            variant="contained"
            color="primary"
            customType="success"
            onClick={handleOpenEafConfirmation}
            isLoading={isLoading}
            disabled={isLoading}
        >
            Accept
        </ActionButton>
    );
};
