import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    getCustomFieldsDisplayValue,
    selectSubassignmentsGroupedRows,
} from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import {
    selectAssignmentsById,
    selectSubassignmentsByIds,
} from 'store/entities/configuration/configurationSelectors';
import {
    selectAnchorCustomFieldId,
    selectCustomFieldValuesByIds,
} from 'store/entities/customFields/selectors';

export const useSubassignmentTitle = (subassignmentId?: string | null) => {
    const assignmentsByIds = useSelector(selectAssignmentsById);
    const subassignmentsByIds = useSelector(selectSubassignmentsByIds);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const anchorCustomFieldId = useSelector(selectAnchorCustomFieldId);
    const groups = useSelector(selectSubassignmentsGroupedRows);

    const subassignment = subassignmentsByIds[subassignmentId];
    const assignment = assignmentsByIds[subassignment?.assignment_id];

    const anchor = getCustomFieldsDisplayValue(
        anchorCustomFieldId,
        customFieldValuesByIds,
        subassignment?.custom_field_value_ids || [],
        subassignment?.all_values_custom_field_ids || [],
    );

    const rowIndex = useMemo(() => {
        const assignmentGroup = groups.find(group => group.id === assignment?.id);
        const row = assignmentGroup?.rows.find(item => item.id === subassignment?.id);
        return row?.rowIndex || 0;
    }, [assignment?.id, groups, subassignment?.id]);

    if (anchor) {
        return anchor;
    }

    return subassignment ? `Sub-assignment #${rowIndex + 1}` : 'Main assignment';
};
