import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { IClientRoleManagePayload } from 'modules/settings/submodules/platformUsers/store/models';
import {
    createClientRole,
    setCreateClientRoleModalState,
} from 'modules/settings/submodules/platformUsers/store/actions';
import {
    selectCreateClientRoleModalOpen,
    selectIsClientRoleCreating,
} from 'modules/settings/submodules/platformUsers/store/selectors';
import {
    ClientRoleForm,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleForm/ClientRoleForm';

export function CreateUserModal() {
    const classes = useColoredFormModalStyles();
    const dispatch = useDispatch();
    const isOpen = useSelector(selectCreateClientRoleModalOpen);
    const isLoading = useSelector(selectIsClientRoleCreating);

    const onClose = useCallback(() => {
        dispatch(setCreateClientRoleModalState(false));
    }, [dispatch]);
    const onSave = useCallback((values: IClientRoleManagePayload) => {
        dispatch(createClientRole.init(values));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Configure Role & Permissions"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <ClientRoleForm
                    onSubmit={onSave}
                    submitTitle="Create"
                    isLoading={isLoading}
                />
            </Box>
        </ModalDialog>
    );
}
