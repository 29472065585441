import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { IBaseCommonEntryFieldsProps } from 'shared/components/forms/entries/CommonEntryInlineFields';
import { IExpenseEntryCreate } from 'store/entities/timesheet/models/Entry';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { selectSheet, selectExpenseStatusIdByName } from 'store/entities/timesheet/selectors';
import { addExpenseEntry } from 'store/entities/timesheet/actions/expenseActions';
import { useExpenseSchema } from 'shared/models/validationSchemes/expenseEntry';
import {
    defaultValues,
    IExpenseEntryFormValues,
} from 'shared/components/forms/entries/ExpenseEntryModel';
import { ExpenseEntryInlineForm } from 'shared/components/forms/entries/AddExpenseEntryInlineForm';
import { InputFields } from 'store/entities/clients/clientsModel';
import { useExpenseEntryDefaultValues, useGetCommonEntryModel } from 'shared/utils/helpers/entries';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';
import { StatusNames } from 'store/entities/timesheet/models/Status';

interface IAddTimeEntryProps extends IBaseCommonEntryFieldsProps {
    payPeriod: IPayPeriod;
    customClasses?: Record<'paper', string>;
    sheetId?: string;
    statusName?: StatusNames;
    inputs: InputFields;
    userId: string;
    disabled?: boolean;
    onChangeCommonValues: (values: ICommonEntryFormValues) => void;
}

export default function AddExpenseEntry({
    payPeriod,
    sheetId,
    statusName,
    inputs,
    disabled = false,
    ...formProps
}: IAddTimeEntryProps) {
    const statusId = useSelector(selectExpenseStatusIdByName(statusName || ''));
    const dispatch = useDispatch();
    const areaId = useSelector(selectSheet(sheetId))?.area_id;

    const { userId } = formProps;
    const schema = useExpenseSchema(userId);
    const initialValues = useExpenseEntryDefaultValues(userId);
    const defaultTask = inputs.task && inputs.task.default_value;
    const getCommonEntryModel = useGetCommonEntryModel(userId, defaultTask);

    const onSubmit = useCallback((
        values: IExpenseEntryFormValues,
        formikActions: FormikHelpers<IExpenseEntryFormValues>,
    ) => {
        const baseEntryModel = getCommonEntryModel(values);
        if (baseEntryModel && values.data) {
            const entry: IExpenseEntryCreate = {
                ...baseEntryModel,
                data: values.data,
                zip_code: values.zipCode || undefined,
                sheet_entry_attachments: [],
                // @ts-ignore
                status_id: statusId,
                // @ts-ignore
                user_id: userId,
                period_start: payPeriod.period_start,
                // @ts-ignore
                period_end: payPeriod.period_end,
            };
            dispatch(addExpenseEntry.init(entry));
            formikActions.setFieldValue('data', defaultValues.data);
            formikActions.setFieldValue('notes', defaultValues.notes);
            formikActions.setTouched({
                data: false,
                notes: false,
            }, false);
        }
        formikActions.setSubmitting(false);
    }, [dispatch, getCommonEntryModel, payPeriod, statusId, userId]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            /* we need to re-render entry form after update initial values shape (custom field values shape)
            It is required for Formik touched behavior on form submission */
            key={`initialValuesKey-${Object.keys(initialValues?.customFieldValues || {}).join('-')}`}
        >
            {props => (
                <>
                    <ExpenseEntryInlineForm
                        {...formProps}
                        {...props}
                        payPeriod={payPeriod}
                        areaId={areaId}
                        inputs={inputs}
                        disabled={disabled}
                    />
                </>
            )}
        </Formik>
    );
}
