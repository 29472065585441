import React from 'react';
import { Switch } from 'react-router-dom';
import { routes } from 'shared/routes';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { EditPayrollSingleGroupPage } from 'modules/payrollProcessorHub/components/EditSheet/EditPayrollSingleGroupPage';
import { PayrollDashboard } from 'modules/payrollProcessorHub/PayrollDashboard/PayrollDashboard';
import { PayrollSheets } from 'modules/payrollProcessorHub/PayrollSheets';

export const PayrollProcessorHubRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path={routes.PAYROLL_PROCESSOR_HUB.EDIT_SHEET}
                component={EditPayrollSingleGroupPage}
            />
            <PrivateRoute
                path={routes.PAYROLL_PROCESSOR_HUB.SHEETS}
                component={PayrollSheets}
            />
            <PrivateRoute
                path={routes.PAYROLL_PROCESSOR_HUB.ROOT}
                component={PayrollDashboard}
                exact
            />
        </Switch>
    );
};
