import { Nullable } from 'types/types';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';

export function useCompensationChangeData(
    assignment: Nullable<IAssignment>,
    subassignment: Nullable<ISubassignment>,
) {
    const endDate = subassignment?.end_date || assignment?.end_date || undefined;
    const payRateType = assignment?.pay_rate_type;
    const payType = assignment?.pay_type;
    const payRateValue = subassignment?.override_rate_value || assignment?.pay_rate_value || '';

    return { endDate, payRateType, payType, payRateValue };
}
