import { Box } from '@material-ui/core';
import React from 'react';
import { HeaderTabs, IHeaderTab } from 'shared/components/common/headerTabs/HeaderTabs';
import { useTabManagement } from 'shared/hooks/useTabManagement';
import { setEcubReportActiveTab } from 'modules/settings/submodules/clients/reports/Ecub/store/actions';
import {
    EcubStatusDisplayName,
    EcubStatusSlug,
} from 'modules/settings/submodules/clients/reports/Ecub/store/models';
import { selectEcubReportActiveTab } from 'modules/settings/submodules/clients/reports/Ecub/store/selectors';

export const EcubStatuses = () => {
    const { activeTab, setActiveTab } = useTabManagement<EcubStatusSlug>(
        selectEcubReportActiveTab,
        setEcubReportActiveTab,
    );

    const ecubTabs: Array<IHeaderTab> = (
        Object
            .entries(EcubStatusDisplayName)
            .map(([value, label]) => ({ value, label }))
    );

    return (
        <Box mb={1}>
            <HeaderTabs
                tabs={ecubTabs}
                mobileHeader="eCub reports"
                value={activeTab}
                onChange={setActiveTab}
            />
        </Box>
    );
};
