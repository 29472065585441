import clsx from 'clsx';
import React from 'react';
import {
    Box, Button,
    Hidden,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
    useOfferLetterStyles,
} from 'modules/offerLetter/OfferLetterStyles';
import { NavLink } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useIsMobile } from 'shared/utils/hooks/media';

export interface IOnboardingHeaderProps {
    title: string;
    icon?: React.ReactNode;
    className?: string;
    hideCloseButton?: boolean;
    children?: React.ReactNode;
}

export const OnboardingHeader = ({
    title,
    icon,
    className,
    children,
}: IOnboardingHeaderProps) => {
    const classes = useOfferLetterStyles();
    const isMobile = useIsMobile();
    const continueLaterButton = (
        <NavLink
            to={routes.EMPLOYEE_OFFER_LETTER.ROOT}
            component={Button}
            color="primary"
            // @ts-ignore
            variant="outlined"
        >
            Continue Later
        </NavLink>
    );

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            className={clsx(classes.headerWrapper, className)}
        >
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Hidden xsDown>
                        {icon}
                    </Hidden>
                    <Typography className={classes.headerText}>
                        {title}
                    </Typography>
                </Box>
                {isMobile && (
                    <NavLink className={classes.link} to={routes.EMPLOYEE_OFFER_LETTER.ROOT}>
                        <Close fontSize="small" classes={{ root: classes.closeIcon }}/>
                    </NavLink>
                )}
            </Box>
            {!isMobile && (
                <Box className={classes.headerButtonsContainer}>
                    {continueLaterButton}
                    {children}
                </Box>
            )}
        </Box>
    );
};
