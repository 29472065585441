import { selectDetailAssignmentId } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    selectAssignmentsRows,
} from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { useAssignmentCells } from 'modules/subassignmentManagement/components/SubassignmentTable/cells';
import { ISubassignmentRow, ISubassignmentTableProps } from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useTableStyles } from 'shared/styles/tableStyles';

export const AssignmentTable = (props: ISubassignmentTableProps) => {
    const rows = useSelector(selectAssignmentsRows);
    const cells = useAssignmentCells(rows);
    const tableClasses = useTableStyles();
    const assignmentDetailId = useSelector(selectDetailAssignmentId);

    const getRowClasses = useCallback((row: ISubassignmentRow) => {
        if (assignmentDetailId === row.id) {
            return [tableClasses.highlightedRow];
        }
        return [];
    }, [tableClasses, assignmentDetailId]);

    return (
        <InfinityGridTable
            {...props}
            rowData={rows}
            cells={cells}
            getKey={row => row.id}
            headerCellClassName={tableClasses.headerLightGrayCell}
            getRowClasses={getRowClasses}
            infiniteScrollProps={{
                scrollableTarget: props.containerId,
            }}
        />
    );
};
