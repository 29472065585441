/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Total, { TotalColor, TotalTypes } from 'shared/components/toolbar/total/Total';
import { useCurrentClientMileageRate } from 'shared/models/Miles';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import {
    timeCounter,
    totalBreakTime,
    totalFiles,
    totalTime,
} from 'shared/utils/counters/timeCounter';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { AvailableTotalConfiguration, ITotalConfiguration, TotalSlug } from 'store/entities/clients/clientsModel';
import { useModifiedTotalConfiguration, useModifiedTotalConfigurationByType } from 'store/entities/clients/hooks';
import { selectActivitiesById } from 'store/entities/configuration/configurationSelectors';
import { useSheetDetailStyles } from './styles';

interface ITotalBlockProps {
    entryType: EntryType;
    entries: IEntry[];
    totalReg: string;
    totalOvertime: string;
    totalDoubletime: string;
    totalHolidays: string;
    totalPto: string;
    isSalaried?: boolean;
    isGrouped?: boolean;
}

interface ITotalElement {
    slug: TotalSlug;
    type: TotalTypes;
    formattedValue: string;
    color?: TotalColor;
    label?: string;
    hide?: boolean;
}

const defaultTimeConfig: ITotalConfiguration[] = [
    {
        slug: TotalSlug.Time,
    },
    {
        slug: TotalSlug.Break,
    },
    {
        slug: TotalSlug.Completes,
        unitLabel: 'completes',
    },
];

const useConfiguration = (entryType: EntryType) => {
    const defaultConfiguration = entryType === EntryType.TIME ? defaultTimeConfig : defaultExpenseConfig;

    const clientConfiguration = useModifiedTotalConfigurationByType(AvailableTotalConfiguration.TimeSheetDetail);
    const hasConfiguration = clientConfiguration.length > 0;
    const baseConfiguration = hasConfiguration && entryType === EntryType.TIME
        ? clientConfiguration : defaultConfiguration;
    return useModifiedTotalConfiguration(baseConfiguration);
};

const defaultExpenseConfig: ITotalConfiguration[] = [
    {
        slug: TotalSlug.Expense,
    },
];

export function TotalBlock({
    entryType,
    entries,
    totalReg,
    totalOvertime,
    totalDoubletime,
    totalHolidays,
    totalPto,
    isSalaried = false,
    isGrouped = false,
}: ITotalBlockProps) {
    const classes = useSheetDetailStyles();
    const configuration = useConfiguration(entryType);

    const activitiesById = useSelector(selectActivitiesById);
    const mileageRate = useCurrentClientMileageRate();
    const formattedValue = EntryType.TIME === entryType ? timeCounter(totalTime(entries))
        : totalDollars(entries, mileageRate);
    const timeBreak = EntryType.TIME === entryType ? totalBreakTime(entries) : 0;
    const totalFilesValue = EntryType.TIME === entryType ? totalFiles(entries) : 0;
    const completesAmount = useMemo(() => {
        return entries.filter(
            entry => activityHasCompletes(activitiesById[entry.activity_id ?? '']),
        ).reduce(
            (acc: number, entry) => acc + completesByNotes(entry.notes || ''), 0,
        );
    }, [activitiesById, entries]);

    const hideIfHasFiles = totalFilesValue > 0 && !isGrouped; //Show time for grouped

    const totalBlocksDictionary: Record<string, ITotalElement> = {
        [TotalSlug.Time]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            formattedValue: formattedValue as string,
            hide: hideIfHasFiles || isSalaried,
        },
        [TotalSlug.Break]: {
            slug: TotalSlug.Break,
            type: TotalTypes.Break,
            formattedValue: formatMinutes(timeBreak),
            hide: timeBreak === 0 || hideIfHasFiles,
        },
        [TotalSlug.Expense]: {
            slug: TotalSlug.Expense,
            type: TotalTypes.Expense,
            formattedValue: formattedValue as string,
            label: 'total amount',
        },
        [TotalSlug.TotalReg]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            formattedValue: totalReg,
            hide: hideIfHasFiles,
        },
        [TotalSlug.OverTime]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            color: TotalColor.Green,
            formattedValue: totalOvertime,
            hide: hideIfHasFiles,
        },
        [TotalSlug.DoubleTime]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            color: TotalColor.Green,
            formattedValue: totalDoubletime,
            hide: hideIfHasFiles,
        },
        [TotalSlug.Pto]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            color: TotalColor.Orange,
            formattedValue: totalPto,
            hide: hideIfHasFiles,
        },
        [TotalSlug.Holidays]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            color: TotalColor.Orange,
            formattedValue: totalHolidays,
            hide: hideIfHasFiles,
        },
        [TotalSlug.Files]: {
            slug: TotalSlug.Files,
            type: TotalTypes.Break,
            color: TotalColor.Orange,
            hide: totalFilesValue === 0,
            formattedValue: `${ totalFilesValue }`,
        },
        [TotalSlug.Completes]: {
            slug: TotalSlug.Completes,
            type: TotalTypes.Time,
            color: TotalColor.Orange,
            hide: completesAmount === 0,
            formattedValue: `${ completesAmount }`,
        },
    };

    const totalBlocks = configuration.map(config => ({
        ...totalBlocksDictionary[config.slug],
        label: config.unitLabel,
    })).filter(config => !config.hide);

    return (
        <Box display="flex" className={classes.totalsBlock}>
            {totalBlocks.map((config: ITotalElement) => (
                <Total
                    {...config}
                    key={config.slug}
                    value={config.formattedValue}
                    classes={{ value: classes.totalNum }}
                />
            ))}
        </Box>
    );
}
