/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getApprovedLevelWithTotalGroupedCell, getDetailsCell, getHoursAmountGroupedCell, getStatusCell, useGroupedSheetColumnDictionary } from 'modules/timeAndExpense/components/GroupedSheetsTable/cells';
import { ISheetGroupRow } from 'modules/timeAndExpense/store/model';
import {
    expenseSheetsSelectors,

    selectTimeAndExpenseActiveStatus,
    timeSheetsSelectors,
} from 'modules/timeAndExpense/store/selectors';
import { setExpenseSheetGroupsChecked, setTimeSheetGroupsChecked } from 'modules/timeAndExpense/store/actions';
import { isApprovedLevelCellAvailable } from 'shared/components/sheetApproval/bodyCells';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { getCheckboxCell } from 'shared/components/table/Cells/checkBoxHelper';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { useExtendedConfiguration } from 'shared/components/table/SheetSupervisorTable/useExtendedConfiguration';
import { getMobileItemsRowByConfig } from 'shared/components/table/utils';
import { EntryType } from 'shared/models/sheet/Sheet';

import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useCheckedItemsStore } from 'shared/utils/hooks/useCheckedItems';
import { AvailableTableConfiguration, IColumnConfiguration, SheetColumnSlug } from 'store/entities/clients/clientsModel';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { getColumnsByConfiguration } from 'store/utils/tables';
import { selectClientApprovalLevels } from 'store/entities/clients/selectors/timeAndPaySelectors';

const mobileInfoCell = (configuration: IColumnConfiguration<SheetColumnSlug>[]) => ({
    key: 'all details',
    title: '',
    width: '2fr',
    render: ({
        className,
        group,
    }: ISheetGroupRow) => {
        return (
            <MobileListCell
                className={className}
                title={getPayPeriodByStartEnd(group.payPeriod.period_start, group.payPeriod.period_end)}
                items={[
                    getMobileItemsRowByConfig(
                        [
                            {
                                slug: SheetColumnSlug.JobNumber,
                                getText: () => group.jobNumbers
                                    .map(jobNumber => jobNumber?.job_number?.toString()).join(', '),
                            },
                        ],
                        configuration,
                    ),
                ]}
            />
        );
    },
});

export const useEmployeeGroupedSheetCells = (entryType: EntryType) => {
    const classes = useSubmittedTableStyles();
    const isMobile = useIsMobile();

    const configuration = useExtendedConfiguration(
        entryType === EntryType.EXPENSE
            ? AvailableTableConfiguration.EmployeeExpenseSheet
            : AvailableTableConfiguration.EmployeeTimeSheet,
    ) as IColumnConfiguration<SheetColumnSlug>[];
    const columnDictionary = useGroupedSheetColumnDictionary();

    const activeTab = useSelector(selectTimeAndExpenseActiveStatus);
    const baseSelector = entryType === EntryType.TIME ? timeSheetsSelectors : expenseSheetsSelectors;
    const sheetGroupsRows = useSelector(baseSelector.selectGroupedSheetRows);
    const approversCount = useSelector(selectClientApprovalLevels);

    const checkItemsUtils = useCheckedItemsStore<ISheetGroupRow>(
        sheetGroupsRows,
        baseSelector.selectCheckedGroupedSheets,
        entryType === EntryType.TIME ? setTimeSheetGroupsChecked : setExpenseSheetGroupsChecked,
    );

    return useMemo(() => {
        const checkboxCellWrapper = activeTab === StatusNames.SUBMITTED ? [
            getCheckboxCell<ISheetGroupRow>(
                checkItemsUtils.checkedItems,
                checkItemsUtils.onCheck,
                checkItemsUtils.checkedAll,
                checkItemsUtils.onCheckAll,
                classes,
                isMobile,
                row => row.id,
            ),
        ] : [];
        const statusCell = getStatusCell(classes, activeTab === StatusNames.ALL);
        const detailsCell = getDetailsCell(classes, entryType, isMobile);
        const amountCell = getHoursAmountGroupedCell(classes, entryType, isMobile)('');
        const approvedLevelCells = isApprovedLevelCellAvailable(activeTab)
            ? [getApprovedLevelWithTotalGroupedCell(approversCount)]
            : [];
        return isMobile ? [
            ...checkboxCellWrapper,
            mobileInfoCell(configuration),
            amountCell,
            detailsCell,
        ] : [
            ...checkboxCellWrapper,
            ...getColumnsByConfiguration(configuration, columnDictionary, classes),
            ...statusCell,
            ...approvedLevelCells,
            detailsCell,
        ];
    }, [
        configuration,
        columnDictionary,
        classes,
        activeTab,
        entryType,
        isMobile,
        checkItemsUtils,
        approversCount,
    ]);
};
