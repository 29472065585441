import {
    IOfferLetterTemplate,
    IOfferLetterTemplateBackend,
    mapOfferLetterTemplateFromBackend,
} from 'modules/offerLetter/store/templates/models';

import baseApi from 'shared/utils/baseApi';

interface IGetTemplatesResponse {
    templates: IOfferLetterTemplateBackend[];
}

export const offerLetterTemplateApi = {
    async getTemplates(): Promise<IOfferLetterTemplate[]> {
        const { data: { templates } } = await baseApi.get<IGetTemplatesResponse>(
            'documents/offer_letters/templates',
        );
        return templates.map(mapOfferLetterTemplateFromBackend);
    },
    async removeTemplate(templateId: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            '/documents/offer_letters/templates',
            templateId,
        );
        return data.status;
    },
};
