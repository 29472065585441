import {
    rescindOffer,
    OfferLetterActions,
} from 'modules/offerLetter/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';

export const initialState = {
    rescindingOffer: '',
};

export const isOfferRescinding = isLoadingReducer(rescindOffer);

export function rescindingOffer(state = initialState.rescindingOffer, action: OfferLetterActions): string {
    switch (action.type) {
        case rescindOffer.successType:
            return action.payload.id;
        default:
            return state;
    }
}

export const offerRescind = combineReducers({
    isOfferRescinding,
    rescindingOffer,
});
