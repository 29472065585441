import { Nullable } from '../../../../types/types';
import { selectDetailAssignment } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { selectEditSubassignment } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/selectors';
import { useSelector } from 'react-redux';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';

export function useAssignmentFromDetails() {
    const assignmentsById = useSelector(selectAssignmentsById);
    const assignment: Nullable<IAssignment> = useSelector(selectDetailAssignment);
    const subassignment: Nullable<ISubassignment> = useSelector(selectEditSubassignment);

    if (!assignment && subassignment) {
        return {
            assignment: assignmentsById[subassignment.assignment_id],
            subassignment,
        };
    }

    return { assignment, subassignment };
}
