import {
    AccessTime,
    AttachMoney,
    CreditCardOutlined,
    Dashboard,
    DescriptionOutlined,
    Equalizer,
    Flight,
    GroupOutlined,
    People,
    PersonOutlined,
    Link,
} from '@material-ui/icons';
import { intersection } from 'lodash-es';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { IMenuCategory, TMenuCategoryChild } from 'shared/models/Global';
import { MenuItemEnum } from 'shared/models/MenuItem';
import { routes } from 'shared/routes';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserImpersonate, selectUserPermissions } from 'store/components/auth/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import {
    selectEnabledClientExternalLinks, selectClientCreditCardPortalPageEnabled, selectImpersonationEnabled,
    selectIsEafApprovalEnabledForClient, selectIsTravelExpensesEnabledForClient, selectZohoAnalyticsPermalink,
} from 'store/entities/clients/selectors/configurationSelectors';
import {
    selectClientHasActiveExpenseActivities,
    selectClientHasActiveTimeActivities,
    selectClientHasActivities,
} from 'store/entities/configuration/configurationSelectors';
import { selectIsCurrentUserPrismId } from 'store/entities/users/selectors';
import { optimizely } from 'utils/optimizely';

const useGetShowTimeAndExpense = (): boolean => {
    const clientHasActivities = useSelector(selectClientHasActivities);
    return clientHasActivities;
};

const useGetShowTimeApproval = (): boolean => {
    const clientHasTimeActivities = useSelector(selectClientHasActiveTimeActivities);
    return clientHasTimeActivities;
};

const useGetShowExpenseApproval = (): boolean => {
    const clientHasExpenseActivities = useSelector(selectClientHasActiveExpenseActivities);
    return clientHasExpenseActivities;
};

const useFilterMenuItemsByPermissionsAndFeatures = (menuItems: TMenuCategoryChild[]): TMenuCategoryChild[] => {
    const userPermissions = useSelector(selectUserPermissions);
    return menuItems.filter(item => {
        if (item.feature && !optimizely.isFeatureEnabled(item.feature)) {
            return false;
        }
        const checkPermission = [...(item.anyPermissionFrom || []), item.permission].filter(Boolean);
        if ((checkPermission?.length && intersection(userPermissions, checkPermission).length === 0)) {
            return false;
        }
        return !item.hide;
    });
};

export function useClientMenu(): Array<IMenuCategory> {
    const clientId = useSelector(selectCurrentClientId);
    const permissions = useSelector(selectUserPermissions);
    const enableEAFApproval = useSelector(selectIsEafApprovalEnabledForClient);
    const clientExternalLinks = useSelector(selectEnabledClientExternalLinks);
    const isTravelExpensesEnabledForClient = useSelector(selectIsTravelExpensesEnabledForClient);
    const isImpersonationEnabled = useSelector(selectImpersonationEnabled);
    const zohoPermalink = useSelector(selectZohoAnalyticsPermalink);
    const hasZohoPermalink = Boolean(zohoPermalink);
    const showTimeAndExpense = useGetShowTimeAndExpense();
    const showTimeApproval = useGetShowTimeApproval();
    const showExpenseApproval = useGetShowExpenseApproval();
    const isCcpEnabled = useSelector(selectClientCreditCardPortalPageEnabled);
    const isUserImpersonate = useSelector(selectIsUserImpersonate);
    const prismUserId = useSelector(selectIsCurrentUserPrismId);

    let permittedTabs: TMenuCategoryChild[] = [];

    if (clientId && permissions) {
        const params = { client_id: clientId };

        permittedTabs = [
            {
                title: MenuItemEnum.Dashboard,
                Icon: Dashboard,
                to: generatePath(routes.CLIENT.DASHBOARD, params),
            },
            {
                title: MenuItemEnum.EmploymentManagement,
                Icon: GroupOutlined,
                to: generatePath(routes.CLIENT.EMPLOYMENT_MANAGEMENT.ROOT, params),
                permission: Permission.ManageSubassignments,
                children: [
                    {
                        title: MenuItemEnum.AssignmentManagement,
                        to: generatePath(routes.CLIENT.EMPLOYMENT_MANAGEMENT.ASSIGNMENT_MANAGEMENT, params),
                        permission: Permission.ManageSubassignments,
                        children: enableEAFApproval ? [
                            {
                                title: MenuItemEnum.EAFPendingApproval,
                                to: generatePath(routes.CLIENT.EMPLOYMENT_MANAGEMENT.EAF_APPROVAL, params),
                                permission: Permission.ManageSubassignments,
                            },
                        ] : [],
                    },
                ],
            },
            {
                title: MenuItemEnum.OfferLetter,
                Icon: DescriptionOutlined,
                to: generatePath(routes.CLIENT.OFFER_LETTER.ROOT, params),
                anyPermissionFrom: [Permission.ViewClientOffersAll, Permission.ViewClientOffersOwn],
            },
            {
                title: MenuItemEnum.TimeApproval,
                Icon: AccessTime,
                to: generatePath(routes.CLIENT.TIME_APPROVAL.ROOT, params),
                anyPermissionFrom: [Permission.ViewSheetApprovalsOwn, Permission.ViewSheetApprovalsAll],
                hide: !showTimeApproval,
            },
            {
                title: MenuItemEnum.ExpenseApproval,
                Icon: AttachMoney,
                to: generatePath(routes.CLIENT.EXPENSE_APPROVAL.ROOT, params),
                anyPermissionFrom: [Permission.ViewSheetApprovalsOwn, Permission.ViewSheetApprovalsAll],
                hide: !showExpenseApproval,
            },
            {
                title: MenuItemEnum.TravelExpenses,
                Icon: Flight,
                to: generatePath(routes.CLIENT.TRAVEL_EXPENSES.ROOT, params),
                permission: Permission.ManageTravelExpenses,
                hide: !isTravelExpensesEnabledForClient,
            },
            {
                title: MenuItemEnum.TimeExpenses,
                Icon: AccessTime,
                to: generatePath(routes.CLIENT.TIME_AND_EXPENSE.ROOT, params),
                permission: Permission.SubmitSheets,
                hide: !showTimeAndExpense,
            },

            {
                title: MenuItemEnum.Reports,
                Icon: Equalizer,
                to: generatePath(routes.CLIENT.REPORTS, params),
                permission: Permission.ViewReports,
                hide: !hasZohoPermalink,
            },
            {
                title: MenuItemEnum.CreditCardPortal,
                Icon: CreditCardOutlined,
                to: generatePath(routes.CLIENT.CCP.ROOT, params),
                anyPermissionFrom: [Permission.ViewCcpUnreconciled, Permission.ViewCcpReconciled],
                hide: !isCcpEnabled,
            },
            ...clientExternalLinks.map(item => {
                return {
                    title: item.name,
                    Icon: Link,
                    // @ts-ignore
                    href: item.link,
                    target: '_blank',
                };
            }),
            {
                title: MenuItemEnum.EmployeePortal,
                Icon: PersonOutlined,
                to: generatePath(routes.CLIENT.EMPLOYEE_PORTAL, params),
                hide: !(!isUserImpersonate && prismUserId),
            },
            {
                title: MenuItemEnum.Impersonation,
                Icon: People,
                to: generatePath(routes.CLIENT.USERS.ROOT, params),
                permission: Permission.Impersonate,
                hide: !isImpersonationEnabled,
                children: [
                    {
                        title: MenuItemEnum.Employees,
                        to: generatePath(routes.CLIENT.USERS.EMPLOYEES, params),
                        permission: Permission.ImpersonateAsEmployee,
                    },
                    {
                        title: MenuItemEnum.Managers,
                        to: generatePath(routes.CLIENT.USERS.MANAGERS, params),
                        permission: Permission.ImpersonateAsManager,
                    },
                ],
            },
        ];
    }

    permittedTabs = useFilterMenuItemsByPermissionsAndFeatures(permittedTabs);

    return [
        {
            id: 'Menu',
            children: permittedTabs,
        },
    ];
}
