import { combineReducers } from 'redux';
import { editControllingOrg } from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/reducer';
import { controllingOrgFilter } from 'modules/settings/submodules/clients/controllingOrg/components/Filter/store/reducer';
import { createControllingOrg } from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/store/reducer';

export const controllingOrg = combineReducers({
    editControllingOrg,
    controllingOrgFilter,
    createControllingOrg,
});
