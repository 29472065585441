import { Theme } from '@material-ui/core';

export const headerHeight = 10;
export const drawerWidth = 248;
export const drawerMinimizedWidth = 56;
export const verticalMenuItemHeight = 5;

export const sidebarIncreasedWidth = 90;

export const smallFieldWidth = 80;
export const mediumFieldWidth = 140;
export const largeFieldWidth = 200;

export const desktopContentSpace = 4;

export const isSmallScreenBreakpoint = 'sm';
export const isMediumScreenBreakpoint = 'md';
export const isMobileBreakpoint = 'xs';

export const timeoutAnimation = 450;

export const colors = {
    primary: '#813772',
    secondary: '#062f4f',

    white: '#fff',
    whiteSecondary: '#f5f5f5',

    black: '#000',
    blackSecondary: '#151b26',

    red: '#b82601',
    blue: '#009be5',
    darkBlue: '#244F99',

    green: '#2db67d',
    lightGreen: '#e5f6d3',

    orange: '#e89c30',
    rhino: '#404854',

    lightGray: '#dadada',
    gray: '#9e9e9e',
    darkGray1: '#8c8c8c',
    darkGray: '#666',
    darkerGray: '#454a54',

    error: '#f44336',
    rtiPrimary: '#244F99',

    warningBorder: '#BDAC95',
};

const increasedSidebarLarge = 1440;
const increasedSidebarMedium = 1224;

export function getIncreasedSidebarWidth(theme: Theme) {
    return {
        [theme.breakpoints.up(increasedSidebarLarge)]: {
            width: '50% !important',
        },
        [theme.breakpoints.between(increasedSidebarMedium, increasedSidebarLarge)]: {
            width: '80%',
        },
        [theme.breakpoints.down(increasedSidebarMedium)]: {
            width: '100%',
        },
    };
}

// Should prevent last pass from filling the field
export const inputParams = {
    'data-lpignore': true,
};
