import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';
import { getRowHeightLine } from 'shared/components/table/GridTable/GridTableStyles';

export const useTableStyles = makeHighPriorityStyles((theme: Theme) => {
    const borderSize = 0;
    const paddingTopBottom = 1;
    const paddingTopBottomInPx = theme.spacing(paddingTopBottom);
    return ({
        headerLightGrayCell: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(paddingTopBottom, 0, paddingTopBottom, 2),
            border: 'none !important',
            lineHeight: `${getRowHeightLine(paddingTopBottomInPx, borderSize)}px !important`,
            backgroundColor: colors.lightGray,
            whiteSpace: 'nowrap',
        },
        iconCell: {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
        },
        cellJustifyCenter: {
            justifyContent: 'center',
        },
        linkInheritColor: {
            'a&&, a&:visited': {
                display: 'flex',
                color: 'inherit',
            },
        },
        highlightedRow: {
            backgroundColor: `${colors.lightGreen} !important`,
        },
    });
});
