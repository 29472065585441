import { ICcpInvoiceBatch, ICcpTransaction } from 'modules/ccp/models/CcpTransaction';
import { IHeaderTab } from 'shared/components/common/headerTabs/HeaderTabs';

export interface ICcpSubmitRequest {
    client_id: string;
}

export interface ICcpSplitItem extends ICcpTransaction {

}

export interface ICcpTransactionsResponse {
    items: ICcpTransaction[];
    total_items: number;
    page: number;
    page_size: number;
    user_ids: string[];
}

export interface ICcpInvoicesResponse {
    items: ICcpInvoiceBatch[];
    total_items: number;
    page: number;
    page_size: number;
}

export interface ICcpTransactionRequest {
    client_id: string;
    user_id?: string;
    submitted_date?: string;
    transaction_status_id: string;
    department_id?: string;
    skip?: number;
    regional_manager_id?: string;
}

export interface ICcpSubmitReconciledTransactionRequestItem {
    transaction_id: string;
}

export interface ICcpSubmitReconciledRequest {
    client_id: string;
    invoice_number: string;
    invoice_date: string;
    transactions: ICcpSubmitReconciledTransactionRequestItem[];
}

export interface ICcpInvoicesRequest {
    client_id: string;
    skip?: number;
    submitted_date?: string;
}

export interface IBulkRowCreationResponse {
    field: string,
    message: string,
    is_error: string,
}

export interface ITransactionValidationResponse {
    account_number: string,
    cardholder_name: string,
    employee_user_id: string,
    tran_date: string,
    post_date: string,
    merchant_name: string,
    merchant_desc: string,
    amount: number,
    ref_number: string,
    transaction_type_id: string,
    transaction_type_name: string,
    mcc_code: string,
}

export interface IBulkCreateCcpTransactionChangeResponse extends ITransactionValidationResponse {
    errors: string[]
}

export interface IBulkCreateCcpTransactionsResponse {
    number_of_transactions: number,
    number_of_transactions_failed: number,
    creates: IBulkCreateCcpTransactionChangeResponse[]
}

export interface ITransactionChange {
    id: string,
    transaction_code: string|null,
    department_id: string|null,
    client_id: string,
    note: string|undefined,
}

export interface ITransactionCodesResponse {
    codes: ITransactionCode[]
}

export interface ITransactionCode {
    id: string,
    name: string,
    gl_code: string,
}

export enum CcpStatusSlug {
    Unreconciled = 'unreconciled',
    Reconciled = 'reconciled',
}

export const invoicedCcpTabName = 'Invoiced';
export const invoicedCcpSlug = 'invoiced';

export const allCcpTabName = 'All';
export const allCcpSlug = 'all';

export const CcpStatusNameMap: Record<CcpStatusSlug, string> = {
    [CcpStatusSlug.Unreconciled]: 'Unreconciled',
    [CcpStatusSlug.Reconciled]: 'Reconciled',
};

export const CcpStatusTabNameMap: Record<CcpStatusSlug | string, string> = {
    [CcpStatusSlug.Unreconciled]: 'Unreconciled',
    [CcpStatusSlug.Reconciled]: 'Reconciled',
    'all': allCcpTabName,
};

export const CcpUnreconciledID = 'a38f5000-2f80-4864-8a63-772eeff8fccf';
export const CcpReconciledID = '9eca1f40-ac8a-4400-8329-9baae778243e';
export const CcpInvoicedID = '19f86d7a-231d-441b-8149-2e68e6939e3b';

export const CcpStatusIdMap: Record<CcpStatusSlug | 'all' | 'invoiced', string> = {
    all: '',
    invoiced: '',
    [CcpStatusSlug.Unreconciled]: CcpUnreconciledID,
    [CcpStatusSlug.Reconciled]: CcpReconciledID,
};

export const CcpStatusIdToNameMap: Record<string, string> = {
    [CcpUnreconciledID]: 'Unreconciled',
    [CcpReconciledID]: 'Reconciled',
    [CcpInvoicedID]: 'Invoiced',
};

export interface ICcpTab extends IHeaderTab {}
export const ccpDefaultTabStatusSlug = CcpStatusSlug.Unreconciled;
export const ccpDefaultFilterState = {
    transaction_status_id: CcpStatusIdMap[ccpDefaultTabStatusSlug],
};

export const ccpTabs: ICcpTab[] = [
    {
        label: CcpStatusNameMap[CcpStatusSlug.Unreconciled],
        value: CcpStatusSlug.Unreconciled,
    },
    {
        label: CcpStatusNameMap[CcpStatusSlug.Reconciled],
        value: CcpStatusSlug.Reconciled,
    },
];

export const allCcpTab: ICcpTab = {
    label: allCcpTabName,
    value: allCcpSlug,
};

export const invoicedCcpTab: ICcpTab = {
    label: invoicedCcpTabName,
    value: invoicedCcpSlug,
};

export interface ICcpTableFilters {
    user_id?: string | null;
    department_id?: string | null;
    submitted_date?: string | null;
    regional_manager_id?: string | null;
}

export interface ICcpReminderRequest {
    client_id: string;
    user_id: string;
}

export interface ICcpUpdateSplitTransactions {
    parent_id: string,
    removed_ids: string[],
    added_ids: string[],
}
