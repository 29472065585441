import { ActionsReturnTypes, createActions } from 'store/utils';
import { IEmployeeType } from 'modules/employmentInfo/models/EmployeeType';

const GET_EMPLOYEE_TYPES = 'GET_EMPLOYEE_TYPES';
const GET_EMPLOYEE_TYPES_SUCCESS = 'GET_EMPLOYEE_TYPES_SUCCESS';
const GET_EMPLOYEE_TYPES_ERROR = 'GET_EMPLOYEE_TYPES_ERROR';

export const getEmployeeTypes = createActions<
void,
Array<IEmployeeType>,
any,
typeof GET_EMPLOYEE_TYPES,
typeof GET_EMPLOYEE_TYPES_SUCCESS,
typeof GET_EMPLOYEE_TYPES_ERROR
>(
    GET_EMPLOYEE_TYPES, GET_EMPLOYEE_TYPES_SUCCESS, GET_EMPLOYEE_TYPES_ERROR,
);

export type EmployeeTypesActions =
    | ActionsReturnTypes<typeof getEmployeeTypes>
