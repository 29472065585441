import { extraShortDateFormat, getFormattedPayPeriod } from 'shared/models/Dates';

export const getPayPeriodByStartEnd = (period_start?: string | null, period_end?: string | null) => {
    if (!period_start || !period_end) {
        return '';
    }
    return getFormattedPayPeriod(
        {
            period_start,
            period_end,
        },
        extraShortDateFormat,
    );
};
