import { call, put, select, take, takeLatest } from 'typed-redux-saga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    getMoreTravelExpensesAction, IGetMoreTravelExpensesPayload,
} from 'modules/travelExpenses/components/TravelExpensesTable/actions';
import { setTravelExpenseFilter } from 'modules/travelExpenses/components/TravelExpenseFilter/action';

import {
    selectTravelExpenseFilter,
    selectTravelExpenseFilterJobNumberIds,
} from 'modules/travelExpenses/components/TravelExpenseFilter/selectors';
import { deleteTravelExpense, getTravelExpenses, getTravelExpenseSheets } from 'modules/travelExpenses/actions';
import { expenseApi, IExpenseEntrySearchRequest } from 'store/entities/timesheet/api/expenseApi';
import { travelExpenseInfinityTableSelectors } from 'modules/travelExpenses/components/TravelExpensesTable/selectors';
import { removeExpenseEntry } from 'store/entities/timesheet/actions/expenseActions';

function* getMoreTravelExpensesSaga({ payload }: ReturnType<typeof getMoreTravelExpensesAction.init>) {
    const filter = yield* select(selectTravelExpenseFilter);
    const filterJobNumberIds = yield* select(selectTravelExpenseFilterJobNumberIds);
    const pageSize = yield* select(travelExpenseInfinityTableSelectors.selectPageSize);
    const cursor = yield* select(travelExpenseInfinityTableSelectors.selectCursor);
    const sheet_ids = (payload as IGetMoreTravelExpensesPayload)?.sheet_ids;
    const request: IExpenseEntrySearchRequest = {
        user_id: filter.employeeId || undefined,
        activity_ids: filter.expenseTypeId ? [filter.expenseTypeId] : undefined,
        job_number_ids: filterJobNumberIds,
        is_travel: true,
        sheet_ids: sheet_ids || undefined,
        page_size: pageSize || undefined,
        cursor: cursor || undefined,
    };

    const response = yield* call(expenseApi.searchEntries, request);

    yield put(getTravelExpenses.success(response.items));
    yield put(getTravelExpenseSheets.success(response.linked.sheets));
    yield put(getMoreTravelExpensesAction.success(response));
}

export function* getMoreTravelExpensesSagaWatcher() {
    yield* takeLatest(
        [
            getMoreTravelExpensesAction.initType,
            setTravelExpenseFilter.action,
        ],
        withBackendErrorHandler(
            getMoreTravelExpensesSaga,
            getMoreTravelExpensesAction.error,
            'Unable to get travel expenses.',
        ),
    );
}

function* deleteTravelExpenseSaga({ payload }: ReturnType<typeof deleteTravelExpense.init>) {
    yield put(removeExpenseEntry.init(payload));
    yield take(removeExpenseEntry.successType);
    yield put(deleteTravelExpense.success(payload));
}

export function* deleteTravelExpensesSagaWatcher() {
    yield* takeLatest(
        [
            deleteTravelExpense.initType,
        ],
        withBackendErrorHandler(
            deleteTravelExpenseSaga,
            deleteTravelExpense.error,
            'Unable to delete travel expense.',
        ),
    );
}
