import { combineReducers } from 'redux';
import {
    getSignupUserInfo,
    RegistrationActions,
    RESET_USER_REGISTRATION,
    userRegistration,
} from 'store/components/registration/actions';
import { ISignupUserInfo } from 'store/entities/users/model';
import { isLoadingReducer } from '../../reducerUtils';

export const initialState = {
    isLoading: false,
    isError: false,
    userMessage: '',
    userInfo: null,
};

const isRegistrationLoading = isLoadingReducer(userRegistration, initialState.isLoading);
function isLoading(state: boolean = initialState.isLoading, action: RegistrationActions): boolean {
    const nextState = isRegistrationLoading(state, action);
    switch (action.type) {
        case userRegistration.initType:
            return true;
        case RESET_USER_REGISTRATION:
            return false;
        default:
            return nextState;
    }
}

function isError(state: boolean = initialState.isError, action: RegistrationActions): boolean {
    switch (action.type) {
        case RESET_USER_REGISTRATION:
        case userRegistration.initType:
            return false;
        case userRegistration.errorType:
            return true;
        default:
            return state;
    }
}

function userMessage(state: string = initialState.userMessage, action: RegistrationActions): string {
    switch (action.type) {
        case RESET_USER_REGISTRATION:
        case userRegistration.initType:
            return initialState.userMessage;
        case userRegistration.errorType:
            return action.payload.message;
        case userRegistration.successType:
            return 'A confirmation email has been sent. Please Check your email to complete the registration process.';
        default:
            return state;
    }
}

function userInfo(
    state: ISignupUserInfo | null = initialState.userInfo,
    action: RegistrationActions,
): ISignupUserInfo | null {
    switch (action.type) {
        case RESET_USER_REGISTRATION:
            return initialState.userInfo;
        case getSignupUserInfo.successType:
            return action.payload;
        default:
            return state;
    }
}

export const registration = combineReducers({
    isLoading,
    isError,
    userMessage,
    userInfo,
});
