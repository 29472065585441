import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const userClientAssociationValidationsSchema = yup.object().shape({
    user: yup.object().nullable().required(ValidationMessages.REQUIRED),
    ...process.env.REACT_APP_CLIENT_ID ? {} : {
        client: yup.object().nullable().required(ValidationMessages.REQUIRED),
    },
    role: yup.object().nullable().required(ValidationMessages.REQUIRED),
});
