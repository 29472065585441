import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { useHeaderCreateButtonStyles } from 'shared/styles/headerCreateButtonStyles';
import { AddNewTravelExpenseSidebar } from 'modules/travelExpenses/components/Create/AddNewTravelExpenseSidebar/AddNewTravelExpenseSidebar';
import useAddNewEntryDialogStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControlsMobile/AddNewEntryDialogStyles';

import { useModal } from 'shared/utils/hooks/useModal';

export const CreateTravelExpenseButton = () => {

    const classes = useHeaderCreateButtonStyles();

    const addNewEntryDialogClasses = useAddNewEntryDialogStyles();

    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    return (
        <>
            <Button
                color="primary"
                variant="contained"
                size="small"
                classes={{
                    root: classes.createButton,
                    label: classes.createButtonLabel,
                }}
                onClick={onModalOpen}
            >
                + Create Expense
            </Button>
            <Box className={addNewEntryDialogClasses.container} position="fixed">
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                >

                    <AddNewTravelExpenseSidebar
                        anchor="right"
                        onClose={onModalClose}
                        isOpened={isModalOpened}
                    />
                </Grid>
            </Box>
        </>
    );
};
