import { IOfferLetterFormValues } from 'modules/offerLetter/components/OfferLetterForm/model';
import { IOfferLetter } from 'modules/offerLetter/store/model';
import { useSelector } from 'react-redux';
import { ITemplate } from 'store/entities/configuration/configurationModel';
import {
    selectBackgroundCheckTemplatesSortedByName,
    selectPhysicalDemandsSortedByName, selectWorkingConditionsSortedByName,
} from 'store/entities/configuration/configurationSelectors';

const findTemplate = (
    templates: ITemplate[], usedTemplateText?: string, templateId?: string | null,
): ITemplate | null => {
    return templates.find(
        template => template.id === templateId || template.template === usedTemplateText,
    ) || null;
};

export const useOfferLetterTemplates = (offer?: IOfferLetter): Partial<IOfferLetterFormValues> => {
    const backgroundChecks = useSelector(selectBackgroundCheckTemplatesSortedByName);
    const physicalsDemands = useSelector(selectPhysicalDemandsSortedByName);
    const workingConditions = useSelector(selectWorkingConditionsSortedByName);
    if (!offer) {
        return {
            workingConditions: '',
            workingConditionsTemplate: null,
            physicalDemands: '',
            physicalDemandsTemplate: null,
            backgroundChecks: '',
            backgroundChecksTemplate: null,
        };
    }
    return {
        workingConditions: offer.working_conditions,
        workingConditionsTemplate: findTemplate(
            workingConditions, offer.working_conditions, offer.working_conditions_id,
        ),
        physicalDemands: offer.physical_demands,
        physicalDemandsTemplate: findTemplate(physicalsDemands, offer.physical_demands, offer.physical_demands_id),
        backgroundChecks: offer.background_checks,
        backgroundChecksTemplate: findTemplate(backgroundChecks, offer.background_checks, offer.background_checks_id),
    };
};
