import { flatten } from 'lodash-es';
import { subassignmentTableStateSelectors } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import {
    getMoreSubassignmentsAction,
    initialLoadSubassignments,
    setSubassignmentFilters,
} from 'modules/subassignmentManagement/store/actions';
import { subassignmentsApi } from 'modules/subassignmentManagement/store/api';
import { ISubassignmentSearchRequest } from 'modules/subassignmentManagement/store/models';
import { selectSubassignmentFilter } from 'modules/subassignmentManagement/store/selectors';
import { getEmployeeProfiles } from 'modules/offerLetter/store/actions';
import { getAssignments, getSubassignments, loadClientAssignmentsWithLinked } from 'store/entities/configuration/configurationAction';
import { IAssignment } from 'store/entities/configuration/configurationModel';
import { call, put, select, take, takeLatest } from 'typed-redux-saga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import assignmentInfoModalSagas from 'modules/subassignmentManagement/components/AssignmentInfo/store/sagas';

import { createSubassignmentsSagas } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/sagas';
import { editSubassignmentsSagas } from 'modules/subassignmentManagement/components/EditSubAssignment/store/sagas';
import { subassignmentManagementCommonSideEffectSagas } from './side-effect-sagas';

function* getSubassignmentsSaga() {
    const filter = yield select(selectSubassignmentFilter);
    const pageSize = yield select(subassignmentTableStateSelectors.selectPageSize);
    const cursor = yield select(subassignmentTableStateSelectors.selectCursor);

    const customFieldValuesIds = flatten(Object.values(filter.custom_fields)).filter(Boolean);
    const request: ISubassignmentSearchRequest = {
        employee_user_id: filter.employee_user_id || undefined,
        approver_user_id: filter.approver_user_id || undefined,
        start_date: filter.start_date || undefined,
        end_date: filter.end_date || undefined,
        // @ts-ignore
        custom_field_value_ids: customFieldValuesIds.length ? customFieldValuesIds : undefined,
        page_size: pageSize,
        cursor,
    };

    const searchResult = yield call(subassignmentsApi.getGroupedSubassignments, request);

    const assignmentIds = searchResult.items.map(item => item.assignment_id);
    if (assignmentIds.length) {
        yield put(loadClientAssignmentsWithLinked.init({ ids: assignmentIds }));

        // Load user profiles
        const resultAction = yield take([getAssignments.successType, loadClientAssignmentsWithLinked.errorType]);
        if (resultAction.type === getAssignments.successType) {
            const employeeProfileIds = resultAction.payload.map(
                (assignment: IAssignment) => assignment?.employee_profile?.id,
            ).filter(Boolean);
            yield put(getEmployeeProfiles.init({
                filters: JSON.stringify({
                    id: {
                        'in': employeeProfileIds,
                    },
                }),
            }));
        }
    }
    const allSubassignments = flatten(searchResult.items.map(group => group.subassignments));
    // @ts-ignore
    yield put(getSubassignments.success(allSubassignments));

    yield put(getMoreSubassignmentsAction.success({
        total: searchResult.total_items,
        cursor: searchResult.next_cursor,
        assignments: searchResult.items.map(({ assignment_id, subassignments }) => ({
            assignment_id,
            subassignmentIds: subassignments.map(item => item.id),
        })),
    }));
}

function* getSubassignmentsWatcher() {
    yield takeLatest([
        getMoreSubassignmentsAction.initType,
        initialLoadSubassignments.action,
        setSubassignmentFilters.action,
    ], withBackendErrorHandler(
        getSubassignmentsSaga,
        getMoreSubassignmentsAction.error,
        'Unable to fetch sub-assignments',
    ));
}

export default [
    getSubassignmentsWatcher,
    ...createSubassignmentsSagas,
    ...editSubassignmentsSagas,
    ...assignmentInfoModalSagas,
    ...subassignmentManagementCommonSideEffectSagas,
];
