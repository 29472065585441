import { Theme } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import React from 'react';
import { Box, Button, Tooltip } from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useTableScrollPanelStyles = makeHighPriorityStyles((theme: Theme) => ({
    panel: {
        position: 'sticky',
        bottom: '15px',
        zIndex: 3,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',

        [theme.breakpoints.down(600)]: {
            position: 'fixed',
            bottom: '115px',
        },
    },
    panelInner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.lightGray,
        borderRadius: '5px',
        pointerEvents: 'all',
        boxShadow: 'none',
        opacity: 0.5,
        transition: 'opacity 0.3s, box-shadow 0.3s',

        '&:hover': {
            boxShadow: shadows[4],
            opacity: 1,
        },
    },
    scrollTitle: {
        padding: '0 15px',
        cursor: 'help',
    },
}));

export interface ITableScrollPanelProps {
    handleClickScrollRight: () => void;
    handleClickScrollLeft: () => void;
}

export const TableScrollPanel = ({
    handleClickScrollLeft,
    handleClickScrollRight,
}: ITableScrollPanelProps) => {
    const classes = useTableScrollPanelStyles();

    return (
        <Box className={classes.panel}>
            <Box className={classes.panelInner}>
                <Button onClick={handleClickScrollLeft} startIcon={<ArrowLeft/>}>
                    Left
                </Button>
                <Box className={classes.scrollTitle}>
                    <Tooltip title={'You can also use "Shift + Mouse Wheel" combination to scroll horizontally'}>
                        <span>Scroll</span>
                    </Tooltip>
                </Box>
                <Button onClick={handleClickScrollRight} endIcon={<ArrowRight/>}>
                    Right
                </Button>
            </Box>
        </Box>
    );
};
