import DayPickerField from 'shared/components/formFields/DayPickerField';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import React, { useCallback } from 'react';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSelector } from 'react-redux';
import { selectPayPeriods } from 'store/entities/timesheet/selectors';

export const WeekEndingField = () => {
    const classes = useEntryEditStyles();
    const payPeriods = useSelector(selectPayPeriods);
    const shouldDisableWeekEndings = useCallback((date: MaterialUiPickersDate) => {
        const payPeriodEndings = payPeriods.map(payPeriod => payPeriod.period_end);
        const formattedDate = date?.format('yyyy-MM-DD') || '';
        return !payPeriodEndings.includes(formattedDate);
    }, [payPeriods]);

    return (
        <FormFieldWithSeparateLabel
            name="weekEnding"
            outerLabel="Week Ending:"
            FieldComponent={DayPickerField}
            withKeyboard
            className={classes.formInputWithLabel}
            disabled
            shouldDisableDate={shouldDisableWeekEndings}
        />
    );
};
