import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { setSheetApprovalTableSort } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/actions';
import { selecSheetApprovalTableSort } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/selectors';
import { setGroupedSheetsCheckedItems } from 'modules/sheetApproval/store/actions';
import { selectGroupedCheckedSheetsItems } from 'modules/sheetApproval/store/selectors';
import {
    getActionCell,
    getDetailsCell,
    getHoursAmountGroupedCell,
    getStatusCell,
    mobileInfoCell,
    useGroupedSheetColumnDictionary,
    getApprovedLevelWithTotalGroupedCell,
} from 'modules/timeAndExpense/components/GroupedSheetsTable/cells';
import { ISheetGroupRow } from 'modules/timeAndExpense/store/model';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import { isApprovedLevelCellAvailable } from 'shared/components/sheetApproval/bodyCells';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { getCheckboxCell } from 'shared/components/table/Cells/checkBoxHelper';
import { SortableTableHead } from 'shared/components/table/GridTable/SortableTableHead';
import { useExtendedConfiguration } from 'shared/components/table/SheetSupervisorTable/useExtendedConfiguration';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useCheckedItemsStore } from 'shared/utils/hooks/useCheckedItems';
import { AvailableTableConfiguration, IColumnConfiguration, SheetColumnSlug } from 'store/entities/clients/clientsModel';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { getColumnsByConfiguration } from 'store/utils/tables';
import { selectClientApprovalLevels } from 'store/entities/clients/selectors/timeAndPaySelectors';

export const renderTitleFactory = (id: string, title: string) => function TableHeadCell() {
    switch (id) {
        case SheetColumnSlug.Employee:
        case SheetColumnSlug.PayPeriod:
            return (
                <SortableTableHead
                    id={id}
                    tableSortingSelector={selecSheetApprovalTableSort}
                    setOrderAction={setSheetApprovalTableSort}
                >
                    {title}
                </SortableTableHead>
            );
        default:
            return undefined;
    }
};

export const useApprovalGroupedSheetCells = (entryType: EntryType, sheetGroupsRows: ISheetGroupRow[]) => {
    const classes = useSubmittedTableStyles();
    const isMobile = useIsMobile();

    const configuration = useExtendedConfiguration(
        entryType === EntryType.EXPENSE
            ? AvailableTableConfiguration.ManagerExpenseSheet
            : AvailableTableConfiguration.ManagerTimeSheet,
    ) as IColumnConfiguration<SheetColumnSlug>[];
    const columnDictionary = useGroupedSheetColumnDictionary(true, renderTitleFactory);

    const activeTab = useSelector(selectTimeAndExpenseActiveStatus);
    const checkItemsUtils = useCheckedItemsStore<ISheetGroupRow>(
        sheetGroupsRows,
        selectGroupedCheckedSheetsItems,
        setGroupedSheetsCheckedItems,
    );
    const approversCount = useSelector(selectClientApprovalLevels);

    return useMemo(() => {
        const checkboxCellWrapper = activeTab === StatusNames.SUBMITTED ? [
            getCheckboxCell<ISheetGroupRow>(
                checkItemsUtils.checkedItems,
                checkItemsUtils.onCheck,
                checkItemsUtils.checkedAll,
                checkItemsUtils.onCheckAll,
                classes,
                isMobile,
                row => row.id,
            ),
        ] : [];
        const actionsCellWrapper = activeTab === StatusNames.SUBMITTED ? [getActionCell(classes)] : [];
        const statusCell = getStatusCell(classes, activeTab === StatusNames.ALL);
        const detailsCell = getDetailsCell(classes, entryType, isMobile);
        const amountCell = getHoursAmountGroupedCell(classes, entryType, isMobile)('');
        const approvedLevelCells = isApprovedLevelCellAvailable(activeTab)
            ? [getApprovedLevelWithTotalGroupedCell(approversCount)]
            : [];
        return isMobile ? [
            ...checkboxCellWrapper,
            mobileInfoCell(configuration),
            amountCell,
            detailsCell,
        ] : [
            ...checkboxCellWrapper,
            ...getColumnsByConfiguration(configuration, columnDictionary, classes),
            ...statusCell,
            ...approvedLevelCells,
            ...actionsCellWrapper,
            detailsCell,
        ];
    }, [
        configuration,
        columnDictionary,
        classes,
        activeTab,
        entryType,
        isMobile,
        checkItemsUtils,
        approversCount,
    ]);
};
