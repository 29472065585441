import { createAssignmentReportMetaDataAction, setOpenAssignmentMetaDataCreateModal } from 'modules/settings/submodules/clients/assignmentsReportData/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const modalIsOpen = singleValueReducer(setOpenAssignmentMetaDataCreateModal.action, false);
const isCreating = isLoadingReducer(createAssignmentReportMetaDataAction);

export const createAssignmentMetaData = combineReducers({
    modalIsOpen,
    isCreating,
});
