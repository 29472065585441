import React, { Fragment, ReactNode } from 'react';
import { Box, List, ListSubheader } from '@material-ui/core';
import { DashboardLink } from 'modules/settings/submodules/components/DashboardLink/DashboardLink';
import { useStyles } from 'modules/settings/submodules/components/DashboardLink/styles';
import { IDashboardMenuItem } from 'modules/settings/submodules/components/DashboardLink/model';

export interface ISubmodulesDashboardProps{
    items: IDashboardMenuItem[],
}

function renderItem(
    item: IDashboardMenuItem,
    classes: ReturnType<typeof useStyles>,
): ReactNode {
    if (item.subheaderForChildren && item.children?.length) {
        return (
            <>
                <ListSubheader className={classes.subheader}>
                    {item.title}
                </ListSubheader>
                <SubmodulesDashboard items={item.children}/>
            </>
        );
    }
    if (item.subheader) {
        return (
            <ListSubheader className={classes.subheader}>
                {item.title}
            </ListSubheader>
        );
    }
    return <DashboardLink {...item}/>;
}

export const SubmodulesDashboard = ({ items }: ISubmodulesDashboardProps) => {
    const classes = useStyles();

    return (
        <Box width="100%">
            <List>
                {items.map(item => (
                    <Fragment key={item.title}>
                        {renderItem(item, classes)}
                    </Fragment>
                ))}
            </List>
        </Box>
    );
};
