import { IDepartment } from 'modules/employmentInfo/models/Department';
import { selectDepartmentsSortedByName } from 'modules/employmentInfo/store/department/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';

export const useUserDepartmentsList = (userId?: string | null): IDepartment[] => {
    const currentUser = useSelector(selectCurrentUser);
    const assignments = useSelector(selectAssignmentsById);
    const allClientDepartments = useSelector(selectDepartmentsSortedByName);
    const filterByUserId = userId || currentUser?.id;

    return useMemo(() => {
        const filteredDepartmentIds = Object.values(assignments)
            // @ts-ignore
            .filter(item => item.department_id && item.user_id === filterByUserId)
            .map(({ department_id }) => department_id);
        return allClientDepartments.filter(({ id }) => filteredDepartmentIds.includes(id));
    }, [filterByUserId, allClientDepartments, assignments]);
};

export const useUserDefaultDepartment = (userId?: string | null): IDepartment | null => {
    const departments = useUserDepartmentsList(userId);
    return departments.length === 1 ? departments[0] : null;
};
