import { getNotifications, NotificationActions, updateNotifications } from 'modules/notificationCenter/store/actions';
import { INotification } from 'modules/notificationCenter/store/model';
import { combineReducers } from 'redux';
import { ItemsById } from 'shared/models/ItemsById';
import { itemsByIds } from 'store/reducerUtils';

export function notificationsById(
    state: ItemsById<INotification> = {},
    action: NotificationActions,
): ItemsById<INotification> {
    switch (action.type) {
        case getNotifications.successType:
        case updateNotifications.successType:
            return {
                ...state,
                ...itemsByIds(action.payload),
            };
        default:
            return state;
    }
}

export const notificationCenter = combineReducers({
    notificationsById,
});
