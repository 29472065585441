import React, { FC, useCallback } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

import useOutlinedTextFieldStyled from './CustomOutlinedTextFieldStyles';
import { inputParams } from 'shared/styles/constants';

interface ICustomOutlinedTextFieldProps {
    label?: string;
    name: string;
    control?: Control;
    customClasses?: string;
    maxCharacters?: number;
}

const CustomOutlinedTextField: FC<ICustomOutlinedTextFieldProps> = (
    { label, name, control, customClasses, maxCharacters }: ICustomOutlinedTextFieldProps,
) => {
    const classes = useOutlinedTextFieldStyled();
    const handleOnChange = useCallback(([{ target }]) => {
        const { value: changedValue = '' } = target;

        if (maxCharacters && changedValue.length > maxCharacters) {
            return changedValue.toString().substr(0, maxCharacters);
        }

        return changedValue;
    }, [maxCharacters]);

    return (
        <FormControl variant="outlined" className={customClasses}>
            <Controller
                name={name}
                control={control}
                onChange={handleOnChange}
                as={(
                    <TextField
                        inputProps={{
                            className: classes.input,
                            ...inputParams,
                        }}
                        InputLabelProps={{ className: classes.label }}
                        label={label}
                        autoComplete="off"
                        variant="outlined"
                    />
                )}
            />
        </FormControl>
    );
};

export default CustomOutlinedTextField;
