import React from 'react';
import clsx from 'clsx';
import { Form, useField } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { useFormWithSectionsStyles } from 'modules/settings/common/components/FormWithSections/styles';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { useZohoSettingsStyles } from 'modules/settings/submodules/clients/reports/zoho/styles';
import { useZohoAnalyticsSetupFormHelper } from 'modules/settings/submodules/clients/reports/zoho/useZohoAnalyticsSetupFormHelper';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';

interface IZohoAnalyticsPermalinkSetupFormProps {
    zohoPermalinkSetupFormHelpers: ReturnType<typeof useZohoAnalyticsSetupFormHelper>;
}

export const ZohoAnalyticsPermalinkSetupForm = ({
    zohoPermalinkSetupFormHelpers: { initialState, isUpdating },
}: IZohoAnalyticsPermalinkSetupFormProps) => {
    const zohoSettingsClasses = useZohoSettingsStyles();
    const defaultClasses = useSettingsFormStyles();
    const customClasses = useFormWithSectionsStyles();
    const [field] = useField('zohoAnalyticsPermalink');
    const isSubmitDisabled = field.value === initialState.zohoAnalyticsPermalink;
    const classes = {
        ...defaultClasses,
        ...customClasses,
    };
    return (
        <Form>
            <Box>
                <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>
                    Zoho Analytics
                </Typography>
                <Typography>
                    Add permalink to embed a Zoho Analytics dashboard to the main menu.
                </Typography>
                <Box
                    mt={1}
                    mb={1}
                    display="flex"
                    justifyContent="stretch"
                    width="100%"
                >
                    <FormField
                        placeholder="https://"
                        className={zohoSettingsClasses.field}
                        name="zohoAnalyticsPermalink"
                        disabled={isUpdating}
                    />
                </Box>
                <ButtonWithLoader
                    isLoading={isUpdating}
                    disabled={isUpdating || isSubmitDisabled}
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    Save
                </ButtonWithLoader>
            </Box>
        </Form>
    );
};
