import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import { IUserInfo } from 'shared/models/User';
import { getLastFirstName } from 'shared/utils/converters/user';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { getFieldValueName } from 'store/entities/customFields/helpers';
import { CellClasses, IPayrollSheetGroupedRow } from '../model';
import {
    usePayrollSheetGroupPositions,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/usePayrollSheetGroupCustomFields';

interface IEmployeeCellPureProps {
    cellClasses: CellClasses,
    className?: string,
    employee?: IUserInfo,
    title?: string,
}

const EmployeeCellPure = ({
    cellClasses,
    className,
    employee,
    title,
}: IEmployeeCellPureProps) => (
    <Box
        className={clsx(className, cellClasses.employeeCell)}
        display="flex"
        flexDirection="column"
        justifyContent="center"
    >
        <Box display="block">{getLastFirstName(employee)}</Box>
        <Typography
            variant="caption"
            className={cellClasses.capturedText}
        >
            {title}
        </Typography>
    </Box>
);

export function makeEmployeeGroupedCell(cellClasses: CellClasses) {
    return separateLogicDecorator<IPayrollSheetGroupedRow, IEmployeeCellPureProps>(row => {
        const positions = usePayrollSheetGroupPositions(row);
        const title = row.userTypes.length ? row.userTypes.join(', ') : positions?.map(getFieldValueName).join(', ');
        return {
            ...row,
            title,
            cellClasses,
        };
    })(EmployeeCellPure);
}
