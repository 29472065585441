import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import {
    AvailableDetailConfiguration,
    AvailableTableConfiguration,
    AvailableTotalConfiguration,
    IFieldsConfiguration,
    ISheetTotalConfiguration,
    ITotalConfiguration,
    SheetTotalSlug,
    TotalSlug,
} from 'store/entities/clients/clientsModel';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export const selectFieldsConfigurationByClientIds = (state: IStore) => state.clients.fieldsConfigurationByClientId;
/**
 * Selects Field configurations for the current client
 * @returns {IFieldsConfiguration | null} Possibly nullable field configuration
 */
export const selectCurrentClientFieldConfiguration = createSelector(
    selectCurrentClientId,
    selectFieldsConfigurationByClientIds,
    (clientId, configurationsById): IFieldsConfiguration | null => {
        return configurationsById[clientId || ''] || null;
    },
);
export const selectCurrentClientInputsConfiguration = createSelector(
    selectCurrentClientFieldConfiguration,
    (configurations: IFieldsConfiguration | null) => {
        return configurations?.inputs;
    },
);
export const selectTableConfiguration = (table: AvailableTableConfiguration) => (state: IStore) => {
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return [];
    }
    return state.clients.fieldsConfigurationByClientId[clientId]?.tables[table] || [];
};
export const selectDetailConfiguration = (detail: AvailableDetailConfiguration) => (state: IStore) => {
    const defaultValue = {
        title: undefined,
        info: [],
    };
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return defaultValue;
    }
    return state.clients.fieldsConfigurationByClientId[clientId]?.detail[detail] || defaultValue;
};
export const selectTotalConfiguration = (totalType: AvailableTotalConfiguration) => (state: IStore) => {
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return [];
    }
    return state.clients.fieldsConfigurationByClientId[clientId]?.totals?.[totalType] || [];
};
export type ITotalConfigurationByTotalSlug = Partial<Record<TotalSlug, ITotalConfiguration>>
export type ISheetTotalConfigurationByTotalSlug = Partial<Record<SheetTotalSlug, ISheetTotalConfiguration>>
