import { useEffect, useMemo } from 'react';
import { browserHistory } from 'shared/utils/browserHistory';
import { UnregisterCallback } from 'history';

/**
 * Show confirmation message before leave the page with unsaved changes
 *
 * @param blockHistory {boolean} flag for detect unsaved changes
 * @param message {string} confirmation message
 * @param onClosePageAction () => void action before close tab
 * @return unblockHistory : callback for unblock history
 */
export const useHistoryBlock = (
    blockHistory: boolean,
    message: string,
    onClosePageAction?: () => void,
): UnregisterCallback | undefined => {
    const unblockHistory = useMemo(() => {
        if (blockHistory) {
            return browserHistory.block(message);
        }
        return undefined;
    }, [message, blockHistory]);

    useEffect(() => {
        const showWarning = (event: BeforeUnloadEvent) => {
            onClosePageAction && onClosePageAction();
            event?.preventDefault();
            (event || window.event).returnValue = message;
            return message;
        };
        if (blockHistory) {
            window.addEventListener('beforeunload', showWarning);
        }
        return () => {
            window.removeEventListener('beforeunload', showWarning);
            unblockHistory && unblockHistory();
        };
    }, [message, blockHistory, unblockHistory, onClosePageAction]);

    return unblockHistory;
};
