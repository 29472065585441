import React from 'react';
import { List } from '@material-ui/core';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { EntryType } from 'shared/models/sheet/Sheet';
import { SheetInfoItem } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { useSheetGroupItems } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/hooks/useSheetGroupItems';

export interface ISheetGroupDetailByTypeProp {
    sheetGroup: IGroupedSheetCalculation;
    type: EntryType;
}

export const SheetGroupDetailByType = ({ sheetGroup, type }: ISheetGroupDetailByTypeProp) => {
    const sheetDetailClasses = useSheetDetailStyles();
    const sheetDetailsList = useSheetGroupItems(sheetGroup, type);

    return (
        <List className={sheetDetailClasses.listWrapper}>
            {sheetDetailsList.map(item => <SheetInfoItem key={item.title} data={item}/>)}
        </List>
    );
};
