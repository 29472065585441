import CostCenterFormWrapper from 'modules/settings/submodules/clients/costCenters/components/CostCenterForm/CostCenterFormWrapper';
import { ICostCenterFormValues } from 'modules/settings/submodules/clients/costCenters/components/CostCenterForm/model';
import { createCostCenter } from 'modules/settings/submodules/clients/costCenters/store/actions';
import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useDispatch } from 'react-redux';

interface INewClientModal extends Omit<IModalProps, 'title' | 'children'> {
    title: string;
    isOpened: boolean;
    onClose: () => void;
}

export default function CreateCostCenterModal({
    title, isOpened, onClose, ...modalProps
}: INewClientModal) {

    const dispatch = useDispatch();
    const onSave = useCallback((values: ICostCenterFormValues) => {
        dispatch(createCostCenter.init({
            ...values,
            number: values.number || 1,
        }));
        onClose();
    }, [dispatch, onClose]);

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={modalProps}
        >
            <Box>
                <CostCenterFormWrapper
                    onSubmit={onSave}
                    onCancel={onClose}
                    submitTitle="Create"
                    isLoading={false}
                />
            </Box>
        </ModalDialog>
    );
}
