import {
    EcubStatusSlug,
    IEcubItemsRequest,
    IEcubItemsResponse, IEcubPausePayload, IEcubPayload,
} from 'modules/settings/submodules/clients/reports/Ecub/store/models';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

const PREFIX = 'settings/reports/ecub';

export const setEcubReportActiveTab = createSingleAction<EcubStatusSlug>(`${PREFIX}/SET_ECUB_REPORT_ACTIVE_TAB`);
export const loadEcubReportItems = createRequestActions<IEcubItemsRequest, IEcubItemsResponse>(
    RequestType.Get,
    'ECUB_REPORT_ITEMS',
    PREFIX,
);
export const donwloadEcubReport = createRequestActions<IEcubPayload, void>(
    RequestType.Get,
    'ECUB_REPORT',
    PREFIX,
);
export const sendEcubReport = createRequestActions<IEcubPayload, void>(
    RequestType.Create,
    'SEND_ECUB_REPORT',
    PREFIX,
);
export const pauseEcubReport = createRequestActions<IEcubPausePayload, IEcubPausePayload>(
    RequestType.Create,
    'PAUSE_ECUB_REPORT',
    PREFIX,
);
