import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAttachmentObjectUrlById } from 'shared/components/attachments/store/selectors';
import { HeadwayLoader } from 'shared/components/loader/HeadwayLoader';
import { IAttachmentPreviewProps } from 'shared/components/attachments/model';
import { useUploadStyles } from './styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

export const AttachmentPreviewFile = ({
    attachment,
    className,
}: IAttachmentPreviewProps) => {
    const classes = useUploadStyles();
    const objectUrl = useSelector(selectAttachmentObjectUrlById(attachment.id));

    if (!objectUrl) {
        return (
            <Box mt={3}>
                <HeadwayLoader/>
            </Box>
        );
    }

    return (
        <div className={clsx(classes.fileIconContainer, className)}>
            <InsertDriveFileIcon className={classes.fileIcon}/>
        </div>
    );
};
