import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const formBasicSpacing = 2;
export const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        width: '100%',
        flexDirection: 'column',
        maxWidth: '650px',
        padding: theme.spacing(7),
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3, 2, 0),
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    input: {
        margin: theme.spacing(formBasicSpacing, formBasicSpacing, 0),
        display: 'flex',
        flexGrow: 1,
    },
    warningNoteStrong: {
        textDecoration: 'underline',
        marginRight: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: theme.spacing(7),
        },
    },
}));
