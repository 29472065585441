import { selectIsClientCreating } from 'modules/settings/submodules/clients/components/NewClientButton/state/selector';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import FormWrapper from 'modules/settings/submodules/clients/components/ClientEditForm/FormWrapper';
import { IClientData } from 'modules/settings/submodules/clients/store/models';
import { FormMode } from 'shared/models/FormMode';
import { createClient } from 'modules/settings/submodules/clients/store/actions';

interface INewClientModal extends Omit<IModalProps, 'title' | 'children'> {
    title: string;
    isOpened: boolean;
    onClose: () => void;
}

export default function NewClientModal({
    title, isOpened, onClose, ...modalProps
}: INewClientModal) {
    const dispatch = useDispatch();

    const isLoading = useSelector(selectIsClientCreating);
    const onSave = useCallback((values: IClientData) => {
        dispatch(createClient.init(values));
    }, [dispatch]);

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={modalProps}
        >
            <Box>
                <FormWrapper
                    submitTitle={'Create client'}
                    onSubmit={onSave}
                    isLoading={isLoading}
                    mode={ FormMode.New }/>
            </Box>
        </ModalDialog>
    );
}
