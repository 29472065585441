import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { ActionsReturnTypes, createActions, createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { EntryType } from 'shared/models/sheet/Sheet';
import { ISheetStatusChange } from 'store/entities/timesheet/models/Status';

const SUBMIT_SHEETS = 'SUBMIT_SHEETS';
export const submitSheets = createSingleAction<{
    payPeriod: IPayPeriod;
    entryTypes: Array<EntryType>;
}, typeof SUBMIT_SHEETS>(
    SUBMIT_SHEETS,
);

const UPDATE_SHEETS_STATUS = 'UPDATE_SHEETS_STATUS';
const UPDATE_SHEETS_STATUS_SUCCESS = 'UPDATE_SHEETS_STATUS_SUCCESS';
const UPDATE_SHEETS_STATUS_ERROR = 'UPDATE_SHEETS_STATUS_ERROR';

export const updateSheetsStatus = createActions<ISheetStatusChange, void, any,
    typeof UPDATE_SHEETS_STATUS, typeof UPDATE_SHEETS_STATUS_SUCCESS, typeof UPDATE_SHEETS_STATUS_ERROR
>(
    UPDATE_SHEETS_STATUS, UPDATE_SHEETS_STATUS_SUCCESS, UPDATE_SHEETS_STATUS_ERROR,
);

export const getStatuses = createRequestActions<string, void>(
    RequestType.Get,
    'SHEET_STATUSES',
);

export type StatusActions = ReturnType<typeof submitSheets>
| ActionsReturnTypes<typeof updateSheetsStatus>;
