import { createInstance } from '@optimizely/react-sdk';
import { LogLevel } from '@optimizely/js-sdk-logging';

const optimizelyKey: string = process.env.REACT_APP_OPTIMIZELY_KEY as string;

export const optimizely = createInstance({
    sdkKey: optimizelyKey,
    logLevel: LogLevel.ERROR,
    datafileOptions: {
        autoUpdate: true,
    },
});
