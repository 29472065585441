import baseApi from 'shared/utils/baseApi';

import {
    gwClientExtensionsService,
    submittingOrgEntity,
} from 'store/entities/configuration/configurationApi';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';

export const api = {
    async updateSubmittingOrgStatus(id: string, active: boolean): Promise<ISubmittingOrg> {
        const url = `/${gwClientExtensionsService}/${submittingOrgEntity}/${id}/patch_status`;
        const { data } = await baseApi.patch<IBody, ISubmittingOrg>(
            url,
            { active },
        );

        return data;
    },
};

interface IBody{
    active: boolean;
}
