import { IOfferLetter } from 'modules/offerLetter/store/model';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentUser, selectIsUserHasPermission } from 'store/components/auth/selectors';

export const useOfferManagePermission = (offerLetter?: IOfferLetter) => {
    const manageClientOffersAll = useSelector(selectIsUserHasPermission(Permission.ManageClientOffersAll));
    const manageClientOffersOwn = useSelector(selectIsUserHasPermission(Permission.ManageClientOffersOwn));
    const user = useSelector(selectCurrentUser);
    if (offerLetter?.creator_id === user?.id && manageClientOffersOwn) {
        return true;
    }
    return manageClientOffersAll;
};
