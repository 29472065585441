import moment from 'moment';
import React, { useState } from 'react';
import clsx from 'clsx';
import { RejectOfferLetterModal } from 'modules/offerLetter/components/RejectOfferLetter/RejectOfferLetterModal';
import { updateOfferLetter } from 'modules/offerLetter/store/actions';
import { OnboardingHeader } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingHeader';
import { IOfferLetter, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { DescriptionOutlined } from '@material-ui/icons';
import { useOfferLetterStyles } from 'modules/offerLetter/OfferLetterStyles';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from 'shared/utils/baseUrl';
import OfferLetterPdf from '../OfferLetterPdf';
import PdfFileError from 'shared/components/PdfViewer/PdfFileError';
import { selectOfferIsUpdating } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import { routes } from 'shared/routes';

export interface IViewOfferStepProps {
    id: string;
    isAlreadyOnboarded: boolean;
    offerLetter?: IOfferLetter;
}

const basePath = `${baseUrl}/documents/offer_letters`;

export const ViewOfferStep = ({ id, offerLetter, isAlreadyOnboarded }: IViewOfferStepProps) => {
    const classes = useOfferLetterStyles();
    const dispatch = useDispatch();
    const filePath = `${basePath}/${id}`;

    const isUpdating = useSelector(selectOfferIsUpdating);
    const [isOpenRejectConfirmation, setIsOpenRejectConfirmation] = useState(false);
    const [isIframe, setIsIframe] = useState(true);
    const isActionDisabled = Boolean(offerLetter?.status?.slug !== OfferLetterStatusSlug.Outstanding
        || offerLetter?.offer_letter_terms_accepted_at) || isUpdating;

    const toggleIsIframe = () => {
        setIsIframe(!isIframe);
    };

    const acceptOfferLetter = () => {
        dispatch(updateOfferLetter.init({
            id,
            values: {
                offer_letter_terms_accepted_at: moment.utc().format(),
            },
        }));
    };

    const rejectOfferLetter = () => {
        setIsOpenRejectConfirmation(true);
    };

    return (
        <Box display="flex" flexDirection="column"
            className={classes.fullWidthHeight}
            onClick={toggleIsIframe}
        >
            <OnboardingHeader
                icon={<DescriptionOutlined fontSize="small" className={classes.headerIcon}/>}
                title="1. Accept or Reject Offer Letter"
                className={isAlreadyOnboarded ? classes.blueHeader : undefined}
            />
            <Box className={clsx(classes.contentWrapper, classes.offerWrapper, classes.fullWidthHeight)}>
                {offerLetter ? (
                    <>
                        <OfferLetterPdf
                            title="Offer Letter"
                            id={id}
                            filePath={filePath}
                            className={classes.pdfWrapper}
                            errorRender={(
                                <PdfFileError
                                    className={classes.pdfWrapper}
                                    simple
                                    title="Document generation is in progress."
                                    message="Please try again later."
                                    linkLabel="Go to Offer Letters List"
                                    link={routes.EMPLOYEE_OFFER_LETTER.ROOT}
                                />
                            )}
                        />
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.actionButtonsWrapper}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={acceptOfferLetter}
                                disabled={isActionDisabled}
                                data-testid="accept-offer-button"
                            >
                                Accept
                                {isUpdating && (
                                    <Box ml={1} mb={-0.5}>
                                        <CircularProgress color="inherit" size={16}/>
                                    </Box>
                                )}
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={rejectOfferLetter}
                                disabled={isActionDisabled}
                                data-testid="reject-offer-button"
                            >
                                Reject
                            </Button>
                        </Box>
                    </>
                ) : (
                    <Typography>
                        The link is incorrect. Please, check the email or contact the support.
                    </Typography>
                )}
            </Box>
            <RejectOfferLetterModal
                offerLetterId={id}
                open={isOpenRejectConfirmation}
                onClose={() => setIsOpenRejectConfirmation(false)}
            />
        </Box>
    );
};
