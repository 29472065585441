import { IStore } from 'store/configureStore';

export const selectWorkingConditionsState = (state: IStore) => {
    return state.modules.settings.clientsSettings.clients.workingConditionsTemplates;
};

/*
 * Create
 */
export const selectIsCreateWorkingConditionsTemplateModalOpened = (state: IStore) =>
    selectWorkingConditionsState(state).isOpenCreateWorkingConditionsModal;
export const selectIsWorkingConditionsTemplateCreating = (state: IStore) =>
    selectWorkingConditionsState(state).isWorkingConditionsCreating;

/*
 * Update
 */
export const selectEditWorkingConditionsTemplateId = (state: IStore) =>
    selectWorkingConditionsState(state).editWorkingConditionsId;
export const selectIsWorkingConditionsTemplateUpdating = (state: IStore) =>
    selectWorkingConditionsState(state).isWorkingConditionsUpdating;
