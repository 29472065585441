import { createActions, createSingleAction } from 'store/utils';
import { IGetOwnConfigurationSuccess } from 'store/components/addEntry/addEntryModel';

export const SET_ADD_ENTRY_WAS_OPENED = 'SET_ADD_ENTRY_SHOULD_OPEN';

const GET_OWN_CONFIGURATION = 'GET_OWN_CONFIGURATION';
const GET_OWN_CONFIGURATION_SUCCESS = 'GET_OWN_CONFIGURATION_SUCCESS';
const GET_OWN_CONFIGURATION_ERROR = 'GET_OWN_CONFIGURATION_ERROR';

export const getOwnConfiguration = createActions<void, IGetOwnConfigurationSuccess, any,
    typeof GET_OWN_CONFIGURATION, typeof GET_OWN_CONFIGURATION_SUCCESS, typeof GET_OWN_CONFIGURATION_ERROR
>(
    GET_OWN_CONFIGURATION, GET_OWN_CONFIGURATION_SUCCESS, GET_OWN_CONFIGURATION_ERROR,
);

export const setAddEntryWasOpened = createSingleAction<void, typeof SET_ADD_ENTRY_WAS_OPENED>(
    SET_ADD_ENTRY_WAS_OPENED,
);
