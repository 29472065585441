import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ClassValue } from 'clsx';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { IGridTableRowProps } from 'shared/components/table/GridTable/GridTableRow';
import { ITableContainerCssProps, useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';
import { VirtualizedGridTableRowPure } from 'shared/components/table/VirtualizedGridTable/VirtualizedGridTableRowPure';

interface IVirtualRowProps<RowData> {
    index: number;
    style?: any;

    getRowByIndex: (index: number) => RowData;
    getRowId: (row: RowData) => string;
    classesProps: ITableContainerCssProps;
    headerHeight: number;

    cells: Array<ICellInfo<RowData>>;
    bodyCellClassName?: string;
    getRowClasses?: (row: RowData) => ClassValue[];
    customRowClassName?: string;
    bodyRowClassName?: string

    RenderRowComponent?: (props: IGridTableRowProps<RowData>) => React.ReactElement;
}

export function VirtualizedGridTableRow<RowData>({
    index,
    style,
    classesProps,
    getRowByIndex,
    getRowId,
    headerHeight,
    bodyRowClassName,
    bodyCellClassName,
    getRowClasses,
    cells,
    RenderRowComponent = VirtualizedGridTableRowPure,
}: IVirtualRowProps<RowData>) {
    const classes = useGridTableStyles(classesProps);
    const paddingStyle = {
        ...style,
        top: `${parseFloat(style.top) + headerHeight}px`,
    };
    const row = getRowByIndex(index);
    if (!row) {
        return (
            <Box
                style={paddingStyle}
                className={classes.skeletonContainer}
                boxSizing="border-box"
                pt={1}
                pr={2}
                pl={2}
            >
                <Skeleton
                    animation="wave"
                    height={24}
                />
            </Box>
        );
    }
    return (
        <RenderRowComponent
            style={ paddingStyle }
            key={ getRowId(row) }
            row={ row }
            index={index}
            customRowClassName={bodyRowClassName}
            bodyCellClassName={bodyCellClassName}
            getRowClasses={getRowClasses}
            cells={cells}
            classes={classes}
        />
    );
}
