import { selectSheetGroupDetailId } from 'modules/sheetApproval/store/selectors';
import { selectEmployeeDetailSheetGroup } from 'modules/timeAndExpense/components/EmployeeSheetDetail/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Some cases of editing sheet group can mutate original set of sheets in group
 * For correct working with edit sheet page we stored original state of group
 */
export const useDetailSheetGroup = () => {
    const sheetGroupDetailId = useSelector(selectSheetGroupDetailId);
    const detailSheetGroup = useSelector(selectEmployeeDetailSheetGroup);

    const [storedDetailSheetGroup, setDetailSheetGroup] = useState(detailSheetGroup);

    useEffect(() => {
        if (sheetGroupDetailId && detailSheetGroup) {
            setDetailSheetGroup(detailSheetGroup);
        } else if (!sheetGroupDetailId) {
            // @ts-ignore
            setDetailSheetGroup(null);
        }
    }, [detailSheetGroup, sheetGroupDetailId]);

    return storedDetailSheetGroup;
};
