import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';
import {
    IAssignmentReportData,
    IAssignmentReportDataCreateRequest,
    IAssignmentReportDataResponse,
    IAssignmentReportDataUpdatePayload,
} from 'modules/settings/submodules/clients/assignmentsReportData/store/model';

const moduleActionPrefix = 'settings/assignment_report_data/';

const SET_EDIT_ID = `${moduleActionPrefix}/SET_EDIT_ID`;
export const setEditAssignmentEditDataId = createSingleAction<string | null, typeof SET_EDIT_ID>(
    SET_EDIT_ID,
);

const SET_OPEN_CREATE_MODAL = `${moduleActionPrefix}/SET_OPEN_CREATE_MODAL`;
export const setOpenAssignmentMetaDataCreateModal = createSingleAction<boolean, typeof SET_OPEN_CREATE_MODAL>(
    SET_OPEN_CREATE_MODAL,
);

export const INITIAL_LOAD_LIST = `${moduleActionPrefix}/INITIAL_LOAD_LIST`;
export const initialLoadAssignmentReportData = createSingleAction<void, typeof INITIAL_LOAD_LIST>(
    INITIAL_LOAD_LIST,
);

export const getMoreAssignmentReportDataAction = createRequestActions<void, IAssignmentReportDataResponse>(
    RequestType.Get,
    'MORE_ASSIGNMENTS',
    moduleActionPrefix,
);

export const updateAssignmentReportMetaDataAction = createRequestActions<
IAssignmentReportDataUpdatePayload, IAssignmentReportData>(
    RequestType.Update,
    'ASSIGNMENT_REPORT_META_DATA',
    moduleActionPrefix,
);

export const createAssignmentReportMetaDataAction = createRequestActions<
IAssignmentReportDataCreateRequest, IAssignmentReportData>(
    RequestType.Create,
    'ASSIGNMENT_REPORT_META_DATA',
    moduleActionPrefix,
);

export type AssignmentReportDataActions =
    | ReturnType<typeof initialLoadAssignmentReportData>
    | ActionsReturnTypes<typeof getMoreAssignmentReportDataAction>
    | ActionsReturnTypes<typeof updateAssignmentReportMetaDataAction>
    | ActionsReturnTypes<typeof createAssignmentReportMetaDataAction>
