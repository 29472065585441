import { IStore } from 'store/configureStore';
import { selectControllingOrgState } from 'modules/settings/submodules/clients/controllingOrg/store/selectors';

const selectCreateControllingOrg = (state: IStore) => {
    const controllingOrgState = selectControllingOrgState(state);
    return controllingOrgState.createControllingOrg;
};

export const selectIsControllingOrgCreating = (state: IStore) => {
    const createControllingOrgState = selectCreateControllingOrg(state);
    return createControllingOrgState.isControllingOrgCreating;
};

export const selectIsControllingOrgModalOpen = (state: IStore) => {
    const createControllingOrgState = selectCreateControllingOrg(state);
    return createControllingOrgState.isCreateControllingOrgModalOpen;
};
