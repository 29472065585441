import {
    formBasicSpacing,
    useSettingsFormStyles,
} from 'modules/settings/submodules/components/sharedStyles/formStyles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { Box, Button } from '@material-ui/core';
import React from 'react';

export interface IFormButtonBlockProps{
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onCancel?: () => void;
}

export const FormButtonBlock = (props: IFormButtonBlockProps) => {
    const { isLoading = false, isDisabled = false, submitTitle, onCancel } = props;
    const classes = useSettingsFormStyles();
    return (
        <Box m={formBasicSpacing} mt={2}>
            <ButtonWithLoader
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={isDisabled}
                isLoading={isLoading}
                data-testid="submit_update_button"
            >
                {submitTitle}
            </ButtonWithLoader>
            {onCancel && (
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={onCancel}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
            )}
        </Box>
    );
};
