import { KeyValuePair } from 'types/types';
import { flatten } from 'lodash-es';
import { IApprover } from 'modules/offerLetter/store/model';
import { IBulkCreateSubAssignmentForm } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/types';
import { IUserInfo } from 'shared/models/User';
import { ISubassignmentBase } from 'store/entities/configuration/configurationModel';

export function getSubassignmentFromFormValues(
    assignmentId: string,
    clientId: string,
    {
        startDate,
        endDate,
        customFieldValues,
        customFieldValue,
        customFieldValuesAllFields,
        overrideRate,
        approvers,
    }: IBulkCreateSubAssignmentForm,
): ISubassignmentBase {
    const customFieldValueIds = [
        ...flatten(Object.values(customFieldValues || {})),
        ...Object.values(customFieldValue),
    ].filter(Boolean) as Array<string>;
    const allCheckedCustomFieldsIds: Array<string> = (
        Object
            .entries(customFieldValuesAllFields || {})
            .filter(([, value]: KeyValuePair<string, boolean>) => value)
            .map(([key]: KeyValuePair<string, boolean>) => key)
    );
    const managers = Object.values(approvers || {}).map(toApprover) || [];

    return {
        assignment_id: assignmentId,
        client_id: clientId,
        start_date: startDate,
        end_date: endDate || null,
        override_rate_value: overrideRate?.toString() || '',
        custom_field_value_ids: customFieldValueIds || [],
        all_values_custom_field_ids: allCheckedCustomFieldsIds || [],
        managers,
    };
}

export function toApprover(user: IUserInfo | null, index: number): IApprover {
    return {
        user_id: user?.id || '',
        manager_level: index + 1,
    };
}
