import React, { useCallback } from 'react';
import { isEmpty } from 'lodash-es';
import { approveEaf, setEafApproveIds } from 'modules/eafApproval/store/actions';
import { selectApproveEafIds } from 'modules/eafApproval/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { pluralize } from 'shared/utils/formatters/pluralize';

export const ApproveEafConfirmation = () => {
    const eafIdsToApprove = useSelector(selectApproveEafIds);

    const dispatch = useDispatch();
    const onClose = useCallback(() => {
        dispatch(setEafApproveIds([]));
    }, [dispatch]);
    const onConfirm = useCallback(() => {
        dispatch(approveEaf.init(eafIdsToApprove));
        onClose();
    }, [eafIdsToApprove, dispatch, onClose]);

    return (
        <ConfirmationDialog
            open={!isEmpty(eafIdsToApprove)}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmText="Accept"
        >
            Are you sure you want to approve
            {pluralize(' this employee action form', eafIdsToApprove.length, ' these employee action forms')}?
        </ConfirmationDialog>
    );
};
