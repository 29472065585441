import { FormControl, Select } from '@material-ui/core';
import React, { ChangeEvent, useCallback } from 'react';
import { useFilterSelectClasses } from './FilterSelectStyles';

interface IFilterSelectProps {
    label: string;
    values: Array<{ id: string; description: string }>;
    customClasses: string;
    value: string;
    onChange: (newValue: string) => void;
}

export default function FilterSelect(
    { label, values, value, onChange, customClasses }: IFilterSelectProps,
) {
    const classes = useFilterSelectClasses();

    // MUI cannot handle target since we use option
    const handleOnChange = useCallback(({ target }: ChangeEvent<{value: any}>) => {
        onChange(target.value);
    }, [onChange]);

    return (
        <FormControl className={customClasses} variant="outlined">
            <Select classes={{ select: classes.select }}
                native
                onChange={handleOnChange}
                value={value}
            >
                <option value="">
                    {label}
                </option>
                {values.map(optionValue => (
                    <option key={optionValue.id} value={optionValue.id}>
                        {optionValue.description}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
}
