import { ValidationMessages } from 'shared/models/Validation';
import * as yup from 'yup';

const minMaxShape = {
    min: yup.number().nullable().required(ValidationMessages.REQUIRED)
        .min(0, 'Value should be more than zero'),
    max: yup.number().nullable().required(ValidationMessages.REQUIRED)
        .min(0, 'Value should be more than zero')
        .when('min', (min: number, shema: yup.NumberSchema) => min ? shema.min(min, 'Max value should be more than min') : shema),
};

export const createPayRangeValidationSchema = yup.object({
    locationValueId: yup.string().nullable().required(ValidationMessages.REQUIRED),
    positionValueId: yup.string().nullable().required(ValidationMessages.REQUIRED),
    ...minMaxShape,
});

export const inlineEditPayRangeValidationSchema = yup.object(minMaxShape);
