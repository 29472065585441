import clsx from 'clsx';
import React from 'react';
import { useStatusStyles } from 'shared/components/table/Cells/StatusStyles';

interface IStatusCellProps {
    className?: string;
    statusClassName: string;
    text: string;
}

export default function StatusCell({ className, statusClassName, text }: IStatusCellProps) {
    const classes = useStatusStyles();
    return (
        <div className={className ?? ''}>
            <span className={clsx(statusClassName, classes.statusElement)}>
                {text}
            </span>
        </div>
    );
}
