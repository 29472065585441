import React from 'react';
import { settingsRoutes } from 'modules/settings/routes';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { ClientCustomFieldsListPage } from 'modules/settings/submodules/clients/customFieldValues/components/ClientCustomFieldsListPage/ClientCustomFieldsListPage';
import { CustomFieldValuePage } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuePage/CustomFieldValuePage';
import { getCustomFields } from 'store/entities/customFields/actions';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';

export const CustomFieldValuesRoutes = () => {
    useWithClientEffect(dispatch => {
        dispatch(getCustomFields.init());
    });

    return (
        <Switch>
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.CUSTOM_FIELD_VALUES.FIELD}
                component={CustomFieldValuePage}
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.CUSTOM_FIELD_VALUES.ROOT}
                component={ClientCustomFieldsListPage}
            />
        </Switch>
    );
};
