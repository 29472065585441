import { IZohoPermalinkFormValues } from 'modules/settings/submodules/clients/reports/zoho/model';
import * as yup from 'yup';

export const zohoAnalyticsPermalinkSetupValidationSchema = yup.object<IZohoPermalinkFormValues>({
    zohoAnalyticsPermalink: (
        yup
            .string()
            .trim()
            .nullable()
    ),
});
