import React from 'react';
import { SvgIcon } from '@material-ui/core';

function PrintSVG() {
    return (
        <SvgIcon fontSize="small">
            <path
                d="M18,17H6v1h12V17z M5,1h9v5h5v2h1V5l-5-5H4v8h1V1z M21,9H3c-2,0-3,1-3,3v6h4v6h16
                v-6h4v-6C24,10,23,9,21,9z M4,13H2v-2h2V13z M19,23H5v-7h14V23z M18,19H6v1h12V19z M18,21H6v1h12V21z"
            />
        </SvgIcon>
    );
}

export default PrintSVG;
