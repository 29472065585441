import {
    editOffer,
    OfferLetterActions,
} from 'modules/offerLetter/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';

export const initialState = {
    editingOffer: '',
};

export const isOfferEditing = isLoadingReducer(editOffer);

export function editingOffer(state = initialState.editingOffer, action: OfferLetterActions): string {
    switch (action.type) {
        case editOffer.successType:
            return action.payload.id;
        default:
            return state;
    }
}

export const offerEdit = combineReducers({
    isOfferEditing,
    editingOffer,
});
