import { setOpenBulkEndAssignmentModal } from 'modules/subassignmentManagement/components/BulkEndAssignment/store/actions';
import { editSingleSubassignment } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/reducers';
import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import {
    bulkDeleteSubAssignmentAction,
    bulkDeleteSubAssignmentPreInitializeAction,
    bulkEditSubAssignmentAction,
    bulkEditSubAssignmentActionV2,
    bulkEditSubAssignmentPreInitializeAction,
    bulkEditSubAssignmentPreInitializeActionV2, getBulkEditSubAssignmentPreInitializePdfReport,
} from 'modules/subassignmentManagement/store/actions';
import { AnyAction, combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import {
    setBulkEditSubassignmentStep,
    setOpenBulkEditSubassignmentModal,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const isOpenBulkEditModal = singleValueReducer(setOpenBulkEditSubassignmentModal.action, false);

const step = (
    state = BulkEditSubassignmentSteps.Form,
    action: AnyAction,
): BulkEditSubassignmentSteps => {
    switch (action.type) {
        case setOpenBulkEditSubassignmentModal.action:
        case setOpenBulkEndAssignmentModal.action:
            return BulkEditSubassignmentSteps.Form;
        case bulkEditSubAssignmentPreInitializeAction.successType:
        case bulkEditSubAssignmentPreInitializeActionV2.successType:
            return BulkEditSubassignmentSteps.EditVerify;
        case bulkEditSubAssignmentAction.successType:
        case bulkEditSubAssignmentActionV2.successType:
            return BulkEditSubassignmentSteps.EditPostReport;
        case bulkDeleteSubAssignmentPreInitializeAction.successType:
            return BulkEditSubassignmentSteps.DeleteVerify;
        case bulkDeleteSubAssignmentAction.successType:
            return BulkEditSubassignmentSteps.DeletePostReport;
        case setBulkEditSubassignmentStep.action:
            return action.payload;
        default:
            return state;
    }
};

/**
 * Bulk edit
 */
const isLoadingEditPreInitializeReport = extendReducer(
    isLoadingReducer(bulkEditSubAssignmentPreInitializeAction),
    isLoadingReducer(bulkEditSubAssignmentPreInitializeActionV2),
);
const isLoadingEditPreInitializePdfReport = isLoadingReducer(getBulkEditSubAssignmentPreInitializePdfReport);
const isBulkUpdating = extendReducer(
    isLoadingReducer(bulkEditSubAssignmentAction),
    isLoadingReducer(bulkEditSubAssignmentActionV2),
);
const editPayload = singleValueReducer(bulkEditSubAssignmentPreInitializeAction.initType, null);
const editPayloadV2 = singleValueReducer(bulkEditSubAssignmentPreInitializeActionV2.initType, null);
const editPreInitializeReport = singleValueReducer(bulkEditSubAssignmentPreInitializeAction.successType, null);
const editReport = singleValueReducer(bulkEditSubAssignmentAction.successType, null);
const editReportV2 = extendReducer(
    singleValueReducer(bulkEditSubAssignmentPreInitializeActionV2.successType, null),
    singleValueReducer(bulkEditSubAssignmentActionV2.successType, null),
);

/**
 * Bulk delete
 */
const isLoadingDeletePreInitializeReport = isLoadingReducer(bulkDeleteSubAssignmentPreInitializeAction);
const isBulkDeleting = isLoadingReducer(bulkDeleteSubAssignmentAction);
const deletePayload = singleValueReducer(bulkDeleteSubAssignmentPreInitializeAction.initType, null);
const deletePreInitializeReport = singleValueReducer(bulkDeleteSubAssignmentPreInitializeAction.successType, null);
const deleteReport = singleValueReducer(bulkDeleteSubAssignmentAction.successType, null);

export const editSubassignments = combineReducers({
    isOpenBulkEditModal,
    step,

    isLoadingEditPreInitializePdfReport,
    isLoadingEditPreInitializeReport,
    isBulkUpdating,
    editPayload,
    editPayloadV2,
    editReportV2,
    editPreInitializeReport,
    editReport,

    isLoadingDeletePreInitializeReport,
    isBulkDeleting,
    deletePayload,
    deletePreInitializeReport,
    deleteReport,

    editSingleSubassignment,
});
