import clsx from 'clsx';
import React from 'react';
import { Button } from '@material-ui/core';
import { AttachmentPreview } from 'shared/components/attachments/AttachmentPreview';
import { AttachmentsPreviewModal } from 'shared/components/attachments/AttachmentsPreviewModal';
import { useUploadStyles } from 'shared/components/attachments/styles';
import { IAttachment } from 'shared/models/Attachments';
import { useModal } from 'shared/utils/hooks/useModal';

interface IAttachmentsPreviewProps {
    attachments: IAttachment[];
    onAttachmentRemove?: (file: IAttachment) => void;
    showModalFromCount?: number;
}

export const AttachmentsPreview = ({
    attachments,
    onAttachmentRemove,
    showModalFromCount = 0,
}: IAttachmentsPreviewProps) => {
    const classes = useUploadStyles();
    const {
        isModalOpened: isPreviewOpen,
        onModalOpen: onPreviewOpen,
        onModalClose: onPreviewClose,
    } = useModal();

    return (
        <>
            {(attachments.length > 0) && (
                <section className={classes.preview}>
                    <div className = {clsx(classes.filePreviewZone)}>
                        {attachments.length > 0 && (
                            <AttachmentPreview attachment={attachments[0]}/>
                        )}
                        {(!showModalFromCount || attachments.length > showModalFromCount) && (
                            <Button
                                className = {classes.imageButton}
                                color="inherit"
                                onClick={onPreviewOpen}>
                                See All {`[${attachments.length}]`}
                            </Button>
                        )}
                    </div>
                </section>
            )}

            {(attachments.length > 0) && isPreviewOpen && (
                <AttachmentsPreviewModal
                    open={isPreviewOpen}
                    onClose={onPreviewClose}
                    files={attachments}
                    onDelete={onAttachmentRemove}
                />
            )}
        </>
    );
};
