import clsx from 'clsx';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { deleteClientRole } from 'modules/settings/submodules/platformUsers/store/actions';
import { selectIsClientRoleDeleting } from 'modules/settings/submodules/platformUsers/store/selectors';
import { useSelector } from 'react-redux';
import { useButtonStyles } from 'shared/components/buttons/buttonStyles';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { Permission } from 'store/components/auth/authModels';
import { selectClientRolesById } from 'store/entities/users/selectors';

interface IDeleteClientRoleButtonProps {
    roleId: string;
    className?: string;
}

const DeleteButton = (props: ButtonProps) => {
    const buttonStyles = useButtonStyles();

    return (
        <Button
            {...props}
            variant="outlined"
            color="primary"
            className={clsx(buttonStyles.redOutlined, props.className)}
        >
            Delete Role
        </Button>
    );
};

export const DeleteClientRoleButton = ({ roleId }: IDeleteClientRoleButtonProps) => {
    const clientRole = useSelector(selectClientRolesById)[roleId];
    const isDeleting = useSelector(selectIsClientRoleDeleting);
    const coloredModalClasses = useColoredFormModalStyles();

    if (!clientRole || clientRole?.users_count !== 0) {
        return null;
    }
    return (
        <DeleteItemButton
            confirmation="Are you sure you want to delete this client role?"
            confirmationTitle="Delete Client Role"
            confirmationCustomClasses={{
                dialogTitle: coloredModalClasses.title,
                content: coloredModalClasses.content,
            }}
            id={roleId}
            deleteAction={deleteClientRole.init}
            disabled={isDeleting}
            permission={Permission.deleteClientSettingsEntities}
            DeleteButtonComponent={DeleteButton}
        />
    );
};
