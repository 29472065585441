import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { TextFilter } from 'shared/components/filters/TextFilter';
import { Status } from 'shared/models/Status';
import { debounce } from 'ts-debounce';
import { setDealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/store/actions';
import { UpdateStatus } from 'modules/settings/submodules/platformUsers/components/UpdateStatus/UpdateStatus';
import { selectDealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/store/selector';
import { useBigSelectStyles, useDealStatusFormControlStyles } from 'modules/settings/submodules/clients/deals/components/Filter/styles';

export const DealFilter = () => {
    const dispatch = useDispatch();
    const filterValues = useSelector(selectDealFilter);
    const selectClasses = useBigSelectStyles();
    const formControlClasses = useDealStatusFormControlStyles();

    const debounceTimeout = 200;
    const changeFilter = useMemo(() =>
        debounce(
            (newFilterValue: string) => {
                dispatch(setDealFilter({
                    search: newFilterValue,
                    status: filterValues.status,
                }));
            },
            debounceTimeout),
    [dispatch, filterValues.status],
    );
    const handleStatusChange = (status: Status) => {
        dispatch(setDealFilter({
            ...filterValues,
            status,
        }));
    };

    return (
        <Box>
            <UpdateStatus
                status={filterValues.status}
                label="Status"
                handleStatusChange={handleStatusChange}
                selectClasses={selectClasses}
                formControlClassName={formControlClasses.root}
            />
            <TextFilter onFilterValueChanged={changeFilter}/>
        </Box>
    );
};
