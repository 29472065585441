import { combineReducers } from 'redux';
import { setRedirectToJobNumberWithDealNumber } from 'modules/settings/submodules/clients/deals/components/DealForm/store/actions';
import { singleValueReducer } from 'store/reducerUtils';

const redirectToJobNumberWithDealNumber = singleValueReducer(
    setRedirectToJobNumberWithDealNumber.action,
    null);

export const dealForm = combineReducers({
    redirectToJobNumberWithDealNumber,
});
