import React, { useContext, useCallback } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { MenuContext } from 'shared/models/menuContext';
import { UserProfileTab } from './store/profileModel';
import { useStyles } from './styles';
import a11yProps from 'shared/components/tabs/a11yProps';
import ProfileContent from './ProfileContent';
import AccountContent from './AccountContent';

export default function UserProfileMobileView() {

    const { menuState, setMenuState } = useContext(MenuContext);
    const classes = useStyles();
    const tabsClasses = {
        root: classes.tabs,
        flexContainer: classes.tabsFlexContainer,
        indicator: classes.tabsIndicator,
    };
    const onChange = useCallback((_, value: UserProfileTab) => {
        setMenuState({
            ...menuState,
            tabs: {
                ...menuState.tabs,
                userProfile: value,
            },
        });
    }, [menuState, setMenuState]);

    return (
        <>
            <Tabs
                classes={tabsClasses}
                value={menuState.tabs.userProfile}
                onChange={onChange}
                textColor="inherit"
            >
                <Tab classes={{ root: classes.tab, selected: classes.tabActive }}
                    label="PROFILE" value={UserProfileTab.Profile}
                    {...a11yProps(0)}/>
                <Tab classes={{ root: classes.tab, selected: classes.tabActive }}
                    label="ACCOUNT" value={UserProfileTab.Account}
                    {...a11yProps(1)}/>
            </Tabs>   
            {menuState.tabs.userProfile === UserProfileTab.Profile
        && (<ProfileContent/>)}
            {menuState.tabs.userProfile === UserProfileTab.Account
        && (<AccountContent/>)}
        </>
    );
}
