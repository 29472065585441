import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import CustomFieldFormWrapper from 'modules/settings/submodules/customFields/components/CustomFieldForm/CustomFieldFormWrapper';
import { ICustomFieldFormValues } from 'modules/settings/submodules/customFields/components/CustomFieldForm/model';
import { selectEditedCustomField, selectIsCustomFieldUpdating } from 'modules/settings/submodules/customFields/components/EditCustomField/store/selectors';
import { setEditCustomFieldId, updateCustomField } from 'modules/settings/submodules/customFields/store/actions';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const EditCustomFieldModal = () => {
    const classes = useColoredFormModalStyles();
    const dispatch = useDispatch();
    const customField = useSelector(selectEditedCustomField);
    const isLoading = useSelector(selectIsCustomFieldUpdating);

    const onClose = useCallback(() => {
        dispatch(setEditCustomFieldId(null));
    }, [dispatch]);
    const onSave = useCallback((values: ICustomFieldFormValues) => {
        dispatch(updateCustomField.init({
            id: customField.id,
            data: {
                name: values.name,
                description: values.description,
                client_ids: values.all_clients ? [] : values.clients,
                all_clients: values.all_clients,
            },
        }));
    }, [customField, dispatch]);

    return (
        <ModalDialog
            title="Edit Custom Field"
            isOpened={Boolean(customField)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <CustomFieldFormWrapper
                    isEdit
                    onSubmit={onSave}
                    submitTitle="Save"
                    isLoading={isLoading}
                    customField={customField}
                />
            </Box>
        </ModalDialog>
    );
};
