import { combineReducers } from 'redux';
import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import {
    createBackgroundCheckTemplate,
    setCreateBackgroundCheckTemplateModalState,
    setEditBackgroundCheckTemplateId,
    updateBackgroundCheckTemplate,
} from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/action';

const isOpenCreateBackgroundCheckModal = isOpenModalReducer(setCreateBackgroundCheckTemplateModalState.action);
const isBackgroundCheckCreating = isLoadingReducer(createBackgroundCheckTemplate);
const isBackgroundCheckUpdating = isLoadingReducer(updateBackgroundCheckTemplate);

function editBackgroundCheckId(
    state: string | null,
    action: ReturnType<typeof setEditBackgroundCheckTemplateId>,
): string | null {
    state = state || null;
    switch (action.type) {
        case setEditBackgroundCheckTemplateId.action:
            return action.payload;
        default:
            return state;
    }
}

export const backgroundChecksTemplates = combineReducers({
    isOpenCreateBackgroundCheckModal,
    isBackgroundCheckCreating,
    editBackgroundCheckId,
    isBackgroundCheckUpdating,
});
