/* eslint-disable react/display-name */
import { Box, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { max } from 'lodash-es';
import { setGroupedSheetsApprovalId, setGroupedSheetsRejectId, setSheetGroupedDetailId, setSheetGroupedSidebarId } from 'modules/sheetApproval/store/actions';
import { useIsUserHasSheetApprovePermission } from 'modules/sheetApproval/store/hooks';
import { ISheetGroupRow, SheetGroup } from 'modules/timeAndExpense/store/model';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ScaWithTooltip } from 'shared/components/customFieldValues/ScaWithTooltip/ScaWithTooltip';
import PdfSVG from 'shared/components/icons/PdfSVG';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import PlainText from 'shared/components/table/Cells/PlainText';
import SheetStatus from 'shared/components/table/Cells/SheetStatus';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import Approve from 'shared/components/table/tableApprovalCells/Approve/Approve';
import QuantityInfo from 'shared/components/table/tableApprovalCells/QuantityMobile/QuantityInfo';
import Reject from 'shared/components/table/tableApprovalCells/Reject/Reject';
import Total from 'shared/components/table/tableApprovalCells/Total/Total';
import { getMobileItemsRowByConfig } from 'shared/components/table/utils';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getUserName, getLastFirstName } from 'shared/utils/converters/user';
import { sheetsTotalDollars } from 'shared/utils/counters/dollarCounter';
import { sheetsTotalTimeInMinutes } from 'shared/utils/counters/timeCounter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { useIsMobile } from 'shared/utils/hooks/media';
import { IColumnConfiguration, SheetColumnSlug } from 'store/entities/clients/clientsModel';

export const getApprovedLevelWithTotalGroupedCell = (approversCount: number) => ({
    key: 'approved_level',
    title: 'Approved',
    width: '80px',
    render: ({ className, group }: ISheetGroupRow) => {
        const levels = group.sheets.map(sh => sh?.approved_level || 0);
        const approvedLevel = max(levels);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
            <PlainText className={ className } value={ `${ approvedLevel || 0 }/${ approversCount }` }/>
        );
    },
});

export const getHoursAmountGroupedCell = (
    classes: ReturnType<typeof useSubmittedTableStyles>,
    entryType: EntryType,
    isMobile: boolean,
) => {
    function getGroupValue(group: SheetGroup): string {
        let value = '';
        if (entryType === EntryType.TIME) {
            value = group.totalMinutes;
            if (group.totalFiles) {
                value = `${value} | ${group.totalFiles}`;
            }
        } else {
            value = group.totalDollars;
        }
        return value;
    }
    function HoursAmountCell({ group, className }: ISheetGroupRow) {
        return (
            <PlainText className={clsx(className, classes.amountCell)} value={getGroupValue(group)}/>
        );
    }

    function HoursAmountMobileCell({ group, className }: ISheetGroupRow) {
        return (
            <QuantityInfo quantity={getGroupValue(group)}
                className={className}
                type={entryType === EntryType.TIME ? 'hours' : ''}
            />
        );
    }

    return (title: string) => ({
        key: 'hours/amount',
        width: isMobile ? 'minmax(90px, 1fr)' : '112px',
        title: title,
        headerClassName: classes.amountCell,
        render: isMobile ? HoursAmountMobileCell : HoursAmountCell,
    });
};

export const getStatusCell = (
    classes: ReturnType<typeof useSubmittedTableStyles>,
    showStatus: boolean,
) => showStatus ? [
    {
        key: 'status',
        title: 'status',
        width: '150px',
        headerClassName: classes.displayCenterCell,
        render: ({ className, group }: ISheetGroupRow) => (
            <SheetStatus sheet={group.sheets[0]} className={className}/>
        ),
    },
] : [];

export const getDetailsCell = (
    classes: ReturnType<typeof useSubmittedTableStyles>,
    entryType: EntryType,
    isMobile?: boolean,
) => ({
    key: 'details',
    title: '',
    width: !isMobile ? '152px' : '48px',
    renderTitle: function DetailsHeaderCell({ rows }: { rows: Array<ISheetGroupRow> }) {
        const sheets = rows.map(row => row.group.sheets).flat();
        return (
            <Total
                total={
                    entryType === EntryType.TIME
                        ? formatMinutes(sheetsTotalTimeInMinutes(sheets))
                        : formatDollars(sheetsTotalDollars(sheets))
                }
                label={entryType === EntryType.TIME ? 'hrs' : ''}
            />
        );
    },
    render: function DetailsCell({ className, id }: ISheetGroupRow) {
        const dispatch = useDispatch();
        const onClick = () => {
            dispatch(setSheetGroupedDetailId(id));
            dispatch(setSheetGroupedSidebarId(id));
        };
        return (
            <div className={clsx(className, classes.iconCell, classes.detailsIcon)}>
                <IconButton
                    onClick={onClick}
                    data-testid="details-button"
                >
                    <ArrowForwardIosIcon/>
                </IconButton>
            </div>
        );
    },
});

export const getActionCell = (classes: ReturnType<typeof useSubmittedTableStyles>) => ({
    key: 'actions',
    title: '',
    width: '88px',
    render: function ActionsCell({ className, group }: ISheetGroupRow) {
        const dispatch = useDispatch();
        const approve = useCallback(() => {
            dispatch(setGroupedSheetsApprovalId(group.id));
        }, [dispatch, group]);
        const reject = useCallback(() => {
            dispatch(setGroupedSheetsRejectId(group.id));
        }, [dispatch, group]);
        const hasPermission = useIsUserHasSheetApprovePermission();
        return (
            <div className={clsx(className, classes.iconCell, classes.actionsIcon)}>
                {hasPermission && (
                    <>
                        <Reject onClick={reject}/>
                        <Approve onClick={approve}/>
                    </>
                )}
            </div>
        );
    },
});

export const mobileInfoCell = (configuration: IColumnConfiguration<SheetColumnSlug>[]) => ({
    key: 'all details',
    title: '',
    width: '2fr',
    render: ({
        className,
        group,
    }: ISheetGroupRow) => {
        return (
            <MobileListCell
                className={className}
                title={getLastFirstName(group.employee) || ''}
                items={[
                    getPayPeriodByStartEnd(group.payPeriod.period_start, group.payPeriod.period_end),
                    getMobileItemsRowByConfig(
                        [
                            {
                                slug: SheetColumnSlug.JobNumber,
                                getText: () => group.jobNumbersString,
                            },
                        ],
                        configuration,
                    ),
                ]}
            />
        );
    },
});

export const useGroupedSheetColumnDictionary = (
    isForManager = false,
    renderTitleFactory?: (id: string, title: string) => any,
): Record<
    SheetColumnSlug,
    (placeholder: string, classes?: any, additionalData?: any,) => ICellInfo<ISheetGroupRow> | null
    > => {
    const classes = useSubmittedTableStyles();
    const isMobile = useIsMobile();

    return {
        [SheetColumnSlug.Position]: () => null,
        [SheetColumnSlug.Area]: () => null,
        [SheetColumnSlug.CustomField]: (placeholder, _, additionalData) => ({
            key: `${SheetColumnSlug.CustomField}-${additionalData?.customFieldId}`,
            title: placeholder,
            render: function CustomFieldCell({ className, group }: ISheetGroupRow) {
                const customFieldId = additionalData?.customFieldId || '';
                return (
                    <PlainText
                        className={className}
                        value={group.getCustomFieldValuesAsStringById(customFieldId)}
                    />
                );
            },
        }),
        [SheetColumnSlug.SCA]: (placeholder, _, additionalData) => ({
            key: `${SheetColumnSlug.CustomField}-${additionalData?.customFieldId}`,
            title: placeholder,
            render: function CustomFieldCell({ className, group }: ISheetGroupRow) {
                const customFieldId = additionalData?.customFieldId || '';
                return (
                    <Box className={className}>
                        {group.customFieldValues[customFieldId]?.map(
                            value => <ScaWithTooltip key={value.id} customFieldValueId={value.id}/>,
                        )}
                    </Box>
                );
            },
        }),
        [SheetColumnSlug.Department]: () => null,
        [SheetColumnSlug.Id]: () => null,
        [SheetColumnSlug.JobNumber]: (placeholder: string) => ({
            key: SheetColumnSlug.JobNumber,
            title: placeholder,
            width: isForManager ? '3fr' : '2fr',
            render: ({ className, group }: ISheetGroupRow) => {
                return (
                    <PlainText
                        className={className}
                        value={group?.jobNumbersString}
                    />
                );
            },
        }),
        [SheetColumnSlug.Location]: () => null,
        [SheetColumnSlug.Approver]: (placeholder: string) => ({
            key: SheetColumnSlug.Employee,
            title: placeholder,
            width: '2fr',
            render: ({ className, group }: ISheetGroupRow) => {
                const value = group.approvers.map(approver => getUserName(approver)).join(', ');
                return (
                    <PlainText
                        className={className}
                        value={value}
                    />
                );
            },
        }),
        [SheetColumnSlug.Employee]: (placeholder: string) => ({
            key: SheetColumnSlug.Employee,
            title: placeholder,
            renderTitle: renderTitleFactory && renderTitleFactory(SheetColumnSlug.Employee, placeholder),
            width: '2fr',
            render: ({ className, group }: ISheetGroupRow) => {
                if (!group.employee) {
                    return (
                        <Box className={className}>
                            <Skeleton width="90px"/>
                        </Box>
                    );
                }
                return (
                    <PlainText
                        className={className}
                        value={getLastFirstName(group.employee)}
                    />
                );
            },
        }),
        [SheetColumnSlug.PayPeriod]: (placeholder: string) => ({
            key: SheetColumnSlug.PayPeriod,
            title: placeholder,
            renderTitle: renderTitleFactory && renderTitleFactory(SheetColumnSlug.PayPeriod, placeholder),
            width: '2fr',
            render: ({ className, group }: ISheetGroupRow) => {
                return (
                    <PlainText
                        className={className}
                        value={getPayPeriodByStartEnd(group.payPeriod.period_start, group.payPeriod.period_end)}
                    />
                );
            },
        }),
        [SheetColumnSlug.Hours]: getHoursAmountGroupedCell(classes, EntryType.TIME, isMobile),
        [SheetColumnSlug.Amount]: getHoursAmountGroupedCell(classes, EntryType.EXPENSE, isMobile),
        [SheetColumnSlug.Receipt]: (placeholder: string) => ({
            key: SheetColumnSlug.Receipt,
            title: placeholder,
            width: '80px',
            headerClassName: classes.displayCenterCell,
            render: ({ className, group }: ISheetGroupRow) => {
                return (
                    <div className={ clsx(className, classes.iconCell, classes.pdfIcon) }>
                        {
                            group.hasReceipt && (
                                <IconButton>
                                    <PdfSVG/>
                                </IconButton>
                            )
                        }
                    </div>
                );
            },
        }),
        [SheetColumnSlug.RegularHours]: (placeholder: string) => ({
            key: 'regularHours',
            title: placeholder,
            width: '2fr',
            render: ({ group, className }: ISheetGroupRow) => {
                let value = group.regularHours;
                if (group.totalFiles) {
                    value = `${value} | ${group.totalFiles}`;
                }
                return (
                    <PlainText className={className} value={value}/>
                );
            },
        }),
        [SheetColumnSlug.Overtime1Hours]: (placeholder: string) => ({
            key: 'overtime1Hours',
            title: placeholder,
            width: '2fr',
            render: ({ group, className }: ISheetGroupRow) => (
                <PlainText
                    className={className}
                    value={group.overtimeHours}
                />
            ),
        }),
        [SheetColumnSlug.Overtime2Hours]: (placeholder: string) => ({
            key: 'overtime2Hours',
            title: placeholder,
            width: '2fr',
            render: ({ group, className }: ISheetGroupRow) => (
                <PlainText
                    className={className}
                    value={group.doubletimeHours}
                />
            ),
        }),
        [SheetColumnSlug.HolidayHours]: (placeholder: string) => ({
            key: 'holidayHours',
            title: placeholder,
            width: '2fr',
            render: ({ group, className }: ISheetGroupRow) => (
                <PlainText
                    className={className}
                    value={group.holidayHours}
                />
            ),
        }),
        [SheetColumnSlug.PtoHours]: (placeholder: string) => ({
            key: 'ptoHours',
            title: placeholder,
            width: '1fr',
            headerClassName: classes.amountCell,
            render: ({ group, className }: ISheetGroupRow) => (
                <PlainText
                    className={clsx(clsx(className, classes.amountCell))}
                    value={group.ptoHours}
                />
            ),
        }),
    };
};
