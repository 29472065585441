import { AssignmentReportDataActions, createAssignmentReportMetaDataAction, getMoreAssignmentReportDataAction, initialLoadAssignmentReportData, updateAssignmentReportMetaDataAction } from 'modules/settings/submodules/clients/assignmentsReportData/store/actions';
import { IAssignmentReportData } from 'modules/settings/submodules/clients/assignmentsReportData/store/model';
import { defaultInfinityScrollState, IInfinityScrollState } from 'store/reducerUtils';

export function assignmentReportDataTable(
    state: IInfinityScrollState<IAssignmentReportData>,
    action: AssignmentReportDataActions,
): IInfinityScrollState<IAssignmentReportData> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case createAssignmentReportMetaDataAction.successType:
        case initialLoadAssignmentReportData.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreAssignmentReportDataAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreAssignmentReportDataAction.successType:
            return {
                // @ts-ignore
                total: action.payload.total_items,
                // @ts-ignore
                items: [...state.items, ...action.payload.user_meta],
                isLoading: false,
            };
        case updateAssignmentReportMetaDataAction.successType: {
            const updatedItem = action.payload;
            return {
                ...state,
                // @ts-ignore
                items: state.items.map(item => updatedItem.id === item.id ? updatedItem : item),
            };
        }
        default:
            return state;
    }
}
