import { ICustomFieldHierarchyNode } from 'store/entities/customFields/model';

export interface ICustomFiledFiltration {
    parentNodeValues: Record<string, string | string[]>;
    siblingsNodesValues: Record<string, string | string[]>;
}

/* Auxiliary function to retrieve parent and siblings nodes */
function getOrganizedNodes(hierarchy: ICustomFieldHierarchyNode[], customFieldId: string) {
    const currentNode = hierarchy.find(node => node.custom_field_id === customFieldId);
    const parentNode = hierarchy.find(node => node.id === currentNode?.parent_id);
    const siblingsNodes = (
        hierarchy
            .filter(node => node.parent_id === currentNode?.parent_id)
            .filter(node => node.custom_field_id !== customFieldId)
    );
    return { parentNode, siblingsNodes };
}

/* Auxiliary function - Parent node can be single or multiselect - we getting it's values as an array */
function getParentNodeValues(
    parentNode: ICustomFieldHierarchyNode | undefined,
    customFieldFormValues: Record<string, string | string[]>,
): Record<string, string | string[]> {
    const parentNodeCustomFieldId = parentNode?.custom_field_id;
    if (parentNodeCustomFieldId) {
        return {
            [parentNodeCustomFieldId]: customFieldFormValues[parentNodeCustomFieldId],
        };
    }
    return {};
}

/* Auxiliary function - Receive all the values from all the sibling fields */
function getSiblingsNodesValues(
    siblingsNodes: ICustomFieldHierarchyNode[],
    customFieldFormValues: Record<string, string | string[]>,
): Record<string, string | string[]> {
    return siblingsNodes.reduce((mem, node) => {
        return {
            ...mem,
            [node.custom_field_id]: customFieldFormValues[node.custom_field_id],
        };
    }, {} as Record<string, string | string[]>);
}

/* Makes filtration by hierarchy - uses all the functions above ^^^^^ */
export const getFiltrationByHierarchy = (
    customFieldId: string,
    hierarchy: ICustomFieldHierarchyNode[],
    customFieldFormValues: Record<string, string | string[]>,
): ICustomFiledFiltration => {
    const { parentNode, siblingsNodes } = getOrganizedNodes(hierarchy, customFieldId);

    const parentNodeValues = getParentNodeValues(parentNode, customFieldFormValues);

    const siblingsNodesValues = getSiblingsNodesValues(siblingsNodes, customFieldFormValues);

    return {
        parentNodeValues,
        siblingsNodesValues,
    };
};
