import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core';

const useActionIconStyles = makeHighPriorityStyles((theme: Theme) => ({
    actionIconCell: {
        padding: 0,
        width: 40,
        textAlign: 'center',
    },
    actionIcon: {
        fontSize: 21,
        verticalAlign: 'middle',
    },
    button: {
        margin: theme.spacing(-0.5, 0),
    },
}));

export default useActionIconStyles;
