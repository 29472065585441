import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const useCommonButtonStyles = (theme: Theme) => ({
    border: `1px solid ${colors.lightGray}`,
    backgroundColor: colors.white,
    padding: theme.spacing(0.5),
    height: theme.spacing(4),
    minWidth: 'auto',
    '& + button': {
        marginLeft: theme.spacing(1),
    },
});

const useSecondaryButtonStyles = (theme: Theme) => ({
    color: colors.lightGray,
    padding: theme.spacing(1, 2),
});

const useActionButtonStyles = makeHighPriorityStyles((theme: Theme) => ({
    default: {
        ...useCommonButtonStyles(theme),
    },
    blue: {
        ...useCommonButtonStyles(theme),
        ...useSecondaryButtonStyles(theme),
        border: `1px solid ${colors.gray}`,
        color: colors.gray,
        '& .active, &:focus, &.active': {
            color: colors.blue,
            borderColor: colors.blue,
            backgroundColor: colors.white,
        },
    },
    green: {
        ...useCommonButtonStyles(theme),
        ...useSecondaryButtonStyles(theme),
        border: `1px solid ${colors.gray}`,
        color: colors.gray,
        '& .active, &:focus, &.active': {
            color: colors.green,
            borderColor: colors.green,
            backgroundColor: colors.white,
        },
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 14,
        lineHeight: 1.6,
    },
}));

export default useActionButtonStyles;
