import { fade } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useField } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import { shortDateFormat } from 'shared/models/Dates';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { moment } from 'utils/momentExtensions';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export interface IDayPickerFieldProps extends Omit<Partial<IFormFieldProps>, 'name'> {
    format?: string;
    disableToolbar?: boolean;
    withKeyboard?: boolean;
    startDateName: string;
    endDateName: string;
    startDatePlaceholder?: string;
    endDatePlaceholder?: string;
    style?: object;
}

const useStyles = makeHighPriorityStyles(theme => ({
    highlight: {
        background: fade(theme.palette.primary.main, .2),
    },
    firstHighlight: {
        background: fade(theme.palette.primary.main, .2),
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    },
    endHighlight: {
        background: fade(theme.palette.primary.main, .2),
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    },
}));

function DayRangePickerField({
    startDateName,
    endDateName,
    id = name,
    className,
    outerLabel,
    style,
    format = shortDateFormat,
    startDatePlaceholder = 'MM/DD/YYYY',
    endDatePlaceholder = 'MM/DD/YYYY',
}: IDayPickerFieldProps) {
    const formHelperTextClasses = useFormHelperTextStyles();
    const [startField] = useField(startDateName);
    const [endField,, endFieldHelper] = useField(endDateName);
    const classes = useStyles();

    useEffect(() => {
        if (startField.value && endField.value && moment(startField.value).isAfter(endField.value, 'd')) {
            endFieldHelper.setError('Start date after End date');
            endFieldHelper.setTouched(true);
            endFieldHelper.setValue(null);
        }
    }, [endField.value, endFieldHelper, startField.value]);

    const renderEndDay = useMemo(
        () => {
            if (!startField.value) {
                return undefined;
            }
            // eslint-disable-next-line react/display-name
            return (date, selectedDate, isInCurrentMonth, dayComponent) => {
                const dateClone = date.clone(date);

                const dayIsBetween = dateClone.isBetween(startField.value, selectedDate, 'd');
                const isFirstDay = dateClone.isSame(startField.value, 'd');
                const isLastDay = dateClone.isSame(selectedDate, 'd');

                const classNames = clsx({
                    [classes.highlight]: dayIsBetween,
                    [classes.firstHighlight]: isFirstDay,
                    [classes.endHighlight]: isLastDay,
                });

                return (
                    <div className={classNames}>
                        {dayComponent}
                    </div>
                );
            };
        },
        [classes, startField],
    );

    return (
        <Box
            id={id}
            className={className}
            style={style}
        >
            {outerLabel && (
                <label
                    className={formHelperTextClasses.outerLabel}
                >
                    {outerLabel}
                </label>
            )}
            <Box
                display="flex"
                alignItems="baseline"
            >
                <DayPickerField
                    name={startDateName}
                    placeholder={startDatePlaceholder}
                    format={format}
                    withKeyboard
                />
                <Box m={1}>
                    to
                </Box>
                <DayPickerField
                    name={endDateName}
                    placeholder={endDatePlaceholder}
                    format={format}
                    minDate={startField.value || undefined}
                    renderDay={renderEndDay}
                    withKeyboard
                />
            </Box>
        </Box>
    );
}

export default DayRangePickerField;
