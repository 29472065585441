import { call, put, takeLatest } from 'typed-redux-saga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { updateAppSettings } from './actions';
import { configurationApi } from './api';
import { getClientFieldsConfiguration } from 'store/entities/clients/clientsAction';

function* updateAppSettingsSaga({ payload }: ReturnType<typeof updateAppSettings.init>) {
    const { clientId, data } = payload;
    const result = yield* call(configurationApi.updateAppSettings, clientId, data);
    yield put(updateAppSettings.success(result));
    yield put(getClientFieldsConfiguration.success(result));
}

function* updateAppSettingsSagaWatcher() {
    yield* takeLatest(
        updateAppSettings.initType,
        withBackendErrorHandler(
            updateAppSettingsSaga,
            updateAppSettings.error,
            'Unable to update application settings',
        ),
    );
}

export default [
    updateAppSettingsSagaWatcher,
];
