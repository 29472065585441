import { createBackgroundCheckTemplate, setCreateBackgroundCheckTemplateModalState, setEditBackgroundCheckTemplateId, updateBackgroundCheckTemplate } from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/action';
import { backgroundCheckTemplateApi } from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/api';
import { call, put, takeLatest } from 'typed-redux-saga';

import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { getBackgroundCheckTemplates, removeBackgroundCheckTemplate } from 'store/entities/configuration/configurationAction';

function* createBackgroundCheckSaga({ payload }: ReturnType<typeof createBackgroundCheckTemplate.init>) {
    const template = yield* call(backgroundCheckTemplateApi.createBackgroundCheckTemplate, payload);
    yield put(createBackgroundCheckTemplate.success());
    yield put(getBackgroundCheckTemplates.success([template]));
    yield put(setCreateBackgroundCheckTemplateModalState(false));
}

function* createBackgroundCheckWatcher() {
    yield* takeLatest(
        createBackgroundCheckTemplate.initType,
        withBackendErrorHandler(
            createBackgroundCheckSaga,
            createBackgroundCheckTemplate.error,
            'Unable to create Background Checks Template',
        ),
    );
}

function* updateBackgroundCheckTemplateSaga({ payload }: ReturnType<typeof updateBackgroundCheckTemplate.init>) {
    const { id, data } = payload;
    const template = yield* call(backgroundCheckTemplateApi.updateBackgroundCheckTemplate, id, data);
    yield put(updateBackgroundCheckTemplate.success());
    yield put(getBackgroundCheckTemplates.success([template]));
    yield put(setEditBackgroundCheckTemplateId(null));
}

function* updateBackgroundCheckWatcher() {
    yield* takeLatest(
        updateBackgroundCheckTemplate.initType,
        withBackendErrorHandler(
            updateBackgroundCheckTemplateSaga,
            updateBackgroundCheckTemplate.error,
            'Unable to update Background Checks Template',
        ),
    );
}

function* deleteBackgroundCheckTemplateSaga(
    { payload: id }: ReturnType<typeof removeBackgroundCheckTemplate.init>,
) {
    yield* call(backgroundCheckTemplateApi.deleteBackgroundCheckTemplate, id);
    yield put(removeBackgroundCheckTemplate.success(id));
}

function* deleteBackgroundCheckTemplateWatcher() {
    yield* takeLatest(
        removeBackgroundCheckTemplate.initType,
        withBackendErrorHandler(
            deleteBackgroundCheckTemplateSaga,
            removeBackgroundCheckTemplate.error,
            'Unable to delete Background Checks Template',
        ),
    );
}

export default [
    createBackgroundCheckWatcher,
    updateBackgroundCheckWatcher,
    deleteBackgroundCheckTemplateWatcher,
];
