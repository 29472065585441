import React, { RefObject } from 'react';
import clsx from 'clsx';
import { Collapse, Popper, ClickAwayListener, Box, List } from '@material-ui/core';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import { TMenuCategoryChild } from 'shared/models/Global';
import { VerticalMenuChildItem } from './VerticalMenuChildItem';

interface IVerticalMenuChildItemsProps {
    menuItems: TMenuCategoryChild[];
    isMinimized: boolean;
    isOpened: boolean;
    onClose: (event: any) => void;
    parentRef: RefObject<HTMLElement | null>;
    className?: string;
}

export const VerticalMenuChildItems = ({
    menuItems,
    isMinimized,
    isOpened,
    onClose,
    parentRef,
    className,
}: IVerticalMenuChildItemsProps) => {
    const classes = useMenuStyles();

    const childItems = (
        <Box className={clsx(
            className,
            classes.pickerContainer,
            { [classes.pickerContainerMinimized]: isMinimized },
        )}>
            <List disablePadding>
                {menuItems.map(child => {
                    const subChildren = (child.children || []).map(item => (
                        <VerticalMenuChildItem
                            className={classes.subItem}
                            menuItem={item}
                            key={item.to}
                        />
                    ));
                    return (
                        <React.Fragment key={child.to}>
                            <VerticalMenuChildItem menuItem={child} key={child.to}/>
                            {subChildren}
                        </React.Fragment>
                    );
                })}
            </List>
        </Box>
    );

    return (
        <>
            {isMinimized ? (
                <Popper
                    open={isOpened}
                    anchorEl={parentRef.current}
                    placement="bottom-start"
                    className={classes.popperMenuContainer}
                >
                    <ClickAwayListener
                        onClickAway={onClose}
                    >
                        {childItems}
                    </ClickAwayListener>
                </Popper>
            ) : (
                <Collapse
                    in={isOpened}
                    timeout="auto"
                    unmountOnExit
                    className={classes.collapseContainer}
                >
                    {childItems}
                </Collapse>
            )}
        </>
    );
};
