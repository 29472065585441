import React, { MouseEvent, useCallback, FC } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import { useApprovalTableStyles } from '../../TableApprovalStyles';
import { IconButton, fade } from '@material-ui/core';
import { useHover } from '../hover';
import { colors } from 'shared/styles/constants';
import clsx from 'clsx';

interface IApproveProps {
    onClick: () => void;
}

const Approve: FC<IApproveProps> = ({ onClick }: IApproveProps) => {
    const initialClasses = useApprovalTableStyles();
    const { onMouseEnter, onMouseLeave } = useHover();

    const onHover = useCallback((event: MouseEvent) => {
        const rowId = event.currentTarget.closest('[role="row"]')?.id;
        onMouseEnter(fade(colors.green, 0.4), rowId ?? '');
    }, [onMouseEnter]);

    const onHoverRemove = useCallback((event: MouseEvent) => {
        const rowId = event.currentTarget.closest('[role="row"]')?.id;
        onMouseLeave(rowId ?? '');
    }, [onMouseLeave]);
    
    return (
        <IconButton onMouseEnter={onHover}
            onMouseLeave={onHoverRemove}
            onClick={onClick}>
            <DoneIcon 
                className={clsx(initialClasses.actionIcon, initialClasses.approveIcon)}
                onMouseEnter={onHover}
                onMouseLeave={onHoverRemove}
            />
        </IconButton>
    );
};

export default Approve;
