/* eslint-disable */
import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { searchJobNumbersEmployees } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/actions';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { Formik } from 'formik';
import { some } from 'lodash-es';
import { IJobNumberUserSearch } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/model';
import { selectIsSearchingJobNumbersUsers } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';

export const UserSearch = () => {
    const classes = useSettingsFormStyles();
    const dispatch = useDispatch();
    const isSearching = useSelector(selectIsSearchingJobNumbersUsers);

    const initialValues: IJobNumberUserSearch = {
        firstName: '',
        lastName: '',
        username: '',
    };
    const searchUsers = useCallback((values: IJobNumberUserSearch) => {
        dispatch(searchJobNumbersEmployees.init(values));
    }, [dispatch]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={searchUsers}
        >
            {props => (
                <form onSubmit={props.handleSubmit} className={clsx(classes.form, classes.noPadding)}>
                    <Box>
                        <Box width="50%">
                            <FormField
                                name="username"
                                label="Username (SSO ID)"
                                className={classes.input}
                            />
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                    >
                        <Box width="50%">
                            <FormField
                                name="firstName"
                                label="First Name"
                                className={classes.input}
                            />
                        </Box>
                        <Box width="50%">
                            <FormField
                                name="lastName"
                                label="Last Name"
                                className={classes.input}
                            />
                        </Box>
                    </Box>
                    <Box m={2}>
                        <ButtonWithLoader
                            type="submit"
                            disabled={!some(Object.values(props.values))}
                            isLoading={isSearching}
                            variant="contained"
                            color="secondary"
                        >
                            Search
                        </ButtonWithLoader>
                    </Box>
                </form>
            )}
        </Formik>
    );
};
