import React, { FC } from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import useQuantityInfoStyles from './QuantityInfoStyles';
import clsx from 'clsx';

interface IListTypeProps {
    className?: string;
    quantity: string;
    type: string;
}

const QuantityInfo: FC<IListTypeProps> = ({ className, quantity, type }: IListTypeProps) => {
    const initialClasses = useQuantityInfoStyles();
    return (
        <div className={clsx(className, initialClasses.textRight)}>
            <List className={initialClasses.listWrapper}>
                <ListItem className={initialClasses.listItem}>
                    <Typography className={initialClasses.amountText} variant="body1">{quantity}</Typography>
                </ListItem>
                <ListItem className={initialClasses.listItem}>
                    <Typography className={initialClasses.labelText} variant="caption">{type}</Typography>
                </ListItem>
            </List>
        </div>
    );
};

export default QuantityInfo;
