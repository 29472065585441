import { selectCustomFieldValuesState } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';

const selectEditCustomFieldValueState = (state: IStore) =>
    selectCustomFieldValuesState(state).editCustomFieldValue;

export const selectIsCustomFieldValueUpdating = (state: IStore) => selectEditCustomFieldValueState(state).isUpdating;
export const selectEditCustomFieldValueId = (state: IStore) => selectEditCustomFieldValueState(state).editId;
export const selectEditCustomFieldValue = createSelector(
    selectCustomFieldValuesByIds,
    selectEditCustomFieldValueId,
    (fieldValuesByIds, editId) => {
        return fieldValuesByIds[editId || ''];
    },
);
