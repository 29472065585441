import { combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';
import { EmployeeTypesActions, getEmployeeTypes } from './actions';
import { IEmployeeType } from 'modules/employmentInfo/models/EmployeeType';

const initialState = {
    employeeTypes: [],
};

const employeeTypesIsLoading = isLoadingReducer(getEmployeeTypes);

const employeeTypes = (
    state: IEmployeeType[] = initialState.employeeTypes,
    action: EmployeeTypesActions,
): IEmployeeType[] => {
    switch (action.type) {
        case getEmployeeTypes.successType: {
            return action.payload;
        }
        default:
            return state;
    }
};

export const employeeType = combineReducers({
    employeeTypesIsLoading,
    employeeTypes,
});
