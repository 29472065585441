import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import ActionButton from 'shared/components/buttons/ActionButton';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { isOfferPayRateRejecting, isOfferStatusOperationInProgress } from 'modules/offerLetter/store/selectors';
import { useSheetRejectStyles } from 'shared/components/sidebars/SheetReject/styles';
import { OfferRejectionCard } from 'modules/offerLetter/components/RejectPayRangeOffer/OfferRejectionCard';
import { IApproveOfferButtonProps } from 'modules/offerLetter/components/ApproveOffer/ApproveOfferButton';
import { rejectOfferPayRange } from 'modules/offerLetter/store/actions';
import { useRejectNotes } from 'modules/payrollProcessorHub/components/SheetStatusAction/hooks/useRejectNotes';
import { useModal } from 'shared/utils/hooks/useModal';

export const RejectOfferButton = ({ offerLetterIds }: IApproveOfferButtonProps) => {
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const { notesById, onNoteChange } = useRejectNotes();

    const isLoading = useSelector(isOfferPayRateRejecting);
    const disabled = useSelector(isOfferStatusOperationInProgress);
    const sidebarClasses = useSidebarStyles();
    const classes = useSheetRejectStyles();
    const confirmButtonClasses = { root: classes.buttonSave, label: classes.buttonTextStyle };
    const cancelButtonClasses = { root: classes.buttonCancel, label: classes.buttonTextStyle };

    const dispatch = useDispatch();
    const onConfirm = useCallback(() => {
        dispatch(rejectOfferPayRange.init(offerLetterIds.map(offerId => {
            return {
                offerId,
                reason: notesById[offerId],
            };
        })));
        onModalClose();
    }, [dispatch, offerLetterIds, onModalClose, notesById]);

    const onChangeReason = useCallback((offerId: string) => (newValue: string) => {
        onNoteChange(offerId, newValue);
    }, [onNoteChange]);

    return (
        <>
            <ActionButton
                variant="outlined"
                color="primary"
                customType="danger"
                onClick={onModalOpen}
                isLoading={isLoading}
                disabled={disabled}
            >
                Reject
            </ActionButton>

            <Sidebar
                isOpened={isModalOpened}
                onClose={onModalClose}
                anchor="right"
                title="Rescind offer letter"
                titleClasses={{
                    root: sidebarClasses.whiteTitle,
                }}
            >
                <Box my={4} mx={7}>
                    <Box>
                        <Typography gutterBottom>
                            You are <b>rescinding</b> this offer letter.
                            Please enter the reason why you are revoking it below.
                        </Typography>
                    </Box>
                </Box>

                {offerLetterIds.map(offerId => (
                    <OfferRejectionCard
                        key={offerId}
                        offerLetterId={offerId}
                        inputValue={notesById[offerId]}
                        onChange={onChangeReason(offerId)}
                    />
                ))}

                <footer className={classes.footer}>
                    <ButtonWithLoader
                        onClick={onConfirm}
                        variant="contained"
                        color="primary"
                        classes={confirmButtonClasses}
                        isLoading={isLoading}
                    >
                        Confirm
                    </ButtonWithLoader>
                    {/*
                    // @ts-ignore */}
                    <Button
                        onClick={onModalClose}
                        variant="contained"
                        classes={cancelButtonClasses}
                        isLoading={isLoading}
                    >
                        Cancel
                    </Button>
                </footer>
            </Sidebar>
        </>
    );
};
