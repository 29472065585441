import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { FormWithSections } from 'modules/settings/common/components/FormWithSections/FormWithSections';
import NumberField from 'shared/components/formFields/NumberField';
import { ApprovalLevelsField } from './components/ApprovalLevelsField';
import { ITimeAndPayClientConfiguration } from 'store/entities/clients/clientsModel';
import { FormSwitchField } from 'modules/settings/submodules/clients/appConfiguration/components/FormSwitchField';

export interface IAppSettingsFormProps extends FormikProps<Partial<ITimeAndPayClientConfiguration>> {
    isLoading?: boolean;
}

export function AppSettingsForm({
    handleSubmit,
    isLoading,
}: IAppSettingsFormProps) {

    const sections = useMemo(() => ([
        {
            name: 'Approvals hierarchy',
            component: (
                <FormFieldWithSeparateLabel
                    name="approvalLevels"
                    outerLabel="Levels of approvers in sheets"
                    FieldComponent={ApprovalLevelsField}
                />
            ),
        },
        {
            name: 'Offer Letter',
            component: (
                <FormFieldWithSeparateLabel
                    name="enableOfferLetterEmployeeNumber"
                    outerLabel="Add External Employee ID"
                    FieldComponent={FormSwitchField}
                />
            ),
        },
        {
            name: 'SCA',
            component: (
                <FormFieldWithSeparateLabel
                    name="scaEnabled"
                    outerLabel="Enabled"
                    FieldComponent={FormSwitchField}
                />
            ),
        },
        {
            name: 'Time & Expenses',
            component: (
                <>
                    <FormFieldWithSeparateLabel
                        name="allowFutureDayEntry"
                        outerLabel="Allow Future Day Entry"
                        FieldComponent={FormSwitchField}
                    />
                    <FormFieldWithSeparateLabel
                        name="allowTimeEntryWithoutEndTime"
                        outerLabel="Allow Time Entry Without End Time"
                        FieldComponent={FormSwitchField}
                    />
                    <FormFieldWithSeparateLabel
                        name="timeTracking.lock_past_dates"
                        outerLabel="Disable past time entry"
                        childLabel={
                            'Toggle on to disable and lock time and expense entry after midnight. '
                            + 'Employees will not be able to enter in time/expense for the day after it has past '
                            + 'and cannot recall or edit or resubmit their timesheet. '
                            + 'Managers will need to make the edits on their end.'
                        }
                        FieldComponent={FormSwitchField}
                    />
                    <FormFieldWithSeparateLabel
                        name="allowTimeSheetGrouping"
                        outerLabel="Allow Timesheet Grouping"
                        childLabel={
                            'Toggle on to have one single timesheet for all assignments. '
                            + 'Toggle off to separate timesheets by assignment.'
                        }
                        FieldComponent={FormSwitchField}
                    />
                    <FormFieldWithSeparateLabel
                        name="expenseReceiptRequiredDollars"
                        outerLabel="Receipt is required for expenses that are over"
                        min={0}
                        InputProps={{
                            startAdornment: '$',
                        }}
                        FieldComponent={NumberField}
                    />
                    <FormFieldWithSeparateLabel
                        name="mileageRate"
                        outerLabel="Mileage Rate"
                        min={0}
                        InputProps={{
                            startAdornment: '$',
                        }}
                        FieldComponent={NumberField}
                    />
                </>
            ),
        },
    ]), []);

    return (
        <FormWithSections
            sections={sections}
            submitTitle="Update"
            onSubmit={handleSubmit}
            isLoading={isLoading}
            isDisabled={isLoading}
        />
    );
}
