import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISettingsTableRow } from 'modules/settings/common/components/SettingsTablePure/SettingsTablePure';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { PaginationCounter } from 'modules/settings/submodules/components/PaginationCounter/PaginationCounter';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useIsMobile } from 'shared/utils/hooks/media';
import { IStore } from 'store/configureStore';
import { ActionCreatorKnownArgs } from 'store/utils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';

export interface ISettingsInfinityTablePureProps<T extends ISettingsTableRow> {
    infinityTableSelector: ReturnType<typeof getInfinityScrollSelectors>,
    rowsSelector: (state: IStore) => T[];
    initialLoadAction: ActionCreatorKnownArgs<void, any>;
    getMoreAction: ActionCreatorKnownArgs<void, any>;
    cells: ICellInfo<T>[];
}

export function SettingsInfinityTablePure<T extends ISettingsTableRow>({
    infinityTableSelector,
    rowsSelector,
    initialLoadAction,
    getMoreAction,
    cells,
}: ISettingsInfinityTablePureProps<T>) {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const tableClasses = useDefaultTableStyles();

    const rows = useSelector(rowsSelector);
    const hasMoreRows = useSelector(infinityTableSelector.selectHasMore);
    const total = useSelector(infinityTableSelector.selectTotalItems);
    const isLoading = useSelector(infinityTableSelector.isLoading);

    useWithClientEffect(() => {
        dispatch(initialLoadAction());
    }, [dispatch, initialLoadAction]);
    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows){
            dispatch(getMoreAction());
        }
    }, [dispatch, getMoreAction, hasMoreRows]);

    return (
        <>
            <InfinityGridTable
                rowData={rows}
                getKey={row => row.id}
                cells={cells}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                hideHeader={isMobile}
                stickyHeader
                isLoading={isLoading}
                onLoadMore={onLoadMoreRows}
                hasMore={hasMoreRows}
            />
            <PaginationCounter
                shown={rows.length}
                total={total}
            />
        </>
    );
}
