import { EAF_APPROVAL_TYPES } from 'modules/eafApproval/store/model/data';
import { selectEafById } from 'modules/eafApproval/store/selectors';
import { EafType, IEmployeeActionForm } from 'modules/employeeActionsForm/store/models';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export const getPermissionForApprovalByEafType = (type?: EafType): Permission => {
    switch (type) {
        case EafType.Termination:
            return Permission.ManageTerminationRequests;
        default:
            return Permission.ApprovePayRate;
    }
};

export const useIsUserHasApprovePermission = (eaf?: IEmployeeActionForm): boolean => {
    const permission = getPermissionForApprovalByEafType(eaf?.employee_action_form_data?.employee_action_form_type);
    return useSelector(selectIsUserHasPermission(permission));
};

export const useIsUserHasApprovePermissionByEafId = (eafId: string): boolean => {
    const eaf = useSelector(selectEafById(eafId));
    return useIsUserHasApprovePermission(eaf);
};

export const useEafApprovalTypes = () => {
    const types = [...EAF_APPROVAL_TYPES];
    types.push(EafType.Termination);
    return types;
};
