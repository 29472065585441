import { AxiosRequestConfig } from 'axios';
import { IAttachmentBackend, IAttachmentCreateMetaInfo } from 'shared/components/formFields/AttachmentInput/model';
import baseApi from 'shared/utils/baseApi';
import { baseUrl } from 'shared/utils/baseUrl';

export const attachmentApi = {
    async uploadAttachment(
        file: File,
        payload: IAttachmentCreateMetaInfo,
    ): Promise<IAttachmentBackend> {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('related_entity_type', payload.related_entity_type);
        if (payload.related_entity_id) {
            formData.append('related_entity_id', payload.related_entity_id);
        }
        const { data } = await baseApi.post<FormData, IAttachmentBackend>(
            `documents/attachments/upload`,
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            },
        );
        return data;
    },
    getAttachmentUrl(attachmentId: string): string {
        return `${baseUrl}/documents/attachment/${attachmentId}/download`;
    },
    async downloadAttachment(attachmentId: string): Promise<BlobPart>{
        const config: AxiosRequestConfig = { responseType: 'blob' };
        const { data } = await baseApi.get<BlobPart>(
            `${baseUrl}/documents/attachment/${attachmentId}/download`, {}, config,
        );
        return data;
    },
};
