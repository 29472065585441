import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useEditOfferLetterModalFormStyles = makeHighPriorityStyles((theme: Theme) => ({
    input: {
        width: '100%',
    },
    formWrapper: {
        boxSizing: 'border-box',
        minHeight: theme.spacing(30),
        width: theme.spacing(100),
        maxWidth: '100%',
        padding: theme.spacing(4),
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0, 4, 4),
    },
}));
