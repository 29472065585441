import React from 'react';
import { Button } from '@material-ui/core';
import { useModal } from 'shared/utils/hooks/useModal';
import CreateCostCenterModal from 'modules/settings/submodules/clients/costCenters/components/CreateCostCenter/CreateCostCenterModal';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const CreateCostCenterButton = () => {
    const classes = useColoredFormModalStyles();
    const { isModalOpened, onModalOpen, onModalClose } = useModal();

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={onModalOpen}>
                + Add new
            </Button>
            <CreateCostCenterModal
                title="Create New Cost Center"
                isOpened={isModalOpened}
                onClose={onModalClose}
                customClasses={classes}
                showCloseIcon
            />
        </>
    );
};
