import { IStore } from 'store/configureStore';
import { selectDealsState } from 'modules/settings/submodules/clients/deals/store/selectors';
import { createSelector } from 'reselect';
import { selectDealByNumber, selectDeals, selectJobNumbers } from 'store/entities/configuration/configurationSelectors';
import { IJobNumber } from 'shared/models/JobNumber';

const selectDealFormState = (state: IStore) => {
    const dealsState = selectDealsState(state);
    return dealsState.dealForm;
};

export const selectRedirectToJobNumberWithDealNumber = (state: IStore) => {
    const dealFormState = selectDealFormState(state);
    return dealFormState.redirectToJobNumberWithDealNumber;
};

export const selectRedirectToJobNumberDeal = (
    createSelector(
        selectRedirectToJobNumberWithDealNumber,
        selectDeals,
        (redirectToJobNumberWithDealNumber, deals) => {
            return deals.find(deal => deal.deal_number === redirectToJobNumberWithDealNumber);
        },
    )
);

export const selectJobNumbersByDealId = createSelector(
    selectDeals,
    selectJobNumbers,
    (deals, jobNumbers) => {
        return deals.reduce((acc, deal) => {
            const dealJobNumbers = jobNumbers.filter(jobNumber => jobNumber.deal_id === deal.id);
            return {
                ...acc,
                [deal.id]: dealJobNumbers,
            };
        }, {}) as Record<string, IJobNumber[]>;
    },
);

export const selectDealJobNumbers = (dealNumber?: string) => (state: IStore) => {
    const jobNumbersByDealId = selectJobNumbersByDealId(state);
    const deal = selectDealByNumber(dealNumber)(state);
    return jobNumbersByDealId[deal?.id || ''] || [];
};
