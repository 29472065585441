import { IPayCode } from 'modules/settings/submodules/clients/payCodes/store/model';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { ActivityTag, ITask } from 'store/entities/configuration/configurationModel';

export interface IActivityFormValues {
    type: EntryType | null;
    dataType: QuantityType | null;
    payCode: IPayCode | null;
    task: ITask | null;
    activity: string;
    shortDescription: string;
    showZipCode: ActivityTag.ShowZipCode | null;
    nonProduction: ActivityTag.NonProduction | null;
    default: ActivityTag.Default | null;
    travel: ActivityTag.Travel | null;
}

export const defaultActivityValues: IActivityFormValues = {
    type: null,
    dataType: null,
    payCode: null,
    task: null,
    activity: '',
    shortDescription: '',
    showZipCode: null,
    nonProduction: null,
    default: null,
    travel: null,
};
