import { IEntity } from 'shared/models/Entity';
import { IStore } from 'store/configureStore';
import { IActionsCreatorCommon } from 'store/utils';
import { select, put, take } from 'typed-redux-saga';
import { difference } from 'lodash-es';

export const defaultTransformLoadActionParams = (ids: string[]) => ({ ids });

/**
 * Saga for optional loading entities. This saga useful to prevent loading already loaded data
 *
 * @param requestedIds - requested entity ids
 * @param entitiesByIdsSelector
 * @param entityLoadAction - action for load entities
 * @param transformLoadActionParams - optional payload transformation for entityLoadAction.init action
 */
export function* optionalLoadEntitiesByIdsSaga(
    requestedIds: string[],
    entitiesByIdsSelector: (state: IStore) => Record<string, IEntity>,
    entityLoadAction: IActionsCreatorCommon<any, any, any, any, any, any>,
    transformLoadActionParams: (ids: string[]) => Object = defaultTransformLoadActionParams,
) {
    const entitiesByIds = yield select(entitiesByIdsSelector);
    const idsToLoad = difference(requestedIds.filter(Boolean), Object.keys(entitiesByIds));
    if (idsToLoad.length) {
        const payload = transformLoadActionParams(idsToLoad);
        yield put(entityLoadAction.init(payload));
        yield take(entityLoadAction.finallyTypes);
    }
}
