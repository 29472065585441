import { selectClientAvailableCustomFields } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { IDashboardMenuItem } from 'modules/settings/submodules/components/DashboardLink/model';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { useIsPayRangesAppliedForClient } from 'store/entities/clients/hooks';
import { ScopeTypeDisplayNames } from 'store/entities/scopes/models';
import { settingsRoutes } from '../../../routes';

export const useClientAssignmentSetupSubModules = (): IDashboardMenuItem[] => {
    const clientId = useSelector(selectCurrentClientId);
    const customFields = useSelector(selectClientAvailableCustomFields);
    const isPayRangesApplied = useIsPayRangesAppliedForClient();

    return clientId ? [
        {
            title: 'Hierarchy',
            description: 'Manage custom fields hierarchy',
            permission: Permission.ManageCustomFields,
            link: generatePath(settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.HIERARCHY, { clientId }),
        },
        {
            title: 'Custom Fields Values',
            description: 'Manage and add custom fields values',
            permission: Permission.ManageCustomFields,
            link: generatePath(settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.CUSTOM_FIELD_VALUES.ROOT, { clientId }),
            children: customFields.length ? customFields.map(field => ({
                title: field.name,
                link: generatePath(
                    settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.CUSTOM_FIELD_VALUES.FIELD,
                    { clientId, customFieldId: field.id },
                ),
            })) : undefined,
        },
        {
            title: 'Scopes',
            description: 'Manage scopes.',
            link: generatePath(settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.SCOPES.ROOT, { clientId }),
            permission: Permission.modifyClientSettings,
            children: Object.entries(ScopeTypeDisplayNames).map(([type, displayName]) => {
                return {
                    title: displayName,
                    link: generatePath(
                        settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.SCOPES.TYPE,
                        { clientId, type },
                    ),
                };
            }),
        },
        ...isPayRangesApplied ? [{
            title: 'Pay Ranges',
            description: 'Pay ranges.',
            link: generatePath(settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.PAY_RANGES, { clientId }),
        }] : [],
        ...[{
            title: 'EAF Settings',
            description: 'Employee action form settings.',
            link: generatePath(settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.EAF_SETTINGS, { clientId }),
        }],
    ] : [];
};
