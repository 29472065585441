import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { GetRowId, ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { rowPropsComparator } from 'shared/components/table/GridTable/GridTableRow';
import { useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';
import { CellWrapper } from 'shared/components/table/VirtualizedGridTable/CellWrapper';

export interface IGridTableRowProps<RowData> {
    row: RowData;
    getRowId?: GetRowId<RowData>;
    cells: Array<ICellInfo<RowData>>;
    classes: ReturnType<typeof useGridTableStyles>;
    bodyCellClassName?: string;
    index: number;
    getRowClasses?: (row: RowData) => ClassValue[];
    customRowClassName?: string;
    style?: any;
}

function GridTableRow<RowData>({
    row,
    getRowId,
    cells,
    classes,
    getRowClasses,
    bodyCellClassName = '',
    customRowClassName,
    style,
}: IGridTableRowProps<RowData>) {
    const tableClasses = useDefaultTableStyles();
    const rowCells = cells.map(({ key, render: Component, cellClassName }) => (
        <CellWrapper
            className={clsx(classes.bodyCell, bodyCellClassName, cellClassName)}
            key={key}
        >
            <Component
                className={clsx(tableClasses.bodyCellDefaultClass)}
                {...row}
            />
        </CellWrapper>
    ));

    return (
        <div
            style={style}
            className={clsx(
                customRowClassName,
                classes.rowContainer,
                ...(getRowClasses ? getRowClasses(row) : []),
            )}
            role="row"
            id={getRowId ? getRowId(row) : undefined}
            data-testid={getRowId ? getRowId(row) : undefined}
        >
            {rowCells}
        </div>
    );
}

export const VirtualizedGridTableRowPure = React.memo(GridTableRow, rowPropsComparator) as typeof GridTableRow;
