import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';

export const selectOfferLetterTemplateState = (state: IStore) => {
    return state.modules.settings.offerLetterTemplate;
};

export const selectIsCreateOfferLetterTemplateModalOpened = (state: IStore) =>
    selectOfferLetterTemplateState(state).isOpenCreateOfferLetterTemplateModal;
export const selectIsOfferLetterTemplateCreating = (state: IStore) =>
    selectOfferLetterTemplateState(state).isOfferLetterTemplateCreating;
export const selectIsOfferLetterTemplateUpdating = (state: IStore) =>
    selectOfferLetterTemplateState(state).isOfferLetterTemplateUpdating;
export const selectIsOfferLetterTemplateUploading = (state: IStore) =>
    selectOfferLetterTemplateState(state).isOfferLetterTemplateUploading;
export const selectOfferLetterTemplateUploaded = (state: IStore) =>
    selectOfferLetterTemplateState(state).offerLetterTemplateUploadedFile;
export const selectOfferLetterTemplateParams = (state: IStore) =>
    selectOfferLetterTemplateState(state).offerLetterTemplateParams;
export const selectOfferLetterTemplateParamsLoading = (state: IStore) =>
    selectOfferLetterTemplateState(state).isOfferLetterTemplateParamsLoading;

export const selectOfferLetterTemplateIdForEdit = createSelector(
    selectOfferLetterTemplateState,
    offerLetterModuleState => offerLetterModuleState.offerLetterTemplateUpdateId,
);

export const selectUpdatingOfferLetterTemplateIds = createSelector(
    selectOfferLetterTemplateState,
    offerLetterModuleState => offerLetterModuleState.updatingOfferLetterTemplateIds,
);

export const selectDeletingOfferLetterTemplateIds = createSelector(
    selectOfferLetterTemplateState,
    offerLetterModuleState => offerLetterModuleState.deletingOfferLetterTemplateIds,
);
