import { createSelector } from 'reselect';
import { IOfferLetter, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import {
    selectEmployeeProfileStatusCheckingByClientId,
    selectMyEmployeeProfilesByClientIds,
    selectOfferLetterPdfIsAvailable,
    selectOfferLetterPdfIsLoading,
    selectOfferLetters,
} from 'modules/offerLetter/store/selectors';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { IOfferLetterRowWithClient } from 'modules/offerLetter/components/OfferLetterTable/cells/employeeCells';

export const selectEmployeeOfferLetterRows = createSelector(
    selectOfferLetters,
    selectOfferLetterPdfIsLoading,
    selectOfferLetterPdfIsAvailable,
    selectAllClientsById,
    selectMyEmployeeProfilesByClientIds,
    selectEmployeeProfileStatusCheckingByClientId,
    selectCustomFieldValuesByIds,
    (
        offerLettersByIds,
        isPdfCheckingById,
        isPdfAvailableById,
        clientsById,
        employeeProfilesByClientIds,
        employeeProfileStatusCheckingByClientId,
        customFieldValuesByIds,
    ): IOfferLetterRowWithClient[] => {
        // @ts-ignore
        return (Object.values(offerLettersByIds) as IOfferLetter[])
            .map((offerLetter: IOfferLetter) => ({
                offerLetter: {
                    ...offerLetter,
                    isPdfLoading: isPdfCheckingById[offerLetter.id],
                    isPdfAvailable: isPdfAvailableById[offerLetter.id],
                    isStatusChecking: employeeProfileStatusCheckingByClientId[offerLetter.client_id] || false,
                    userCanContinueOnboarding: (
                        [
                            OfferLetterStatusSlug.Outstanding,
                            OfferLetterStatusSlug.PendingManagerApproval,
                        ].includes(offerLetter?.status?.slug)
                        && !employeeProfilesByClientIds[offerLetter.client_id]?.prism_onboarding_completed
                    ),
                },
                isPdfLoading: isPdfCheckingById[offerLetter.id],
                isPdfAvailable: isPdfAvailableById[offerLetter.id],
                client: clientsById[offerLetter.client_id],
                positionDisplayValue: offerLetter.position?.name
                    || customFieldValuesByIds[offerLetter.position_value_id]?.data?.name,
                locationDisplayValue: offerLetter.location?.name
                    || customFieldValuesByIds[offerLetter.location_value_id]?.data?.name,
                departmentDisplayValue: offerLetter.department?.name
                    || customFieldValuesByIds[offerLetter.department_value_id]?.data?.name,
            }));
    },
);
