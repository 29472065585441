import { Nullable } from 'types/types';
import { isEmpty, fromPairs } from 'lodash-es';
import { SheetGroup } from 'modules/timeAndExpense/store/model';
import {
    getApproversBySheetId,
    getSheetRows,
    groupedSheetRowsSelector,
    groupedSheetsSelector,
    selectTimeAndExpenseActiveStatus,
} from 'modules/timeAndExpense/store/selectors';
import { createSelector } from 'reselect';
import { ItemsById } from 'shared/models/ItemsById';
import { IJobNumber } from 'shared/models/JobNumber';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IManagerSubmittedSheetsFilter } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsModel';
import { selectManagerSubmittedSheetsFilters } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';
import { IStore } from 'store/configureStore';
import { ApproversFromFields, ITimeAndPayClientConfiguration } from 'store/entities/clients/clientsModel';
import { selectClientApproverSheetGrouping } from 'store/entities/clients/selectors/configurationSelectors';
import { selectClientTimeAndPayConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import {
    selectAssignmentsById,
    selectJobNumbersById,
    selectSubassignmentsByIds,
} from 'store/entities/configuration/configurationSelectors';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import {
    selectAllEntries,
    selectCalculationsByTimesheetId,
    selectExpensesSheets,
    selectTimeSheets,
} from 'store/entities/timesheet/selectors';
import { selectUsersById } from 'store/entities/users/selectors';

export const selectCurrentUserApprovalsLevelBySubassignmentId = createSelector(
    selectSubassignmentsByIds,
    selectCurrentUser,
    (subassignmentsById, currentUser): Record<string, number> => {
        return fromPairs(
            (Object.values(subassignmentsById) as ISubassignment[]).map((subassignment: ISubassignment) => [
                subassignment.id,
                subassignment?.managers?.find(item => item.user_id === currentUser?.id)?.manager_level || 1,
            ]),
        );
    },
);

export const selectSheetApprovalState = (state: IStore) => state.modules.sheetApproval;

const sheetSelector = (
    sheets: ISheet[],
    activeStatus: StatusNames,
    currentUser: IUserInfo,
    approvalLevelsBySubAssignments: Record<string, number>,
    timeAndPayConfiguration: ITimeAndPayClientConfiguration,
    jobNumbersById: ItemsById<IJobNumber>,
): ISheet[] => {
    const getManagerId = (jobNumberId: Nullable<string>) => {
        return jobNumbersById[jobNumberId || '']?.manager_id;
    };

    const getApprovalLevel = (subAssignmentId: Nullable<string>): number => {
        return approvalLevelsBySubAssignments[subAssignmentId || ''] || 0;
    };

    const byManagerId = (managerId: Nullable<string>) =>
        (sheet: ISheet) => getManagerId(sheet.job_number_id) === managerId;
    const currentUserIsManager = byManagerId(currentUser?.id);

    const byStatusName = (statusName: StatusNames) => (sheet: ISheet) => sheet.status.name === statusName;
    const byActiveStatusName = byStatusName(activeStatus);
    const byLowerApprovalLevel = (sheet: ISheet) => sheet.approved_level < getApprovalLevel(sheet.subassignment_id);
    const byHigherApprovalLevel = (sheet: ISheet) => sheet.approved_level >= getApprovalLevel(sheet.subassignment_id);
    const approverFrom = timeAndPayConfiguration?.approversFrom || ApproversFromFields.FromAssignment;

    switch (activeStatus) {
        case StatusNames.ALL:
            return sheets.filter(sheet => sheet.status.name !== StatusNames.WORKING);

        case StatusNames.SUBMITTED: {
            if (approverFrom === ApproversFromFields.FromJobNumber) {
                return sheets.filter(sheet => byActiveStatusName(sheet) && currentUserIsManager(sheet));
            }
            return sheets.filter(sheet => byActiveStatusName(sheet) && byLowerApprovalLevel(sheet));
        }

        case StatusNames.APPROVED: {
            if (approverFrom === ApproversFromFields.FromJobNumber) {
                return sheets.filter(sheet => byActiveStatusName(sheet) && currentUserIsManager(sheet));
            }
            return sheets.filter(sheet => byActiveStatusName(sheet)
                || (byStatusName(StatusNames.SUBMITTED)(sheet) && byHigherApprovalLevel(sheet)));
        }

        default:
            return sheets.filter(byActiveStatusName);
    }
};

const filteredSheetSelector = (
    sheets: ISheet[],
    filters: IManagerSubmittedSheetsFilter,
    activeStatus: StatusNames,
    assignmentsById: ItemsById<IAssignment>,
) => {
    if (isEmpty(filters)) {
        return sheets;
    }
    return sheets.filter(sheet => {
        const assignment = assignmentsById[sheet.assignment_id ?? ''];

        if (assignment) {
            if (filters.location_id && filters.location_id !== assignment.location_id) {
                return false;
            }

            if (filters.position_id && filters.position_id !== assignment.position_id) {
                return false;
            }
        }

        if (filters.user_id && sheet.user_id !== filters.user_id) {
            return false;
        }

        if (filters.job_number_id && sheet.job_number_id !== filters.job_number_id) {
            return false;
        }

        if (filters.pay_period && sheet.period_end !== filters.pay_period.period_end) {
            return false;
        }

        return true;
    });
};

const getApprovalGroupedSheetsSelectors = (entry_type: EntryType) => {
    const baseAllSheetsSelector: (state: IStore) => ISheet[] = (
        entry_type === EntryType.TIME
            ? selectTimeSheets
            : selectExpensesSheets
    );
    const isLoadingSelector = entry_type === EntryType.TIME
        ? (state: IStore) => state.components.timeApproval.isLoading
        : (state: IStore) => state.components.expensesApproval.isLoading;

    const otherUsersSheetsSelector = createSelector(
        baseAllSheetsSelector,
        selectCurrentUser,
        (sheets, user) => user ? sheets.filter(sheet => sheet.user_id !== user.id) : [],
    );

    const selectSheets = createSelector(
        otherUsersSheetsSelector,
        selectTimeAndExpenseActiveStatus,
        selectCurrentUser,
        selectCurrentUserApprovalsLevelBySubassignmentId,
        selectClientTimeAndPayConfiguration,
        selectJobNumbersById,
        sheetSelector,
    );
    const selectFilteredSheets = createSelector(
        selectSheets,
        selectManagerSubmittedSheetsFilters,
        selectTimeAndExpenseActiveStatus,
        selectAssignmentsById,
        filteredSheetSelector,
    );
    const selectSheetRows = createSelector(
        selectFilteredSheets,
        selectSubassignmentsByIds,
        selectJobNumbersById,
        selectUsersById,
        selectCalculationsByTimesheetId,
        selectClientTimeAndPayConfiguration,
        getSheetRows,
    );
    const selectApproversBySheetId = createSelector(
        selectFilteredSheets,
        selectAssignmentsById,
        selectSubassignmentsByIds,
        selectJobNumbersById,
        selectClientTimeAndPayConfiguration,
        getApproversBySheetId,
    );
    const selectGroupedSheets = createSelector(
        selectFilteredSheets,
        selectClientApproverSheetGrouping,
        selectUsersById,
        selectJobNumbersById,
        selectCalculationsByTimesheetId,
        selectApproversBySheetId,
        selectAllEntries,
        selectCustomFieldValuesByIds,
        groupedSheetsSelector,
    );
    const selectSheetGroupById = (id: string) => (state: IStore): SheetGroup | null =>
        // @ts-ignore
        (selectGroupedSheets(state) || []).find(group => group.id === id);

    const selectGroupedSheetRows = createSelector(
        selectGroupedSheets,
        groupedSheetRowsSelector,
    );

    return {
        selectSheets,
        selectFilteredSheets,
        selectSheetRows,
        selectGroupedSheets,
        selectSheetGroupById,
        selectGroupedSheetRows,
        isLoading: isLoadingSelector,
    };
};

export const timeApprovalSelectors = getApprovalGroupedSheetsSelectors(EntryType.TIME);
export const expenseApprovalSelectors = getApprovalGroupedSheetsSelectors(EntryType.EXPENSE);

export const selectGroupedCheckedSheetsItems = (state: IStore) => selectSheetApprovalState(state).checkedSheetGroups;
export const selectSheetGroupDetailId = (state: IStore) => selectSheetApprovalState(state).detailSheetGroupId;
export const selectSheetGroupSidebarId = (state: IStore) => selectSheetApprovalState(state).sidebarSheetGroupId;
export const selectApproveSheetGroupId = (state: IStore) => selectSheetApprovalState(state).approveSheetGroupId;
export const selectRejectSheetGroupId = (state: IStore) => selectSheetApprovalState(state).rejectSheetGroupId;
export const selectEditSheetGroup = (state: IStore) => selectSheetApprovalState(state).editSheetGroup;
