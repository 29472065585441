import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { fade, Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useFiltersChipsStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        maxWidth: '100%',
        backgroundColor: colors.orange,
        color: colors.white,
        borderRadius: theme.spacing(0.5),
        height: theme.spacing(3),
        fontWeight: 500,
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
    deleteIcon: {
        color: colors.white,

        '&:hover': {
            color: fade(colors.white, 0.8),
        },
    },
}));
