import React from 'react';
import { Formik } from 'formik';
import { ICostCenter } from 'shared/models/CostCenters';
import { CostCenterForm } from 'modules/settings/submodules/clients/costCenters/components/CostCenterForm/CostCenterForm';
import { defaultCostCenterFormValues, ICostCenterFormValues } from 'modules/settings/submodules/clients/costCenters/components/CostCenterForm/model';
import { costCenterValidationSchema } from 'modules/settings/submodules/clients/costCenters/components/CostCenterForm/ValidationsSchema';

export interface ICostCenterFormWrapperProps {
    onSubmit: (values: ICostCenterFormValues) => void;
    onCancel?: () => void;
    submitTitle: string;
    isLoading: boolean;
    costCenter?: ICostCenter;
}

const CostCenterFormWrapper = ({
    onSubmit,
    onCancel,
    submitTitle,
    isLoading,
    costCenter,
}: ICostCenterFormWrapperProps) => {

    const initialFormValues = {
        number: costCenter?.number || defaultCostCenterFormValues.number,
        description: costCenter?.description || defaultCostCenterFormValues.description,
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={costCenterValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <CostCenterForm
                    {...props}
                    isLoading={isLoading}
                    onCancel={onCancel}
                    submitTitle={submitTitle}
                />
            )}
        </Formik>
    );
};

export default CostCenterFormWrapper;
