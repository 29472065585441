import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import { setEafDetailId } from 'modules/eafApproval/store/actions';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';

export const EafApprovalGridCellDetails: React.FC<EafApprovalCellProps> = ({
    className,
    id,
}: EafApprovalCellProps) => {
    const classes = useDefaultTableStyles();
    const dispatch = useDispatch();
    const onClick = () => dispatch(setEafDetailId(id));
    return (
        <div className={clsx(className, classes.iconCell, classes.detailsIcon)}>
            <IconButton
                onClick={onClick}
                data-testid="details-button"
            >
                <ArrowForwardIosIcon/>
            </IconButton>
        </div>
    );
};
