import { IStore } from 'store/configureStore';

import {
    selectTravelExpensesById,
    selectTravelExpensesState,
    selectTravelExpenseSheetsById,
} from 'modules/travelExpenses/selectors';
import { createSelector } from 'reselect';
import { ITravelExpenseSidebarFormValues } from 'modules/travelExpenses/components/SidebarForm/TravelExpenseSidebarForm';

const selectEditTravelExpenseState = (state: IStore) => {
    const travelExpenseState = selectTravelExpensesState(state);
    return travelExpenseState.editTravelExpense;
};
export const selectEditTravelExpenseId = (state: IStore) => {
    return selectEditTravelExpenseState(state).travelExpenseId;
};
const selectEditTravelExpense = createSelector(
    selectEditTravelExpenseId,
    selectTravelExpensesById,
    (editTravelExpenseId, travelExpensesById ) => {
        return travelExpensesById[editTravelExpenseId || ''];
    },
);
export const selectEditTravelExpenseFormInitialValues = createSelector(
    selectEditTravelExpense,
    selectTravelExpenseSheetsById,
    (editTravelExpense, travelExpenseSheetsById) => {
        const sheet = travelExpenseSheetsById[editTravelExpense?.sheet_id || ''];
        return {
            employeeId: sheet?.user_id,
            weekEnding: sheet?.period_end,
            jobNumberId: editTravelExpense?.job_number_id,
            expenseTypeId: editTravelExpense?.activity_id,
            data: editTravelExpense?.data,
            travelDate: editTravelExpense?.entry_date,
            notes: editTravelExpense?.notes,
        } as ITravelExpenseSidebarFormValues;
    },
);
export const selectIsEditTravelExpenseSidebarOpen = (state: IStore) => {
    const editTravelExpenseId = selectEditTravelExpenseId(state);
    return Boolean(editTravelExpenseId);
};
