import { IActionsCreatorCommon } from 'store/utils';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeEvery, takeLatest } from 'typed-redux-saga';

export function getLoadEntitiesByRequestSagaWatcher<EntityType, ActionRequestPayload>(
    action: IActionsCreatorCommon<any, any, any, any, any, any>,
    api: (request: ActionRequestPayload) => Promise<EntityType[] | EntityType>,
    entityName: string,
    every = false,
) {
    function* getEntitiesSaga({ payload }: ReturnType<typeof action.init>) {
        const items = yield* call(api, payload || {});
        yield put(action.success(items));
    }

    if (every) {
        return function* getEveryEntitiesWatcher() {
            yield takeEvery(
                action.initType,
                withBackendErrorHandler(
                    getEntitiesSaga,
                    action.error,
                    `Unable to fetch ${entityName}`,
                ),
            );
        };
    }

    return function* getEntitiesWatcher() {
        yield takeLatest(
            action.initType,
            withBackendErrorHandler(
                getEntitiesSaga,
                action.error,
                `Unable to fetch ${entityName}`,
            ),
        );
    };
}
