import { selectJobNumbersState } from 'modules/settings/submodules/clients/jobNumber/store/selectors';
import { IStore } from 'store/configureStore';

const selectAssignUserJobNumberState = (state: IStore) => selectJobNumbersState(state).jobNumberAssignUser;

export const selectIsAssignUserUpdating = (state: IStore) => selectAssignUserJobNumberState(state).isUpdating;
export const selectAssignUserJobNumberId = (state: IStore): string | null =>
    selectAssignUserJobNumberState(state).assignToJobNumberId;
export const selectJobNumberUserSearchResult = (state: IStore) =>
    selectAssignUserJobNumberState(state).userSearchResult;
export const selectIsSearchingJobNumbersUsers = (state: IStore) => selectAssignUserJobNumberState(state).isSearching;
