import { Nullable } from 'types/types';
import { createSelector } from 'reselect';
import { IUserInfo } from 'shared/models/User';
import { IStore } from 'store/configureStore';
import { IUserLookupRequest } from 'store/entities/users/model';
import { selectUsersById } from 'store/entities/users/selectors';
import { IUsersById } from 'store/entities/users/reducers';
import { LookupEmployeeState } from './reducers';

const selectLookup = (state: IStore): LookupEmployeeState => state.modules.offerLetter.lookup;
// @ts-ignore
export const selectLookupIsLoading = (state: IStore): boolean => selectLookup(state).isLoading;
export const selectIsLookupSidebarOpen = (state: IStore): boolean => selectLookup(state).isLookupSidebarOpen;
export const selectLookupEmployeeHash = (state: IStore): string | null => selectLookup(state).employeeHash;
const selectLookupEmployeeIds = (state: IStore): string[] | null => selectLookup(state).employeeIds;
export const selectLookupEmployee = createSelector(
    selectLookupEmployeeIds,
    selectUsersById,
    (ids: string[] | null, usersById: IUsersById): Nullable<IUserInfo[]> => {
        if (ids) {
            return ids.map(id => usersById[id]).filter(user => user !== null);
        }
        return ids;
    },
);
// @ts-ignore
export const selectLookupData = (state: IStore): IUserLookupRequest => selectLookup(state).lookupData;
