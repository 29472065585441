import ReactGA from 'react-ga4';

const analyticsKey: string = process.env.REACT_APP_GA_TRACKER as string;

class Analytics {
    initialized = false;

    listen() {
        if (!this.initialized) {
            if (analyticsKey) {
                ReactGA.initialize(analyticsKey);
                this.initialized = true;
            }
        }
    }
}

export default new Analytics();
