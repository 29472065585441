import { setEditSheetGroup } from 'modules/sheetApproval/store/actions';
import {
    useIsUserHasSheetEditApprovePermission,
} from 'modules/sheetApproval/store/hooks';
import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useFilterAndActionControlsStyles
    from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import clsx from 'clsx';

interface IRejectButtonProps {
    sheetType: EntryType;
    sheets: Array<ISheet>;
    onAction?: () => void;
    groupId?: string;
}

export const EditButton = ({ sheets, sheetType, groupId, onAction }: IRejectButtonProps) => {
    const classes = useFilterAndActionControlsStyles();

    const dispatch = useDispatch();
    const handleClick = useCallback(() => {
        dispatch(setEditSheetGroup({
            groupId,
            entryType: sheetType,
            sheetIds: sheets.map(sheet => sheet.id),
        }));
        onAction && onAction();
    }, [dispatch, onAction, sheets, sheetType, groupId]);

    const hasPermission = useIsUserHasSheetEditApprovePermission();
    if (!hasPermission) {
        return null;
    }

    return (
        <Button
            variant="contained"
            color="secondary"
            size="small"
            classes={{ root: clsx(classes.actionButtonBase) }}
            onClick={handleClick}>
            Edit
        </Button>
    );
};
