import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useSubmittedTableStyles } from '../../sheetsSubmitted/sheetsSubmittedStyles';
import { routes } from '../../../routes';

interface IButtonCellProps {
    className?: string;
    sheetId: string;
    clientId: string | null;
}

export default function ButtonCell({ className, sheetId, clientId }: IButtonCellProps) {
    const classes = useSubmittedTableStyles();
    const link = clientId ? generatePath(routes.CLIENT.OFFER_LETTER.DETAILS, { client_id: clientId, id: sheetId }) : '';
    return (
        <div className={clsx(className, classes.iconCell, classes.detailsIcon)}>
            <IconButton
                data-testid="button-cell-link"
                component={Link}
                to={link}
            >
                <ArrowForwardIosIcon/>
            </IconButton>
        </div>
    );
}
