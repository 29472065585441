import { combineReducers } from 'redux';
import { calculations } from 'store/entities/timesheet/reducers/calculations';
import { sheetsReducers } from './sheets';
import { time } from './time';
import { expenses } from './expenses';

export const sheets = combineReducers({
    time,
    expenses,
    calculations,
    ...sheetsReducers,
});

export type SheetState = ReturnType<typeof sheets>;
