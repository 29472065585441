import React from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { selectDealTypes, selectDealTypesAreLoading } from 'store/entities/configuration/configurationSelectors';
import { IDealType } from 'shared/models/DealType';

interface IDealTypeSelectProps extends IFormFieldProps, IFormSelect {
    filter?: (item: IDealType) => boolean;
}

export function DealTypeSelect(props: IDealTypeSelectProps) {
    const dealTypes = useSelector(selectDealTypes);
    const isDealTypesLoading = useSelector(selectDealTypesAreLoading);

    return (
        <FormSelect
            {...props}
            getKey={(dealType: IDealType) => dealType.id}
            getText={(dealType: IDealType) => dealType.name}
            options={dealTypes}
            disabled={!dealTypes.length}
            isLoading={isDealTypesLoading}
        />
    );
}
