import { bulkDeleteSubAssignmentAction } from 'modules/subassignmentManagement/store/actions';
import { IBulkDeleteSubassignmentsResponse } from 'modules/subassignmentManagement/store/models';
import { combineReducers } from 'redux';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { ICostCenter } from 'shared/models/CostCenters';
import { IDeal } from 'shared/models/Deal';
import { IDealSegment } from 'shared/models/DealSegment';
import { IDealType } from 'shared/models/DealType';
import { IDealTypeArBucket } from 'shared/models/DealTypeArBucket';
import { IJobNumber, IJobNumberUserType } from 'shared/models/JobNumber';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { ISubmittingOrgGenworthBranch } from 'shared/models/SubmittingOrgGenworthBranch';
import { ISubmittingOrgLocation } from 'shared/models/SubmittingOrgLocation';
import {
    ConfigurationAction,
    deleteActivity,
    deletePayRange,
    getActivities,
    getAreas,
    getAssignments,
    getBackgroundCheckTemplates,
    getControllingOrgs,
    getCostCenters,
    getDeals,
    getDealSegments,
    getDealTypeArBuckets,
    getDealTypePayCodeArBuckets,
    getDealTypes,
    getJobNumbers,
    getLocations,
    getPayRangeByValue,
    getPayRanges,
    getPhysicalDemands,
    getPositions,
    getProjects,
    getProjectsAssignments,
    getSubassignments,
    getSubmittingOrgGenworthBranches,
    getSubmittingOrgLocations,
    getSubmittingOrgs,
    getTasks,
    getUserTypesAction,
    getWorkingConditions,
    removeBackgroundCheckTemplate,
    removePhysicalDemandsTemplate,
    removeWorkingConditionsTemplate, searchSubassignments,
} from 'store/entities/configuration/configurationAction';
import {
    IActivity,
    IArea,
    IAssignment,
    IBackgroundCheckTemplate,
    IPayRange,
    IPhysicalDemandsTemplate,
    IProject,
    ISubassignment,
    ITask,
    IWorkingConditionsTemplate,
} from 'store/entities/configuration/configurationModel';
import {
    deleteItemByIdReducer, deleteItemsByIdsReducer,
    IActionsCreatorItemsById,
    isLoadingReducer,
    itemsByIdReducer,
    singleValueReducer,
} from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';
import { ActivitiesByTaskId, activitiesByTaskIdReducer } from './helpers/activitiesByTaskIdReducer';

export type ProjectIdsByAssignmentId = Record<string, string[]>
function projectIdsByAssignmentId(
    state: ProjectIdsByAssignmentId = {},
    action: ConfigurationAction,
): ProjectIdsByAssignmentId {
    switch (action.type) {
        case getProjectsAssignments.successType:
            return {
                ...state,
                ...(action as ReturnType<typeof getProjectsAssignments.success>).payload
                    .reduce((acc, { project, assignment }) => {
                        const projectsByAssignment = acc as ProjectIdsByAssignmentId;
                        const projects = projectsByAssignment[assignment.id] ? projectsByAssignment[assignment.id] : [];
                        projects.push(project.id);
                        return {
                            ...acc,
                            [assignment.id]: projects,
                        };
                    }, {} as ProjectIdsByAssignmentId),
            };
        default:
            return state;
    }
}

const projectWithAssignments = singleValueReducer(getProjectsAssignments.successType, []);
const assignmentsById = itemsByIdReducer<IAssignment, IAssignment>(getAssignments);

const subassignmentsById = extendReducer(
    itemsByIdReducer<ISubassignment, ISubassignment>(getSubassignments),
    deleteItemsByIdsReducer<ISubassignment>(
        bulkDeleteSubAssignmentAction,
        (payload: IBulkDeleteSubassignmentsResponse) => {
            return payload.subassignments.filter(item => !item.failed_reason).map(item => item.id);
        },
    ),
);
const isLoadingSubassignments = isLoadingReducer(searchSubassignments);

const projectsById = itemsByIdReducer<IProject, IProject>(getProjects);
const areasById = itemsByIdReducer<IArea, IArea>(getAreas);
const isAreasLoading = isLoadingReducer(getAreas);

export function activitiesByTaskId(
    state: ActivitiesByTaskId = {},
    action: ConfigurationAction,
): ActivitiesByTaskId {
    switch (action.type) {
        case getActivities.successType:
            return activitiesByTaskIdReducer((action as ReturnType<typeof getActivities.success>).payload, state);
        default:
            return state;
    }
}

const activitiesById = extendReducer(
    itemsByIdReducer<IActivity, IActivity>(getActivities),
    deleteItemByIdReducer<IActivity>(deleteActivity),
);
const isActivitiesLoading = isLoadingReducer(getActivities);
const activitiesLoaded = singleValueReducer(getActivities.successType, false);

const tasksById = itemsByIdReducer<ITask, ITask>(getTasks);
const positionsById = itemsByIdReducer<IPosition, IPosition>(getPositions);
const isPositionsLoading = isLoadingReducer(getPositions);
const physicalDemandsById = extendReducer(
    itemsByIdReducer<IPhysicalDemandsTemplate, IPhysicalDemandsTemplate>(getPhysicalDemands),
    deleteItemByIdReducer<IPhysicalDemandsTemplate>(removePhysicalDemandsTemplate),
);
const isPhysicalDemandsTemplatesLoading = isLoadingReducer(getPhysicalDemands);

const workingConditionsById = extendReducer(
    itemsByIdReducer<IWorkingConditionsTemplate, IWorkingConditionsTemplate>(getWorkingConditions),
    deleteItemByIdReducer<IWorkingConditionsTemplate>(removeWorkingConditionsTemplate),
);
const isWorkingConditionsLoading = isLoadingReducer(getWorkingConditions);

const backgroundCheckTemplatesById = extendReducer(
    itemsByIdReducer<IBackgroundCheckTemplate, IBackgroundCheckTemplate>(getBackgroundCheckTemplates),
    deleteItemByIdReducer<IBackgroundCheckTemplate>(removeBackgroundCheckTemplate),
);
const isBackgroundCheckTemplatesLoading = isLoadingReducer(getBackgroundCheckTemplates);

const locationsById = itemsByIdReducer<ILocation, ILocation>(getLocations);
const locationsLoading = isLoadingReducer(getLocations);

const controllingOrgsById = itemsByIdReducer<IControllingOrg, IControllingOrg>(getControllingOrgs);
const controllingOrgsIsLoading = isLoadingReducer(getControllingOrgs);

const submittingOrgsById = itemsByIdReducer<ISubmittingOrg, ISubmittingOrg>(getSubmittingOrgs);
const submittingOrgsIsLoading = isLoadingReducer(getSubmittingOrgs);

const submittingOrgLocationsById = itemsByIdReducer<ISubmittingOrgLocation,
ISubmittingOrgLocation>(getSubmittingOrgLocations);
const submittingOrgLocationsIsLoading = isLoadingReducer(getSubmittingOrgLocations);

const submittingOrgGenworthBranchesById = itemsByIdReducer<ISubmittingOrgGenworthBranch,
ISubmittingOrgGenworthBranch>(getSubmittingOrgGenworthBranches);
const submittingOrgGenworthBranchesIsLoading = isLoadingReducer(getSubmittingOrgGenworthBranches);

const costCentersById = itemsByIdReducer<ICostCenter, ICostCenter>(getCostCenters);
const costCentersIsLoading = isLoadingReducer(getCostCenters);

const jobNumbersById = itemsByIdReducer<IJobNumber, IJobNumber>(getJobNumbers);
const isJobNumbersLoading = isLoadingReducer(getJobNumbers);
const jobNumbersUserTypesById = itemsByIdReducer<IJobNumberUserType, IJobNumberUserType>(getUserTypesAction);
const isJobNumbersUserTypesLoading = isLoadingReducer(getUserTypesAction);

const dealsById = itemsByIdReducer<IDeal, IDeal>(getDeals);
const dealsIsLoading = isLoadingReducer(getDeals);

const dealTypesById = itemsByIdReducer<IDealType, IDealType>(getDealTypes);
const dealsTypesAreLoading = isLoadingReducer(getDealTypes);
const dealSegments = singleValueReducer<IDealSegment[]>(getDealSegments.successType, []);
const dealsSegmentsAreLoading = isLoadingReducer(getDealSegments);

const dealTypeArBuckets = singleValueReducer<string[]>(getDealTypeArBuckets.successType, []);
const dealTypeArBucketsAreLoading = isLoadingReducer(getDealTypeArBuckets);
const dealTypePayCodeArBuckets = itemsByIdReducer<IDealTypeArBucket, IDealTypeArBucket>(getDealTypePayCodeArBuckets);
const dealTypePayCodeArBucketsAreLoading = isLoadingReducer(getDealTypeArBuckets);

const payRangesById = extendReducer(
    itemsByIdReducer<IPayRange, IPayRange>(getPayRanges as IActionsCreatorItemsById<IPayRange>),
    itemsByIdReducer<IPayRange, IPayRange>(getPayRangeByValue as IActionsCreatorItemsById<IPayRange>),
    deleteItemByIdReducer<IPayRange>(deletePayRange),
);

const payRangeLoading = extendReducer(
    isLoadingReducer(getPayRanges),
    isLoadingReducer(getPayRangeByValue),
);

export const configuration = combineReducers({
    assignmentsById,
    subassignmentsById,
    isLoadingSubassignments,
    projectsById,
    areasById,
    isAreasLoading,
    projectWithAssignments,
    projectIdsByAssignmentId,
    activitiesByTaskId,
    activitiesById,
    isActivitiesLoading,
    activitiesLoaded,
    tasksById,
    positionsById,
    isPositionsLoading,
    locationsById,
    locationsLoading,
    controllingOrgsById,
    controllingOrgsIsLoading,
    submittingOrgsById,
    submittingOrgsIsLoading,
    submittingOrgLocationsById,
    submittingOrgLocationsIsLoading,
    submittingOrgGenworthBranchesById,
    submittingOrgGenworthBranchesIsLoading,
    physicalDemandsById,
    isPhysicalDemandsTemplatesLoading,
    workingConditionsById,
    isWorkingConditionsLoading,
    backgroundCheckTemplatesById,
    isBackgroundCheckTemplatesLoading,
    costCentersById,
    costCentersIsLoading,
    jobNumbersById,
    isJobNumbersLoading,
    jobNumbersUserTypesById,
    isJobNumbersUserTypesLoading,
    dealsById,
    dealsIsLoading,
    dealTypesById,
    dealsTypesAreLoading,
    dealSegments,
    dealsSegmentsAreLoading,
    dealTypeArBuckets,
    dealTypeArBucketsAreLoading,
    dealTypePayCodeArBuckets,
    dealTypePayCodeArBucketsAreLoading,
    payRangesById,
    payRangeLoading,
});
