import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { setPayrollEditCalculationGroupId } from 'modules/payrollProcessorHub/store/actions';
import ActionButton from 'shared/components/buttons/ActionButton';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export interface IEditGroupButtonProps {
    calculationGroup: ISheetCalculationBatch;
}

export function EditGroupButton({ calculationGroup }: IEditGroupButtonProps) {
    const dispatch = useDispatch();
    const openEdit = useCallback(() => {
        dispatch(setPayrollEditCalculationGroupId(calculationGroup.id));
    }, [dispatch, calculationGroup]);

    const userHasPermission = useSelector(selectIsUserHasPermission(Permission.CanEditPphSheet));
    if (!userHasPermission) {
        return null;
    }

    return (
        <ActionButton
            variant="outlined"
            color="primary"
            customType="danger"
            onClick={openEdit}
        >
            Edit
        </ActionButton>
    );
}
