import { selectSubassignmentManagementState } from 'modules/subassignmentManagement/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { CustomFieldType } from 'store/entities/customFields/model';
import { selectCustomFieldsByIds, selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';

export const selectIsPromotionDemotionOpened = (state: IStore) =>
    selectSubassignmentManagementState(state).promotionDemotion.isOpen;

export const selectPositionCustomFieldValueId = createSelector(
    selectOrderedCustomFieldAssignmentNodes,
    selectCustomFieldsByIds,
    (hierarchy, customFieldsByIds) => {
        const fields = hierarchy.map(node => customFieldsByIds[node.custom_field_id]).filter(Boolean);
        const position = fields.find(field => field?.headway_connect_field?.key === CustomFieldType.Position);
        return position?.id || '';
    },
);
