import { EafApprovalReducer } from 'modules/eafApproval/store/reducers';
import { combineReducers, DeepPartial } from 'redux';
import { attachments } from 'shared/components/attachments/store/reducer';
import { keycloakLogout, logout, resetState } from 'store/components/auth/authActions';
import { setClientId } from 'store/entities/clients/clientsAction';
import { scopes } from 'store/entities/scopes/reducers';
import { appConfig } from 'store/entities/appConfig/reducer';
import { sheets } from 'store/entities/timesheet/reducers';
import { scaZone } from 'store/entities/scaZone/reducer';
import { zipCodes } from 'store/entities/zipCode/reducer';
import { auth } from 'store/components/auth/authReducer';
import { components } from 'store/components/reducers';
import { users } from 'store/entities/users/reducers';
import { sheetEntryAttachments } from './entities/timesheet/reducers/sheetEntryAttachments';
import { configuration } from 'store/entities/configuration/configurationReducer';
import { clients } from 'store/entities/clients/clientsReducer';
import { offerLetter } from 'modules/offerLetter/store/reducer';
import { notificationCenter } from 'modules/notificationCenter/store/reducer';
import { payrollProcessorHub } from 'modules/payrollProcessorHub/store/reducer';
import { home } from 'modules/home/store/reducers';
import { profile } from 'modules/profile/store/profileReducer';
import { settings } from 'modules/settings/store/reducer';
import { employment } from 'modules/employmentInfo/store/reducer';
import { timeAndExpense } from 'modules/timeAndExpense/store/reducers';
import { sheetApproval } from 'modules/sheetApproval/store/reducers';
import { customFields } from 'store/entities/customFields/reducers';
import { subassignmentManagement } from 'modules/subassignmentManagement/store/reducers';
import { employeeActonForm } from 'modules/employeeActionsForm/store/reducers';
import { travelExpenses } from 'modules/travelExpenses/reducer';
import { usersModule } from 'modules/users/store/reducers';
import { ccpTransactions } from 'modules/ccp/store/reducer';

const appReducer = combineReducers({
    auth,
    configuration,
    clients,
    customFields,
    scopes,
    sheets,
    users,
    scaZone,
    zipCodes,
    appConfig,
    components,
    sheetEntryAttachments,
    attachments,
    modules: combineReducers({
        offerLetter,
        settings,
        notificationCenter,
        payrollProcessorHub,
        travelExpenses,
        profile,
        home,
        employment,
        timeAndExpense,
        sheetApproval,
        subassignmentManagement,
        employeeActonForm,
        users: usersModule,
        eafApproval: EafApprovalReducer,
        ccpTransactions,
    }),
});

const rootReducer = (state: DeepPartial<ReturnType<typeof appReducer>> | undefined, action: any) => {
    if ([logout.action, keycloakLogout.action, resetState.action].includes(action.type)) {
        state = {
            appConfig: {
                toastState: state?.appConfig?.toastState,
                optimizelyReady: state?.appConfig?.optimizelyReady || false,
            },
        };
    }

    if (action.type === setClientId.action) {
        // Reset tenant specific state
        state = {
            auth: state?.auth,
            appConfig: state?.appConfig,
            clients: state?.clients,
            modules: {
                payrollProcessorHub: state?.modules?.payrollProcessorHub,
                settings: {
                    ...state?.modules?.settings,
                    clientsSettings: {
                        ...state?.modules?.settings?.clientsSettings,
                        clients: undefined,
                    },
                },
                profile: state?.modules?.profile,
            },
        } as DeepPartial<ReturnType<typeof appReducer>>;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return appReducer(state, action);
};

export default rootReducer;
