/* eslint-disable react/display-name */
import React, { FC, useCallback } from 'react';
import { setEafDetailId } from 'modules/eafApproval/store/actions';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import { useDispatch } from 'react-redux';

export const withOpenEafDetailCell = (Component: FC<EafApprovalCellProps>) =>
    (props: EafApprovalCellProps) => {
        const dispatch = useDispatch();
        const onClick = useCallback(() => dispatch(setEafDetailId(props.id)), [dispatch, props]);
        return <Component {...props} onClick={onClick}/>;
    };
