import { ValidationMessages } from 'shared/models/Validation';
import { CustomFieldType } from 'store/entities/customFields/model';
import * as yup from 'yup';

export const WorkLocationValidationsSchema = yup.object().shape({
    name: yup.string().trim().required(ValidationMessages.REQUIRED),
    address: yup.string().trim().required(ValidationMessages.REQUIRED),
    address2: yup.string().trim().matches(/^((?!#).)*$/, 'Please remove special characters'),
    city: yup.string().trim().required(ValidationMessages.REQUIRED),
    state_code: yup.string().trim().required(ValidationMessages.REQUIRED),
    county: yup.string().trim().required(ValidationMessages.REQUIRED),
    zip_code: yup.string().trim().required(ValidationMessages.REQUIRED),
    override_work_geo_code: yup.string().nullable().required(ValidationMessages.REQUIRED),
});

export const codeValidationSchema = yup.string().trim().required(ValidationMessages.REQUIRED)
    .matches(/^[a-zA-Z0-9]([a-zA-Z0-9-_]*)$/, 'Code should start with letter or number. Use letters, numbers and dash.');

export const nameCodeValidationsSchema = yup.object().shape({
    name: yup.string().trim().required(ValidationMessages.REQUIRED),
    code: codeValidationSchema,
});

const nameMixin = {
    name: yup.string().trim().required(ValidationMessages.REQUIRED),
};

export const nameValueValidationsSchema = yup.object().shape({
    ...nameMixin,
    value: yup.string().trim().required(ValidationMessages.REQUIRED),
});

const codeNameDescriptionValidationMixin = {
    ...nameMixin,
    code: codeValidationSchema,
    description: yup.string(),
};

export const nameCodeDescriptionValidationsSchema = yup.object().shape(codeNameDescriptionValidationMixin);

export const nameCodeDescriptionProjectClassValidationSchema = yup.object().shape({
    ...codeNameDescriptionValidationMixin,
    project_class: yup.string().nullable().required(ValidationMessages.REQUIRED),
});

export const shiftCodeValidationSchema = yup.object().shape({
    ...codeNameDescriptionValidationMixin,
    differential_method: yup.string().nullable().required(ValidationMessages.REQUIRED),
});

export const SCAValidationSchema = yup.object().shape({
    ...nameMixin,
    pay_rate: yup.number().nullable().required(ValidationMessages.REQUIRED),
    health_and_welfare_rate: yup.number().nullable().required(ValidationMessages.REQUIRED),
    wage_determination_number: yup.string().nullable().required(ValidationMessages.REQUIRED),
    state: yup.string().nullable().required(ValidationMessages.REQUIRED),
    counties: yup.array(yup.string()).required(ValidationMessages.REQUIRED),
    positions: yup.array(yup.string()).required(ValidationMessages.REQUIRED),
});

export const useCustomFieldValueValidationSchemaByType = (headway_connect_field: CustomFieldType) => {
    let dataValidationSchema;
    switch (headway_connect_field) {
        case CustomFieldType.Location:
            dataValidationSchema = WorkLocationValidationsSchema;
            break;
        case CustomFieldType.NameCode:
            dataValidationSchema = nameCodeValidationsSchema;
            break;
        case CustomFieldType.NameCodeDescription:
            dataValidationSchema = nameCodeDescriptionValidationsSchema;
            break;
        case CustomFieldType.NameCodeDescriptionProjectClass:
            dataValidationSchema = nameCodeDescriptionProjectClassValidationSchema;
            break;
        case CustomFieldType.ShiftCode:
            dataValidationSchema = shiftCodeValidationSchema;
            break;
        case CustomFieldType.NameValue:
            dataValidationSchema = nameValueValidationsSchema;
            break;
        case CustomFieldType.SCA:
            dataValidationSchema = SCAValidationSchema;
            break;
        default:
            dataValidationSchema = yup.object();
            break;
    }
    return yup.object().shape({
        data: dataValidationSchema,
    });
};
