import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useTotalStyles = makeHighPriorityStyles((theme: Theme) => ({
    totalContainer: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0.5),
            justifyContent: 'flex-end',
        },
    },
    totalTimeContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    loading: {
        color: colors.gray,
    },
    progress: {
        marginRight: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(0, 2),
        backgroundColor: colors.lightGray,
    },
}));

export default useTotalStyles;
