import { setSheetApprovalLoadingState } from 'store/components/sheetApprovalCommon/sheetApprovalCommonActions';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import {
    ExpensesApprovalActions,
    getExpenseReviewerSheets,
} from 'store/components/expensesApproval/expensesApprovalActions';
import { combineReducers } from 'redux';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const initialState = {
    isLoaded: false,
    isLoading: true,
};

const isLoading = extendReducer(
    singleValueReducer(setSheetApprovalLoadingState.action, initialState.isLoading),
    isLoadingReducer(getExpenseReviewerSheets, initialState.isLoading),
);

function isLoaded(state = initialState.isLoaded, action: ExpensesApprovalActions): boolean {
    switch (action.type) {
        case getExpenseReviewerSheets.successType:
            return true;
        case getExpenseReviewerSheets.initType:
            return false;
        default:
            return state;
    }
}

export const expensesApproval = combineReducers({
    isLoading,
    isLoaded,
});
