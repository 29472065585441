import { createActions, createSingleAction } from 'store/utils';
import {
    ICreateTemplatePayload,
    IUpdateTemplatePayload,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';

export const SET_CREATE_WORKING_CONDITIONS_TEMPLATE_MODAL_STATE = 'settings/SET_CREATE_WORKING_CONDITIONS_TEMPLATE_MODAL_STATE';
export const setCreateWorkingConditionsTemplateModalState = createSingleAction<
boolean,
typeof SET_CREATE_WORKING_CONDITIONS_TEMPLATE_MODAL_STATE
>(
    SET_CREATE_WORKING_CONDITIONS_TEMPLATE_MODAL_STATE,
);

export const CREATE_WORKING_CONDITIONS_TEMPLATE = 'settings/CREATE_WORKING_CONDITIONS_TEMPLATE';
export const CREATE_WORKING_CONDITIONS_TEMPLATE_SUCCESS = 'settings/CREATE_WORKING_CONDITIONS_TEMPLATE_SUCCESS';
export const CREATE_WORKING_CONDITIONS_TEMPLATE_ERROR = 'settings/CREATE_WORKING_CONDITIONS_TEMPLATE_ERROR';

export const createWorkingConditionsTemplate = createActions<
ICreateTemplatePayload,
void,
any,
typeof CREATE_WORKING_CONDITIONS_TEMPLATE,
typeof CREATE_WORKING_CONDITIONS_TEMPLATE_SUCCESS,
typeof CREATE_WORKING_CONDITIONS_TEMPLATE_ERROR
>(
    CREATE_WORKING_CONDITIONS_TEMPLATE,
    CREATE_WORKING_CONDITIONS_TEMPLATE_SUCCESS,
    CREATE_WORKING_CONDITIONS_TEMPLATE_ERROR,
);

export const SET_EDIT_WORKING_CONDITIONS_TEMPLATE_ID = 'settings/SET_EDIT_WORKING_CONDITIONS_TEMPLATE_ID';
export const setEditWorkingConditionsTemplateId = createSingleAction<
string | null,
typeof SET_EDIT_WORKING_CONDITIONS_TEMPLATE_ID
>(
    SET_EDIT_WORKING_CONDITIONS_TEMPLATE_ID,
);

export const UPDATE_WORKING_CONDITIONS_TEMPLATE = 'settings/UPDATE_WORKING_CONDITIONS_TEMPLATE';
export const UPDATE_WORKING_CONDITIONS_TEMPLATE_SUCCESS = 'settings/UPDATE_WORKING_CONDITIONS_TEMPLATE_SUCCESS';
export const UPDATE_WORKING_CONDITIONS_TEMPLATE_ERROR = 'settings/UPDATE_WORKING_CONDITIONS_TEMPLATE_ERROR';

export const updateWorkingConditionsTemplate = createActions<
IUpdateTemplatePayload,
void,
any,
typeof UPDATE_WORKING_CONDITIONS_TEMPLATE,
typeof UPDATE_WORKING_CONDITIONS_TEMPLATE_SUCCESS,
typeof UPDATE_WORKING_CONDITIONS_TEMPLATE_ERROR
>(
    UPDATE_WORKING_CONDITIONS_TEMPLATE,
    UPDATE_WORKING_CONDITIONS_TEMPLATE_SUCCESS,
    UPDATE_WORKING_CONDITIONS_TEMPLATE_ERROR,
);
