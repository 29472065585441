import { Box } from '@material-ui/core';
import { OpenModalButton } from 'modules/settings/common/components/OpenModalButton/OpenModalButton';
import {
    CreateUserClientAssociationModal,
} from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/CreateUserClientAssociationModal';
import {
    setCreateUserAssociationModalState,
} from 'modules/settings/submodules/platformUsers/store/actions';
import {
    selectIsClientUsersAssociationsCreateModalOpen,
} from 'modules/settings/submodules/platformUsers/store/selectors';
import React from 'react';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { useReduxModal } from 'shared/utils/hooks/useModal';

export const CreateUserClientAssociationButton = () => {
    const classes = useColoredFormModalStyles();
    const { isModalOpened, closeModal } = useReduxModal(
        selectIsClientUsersAssociationsCreateModalOpen,
        setCreateUserAssociationModalState,
    );

    return (
        <>
            <Box>
                <OpenModalButton openAction={setCreateUserAssociationModalState} title="+ Add Association"/>
            </Box>
            <CreateUserClientAssociationModal
                title="Add Association to User"
                isOpened={isModalOpened}
                onClose={closeModal}
                customClasses={classes}
                showCloseIcon
            />
        </>
    );
};
