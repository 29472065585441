import React, { useCallback, useState } from 'react';
import { UnregisterCallback } from 'history';
import { useHistoryBlock } from 'modules/payrollProcessorHub/components/EditSheet/utils';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';

export interface ILeavePageConfirmationChild {
    onBeforeClose?: UnregisterCallback;
    openConfirmation?: () => void;
    onClose: () => void;
}

export interface ILeavePageConfirmation {
    where: boolean;
    message: string;
    onClose: () => void;
    children: (params: ILeavePageConfirmationChild) => React.ReactNode;
}

export const LeavePageConfirmation = ({
    where: showLeaveConfirmation,
    message,
    onClose,
    children,
}: ILeavePageConfirmation) => {
    const [isOpenConfirmation, setOpenConfirmation] = useState(false);
    const openConfirmation = useCallback(() => setOpenConfirmation(true), [setOpenConfirmation]);
    const unblockHistory = useHistoryBlock(showLeaveConfirmation, message, openConfirmation);
    const confirmClose = useCallback(() => {
        unblockHistory && unblockHistory();
        onClose();
    }, [onClose, unblockHistory]);

    return (
        <>
            <ConfirmationDialog
                open={isOpenConfirmation}
                onConfirm={confirmClose}
                onCancel={() => setOpenConfirmation(false)}
                confirmText="Leave page"
            >
                {message}
            </ConfirmationDialog>
            {children({
                onBeforeClose: unblockHistory,
                openConfirmation: showLeaveConfirmation ? openConfirmation : undefined,
                onClose: confirmClose,
            })}
        </>
    );
};
