import axios from 'axios';
import { call, put } from 'typed-redux-saga';
import { ActionCreatorKnownArgs } from 'store/utils';
import { getBackendErrorOrDefaultMessage } from 'store/utils/sagas/getBackendErrorOrDefaultMessage';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { AuthenticationError } from 'shared/models/Authentication';

export function withBackendErrorHandler<FunctionType extends(...args: any) => any>(
    originalSaga: FunctionType,
    errorAction: ActionCreatorKnownArgs<any, any>,
    defaultErrorMessage: string,
    globalToaster = true,
) {
    return function* (...args: Parameters<FunctionType>) {
        try {
            yield* call(originalSaga, ...args);
        } catch (e) {
            if (axios.isCancel(e)) {
                return;
            }
            if (e instanceof AuthenticationError) {
                return;
            }
            if (process.env.NODE_ENV === 'development') {
                console.error(e);
            }
            const errorMessage = getBackendErrorOrDefaultMessage(e, defaultErrorMessage);
            if (globalToaster) {
                yield put(setGlobalToast({
                    severity: IModalSeverity.Error,
                    title: errorMessage,
                    autoHideDuration: autoHideDefaultDuration * 2,
                }));
            }
            if (e?.code === 'ECONNABORTED') {
                return;
            }
            logErrorWithCustomMessage(e, errorMessage);
            let errorPayload = {
                error: e,
            };
            const originAction = args[0];
            // Get payload from origin action
            if (originAction && originAction.payload) {
                errorPayload = {
                    ...errorPayload,
                    ...originAction.payload,
                };
            }
            yield* put(errorAction(errorPayload));
        }
    };
}
