import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import {
    usePayrollSheetGroupApprovers,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/useSheetGroupApprovers';
import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';

type ApproversCellProps = ICellProps<{
    approvers: IUserInfo[];
}>

export const ApproversCell = ({ className, approvers }: ApproversCellProps) => {
    const approversNameList = approvers.map(approver => getUserName(approver));
    const value = approversNameList.join(', ');

    return (
        <PlainText
            className={className}
            value={value}
        />
    );
};

export const GroupedApproversCell = ({ className, ...row }: ICellProps<IPayrollSheetGroupedRow>) => {
    const approvers = usePayrollSheetGroupApprovers(row);
    return (
        <ApproversCell
            className={className}
            approvers={approvers}
        />
    );
};
