import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISubassignmentRow } from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import { setSubassignmentCheckedGroups, setSubassignmentCheckedItems } from 'modules/subassignmentManagement/components/SubassignmentTable/store/actions';
import { selectSubassignmentCheckedGroups, selectSubassignmentCheckedItems } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { AssignmentTableGroupHeader, IAssignmentTableGroupHeaderBaseProps } from 'modules/employmentManagement/components/AssignmentTable/AssignmentTableGroupHeader';

interface IAssignmentGroupHeaderProps extends IAssignmentTableGroupHeaderBaseProps {
    rows: ISubassignmentRow[];
}

export const AssignmentGroupHeader = ({
    rows,
    ...baseProps
}: IAssignmentGroupHeaderProps) => {
    const { assignmentId } = baseProps;
    const dispatch = useDispatch();

    const checkedItems = useSelector(selectSubassignmentCheckedItems);
    const checkedGroups = useSelector(selectSubassignmentCheckedGroups);

    const rowsInGroupChecked = useMemo(() => !rows.some(row => !checkedItems[row.id]), [rows, checkedItems]);
    const groupChecked = (!rows.length && checkedGroups[assignmentId])
        || Boolean(rows.length && rowsInGroupChecked);

    useEffect(() => {
        if (rows.length && checkedGroups[assignmentId] !== rowsInGroupChecked) {
            dispatch(setSubassignmentCheckedGroups({
                ...checkedGroups,
                [assignmentId]: rowsInGroupChecked,
            }));
        }
    }, [assignmentId, checkedGroups, dispatch, rows, rowsInGroupChecked]);

    const onCheckGroup = () => {
        dispatch(setSubassignmentCheckedGroups({
            ...checkedGroups,
            [assignmentId]: !groupChecked,
        }));
        dispatch(setSubassignmentCheckedItems(rows.reduce((newCheckedItems, row) => {
            return {
                ...newCheckedItems,
                [row.id]: !groupChecked,
            };
        }, checkedItems)));
    };

    return (
        <AssignmentTableGroupHeader
            {...baseProps}
            useCheckbox
            groupChecked={groupChecked}
            onCheckGroup={onCheckGroup}
            hasChildren={Boolean(rows.length)}
        />
    );
};
