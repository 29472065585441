import { IStore } from 'store/configureStore';
import { selectCcpTransactionsState } from '../../../store/selectors';
import { BulkCreateCcpTransactionsSteps } from './actions';
import { ITransactionCode } from '../../../models/ccpModel';

export const selectCreateBulkCcpState = (state: IStore) => {
    return selectCcpTransactionsState(state).createCcpTransactions;
};

export const selectIsOpenCcpUploadCreationModal = (state: IStore) =>
    selectCreateBulkCcpState(state).isOpenBulkCcpCreationUploadModal;
export const selectCcpBulkCreationStep = (state: IStore): BulkCreateCcpTransactionsSteps =>
    selectCreateBulkCcpState(state).ccpBulkStep;
export const selectIsCcpBulkLoadingPreInitialize = (state: IStore) =>
    selectCreateBulkCcpState(state).isLoadingCcpPreinitialize;

export const selectTransactionCodes = (state: IStore) =>
    selectCcpTransactionsState(state).ccpTransactionCodesById;

export const selectAllTransactionCodes = (state: IStore): ITransactionCode[] =>
    Object.values(selectTransactionCodes(state));

export const selectIsLoadingPreInitializeImport = (state: IStore) =>
    selectCreateBulkCcpState(state).isLoadingCcpPreinitialize;
export const selectIsLoadingImport = (state: IStore) =>
    selectCreateBulkCcpState(state).isLoadingCcpImport;

export const selectCcpImportState = (state: IStore) =>
    selectCreateBulkCcpState(state).report;
export const selectCcpPostImportState = (state: IStore) =>
    selectCreateBulkCcpState(state).postReport;
export const selectCcpImportRows = (state: IStore) =>
    selectCcpImportState(state)?.creates;
export const selectCcpPostImportRows = (state: IStore) =>
    selectCcpPostImportState(state)?.creates;
