import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { setEditCostCenterId } from 'modules/settings/submodules/clients/costCenters/components/EditCostCenter/store/actions';
import { updateCostCenter } from 'modules/settings/submodules/clients/costCenters/store/actions';

const costCenterId = singleValueReducer<string | null>(setEditCostCenterId.action, null);
const isUpdating = isLoadingReducer(updateCostCenter);

export const editCostCenter = combineReducers({
    costCenterId,
    isUpdating,
});
