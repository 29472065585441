import React from 'react';
import { Formik } from 'formik';
import { IDeal } from 'shared/models/Deal';
import { IJobNumber } from 'shared/models/JobNumber';
import {
    IJobNumberFormValues,
} from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/model';
import { JobNumberForm } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/JobNumberForm';
import { jobNumbersValidationSchema } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/ValidationsSchema';
import { useJobNumberInitialFormValues } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/utils';

export interface ICostCenterFormWrapperProps {
    onSubmit: (values: IJobNumberFormValues) => void;
    onBack?: () => void;
    submitTitle: string;
    isLoading: boolean;
    isEdit?: boolean;
    jobNumber?: IJobNumber;
    deal?: IDeal;
    dealReadonly?: boolean;
}

const JobNumberFormWrapper = ({
    onSubmit,
    onBack,
    submitTitle,
    jobNumber,
    deal,
    dealReadonly,
    isLoading,
    isEdit = false,
}: ICostCenterFormWrapperProps) => {
    const initialFormValues = useJobNumberInitialFormValues(jobNumber, deal);

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={jobNumbersValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <JobNumberForm
                    {...props}
                    isLoading={isLoading}
                    onBack={onBack}
                    submitTitle={submitTitle}
                    isEdit={isEdit}
                    dealReadonly={dealReadonly}
                />
            )}
        </Formik>
    );
};

export default JobNumberFormWrapper;
