import { combineReducers } from 'redux';
import { THoverState } from 'shared/components/table/tableApprovalCells/hover';
import { IGlobalModal } from 'shared/components/toasts/modal';
import {
    appSidebarActionsType,
    SET_GLOBAL_TOAST,
    SET_GRID_HOVER_STATUS,
    SET_HEADER_TITLE,

    SET_SIDEBAR_OPENED,
    setGlobalToastActionType,
    setGridHoverStatusActionType,
    setHeaderTitle, setOptimizelyReady,
    increaseSyncing, decreaseSyncing, clearSyncing, setSheetGridStatus,
} from 'store/entities/appConfig/actions';
import { IHeaderTitle } from 'store/entities/appConfig/appConfigModel';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { singleValueReducer } from 'store/reducerUtils';
import { SyncingModels } from './syncing/models';

const defaultState = {
    isSidebarOpened: true,
    toastState: null,
    sheetGridStatus: StatusNames.WORKING,
    hoverState: {
        color: '',
        isHover: false,
        rowId: '',
    },
    headerTitle: {
        desktopHeader: '',
        mobileHeader: '',
        tabHeader: '',
    },
    optimizelyReady: false,
    syncingState: {
        [SyncingModels.EditableEmployeeSheet]: 0,
        [SyncingModels.PayrollApprovalSheet]: 0,
        [SyncingModels.ApprovalSheet]: 0,
        [SyncingModels.OfferLetterTemplatesSettings]: 0,
        [SyncingModels.Users]: 0,
    } as Record<SyncingModels, number>,
};

function isSidebarOpened(state: boolean = defaultState.isSidebarOpened, action: appSidebarActionsType): boolean {
    switch (action.type) {
        case SET_SIDEBAR_OPENED:
            return action.payload;
        default:
            return state;
    }
}

function headerTitle(state = defaultState.headerTitle, action: ReturnType<typeof setHeaderTitle>): IHeaderTitle {
    switch (action.type) {
        case SET_HEADER_TITLE:
            if (typeof action.payload === 'string') {
                return {
                    desktopHeader: action.payload,
                    mobileHeader: action.payload,
                    tabHeader: action.payload,
                };
            } else {
                return action.payload;
            }
        default:
            return state;
    }
}

function toastState(state: IGlobalModal | null = defaultState.toastState, action: setGlobalToastActionType) {
    switch (action.type) {
        case SET_GLOBAL_TOAST:
            return action.payload;
        default:
            return state;
    }
}

function uiSyncing(
    state: Record<SyncingModels, number> = defaultState.syncingState,
    action: ReturnType<typeof increaseSyncing>,
) {
    switch (action.type) {
        case increaseSyncing.action:
            return {
                ...state,
                [action.payload]: (state[action.payload] || 0) + 1,
            };
        case decreaseSyncing.action:
            return {
                ...state,
                [action.payload]: state[action.payload] ? state[action.payload] - 1 : 0,
            };
        case clearSyncing.action:
            return {
                ...state,
                [action.payload]: 0,
            };
        default:
            return state;
    }
}

export const SHEET_GRID_TAB_STATUS_DEFAULT = StatusNames.SUBMITTED;
const sheetGridStatus = singleValueReducer(setSheetGridStatus.action, SHEET_GRID_TAB_STATUS_DEFAULT);

function approvalGridHoverStatus(state = defaultState.hoverState, action: setGridHoverStatusActionType): THoverState {
    switch (action.type) {
        case SET_GRID_HOVER_STATUS:
            return action.payload;
        default:
            return state;
    }
}

function optimizelyReady(
    state: boolean = defaultState.optimizelyReady, action: ReturnType<typeof setOptimizelyReady>,
): boolean {
    switch (action.type) {
        case setOptimizelyReady.action:
            return action.payload;
        default:
            return state;
    }
}

export const appConfig = combineReducers({
    headerTitle,
    isSidebarOpened,
    toastState,
    sheetGridStatus,
    approvalGridHoverStatus,
    optimizelyReady,
    uiSyncing,
});
