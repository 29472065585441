import { IStore } from 'store/configureStore';

export const selectBackgroundCheckState = (state: IStore) => {
    return state.modules.settings.clientsSettings.clients.backgroundChecksTemplates;
};

/*
 * Create
 */
export const selectIsCreateBackgroundCheckTemplateModalOpened = (state: IStore) =>
    selectBackgroundCheckState(state).isOpenCreateBackgroundCheckModal;
export const selectIsBackgroundCheckTemplateCreating = (state: IStore) =>
    selectBackgroundCheckState(state).isBackgroundCheckCreating;

/*
 * Update
 */
export const selectEditBackgroundCheckTemplateId = (state: IStore) =>
    selectBackgroundCheckState(state).editBackgroundCheckId;
export const selectIsBackgroundCheckTemplateUpdating = (state: IStore) =>
    selectBackgroundCheckState(state).isBackgroundCheckUpdating;
