import {
    tryAddPtoItemBasedMaxUseAvailable,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/tryAddPtoItem';
import {
    useCustomFieldsItemsBatch,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/hooks/useCustomFieldsItemsBatch';
import { ISheetCalculationBatch, PayrollSheetStatuses } from 'modules/payrollProcessorHub/store/model';
import { useSelector } from 'react-redux';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { formatUtcWithEstZone } from 'shared/models/Dates';
import { ILocation } from 'shared/models/Location';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IPaidTimeOffResponse } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import { formatApprovals } from 'shared/utils/formatters/formatApprovals';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { formatEditedOn } from 'shared/utils/formatters/sheetDetail';
import { IClient } from 'store/entities/clients/clientsModel';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';
import { selectUsersById } from 'store/entities/users/selectors';
import { Nullable } from 'types/types';
import {
    resolveClient,
    resolveDealNumberBatch,
    resolveDepartment,
    resolveExpenseSheetIdBatch,
    resolveJobNumberBatch,
    resolveLocation,
    resolvePayPeriod,
    resolvePayType,
    resolvePositionBatch,
    resolvePrismBatchNumberList,
    resolveTimeSheetIdBatch,
} from '../helpers/detailItemValueResolvers';
import { onlyNotEmptyItems } from '../helpers/filters';
import { SheetInfoItemTitle } from '../model/SheetInfoItemTitle';
import { useSheetGroupAdditionalDataBatch } from './useSheetGroupAdditionalDataBatch';

/**
 * Resolves Prism batch item depending on entry type
 */
function getPrismBatchItem(type: EntryType, calcGroup: ISheetCalculationBatch) {
    return {
        title: SheetInfoItemTitle.PrismBatchId,
        value: resolvePrismBatchNumberList(type, calcGroup),
    };
}

/**
 * Retrieves items specific to Time Entry
 */
function getTimeEntryItems(client: IClient, calcGroup: ISheetCalculationBatch) {
    return [
        {
            title: SheetInfoItemTitle.Client,
            value: resolveClient(client),
        },
        (
            calcGroup.payroll_status === PayrollSheetStatuses.CLOSED
                ? getPrismBatchItem(EntryType.TIME, calcGroup)
                : {
                    title: SheetInfoItemTitle.TimesheetId,
                    value: resolveTimeSheetIdBatch(calcGroup),
                }
        ),
        {
            title: SheetInfoItemTitle.PayType,
            value: resolvePayType(calcGroup),
        },
    ];
}

/**
 * Retrieves items specific to Expense Entry
 */
function getExpenseEntryItems(locations: Array<ILocation>, calcGroup: ISheetCalculationBatch) {
    return [
        {
            title: SheetInfoItemTitle.Location,
            value: resolveLocation(...locations),
        },
        (
            calcGroup.payroll_status === PayrollSheetStatuses.CLOSED
                ? getPrismBatchItem(EntryType.EXPENSE, calcGroup)
                : {
                    title: SheetInfoItemTitle.ExpenseSheetId,
                    value: resolveExpenseSheetIdBatch(calcGroup),
                }
        ),
    ];
}

const resolveItemsByEntryType = (
    type: EntryType,
    client: IClient,
    calcGroup: ISheetCalculationBatch,
    locations: Array<ILocation>,
) => (
    type === EntryType.TIME
        ? getTimeEntryItems(client, calcGroup)
        : getExpenseEntryItems(locations, calcGroup)
);

const getLatestSubmittedTime = (calculationGroup: ISheetCalculationBatch, type: EntryType): Nullable<string> => {
    const calculations: ICalculation[] = type === EntryType.TIME
        ? calculationGroup.time_calculations
        : calculationGroup.expense_calculations;
    return calculations.map(calculation => calculation.sheet_submitted_at)
        .sort()
        .reverse()
        .find(Boolean);
};

export function useSheetGroupItemsBatch(calculationGroup: ISheetCalculationBatch, type: EntryType) {
    const {
        pto,
        client,
        locations,
        departments,
        approvers,
        approvals,
        sheetLogs,
    } = useSheetGroupAdditionalDataBatch(calculationGroup);
    const usersByIds = useSelector(selectUsersById);

    const typeRelatedInfo = resolveItemsByEntryType(type, client, calculationGroup, locations);
    const latestSubmittedTime = getLatestSubmittedTime(calculationGroup, type);

    const items: Array<ISheetInfoItemData> = [
        {
            title: SheetInfoItemTitle.PayPeriod,
            value: resolvePayPeriod(calculationGroup),
        },
        ...typeRelatedInfo,
        {
            title: SheetInfoItemTitle.DealNumber,
            value: resolveDealNumberBatch(calculationGroup),
        },
        {
            title: SheetInfoItemTitle.JobNumber,
            value: resolveJobNumberBatch(calculationGroup),
        },
        {
            title: SheetInfoItemTitle.Position,
            value: resolvePositionBatch(calculationGroup),
        },
        {
            title: SheetInfoItemTitle.Department,
            value: resolveDepartment(...departments),
        },
        {
            title: SheetInfoItemTitle.Submitted,
            value: latestSubmittedTime ? formatUtcWithEstZone(latestSubmittedTime) : '',
        },
        ...[
            {
                title: `Approving ${pluralize('Manager', approvers.length)}`,
                value: approvers.map(approver => getUserName(approver)).join(', '),
            },
            {
                title: 'Approved Timestamp',
                value: formatApprovals(approvals, usersByIds),
            },
        ],
    ].filter(onlyNotEmptyItems);

    const updatedSheetDetailsList = useCustomFieldsItemsBatch(
        type,
        calculationGroup,
        items,
    );

    tryAddPtoItemBasedMaxUseAvailable(
        pto as IPaidTimeOffResponse | null,
        type,
        updatedSheetDetailsList,
    );

    sheetLogs.length && updatedSheetDetailsList.push({
        title: 'Edited on',
        // @ts-ignore
        value: formatEditedOn(usersByIds, sheetLogs),
    });

    return updatedSheetDetailsList;
}
