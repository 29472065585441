import React, { useCallback } from 'react';
import { EditPayrollSheetPage } from 'modules/payrollProcessorHub/components/EditSheet/EditPayrollSheetPage';
import {
    loadGroupedSheets,
    setPayrollDetailCalculationGroupId,
    setPayrollEditCalculationGroupId,
} from 'modules/payrollProcessorHub/store/actions';
import { PayrollSheetStatuses } from 'modules/payrollProcessorHub/store/model';
import {
    selectEditCalculationGroup,
    selectIsLoadingEditCalculationGroup,
} from 'modules/payrollProcessorHub/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { StatusNames } from 'store/entities/timesheet/models/Status';

export const EditPayrollGroupedSheetPage = () => {
    const editGroup = useSelector(selectEditCalculationGroup);
    const timeSheetIds = editGroup?.time_calculations?.map(calculation => calculation.sheet_id) || [];
    const expenseSheetId = editGroup?.expense_calculations?.map(calculation => calculation.sheet_id) || [];
    const isLoading = useSelector(selectIsLoadingEditCalculationGroup);
    const dispatch = useDispatch();
    const handleClosePage = useCallback(() => {
        dispatch(setPayrollEditCalculationGroupId(null));
        dispatch(setPayrollDetailCalculationGroupId(null));
        dispatch(loadGroupedSheets());
    }, [dispatch]);
    const statusName = editGroup?.payroll_status === PayrollSheetStatuses.MISSING
        ? StatusNames.WORKING : StatusNames.APPROVED;

    return (
        <EditPayrollSheetPage
            timeSheetIds={timeSheetIds}
            expenseSheetIds={expenseSheetId}
            isLoading={isLoading}
            onClose={handleClosePage}
            // @ts-ignore
            statusName={statusName}
            // @ts-ignore
            showAllEntriesForPeriod={{
                period_start: editGroup?.pay_period_starts_at,
                period_end: editGroup?.pay_period_ends_at,
            }}
        />
    );
};
