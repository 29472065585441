import { ItemsById } from 'shared/models/ItemsById';
import { IStore } from 'store/configureStore';
import { IClient, IClientInfo } from 'store/entities/clients/clientsModel';
import createSelectorSortedByNameFromById from 'store/utils/selectors/createSelectorSortedByNameFromById';

export const selectCurrentClientId = (state: IStore) => state.clients.clientId;
export const selectFieldConfiguration = (id: string) => (state: IStore) =>
    state.clients.fieldsConfigurationByClientId[id];

export const selectIsLoadingClientConfiguration = (state: IStore) => {
    return state.clients.isLoadingFieldsConfiguration;
};
export const selectPayrollProcessing = (state: IStore) => state.clients.isPayrollProcessing;

export const selectAvailableClients = (state: IStore): ItemsById<IClient> => state.clients.clientsById;
export const selectAvailableClientsSortedAlphabetically = createSelectorSortedByNameFromById(selectAvailableClients);
export const selectAllClientsById = (state: IStore): ItemsById<IClient> => state.clients.allClientsById;
export const selectAllClientsSortedAlphabetically = createSelectorSortedByNameFromById(selectAllClientsById);
export const selectClientById = (clientId: string) => (state: IStore) => state.clients.clientsById[clientId];
export const selectCurrentClient = (state: IStore): IClientInfo => {
    const clientId = selectCurrentClientId(state);
    return state.clients.clientsById[clientId];
};
export const selectCurrentClientHasAssignments = (state: IStore): boolean => {
    const currentClientInfo = selectCurrentClient(state);
    return currentClientInfo?.has_assignments || false;
};

export const selectClientDisplayName = (state: IStore) =>
    selectClientById(selectCurrentClientId(state) as string)(state)?.name;

export const selectIsClientConfigurationUpdating = (state: IStore) => state.clients.isClientConfigurationUpdating;
