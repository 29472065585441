import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useScaZone } from 'shared/components/formSpecialFields/scaZone/hooks';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import { INamedEntity } from 'shared/models/Entity';
import clsx from 'clsx';
import { useScaZoneSeparatedStyles } from 'shared/components/formSpecialFields/scaZone/scaZoneSeparatedStyles';
import { IScaZoneFull } from 'shared/models/sheet/Sheet';
import { createScaZoneFull } from 'shared/components/formSpecialFields/scaZone/model';
import { inputParams } from 'shared/styles/constants';

interface IScaZoneSeparatedProps {
    label: string;
    assignmentId?: string;
    parentScaZoneId: number;
    customClasses?: string;
    onChange: (value: IScaZoneFull | null) => void;
    error?: string;
    value?: IScaZoneFull | null;
}

export function ScaZoneSeparatedCommon({
    label, assignmentId, parentScaZoneId, onChange, error, value, customClasses = '',
}: IScaZoneSeparatedProps) {
    const classes = useScaZoneSeparatedStyles();
    const {
        optionsList, onSetStateId, onSetCountyId, stateValue,
        scaZoneValue, scaZonesLoading, statesLoading, countyValue,
    } = useScaZone(
        parentScaZoneId,
        assignmentId,
        { },
        value?.state_id ?? null,
        value?.county_id ?? null,
    );
    const onChangeRef = useRef(onChange);

    const onStateChange = useCallback((_, newValue: INamedEntity | null) => {
        onSetStateId(newValue ? newValue.id : null);
    }, [onSetStateId]);

    const onCountyChange = useCallback((_, newValue: INamedEntity | null) => {
        onSetCountyId(newValue ? newValue.id : null);
    }, [onSetCountyId]);

    const stateAutocompleteValue = useMemo(
        () => stateValue ? optionsList.states.values.find(option => option.id === stateValue.id) : null,
        [stateValue, optionsList.states],
    );

    const countyAutocompleteValue = useMemo(
        () => countyValue ? optionsList.counties.values.find(option => option.id === countyValue.id) : null,
        [countyValue, optionsList.counties],
    );

    useEffect(() => {
        onChangeRef.current(createScaZoneFull(scaZoneValue, stateValue, countyValue));
    }, [onChangeRef, scaZoneValue, stateValue, countyValue]);

    const renderInput = useCallback((inputLabel: string, loading: boolean, params: any) => (
        <TextField {...params} label={inputLabel}
            inputProps={{
                ...params.inputProps,
                ...inputParams,
            }}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <React.Fragment>
                        {loading ? (
                            <CircularProgress
                                className={classes.loading}
                                color="primary"
                                size={24}
                            />
                        ) : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
            variant="outlined"
            error={!!error}/>
    ), [error, classes.loading]);

    const renderStatesInput = useMemo(
        () => renderInput.bind(null, optionsList.states.title, statesLoading),
        [renderInput, optionsList.states.title, statesLoading],
    );

    const renderCountiesInput = useMemo(
        () => renderInput.bind(null, optionsList.counties.title, scaZonesLoading),
        [renderInput, optionsList.counties.title, scaZonesLoading],
    );

    return (
        <section className={clsx(customClasses, classes.root)}>
            <Typography
                className={classes.label}
                variant="body1"
                component="label"
            >
                {label}
            </Typography>
            <div className={classes.inputsWrapper}>
                <Autocomplete
                    value={stateAutocompleteValue}
                    className={classes.stateInput}
                    options={optionsList.states.values}
                    loading={statesLoading}
                    getOptionLabel={option => option.name}
                    onChange={onStateChange}
                    renderInput={renderStatesInput}
                    filterOptions={(options, state) => options.filter(
                        option => option.name.toLowerCase().startsWith(state.inputValue.toLowerCase()),
                    )}
                />
                <Autocomplete
                    value={countyAutocompleteValue}
                    disabled={!stateValue}
                    className={classes.countyInput}
                    options={optionsList.counties.values}
                    loading={scaZonesLoading}
                    getOptionLabel={option => option.name}
                    onChange={onCountyChange}
                    renderInput={renderCountiesInput}
                    filterOptions={(options, state) => options.filter(
                        option => option.name.toLowerCase().startsWith(state.inputValue.toLowerCase()),
                    )}
                />
            </div>
        </section>
    );
}
