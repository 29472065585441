import React, { useMemo } from 'react';
import { EafApprovalStatusDisplayName } from 'modules/eafApproval/store/model/data';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import { EafStatusSlug } from 'modules/employeeActionsForm/store/models';
import { useStatusStyles } from 'shared/components/table/Cells/StatusStyles';
import StatusCell from 'shared/components/table/Cells/StatusCell';

export const EafApprovalGridCellStatus = ({
    className,
    status,
}: EafApprovalCellProps) => {
    const classes = useStatusStyles();
    const { slug } = status;
    const text = EafApprovalStatusDisplayName[slug];

    const statusClassName = useMemo(() => {
        const stylesMap: Record<EafStatusSlug, string> = {
            [EafStatusSlug.ApprovalRejected]: classes.red,
            [EafStatusSlug.PendingApproval]: classes.blue,
            [EafStatusSlug.Approved]: classes.lightGreen,
        };
        return stylesMap[slug];
    }, [classes.blue, classes.lightGreen, classes.red, slug]);

    return (
        <StatusCell
            text={text}
            className={className}
            statusClassName={statusClassName}
        />
    );
};
