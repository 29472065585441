import React from 'react';
import { Drawer } from '@material-ui/core';
import SidebarHeader from 'shared/components/sidebars/Sidebar/SidebarHeader';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import clsx from 'clsx';

export type Anchor = 'top' | 'left' | 'bottom' | 'right' ;

export interface ISidebarCommonProps {
    title: string;
    open: boolean;
    onBack?: () => void;
    onClose?: () => void;
    children: React.ReactNode;
    customClasses?: string;
    titleClasses?: Partial<{
        root: string;
        text: string;
        icon: string;
    }>;
    anchor?: Anchor;
    whiteTitle?: boolean;
}

export default function SidebarCommon({
    title,
    open,
    onBack,
    onClose,
    children,
    customClasses = '',
    titleClasses = {},
    whiteTitle = false,
    anchor = 'right',
    ...drawerProps
}: ISidebarCommonProps) {
    const classes = useSidebarStyles();

    return (
        <Drawer
            {...drawerProps}
            classes={{ root: classes.root, paper: clsx(classes.wrapper, customClasses) }}
            anchor={anchor}
            open={open}
        >
            <SidebarHeader
                onBack={onBack}
                title={title}
                onClose={onClose}
                white={whiteTitle}
                titleClasses={titleClasses}
            />
            {children}
        </Drawer>
    );
}
