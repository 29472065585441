import { useBulkPostImportCreateCcpOverviewItems } from './store/hooks';
import { useSelector } from 'react-redux';
import { selectIsLoadingImport } from './store/selectors';
import { BulkCcpImportReport } from './BulkCcpImportReport';
import React from 'react';

interface ICcpCreationPostImportReportProps {
    onNextClick: () => void;
}

export const CcpPostCreateReport = ({
    onNextClick,
}: ICcpCreationPostImportReportProps) => {
    const overviewItems = useBulkPostImportCreateCcpOverviewItems();
    const isLoading = useSelector(selectIsLoadingImport);
    return (
        <BulkCcpImportReport
            handleAction={onNextClick}
            isLoading={isLoading}
            title="Post-Initialize Roll Up"
            buttonTitle="OK"
            overviewItems={overviewItems}
        />
    );
};
