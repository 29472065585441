import clsx from 'clsx';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';

export function makeStickyCellCustomizer(isStickyCellsEnabled = true) {
    const withStickyStyles = (
        cell: ICellInfo<any>,
        headerCellClassName: string,
        bodyCellClassName: string,
    ) => ({
        ...cell,
        headerClassName: clsx({ [headerCellClassName]: isStickyCellsEnabled }, cell.headerClassName),
        cellClassName: clsx({ [bodyCellClassName]: isStickyCellsEnabled }, cell.cellClassName),
    });

    return withStickyStyles;
}
