import { Button, Typography } from '@material-ui/core';
import { useIsEmployeeSheetGroupingEnabled } from 'modules/timeAndExpense/store/hooks';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecallStyles } from 'shared/components/sheetsSubmitted/filterAndActionControls/RecallStyles';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { EntryType } from 'shared/models/sheet/Sheet';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { SheetsEntryTypes, StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllSheets } from 'store/entities/timesheet/selectors';
import { useDisallowPastDayEntry } from '../../../../store/entities/clients/hooks';

interface IRecallSidebarProps {
    onClose: () => void;
    sheetsEntryTypes: SheetsEntryTypes;
    onAction?: () => void;
}

export function RecallSidebar({ onClose, sheetsEntryTypes: sheetsEntryTypesProps, onAction }: IRecallSidebarProps) {
    const classes = useRecallStyles();
    const dispatch = useDispatch();
    const enableGrouping = useIsEmployeeSheetGroupingEnabled();
    const disallowPastDayEntry = useDisallowPastDayEntry();
    const sheets = useSelector(selectAllSheets);
    const sheetsEntryTypes = useMemo((): SheetsEntryTypes => {
        if (!enableGrouping) {
            return sheetsEntryTypesProps;
        }
        // Recall all submitted sheets for period
        const sheetsToRecall = sheets.filter(sheet => sheetsEntryTypesProps[sheet.id]);
        return sheets.filter(sheet => sheetsToRecall.find(recalledSheet =>
            recalledSheet.entry_type === sheet.entry_type
            && recalledSheet.status.id === sheet.status.id
            && recalledSheet.period_start === sheet.period_start
            && recalledSheet.period_end === sheet.period_end),
        ).reduce((mem, sheet) => ({
            ...mem,
            [sheet.id]: sheet.entry_type,
        }), {} as SheetsEntryTypes);
    }, [sheetsEntryTypesProps, enableGrouping, sheets]);

    const onRecall = useCallback(() => {
        dispatch(updateSheetsStatus.init({
            statusName: StatusNames.RECALLED,
            sheetsEntryTypes,
        }));

        onClose();

        if (onAction) {
            onAction();
        }
    }, [sheetsEntryTypes, onClose, onAction, dispatch]);

    const sheetsEntryTypesValues = Object.values(sheetsEntryTypes);

    const amount = sheetsEntryTypesValues.length;
    const hasTime = sheetsEntryTypesValues.some(entryTypes => entryTypes.includes(EntryType.TIME));
    const hasExpense = sheetsEntryTypesValues.some(entryTypes => entryTypes.includes(EntryType.EXPENSE));

    let sheetsTitle = '';

    if (hasTime && hasExpense) {
        sheetsTitle = 'time and expense sheet';
    } else if (hasTime) {
        sheetsTitle = 'timesheet';
    } else if (hasExpense) {
        sheetsTitle = 'expense sheet';
    }

    const haveHas = pluralize('has', amount, 'have');
    const sheetTitlePluralized = pluralize(sheetsTitle, amount);

    return (
        <Sidebar title={`Recall ${sheetsTitle}s`} customClasses={classes.paper}
            isOpened
            onClose={onClose}
        >
            <section className={classes.sidebarSection}>
                <Typography className={classes.infoText} variant="body1">
                    This action is if you have submitted {pluralize('a', amount, '')} {sheetTitlePluralized} that {haveHas} <b>NOT</b> been approved
                    by your supervisor and you need to make an edit.
                </Typography>
                <Typography className={classes.infoText} variant="body1">
                    A total of {amount} {sheetTitlePluralized} {haveHas} been selected to recall.
                    This will revert your submission and you will need to re-submit after your edits.
                </Typography>
            </section>
            <section className={classes.sidebarSection}>
                {!disallowPastDayEntry && (
                    <Button color="primary"
                        variant="contained" onClick={onRecall }
                    >
                        Recall
                    </Button>
                )}
                <Button className={classes.secondaryButton} color="primary"
                    variant="text" onClick={onClose}
                >
                    Cancel
                </Button>
            </section>
        </Sidebar>
    );

}
