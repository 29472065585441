import baseApi from 'shared/utils/baseApi';
import { IDepartment, IDepartmentRequest } from '../../models/Department';
import { withClientId } from 'store/utils/withClientId';

const servicePath = 'client-users';
const departmentPath = 'departments';

export const departmentApi = {
    async getAll(request: IDepartmentRequest): Promise<IDepartment[]> {
        const { data } = await baseApi.get<{ departments: IDepartment[] }>(
            `${servicePath}/${departmentPath}`, withClientId(request),
        );
        return data.departments;
    },
};
