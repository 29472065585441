import { uniq } from 'lodash-es';
import { Nullable } from '../../../../../types/types';

export function findUniqFilteredItems<TItem>(
    items: Nullable<Array<TItem>>,
    prop: keyof TItem,
): Array<TItem[keyof TItem]> {
    const selectedItems = (
        (items || [])
            .filter(Boolean)
            .map(item => item[prop])
            .filter(Boolean)
    );
    return uniq(selectedItems);
}
