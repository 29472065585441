import React from 'react';
import clsx from 'clsx';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { TimeAndMoneyCell } from '../components/cells/abstract/TimeAndMoneyCell';
import { TableClasses } from '../model';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type OvertimeHoursCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export function makeOvertimeHoursCell(tableClasses: TableClasses) {
    const OvertimeHoursCell = ({ className, time_ot_hours, time_ot_dollars }: OvertimeHoursCellProps) => (
        <TimeAndMoneyCell
            className={clsx(className, tableClasses.pullRight)}
            time={time_ot_hours}
            money={time_ot_dollars}
        />
    );

    return OvertimeHoursCell;
}
