import { call, select, put, takeLatest } from 'typed-redux-saga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { syncClientPaySettings } from 'modules/settings/submodules/clients/paySettings/components/SyncPaySettings/store/actions';
import { getPaySettings } from 'store/entities/clients/clientsAction';
import { api } from 'modules/settings/submodules/clients/paySettings/components/SyncPaySettings/store/api';
import baseApi from 'shared/utils/baseApi';
import { selectClientById } from 'store/entities/clients/selectors/clientsSelectors';

function* syncClientPaySettingsSaga() {
    const client = yield* select(selectClientById(baseApi.clientId || ''));
    const paySettings = yield* call(api.syncPaySettings, client?.prism_client_id || '');
    yield put(getPaySettings.success(paySettings));
    yield put(syncClientPaySettings.success());
}

export function* syncClientPaySettingsWatcher() {
    yield* takeLatest(
        syncClientPaySettings.initType,
        withBackendErrorHandler(
            syncClientPaySettingsSaga,
            syncClientPaySettings.error,
            'Unable to sync Pay Settings',
        ),
    );
}
