import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import CreateOfferSidebar from 'modules/offerLetter/components/CreateOffer/CreateOfferSidebar';
import { selectCreateSidebarOpened } from 'modules/offerLetter/components/CreateOffer/store/selectors';
import LookupResult from 'modules/offerLetter/components/lookup/LookupResult';
import { useSidebarStyles } from 'modules/offerLetter/components/lookup/useSidebarStyles';
import { setCreateSidebarOpened } from 'modules/offerLetter/store/actions';
import { selectLookupEmployee, selectLookupIsLoading } from 'modules/offerLetter/components/lookup/data/selectors';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import SidebarCommon from 'shared/components/sidebars/Sidebar/SidebarCommon';

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    inviteButton: {
        width: '100%',
        padding: theme.spacing(2),
        bottom: 0,
    },
    tableContainer: {
        minHeight: `calc(100% - 56px)`,
    },
    sidebarContent: {
        position: 'relative',
        minHeight: `calc(100% - 65px)`,
    },
}));

export interface ILookupResultsMobileProps {
    onClose: () => void;
    onBack: () => void;
    open: boolean;
}

const LookupResultsMobile = ({ open, onClose, onBack }: ILookupResultsMobileProps) => {
    const classes = useStyles();
    const sidebarClasses = useSidebarStyles();
    const title = 'Employee look up';
    const employees = useSelector(selectLookupEmployee);
    const isLoading = useSelector(selectLookupIsLoading);
    const createSidebarOpened = useSelector(selectCreateSidebarOpened);
    const dispatch = useDispatch();
    const setCreateSidebarOpen = useCallback((state: boolean) => {
        dispatch(setCreateSidebarOpened(state));
    }, [dispatch]);
    const onCloseCreateSidebar = useCallback(() => {
        setCreateSidebarOpen(false);
        onClose();
    }, [onClose, setCreateSidebarOpen]);

    return (
        <SidebarCommon
            open={open}
            title={title}
            onBack={onBack}
            onClose={onClose}
            titleClasses={{
                root: sidebarClasses.sidebarTitle,
            }}
        >
            {isLoading ? (
                <Box
                    mt={5}
                    display="flex"
                    justifyContent="center"
                >
                    <CircularProgress color="inherit"/>
                </Box>
            ) : (
                <Box className={classes.sidebarContent}>
                    {employees !== null && (
                        <LookupResult
                            employees={employees}
                            onInvite={() => setCreateSidebarOpen(true)}
                            classes={classes}
                        />
                    )}
                </Box>
            )}
            <CreateOfferSidebar
                open={createSidebarOpened}
                onBack={() => setCreateSidebarOpen(false)}
                onClose={onCloseCreateSidebar}
            />
        </SidebarCommon>
    );
};

export default LookupResultsMobile;
