import { ICcpTransaction } from 'modules/ccp/models/CcpTransaction';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectCcpTransactionsState } from '../../../store/selectors';
import { selectCcpTransactionsById } from '../../CcpTransactionsTable/store/selectors';
import { groupBy } from 'lodash-es';

export const selectEditCcpState = (state: IStore) => {
    return selectCcpTransactionsState(state).editCcpTransactions;
};

export const selectOpenedTransactionId = (state: IStore) =>
    selectEditCcpState(state).noteSideBarSelectedTransactionId;

export const selectOpenedReceiptTransactionId = (state: IStore) =>
    selectEditCcpState(state).receiptSideBarSelectedTransactionId;

export const selectIsOpenCcpEditNoteSidebar = (state: IStore) => {
    const currentSelectedId = selectEditCcpState(state).noteSideBarSelectedTransactionId;
    return !!currentSelectedId;
};

export const selectIsOpenCcpReceiptsSidebar = (state: IStore) => {
    const currentSelectedId = selectEditCcpState(state).receiptSideBarSelectedTransactionId;
    return !!currentSelectedId;
};

export const selectIsCcpSubmitting = (state: IStore) =>
    selectEditCcpState(state).isCcpSubmitting;

export const selectAttachmentFileUploading = (state: IStore) =>
    selectEditCcpState(state).isCcpAttachmentUploading;

export const userIdsAvailable = (state: IStore) =>
    selectEditCcpState(state).availableUserIds;

export const allUserIdsAvailable = (state: IStore) =>
    selectEditCcpState(state).allAvailableUserIds;

export const selectAttachmentFileDeleting = (state: IStore) =>
    selectEditCcpState(state).isCcpAttachmentDeleting;

export const selectIsOpenSubmitModal = (state: IStore) =>
    selectEditCcpState(state).isOpenBulkCcpCreationUploadModal;

export const selectCcpSubmitModalStep = (state: IStore) =>
    selectEditCcpState(state).ccpSubmitStep;

export const selectCcpSubmitConfirmed = (state: IStore) =>
    selectEditCcpState(state).isConfirmedCcpSubmit;

export const selectPostCcpSubmitReport = (state: IStore) =>
    selectEditCcpState(state).postReport;

export const selectCcpDirtyTransactions = createSelector(
    selectCcpTransactionsById,
    (ccpByIds): ICcpTransaction[] => {
        // @ts-ignore
        return Object.values(ccpByIds).filter(tr => tr.is_dirty && !tr.is_unsaved_split);
    },
);

export const selectCcpChildTransactionsById = createSelector(
    selectCcpTransactionsById,
    ccpByIds => {
        // @ts-ignore
        const unsavedTransactions = Object.values(ccpByIds).filter(tr => tr.parent_transaction_id);
        return groupBy(unsavedTransactions, 'parent_transaction_id');
    },
);

export const selectCcpUnsavedChildTransactionsById = createSelector(
    selectCcpTransactionsById,
    ccpByIds => {
        // @ts-ignore
        const unsavedTransactions = Object.values(ccpByIds).filter(tr => tr.is_unsaved_split);
        return groupBy(unsavedTransactions, 'parent_transaction_id');
    },
);
