import React, { useCallback, useEffect } from 'react';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash-es';

import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import { debounce } from 'ts-debounce';

import { ITravelExpenseFilters } from 'modules/travelExpenses/components/TravelExpenseFilter/model';
import { DealTypeSelect } from 'modules/settings/submodules/clients/deals/components/DealTypeSelect';
import { travelExpenseFilterDefaultValues } from 'modules/travelExpenses/components/TravelExpenseFilter/reducer';
import { useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { TravelExpenseTypeSelect } from 'modules/travelExpenses/components/TravelExpenseFilter/TravelExpenseTypeSelect';
import { useFiltersStyles } from 'shared/styles/filtersStyles';
import { UserType } from 'store/entities/users/model';
import { IDealType } from 'shared/models/DealType';

export interface ITravelExpenseFilterFormProps extends FormikProps<ITravelExpenseFilters> {
    onChange: (value: Partial<ITravelExpenseFilters>) => void;
    actualFilterValues: ITravelExpenseFilters;
}

export const TravelExpenseFilterForm = ({
    actualFilterValues,
    handleSubmit,
    onChange,
    values,
}: ITravelExpenseFilterFormProps) => {
    const classes = useFiltersStyles();
    const currentClientId = useSelector(selectCurrentClientId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFilterChange = useCallback(debounce(
        (newValues: ITravelExpenseFilters) => {
            onChange({
                employeeId: newValues.employeeId || travelExpenseFilterDefaultValues.employeeId,
                dealTypeId: newValues.dealTypeId || travelExpenseFilterDefaultValues.dealTypeId,
                expenseTypeId: newValues.expenseTypeId || travelExpenseFilterDefaultValues.expenseTypeId,
            });
        },
        300,
    ), [onChange]);

    useEffect(() => {
        if (!isEqual(values, actualFilterValues)) {
            onFilterChange(values);
        }
    }, [actualFilterValues, values, onChange, onFilterChange]);

    const dealTypeFilter = useCallback((dealType: IDealType) => {
        return Boolean(dealType.billing_type);
    }, []);

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <UserAutocompleteWithSearch
                additionalFilter={{
                    client_id: currentClientId || undefined,
                    user_type: UserType.Employee,
                }}
                name="employeeId"
                label="Find an employee"
                useIdValue
                className={classes.input}
            />
            <DealTypeSelect
                name="dealTypeId"
                label="Deal Type"
                useIdValue
                filter={dealTypeFilter}
                className={classes.input}
            />
            <TravelExpenseTypeSelect
                name="expenseTypeId"
                label="Expense Type"
                useIdValue
                className={classes.input}
            />
        </form>
    );
};
