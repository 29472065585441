import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash-es';
import { IUserInfo } from 'shared/models/User';
import { useSubassignmentApproversInitialState } from '../../../hooks/useSubassignmentApproversInitialState';
import { selectUsersById } from 'store/entities/users/selectors';
import { ISubassignment } from 'store/entities/configuration/configurationModel';

export function useSubassignmentApprovers(subassignment?: ISubassignment, numberOfApprovers = 1) {
    const usersById = useSelector(selectUsersById);
    const initialState = useSubassignmentApproversInitialState(numberOfApprovers);

    const subassignmentApprovers = useMemo(() => {
        if (!subassignment) {
            return initialState;
        }

        const managers = orderBy(subassignment.managers || [], 'manager_level', 'asc');
        const entries = (
            managers
                .map(manager => manager.user_id)
                .map(managerId => usersById[managerId])
                .map((managerUser: IUserInfo, index: number) => [index, managerUser])
        );
        const actualState = Object.fromEntries(entries);
        return {
            approvers: {
                ...initialState.approvers,
                ...actualState,
            },
        };
    }, [initialState, subassignment, usersById]);

    return subassignmentApprovers;
}
