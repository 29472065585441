import React from 'react';
import { useSelector } from 'react-redux';
import { autocompleteSearchDeal } from 'shared/components/autocomplete/deals/actions';
import { EntityAutocompleteWithSearch } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/EntityAutocompleteWithSearch';
import { IFormSelect } from 'shared/components/selects/model';
import { IDeal } from 'shared/models/Deal';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IDealRequest } from 'store/entities/configuration/configurationModel';
import { selectDealsById } from 'store/entities/configuration/configurationSelectors';

export interface IDealAutocompleteWithSearchProps extends IFormFieldProps, IFormSelect {
    additionalFilter?: Partial<IDealRequest>;
    initialSearch?: Partial<IDealRequest>;
    minLettersSearch?: number;
    placeholder?: string;
}

export const DealAutocompleteWithSearch = ({ minLettersSearch = 0, ...props }: IDealAutocompleteWithSearchProps) => {
    const dealsByIds = useSelector(selectDealsById);

    return (
        <EntityAutocompleteWithSearch
            {...props}
            entitiesByIds={dealsByIds}
            searchAction={autocompleteSearchDeal}
            getText={(deal: IDeal) => deal.deal_number}
            minLettersSearch={minLettersSearch}
        />
    );
};
