import React from 'react';
import { useSelector } from 'react-redux';
import { getLastFirstName } from 'shared/utils/converters/user';
import { formatPayRate } from 'shared/utils/formatters/payRate';
import { getFieldValueName } from 'store/entities/customFields/helpers';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { selectOfferLetter } from 'modules/offerLetter/store/selectors';
import { RejectCard } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectCard';

interface IOfferRejectionCardProps {
    offerLetterId: string;
    inputValue: string;
    onChange: (value: string) => void;
}

export const OfferRejectionCard = ({ offerLetterId, inputValue, onChange }: IOfferRejectionCardProps) => {
    const offerLetter = useSelector(selectOfferLetter(offerLetterId));
    const customFieldValueByIds = useSelector(selectCustomFieldValuesByIds);

    const employeeFullName = getLastFirstName(offerLetter);
    const employeePosition = getFieldValueName(customFieldValueByIds[offerLetter.position_value_id]);
    const employeeLocation = getFieldValueName(customFieldValueByIds[offerLetter.location_value_id]);
    const payRate = formatPayRate(offerLetter, '');

    return (
        <RejectCard
            onChange={({ target }) => onChange(target.value)}
            employeeFullName={employeeFullName}
            employeePosition={employeePosition}
            employeeLocation={employeeLocation}
            payRate={payRate}
            inputValue={inputValue}
        />
    );
};
