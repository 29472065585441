import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useDayOfWeekDateStyles = makeHighPriorityStyles((theme: Theme) => ({
    dayOfWeekDateBox: {
        backgroundColor: colors.lightGray,
        display: 'flex',
        minWidth: theme.spacing(8),
        justifyContent: 'center',
        alignItems: 'baseline',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    label: {
        letterSpacing: 2,
        textTransform: 'uppercase',
    },
    weekday: {
        fontWeight: 500,
    },
    date: {
        fontSize: 12,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),

        [theme.breakpoints.down('xs')]: {
            margin: 0,
        },
    },
    dayOfMonthBox: {
        display: 'flex',
        justifyContent: 'space-around',
    },
}));
