import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { setHeaderContent } from 'modules/home/header/Header';
import OfferLetterTabs from 'modules/offerLetter/components/OfferLetterTabs';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { getSpecialUsersAction } from 'store/entities/users/actions';
import { Permission } from 'store/components/auth/authModels';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';

interface IOfferLetterLayout {
    children?: React.ReactChild;
}

export const OfferLetterLayout = ({ children }: IOfferLetterLayout) => {
    useTitleUpdate('Offer Letter');
    useEffect(() => {
        setHeaderContent(
            <Box display="flex" alignItems="flex-end">
                <OfferLetterTabs/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, []);
    useWithClientEffect((dispatch, clientId) => {
        dispatch(getSpecialUsersAction.init({
            purpose: Permission.ApproveSheets,
            client_id: clientId || undefined,
        }));
    });

    return children;
};
