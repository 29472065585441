import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDayOfWeekDateStyles } from './dayOfWeekDateStyles';
import { monthNamesShort } from 'shared/models/Dates';
import moment, { Moment } from 'moment';
import clsx from 'clsx';

interface IDayOfWeekDateProps {
    date: Moment;
    customClasses?: string;
}

export default function DayOfWeekDate({ date, customClasses = '' }: IDayOfWeekDateProps) {
    const classes = useDayOfWeekDateStyles();

    return (
        <Box className={clsx(classes.dayOfWeekDateBox, customClasses)}>
            <Typography variant="body1" classes={{ root: clsx(classes.label, classes.weekday) }}>
                {moment.weekdaysShort(true, date.weekday())}
            </Typography>
            <Typography classes={{ root: clsx(classes.label, classes.date) }}>
                {monthNamesShort[date.month()]} {date.date()}
            </Typography>
        </Box>
    );
}
