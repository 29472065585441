import { IAttachment } from 'shared/models/Attachments';
import { ICreatedUpdated, IEntity } from 'shared/models/Entity';
import { IStatusEntity } from 'shared/models/Status';
import { IManagerInfo } from 'store/entities/configuration/configurationModel';

export enum EafType {
    AssignmentEnded = 'assignment_ended',
    Termination = 'termination',
    AssignmentStarted = 'assignment_started',
    CompensationChange = 'compensation_change',
    Resignation = 'resignation',
    Promotion = 'promotion',
    Demotion = 'demotion',
    StartDateChange = 'start_date_change',
    EndDateChange = 'end_date_change',
    ApproverChange = 'approver_change',
    TransferLocation = 'transfer_location',
}

export const eafTypeDisplayValues: Record<EafType, string> = {
    [EafType.AssignmentEnded]: 'Assignment Ended',
    [EafType.Termination]: 'Termination',
    [EafType.AssignmentStarted]: 'Assignment Started',
    [EafType.CompensationChange]: 'Compensation Change',
    [EafType.Resignation]: 'Resignation',
    [EafType.Promotion]: 'Promotion',
    [EafType.Demotion]: 'Demotion',
    [EafType.StartDateChange]: 'Change Start Date',
    [EafType.EndDateChange]: 'Change End Date',
    [EafType.ApproverChange]: 'Approver Change',
    [EafType.TransferLocation]: 'Transfer Location',
};

export enum EafReasonKey {
    LeftForAnotherJobRehireEligible = 'left_for_another_job_rehire_eligible',
    LeftForAnotherJobRehireNotEligible = 'left_for_another_job_rehire_not_eligible',
    PayRateIssueRehireEligible = 'pay_rate_issue_rehire_eligible',
    PayRateIssueRehireNotEligible = 'pay_rate_issue_rehire_not_eligible',
    PersonalReasonsRehireEligible = 'personal_reasons_rehire_eligible',
    PersonalReasonsRehireNotEligible = 'personal_reasons_rehire_not_eligible',
    DissatisfiedRehireEligible = 'dissatisfied_rehire_eligible',
    DissatisfiedRehireNotEligible = 'dissatisfied_rehire_not_eligible',
    RehireEligible = 'rehire_eligible',
    RehireNotEligible = 'rehire_not_eligible',
    Floating = 'floating',
    Correction = 'correction',
}

export enum EafStatusSlug {
    Approved = 'approved',
    PendingApproval = 'pending_approval',
    ApprovalRejected = 'approval_rejected',
}

export interface IEafStatus extends IStatusEntity<EafStatusSlug> {
}

export interface IEafDataCommon {
    effective_date: string;
    is_retro: boolean;
    employee_action_form_type: EafType;
}

export interface IEafAssignmentStartData extends IEafDataCommon {
    employee_action_form_type: EafType.AssignmentStarted;
    employee_action_form_reason: EafReasonKey;
}

export interface IEafAssignmentEndData extends IEafDataCommon {
    employee_action_form_type: EafType.AssignmentEnded;
    eligible_for_rehire: boolean;
    employee_action_form_reason?: EafReasonKey;
    do_not_send_employee_email?: boolean;
}

export interface IChangePayRateData {
    old_base_pay_rate: number;
    new_base_pay_rate: number;
}

export interface IEafCompensationChangeData extends IEafDataCommon, IChangePayRateData {
    employee_action_form_type: EafType.CompensationChange;
    employee_action_form_reason: EafReasonKey;
}

export interface IChangedPositionData {
    old_position_custom_field_value_id: string;
    new_position_custom_field_value_id: string;
}

export interface IEafPromotionDemotion extends IEafDataCommon, IChangedPositionData, IChangePayRateData {
}

export interface IEafPromotionData extends IEafPromotionDemotion {
    employee_action_form_type: EafType.Promotion;
}

export interface IEafDemotionData extends IEafPromotionDemotion {
    employee_action_form_type: EafType.Demotion;
}

export interface IEafTransferLocationData extends IEafDataCommon {
    employee_action_form_type: EafType.TransferLocation;
    old_location_custom_field_value_id: string;
    new_location_custom_field_value_id: string;
}

export interface IEafApproverChangeData extends IEafDataCommon {
    employee_action_form_type: EafType.ApproverChange;
    old_managers: IManagerInfo[];
    new_managers: IManagerInfo[];
}

export interface IEafStartDateChangeData extends Omit<IEafDataCommon, 'effective_date'> {
    employee_action_form_type: EafType.StartDateChange;
    applies_to_subassignments: boolean;
    old_start_date?: string;
    new_start_date: string;
}

export interface IEafEndDateChangeData extends Omit<IEafDataCommon, 'effective_date'> {
    employee_action_form_type: EafType.EndDateChange;
    old_end_date?: string;
    new_end_date: string | null;
}

export type EmployeeActionFormData =
    | IEafAssignmentStartData
    | IEafAssignmentEndData
    | IEafPromotionData
    | IEafDemotionData
    | IEafStartDateChangeData
    | IEafEndDateChangeData
    | IEafTransferLocationData
    | IEafApproverChangeData
    | IEafCompensationChangeData;

export interface IEmployeeActionForm extends IEntity, ICreatedUpdated {
    client_id: string;
    assignment_id: string;
    subassignment_id: string | null;
    employee_action_form_data: EmployeeActionFormData;
    status: IEafStatus;
    approval_rejection_reason: string | null;
    created_by: string;
    employee_user_id?: string;
    attachments: IAttachment[];
}

export interface IEafReason {
    key: EafReasonKey;
    employee_action_form_types: EafType[];
    text: string;
}

export interface IGroupedEmployeeActionForms {
    assignment_id: string;
    employee_action_forms: IEmployeeActionForm[];
}

export interface IEafRequest {
    client_id: string;

    position_value_id?: string;
    department_value_id?: string;
    location_value_id?: string;

    approver_user_id?: string;
    employee_user_id?: string;
    start_date?: string;
    end_date?: string;

    cursor: string | null;
    page_size: number;
}

export interface IEafGroupedResponse {
    total_items: number;
    page_size: number;
    next_cursor: string | null;
    items: IGroupedEmployeeActionForms;
}

export interface IGroupedEafPagePayload {
    assignments: IGroupedEmployeeActionForms[];
    total: number;
    cursor: string;
}

export interface ICreateEaf {
    assignment_id: string;
    subassignment_id?: string;
    employee_action_form_data: EmployeeActionFormData;
}

export interface IGetMaxEntryDateResponse {
    max_entry_date: string | null;
}

export interface IGetMaxEntryDatePayload extends IGetMaxEntryDateResponse {
    assignmentId: string;
}
