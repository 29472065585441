import React from 'react';
import { Box } from '@material-ui/core';
import { OpenModalButton } from 'modules/settings/common/components/OpenModalButton/OpenModalButton';
import { setOpenAssignmentMetaDataCreateModal } from 'modules/settings/submodules/clients/assignmentsReportData/store/actions';
import { AssignmentsReportDataList } from 'modules/settings/submodules/clients/assignmentsReportData/components/AssignmentsReportDataList/AssignmentsReportDataList';
import { EditAssignmentsReportDataModal } from './components/EditAssignmentsReportData/EditAssignmentsReportDataModal';
import { CreateAssignmentReportMetaDataModal } from 'modules/settings/submodules/clients/assignmentsReportData/components/CreateAssignmentReportMetaData/CreateAssignmentReportMetaDataModal';

export function AssignmentsReportDataPage() {
    return (
        <>
            <Box mb={2}>
                <OpenModalButton openAction={setOpenAssignmentMetaDataCreateModal}/>
            </Box>
            <AssignmentsReportDataList/>
            <EditAssignmentsReportDataModal/>
            <CreateAssignmentReportMetaDataModal/>
        </>
    );
}
