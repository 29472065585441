import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';
import { makeStyles } from '@material-ui/core';

export const useModalStyles = makeHighPriorityStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
        },
        paper: {
            maxWidth: 'unset',
            backgroundColor: colors.whiteSecondary,
            boxShadow: theme.shadows[5],
        },
        title: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
        },
        closeIcon: {
            position: 'absolute',
            top: '50%',
            right: theme.spacing(0.5),
            transform: 'translateY(-50%)',
        },
        content: {
            padding: 0,
            margin: 0,
        },
    }),
);

export const useColoredFormModalStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
    },
    paper: {
        minWidth: theme.spacing(100),
        maxWidth: 'unset',
        backgroundColor: `${colors.white} !important`,
        boxShadow: theme.shadows[5],
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-start !important',
        position: 'relative',
        backgroundColor: theme.palette.secondary.main,
        color: colors.white,
        fontSize: 18,
    },
    closeIcon: {
        position: 'absolute',
        top: '50%',
        right: theme.spacing(0.5),
        transform: 'translateY(-50%)',
        color: colors.white,
    },
    content: {
        '&&': {
            padding: theme.spacing(3),
            margin: 0,
        },
    },
}));
