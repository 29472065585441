import React, { useMemo } from 'react';
import moment from 'moment';
import { FormikProvider, useFormik } from 'formik';
import { Box, Button } from '@material-ui/core';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';
import { IEmployeeActionFormDataModel } from '../EndAssignment/EndAssignmentForm';

export interface IAssignmentEditDateFormProps {
    isLoading: boolean,
    onCancel: () => void,
    relatedSubassignments?: ISubassignment[];
    relatedAssignments?: IAssignment[],
    assignmentMaxEntryDate?: string;
    assignment?: IAssignment;
    initialValues: IEmployeeActionFormDataModel,
    onSubmit: (values: IEmployeeActionFormDataModel) => void,
}

export const ReopenAssignmentForm = ({
    isLoading,
    onCancel,
    relatedSubassignments,
    relatedAssignments,
    assignment,
    initialValues,
    onSubmit,
}: IAssignmentEditDateFormProps) => {
    const formClasses = sidebarFormStyles();

    const formikData = useFormik<IEmployeeActionFormDataModel>({
        initialValues,
        validateOnBlur: true,
        onSubmit,
    });
    const { handleSubmit } = formikData;

    const maxEffectiveDate = useMemo(() => {
        if (relatedAssignments && relatedAssignments.length > 0) {
            const assignmentEndDate = moment(assignment?.end_date);
            const assignmentsAfterEndDate = relatedAssignments
                .filter(x => moment(x.hire_date).isAfter(assignmentEndDate));
            if (assignmentsAfterEndDate.length > 0) {
                const minNextAssignmentDate = moment
                    .min(relatedAssignments.map(x => moment(x.hire_date))).add(-1, 'day');
                return minNextAssignmentDate;
            }
        }
        return null;
    }, [assignment?.end_date, relatedAssignments]);

    const countSubassignmentToEdit = useMemo(() => {
        const assignmentEndDate = assignment?.end_date;
        return relatedSubassignments?.filter(subasssignment => {
            return assignmentEndDate === subasssignment.end_date;
        }).length || 0;
    }, [assignment?.end_date, relatedSubassignments]);

    return (
        <FormikProvider value={formikData}>
            <form
                onSubmit={handleSubmit}
                className={formClasses.form}
            >
                {countSubassignmentToEdit !== 0 && (
                    <Box mb={2}>
                        <WarningAlertWithIcon>
                            Are you sure you want to re-open the main assignment?
                            {/* eslint-disable-next-line max-len */}
                            This will remove the end date and only the most recent {countSubassignmentToEdit} sub-assignments will be active
                        </WarningAlertWithIcon>
                    </Box>
                )}
                {maxEffectiveDate && (
                    <Box mb={2}>
                        <WarningAlertWithIcon>
                            There is another assignment with the hire date after this assignment hire date.
                            {/* eslint-disable-next-line max-len */}
                            It is impossible to remove end date fot this assignment.
                        </WarningAlertWithIcon>
                    </Box>
                )}
                <Box
                    display="flex"
                    alignItems="baseline"
                >
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={formClasses.button}
                        isLoading={isLoading}
                        data-testid="submit_button"
                        disabled={!!maxEffectiveDate}
                    >
                        Confirm
                    </ButtonWithLoader>
                    <Button
                        disabled={isLoading}
                        onClick={onCancel}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Box>
            </form>
        </FormikProvider>
    );
};
