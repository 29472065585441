import { dealsTable } from 'modules/settings/submodules/clients/deals/components/DealsTable/store/reducers';
import { combineReducers } from 'redux';
import { dealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/store/reducer';
import { createDeal } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/reducer';
import { editDeal } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/reducer';
import {
    invoiceRecipients,
    invoiceRecipientsIsLoading,
} from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/reducer';
import { isDealStatusUpdating } from 'modules/settings/submodules/clients/deals/components/UpdateStatusSelect/store/reducer';
import { dealForm } from 'modules/settings/submodules/clients/deals/components/DealForm/store/reducer';

export const deals = combineReducers({
    createDeal,
    editDeal,
    dealForm,
    dealFilter,
    invoiceRecipients,
    invoiceRecipientsIsLoading,
    isDealStatusUpdating,
    dealsTable,
});
