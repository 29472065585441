import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { GlobalRoles } from 'store/entities/users/model';

interface ICorpTypeProps extends IFormFieldProps, IFormSelect {}

export default function ClientContactTitleSelect({
    ...props
}: ICorpTypeProps) {
    const titles = [GlobalRoles.PayrollCoordinator];

    return (
        <FormSelect
            {...props}
            getKey={(title: string) => title}
            getText={(title: string) => title}
            options={titles}
        />
    );
}
