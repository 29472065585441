import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useEditableInfoStyles = makeHighPriorityStyles(theme => ({
    infoItem: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        lineHeight: `${theme.spacing(2.5)}px`,
        marginBottom: theme.spacing(0.5),
        paddingRight: `${theme.spacing(2)}px !important`,
        '& h6': {
            minWidth: '120px',
        },
        '& p': {
            wordBreak: 'break-all',
            whiteSpace: 'pre-wrap',
            display: 'flex',
            flexGrow: 1,
        },
        '& > *': {
            fontSize: 14,
            letterSpacing: 'normal',
            maxWidth: '100%',

            [theme.breakpoints.up('sm')]: {
                '& + *': {
                    marginLeft: theme.spacing(0.5),
                },
            },
        },

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    title: {
        whiteSpace: 'nowrap',
    },
    button: {
        padding: 0,
    },
}));
