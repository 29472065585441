import React from 'react';
import { IconButtonProps, CircularProgress, IconButton } from '@material-ui/core';

// @ts-ignore
interface IIconButtonWithLoaderProps extends IconButtonProps {
    children: string | JSX.Element;
    isLoading: boolean;
    size?: number | 'small' | 'medium';
}

export default function IconButtonWithLoader({
    children,
    isLoading,
    disabled,
    size = 24,
    ...props
}: IIconButtonWithLoaderProps) {
    return (
        <IconButton
            disabled={isLoading || disabled}
            {...props}
        >
            {isLoading ? (
                <CircularProgress
                    color="inherit"
                    size={size}
                />
            ) : children}
        </IconButton>
    );
}
