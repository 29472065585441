import { OverrideWorkGeoCode } from 'modules/settings/submodules/clients/store/models';
import { IEntity } from 'shared/models/Entity';
import { PayType } from 'shared/models/PayType';

import { IConfigurationByClient } from 'store/entities/configuration/configurationModel';

export interface ICustomField extends IEntity {
    name: string;
    description: string;
    prism_field: IPrismMappingField | null;
    headway_connect_field: IHeadwayMappingField | null;
    client_ids: string[];
    all_clients: boolean;
    editable: boolean;
    deletable: boolean;
}

export interface IHeadwayMappingField {
    key: CustomFieldType;
    name: string;
}

export interface IPrismMappingField {
    key: string;
    name: string;
}

export const departmentMappingKey = 'department';

export enum HierarchyNodeOperation {
    Actionable='actionable',
    ReadOnly='read_only',
    Hidden='hidden',
}

export const HierarchyNodeOperationTitle: Record<HierarchyNodeOperation, string> = {
    [HierarchyNodeOperation.Actionable]: 'Actionable',
    [HierarchyNodeOperation.ReadOnly]: 'Read-only',
    [HierarchyNodeOperation.Hidden]: 'Hidden',
};

export enum HierarchyType {
    Assignment='assignment',
    OfferLetter='offer',
}

export interface ICustomFieldHierarchyNode extends IEntity {
    parent_id: string | null,
    client_id: string | null,
    custom_field_id: string,
    display_on_grid: boolean,
    anchor: boolean | null,
    siblings_order: number,
    operation: HierarchyNodeOperation,
    hierarchy_type: HierarchyType,
}

export enum CustomFieldType {
    Location = 'location',
    Position = 'position',
    NameCodeDescriptionProjectClass = 'name_code_description_project_class',
    ShiftCode = 'shift_code',
    NameCode = 'name_code',
    NameCodeDescription = 'name_code_description',
    NameValue = 'name_value',
    SCA = 'sca',
}

export const DepartmentPrismFieldType = 'department';

export enum DifferentialMethod {
    Fixed = 'F',
    Percent = 'P',
}

export const differentialMethodDisplayValues: Record<DifferentialMethod, string> = {
    [DifferentialMethod.Fixed]: 'Fixed',
    [DifferentialMethod.Percent]: 'Percent',
};

export interface IHeadwayConnectCustomField {
    headway_connect_field: CustomFieldType;
    name: string;
}

export interface ILocationCustomField extends IHeadwayConnectCustomField {
    headway_connect_field: CustomFieldType.Location,
    city: string;
    state_code: string;
    work_comp_state_code: string;
    address: string;
    address2: string | null;
    zip_code: string;
    county: string;
    override_work_geo_code : OverrideWorkGeoCode;
    geo_code: string | undefined;
}

export interface INameCodeCustomField extends IHeadwayConnectCustomField {
    headway_connect_field: CustomFieldType.NameCode,
    code: string;
}

export interface INameCodeDescriptionCustomField extends
    Omit<INameCodeCustomField, 'headway_connect_field'>,
    IHeadwayConnectCustomField
{
    headway_connect_field: CustomFieldType.NameCodeDescription,
    description: string;
}

export interface INameValueCustomField extends IHeadwayConnectCustomField {
    headway_connect_field: CustomFieldType.NameValue,
    value: string;
}

export interface IPositionCustomField extends IHeadwayConnectCustomField {
    headway_connect_field: CustomFieldType.Position,
    pay_range_available?: boolean,
    pay_type?: PayType;
    role?: string;
}

export interface INameCodeDescriptionProjectClassCustomField extends
    Omit<INameCodeDescriptionCustomField, 'headway_connect_field'>,
    IHeadwayConnectCustomField
{
    headway_connect_field: CustomFieldType.NameCodeDescriptionProjectClass,
    project_class: string | null;
}

export interface IShiftCodeCustomField extends
    Omit<INameCodeDescriptionCustomField, 'headway_connect_field'>,
    IHeadwayConnectCustomField
{
    headway_connect_field: CustomFieldType.ShiftCode,
    differential_method: DifferentialMethod | null;
}

export interface ISCACustomField extends IHeadwayConnectCustomField {
    headway_connect_field: CustomFieldType.SCA;
    pay_rate: number | null;
    health_and_welfare_rate: number | null;
    state: string | null;
    wage_determination_number: string | null;
    counties: Array<string>;
    positions: Array<string>;
}

export type CustomFieldValueData =
    | ILocationCustomField
    | IShiftCodeCustomField
    | INameCodeCustomField
    | INameCodeDescriptionCustomField
    | INameCodeDescriptionProjectClassCustomField
    | INameValueCustomField
    | IPositionCustomField
    | ISCACustomField;

export interface IRelatedValues {
    sibling_custom_field_value_ids: string[];
    all_values_custom_field_ids: string[];
    parent_custom_field_value_ids: string[];
    all_parent_custom_field_ids: string[];
    /** Use parent array
     * @deprecated
     */
    parent_custom_field_value_id?: string | null;
}

export interface ICreateRelatedValues extends IRelatedValues {
    children_custom_field_value_ids?: string[];
}

export interface ICustomFieldValue extends IEntity {
    custom_field_id: string;
    prism_field_id: string;
    client_id: string;
    data: CustomFieldValueData;
    offer_hierarchy: IRelatedValues;
    assignment_hierarchy: IRelatedValues;
    deleted_at?: string | null;
    is_active: boolean;
}

export interface ICustomFieldValuesQueryRequest extends IConfigurationByClient {
    custom_field_id?: string;
    custom_field_value_ids?: string[];
    field_type?: CustomFieldType;
}

export interface ICustomFieldValuesExportRequest extends IConfigurationByClient {
    custom_field_id: string;
    client_id: string;
    filename: string;
}

export interface IProjectClass {
    project_class: string;
    description: string;
}

export interface ICustomFieldHierarchyWithLinkedResponse {
    nodes: ICustomFieldHierarchyNode[];
    linked: {
        custom_fields: ICustomField[];
        custom_field_values?: ICustomFieldValue[];
    },
    is_offer_letter_ready: boolean;
}

export interface IHierarchyRequest extends IConfigurationByClient {
    hierarchy_type?: HierarchyType,
    relations?: boolean,
    cfvs?: boolean,
}

export interface ICustomFieldValuesLoaded extends IConfigurationByClient {
    custom_field_id: string;
}
