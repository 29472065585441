import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import TemplateFormWrapper
    from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/TemplateFormWrapper';
import { ITemplateFormValues } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';
import {
    createBackgroundCheckTemplate,
    setCreateBackgroundCheckTemplateModalState,
} from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/action';
import {
    selectIsBackgroundCheckTemplateCreating,
    selectIsCreateBackgroundCheckTemplateModalOpened,
} from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/selectors';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export default function CreateBackgroundCheckModal() {
    const dispatch = useDispatch();
    const classes = useColoredFormModalStyles();

    const isLoading = useSelector(selectIsBackgroundCheckTemplateCreating);
    const onSave = useCallback((values: ITemplateFormValues) => {
        dispatch(createBackgroundCheckTemplate.init(values));
    }, [dispatch]);
    const isOpen = useSelector(selectIsCreateBackgroundCheckTemplateModalOpened);
    const onClose = useCallback(() => {
        dispatch(setCreateBackgroundCheckTemplateModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Add New Background Checks Template"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <TemplateFormWrapper
                submitTitle="Add template"
                onSubmit={onSave}
                templateLabel="Background Checks"
                isLoading={isLoading}
                onCancel={onClose}
            />
        </ModalDialog>
    );
}
