/* eslint-disable react/display-name */
import clsx from 'clsx';
import React from 'react';
import { ScaWithTooltip } from 'shared/components/customFieldValues/ScaWithTooltip/ScaWithTooltip';
import PdfSVG from 'shared/components/icons/PdfSVG';
import { Box, IconButton } from '@material-ui/core';
import { areaCell, getHoursAmountCell, payPeriodCell } from 'shared/components/sheetApproval/bodyCells';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { EntryType, IExpenseSheet } from 'shared/models/sheet/Sheet';
import { getUserName, getLastFirstName } from 'shared/utils/converters/user';
import { formatFiles } from 'shared/utils/formatters/timePaymentFormatter';
import { EntryColumnSlug, SheetColumnSlug } from 'store/entities/clients/clientsModel';
import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';
import { useSheetCustomFieldValues } from 'shared/utils/hooks/customFieldsWithValuesHooks';

export const useSheetColumnDictionary = (isMobile: boolean):
Record<SheetColumnSlug, (placeholder: string, classes?: any, additionalData?: any,) => ICellInfo<ISheetRow>> => {
    const classes = useSubmittedTableStyles();

    return {
        [SheetColumnSlug.CustomField]: (placeholder, _, additionalData) => ({
            key: `${SheetColumnSlug.CustomField}-${additionalData?.customFieldId}`,
            title: placeholder,
            width: 'minmax(0, 1fr)',
            render: function CustomFieldCell({ className, sheet }: ISheetRow) {
                const customFieldId = additionalData?.customFieldId || '';
                const sheetCustomFieldValues = useSheetCustomFieldValues(sheet.id, customFieldId);
                const valuesStrings = sheetCustomFieldValues.map(value => value.data.name);
                const valuesString = valuesStrings.join(', ');
                return (
                    <PlainText
                        className={className}
                        value={valuesString}
                    />
                );
            },
        }),
        [SheetColumnSlug.SCA]: (placeholder, _, additionalData) => ({
            key: `${EntryColumnSlug.CustomField}-${additionalData?.customFieldId}`,
            title: placeholder,
            width: 'minmax(0, 1fr)',
            render: function CustomFieldScaCell({ className, sheet }: ISheetRow) {
                const customFieldId = additionalData?.customFieldId || '';
                const sheetCustomFieldValues = useSheetCustomFieldValues(sheet.id, customFieldId);
                return (
                    <Box className={className}>
                        {sheetCustomFieldValues.map(
                            value => <ScaWithTooltip key={value.id} customFieldValueId={value.id}/>,
                        )}
                    </Box>
                );
            },
        }),
        [SheetColumnSlug.Employee]: (placeholder: string) => ({
            key: SheetColumnSlug.Employee,
            title: placeholder,
            width: 'minmax(0, 2fr)',
            render: ({ className, user }: ISheetRow) => (
                <PlainText
                    className={className}
                    value={getLastFirstName(user)}
                />
            ),
        }),
        [SheetColumnSlug.PayPeriod]: payPeriodCell,
        [SheetColumnSlug.Area]: areaCell,
        [SheetColumnSlug.Hours]: getHoursAmountCell(classes, EntryType.TIME, isMobile),
        [SheetColumnSlug.Amount]: getHoursAmountCell(classes, EntryType.EXPENSE, isMobile),
        [SheetColumnSlug.Receipt]: (placeholder: string) => ({
            key: SheetColumnSlug.Receipt,
            title: placeholder,
            width: '80px',
            headerClassName: classes.displayCenterCell,
            render: ({ className, sheet }: ISheetRow) => {
                return (
                    <div className={ clsx(className, classes.iconCell, classes.pdfIcon) }>
                        {(sheet as IExpenseSheet).has_receipts && (
                            <IconButton>
                                <PdfSVG/>
                            </IconButton>
                        )}
                    </div>
                );
            },
        }),
        [SheetColumnSlug.Id]: (placeholder: string) => ({
            key: 'id',
            width: 'minmax(0, 3fr)',
            title: placeholder,
            render: ({ sheet, className }: ISheetRow) => (
                <PlainText className={className} value={sheet.id}/>
            ),
        }),
        [SheetColumnSlug.Position]: (placeholder: string) => ({
            key: 'position',
            width: 'minmax(0, 3fr)',
            title: placeholder,
            render: ({ className, position }: ISheetRow) => (
                <PlainText className={className} value={position?.name}/>
            ),
        }),
        [SheetColumnSlug.Location]: (placeholder: string) => ({
            key: 'location',
            title: placeholder,
            width: 'minmax(0, 1fr)',
            render: ({ className, location }: ISheetRow) => (
                <PlainText className={className} value={location?.name}/>
            ),
        }),
        [SheetColumnSlug.Department]: (placeholder: string) => ({
            key: 'department',
            title: placeholder,
            width: 'minmax(0, 1fr)',
            render: ({ className, department }: ISheetRow) => (
                <PlainText className={className} value={department?.name}/>
            ),
        }),
        [SheetColumnSlug.Approver]: (placeholder: string) => ({
            key: 'approver',
            width: 'minmax(0, 3fr)',
            title: placeholder,
            render: ({ className, approvers }: ISheetRow) => {
                return (
                    <PlainText className={className}
                        value={approvers.map(approver => getUserName(approver)).join(', ')}/>
                );
            },
        }),
        [SheetColumnSlug.JobNumber]: (placeholder: string) => ({
            key: 'jobNumber',
            title: placeholder,
            width: 'minmax(0, 1fr)',
            render: ({ jobNumber, className }: ISheetRow) => (
                <PlainText className={className} value={jobNumber?.job_number?.toString() || ''}/>
            ),
        }),
        [SheetColumnSlug.RegularHours]: (placeholder: string) => ({
            key: 'regularHours',
            title: placeholder,
            width: 'minmax(0, 2fr)',
            render: ({ calculation, className }: ISheetRow) => {
                let value = '';
                if (calculation?.files) {
                    value = formatFiles(calculation?.files);
                } else if (calculation?.rt_hours) {
                    value = formatDecimalHoursStringAsHoursAndMinutes(calculation.rt_hours);
                }
                return (
                    <PlainText className={className} value={value}/>
                );
            },
        }),
        [SheetColumnSlug.Overtime1Hours]: (placeholder: string) => ({
            key: 'overtime1Hours',
            title: placeholder,
            width: 'minmax(0, 2fr)',
            render: ({ calculation, className }: ISheetRow) => (
                <PlainText
                    className={className}
                    value={calculation?.ot_hours ? formatDecimalHoursStringAsHoursAndMinutes(calculation.ot_hours) : ''}
                />
            ),
        }),
        [SheetColumnSlug.Overtime2Hours]: (placeholder: string) => ({
            key: 'overtime2Hours',
            title: placeholder,
            width: 'minmax(0, 2fr)',
            render: ({ calculation, className }: ISheetRow) => (
                <PlainText
                    className={className}
                    value={calculation?.dt_hours ? formatDecimalHoursStringAsHoursAndMinutes(calculation.dt_hours) : ''}
                />
            ),
        }),
        [SheetColumnSlug.HolidayHours]: (placeholder: string) => ({
            key: 'holidayHours',
            title: placeholder,
            width: 'minmax(0, 2fr)',
            render: ({ calculation, className }: ISheetRow) => (
                <PlainText
                    className={className}
                    value={calculation?.holiday_hours ? formatDecimalHoursStringAsHoursAndMinutes(calculation.holiday_hours) : ''}
                />
            ),
        }),
        [SheetColumnSlug.PtoHours]: (placeholder: string) => ({
            key: 'PtoHours',
            title: placeholder,
            width: 'minmax(0, 2fr)',
            render: ({ calculation, className }: ISheetRow) => (
                <PlainText
                    className={className}
                    // @ts-ignore
                    value={calculation?.pto_hours ? formatDecimalHoursStringAsHoursAndMinutes(calculation.holiday_hours) : ''}
                />
            ),
        }),
    };
};
