import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { bulkEditSubAssignmentPreInitializeActionV2 } from 'modules/subassignmentManagement/store/actions';
import { EmployeeActionFormData, ICreateEaf } from 'modules/employeeActionsForm/store/models';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { AdditionalSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/hooks';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectCheckedSubassignments, selectCheckedSubassignmentsAssignments } from '../../SubassignmentTable/store/selectors';
import { BulkEditSubAssignmentFormV2Common, IBulkEditSubAssignmentFormV2CommonProps } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSubAssignmentForm/BulkEditSubAssignmentFormV2Common';
import { selectIsLoadingEditPreInitialize } from '../store/selectors';
import { IBulkEditSubAssignmentFormValuesV2 } from './types';

interface IBulkEditSubAssignmentFormV2Props {
    setAdditionalSteps: (steps: AdditionalSteps) => void;
}

export const BulkEditSubAssignmentFormV2 = separateLogicDecorator<
IBulkEditSubAssignmentFormV2Props,
IBulkEditSubAssignmentFormV2CommonProps
>(
    (props: IBulkEditSubAssignmentFormV2Props) => {
        const dispatch = useDispatch();

        const subassignments = useSelector(selectCheckedSubassignments);
        const isLoading = useSelector(selectIsLoadingEditPreInitialize);
        const assignmentsForSubassignments = useSelector(selectCheckedSubassignmentsAssignments);
        const payRateType = assignmentsForSubassignments[0]?.pay_rate_type;
        const payType = assignmentsForSubassignments[0]?.pay_type;

        const onSubmit = useCallback((
            formValues: IBulkEditSubAssignmentFormValuesV2,
            additionalEafData?: EmployeeActionFormData,
        ) => {
            const employeeActionForms = additionalEafData ? subassignments.map(
                (subassignment: ISubassignment): ICreateEaf => ({
                    assignment_id: subassignment.assignment_id,
                    subassignment_id: subassignment.id,
                    employee_action_form_data: additionalEafData,
                }),
            ) : [];
            const hasSubassignmentChanges = formValues.startDate
                || formValues.endDate || !isEmpty(formValues.approvers);

            const subassignmentUpdates = hasSubassignmentChanges ? subassignments.map(
                subassignment => ({
                    id: subassignment.id,
                    client_id: subassignment.client_id,
                    start_date: formValues.startDate || undefined,
                    end_date: formValues.endDate || undefined,
                    // @ts-ignore
                    managers: isEmpty(formValues.approvers) ? undefined : formValues.approvers.map((ap, index) => {
                        return {
                            user_id: ap?.id || '',
                            manager_level: index + 1,
                        };
                    }),
                }),
            ) : [];
            dispatch(bulkEditSubAssignmentPreInitializeActionV2.init({
                employee_action_forms: employeeActionForms,
                subassignments: subassignmentUpdates,
            }));
        }, [dispatch, subassignments]);

        return {
            onSubmit,
            isLoading,
            payRateType,
            payType,
            selectedCount: subassignments.length,
            selectedEntityName: 'sub-assignment',
            ...props,
        };
    },
)(BulkEditSubAssignmentFormV2Common);
