import { OverrideWorkGeoCode } from 'modules/settings/submodules/clients/store/models';
import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';

interface ICorpTypeProps extends IFormFieldProps, IFormSelect {}

export default function OverrideWorkGeoCodeSelect({
    ...props
}: ICorpTypeProps) {
    const overrideWorkGeocode = Object.values(OverrideWorkGeoCode);

    return (
        <FormSelect
            {...props}
            getKey={(overrideType: string) => overrideType}
            getText={(overrideType: string) => overrideType}
            options={overrideWorkGeocode}
        />
    );
}
