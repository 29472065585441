import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useScaZoneSeparatedStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    label: {
        fontWeight: 'bold',
    },
    loading: {
        position: 'absolute',
        right: theme.spacing(1),
    },
    inputsWrapper: {
        marginTop: theme.spacing(1),
        display: 'flex',
    },
    stateInput: {
        flexGrow: 1,
    },
    countyInput: {
        marginLeft: theme.spacing(2),
        flexGrow: 1.5,
    },
    inputLabel: {
        color: colors.darkGray,
        '&.Mui-error': {
            color: 'inherit',
        },

        '&.Mui-error.Mui-disabled': {
            color: theme.palette.text.disabled,
        },
    },
    inputLabelFilled: {
        '&.Mui-error': {
            color: theme.palette.error.main,
        },
    },
}));
