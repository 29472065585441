import { IMobileTextFormatter } from 'shared/components/table/GridTable/GridTableModel';
import { ColumnSlugs, IColumnConfiguration } from 'store/entities/clients/clientsModel';

export const getMobileItemsRowByConfig = (
    formatters: IMobileTextFormatter[],
    config: IColumnConfiguration<ColumnSlugs>[],
    separator = ' | ',
): string => {
    const parts: string[] = [];
    formatters.forEach(formatter => {
        if (config.find(c => c.slug === formatter.slug)) {
            const text = formatter.getText();
            if (text) {
                parts.push(text);
            }
        }
    });
    return parts.join(separator);
};
