import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { fade, Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useEntriesTableStyles = makeHighPriorityStyles((theme: Theme) => ({
    newTableWrapper: {
        borderWidth: `0 2px`,
        borderStyle: 'solid',
        borderColor: colors.lightGray,
        marginBottom: theme.spacing(2),
    },
    tableWrapperLoading: {
        borderWidth: `0`,
    },
    activityText: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
    bodyCell: {
        fontSize: 14,
        fontWeight: 'normal',
    },
    iconCell: {
        '&&': {
            padding: '0 16',
        },
    },
    bodyCellModal: {
        padding: theme.spacing(1.5, 0, 1.5, 2),
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: colors.lightGray,
        padding: theme.spacing(0, 2),
        position: 'sticky',
        minHeight: '40px',
        top: 0,
        zIndex: 2,
    },
    toolbarCollapsible: {
        padding: theme.spacing(0, 2, 0, 0.5),
        borderBottom: `1px solid ${colors.darkGray}`,
    },
    toolbarInfo: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
            flexGrow: 1,
        },
    },
    dayHeaderActions: {
        display: 'flex',
        flexDirection: 'row',

        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-end',
            marginRight: theme.spacing(-1.5),

            '& [class*="MuiCheckbox-root"]': {
                padding: theme.spacing(0.5),
            },
        },
    },
    toolbarContent: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    headerCheckboxBlock: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    headerCheckbox: {
        fontSize: 12,
    },
    headerCellClassName: {
        '&&': {
            position: 'sticky',
            background: colors.white,
            zIndex: 1,
            top: '40px',
        },
    },
    amountBodyCell: {
        '&&': {
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
            textAlign: 'right',
        },
    },
    firstActionIcon: {
        borderLeft: `1px solid ${colors.lightGray}`,
    },
    textIcon: {
        fontSize: 16,
        padding: theme.spacing(0, 1, 0.25, 0),
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    hoursAmountHeader: {
        textAlign: 'right',
        paddingLeft: '0 !important',
        paddingRight: theme.spacing(2),
    },
    receiptCell: {
        '&&': { padding: theme.spacing(0, 0, 0, 2) },
    },
    bodyCellWithWarning: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    bodyCellWarning: {
        color: `${colors.orange}`,
        margin: 0,
        fontSize: 12,
    },
    pullLeft: {
        '&&': {
            alignItems: 'flex-start',
        },
    },
    disabled: {
        background: fade(colors.lightGray, 0.5),
    },
}));
