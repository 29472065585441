import TimeSagas from './timeSagas';
import ExpenseSagas from './expenseSagas';
import StatusSagas from './statusSagas';
import AttachmentsSagas from './attachments';
import CommonSheetsSagas from './sheets';

export default [
    ...TimeSagas,
    ...ExpenseSagas,
    ...StatusSagas,
    ...AttachmentsSagas,
    ...CommonSheetsSagas,
];
