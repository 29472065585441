import { IStore } from 'store/configureStore';

export const selectEmployeeDetailState = (state: IStore) => state.modules.offerLetter.employeeOfferDetail;
export const selectOnboardingStep = (state: IStore) => selectEmployeeDetailState(state).onboardingStep;
export const selectOfferIsUpdating = (state: IStore) => selectEmployeeDetailState(state).isOfferUpdating;
export const selectProfileIsCreated = (state: IStore) => selectEmployeeDetailState(state).isProfileCreated;
export const selectProfileIsUpdating = (state: IStore) => selectEmployeeDetailState(state).isProfileUpdating;
export const selectIsSSOLikLoading = (state: IStore) => selectEmployeeDetailState(state).isSSOLinkLoading;
export const selectSSOLink = (state: IStore) => selectEmployeeDetailState(state).SSOLink;
export const selectIsMyEmployeeProfileLoading = (state: IStore): boolean =>
    selectEmployeeDetailState(state).isEmployeeProfileLoading;
export const selectOnboardingIdFinishing = (state: IStore) => selectEmployeeDetailState(state).onboardingFinishing;
