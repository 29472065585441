import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import { finishOnboarding, getMyEmployeeProfile, setOnboardingStep, updateEmployeeProfile } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import {
    getPrismSSOLink,
    OfferLetterActions,
    updateOfferLetter,
} from 'modules/offerLetter/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

export const isOfferUpdating = isLoadingReducer(updateOfferLetter);
export const isProfileUpdating = isLoadingReducer(updateEmployeeProfile);
export const isSSOLinkLoading = isLoadingReducer(getPrismSSOLink);
export const isEmployeeProfileLoading = isLoadingReducer(getMyEmployeeProfile);

function isProfileCreated(
    state = false,
    action: OfferLetterActions,
): boolean {
    switch (action.type) {
        case updateEmployeeProfile.successType:
        case getMyEmployeeProfile.successType:
            return true;
        case updateEmployeeProfile.errorType:
        case getMyEmployeeProfile.errorType:
            return false;
        default:
            return state;
    }
}

function SSOLink(
    state = '',
    action: OfferLetterActions,
): string {
    switch (action.type) {
        case getPrismSSOLink.successType:
            return action.payload;
        case getPrismSSOLink.initType:
        case getPrismSSOLink.errorType:
            return '';
        default:
            return state;
    }
}

const onboardingStep = singleValueReducer(setOnboardingStep.action, OnboardingSteps.ViewPdf);
const onboardingFinishing = isLoadingReducer(finishOnboarding);

export const employeeOfferDetail = combineReducers({
    onboardingStep,
    isOfferUpdating,
    isProfileUpdating,
    isProfileCreated,
    isSSOLinkLoading,
    isEmployeeProfileLoading,
    SSOLink,
    onboardingFinishing,
});
