import { groupBy, sortBy, last } from 'lodash-es';
import { IOfferLetter, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { selectOfferLetters } from 'modules/offerLetter/store/selectors';
import { useSelector } from 'react-redux';

/**
 * Return list of latest offer letters with target status for user
 *
 * @param email
 * @param statusSlugs
 */
export function useLatestEmployeeOffersByStatus(email: string, statusSlugs: OfferLetterStatusSlug[]): IOfferLetter[] {
    const offerLettersByIds = useSelector(selectOfferLetters);
    const userOffers = Object.values(offerLettersByIds)
        .filter((offer: IOfferLetter) => offer.email === email && statusSlugs.includes(offer.status.slug));
    // @ts-ignore
    return Object.values(groupBy(userOffers, offer => offer.client_id)).map(offers => {
        if (offers.length === 0) {
            return offers[0];
        }
        return last(sortBy(offers, ['offer_date']));
    });
}
