import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useEntryDetailsStyles = makeHighPriorityStyles((theme: Theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableCell: {
        display: 'flex',
        height: 'unset',
        alignItems: 'center',
    },
    details: {
        fontSize: 11,
        color: colors.darkGray,
        lineHeight: '14px',
    },
    longInfo: {
        maxWidth: `calc(100vw - ${theme.spacing(16)}px)`,
    },
    longInfoEditable: {
        maxWidth: `calc(100vw - ${theme.spacing(29)}px)`,
    },
    activityInfo: {
        fontSize: 14,
        lineHeight: '19px',
        color: colors.black,
    },
}));
