import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { createEmployeeActionForm } from 'modules/employeeActionsForm/store/actions';
import { selectIsEafCreating } from 'modules/employeeActionsForm/store/selectors';
import { PositionChangeForm } from 'modules/subassignmentManagement/components/PromotionDemotion/PositionChangeForm';
import { setOpenPromotionDemotion } from 'modules/subassignmentManagement/components/PromotionDemotion/store/actions';
import { selectIsPromotionDemotionOpened } from 'modules/subassignmentManagement/components/PromotionDemotion/store/selectors';
import { selectAssignmentByEditSubassignment, selectEditSubassignment } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/selectors';
import { IPositionChangeFormValues } from 'modules/subassignmentManagement/components/PromotionDemotion/store/model';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import { WorkspaceSidebar } from 'shared/components/workspaces/WorkspaceSidebar/WorkspaceSidebar';
import { selectClientHideSubassignments } from 'store/entities/clients/selectors/configurationSelectors';
import { selectPaySettings } from 'store/entities/clients/selectors/timeAndPaySelectors';

export const PromotionDemotion = () => {
    const dispatch = useDispatch();

    const isOpen = useSelector(selectIsPromotionDemotionOpened);
    const assignment = useSelector(selectAssignmentByEditSubassignment);
    const subassignment = useSelector(selectEditSubassignment);
    const paySettings = useSelector(selectPaySettings);
    const isLoading = useSelector(selectIsEafCreating);
    const hideSubassignments = useSelector(selectClientHideSubassignments);
    const onClose = useCallback(() => {
        dispatch(setOpenPromotionDemotion(false));
    }, [dispatch]);

    const handleSubmit = useCallback((values: IPositionChangeFormValues) => {
        dispatch(createEmployeeActionForm.init({
            // @ts-ignore
            assignment_id: assignment.id,
            subassignment_id: subassignment?.id,
            // @ts-ignore
            employee_action_form_data: {
                // @ts-ignore
                employee_action_form_type: values.type,
                effective_date: values.effectiveDate,
                new_base_pay_rate: values.newPayRate,
                new_position_custom_field_value_id: values.newPosition,
                // @ts-ignore
                is_retro: values.isRetro,
            },
        }));
    }, [dispatch, assignment, subassignment]);

    return (
        <WorkspaceSidebar
            title="Promotion/Demotion"
            isOpened={isOpen}
            onClose={onClose}
        >
            <Box m={3}>
                {!hideSubassignments && (
                    <WarningAlertWithIcon>
                        This action will end the current sub-assignment and create a new one.
                    </WarningAlertWithIcon>
                )}
                <PositionChangeForm
                    // @ts-ignore
                    assignment={assignment}
                    subassignment={subassignment}
                    // @ts-ignore
                    paySettings={paySettings}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    onCancel={onClose}
                />
            </Box>
        </WorkspaceSidebar>
    );
};
