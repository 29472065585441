import { IFilterStoreProps } from 'shared/models/Filters';

export interface IFiltersContentProps extends IFilterStoreProps {
    onClose: () => void;
}

export enum FiltersTestIds {
    Button = 'filters/open-button',
    ButtonMock = ' filters/open-button-mock',
    Wrapper = 'filters/content-wrapper',
    ChipsWrapper = 'filters/chips-wrapper',
}
