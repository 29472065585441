import * as qs from 'query-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setSheetGridStatus } from 'store/entities/appConfig/actions';
import { SheetStatusSlug, statusSlugToName } from 'store/entities/timesheet/models/Status';

export const useQueryStatusRedirect = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        const searchParams = qs.parse(location.search);
        const queryStatus = searchParams.status as SheetStatusSlug | null;
        if (queryStatus && Object.values(SheetStatusSlug).includes(queryStatus)) {
            dispatch(setSheetGridStatus(statusSlugToName[queryStatus]));
        }
    }, [dispatch, location]);
};
