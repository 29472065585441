import { getHomeDashboardStats, HomeActions, SET_HOME_FILTER } from 'modules/home/store/actions';
import { IHomeDashboardResponse, IHomeFilter } from 'modules/home/store/model';
import { combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';

const initialState = {
    dashboardStats: {
        sheets: [],
        offer_letters: [],
    },
    dashboardFilter: {},
};

export function dashboardStats(
    state: IHomeDashboardResponse = initialState.dashboardStats,
    action: HomeActions,
): IHomeDashboardResponse {
    switch (action.type) {
        case getHomeDashboardStats.successType:
            return action.payload;
        case getHomeDashboardStats.errorType:
            return initialState.dashboardStats;
        default:
            return state;
    }
}

export function dashboardFilter(
    state: IHomeFilter = initialState.dashboardFilter,
    action: HomeActions,
): IHomeFilter {
    switch (action.type) {
        case SET_HOME_FILTER:
            return action.payload;
        default:
            return state;
    }
}

const isStatsLoading = isLoadingReducer(getHomeDashboardStats);

export const home = combineReducers({
    isStatsLoading,
    dashboardFilter,
    dashboardStats,
});
