import { useCallback, useState } from 'react';

export function useRejectNotes() {
    const [notesById, setNotesById] = useState<Record<string, string>>({});

    const onNoteChange = useCallback((id: string, note: string) => {
        setNotesById(state => ({
            ...state,
            [id]: note,
        }));
    }, []);

    return { notesById, onNoteChange };
}
