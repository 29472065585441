import React from 'react';
import clsx from 'clsx';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { TableClasses } from '../model';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type MilesCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export function makeMilesCell(tableClasses: TableClasses) {
    const MilesCell = ({ className, expense_total_miles }: MilesCellProps) => {
        const miles = parseFloat(expense_total_miles || '');
        return (
            <PlainText
                className={clsx(className, tableClasses.pullRight)}
                value={(miles && miles.toString()) || '0'}
            />
        );
    };

    return MilesCell;
}
