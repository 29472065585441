import React, { useEffect } from 'react';
import TimeAndExpensePageContent from 'modules/clients/content/TimeAndExpensePage/TimeAndExpensePageContent';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { HeadwayLoader } from 'shared/components/loader/HeadwayLoader';
import { routes } from 'shared/routes';
import { useIsLoadingTimeAndExpense, useShowTimeAndExpense } from 'modules/timeAndExpense/store/hooks';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { searchSubassignments } from 'store/entities/configuration/configurationAction';

export default function TimeAndExpensePage() {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const user = useSelector(selectCurrentUser);
    useEffect(() => {
        if (clientId && user) {
            dispatch(searchSubassignments.init({
                client_id: clientId,
                employee_user_id: user.id,
            }));
        }
    }, [dispatch, clientId, user]);

    const isLoading = useIsLoadingTimeAndExpense();
    const show = useShowTimeAndExpense();

    if (isLoading) {
        return <HeadwayLoader fullScreen/>;
    }
    if (!show) {
        return <Redirect to={generatePath(routes.CLIENT.DASHBOARD, { client_id: clientId })}/>;
    }

    return (
        <TimeAndExpensePageContent/>
    );
}
