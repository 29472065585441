import React from 'react';
import { usePayRangeCells } from 'modules/settings/submodules/clients/payRanges/components/payRangesTable/cells';
import { SettingsInfinityTablePure } from 'modules/settings/common/components/SettingsTablePure/SettingsInfinityTablePure';
import { getMorePayRangesAction, initialLoadPayRanges } from 'modules/settings/submodules/clients/payRanges/store/actions';
import { payRangesTableSelectors, selectPayRangesTableRows } from 'modules/settings/submodules/clients/payRanges/store/selectors';

export const PayRangesTable = () => {
    const cells = usePayRangeCells();

    return (
        <SettingsInfinityTablePure
            infinityTableSelector={payRangesTableSelectors}
            rowsSelector={selectPayRangesTableRows}
            initialLoadAction={initialLoadPayRanges}
            getMoreAction={getMorePayRangesAction.init}
            cells={cells}
        />
    );
};
