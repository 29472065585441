export interface IRegistrationFormValues {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    password: string;
    passwordConfirmation: string;
    termsAnsConditionsAgreement: boolean;
}

export const registrationInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    passwordConfirmation: '',
    termsAnsConditionsAgreement: false,
};

export interface ILoginFormValues {
    username: string;
    password: string;
}

export const loginInitialValues = {
    username: '',
    password: '',
};

export interface IResettingPasswordFormValues {
    email: string;
}

export interface INewPasswordFormValues {
    password: string;
    passwordConfirmation: string;
}
