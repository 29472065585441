import React from 'react';
import {
    useSubassignmentTitle,
} from 'modules/subassignmentManagement/components/EditSubAssignment/hooks/useSubassignmentTitle';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import PlainText from 'shared/components/table/Cells/PlainText';

export const EafGridCellSubassignment = ({
    className,
    subassignment_id,
    onClick,
}: EafApprovalCellProps) => {
    const value = useSubassignmentTitle(subassignment_id);
    return (
        <PlainText
            value={value}
            className={className}
            onClick={onClick}
        />
    );
};
