import { getPayPeriodWorkingSheets, setCurrentPayPeriod, setWorkingEntryTypeFilter } from 'modules/timeAndExpense/components/WorkingPage/store/actions';
import { combineReducers } from 'redux';
import { WorkingSheetsActions } from 'store/components/workingSheets/workingSheetsActions';
import { initialState } from 'store/components/workingSheets/workingSheetsReducer';
import { singleValueReducer } from 'store/reducerUtils';

const currentPayPeriod = singleValueReducer(setCurrentPayPeriod.action, null);
const entryTypeFilter = singleValueReducer(setWorkingEntryTypeFilter.action, null);

function isSheetsLoadingByPayPeriods(
    state: Record<string, boolean> = initialState.isWeekLoaded, action: WorkingSheetsActions,
): Record<string, boolean> {
    switch (action.type) {
        case getPayPeriodWorkingSheets.initType:
            return {
                ...state,
                // @ts-ignore
                [action.payload.period_end]: true,
            };
        case getPayPeriodWorkingSheets.successType:
        case getPayPeriodWorkingSheets.errorType:
            return {
                ...state,
                // @ts-ignore
                [action.payload.period_end]: false,
            };
        default:
            return state;
    }
}

export const workingPage = combineReducers({
    currentPayPeriod,
    entryTypeFilter,
    isSheetsLoadingByPayPeriods,
});
