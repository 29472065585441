import { call, put, takeLatest } from 'typed-redux-saga';
import { getDeals } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { setDealStatus } from 'modules/settings/submodules/clients/deals/components/UpdateStatusSelect/store/actions';
import { api } from 'modules/settings/submodules/clients/deals/components/UpdateStatusSelect/store/api';

function* updateDealStatusSaga(action: ReturnType<typeof setDealStatus.init>) {
    const { id, status } = action.payload;
    const result = yield* call(api.updateDealStatus, id, status);
    yield put(setDealStatus.success(result));
    yield put(getDeals.success([result]));
}

export function* updateDealStatusWatcher() {
    yield* takeLatest(
        setDealStatus.initType,
        withBackendErrorHandler(
            updateDealStatusSaga,
            setDealStatus.error,
            'Unable to update Deal Status.',
        ),
    );
}
