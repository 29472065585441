import { Box, Typography } from '@material-ui/core';
import React from 'react';
import FormField from 'shared/components/formFields/FormField';
import PhoneField from 'shared/components/formSpecialFields/phoneField/PhoneField';
import { formBasicSpacing, useStyles } from '../styles';

export const OFFER_LETTER_FORM_STEP_INVITE = 'OFFER_LETTER_FORM_STEP_INVITE';

export function OfferLetterFormStepInvite() {
    const classes = useStyles();

    return (
        <Box className={classes.form}>
            <Box
                m={formBasicSpacing}
                mt={2.5}
                mb={0}
            >
                <Typography variant="subtitle2">
                    1. Invite Employee
                </Typography>
            </Box>
            <FormField
                name="first_name"
                label="First Name"
                className={classes.field}
            />
            <FormField
                name="last_name"
                label="Last Name"
                className={classes.field}
            />
            <FormField
                name="email"
                type="email"
                label="Email Address"
                className={classes.field}
            />
            <PhoneField
                name="phone"
                label="Cell Phone Number"
                className={classes.field}
            />
        </Box>
    );
}
