import { fade, Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useDayPickerStyles = makeHighPriorityStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            border: `1px solid ${colors.gray}`,

            '& > *': {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
            },
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    dayContainer: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
    },
    dayOfWeek: {
        textAlign: 'center',
        fontSize: 14,
        letterSpacing: 1,
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    dayOfMonth: {
        textAlign: 'center',
        padding: 0,
        height: 25,
        width: 25,
        borderRadius: '50%',
        minWidth: 'auto',
        fontSize: 14,
        fontWeight: 400,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.2),
        },
        '&.active': {
            backgroundColor: theme.palette.primary.main,
            color: colors.white,
        },
    },
    controlButton: {
        height: theme.spacing(4),
    },
}));
