import {
    ICustomField,
    ICustomFieldHierarchyNode,
    ICustomFieldValue, ICustomFieldValuesExportRequest, ICustomFieldValuesLoaded,
    ICustomFieldValuesQueryRequest,
    IHierarchyRequest,
    IPrismMappingField,
} from 'store/entities/customFields/model';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { ISyncCustomFieldPayload } from '../../../modules/settings/submodules/customFields/store/models';

export const getCustomFields = createRequestActions<void, ICustomField[]>(
    RequestType.Get,
    'CUSTOM_FIELDS',
);

export const getCustomFieldsMappings = createRequestActions<void, IPrismMappingField[]>(
    RequestType.Get,
    'CUSTOM_FIELDS_MAPPING',
);

export const deleteCustomField = createRequestActions<string, string>(
    RequestType.Delete,
    'CUSTOM_FIELDS',
);

const SYNC_CUSTOM_FIELD_WITH_PRISM = 'SYNC_CUSTOM_FIELD_WITH_PRISM';
export const syncCustomField = createRequestActions<ISyncCustomFieldPayload, string>(
    RequestType.Create,
    SYNC_CUSTOM_FIELD_WITH_PRISM,
);

const clearLocalClientHierarchyNodesActionType = 'CUSTOM_FIELDS_HIERARCHY_NODES/Clear_local_nodes';
export const clearLocalClientHierarchyNodes = createSingleAction<
void, typeof clearLocalClientHierarchyNodesActionType>(clearLocalClientHierarchyNodesActionType);

export const getCustomFieldsHierarchyNodes = createRequestActions<void | IHierarchyRequest
, ICustomFieldHierarchyNode[]>(
    RequestType.Get,
    'CUSTOM_FIELDS_HIERARCHY_NODES',
);

export const deleteCustomFieldHierarchyNode = createRequestActions<string, string>(
    RequestType.Delete,
    'CUSTOM_FIELD_HIERARCHY_NODE',
);

export const getCustomFieldValues = createRequestActions<ICustomFieldValuesQueryRequest, ICustomFieldValue[]>(
    RequestType.Get,
    'GET_CUSTOM_FIELD_VALUES',
);
export const queryCustomFieldValues = createRequestActions<ICustomFieldValuesQueryRequest, ICustomFieldValue[]>(
    RequestType.Get,
    'QUERY_CUSTOM_FIELD_VALUES',
);

export const exportCustomFieldValues = createRequestActions<ICustomFieldValuesExportRequest, void>(
    RequestType.Get,
    'EXPORT_CUSTOM_FIELD_VALUES',
);

export const deleteCustomFieldValue = createRequestActions<string, string>(
    RequestType.Delete,
    'CUSTOM_FIELD_VALUE',
);

const GET_CLIENT_CUSTOM_FIELD_CONFIGURATION = 'GET_CLIENT_CUSTOM_FIELD_CONFIGURATION';
export const getClientCustomFieldConfiguration = createSingleAction<void, typeof GET_CLIENT_CUSTOM_FIELD_CONFIGURATION>(
    GET_CLIENT_CUSTOM_FIELD_CONFIGURATION,
);

const SET_USE_SINGLE_HIERARCHY = 'SET_USE_SINGLE_HIERARCHY';
export const setUseSingleHierarchy = createSingleAction<boolean, typeof SET_USE_SINGLE_HIERARCHY>(
    SET_USE_SINGLE_HIERARCHY,
);

const CUSTOM_FIELD_VALUES_LOADED = 'CUSTOM_FIELD_VALUES_LOADED';
export const setFieldValuesIsLoaded = createSingleAction<
ICustomFieldValuesLoaded,
typeof CUSTOM_FIELD_VALUES_LOADED
>(CUSTOM_FIELD_VALUES_LOADED);
