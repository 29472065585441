import DayPickerField from 'shared/components/formFields/DayPickerField';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import React from 'react';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { useSelector } from 'react-redux';
import { selectJobNumberById } from 'store/entities/configuration/configurationSelectors';
import { useUserAssignments } from 'modules/travelExpenses/components/SidebarForm/hooks';

export interface ITravelDateFieldProps {
    jobNumberId?: string;
}

export const TravelDateField = ( { jobNumberId } : ITravelDateFieldProps) => {
    const jobNumber = useSelector(selectJobNumberById(jobNumberId || ''));
    const assignments = useUserAssignments(jobNumber?.user_id || '');
    const assignment = assignments?.length ? assignments[0] : null;
    // @ts-ignore
    const minDate = jobNumber?.start_date > assignment?.hire_date
        ? jobNumber?.start_date
        // @ts-ignore
        : assignment?.hire_date;
    const classes = useEntryEditStyles();
    return (
        <FormFieldWithSeparateLabel
            name="travelDate"
            outerLabel="Travel Date:"
            FieldComponent={DayPickerField}
            withKeyboard
            className={classes.formInputWithLabel}
            minDate={minDate || undefined}
            maxDate={jobNumber?.end_date || undefined}
        />
    );
};
