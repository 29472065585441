import { IStore } from 'store/configureStore';

const dealTypeState = (state: IStore) => {
    return state.modules.settings.clientsSettings.clients.dealType;
};

export const selectIsDealTypeCreating = (state: IStore) => {
    const dealType = dealTypeState(state);
    return dealType.isCreationInProgress;
};
