import React from 'react';
import { useSelector } from 'react-redux';
import SheetRejectedView from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/SheetRejectedView';
import { useOpenSheetDetail } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/useOpenSheetDetail';

import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';
import SheetsRejectedActions from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/SheetsRejectedActions';
import { selectEmployeeSubmittedEditSheet } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { useDetailSheetGroup } from '../SheetsInProgress/hooks/useDetailSheetGroup';

export default function SheetsRejectedPage() {
    const handleSheetClick = useOpenSheetDetail();
    const editSheetInfo = useSelector(selectEmployeeSubmittedEditSheet);
    const sheetGroup = useDetailSheetGroup();

    if (editSheetInfo) {
        return (
            <SheetRejectedView
                sheetIds={editSheetInfo.sheetIds}
                entryType={editSheetInfo.entryType}
                sheetGroup={sheetGroup}
            />
        );
    }
    return (
        <SheetsSubmittedView
            onDetailsClick={handleSheetClick}
            availableActions={SheetsRejectedActions}
        />
    );
}
