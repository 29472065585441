import { selectSettingClientsState } from 'modules/settings/submodules/clients/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectUsersById } from 'store/entities/users/selectors';
import { assignmentsReportDataSelectors } from '../../AssignmentsReportDataList/store/selectors';

const selectEditState = (state: IStore) =>
    selectSettingClientsState(state).assignmentsReportData.editAssignmentMetaData;
const selectMetaDataId = (state: IStore) => selectEditState(state).editId;
export const selectEditAssignmentMetaData = createSelector(
    selectMetaDataId,
    assignmentsReportDataSelectors.selectItems,
    (id, metaDataList) => {
        return metaDataList.find(item => item.id === id);
    },
);
export const selectEditMetaDataUser = createSelector(
    selectEditAssignmentMetaData,
    selectUsersById,
    (metaData, usersByIds) => {
        return usersByIds[metaData?.user_id || ''];
    },
);
export const selectIsUpdatingMetaData = (state: IStore) => selectEditState(state).isLoading;
