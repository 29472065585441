import React from 'react';
import MainContent from 'shared/components/common/MainContent';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';

const Onboarding: React.FC<{}> = () => {
    useTitleUpdate('Onboarding');
    return (
        <MainContent>
            <h1>Onboarding component works!</h1>
        </MainContent>
    );
};

export default Onboarding;
