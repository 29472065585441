import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import { combineReducers } from 'redux';
import {
    createJobNumberAction,
    setCreateJobNumberModalState,
} from 'modules/settings/submodules/clients/jobNumber/store/actions';

const isCreating = isLoadingReducer(createJobNumberAction);
const isModalOpen = isOpenModalReducer(setCreateJobNumberModalState.action);

export const createJobNumbers = combineReducers({
    isCreating,
    isModalOpen,
});
