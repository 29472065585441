import { useMaxLevelApproverIdBySheetId } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/hooks/useMaxLevelApproverIdBySheetId';
import React, { useCallback } from 'react';
import { Checkbox, ListItemText, MenuItem, Box } from '@material-ui/core';
import { setPayrollDetailCalculationGroupId, unlockSheet } from 'modules/payrollProcessorHub/store/actions';
import { useDispatch } from 'react-redux';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getUserName } from 'shared/utils/converters/user';
import { useManagerListForUnlock } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/hooks/useManagerListForUnlock';
import { useManagersList } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/hooks/useManagersList';
import { useConfirmationDialogCustomClasses } from './styles/useConfirmationDialogCustomClasses';
import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';

interface IUnlockConfirmationDialogSeveralApproversProps {
    isOpened: boolean;
    onCancel: () => void;
    calculationGroup: ISheetCalculationBatch;
}

const TITLE = `Unlock time & expense sheet`;
const MESSAGE = `
Select the corresponding approving manager(s) for the employee's
time and expense sheet that you want to unlock.
`;

export const UnlockConfirmationDialogSeveralApprovers = ({
    isOpened,
    onCancel,
    calculationGroup,
}: IUnlockConfirmationDialogSeveralApproversProps) => {
    const dispatch = useDispatch();
    const approvers = useManagersList(calculationGroup);
    const approverIdsBySheetIds = useMaxLevelApproverIdBySheetId(calculationGroup);
    const {
        toggleManagerIsChecked,
        isManagerChecked,
        checkedMangersIds,
    } = useManagerListForUnlock();
    const confirmationDialogCustomClasses = useConfirmationDialogCustomClasses();

    const handleConfirmUnlock = useCallback(() => {
        const timeSheetIds = calculationGroup.time_calculations
            // @ts-ignore
            .filter(calculation => checkedMangersIds.includes(approverIdsBySheetIds[calculation.sheet_id]))
            .map(calculation => calculation.sheet_id);
        const expenseSheetIds = calculationGroup.expense_calculations
            // @ts-ignore
            .filter(calculation => checkedMangersIds.includes(approverIdsBySheetIds[calculation.sheet_id]))
            .map(calculation => calculation.sheet_id);
        dispatch(unlockSheet({
            sheets: [
                ...timeSheetIds.map(id => ({
                    id: id,
                    sheetType: EntryType.TIME,
                })),
                ...expenseSheetIds.map(id => ({
                    id: id,
                    sheetType: EntryType.EXPENSE,
                })),
            ],
        }));
        onCancel();
        dispatch(setPayrollDetailCalculationGroupId(null));
    }, [dispatch, calculationGroup, checkedMangersIds, onCancel, approverIdsBySheetIds]);

    return (
        <ConfirmationDialog
            dialogTitle={TITLE}
            open={isOpened}
            onCancel={onCancel}
            onConfirm={handleConfirmUnlock}
            disableConfirm={checkedMangersIds.length === 0}
            customClasses={{
                actions: confirmationDialogCustomClasses.actions,
                confirmButton: confirmationDialogCustomClasses.confirmButton,
                dialogTitle: confirmationDialogCustomClasses.dialogTitle,
            }}
        >
            {MESSAGE}
            <Box mt={2}>
                {approvers.map(approver => (
                    <MenuItem key={approver.id} onClick={() => toggleManagerIsChecked(approver.id)}>
                        <Checkbox checked={isManagerChecked(approver.id)}/>
                        <ListItemText primary={getUserName(approver)}/>
                    </MenuItem>
                ))}
            </Box>
        </ConfirmationDialog>
    );
};
