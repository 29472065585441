import { useField } from 'formik';
import React, { useCallback, useMemo, useRef } from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import { IPredefinedTimeOption } from 'store/entities/timesheet/models/Entry';
import { compareInOutTimeValue, printTimeByHours, TIME_FORMAT_FULL } from 'shared/models/DateTime';
import { QuantityType } from 'shared/models/sheet/Sheet';
import IntervalDataField from '../intervalField/IntervalDataField';
import { inOutWithPredefinedPropsComponent } from './InOutTimeFieldPicker';

interface ITimeEntryEditFieldProps extends IFormFieldProps {
    options: IPredefinedTimeOption[];
    entryType: QuantityType;
}

const rowMaxOptionsCount = 2;

function TimeEntryEditField({
    options, entryType, disabled, name,
}: ITimeEntryEditFieldProps) {
    const { current: inputs } = useRef([
        {
            name: 'timeIn',
            label: 'IN',
        },
        {
            name: 'timeOut',
            label: 'OUT',
        },
    ]);
    const compare = useCallback(compareInOutTimeValue, []);
    const convert = useCallback((value: string) => value, []);

    const classes = useEntryEditStyles();
    const [field, , helper] = useField(name);

    const timeOptionsGrouped = useMemo(() => options.reduce<Array<Array<IPredefinedTimeOption>>>(
        (acc, item, index) => {
            const arrayIndex = Math.floor(index / rowMaxOptionsCount);
            return [
                ...acc.slice(0, arrayIndex),
                [...(acc[arrayIndex] || []), item],
            ];
        }, [],
    ), [options]);

    const onTimeOptionSelect = (key: string) => {
        const selectedOption = options.find(option => option.label === key);

        if (selectedOption) {
            const { hours, minutes } = selectedOption;
            const { value } = field;
            const [timeIn, timeOut] = inputs;
            const newValue = printTimeByHours(hours, minutes, TIME_FORMAT_FULL);
            /**
             * The login of getting field for initialization
             * If time_in isn't set then initialize it
             * If time_in set and time_out isn't set then initialize time_out
             * If both time fields are set then change value of time_in
             */
            const currentValue = value || {};
            if (!currentValue[timeIn.name] || currentValue[timeOut.name]) {
                helper.setValue({
                    ...currentValue,
                    [timeIn.name]: newValue,
                    [timeOut.name]: currentValue[timeOut.name],
                });
            } else {
                helper.setValue({
                    ...currentValue,
                    [timeIn.name]: currentValue[timeIn.name],
                    [timeOut.name]: newValue,
                });
            }
        }
    };

    const timeInput = useMemo(
        () => inOutWithPredefinedPropsComponent({ picker: { iconButton: classes.timeInputIcon } }, true),
        [classes],
    );

    return (
        <>
            <Typography
                classes={{ root: classes.timeInputsHeader }}
                color="primary"
                variant="h6"
            >
                Logged Time
            </Typography>
            <div className={classes.timeInputs}>
                <IntervalDataField
                    Input={timeInput}
                    inputs={inputs}
                    name={name}
                    disabled={disabled}
                    showTotalInput={false}
                    compare={compare}
                    convert={convert}
                    entryType={entryType}
                    classes={{ inputs: classes.timeInput, secondInput: classes.timeInputWithMargin }}
                />
            </div>

            {/* TODO: It has to be refactored to Button-like radio */}
            {timeOptionsGrouped.map(group => (
                <Grid
                    container
                    spacing={2}
                    key={group[0].label}
                >
                    {group.map(option => (
                        <Grid item key={option.label}>
                            <Button
                                onClick={() => onTimeOptionSelect(option.label)}
                                variant="contained"
                                color="primary" classes={{ root: classes.timeOptionButton }}
                            >
                                {option.label}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            ))}
        </>
    );
}

export default React.memo(TimeEntryEditField);
