import React from 'react';
import { useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { Permission } from 'store/components/auth/authModels';
import { selectUserPermissions } from 'store/components/auth/selectors';
import { FeatureSwitches } from 'utils/featureSwitches';

export interface ICheckPermissionAndFeature {
    permission?: Permission;
    feature?: FeatureSwitches;
}

export interface ICheckPermissionAndFeatureProps extends ICheckPermissionAndFeature {
    children: React.ReactNode;
}

export const CheckPermissionAndFeature = ({ feature, permission, children }: ICheckPermissionAndFeatureProps) => {
    const userPermissions = useSelector(selectUserPermissions);
    const isPermitted = permission === undefined || userPermissions.includes(permission);
    let isEnabled = true;
    if (feature) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isFeatureEnabled] = useFeature(feature);
        isEnabled = isFeatureEnabled;
    }

    return (
        <>
            {isPermitted && isEnabled && children}
        </>
    );
};
