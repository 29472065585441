import { LoadingListItemRedux } from 'store/utils/LoadingListItemRedux';
import { getGroupedSheetPdf, sendSheetGroupReminder } from './actions';
import { ActionsReturnTypes } from '../../../store/utils';
import { generateGroupRowId } from './helpers';
import { IGroupedSheetCalculation } from './model';

export const downloadSheetPdfLoadingModule = new LoadingListItemRedux<
typeof getGroupedSheetPdf,
ActionsReturnTypes<typeof getGroupedSheetPdf>
>({
    actionCreator: getGroupedSheetPdf,
    moduleName: 'downloadSheets',
    getUniqKey: (action: ActionsReturnTypes<typeof getGroupedSheetPdf>) => generateGroupRowId({
        time_sheet_id: action.payload.timeSheetId,
        expense_sheet_id: action.payload.expenseSheetId,
    } as IGroupedSheetCalculation),
});

export const sendReminderLoadingModule = new LoadingListItemRedux<
typeof sendSheetGroupReminder,
ActionsReturnTypes<typeof sendSheetGroupReminder>
>({
    actionCreator: sendSheetGroupReminder,
    moduleName: 'sendReminder',
    getUniqKey: (action: ActionsReturnTypes<typeof sendSheetGroupReminder>) => generateGroupRowId({
        time_sheet_id: action.payload.timeSheetId,
        expense_sheet_id: action.payload.expenseSheetId,
    } as IGroupedSheetCalculation),
});
