import React from 'react';
import { IPayrollTimeExpenseRow } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/common/SheetEntryTable';
import PlainText from 'shared/components/table/Cells/PlainText';
import { getUserName } from 'shared/utils/converters/user';

export const CalculationEntryManagerCell = ({
    className,
    approver,
}: IPayrollTimeExpenseRow) => {
    return (
        <PlainText className={className} value={approver ? getUserName(approver) : ''}/>
    );
};
