import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { EAF_APPROVAL_TYPES } from 'modules/eafApproval/store/model/data';
import { useIsEafRetroEnabledForClient } from 'modules/employeeActionsForm/store/hooks';
import { EafType, eafTypeDisplayValues } from 'modules/employeeActionsForm/store/models';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { FormSwitchField } from 'modules/settings/submodules/clients/appConfiguration/components/FormSwitchField';
import React from 'react';
import { setEafApprovalFilter } from 'modules/eafApproval/store/actions';
import { selectEafApprovalGridFilter } from 'modules/eafApproval/store/selectors';
import { useSelector } from 'react-redux';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import { useFilterContentStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import { StateFilterButtonWithForm } from 'shared/components/filters/FilterButtonAndForm/StateFilterButtonWithForm';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { UserType } from 'store/entities/users/model';

export const EafApprovalGridFilter: React.FC = () => {
    const classes = useFilterContentStyles();
    const currentClientId = useSelector(selectCurrentClientId);

    const isRetroEnabled = useIsEafRetroEnabledForClient();

    return (
        <StateFilterButtonWithForm
            filterSelector={selectEafApprovalGridFilter}
            setFilterAction={setEafApprovalFilter}
        >
            <UserAutocompleteWithSearch
                additionalFilter={{
                    client_id: currentClientId || undefined,
                    user_type: UserType.Employee,
                }}
                name="employeeUserId"
                label="Find an employee"
                useIdValue
                className={clsx(classes.selectField, classes.field)}
            />

            <EnumFormSelect
                name="type"
                values={EafType}
                displayValues={eafTypeDisplayValues}
                allowedValues={EAF_APPROVAL_TYPES}
                label="Type"
                className={clsx(classes.selectField, classes.field)}
            />

            {isRetroEnabled && (
                <Box ml={1}>
                    <FormFieldWithSeparateLabel
                        name="isRetro"
                        outerLabel="Only retro"
                        FieldComponent={FormSwitchField}
                        containerClassName={classes.field}
                    />
                </Box>
            )}
        </StateFilterButtonWithForm>
    );
};
