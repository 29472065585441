import { createSelector } from 'reselect';
import { selectCostCenterFilter } from 'modules/settings/submodules/clients/costCenters/components/Filter/store/selector';
import { isContainSubstring } from 'shared/utils/helpers/isContainSubstring';
import { selectCostCenters } from 'store/entities/configuration/configurationSelectors';

export const selectFilteredCostCenters = (
    createSelector(
        selectCostCenters,
        selectCostCenterFilter,
        (costCenters, costCenterFilter) => {
            if (!costCenterFilter){
                return costCenters;
            }
            return costCenters.filter(costCenter => {
                // @ts-ignore
                return isContainSubstring(costCenter.number.toString(), costCenterFilter)
                    // @ts-ignore
                    || isContainSubstring(costCenter.description.toString(), costCenterFilter);
            });
        },
    )
);
