export interface IControllingOrgFormValues {
    name: string;
    b_number: string;
    address: string;
    city: string;
    state: string;
    zip_code: string;
    county: string;
}

export const defaultControllingOrgFormValues: IControllingOrgFormValues = {
    name: '',
    b_number: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    county: '',
};
