import { useReducer, useRef, useLayoutEffect, useEffect, ChangeEvent } from 'react';
import { timeFormat } from './cleanByIndexAndMask';

const accept = /[\dapAPM]/g;

export interface ITime12Hours {
    value: string;
    onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}
type RenderProps = {
    value: string;
    onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
};

type CurrentValueRef = [string, HTMLInputElement, boolean, boolean, ChangeEvent<HTMLInputElement>]

/**
 * Create string from only accepted symbols
 * @param {string} str - modifiable string
 */
const clean = (str: string) => (str.match(accept) || []).join('');

/**
 * Get position for cursor in input
 * @param {string} formattedValue - formatted for 12-hours time string
 * @param valueBeforeSelectionStart - current value from input event after cleaning and slicing before cursor position
 */
const getCursorPosition = (formattedValue: string, valueBeforeSelectionStart: string) => {
    let start = 0;
    let cleanPos = 0;

    const cleanedVal = clean(formattedValue);
    // Going through inputted value or displayed until all of them are finished
    // It's need for symbol "M" in meridian part. It isn't entered by keyboard but displayed
    // If we run only through event value (as rifm do) we'll have cursor inside meridian block (xx:xxP|M)
    const loopEnd = Math.max(valueBeforeSelectionStart.length, cleanedVal.length);
    // try to find new cursor position step-by-step
    for (let i = 0; i !== loopEnd; ++i) {
        // Getting last char before cursor.
        // It can be inputted by user or attached by algorithm (as M in meridian block)
        const charBeforeSelectionStart = valueBeforeSelectionStart[i] || cleanedVal[i];
        let newPos = formattedValue.indexOf(charBeforeSelectionStart, start) + 1;
        let newCleanPos = cleanedVal.indexOf(charBeforeSelectionStart, cleanPos) + 1;
        if (newCleanPos - cleanPos > 1) {
            newPos = start;
            newCleanPos = cleanPos;
        }
        cleanPos = Math.max(newCleanPos, cleanPos);
        start = Math.max(start, newPos);
    }
    return start;
};

export const useTime12Hours = (props: ITime12Hours): RenderProps => {
    const [, refresh] = useReducer(c => c + 1, 0);
    const valueRef = useRef<CurrentValueRef | null>(null);
    const userValue = props.value;

    // State of delete button
    // There is no way I found to distinguish in onChange backspace or delete was called in some situations
    // Firefox track https://bugzilla.mozilla.org/show_bug.cgi?id=1447239
    const isDeleteButtonDownRef = useRef<boolean>(false);

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const target = evt.target;
        const eventValue = target.value.toUpperCase();
        evt.persist();

        valueRef.current = [
            eventValue, // current value of input from event
            target, // input
            isDeleteButtonDownRef.current, // is delete button clicked
            userValue === timeFormat(eventValue, userValue), // input value isn't formatted
            evt, // change event
        ];
        refresh();
    };

    useLayoutEffect(() => {
        // initialization
        if (valueRef.current === null) {
            return;
        }

        const [
            eventValue,
            input,
            isDeleteButtonDown,
            isNoOperation,
            evt,
        ] = valueRef.current;
        valueRef.current = null;

        // Flag marks that it was pressed delete key in position before symbol ':' or mask part ('_')
        // It means that there is no effects on next symbol and we need to find next valued symbol
        const deleteWasNoOp = isDeleteButtonDown && isNoOperation;
        const valueAfterSelectionStart = eventValue.slice(input.selectionStart || undefined);
        // Searching next valued (accepted) symbol after cursor position
        const acceptedCharIndexAfterDelete = valueAfterSelectionStart.search(accept);
        // Calculating how many symbols we need to skip  after cursor if key Delete clicked
        const charsToSkipAfterDelete = acceptedCharIndexAfterDelete !== -1 ? acceptedCharIndexAfterDelete : 0;
        const valueBeforeSelectionStart = clean(eventValue.substr(0, input.selectionStart || undefined));

        const formattedValue = timeFormat(eventValue, userValue);
        if (userValue === formattedValue) {
            // if nothing changed for formatted value, just refresh so userValue will be used at render
            refresh();
        } else {
            const newEvent = {
                ...evt,
                target: {
                    ...input,
                    value: formattedValue,
                },
            };
            props.onChange(newEvent);
        }

        return () => {
            const start = getCursorPosition(formattedValue, valueBeforeSelectionStart);
            input.selectionStart = input.selectionEnd = start + (deleteWasNoOp ? 1 + charsToSkipAfterDelete : 0);
        };
    });

    // Catching delete keyboard events for exact determination of initted changes
    useEffect(() => {
        const handleKeyDown = (evt: KeyboardEvent) => {
            if (evt.code === 'Delete') {
                isDeleteButtonDownRef.current = true;
            }
        };
        const handleKeyUp = (evt: KeyboardEvent) => {
            if (evt.code === 'Delete') {
                isDeleteButtonDownRef.current = false;
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    return {
        value: valueRef.current !== null ? valueRef.current[0] : userValue,
        onChange,
    };
};
