import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';

import SubmittingOrgFormWrapper
    from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgForm/SubmittingOrgFormWrapper';
import { ISubmittingOrgFormValues } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgForm/model';
import {
    selectEditSubmittingOrgId,
    selectIsSubmittingOrgUpdating,
} from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/selectors';
import {
    setEditSubmittingOrgId,
    updateSubmittingOrg,
} from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/actions';
import { selectSubmittingOrgById } from 'store/entities/configuration/configurationSelectors';
import { IUpdateSubmittingOrgRequest } from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/models';
import { Status } from 'shared/models/Status';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export default function EditSubmittingOrgModal() {
    const modalClasses = useColoredFormModalStyles();
    const editSubmittingOrgId = useSelector(selectEditSubmittingOrgId);
    const isLoading = useSelector(selectIsSubmittingOrgUpdating);
    const editSubmittingOrg = useSelector(selectSubmittingOrgById(editSubmittingOrgId));
    const dispatch = useDispatch();
    const onSave = useCallback((formValues: ISubmittingOrgFormValues) => {
        const { zip_code, county, status, branch_id, ...valuesCleared } = formValues;
        const data = {
            ...valuesCleared,
            branch_id: branch_id || null,
            active: status !== Status.inactive,
            zip: zip_code,
        } as IUpdateSubmittingOrgRequest;
        dispatch(updateSubmittingOrg.init({
            id: editSubmittingOrgId,
            data,
        }));
    }, [dispatch, editSubmittingOrgId]);
    const onClose = useCallback(() => {
        dispatch(setEditSubmittingOrgId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit Submitting Org"
            isOpened={Boolean(editSubmittingOrgId)}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <SubmittingOrgFormWrapper
                    submitTitle="Save"
                    onSubmit={onSave}
                    onCancel={onClose}
                    isLoading={isLoading}
                    submittingOrg={editSubmittingOrg}
                />
            </Box>
        </ModalDialog>
    );
}
