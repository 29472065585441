import React from 'react';
import { settingsRoutes } from 'modules/settings/routes';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { ScopesDashboard } from 'modules/settings/submodules/clients/scopes/components/ScopesDashboard/ScopesDashboard';
import { TypedScopesPage } from 'modules/settings/submodules/clients/scopes/components/TypedScopesPage/TypedScopesPage';

export const ScopesRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.SCOPES.TYPE}
                component={TypedScopesPage}
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.SCOPES.ROOT}
                component={ScopesDashboard}
            />
        </Switch>
    );
};
