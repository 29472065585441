import React from 'react';
import { Box } from '@material-ui/core';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useCounterStyles = makeHighPriorityStyles(theme => ({
    counter: {
        color: theme.palette.primary.main,
        fontSize: 24,
    },
    counterBox: {
        fontWeight: 'bold',
    },
}));

interface ISelectedCounterBoxProps {
    count: number,
    entityName: string
}

export const SelectedCounterBox = ({ count, entityName }: ISelectedCounterBoxProps) => {
    const classes = useCounterStyles();

    return (
        <Box mb={5} className={classes.counterBox}>
            <span className={classes.counter}>{count}</span>
            {' '}{pluralize(entityName, count)} selected
        </Box>
    );
};
