import { ICreateCustomFieldValueRequest, IUpdateCustomFieldValuePayloadRequest } from 'modules/settings/submodules/clients/customFieldValues/store/models';
import { ICustomFieldValue, IProjectClass } from 'store/entities/customFields/model';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

export const createCustomFieldValue = createRequestActions<ICreateCustomFieldValueRequest, ICustomFieldValue>(
    RequestType.Create,
    'CUSTOM_FIELD_VALUE',
    'settings/customFieldValues',
);

export const updateCustomFieldValue = createRequestActions<IUpdateCustomFieldValuePayloadRequest, ICustomFieldValue>(
    RequestType.Update,
    'CUSTOM_FIELD_VALUE',
    'settings/customFieldValues',
);

const SET_CUSTOM_FIELD_ID = 'settings/customFieldValues/SET_CUSTOM_FIELD_ID';
export const setCustomFieldId = createSingleAction<string | null, typeof SET_CUSTOM_FIELD_ID>(
    SET_CUSTOM_FIELD_ID,
);

const SET_CREATE_CUSTOM_FIELD_VALUE_MODAL_STATE = 'settings/customFieldValues/SET_CREATE_CUSTOM_FIELD_VALUE_MODAL_STATE';
export const setCreateCustomFieldValueModalState = createSingleAction<
boolean,
typeof SET_CREATE_CUSTOM_FIELD_VALUE_MODAL_STATE
>(
    SET_CREATE_CUSTOM_FIELD_VALUE_MODAL_STATE,
);

const SET_EDIT_CUSTOM_FIELD_VALUE_ID = 'settings/customFieldValues/SET_EDIT_CUSTOM_FIELD_VALUE_ID';
export const setEditCustomFieldValueId = createSingleAction<string | null, typeof SET_EDIT_CUSTOM_FIELD_VALUE_ID>(
    SET_EDIT_CUSTOM_FIELD_VALUE_ID,
);

export const getProjectClasses = createRequestActions<void, IProjectClass[]>(
    RequestType.Get,
    'PROJECT_CLASS',
);
