/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { setExpenseSheetsChecked, setTimeSheetsChecked } from 'modules/timeAndExpense/store/actions';
import {
    expenseSheetsSelectors,
    selectTimeAndExpenseActiveStatus,
    timeSheetsSelectors,
} from 'modules/timeAndExpense/store/selectors';
import { useSelector } from 'react-redux';
import {
    getApprovedLevelWithTotalCell,
    useDetailsCell,
    getHoursAmountCell,
    getReasonRejectionCell,
    getStatusCell,
    hoursAmountCellKey,
    isApprovedLevelCellAvailable,
} from 'shared/components/sheetApproval/bodyCells';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { getCheckboxCell } from 'shared/components/table/Cells/checkBoxHelper';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { useSheetColumnDictionary } from 'shared/components/table/SheetSupervisorTable/useSheetColumnDictionary';
import { getMobileItemsRowByConfig } from 'shared/components/table/utils';
import { IJobNumber } from 'shared/models/JobNumber';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { EntryType, ISheet, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { getLastFirstName } from 'shared/utils/converters/user';
import { areaView } from 'shared/utils/formatters/areaFormatter';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useCheckedItemsStore } from 'shared/utils/hooks/useCheckedItems';

import { AvailableTableConfiguration, IColumnConfiguration, SheetColumnSlug } from 'store/entities/clients/clientsModel';
import { IArea } from 'store/entities/configuration/configurationModel';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { getColumnsByConfiguration } from 'store/utils/tables';
import { useExtendedConfiguration } from '../../table/SheetSupervisorTable/useExtendedConfiguration';

export interface ISheetRow {
    id: string;
    sheet: ISheet;
    area?: IArea;
    user?: IUserInfo;
    className?: string;
    approvers: IUserInfo[];
    position?: IPosition;
    location?: ILocation;
    department?: IDepartment;
    jobNumber?: IJobNumber;
    calculation?: ITimesheetCalculation;
}

export const useCells = (
    entryType: EntryType,
    onDetailsClick?: (sheetInfo: ISheetClickInfo) => void,
): Array<ICellInfo<ISheetRow>> => {
    const isMobile = useIsMobile();
    const classes = useSubmittedTableStyles();
    const activeStatus = useSelector(selectTimeAndExpenseActiveStatus);
    const configuration = useExtendedConfiguration(
        entryType === EntryType.TIME
            ? AvailableTableConfiguration.EmployeeTimeSheet : AvailableTableConfiguration.EmployeeExpenseSheet,
    ) as IColumnConfiguration<SheetColumnSlug>[];
    const baseSelector = entryType === EntryType.TIME ? timeSheetsSelectors : expenseSheetsSelectors;
    const rows = useSelector(baseSelector.selectTableRows);
    const checkItemsUtils = useCheckedItemsStore<ISheetRow>(
        rows,
        baseSelector.selectCheckedSingleSheets,
        entryType === EntryType.TIME ? setTimeSheetsChecked : setExpenseSheetsChecked,
    );

    const columnDictionary = useSheetColumnDictionary(isMobile);
    const hasFiles = useMemo(() => {
        return Boolean(rows.find(row => row.sheet?.total_files));
    }, [rows]);
    const hasHours = useMemo(() => {
        return Boolean(rows.find(row => row.sheet?.total_minutes));
    }, [rows]);
    const detailsCell = useDetailsCell(classes, entryType, onDetailsClick, isMobile);

    return useMemo(() => {
        const checkboxCellWrapper = [StatusNames.SUBMITTED, StatusNames.REJECTED].includes(activeStatus) ? [
            getCheckboxCell<ISheetRow>(
                checkItemsUtils.checkedItems,
                checkItemsUtils.onCheck,
                checkItemsUtils.checkedAll,
                checkItemsUtils.onCheckAll,
                classes,
                isMobile,
                row => row.id,
            ),
        ] : [];
        const getHoursAmountCellByTitle = getHoursAmountCell(classes, entryType, isMobile);
        const statusCell = getStatusCell(classes, activeStatus === StatusNames.ALL);
        const approvedLevelCell = getApprovedLevelWithTotalCell();
        const approvedLevelCells = isApprovedLevelCellAvailable(activeStatus) ? [approvedLevelCell] : [];
        const reasonRejectionCell = getReasonRejectionCell(activeStatus === StatusNames.REJECTED);
        const columnsByConfiguration = getColumnsByConfiguration(configuration, columnDictionary, classes);
        columnsByConfiguration.splice(columnsByConfiguration.length - 2, 0, ...reasonRejectionCell);

        const cells = isMobile ? [
            ...checkboxCellWrapper,
            {
                key: 'fullEmployeeInfo',
                title: '',
                width: '2fr',
                render: ({
                    sheet,
                    area,
                    position,
                    className,
                    approvers,
                }: ISheetRow) => (
                    <MobileListCell
                        title={getMobileItemsRowByConfig(
                            [
                                {
                                    slug: SheetColumnSlug.Area,
                                    getText: () => areaView(area),
                                },
                                {
                                    slug: SheetColumnSlug.Position,
                                    getText: () => position?.name,
                                },
                            ],
                            configuration,
                        )}
                        className={className}
                        items={[
                            getPayPeriodByStartEnd(sheet.period_start, sheet.period_end),
                            approvers.map(approver => getLastFirstName(approver)).join(', '),
                        ]}
                    />
                ),
            },
            getHoursAmountCellByTitle(entryType === EntryType.TIME ? 'hours' : 'amount'),
            detailsCell,
        ] : [
            ...checkboxCellWrapper,
            ...columnsByConfiguration,
            ...statusCell,
            ...approvedLevelCells,
            detailsCell,
        ];
        if (entryType === EntryType.TIME && hasFiles) {
            /**
             * Patch cell header for files
             */
            const hoursAmountCell = cells.find(cell => cell.key === hoursAmountCellKey);
            const filesCellHeader = 'files';
            if (hoursAmountCell) {
                if (hasHours) {
                    hoursAmountCell['title'] = `${hoursAmountCell['title']}/${filesCellHeader}`;
                } else {
                    hoursAmountCell['title'] = filesCellHeader;
                }
            }
        }
        return cells;
    }, [
        classes,
        isMobile,
        entryType,
        configuration,
        columnDictionary,
        activeStatus,
        hasFiles,
        hasHours,
        checkItemsUtils,
        detailsCell,
    ]);
};
