import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Schema } from 'yup';
import { MultiStepFormStepWrapper } from './MultiStepFormStepWrapper';

export interface IMultiStepFormStepProps {
    step: number;
    forceValidate?: () => void;
}

export type MultiStepFormValues<FormValues> = FormValues & { [x: string]: string }

interface IMultiStepFormStep<FormValues> {
    name: string;
    component: React.ComponentType<FormikProps<MultiStepFormValues<FormValues>> & IMultiStepFormStepProps>;
}

export interface IMultiStepFormProps<FormValues> {
    NextButton: React.ComponentType<React.HTMLProps<HTMLButtonElement>>;
    FinalStepBlock: React.ComponentType;
    steps: IMultiStepFormStep<FormValues>[];
    initialValues: FormValues;
    onSubmit: (values: FormValues) => void;
    step?: number;
    onSetStep?: (value: number) => void;
    stepFieldName?: string;
    validationSchema: Schema<FormValues>;
}

export function MultiStepForm<FormValues>({
    NextButton,
    onSubmit,
    FinalStepBlock,
    step,
    onSetStep,
    steps,
    stepFieldName = 'step',
    initialValues,
    validationSchema,
}: IMultiStepFormProps<FormValues>) {

    const [stepIndex, setStep] = useState<number>(step || 0);
    useEffect(() => {
        if (step !== undefined) {
            setStep(step);
        }
    }, [step]);

    const stepCount = useMemo(() => steps.length, [steps]);
    const { component: StepContent, name: stepName } = useMemo(() => {
        return steps[stepIndex];
    }, [stepIndex, steps]);

    const onStepSubmit = useCallback((values, actions) => {
        if (stepIndex < stepCount - 1) {
            const nextStep = stepIndex + 1;
            setStep(nextStep);
            if (typeof onSetStep === 'function') {
                onSetStep(nextStep);
            }
            actions.setTouched({});
            actions.setSubmitting(false);
        } else {
            const { [stepFieldName]: stepField, ...valuedValues } = values;
            onSubmit(valuedValues);
        }
    }, [stepFieldName, setStep, onSubmit, stepIndex, stepCount, onSetStep]);

    return (
        <Formik
            initialValues={{ ...initialValues, [stepFieldName]: stepName }}
            validationSchema={validationSchema}
            onSubmit={onStepSubmit}
            validateOnBlur={false}
        >
            {formProps => (
                <MultiStepFormStepWrapper
                    {...formProps}
                    stepFieldName={stepFieldName}
                    stepFieldValue={stepName}
                >
                    <form onSubmit={formProps.handleSubmit}>
                        <StepContent
                            {...formProps}
                            step={stepIndex}
                            forceValidate={formProps.validateForm}
                        />
                        {
                            (stepIndex < stepCount - 1)
                                ? (
                                    <NextButton onClick={formProps.submitForm}/>
                                )
                                : (
                                    <FinalStepBlock/>
                                )}

                    </form>
                </MultiStepFormStepWrapper>
            )}
        </Formik>
    );
}
