import React, { useEffect } from 'react';
import { Box, Grid, GridProps } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { useDispatch, useSelector } from 'react-redux';
import MoneyField from 'shared/components/formFields/MoneyField';
import { TextTagField } from 'shared/components/formFields/TextTagField';
import { StateCodeSelect } from 'shared/components/selects/StateCodeSelect';
import { ISCACustomField } from 'store/entities/customFields/model';
import FormField from 'shared/components/formFields/FormField';
import { getStates } from 'store/entities/scaZone/actions';
import { selectIsStatesLoading, selectStateCodes } from 'store/entities/scaZone/selectors';

const makePathTemplate = (path: string) => (prop: keyof ISCACustomField) => `${path}${prop}`;

const gridItemProps: Partial<GridProps> = {
    sm: 6,
    xs: 12,
    item: true,
};

export const SCACustomFieldValueDataSection: React.FC<{ path?: string }> = ({ path = '' }) => {
    const classes = useSettingsFormStyles();
    const withPath = makePathTemplate(path);
    const dispatch = useDispatch();
    const usStateCodes = useSelector(selectStateCodes);
    const isStatesLoading = useSelector(selectIsStatesLoading);

    useEffect(() => {
        if (!usStateCodes.length && !isStatesLoading) {
            dispatch(getStates.init());
        }
    }, [dispatch, usStateCodes.length, isStatesLoading]);

    return (
        <Box className={classes.formBlock}>

            <Grid container>

                <Grid {...gridItemProps}>
                    <FormField
                        name={withPath('name')}
                        label="Name"
                        className={classes.input}
                    />
                </Grid>

                <Grid {...gridItemProps}>
                    <FormField
                        name={withPath('wage_determination_number')}
                        label="Wage Determination Number"
                        className={classes.input}
                    />
                </Grid>

                <Grid {...gridItemProps} sm={3}>
                    <MoneyField
                        name={withPath('pay_rate')}
                        label="Pay Rate"
                        className={classes.input}
                    />
                </Grid>

                <Grid {...gridItemProps} sm={3}>
                    <MoneyField
                        name={withPath('health_and_welfare_rate')}
                        label="Health & Welfare Rate"
                        className={classes.input}
                    />
                </Grid>

                <Grid {...gridItemProps}>
                    <StateCodeSelect
                        name={withPath('state')}
                        label="State"
                        className={classes.input}
                    />
                </Grid>

                <Grid {...gridItemProps} sm={12}>
                    <TextTagField
                        name={withPath('counties')}
                        label="Counties"
                        className={classes.input}
                    />
                </Grid>

                <Grid {...gridItemProps} sm={12}>
                    <TextTagField
                        name={withPath('positions')}
                        label="Positions"
                        className={classes.input}
                    />
                </Grid>

            </Grid>

        </Box>
    );
};
