import React from 'react';
import { useSelector } from 'react-redux';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import Sidebar, { Anchor, ISidebarProps } from 'shared/components/sidebars/Sidebar/Sidebar';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/selectors/clientsSelectors';
import AddEntrySave from 'shared/components/sidebars/AddEntry/AddEntrySave';
import { AddNewTravelExpenseSidebarContent } from 'modules/travelExpenses/components/Create/AddNewTravelExpenseSidebar/AddNewTravelExpenseSidebarContent';

interface IAddNewTravelExpenseSidebarProps extends Omit<ISidebarProps, 'title' | 'children' | 'anchor'> {
    anchor: Anchor;
}

export function AddNewTravelExpenseSidebar({
    onClose,
    ...sidebarProps
}: IAddNewTravelExpenseSidebarProps) {
    const editEntryClasses = useEntryEditStyles();
    const clientId = useSelector(selectCurrentClientId);
    const configuration = useSelector(selectFieldConfiguration(clientId || ''));
    return (
        <Sidebar title={'CREATE TRAVEL EXPENSE'}
            anchor={'right'}
            onClose={onClose}
            customClasses={editEntryClasses.paper}
            {...sidebarProps}>
            <>
                {configuration && (
                    <AddNewTravelExpenseSidebarContent
                        SubmitBlock={AddEntrySave}
                        onClose={onClose}
                        inputs={configuration.inputs.expense}
                    />
                )}

            </>
        </Sidebar>
    );
}
