import { IEntity } from 'shared/models/Entity';
import { Status } from 'shared/models/Status';

export enum RecipientType {
    ControllingOrg = 'controlling_org',
    SubmittingOrg = 'submitting_org',
}

export interface IInvoiceRecipient {
    recipient_id: string;
    recipient_type: RecipientType;
}

export interface IDeal extends IEntity {
    deal_number: string;
    type_id: string;
    segment_id: string;
    controlling_org_id: string;
    submitting_org_id: string;
    recipient: IInvoiceRecipient,
    cost_center_id: string;
    status: Status,
    client_id: string;
    created_at: string;
    updated_at: string;
}
