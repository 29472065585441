import { Nullable } from '../../../../../types/types';
import { findUniqFilteredItems } from './findUniqFilteredItems';

export function concatBatchProps<TItem>(
    items: Nullable<Array<TItem>>,
    prop: keyof TItem,
): string {
    const selectedItems = findUniqFilteredItems(items, prop);
    const result = selectedItems.join(', ');
    return result || '';
}
