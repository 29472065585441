import { call, put, takeLatest } from 'typed-redux-saga';

import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { getPhysicalDemands, removePhysicalDemandsTemplate } from 'store/entities/configuration/configurationAction';
import { physicalDemandsApi } from 'modules/settings/submodules/clients/physicalDemands/store/api';
import {
    createPhysicalDemandsTemplate,
    setCreatePhysicalDemandsTemplateModalState, setEditPhysicalDemandsTemplateId, updatePhysicalDemandsTemplate,
} from 'modules/settings/submodules/clients/physicalDemands/store/action';

function* createPhysicalDemandsSaga({ payload }: ReturnType<typeof createPhysicalDemandsTemplate.init>) {
    const template = yield* call(physicalDemandsApi.createPhysicalDemandsTemplate, payload);
    yield put(createPhysicalDemandsTemplate.success());
    yield put(getPhysicalDemands.success([template]));
    yield put(setCreatePhysicalDemandsTemplateModalState(false));
}

function* createPhysicalDemandsWatcher() {
    yield* takeLatest(
        createPhysicalDemandsTemplate.initType,
        withBackendErrorHandler(
            createPhysicalDemandsSaga,
            createPhysicalDemandsTemplate.error,
            'Unable to create Physical Demands Template',
        ),
    );
}

function* updatePhysicalDemandsTemplateSaga({ payload }: ReturnType<typeof updatePhysicalDemandsTemplate.init>) {
    const { id, data } = payload;
    const template = yield* call(physicalDemandsApi.updatePhysicalDemandsTemplate, id, data);
    yield put(updatePhysicalDemandsTemplate.success());
    yield put(getPhysicalDemands.success([template]));
    yield put(setEditPhysicalDemandsTemplateId(null));
}

function* updatePhysicalDemandsWatcher() {
    yield* takeLatest(
        updatePhysicalDemandsTemplate.initType,
        withBackendErrorHandler(
            updatePhysicalDemandsTemplateSaga,
            updatePhysicalDemandsTemplate.error,
            'Unable to update Physical Demands Template',
        ),
    );
}

function* deletePhysicalDemandsTemplateSaga(
    { payload: id }: ReturnType<typeof removePhysicalDemandsTemplate.init>,
) {
    yield* call(physicalDemandsApi.deletePhysicalDemandsTemplate, id);
    yield put(removePhysicalDemandsTemplate.success(id));
}

function* deletePhysicalDemandsTemplateWatcher() {
    yield* takeLatest(
        removePhysicalDemandsTemplate.initType,
        withBackendErrorHandler(
            deletePhysicalDemandsTemplateSaga,
            removePhysicalDemandsTemplate.error,
            'Unable to delete Physical Demands Template',
        ),
    );
}

export default [
    createPhysicalDemandsWatcher,
    updatePhysicalDemandsWatcher,
    deletePhysicalDemandsTemplateWatcher,
];
