import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const useCheckBoxStyles = makeHighPriorityStyles((theme: Theme) => ({
    checkBoxCell: {
        padding: theme.spacing(0),
        width: theme.spacing(7),
        textAlign: 'left',
        cursor: 'pointer',
    },
    cbHeaderCell: {
        backgroundColor: colors.lightGray,
    },
    checkBoxIcon: {
        verticalAlign: 'middle',
    },
    root: {
        color: colors.green,
    },
    blue: {
        color: `${colors.blue} !important`,
    },
}));

export default useCheckBoxStyles;
