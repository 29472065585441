import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import { getDisplayCustomFieldsValues } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import {
    IBulkEditSubassignmentsResponse,
    IEditSubassignmentReport,
    IBulkDeleteSubassignmentsResponse,
    IDeleteSubassignmentReport,
} from 'modules/subassignmentManagement/store/models';
import { selectSubassignmentManagementState } from 'modules/subassignmentManagement/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { selectCustomFieldValuesByIds, selectFirstTwoRootCustomFields } from 'store/entities/customFields/selectors';
import { selectUsersById } from 'store/entities/users/selectors';

export const selectEditSubAssignmentState = (state: IStore) =>
    selectSubassignmentManagementState(state).editSubassignments;

export const selectIsOpenModal = (state: IStore) => selectEditSubAssignmentState(state).isOpenBulkEditModal;
export const selectEditStep = (state: IStore): BulkEditSubassignmentSteps => selectEditSubAssignmentState(state).step;

export const selectIsLoadingEditPreInitialize = (state: IStore) =>
    selectEditSubAssignmentState(state).isLoadingEditPreInitializeReport;
export const selectIsLoadingEditPreInitializePdf = (state: IStore) =>
    selectEditSubAssignmentState(state).isLoadingEditPreInitializePdfReport;

export const selectIsLoadingDeletePreInitialize = (state: IStore) =>
    selectEditSubAssignmentState(state).isLoadingDeletePreInitializeReport;

export const getEditReportRowsSelector = (baseReportSelector: (state: IStore) => IBulkEditSubassignmentsResponse) => {
    return createSelector(
        baseReportSelector,
        selectFirstTwoRootCustomFields,
        selectCustomFieldValuesByIds,
        selectAssignmentsById,
        selectUsersById,
        (report, displayCustomFields, customFieldValuesByIds, assignmentsByIds, usersByIds) => {
            return (report?.subassignments || []).map((subassignment: IEditSubassignmentReport) => {
                const displayCustomFieldValues = getDisplayCustomFieldsValues(
                    displayCustomFields,
                    customFieldValuesByIds,
                    subassignment.new_custom_field_value_ids || [],
                    subassignment.new_all_values_custom_field_ids || [],
                );
                const assignment = assignmentsByIds[subassignment.assignment_id];
                const difference = parseFloat(subassignment?.new_override_rate_value) - assignment?.pay_rate_value || 0;
                const employee = usersByIds[assignment?.user_id];
                return {
                    ...subassignment,
                    displayCustomFieldValues,
                    difference,
                    employee,
                };
            });
        },
    );
};

const getDeleteReportRowsSelector = (baseReportSelector: (state: IStore) => IBulkDeleteSubassignmentsResponse) => {
    return createSelector(
        baseReportSelector,
        selectFirstTwoRootCustomFields,
        selectCustomFieldValuesByIds,
        selectAssignmentsById,
        selectUsersById,
        (report, displayCustomFields, customFieldValuesByIds, assignmentsByIds, usersByIds) => {
            return (report?.subassignments || []).map((subassignment: IDeleteSubassignmentReport) => {
                const displayCustomFieldValues = getDisplayCustomFieldsValues(
                    displayCustomFields,
                    customFieldValuesByIds,
                    subassignment.custom_field_value_ids,
                    subassignment.all_values_custom_field_ids || [],
                );
                const assignment = assignmentsByIds[subassignment.assignment_id];
                const difference = parseFloat(subassignment?.override_rate_value) - assignment?.pay_rate_value || 0;
                const employee = usersByIds[assignment?.user_id];
                return {
                    ...subassignment,
                    displayCustomFieldValues,
                    difference,
                    employee,
                };
            });
        },
    );
};

export const selectIsBulkBeingUpdated = (state: IStore) =>
    selectEditSubAssignmentState(state).isBulkUpdating;
export const selectPreInitializeEditReportState = (state: IStore) =>
    selectEditSubAssignmentState(state).editPreInitializeReport;
export const selectEditReportState = (state: IStore) =>
    selectEditSubAssignmentState(state).editReport;
export const selectEditReportV2State = (state: IStore) =>
    selectEditSubAssignmentState(state).editReportV2;

export const selectPreInitializeEditReportTableRows = getEditReportRowsSelector(selectPreInitializeEditReportState);
export const selectEditReportTableRows = getEditReportRowsSelector(selectEditReportState);

export const selectIsBulkBeingDeleted = (state: IStore) =>
    selectEditSubAssignmentState(state).isBulkDeleting;
export const selectPreInitializeDeleteReportState = (state: IStore) =>
    selectEditSubAssignmentState(state).deletePreInitializeReport;
export const selectDeleteReportState = (state: IStore) =>
    selectEditSubAssignmentState(state).deleteReport;

export const selectPreInitializeDeleteReportTableRows = getDeleteReportRowsSelector(
    selectPreInitializeDeleteReportState);
export const selectDeleteReportTableRows = getDeleteReportRowsSelector(
    selectDeleteReportState);
