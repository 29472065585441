import React, { useCallback, useEffect } from 'react';
import { selectIsEafCreating } from 'modules/employeeActionsForm/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { selectAssignmentInfo, selectDetailAssignment } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { selectAssignmentMaxEntryDate, selectRelatedSubassignments } from 'modules/employeeActionsForm/components/EndAssignment/store/selectors';
import { EndAssignmentForm, IEmployeeActionFormDataModel } from 'modules/employeeActionsForm/components/EndAssignment/EndAssignmentForm';
import { EafType, IEafAssignmentEndData } from 'modules/employeeActionsForm/store/models';
import { Status } from 'shared/models/Status';
import { createEmployeeActionForm, getAssignmentMaxEntryDate } from 'modules/employeeActionsForm/store/actions';
import { WorkspaceSidebar } from 'shared/components/workspaces/WorkspaceSidebar/WorkspaceSidebar';

interface ITerminationSidebarProps {
    isOpened: boolean;
    onClose: () => void;
}

export const TerminationSidebar = (props: ITerminationSidebarProps) => {
    const dispatch = useDispatch();
    const assignment = useSelector(selectDetailAssignment);
    const assignmentInfo = useSelector(selectAssignmentInfo);
    const relatedSubassignments = useSelector(selectRelatedSubassignments);
    const isLoading = useSelector(selectIsEafCreating);
    const assignmentMaxEntryDate = useSelector(selectAssignmentMaxEntryDate);

    useEffect(() => {
        if (assignment?.id) {
            dispatch(getAssignmentMaxEntryDate.init(assignment.id));
        }
    }, [assignment, dispatch]);

    const onSubmit = useCallback((values: IEmployeeActionFormDataModel) => {
        const { eligibleForRehire, attachments, ...rest } = values;
        dispatch(createEmployeeActionForm.init({
            // @ts-ignore
            assignment_id: assignment?.id,
            employee_action_form_data: {
                ...rest,
                eligible_for_rehire: eligibleForRehire === Status.active,
            } as IEafAssignmentEndData,
            attachments,
        }));
    }, [dispatch, assignment]);

    return (
        <WorkspaceSidebar
            title={assignmentInfo.employeeName || 'Termination'}
            {...props}
        >
            <Box m={3}>
                <EndAssignmentForm
                    isLoading={isLoading}
                    onCancel={props.onClose}
                    relatedSubassignments={relatedSubassignments}
                    assignment={assignment}
                    assignmentMaxEntryDate={assignmentMaxEntryDate}
                    initialValues={{
                        employee_action_form_type: EafType.Termination,
                        employee_action_form_reason: null,
                        effective_date: '',
                        eligibleForRehire: null,
                        attachments: [],
                    }}
                    onSubmit={onSubmit}
                    isTermination
                />
            </Box>
        </WorkspaceSidebar>
    );
};
