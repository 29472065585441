import React, { useCallback, useMemo, useState } from 'react';
import { FormControl } from '@material-ui/core';
import { useField } from 'formik';
import { uploadFile } from 'shared/components/formFields/AttachmentInput/utils';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FileInput, { IFileInputProps } from 'shared/components/attachments/FileInput';
import { IAttachment } from 'shared/models/Attachments';

export interface IAttachmentInputProps extends IFormFieldProps, Omit<IFileInputProps, 'attachments' | 'onAttachmentChange' | 'onAttachmentRemove'> {
    name: string;
    info?: React.ReactNode;
    multiple?: boolean;
    relatedEntityId?: string;
    relatedEntityType: string;
    onAttachmentChange?: (files: IAttachment[]) => void;
    onAttachmentRemove?: (files: IAttachment) => void;
    showModalFromCount?: number;
}

export const AttachmentInput = ({
    name,
    id = String(name),
    className,
    multiple,
    info,
    relatedEntityId,
    relatedEntityType,
    ...props
}: IAttachmentInputProps) => {
    const [field, meta, helper] = useField(name);

    const initialValue = useMemo(() => {
        return multiple ? field.value : [field.value].filter(Boolean);
    }, [field.value, multiple]);
    const [attachments, setAttachments] = useState<IAttachment[]>(initialValue);
    const [isLoading, setLoading] = useState<boolean>(false);

    const setFieldValue = useCallback((newValues: IAttachment[]) => {
        const fieldValue = multiple ? newValues : newValues[0] || null;
        helper.setValue(fieldValue);
    }, [helper, multiple]);
    const propsOnAttachmentChange = props.onAttachmentChange;
    const onAttachmentChange = useCallback((files: File[]) => {
        if (files.length) {
            setLoading(true);
            Promise.all(files.map(file => {
                return uploadFile(
                    file,
                    {
                        related_entity_id: relatedEntityId,
                        related_entity_type: relatedEntityType,
                    },
                );
            })).then(uploadedAttachments => {
                const newValues = [
                    ...attachments,
                    ...uploadedAttachments.filter(Boolean) as IAttachment[],
                ];
                setAttachments(newValues);
                setFieldValue(newValues);
                if (propsOnAttachmentChange) {
                    propsOnAttachmentChange(newValues);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [attachments, relatedEntityId, relatedEntityType, setFieldValue, propsOnAttachmentChange]);
    const propsOnAttachmentRemove = props.onAttachmentRemove;
    const onAttachmentRemove = useCallback(toRemoveAttachment => {
        const newValues = attachments.filter(attachment => attachment.id !== toRemoveAttachment.id);
        setAttachments(newValues);
        setFieldValue(newValues);
        if (propsOnAttachmentRemove) {
            propsOnAttachmentRemove(toRemoveAttachment);
        }
    }, [attachments, setFieldValue, propsOnAttachmentRemove]);

    const error = meta.error && meta.touched ? meta.error : null;
    return (
        <FormControl
            className={className}
            variant="outlined"
            key={id}
        >
            <FileInput
                {...props}
                attachments={attachments}
                onAttachmentChange={onAttachmentChange}
                onAttachmentRemove={onAttachmentRemove}
                error={error}
                multiple={multiple}
                isLoading={isLoading}
            />
            {info}
        </FormControl>
    );
};
