import React from 'react';
import { Route } from 'react-router-dom';
import { RegistrationRedirect } from 'shared/components/auth/RegistrationRedirect';

export function RegistrationRoute({ path }: { path: string }) {
    return (
        <Route
            path={path}
            component={RegistrationRedirect}
        />
    );
}
