import React from 'react';
import { Box, Paper, Slide } from '@material-ui/core';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import SidebarHeader from 'shared/components/sidebars/Sidebar/SidebarHeader';
import { colors } from 'shared/styles/constants';
import { useIsMobile } from 'shared/utils/hooks/media';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

interface IWorkspaceSidebarProps {
    children: React.ReactNode,
    title: string;
    isOpened: boolean;
    onClose: () => void;
    onBack?: () => void;
    titleClasses?: Partial<{
        root: string;
        text: string;
        icon: string;
    }>;
}

const useStyles = makeHighPriorityStyles({
    paper: {
        border: `1px solid ${colors.darkGray1}`,
        maxHeight: '100%',
        display: 'flex',
        flexFlow: 'column',
        boxSizing: 'border-box',
        overflowY: 'auto',

        // absolute for stack sidebars
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
        zIndex: 1,
    },
    contentWrapper: {
        overflowY: 'auto',
    },
});

export const WorkspaceSidebar = ({
    isOpened,
    onClose,
    onBack,
    title,
    titleClasses,
    children,
}: IWorkspaceSidebarProps) => {
    const classes = useStyles();
    const isMobile = useIsMobile();
    if (!isMobile) {
        if (!isOpened) {
            return null;
        }
        return (
            <Slide
                direction="left"
                in
                mountOnEnter
                unmountOnExit
            >
                <Paper
                    variant="outlined"
                    square
                    className={classes.paper}
                >
                    <SidebarHeader
                        onBack={onBack}
                        title={title}
                        onClose={onClose}
                        titleClasses={titleClasses}
                        white
                        compact
                    />
                    <Box className={classes.contentWrapper}>
                        {children}
                    </Box>
                </Paper>
            </Slide>
        );
    }
    return (
        <Sidebar
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            titleClasses={titleClasses}
            whiteTitle
        >
            {children}
        </Sidebar>
    );
};
