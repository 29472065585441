import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const BreakCaRule = () => {
    return (
        <Box m={2} maxWidth="300px">
            <Typography gutterBottom variant="body2">
                You have entered less than 30 minutes for your meal period.
                In accordance with CA regulations, your meal period must be 30 minutes.
            </Typography>
            <Typography variant="body2">
                Please ensure that you take the full 30 minutes
                you are provided whenever you are working more than 5 hours in a day.
            </Typography>
        </Box>
    );
};
