import React, { useEffect } from 'react';
import Sidebar, { ISidebarProps } from 'shared/components/sidebars/Sidebar/Sidebar';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { Permission } from 'store/components/auth/authModels';
import { selectIsSheetDetailLoading } from 'store/components/sheetDetails/selectors';
import { DetailSlugs, IDetailConfiguration } from 'store/entities/clients/clientsModel';
import { selectTypedEntries, selectTypedSheet } from 'store/entities/timesheet/selectors';
import SheetDetailContent from './SheetDetailContent';
import { useDispatch, useSelector } from 'react-redux';
import { getSheetsDetails } from 'store/components/sheetDetails/sheetDetailsActions';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

interface ISheetDetailProps extends Omit<ISidebarProps, 'title' | 'children'> {
    sheetId: string;
    entryType: EntryType;
    title: string;
    sheetAvailableActions?: JSX.Element | null;
    permission: Permission;
    configuration: IDetailConfiguration<DetailSlugs>[];
    showPTO?: boolean;
}

export interface ISheetDetailWrapperProps extends Omit<ISheetDetailProps, 'title' | 'sheetAvailableActions' | 'sheet' | 'permission' | 'configuration'> {
    sheetId: string;
    entryType: EntryType;
}

export default function SheetDetail({
    sheetId, entryType, title, sheetAvailableActions, onClose, permission, configuration, showPTO, ...sidebarProps
}: ISheetDetailProps) {
    const dispatch = useDispatch();
    const classes = useSheetDetailStyles();
    const loading = useSelector(selectIsSheetDetailLoading);
    const sheet = useSelector(selectTypedSheet(sheetId, entryType));
    const clientId = useSelector(selectCurrentClientId);

    useEffect(() => {
        if (sheetId && entryType && clientId) {
            dispatch(getSheetsDetails.init({ sheetIds: [sheetId], entryType, permission }));
        }
    }, [dispatch, permission, sheetId, entryType, clientId]);

    const entries = useSelector(selectTypedEntries(sheet?.entry_type)).filter(
        entry => sheet && entry.sheet_id === sheet.id,
    );

    return (
        <Sidebar title={title}
            customClasses={classes.paper}
            titleClasses={{
                root: classes.paperTitle,
                text: classes.paperTitleText,
                icon: classes.paperTitleIcon,
            }}
            onClose={onClose}
            {...sidebarProps}
        >
            {sheet && (
                <>
                    <SheetDetailContent sheet={sheet}
                        sheetAvailableActions={sheetAvailableActions}
                        isLoading={loading}
                        entries={entries}
                        configuration={configuration}
                        showPTO={showPTO}
                    />
                </>
            )}
        </Sidebar>
    );
}
