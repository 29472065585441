import { IActionsCreatorCommon } from 'store/utils';
import { downloadFileSaga } from 'store/utils/sagas/downloadFileSaga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';

export function getDownloadFileWithNameByRequestSagaWatcher<ActionRequestPayload>(
    action: IActionsCreatorCommon<any, void, any, any, any, any>,
    api: (request: ActionRequestPayload) => Promise<BlobPart>,
    getFilenameSaga: ((request: ActionRequestPayload) => Generator) | ((request: ActionRequestPayload) => string),
) {
    function* getEntitiesSaga({ payload }: ReturnType<typeof action.init>) {
        const filename = (yield* call(getFilenameSaga, payload)) as string;
        const result = yield call(api, payload || {});
        yield call(downloadFileSaga, result, filename);
        yield put(action.success());
    }

    return function* getEntitiesWatcher() {
        yield takeLatest(
            action.initType,
            withBackendErrorHandler(
                getEntitiesSaga,
                action.error,
                `Unable to download file`,
            ),
        );
    };
}

export function getDownloadFileByRequestSagaWatcher<ActionRequestPayload>(
    action: IActionsCreatorCommon<ActionRequestPayload, void, any, any, any, any>,
    api: (request: ActionRequestPayload) => Promise<BlobPart>,
    getFilename: () => string,
) {
    return getDownloadFileWithNameByRequestSagaWatcher(
        action,
        api,
        function* () {
            return yield getFilename();
        },
    );
}
