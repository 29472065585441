import baseApi from 'shared/utils/baseApi';
import {
    dealEntity,
    gwClientExtensionsService,
} from 'store/entities/configuration/configurationApi';

import {
    IUpdateDealRequest,
} from 'modules/settings/submodules/clients/deals/components/EditDeal/store/models';
import { IDeal } from 'shared/models/Deal';

export const api = {
    async updateDeal(id: string, body: IUpdateDealRequest): Promise<IDeal> {
        const url = `/${gwClientExtensionsService}/${dealEntity}/${id}`;
        const { data } = await baseApi.patch<IUpdateDealRequest, IDeal>(
            url,
            body,
        );

        return data;
    },
};
