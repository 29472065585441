import { FormikConfig, FormikErrors } from 'formik/dist/types';
import { IOfferLetterTemplateEditForm } from 'modules/settings/submodules/offerLetters/model';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

type Validate = Required<FormikConfig<IOfferLetterTemplateEditForm>>['validate'];
type Errors = FormikErrors<IOfferLetterTemplateEditForm>;

export function useOfferLetterTemplateUpdateValidation() {
    const validationSchema = useMemo(() => yup.object({
        name: yup.string().required(),
        description: yup.string().nullable(true),
        status: yup.string().required(),
        clients: yup.array(yup.object()),
        allClients: yup.boolean().required(),
    }), []);

    const validate = useCallback<Validate>((values: IOfferLetterTemplateEditForm) => {
        const errors: Errors = {};

        if (!values.allClients && values.clients.length === 0) {
            errors.clients = 'Required';
        }

        return errors;
    }, []);

    return {
        validationSchema,
        validate,
    };
}
