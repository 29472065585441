import { getPayPeriodWorkingSheets } from 'modules/timeAndExpense/components/WorkingPage/store/actions';
import { ISheetsGridFilters } from 'store/components/workingSheets/workingSheetsModel';
import { ActionsReturnTypes, createSingleAction } from 'store/utils';

const SET_SHEETS_GRID_FILTERS = 'SET_SHEETS_GRID_FILTERS';

export const setSheetsGridFilters = createSingleAction<Partial<ISheetsGridFilters>, typeof SET_SHEETS_GRID_FILTERS>(
    SET_SHEETS_GRID_FILTERS,
);

export type WorkingSheetsActions =
    | ActionsReturnTypes<typeof getPayPeriodWorkingSheets>
    | ReturnType<typeof setSheetsGridFilters>;
