import React, { useCallback, useMemo, useState } from 'react';
import { getFieldValueId, getFieldValueName } from 'store/entities/customFields/helpers';
import { v4 as uuid4 } from 'uuid';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import ListSubheader from '@material-ui/core/ListSubheader';
import { AutocompleteRenderGroupParams } from '@material-ui/lab';
import FormMultipleAutocomplete from 'shared/components/autocomplete/FormMultipleAutocomplete';
import { useVirtualizedListBoxContainer } from 'shared/components/autocomplete/VirtualizedListBoxContainer';
import { ICustomFieldValuesMultiselectProps } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesMultiselect';
import { useFilteredCustomFieldValues, useSelectedFieldIds } from 'shared/components/selects/CustomFieldValuesSelect/hooks';
import { HierarchyType, ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldById, selectCustomFieldValuesByFieldId } from 'store/entities/customFields/selectors';
import { sortBy, difference, uniq } from 'lodash-es';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useStyles = makeHighPriorityStyles(theme => ({
    secondary: {
        fontSize: 11,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: theme.palette.grey.A200,
    },
}));

// hack for diplaying all with grouping by state
const selectAllOption = {
    id: 'all',
    data: {
        name: 'All',
        state: '',
    },
};
// @ts-ignore
const groupByState = (value: ICustomFieldValue) => value?.data?.state || '';

export const SCACustomFieldValueMultiselect = ({
    customFieldId,
    selectAllFieldName,
    maxChips = 1,
    hierarchy,
    customFieldFormValues = {},
    hierarchyType = HierarchyType.Assignment,
    scopeActionFilter,
    onlyActionable = true,
    ...props
}: ICustomFieldValuesMultiselectProps) => {
    const classes = useStyles();
    const customField = useSelector(selectCustomFieldById(customFieldId));
    const customFieldValues = useSelector(selectCustomFieldValuesByFieldId(customFieldId));

    // Force rerender key
    const [syntheticKey, setSynteticKey] = useState(uuid4());
    const [field,, helpers] = useField(props.name);
    const [selectAllField,, selectAllHelper] = useField(selectAllFieldName);
    const onSetSelectedAll = useCallback((value: boolean) => {
        selectAllHelper.setValue(value);
        setSynteticKey(uuid4());
    }, [selectAllHelper, setSynteticKey]);

    // @ts-ignore
    const selectedIds = useSelectedFieldIds(true, field.value, getFieldValueId, props.useIdValue);
    const filteredCustomFieldValues = useFilteredCustomFieldValues({
        hierarchy,
        customFieldId,
        customFieldFormValues,
        customFieldValues,
        hierarchyType,
        onlyActionable,
        scopeActionFilter,
        selectedIds,
    });

    const VirtualizedListBoxContainer = useVirtualizedListBoxContainer(56);

    const filterOptions = useCallback((options, state) => options.filter(
        option => {
            const inputValue = state.inputValue.toLowerCase().trim();
            if (inputValue.length < 2) {
                return true;
            }

            if (inputValue.length === 2) {
                // search only by state abbr
                return option.data?.state.toLowerCase().startsWith(inputValue);
            }

            return option.data?.state.toLowerCase().startsWith(inputValue)
                || option.data?.name.toLowerCase().indexOf(inputValue) >= 0
                || option.data?.counties?.some(county => county.toLowerCase().indexOf(inputValue) >= 0);
        },
    ), []);

    const options = useMemo(() => {
        // @ts-ignore
        return sortBy(filteredCustomFieldValues, value => value?.data?.state);
    }, [filteredCustomFieldValues]);

    const renderOption = useCallback((option, { selected }) => {
        const counties = option?.data?.counties
            ? `Counties: ${option?.data?.counties?.join(', ')}` : '';
        const optionText = getFieldValueName(option);
        return optionText === 'All' ? (
            <React.Fragment>
                <Checkbox
                    style={{ marginLeft: -12 }}
                    checked={selected || selectAllField.value}
                />
                <Typography variant="subtitle2" color="primary">{optionText}</Typography>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Checkbox
                    style={{ marginLeft: 12 }}
                    checked={selected || selectAllField.value}
                />
                <Box width="calc(100% - 48px)">
                    <Box fontSize={12}>{optionText}</Box>
                    {counties && (
                        <Box
                            className={classes.secondary}
                            title={counties}
                        >
                            {counties}
                        </Box>
                    )}
                </Box>
            </React.Fragment>
        );
    }, [classes, selectAllField.value]);

    const optionsByState = useMemo(() => {
        return customFieldValues.reduce((mem, value) => {
            return {
                ...mem,
                // @ts-ignore
                [value?.data?.state]: [
                    // @ts-ignore
                    ...(mem[value?.data?.state] || []),
                    value,
                ],
            };
        }, {} as Record<string, ICustomFieldValue[]>);
    }, [customFieldValues]);
    const isGroupChecked = useCallback((stateName: string) => {
        const groupOptions = optionsByState[stateName] || [];
        const optionIds = groupOptions.map(getFieldValueId);
        return selectAllField.value || difference(optionIds, field.value).length === 0;
    }, [field.value, optionsByState, selectAllField.value]);
    const toggleGroup = useCallback((stateName: string) => {
        let newValue = field.value || [];
        const groupOptions = optionsByState[stateName] || [];
        const optionIds = groupOptions.map(getFieldValueId);
        if (isGroupChecked(stateName)) {
            newValue = newValue.filter(valueId => !optionIds.includes(valueId));
        } else {
            newValue = uniq([...newValue, ...optionIds]);
        }
        helpers.setValue(newValue);
        setSynteticKey(uuid4());
    }, [field.value, optionsByState, isGroupChecked, helpers, setSynteticKey]);
    const renderGroup = useCallback((params: AutocompleteRenderGroupParams) => [
        params.group ? (
            <ListSubheader
                key={params.key}
                component="div"
                color="primary"
            >
                <Checkbox
                    style={{ marginLeft: -12 }}
                    checked={isGroupChecked(params.group)}
                    onClick={() => toggleGroup(params.group)}
                />
                {params.group}
            </ListSubheader>
        ) : null,
        params.children,
    ], [isGroupChecked, toggleGroup]);

    return (
        <FormMultipleAutocomplete
            key={syntheticKey}
            getKey={getFieldValueId}
            getText={getFieldValueName}
            groupBy={groupByState}
            options={options}
            outerLabel={customField.name}
            placeholder="Search state or county"
            useSelectAll
            disableSmartSelectAll
            onSetSelectedAll={onSetSelectedAll}
            initialAllSelected={selectAllField.value}
            maxChips={maxChips}
            ListboxComponent={VirtualizedListBoxContainer}
            disableListWrap
            disableCloseOnSelect
            renderOption={renderOption}
            renderGroup={renderGroup}
            filterOptions={filterOptions}
            customSelectAllOption={selectAllOption}
            {...props}
        />
    );
};
