import {
    CREATE_OFFER_FOR_COMBINED_USER,
    CREATE_OFFER_FOR_USER,
    createOffer,
    OfferLetterActions,
    SET_CREATE_SIDEBAR_OPENED,
} from 'modules/offerLetter/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';
import { IUserInfo } from '../../../../../shared/models/User';

export const initialState = {
    createSidebarOpened: false,
    selectedUser: null,
    selectedCombinedUser: null,
    createdOffer: '',
};

export const isOfferCreating = isLoadingReducer(createOffer);

export function createSidebarOpened(state = initialState.createSidebarOpened, action: OfferLetterActions): boolean {
    switch (action.type) {
        case SET_CREATE_SIDEBAR_OPENED:
            return action.payload;
        case CREATE_OFFER_FOR_USER:
            return true;
        case CREATE_OFFER_FOR_COMBINED_USER:
            return true;
        case createOffer.successType:
            return false;
        default:
            return state;
    }
}

export function selectedCombinedUser(
    state = initialState.selectedCombinedUser,
    action: OfferLetterActions,
): IUserInfo | null {
    switch (action.type) {
        case SET_CREATE_SIDEBAR_OPENED:
            if (!action.payload) {
                return null;
            }
            return state;
        case CREATE_OFFER_FOR_COMBINED_USER:
            return action.payload;
        case createOffer.successType:
            return null;
        default:
            return state;
    }
}

export function selectedUser(state = initialState.selectedUser, action: OfferLetterActions): string | null {
    switch (action.type) {
        case SET_CREATE_SIDEBAR_OPENED:
            if (!action.payload) {
                return null;
            }
            return state;
        case CREATE_OFFER_FOR_USER:
            return action.payload;
        case createOffer.successType:
            return null;
        default:
            return state;
    }
}

export function createdOffer(state = initialState.createdOffer, action: OfferLetterActions): string {
    switch (action.type) {
        case createOffer.successType:
            return action.payload.id;
        default:
            return state;
    }
}

export const offerCreate = combineReducers({
    createSidebarOpened,
    isOfferCreating,
    selectedUser,
    selectedCombinedUser,
    createdOffer,
});
