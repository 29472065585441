import baseApi from 'shared/utils/baseApi';
import { ICostCenter } from 'shared/models/CostCenters';
import { costCenterEntity, gwClientExtensionsService } from 'store/entities/configuration/configurationApi';
import { ICreateCostCenterRequest, IUpdateCostCenterRequest } from 'modules/settings/submodules/clients/costCenters/store/model';
import { withClientId } from 'store/utils/withClientId';

export const api = {
    async createCostCenter(costCenter: ICreateCostCenterRequest): Promise<ICostCenter> {
        const { data } = await baseApi.create<ICreateCostCenterRequest, ICostCenter>(
            gwClientExtensionsService,
            costCenterEntity,
            withClientId(costCenter),
        );

        return data;
    },
    async updateCostCenter(id: string, body: IUpdateCostCenterRequest): Promise<ICostCenter> {
        const { data } = await baseApi.patch<IUpdateCostCenterRequest, ICostCenter>(
            `/${gwClientExtensionsService}/${costCenterEntity}/${id}`,
            body,
        );

        return data;
    },
};
