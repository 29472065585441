import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { impersonateSwitchBack } from 'store/components/auth/authActions';

export const ImpersonationSuccessToast = () => {
    const dispatch = useDispatch();
    const switchBack = () => {
        dispatch(impersonateSwitchBack.init());
    };
    return (
        <>
            You{'\''}re temporarily logged in as another user. When you{'\''}re done,{' '}
            <Link
                component="button"
                variant="body2"
                color="secondary"
                onClick={switchBack}
                style={{
                    color: colors.darkBlue,
                }}
            >
                switch back
            </Link> to your account.
        </>
    );
};
