import { Theme } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useQuickEditStyles = makeHighPriorityStyles((theme: Theme) => ({
    input: {
        fontSize: 14,
        width: '100%',
        height: 'unset',
        textAlign: 'right',
    },
    inputRoot: {
        '&&': {
            flexDirection: 'row',
            height: 'unset',
        },
        '& fieldset': {
            borderWidth: 0,
        },
        //TODO: I can't find another way replace focus styles. Need to find the solution without !important
        '&.Mui-focused fieldset': {
            borderWidth: '0 !important',
            boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px inset, rgba(202, 202, 202) 0px 0px 5px 0px',
        },
        '& input': {
            minHeight: 'auto',
            padding: 0,
        },
    },
    inputRootNoShadow: {
        '&.Mui-focused fieldset': {
            boxShadow: 'none',
        },
        '&>div': {
            padding: 0,
            fontSize: 14,
            lineHeight: '14px',
            alignItems: 'baseline',
        },
        '& .Mui-focused fieldset': {
            borderWidth: '0 !important',
        },
    },
    disabledInput: {
        color: theme.palette.text.primary,
    },
}));
