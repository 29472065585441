import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { IBulkCreateSubAssignmentForm } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/types';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { HierarchyNodeOperation, ICustomFieldHierarchyNode } from 'store/entities/customFields/model';
import { CustomFieldValueMultiselectGateway } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValueMultiselectGateway';
import { ScopeAction } from 'store/entities/scopes/models';

export interface ICreateSubassignmentCustomFieldsProps {
    node: ICustomFieldHierarchyNode,
    disabled: boolean,
    classes: any,
    hierarchy: ICustomFieldHierarchyNode[],
    values: IBulkCreateSubAssignmentForm,
}

export function CreateSubassignmentCustomFields(props: ICreateSubassignmentCustomFieldsProps) {
    const customFieldFormValues = useMemo(() => {
        return {
            ...props.values.customFieldValues,
            ...props.values.customFieldValue,
        } as Record<string, string>;
    }, [props.values]);
    return (
        <Grid item sm={6}>
            {props.node.operation === HierarchyNodeOperation.Actionable ? (
                <CustomFieldValueMultiselectGateway
                    customFieldId={props.node.custom_field_id}
                    name={`customFieldValues[${props.node.custom_field_id}]`}
                    className={props.classes.input}
                    useIdValue
                    hierarchy={props.hierarchy}
                    customFieldFormValues={customFieldFormValues}
                    selectAllFieldName={`customFieldValuesAllFields[${props.node.custom_field_id}]`}
                    scopeActionFilter={ScopeAction.Assignment}
                    onlyActionable={false}
                />
            ) : (
                <CustomFieldValuesSelect
                    customFieldId={props.node.custom_field_id}
                    name={`customFieldValue[${props.node.custom_field_id}]`}
                    className={props.classes.input}
                    useIdValue
                    hierarchy={props.hierarchy}
                    customFieldFormValues={customFieldFormValues}
                    scopeActionFilter={ScopeAction.Assignment}
                    onlyActionable={false}
                />
            )}
        </Grid>
    );
}
