import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';
import { useSubAssignmentBulkCreateEditFormStyles } from '../../CreateSubAssignment/CreateSubAssignmentForm/styles';
import { useEditCustomFieldsInitialFormValues } from '../hooks/useEditCustomFieldsInitialFormValues';
import { FooterSection } from './components/editFormSections/FooterSection';
import { DatesSection } from './components/editFormSections/DatesSection';
import { RatesSection } from './components/editFormSections/RatesSection';
import { SubassignmentApprovers } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/SubassignmentApprovers';
import { useSubassignmentApprovers } from 'modules/subassignmentManagement/components/EditSubAssignment/hooks/useSubassignmentApprovers';
import { EditSubassignmentCustomFieldsSection } from './components/editFormSections/EditSubassignmentCustomFieldsSection';
import { IEditSubAssignmentFormProps } from './types';
import { useEditSubAssignmentValidationSchema } from './validationsSchema';
import { Nullable } from '../../../../../types/types';

function getOverrideRate(subassignment?: Nullable<ISubassignment>) {
    if (subassignment?.override_rate_value) {
        return parseFloat(subassignment.override_rate_value);
    }
    return null;
}

export function EditSubAssignmentForm({
    submitButtonTitle,
    onSubmit,
    additionalButtons,
    isLoading,
    baseRate,
    subassignment,
    numberOfApprovers,
    assignmentStartDate,
}: IEditSubAssignmentFormProps) {
    const classes = useSubAssignmentBulkCreateEditFormStyles();
    const formHelperTextClasses = useFormHelperTextStyles();
    const hierarchy = useSelector(selectOrderedCustomFieldAssignmentNodes);
    const isBulkEdit = !subassignment;
    const validationSchema = useEditSubAssignmentValidationSchema(
        isBulkEdit,
        numberOfApprovers,
        subassignment?.assignment_id,
        subassignment?.id,
    );
    const customFieldsInitialFormValues = useEditCustomFieldsInitialFormValues(hierarchy, subassignment);
    const approversFormState = useSubassignmentApprovers(subassignment, numberOfApprovers);

    const formikData = useFormik({
        initialValues: {
            startDate: subassignment?.start_date || '',
            endDate: subassignment?.end_date || '',
            overrideRate: getOverrideRate(subassignment),
            modifyCustomFields: false,
            modifyApprovers: false,
            ...customFieldsInitialFormValues,
            ...approversFormState,
        },
        validationSchema,
        // @ts-ignore
        onSubmit,
        validateOnBlur: true,
        enableReinitialize: true,
    });

    return (
        <FormikProvider value={formikData}>
            <Form onSubmit={formikData.handleSubmit}>
                <DatesSection
                    classes={classes}
                    isLoading={isLoading}
                    assignmentStartDate={assignmentStartDate}
                />

                {isBulkEdit && (
                    <RatesSection
                        classes={classes}
                        baseRate={baseRate}
                        formHelperTextClasses={formHelperTextClasses}
                        isLoading={isLoading}
                    />
                )}

                <EditSubassignmentCustomFieldsSection
                    hierarchy={hierarchy}
                    isLoading={isLoading}
                    isBulkEdit={isBulkEdit}
                />

                {/*
                // @ts-ignore */}
                {formikData.errors.combinationOfActionableFieldsAlreadyUsed && (
                    <FormHelperText error>
                        {/*
                        // @ts-ignore */}
                        {formikData.errors.combinationOfActionableFieldsAlreadyUsed}
                    </FormHelperText>
                )}

                <SubassignmentApprovers
                    numberOfApprovers={numberOfApprovers}
                    inputClassName={classes.input}
                    isLoading={isLoading}
                    isBulkEdit={isBulkEdit}
                />

                <FooterSection
                    classes={classes}
                    loading={isLoading}
                    submitButtonTitle={submitButtonTitle}
                    additionalButtons={additionalButtons}
                />
            </Form>
        </FormikProvider>
    );
}
