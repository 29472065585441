import React from 'react';
import { AppBar, Box } from '@material-ui/core';
import { useHeaderStyles } from 'shared/styles/header';
import { UserProfileMenu } from 'modules/home/header/UserProfileMenu';
import { SettingsBreadcrumbs } from 'modules/settings/submodules/components/SettingsAppBar/SettingsBreadcrumbs';

export const SettingsAppBar = () => {
    const headerClasses = useHeaderStyles();
    return (
        <AppBar
            className={headerClasses.headerNewRoot}
            position="sticky"
            elevation={0}
        >
            <SettingsBreadcrumbs/>
            <Box/>
            <UserProfileMenu/>
        </AppBar>
    );
};
