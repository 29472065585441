import { createSingleAction } from 'store/utils';

const SET_OPEN_BULK_CREATE_SUBASSIGNMENTS_MODAL = 'employment_management/SET_OPEN_BULK_CREATE_SUBASSIGNMENTS_MODAL';
export const setOpenBulkCreateSubassignmentModal = createSingleAction<
boolean,
typeof SET_OPEN_BULK_CREATE_SUBASSIGNMENTS_MODAL
>(
    SET_OPEN_BULK_CREATE_SUBASSIGNMENTS_MODAL,
);

const SET_OPEN_BULK_CREATE_SUBASSIGNMENTS_UPLOAD_MODAL = 'employment_management/SET_OPEN_BULK_CREATE_SUBASSIGNMENTS_UPLOAD_MODAL';
export const setOpenBulkCreateSubassignmentUploadModal = createSingleAction<
boolean,
typeof SET_OPEN_BULK_CREATE_SUBASSIGNMENTS_UPLOAD_MODAL
>(
    SET_OPEN_BULK_CREATE_SUBASSIGNMENTS_UPLOAD_MODAL,
);

export enum BulkCreateSubassignmentSteps {
    Form,
    Verify,
    PostReport,
}

const SET_BULK_CREATE_SUBASSIGNMENT_STEP = 'employment_management/SET_BULK_CREATE_SUBASSIGNMENT_STEP';
export const setBulkCreateSubassignmentStep = createSingleAction<
BulkCreateSubassignmentSteps,
typeof SET_BULK_CREATE_SUBASSIGNMENT_STEP
>(
    SET_BULK_CREATE_SUBASSIGNMENT_STEP,
);
