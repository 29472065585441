import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectUsersById } from 'store/entities/users/selectors';
import { IInfinityScrollState } from 'store/reducerUtils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { IEmployeeFilter } from 'modules/users/employees/store/models';

export const pageSize = 30;

export const selectEmployeesState = (state: IStore) => state.modules.users.employees;
export const selectEmployeesTableState = (state: IStore): IInfinityScrollState<string> =>
    selectEmployeesState(state).employeesTable;
export const employeesSelectors = getInfinityScrollSelectors(selectEmployeesTableState);
export const selectEmployeeFilter = (state: IStore): IEmployeeFilter => selectEmployeesState(state).employeesFilter;
export const selectEmployeeTableRows = createSelector(
    employeesSelectors.selectItems,
    selectUsersById,
    (userIds, usersByIds) => userIds.map(id => usersByIds[id]).filter(Boolean),
);
