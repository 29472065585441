import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const usePayrollSheetsTableStyles = makeHighPriorityStyles((theme: Theme) => ({
    syncMessageWrapper: {
        '&&': {
            margin: theme.spacing(0, 0, 2, 0),
        },
    },
    flexTableWrapper: {
        display: 'flex',
        flexGrow: 1,
    },
}));
export default usePayrollSheetsTableStyles;
