import React, { useCallback } from 'react';
import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import {
    IPayrollSheetTableProps,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetTablePure';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { VirtualInfinityGridTable } from 'shared/components/table/VirtualizedGridTable/VirtualizedGridTable';

export function PayrollSheetVirtualTable<RowData extends IPayrollSheetGroupedRow>(
    props: IPayrollSheetTableProps<RowData>,
) {
    const tableClasses = useDefaultTableStyles();
    const calcRowWidth = useCallback((cells: Array<ICellInfo<RowData>>) => {
        if (cells.length < 10) {
            return '100%';
        }
        return '2000px !important';
    }, []);
    const getKey = useCallback((row: RowData) => row.id, []);

    return (
        <VirtualInfinityGridTable
            {...props}
            totalCount={props.totalCount || 0}
            getKey={getKey}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            calcRowWidth={calcRowWidth}
        />
    );
}
