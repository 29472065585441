import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { FormSwitchField } from 'modules/settings/submodules/clients/appConfiguration/components/FormSwitchField';
import { FormWithSections, IFormWithSectionsSection } from 'modules/settings/common/components/FormWithSections/FormWithSections';
import { Box } from '@material-ui/core';

export interface IEafSettingsFormValues {
    hideSubassignments: boolean;
    showRetro: boolean;
}

interface IEafSettingsFormProps extends FormikProps<IEafSettingsFormValues> {
    isLoading: boolean;
}

export const EafSettingsForm = ({
    isLoading,
    handleSubmit,
}: IEafSettingsFormProps) => {
    const classes = useSettingsFormStyles();

    const sections = useMemo((): IFormWithSectionsSection[] => {
        return [
            ...[
                {
                    name: 'Retro Feature',
                    component: (
                        <>
                            <Box className={classes.sectionHelp}>
                                The retro feature applies to the effective date in the compensation change and promotion
                                forms which will allow you to choose a date from previous pay period. Leaving this off
                                will only let you to pick a date in the future.
                            </Box>
                            <FormFieldWithSeparateLabel
                                name="showRetro"
                                outerLabel="Show retro option"
                                FieldComponent={FormSwitchField}
                                containerClassName={classes.separateLabelWithFixedWidth}
                            />
                        </>
                    ),
                },
            ],
            ...[
                {
                    name: 'Sub-Assignments Display',
                    component: (
                        <>
                            <Box className={classes.sectionHelp}>
                                This is for clients with employees that only have one active sub-assignment at a time.
                                Switching this on will hide all sub-assignments and only display the active one.
                            </Box>
                            <FormFieldWithSeparateLabel
                                name="hideSubassignments"
                                outerLabel="Hide Sub-Assignments"
                                FieldComponent={FormSwitchField}
                                containerClassName={classes.separateLabelWithFixedWidth}
                            />
                        </>
                    ),
                },
            ],
        ];
    }, [classes]);
    return (
        <FormWithSections
            sections={sections}
            submitTitle="Save"
            onSubmit={handleSubmit}
            isLoading={isLoading}
            isDisabled={isLoading}
        />
    );
};
