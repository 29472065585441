import React from 'react';
import { Box } from '@material-ui/core';
import { Permission } from 'store/components/auth/authModels';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import {
    getActivities,
    getControllingOrgs,
    getDealTypes,
    getSubmittingOrgs,
    getUserTypesAction,
} from 'store/entities/configuration/configurationAction';
import { getSpecialUsersAction } from 'store/entities/users/actions';
import { JobNumberFilter } from 'modules/settings/submodules/clients/jobNumber/components/Filter/JobNumberFilter';
import { JobNumberTable } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberTable/JobNumberTable';
import { CreateJobNumberButton } from 'modules/settings/submodules/clients/jobNumber/components/CreateJobNumber/CreateJobNumberButton';
import { EditJobNumberModal } from 'modules/settings/submodules/clients/jobNumber/components/EditJobNumber/EditJobNumberModal';
import { AssignUserModal } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/AssignUserModal';

export function JobNumberPage() {
    useWithClientEffect((dispatch, clientId) => {
        dispatch(getUserTypesAction.init());
        dispatch(getActivities.init());
        dispatch(getDealTypes.init());
        dispatch(getSubmittingOrgs.init());
        dispatch(getControllingOrgs.init());
        dispatch(getSpecialUsersAction.init({
            purpose: Permission.ApproveSheets,
            client_id: clientId,
        }));
    }, []);

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                mb={2}
            >
                <CreateJobNumberButton/>
                <JobNumberFilter/>
            </Box>
            <JobNumberTable/>
            <EditJobNumberModal/>
            <AssignUserModal/>
        </>
    );
}
