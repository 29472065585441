import React, { ChangeEvent, useCallback, useContext } from 'react';
import { useCellSelectStyles } from 'modules/settings/submodules/components/sharedStyles/tableCellStyles';
import { FormControl, Select } from '@material-ui/core';
import { HierarchyNodeOperation, HierarchyNodeOperationTitle } from 'store/entities/customFields/model';
import { HierarchyContext } from 'modules/settings/submodules/components/HierarchyPage/HierarchyListsContext';

export interface IUpdateOperationSelectProps {
    id: string;
    operation: HierarchyNodeOperation;
    className?: string;
    disabled?: boolean;
}

export const OperationSelect = ({ id, operation, className, disabled }: IUpdateOperationSelectProps) => {
    const { hierarchyList, setHierarchyList } = useContext(HierarchyContext);
    const handleChange = useCallback((newValue: HierarchyNodeOperation) => {
        const displayOnGridOperations = [HierarchyNodeOperation.Actionable];
        const resetDisplayOnGrid = !displayOnGridOperations.includes(newValue);
        setHierarchyList(hierarchyList.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    hierarchyNode: {
                        ...item.hierarchyNode,
                        operation: newValue,
                        ...(resetDisplayOnGrid ? { display_on_grid: false } : {}),
                    },
                };
            }
            return item;
        }));
    }, [id, hierarchyList, setHierarchyList]);

    const classes = useCellSelectStyles();
    const values = Object.values(HierarchyNodeOperation).map(enumValue => {
        return ({
            id: enumValue,
            description: HierarchyNodeOperationTitle[enumValue],
        });
    });
    const onChange = useCallback(({ target }: ChangeEvent<{value: any}>) => {
        handleChange(target.value);
    }, [handleChange]);
    return (
        <FormControl
            variant="outlined"
            size="small"
            className={className}
        >
            <Select
                classes={classes}
                native
                onChange={onChange}
                value={operation}
                disabled={disabled}
            >
                {values.map(optionValue => (
                    <option key={optionValue.id} value={optionValue.id}>
                        {optionValue.description}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};
