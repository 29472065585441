import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import { PayType } from 'shared/models/PayType';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';

interface IPayTypeSelectProps {
    positionId?: string | null;
    className?: string;
    disabled?: string;
    label?: string;
    name: string;
}

export const PayTypeSelect = ({ positionId, ...props }: IPayTypeSelectProps) => {
    const customFieldValues = useSelector(selectCustomFieldValuesByIds);

    const options = useMemo(() => {
        const position = customFieldValues[positionId || ''];
        const payTypeValues = Object.values(PayType);
        const positionPayType = position?.data?.pay_type || null;
        if (positionPayType) {
            return payTypeValues.filter(payType => payType === positionPayType);
        }
        return payTypeValues;
    }, [customFieldValues, positionId]);

    return (
        <EnumFormSelect
            {...props}
            // @ts-ignore
            values={options}
            resetIfValueNotAvailable
            autoSelectSingleVariant
        />
    );
};
