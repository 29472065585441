import { IEntity } from 'shared/models/Entity';

export enum DealTypeBillingType {
    Daily = 'daily',
    PerFile = 'per_file',
}

export const DealTypeBillingTypeName: Record<DealTypeBillingType, string> = {
    [DealTypeBillingType.Daily]: 'Daily',
    [DealTypeBillingType.PerFile]: 'Per File',
};

export interface IDealType extends IEntity {
    name: string;
    ecub_name?: string;
    client_id: string;
    use_default_activity: boolean;
    deleted_at: string;
    billing_info_required: boolean;
    billing_type?: DealTypeBillingType | null;
}
