import React, { useCallback, useEffect, useMemo } from 'react';
import { EditPayrollSheetPage } from 'modules/payrollProcessorHub/components/EditSheet/EditPayrollSheetPage';
import { getSheetEditInfo } from 'modules/payrollProcessorHub/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { nullOrValue } from 'shared/utils/converters/urlParams';
import { selectIsLoadingSheetEditInfo } from 'modules/payrollProcessorHub/store/selectors';

export const EditPayrollSingleGroupPage = () => {
    const {
        timeSheetId: timeSheetIdParam,
        expenseSheetId: expenseSheetIdParam,
    } = useParams<{ timeSheetId: string; expenseSheetId: string; clientId: string }>();
    const timeSheetId = nullOrValue(timeSheetIdParam);
    const expenseSheetId = nullOrValue(expenseSheetIdParam);
    const groupId = useMemo(() => ({
        timeSheetId,
        expenseSheetId,
    }), [timeSheetId, expenseSheetId]);
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingSheetEditInfo);

    useEffect(() => {
        dispatch(getSheetEditInfo.init(groupId));
    }, [dispatch, groupId]);
    const handleClosePage = useCallback(() => {
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
    }, []);

    return (
        <EditPayrollSheetPage
            timeSheetIds={timeSheetId ? [timeSheetId] : []}
            expenseSheetIds={expenseSheetId ? [expenseSheetId] : []}
            isLoading={isLoading}
            onClose={handleClosePage}
        />
    );
};
