import { Theme } from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const submitButtonsBlockHeight = 42;

export const useSubassignmentFilterFormStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        maxWidth: 1000,
    },
    input: {
        display: 'flex',
        '& input, & select, & [class*="MuiOutlinedInput-root"]': {
            background: colors.white,
        },
    },
    linkButtonContainer: {
        position: 'absolute',
        marginTop: theme.spacing(2),
    },
    linkButton: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 14,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    submitButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: submitButtonsBlockHeight,
        marginTop: -submitButtonsBlockHeight,
        marginBottom: submitButtonsBlockHeight + theme.spacing(2),
    },
    submitButtonsExpanded: {
        marginTop: theme.spacing(2),
        marginBottom: 0,
    },
    showAllButton: {
        padding: theme.spacing(1),
        width: 115,
    },
}));
