import React from 'react';
import GridTableRow, { IGridTableRowProps } from 'shared/components/table/GridTable/GridTableRow';

export interface IGridTableRowsProps<RowData> extends Omit<IGridTableRowProps<RowData>, 'row' | 'index' | 'rowsLength'>{
    rows: RowData[];
    getKey: (row: RowData) => string;
}

export function GridTableRows<RowData>({
    rows,
    getKey,
    ...props
}: IGridTableRowsProps<RowData>) {
    return (
        <>
            {rows.map((row, index) => (
                <GridTableRow
                    key={ getKey(row) }
                    row={ row }
                    index={index}
                    rowsLength={rows.length}
                    {...props}
                />
            ))}
        </>
    );
}
