import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { logger } from 'shared/utils/logging/logger';
import { ColumnSlugs, IColumnConfiguration } from 'store/entities/clients/clientsModel';

export const getColumnsByConfiguration = <Slugs extends ColumnSlugs, IOutputData>(
    configuration: IColumnConfiguration<Slugs>[],
    dictionary: Record<Slugs, (
        placeholder: string,
        classes: any,
        additionalData?: any,
    ) => ICellInfo<IOutputData> | null>,
    classes: any,
): ICellInfo<IOutputData>[] => {
    return configuration.map(columnConfig => {
        if (dictionary[columnConfig.slug]) {
            return dictionary[columnConfig.slug](columnConfig.placeholder, classes, columnConfig.additionalData);
        }
        logger.error(new Error(`Column slug not found: ${columnConfig.slug}`));
        return null;
    }).filter(item => Boolean(item)) as ICellInfo<IOutputData>[];
};
