import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { selectAttachmentObjectUrlById } from 'shared/components/attachments/store/selectors';
import { HeadwayLoader } from 'shared/components/loader/HeadwayLoader';
import { IAttachmentPreviewProps } from 'shared/components/attachments/model';
import { useUploadStyles } from './styles';
import PdfSVG from '../icons/PdfSVG';

export const AttachmentPreviewPdf = ({
    attachment,
    className,
}: IAttachmentPreviewProps) => {
    const classes = useUploadStyles();
    const objectUrl = useSelector(selectAttachmentObjectUrlById(attachment.id));

    if (!objectUrl) {
        return (
            <Box mt={3}>
                <HeadwayLoader/>
            </Box>
        );
    }

    const onPdfPreview = () => {
        window.open(objectUrl);
    };

    return (
        <div className={clsx(classes.fileIconContainer, className)}>
            <Button
                aria-label="view"
                onClick={onPdfPreview}
                className={classes.pdfPreview}
            >
                <PdfSVG customClasses={classes.pdfPreview}/>
            </Button>
        </div>
    );
};
