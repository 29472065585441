import { ISelectorPanelEntry } from 'modules/timeAndExpense/components/AddEntry/store/models';
import { selectTimeAndExpenseState } from 'modules/timeAndExpense/store/selectors';
import { createSelector } from 'reselect';
import { IDeal } from 'shared/models/Deal';
import { IStore } from 'store/configureStore';
import { selectIsJobNumberFieldsApplied } from 'store/entities/clients/selectors/timeAndPaySelectors';
import {
    selectDealsById,
    selectSubmittingOrgsById,
    selectTasksById,
} from 'store/entities/configuration/configurationSelectors';
import { HierarchyNodeOperation } from 'store/entities/customFields/model';
import {
    selectCustomFieldNodeByFieldId,
    selectCustomFieldsByIds,
    selectCustomFieldValuesByIds,
    selectOrderedCustomFieldIds,
} from 'store/entities/customFields/selectors';

const selectAddEntryState = (state: IStore) => {
    const timeAndExpenseState = selectTimeAndExpenseState(state);
    return timeAndExpenseState.addEntry;
};

export const selectCommonEntryFormValues = (state: IStore) => selectAddEntryState(state).commonEntryFormValues;
export const selectApplyAutoSelectSingleValue = (state: IStore) =>
    selectAddEntryState(state).applyAutoSelectSingleValue.apply;

export const selectSelectorsPanelEntries = createSelector(
    selectCommonEntryFormValues,
    selectDealsById,
    selectSubmittingOrgsById,
    selectTasksById,
    selectOrderedCustomFieldIds,
    selectCustomFieldValuesByIds,
    selectCustomFieldsByIds,
    selectCustomFieldNodeByFieldId,
    selectIsJobNumberFieldsApplied,
    (
        commonEntryFormValues,
        dealsById,
        submittingOrgsById,
        tasksById,
        orderedFieldIds,
        valuesByIds,
        fieldsByIds,
        nodesByFieldId,
        hasJobNumberField,
    ): ISelectorPanelEntry[] => {
        const deal = dealsById[commonEntryFormValues.jobNumber?.deal_id] as IDeal;
        const jobSiteLocation = submittingOrgsById[deal?.submitting_org_id];
        const task = tasksById[commonEntryFormValues.taskId];

        const selectedCustomFields: ISelectorPanelEntry[] = orderedFieldIds.map(fieldId => {
            const valueId = commonEntryFormValues?.customFieldValues[fieldId];
            const node = nodesByFieldId[fieldId];
            return {
                value: valuesByIds[valueId]?.data?.name,
                key: `customFieldValues[${fieldId}]`,
                title: fieldsByIds[fieldId]?.name,
                noInteraction: node.operation !== HierarchyNodeOperation.Actionable,
                hidden: node.operation === HierarchyNodeOperation.Hidden,
            };
        }).filter(item => !item.hidden);

        return [
            {
                value: commonEntryFormValues.location?.name,
                key: 'location',
                title: 'Location',
            },
            {
                value: commonEntryFormValues.position?.name,
                key: 'position',
                title: 'Position',
            },
            {
                value: commonEntryFormValues.jobNumber?.job_number,
                key: 'jobNumber',
                title: 'Job Number',
            },
            {
                value: commonEntryFormValues.jobNumber?.description,
                key: undefined,
                title: 'Job Description',
            },
            {
                value: jobSiteLocation?.client_site_name,
                title: 'Job Site Location',
                key: undefined,
            },
            {
                value: commonEntryFormValues.projectAssignment?.description,
                key: 'projectAssignment',
                title: 'Project + Assign',
            },
            {
                value: task?.description,
                key: 'taskId',
                title: 'Task',
            },
            {
                value: commonEntryFormValues.activity?.description,
                key: 'activity',
                title: 'Activity',
            },
            ...hasJobNumberField ? [] : selectedCustomFields,
        ].filter(panelEntry => panelEntry.value);
    },
);
