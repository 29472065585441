import baseApi from 'shared/utils/baseApi';
import {
    IControllingOrg,
    IUpdateControllingOrgRequest,
} from 'shared/models/ControllingOrg';
import { controllingOrgEntity, gwClientExtensionsService } from 'store/entities/configuration/configurationApi';

export const api = {
    async updateControllingOrg(id: string, body: IUpdateControllingOrgRequest): Promise<IControllingOrg> {
        const url = `/${gwClientExtensionsService}/${controllingOrgEntity}/${id}`;
        const { data } = await baseApi.patch<IUpdateControllingOrgRequest, IControllingOrg>(
            url,
            body,
        );

        return data;
    },
};
