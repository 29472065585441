import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { syncCustomField } from '../../../../../../../store/entities/customFields/actions';
import { selectIsCustomFieldSyncronizing } from './store/selectors';

interface ISyncCustomFieldButtonProps {
    fieldPrismId: string;
    clientId: string;
    fieldId: string
}

export const SyncCustomFieldValueButton = ({
    fieldPrismId,
    clientId,
    fieldId,
}: ISyncCustomFieldButtonProps) => {
    const dispatch = useDispatch();
    const isSyncronizing = useSelector(selectIsCustomFieldSyncronizing);
    const sendSync = useCallback(() => {
        dispatch(syncCustomField.init({
            client_id: clientId,
            field_prism_name: fieldPrismId,
            field_id: fieldId,
        }));
    }, [dispatch, clientId, fieldPrismId, fieldId]);
    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={sendSync}
                disabled={isSyncronizing}
            >
                Sync Prism
            </Button>
        </>);
};
