import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const appSettingsValidationsSchema = yup.object().shape({
    approvalLevels: yup.number().required(ValidationMessages.REQUIRED),
    scaEnabled: yup.boolean(),
    allowFutureDayEntry: yup.boolean(),
    allowTimeEntryWithoutEndTime: yup.boolean(),
    expenseReceiptRequiredDollars: yup.number().required(ValidationMessages.REQUIRED),
});
