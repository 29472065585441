import { settingsRoutes } from 'modules/settings/routes';
import {
    useGlobalAssignmentSetupSubModules,
} from 'modules/settings/submodules/clients/globalAssignmentSetup/useGlobalAssignmentSetupSubModules';
import { SideMenu } from 'modules/settings/submodules/components/SideMenu/SideMenu';
import React from 'react';
import { Permission } from 'store/components/auth/authModels';

export const CommonSettingsSideMenu = () => {
    const globalAssignmentSetupSubModules = useGlobalAssignmentSetupSubModules();
    const items = [
        {
            title: 'Clients',
            link: settingsRoutes.CLIENTS.ROOT,
            permission: Permission.modifyClientSettings,
        },
        {
            title: 'Platform Users',
            link: settingsRoutes.PLATFORM_USERS.ROOT,
            permission: Permission.ManageManagerUsers,
            children: [
                {
                    title: 'Client Roles & Permissions',
                    link: settingsRoutes.PLATFORM_USERS.CLIENT_ROLES,
                },
                {
                    title: 'User Role Associations',
                    link: settingsRoutes.PLATFORM_USERS.USER_ROLES,
                },
            ],
        },
        {
            title: 'Assignment Setup',
            description: 'Manage custom fields, hierarchy',
            link: settingsRoutes.ASSIGNMENT_SETUP.ROOT,
            permission: Permission.ManageCustomFields,
            children: globalAssignmentSetupSubModules,
        },
        {
            title: 'Employees',
            link: settingsRoutes.EMPLOYEES.ROOT,
            permission: Permission.ManageEmployeeUsers,
        },
        {
            title: 'Offer Letter Templates',
            link: settingsRoutes.OFFER_LETTERS.TEMPLATES,
            permission: Permission.ManageOfferLetterTemplates,
        },
    ];

    // @ts-ignore
    return (<SideMenu items={items}/>);
};
