import clsx from 'clsx';
import React, { useMemo } from 'react';
import { makeOverdueDetailsBatchCell } from 'modules/payrollProcessorHub/components/PayrollSheetTable/hoc/makeOverdueDetailsBatchCell';
import { makePslCell } from 'modules/payrollProcessorHub/components/PayrollSheetTable/hoc/makePslCell';
import { makePtoCell } from 'modules/payrollProcessorHub/components/PayrollSheetTable/hoc/makePtoCell';
import { usePayrollCellsSizes } from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/usePayrollCellsSizes';
import { useGroupedSheetTableStyles } from 'modules/payrollProcessorHub/components/PayrollSheetTable/styles/useGroupedSheetTableStyles';
import { useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { SortableTableHead } from 'shared/components/table/GridTable/SortableTableHead';

import { GroupedApprovedByCell } from '../components/cells/body/ApprovedByCell';
import { GroupedApproversCell } from '../components/cells/body/ApproversCell';
import { ClientCell } from '../components/cells/body/ClientCell';
import { FilesCell } from '../components/cells/body/FilesCell';
import { PayPeriodCell } from '../components/cells/body/PayPeriodCell';
import { PayPeriodInfoGroupedCell } from '../components/cells/body/PayPeriodInfoCell';
import { PayTypeGroupedCell } from '../components/cells/body/PayTypeCell';
import { PayTypeHeaderCell } from '../components/cells/header/PayTypeHeaderCell';
import { StatusCell } from '../components/cells/body/StatusCell';

import { makeApprovedLevelGroupedCell } from '../hoc/makeApprovedLevelCell';
import { makeDetailsGroupCell } from '../hoc/makeDetailsCell';
import { makeDoubleTimeHoursCell } from '../hoc/makeDoubleTimeHoursCell';
import { makeEmployeeGroupedCell } from '../hoc/makeEmployeeCell';
import { makeExpensesCell } from '../hoc/makeExpensesCell';
import { makeMilesCell } from '../hoc/makeMilesCell';

import { makeOvertimeHoursCell } from '../hoc/makeOvertimeHoursCell';
import { makeRegularHoursGroupedCell } from '../hoc/makeRegularHoursCell';
import { makeTotalPayCell } from '../hoc/makeTotalPayCell';
import { makeGroupedTypeCell } from '../hoc/makeTypeCell';

import { IPayrollSheetGroupedRow, PayrollCells } from '../model';
import { setPayrollProcessorSort } from 'modules/payrollProcessorHub/store/actions';
import { selectPayrollProcessorSort } from 'modules/payrollProcessorHub/store/selectors';
import { GroupedEditedByCell } from '../components/cells/body/EditedByCell';

type PayrollGroupedCellDictionaryFull = Record<PayrollCells, ICellInfo<IPayrollSheetGroupedRow>>;
type PayrollGroupedCellDictionaryPartial = Omit<
PayrollGroupedCellDictionaryFull,
PayrollCells.JobNumber | PayrollCells.DealNumber
>;

const renderTitle = (id, title) => function TableHeadCell() {
    return (
        <SortableTableHead
            id={id}
            tableSortingSelector={selectPayrollProcessorSort}
            setOrderAction={setPayrollProcessorSort}
        >
            {title}
        </SortableTableHead>
    );
};

export const usePayrollGroupedCellDictionary = (): PayrollGroupedCellDictionaryPartial => {
    const tableClasses = useDefaultTableStyles();
    const cellClasses = useGroupedSheetTableStyles();
    const userHasEditPermission = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));
    const cellSizes = usePayrollCellsSizes();

    return useMemo(() => ({
        [PayrollCells.Details]: {
            key: 'details',
            title: '',
            width: cellSizes[PayrollCells.Details],
            render: makeDetailsGroupCell(tableClasses, userHasEditPermission),
        },
        [PayrollCells.OverdueDetails]: {
            key: 'details',
            title: '',
            width: cellSizes[PayrollCells.OverdueDetails],
            render: makeOverdueDetailsBatchCell(tableClasses),
        },
        [PayrollCells.Type]: {
            key: 'type',
            title: '',
            width: cellSizes[PayrollCells.Type],
            render: makeGroupedTypeCell(cellClasses),
        },
        [PayrollCells.Employee]: {
            key: 'employee',
            title: 'employee',
            renderTitle: renderTitle('user_name', 'employee'),
            width: cellSizes[PayrollCells.Employee],
            render: makeEmployeeGroupedCell(cellClasses),
        },
        [PayrollCells.PayPeriod]: {
            key: 'payPeriod',
            title: 'pay period',
            renderTitle: renderTitle('pay_period_ends_at', 'pay period'),
            width: cellSizes[PayrollCells.PayPeriod],
            render: PayPeriodCell,
        },
        [PayrollCells.PayPeriodInfo]: {
            key: 'payPeriodInfo',
            title: 'PP',
            width: cellSizes[PayrollCells.PayPeriodInfo],
            render: PayPeriodInfoGroupedCell,
        },
        [PayrollCells.Client]: {
            key: 'client',
            title: 'client',
            width: cellSizes[PayrollCells.Client],
            render: ClientCell,
        },
        [PayrollCells.ApprovedLevel]: {
            key: 'approvedLevel',
            title: 'Approved',
            width: cellSizes[PayrollCells.ApprovedLevel],
            headerClassName: tableClasses.pullRight,
            render: makeApprovedLevelGroupedCell(tableClasses),
        },
        [PayrollCells.Files]: {
            key: 'files',
            title: 'files | pay',
            width: cellSizes[PayrollCells.Files],
            render: FilesCell,
        },
        [PayrollCells.RegularHours]: {
            key: 'regularHours',
            title: 'REG HRS | PAY',
            width: cellSizes[PayrollCells.RegularHours],
            headerClassName: tableClasses.pullRight,
            renderTitle: renderTitle('rt_hours', 'REG HRS | PAY'),
            render: makeRegularHoursGroupedCell(tableClasses),
        },
        [PayrollCells.OvertimeHours]: {
            key: 'overtimeHours',
            title: 'OT HRS | PAY',
            width: cellSizes[PayrollCells.OvertimeHours],
            headerClassName: tableClasses.pullRight,
            renderTitle: renderTitle('ot_hours', 'OT HRS | PAY'),
            render: makeOvertimeHoursCell(tableClasses),
        },
        [PayrollCells.DoubleTimeHours]: {
            key: 'doubleTimeHours',
            title: 'DT HRS | PAY',
            width: cellSizes[PayrollCells.DoubleTimeHours],
            headerClassName: tableClasses.pullRight,
            renderTitle: renderTitle('dt_hours', 'DT HRS | PAY'),
            render: makeDoubleTimeHoursCell(tableClasses),
        },
        [PayrollCells.PTO]: {
            key: 'pto',
            title: 'PTO',
            width: cellSizes[PayrollCells.PTO],
            headerClassName: tableClasses.pullRight,
            renderTitle: renderTitle('pto_hours', 'PTO'),
            render: makePtoCell(tableClasses),
        },
        [PayrollCells.PSL]: {
            key: 'psl',
            title: 'PSL',
            width: cellSizes[PayrollCells.PSL],
            headerClassName: tableClasses.pullRight,
            renderTitle: renderTitle('psl_hours', 'PSL'),
            render: makePslCell(tableClasses),
        },
        [PayrollCells.Expenses]: {
            key: 'expenses',
            title: 'expenses',
            headerClassName: tableClasses.pullRight,
            width: cellSizes[PayrollCells.Expenses],
            renderTitle: renderTitle('total_expenses', 'expenses'),
            render: makeExpensesCell(tableClasses),
        },
        [PayrollCells.Miles]: {
            key: 'miles',
            title: 'miles',
            width: cellSizes[PayrollCells.Miles],
            headerClassName: tableClasses.pullRight,
            render: makeMilesCell(tableClasses),
        },
        [PayrollCells.TotalPay]: {
            key: 'totalPay',
            title: 'total pay',
            width: cellSizes[PayrollCells.TotalPay],
            headerClassName: clsx(tableClasses.pullRight, cellClasses.bold),
            render: makeTotalPayCell(tableClasses, cellClasses),
        },
        [PayrollCells.Status]: {
            key: 'status',
            title: 'status',
            width: cellSizes[PayrollCells.Status],
            renderTitle: renderTitle('payroll_status', 'status'),
            render: StatusCell,
        },
        [PayrollCells.ApprovedByCell]: {
            key: 'approvedBy',
            title: 'approved by',
            width: cellSizes[PayrollCells.ApprovedByCell],
            render: GroupedApprovedByCell,
        },
        [PayrollCells.EditedByCell]: {
            key: 'editedBy',
            title: 'edited by',
            width: cellSizes[PayrollCells.ApprovedByCell],
            render: GroupedEditedByCell,
        },
        [PayrollCells.Approvers]: {
            key: 'approvers',
            title: 'approvers',
            width: cellSizes[PayrollCells.Approvers],
            render: GroupedApproversCell,
        },
        [PayrollCells.PayType]: {
            key: 'pay_type',
            width: cellSizes[PayrollCells.PayType],
            renderTitle: PayTypeHeaderCell,
            render: PayTypeGroupedCell,
        },
    }), [cellClasses, cellSizes, tableClasses, userHasEditPermission]);
};
