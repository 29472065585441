import { useMemo } from 'react';

export function useSubassignmentApproversInitialState(numberOfApprovers = 1) {
    return useMemo(() => {
        const seq = Array.from(Array(numberOfApprovers).keys());

        return {
            approvers: Object.fromEntries(
                seq.map(index => [index, null]),
            ),
        };
    }, [numberOfApprovers]);
}
