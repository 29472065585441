import { Theme, fade } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useSheetApprovalStyles = makeHighPriorityStyles((theme: Theme) => ({
    buttonApprove: {
        padding: theme.spacing(1, 2.5),
        height: 'fit-content',
        margin: theme.spacing(1),
        minWidth: 'auto',
        boxShadow: `${fade(colors.black, 0.14)} 0px 1px 1px 0px, ${fade(colors.black, 0.12)} 0px 2px 1px -1px, ${fade(colors.black, 0.2)} 0px 1px 3px 0px`,
        '&:hover': {
            backgroundColor: colors.green,
        },
    },
    buttonReject: {
        boxShadow: 'none',
        textDecoration: 'underline',
        backgroundColor: 'inherit',
        padding: theme.spacing(1, 2.5),
        height: 'fit-content',
        margin: theme.spacing(1),
        color: theme.palette.primary.main,
        minWidth: 'auto',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    buttonTextStyle: {
        textTransform: 'uppercase',
        fontSize: 14,
    },
    footer: {
        padding: theme.spacing(2),
        marginTop: 'auto',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
    content: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
    modal: {
        textAlign: 'center',
        margin: theme.spacing(2),
    },
}));
