import { ILogger } from 'shared/utils/logging/logger';

export const consoleLogger: ILogger = {
    critical: console.error,
    error: console.error,
    warning: console.warn,
    info: console.info,
    setUserId: userId => {
        console.log('setUserId', userId);
    },
};
