import { call, put, takeLatest } from 'typed-redux-saga';
import { getEmployeeTypes } from './actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { employeeTypeApi } from './api';

function* getEmployeeTypesSaga() {
    const employeeTypes = yield* call(employeeTypeApi.getAll);
    yield put(getEmployeeTypes.success(employeeTypes));
}

function* getEmployeeTypesWatcher() {
    yield takeLatest(
        getEmployeeTypes.initType,
        withBackendErrorHandler(
            getEmployeeTypesSaga,
            getEmployeeTypes.error,
            'Unable to load employee types',
        ),
    );
}

export default [
    getEmployeeTypesWatcher,
];
