import { IEntry } from 'shared/models/sheet/Sheet';
import { useMemo } from 'react';

export function useEntriesByDay(entries: IEntry[]): Record<string, IEntry[]> {
    return useMemo(() => {
        return entries.reduce<Record<string, IEntry[]>>((entriesMap, entry) => {
            if (!entriesMap[entry.entry_date]) {
                entriesMap[entry.entry_date] = [];
            }
            entriesMap[entry.entry_date].push(entry);
            return entriesMap;
        }, {});
    }, [entries]);
}

export function useEntriesByDayArray(entries: IEntry[]): IEntry[][] {
    const entriesByDay = useEntriesByDay(entries);
    return useMemo(() => {
        const entriesByDayArray = Object.keys(entriesByDay).sort().map(day => entriesByDay[day]);
        const minDaysInWeek = 7;
        while (entriesByDayArray.length < minDaysInWeek) {
            entriesByDayArray[entriesByDayArray.length] = [];
        }
        return entriesByDayArray;
    }, [entriesByDay]);
}
