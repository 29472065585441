import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { HierarchyPageContent } from './HierarchyPageContent';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ContextProvider } from 'react-sortly';
import { HierarchyTable } from './HierarchyTable/HierarchyTable';
import { HierarchyTableSortableBody } from './HierarchyTable/components/HierarchyTableSortableBody';
import { AvailableCustomFieldList } from './AvailableCustomFields/AvailableCustomFieldList';
import { HierarchyContext, IHierarchyContext } from './HierarchyListsContext';
import { ICustomFieldHierarchyRow } from '../../customFields/store/models';
import { useSelector } from 'react-redux';
import { hierarchyRowsSelector } from './HierarchyTable/store/selectors';
import { availableHierarchyRowsSelector } from './AvailableCustomFields/store/selectors';

export const HierarchyPageSortable = () => {
    const rows = useSelector(hierarchyRowsSelector);
    const availableRows = useSelector(availableHierarchyRowsSelector);

    const [availableList, setAvailableList] = React.useState<ICustomFieldHierarchyRow[]>(availableRows);
    const [hierarchyList, setHierarchyList] = React.useState<ICustomFieldHierarchyRow[]>(rows);
    const hierarchyStore: IHierarchyContext = {
        hierarchyList,
        availableList,
        setAvailableList,
        setHierarchyList,
    };
    useEffect(() => {
        setAvailableList(availableRows);
    }, [availableRows]);
    useEffect(() => {
        setHierarchyList(rows);
    }, [rows]);

    return (
        <HierarchyContext.Provider value={hierarchyStore}>
            <HierarchyPageContent>
                <DndProvider backend={HTML5Backend}>
                    <ContextProvider>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            paddingX={3}
                        >
                            <AvailableCustomFieldList/>
                            <HierarchyTable BodyComponent={HierarchyTableSortableBody} rowData={hierarchyList}/>
                        </Box>
                    </ContextProvider>
                </DndProvider>
            </HierarchyPageContent>
        </HierarchyContext.Provider>
    );
};
