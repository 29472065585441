import React from 'react';
import { useFormikContext } from 'formik';
import { FormControl, Grid, OutlinedInput } from '@material-ui/core';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { IBulkEditSubAssignmentForm, SubassignmentEditFormClasses } from '../../types';
import MoneyField from 'shared/components/formFields/MoneyField';
import { Nullable } from '../../../../../../../types/types';

interface IRatesSectionProps {
    classes: SubassignmentEditFormClasses;
    baseRate?: Nullable<string>;
    formHelperTextClasses: any;
    isLoading?: boolean;
}

export const RatesSection = ({
    baseRate,
    classes,
    formHelperTextClasses,
    isLoading,
}: IRatesSectionProps) => {
    const formikContext = useFormikContext<IBulkEditSubAssignmentForm>();
    const { overrideRate } = formikContext?.values || {};
    const differential = baseRate && overrideRate ? overrideRate - parseFloat(baseRate) : null;

    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                md={6}
            >
                <MoneyField
                    name="overrideRate"
                    outerLabel="Override Rate"
                    className={classes.input}
                    withDollar
                    disabled={isLoading}
                />
            </Grid>

            {baseRate && (
                <Grid
                    item
                    sm={8}
                    md={4}
                >
                    <FormControl
                        className={classes.input}
                        variant="outlined"
                    >
                        <label className={formHelperTextClasses.outerLabel}>
                        Differential Rate Calculation
                        </label>
                        <OutlinedInput
                            disabled
                            autoComplete="off"
                            value={differential === null ? '' : formatDollars(differential)}
                        />
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
};
