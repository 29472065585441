import React, { useCallback } from 'react';
import { CompensationChange } from 'modules/subassignmentManagement/components/CompensationChange/CompensationChange';
import {
    useSubassignmentTitle,
} from 'modules/subassignmentManagement/components/EditSubAssignment/hooks/useSubassignmentTitle';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { EditSubAssignmentFormShort } from '../EditSubAssignmentForm/EditSubAssignmentFormShort';
import { SubAssignmentIsActiveWarning } from '../SubAssignmentIsActiveWarning';
import { editSubAssignmentAction } from 'modules/subassignmentManagement/store/actions';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import {
    selectAllNotDeletedCustomFieldValues,
} from 'store/entities/customFields/selectors';
import {
    selectAssignmentByEditSubassignment,
    selectEditSubassignment,
    selectEditSubassignmentId,
    selectIsUpdatingSubassignment,
} from './store/selectors';
import { SubassignmentDetails } from './SubassignmentDetail';
import { EditSubAssignmentForm } from '../EditSubAssignmentForm/EditSubAssignmentForm';
import { IBulkEditSubAssignmentForm } from '../EditSubAssignmentForm/types';
import { prepareCustomFieldsPayload } from '../helpers';
import { setEditSubassignmentId } from '../store/actions';
import { WorkspaceSidebar } from 'shared/components/workspaces/WorkspaceSidebar/WorkspaceSidebar';
import { PromotionDemotion } from 'modules/subassignmentManagement/components/PromotionDemotion/PromotionDemotion';

export const EditSingleSubassignmentSidebar = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const editId = useSelector(selectEditSubassignmentId);
    const assignment = useSelector(selectAssignmentByEditSubassignment);
    const subassignment = useSelector(selectEditSubassignment);
    const customFieldValues = useSelector(selectAllNotDeletedCustomFieldValues);
    const isLoading = useSelector(selectIsUpdatingSubassignment);
    const isSubAssignmentActive = !!subassignment?.is_active;
    const approvalLevel = assignment?.approval_levels || 0;
    const assignmentStartDate = assignment?.hire_date;

    const onClose = useCallback(() => {
        dispatch(setEditSubassignmentId(null));
    }, [dispatch]);

    const onEdit = useCallback((formValues: IBulkEditSubAssignmentForm) => {
        const customFieldsPayload = prepareCustomFieldsPayload(formValues, customFieldValues);

        dispatch(editSubAssignmentAction.init({
            id: editId,
            client_id: clientId,
            start_date: formValues.startDate,
            end_date: formValues.endDate || null,
            override_rate_value: formValues.overrideRate?.toString() || '',
            custom_field_value_ids: customFieldsPayload.fieldValueIds?.filter(Boolean),
            all_values_custom_field_ids: customFieldsPayload.allCheckedFieldIds,
            managers: (
                Object
                    .values(formValues.approvers || {})
                    .map((a, i) => ({ user_id: a?.id || '', manager_level: i + 1 }))
            ),
        }));
    }, [dispatch, clientId, editId, customFieldValues]);

    const title = useSubassignmentTitle(subassignment?.id);

    return (
        <WorkspaceSidebar
            title={title}
            isOpened={Boolean(editId)}
            onClose={onClose}
            key={subassignment?.id}
        >
            <Box m={3}>
                <SubAssignmentIsActiveWarning show={isSubAssignmentActive}/>

                <SubassignmentDetails/>

                {isSubAssignmentActive && (
                    <EditSubAssignmentFormShort
                        submitButtonTitle="Save"
                        isLoading={isLoading}
                        subassignment={subassignment}
                        approvalLevel={approvalLevel}
                        assignmentStartDate={assignmentStartDate}
                    />
                )}

                {!isSubAssignmentActive && (
                    <EditSubAssignmentForm
                        submitButtonTitle="Save"
                        onSubmit={onEdit}
                        isLoading={isLoading}
                        baseRate={assignment?.pay_rate_value}
                        subassignment={subassignment}
                        numberOfApprovers={approvalLevel}
                        assignmentStartDate={assignmentStartDate}
                    />
                )}
            </Box>
            <PromotionDemotion/>
            <CompensationChange/>
        </WorkspaceSidebar>
    );
};
