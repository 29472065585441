import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { PrismCorporationType, PrismCorporationTypeTitle } from 'store/entities/clients/clientsModel';
import { IFormSelect } from './model';
import { IFormFieldProps } from '../formFields/models';

export interface ICorpTypeProps extends IFormFieldProps, IFormSelect {
}

export default function CorpType({
    ...props
}: ICorpTypeProps) {
    const corpTypes = Object.keys(PrismCorporationType);

    return (
        <FormSelect
            {...props}
            getKey={(corpType: PrismCorporationType) => corpType}
            getText={(corpType: PrismCorporationType) => PrismCorporationTypeTitle[corpType]}
            options={corpTypes}
        />
    );
}
