import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AddTemplateButton } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/AddTemplateButton';
import { setCreateBackgroundCheckTemplateModalState } from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/action';
import CreateBackgroundCheckModal from 'modules/settings/submodules/clients/backgroundCheckTemplates/CreateBackgroundCheck/CreateBackgroundCheckModal';

export const CreateBackgroundCheckButton = () => {
    const dispatch = useDispatch();

    const openModal = useCallback(() => {
        dispatch(setCreateBackgroundCheckTemplateModalState(true));
    }, [dispatch]);
    return (
        <>
            <AddTemplateButton onClick={openModal}/>
            <CreateBackgroundCheckModal/>
        </>
    );
};
