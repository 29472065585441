import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectClientMileageRate } from 'store/entities/clients/selectors/configurationSelectors';
import { IModifiers } from '../components/table/addEntryControls/quantity/model';
import { Decimal } from 'decimal.js';

export const milesModifiers: IModifiers = { min: 0, max: 10 ** 7, fractional: 0 };

export const defaultMileageRate = new Decimal(process.env.REACT_APP_MILEAGE_RATE as string);

export const useClientMileageRate = (clientId: string) => {
    const configRate = useSelector(selectClientMileageRate(clientId));
    return useMemo(() => {
        return new Decimal(configRate || process.env.REACT_APP_MILEAGE_RATE as string);
    }, [configRate]);
};
export const useCurrentClientMileageRate = () => {
    const clientId = useSelector(selectCurrentClientId);
    return useClientMileageRate(clientId);
};
