import { createSelector } from 'reselect';
import { IUserInfo } from 'shared/models/User';
import { IStore } from 'store/configureStore';
import { selectSettingClientsState } from 'modules/settings/submodules/clients/store/selectors';
import { IAssignmentReportData } from 'modules/settings/submodules/clients/assignmentsReportData/store/model';
import { selectUsersById } from 'store/entities/users/selectors';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';

export interface IAssignmentReportDataRow extends IAssignmentReportData {
    employee?: IUserInfo;
    className?: string;
}

const selectTableState = (state: IStore) =>
    selectSettingClientsState(state).assignmentsReportData.assignmentReportDataTable;
export const assignmentsReportDataSelectors = getInfinityScrollSelectors<IAssignmentReportData>(selectTableState);
export const selectAssignmentReportDataRows = createSelector(
    assignmentsReportDataSelectors.selectItems,
    selectUsersById,
    (displayAssignmentReportData, userInfoById) => {
        return displayAssignmentReportData.map(assignmentData => {
            return {
                ...assignmentData,
                employee: userInfoById[assignmentData?.user_id],
            };
        });
    },
);
