import { EafApprovalGrid } from 'modules/eafApproval/components/EafApprovalGrid';
import { EafDetailSidebar } from 'modules/eafApproval/components/EafDetail/EafDetailSidebar';
import {
    resetEafApprovalItems,
    setEafApprovalFilter,
    setEafApprovalSortOrder,
} from 'modules/eafApproval/store/actions';
import { eafTableSelectors } from 'modules/eafApproval/store/selectors';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { useEafHistoryGridCells } from 'modules/subassignmentManagement/components/EafHistory/useEafHistoryGridCells';
import React from 'react';
import { Order } from 'shared/models/Order';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

interface IEafHistoryProps {
    assignmentId: string;
}

export const useStyles = makeHighPriorityStyles({
    rowHighlited: {
        background: 'transparent',
        transition: 'background-color 0.3s',
        '&:hover': {
            background: colors.lightGreen,
        },
    },
});

export const EafHistory = ({ assignmentId }: IEafHistoryProps) => {
    const classes = useStyles();
    useWithClientEffect((dispatch, clientId) => {
        dispatch(setEafApprovalFilter({
            clientId,
            assignmentId: assignmentId,
            allStatuses: true,
        }));
        dispatch(setEafApprovalSortOrder({ 'created_at': Order.desc }));
        return function cleanUp() {
            dispatch(resetEafApprovalItems());
        };
    }, []);

    const cells = useEafHistoryGridCells();

    return (
        <>
            <EafApprovalGrid
                cells={cells}
                rowsSelector={eafTableSelectors.selectItems}
                bodyRowClassName={classes.rowHighlited}
            />
            <EafDetailSidebar/>
        </>
    );
};
