import { throttle } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { horizontalScrollBy } from 'shared/utils/helpers/scrolling/horizontalScrollBy';

import { checkIsScrollable } from '../helpers/checkIsScrollable';
import { getScrollStats, getScrollStatsFromEvent } from '../helpers/getScrollStats';

const THROTTLE_SCROLL_TIMEOUT_MS = 10;
const SCROLL_AMOUNT = 250;

export function useTableScroll() {
    const [tableInstance, setTableInstance] = useState<HTMLDivElement | null>(null);
    const [isScrollable, setIsScrollable] = useState(false);

    const setTableRef = useCallback((instance: HTMLDivElement | null) => setTableInstance(instance), []);

    const handleScroll = useCallback((event: Event) => {
        const stats = getScrollStatsFromEvent(event);
        const isScrollableInner = checkIsScrollable(stats);
        if (isScrollableInner !== isScrollable) {
            setIsScrollable(isScrollableInner);
        }
    }, [isScrollable]);

    const handleClickScrollRight = useCallback(() => {
        if (tableInstance) {
            horizontalScrollBy(tableInstance, SCROLL_AMOUNT);
        }
    }, [tableInstance]);

    const handleClickScrollLeft = useCallback(() => {
        if (tableInstance) {
            horizontalScrollBy(tableInstance, -SCROLL_AMOUNT);
        }
    }, [tableInstance]);

    useEffect(() => {
        if (tableInstance) {
            const isScrollableInner = checkIsScrollable(getScrollStats(tableInstance));
            setIsScrollable(isScrollableInner);
            const scrollHandler = throttle(handleScroll, THROTTLE_SCROLL_TIMEOUT_MS);
            tableInstance.addEventListener('scroll', scrollHandler);
            return () => tableInstance?.removeEventListener('scroll', scrollHandler);
        } else {
            setIsScrollable(false);
        }
    }, [handleScroll, tableInstance]);

    return {
        setTableRef,
        isScrollable,
        handleClickScrollRight,
        handleClickScrollLeft,
    };
}
