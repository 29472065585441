import { useField } from 'formik';
import { useIsEafRetroEnabledForClient } from 'modules/employeeActionsForm/store/hooks';
import { FormSwitchField } from 'modules/settings/submodules/clients/appConfiguration/components/FormSwitchField';
import React from 'react';
import { Box } from '@material-ui/core';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';
import { EffectiveDatePicker, IEffectiveDatePickerProps } from 'modules/subassignmentManagement/components/EafFormShared/EffectiveDatePicker';
import { useEafFormStyles } from 'modules/subassignmentManagement/components/EafFormShared/useEafFormStyles';
import { shortDateFormat } from 'shared/models/Dates';
import { IPaySettings } from 'store/entities/clients/clientsModel';

interface IEffectiveDateSectionProps extends Partial<IEffectiveDatePickerProps> {
    name?: string;
    maxDate?: string;
    paySettings: IPaySettings;
    retroFieldName?: string;
}

export const EffectiveDateSection = ({
    label = 'Effective Date:',
    name = 'effectiveDate',
    retroFieldName,
    ...props
}: IEffectiveDateSectionProps) => {
    const classes = useEafFormStyles();
    const formClasses = sidebarFormStyles();
    const isRetroEnabled = useIsEafRetroEnabledForClient();

    const [retroField] = useField<boolean>(retroFieldName || '');

    return (
        <Box className={classes.row}>
            <label htmlFor={name} className={formClasses.inlineInputLabel}>{label}</label>
            <Box width="160px">
                <EffectiveDatePicker
                    id={name}
                    name={name}
                    format={shortDateFormat}
                    className={formClasses.input}
                    allowRetro={retroFieldName ? retroField.value : false}
                    {...props}
                />
            </Box>
            {isRetroEnabled && retroFieldName && (
                <Box display="flex" alignItems="baseline">
                    <FormSwitchField name={retroFieldName}/>
                    <label>Retro</label>
                </Box>
            )}
        </Box>
    );
};
