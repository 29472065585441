import React from 'react';
import { browserHistory } from 'shared/utils/browserHistory';

/**
 * Navigate user to specific page if in current host is opened in iframe
 * @param url
 */
export const navigateIframedHost = (url: string) => (evt: React.SyntheticEvent) => {
    try {
        const iframe = evt.currentTarget as HTMLIFrameElement;
        if (iframe?.contentWindow?.location.host === window.location.host) {
            browserHistory.push(url);
        }
        // eslint-disable-next-line no-empty
    } catch (e) {
    }
};
