import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { IEmployeeClientInfo } from 'store/entities/clients/clientsModel';

const selectSettingsEmployeesState = (state: IStore) => {
    return state.modules.settings.employees;
};

export const selectEditEmployeeId = createSelector(
    selectSettingsEmployeesState,
    state => state.editEmployeeId,
);

export const selectIsEmployeeUpdating = createSelector(
    selectSettingsEmployeesState,
    state => state.isUpdating,
);

export const selectIsStatusUpdating = createSelector(
    selectSettingsEmployeesState,
    state => state.isStatusUpdating || state.isOffersLoading,
);

export const selectIsEmployeeDeleting = createSelector(
    selectSettingsEmployeesState,
    state => state.isDeleting,
);

export const selectIsPasswordResetting = createSelector(
    selectSettingsEmployeesState,
    state => state.isPasswordResetting,
);

export const selectEditEmployeeClientsIds = createSelector(
    selectSettingsEmployeesState,
    state => state.editEmployeeClients.map(client => client.client.id),
);

export const selectEmployeeClientsData = (state: IStore) => {
    const data = selectSettingsEmployeesState(state);
    return data.editEmployeeClients as IEmployeeClientInfo[];
};

export const selectIsEmployeeSyncing = createSelector(
    selectSettingsEmployeesState,
    state => state.isEmployeeSyncing,
);
