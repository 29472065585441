import { combineReducers } from 'redux';
import {
    IEmployeeSubmittedSheetsFilters,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsModel';
import {
    SET_EMPLOYEE_SUBMITTED_SHEETS_FILTERS,
    setEmployeeSubmittedSheetsFilters, setResubmitSheet,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import { singleValueReducer } from 'store/reducerUtils';

const initialState = {
    filters: {
        location_id: '',
        position_id: '',
        customFieldValues: {},
    } as IEmployeeSubmittedSheetsFilters,
    editSheet: null,
};

function filters(
    state: IEmployeeSubmittedSheetsFilters = initialState.filters,
    action: ReturnType<typeof setEmployeeSubmittedSheetsFilters>,
): IEmployeeSubmittedSheetsFilters {
    switch (action.type) {
        case SET_EMPLOYEE_SUBMITTED_SHEETS_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

const editSheet = singleValueReducer(setResubmitSheet.action, initialState.editSheet);

export const employeeSubmittedSheets = combineReducers({
    filters,
    editSheet,
});
