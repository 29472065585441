import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ILogger } from 'shared/utils/logging/logger';
import { createBrowserHistory } from 'history';

const errorsToIgnore = [
    'ResizeObserver loop limit exceeded', // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
];

const history = createBrowserHistory();
const SENTRY_TRACE_SAMPLE_RATE: number | undefined = process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
    : undefined;

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
    integrations: [
        new Integrations.BrowserTracing({
            idleTimeout: 15000,
            tracingOrigins: [/^http(s?):\/\/api-.*.headway247.com\/.*$/],
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
    ],
    normalizeDepth: 8,
    ignoreErrors: errorsToIgnore,
});

const trackErrorEvent = (severity: Sentry.Severity) => (error: any, errorInfo?: any) => {
    const logError = error instanceof Error ? error : new Error(error);
    let fingerprint = '';
    if (logError?.message?.includes('Request failed with status code') || logError?.message?.includes('in root saga')) {
        if (logError?.message?.startsWith('Request failed with status code')) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const configReponse = logError['response']?.config;
            const errorRequestUrl = configReponse?.url;
            if (errorRequestUrl) {
                fingerprint = errorRequestUrl;
            } else {
                fingerprint = logError?.message;
            }
        } else {
            fingerprint = logError?.message;
        }
        Sentry.captureException(
            logError,
            {
                level: severity,
                extra: errorInfo,
                fingerprint: [fingerprint],
            },
        );
    } else {
        Sentry.captureException(
            logError,
            {
                level: severity,
                extra: errorInfo,
            },
        );
    }
};

export const sentryLogger: ILogger = {
    critical: trackErrorEvent(Sentry.Severity.Critical),
    error: trackErrorEvent(Sentry.Severity.Error),
    warning: trackErrorEvent(Sentry.Severity.Warning),
    info: trackErrorEvent(Sentry.Severity.Info),
    setUserId: userId => {
        Sentry.setUser({ id: userId });
    },
};

export {
    history,
};
