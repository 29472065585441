import { logger } from 'shared/utils/logging/logger';

export interface ICookieSupportedResponse {
    supported: boolean;
    timeOut: boolean;
}

let supported: boolean | null = null;

export function thirdPartyCookiesCheck(): Promise<ICookieSupportedResponse> {
    if (supported !== null) {
        return Promise.resolve({ supported, timeOut: false }); 
    }

    return new Promise<ICookieSupportedResponse>(function (resolve) {
        const cookieCheckerUrl = process.env.REACT_APP_COOKIE_CHECK_URL;
        if (!cookieCheckerUrl) {
            logger.error(new Error('REACT_APP_COOKIE_CHECK_URL not defined.'));
            resolve({ supported: true, timeOut: false });
            return;
        }

        const frame = document.createElement('iframe');
        frame.id = '3pc';
        frame.src = process.env.REACT_APP_COOKIE_CHECK_URL as string;
        frame.style.display = 'none';
        frame.style.position = 'fixed';

        window.addEventListener(
            'message',
            function listen(event) {
                if (event.data === '3rd.party.cookies.enabled' || event.data === '3rd.party.cookies.disabled') {
                    supported = event.data === '3rd.party.cookies.enabled';
                    resolve({ supported, timeOut: false });
                    document.body.removeChild(frame);
                    window.removeEventListener('message', listen);
                }
            },
            false,
        );

        setTimeout(function () {
            if (supported === null) {
                supported = false;
                resolve({ supported, timeOut: true });
                document.body.removeChild(frame);
            }
        }, 5000);

        document.body.appendChild(frame);
    });
}
