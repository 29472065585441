import { createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { IControllingOrg, ICreateControllingOrgPayload } from 'shared/models/ControllingOrg';

export const createControllingOrg = createRequestActions<ICreateControllingOrgPayload, IControllingOrg>(
    RequestType.Create,
    'CONTROLLING_ORG',
    'settings/');

const SET_CREATE_CONTROLLING_ORG_MODAL_STATE = 'settings/SET_CREATE_CONTROLLING_ORG_MODAL_STATE';
export const setCreateControllingOrgModalState = createSingleAction<boolean,
    typeof SET_CREATE_CONTROLLING_ORG_MODAL_STATE>(
    SET_CREATE_CONTROLLING_ORG_MODAL_STATE,
);
