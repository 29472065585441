import { IMenuItemType } from '../../../shared/models/MenuItemType';
import moment from 'moment';

export function useOfferLetterYearFilterValues() {
    const thisYear = moment().year();
    const rangeLength = 10;
    const startYear = thisYear - rangeLength;
    const yearsArray = Array.from(Array(rangeLength + 1).keys()).map(x => x + startYear);
    const items: Array<IMenuItemType<string>> = yearsArray.map(year => ({
        // @ts-ignore
        text: String(year),
        value: '01-01-' + String(year),
    }));
    return items;
}
