import { combineReducers } from 'redux';
import {
    deleteCustomField,
    deleteCustomFieldHierarchyNode,
    deleteCustomFieldValue,
    getCustomFields,
    getCustomFieldsHierarchyNodes,
    getCustomFieldsMappings,
    queryCustomFieldValues, setFieldValuesIsLoaded,
    setUseSingleHierarchy,
} from 'store/entities/customFields/actions';
import { ICustomField, ICustomFieldHierarchyNode, ICustomFieldValue } from 'store/entities/customFields/model';
import {
    deleteItemByIdReducer,
    isLoadingReducer,
    itemsByIdReducer,
    singleValueReducer,
} from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';
import {
    updateCustomFieldHierarchy,
} from 'modules/settings/submodules/components/HierarchyPage/store/actions';

const customFieldsByIds = extendReducer(
    itemsByIdReducer<ICustomField, ICustomField>(getCustomFields),
    deleteItemByIdReducer<ICustomField>(deleteCustomField),
);
const isLoadingCustomFields = isLoadingReducer(getCustomFields);

const mappingFields = singleValueReducer(getCustomFieldsMappings.successType, []);
const isLoadingMappingFields = isLoadingReducer(getCustomFieldsMappings);

const customFieldValuesByIds = extendReducer(
    itemsByIdReducer<ICustomFieldValue, ICustomFieldValue>(queryCustomFieldValues),
    deleteItemByIdReducer<ICustomFieldValue>(deleteCustomFieldValue),
);

const isQueryingCustomFieldValues = isLoadingReducer(queryCustomFieldValues);

const isCustomFieldsHierarchySaving = isLoadingReducer(updateCustomFieldHierarchy);

const customFieldsHierarchyNodesByIds = extendReducer(
    itemsByIdReducer<ICustomFieldHierarchyNode, ICustomFieldHierarchyNode>(getCustomFieldsHierarchyNodes),
    deleteItemByIdReducer<ICustomFieldHierarchyNode>(deleteCustomFieldHierarchyNode),
);

const isLoadingCustomFieldsHierarchyNodes = isLoadingReducer(getCustomFieldsHierarchyNodes);
const isClientHierarchyCompatibleWithOfferLetter = singleValueReducer(setUseSingleHierarchy.action, false);

const customFieldValuesLoaded = (
    state = {},
    action: ReturnType<typeof setFieldValuesIsLoaded>,
): Record<string, boolean> => {
    switch (action.type) {
        case setFieldValuesIsLoaded.action:
            if (action.payload.custom_field_id) {
                return {
                    ...state,
                    [action.payload.custom_field_id]: true,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};

export const customFields = combineReducers({
    customFieldsByIds,
    isLoadingCustomFields,
    mappingFields,
    isLoadingMappingFields,
    customFieldValuesByIds,
    isQueryingCustomFieldValues,
    customFieldsHierarchyNodesByIds,
    isLoadingCustomFieldsHierarchyNodes,
    isCustomFieldsHierarchySaving,
    isClientHierarchyCompatibleWithOfferLetter,
    customFieldValuesLoaded,
});
