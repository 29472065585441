import { ActionsReturnTypes, createActions } from 'store/utils';

import { IOfferLetterTemplate } from 'modules/offerLetter/store/templates/models';

const GET_OFFER_LETTER_TEMPLATES = 'GET_OFFER_LETTER_TEMPLATES';
const GET_OFFER_LETTER_TEMPLATES_SUCCESS = 'GET_OFFER_LETTER_TEMPLATES_SUCCESS';
const GET_OFFER_LETTER_TEMPLATES_ERROR = 'GET_OFFER_LETTER_TEMPLATES_ERROR';

export const getOfferLetterTemplates = createActions<
void,
Array<IOfferLetterTemplate>,
any,
typeof GET_OFFER_LETTER_TEMPLATES,
typeof GET_OFFER_LETTER_TEMPLATES_SUCCESS,
typeof GET_OFFER_LETTER_TEMPLATES_ERROR
>(
    GET_OFFER_LETTER_TEMPLATES, GET_OFFER_LETTER_TEMPLATES_SUCCESS, GET_OFFER_LETTER_TEMPLATES_ERROR,
);

const REMOVE_OFFER_LETTER_TEMPLATE = 'REMOVE_OFFER_LETTER_TEMPLATE';
const REMOVE_OFFER_LETTER_TEMPLATE_SUCCESS = 'REMOVE_OFFER_LETTER_TEMPLATE_SUCCESS';
const REMOVE_OFFER_LETTER_TEMPLATE_ERROR = 'REMOVE_OFFER_LETTER_TEMPLATE_ERROR';

export const removeOfferLetterTemplate = createActions<
string,
string,
any,
typeof REMOVE_OFFER_LETTER_TEMPLATE,
typeof REMOVE_OFFER_LETTER_TEMPLATE_SUCCESS,
typeof REMOVE_OFFER_LETTER_TEMPLATE_ERROR
>(
    REMOVE_OFFER_LETTER_TEMPLATE, REMOVE_OFFER_LETTER_TEMPLATE_SUCCESS, REMOVE_OFFER_LETTER_TEMPLATE_ERROR,
);

export type OfferLetterTemplateActions =
    | ActionsReturnTypes<typeof getOfferLetterTemplates>
    | ActionsReturnTypes<typeof removeOfferLetterTemplate>
