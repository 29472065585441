import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import { TimesheetSettings, TimesheetSettingsDisplayValues, UWSystem, UWSystemDisplayValues } from 'shared/models/JobNumber';
import { TimeActivitySelect } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/components/TimeActivitySelect';
import { IDeal } from 'shared/models/Deal';
import NumberField from 'shared/components/formFields/NumberField';

import { useAdditionalValidityErrorText } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/store/useAdditionalValidityErrorText';

export interface ISettingsFormSectionProps {
    deal?: IDeal | null;
}

export const SettingsFormSection = ({ deal }: ISettingsFormSectionProps) => {
    const classes = useSettingsFormStyles();
    const additionalValidityErrorText = useAdditionalValidityErrorText(deal);

    return (
        <Box className={classes.formBlock}>
            <Typography
                variant="subtitle2"
                className={classes.formBlockTitle}
            >
                Settings
            </Typography>

            <Box
                display="flex"
                flexDirection="row"
            >
                <Box width="50%">
                    <EnumFormSelect
                        name="uwSystem"
                        outerLabel="UW System"
                        className={classes.input}
                        values={UWSystem}
                        displayValues={UWSystemDisplayValues}
                    />
                </Box>
                <Box width="50%" mt={2.5}>
                    <NumberField
                        name="fte"
                        label="FTE"
                        inputProps={{
                            decimalScale: 3,
                            fixedDecimalScale: true,
                            allowNegative: false,
                        }}
                        className={classes.input}
                    />
                </Box>
            </Box>

            <Box width="50%">
                <EnumFormSelect
                    name="timesheetSettings"
                    outerLabel="Timesheet Settings"
                    className={classes.input}
                    values={TimesheetSettings}
                    displayValues={TimesheetSettingsDisplayValues}
                />

                {
                    additionalValidityErrorText
                        ? (
                            <Box p={2}>
                                <WarningAlertWithIcon>
                                    { additionalValidityErrorText }
                                </WarningAlertWithIcon>
                            </Box>
                        )
                        : (
                            <TimeActivitySelect
                                name="timeActivities"
                                outerLabel={`Select all time activities`}
                                title="To select multiple options, hold down the CTRL (or COMMAND on a mac) button and then select"
                                className={classes.input}
                                disabled={!deal}
                            />
                        )
                }
            </Box>
        </Box>
    );
};
