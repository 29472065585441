import { Box, Typography } from '@material-ui/core';
import { ICalculationGroupActionsProps } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/ICalculationGroupActionsProps';
import { findUniqFilteredItems } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/findUniqFilteredItems';
import { useApproveStyles } from 'modules/payrollProcessorHub/components/SheetStatusAction/approveStyles';
import { useTemplatesResolverBatch } from 'modules/payrollProcessorHub/components/SheetStatusAction/hooks/useTemplatesResolverBatch';
import {

    useOptionalTimeExpenseReportTemplate,
} from 'modules/payrollProcessorHub/components/SheetStatusAction/utils';
import { changeCalculationGroupStatus, changeSheetGroupStatus } from 'modules/payrollProcessorHub/store/actions';
import { ISheetGroupId } from 'modules/payrollProcessorHub/store/model';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ActionButton from 'shared/components/buttons/ActionButton';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { useModal } from 'shared/utils/hooks/useModal';
import { StatusNames } from 'store/entities/timesheet/models/Status';

export interface IApproveButtonProps {
    groupIds: ISheetGroupId[];
}

interface IApproveButtonCoreProps {
    onClick: () => void;
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    template1: string;
    template2: string;
    template3: string;
}

function ApproveButtonCore(props: IApproveButtonCoreProps) {
    const classes = useApproveStyles();
    return (
        <>
            <ActionButton
                variant="contained"
                color="primary"
                customType="success"
                onClick={props.onClick}
            >
                Accept
            </ActionButton>

            <ConfirmationDialog
                open={props.open}
                onConfirm={props.onConfirm}
                onCancel={props.onCancel}
                confirmText="Accept"
                customClasses={{
                    paper: classes.confirmationDialog,
                    actions: classes.actions,
                    cancelButton: classes.cancelButton,
                }}
            >
                <Box className={classes.content}>
                    <Typography variant="h3" className={classes.heading}>
                        Approve Submitted
                        {props.template1}
                        report
                    </Typography>
                    <Typography gutterBottom>
                        You are not on the approver list of this
                        {props.template2}
                        report.
                    </Typography>
                    <Typography gutterBottom>
                        Are you sure you want to bypass the regular approval flow and accept
                        {props.template3}
                        report?
                    </Typography>
                </Box>
            </ConfirmationDialog>
        </>
    );
}

export const ApproveButton = ({ groupIds }: IApproveButtonProps) => {
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const dispatch = useDispatch();
    const optionalTemplateFunc = useOptionalTimeExpenseReportTemplate(groupIds);

    const onConfirm = () => {
        dispatch(changeSheetGroupStatus.init({
            status: StatusNames.APPROVED,
            groups: groupIds.map(groupId => ({ groupId })),
        }));
        onModalClose();
    };

    return (
        <ApproveButtonCore
            onClick={onModalOpen}
            open={isModalOpened}
            onConfirm={onConfirm}
            onCancel={onModalClose}
            template1={optionalTemplateFunc('Timesheet', 'Expense', 'Timesheet and Expense')}
            template2={optionalTemplateFunc('timesheet', 'expense', 'timesheet and expense')}
            template3={optionalTemplateFunc('the timesheet', 'the expense', 'both timesheet and expense')}
        />
    );
};

export const ApproveButtonBatch = ({ calculationGroups }: ICalculationGroupActionsProps) => {
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const resolveTemplates = useTemplatesResolverBatch(calculationGroups);

    const dispatch = useDispatch();
    const onConfirm = useCallback(() => {
        dispatch(changeCalculationGroupStatus.init({
            statusName: StatusNames.APPROVED,
            groups: calculationGroups.map(calculationGroup => ({
                expenseSheetIds: findUniqFilteredItems(calculationGroup.expense_calculations, 'sheet_id') as string[],
                timeSheetIds: findUniqFilteredItems(calculationGroup.time_calculations, 'sheet_id') as string[],
                clientId: calculationGroup.client_id,
            })),
        }));
        onModalClose();
    }, [calculationGroups, dispatch, onModalClose]);

    return (
        <ApproveButtonCore
            onClick={onModalOpen}
            open={isModalOpened}
            onConfirm={onConfirm}
            onCancel={onModalClose}
            template1={resolveTemplates('Timesheet', 'Expense', 'Timesheet and Expense')}
            template2={resolveTemplates('timesheet', 'expense', 'timesheet and expense')}
            template3={resolveTemplates('the timesheet', 'the expense', 'both timesheet and expense')}
        />
    );
};
