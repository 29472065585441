import React, { useCallback } from 'react';
import { TerminationSidebar } from 'modules/employeeActionsForm/components/Termination/TerminationSidebar';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectDetailAssignment } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { isAssignmentActive } from 'shared/utils/helpers/assingmentsHelper';
import { useModal } from 'shared/utils/hooks/useModal';
import { IAssignment } from 'store/entities/configuration/configurationModel';

export const TerminationButton = () => {
    const assignment = useSelector(selectDetailAssignment);
    const { isModalOpened, onModalOpen, onModalClose } = useModal();

    const openEndSidebar = useCallback(() => {
        if (assignment?.id) {
            onModalOpen();
        }
    }, [onModalOpen, assignment]);

    const isActiveAssignment = Boolean(assignment) && isAssignmentActive(assignment as IAssignment);

    return (
        <>
            {isActiveAssignment && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={openEndSidebar}>
                    Terminate
                </Button>
            )}
            <TerminationSidebar
                isOpened={isModalOpened}
                onClose={onModalClose}
            />
        </>
    );
};
