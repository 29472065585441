import { FieldHelperProps } from 'formik/dist/types';
import { pick, difference } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filterByHierarchy } from 'shared/utils/helpers/customFields/filterByHierarchy';
import { filterBySubassignments } from 'shared/utils/helpers/customFields/filterBySubassignments';
import { getFiltrationByHierarchy } from 'shared/utils/helpers/customFields/getFiltrationByHierarchy';
import { useFilteredSubassignments } from 'shared/utils/helpers/entries';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentUser, selectIsUserHasPermission } from 'store/components/auth/selectors';
import { getFieldValueId } from 'store/entities/customFields/helpers';
import {
    HierarchyNodeOperation,
    HierarchyType,
    ICustomFieldHierarchyNode,
    ICustomFieldValue,
} from 'store/entities/customFields/model';
import { ScopeAction } from 'store/entities/scopes/models';
import { selectCustomFieldScopeRestrictionsByAction, selectUserHasScopeRestrictionByType } from 'store/entities/scopes/selectors';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

/**
 * For filtration custom field values we want to consider values that placed before target field
 *
 * @param customFieldId - field id
 * @param hierarchy - nodes
 * @param customFieldFormValues - all values selected on form
 * @param onlyActionable - for submit entry we want to consider actionable fields
 * but for subassignment we also should consider non-actionable fields
 */
export const useSelectedCustomFieldsBefore = (
    customFieldId: string,
    hierarchy?: ICustomFieldHierarchyNode[],
    customFieldFormValues?: Record<string, string>,
    onlyActionable = true,
): Record<string, string> => {
    return useMemo((): Record<string, string> => {
        const orderedCustomFieldIds = hierarchy ? hierarchy
            .filter(node => onlyActionable ? node.operation === HierarchyNodeOperation.Actionable : true)
            .map(node => node.custom_field_id) : [];
        const customFieldsBefore = orderedCustomFieldIds.slice(0, orderedCustomFieldIds.indexOf(customFieldId));
        return pick(customFieldFormValues, customFieldsBefore) as Record<string, string>;
    }, [hierarchy, customFieldId, customFieldFormValues, onlyActionable]);
};

export const useSelectedFieldIds = (
    multiple: boolean | undefined,
    value: any,
    useIdValues?: boolean,
) => {
    return useMemo((): string[] => {
        let result = [];
        if (multiple && value) {
            result = useIdValues ? value : value.map((option: ICustomFieldValue) => getFieldValueId(option));
        } else if (value) {
            // @ts-ignore
            result = useIdValues ? [value] : [getFieldValueId(value)];
        }
        return result.filter(Boolean);
    }, [multiple, value, useIdValues]);
};

export const useFilteredCustomFieldValues = ({
    hierarchy,
    customFieldId,
    customFieldFormValues,
    customFieldValues,
    useSubassignments = false,
    valuesToShow,
    hierarchyType,
    date,
    payPeriod,
    onlyActionable = true,
    scopeActionFilter,
    userId,
    selectedIds,
    filterInactive = true,
}: {
    hierarchy?: ICustomFieldHierarchyNode[];
    customFieldId: string;
    customFieldFormValues?: Record<string, string>;
    customFieldValues: ICustomFieldValue[];
    useSubassignments?: boolean;
    valuesToShow?: ICustomFieldValue[];
    hierarchyType: HierarchyType;
    date?: string;
    payPeriod?: IPayPeriod;
    onlyActionable?: boolean;
    scopeActionFilter?: ScopeAction;
    userId?: string;
    selectedIds?: string[];
    filterInactive?: boolean;
}) => {
    const selectedCustomFieldsBefore = useSelectedCustomFieldsBefore(
        customFieldId,
        hierarchy,
        customFieldFormValues,
        onlyActionable,
    );

    const scopeRequiredForUser = useSelector(
        selectIsUserHasPermission(Permission.SubAssignmentManagementScopeRequired),
    );

    const userHasScopesByAction = useSelector(selectUserHasScopeRestrictionByType(scopeActionFilter));
    const scopeAllowedCustomFieldValueIdsByAction = useSelector(
        selectCustomFieldScopeRestrictionsByAction(scopeActionFilter, customFieldId),
    );
    const filterInactiveValues = filterInactive;

    const userHasScopes = userHasScopesByAction;
    const scopeAllowedCustomFieldValueIds = scopeAllowedCustomFieldValueIdsByAction;

    const user = useSelector(selectCurrentUser);
    const userSubassignments = useFilteredSubassignments(
        userId || user?.id,
        payPeriod,
        selectedCustomFieldsBefore,
    );

    return useMemo((): ICustomFieldValue[] => {
        let results = [...customFieldValues];
        if (hierarchy && customFieldFormValues) {
            /**
             * Filter values by hierarchy relations - parent and siblings
             */
            const filtrationData = getFiltrationByHierarchy(customFieldId, hierarchy, selectedCustomFieldsBefore);
            results = filterByHierarchy(results, hierarchyType, filtrationData);
        }

        if (filterInactiveValues) {
            results = results.filter(result => result.is_active);
        }

        if (useSubassignments) {
            results = filterBySubassignments(results, userSubassignments, payPeriod, date);
        }

        if (valuesToShow) {
            results = results.filter(result => valuesToShow.some(value => value.id === result.id));
        }

        if (scopeActionFilter) {
            if (userHasScopes && scopeAllowedCustomFieldValueIds.length > 0) {
                results = results.filter(fieldValue => scopeAllowedCustomFieldValueIds.includes(fieldValue.id));
            }
            if (!userHasScopes && scopeRequiredForUser) {
                //hide all values if no scopes available when scopes required for users
                results = [];
            }
        }
        if (selectedIds) {
            // verify that selected values available in options for multiselects
            const filteredIds = results.map(fieldValue => fieldValue.id);
            const missingValueIds = difference(selectedIds, filteredIds);
            if (missingValueIds.length > 0) {
                results = [
                    ...results,
                    ...customFieldValues.filter(fieldValue => missingValueIds.includes(fieldValue.id)),
                ];
            }
        }

        return results;
    }, [
        hierarchy,
        customFieldId,
        customFieldFormValues,
        customFieldValues,
        useSubassignments,
        valuesToShow,
        hierarchyType,
        userSubassignments,
        date,
        payPeriod,
        selectedCustomFieldsBefore,
        scopeActionFilter,
        scopeAllowedCustomFieldValueIds,
        selectedIds,
        userHasScopes,
        scopeRequiredForUser,
        filterInactiveValues,
    ]);
};

export const useAutoselectSingleVariant = ({
    autoSelectSingleVariant,
    selectedValue,
    filteredCustomFieldValues,
    helper,
    useIdValue,
    callback,
}: {
    autoSelectSingleVariant?: boolean;
    selectedValue?: any;
    useIdValue?: boolean;
    filteredCustomFieldValues: ICustomFieldValue[],
    helper: FieldHelperProps<any>;
    callback?: () => void;
}) => {
    useEffect(() => {
        if (autoSelectSingleVariant && !selectedValue && filteredCustomFieldValues.length === 1) {
            const newValueItem = filteredCustomFieldValues[0];
            const newFieldValue = useIdValue ? getFieldValueId(newValueItem) : newValueItem;
            if (newFieldValue !== selectedValue) {
                helper.setValue(newFieldValue);
                callback && callback();
            }
        }
    }, [autoSelectSingleVariant, selectedValue, filteredCustomFieldValues, helper, useIdValue, callback]);
};

export const useFieldResetIfSelectedValueFiltered = ({
    filteredCustomFieldValues,
    customFieldValues,
    selectedValue,
    useIdValue,
    helper,
    callback,
}: {
    selectedValue?: any;
    useIdValue?: boolean;
    customFieldValues: ICustomFieldValue[],
    filteredCustomFieldValues: ICustomFieldValue[],
    helper: FieldHelperProps<any>;
    callback?: () => void;
}) => {
    useEffect(() => {
        if (selectedValue) {
            // Reset field if selected value does not fit the filtration conditions
            const selectedId = useIdValue ? selectedValue : getFieldValueId(selectedValue);
            const isSelectedItem = (item: ICustomFieldValue) => getFieldValueId(item) === selectedId;
            const selectedValueInStore = customFieldValues.find(isSelectedItem);
            const selectedFilteredValue = filteredCustomFieldValues.find(isSelectedItem);
            if (selectedValueInStore && !selectedFilteredValue) {
                helper.setValue(null);
                callback && callback();
            }
        }
    }, [filteredCustomFieldValues, customFieldValues, selectedValue, useIdValue, helper, callback]);
};
