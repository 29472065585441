import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsEditedMissingSheetsHasChanges } from 'modules/payrollProcessorHub/store/selectors';
import { EditPayrollSheetPageContent, IEditPayrollSheetPageContentProps } from 'modules/payrollProcessorHub/components/EditSheet/EditPayrollSheetPageContent';
import { LeavePageConfirmation } from 'modules/payrollProcessorHub/components/EditSheet/LeavePageConfirmation';

interface IEditPayrollSheetPageProps extends Omit<IEditPayrollSheetPageContentProps, 'openConfirmation'> {}

export const EditPayrollSheetPage = (props: IEditPayrollSheetPageProps) => {
    const missingSheetHasChanges = useSelector(selectIsEditedMissingSheetsHasChanges(
        props.timeSheetIds,
        props.expenseSheetIds,
    ));
    const onLeaveMessage = `
Sheet status has become overdue and this sheet will no longer be available on the missing tab.
Are you sure you want to leave the page without submit?
You will not be able to submit this sheet later.`;

    return (
        <LeavePageConfirmation
            where={missingSheetHasChanges}
            message={onLeaveMessage}
            onClose={props.onClose}
        >
            {({ onClose, openConfirmation }) => (
                <EditPayrollSheetPageContent
                    {...props}
                    onClose={onClose}
                    openConfirmation={openConfirmation}
                />
            )}
        </LeavePageConfirmation>
    );
};
