import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useSheetSummaryStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        backgroundColor: colors.white,
        maxHeight: 80,
        border: '1px solid',
        borderColor: colors.lightGray,
    },
    rootShort: {
        display: 'flex',
        backgroundColor: colors.white,
        maxHeight: 50,
        border: '1px solid',
        borderColor: colors.lightGray,
    },
    summaryBlock: {
        minHeight: theme.spacing(6),
        padding: theme.spacing(1, 1),
        textAlign: 'right',
    },
    divider: {
        alignSelf: 'center',
        height: theme.spacing(5),
        borderLeft: `1px dotted ${colors.black}`,
        backgroundColor: colors.white,
    },
    value: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 'normal',
    },
    valueLabel: {
        fontSize: 12,
        lineHeight: 'normal',
        textAlign: 'right',
        fontWeight: 300,
        letterSpacing: 'normal',
    },
    blue: {
        color: colors.blue,
    },
    green: {
        color: colors.green,
    },
    orange: {
        color: colors.orange,
    },
    red: {
        color: colors.red,
    },
}));
