import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const useFormHelperTextStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        fontSize: 12,
        margin: theme.spacing(0.5, 0, 0),
    },
    outerLabel: {
        fontSize: 11,
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom: theme.spacing(0.5),
    },
    chipInputFormHelperTextActive: {
        marginBottom: theme.spacing(0.1),
    },
}));
