import React from 'react';
import { PayrollStatus } from 'modules/payrollProcessorHub/components/PayrollStatus/PayrollStatus';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type StatusCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export const StatusCell = ({ className, payroll_status, pay_period_ends_at }: StatusCellProps) => (
    <PayrollStatus
        className={className}
        payPeriodEnd={pay_period_ends_at}
        status={payroll_status}
    />
);
