import { ActionsReturnTypes, createActions } from 'store/utils';
import { StatusNames } from 'store/entities/timesheet/models/Status';

const GET_TIME_REVIEWER_SHEETS = 'GET_TIME_REVIEWER_SHEETS';
const GET_TIME_REVIEWER_SHEETS_SUCCESS = 'GET_TIME_REVIEWER_SHEETS_SUCCESS';
const GET_TIME_REVIEWER_SHEETS_ERROR = 'GET_TIME_REVIEWER_SHEETS_ERROR';

export const getTimeReviewerSheets = createActions<StatusNames, void, any,
    typeof GET_TIME_REVIEWER_SHEETS, typeof GET_TIME_REVIEWER_SHEETS_SUCCESS,
    typeof GET_TIME_REVIEWER_SHEETS_ERROR>(
    GET_TIME_REVIEWER_SHEETS, GET_TIME_REVIEWER_SHEETS_SUCCESS, GET_TIME_REVIEWER_SHEETS_ERROR,
);

export type TimeApprovalActions = ActionsReturnTypes<typeof getTimeReviewerSheets>;
