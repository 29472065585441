import { call, put, takeLatest } from 'typed-redux-saga';
import { getSubmittingOrgs } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { updateSubmittingOrg } from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/actions';
import { api } from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/api';

function* updateSubmittingOrgSaga(action: ReturnType<typeof updateSubmittingOrg.init>) {
    const { id, data } = action.payload;
    const result = yield* call(api.updateSubmittingOrg, id, data);
    yield put(updateSubmittingOrg.success(result));
    yield put(getSubmittingOrgs.success([result]));
}

export function* updateSubmittingOrgWatcher() {
    yield* takeLatest(
        updateSubmittingOrg.initType,
        withBackendErrorHandler(
            updateSubmittingOrgSaga,
            updateSubmittingOrg.error,
            'Unable to update Submitting Org.',
        ),
    );
}
