import { EntryType } from 'shared/models/sheet/Sheet';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

const SET_CURRENT_PAY_PERIOD = 'working_page/SET_CURRENT_PAY_PERIOD';
export const setCurrentPayPeriod = createSingleAction<IPayPeriod, typeof SET_CURRENT_PAY_PERIOD>(
    SET_CURRENT_PAY_PERIOD,
);

const SET_WORKING_ENTRY_TYPE_FILTER = 'working_page/SET_WORKING_ENTRY_TYPE_FILTER';
export const setWorkingEntryTypeFilter = createSingleAction<EntryType | null, typeof SET_WORKING_ENTRY_TYPE_FILTER>(
    SET_WORKING_ENTRY_TYPE_FILTER,
);

export const getPayPeriodWorkingSheets = createRequestActions<IPayPeriod, IPayPeriod>(
    RequestType.Get,
    'PAY_PERIOD_WORKING_SHEETS',
    'sheets',
);
