import { IStore } from '../../../../store/configureStore';
import { ActionCreatorKnownArgs } from '../../../../store/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
    CheckBoxCellActions,
    CheckedItems,
    useCheckedItemsWithoutState,
} from '../../../../shared/utils/hooks/useCheckedItems';
import { ICcpTransaction } from '../../models/CcpTransaction';

export function useCcpCheckedItemsStore(
    items: Array<ICcpTransaction>,
    selector: (store: IStore) => CheckedItems,
    action: ActionCreatorKnownArgs<CheckedItems, { type: any; payload: CheckedItems }>,
): CheckBoxCellActions {
    const checkedItems = useSelector(selector);
    const dispatch = useDispatch();
    const setCheckedItems = useCallback((newCheckedValues: CheckedItems) => {
        dispatch(action(newCheckedValues));
    }, [dispatch, action]);
    const checkableItems = items.filter(x => !x.parent_transaction_id);
    return useCheckedItemsWithoutState(checkableItems, checkedItems, setCheckedItems);
}
