import {
    selectClientAvailableCustomFields,
} from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { IDashboardMenuItem } from 'modules/settings/submodules/components/DashboardLink/model';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { settingsRoutes } from 'modules/settings/routes';

export const useCustomFieldValueModules = (): IDashboardMenuItem[] => {
    const clientId = useSelector(selectCurrentClientId);
    const customFields = useSelector(selectClientAvailableCustomFields);
    return clientId && customFields.length ? customFields.map(({ id, name, description }) => (
        {
            title: name,
            description: description,
            link: generatePath(
                settingsRoutes.CLIENTS.ASSIGNMENT_SETUP.CUSTOM_FIELD_VALUES.FIELD,
                { clientId, customFieldId: id },
            ),
        }
    )) : [];
};
