import React from 'react';
import { Box } from '@material-ui/core';

import { PayrollStatus } from 'modules/payrollProcessorHub/components/PayrollStatus/PayrollStatus';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { useGroupedPayrollEntries } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/utils';
import { useEntryTypeTabs } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/hooks/useEntryTypeTabs';

import { SheetEntryTable } from '../common/SheetEntryTable';
import { EntryTypeTabSwitcher } from '../common/EntryTypeTabSwitcher';
import { SheetGroupTotalByType } from '../common/SheetGroupTotalByType';
import { SheetGroupActions } from './SheetGroupActions';
import { SheetGroupDetailByType } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/single/SheetGroupDetailByType';

export interface ISheetGroupDetailProp {
    sheetGroup: IGroupedSheetCalculation;
}

export const SheetGroupDetail = ({ sheetGroup }: ISheetGroupDetailProp) => {
    const hasTimeSheet = Boolean(sheetGroup?.time_sheet_id);
    const { activeTab, switchToTimeTab, switchToExpenseTab } = useEntryTypeTabs(hasTimeSheet);
    const groupsByDay = useGroupedPayrollEntries(sheetGroup, activeTab);

    return (
        <Box>
            <EntryTypeTabSwitcher
                onTimeClick={switchToTimeTab}
                onExpenseClick={switchToExpenseTab}
                activeTab={activeTab}
                disabled={!sheetGroup.time_sheet_id || !sheetGroup.expense_sheet_id}
            />

            <Box component="section" p={2}>
                <Box display="flex" flexDirection="row">
                    <SheetGroupDetailByType
                        sheetGroup={sheetGroup}
                        type={activeTab}
                    />
                    <PayrollStatus
                        status={sheetGroup.payroll_status}
                        payPeriodEnd={sheetGroup.pay_period_ends_at}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={3}
                >
                    <Box justifyContent="flex-start">
                        <SheetGroupActions sheetGroup={sheetGroup}/>
                    </Box>
                    <Box justifyContent="flex-end">
                        <SheetGroupTotalByType
                            sheetGroup={sheetGroup}
                            type={activeTab}
                        />
                    </Box>
                </Box>
            </Box>

            <SheetEntryTable
                type={activeTab}
                entriesByDay={groupsByDay}
            />
        </Box>
    );
};
