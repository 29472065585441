import baseApi from 'shared/utils/baseApi';
import { getUsers } from 'store/entities/users/actions';
import { usersApi } from 'store/entities/users/api';
import { IGetUserRequest, UserType } from 'store/entities/users/model';
import { getWildcardMask } from 'store/entities/users/utils';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';
import { searchJobNumbersEmployees } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/actions';

function* searchJobNumbersEmployeesSaga({ payload }: ReturnType<typeof searchJobNumbersEmployees.init>) {
    const request: IGetUserRequest = {
        first_name: payload.firstName ? getWildcardMask(payload.firstName) : undefined,
        last_name: payload.lastName ? getWildcardMask(payload.lastName) : undefined,
        username: payload.username ? getWildcardMask(payload.username) : undefined,
        client_id: baseApi.clientId || undefined,
        user_type: UserType.Employee,
    };
    const data = yield* call(usersApi.getUsers, request);
    yield put(searchJobNumbersEmployees.success(data.users));
    yield put(getUsers.success(data.users));
}

function* searchJobNumbersEmployeesSagaWatcher() {
    yield* takeLatest(
        searchJobNumbersEmployees.initType,
        withBackendErrorHandler(
            searchJobNumbersEmployeesSaga,
            searchJobNumbersEmployees.error,
            'User search failed',
        ),
    );
}

export const jobNumbersAssignUsersSagas = [
    searchJobNumbersEmployeesSagaWatcher,
];
