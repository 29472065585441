import { selectSettingClientsState } from 'modules/settings/submodules/clients/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectScopesByIds } from 'store/entities/scopes/selectors';

export const selectSettingsScopesState = (store: IStore) => selectSettingClientsState(store).scopes;

export const selectScopeIsDeleting = (store: IStore): boolean => {
    return selectSettingsScopesState(store).isDeleting;
};
export const selectScopeIsCreating = (store: IStore): boolean => {
    return selectSettingsScopesState(store).isCreating;
};
export const selectScopeIsUpdating = (store: IStore): boolean => {
    return selectSettingsScopesState(store).isUpdating;
};

export const selectIsOpenCreateScopeModal = (store: IStore): boolean => {
    return selectSettingsScopesState(store).createModalState;
};
export const selectEditScopeId = (store: IStore) => {
    return selectSettingsScopesState(store).editScopeId;
};
export const selectEditScope = createSelector(
    selectEditScopeId,
    selectScopesByIds,
    (id, scopesByIds) => scopesByIds[id],
);
