import { SheetInfoItemTitle } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/model/SheetInfoItemTitle';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';

export function onlyNotEmptyItems(item?: { value: string }): boolean {
    return Boolean(item?.value);
}

export function excludePosition(item: ISheetInfoItemData) {
    return item.title !== SheetInfoItemTitle.Position;
}
