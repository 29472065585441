import React from 'react';
import { ICustomFieldHierarchyRow } from '../../customFields/store/models';
import { noop } from 'lodash-es';

export const HierarchyContext = React.createContext<IHierarchyContext>({
    hierarchyList: [],
    availableList: [],
    setHierarchyList: noop,
    setAvailableList: noop,
});

export interface IHierarchyContext {
    hierarchyList: ICustomFieldHierarchyRow[];
    availableList: ICustomFieldHierarchyRow[];
    setHierarchyList: (list: ICustomFieldHierarchyRow[]) => void;
    setAvailableList: (list: ICustomFieldHierarchyRow[]) => void;
}
