import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { fade, Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useScaZoneStyles = makeHighPriorityStyles((theme: Theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    addButton: {
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colors.darkGray,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    inputHidden: {
        display: 'none',
    },
    title: {
        margin: theme.spacing(0.5, 0, 0, 0.5),
        fontSize: 12,
    },
    selectInput: {
        height: theme.spacing(3),
        padding: theme.spacing(1, 2, 1, 1),
    },
    selectInputWithPrefix: {
        paddingLeft: 0,
        marginLeft: theme.spacing(-0.5),
    },
    popperElement: {
        zIndex: theme.zIndex.appBar + 1,
    },
    popoverWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(1),
        minWidth: theme.spacing(24),
        maxWidth: '75vw',
        backgroundColor: colors.white,
    },
    popoverTitle: {
        width: '100%',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    optionsWrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxHeight: theme.spacing(15),
        overflowY: 'auto',
        overflowX: 'hidden',
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    optionElement: {
        cursor: 'pointer',
        lineHeight: 1.5,
    },
    active: {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
    },
    withPrefix: {
        paddingLeft: theme.spacing(1),
    },
    inputAdornment: {
        fontSize: 14,
    },
}));
