import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import {
    createSubmittingOrg as createSubmittingOrgAction,
    setCreateSubmittingOrgModalState,
} from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/actions';

import { combineReducers } from 'redux';

const isSubmittingOrgCreating = isLoadingReducer(createSubmittingOrgAction);
const isCreateSubmittingOrgModalOpen = isOpenModalReducer(setCreateSubmittingOrgModalState.action);

export const createSubmittingOrg = combineReducers({
    isSubmittingOrgCreating,
    isCreateSubmittingOrgModalOpen,
});
