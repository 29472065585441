import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

const useQuantityInfoStyles = makeHighPriorityStyles((theme: Theme) => ({
    textRight: {
        justifyContent: 'flex-end',
    },
    listWrapper: {
        padding: 0,
    },
    listItem: {
        padding: theme.spacing(0),
        justifyContent: 'flex-end',
    },
    amountText: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: `${theme.spacing(2.5)}px`,
    },
    labelText: {
        fontSize: 12,
        color: theme.palette.text.primary,
    },
}));

export default useQuantityInfoStyles;
