import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStyles = makeHighPriorityStyles({
    container: {
        background: colors.white,
        minHeight: '100%',
        minWidth: '100%',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
        maxWidth: `calc(100% - 200px)`,
    },
});
