import React from 'react';
import { useSelector } from 'react-redux';
import ItemRendererProps from 'react-sortly/lib/types/ItemRendererProps';
import GridTableRow, { IGridTableRowProps } from 'shared/components/table/GridTable/GridTableRow';
import { ICustomFieldHierarchyRow } from 'modules/settings/submodules/customFields/store/models';
import { selectCurrentClientHasAssignments } from 'store/entities/clients/selectors/clientsSelectors';
import { DraggableItemRender } from '../../DraggableItemRender';
import { useHierarchyTableStyles } from '../styles';

interface IHierarchyRowProps extends
    ItemRendererProps<ICustomFieldHierarchyRow>, IGridTableRowProps<ICustomFieldHierarchyRow>
{
    key: string;
}

export function HierarchyRow({ data, index, key, depth, ...rowProps }: IHierarchyRowProps) {
    const classes = useHierarchyTableStyles();
    const currentClientHasAssignments = useSelector(selectCurrentClientHasAssignments);
    return (
        <DraggableItemRender
            classes={{ root: classes.tableRow }}
            depth={depth}
            disabled={currentClientHasAssignments}
        >
            <GridTableRow
                key={key}
                row={data}
                index={index}
                {...rowProps}
            />
        </DraggableItemRender>
    );
}
