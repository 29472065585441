import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { SheetApprovalContainer } from 'modules/sheetApproval/components/SheetApproval/SheetApprovalContainer';
import { approvalTableSelectorsByType } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/helper';
import { approvalTableSelectors } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/selectors';
import {
    ManagerSheetGroupDetailSidebar,
} from 'modules/sheetApproval/components/SheetGroupDetailSidebar/ManagerSheetGroupDetailSidebar';
import {
    SheetGroupQuickActions,
} from 'modules/sheetApproval/components/SheetGroupDetailSidebar/SheetGroupQuickActions';
import { selectGroupedCheckedSheetsItems } from 'modules/sheetApproval/store/selectors';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { setSheetApprovalLoadingState } from 'store/components/sheetApprovalCommon/sheetApprovalCommonActions';
import { useApprovalTableStyles } from 'shared/components/table/TableApprovalStyles';
import { EntryType } from 'shared/models/sheet/Sheet';
import {
    SheetApprovalInfinityTable,
} from 'modules/sheetApproval/components/SheetApprovalInfinityTable/SheetApprovalInfinityTable';
import { getClientPayPeriod } from 'store/entities/timesheet/actions/sheets';

interface ISheetApprovalProps {
    sheetType: EntryType;
}

export function SheetApproval({ sheetType }: ISheetApprovalProps) {
    const classes = useApprovalTableStyles();
    const dispatch = useDispatch();

    const tableSelectors = approvalTableSelectorsByType(sheetType);
    const isLoading = useSelector(approvalTableSelectors.isLoading);
    const total = useSelector(approvalTableSelectors.selectTotalItems);
    const checkedGroups = useSelector(selectGroupedCheckedSheetsItems);
    const rows = useSelector(tableSelectors.selectGroupedSheetRows);
    const checkedSheets = useMemo(() => {
        return rows.filter(row => checkedGroups[row.id]).map(row => row.group.sheets).flat();
    }, [rows, checkedGroups]);
    const linkedUsers = useMemo(() => rows.map(row => row.group.employee), [rows]);

    useEffect(() => {
        return function cleanUp() {
            // to have initial loading by default when returning to the page
            dispatch(setSheetApprovalLoadingState(true));
        };
    }, [dispatch, sheetType]);
    useWithClientEffect(dispatchWithClient => {
        dispatchWithClient(getClientPayPeriod.init());
    });

    return (
        <Box className={classes.mainContainer}>
            <SheetApprovalContainer
                sheetType={sheetType}
                linkedUsers={linkedUsers}
                checkedSheets={checkedSheets}
                // @ts-ignore
                total={total}
                isLoading={isLoading}
                classes={classes}
                withInfinityTable
            >
                <SheetApprovalInfinityTable sheetType={sheetType}/>
            </SheetApprovalContainer>
            <ManagerSheetGroupDetailSidebar sheetType={sheetType}/>
            <SheetGroupQuickActions sheetType={sheetType}/>
        </Box>
    );
}
