import React from 'react';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import { Typography } from '@material-ui/core';
import { getFormattedPayPeriod, monthTextDateFormat } from 'shared/models/Dates';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

export interface IPayPeriodReadonlyProps {
    payPeriod?: IPayPeriod;
}

export const PayPeriodReadonly = ({ payPeriod }: IPayPeriodReadonlyProps) => {
    const classes = useSheetsInProgressStyles();

    return (
        <>
            {payPeriod && (
                <Typography className={classes.durationText} variant="body1">
                    {getFormattedPayPeriod(payPeriod, monthTextDateFormat)}
                </Typography>
            )}
        </>
    );
};
