import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface IPdfSVGProps {
    customClasses?: string;
}

const PdfSVG: FC<IPdfSVGProps> = ({ customClasses }: IPdfSVGProps) => {
    return (
        <SvgIcon fontSize="small" className={customClasses}>
            <path
                d="M 16 3.38 c -0.23 -0.22 -0.5 -0.39 -0.79 -0.5 c -0.29 -0.13 -0.6 -0.2 -0.92 -0.21 H 5 c -0.55 0 -1 0.45 -1 1 v 16.66 c 0 0.55
                 0.45 1 1 1 h 14 c 0.55 0 1 -0.45 1 -1 v -12 c -0.01 -0.32 -0.08 -0.63 -0.21 -0.92 c -0.11 -0.29 -0.28 -0.56 -0.5 -0.79 L 16 3.38
                  Z M 15.05 4.32 l 3.26 3.26 c 0.11 0.13 0.19 0.27 0.23 0.43 h -3.87 V 4.09 c 0.15 0.05 0.3 0.12 0.42 0.23 H 15.05 Z M 18.66 20 H
                   5.34 V 4 h 8 v 4.34 c 0 0.55 0.45 1 1 1 h 4.33 V 20 l 0 0 H 18.66 Z M 13.31 13.83 c -0.73 -0.67 -1.26 -1.53 -1.52 -2.48 c 0.1
                   -0.66 0.15 -1.33 0.15 -2 V 9 c 0 -0.03 0 -0.05 0 -0.08 c 0 -0.03 0 -0.05 0 -0.08 c 0.08 -0.24 0.05 -0.5 -0.09 -0.71 C 11.76
                   8.04 11.64 7.99 11.51 8 h -0.23 c -0.23 0.01 -0.42 0.19 -0.44 0.42 c -0.1 0.44 -0.12 0.89 -0.07 1.33 c 0.05 0.56 0.16 1.12 0.32
                    1.66 c -0.28 0.94 -0.64 1.85 -1.09 2.72 c -0.37 0.78 -0.74 1.47 -1.1 2.06 l 0 0 c -0.03 -0.08 -0.12 -0.12 -0.19 -0.09 c -0.02 0.01
                    -0.03 0.02 -0.05 0.03 c -0.51 0.25 -0.97 0.59 -1.37 1 c -0.28 0.28 -0.48 0.62 -0.58 1 c -0.04 0.13 -0.02 0.26 0.06 0.37 l 0.03 -0.03
                    l 0.25 0.12 c 0.09 0.05 0.19 0.07 0.29 0.07 c 0.62 0 1.46 -0.91 2.52 -2.73 c 1.32 -0.44 2.69 -0.73 4.08 -0.86 c 0.43 0.23 0.88 0.41
                    1.35 0.55 c 0.39 0.13 0.79 0.2 1.2 0.21 c 0.31 0.09 0.64 -0.09 0.74 -0.4 l 0 0 l 0 0 l 0 0 c 0.11 -0.16 0.11 -0.38 0 -0.54 c -0.5
                    -0.44 -1.18 -0.63 -1.84 -0.51 c -0.41 0 -0.82 0.02 -1.22 0.07 c -0.3 -0.17 -0.59 -0.37 -0.87 -0.58 l 0 0 L 13.31 13.83 Z M 7.83 17.33
                     c 0.27 -0.32 0.58 -0.61 0.91 -0.87 c -0.34 0.65 -0.83 1.22 -1.43 1.64 C 7.45 17.82 7.62 17.56 7.83 17.33 Z M 11.46 8.52 L 11.46 8.52
                      c 0.09 0.1 0.14 0.23 0.14 0.37 v 0.02 c 0 0.03 0 0.05 0 0.08 c -0.05 0.28 -0.07 0.43 -0.07 0.45 L 11.46 9.9 c -0.1 -0.45 -0.1 -0.92
                       0 -1.37 l 0 0 L 11.46 8.52 Z M 10.64 14.52 c 0.34 -0.66 0.63 -1.34 0.86 -2.05 c 0.31 0.69 0.76 1.32 1.32 1.83 L 13 14.46 l 0.14 0.1
                        c -1.03 0.18 -2.03 0.46 -3 0.84 L 10.64 14.52 Z M 16.9 15.22 L 16.9 15.22 h -0.17 c -0.44 0 -0.88 -0.08 -1.29 -0.22 c 0.5 -0.07 1.01
                        0.02 1.46 0.25 l 0 0 L 16.9 15.22 Z"
            />
        </SvgIcon>
    );
};

export default PdfSVG;
