import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssignmentTable } from 'modules/subassignmentManagement/components/SubassignmentTable/AssignmentTable';
import { subassignmentTableStateSelectors } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { SubassignmentTable } from 'modules/subassignmentManagement/components/SubassignmentTable/SubassignmentTable';
import { getMoreSubassignmentsAction } from 'modules/subassignmentManagement/store/actions';
import { selectClientHideSubassignments } from 'store/entities/clients/selectors/configurationSelectors';

export interface ISubassignmentTableGatewayProps {
    containerId: string;
}

export const SubassignmentTableGateway = ({ containerId }: ISubassignmentTableGatewayProps) => {
    const dispatch = useDispatch();

    const hideSubassignments = useSelector(selectClientHideSubassignments);
    const hasMoreRows = useSelector(subassignmentTableStateSelectors.selectHasMore);
    const isLoading = useSelector(subassignmentTableStateSelectors.isLoading);

    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows){
            dispatch(getMoreSubassignmentsAction.init());
        }
    }, [dispatch, hasMoreRows]);

    if (hideSubassignments) {
        return (
            <AssignmentTable
                containerId={containerId}
                hasMore={hasMoreRows}
                isLoading={isLoading}
                onLoadMore={onLoadMoreRows}
            />
        );
    }
    return (
        <SubassignmentTable
            containerId={containerId}
            hasMore={hasMoreRows}
            isLoading={isLoading}
            onLoadMore={onLoadMoreRows}
        />
    );
};
