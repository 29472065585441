import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import FormSelect, { IFormSelectProps } from 'shared/components/selects/FormSelect';
import { ILocationCustomField } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByFieldId } from 'store/entities/customFields/selectors';

interface ILocationFieldStateSelectProps extends Omit<IFormSelectProps<string>, 'getKey' | 'getText' | 'options'> {
    customFieldId: string;
    locationId?: string;
}

export const LocationFieldStateSelect = ({ customFieldId, locationId, ...props }: ILocationFieldStateSelectProps) => {
    const customFieldValues = useSelector(selectCustomFieldValuesByFieldId(customFieldId));
    const states = useMemo(() => {
        return [...new Set(
            customFieldValues
                .filter(fieldValue => !locationId || locationId === fieldValue.id )
                .map(fieldValue => (fieldValue?.data as ILocationCustomField)?.state_code)
                .filter(Boolean),
        )];
    }, [customFieldValues, locationId]);
    return (
        <FormSelect
            getKey={value => value}
            getText={value => value}
            options={states}
            label="States"
            {...props}
        />
    );
};
