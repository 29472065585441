import { EafApprovalGridCellDetails } from 'modules/eafApproval/components/cells/EafApprovalGridCellDetails';
import { EafApprovalGridCellReason } from 'modules/eafApproval/components/cells/EafApprovalGridCellReason';
import { EafApprovalGridCellStatus } from 'modules/eafApproval/components/cells/EafApprovalGridCellStatus';
import { selectEafApprovalActiveTab } from 'modules/eafApproval/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { EafStatusSlug, EafType } from '../../employeeActionsForm/store/models';
import { EafApprovalGridCellActions } from '../components/cells/EafApprovalGridCellActions';
import { EafApprovalGridCellChangeType } from '../components/cells/EafApprovalGridCellChangeType';
import { getEafApprovalGridCellCheckbox } from '../components/cells/EafApprovalGridCellCheckbox';
import { EafApprovalGridCellCreationDate } from '../components/cells/EafApprovalGridCellCreationDate';
import { EafApprovalGridCellEffectiveDate, getEffectiveDateHead } from '../components/cells/EafApprovalGridCellEffectiveDate';
import { EafApprovalGridCellEmployee } from '../components/cells/EafApprovalGridCellEmployee';
import { EafApprovalGridCellManager } from '../components/cells/EafApprovalGridCellManager';
import { EafApprovalGridCellNewValue } from '../components/cells/EafApprovalGridCellNewValue';
import { EafApprovalGridCellOriginalValue } from '../components/cells/EafApprovalGridCellOriginalValue';
import { getEafApprovalGridTitleCellCheckbox } from '../components/cells/EafApprovalGridTitleCellCheckbox';
import { ALL_EAF_STATUSES_SLUG, EafApprovalCellTitleMap } from '../store/model/data';
import { EafApprovalCell, EafApprovalGridCellKey } from '../store/model/types';

const useStyles = makeHighPriorityStyles(() => ({
    statusHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export function useEafApprovalGridCells(): Array<EafApprovalCell> {
    const classes = useStyles();
    const activeTab = useSelector(selectEafApprovalActiveTab);
    const isPendingApproval = activeTab === EafStatusSlug.PendingApproval;
    const isUserHasApprovePermission = useSelector(selectIsUserHasPermission(Permission.ApprovePayRate));
    const showCheckboxes = isPendingApproval && isUserHasApprovePermission;
    const showActions = isPendingApproval && isUserHasApprovePermission;
    const showStatus = activeTab === ALL_EAF_STATUSES_SLUG;
    const showReason = activeTab === EafStatusSlug.ApprovalRejected;

    return useMemo(() => ([
        ...(showCheckboxes ? [{
            key: EafApprovalGridCellKey.Checkbox,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Checkbox],
            render: getEafApprovalGridCellCheckbox([EafType.Termination]),
            renderTitle: getEafApprovalGridTitleCellCheckbox([EafType.Termination]),
            width: '60px',
        }] : []),
        {
            key: EafApprovalGridCellKey.Employee,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Employee],
            render: EafApprovalGridCellEmployee,
        },
        {
            key: EafApprovalGridCellKey.ChangeType,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.ChangeType],
            render: EafApprovalGridCellChangeType,
        },
        {
            key: EafApprovalGridCellKey.OriginalValue,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.OriginalValue],
            render: EafApprovalGridCellOriginalValue,
        },
        {
            key: EafApprovalGridCellKey.NewValue,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.NewValue],
            render: EafApprovalGridCellNewValue,
        },
        {
            key: EafApprovalGridCellKey.Manager,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Manager],
            render: EafApprovalGridCellManager,
        },
        {
            key: EafApprovalGridCellKey.CreationDate,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.CreationDate],
            render: EafApprovalGridCellCreationDate,
            width: '130px',
        },
        {
            key: EafApprovalGridCellKey.EffectiveDate,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.EffectiveDate],
            render: EafApprovalGridCellEffectiveDate,
            renderTitle: getEffectiveDateHead(),
            width: '150px',
        },
        ...(showReason ? [
            {
                key: EafApprovalGridCellKey.Reason,
                title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Reason],
                render: EafApprovalGridCellReason,
            },
        ] : []),
        ...(showActions ? [
            {
                key: EafApprovalGridCellKey.Actions,
                title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Actions],
                render: EafApprovalGridCellActions,
            },
        ] : []),
        ...(showStatus ? [
            {
                key: EafApprovalGridCellKey.Status,
                title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Status],
                render: EafApprovalGridCellStatus,
                headerClassName: classes.statusHeader,
                width: '165px',
            },
        ] : []),
        {
            key: EafApprovalGridCellKey.Details,
            render: EafApprovalGridCellDetails,
            width: '80px',
        },
    ]), [classes, showActions, showCheckboxes, showReason, showStatus]);
}
