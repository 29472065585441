import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const formBasicSpacing = 2;
export const useInlineFormStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        flexWrap: 'wrap',
        margin: theme.spacing(0, -1),
    },
    flexEnd: {
        justifyContent: 'flex-end',
    },
    input: {
        width: 240,
        display: 'flex',
        margin: theme.spacing(1),
    },
    shortInput: {
        width: 140,
        display: 'flex',
        margin: theme.spacing(1),
    },
    extraShortInput: {
        width: 80,
        display: 'flex',
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
        maxHeight: theme.spacing(5),
    },
}));
