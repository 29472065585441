import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from '../formFields/models';
import { IFormSelect } from './model';
import { useSelector } from 'react-redux';
import { IEmployeeType } from 'modules/employmentInfo/models/EmployeeType';
import {
    selectEmployeeTypesSortedByName,
    selectIsEmployeeTypesLoading,
} from 'modules/employmentInfo/store/employeeType/selectors';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';

interface IEmployeeTypeStoreProps {
    employeeTypes?: IEmployeeType[];
    isLoading: boolean;
}

interface IEmployeeTypeProps extends IFormFieldProps, IFormSelect {

}

export const EmployeeTypePure = ({ employeeTypes, ...props }: IEmployeeTypeStoreProps & IEmployeeTypeProps) => {
    return (
        <FormSelect
            getKey={(employeeType: IEmployeeType) => employeeType.name}
            getText={(employeeType: IEmployeeType) => employeeType.name}
            options={employeeTypes || []}
            {...props}
        />
    );
};

export const EmployeeType = separateLogicDecorator<IEmployeeTypeProps, IEmployeeTypeStoreProps>(() => ({
    employeeTypes: useSelector(selectEmployeeTypesSortedByName),
    isLoading: useSelector(selectIsEmployeeTypesLoading),
}))(EmployeeTypePure);
