import { IUserInfo } from 'shared/models/User';
import { ISignupUserInfo, IUserSignupRequest } from 'store/entities/users/model';
import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';

export const USER_REGISTRATION = 'USER_REGISTRATION';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_ERROR = 'USER_REGISTRATION_ERROR';

export const userRegistration = createActions<
IUserSignupRequest,
IUserInfo,
any,
typeof USER_REGISTRATION,
typeof USER_REGISTRATION_SUCCESS,
typeof USER_REGISTRATION_ERROR
>(
    USER_REGISTRATION,
    USER_REGISTRATION_SUCCESS,
    USER_REGISTRATION_ERROR,
);

export const RESET_USER_REGISTRATION = 'RESET_USER_REGISTRATION';

export const resetUserRegistration = createSingleAction<void, typeof RESET_USER_REGISTRATION>(
    RESET_USER_REGISTRATION,
);

export const GET_SIGNUP_USER_INFO = 'GET_SIGNUP_USER_INFO';
export const GET_SIGNUP_USER_INFO_SUCCESS = 'GET_SIGNUP_USER_INFO_SUCCESS';
export const GET_SIGNUP_USER_INFO_ERROR = 'GET_SIGNUP_USER_INFO_ERROR';

export const getSignupUserInfo = createActions<
string,
ISignupUserInfo,
any,
typeof GET_SIGNUP_USER_INFO,
typeof GET_SIGNUP_USER_INFO_SUCCESS,
typeof GET_SIGNUP_USER_INFO_ERROR
>(
    GET_SIGNUP_USER_INFO,
    GET_SIGNUP_USER_INFO_SUCCESS,
    GET_SIGNUP_USER_INFO_ERROR,
);

export type RegistrationActions = ActionsReturnTypes<typeof userRegistration>
| ReturnType<typeof resetUserRegistration>
| ActionsReturnTypes<typeof getSignupUserInfo>;
