import { Nullable } from '../../../../../../../types/types';
import React from 'react';
import { Grid } from '@material-ui/core';
import { EndDateComponent } from '../EndDateComponent';
import { StartDateComponent } from '../StartDateComponent';
import { SubassignmentEditFormClasses } from '../../types';

interface IDatesSectionProps {
    classes: SubassignmentEditFormClasses;
    isLoading?: boolean;
    assignmentStartDate?: Nullable<string>;
    hideEndDate?: boolean;
}

export const DatesSection = ({
    classes,
    isLoading,
    assignmentStartDate,
    hideEndDate = false,
}: IDatesSectionProps) => (
    <Grid container spacing={2}>
        <Grid
            item
            xs={12}
            md={6}
        >
            <StartDateComponent
                classes={classes}
                disabled={isLoading}
                assignmentStartDate={assignmentStartDate}
            />
        </Grid>
        {!hideEndDate && (
            <Grid
                item
                xs={12}
                md={6}
            >
                <EndDateComponent classes={classes} disabled={isLoading}/>
            </Grid>
        )}
    </Grid>
);
