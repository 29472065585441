import React, { useCallback, useMemo } from 'react';
import { orderBy } from 'lodash-es';
import { selectEditSubassignmentId } from '../EditSingleSubassignmentModal/store/selectors';
import { useEditSubAssignmentValidationSchema } from './validationsSchema';
import { editSubAssignmentAction } from 'modules/subassignmentManagement/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IUserInfo } from 'shared/models/User';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectUsersById } from 'store/entities/users/selectors';
import { Nullable } from 'types/types';
import { Form, FormikProvider, useFormik } from 'formik';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { useSubAssignmentBulkCreateEditFormStyles } from '../../CreateSubAssignment/CreateSubAssignmentForm/styles';
import { SubassignmentApprovers } from '../../CreateSubAssignment/CreateSubAssignmentForm/SubassignmentApprovers';
import { DatesSection } from './components/editFormSections/DatesSection';
import { FooterSection } from './components/editFormSections/FooterSection';

export interface IEditSubAssignmentFormShortProps {
    isLoading: boolean;
    approvalLevel: number;
    submitButtonTitle: string;
    additionalButtons?: JSX.Element;
    subassignment: Nullable<ISubassignment>;
    assignmentStartDate: Nullable<string>;
}

export interface IEditSubAssignmentFormShortValues {
    startDate: Nullable<string>;
    endDate: Nullable<string>;
    modifyApprovers: boolean;
    approvers: Record<number, Nullable<IUserInfo>>;
}

const IS_BULK_EDIT = false;
const IS_SHORT_FORM = true;

export const EditSubAssignmentFormShort = ({
    isLoading,
    approvalLevel,
    submitButtonTitle,
    additionalButtons,
    subassignment,
    assignmentStartDate,
}: IEditSubAssignmentFormShortProps) => {
    const clientId = useSelector(selectCurrentClientId);
    const editId = useSelector(selectEditSubassignmentId);
    const dispatch = useDispatch();
    const classes = useSubAssignmentBulkCreateEditFormStyles();
    const usersById = useSelector(selectUsersById);
    const validationSchema = useEditSubAssignmentValidationSchema(
        IS_BULK_EDIT,
        approvalLevel,
        subassignment?.assignment_id || '',
        subassignment?.id || '',
        IS_SHORT_FORM,
    );

    const initialValues = useMemo<IEditSubAssignmentFormShortValues>(() => {
        const managers = subassignment?.managers?.length ? subassignment.managers : [];
        const orderedManagers = orderBy(managers, 'manager_level', 'asc');
        const managerEntries = orderedManagers.map((manager, index) => [index, usersById[manager.user_id]]);
        const approvers = Object.fromEntries(managerEntries);
        return {
            startDate: subassignment?.start_date || null,
            endDate: subassignment?.end_date || null,
            approvers,
            modifyApprovers: true,
        };
    }, [subassignment?.end_date, subassignment?.managers, subassignment?.start_date, usersById]);

    const onSubmit = useCallback((values: IEditSubAssignmentFormShortValues) => {
        // @ts-ignore
        dispatch(editSubAssignmentAction.init({
            id: editId,
            client_id: clientId,
            start_date: values.startDate as string,
            end_date: values.endDate || null,
            managers: (
                Object
                    .values(values.approvers || {})
                    .map((approver, index) => ({ user_id: approver?.id || '', manager_level: index + 1 }))
            ),
        }));
    }, [clientId, dispatch, editId]);

    const formikData = useFormik<IEditSubAssignmentFormShortValues>({
        initialValues,
        onSubmit,
        validationSchema,
        validateOnBlur: true,
        enableReinitialize: true,
    });

    return (
        <FormikProvider value={formikData}>
            <Form onSubmit={formikData.handleSubmit}>
                <DatesSection
                    classes={classes}
                    isLoading={isLoading}
                    assignmentStartDate={assignmentStartDate}
                />

                <SubassignmentApprovers
                    numberOfApprovers={approvalLevel}
                    isLoading={isLoading}
                    isBulkEdit={false}
                    inputClassName={classes.input}
                />

                <FooterSection
                    classes={classes}
                    loading={isLoading}
                    submitButtonTitle={submitButtonTitle}
                    additionalButtons={additionalButtons}
                />
            </Form>
        </FormikProvider>
    );
};
