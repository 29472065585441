/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { Formik } from 'formik';
import { FormikConfig } from 'formik/dist/types';
import { ICommonEntryForm } from 'shared/components/forms/entries/interfaces';
import { ITimeEntryFormValues } from 'shared/components/forms/entries/TimeEntryModel';
import { TimeEntrySidebarForm } from 'shared/components/forms/entries/TimeEntrySidebarForm';
import { TSubmitBlock } from 'shared/components/sidebars/EntryEdit/models';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { IWithInputFields } from '../../forms/utils';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

interface IEditTimeEntryPureProps extends FormikConfig<ITimeEntryFormValues>, ICommonEntryForm, IWithInputFields {
    SubmitBlock: TSubmitBlock;
    onDelete?: () => void;
    payPeriod: IPayPeriod;
}

export const EditTimeEntryPure = memo(({
    initialValues,
    validationSchema,
    onSubmit,
    onDelete,
    supervisorId,
    areaId,
    userId,
    sheetId,
    inputs,
    SubmitBlock,
    payPeriod,
}: IEditTimeEntryPureProps) => {
    const classes = useEntryEditStyles();
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <>
                    <TimeEntrySidebarForm
                        {...props}
                        supervisorId={supervisorId}
                        areaId={areaId}
                        userId={userId}
                        sheetId={sheetId}
                        inputs={inputs}
                        payPeriod={payPeriod}
                    />
                    <footer className={classes.footer}>
                        <SubmitBlock
                            onSubmit={props.submitForm}
                            onDelete={onDelete}
                        />
                    </footer>
                </>
            )}
        </Formik>
    );
});
