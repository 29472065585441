import React, { useEffect } from 'react';
import { SheetApprovalTabs } from 'modules/clients/header/SheetApprovalTabs';
import { setHeaderContent } from 'modules/home/header/Header';
import { Box } from '@material-ui/core';
import { EntryType } from '../../../shared/models/sheet/Sheet';

export function useSheetApprovalTabs(entryType: EntryType) {
    useEffect(() => {
        setHeaderContent(
            <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                width="100%"
            >
                <SheetApprovalTabs entryType={entryType}/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, [entryType]);
}
