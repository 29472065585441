import baseApi from 'shared/utils/baseApi';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { gwClientExtensionsService, submittingOrgEntity } from 'store/entities/configuration/configurationApi';
import { ICreateSubmittingOrgRequest } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/models';

export const api = {
    async createSubmittingOrg(submittingOrg: ICreateSubmittingOrgRequest): Promise<ISubmittingOrg> {
        const { data } = await baseApi.create<ICreateSubmittingOrgRequest, ISubmittingOrg>(
            gwClientExtensionsService,
            submittingOrgEntity,
            submittingOrg,
        );

        return data;
    },
};
