import { INamedEntity } from 'shared/models/Entity';
import { IScaZoneFull } from 'shared/models/sheet/Sheet';
import { ICounty, IScaZone, IStateUS } from 'store/entities/scaZone/models';

export interface IOptionsConfig {
    title: string;
    setValue: (id: string) => void;
    values: Array<INamedEntity>;
}

export interface IScaOptions {
    states: IOptionsConfig;
    counties: IOptionsConfig;
}

export function createScaZoneFull(scaZone?: IScaZone, state?: IStateUS, countyValue?: ICounty): IScaZoneFull | null {
    return scaZone && state && countyValue ? {
        sca_zone_id: scaZone.id,
        county_id: countyValue.id,
        county_name: countyValue.county_name,
        state_id: state.id,
        state_short_name: state.state_shortname,
    } : null;
}
