import { setDisplayAssignmentStartDateChangeDialog } from 'modules/subassignmentManagement/components/AssignmentStartDateChange/store/actions';
import { setOpenCompensationChange } from 'modules/subassignmentManagement/components/CompensationChange/store/actions';
import { setOpenPromotionDemotion } from 'modules/subassignmentManagement/components/PromotionDemotion/store/actions';
import { put, takeEvery } from 'typed-redux-saga';
import { initialLoadSubassignments, resetSubassignmentsTable, setDetailAssignmentId, setSubassignmentFilters } from 'modules/subassignmentManagement/store/actions';
import { setEditSubassignmentId } from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';

function* setAssignmentDetailIdSaga({ payload: assignmentId }: ReturnType<typeof setDetailAssignmentId>) {
    // If assignment detail is active - we should reset subassignment detail
    if (assignmentId) {
        yield put(setEditSubassignmentId(null));
    }
}

function* setAssignmentDetailIdSagaWatcher() {
    yield* takeEvery(
        setDetailAssignmentId.action,
        setAssignmentDetailIdSaga,
    );
}

function* setSubassignmentDetailIdSaga({ payload: subassignmentId }: ReturnType<typeof setEditSubassignmentId>) {
    // If subassignment detail is active - we should reset assignment detail
    if (subassignmentId) {
        yield put(setDetailAssignmentId(null));
    }
}

function* setSubassignmentDetailIdSagaWatcher() {
    yield* takeEvery(
        setEditSubassignmentId.action,
        setSubassignmentDetailIdSaga,
    );
}

function* resetEafSaga() {
    yield put(setOpenCompensationChange(false));
    yield put(setOpenPromotionDemotion(false));
    yield put(setDisplayAssignmentStartDateChangeDialog(false));
}

function* resetEafSagaWatcher() {
    const triggers: Array<string> = [
        setEditSubassignmentId.action,
        setDetailAssignmentId.action,
    ];
    yield* takeEvery(triggers, resetEafSaga);
}

function* resetAssignmentDetailSaga() {
    yield put(setDetailAssignmentId(null));
    yield put(setEditSubassignmentId(null));
    yield* resetEafSaga();
}

function* resetAssignmentDetailSagaWatcher() {
    yield* takeEvery([
        initialLoadSubassignments.action,
        setSubassignmentFilters.action,
        resetSubassignmentsTable.action,
    ], resetAssignmentDetailSaga);
}

export const subassignmentManagementCommonSideEffectSagas = [
    setAssignmentDetailIdSagaWatcher,
    setSubassignmentDetailIdSagaWatcher,
    resetEafSagaWatcher,
    resetAssignmentDetailSagaWatcher,
];
