import { ValidationMessages } from 'shared/models/Validation';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    userId: yup.string().nullable().required(ValidationMessages.REQUIRED),
    uwNumber: yup.string().nullable()
        .matches(/^([0-9]{4})$/g, 'UW Number should be 4-digit number'),
    ssoId: yup.string().nullable().required(ValidationMessages.REQUIRED)
        .matches(/^([0-9]{9})$/g, 'SSO ID should be 9-digit number'),
});
