import { routes } from 'shared/routes';

export const settingsRoutes = {
    ROOT: routes.SETTINGS,
    CLIENTS: {
        ROOT: `${routes.SETTINGS}/clients`,
        DASHBOARD: `${routes.SETTINGS}/clients/:clientId`,
        GENERAL: `${routes.SETTINGS}/clients/:clientId/general`,
        ASSIGNMENT_SETUP: {
            ROOT: `${routes.SETTINGS}/clients/:clientId/assignment-setup`,
            HIERARCHY: `${routes.SETTINGS}/clients/:clientId/assignment-setup/hierarchy`,
            CUSTOM_FIELD_VALUES: {
                ROOT: `${routes.SETTINGS}/clients/:clientId/assignment-setup/custom-field-values`,
                FIELD: `${routes.SETTINGS}/clients/:clientId/assignment-setup/custom-field-values/:customFieldId`,
            },
            SCOPES: {
                ROOT: `${routes.SETTINGS}/clients/:clientId/assignment-setup/scopes`,
                TYPE: `${routes.SETTINGS}/clients/:clientId/assignment-setup/scopes/:type`,
            },
            PAY_RANGES: `${routes.SETTINGS}/clients/:clientId/assignment-setup/pay-ranges`,
            EAF_SETTINGS: `${routes.SETTINGS}/clients/:clientId/assignment-setup/eaf-settings`,
        },
        CONTROLLING_ORG: `${routes.SETTINGS}/clients/:clientId/controlling-org`,
        SUBMITTING_ORG: `${routes.SETTINGS}/clients/:clientId/submitting-org`,
        COST_CENTERS: `${routes.SETTINGS}/clients/:clientId/cost-centers`,
        DEALS: `${routes.SETTINGS}/clients/:clientId/deals`,
        PAYCODE_DEAL_TYPE_AR_BUCKET: `${routes.SETTINGS}/clients/:clientId/ar-bucket`,
        DEAL_TYPE: `${routes.SETTINGS}/clients/:clientId/deal-type`,
        JOB_NUMBER: `${routes.SETTINGS}/clients/:clientId/job-number`,
        TIMESHEET: `${routes.SETTINGS}/clients/:clientId/timesheet`,
        EXPENSE: `${routes.SETTINGS}/clients/:clientId/expense`,
        PAYCODES: `${routes.SETTINGS}/clients/:clientId/paycodes`,
        PAY_SETTINGS: `${routes.SETTINGS}/clients/:clientId/pay-settings`,
        ACTIVITIES: `${routes.SETTINGS}/clients/:clientId/activities`,
        PHYSICAL_DEMANDS: `${routes.SETTINGS}/clients/:clientId/physical-demands`,
        WORKING_CONDITIONS: `${routes.SETTINGS}/clients/:clientId/working-conditions`,
        BACKGROUND_CHECKS: `${routes.SETTINGS}/clients/:clientId/background-checks`,
        APP_CONFIGURATION: {
            ROOT: `${routes.SETTINGS}/clients/:clientId/app-configuration`,
            LINKS: `${routes.SETTINGS}/clients/:clientId/app-configuration/links`,
        },
        ASSIGNMENTS_REPORT_DATA: `${routes.SETTINGS}/clients/:clientId/assignment-report-data`,
        REPORTS: {
            ROOT: `${routes.SETTINGS}/clients/:clientId/reports`,
            ZOHO: `${routes.SETTINGS}/clients/:clientId/reports/zoho-analytics`,
            LATEST_PAY_RATES: `${routes.SETTINGS}/clients/:clientId/reports/latest-pay-rates`,
            ECUB: `${routes.SETTINGS}/clients/:clientId/reports/ecub`,
        },
    },
    PLATFORM_USERS: {
        ROOT: `${routes.SETTINGS}/platform-users`,
        USER_ROLES: `${routes.SETTINGS}/platform-users/user-roles`,
        CLIENT_ROLES: `${routes.SETTINGS}/platform-users/client-roles`,
    },
    EMPLOYEES: {
        ROOT: `${routes.SETTINGS}/employees`,
    },
    OFFER_LETTERS: {
        TEMPLATES: `${routes.SETTINGS}/offer-letters/templates`,
    },
    ASSIGNMENT_SETUP: {
        ROOT: `${routes.SETTINGS}/assignment-setup`,
        DEFAULT_HIERARCHY: `${routes.SETTINGS}/assignment-setup/default-hierarchy`,
        CUSTOM_FIELDS: `${routes.SETTINGS}/assignment-setup/custom-fields`,
    },
};
