import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Grid, Hidden, Theme, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { showField } from 'shared/components/forms/utils';
import { EntryType } from 'shared/models/sheet/Sheet';
import { ActivitySelect } from 'shared/components/selects/ActivitySelect/ActivitySelect';
import SidebarEntryDayPickerField from 'shared/components/formSpecialFields/dayPickerField/SidebarEntryDayPickerField';
import SheetInfo from 'shared/components/sidebars/EntryEdit/SheetInfo';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { isMobileBreakpoint } from 'shared/styles/constants';
import { useFilteredSubassignments, useNestedCustomFieldValuesUpdate } from 'shared/utils/helpers/entries';
import { selectIsJobNumberFieldsApplied } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { HierarchyNodeOperation, ICustomFieldHierarchyNode } from 'store/entities/customFields/model';
import { selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';
import { ICommonEntryFormValues } from './EntryCommonFields';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { JobNumbers, useJobNumbersForUser } from 'shared/components/selects/JobNumbers';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { CustomFieldsValuesSelectGateway } from './CustomFieldsValuesSelectGateway';
import { useScopes } from 'modules/timeAndExpense/store/hooks';

export interface ICommonEntryFieldsProps {
    supervisorId?: string;
    areaId?: number;
    setFieldValue: (fieldName: string, value: any) => void;
    values: ICommonEntryFormValues;
    sheetId?: string;
    entryType: EntryType;
    inputs: InputFields;
    userId?: string;
    payPeriod: IPayPeriod;
}

export function CommonEntrySidebarFields({
    setFieldValue,
    values,
    sheetId,
    entryType,
    inputs,
    userId,
    payPeriod,
}: ICommonEntryFieldsProps) {
    const {
        jobNumber,
        customFieldValues,
    } = values;
    const classes = useEntryEditStyles();
    const sidebarClasses = useSidebarStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMobileBreakpoint));
    const customFieldNodes = useSelector(selectOrderedCustomFieldAssignmentNodes);
    const hasJobNumberField = useSelector(selectIsJobNumberFieldsApplied);

    const jobNumbers = useJobNumbersForUser(userId, payPeriod);
    useEffect(() => {
        if (jobNumber && !jobNumbers.some(jn => jn.id === jobNumber?.id)){
            setFieldValue('jobNumber', null);
        }
    }, [jobNumbers, jobNumber, setFieldValue]);
    const subassignments = useFilteredSubassignments(
        userId,
        payPeriod,
        customFieldValues,
        values.entry_date,
    );
    const scopes = useScopes(customFieldValues);
    useNestedCustomFieldValuesUpdate(customFieldValues, setFieldValue, subassignments);

    const filtercustomFieldNodes: ICustomFieldHierarchyNode[] = customFieldNodes
        .filter(customFieldNode => (customFieldNode.operation !== HierarchyNodeOperation.Hidden));

    return (
        <>
            {showField(inputs, EntrySlug.Day) && (
                <SidebarEntryDayPickerField
                    name="entry_date"
                    label={inputs.day.placeholder}
                    payPeriod={payPeriod}
                    subassignments={subassignments}
                />
            )}

            {sheetId && (
                <Hidden xsDown>
                    <SheetInfo
                        sheetId={sheetId}
                        entryType={entryType}
                    />
                </Hidden>
            )}

            <section className={clsx(sidebarClasses.sidebarElement, classes.inputsWrapper)}>
                <Grid container spacing={isMobile ? 0 : 2}>
                    {showField(inputs, EntrySlug.JobNumber) && (
                        <Grid item xs={12}>
                            <JobNumbers
                                name="jobNumber"
                                label={inputs[EntrySlug.JobNumber].placeholder}
                                className={classes.formInput}
                                userId={userId}
                                payPeriod={payPeriod}
                            />
                        </Grid>
                    )}

                    {!hasJobNumberField && filtercustomFieldNodes.map(customFieldNode => (
                        <Grid
                            item
                            xs={12}
                            key={customFieldNode.custom_field_id}
                        >
                            <CustomFieldsValuesSelectGateway
                                customFieldId={customFieldNode.custom_field_id}
                                name={`customFieldValues[${customFieldNode.custom_field_id}]`}
                                className={classes.formInput}
                                useIdValue
                                useLabel
                                useSubassignments
                                autoSelectSingleVariant
                                disabled={customFieldNode.operation === HierarchyNodeOperation.ReadOnly}
                                hierarchy={customFieldNodes}
                                customFieldFormValues={customFieldValues}
                                userId={userId}
                                date={values.entry_date}
                                payPeriod={payPeriod}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <ActivitySelect
                            name="activity"
                            label={inputs.activity.placeholder}
                            className={classes.formInput}
                            entryType={entryType}
                            jobNumber={jobNumber}
                            scopes={scopes}
                        />
                    </Grid>
                </Grid>
            </section>
        </>
    );
}
