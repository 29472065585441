import React from 'react';
import FormSelect, { IFormSelectProps } from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { IFormFieldProps } from 'shared/components/formFields/models';

interface IEnumSelectProps<T extends Record<string, string>> extends IFormFieldProps, IFormSelect {
    // TODO: Adjust values type to Array<string>
    values: T;
    allowedValues?: string[];
    displayValues?: Record<string, string>,
    InputComponent?: React.ComponentType<IFormSelectProps<string>>
}

export default function EnumFormSelect<T extends Record<string, string>>({
    values,
    allowedValues,
    displayValues = {},
    InputComponent = FormSelect,
    ...props
}: IEnumSelectProps<T>) {
    const options = Object.values(values);

    return (
        <InputComponent
            {...props}
            getKey={(value: string) => value}
            getText={(value: string) => displayValues[value] || value}
            options={allowedValues || options}
        />
    );
}
