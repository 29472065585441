import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export const UpperText = () => {
    const currentClientId = useSelector(selectCurrentClientId);

    if (currentClientId) {
        return (
            <Typography variant="body2">
                <div>
                    Drag and drop the available custom fields into the table below
                    to create a hierarchy for this client.
                    For each custom field selected, choose an operation.
                </div>
                <ul>
                    <li>Read-only: field will display in the details only.</li>
                    <li>Actionable: field will be added to the time and expense control.</li>
                    <li>Hidden: not shown to the user, but will be available as a data point.</li>
                </ul>
                <div>
                    To display the custom field as a column on the employees and manager&apos;s timesheet table,
                    check the &quot;Display on grid&quot; checkbox.
                </div>
            </Typography>
        );
    }

    return (
        <Typography variant="body2">
            <div>
                Drag and drop the available custom fields into the table below to create a global defaulted hierarchy.
                This will automatically create this hierarchy if the same custom fields are used within a client.
            </div>
            <div>For each custom field selected, choose an operation.</div>
            <ul>
                <li>Read-only: field will display in the details only.</li>
                <li>Actionable: field will be added to the time and expense control.</li>
                <li>Hidden: not shown to the user, but will be available as a data point.</li>
            </ul>
        </Typography>
    );
};
