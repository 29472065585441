import { combineReducers } from 'redux';
import { IEntity } from 'shared/models/Entity';
import { ItemsById } from 'shared/models/ItemsById';
import { IZipCode } from 'store/entities/zipCode/model';
import { getZipCodes, ZipCodeAction } from 'store/entities/zipCode/actions';
import { extractIds, isLoadingReducer, itemsByIds } from 'store/reducerUtils';

const defaultState = {
    zipCodesById: {},
    zipCodesSearch: [],
    zipCodesLoading: false,
};

function zipCodesById(
    state: ItemsById<IZipCode> = defaultState.zipCodesById, action: ZipCodeAction,
): ItemsById<IZipCode> {
    switch (action.type) {
        case getZipCodes.successType:
            return {
                ...state,
                ...itemsByIds(action.payload as IEntity[]),
            };
        default:
            return state;
    }
}

const zipCodesLoading = isLoadingReducer(getZipCodes, defaultState.zipCodesLoading);

function zipCodesSearch(state: Array<string> = defaultState.zipCodesSearch, action: ZipCodeAction) {
    switch (action.type) {
        case getZipCodes.successType:
            return extractIds(action.payload as IEntity[]);
        default:
            return state;
    }
}

export const zipCodes = combineReducers({
    zipCodesById,
    zipCodesSearch,
    zipCodesLoading,
});
