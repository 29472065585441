import React from 'react';
import clsx from 'clsx';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { CellClasses, TableClasses } from '../model';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type TotalPayCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export function makeTotalPayCell(tableClasses: TableClasses, cellClasses: CellClasses) {
    const TotalPayCell = ({ className, total_dollars }: TotalPayCellProps) => (
        <PlainText
            className={clsx(className, tableClasses.pullRight, cellClasses.bold)}
            value={formatDollars(total_dollars)}
        />
    );

    return TotalPayCell;
}
