import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { setEditJobNumberId, updateJobNumber } from 'modules/settings/submodules/clients/jobNumber/store/actions';

const jobNumberId = singleValueReducer<string | null>(setEditJobNumberId.action, null);
const isUpdating = isLoadingReducer(updateJobNumber);

export const editJobNumber = combineReducers({
    jobNumberId,
    isUpdating,
});
