import React, { useEffect } from 'react';
import { setHeaderContent } from 'modules/home/header/Header';
import TimeEntryTabs from 'modules/clients/header/TimeEntryTabs';
import { Box } from '@material-ui/core';

export function useTimeAndExpensePageContent() {
    useEffect(() => {
        setHeaderContent(
            <Box display="flex" alignItems="flex-end"
                justifyContent="flex-end"
            >
                <TimeEntryTabs/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, []);
}
