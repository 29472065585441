import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectClientRolesById, selectUsersById } from 'store/entities/users/selectors';

export const selectManagersState = (state: IStore) => state.modules.users.managers;
export const selectManagersIsLoading = (state: IStore) => selectManagersState(state).isLoading;
export const selectManagersUserRoles = (state: IStore) => selectManagersState(state).userRoles;

export const selectManagersRows = createSelector(
    selectManagersUserRoles,
    selectClientRolesById,
    selectUsersById,
    (userRoles, clientRolesById, usersById) => {
        return userRoles.map(role => ({
            ...role,
            user: usersById[role.user_id],
            role: clientRolesById[role.client_role_id],
        }));
    },
);
