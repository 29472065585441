import { IEntity } from 'shared/models/Entity';
import { AnyAction } from 'redux';
import { ActionCreatorKnownArgs, IActionsCreatorCommon } from 'store/utils';
import { ItemsById } from 'shared/models/ItemsById';
import { ISystemState } from 'shared/models/Global';
import { Nullable } from 'types/types';
import { mergeWith, isArray, omit } from 'lodash-es';

export function itemsByIds<Input extends IEntity, Output extends IEntity = Input>(
    array: Array<Input>, getItem?: (item: Input) => Output,
): ItemsById<Output> {
    return array.reduce((acc, item) => ({
        ...acc,
        [item.id]: getItem ? getItem(item) : item,
    }), {});
}

export function itemsByKey<Input>(
    array: Array<Input>,
    getKey: (item: Input) => string,
): Record<string, Input> {
    return array.reduce((acc, item) => ({
        ...acc,
        [getKey(item)]: item,
    }), {});
}

export function extractIds(array: Array<IEntity>) {
    return array.map(item => item.id);
}

export function isLoadingReducer(
    actionsCreator: IActionsCreatorCommon<any, any, any, any, any, any>,
    defaultState = false,
) {
    return (state = defaultState, action: AnyAction): boolean => {
        switch (action.type) {
            case actionsCreator.initType:
                return true;
            case actionsCreator.successType:
            case actionsCreator.errorType:
                return false;
            default:
                return state;
        }
    };
}

const withMessageReducerInitialState: Nullable<ISystemState> = null;
export function withMessageReducer(
    actionsCreator: IActionsCreatorCommon<any, any, any, any, any, any>,
    defaultState: Nullable<ISystemState>,
    skipMessageAction?: string,
) {
    return (state = defaultState, action: AnyAction): Nullable<ISystemState> => {
        switch (action.type) {
            case skipMessageAction:
            case actionsCreator.initType:
                return withMessageReducerInitialState;
            case actionsCreator.successType:
                return {
                    isError: false,
                    message: action.payload,
                };
            case actionsCreator.errorType:
                return {
                    isError: true,
                    message: action.payload,
                };
            default:
                return state;
        }
    };
}

export function singleValueReducer<TValue>(actionName: string, defaultState: TValue) {
    return (state = defaultState, action: AnyAction): TValue => {
        switch (action.type) {
            case actionName:
                return action.payload;
            default:
                return state;
        }
    };
}

export function singleValueWithGetterReducer<TInput, TValue>(
    actionName: string,
    get: (input: TInput) => TValue,
    defaultValue: TValue,
) {
    return function reducer(state = defaultValue, action: AnyAction): TValue {
        switch (action.type) {
            case actionName:
                return get(action.payload);
            default:
                return state;
        }
    };
}

export function resetStateReducer<TValue>(actionName: string, defaultState: TValue) {
    return (state = defaultState, action: AnyAction): TValue => {
        switch (action.type) {
            case actionName:
                return defaultState;
            default:
                return state;
        }
    };
}

export function isOpenModalReducer(actionName: string, defaultState = false) {
    return singleValueReducer<boolean>(actionName, defaultState);
}

export interface IActionsCreatorItemsById<Item> extends IActionsCreatorCommon<any, any, any, any, any, any>{
    success: ActionCreatorKnownArgs<Array<Item>, any>;
}

interface IActionsCreatorDeleteById extends IActionsCreatorCommon<any, any, any, any, any, any>{
}

function replaceArrayMergeCustomizer(objValue: any, srcValue: any) {
    if (isArray(srcValue)) {
        return srcValue;
    }
}

function getItemData<InputItem, Item extends IEntity>(
    actionCreator: IActionsCreatorItemsById<InputItem>,
    action: AnyAction,
    getItem?: (inputItem: InputItem) => Item,
) {
    const payload = action.payload as Parameters<typeof actionCreator.success>[0];
    const normalizedPayload = Array.isArray(payload) ? payload : [payload];
    return getItem ? normalizedPayload
        .filter(Boolean)
        .map(getItem as (inputItem: InputItem | undefined) => Item) : normalizedPayload as unknown as Array<Item>;
}

export function itemsByIdReducer<InputItem, Item extends IEntity>(
    actionCreator: IActionsCreatorItemsById<InputItem>,
    defaultState = {},
    getItem?: (inputItem: InputItem) => Item,
) {
    return (state: ItemsById<Item> = defaultState, action: AnyAction): ItemsById<Item> => {
        switch (action.type) {
            case actionCreator.successType: {
                const data = getItemData(actionCreator, action, getItem);
                // recursive merge items and replace arrays
                return mergeWith(
                    {},
                    state,
                    itemsByIds(data),
                    replaceArrayMergeCustomizer,
                );
            }
            default:
                return state;
        }
    };
}

export function deleteItemById<Item>(
    itemsByIdState: Record<string, Item>,
    removeItemId: string,
): Record<string, Item> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [removeItemId]: removedEntry, ...newState } = itemsByIdState;
    return newState;
}

export function deleteItemByIdReducer<Item extends IEntity>(
    actionCreator: IActionsCreatorDeleteById,
    defaultState = {},
) {
    return (state: Record<string, Item> = defaultState, action: AnyAction): ItemsById<Item> => {
        switch (action.type) {
            case actionCreator.successType:
                return deleteItemById(state, action.payload);
            default:
                return state;
        }
    };
}

export function deleteItemsByIdsReducer<TItem extends IEntity>(
    actionCreator: IActionsCreatorDeleteById,
    getItemsIds: (payload: any) => Array<string>,
    defaultState: ItemsById<TItem> = {},
) {
    return function deleteReducer(state: ItemsById<TItem> = defaultState, action: AnyAction): ItemsById<TItem> {
        if (action?.type === actionCreator.successType) {
            const ids = getItemsIds(action.payload);
            return omit(state, ids);
        }
        return state;
    };
}

export interface IInfinityScrollState<Item> {
    total?: number;
    items: Item[];
    isLoading: boolean;
    cursor?: string | null;
}

export const defaultInfinityScrollState = {
    total: undefined,
    items: [],
    isLoading: false,
};
