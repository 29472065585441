import { IEntity } from 'shared/models/Entity';
import { Status } from 'shared/models/Status';

import { Permission } from 'store/components/auth/authModels';

export enum UserType {
    Employee = 'employee',
    Manager = 'manager',
}

export interface IUserLookupRequest {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
}

export interface IUserSignupRequest {
    email: string;
    first_name: string;
    last_name: string;
    username: string;
    password: string;
}

export interface ISignupUserInfo {
    token: string;
    email: string;
    first_name: string;
    last_name: string;
}

export interface IPasswordResetingRequest {
    email: string;
    force_reset?: boolean;
}

export interface INewPasswordAfterResetingRequest {
    password: string;
    secret_code?: string;
    email: string;
    current_password?: string;
}

export interface INewPasswordCodeCheckingRequest {
    secret_code: string;
    email: string;
}
export interface IUserAuthInfo {
    last_password_change?: string;
    last_login?: string;
    mfa_credentials_exists: boolean;
}

export interface INewPasswordAfterResetingResponse {
    id_token: string;
}

export interface IEmailConfirmation {
    email: string;
    secret_code: string;
}

export interface IGetUsersByClientRoleRequest {
    ids?: string; // Comma-delimited list of UUID's
    client_id?: string;
    location_id?: string;
    position_id?: string;
    first_name?: string;
    last_name?: string;
    username?: string;
    name?: string;
    search?: string;
    email?: string;
    phone?: string;
    id?: string;
    purpose?: Permission;
    permission?: Permission;
    prism_user_type?: 'I';
    page_number?: number;
    page_size?: number;
    sort?: string;
    global_role_id?: string;
    client_role_id: string;
    status?: Status;
    user_type?: UserType;
}

export interface IGetUserRequest {
    ids?: string; // Comma-delimited list of UUID's
    client_id?: string;
    location_id?: string;
    position_id?: string;
    first_name?: string;
    last_name?: string;
    username?: string;
    name?: string;
    search?: string;
    email?: string;
    phone?: string;
    id?: string;
    purpose?: Permission;
    permission?: Permission;
    prism_user_type?: 'I';
    page_number?: number;
    page_size?: number;
    sort?: string;
    global_role_id?: string;
    client_role_id?: string;
    status?: Status;
    user_type?: UserType;
}

export enum PrismUserType {
    I = 'I'
}

export type UserFeature = 'purpose' | 'permission' | 'prism_user_type';

export interface IPurposeRequest {
    purpose: Permission;
    client_id?: string;
}

export interface IPermissionRequest {
    permission: Permission;
}

export interface IPrismUserTypeRequest {
    prism_user_type: PrismUserType;
}

export type GetSpecialUsersRequest = IPurposeRequest | IPermissionRequest | IPrismUserTypeRequest;

export interface ICreateClientUserAssociation {
    client_id: string;
    user_id: string;
    client_role_id: string;
}

export interface IClientUserAssociation extends IEntity {
    client_id: string;
    user_id: string;
    client_role_id: string;
}

export interface IPaidTimeOff {
    prism_pto_max_usage_limit: string,
    client_id: string;
    prism_carry_over_hours: string;
    prism_pto_plan_id: string;
    prism_used_hours_ytd: string;
    user_id: string;
    prism_accrued_hours_ytd: string;
}

export enum GlobalRoles {
    GlobalEmployee = 'global_employee',
    Employee = 'Employee',
    PayrollCoordinator = 'Payroll Coordinator',
    AccountManager = 'Account Manager',
    SuperAdmin = 'Super Admin',
}
