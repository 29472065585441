import React, {
    ChangeEvent, Ref, useCallback, useMemo, useState,
} from 'react';
import { useField, useFormikContext } from 'formik';
import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Tooltip,
} from '@material-ui/core';
import { useFormMultiselectStyles } from 'shared/components/selects/FormMultiselectStyles';
import { IFormFieldProps } from '../formFields/models';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { IFormSelect } from './model';
import { difference, orderBy } from 'lodash-es';

export interface IFormMultiselectProps<T> extends IFormFieldProps, IFormSelect {
    options: T[];
    getKey: (item: T) => string;
    getText: (item: T) => string;
    getOptionTitle?: (item: T) => string;
    onChanged?: (item: T) => void;
    inputRef?: Ref<HTMLDivElement>;
    maxChips?: number;
    useSelectAll?: boolean;
    disableSmartSelectAll?: boolean;
    onSetSelectedAll?: (value: boolean) => void;
    initialAllSelected?: boolean;
}

const defaultValue: any[] = [];
const selectAllOption = {
    key: 'all',
    text: 'All',
};

export default function FormMultiselect({
    name,
    label,
    outerLabel,
    options,
    disabled = false,
    className,
    getKey,
    getText,
    getOptionTitle,
    onChanged,
    onSetSelectedAll,
    initialAllSelected,
    useIdValue = false,
    title = '',
    id = name,
    maxChips = 2,
    useSelectAll = false,
    disableSmartSelectAll = false,
}: IFormMultiselectProps<any>,
) {
    const formHelperTextClasses = useFormHelperTextStyles();
    const classes = useFormMultiselectStyles();
    const formikContext = useFormikContext<any>();
    const [field, meta, helpers] = useField(name);

    const errorMessage = meta.error || (formikContext?.errors || {})[name];
    const hasError = Boolean(errorMessage && meta.touched);

    const optionsIds = useMemo(() => {
        return options.map(option => getKey(option));
    }, [options, getKey]);
    const initialSelectedInnerIds = useMemo(() => {
        return (field.value || defaultValue).map((value: any) => useIdValue ? value : getKey(value));
    }, [field.value, getKey, useIdValue]);
    const initialAllSelectedInner = useMemo(() => {
        if (initialAllSelected !== undefined || disableSmartSelectAll) {
            return Boolean(initialAllSelected);
        }
        return difference(optionsIds, initialSelectedInnerIds).length === 0;
    }, [initialSelectedInnerIds, optionsIds, initialAllSelected, disableSmartSelectAll]);

    const [allSelected, setAllSelectedValue] = useState(initialAllSelectedInner);
    const setAllSelected = useCallback((value: boolean) => {
        setAllSelectedValue(value);
        if (onSetSelectedAll) {
            onSetSelectedAll(value);
        }
    }, [onSetSelectedAll, setAllSelectedValue]);

    const [selectedInnerIds, setSelectedInnerIds] = useState(
        allSelected ? [selectAllOption.key, ...optionsIds] : initialSelectedInnerIds,
    );

    const onChange = useCallback(
        (event: ChangeEvent<{ name?: string; value: unknown }>) => {
            const { value: changedValueIds = defaultValue } = event.target as { value: any[] };
            let newInnerValues = changedValueIds;
            if (useSelectAll) {
                const optionIds = options.map(option => getKey(option));
                const allOptionChecked = newInnerValues.includes(selectAllOption.key);
                if (allOptionChecked && !allSelected) {
                    // Check all option & all items
                    setAllSelected(true);
                    newInnerValues = optionIds;
                    newInnerValues.push(selectAllOption.key);
                } else if (allSelected && !allOptionChecked) {
                    // Uncheck all option & all items
                    setAllSelected(false);
                    newInnerValues = [];
                } else if (allSelected && allOptionChecked && optionIds.length + 1 !== newInnerValues.length) {
                    // Unchecked some item, so we need to uncheck 'all' option
                    setAllSelected(false);
                    newInnerValues = newInnerValues.filter(optionId => optionId !== selectAllOption.key);
                } else if (
                    !disableSmartSelectAll
                    && !allSelected
                    && !allOptionChecked
                    && optionIds.length === newInnerValues.length
                ) {
                    // Checked all options exclude all
                    setAllSelected(true);
                    newInnerValues.push(selectAllOption.key);
                }
            }

            setSelectedInnerIds(newInnerValues);
            const objectValues = options.filter(option => newInnerValues.indexOf(getKey(option)) > -1);
            const newFieldValue = objectValues.map(option => useIdValue ? getKey(option) : option);
            helpers.setValue(newFieldValue);
            helpers.setTouched(true);
            if (onChanged) {
                onChanged(objectValues);
            }
        },
        [
            useSelectAll,
            options,
            helpers,
            onChanged,
            allSelected,
            getKey,
            setAllSelected,
            useIdValue,
            disableSmartSelectAll,
        ],
    );

    const onBlur = () => {
        helpers.setTouched(false);
    };

    const orderedOptions = useMemo(() => {
        return orderBy(options, option => getText(option));
    }, [options, getText]);

    const renderChips = useCallback((selected: string[]) => {
        if (allSelected) {
            return (
                <div className={classes.chips}>
                    <Chip
                        label={selectAllOption.text}
                        className={classes.chip}
                    />
                </div>
            );
        }
        let hiddenCount = 0;
        let displayItems: string[] = selected || [];
        if (displayItems.length > maxChips) {
            hiddenCount = displayItems.length - maxChips;
            displayItems = displayItems.slice(0, maxChips);
        }
        return (
            <div className={classes.chips}>
                {displayItems.map(optionId => (
                    <Chip
                        key={optionId}
                        label={getText(options.find(option => getKey(option) === optionId))}
                        className={classes.chip}
                    />
                ))}
                {hiddenCount > 0 && (
                    <Box display="flex" alignItems="center">
                        and others {hiddenCount}
                    </Box>
                )}
            </div>
        );
    }, [classes, getKey, getText, options, maxChips, allSelected]);

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            {label && (
                <InputLabel>{label}</InputLabel>
            )}
            <Tooltip title={title}>
                <>
                    {outerLabel && (
                        <label
                            htmlFor={id}
                            className={formHelperTextClasses.outerLabel}
                        >
                            {outerLabel}
                        </label>
                    )}
                    <Select
                        {...field}
                        multiple
                        value={selectedInnerIds}
                        disabled={disabled}
                        id={id}
                        onChange={onChange}
                        onBlur={onBlur}
                        renderValue={renderChips as /* to fix TS type mismatch */ (value: unknown) => React.ReactNode}
                        MenuProps={{
                            autoFocus: false,
                        }}
                    >
                        {useSelectAll && (
                            <MenuItem
                                value={selectAllOption.key}
                                key={selectAllOption.key}
                            >
                                <Checkbox checked={selectedInnerIds.indexOf(selectAllOption.key) > -1}/>
                                <ListItemText primary={selectAllOption.text}/>
                            </MenuItem>
                        )}
                        {orderedOptions.map(option => (
                            <MenuItem
                                value={getKey(option)}
                                key={getKey(option)}
                            >
                                <Checkbox checked={selectedInnerIds.indexOf(getKey(option)) > -1}/>
                                <ListItemText
                                    primary={getText(option)}
                                    title={getOptionTitle && getOptionTitle(option)}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </>
            </Tooltip>
            {hasError && (
                <FormHelperText classes={{
                    root: formHelperTextClasses.root,
                }}>
                    {errorMessage}
                </FormHelperText>
            )}
        </FormControl>
    );
}
