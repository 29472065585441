import {
    createPayRangeAction,
    getMorePayRangesAction,
    initialLoadPayRanges,
    PayRangesActions,
    setPayRangesFilter,
} from 'modules/settings/submodules/clients/payRanges/store/actions';
import { IPayRangeResponse } from 'modules/settings/submodules/clients/payRanges/store/models';
import { combineReducers } from 'redux';
import {
    defaultInfinityScrollState,
    IInfinityScrollState,
    isLoadingReducer,
    singleValueReducer,
} from 'store/reducerUtils';

function payRangesTable(
    state: IInfinityScrollState<string>,
    action: PayRangesActions,
): IInfinityScrollState<string> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setPayRangesFilter.action:
        case initialLoadPayRanges.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMorePayRangesAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMorePayRangesAction.successType:{
            const payload = action.payload as IPayRangeResponse;
            const newItems = [
                ...state.items,
                ...payload.pay_ranges.map(entry => entry.id),
            ];
            return {
                total: payload.total_items,
                items: newItems,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}

const payRangesFilter = singleValueReducer(setPayRangesFilter.action, {});

const isCreating = isLoadingReducer(createPayRangeAction);

export const payRanges = combineReducers({
    payRangesTable,
    payRangesFilter,
    isCreating,
});
