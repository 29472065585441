import { CheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { IGroupedSheetCalculation, IGroupedSheetCalculationRequest } from 'modules/payrollProcessorHub/store/model';

export const getSheetCalculationsAction = createRequestActions<
IGroupedSheetCalculationRequest,
IGroupedSheetCalculation[]
>(
    RequestType.Get,
    'SHEET_CALCULATIONS',
);

const SET_TIME_SHEETS_CHECKED = 'time_and_expense/SET_TIME_SHEETS_CHECKED';
export const setTimeSheetsChecked = createSingleAction<CheckedItems, typeof SET_TIME_SHEETS_CHECKED>(
    SET_TIME_SHEETS_CHECKED,
);
const SET_EXPENSE_SHEETS_CHECKED = 'time_and_expense/SET_EXPENSE_SHEETS_CHECKED';
export const setExpenseSheetsChecked = createSingleAction<CheckedItems, typeof SET_EXPENSE_SHEETS_CHECKED>(
    SET_EXPENSE_SHEETS_CHECKED,
);
const SET_TIME_SHEET_GROUPS_CHECKED = 'time_and_expense/SET_TIME_SHEET_GROUPS_CHECKED';
export const setTimeSheetGroupsChecked = createSingleAction<CheckedItems, typeof SET_TIME_SHEET_GROUPS_CHECKED>(
    SET_TIME_SHEET_GROUPS_CHECKED,
);
const SET_EXPENSE_SHEET_GROUPS_CHECKED = 'time_and_expense/SET_EXPENSE_SHEET_GROUPS_CHECKED';
export const setExpenseSheetGroupsChecked = createSingleAction<CheckedItems, typeof SET_EXPENSE_SHEET_GROUPS_CHECKED>(
    SET_EXPENSE_SHEET_GROUPS_CHECKED,
);
