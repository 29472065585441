import { useSubAssignmentBulkCreateEditFormStyles } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/styles';
import { selectIsLoadingDeletePreInitialize } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { selectCheckedSubassignmentIds, selectCheckedSubassignments } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { bulkDeleteSubAssignmentPreInitializeAction } from 'modules/subassignmentManagement/store/actions';
import { useButtonStyles } from 'shared/components/buttons/buttonStyles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';

export const DeleteSubassignmentsButton = () => {
    const classes = useSubAssignmentBulkCreateEditFormStyles();
    const buttonStyles = useButtonStyles();
    const dispatch = useDispatch();
    const subassignmentsIds = useSelector(selectCheckedSubassignmentIds);

    const subassignments = useSelector(selectCheckedSubassignments);
    const allCheckedSubassignmentsIsInactive = useMemo(() => {
        return subassignments.every(subassignment => !subassignment.is_active);
    }, [subassignments]);

    const deleteButtonHandler = useCallback(() => {
        dispatch(bulkDeleteSubAssignmentPreInitializeAction.init({
            subassignmentsIds,
        }));
    }, [dispatch, subassignmentsIds]);

    const isLoadingDeletePre = useSelector(selectIsLoadingDeletePreInitialize);

    return (
        <ButtonWithLoader
            variant="outlined"
            color="secondary"
            className={clsx(classes.button, buttonStyles.redOutlined)}
            isLoading={isLoadingDeletePre}
            disabled={!allCheckedSubassignmentsIsInactive}
            onClick={deleteButtonHandler}
        >
            Delete sub-assignments
        </ButtonWithLoader>
    );
};
