import { createActions, createSingleAction } from 'store/utils';
import {
    ICreateTemplatePayload,
    IUpdateTemplatePayload,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';

export const SET_CREATE_PHYSICAL_DEMANDS_TEMPLATE_MODAL_STATE = 'settings/SET_CREATE_PHYSICAL_DEMANDS_TEMPLATE_MODAL_STATE';
export const setCreatePhysicalDemandsTemplateModalState = createSingleAction<
boolean,
typeof SET_CREATE_PHYSICAL_DEMANDS_TEMPLATE_MODAL_STATE
>(
    SET_CREATE_PHYSICAL_DEMANDS_TEMPLATE_MODAL_STATE,
);

export const CREATE_PHYSICAL_DEMANDS_TEMPLATE = 'settings/CREATE_PHYSICAL_DEMANDS_TEMPLATE';
export const CREATE_PHYSICAL_DEMANDS_TEMPLATE_SUCCESS = 'settings/CREATE_PHYSICAL_DEMANDS_TEMPLATE_SUCCESS';
export const CREATE_PHYSICAL_DEMANDS_TEMPLATE_ERROR = 'settings/CREATE_PHYSICAL_DEMANDS_TEMPLATE_ERROR';

export const createPhysicalDemandsTemplate = createActions<
ICreateTemplatePayload,
void,
any,
typeof CREATE_PHYSICAL_DEMANDS_TEMPLATE,
typeof CREATE_PHYSICAL_DEMANDS_TEMPLATE_SUCCESS,
typeof CREATE_PHYSICAL_DEMANDS_TEMPLATE_ERROR
>(
    CREATE_PHYSICAL_DEMANDS_TEMPLATE,
    CREATE_PHYSICAL_DEMANDS_TEMPLATE_SUCCESS,
    CREATE_PHYSICAL_DEMANDS_TEMPLATE_ERROR,
);

export const SET_EDIT_PHYSICAL_DEMANDS_TEMPLATE_ID = 'settings/SET_EDIT_PHYSICAL_DEMANDS_TEMPLATE_ID';
export const setEditPhysicalDemandsTemplateId = createSingleAction<
string | null,
typeof SET_EDIT_PHYSICAL_DEMANDS_TEMPLATE_ID
>(
    SET_EDIT_PHYSICAL_DEMANDS_TEMPLATE_ID,
);

export const UPDATE_PHYSICAL_DEMANDS_TEMPLATE = 'settings/UPDATE_PHYSICAL_DEMANDS_TEMPLATE';
export const UPDATE_PHYSICAL_DEMANDS_TEMPLATE_SUCCESS = 'settings/UPDATE_PHYSICAL_DEMANDS_TEMPLATE_SUCCESS';
export const UPDATE_PHYSICAL_DEMANDS_TEMPLATE_ERROR = 'settings/UPDATE_PHYSICAL_DEMANDS_TEMPLATE_ERROR';

export const updatePhysicalDemandsTemplate = createActions<
IUpdateTemplatePayload,
void,
any,
typeof UPDATE_PHYSICAL_DEMANDS_TEMPLATE,
typeof UPDATE_PHYSICAL_DEMANDS_TEMPLATE_SUCCESS,
typeof UPDATE_PHYSICAL_DEMANDS_TEMPLATE_ERROR
>(
    UPDATE_PHYSICAL_DEMANDS_TEMPLATE,
    UPDATE_PHYSICAL_DEMANDS_TEMPLATE_SUCCESS,
    UPDATE_PHYSICAL_DEMANDS_TEMPLATE_ERROR,
);
