import { EditButton } from 'modules/sheetApproval/components/EditSheet/EditSheetButton';
import { useSheetGroupDetail, useSheetGroupDetailAdditionalData } from 'modules/sheetApproval/components/SheetGroupDetailSidebar/hooks';
import { useApprovalSelectors } from 'modules/timeAndExpense/store/hooks';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { setSheetGroupedDetailId } from 'modules/sheetApproval/store/actions';
import { selectSheetGroupDetailId } from 'modules/sheetApproval/store/selectors';
import ApproveButton from 'shared/components/sheetApproval/filterAndActionControls/buttons/ApproveButton';
import RejectButton from 'shared/components/sheetApproval/filterAndActionControls/buttons/RejectButton';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { EntryType } from 'shared/models/sheet/Sheet';
import { AvailableDetailConfiguration } from 'store/entities/clients/clientsModel';
import { selectDetailConfiguration } from 'store/entities/clients/selectors/fieldSelectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { SheetGroupDetailSidebar } from './SheetGroupDetailSidebar';

interface ISheetGroupDetailSidebarProps {
    sheetType: EntryType;
    availableActions?: React.ReactNode;
}

export const ManagerSheetGroupDetailSidebar = ({ sheetType }: ISheetGroupDetailSidebarProps) => {
    const dispatch = useDispatch();
    const classes = useSheetDetailStyles();

    const selectors = useApprovalSelectors(sheetType);
    const groupId = useSelector(selectSheetGroupDetailId);
    const group = useSelector(selectors.selectSheetGroupById(groupId));

    const onClose = useCallback(() => dispatch(setSheetGroupedDetailId(null)), [dispatch]);
    const configuration = useSelector(selectDetailConfiguration(
        sheetType === EntryType.TIME
            ? AvailableDetailConfiguration.ManagerTimeSheet : AvailableDetailConfiguration.ManagerExpenseSheet,
    )).info;
    useSheetGroupDetailAdditionalData(group);
    // @ts-ignore
    const detailInfo = useSheetGroupDetail(group, sheetType, configuration, true);

    const availableActions = useMemo(() => (
        <>
            {group && (
                <Box className={classes.buttonWrapper}>
                    {group.status.name === StatusNames.SUBMITTED && (
                        <>
                            <RejectButton
                                sheets={group.sheets}
                                users={[group.employee]}
                                sheetType={sheetType}
                                onAction={onClose}
                            />
                            <ApproveButton
                                sheets={group.sheets}
                                sheetType={sheetType}
                                onAction={onClose}
                            />
                        </>
                    )}
                    {[StatusNames.SUBMITTED, StatusNames.WORKING].includes(group.status.name) && (
                        <>
                            <EditButton
                                groupId={group.id}
                                sheets={group.sheets}
                                sheetType={sheetType}
                            />
                        </>
                    )}
                </Box>
            )}
        </>
    ), [group, sheetType, onClose, classes]);

    return (
        <SheetGroupDetailSidebar
            group={group}
            detailInfo={detailInfo}
            sheetType={sheetType}
            availableActions={availableActions}
            onClose={onClose}
        />
    );
};
