import {
    IBulkCreateCcpTransactionsResponse,
    ICcpInvoicesRequest,
    ICcpInvoicesResponse,
    ICcpSplitItem,
    ICcpSubmitReconciledRequest,
    ICcpSubmitRequest,
    ICcpTransactionRequest,
    ICcpTransactionsResponse,
    ITransactionChange,
    ITransactionCode,
    ITransactionCodesResponse,
    ICcpReminderRequest,
} from 'modules/ccp/models/ccpModel';
import baseApi from 'shared/utils/baseApi';
import { IAttachment } from '../../../../shared/models/Attachments';
import { ICcpAttachment, ICcpTransaction } from '../../models/CcpTransaction';

export const ccpService = 'ccp';
const ccpPath = `/${ccpService}`;

const prepareCsvUploadFormData = (file: File) => {
    const formData = new FormData();
    formData.append('csv', file);
    return formData;
};

export const ccpApi = {
    async getCcpTransactions(params: ICcpTransactionRequest): Promise<ICcpTransactionsResponse> {
        const { data } = await baseApi.get<ICcpTransactionsResponse>(
            `${ccpPath}/transactions`,
            {
                client_id: baseApi.clientId,
                skip: params.skip,
                user_id: params.user_id,
                submitted_date: params.submitted_date,
                department_id: params.department_id,
                transaction_status_id: params.transaction_status_id,
                regional_manager_id: params.regional_manager_id,
            },
        );
        return data;
    },
    async getCcpInvoices(params: ICcpInvoicesRequest): Promise<ICcpInvoicesResponse> {
        const { data } = await baseApi.get<ICcpInvoicesResponse>(
            `${ccpPath}/invoices`,
            {
                client_id: params.client_id,
                skip: params.skip,
                submitted_date: params.submitted_date,
            },
        );
        return data;
    },
    async getTransactionCodes(): Promise<ITransactionCode[]> {
        const { data } = await baseApi.get<ITransactionCodesResponse>(
            `${ccpPath}/transaction_codes`,
        );
        const codesList = data?.codes;
        return codesList;
    },
    async uploadCsvBulkValidateTransactions(file: File): Promise<IBulkCreateCcpTransactionsResponse> {
        const { data } = await baseApi.post<FormData, IBulkCreateCcpTransactionsResponse>(
            `${ccpPath}/transactions/bulk_create/csv_import/validate?client_id=${baseApi.clientId}`,
            prepareCsvUploadFormData(file),
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            },
        );
        return data;
    },
    async uploadCsvImportTransactions(file: File): Promise<IBulkCreateCcpTransactionsResponse> {
        const { data } = await baseApi.post<FormData, IBulkCreateCcpTransactionsResponse>(
            `${ccpPath}/transactions/bulk_create/csv_import?client_id=${baseApi.clientId}`,
            prepareCsvUploadFormData(file),
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            },
        );
        return data;
    },
    async patchTransaction(changeRequest: ITransactionChange): Promise<ITransactionCode> {
        const body: ITransactionChangeBody = {
            client_id: changeRequest.client_id,
            department_id: changeRequest.department_id,
            note: changeRequest.note,
            transaction_code_id: changeRequest.transaction_code,
        };
        const { data } = await baseApi.patch<ITransactionChangeBody, ITransactionCode>(
            `${ccpPath}/transactions/${changeRequest.id}`,
            body,
        );
        return data;
    },
    async putSplitItems(items: ICcpSplitItem[]): Promise<ICcpTransaction[]> {
        const transactionId = items[0]?.parent_transaction_id;
        const body = {
            items: items.map(x => {
                return {
                    transaction_code_id: x.transaction_code_id,
                    department_id: x.department_id,
                    amount: x.amount,
                    note: x.note,
                };
            }),
        };
        const { data } = await baseApi.put(
            `${ccpPath}/transactions/${transactionId}/split?client_id=${baseApi.clientId}`,
            body,
        );
        return data?.items;
    },
    async createCcpEntryAttachment(relatedEntryId: string, attachment: IAttachment): Promise<ICcpAttachment> {
        const response = await baseApi.post<IAttachment, { transaction_attachment: ICcpAttachment }>(
            `${ccpPath}/transactions/${relatedEntryId}/receipt?client_id=${baseApi.clientId}`,
            attachment,
        );
        return response.data.transaction_attachment;
    },
    async deleteCcpEntryAttachment(itemToDelete: ICcpAttachment): Promise<number> {
        const response = await baseApi.deleteRaw(
            `${ccpPath}/transactions/${itemToDelete.transaction_id}/receipt/${itemToDelete.id}?client_id=${baseApi.clientId}`,
        );
        return response.status;
    },
    async submitUnreconciledTransactions(): Promise<ICcpTransaction[]> {
        const request = {
            client_id: baseApi.clientId,
        } as ICcpSubmitRequest;
        const response = await baseApi.post<ICcpSubmitRequest, { updates: ICcpTransaction[] }>(
            `${ccpPath}/transactions/unreconciled/submit`,
            request,
        );
        return response.data.updates;
    },
    async downloadReconciledCcpReportCsv(): Promise<BlobPart> {
        const { data } = await baseApi.get<BlobPart>(
            `${ccpPath}/transactions/reconciled/csv?client_id=${baseApi.clientId}`,
        );
        return data;
    },
    async downloadUnreconciledCcpReportCsv(): Promise<BlobPart> {
        const { data } = await baseApi.get<BlobPart>(
            `${ccpPath}/transactions/unreconciled/csv?client_id=${baseApi.clientId}`,
        );
        return data;
    },
    async downloadInvoicedCcpReportCsv(id: string): Promise<BlobPart> {
        const { data } = await baseApi.get<BlobPart>(
            `${ccpPath}/invoices/${id}/csv?client_id=${baseApi.clientId}`,
        );
        return data;
    },
    async submitReconciledTransactions(request: ICcpSubmitReconciledRequest): Promise<BlobPart> {
        const { data } = await baseApi.post<ICcpSubmitReconciledRequest, BlobPart>(
            `${ccpPath}/transactions/reconciled/submit`,
            request,
        );
        return data;
    },
    async sendReminder(request: ICcpReminderRequest): Promise<number> {
        const response = await baseApi.post<ICcpReminderRequest, { status: string }>(
            `${ccpPath}/transactions/reminder`,
            request,
        );
        return response.status;
    },
    async putMultipleTransactions(changeRequest: IMultipleTransactionsChangeTransaction[]): Promise<number> {
        const body: IMultipleTransactionsChangeBody = {
            client_id: baseApi.clientId,
            transactions: changeRequest,
        };
        const { status } = await baseApi.put<IMultipleTransactionsChangeBody, { status: string }>(
            `${ccpPath}/transactions`,
            body,
        );
        return status;
    },
};

export interface ITransactionChangeBody {
    client_id: string,
    department_id: string|null,
    note: string|undefined,
    transaction_code_id: string|null,
}

export interface IMultipleTransactionsChangeSplit {
    transaction_code_id: string,
    department_id: string,
    amount: string,
    note: string|null,
}

export interface IMultipleTransactionsChangeTransaction {
    transaction_id: string,
    department_id?: string|null,
    transaction_code_id?: string|null,
    note?: string|null,
    attachments?: IAttachment[],
    split_items?: IMultipleTransactionsChangeSplit[],
}

interface IMultipleTransactionsChangeBody {
    client_id: string|null,
    transactions: IMultipleTransactionsChangeTransaction[],
}
