import { intersection, pull } from 'lodash-es';
import {
    AccessLevel,
    IClientRoleFormValues,
    IClientRolePermissions,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleForm/model';
import { IClientRoleManagePayload } from 'modules/settings/submodules/platformUsers/store/models';
import { IClientRole } from 'shared/models/User';
import { logger } from 'shared/utils/logging/logger';
import { Permission } from 'store/components/auth/authModels';

enum PermissionMapType {
    Select,
    Checkbox,
}

const permissionFormMapping = {
    approval: {
        type: PermissionMapType.Select,
        permissions: {
            [AccessLevel.NoAccess]: [],
            [AccessLevel.ReadOnly]: [Permission.ViewSheetApprovalsOwn, Permission.ViewSheetApprovalsAll],
            [AccessLevel.ViewEditOwn]: [Permission.ViewSheetApprovalsOwn, Permission.ApproveSheets],
            [AccessLevel.ViewEditAll]: [
                Permission.ViewSheetApprovalsOwn,
                Permission.ApproveSheets,
                Permission.ViewSheetApprovalsAll,
                Permission.ApproveAllSheets,
            ],
        },
    },
    offerLetters: {
        type: PermissionMapType.Select,
        permissions: {
            [AccessLevel.NoAccess]: [],
            [AccessLevel.ReadOnly]: [Permission.ViewClientOffersOwn, Permission.ViewClientOffersAll],
            [AccessLevel.ViewEditOwn]: [
                Permission.CreateOffer,
                Permission.ViewClientOffersOwn,
                Permission.ManageClientOffersOwn,
            ],
            [AccessLevel.ViewEditAll]: [
                Permission.CreateOffer,
                Permission.ViewClientOffersOwn,
                Permission.ManageClientOffersOwn,
                Permission.ViewClientOffersAll,
                Permission.ManageClientOffersAll,
            ],
        },
    },
    editSheetAsApprover: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.EditSheetAsApprover],
    },
    approvePayRate: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.ApprovePayRate],
    },
    payRateAutoApprove: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.PayRateAutoApprove],
    },
    assignmentManagement: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.ManageSubassignments, Permission.ManageEaf],
    },
    assignmentManagementScopeRequired: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.SubAssignmentManagementScopeRequired],
    },
    employeeImpersonation: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.ImpersonateAsEmployee, Permission.Impersonate],
    },
    managerImpersonation: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.ImpersonateAsManager, Permission.Impersonate],
    },
    viewReports: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.ViewReports],
    },
    manageTravelExpenses: {
        type: PermissionMapType.Checkbox,
        permissions: [Permission.ManageTravelExpenses],
    },
};

const getFormPermissions = (rolePermissions: Permission[] = []): IClientRolePermissions => {
    const result: IClientRolePermissions = {
        approval: AccessLevel.NoAccess,
        offerLetters: AccessLevel.NoAccess,
        editSheetAsApprover: false,
        approvePayRate: false,
        payRateAutoApprove: false,
        assignmentManagement: false,
        assignmentManagementScopeRequired: false,
        employeeImpersonation: false,
        managerImpersonation: false,
        viewReports: false,
        manageTravelExpenses: false,
        notManaged: [],
    };
    let notManaged = [...rolePermissions];
    Object.entries(permissionFormMapping).forEach(([formValueKey, mapping]) => {
        switch (mapping.type) {
            case PermissionMapType.Select: {
                const permissionsAccessLevelMapping = mapping.permissions as Record<AccessLevel, Permission[]>;
                notManaged = pull(notManaged, ...Object.values(permissionsAccessLevelMapping).flat());
                for (const accessLevel of [AccessLevel.ReadOnly, AccessLevel.ViewEditOwn, AccessLevel.ViewEditAll]){
                    const permissions = permissionsAccessLevelMapping[accessLevel];
                    const hasAccess = intersection(rolePermissions, permissions).length === permissions.length;
                    if (hasAccess) {
                        result[formValueKey] = accessLevel;
                    }
                }
                break;
            }
            case PermissionMapType.Checkbox: {
                const { permissions } = mapping as { permissions: Permission[] };
                result[formValueKey] = intersection(rolePermissions, permissions).length === permissions.length;
                notManaged = pull(notManaged, ...permissions);
                break;
            }
            default:
                logger.warning('Permission form mapping not implemented');
                break;
        }
    });
    result.notManaged = notManaged;
    return result;
};

const getPermissionsFromFormValues = (formPermission: IClientRolePermissions): Permission[] => {
    let result: Permission[] = [...formPermission.notManaged];
    Object.entries(permissionFormMapping).forEach(([formValueKey, mapping]) => {
        switch (mapping.type) {
            case PermissionMapType.Select: {
                const permissionsAccessLevelMapping = mapping.permissions as Record<AccessLevel, Permission[]>;
                const accessLevel = formPermission[formValueKey] as AccessLevel;
                const permissions = permissionsAccessLevelMapping[accessLevel];
                result = [...result, ...permissions];
                break;
            }
            case PermissionMapType.Checkbox: {
                const { permissions } = mapping as { permissions: Permission[] };
                if (formPermission[formValueKey]) {
                    result = [...result, ...permissions];
                }
                break;
            }
            default:
                logger.warning('Permission form mapping not implemented');
                break;
        }
    });
    return result;
};

export const getInitialValues = (clientRole?: IClientRole): IClientRoleFormValues => {
    return {
        name: clientRole?.name || '',
        description: clientRole?.description || '',
        clientIds: clientRole?.client_ids || [],
        allClients: clientRole ? clientRole.all_clients : true,
        permission: getFormPermissions(clientRole?.permissions),
    };
};

export const getClientRolePayloadFromFormValues = (values: IClientRoleFormValues): IClientRoleManagePayload => {
    return {
        name: values.name,
        description: values.description,
        all_clients: values.allClients,
        client_ids: values.clientIds,
        permissions: getPermissionsFromFormValues(values.permission),
    };
};
