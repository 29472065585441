import { BulkCreateSubassignmentSteps, setBulkCreateSubassignmentStep, setOpenBulkCreateSubassignmentModal, setOpenBulkCreateSubassignmentUploadModal } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/actions';
import {
    bulkCreateSubAssignmentAction,
    bulkCreateSubAssignmentActionV2,
    bulkCreateSubAssignmentPreInitializeAction,
    bulkCreateSubAssignmentPreInitializeActionV2,
    bulkUploadCreateSubAssignmentAction,
    bulkUploadCreateSubAssignmentPreInitializeAction,
    downloadFailedCsvCreateSubAssignmentAction, getCreateSubAssignmentCsvSampleAction,
    getPreInitializeCreateSubAssignmentPdfAction,
    getUploadPreInitializeCreateSubAssignmentPdfAction,
    uploadCreateSubAssignmentAndDownloadFailedCsvAction,
} from 'modules/subassignmentManagement/store/actions';
import { AnyAction, combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const isOpenBulkCreationModal = singleValueReducer(setOpenBulkCreateSubassignmentModal.action, false);
const isOpenBulkCreationUploadModal = singleValueReducer(setOpenBulkCreateSubassignmentUploadModal.action, false);

const isLoadingPreInitializeReport = extendReducer(
    isLoadingReducer(bulkCreateSubAssignmentPreInitializeAction),
    isLoadingReducer(bulkCreateSubAssignmentPreInitializeActionV2),
    isLoadingReducer(bulkUploadCreateSubAssignmentPreInitializeAction),
);
const isBulkCreating = extendReducer(
    isLoadingReducer(bulkCreateSubAssignmentAction),
    isLoadingReducer(bulkCreateSubAssignmentActionV2),
    isLoadingReducer(bulkUploadCreateSubAssignmentAction),
);
const isLoadingPdf = extendReducer(
    isLoadingReducer(getPreInitializeCreateSubAssignmentPdfAction),
    isLoadingReducer(getUploadPreInitializeCreateSubAssignmentPdfAction),
);
const isLoadingFailedCsv = extendReducer(
    isLoadingReducer(downloadFailedCsvCreateSubAssignmentAction),
    isLoadingReducer(uploadCreateSubAssignmentAndDownloadFailedCsvAction),
);
const isLoadingSample = isLoadingReducer(getCreateSubAssignmentCsvSampleAction);

const step = (
    state = BulkCreateSubassignmentSteps.Form,
    action: AnyAction,
): BulkCreateSubassignmentSteps => {
    switch (action.type) {
        case setOpenBulkCreateSubassignmentModal.action:
        case setOpenBulkCreateSubassignmentUploadModal.action:
            return BulkCreateSubassignmentSteps.Form;
        case bulkCreateSubAssignmentPreInitializeAction.successType:
        case bulkCreateSubAssignmentPreInitializeActionV2.successType:
        case bulkUploadCreateSubAssignmentPreInitializeAction.successType:
            return BulkCreateSubassignmentSteps.Verify;
        case bulkCreateSubAssignmentAction.successType:
        case bulkCreateSubAssignmentActionV2.successType:
        case bulkUploadCreateSubAssignmentAction.successType:
            return BulkCreateSubassignmentSteps.PostReport;
        case setBulkCreateSubassignmentStep.action:
            return action.payload;
        default:
            return state;
    }
};

const creationPayload = singleValueReducer(bulkCreateSubAssignmentPreInitializeAction.initType, null);
const preInitializeReport = singleValueReducer(bulkCreateSubAssignmentPreInitializeAction.successType, null);
const creationReport = singleValueReducer(bulkCreateSubAssignmentAction.successType, null);

const reportV2 = extendReducer(
    singleValueReducer(bulkCreateSubAssignmentPreInitializeActionV2.successType, null),
    singleValueReducer(bulkCreateSubAssignmentActionV2.successType, null),
    singleValueReducer(bulkUploadCreateSubAssignmentPreInitializeAction.successType, null),
    singleValueReducer(bulkUploadCreateSubAssignmentAction.successType, null),
);

export const createSubassignments = combineReducers({
    isOpenBulkCreationModal,
    isOpenBulkCreationUploadModal,
    step,
    isLoadingPreInitializeReport,
    isBulkCreating,
    creationPayload,
    preInitializeReport,
    creationReport,
    reportV2,
    isLoadingPdf,
    isLoadingFailedCsv,
    isLoadingSample,
});
