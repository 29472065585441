import { groupBy } from 'lodash-es';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ItemsById } from 'shared/models/ItemsById';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { HierarchyNodeOperation, ICustomFieldHierarchyNode, ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';

export type CustomFieldsInitialState = {
    customFieldValue: Record<string, string>,
    customFieldValues: Record<string, string | string[]>,
    customFieldValuesAllFields: Record<string, boolean>,
}

/**
 * Prepares initial form values by hierarchy
 */
export function prepareDefaultInitialFormValues(hierarchy: ICustomFieldHierarchyNode[]) {
    const defaultInitialFormValues: Partial<CustomFieldsInitialState> = {
        customFieldValue: hierarchy.reduce((mem, node) => {
            if (node.operation !== HierarchyNodeOperation.Actionable) {
                return { ...mem, [node.custom_field_id]: null };
            }
            return mem;
        }, {}),
        customFieldValues: hierarchy.reduce((mem, node) => {
            if (node.operation === HierarchyNodeOperation.Actionable) {
                return { ...mem, [node.custom_field_id]: [] };
            }
            return mem;
        }, {}),
        customFieldValuesAllFields: hierarchy.reduce((mem, node) => ({ ...mem, [node.custom_field_id]: false }), {}),
    };
    return defaultInitialFormValues;
}

/**
 * Prepares form values from subassignment to be prefilled on edit form
 */
function prepareSubassignmentStateFormValues(
    subassignment: ISubassignment,
    customFieldValuesByIds: ItemsById<ICustomFieldValue>,
    defaultCustomFieldValues: Partial<CustomFieldsInitialState>,
) {
    const subassignmentCustomFieldValues = (
        (subassignment.custom_field_value_ids || [])
            .map(id => customFieldValuesByIds[id])
            .filter(Boolean)
            .filter(customFieldValue => !customFieldValue.deleted_at)
    );

    const groupedCustomFieldValues: Record<string, ICustomFieldValue[]> = groupBy(
        subassignmentCustomFieldValues,
        'custom_field_id',
    );
    // @ts-ignore
    const customFieldValue: Record<string, string> = Object.keys(defaultCustomFieldValues.customFieldValue)
        .reduce((mem, key) => {
            return {
                ...mem,
                // @ts-ignore
                [key]: groupedCustomFieldValues[key]?.[0]?.id || defaultCustomFieldValues.customFieldValue[key],
            };
        }, {} as Record<string, string>);
    // @ts-ignore
    const customFieldValues: Record<string, string[]> = Object.keys(defaultCustomFieldValues.customFieldValues)
        .reduce((mem, key) => {
            return {
                ...mem,
                [key]: groupedCustomFieldValues[key]?.map(item => item.id)
                    // @ts-ignore
                    || defaultCustomFieldValues.customFieldValues[key],
            };
        }, {} as Record<string, string[]>);

    return {
        customFieldValue,
        customFieldValues,
        customFieldValuesAllFields: subassignment.all_values_custom_field_ids?.reduce((mem, id) => ({
            ...mem,
            [id]: true,
        }), {}) || {},
    };
}

export function useEditCustomFieldsInitialFormValues(
    hierarchy: ICustomFieldHierarchyNode[],
    subassignment?: ISubassignment,
): Partial<CustomFieldsInitialState> {
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);

    return useMemo(() => {
        const defaultInitialFormValues = prepareDefaultInitialFormValues(hierarchy);
        if (!subassignment) {
            // will be used for bulk edit
            return defaultInitialFormValues;
        }

        return prepareSubassignmentStateFormValues(
            subassignment,
            customFieldValuesByIds,
            defaultInitialFormValues,
        );
    }, [customFieldValuesByIds, hierarchy, subassignment]);
}
