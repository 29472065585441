import clsx from 'clsx';
import React, { ChangeEventHandler } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { useSheetRejectStyles } from 'shared/components/sidebars/SheetReject/styles';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { inputParams } from 'shared/styles/constants';
import useOutlinedTextFieldStyled from 'shared/components/textField/CustomOutlinedTextFieldStyles';

interface IRejectCardProps {
    inputValue: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    employeeFullName: string;
    employeePosition?: string;
    employeeLocation?: string;
    totalHours?: string;
    totalDollars?: string | null;
    payPeriod?: string;
    payRate?: string;
}

export const RejectCard = ({
    inputValue,
    onChange,
    employeeFullName,
    employeePosition,
    employeeLocation,
    payPeriod,
    totalHours,
    totalDollars,
    payRate,
}: IRejectCardProps) => {
    const classes = useSheetRejectStyles();
    const sidebarClasses = useSidebarStyles();
    const inputClasses = useOutlinedTextFieldStyled();
    const sectionRootClassName = clsx(sidebarClasses.sidebarElement, classes.rejectionCard);
    return (
        <section className={sectionRootClassName}>
            <Typography variant="h6">
                {employeeFullName}
            </Typography>
            <section className={classes.sheetDisplay}>
                <Typography variant="body1" className={classes.weekendPullLeft}>
                    {employeePosition}
                    {Boolean(employeePosition && employeeLocation) && (
                        <>&nbsp;/&nbsp;</>
                    )}
                    {employeeLocation}
                    <br/>
                    {payPeriod}
                </Typography>
                <Typography variant="subtitle1" className={classes.hoursPullRight}>
                    {totalHours && (
                        <>
                            {totalHours} hrs.
                            <br/>
                        </>
                    )}
                    {totalDollars && `$${totalDollars}`}
                    {payRate}
                </Typography>
            </section>
            <section className={classes.notes}>
                <TextField
                    inputProps={{
                        className: inputClasses.input,
                        ...inputParams,
                    }}
                    InputLabelProps={{ className: inputClasses.label }}
                    className={classes.notesInput}
                    label="Reason for rejection"
                    autoComplete="off"
                    variant="outlined"
                    value={inputValue}
                    onChange={onChange}
                />
            </section>
        </section>
    );
};
