import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { createStyles, Theme } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

const fontSize = 14;

const useOutlinedTextFieldStyled = makeHighPriorityStyles((theme: Theme) => {
    const labelTransform = `translate(${theme.spacing(1)}px, ${theme.spacing(1.5)}px)`;
    return createStyles({
        input: {
            height: theme.spacing(5),
            padding: theme.spacing(0, 1),
            fontSize,
        },
        labelNoColorsChange: {
            fontSize,
            transform: labelTransform,
        },
        label: {
            transform: labelTransform,
            color: colors.darkGray,
            fontSize,
            '&.Mui-error': {
                color: 'inherit',
            },
        },
        labelFilled: {
            '&.Mui-error': {
                color: theme.palette.error.main,
            },
        },
    });
});

export default useOutlinedTextFieldStyled;
