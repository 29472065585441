import { IStore } from 'store/configureStore';
import {
    codeCheckingResult,
    newPassword,
    newPasswordCode,
    newPasswordResult,
    resetPassword,
    resetPasswordResult,
    ResettingPasswordState,
} from './reducers';

const selectResettingPasswordModule = (state: IStore): ResettingPasswordState =>
    state.components.resettingPassword;
export const selectResettingPasswordIsLoading = (state: IStore): ReturnType<typeof resetPassword> =>
    selectResettingPasswordModule(state).resetPassword;
export const selectResettingPasswordNewPasswordIsLoading = (state: IStore): ReturnType<typeof newPassword> =>
    selectResettingPasswordModule(state).newPassword;
export const selectResettingPasswordCodeValidatingIsLoading = (state: IStore): ReturnType<typeof newPasswordCode> =>
    selectResettingPasswordModule(state).newPasswordCode;
export const selectResettingPasswordResult = (state: IStore): ReturnType<typeof resetPasswordResult> =>
    selectResettingPasswordModule(state).resetPasswordResult;
export const selectResettingPasswordNewPassword = (state: IStore): ReturnType<typeof newPasswordResult> =>
    selectResettingPasswordModule(state).newPasswordResult;
export const selectResettingPasswordCodeValidating = (state: IStore): ReturnType<typeof codeCheckingResult> =>
    selectResettingPasswordModule(state).codeCheckingResult;
