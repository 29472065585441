import React from 'react';
import PdfFileError from 'shared/components/PdfViewer/PdfFileError';
import { useSelector } from 'react-redux';
import { routes } from 'shared/routes';
import { selectIsManager } from 'store/components/auth/selectors';

export const DefaultPdfFallback = () => {
    const isManager = useSelector(selectIsManager);

    return (
        <PdfFileError
            title="Document generation is in progress."
            message="Please try again later."
            // We don't have clintId so we could not transfer the manager to Offer Letters
            linkLabel={isManager ? 'Go to Home Page' : 'Go to Offer Letters List'}
            link={isManager ? routes.HOME : routes.EMPLOYEE_OFFER_LETTER.ROOT}
        />
    );
};
