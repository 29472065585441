import axios from 'axios';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { AuthenticationError, authRequestTimeout, IAuthData, LocalStorageKey } from 'shared/models/Authentication';
import { shouldIgnoreRequestError } from 'shared/utils/axios/shouldIgnoreRequestError';
import { IExpiringToken, IRefreshToken, IUserTokenInfo, SearchParams } from 'store/components/auth/authModels';
import { logErrorWithCustomMessage, logger } from 'shared/utils/logging/logger';
import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { routes } from 'shared/routes';
import { ToastMessagesCodes } from 'shared/models/ToastMessagesModel';

const clientId = process.env.REACT_APP_AUTH_CLIENT_ID as string;
const scope = process.env.REACT_APP_SCOPE as string;
const EXPIRE_IN_MINUTES = 2;
const authBasePath = process.env.REACT_APP_KEYCLOACK_URL as string;
const registrationsUrl = '/realms/headway/protocol/openid-connect/registrations';
const authUrl = '/realms/headway/protocol/openid-connect/auth';

export interface IKeycloakToken {
    access_token: string,
    expires_in: number,
    refresh_expires_in: number,
    refresh_token: string,
    token_type: string,
    id_token: string,
    'not-before-policy': number,
    session_state: string,
    scope: string,
}

const keycloakCreateConfig: KeycloakConfig = {
    url: authBasePath + '/',
    realm: 'headway',
    clientId,
};

export abstract class AuthBaseApi {
    protected static pendingAccessTokenRequest: Promise<string> | null = null;

    abstract async refreshTokens(): Promise<IUserTokenInfo>;
    abstract async logout(fullLogout: boolean): Promise<void>;

    public setAuthTokens(access_token: string, refresh_token: string) {
        console.log('Set Auth Tokens');
        localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, access_token);
        localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, refresh_token);
    }

    public removeTokens() {
        console.log('Remove tokens');
        localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN);
    }

    public getParsedRefreshToken(): IRefreshToken | null {
        console.log('Get parsed token');
        const token = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN) as string;
        if (!token) {
            return null;
        }
        return jwtDecode<IRefreshToken>(token);
    }

    public async getAccessToken(): Promise<string> {
        console.log('Get Access token');
        const token = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN) as string;
        const parsedAccessToken = jwtDecode<IRefreshToken>(token);

        if (AuthBaseApi.tokenIsExpiresSoon(parsedAccessToken) && AuthKeycloakApi.pendingAccessTokenRequest === null) {
            const parsedRefreshToken = this.getParsedRefreshToken();
            if (parsedRefreshToken && AuthBaseApi.tokenIsExpiresSoon(parsedRefreshToken)) {
                return Promise.reject(new AuthenticationError('Auth token expired'));
            }

            AuthBaseApi.pendingAccessTokenRequest = new Promise<string>((resolve, reject) => {
                console.log('Pending token refresh');
                this.refreshTokens()
                    .then(() => {
                        console.log('Pending token refresh END');
                        const newToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN) as string;
                        resolve(newToken);
                    })
                    .catch(error => {
                        console.log('Pending token refresh CATCH');
                        logErrorWithCustomMessage(error, `Auth get token error`);
                        reject(error);
                    })
                    .finally(() => {
                        console.log('Pending token refresh FINALLY');
                        AuthKeycloakApi.pendingAccessTokenRequest = null;
                    });
            });
        }

        if (AuthKeycloakApi.pendingAccessTokenRequest) {
            return AuthKeycloakApi.pendingAccessTokenRequest;
        }

        return Promise.resolve(token);
    }

    static async authRequest(rawParams: Record<string, string>) {
        const params = new URLSearchParams();
        params.append('client_id', clientId);
        params.append('scope', scope);
        Object.entries(rawParams).forEach(([key, value]) => {
            params.append(key, value);
        });

        return axios.post<IAuthData>(
            `${authBasePath}/realms/headway/protocol/openid-connect/token`,
            params,
            {
                timeout: authRequestTimeout,
            },
        );
    }

    public static tokenIsExpiresSoon({ exp }: IExpiringToken): boolean {
        const now = moment();
        const expiration = moment.unix(exp);

        return expiration.diff(now, 'minutes') < EXPIRE_IN_MINUTES;
    }

    public getOriginalImpersonateToken() {
        const accessToken = localStorage.getItem(LocalStorageKey.IMPERSONATE_ORIGINAL_ACCESS_TOKEN) as string;
        const refreshToken = localStorage.getItem(LocalStorageKey.IMPERSONATE_ORIGINAL_REFRESH_TOKEN) as string;
        return {
            accessToken,
            refreshToken,
        };
    }

    public setOriginalImpersonateToken(access_token: string, refresh_token: string) {
        localStorage.setItem(LocalStorageKey.IMPERSONATE_ORIGINAL_ACCESS_TOKEN, access_token);
        localStorage.setItem(LocalStorageKey.IMPERSONATE_ORIGINAL_REFRESH_TOKEN, refresh_token);
    }

    public saveOriginalImpersonateToken() {
        const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN) as string;
        const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN) as string;
        this.setOriginalImpersonateToken(accessToken, refreshToken);
    }

    public removeOriginalImpersonateToken() {
        localStorage.removeItem(LocalStorageKey.IMPERSONATE_ORIGINAL_ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageKey.IMPERSONATE_ORIGINAL_REFRESH_TOKEN);
    }
}

export class AuthKeycloakApi extends AuthBaseApi {
    keycloak: Keycloak;

    constructor(instance?: Keycloak) {
        super();
        this.keycloak = instance ?? new Keycloak(keycloakCreateConfig);
    }

    private async getTokenFromAdapter(): Promise<string> {
        if (this.keycloak.token && this.keycloak.refreshToken) {
            this.setAuthTokens(this.keycloak.token, this.keycloak.refreshToken);
            await this.init();
            return this.keycloak.refreshToken;
        }
        const isSuccess = await this.init();
        if (isSuccess && this.keycloak.token && this.keycloak.refreshToken) {
            const refreshToken = this.keycloak.refreshToken;
            this.setAuthTokens(this.keycloak.token, refreshToken);
            return refreshToken;
        }
        return '';
    }

    async init() {
        const loginResult = await this.keycloak.init({
            checkLoginIframe: false,
            onLoad: 'login-required',
            timeSkew: 10,
            token: localStorage.getItem(LocalStorageKey.ACCESS_TOKEN) ?? undefined,
            refreshToken: localStorage.getItem(LocalStorageKey.REFRESH_TOKEN) ?? undefined,
        }).catch(e => {
            logger.error(e, { message: 'keycloak init error' });
            const loginUrl = this.keycloak.createLoginUrl({
                redirectUri: window.location.origin + routes.HOME,
            });
            this.keycloak.logout({
                redirectUri: loginUrl,
            });
        });
        if (localStorage.getItem(LocalStorageKey.FORCE_LOGOUT)) {
            await this.logout(true);
        }
        return loginResult;
    }

    public async refreshByTokenIfExists() {
        const refreshToken = await this.tryGetLivingToken(LocalStorageKey.REFRESH_TOKEN);
        const authToken = await this.tryGetLivingToken(LocalStorageKey.ACCESS_TOKEN);
        if (!refreshToken) {
            return null;
        }
        if (authToken) {
            return jwtDecode<IUserTokenInfo>(authToken);
        }

        try {
            const { data } = await AuthKeycloakApi.authRequest({ refresh_token: refreshToken, grant_type: 'refresh_token' });
            this.setAuthTokens(data.access_token, data.refresh_token);
            await this.refreshImpersonationOriginalUserTokens();
            return jwtDecode<IUserTokenInfo>(data.access_token);
        } catch (e) {
            this.removeTokens();
            if (shouldIgnoreRequestError(e)) {
                throw new AuthenticationError('Unable to refresh tokens', e);
            }
            throw e;
        }
    }

    public async refreshTokens() {
        let refreshToken = await this.tryGetLivingToken(LocalStorageKey.REFRESH_TOKEN);
        const authToken = await this.tryGetLivingToken(LocalStorageKey.ACCESS_TOKEN);
        if (!refreshToken) {
            refreshToken = await this.getTokenFromAdapter();
        }
        if (authToken) {
            return jwtDecode<IUserTokenInfo>(authToken);
        }

        try {
            const { data } = await AuthKeycloakApi.authRequest({ refresh_token: refreshToken, grant_type: 'refresh_token' });
            this.setAuthTokens(data.access_token, data.refresh_token);
            await this.refreshImpersonationOriginalUserTokens();
            return jwtDecode<IUserTokenInfo>(data.access_token);
        } catch (e) {
            this.removeTokens();
            if (shouldIgnoreRequestError(e)) {
                throw new AuthenticationError('Unable to refresh tokens', e);
            }
            throw e;
        }
    }

    public async login(messageCode?: ToastMessagesCodes) {
        const loginUrlParsed = new URL(authBasePath + authUrl);
        loginUrlParsed.searchParams.append('client_id', 'web-frontend');
        loginUrlParsed.searchParams.append('response_mode', 'fragment');
        loginUrlParsed.searchParams.append('response_type', 'code');
        loginUrlParsed.searchParams.append('scope', 'openid');

        if (messageCode) {
            loginUrlParsed.searchParams.append('redirect_uri', window.location.protocol + '//' + window.location.host);
            loginUrlParsed.searchParams.append('messageCode', messageCode);
        } else {
            loginUrlParsed.searchParams.append('redirect_uri', window.location.href);
        }
        const loginUrl = loginUrlParsed.toString();

        // The user may be authorized in keycloak, but not in WebUI
        // We would like to sign him out first.
        // May fix the issue with infinite redirect
        this.removeTokens();
        this.removeOriginalImpersonateToken();
        window.location.href = loginUrl;
    }

    public async register(additionalParams: Record<string, SearchParams> = {}) {
        await this.refreshByTokenIfExists();
        const registerUrl = new URL(authBasePath + registrationsUrl);
        registerUrl.searchParams.append('client_id', 'web-frontend');
        registerUrl.searchParams.append('response_mode', 'fragment');
        registerUrl.searchParams.append('response_type', 'code');
        registerUrl.searchParams.append('scope', 'openid');
        registerUrl.searchParams.append('redirect_uri', window.location.protocol + '//' + window.location.host);
        Object.entries(additionalParams).forEach(([name, value]) => {
            if (Array.isArray(value)) {
                value.forEach(item => {
                    registerUrl.searchParams.append(name, item);
                });
            } else if (null !== value) {
                registerUrl.searchParams.append(name, value);
            }
        });
        this.removeTokens();
        this.removeOriginalImpersonateToken();
        window.location.href = registerUrl.href;
    }

    public async logout(keycloakLogout: boolean) {
        if (keycloakLogout) {
            let redirectUri;
            try {
                redirectUri = this.keycloak.createLoginUrl();
                localStorage.removeItem(LocalStorageKey.FORCE_LOGOUT);
            } catch (e) {
                console.log('Uninitialized keycloak logout attempt, reinitializing');
                localStorage.setItem(LocalStorageKey.FORCE_LOGOUT, 'FORCE');
            }
            this.removeTokens();
            this.removeOriginalImpersonateToken();
            if (!redirectUri) {
                await this.keycloak.init({
                    checkLoginIframe: false,
                    onLoad: 'login-required',
                    timeSkew: 10,
                });
                redirectUri = this.keycloak.createLoginUrl();
            }
            return this.keycloak.logout({
                redirectUri: redirectUri,
            });
        }
    }

    public async refreshImpersonationOriginalUserTokens() {
        const refreshToken = localStorage.getItem(LocalStorageKey.IMPERSONATE_ORIGINAL_REFRESH_TOKEN);
        if (!refreshToken) {
            return;
        }
        try {
            const { data } = await AuthKeycloakApi.authRequest({ refresh_token: refreshToken, grant_type: 'refresh_token' });
            this.setOriginalImpersonateToken(data.access_token, data.refresh_token);
        } catch (e) {
            this.removeOriginalImpersonateToken();
            logger.info(e);
        }
    }

    public async tryGetLivingToken(key: LocalStorageKey): Promise<string | null> {
        const token = await localStorage.getItem(key);
        if (token) {
            try {
                const { exp } = jwtDecode<{ exp: number }>(token);
                return this.isExpired(exp) ? null : token;
            } catch {
                return null;
            }
        }
        return null;
    }

    public isExpired(unixTimestamp: number): boolean {
        const expireDate = moment.unix(unixTimestamp);
        const now = moment.utc();
        return now.isAfter(expireDate);
    }
}

const authApi = new AuthKeycloakApi();

export function getAuthApi() {
    return authApi;
}
