import { useSelector } from 'react-redux';
import { getCustomFieldValueGroupedByCustomFieldId, getFieldValueName } from 'store/entities/customFields/helpers';
import {
    ICustomFieldValue,
    HierarchyType,
    ICustomField,
    CustomFieldType,
    DepartmentPrismFieldType,
} from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';

export const useSplitCustomFieldValuesIdsByCustomFiledIds = (valueIds: string[] = []) => {
    const fieldValuesByIds = useSelector(selectCustomFieldValuesByIds);

    return valueIds.reduce((mem, valueId) => {
        const value = fieldValuesByIds[valueId];
        if (value) {
            if (mem[value.custom_field_id]) {
                mem[value.custom_field_id].push(valueId);
            } else {
                mem[value.custom_field_id] = [valueId];
            }
        }
        return mem;
    }, {} as Record<string, string[]>);
};

export const useSplitCustomFieldValueIdsByCustomFiledId = (valueIds: string[] = []) => {
    const fieldValuesByIds = useSelector(selectCustomFieldValuesByIds);

    return valueIds.reduce((mem, valueId) => {
        const value = fieldValuesByIds[valueId];
        if (value) {
            mem[value.custom_field_id] = valueId;
        }
        return mem;
    }, {} as Record<string, string>);
};

export const getHierarchyRelations = (value: ICustomFieldValue, type: HierarchyType) => {
    switch (type) {
        case HierarchyType.OfferLetter:
            return value.offer_hierarchy;
        case HierarchyType.Assignment:
        default:
            return value.assignment_hierarchy;
    }
};

export const getGroupedCustomFieldDisplayValues = (
    customFieldValueIds: string[],
    allCustomFieldIds: string[],
    customFieldValuesByIds: Record<string, ICustomFieldValue>,
): Record<string, string[]> => {
    return {
        ...getCustomFieldValueGroupedByCustomFieldId(
            customFieldValueIds,
            customFieldValuesByIds,
            getFieldValueName,
        ),
        ...allCustomFieldIds.reduce((result, id) => ({
            ...result,
            [id]: ['All'],
        }), {}),
    };
};

export const isCustomFieldOptional = (customField?: ICustomField): boolean => {
    // SCA and department is optional for subassignment
    return customField?.headway_connect_field?.key === CustomFieldType.SCA
        || customField?.prism_field?.key === DepartmentPrismFieldType;
};
