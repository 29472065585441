import React from 'react';

/**
 * Decorator allows to separate all logic of getting data of creating callbacks from pure component
 * @param logicFunc
 */
export const separateLogicDecorator = <Props extends object, SeparatedLogicProps extends object>
(logicFunc: (props: Props) => SeparatedLogicProps) => {
    function Wrapper(WrappedComponent: React.FC<Props & SeparatedLogicProps>) {
        function WithLogicComponent(props: Props) {
            const logicProps = logicFunc(props);

            return (
                <WrappedComponent {...props} {...logicProps}/>
            );
        }
        return WithLogicComponent;
    }
    return Wrapper;
};
