import { IScaZone, IStateUS } from 'store/entities/scaZone/models';
import { ActionsReturnTypes, createActions, createRequestActions, RequestType } from 'store/utils';

export const getStates = createRequestActions<void, IStateUS[]>(RequestType.Get, 'STATES', 'addresses');

export interface IStateByScaZoneRequestPayload {
    parentScaZoneId?: number;
}
export interface IStateByScaZoneSuccessPayload {
    parentScaZoneId?: number;
    stateIds: string[];
}
export const getStatesByScaZone = createRequestActions<IStateByScaZoneRequestPayload, IStateByScaZoneSuccessPayload>(
    RequestType.Get,
    'STATES_BY_SCA_ZONE',
    'addresses',
);

const GET_SCA_ZONES = 'GET_SCA_ZONES';
const GET_SCA_ZONES_SUCCESS = 'GET_SCA_ZONES_SUCCESS';
const GET_SCA_ZONES_ERROR = 'GET_SCA_ZONES_ERROR';

export const getScaZones = createActions<{
    assignmentId: string;
    stateId: string;
    parentScaZoneId?: number;
},
{
    assignmentId: string;
    stateId: string;
    parentScaZoneId?: number;
    scaZones: Array<IScaZone>;
}, any,
    typeof GET_SCA_ZONES, typeof GET_SCA_ZONES_SUCCESS, typeof GET_SCA_ZONES_ERROR
>(
    GET_SCA_ZONES, GET_SCA_ZONES_SUCCESS, GET_SCA_ZONES_ERROR,
);

export type ScaZoneActions =
    | ActionsReturnTypes<typeof getScaZones>
    | ActionsReturnTypes<typeof getStatesByScaZone>
    | ActionsReturnTypes<typeof getStates>;
