import { IStore } from 'store/configureStore';
import { ITravelExpenseFilters } from 'modules/travelExpenses/components/TravelExpenseFilter/model';
import { selectTravelExpensesState } from 'modules/travelExpenses/selectors';
import { createSelector } from 'reselect';
import { selectDeals } from 'store/entities/configuration/configurationSelectors';
import {
    selectJobNumbersByDealId,
} from 'modules/settings/submodules/clients/deals/components/DealForm/store/selectors';
import { IDeal } from 'shared/models/Deal';

export const selectTravelExpenseFilter = (state: IStore): ITravelExpenseFilters =>
    selectTravelExpensesState(state).travelExpenseFilter;

export const selectTravelExpenseFilterJobNumberIds = createSelector(
    selectTravelExpenseFilter,
    selectDeals,
    selectJobNumbersByDealId,
    (filter, allDeals, jobNumbersByDealId) => {
        const { dealTypeId } = filter;
        if (!dealTypeId){
            return undefined;
        }
        const deals = allDeals.filter((deal: IDeal) => deal.type_id === dealTypeId);
        const jobNumbers = deals.flatMap((deal: IDeal) => {
            return jobNumbersByDealId[deal.id].map(jobNumber => jobNumber.id);
        });
        return jobNumbers;
    });
