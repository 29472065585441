import { IStore } from 'store/configureStore';
import { IPayCode } from './model';

export const selectPayCodesState = (state: IStore) =>
    state.modules.settings.clientsSettings.clients.payCodes;
export const selectIsPayCodesLoading = (state: IStore) =>
    selectPayCodesState(state).isLoading;
export const selectPayCodes = (state: IStore) => (
    Object
        .values<IPayCode>(selectPayCodesState(state).payCodesById)
        .filter(payCode => !payCode.deleted_at)
        .sort((payCode0, payCode1) => payCode0.description.localeCompare(payCode1.description))
);
export const selectPayCodesById = (state: IStore) =>
    selectPayCodesState(state).payCodesById;
