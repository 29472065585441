import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setGridHoverStatus } from 'store/entities/appConfig/actions';

export type THoverState = {
    rowId: string;
    color: string;
    isHover: boolean;
}

export function useHover() {
    const dispatch = useDispatch();
    const onMouseEnter = useCallback((color: string, rowId: string) => {
        dispatch(setGridHoverStatus({ isHover: true, color: color, rowId: rowId }));
    }, [dispatch]);

    const onMouseLeave = useCallback((rowId: string) => {
        dispatch(setGridHoverStatus({ isHover: false, color: '', rowId: rowId }));
    }, [dispatch]);

    return {
        onMouseEnter,
        onMouseLeave,
    };
}
