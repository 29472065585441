import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const useFormMultiselectStyles = makeHighPriorityStyles((theme: Theme) => ({
    chips: {
        maxWidth: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
}));
