import React, { useCallback, useEffect } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCurrentClientHasNoGeocode,
    selectIsNoGeocodeWarningPopupShown,
} from 'modules/settings/submodules/clients/store/selectors';
import { setIsNoGeocodeWarningShown } from 'modules/settings/submodules/clients/store/actions';

export const noGeoCodeWarningText = 'The system could not identify a geocode for the location you entered. Please manually add geocode in Prism.';

export const NoGeocodeWarning = () => {
    const isPopupShown = useSelector(selectIsNoGeocodeWarningPopupShown);
    const dispatch = useDispatch();
    const setIsPopupShown = useCallback((newValue: boolean) => {
        dispatch(setIsNoGeocodeWarningShown(newValue));
    }, [dispatch]);
    const closePopup = useCallback(() => {
        setIsPopupShown(false);
    }, [setIsPopupShown]);
    const currentClientHasNoGeocode = useSelector(selectCurrentClientHasNoGeocode);
    useEffect(() => {
        if (currentClientHasNoGeocode){
            setIsPopupShown(true);
        }
    }, [currentClientHasNoGeocode, setIsPopupShown]);
    return (
        <>
            {isPopupShown && (
                <Dialog
                    open={isPopupShown}
                    onClose={closePopup}
                    disableBackdropClick
                >
                    <DialogContent>
                        <Typography gutterBottom>
                            {noGeoCodeWarningText}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={closePopup}
                            variant="contained"
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
