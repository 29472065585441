import { isNaN, isUndefined } from 'lodash-es';
import {
    defaultJobNumberFormValues,
    IJobNumberFormValues,
} from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/model';
import {
    IManageJobNumberCommonRequest,
    IUpdateJobNumberRequest,
} from 'modules/settings/submodules/clients/jobNumber/store/models';
import { useSelector } from 'react-redux';
import { IDeal } from 'shared/models/Deal';
import { IJobNumber } from 'shared/models/JobNumber';
import { selectDealsById, selectDealTypeById } from 'store/entities/configuration/configurationSelectors';

export const normalizeOptionalNumberValue = (
    value?: string | null,
    defaultValue: number | null = null,
): number | null => {
    if (isUndefined(value) || value === null) {
        return defaultValue;
    }
    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? defaultValue : numberValue;
};

export const formValuesToPayload = (values: IJobNumberFormValues): IManageJobNumberCommonRequest => {
    return {
        job_number_group: values.jobNumberGroup || null,
        deal_id: values.deal?.id || '',
        description: values.jobDescription,
        fee_type: values.billingInfo,
        // @ts-ignore
        fte: values.fte,
        is_per_diem: values.payrollIsPerDiem,
        manager_id: values.managerId || '',
        per_diem_rate: normalizeOptionalNumberValue(values.payrollPerDiemRate, null),
        per_file_rate: normalizeOptionalNumberValue(values.payrollFileRate, null),
        per_diem_ot_rate: normalizeOptionalNumberValue(values.billingPerDiemRate, null),
        per_file_ot_rate: normalizeOptionalNumberValue(values.billingFileRate, null),
        hourly_pay_rate: normalizeOptionalNumberValue(values.payrollHourlyRate, null),
        start_date: values.startDate,
        end_date: values.endDate || null,
        time_activities: values.timeActivities.map(activity => ({ id: activity.id })),
        timesheet_setting: values.timesheetSettings,
        user_type_id: values.userTypeId || '',
        uw_system: values.uwSystem,
        mi_credit: normalizeOptionalNumberValue(values.miCredit, null),
        billing_max: normalizeOptionalNumberValue(values.max, null),
        billing_min: normalizeOptionalNumberValue(values.min, null),
        blended_amount: normalizeOptionalNumberValue(values.blendedAmount, null),
    };
};

export const updateFormValuesToPayload = (values: IJobNumberFormValues): IUpdateJobNumberRequest => {
    return {
        ...formValuesToPayload(values),
        reason_for_close: values.reasonForClose,
        close_on_date: values.closeOnDate,
    };
};

export const useJobNumberInitialFormValues = (jobNumber?: IJobNumber, deal?: IDeal): IJobNumberFormValues => {
    const dealsByIds = useSelector(selectDealsById);
    const dealType = useSelector(selectDealTypeById(deal?.type_id));
    return {
        jobNumberGroup: jobNumber?.job_number_group,
        dealType: dealType,
        // @ts-ignore
        jobNumber: jobNumber?.job_number,
        deal: deal || dealsByIds[jobNumber?.deal_id || ''] || null,
        jobDescription: jobNumber?.description || defaultJobNumberFormValues.jobDescription,
        startDate: jobNumber?.start_date || defaultJobNumberFormValues.startDate,
        endDate: jobNumber?.end_date || defaultJobNumberFormValues.endDate,
        managerId: jobNumber?.manager_id || defaultJobNumberFormValues.managerId,
        userTypeId: jobNumber?.user_type_id || defaultJobNumberFormValues.userTypeId,
        uwSystem: jobNumber?.uw_system || defaultJobNumberFormValues.uwSystem,
        // @ts-ignore
        fte: jobNumber?.fte || defaultJobNumberFormValues.fte,
        timesheetSettings: jobNumber?.timesheet_setting || defaultJobNumberFormValues.timesheetSettings,
        timeActivities: jobNumber?.time_activities?.map(activity => ({ id: activity.activity_id }))
            || defaultJobNumberFormValues.timeActivities,

        billingInfo: jobNumber?.fee_type || defaultJobNumberFormValues.billingInfo,
        max: jobNumber?.billing_max || defaultJobNumberFormValues.max,
        min: jobNumber?.billing_min || defaultJobNumberFormValues.min,
        miCredit: jobNumber?.mi_credit || defaultJobNumberFormValues.miCredit,
        blendedAmount: jobNumber?.blended_amount || defaultJobNumberFormValues.blendedAmount,
        billingFileRate: jobNumber?.per_file_ot_rate || defaultJobNumberFormValues.billingPerDiemRate,
        billingPerDiemRate: jobNumber?.per_diem_ot_rate || defaultJobNumberFormValues.payrollPerDiemRate,

        payrollIsPerDiem: jobNumber?.is_per_diem || defaultJobNumberFormValues.payrollIsPerDiem,
        payrollHourlyRate: jobNumber?.hourly_pay_rate || defaultJobNumberFormValues.payrollHourlyRate,
        payrollFileRate: jobNumber?.per_file_rate || defaultJobNumberFormValues.payrollFileRate,
        payrollPerDiemRate: jobNumber?.per_diem_rate || defaultJobNumberFormValues.payrollPerDiemRate,

        reasonForClose: jobNumber?.reason_for_close || defaultJobNumberFormValues.reasonForClose,
        closeOnDate: jobNumber?.close_on_date || defaultJobNumberFormValues.closeOnDate,
    };
};
