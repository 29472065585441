import { IDealResponse } from 'store/entities/configuration/configurationModel';
import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';

const moduleActionPrefix = 'settings/deals';

const INITIAL_LOAD_LIST = `${moduleActionPrefix}/INITIAL_LOAD_LIST`;
export const initialLoadDealsPage = createSingleAction<void, typeof INITIAL_LOAD_LIST>(
    INITIAL_LOAD_LIST,
);

export const getMoreDealsAction = createRequestActions<void, IDealResponse>(
    RequestType.Get,
    'MORE_DEALS',
    moduleActionPrefix,
);

export type DealsActions =
    | ReturnType<typeof initialLoadDealsPage>
    | ActionsReturnTypes<typeof getMoreDealsAction>
