import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { ISubassignmentBase } from 'store/entities/configuration/configurationModel';
import { SelectedCounterBox } from 'modules/subassignmentManagement/components/BulkOperations/SelectedCounterBox';
import { CreateSubAssignmentForm } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/CreateSubAssignmentFormV2';
import { getSubassignmentFromFormValues } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/helpers';
import { selectCheckedAssignmentIds, selectCheckedAssignmentsApprovalLevel } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';

interface IBulkCreateWithSameValuesProps {
    onSubmit: (subassignments: ISubassignmentBase[]) => void,
}

export const BulkCreateWithSameValues = ({ onSubmit }: IBulkCreateWithSameValuesProps) => {
    const assignmentsIds = useSelector(selectCheckedAssignmentIds);
    const numberOfApprovers = useSelector(selectCheckedAssignmentsApprovalLevel);
    const clientId = useSelector(selectCurrentClientId);

    const handleSubmit = useCallback(formValues => {
        const subassignments = assignmentsIds.map(assignmentId => {
            return getSubassignmentFromFormValues(assignmentId, clientId, formValues);
        });
        onSubmit(subassignments);
    }, [assignmentsIds, onSubmit, clientId]);

    return (
        <Box>
            <SelectedCounterBox
                count={assignmentsIds.length}
                entityName="employee"
            />
            <CreateSubAssignmentForm
                onSubmit={handleSubmit}
                numberOfApprovers={numberOfApprovers}
            />
        </Box>
    );
};
