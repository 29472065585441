import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { Dialog, DialogContent, Button, DialogActions, DialogTitle } from '@material-ui/core';

export interface IConfirmationDialogProps extends Partial<DialogProps> {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    cancelText?: string;
    confirmText?: string;
    confirmationInProgress?: boolean;
    dialogTitle?: React.ReactNode;
    children: React.ReactNode;
    disableConfirm?: boolean;
    customClasses?: IConfirmationDialogCustomClasses
}

export interface IConfirmationDialogCustomClasses {
    paper?: string;
    dialogTitle?: string;
    content?: string;
    actions?: string;
    confirmButton?: string;
    cancelButton?: string;
}

export default function ConfirmationDialog({
    open,
    children,
    onCancel,
    onConfirm,
    customClasses,
    dialogTitle,
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    disableConfirm = false,
    confirmationInProgress = false,
    ...props
}: IConfirmationDialogProps) {
    return (
        <Dialog
            {...props}
            open={open}
            onClose={onCancel}
            disableBackdropClick
            classes={{
                paper: customClasses?.paper || '',
            }}
        >
            {Boolean(dialogTitle) && (
                <DialogTitle className={customClasses?.dialogTitle}>
                    {dialogTitle}
                </DialogTitle>
            )}
            <DialogContent className={customClasses?.content}>
                {children}
            </DialogContent>
            <DialogActions className={customClasses?.actions}>
                <Button
                    onClick={onCancel}
                    variant="contained"
                    className={customClasses?.cancelButton}
                >
                    {cancelText}
                </Button>
                <ButtonWithLoader
                    className={customClasses?.confirmButton}
                    onClick={onConfirm}
                    isLoading={confirmationInProgress}
                    disabled={disableConfirm}
                    variant="contained"
                    color="primary"
                    autoFocus
                >
                    {confirmText}
                </ButtonWithLoader>
            </DialogActions>
        </Dialog>
    );
}
