import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { usePdfViewStyles } from 'shared/components/PdfViewer/PdfViewStyles';
import { Document, Page, pdfjs } from 'react-pdf';
import { GetApp } from '@material-ui/icons';
import clsx from 'clsx';
import PdfObject from 'pdfobject';
import { debounce } from 'ts-debounce';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const initialWidth = 595;
const bordersWidth = 8;

export interface IPdfViewProps {
    filePath: string;
    title: string;
    className?: string;
}

export function PdfView({ title, filePath, className = '' }: IPdfViewProps) {
    const classes = usePdfViewStyles();
    const elementWrapper = useRef<HTMLDivElement | null>(null);
    const [scale, setScale] = useState(1.0);
    const setScaleRef = useRef(setScale);

    const isEmbedded = PdfObject.supportsPDFs;

    useEffect(() => {
        if (isEmbedded) {
            PdfObject.embed(filePath, elementWrapper.current);
        }
    }, [filePath, elementWrapper, isEmbedded]);

    useEffect(() => {
        const debouncedResize = debounce(() => {
            if (elementWrapper.current) {
                const realWidth = elementWrapper.current.getBoundingClientRect().width - bordersWidth;
                const newScale = realWidth / initialWidth;
                setScaleRef.current(newScale);
            }
        }, 200);

        debouncedResize();

        window.addEventListener('resize', debouncedResize);
        return () => window.removeEventListener('resize', debouncedResize);
    }, [setScaleRef, elementWrapper]);

    const [pagesAmount, setPagesAmount] = useState<number | null>(null);

    function onDocumentLoadSuccess({ numPages: numPagesNew }: { numPages: number }) {
        setPagesAmount(numPagesNew);
    }

    const pages = Array(pagesAmount).fill(0);

    return (
        <div className={clsx(classes.wrapper, className)} ref={elementWrapper}>
            {!isEmbedded ? (
                <Box className={classes.ownPdfRoot}>
                    <Box display="flex" alignItems="center"
                        justifyContent="space-between"
                        className={clsx(classes.header, classes.fontColor)}
                    >
                        <span>
                            {title}
                        </span>
                        <div>
                            <a href={filePath} download
                                className={classes.linkStyles}
                            >
                                <GetApp/>
                            </a>
                        </div>
                    </Box>
                    <Document
                        file={filePath}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<Box className={classes.ownPdfLoader}><CircularProgress/></Box>}
                        options={{
                            disableFontFace: true,
                        }}
                        className={classes.ownPdfContent}
                    >
                        {pages.map((page, index) => (
                            <Page key={index} scale={scale}
                                pageNumber={index + 1}
                            />
                        ))}
                    </Document>
                </Box>
            ) : (
                <CircularProgress/>
            )}
        </div>
    );
}
