import { activities } from 'modules/settings/submodules/clients/activities/store/reducer';
import { assignmentsReportData } from 'modules/settings/submodules/clients/assignmentsReportData/store/reducers';
import { editClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/store/reducer';
import { costCenters } from 'modules/settings/submodules/clients/costCenters/store/reducer';
import { customFieldValues } from 'modules/settings/submodules/clients/customFieldValues/store/reducers';
import { jobNumbers } from 'modules/settings/submodules/clients/jobNumber/store/reducer';
import { payCodes } from 'modules/settings/submodules/clients/payCodes/store/reducer';
import { payRanges } from 'modules/settings/submodules/clients/payRanges/store/reducers';
import { physicalDemandsTemplates } from 'modules/settings/submodules/clients/physicalDemands/store/reducers';
import { reports } from 'modules/settings/submodules/clients/reports/store/reducer';
import { scopes } from 'modules/settings/submodules/clients/scopes/store/reducers';
import {
    deleteClient, setClientTableSort,
    setIsNoGeocodeWarningShown,
    updateClient,
} from 'modules/settings/submodules/clients/store/actions';
import {
    createClientModal,
    isClientCreating,
} from 'modules/settings/submodules/clients/components/NewClientButton/state/reducer';
import { combineReducers } from 'redux';
import { Order } from 'shared/models/Order';
import { isLoadingReducer, isOpenModalReducer, singleValueReducer } from 'store/reducerUtils';
import { workingConditionsTemplates } from 'modules/settings/submodules/clients/workingConditions/store/reducers';
import { backgroundChecksTemplates } from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/reducers';
import { appConfiguration } from 'modules/settings/submodules/clients/appConfiguration/store/reducers';
import { controllingOrg } from 'modules/settings/submodules/clients/controllingOrg/store/reducer';
import { submittingOrg } from 'modules/settings/submodules/clients/submittingOrg/store/reducer';
import { deals } from 'modules/settings/submodules/clients/deals/store/reducer';
import { paySettings } from 'modules/settings/submodules/clients/paySettings/store/reducer';
import { payCodeArBucket } from 'modules/settings/submodules/clients/payCodeArBucket/store/reducer';
import { dealType } from 'modules/settings/submodules/clients/dealType/store/reducer';

export const isClientUpdating = isLoadingReducer(updateClient);
export const isClientDeleting = isLoadingReducer(deleteClient);
const isNoGeocodeWarningShown = isOpenModalReducer(setIsNoGeocodeWarningShown.action);
export const clientTableSort = singleValueReducer(setClientTableSort.action, { name: Order.asc });

export const clients = combineReducers({
    createClientModal,
    isClientCreating,
    isClientUpdating,
    isClientDeleting,
    editClientLogo,
    controllingOrg,
    submittingOrg,
    deals,
    payCodes,
    activities,
    physicalDemandsTemplates,
    workingConditionsTemplates,
    backgroundChecksTemplates,
    appConfiguration,
    costCenters,
    jobNumbers,
    paySettings,
    isNoGeocodeWarningShown,
    customFieldValues,
    payCodeArBucket,
    dealType,
    assignmentsReportData,
    scopes,
    payRanges,
    clientTableSort,
    reports,
});
