import { call, put, takeEvery, takeLatest } from 'typed-redux-saga';

import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    createWorkingConditionsTemplate,
    setCreateWorkingConditionsTemplateModalState, setEditWorkingConditionsTemplateId, updateWorkingConditionsTemplate,
} from 'modules/settings/submodules/clients/workingConditions/store/actions';
import { workingConditionsApi } from 'modules/settings/submodules/clients/workingConditions/store/api';
import {
    getWorkingConditions,
    removeWorkingConditionsTemplate,
} from 'store/entities/configuration/configurationAction';

function* createWorkingConditionsSaga(action: ReturnType<typeof createWorkingConditionsTemplate.init>) {
    const { payload } = action;
    const responseNewEntity = yield* call(workingConditionsApi.createWorkingConditions, payload);
    yield put(createWorkingConditionsTemplate.success());
    yield put(getWorkingConditions.success([responseNewEntity]));
    yield put(setCreateWorkingConditionsTemplateModalState(false));
}

function* createWorkingConditionsWatcher() {
    yield* takeEvery(
        createWorkingConditionsTemplate.initType,
        withBackendErrorHandler(
            createWorkingConditionsSaga,
            createWorkingConditionsTemplate.error,
            'Unable to create Working Conditions Template',
        ),
    );
}

function* deleteWorkingConditionsTemplateSaga(
    { payload: id }: ReturnType<typeof removeWorkingConditionsTemplate.init>,
) {
    yield* call(workingConditionsApi.deleteWorkingConditions, id);
    yield put(removeWorkingConditionsTemplate.success(id));
}

function* deleteWorkingConditionsTemplateWatcher() {
    yield* takeLatest(
        removeWorkingConditionsTemplate.initType,
        withBackendErrorHandler(
            deleteWorkingConditionsTemplateSaga,
            removeWorkingConditionsTemplate.error,
            'Unable to delete Working Conditions Template',
        ),
    );
}

function* updateWorkingConditionsTemplateSaga({ payload }: ReturnType<typeof updateWorkingConditionsTemplate.init>) {
    const { id, data } = payload;
    const template = yield* call(workingConditionsApi.updateWorkingConditions, id, data);
    yield put(updateWorkingConditionsTemplate.success());
    yield put(getWorkingConditions.success([template]));
    yield put(setEditWorkingConditionsTemplateId(null));
}

function* updateWorkingConditionsWatcher() {
    yield* takeLatest(
        updateWorkingConditionsTemplate.initType,
        withBackendErrorHandler(
            updateWorkingConditionsTemplateSaga,
            updateWorkingConditionsTemplate.error,
            'Unable to update Working Conditions Template',
        ),
    );
}

export default [
    createWorkingConditionsWatcher,
    deleteWorkingConditionsTemplateWatcher,
    updateWorkingConditionsWatcher,
];
