import React from 'react';
import { DateCell } from 'modules/eafApproval/components/cells/common/DateCell';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';

export const EafApprovalGridCellCreationDate = ({
    created_at,
    ...props
}: EafApprovalCellProps) => {
    return (
        <DateCell
            {...props}
            dateString={created_at}
        />
    );
};
