import { createSingleAction } from 'store/utils';
import { ICcpSplitItem } from '../../../models/ccpModel';

const SET_OPEN_SPLIT_CCP_SIDEBAR = 'ccp/SET_OPEN_SPLIT_CCP_SIDEBAR';
export const setOpenSplitCcpSidebar = createSingleAction<
string, typeof SET_OPEN_SPLIT_CCP_SIDEBAR>(SET_OPEN_SPLIT_CCP_SIDEBAR);

const SET_ADD_SPLIT_CCP_ITEM = 'ccp/SET_ADD_SPLIT_CCP_ITEM';
export const addCcpSplitItem = createSingleAction<
void, typeof SET_ADD_SPLIT_CCP_ITEM>(SET_ADD_SPLIT_CCP_ITEM);

const REMOVE_CCP_SPLIT_ITEM = 'ccp/REMOVE_SPLIT_CCP_ITEM';
export const removeCcpSplitItem = createSingleAction<
string, typeof REMOVE_CCP_SPLIT_ITEM>(REMOVE_CCP_SPLIT_ITEM);

const CHANGE_CCP_SPLIT = 'ccp/CHANGE_CCP_SPLIT_ITEM';
export const changeSplitItemNote = createSingleAction<
ICcpSplitItem, typeof CHANGE_CCP_SPLIT>(CHANGE_CCP_SPLIT);

export const CHANGE_SPLIT_AMOUNT_TOTAL = 'ccp/CHANGE_SPLIT_AMOUNT_TOTAL';
export const changeSplitAmountTotal = createSingleAction<
number, typeof CHANGE_SPLIT_AMOUNT_TOTAL>(CHANGE_SPLIT_AMOUNT_TOTAL);

export type CcpSplitAmountActions = ReturnType<typeof changeSplitAmountTotal>;

export type CcpSplitActions = ReturnType<typeof setOpenSplitCcpSidebar>
| ReturnType<typeof addCcpSplitItem>
| ReturnType<typeof removeCcpSplitItem>
| ReturnType<typeof changeSplitItemNote>
