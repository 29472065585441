import React from 'react';
import { Box, Button } from '@material-ui/core';
import { usePayrollModalStyle } from './PayrollModalStyle';
import PostPayrollReport from './reports/PostPayrollReport';
import {
    DownloadPayrollErrorsButton,
} from 'modules/payrollProcessorHub/components/PayrollModal/DownloadPayrollErrorsButton';

interface IPostPayrollModal {
    close: () => void;
}

const PostPayrollModal = ({ close }: IPostPayrollModal) => {
    const classes = usePayrollModalStyle();

    return (
        <Box className={classes.ContentWrapper}>
            <PostPayrollReport/>
            <Box
                className={classes.ButtonWrapper}
                justifyContent="space-between"
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={close}
                >
                    Close
                </Button>
                <DownloadPayrollErrorsButton/>
            </Box>
        </Box>
    );
};

export default PostPayrollModal;
