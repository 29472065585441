export const getUserName = (
    user?: {
        first_name?: string;
        last_name?: string;
    } | null,
    emptyPlaceholder = '',
    separator = ' ',
    reverse = false,
) => {
    const firstName = user?.first_name ? user.first_name : '';
    const lastName = user?.last_name ? user.last_name : '';
    const elements = [
        firstName,
        firstName && lastName ? separator : '',
        lastName,
    ];
    if (reverse) {
        elements.reverse();
    }
    const fullUserName = ''.concat(...elements);
    return fullUserName || emptyPlaceholder;
};

export const getLastFirstName = (user?: {
    first_name?: string;
    last_name?: string;
} | null, emptyPlaceholder = '' ) => {
    return getUserName(
        user,
        emptyPlaceholder,
        ', ',
        true,
    );
};

export const getUserInitials = (user: {
    first_name: string;
    last_name: string;
}) => {
    return `${user.first_name[0]}${user.last_name[0]}`;
};
