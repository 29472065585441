import React from 'react';
import { setEafApprovalFilter } from 'modules/eafApproval/store/actions';
import { selectEafApprovalGridFilter } from 'modules/eafApproval/store/selectors';
import { useSelector } from 'react-redux';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import { useFilterContentStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import { StateFilterButtonWithForm } from 'shared/components/filters/FilterButtonAndForm/StateFilterButtonWithForm';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { Permission } from 'store/components/auth/authModels';

export const TerminationRequestsFilter = () => {
    const classes = useFilterContentStyles();
    const filter = useSelector(selectEafApprovalGridFilter);

    return (
        <StateFilterButtonWithForm
            filterSelector={selectEafApprovalGridFilter}
            setFilterAction={setEafApprovalFilter}
        >
            <UserAutocompleteWithSearch
                additionalFilter={{
                    purpose: Permission.SubmitSheets,
                    client_id: filter.clientId || undefined,
                }}
                name="employeeUserId"
                label="Find an employee"
                useIdValue
                className={classes.field}
            />

            <ClientSelect
                name="clientId"
                label="Client"
                useIdValue
                className={classes.field}
            />
        </StateFilterButtonWithForm>
    );
};
