import React from 'react';
import MainContent from 'shared/components/common/MainContent';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';

const Evaluations: React.FC<{}> = () => {
    useTitleUpdate('Evaluations');
    return (
        <MainContent>
            <h1>Evaluations component works!</h1>
        </MainContent>
    );
};

export default Evaluations;
