import { createSingleAction } from '../../../../../store/utils';

const moduleActionPrefix = 'ccp';

const INITIAL_LOAD_INVOICES_LIST = `${moduleActionPrefix}/INITIAL_LOAD_INVOICES_LIST`;
export const initialLoadCcpInvoicesPage = createSingleAction<void, typeof INITIAL_LOAD_INVOICES_LIST>(
    INITIAL_LOAD_INVOICES_LIST,
);

const TRIGGER_INVOICES_LIST = `${moduleActionPrefix}/TRIGGER_INVOICES_LIST`;
export const triggerLoadCcpInvoicesPage = createSingleAction<void, typeof TRIGGER_INVOICES_LIST>(
    TRIGGER_INVOICES_LIST,
);

const SET_OPEN_SUBMIT_CCP_INVOICES_MODAL = 'ccp/SET_OPEN_SUBMIT_CCP_INVOICES_MODAL';
export const setOpenCcpSubmitInvoicesModal = createSingleAction<
boolean, typeof SET_OPEN_SUBMIT_CCP_INVOICES_MODAL>(SET_OPEN_SUBMIT_CCP_INVOICES_MODAL);

export type CcpTransactionsActions =
    | ReturnType<typeof initialLoadCcpInvoicesPage>
