export interface ITemplateFormValues {
    name: string;
    template: string;
}

export const defaultTemplateFormValues: ITemplateFormValues = {
    name: '',
    template: '',
};

export interface ICreateTemplatePayload extends ITemplateFormValues{
}

export interface IUpdateTemplatePayload {
    id: string;
    data: ITemplateFormValues;
}
