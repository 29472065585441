import { combineReducers } from 'redux';
import { ItemsById } from 'shared/models/ItemsById';
import {
    EntryType,
    IStatus,
    ITimeEntry,
    ITimeSheet,
    ITimeSheetBackend,
} from 'shared/models/sheet/Sheet';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { isLoadingReducer, itemsByIds } from 'store/reducerUtils';
import {
    addTimeEntry,
    TimeSheetActions,
    loadTimeSheetsWithEntries,
    removeTimeEntry,
    updateTimeEntry,
    updateTimeSheets,
    getTimeStatuses,
    patchEntriesDateSpecificBySheetId,
    setTimeSheetPatchingById,
    loadTimeSheets,
    clearEmptyTimesheetsByPayPeriod,
    updateTemporaryTimeEntry,
    removeTimeSheet,
} from 'store/entities/timesheet/actions/timeActions';

const initialState = {
    sheetsById: {},
    entriesById: {},
    isSheetsLoading: false,
    statuses: [],
};

function transformSheet(item: Omit<ITimeSheetBackend, 'entries'>): ITimeSheet {
    return {
        ...item,
        entry_type: EntryType.TIME,
    };
}

export function getTimeEntriesFromSheet(sheet: ITimeSheetBackend): ItemsById<ITimeEntry> {
    return sheet.entries?.reduce((entriesWithSheetTypeById: ItemsById<ITimeEntry>, sheetEntry) => {
        return {
            ...entriesWithSheetTypeById,
            [sheetEntry.id]: {
                ...sheetEntry,
                entry_type: EntryType.TIME,
            },
        };
    }, {}) || {};
}

export function isSamePayPeriod(payPeriod1: IPayPeriod, payPeriod2: IPayPeriod) {
    return payPeriod1.period_start === payPeriod2.period_start && payPeriod1.period_end === payPeriod2.period_end;
}

function clearEmptyTimeSheetsByPayPeriod(sheetsByIds: ItemsById<ITimeSheet>, payPeriod: IPayPeriod) {
    return itemsByIds(Object.values(sheetsByIds).filter(
        sheet => !isSamePayPeriod(sheet, payPeriod) || sheet.total_minutes || sheet.total_files,
    ));
}

export const isSheetsLoading = isLoadingReducer(loadTimeSheetsWithEntries);

export function sheetsById(
    state: ItemsById<ITimeSheet> = initialState.sheetsById,
    action: TimeSheetActions,
): ItemsById<ITimeSheet> {
    switch (action.type) {
        case loadTimeSheets.successType:
        case updateTimeSheets.successType: {
            return {
                ...state,
                ...itemsByIds(
                    action.payload,
                    transformSheet,
                ),
            };
        }
        case loadTimeSheetsWithEntries.successType:
        case patchEntriesDateSpecificBySheetId.successType: {
            // We want to save every property but entries info this reducer
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const updatedSheets = action.payload.reduce((
                acc: Record<string, ITimeSheet>,
                { entries, ...updatedSheet }: ITimeSheetBackend,
            ) => ({
                ...acc,
                [updatedSheet.id]: transformSheet(updatedSheet),
            }), {});
            return {
                ...state,
                ...updatedSheets,
            };
        }
        case clearEmptyTimesheetsByPayPeriod.action:
            return clearEmptyTimeSheetsByPayPeriod(state, action.payload);
        case removeTimeSheet.action:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
            const { [action.payload]: removedSheet, ...otherSheets } = state;
            return {
                ...otherSheets,
            };
        default:
            return state;
    }
}

type PatchingStateById = Record<string, boolean>;
const timeSheetIsPatching = (
    state: PatchingStateById = {},
    action: ReturnType<typeof setTimeSheetPatchingById>,
): PatchingStateById => {
    switch (action.type) {
        case setTimeSheetPatchingById.action:
            return {
                ...state,
                [action.payload.sheetId]: action.payload.state,
            };
        default:
            return state;
    }
};

export function entriesById(
    state: ItemsById<ITimeEntry> = initialState.entriesById,
    action: TimeSheetActions,
): ItemsById<ITimeEntry> {
    switch (action.type) {
        case loadTimeSheetsWithEntries.successType:
        case patchEntriesDateSpecificBySheetId.successType:
            return {
                ...state,
                ...action.payload.reduce((acc: ItemsById<ITimeEntry>, sheet: ITimeSheetBackend) => ({
                    ...acc,
                    ...getTimeEntriesFromSheet(sheet),
                }), {}),
            };
        case addTimeEntry.successType:
        case updateTimeEntry.successType:
            return {
                ...state,
                [action.payload.id]: {
                    ...action.payload,
                    entry_type: EntryType.TIME,
                },
            };
        case updateTemporaryTimeEntry.action:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
            const { [action.payload.temporaryId]: temporaryEntry, ...otherEntries } = state;
            return {
                ...otherEntries,
                [action.payload.entry.id]: {
                    ...action.payload.entry,
                    entry_type: EntryType.TIME,
                },
            };
        case removeTimeEntry.successType: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [action.payload]: removedEntry, ...newState } = state;
            return newState;
        }
        default:
            return state;
    }
}

export function statuses(state: Array<IStatus> = initialState.statuses, action: TimeSheetActions): Array<IStatus> {
    switch (action.type) {
        case getTimeStatuses.successType:
            return action.payload;
        default:
            return state;
    }
}

export const time = combineReducers({
    sheetsById,
    entriesById,
    isSheetsLoading,
    statuses,
    timeSheetIsPatching,
});
