import { call, put, takeLatest } from 'typed-redux-saga';
import { getDealTypes } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import baseApi from 'shared/utils/baseApi';

import { createDealType } from 'modules/settings/submodules/clients/dealType/store/actions';
import { ICreateDealTypeRequest } from 'modules/settings/submodules/clients/dealType/store/models';
import { api } from 'modules/settings/submodules/clients/dealType/store/api';

function* createDealTypeSaga(action: ReturnType<typeof createDealType.init>) {
    const { payload } = action;
    const request = {
        ...payload,
        client_id: baseApi.clientId,
    } as ICreateDealTypeRequest;

    const result = yield* call(api.createDealType, request);
    yield put(createDealType.success(result));
    yield put(getDealTypes.success([result]));
}

export function* createDealTypeWatcher() {
    yield* takeLatest(
        createDealType.initType,
        withBackendErrorHandler(
            createDealTypeSaga,
            createDealType.error,
            'Unable to create Deal Type.',
        ),
    );
}
