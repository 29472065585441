import { combineReducers, StateFromReducersMapObject } from 'redux';
import { isLoadingReducer, withMessageReducer } from 'store/reducerUtils';
import {
    newPasswordAfterResetting,
    passwordResetting,
    newPasswordCodeChecking,
    passwordResettingClearMessage,
} from './actions';

export const initialState = {
    resetPassword: false,
    newPassword: false,
    newPasswordCode: false,
    resetPasswordResult: null,
    newPasswordResult: null,
    codeCheckingResult: null,
};

export const resetPassword = isLoadingReducer(passwordResetting, initialState.resetPassword);
export const newPassword = isLoadingReducer(newPasswordAfterResetting, initialState.newPassword);
export const newPasswordCode = isLoadingReducer(newPasswordCodeChecking, initialState.newPasswordCode);

export const resetPasswordResult = withMessageReducer(
    passwordResetting,
    initialState.resetPasswordResult,
    passwordResettingClearMessage.action,
);
export const newPasswordResult = withMessageReducer(
    newPasswordAfterResetting,
    initialState.newPasswordResult,
    passwordResettingClearMessage.action,
);
export const codeCheckingResult = withMessageReducer(
    newPasswordCodeChecking,
    initialState.codeCheckingResult,
    passwordResettingClearMessage.action,
);

const resettingPasswordReducers = {
    resetPassword,
    newPassword,
    newPasswordCode,
    resetPasswordResult,
    newPasswordResult,
    codeCheckingResult,
};

export type ResettingPasswordState = StateFromReducersMapObject<typeof resettingPasswordReducers>;

export const resettingPassword = combineReducers(resettingPasswordReducers);
