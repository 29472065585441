import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectSubassignmentsIsLoading } from 'store/entities/configuration/configurationSelectors';
import { selectIsLoadingCustomFieldsHierarchyNodes } from 'store/entities/customFields/selectors';

export const selectWorkingSheetsFilters = (state: IStore) => state.components.sheetsGrid.filters;
export const selectWorkingSheetFiltersConfig = (state: IStore) => {
    const clientId = selectCurrentClientId(state) ?? '';
    return state.clients.fieldsConfigurationByClientId[clientId]?.filters?.employee_entries || {};
};
export const selectIsInitialLoadingTimeAndExpense = createSelector(
    selectSubassignmentsIsLoading,
    selectIsLoadingCustomFieldsHierarchyNodes,
    (...args) => args.some(Boolean),
);
