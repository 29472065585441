import { showAssignmentStartDateChangeDialog } from '../../AssignmentStartDateChange/store/reducer';
import { setDetailAssignmentId } from 'modules/subassignmentManagement/store/actions';
import { getOfferLetters } from 'modules/offerLetter/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const detailId = singleValueReducer(setDetailAssignmentId.action, null);
const isLoading = isLoadingReducer(getOfferLetters);

export const assignmentInfoCard = combineReducers({
    detailId,
    isLoading,
    showAssignmentStartDateChangeDialog,
});
