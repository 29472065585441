import { getNotifications, updateNotifications } from 'modules/notificationCenter/store/actions';
import { notificationApi } from 'modules/notificationCenter/store/api';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';

export function* getNotificationsSaga() {
    const notifications = yield* call(notificationApi.getNotifications);
    yield put(getNotifications.success(notifications));
}

function* getNotificationsWatcher() {
    yield takeLatest(
        getNotifications.initType,
        withBackendErrorHandler(
            getNotificationsSaga,
            getNotifications.error,
            'Unable to fetch notifications.',
            false,
        ),
    );
}

export function* updateNotificationsSaga({ payload }: ReturnType<typeof updateNotifications.init>) {
    const updatedNotifications = yield* call(notificationApi.updateNotifications, payload);
    yield put(updateNotifications.success(updatedNotifications));
}

function* updateNotificationsWatcher() {
    yield takeLatest(
        updateNotifications.initType,
        withBackendErrorHandler(
            updateNotificationsSaga,
            updateNotifications.error,
            'Unable to update notifications.',
        ),
    );
}

export default [
    getNotificationsWatcher,
    updateNotificationsWatcher,
];
