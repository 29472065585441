import { Box } from '@material-ui/core';
import React from 'react';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormField from 'shared/components/formFields/FormField';

type FormFieldWithSeparateLabelProps<FieldProps extends IFormFieldProps> = FieldProps & {
    outerLabel?: string;
    labelClassName?: string;
    containerClassName?: string;
    classes?: Partial<Record<'input', string>>;
    FieldComponent?: React.ComponentType<FieldProps>
    childLabel?: string;
}

export function FormFieldWithSeparateLabel<FieldPropType extends IFormFieldProps>({
    outerLabel,
    classes,
    FieldComponent,
    labelClassName,
    containerClassName,
    childLabel,
    ...props
}: FormFieldWithSeparateLabelProps<FieldPropType>) {
    const defaultClasses = useSettingsFormStyles();
    const customClasses = {
        ...defaultClasses,
        ...classes,
    };
    const Field = FieldComponent || FormField;
    return (
        <Box>
            <Box
                display="flex"
                alignItems="baseline"
                justifyContent="flex-start"
                className={containerClassName}
            >
                <label className={labelClassName}>{ outerLabel }</label>
                <Field
                    className={customClasses.input}
                    {...props as FieldPropType}
                />
            </Box>
            { childLabel !== undefined ? (
                <Box component="span" ml={2}
                    fontSize="small">
                    {childLabel}
                </Box>
            ) : undefined }
        </Box>
    );
}
