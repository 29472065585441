import { Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import {
    useSettingsFormGridStyles,
} from 'modules/settings/submodules/components/sharedStyles/formStyles';
import ClientRoleSelect
    from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/ClientRoleSelect';
import {
    defaultUserClientAssociationValues,
    IUserClientAssociationFormValues,
} from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/model';
import {
    userClientAssociationValidationsSchema,
} from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/ValidationsSchema';
import { createClientUsersAssociation } from 'modules/settings/submodules/platformUsers/store/actions';
import { selectIsClientUsersAssociationsCreating } from 'modules/settings/submodules/platformUsers/store/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    UserAutocompleteWithSearch,
} from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { UserType } from 'store/entities/users/model';

export const CreateUserClientAssociationModal = ({ title, isOpened, onClose }: Omit<IModalProps, 'children'>) => {
    const modalClasses = useColoredFormModalStyles();
    const classes = useSettingsFormGridStyles();

    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsClientUsersAssociationsCreating);
    const onSubmit = useCallback((
        values: IUserClientAssociationFormValues,
    ) => {
        dispatch(createClientUsersAssociation.init({
            user_id: values.user?.id || '',
            client_id: values.client?.id || process.env.REACT_APP_CLIENT_ID || '',
            client_role_id: values.role?.id || '',
        }));
    }, [dispatch]);
    const formikData = useFormik<IUserClientAssociationFormValues>({
        initialValues: defaultUserClientAssociationValues,
        validationSchema: userClientAssociationValidationsSchema,
        onSubmit,
    });
    const { handleSubmit, values } = formikData;
    const selectedClient = values?.client?.id;

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <FormikProvider value={formikData}>
                <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
                    <Grid container spacing={2}>
                        <Grid item sm={4}>
                            <UserAutocompleteWithSearch
                                additionalFilter={{
                                    user_type: UserType.Manager,
                                }}
                                name="user"
                                label="Search user"
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <ClientSelect
                                name="client"
                                label="Client"
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <ClientRoleSelect
                                name="role"
                                label="Role"
                                title={selectedClient ? '' : 'Select Client first'}
                                disabled={!selectedClient}
                                clientId={selectedClient}
                                blockNameList={['Employee']}
                                className={classes.input}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <ButtonWithLoader
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disabled={isLoading}
                            isLoading={isLoading}
                            data-testid="submit_update_button"
                        >
                            Create
                        </ButtonWithLoader>
                    </Box>
                </form>
            </FormikProvider>
        </ModalDialog>
    );
};
