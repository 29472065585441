import React from 'react';
import { useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { useManagersCells } from 'modules/users/managers/components/UserRolesTable/cells';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { getClientManagersAction } from 'modules/users/managers/store/actions';
import { selectManagersIsLoading, selectManagersRows } from 'modules/users/managers/store/selectors';

export const ManagersTable = () => {
    useWithClientEffect(dispatch => {
        dispatch(getClientManagersAction.init());
    });
    const tableClasses = useDefaultTableStyles();
    const cells = useManagersCells();
    const isLoading = useSelector(selectManagersIsLoading);
    const rows = useSelector(selectManagersRows);

    return (
        <GridTable
            rowData={rows}
            getKey={row => row.id}
            cells={cells}
            isLoading={isLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            stickyHeader
        />
    );
};
