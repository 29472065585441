import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const useTotalStyles = makeHighPriorityStyles((theme: Theme) => ({
    totalCell: {
        backgroundColor: colors.lightGray,
    },
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'flex-end',
    },
    divider: {
        backgroundColor: `${colors.lightGray} !important`,
    },
    total: {
        textAlign: 'right',
        fontSize: 20,
        lineHeight: `${theme.spacing(2.5)}px`,
        textTransform: 'inherit',
        fontWeight: 600,
        paddingRight: theme.spacing(0.5),
        width: '100%',
        letterSpacing: 'normal',
    },
    label: {
        textAlign: 'right',
        fontSize: 12,
        lineHeight: '14px',
        textTransform: 'none',
        fontWeight: 'normal',
        paddingLeft: 0,
        letterSpacing: 'normal',
    },

}));

export default useTotalStyles;
