import { IClientUserAssociationRequest } from 'modules/settings/submodules/platformUsers/store/models';
import {
    IClientRole,
    IClientRoleRequest,
    IGetPaidTimeOffRequest,
    IGlobalRole,
    IPaidTimeOffPayload,
    IUserInfo,
    IUserInfoWithClientRole,
} from 'shared/models/User';
import {
    GetSpecialUsersRequest,
    IClientUserAssociation,
    IGetUserRequest,
    IUserAuthInfo,
    IUserLookupRequest,
} from 'store/entities/users/model';
import {
    ActionsReturnTypes,
    createActions,
    createRequestActions,
    createSingleAction,
    RequestType,
} from '../../utils';

/**
 * Get Users
 */

export const getUsers = createRequestActions<void | IGetUserRequest, IUserInfo[]>(
    RequestType.Get,
    'USERS',
);

export const getUsersWithClientRole = createRequestActions<
void | IGetUserRequest,
IUserInfoWithClientRole>(
    RequestType.Get,
    'USERS_WITH_CLIENT_ROLE',
);

/**
 * LOOKUP
 */

const LOOKUP = 'users/LOOKUP';
const LOOKUP_SUCCESS = 'users/LOOKUP_SUCCESS';
const LOOKUP_ERROR = 'users/LOOKUP_ERROR';

export const lookupUsers = createActions<
IUserLookupRequest,
IUserInfo[],
any,
typeof LOOKUP,
typeof LOOKUP_SUCCESS,
typeof LOOKUP_ERROR
>(
    LOOKUP,
    LOOKUP_SUCCESS,
    LOOKUP_ERROR,
);

/**
 * Get special users
 */

const GET_SPECIAL_USERS = 'users/GET_SPECIAL_USERS';
const GET_SPECIAL_USERS_SUCCESS = 'users/GET_SPECIAL_USERS_SUCCESS';
const GET_SPECIAL_USERS_ERROR = 'users/GET_SPECIAL_USERS_ERROR';

export const getSpecialUsersAction = createActions<
GetSpecialUsersRequest,
{
    request: GetSpecialUsersRequest;
    result: IUserInfo[];
},
any,
typeof GET_SPECIAL_USERS,
typeof GET_SPECIAL_USERS_SUCCESS,
typeof GET_SPECIAL_USERS_ERROR
>(
    GET_SPECIAL_USERS,
    GET_SPECIAL_USERS_SUCCESS,
    GET_SPECIAL_USERS_ERROR,
);

/**
 * LOOKUP
 */

export interface IUserSearchInitPayload {
    searchKey: string;
    request: IGetUserRequest;
}
export interface IUserSearchSuccessPayload {
    searchKey: string;
    userIds: string[];
}
export interface IUserSearchErrorPayload {
    searchKey: string;
    error: any;
}

const SEARCH_USER = 'users/SEARCH_USER';
const SEARCH_USER_SUCCESS = 'users/SEARCH_USER_SUCCESS';
const SEARCH_USER_ERROR = 'users/SEARCH_USER_ERROR';

export const searchUsers = createActions<
IUserSearchInitPayload,
IUserSearchSuccessPayload,
IUserSearchErrorPayload,
typeof SEARCH_USER,
typeof SEARCH_USER_SUCCESS,
typeof SEARCH_USER_ERROR
>(
    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_ERROR,
);

export const RESET_SEARCH_USER = 'users/RESET_SEARCH_USER';
export const resetSearchUser = createSingleAction<string, typeof RESET_SEARCH_USER>(RESET_SEARCH_USER);

/**
 * LOAD_GLOBAL_ROLES
 */

const LOAD_GLOBAL_ROLES = 'users/LOAD_GLOBAL_ROLES';
const LOAD_GLOBAL_ROLES_SUCCESS = 'users/LOAD_GLOBAL_ROLES_SUCCESS';
const LOAD_GLOBAL_ROLES_ERROR = 'users/LOAD_GLOBAL_ROLES_ERROR';

export const loadGlobalRoles = createActions<
void,
Array<IGlobalRole>,
any,
typeof LOAD_GLOBAL_ROLES,
typeof LOAD_GLOBAL_ROLES_SUCCESS,
typeof LOAD_GLOBAL_ROLES_ERROR
>(
    LOAD_GLOBAL_ROLES,
    LOAD_GLOBAL_ROLES_SUCCESS,
    LOAD_GLOBAL_ROLES_ERROR,
);

/**
 * LOAD_CLIENT_ROLES
 */

const LOAD_CLIENT_ROLES = 'users/LOAD_CLIENT_ROLES';
const LOAD_CLIENT_ROLES_SUCCESS = 'users/LOAD_CLIENT_ROLES_SUCCESS';
const LOAD_CLIENT_ROLES_ERROR = 'users/LOAD_CLIENT_ROLES_ERROR';

export const loadClientRoles = createActions<
void | IClientRoleRequest,
Array<IClientRole>,
any,
typeof LOAD_CLIENT_ROLES,
typeof LOAD_CLIENT_ROLES_SUCCESS,
typeof LOAD_CLIENT_ROLES_ERROR
>(
    LOAD_CLIENT_ROLES,
    LOAD_CLIENT_ROLES_SUCCESS,
    LOAD_CLIENT_ROLES_ERROR,
);

export const getPaidTimeOff = createRequestActions<IGetPaidTimeOffRequest, IPaidTimeOffPayload>(
    RequestType.Get,
    'PaidTimeOff',
);

export const getClientUserRoles = createRequestActions<IClientUserAssociationRequest, IClientUserAssociation[]>(
    RequestType.Get,
    'USERS_CLIENT_ROLES',
);

export const getUserAuthStats = createRequestActions<string, IUserAuthInfo>(
    RequestType.Get,
    'USER_AUTH_STATS',
    'settings',
);

export type UsersActions =
    | ActionsReturnTypes<typeof getUsers>
    | ActionsReturnTypes<typeof lookupUsers>
    | ActionsReturnTypes<typeof searchUsers>
    | ActionsReturnTypes<typeof loadGlobalRoles>
    | ActionsReturnTypes<typeof loadClientRoles>
    | ActionsReturnTypes<typeof getSpecialUsersAction>
    | ActionsReturnTypes<typeof getPaidTimeOff>
    | ActionsReturnTypes<typeof getUsersWithClientRole>;
