import { IEntity } from 'shared/models/Entity';
import { IStatusEntity } from 'shared/models/Status';
import { Nullable } from 'types/types';
import { Permission } from 'store/components/auth/authModels';
import { SheetStatusSlug, StatusNames } from 'store/entities/timesheet/models/Status';
import { IEntryAttachment } from '../Attachments';

export interface IStatus extends IStatusEntity<SheetStatusSlug, StatusNames> {
    client_id: string;
    is_default: boolean;
    is_entries_can_be_changed: boolean;
}

export enum EntryType {
    TIME = 'time',
    EXPENSE = 'expense'
}

export interface ISheetEntry {
    sheet_entry_type: EntryType;
    sheet_entries: IEntryCommonBackend[];
}

export interface INoteBackend {
    created_at: string;
    notes: string;
}

export interface ISheetApproval extends IEntity {
    sheet_id: string;
    user_id: string;
    level: number;
    created_at: string;
    updated_at: string;
}

export interface ISheetCommonBackend {
    id: string;
    status: IStatus;
    user_id: string;
    client_id: string;
    job_number_id: string | null;
    area_id: number | null;
    total_minutes: number;
    total_hours: string; //after prism rounded total_minutes
    total_break_minutes?: number;
    total_ot_minutes?: number;
    total_dt_minutes?: number;
    total_dollars: string;
    total_miles?: number;
    total_files?: number;
    subassignment_id?: string;
    assignment_id?: string;
    notes: INoteBackend[];
    prism_batch_id?: string;
    approved_level: number;
    updated_at?: string;
    // @deprecated use period_end and period_start
    weekend?: string;
    period_start: string;
    period_end: string;
    sheet_group_id: string;
    submitted_at: string | null;
    is_holiday: Nullable<boolean>;
    approvals: ISheetApproval[];
}

interface IExpenseSpecificFields {
    has_receipts: boolean;
}

interface IExpenseSheetWithEntries extends ISheetCommonBackend {
    entries: IExpenseEntryBackend[];
    approvals: ISheetApproval[];
}

export type IExpenseSheetBackend = IExpenseSheetWithEntries & IExpenseSpecificFields;

export interface ITimeSheetBackend extends ISheetCommonBackend {
    entries: Array<ITimeEntryBackend>;
}

export type ISheetBackend = IExpenseSheetBackend | ITimeSheetBackend;

export interface IExpenseSheet extends ISheetCommonBackend {
    has_receipts: boolean;
    entry_type: EntryType.EXPENSE;
}

export interface ITimeSheet extends ISheetCommonBackend {
    entry_type: EntryType.TIME;
}

export type ISheet = IExpenseSheet | ITimeSheet;

export interface IEntryBaseBackend {
    id: string;
    created_at: string;
    updated_at: string;
    notes: string | undefined;
    entry_date: string;
    task_id: string;
    activity_id: string;
    assignment_id: string;
    project_id: string | undefined;
    sheet_id: string;
    position_id: string | null;
    location_id: string | null;
    department_id: string | undefined;
    job_number_id?: string | null;
    status_id: string;
    is_per_diem?: boolean,
    is_holiday_time?: boolean,
    custom_field_value_ids?: string[];
    processed_in_ecub_at?: string;
}

export interface IEntryCommonBackend extends IEntryBaseBackend{
    data: TEntryData;
}

export interface IExpenseEntryBackend extends IEntryCommonBackend {
    sheet_entry_attachments: IEntryAttachment[];
    zip_code: IZipCodeFull;
}

export interface ITimeEntryBackend extends IEntryCommonBackend {
    sca_zone: IScaZoneFull | null;
}

export type IEntryBackend = IExpenseEntryBackend | ITimeEntryBackend;

export interface ITypedEntry extends IEntryCommonBackend {
    entry_type: EntryType;
}

export interface ITimeEntry extends ITimeEntryBackend, ITypedEntry {
    entry_type: EntryType.TIME;
}

export interface ITypedEntryTemporary extends ITypedEntry {
    temporaryId: string;
}

export interface IExpenseEntry extends IExpenseEntryBackend, ITypedEntry {
    entry_type: EntryType.EXPENSE;
}

export type IEntry = ITimeEntry | IExpenseEntry;

export enum QuantityType {
    ODOMETER = 'odometer',
    UNITS = 'units',
    TIME = 'time',
    DAYS = 'days',
    MONEY = 'money',
    MILES = 'miles',
    TIME_BREAK = 'time_break',
    TIME_IN_OUT = 'time_in_out',
    TIME_IN_OUT_BREAK = 'time_in_out_break',
    TIME_IN_OUT_MEAL_BREAK = 'time_in_out_meal_break',
    FILE = 'files',
}

export const QuantityTypeLabelMap = {
    [QuantityType.ODOMETER]: 'Odometer',
    [QuantityType.UNITS]: 'Units',
    [QuantityType.TIME]: 'Time',
    [QuantityType.DAYS]: 'Days',
    [QuantityType.MONEY]: 'Money',
    [QuantityType.MILES]: 'Miles',
    [QuantityType.TIME_BREAK]: 'Unpaid Break Time',
    [QuantityType.TIME_IN_OUT]: 'Time In/Out',
    [QuantityType.TIME_IN_OUT_BREAK]: 'Time In/Out with Break',
    [QuantityType.TIME_IN_OUT_MEAL_BREAK]: 'Time In/Out with Meal Break',
    [QuantityType.FILE]: 'File',
};

export type TimeRFC3339 = string; // TODO: implement RFC-3339
export type TimeHoursMinutes = string; // TODO: implement h:mm

export interface IEntryData {
    entry_type: QuantityType;
}

export interface ITimeEntryData extends IEntryData {
    entry_type: QuantityType.TIME;
    hours: number;
    minutes: number;
}

export interface IOdometerEntryData extends IEntryData {
    entry_type: QuantityType.ODOMETER;
    miles_start: number;
    miles_end: number;
}

export interface IAmountEntryData extends IEntryData {
    entry_type: QuantityType.MONEY;
    dollars: string;
}

export interface IMilesEntryData extends IEntryData {
    entry_type: QuantityType.MILES;
    miles: number;
}

export interface IInOutEntryData extends IEntryData {
    entry_type: QuantityType.TIME_IN_OUT;
    timeIn: TimeHoursMinutes;
    timeOut: TimeHoursMinutes | null;
}

export interface IBreakEntryData extends IEntryData {
    entry_type: QuantityType.TIME_BREAK;
    timeIn: TimeHoursMinutes;
    timeOut: TimeHoursMinutes | null;
}

export interface IInOutBreakEntryData extends IEntryData {
    entry_type: QuantityType.TIME_IN_OUT_BREAK;
    timeIn: TimeHoursMinutes;
    timeOut: TimeHoursMinutes | null;
    hoursBreak: number;
    minutesBreak: number;
}

export interface IInOutMealBreakEntryData extends IEntryData {
    entry_type: QuantityType.TIME_IN_OUT_MEAL_BREAK;
    timeIn: TimeHoursMinutes;
    timeOut: TimeHoursMinutes | null;
    breakTimeIn: TimeHoursMinutes | null;
    breakTimeOut: TimeHoursMinutes | null;
}

export interface IFileEntryData extends IEntryData {
    entry_type: QuantityType.FILE;
    files: number;
}

export interface IInOutEntryDataBackend extends IEntryData {
    entry_type: QuantityType.TIME_IN_OUT;
    time_in: TimeRFC3339;
    time_out: TimeRFC3339 | null;
}

export interface IBreakEntryDataBackend extends IEntryData {
    entry_type: QuantityType.TIME_BREAK;
    time_in: TimeRFC3339;
    time_out: TimeRFC3339 | null;
}

export interface ITimeEntryDataBackend extends IEntryData {
    entry_type: QuantityType.TIME;
    hours: number;
    minutes: number;
}

export interface IInOutBreakDataBackend extends IEntryData {
    entry_type: QuantityType.TIME_IN_OUT_BREAK;
    time_in: TimeRFC3339;
    time_out: TimeRFC3339 | null;
    break_minutes: number;
}

export interface IInOutMealBreakDataBackend extends IEntryData {
    entry_type: QuantityType.TIME_IN_OUT_MEAL_BREAK;
    time_in: TimeRFC3339;
    time_out: TimeRFC3339 | null;
    break_time_in: TimeRFC3339 | null;
    break_time_out: TimeRFC3339 | null;
}

export interface IFileEntryDataBackend extends IEntryData {
    entry_type: QuantityType.FILE;
    files: number;
}

export type TimeEntryData =
    | ITimeEntryData
    | IInOutEntryData
    | IBreakEntryData
    | IInOutBreakEntryData
    | IInOutMealBreakEntryData
    | IFileEntryData;
export type TimeEntryDataBackend =
    | ITimeEntryDataBackend
    | IInOutEntryDataBackend
    | IBreakEntryDataBackend
    | IInOutBreakDataBackend
    | IInOutMealBreakDataBackend
    | IFileEntryDataBackend;
export type InOutEntryData =
    | IInOutEntryDataBackend
    | IBreakEntryDataBackend
    | IInOutBreakDataBackend
    | IInOutMealBreakDataBackend;
export type TExpenseEntryData = IAmountEntryData | IOdometerEntryData | IMilesEntryData;
export type TEntryData = TimeEntryDataBackend | TExpenseEntryData;

export interface IScaZoneFull {
    sca_zone_id: number;
    county_id: string;
    county_name: string;
    state_id: string;
    state_short_name: string;
}

export interface IZipCodeFull {
    state_id: string;
    state_short_name: string;
    zip_code: string;
}

export interface ISheetClickInfo {
    clientId?: string;
    sheetId: string;
    entryType: EntryType;
    permission: Permission;
}

export interface ISheetStatusesId {
    timeStatusId?: string;
    expenseStatusId?: string;
}

export interface ISheetLog {
    sheetId: string;
    actorId: string;
    timestamp: string;
}
