import { ISheetInfoPureProps, SheetInfoPure } from 'shared/components/sidebars/SheetDetail/SheetInfoPure';
import { useSheetDetail } from 'shared/utils/formatters/sheetDetail';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { DetailSlugs, IDetailConfiguration } from 'store/entities/clients/clientsModel';

interface ISheetInfoProps {
    sheetId: string;
    configuration: IDetailConfiguration<DetailSlugs>[];
    showPTO?: boolean;
}

const SheetInfo = separateLogicDecorator<ISheetInfoProps, ISheetInfoPureProps>(
    ({ sheetId, configuration, showPTO }: ISheetInfoProps) => {
        const data = useSheetDetail(sheetId, configuration, showPTO);
        return { data };
    },
)(SheetInfoPure);

export default SheetInfo;
