import { fade, Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const fadeCoefficient = 0.3;

export const useStatusStyles = makeHighPriorityStyles((theme: Theme) => ({
    statusElement: {
        width: '100%',
        height: '23px',
        lineHeight: '20px',
        padding: theme.spacing(0.25, 1.5),
        boxSizing: 'border-box',
        borderRadius: theme.spacing(0.5),
        fontSize: '12px',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
        minWidth: 95,

        '&:hover': {
            overflow: 'visible',
            whiteSpace: 'initial',
        },
    },
    blue: {
        backgroundColor: fade(colors.blue, fadeCoefficient),
    },
    red: {
        backgroundColor: fade(colors.red, fadeCoefficient),
    },
    lightGreen: {
        backgroundColor: colors.lightGreen,
    },
    lightGray: {
        backgroundColor: colors.lightGray,
    },
    orange: {
        backgroundColor: fade(colors.orange, fadeCoefficient),
    },
    brown: {
        backgroundColor: `rgba(232, 156, 48, 0.3)`,
    },
}));
