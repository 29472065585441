import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { WorkOutlined } from '@material-ui/icons';
import { FinishOnboardingButton } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/FinishOnboardingConfirmation/FinishOnboardingButton';
import { OnboardingHeader } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingHeader';
import { selectIsSSOLikLoading, selectSSOLink } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import { useOfferLetterStyles } from 'modules/offerLetter/OfferLetterStyles';
import { getPrismSSOLink } from 'modules/offerLetter/store/actions';
import { IOfferLetter } from 'modules/offerLetter/store/model';
import { routes } from 'shared/routes';
import { isIOs, isSafari } from 'utils/envCheck';
import { thirdPartyCookiesCheck } from 'utils/thirdPartyCookiesCheck';
import { navigateIframedHost } from '../../helpers/navigateIframedHost';
import { useIsMobile } from '../../../../shared/utils/hooks/media';
import { v4 as uuidv4 } from 'uuid';

export interface IOnboardingStep {
    clientId: string;
    offerLetterId: string;
    offerLetter: IOfferLetter;
}

export const OnboardingStep = ({ clientId, offerLetterId }: IOnboardingStep) => {
    const isMobile = useIsMobile();
    const classes = useOfferLetterStyles({ isMobile });
    const dispatch = useDispatch();
    const [iframeLoading, setIframeLoading] = useState(true);
    const isIframeDisable = isIOs && isSafari;
    const [cookiesEnabled, setCookiesEnabled] = useState(!isIframeDisable);
    const isSSOLinkLoading = useSelector(selectIsSSOLikLoading);
    const isLoading = iframeLoading || isSSOLinkLoading;
    const link = useSelector(selectSSOLink);

    const frameHash = useMemo(() => {
        return uuidv4();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, offerLetterId, link]);

    useEffect(() => {
        dispatch(getPrismSSOLink.init(clientId));
    }, [dispatch, clientId, offerLetterId]);

    useEffect(() => {
        if (!isIframeDisable) {
            thirdPartyCookiesCheck().then(({ supported }) => {
                setCookiesEnabled(supported);
            });
        }
    }, [setCookiesEnabled, isIframeDisable]);

    const onLoad = (evt: React.SyntheticEvent) => {
        navigateIframedHost(routes.EMPLOYEE_OFFER_LETTER.ROOT)(evt);
        setIframeLoading(false);
    };

    return (
        <Box className={classes.onboardingContainer}>
            <OnboardingHeader
                icon={<WorkOutlined fontSize="small" className={classes.headerIcon}/>}
                title="3. Complete Onboarding"
            >
                <FinishOnboardingButton offerLetterId={offerLetterId}/>
            </OnboardingHeader>
            {cookiesEnabled ? (
                <>
                    {isLoading && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            height="100%"
                            flexGrow={1}
                        >
                            <CircularProgress/>
                        </Box>
                    )}
                    {link && (
                        <Box className={classes.onboardingIframe}>
                            <iframe
                                src={link}
                                title="Onboarding"
                                name={`Onboarding-${frameHash}`}
                                frameBorder="0"
                                height={isLoading ? 0 : '100%'}
                                width="100%"
                                onLoad={onLoad}
                            />
                        </Box>
                    )}
                </>
            ) : (
                <Box className={classes.noCookieFrame}>
                    <Box mt={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={ link }>
                            Complete Onboarding
                        </Button>
                    </Box>
                </Box>
            )}
            {isMobile && (
                <Box
                    display="flex"
                    alignItems="center"
                    className={classes.actionButtonsWrapper}
                >
                    <FinishOnboardingButton offerLetterId={offerLetterId}/>
                </Box>
            )}
        </Box>
    );
};
