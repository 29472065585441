import { IEmployeeManagementFilter } from 'modules/employmentManagement/components/store/model';
import {
    IBulkCreateSubassignmentResponseV2,
    IBulkCreateSubassignmentsRequest,
    IBulkCreateSubassignmentsResponse,
    IBulkDeleteSubassignmentsResponse,
    IBulkEditSubassignmentPayloadV2,
    IBulkEditSubassignmentResponseV2,
    IBulkEditSubassignmentsResponse,
    IEditSubassignmentRequestModel,
    ISubAssignmentBulkDeletePayload,
    ISubAssignmentBulkEditPayload,
    ISubassignmentPagePayload,
} from 'modules/subassignmentManagement/store/models';
import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';

export const EMPLOYMENT_MANAGEMENT_PREFIX = 'employment_management';

const SET_SUBASSIGNMENT_FILTER = `${EMPLOYMENT_MANAGEMENT_PREFIX}/SET_SUBASSIGNMENT_FILTER`;
export const setSubassignmentFilters = createSingleAction<IEmployeeManagementFilter, typeof SET_SUBASSIGNMENT_FILTER>(
    SET_SUBASSIGNMENT_FILTER,
);

const SET_HIGHLIGHTED_ASSIGNMENT_IDS = `${EMPLOYMENT_MANAGEMENT_PREFIX}/SET_HIGHLIGHTED_ASSIGNMENT_IDS`;
export const setHighlightedAssignmentIds = createSingleAction<string[], typeof SET_HIGHLIGHTED_ASSIGNMENT_IDS>(
    SET_HIGHLIGHTED_ASSIGNMENT_IDS,
);

const INITIAL_LOAD_SUBASSIGNMENTS = `${EMPLOYMENT_MANAGEMENT_PREFIX}/INITIAL_LOAD_SUBASSIGNMENTS`;
export const initialLoadSubassignments = createSingleAction<void, typeof INITIAL_LOAD_SUBASSIGNMENTS>(
    INITIAL_LOAD_SUBASSIGNMENTS,
);

const RESET_SUBASSIGNMENTS_TABLE = `${EMPLOYMENT_MANAGEMENT_PREFIX}/RESET_SUBASSIGNMENTS_TABLE`;
export const resetSubassignmentsTable = createSingleAction<void, typeof RESET_SUBASSIGNMENTS_TABLE>(
    RESET_SUBASSIGNMENTS_TABLE,
);

export const getMoreSubassignmentsAction = createRequestActions<void, ISubassignmentPagePayload>(
    RequestType.Get,
    'MORE_SUBASSIGNMENTS',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

const SET_DETAIL_ASSIGNMENT_ID = `${EMPLOYMENT_MANAGEMENT_PREFIX}/SET_DETAIL_ASSIGNMENT_ID`;
export const setDetailAssignmentId = createSingleAction<string | null, typeof SET_DETAIL_ASSIGNMENT_ID>(
    SET_DETAIL_ASSIGNMENT_ID,
);

const SET_EXPAND_ALL_SUBASSIGNMENTS = `${EMPLOYMENT_MANAGEMENT_PREFIX}/SET_EXPAND_ALL_SUBASSIGNMENTS`;
export const setExpandAllSubassignments = createSingleAction<boolean, typeof SET_EXPAND_ALL_SUBASSIGNMENTS>(
    SET_EXPAND_ALL_SUBASSIGNMENTS,
);

/**
 * Bulk create v1
 */
export const bulkCreateSubAssignmentAction = createRequestActions<
IBulkCreateSubassignmentsRequest,
IBulkCreateSubassignmentsResponse
>(
    RequestType.Create,
    'BULK_SUBASSIGNMENTS',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export const bulkCreateSubAssignmentPreInitializeAction = createRequestActions<
IBulkCreateSubassignmentsRequest,
IBulkCreateSubassignmentsResponse
>(
    RequestType.Create,
    'BULK_SUBASSIGNMENTS_PRE_INITIALIZE',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

/**
 * Bulk create v2
 */
export const bulkCreateSubAssignmentActionV2 = createRequestActions<
IBulkCreateSubassignmentsRequest,
IBulkCreateSubassignmentResponseV2
>(
    RequestType.Create,
    'BULK_SUBASSIGNMENTS_V2',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);
export const bulkCreateSubAssignmentPreInitializeActionV2 = createRequestActions<
IBulkCreateSubassignmentsRequest,
IBulkCreateSubassignmentResponseV2
>(
    RequestType.Create,
    'BULK_SUBASSIGNMENTS_PRE_INITIALIZE_V2',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);
export const getPreInitializeCreateSubAssignmentPdfAction = createRequestActions<
IBulkCreateSubassignmentsRequest,
void
>(
    RequestType.Create,
    'BULK_SUBASSIGNMENTS_PRE_INITIALIZE_PDF',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);
export const downloadFailedCsvCreateSubAssignmentAction = createRequestActions<
IBulkCreateSubassignmentsRequest,
void
>(
    RequestType.Create,
    'BULK_SUBASSIGNMENTS_DOWNLOAD_FAILED_CSV',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

/***
 * Bulk create with upload file
 */
export const getCreateSubAssignmentCsvSampleAction = createRequestActions<
void,
void
>(
    RequestType.Get,
    'BULK_UPLOAD_SUBASSIGNMENTS_CSV_EXAMPLE',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);
export const bulkUploadCreateSubAssignmentAction = createRequestActions<
File,
IBulkCreateSubassignmentResponseV2
>(
    RequestType.Create,
    'BULK_UPLOAD_SUBASSIGNMENTS',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);
export const bulkUploadCreateSubAssignmentPreInitializeAction = createRequestActions<
File,
IBulkCreateSubassignmentResponseV2
>(
    RequestType.Create,
    'BULK_UPLOAD_SUBASSIGNMENTS_PRE_INITIALIZE',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);
export const getUploadPreInitializeCreateSubAssignmentPdfAction = createRequestActions<
File,
void
>(
    RequestType.Create,
    'BULK_UPLOAD_SUBASSIGNMENTS_PRE_INITIALIZE_PDF',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);
export const uploadCreateSubAssignmentAndDownloadFailedCsvAction = createRequestActions<
File,
void
>(
    RequestType.Create,
    'BULK_UPLOAD_SUBASSIGNMENTS_DOWNLOAD_FAILED_CSV',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

/**
 * Bulk edit
 */
export const bulkEditSubAssignmentAction = createRequestActions<
ISubAssignmentBulkEditPayload,
IBulkEditSubassignmentsResponse
>(
    RequestType.Update,
    'BULK_SUBASSIGNMENTS',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export const bulkEditSubAssignmentPreInitializeAction = createRequestActions<
ISubAssignmentBulkEditPayload,
IBulkEditSubassignmentsResponse
>(
    RequestType.Update,
    'BULK_SUBASSIGNMENTS_PRE_INITIALIZE',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export const bulkEditSubAssignmentPreInitializeActionV2 = createRequestActions<
IBulkEditSubassignmentPayloadV2,
void
>(
    RequestType.Update,
    'BULK_SUBASSIGNMENTS_PRE_INITIALIZE_V2',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export const getBulkEditSubAssignmentPreInitializePdfReport = createRequestActions<
IBulkEditSubassignmentPayloadV2,
void
>(
    RequestType.Get,
    'BULK_UPDATE_SUBASSIGNMENTS_PRE_INITIALIZE_PDF_REPORT_V2',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export const bulkEditSubAssignmentActionV2 = createRequestActions<
IBulkEditSubassignmentPayloadV2,
IBulkEditSubassignmentResponseV2
>(
    RequestType.Update,
    'BULK_SUBASSIGNMENTS_V2',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export const bulkDeleteSubAssignmentAction = createRequestActions<
ISubAssignmentBulkDeletePayload,
IBulkDeleteSubassignmentsResponse
>(
    RequestType.Delete,
    'BULK_SUBASSIGNMENTS',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export const bulkDeleteSubAssignmentPreInitializeAction = createRequestActions<
ISubAssignmentBulkDeletePayload,
IBulkDeleteSubassignmentsResponse
>(
    RequestType.Delete,
    'BULK_SUBASSIGNMENTS_PRE_INITIALIZE',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export const editSubAssignmentAction = createRequestActions<
IEditSubassignmentRequestModel,
void
>(
    RequestType.Update,
    'SUBASSIGNMENT',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);

export type EmploymentManagementActionsType =
    | ActionsReturnTypes<typeof getMoreSubassignmentsAction>
    | ActionsReturnTypes<typeof bulkCreateSubAssignmentAction>
    | ActionsReturnTypes<typeof bulkCreateSubAssignmentPreInitializeAction>
    | ReturnType<typeof setSubassignmentFilters>
    | ReturnType<typeof initialLoadSubassignments>;
