import { call, put, takeLatest } from 'typed-redux-saga';
import { EntryType, IExpenseSheetBackend, ISheetBackend, ITimeSheetBackend } from 'shared/models/sheet/Sheet';
import { timeApi } from 'store/entities/timesheet/api/timeApi';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';
import { getSheetsDetails } from 'store/components/sheetDetails/sheetDetailsActions';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';

function* getSheetDetailsSaga({ payload: sheetInfo }: ReturnType<typeof getSheetsDetails.init>) {
    let results: ISheetBackend[];
    if (sheetInfo.entryType === EntryType.TIME) {
        results = yield* call(
            timeApi.getSheetListByPurpose,
            sheetInfo.permission,
            { sheet_ids: sheetInfo.sheetIds },
        );
        yield* put(loadTimeSheetsWithEntries.success(results as ITimeSheetBackend[]));
    } else {
        results = yield* call(
            expenseApi.getSheetListByPurpose,
            sheetInfo.permission,
            { sheet_ids: sheetInfo.sheetIds },
        );
        yield* put(loadExpenseSheetsWithEntries.success(results as IExpenseSheetBackend[]));
    }

    yield* put(getSheetsDetails.success(results));
}

function* getSheetDetailsWatcher() {
    yield* takeLatest(getSheetsDetails.initType, getSheetDetailsSaga);
}

export default [
    getSheetDetailsWatcher,
];
