import { AnyAction, combineReducers } from 'redux';

import {
    SET_EDIT_SUBMITTING_ORG_ID, updateSubmittingOrg,
} from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/actions';
import { isLoadingReducer } from 'store/reducerUtils';

const submittingOrgId = (state = null, action: AnyAction): string | null => {
    switch (action.type) {
        case SET_EDIT_SUBMITTING_ORG_ID:
            return action.payload;
        case updateSubmittingOrg.successType:
            return null;
        default:
            return state;
    }
};

const isUpdating = isLoadingReducer(updateSubmittingOrg);

export const editSubmittingOrg = combineReducers({
    submittingOrgId,
    isUpdating,
});
