import { useBulkPreCreateCcpOverviewItems } from './store/hooks';
import { BulkCcpImportReport } from './BulkCcpImportReport';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoadingImport, selectIsLoadingPreInitializeImport } from './store/selectors';

interface IPreinitializeCcpCreationReportProps {
    onBack: () => void;
    onNextClick: () => void;
}

export const PreinitializeCcpCreateReport = ({
    onBack,
    onNextClick,
}: IPreinitializeCcpCreationReportProps) => {
    const overviewItems = useBulkPreCreateCcpOverviewItems();
    const isLoading = useSelector(selectIsLoadingPreInitializeImport);
    const isLoadingImport = useSelector(selectIsLoadingImport);
    const isLoadingData = isLoading || isLoadingImport;
    return (
        <BulkCcpImportReport
            onBack={onBack}
            handleAction={onNextClick}
            isLoading={isLoadingData}
            title="Pre-Initialize Roll Up"
            buttonTitle="Import"
            overviewItems={overviewItems}
        />
    );
};
