import React, { useCallback } from 'react';
import { setGroupedSheetsApprovalId, setGroupedSheetsRejectId } from 'modules/sheetApproval/store/actions';
import { selectApproveSheetGroupId, selectRejectSheetGroupId } from 'modules/sheetApproval/store/selectors';
import { useApprovalSelectors } from 'modules/timeAndExpense/store/hooks';
import { useDispatch, useSelector } from 'react-redux';
import SheetQuickAction from 'shared/components/sheetApproval/sheetQuickActions/SheetQuickAction';
import { EntryType } from 'shared/models/sheet/Sheet';

interface ISheetGroupQuickActionsProps {
    sheetType: EntryType;
}

export const SheetGroupQuickActions = ({
    sheetType,
}: ISheetGroupQuickActionsProps) => {
    const selectors = useApprovalSelectors(sheetType);
    const approveGroupId = useSelector(selectApproveSheetGroupId);
    const rejectGroupId = useSelector(selectRejectSheetGroupId);
    const groupId = approveGroupId || rejectGroupId;
    const group = useSelector(selectors.selectSheetGroupById(groupId));

    const dispatch = useDispatch();
    const onModalClose = useCallback(() => {
        dispatch(setGroupedSheetsApprovalId(null));
        dispatch(setGroupedSheetsRejectId(null));
    }, [dispatch]);

    return (
        <>
            {groupId && (
                <SheetQuickAction
                    sheetType={sheetType}
                    linkedUsers={group?.employee ? [group?.employee] : []}
                    sheets={group?.sheets}
                    isApprove={Boolean(approveGroupId)}
                    isOpened={Boolean(groupId)}
                    onClose={onModalClose}
                />
            )}
        </>
    );
};
