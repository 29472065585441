import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { setAssignUserModalJobNumberId } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/actions';
import {
    selectAssignUserJobNumberId,
} from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/selectors';
import { JobNumberAssignUser } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/JobNumberAssignUser';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const AssignUserModal = () => {
    const modalClasses = useColoredFormModalStyles();
    const dispatch = useDispatch();
    const jobNumberId = useSelector(selectAssignUserJobNumberId);

    const onClose = useCallback(() => {
        dispatch(setAssignUserModalJobNumberId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Assign User to Job Number"
            isOpened={Boolean(jobNumberId)}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <JobNumberAssignUser
                onClose={onClose}
            />
        </ModalDialog>
    );
};
