import { isArray, isObject, isString } from 'lodash-es';

const normalizeBackendFieldError = (backendError: any) => {
    const fieldKey = Object.keys(backendError)[0];
    if (fieldKey) {
        let fieldError = backendError[fieldKey];
        if (isArray(fieldError)) {
            fieldError = fieldError[0];
        }
        if (isString(fieldError)) {
            return `${fieldKey} - ${fieldError}`;
        }
    }
};

export const getBackendErrorOrDefaultMessage = (e: any, defaultMessage: string) => {
    const backendError = e.response?.data?.error?.message;
    if (backendError) {
        if (isString(backendError)) {
            return backendError;
        }
        if (isObject(backendError)) {
            const error = normalizeBackendFieldError(backendError);
            if (error) {
                return error;
            }
        }
    }
    return e.userMessage || defaultMessage;
};
