import { useSelector } from 'react-redux';
import { AvailableTotalConfiguration, ITotalConfiguration, TotalSlug } from 'store/entities/clients/clientsModel';
import {
    selectAllowFutureDayEntry,
    selectDisallowPastDayEntry,
    selectIsClientHasMealBreaks,
    selectIsPayRangesEnabledForClient,
} from 'store/entities/clients/selectors/configurationSelectors';
import {
    ITotalConfigurationByTotalSlug,
    selectTotalConfiguration,
} from 'store/entities/clients/selectors/fieldSelectors';
import { selectIsUserHasPermission } from '../../components/auth/selectors';
import { Permission } from '../../components/auth/authModels';

export const useAllowFutureDayEntry = () => {
    return useSelector(selectAllowFutureDayEntry);
};

export const useDisallowPastDayEntry = () => {
    const isManager = useSelector(selectIsUserHasPermission(Permission.EditSheetAsApprover));
    const isAdmin = useSelector(selectIsUserHasPermission(Permission.ManageEverything));
    const pastEntriesDisabled = useSelector(selectDisallowPastDayEntry);
    return pastEntriesDisabled && !(isManager || isAdmin);
};

export const useIsPayRangesAppliedForClient = () => {
    return useSelector(selectIsPayRangesEnabledForClient);
};

export const useModifiedTotalConfiguration = (configuration: ITotalConfiguration[]) => {
    const clientHasMealBreaks = useSelector(selectIsClientHasMealBreaks);
    return configuration.map((totalBlock: ITotalConfiguration) => {
        if (clientHasMealBreaks && totalBlock.slug === TotalSlug.Break) {
            return {
                ...totalBlock,
                unitLabel: 'total meal break',
            };
        }
        return totalBlock;
    });
};

export const useModifiedTotalConfigurationByType = (totalType: AvailableTotalConfiguration): ITotalConfiguration[] => {
    const configuration = useSelector(selectTotalConfiguration(totalType));
    return useModifiedTotalConfiguration(configuration);
};

export const useModifiedTotalConfigurationBySlug = (totalType: AvailableTotalConfiguration) => {
    const totalConfiguration = useModifiedTotalConfigurationByType(totalType);
    return totalConfiguration.reduce<ITotalConfigurationByTotalSlug>(
        (acc, field) => {
            acc[field.slug] = field;
            return acc;
        },
        {} as ITotalConfigurationByTotalSlug,
    );
};
