import React from 'react';
import clsx from 'clsx';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { Autorenew } from '@material-ui/icons';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

const useSyncMessageStyles = makeHighPriorityStyles((theme: Theme) => ({
    wrapper: {
        width: theme.spacing(25),
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    labelWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    label: {
        paddingLeft: theme.spacing(.5),
    },
    icon: {
        fontSize: 12,
    },
}));

interface ISyncMessagePureProps {
    classes?: Partial<{
        wrapper: string;
    }>;
}

export const SyncMessagePure = ({ classes }: ISyncMessagePureProps) => {
    const baseClasses = useSyncMessageStyles();
    return (
        <Box className={clsx(baseClasses.wrapper, classes?.wrapper)}>
            <Box className={baseClasses.labelWrapper}>
                <Autorenew fontSize="small" className={baseClasses.icon}/>
                <Typography variant="body2" className={baseClasses.label}>Syncing</Typography>
            </Box>
            <LinearProgress/>
        </Box>
    );
};
