import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStyles = makeHighPriorityStyles(() => ({
    input: {
        '& > div': {
            paddingLeft: '2px !important',
        },

        '& ::placeholder': {
            color: 'inherit',
            opacity: 1,
        },
    },
    popupIndicator: {
        marginRight: -10,
    },
}));
