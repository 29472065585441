import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useApproveStyles = makeHighPriorityStyles((theme: Theme) => ({
    confirmationDialog: {
        background: colors.lightGray,
    },
    actions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: theme.spacing(3, 6),
        '& > * + *': {
            marginRight: theme.spacing(2),
        },
    },
    cancelButton: {
        textDecoration: 'none',
        textTransform: 'none',
    },
    content: {
        padding: theme.spacing(3, 5, 6),
        maxWidth: 480,
        boxSizing: 'border-box',
        fontSize: theme.typography.pxToRem(16),
    },
    heading: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(20),
        marginBottom: theme.spacing(3),
    },
}));
