import { setEditAssignmentEditDataId, updateAssignmentReportMetaDataAction } from 'modules/settings/submodules/clients/assignmentsReportData/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const editId = singleValueReducer(setEditAssignmentEditDataId.action, null);
const isLoading = isLoadingReducer(updateAssignmentReportMetaDataAction);

export const editAssignmentMetaData = combineReducers({
    editId,
    isLoading,
});
