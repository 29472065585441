import { ICcpInvoiceBatch } from '../../../models/CcpTransaction';
import { IStore } from '../../../../../store/configureStore';
import { getInfinityScrollSelectors } from '../../../../../store/utils/infinityScroll/selectors';
import { createSelector } from 'reselect';
import { selectCcpTransactionsState } from '../../../store/selectors';

export interface ICcpInvoiceBatchRow extends ICcpInvoiceBatch {
    className?: string;
}

export const selectCcpInvoices = (state: IStore) => selectCcpTransactionsState(state).ccpInvoices;

export const selectCcpInvoicesById = (state: IStore) => selectCcpInvoices(state).ccpTransactionsById;
export const selectCcpInvoicesTableState = (state: IStore) => selectCcpInvoices(state).ccpInvoicesTable;
export const ccpInvoiceInfinityTableSelectors = getInfinityScrollSelectors<ICcpInvoiceBatch>(
    selectCcpInvoicesTableState);

export const selectCcpCheckedTransactions = (state: IStore) =>
    selectCcpInvoices(state).checkedCcpItems;

export const selectCcpIsSubmittingInvoice = (state: IStore) =>
    selectCcpInvoices(state).isSubmittingInvoice;

export const selectCcpInvoicesRows = createSelector(
    ccpInvoiceInfinityTableSelectors.selectItems,
    selectCcpInvoicesById,
    (ids, ccpByIds): ICcpInvoiceBatchRow[] => {
        return ids.map(id => ccpByIds[id]);
    },
);

export const selectIsOpenCcpSubmitInvoiceModal = (state: IStore) =>
    selectCcpInvoices(state).isOpenCcpSubmitInvoiceModal;
