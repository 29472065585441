import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Box, Button, Typography } from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import FormField from 'shared/components/formFields/FormField';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { IOnboardingConfirmationButtonProps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/FinishOnboardingConfirmation/model';

const errorMessage = `Please type 'finished'`;
const validationSchema = yup.object({
    finished: yup.string()
        .required(errorMessage)
        .lowercase()
        .trim()
        .matches(/^finished$/, errorMessage),
});

const useStyles = makeHighPriorityStyles(theme => ({
    paper: {
        maxWidth: '740px !important',
        minWidth: '400px',
    },
    content: {
        padding: `${theme.spacing(1)}px !important`,
    },
    img: {
        border: `1px solid ${colors.black}`,
        maxWidth: '100%',
        margin: theme.spacing(2, 0),
        boxSizing: 'border-box',
    },
    input: {
        width: '100%',
        margin: theme.spacing(0.5, 0, 2),
    },
    button: {
        width: '100%',
        color: colors.red,
        borderColor: colors.red,
        fontWeight: theme.typography.fontWeightMedium,
        letterSpacing: '1px',

        '&:hover': {
            color: colors.white,
            backgroundColor: colors.red,
        },
    },
}));

export const FinishOnboardingButtonV2 = ({ isLoading, onConfirm }: IOnboardingConfirmationButtonProps) => {
    const [isOpenFinishConfirmation, setIsOpenFinishConfirmation] = useState(false);
    const toggleConfirmation = useCallback(() => {
        setIsOpenFinishConfirmation(!isOpenFinishConfirmation);
    }, [setIsOpenFinishConfirmation, isOpenFinishConfirmation]);
    const modalClasses = useColoredFormModalStyles();
    const classes = useStyles();
    const imageUrl = `${process.env.PUBLIC_URL}/images/onboarding_completion.png`;

    return (
        <>
            <link
                rel="preload"
                as="image"
                href={imageUrl}
            />
            <Button
                onClick={toggleConfirmation}
                disabled={isLoading}
                color="primary"
                variant="outlined"
            >
                Finished forms below
            </Button>
            <ModalDialog
                title="Are you sure you have finished onboarding?"
                isOpened={isOpenFinishConfirmation}
                onClose={toggleConfirmation}
                modalProps={{
                    customClasses: {
                        ...modalClasses,
                        ...classes,
                    },
                    showCloseIcon: true,
                }}
            >
                <Box m={2}>
                    <Typography>
                        Please make sure you see the below screen on this page before moving forward.
                        If you continue before completing the{' '}
                        <b>
                            I-9, employee information, race/ethnicity,
                            disability, veteran, tax, and direct deposit forms
                        </b>,
                        your onboarding will be delayed resulting in a possible delay of pay with Headway.
                    </Typography>
                    <img
                        src={imageUrl}
                        alt="Onboarding completed"
                        className={classes.img}
                    />
                    <Typography>
                        Please type <b>finished</b> below to confirm you{`'`}ve reached this screen.
                    </Typography>
                    <Formik
                        initialValues={{
                            finished: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={onConfirm}
                    >
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                <FormField
                                    name="finished"
                                    className={classes.input}
                                />
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    className={classes.button}
                                    disabled={isLoading}
                                >
                                    I understand the consequences, i have finished onboarding
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Box>
            </ModalDialog>
        </>
    );
};
