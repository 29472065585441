import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const UserValidationSchema = yup.object().shape({
    first_name: yup.string().trim().required(ValidationMessages.REQUIRED),
    last_name: yup.string().trim().required(ValidationMessages.REQUIRED),
    email: yup.string().nullable()
        .email('Please enter valid email')
        .required(ValidationMessages.REQUIRED),
    cell_phone: yup.string().nullable()
        .min(10, 'Please enter valid phone')
        .required(ValidationMessages.REQUIRED),
    global_role_id: yup.string().nullable(),

    address: yup.string().trim().required(ValidationMessages.REQUIRED),
    address2: yup.string()
        .trim()
        .matches(/^((?!#).)*$/, 'Please remove special characters'),
    city: yup.string().trim().required(ValidationMessages.REQUIRED),
    state: yup.string().trim().required(ValidationMessages.REQUIRED),
    county: yup.string().trim().required(ValidationMessages.REQUIRED),
    zip_code: yup.string().trim().required(ValidationMessages.REQUIRED),
});
