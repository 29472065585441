import React from 'react';
import clsx from 'clsx';
import { PayType } from 'shared/models/PayType';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { TimeAndMoneyCell } from '../components/cells/abstract/TimeAndMoneyCell';
import { IPayrollSheetGroupedRow, IPayrollSheetRow, TableClasses } from '../model';

interface IRegularHoursCellPureProps {
    className?: string;
    tableClasses: TableClasses;
    hours: string | null;
    dollars: string | null;
}

const RegularHoursCellPure = ({
    className,
    tableClasses,
    hours,
    dollars,
}: IRegularHoursCellPureProps) => {
    return (
        <TimeAndMoneyCell
            className={clsx(className, tableClasses.pullRight)}
            time={hours}
            money={dollars}
        />
    );
};

export function makeRegularHoursCell(tableClasses: TableClasses) {
    // @ts-ignore
    return separateLogicDecorator<IPayrollSheetRow, IRegularHoursCellPureProps>(({
        time_rt_hours,
        time_rt_dollars,
        assignment,
        time_salaried_hours,
        ...props
    }) => {
        const isSalaried = assignment?.pay_type === PayType.Salaried;
        const hours = isSalaried ? time_salaried_hours : time_rt_hours;
        const dollars = isSalaried ? assignment?.pay_rate_value : time_rt_dollars;
        return {
            ...props,
            tableClasses,
            hours,
            dollars,
        };
    })(RegularHoursCellPure);
}

export function makeRegularHoursGroupedCell(tableClasses: TableClasses) {
    return separateLogicDecorator<IPayrollSheetGroupedRow, IRegularHoursCellPureProps>(({
        time_rt_hours,
        time_rt_dollars,
        time_salaried_dollars,
        time_salaried_hours,
        time_is_salary,
        ...props
    }) => {
        const hours = time_is_salary ? time_salaried_hours : time_rt_hours;
        const dollars = time_is_salary ? time_salaried_dollars : time_rt_dollars;
        return {
            ...props,
            tableClasses,
            hours,
            dollars,
        };
    })(RegularHoursCellPure);
}
