import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useOverflownTableStyles = makeHighPriorityStyles({
    overflownTable: {
        width: '100%',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
    },
    headingScrollableRow: {
        minWidth: '100%',
        width: '1800px !important',
    },
    bodyScrollableRow: {
        minWidth: '100%',
        width: '1800px !important',
    },
});
