import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import { IUpdateProfileRequest } from 'modules/offerLetter/store/model';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { IClient } from 'store/entities/clients/clientsModel';
import { createActions, createRequestActions, createSingleAction, RequestType } from 'store/utils';

const VIEW_OFFER_LETTER = 'VIEW_OFFER_LETTER';
export const viewOfferLetter = createSingleAction<string, typeof VIEW_OFFER_LETTER>(
    VIEW_OFFER_LETTER,
);

export const updateEmployeeProfile = createRequestActions<{
    clientId: string;
    profile: IUpdateProfileRequest;
},
IEmployeeProfile
>(
    RequestType.Update,
    'EMPLOYEE_PROFILE',
    'offer',
);

export const getMyEmployeeProfile = createRequestActions<string, IEmployeeProfile>(
    RequestType.Get,
    'MY_EMPLOYEE_PROFILE',
    'offer_letter',
);

export const getMyEmployeeProfiles = createRequestActions<string, IEmployeeProfile[]>(
    RequestType.Get,
    'MY_EMPLOYEE_PROFILES',
    'offer_letter',
);

const CHECK_EMPLOYEE_ONBOARDING_STATUS = 'CHECK_EMPLOYEE_ONBOARDING_STATUS';
const CHECK_EMPLOYEE_ONBOARDING_STATUS_SUCCESS = 'CHECK_EMPLOYEE_ONBOARDING_STATUS_SUCCESS';
const CHECK_EMPLOYEE_ONBOARDING_STATUS_ERROR = 'CHECK_EMPLOYEE_ONBOARDING_STATUS_ERROR';
export const checkEmployeeOnboardingStatus = createActions<{
    profile: IEmployeeProfile,
    client?: IClient,
},
IEmployeeProfile,
any,
typeof CHECK_EMPLOYEE_ONBOARDING_STATUS,
typeof CHECK_EMPLOYEE_ONBOARDING_STATUS_SUCCESS,
typeof CHECK_EMPLOYEE_ONBOARDING_STATUS_ERROR>(
    CHECK_EMPLOYEE_ONBOARDING_STATUS, CHECK_EMPLOYEE_ONBOARDING_STATUS_SUCCESS, CHECK_EMPLOYEE_ONBOARDING_STATUS_ERROR,
);

const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export const setOnboardingStep = createSingleAction<OnboardingSteps, typeof SET_ONBOARDING_STEP>(SET_ONBOARDING_STEP);

export const finishOnboarding = createRequestActions<string, void>(
    RequestType.Update,
    'OFFER_LETTER_ONBOARDING',
    'offer',
);
