import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { VerticalMenuChildItems } from 'shared/components/verticalMenu/VerticalMenuChildItems';
import { TMenuCategoryChild } from 'shared/models/Global';
import { IClient, IThemeConfigurationWrapper } from 'store/entities/clients/clientsModel';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { routes } from 'shared/routes';
import { VerticalMenuIds } from 'shared/components/verticalMenu/testIds';
import { MenuIconClient } from 'shared/components/verticalMenu/Icon';
import {
    selectAvailableClientsSortedAlphabetically,
} from 'store/entities/clients/selectors/clientsSelectors';

interface IClientPickerProps {
    configuration: IThemeConfigurationWrapper;
    tenantName: string;
    isMinimized: boolean;
    isSmallScreen: boolean;
    clientId: string;
}

export function ClientPicker({
    configuration,
    tenantName,
    isMinimized,
    isSmallScreen,
    clientId,
}: IClientPickerProps) {
    const classes = useMenuStyles();

    const clients = useSelector(selectAvailableClientsSortedAlphabetically);
    const clientsMenu: TMenuCategoryChild[] = Object.values(clients)
        .filter(client => client.id !== clientId)
        .map((client: IClient) => ({
            id: client.id,
            to: generatePath(routes.CLIENT.ROOT, { client_id: client.id }),
            title: client.name,
        }));
    const canSwitchClients = clientsMenu.length > 0;
    const [isOpened, setIsOpened] = useState(false);
    const parentItemRef = useRef(null);

    const toggleIsOpened = useCallback(event => {
        isSmallScreen && event.stopPropagation();
        setIsOpened(!isOpened);
    }, [setIsOpened, isOpened, isSmallScreen]);

    const [storedIsMinimized, setStoreIsMinimized] = useState(isMinimized);
    useEffect(() => {
        if (isMinimized && !storedIsMinimized) {
            setIsOpened(false);
        }
        setStoreIsMinimized(isMinimized);
    }, [isMinimized, storedIsMinimized, setIsOpened]);

    return (
        <>
            <ListItem
                className={clsx(
                    classes.item, classes.itemCategory, classes.tenantItem,
                    {
                        [classes.tenantMinimized]: isMinimized,
                    },
                )}
                onClick={toggleIsOpened}
                data-testid={VerticalMenuIds.ClientPicker}
                ref={parentItemRef}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    minHeight={32}
                >
                    {configuration && configuration.theme.logo_url ? (
                        <MenuIconClient icon_url={configuration.theme.logo_url}/>
                    ) : (
                        <ListItemIcon className={classes.itemIcon}><img alt="Default client logo" width="24"
                            height="24" src={`${process.env.PUBLIC_URL}/images/horizontal-white-transparent_square.png`}/>
                        </ListItemIcon>
                    )}

                    {(!isMinimized) && (
                        <ListItemText className={classes.tenantNameText}>
                            {tenantName}
                        </ListItemText>
                    )}
                    {canSwitchClients && (isOpened || !isMinimized) && (
                        <>
                            {isOpened ? (
                                <ExpandLess
                                    className={classes.expandButton}
                                    data-testid={VerticalMenuIds.ClientPickerOpened}
                                />
                            ) : !isMinimized && (
                                <ExpandMore
                                    className={classes.expandButton}
                                    data-testid={VerticalMenuIds.ClientPickerClosed}
                                />
                            )}
                        </>
                    )}
                </Box>
                <VerticalMenuChildItems
                    menuItems={clientsMenu}
                    isMinimized={isMinimized}
                    isOpened={isOpened}
                    onClose={toggleIsOpened}
                    parentRef={parentItemRef}
                    className={clsx({ [classes.availableClientContainer]: !isMinimized })}
                />
            </ListItem>
        </>
    );
}
