import { CheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { createSingleAction } from 'store/utils';

const PREFIX = 'employment_management';

const SET_SUBASSIGNMENT_CHECKED_ITEMS = `${PREFIX}/SET_SUBASSIGNMENT_CHECKED_ITEMS`;
export const setSubassignmentCheckedItems = createSingleAction<CheckedItems, typeof SET_SUBASSIGNMENT_CHECKED_ITEMS>(
    SET_SUBASSIGNMENT_CHECKED_ITEMS,
);

const SET_SUBASSIGNMENT_CHECKED_GROUPS = `${PREFIX}/SET_SUBASSIGNMENT_CHECKED_GROUPS`;
export const setSubassignmentCheckedGroups = createSingleAction<CheckedItems, typeof SET_SUBASSIGNMENT_CHECKED_GROUPS>(
    SET_SUBASSIGNMENT_CHECKED_GROUPS,
);

export const setAssignmentExpandedGroupId = createSingleAction<string | null>(`${PREFIX}/SET_EXPANDED_GROUP_ID`);
