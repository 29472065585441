import { combineReducers } from 'redux';
import { defaultInfinityScrollState, IInfinityScrollState, singleValueReducer } from 'store/reducerUtils';
import { createJobNumbers } from 'modules/settings/submodules/clients/jobNumber/components/CreateJobNumber/store/reducer';
import { editJobNumber } from 'modules/settings/submodules/clients/jobNumber/components/EditJobNumber/store/reducer';
import { jobNumberAssignUser } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/reducer';
import {
    getMoreJobNumbersAction, initialLoadJobNumbers,
    JobNumbersSettingsActions,
    setJobNumberFilter,
} from 'modules/settings/submodules/clients/jobNumber/store/actions';
import {
    IJobNumberPagePayload,
    jobNumberFilterDefaultValues,
} from 'modules/settings/submodules/clients/jobNumber/store/models';

export function jobNumbersTable(
    state: IInfinityScrollState<string>,
    action: JobNumbersSettingsActions,
): IInfinityScrollState<string> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setJobNumberFilter.action:
        case initialLoadJobNumbers.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreJobNumbersAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreJobNumbersAction.successType:
        {
            const payload = action.payload as IJobNumberPagePayload;
            const newItems = [
                ...state.items.filter(itemId => !payload.job_number_ids.includes(itemId)),
                ...payload.job_number_ids,
            ];
            return {
                // @ts-ignore
                total: action.payload.total_items,
                items: newItems,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}

const jobNumbersFilter = singleValueReducer(setJobNumberFilter.action, jobNumberFilterDefaultValues);

export const jobNumbers = combineReducers({
    jobNumbersTable,
    jobNumbersFilter,
    createJobNumbers,
    jobNumberAssignUser,
    editJobNumber,
});
