import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { selectAllClientsSortedAlphabetically } from 'store/entities/clients/selectors/clientsSelectors';
import FormSelect from './FormSelect';

interface IClientSelectProps extends IFormFieldProps, IFormSelect {
    allowedIds?: string[];
    autoSelectSingleVariant?: boolean;
}

export default function ClientSelect({ allowedIds, ...props }: IClientSelectProps) {
    const clients = useSelector(selectAllClientsSortedAlphabetically);

    const options = useMemo(() => {
        return clients.filter(client => allowedIds ? allowedIds.includes(client.id) : true);
    }, [allowedIds, clients]);
    return (
        <FormSelect
            getKey={client => client.id}
            getText={client => client.name}
            options={options}
            {...props}
        />
    );
}
