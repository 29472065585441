import { usePayrollReportStyle } from 'modules/payrollProcessorHub/components/PayrollModal/reports/PayrollStyle';
import { selectEditReportV2State } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { useSelector } from 'react-redux';

export function useBulkPreEndOverviewItems() {
    const preInitializeReportState = useSelector(selectEditReportV2State);
    const classes = usePayrollReportStyle();

    const numberOfFailed = preInitializeReportState?.number_of_subassignments_failed;
    const numberOfSubassignments = preInitializeReportState?.number_of_subassignments;

    return [
        {
            label: '# of assignments that will end',
            values: [String(numberOfSubassignments - numberOfFailed)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# of assignments that will fail',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}

export function useBulkPostEndOverviewItems() {
    const preInitializeReportState = useSelector(selectEditReportV2State);
    const classes = usePayrollReportStyle();

    const numberOfFailed = preInitializeReportState?.number_of_subassignments_failed;
    const numberOfSubassignments = preInitializeReportState?.number_of_subassignments;

    return [
        {
            label: '# of assignments that was end',
            values: [String(numberOfSubassignments - numberOfFailed)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# of assignments that was fail',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}
