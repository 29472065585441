import React, { useCallback } from 'react';
import { IOfferLetterWithPdf, useOfferLetterCells } from 'modules/offerLetter/components/OfferLetterTable/cells/cells';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useTableStyles } from 'shared/styles/tableStyles';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useDispatch, useSelector } from 'react-redux';
import { offerLettersTableStateSelectors } from '../../store/selectors';
import { getMoreOfferLetterAction, initialLoadOfferLetters, checkOfferLetterPdf } from 'modules/offerLetter/store/actions';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { selectOfferLetterRows } from 'modules/offerLetter/components/OfferLetterTable/selectors';
import { openOfferLetterPdf, useNeedToBeOpenPdf } from '../../store/utils';

export default function OfferLetterInfinityTable() {
    const tableClasses = useTableStyles();
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const hasMoreRows = useSelector(offerLettersTableStateSelectors.selectHasMore);
    const isLoading = useSelector(offerLettersTableStateSelectors.isLoading);
    const offerLettersRows = useSelector(selectOfferLetterRows);

    useWithClientEffect(dispatchWithClient => {
        dispatchWithClient(initialLoadOfferLetters());
    });
    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows){
            dispatch(getMoreOfferLetterAction.init());
        }
    }, [dispatch, hasMoreRows]);
    const openDetail = useCallback((offerLetter: IOfferLetterWithPdf) => {
        if (offerLetter.isPdfAvailable) {
            openOfferLetterPdf(offerLetter.id);
        } else {
            dispatch(checkOfferLetterPdf.init({ id: offerLetter.id, hasGlobalToaster: true }));
        }
    }, [dispatch]);
    useNeedToBeOpenPdf(openOfferLetterPdf);
    const cells = useOfferLetterCells(openDetail, offerLettersRows);

    return (
        <InfinityGridTable
            rowData={offerLettersRows}
            getKey={row => row.offerLetter.id}
            cells={cells}
            headerCellClassName={tableClasses.headerLightGrayCell}
            hideHeader={isMobile}
            isLoading={isLoading}
            onLoadMore={onLoadMoreRows}
            hasMore={hasMoreRows}
        />
    );
}
