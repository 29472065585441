import { createRequestActions, RequestType } from 'store/utils';
import { IExpenseEntryBackend, ISheetCommonBackend } from 'shared/models/sheet/Sheet';

export const getTravelExpenses = createRequestActions<void, IExpenseEntryBackend[]>(
    RequestType.Get,
    'TRAVEL_EXPENSES');

export const getTravelExpenseSheets = createRequestActions<void, ISheetCommonBackend[]>(
    RequestType.Get,
    'TRAVEL_EXPENSE_SHEETS');

export const deleteTravelExpense = createRequestActions<string, string>(
    RequestType.Delete,
    'TRAVEL_EXPENSE');
