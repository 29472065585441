import React from 'react';
import { Box } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import CheckboxField from 'shared/components/formFields/CheckboxField';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { EafSubmitBlock } from '../EafFormShared/EafSubmitBlock';
import { ChangeAssignmentStartDateFormProps, IChangeAssignmentStartDateFormValues } from './model/types';
import { ParticularDateField } from './ParticularDateField';

export interface IAssignmentStartDateChangeFormProps extends FormikProps<IChangeAssignmentStartDateFormValues> {
    isLoading: boolean;
    onCancel: () => void;
}

const WARNING_TEXT = `Do not set the new start date earlier than the Prism start date.`;

export const AssignmentStartDateChangeForm = ({
    isLoading,
    onCancel,
    values,
}: IAssignmentStartDateChangeFormProps) => {
    return (
        <Form>
            <ParticularDateField
                label="Current Start Date"
                name={ChangeAssignmentStartDateFormProps.CurrentStartDate}
                disabled
            />

            <ParticularDateField
                label="Prism Start Date"
                name={ChangeAssignmentStartDateFormProps.PrismStartDate}
                disabled
            />

            <Box display="flex">
                <ParticularDateField
                    disabled={isLoading}
                    disableToolbar
                    withKeyboard
                    label="New Start Date"
                    name={ChangeAssignmentStartDateFormProps.NewStartDate}
                    minDate={values[ChangeAssignmentStartDateFormProps.PrismStartDate]}
                />

                <Box mt={1.25} ml={3}>
                    <WarningAlertWithIcon>
                        {WARNING_TEXT}
                    </WarningAlertWithIcon>
                </Box>
            </Box>

            <CheckboxField
                disabled={isLoading}
                name={ChangeAssignmentStartDateFormProps.ShouldApplyToSubassignments}
                label={`Apply new start date to all sub assignments that start on
                ${formatShortDate(values.currentStartDate || '')}`}
            />

            <EafSubmitBlock
                isLoading={isLoading}
                onCancel={onCancel}
            />
        </Form>
    );
};
