import { EntryType } from 'shared/models/sheet/Sheet';
import { CheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

const prefix = 'sheetApproval';

const SET_GROUPED_SHEETS_CHECKED_ITEMS = 'sheetApproval/SET_GROUPED_SHEETS_CHECKED_ITEMS';
export const setGroupedSheetsCheckedItems = createSingleAction<CheckedItems, typeof SET_GROUPED_SHEETS_CHECKED_ITEMS>(
    SET_GROUPED_SHEETS_CHECKED_ITEMS,
);

const SET_SHEET_GROUP_DETAIL_ID = 'sheetApproval/SET_SHEET_GROUP_DETAIL_ID';
export const setSheetGroupedDetailId = createSingleAction<string | null, typeof SET_SHEET_GROUP_DETAIL_ID>(
    SET_SHEET_GROUP_DETAIL_ID,
);

const SET_GROUPED_SHEETS_APPROVAL_ID = 'sheetApproval/SET_GROUPED_SHEETS_APPROVAL_ID';
export const setGroupedSheetsApprovalId = createSingleAction<string | null, typeof SET_GROUPED_SHEETS_APPROVAL_ID>(
    SET_GROUPED_SHEETS_APPROVAL_ID,
);

const SET_GROUPED_SHEETS_REJECT_ID = 'sheetApproval/SET_GROUPED_SHEETS_REJECT_ID';
export const setGroupedSheetsRejectId = createSingleAction<string | null, typeof SET_GROUPED_SHEETS_REJECT_ID>(
    SET_GROUPED_SHEETS_REJECT_ID,
);

const SET_SHEET_GROUP_SIDEBAR_ID = 'sheetApproval/SET_SHEET_GROUP_SIDEBAR_ID';
export const setSheetGroupedSidebarId = createSingleAction<string | null, typeof SET_SHEET_GROUP_SIDEBAR_ID>(
    SET_SHEET_GROUP_SIDEBAR_ID,
);

interface IEditSheetsPayload {
    groupId?: string;
    sheetIds: string[];
    entryType: EntryType;
}
const SET_EDIT_SHEET_GROUP = 'sheetApproval/SET_EDIT_SHEET_GROUP';
export const setEditSheetGroup = createSingleAction<IEditSheetsPayload | null, typeof SET_EDIT_SHEET_GROUP>(
    SET_EDIT_SHEET_GROUP,
);

export const exportCsvTimeApproval = createRequestActions<void, void>(
    RequestType.Create,
    'TIME_APPROVAL_CSV_EXPORT',
    prefix,
);
export const exportCsvExpenseApproval = createRequestActions<void, void>(
    RequestType.Create,
    'EXPENSE_APPROVAL_CSV_EXPORT',
    prefix,
);
