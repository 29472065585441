import { DropLocation } from 'modules/settings/submodules/components/HierarchyPage/store/models';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import Sortly, { DragObject, ItemData, remove, add, findDescendants } from 'react-sortly';
import { useDrop } from 'react-dnd';
import { Box } from '@material-ui/core';
import { useHierarchyPageStyles } from 'modules/settings/submodules/components/HierarchyPage/styles';
import { selectCurrentClientHasAssignments } from 'store/entities/clients/selectors/clientsSelectors';
import { useAvailableCustomFieldsStyles } from './styles';
import { AvailableCustomFieldListItem } from './AvailableCustomFieldListItem';
import { HierarchyContext } from '../HierarchyListsContext';
import { ICustomFieldHierarchyRow } from '../../../customFields/store/models';

export const AvailableCustomFieldList = () => {
    const { availableList, hierarchyList, setAvailableList, setHierarchyList } = useContext(HierarchyContext);
    const hierarchyPageStyles = useHierarchyPageStyles();
    const availableCustomFieldsClasses = useAvailableCustomFieldsStyles();

    const handleChange = useCallback((items: ItemData<ICustomFieldHierarchyRow>[]) => {
        setAvailableList(items);
    }, [setAvailableList]);
    const handleEnter = useCallback((draggedItemObj: DragObject) => {
        if (availableList.some(availableListItem => availableListItem.id === draggedItemObj.id)) {
            return;
        }
        const draggedItemIndex = hierarchyList.findIndex(item => item.id === draggedItemObj.id);
        const draggedItem = hierarchyList[draggedItemIndex];

        const hierarchyChildren = findDescendants(hierarchyList, draggedItemIndex);
        const draggedItems = [draggedItem, ...hierarchyChildren];
        if (draggedItem) {
            setHierarchyList(remove(hierarchyList, draggedItemIndex));
            setAvailableList(add(availableList, draggedItems));
        }
    }, [hierarchyList, availableList, setAvailableList, setHierarchyList]);

    const [{ hovered, dragItem }, drop] = useDrop({
        accept: DropLocation.HierarchyTableLocation,
        collect: monitor => ({
            hovered: monitor.isOver(),
            dragItem: monitor.getItem(),
        }),
    });
    const handleMove = React.useCallback(() => {
        if (!dragItem) {
            return;
        }
        if (hovered) {
            handleEnter(dragItem);
        }
    }, [dragItem, hovered, handleEnter]);
    const currentClientHasAssignments = useSelector(selectCurrentClientHasAssignments);

    React.useEffect(() => {
        if (dragItem) {
            handleMove();
        }
    }, [dragItem, hovered, handleMove]);

    return (
        <Box>
            <Box className={hierarchyPageStyles.label}>
                <label>Available Custom Fields</label>
            </Box>
            <div ref={drop}>
                <Box className={ availableCustomFieldsClasses.customFieldsBox }>
                    <Sortly
                        items={availableList}
                        onChange={handleChange}
                        type={DropLocation.HierarchyTableLocation}
                    >
                        {({ data, id, depth }) => (
                            <AvailableCustomFieldListItem
                                id={id}
                                depth={depth}
                                disabled={currentClientHasAssignments}
                                {...data}
                            />
                        )}
                    </Sortly>
                </Box>
            </div>
        </Box>
    );
};
