import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { CheckedItems, OnCheck } from 'shared/utils/hooks/useCheckedItems';

export interface ICheckboxClasses {
    iconCell: string;
    checkbox: string;
    checkboxChecked?: string;
    checkboxSubmittedStyle?: string;
    rootCheckBox?: string;
}

export interface ICheckBoxCellPureProps {
    checkedItems: CheckedItems,
    onCheck: OnCheck,
    classes: ICheckboxClasses,
    customClasses?: Array<string>,
    rowClassName?: string;
    rowId: string;
    isMobile?: boolean;
}

export interface ICheckBoxTitlePureProps {
    classes: ICheckboxClasses,
    checkedAll: boolean,
    onCheckAll: (checked: boolean) => void,
}

export const CheckBoxTitlePure = ({
    classes,
    checkedAll,
    onCheckAll,
}: ICheckBoxTitlePureProps) => {
    return (
        <Checkbox
            color="primary"
            checked={checkedAll}
            onChange={() => onCheckAll(!checkedAll)}
            classes={{ checked: classes.checkbox }}
        />
    );
};

export const CheckBoxCellPure = ({
    customClasses = [],
    classes,
    checkedItems,
    onCheck,
    rowClassName,
    rowId,
    isMobile,
}: ICheckBoxCellPureProps) => {
    const name = `row_${rowId}`;
    const className = useMemo(() => {
        if (isMobile) {
            return classes.checkboxSubmittedStyle;
        } else {
            return classes.checkbox;
        }
    }, [isMobile, classes]);
    const classesPrepared = useMemo(() => {
        return isMobile
            ? { checked: classes.checkboxChecked, root: classes.rootCheckBox }
            : { checked: classes.checkboxChecked };
    }, [isMobile, classes]);
    return (
        <div className={clsx(...customClasses, rowClassName, classes.iconCell, className)}>
            <Checkbox
                color="primary"
                checked={checkedItems[rowId] ?? false}
                onChange={() => onCheck(!(checkedItems[rowId] ?? false), rowId)}
                classes={classesPrepared}
                name={name}
                inputProps={{ 'aria-label': name }}
            />
        </div>
    );
};

export function getCheckboxCell<IRow extends { className?: string }>(
    checkedItems: CheckedItems,
    onCheck: OnCheck,
    checkedAll: boolean,
    onCheckAll: (checked: boolean) => void,
    classes: ICheckboxClasses,
    isMobile: boolean,
    getId: (row: IRow) => string,
    customHeaderClasses: Array<string> = [],
    customClasses: Array<string> = [],
): ICellInfo<IRow> {
    return {
        key: 'checkbox',
        title: '',
        width: isMobile ? '40px' : '60px',
        headerClassName: clsx(...customHeaderClasses, classes.iconCell, classes.checkbox),
        renderTitle: function CheckboxCellHeader() {
            return (
                <CheckBoxTitlePure
                    checkedAll={checkedAll}
                    onCheckAll={onCheckAll}
                    classes={classes}
                />
            );
        },
        render: function CheckboxCell(row: IRow) {
            return (
                <CheckBoxCellPure
                    customClasses={customClasses}
                    classes={classes}
                    checkedItems={checkedItems}
                    onCheck={onCheck}
                    rowClassName={row.className}
                    rowId={getId(row)}
                    isMobile={isMobile}
                />
            );
        },
    };
}
