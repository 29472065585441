import { uniq, isEqual, isBoolean, isEmpty } from 'lodash-es';
import {
    approveEaf,
    getEafDetails,
    initialLoadEafApprovalItems,
    loadMoreEafApproval,
    patchEafItemsRequest,
    rejectEaf,
    setEafApprovalActiveTab,
    setEafApprovalCheckedItemsIds,
    setEafApprovalFilter, setEafApprovalSortOrder,
    setEafDetailId,
    setPendingEafCount,
} from 'modules/eafApproval/store/actions';
import { eafApprovalApi } from 'modules/eafApproval/store/api';
import { IEafApprovalItemsRequest, IPatchEmployeeActionFormRequest } from 'modules/eafApproval/store/model/types';
import { eafTableSelectors, selectDetailEaf, selectEafApprovalActiveTab, selectEafApprovalGridFilter, selectEafApprovalGridOrder } from 'modules/eafApproval/store/selectors';
import { EafStatusSlug } from 'modules/employeeActionsForm/store/models';
import { getEmployeeProfiles } from 'modules/offerLetter/store/actions';
import { selectEmployeeProfilesByIds } from 'modules/offerLetter/store/selectors';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { decreaseSyncing, increaseSyncing, setGlobalToast } from 'store/entities/appConfig/actions';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { getAssignments, getSubassignments } from 'store/entities/configuration/configurationAction';
import { selectAssignmentById, selectAssignmentsById, selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import { getUsers } from 'store/entities/users/actions';
import { selectUsersById } from 'store/entities/users/selectors';
import { defaultTransformLoadActionParams, optionalLoadEntitiesByIdsSaga } from 'store/utils/sagas/optionalLoadEntitiesByIdsSaga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeLatest, all } from 'typed-redux-saga';

export function* loadMoreEafApprovalItemsSaga() {
    const statusSlug = yield select(selectEafApprovalActiveTab);
    const cursor = yield select(eafTableSelectors.selectCursor);
    const filter = yield select(selectEafApprovalGridFilter);
    const pageSize = yield select(eafTableSelectors.selectPageSize);
    const shouldAddStatus = Boolean(statusSlug) && !isEqual(statusSlug, 'all') && !filter.allStatuses;
    const sortOrder = yield select(selectEafApprovalGridOrder);
    const sort = Object.entries(sortOrder)[0] || [];

    const request: IEafApprovalItemsRequest = {
        client_id: filter.clientId || undefined,
        status_slug: shouldAddStatus ? (statusSlug as EafStatusSlug) : undefined,
        cursor: cursor || null,
        employee_user_id: filter.employeeUserId || undefined,
        assignment_id: filter.assignmentId || undefined,
        eaf_types: filter.type ? [filter.type] : filter.defaultTypes,
        is_retro: isBoolean(filter.isRetro) ? filter.isRetro : undefined,
        page_size: pageSize,
        // @ts-ignore
        sort: isEmpty(sort) ? undefined : sort,
    };

    const data = yield call(eafApprovalApi.getEafApprovalItems, request);

    if (statusSlug === EafStatusSlug.PendingApproval) {
        yield put(setPendingEafCount(data.total_items));
    }

    const usersIds = uniq(data.items.flatMap(item => [item.created_by, item.employee_user_id]));
    yield call(
        // @ts-ignore
        optionalLoadEntitiesByIdsSaga,
        usersIds,
        selectUsersById,
        getUsers,
        userIds => ({ ids: userIds.join(',') }),
    );

    yield put(loadMoreEafApproval.success(data));
}

export function* getEafApprovalItemsRequestSagaWatcher() {
    yield takeLatest(
        [
            loadMoreEafApproval.initType,
            initialLoadEafApprovalItems.action,
            setEafApprovalFilter.action,
            setEafApprovalSortOrder.action,
            setEafApprovalActiveTab.action,
        ],
        withBackendErrorHandler(
            loadMoreEafApprovalItemsSaga,
            loadMoreEafApproval.error,
            'Failed to get EAF approval items',
        ),
    );
}

function* patchEafItemsRequestSaga({ payload }: { payload: IPatchEmployeeActionFormRequest }) {
    yield put(setEafDetailId(null));
    yield put(setEafApprovalCheckedItemsIds([]));
    yield put(increaseSyncing(SyncingModels.EafApproval));
    try {
        const data = yield* call(eafApprovalApi.patchEmployeeActionForms, payload);
        yield put(patchEafItemsRequest.success(data));
    } finally {
        yield put(decreaseSyncing(SyncingModels.EafApproval));
    }
}

export function* patchEafItemsRequestSagaWatcher() {
    yield takeLatest(
        // @ts-ignore
        patchEafItemsRequest.initType,
        withBackendErrorHandler(
            patchEafItemsRequestSaga,
            patchEafItemsRequest.error,
            'Unable to change employee action form status',
        ),
    );
}

function* approveEafSaga({ payload }: ReturnType<typeof approveEaf.init>) {
    const requestPayload: IPatchEmployeeActionFormRequest = {
        employee_action_forms: payload.map(id => ({
            employee_action_form_id: id,
            status_slug: EafStatusSlug.Approved,
        })),
    };
    yield* call(patchEafItemsRequestSaga, { payload: requestPayload });
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Employee action form successfully approved',
    }));
    yield put(approveEaf.success());
}

export function* approveEafSagaWatcher() {
    yield takeLatest(
        approveEaf.initType,
        withBackendErrorHandler(
            approveEafSaga,
            approveEaf.error,
            'Unable to approve employee action forms',
        ),
    );
}

function* rejectEafSaga({ payload }: ReturnType<typeof rejectEaf.init>) {
    yield* call(patchEafItemsRequestSaga, { payload });
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Employee action form successfully rejected',
    }));
    yield put(rejectEaf.success());
}

export function* rejectEafSagaWatcher() {
    yield takeLatest(
        rejectEaf.initType,
        withBackendErrorHandler(
            rejectEafSaga,
            rejectEaf.error,
            'Unable to reject employee action forms',
        ),
    );
}

function* loadEafDetailSaga() {
    const detailEaf = yield select(selectDetailEaf);
    if (detailEaf) {
        const loadWithClientId = (ids: string[]) => ({
            ...defaultTransformLoadActionParams(ids),
            client_id: detailEaf.client_id,
        });

        yield put(getEafDetails.init());
        yield* all([
            call(optionalLoadEntitiesByIdsSaga,
                [detailEaf.assignment_id], selectAssignmentsById, getAssignments, loadWithClientId),
            call(optionalLoadEntitiesByIdsSaga,
                [detailEaf.subassignment_id], selectSubassignmentsByIds, getSubassignments, loadWithClientId),
        ]);
        const assignment = yield select(selectAssignmentById(detailEaf.assignment_id));
        yield call(
            optionalLoadEntitiesByIdsSaga,
            [assignment?.employee_profile_id],
            selectEmployeeProfilesByIds,
            getEmployeeProfiles,
            ids => ({
                client_id: detailEaf.client_id,
                filters: JSON.stringify({
                    id: {
                        'in': ids,
                    },
                }),
            }),
        );
        yield put(getEafDetails.success());
    }
}

export function* loadEafDetailSagaWatcher() {
    yield takeLatest(
        setEafDetailId.action,
        loadEafDetailSaga,
    );
}
