import baseApi from 'shared/utils/baseApi';
import { IUserProfileInfo, IAvatar, IAvatarUpload } from './profileModel';

class ProfileApi {
    async getUserById(id: string): Promise<IUserProfileInfo> {
        const { data } = await baseApi.get<IUserProfileInfo>(
            `/client-users/users/${id}`,
        );        
        return data;
    }
    async getUserAvatar(): Promise<IAvatar> {
        const response = await baseApi.get<{avatar_attachment: IAvatar}>(
            `/client-users/avatar`,
        );        
        return response?.data?.avatar_attachment;  
    }
    async createUserAvatar(avatarToUpload: IAvatarUpload): Promise<IAvatar> {
        const response = await baseApi.post<IAvatarUpload, { avatar_attachment: IAvatar }>(
            `/client-users/avatar/`, avatarToUpload,
        );
        return response.data.avatar_attachment;
    }
}

export const profileApi = new ProfileApi();
