import baseApi from 'shared/utils/baseApi';

import {
    dealEntity,
    gwClientExtensionsService,
} from 'store/entities/configuration/configurationApi';
import { IDeal } from 'shared/models/Deal';
import { Status } from 'shared/models/Status';

export const api = {
    async updateDealStatus(id: string, status: Status): Promise<IDeal> {
        const url = `/${gwClientExtensionsService}/${dealEntity}/${id}`;
        const { data } = await baseApi.patch<IBody, IDeal>(
            url,
            { status },
        );

        return data;
    },
};

interface IBody{
    status: Status;
}
