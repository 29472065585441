import React, { useMemo } from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { useSelector } from 'react-redux';
import { IUserInfo } from 'shared/models/User';
import { Permission } from 'store/components/auth/authModels';
import { selectUsersByFeature } from 'store/entities/users/selectors';
import { IFormSelect } from './model';
import { PrismUserType, UserFeature } from 'store/entities/users/model';
import { IFormFieldProps } from '../formFields/models';
import { Status } from 'shared/models/Status';

export interface ISpecialUserSelectProps extends IFormFieldProps, IFormSelect {
    feature: UserFeature;
    featureValue: Permission | PrismUserType;
    activeOnly?: boolean;
}

export default function SpecialUserSelect({
    feature,
    featureValue,
    activeOnly,
    ...props
}: ISpecialUserSelectProps) {
    const users = useSelector(selectUsersByFeature(feature, featureValue));

    const filteredUsers = useMemo(() => {
        const nonEmptyUsers: Array<IUserInfo> = users.filter(user => !!user);
        return activeOnly === true ? nonEmptyUsers.filter(user => user.status === Status.active) : nonEmptyUsers;
    }, [users, activeOnly]);

    return (
        <FormSelect
            {...props}
            getKey={(user: IUserInfo) => user.id || ''}
            getText={(user: IUserInfo) => `${user.first_name} ${user.last_name}`}
            options={filteredUsers}
        />
    );
}
