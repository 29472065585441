import { useCallback, useMemo } from 'react';

export function useVirtualTableRowsHelper<RowData>(rows: RowData[]) {
    const getRowByIndex = useCallback((index: number): RowData => {
        return rows[index];
    }, [rows]);
    const dataLength = useMemo((): number => {
        return rows.length;
    }, [rows]);
    const getIsRowLoaded = useCallback((index: number): boolean => {
        return Boolean(getRowByIndex(index));
    }, [getRowByIndex]);
    return {
        getRowByIndex,
        dataLength,
        getIsRowLoaded,
    };
}
