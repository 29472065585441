import { IEmailConfirmation } from 'store/entities/users/model';
import { ActionsReturnTypes, createActions } from 'store/utils';

export const EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION';
export const EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS';
export const EMAIL_CONFIRMATION_ERROR = 'EMAIL_CONFIRMATION_ERROR';

export const confirmEmail = createActions<
IEmailConfirmation,
void,
any,
typeof EMAIL_CONFIRMATION,
typeof EMAIL_CONFIRMATION_SUCCESS,
typeof EMAIL_CONFIRMATION_ERROR
>(
    EMAIL_CONFIRMATION,
    EMAIL_CONFIRMATION_SUCCESS,
    EMAIL_CONFIRMATION_ERROR,
);

export type EmailConfirmationActions = ActionsReturnTypes<typeof confirmEmail>;
