import React, { useMemo } from 'react';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import { HeaderTabs, IHeaderTab } from 'shared/components/common/headerTabs/HeaderTabs';
import { useTabManagement } from 'shared/hooks/useTabManagement';

import { setSheetGridStatus } from 'store/entities/appConfig/actions';

import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useSelector } from 'react-redux';
import { selectMissingSheetsGenerationEnabled } from '../../../store/entities/clients/selectors/configurationSelectors';
import { EntryType } from '../../../shared/models/sheet/Sheet';

const tabsRegular: Array<IHeaderTab> = [
    {
        label: 'Submitted',
        value: StatusNames.SUBMITTED,
    },
    {
        label: 'Working',
        value: StatusNames.WORKING,
    },
    {
        label: 'Approved',
        value: StatusNames.APPROVED,
    },
    {
        label: 'Rejected',
        value: StatusNames.REJECTED,
    },
];

const tabAll: IHeaderTab = {
    label: 'All',
    value: StatusNames.ALL,
};

const tabMissing: IHeaderTab = {
    label: 'Missing',
    value: StatusNames.MISSING,
};

export interface ISheetApprovalTabsPageProps {
    entryType: EntryType;
}

export const SheetApprovalTabs: React.FC<ISheetApprovalTabsPageProps> = (
    { entryType }: ISheetApprovalTabsPageProps) => {
    const { activeTab, setActiveTab } = useTabManagement<StatusNames>(
        selectTimeAndExpenseActiveStatus,
        setSheetGridStatus,
    );
    const hasMissingEnabled = useSelector(selectMissingSheetsGenerationEnabled);

    const tabs = useMemo(() => {
        if (hasMissingEnabled && (entryType === EntryType.TIME || entryType === EntryType.EXPENSE)) {
            return tabsRegular.concat([tabMissing, tabAll]);
        } else {
            return tabsRegular.concat([tabAll]);
        }
    }, [hasMissingEnabled, entryType]);

    return (
        <HeaderTabs
            tabs={tabs}
            mobileHeader="Approval Status"
            value={activeTab}
            onChange={setActiveTab}
        />
    );
};
