import { setEditLogoClientId, updateClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const clientId = singleValueReducer<string | null>(setEditLogoClientId.action, null);
const isUpdating = isLoadingReducer(updateClientLogo);

export const editClientLogo = combineReducers({
    clientId,
    isUpdating,
});
