import OfferLetterEmployeeDetail
    from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OfferLetterEmployeeDetail';
import OfferLetterEmployee from 'modules/offerLetter/OfferLetterEmployee/OfferLetterEmployee';
import React from 'react';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { routes } from 'shared/routes';

export const OfferLetterEmployeeRoutes = () => {
    return (
        <>
            <PrivateRoute
                path={routes.EMPLOYEE_OFFER_LETTER.DETAIL}
                component={OfferLetterEmployeeDetail}
            />
            <PrivateRoute
                path={routes.EMPLOYEE_OFFER_LETTER.ROOT}
                component={OfferLetterEmployee}
            />
        </>
    );
};
