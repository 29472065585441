import { IPayCode, PayCodeClass } from 'modules/settings/submodules/clients/payCodes/store/model';
import { selectIsPayCodesLoading, selectPayCodes } from 'modules/settings/submodules/clients/payCodes/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { EntryType } from 'shared/models/sheet/Sheet';

interface IPayCodeSelectProps extends IFormFieldProps, IFormSelect {
    entryType?: EntryType;
}

export default function PayCodeSelect({
    entryType,
    ...props
}: IPayCodeSelectProps) {
    const allPayCodes = useSelector(selectPayCodes);
    const isPayCodesLoading = useSelector(selectIsPayCodesLoading);

    const payCodeClass = entryType === EntryType.EXPENSE ? PayCodeClass.ExpenseReimbursement : PayCodeClass.Earnings;

    const payCodes = !entryType ? allPayCodes : allPayCodes.filter(payCode => {
        return payCode.pay_class === payCodeClass && payCode.can_create_activity !== false;
    });

    return (
        <FormSelect
            {...props}
            getKey={(code: IPayCode) => code.id}
            getText={(code: IPayCode) => code.description}
            options={payCodes}
            disabled={!payCodes.length || props.disabled}
            isLoading={isPayCodesLoading}
        />
    );
}
