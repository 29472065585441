import React, { FC } from 'react';
import { OutlinedInput } from '@material-ui/core';
import { inputParams } from 'shared/styles/constants';

export interface IFormattedValue {
    formattedValue: string;
    value: string;
    floatValue: number;
}

interface IOutlinedFieldProps {
    label?: string;
    customClasses?: Partial<Record<'input' | 'inputRoot' | 'disabled', string>>;
    onBlur?: any;
    onFocus?: any;
    value?: string;
    placeholder?: string;
    formatter?: (value: string) => void;
    removeFormatter?: (value: string) => string;
    decimalScale?: number;
    numberFormat: any;
    isAllowed?: (value: IFormattedValue) => boolean;
    disabled?: boolean;
}

const OutlinedField: FC<IOutlinedFieldProps> = ({
    customClasses,
    formatter,
    numberFormat,
    removeFormatter,
    decimalScale,
    isAllowed,
    disabled,
    ...props
}: IOutlinedFieldProps) => {
    return (
        <OutlinedInput
            {...props}
            disabled={disabled}
            inputComponent={numberFormat}
            classes={{ root: customClasses?.inputRoot, disabled: customClasses?.disabled }}
            inputProps={{
                format: formatter,
                disabled: disabled,
                removeFormatting: removeFormatter,
                decimalScale: decimalScale,
                className: customClasses?.input,
                allowNegative: false,
                thousandSeparator: true,
                isAllowed: isAllowed,
                ...inputParams,
            }}
        />
    );
};

export default OutlinedField;
