import { uniq } from 'lodash-es';
import { removeOfferLetterTemplate } from 'modules/offerLetter/store/templates/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, isOpenModalReducer, singleValueReducer } from 'store/reducerUtils';
import {
    createOfferLetterTemplate,
    getOfferLetterTemplateParams, SET_UPDATE_OFFER_LETTER_TEMPLATE_MODAL_STATE,
    setCreateOfferLetterTemplateModalState,
    updateOfferLetterTemplate,
    uploadOfferLetterTemplate,
} from 'modules/settings/submodules/offerLetters/store/action';
import { ActionsReturnTypes } from 'store/utils';
import { Nullable } from '../../../../../types/types';
import { IOfferLetterTemplateParam, UpdateOfferLetterTemplateModalStatePayload } from './models';

const initialState = {
    offerLetterTemplateUploadedFile: '',
    offerLetterTemplateParams: null,
    offerLetterTemplateUpdateId: null,
    updatingOfferLetterTemplateIds: [],
    deletingOfferLetterTemplateIds: [],
};

const isOpenCreateOfferLetterTemplateModal = isOpenModalReducer(setCreateOfferLetterTemplateModalState.action);
const isOfferLetterTemplateCreating = isLoadingReducer(createOfferLetterTemplate);
const isOfferLetterTemplateUpdating = isLoadingReducer(updateOfferLetterTemplate);
const isOfferLetterTemplateUploading = isLoadingReducer(uploadOfferLetterTemplate);
const isOfferLetterTemplateParamsLoading = isLoadingReducer(getOfferLetterTemplateParams);

const offerLetterTemplateUploadedFile = (
    state: string = initialState.offerLetterTemplateUploadedFile,
    action: ActionsReturnTypes<typeof uploadOfferLetterTemplate>,
): string => {
    switch (action.type) {
        case uploadOfferLetterTemplate.successType:
            return action.payload;
        default:
            return state;
    }
};

const offerLetterTemplateParams = (
    state: Nullable<IOfferLetterTemplateParam[]> = initialState.offerLetterTemplateParams,
    action: ActionsReturnTypes<typeof getOfferLetterTemplateParams>,
): Nullable<IOfferLetterTemplateParam[]> => {
    switch (action.type) {
        case getOfferLetterTemplateParams.successType:
            return action.payload;
        default:
            return state;
    }
};

const updatingOfferLetterTemplateIds = (
    state: Array<string> = initialState.updatingOfferLetterTemplateIds,
    action: ActionsReturnTypes<typeof updateOfferLetterTemplate>,
): Array<string> => {
    switch (action.type) {
        case updateOfferLetterTemplate.initType: {
            const id = action.payload.id;
            return uniq([...state, id]);
        }
        case updateOfferLetterTemplate.successType:
        case updateOfferLetterTemplate.errorType: {
            const finishedId = action.payload.id;
            return state.filter(id => id !== finishedId);
        }
        default: return state;
    }
};

const deletingOfferLetterTemplateIds = (
    state: Array<string> = initialState.deletingOfferLetterTemplateIds,
    action: ActionsReturnTypes<typeof removeOfferLetterTemplate>,
): Array<string> => {
    switch (action.type) {
        case removeOfferLetterTemplate.initType: {
            const id = action.payload;
            return uniq([...state, id]);
        }
        case removeOfferLetterTemplate.successType:
        case removeOfferLetterTemplate.errorType: {
            const finishedId = action.payload;
            return state.filter(id => id !== finishedId);
        }
        default: return state;
    }
};

const offerLetterTemplateUpdateId = singleValueReducer<UpdateOfferLetterTemplateModalStatePayload>(
    SET_UPDATE_OFFER_LETTER_TEMPLATE_MODAL_STATE,
    initialState.offerLetterTemplateUpdateId,
);

export const offerLetterTemplate = combineReducers({
    isOpenCreateOfferLetterTemplateModal,
    isOfferLetterTemplateCreating,
    isOfferLetterTemplateUpdating,
    isOfferLetterTemplateUploading,
    offerLetterTemplateUploadedFile,
    isOfferLetterTemplateParamsLoading,
    offerLetterTemplateParams,
    offerLetterTemplateUpdateId,
    updatingOfferLetterTemplateIds,
    deletingOfferLetterTemplateIds,
});
