import { Permission } from 'store/components/auth/authModels';

export enum AccessLevel {
    NoAccess = 'NoAccess',
    ReadOnly = 'ReadOnly',
    ViewEditOwn = 'ViewEditOwn',
    ViewEditAll = 'ViewEditAll',
}

export const accessLevelDisplayValues: Record<AccessLevel, string> = {
    [AccessLevel.NoAccess]: 'No Access',
    [AccessLevel.ReadOnly]: 'Read-only',
    [AccessLevel.ViewEditOwn]: 'Create & View only their own',
    [AccessLevel.ViewEditAll]: 'Create & View all user data',
};

export interface IClientRoleFormValues {
    name: string;
    description: string;
    clientIds: string[];
    allClients: boolean;

    permission: IClientRolePermissions;
}

export interface IClientRolePermissions {
    approval: AccessLevel;
    offerLetters: AccessLevel;

    editSheetAsApprover: boolean;
    approvePayRate: boolean;
    payRateAutoApprove: boolean;
    assignmentManagement: boolean;
    assignmentManagementScopeRequired: boolean;
    employeeImpersonation: boolean;
    managerImpersonation: boolean;
    viewReports: boolean;
    manageTravelExpenses: boolean;

    notManaged: Permission[];
}
