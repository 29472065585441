import baseApi from 'shared/utils/baseApi';

import {
    activityEntity,
    clientConfigurationsService,
} from 'store/entities/configuration/configurationApi';

import { IActivity } from 'store/entities/configuration/configurationModel';

export const api = {
    async updateActivityStatus(id: string, is_active: boolean): Promise<IActivity> {
        const url = `/${clientConfigurationsService}/${activityEntity}/${id}`;
        const { data } = await baseApi.patch<IBody, IActivity>(
            url,
            { is_active },
        );

        return data;
    },
};

interface IBody{
    is_active: boolean;
}
