import { Typography } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import React from 'react';
import { DealTypeBillingType, IDealType } from 'shared/models/DealType';
import { FeeType, TimesheetSettings } from 'shared/models/JobNumber';
import { DailyBillingInfoFormSection } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/sections/BillingInfoSection/DailyBillingInfoFormSection';
import { PerFileBillingInfoFormSection } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/sections/BillingInfoSection/PerFileBillingInfoFormSection';

interface IBillingInfoFormSectionProps {
    timesheetSettings?: TimesheetSettings;
    feeType?: FeeType;
    dealType: IDealType | null;
    path: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const BillingInfoFormSection = ({
    feeType,
    dealType,
    path,
    setFieldValue,
}: IBillingInfoFormSectionProps) => {
    const classes = useSettingsFormStyles();
    const blockTitle = (
        <Typography
            variant="subtitle2"
            className={classes.formBlockCardTitle}
        >
            Billing Info:
        </Typography>
    );
    return (
        <>
            {dealType?.billing_type === DealTypeBillingType.Daily && (
                <DailyBillingInfoFormSection
                    titleElement={blockTitle}
                    path={path}
                    feeType={feeType}
                    setFieldValue={setFieldValue}
                />
            )}
            {dealType?.billing_type === DealTypeBillingType.PerFile && (
                <PerFileBillingInfoFormSection
                    titleElement={blockTitle}
                    path={path}
                />
            )}
        </>
    );
};
