import { useDispatch, useSelector } from 'react-redux';
import { selectCcpIsSubmittingInvoice, selectIsOpenCcpSubmitInvoiceModal } from './store/selectors';
import React, { useCallback } from 'react';
import { setOpenCcpSubmitInvoicesModal } from './store/actions';
import { SubmitCcpInvoiceForm } from './SubmitCcpInvoiceForm';
import Modal from '../../../../shared/components/modals/Modal';
import { useCcpModalStyle } from '../../styles';

export const SubmitCcpInvoiceModal = () => {
    const dispatch = useDispatch();
    const isOpened = useSelector(selectIsOpenCcpSubmitInvoiceModal);
    const isLoading = useSelector(selectCcpIsSubmittingInvoice);
    const classes = useCcpModalStyle();
    const onClose = useCallback(() => {
        dispatch(setOpenCcpSubmitInvoicesModal(false));
    }, [dispatch]);
    return (
        <Modal
            title={'Create Invoice Batch'}
            isOpened={isOpened}
            onClose={onClose}
            showCloseIcon={true}
            customClasses={{
                paper: classes.modalPaper,
                title: classes.title,
                content: classes.modalContentNoScroll,
            }}
        >
            <SubmitCcpInvoiceForm
                isLoading={isLoading}
                onClose={onClose}
            />
        </Modal>
    );
};
