import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { ITemplateFormValues } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';
import { selectBackgroundCheckTemplateById } from 'store/entities/configuration/configurationSelectors';
import { setEditBackgroundCheckTemplateId, updateBackgroundCheckTemplate } from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/action';
import { selectEditBackgroundCheckTemplateId, selectIsBackgroundCheckTemplateUpdating } from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/selectors';
import TemplateFormWrapper from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/TemplateFormWrapper';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export default function EditBackgroundCheckModal() {
    const dispatch = useDispatch();
    const id = useSelector(selectEditBackgroundCheckTemplateId);
    const template = useSelector(selectBackgroundCheckTemplateById(id));
    const isLoading = useSelector(selectIsBackgroundCheckTemplateUpdating);

    const onSave = useCallback((data: ITemplateFormValues) => {
        dispatch(updateBackgroundCheckTemplate.init({
            id,
            data,
        }));
    }, [dispatch, id]);

    const classes = useColoredFormModalStyles();
    const onClose = useCallback(() => {
        dispatch(setEditBackgroundCheckTemplateId(null));
    }, [dispatch]);
    return (
        <ModalDialog
            title="Edit Background Check Template"
            isOpened={Boolean(id)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <TemplateFormWrapper
                    onSubmit={onSave}
                    submitTitle="Update"
                    templateLabel="Background checks"
                    isLoading={isLoading}
                    template={template}
                />
            </Box>
        </ModalDialog>
    );
}
