import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import ReasonSelect from 'modules/employeeActionsForm/components/EndAssignment/ReasonSelect';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';
import { EafType } from 'modules/employeeActionsForm/store/models';
import ShowPayRateType from 'modules/offerLetter/components/OfferLetterForm/ShowPayRateType';
import { ICompensationChangeFormValues } from 'modules/subassignmentManagement/components/CompensationChange/store/model';
import { getCompensationChangeValidationSchema } from 'modules/subassignmentManagement/components/CompensationChange/validationSchema';
import { EafSubmitBlock } from 'modules/subassignmentManagement/components/EafFormShared/EafSubmitBlock';
import { EffectiveDateSection } from 'modules/subassignmentManagement/components/EafFormShared/EffectiveDateSection';
import { useEafFormStyles } from 'modules/subassignmentManagement/components/EafFormShared/useEafFormStyles';
import FormField from 'shared/components/formFields/FormField';
import MoneyField from 'shared/components/formFields/MoneyField';
import { PayRateType, PayType } from 'shared/models/PayType';
import { formatPayRate } from 'shared/utils/formatters/payRate';
import { IPaySettings } from 'store/entities/clients/clientsModel';

export interface ICompensationChangeFormProps {
    onSubmit: (values: ICompensationChangeFormValues) => void;
    onCancel: () => void;
    isLoading: boolean;
    paySettings: IPaySettings;
    payRateValue?: string;
    payRateType?: PayRateType;
    payType?: PayType;
    endDate?: string;
    initialValueOverride?: Partial<ICompensationChangeFormValues>;
    readonlyPayRate?: boolean;
    startDate?: string;
}

export function CompensationChangeForm({
    onSubmit,
    onCancel,
    isLoading,
    paySettings,
    payRateType,
    payRateValue,
    payType,
    endDate,
    initialValueOverride,
    readonlyPayRate,
    startDate,
}: ICompensationChangeFormProps) {
    const classes = useEafFormStyles();
    const formClasses = sidebarFormStyles();
    const validationSchema = useMemo(() => getCompensationChangeValidationSchema(paySettings, startDate),
        [paySettings, startDate]);
    const formikData = useFormik({
        initialValues: {
            currentPayRate: formatPayRate({
                pay_rate_value: payRateValue || '',
                pay_rate_type: payRateType || PayRateType.PER_HOUR,
            }, undefined),
            newPayRate: null,
            reason: null,
            effectiveDate: '',
            isRetro: false,
            ...initialValueOverride,
        },
        validationSchema,
        onSubmit,
        validateOnBlur: false,
        validateOnChange: true,
    });

    return (
        <FormikProvider value={formikData}>
            <Form>
                {payRateValue && (
                    <Box className={classes.row}>
                        <label htmlFor="effectiveDate" className={formClasses.inlineInputLabel}>Current Pay Rate:</label>
                        <Box width="130px">
                            <FormField
                                name="currentPayRate"
                                disabled
                                className={formClasses.input}
                            />
                        </Box>
                    </Box>
                )}

                <Box className={classes.row}>
                    <label htmlFor="effectiveDate" className={formClasses.inlineInputLabel}>New Pay Rate:</label>
                    <Box width="130px">
                        <MoneyField
                            name="newPayRate"
                            withDollar
                            className={formClasses.input}
                            disabled={readonlyPayRate}
                        />
                    </Box>
                    <Box ml={3}>
                        <ShowPayRateType type={payRateType} payType={payType}/>
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <label htmlFor="reason" className={formClasses.inlineInputLabel}>Reason:</label>
                    <Box width="250px">
                        <ReasonSelect
                            id="reason"
                            name="reason"
                            type={EafType.CompensationChange}
                            label="Reason"
                            useIdValue
                            className={formClasses.input}
                        />
                    </Box>
                </Box>

                <EffectiveDateSection
                    name="effectiveDate"
                    maxDate={endDate || undefined}
                    paySettings={paySettings}
                    retroFieldName="isRetro"
                />

                <EafSubmitBlock
                    isLoading={isLoading}
                    onCancel={onCancel}
                />
            </Form>
        </FormikProvider>
    );
}
