import { useField } from 'formik';
import React, { useEffect, useRef } from 'react';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { IFormFieldProps } from './models';
import NumberField from './NumberField';

interface IMoneyQuantityProps extends IFormFieldProps {
    notes: string;
}

export default function CompletesQuantity({
    notes,
    ...props
}: IMoneyQuantityProps) {
    const [field,, helper] = useField(props.name);
    const setValueRef = useRef(helper.setValue);

    useEffect(() => {
        const amount = completesByNotes(notes);

        Promise.resolve().then(() => {
            setValueRef.current(amount > 0 ? amount : undefined);
        });
    }, [notes, setValueRef]);

    return (
        <NumberField
            key={field.value}
            {...props}
            disabled
            inputProps={{
                decimalScale: 0,
                allowNegative: false,
                thousandSeparator: false,
            }}
        />
    );
}
