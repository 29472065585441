import { useField } from 'formik';
import React, { useMemo } from 'react';
import NumberInput from 'shared/components/formFields/NumberInput';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import {
    Typography,
    Button,
    Grid,
    FormHelperText,
    Box,
} from '@material-ui/core';
import { QuantityType } from 'shared/models/sheet/Sheet';
import clsx from 'clsx';

interface ITimeEntryFieldProps extends IFormFieldProps {}

const rowMaxOptionsCount = 2;

export default function TimeEntryField({
    name,
}: ITimeEntryFieldProps) {
    const classes = useEntryEditStyles();
    const [field, meta, helper] = useField(name);

    const error = meta.touched ? meta.error : undefined;

    const timeOptions = useMemo(() => [
        { hours: '1', minutes: '30', key: 1 },
        { hours: '1', minutes: '45', key: 2 },
        { hours: '2', minutes: '00', key: 3 },
        { hours: '2', minutes: '15', key: 4 },
    ], []);

    const timeOptionsGrouped = useMemo(() => timeOptions.reduce<Array<Array<typeof timeOptions[0]>>>(
        (acc, item, index) => {
            const arrayIndex = Math.floor(index / rowMaxOptionsCount);
            return [
                ...acc.slice(0, arrayIndex),
                [...(acc[arrayIndex] || []), item],
            ];
        }, [],
    ), [timeOptions]);

    const onTimeOptionSelect = (key: number) => {
        const selectedOption = timeOptions.find(timeOption => timeOption.key === key) as typeof timeOptions[0];
        const hours = parseInt(selectedOption.hours, 10);
        const minutes = parseInt(selectedOption.minutes, 10);

        helper.setValue({
            hours,
            minutes,
            entry_type: QuantityType.TIME,
        });
    };

    return (
        <>
            <Typography
                classes={{ root: classes.timeInputsHeader }}
                color="primary"
                variant="h6"
            >
                Logged Time
            </Typography>
            <div className={classes.timeInputs}>
                <NumberInput
                    initialValue={field.value?.hours}
                    setTouched={helper.setTouched}
                    onChange={hours => helper.setValue({
                        hours: parseInt(hours, 10) || 0,
                        minutes: field.value?.minutes || 0,
                        entry_type: QuantityType.TIME,
                    })}
                    className={classes.timeInput}
                    label="HR."
                    name="hours"
                    min={0}
                    max={24}
                />
                <NumberInput
                    initialValue={field.value?.minutes}
                    setTouched={helper.setTouched}
                    onChange={minutes => helper.setValue({
                        hours: field.value?.hours || 0,
                        minutes: parseInt(minutes, 10) || 0,
                        entry_type: QuantityType.TIME,
                    })}
                    className={clsx(classes.timeInput, classes.timeInputWithMargin)}
                    label="MIN."
                    name="minutes"
                    min={0}
                    max={59}
                />
            </div>
            {error && (
                <Box mb={2} mt={-1}>
                    <FormHelperText error>{error}</FormHelperText>
                </Box>
            )}
            {timeOptionsGrouped.map(group => (
                <Grid container spacing={2}
                    key={group[0].key}>
                    {group.map(timeOption => (
                        <Grid item key={timeOption.key}>
                            <Button onClick={() => onTimeOptionSelect(timeOption.key)} variant="contained"
                                color="primary" classes={{ root: classes.timeOptionButton }}
                            >
                                {timeOption.hours} hr {timeOption.minutes} min
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            ))}
        </>
    );
}
