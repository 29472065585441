import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useAssociatedJobNumbersStyles = makeStyles((theme: Theme) => ({
    root: {
        textIndent: theme.spacing(-2.25),
        paddingLeft: theme.spacing(2.25),
        maxWidth: theme.spacing(50),
    },
}));
