import React from 'react';
import { useParams } from 'react-router-dom';
import ManagerSheetDetails from 'shared/components/sheetApproval/filterAndActionControls/ManagerSheetDetails';
import { EntryType } from 'shared/models/sheet/Sheet';
import { browserHistory } from 'shared/utils/browserHistory';

export interface IManagerSheetDetailPageProps {
    entryType: EntryType;
}

export default function ManagerSheetDetailPage({ entryType }: IManagerSheetDetailPageProps) {
    const { sheetId } = useParams<{ sheetId: string }>();

    const onModalClose = () => {
        browserHistory.push('..');
    };

    return (
        <ManagerSheetDetails
            isOpened
            onClose={onModalClose}
            sheetId={sheetId}
            entryType={entryType}
        />
    );
}
