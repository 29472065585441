import { useMaxLevelApproverIdBySheetId } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/hooks/useMaxLevelApproverIdBySheetId';
import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { useSelector } from 'react-redux';
import { selectUsersById } from 'store/entities/users/selectors';
import { uniq } from 'lodash-es';

export function useManagersList(calculationGroup: ISheetCalculationBatch) {
    const usersByIds = useSelector(selectUsersById);
    const maxLevelApproverIdsBySheetId = useMaxLevelApproverIdBySheetId(calculationGroup);
    const managerIds = uniq(Object.values(maxLevelApproverIdsBySheetId));

    // @ts-ignore
    return managerIds.map(managerUserId => usersByIds[managerUserId]).filter(Boolean);
}
