import {
    call, put, takeEvery,
} from 'typed-redux-saga';

import { expenseApi } from '../api/expenseApi';
import { IFileUpload } from 'shared/models/Attachments';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';

import { addNewAttachment, removeAttachment } from '../actions/entryAttachments';

function* addNewEntryAttachmentSaga(action: ReturnType<typeof addNewAttachment.init>) {
    const entryToUpload: IFileUpload = {
        ...action.payload,
    };
    try {
        const newAttachment = yield* call(expenseApi.createSheetEntryAttachment, entryToUpload);
        yield* put(addNewAttachment.success(newAttachment));
    } catch (e) {
        if (e?.response?.status === 400) {
            yield* put(setGlobalToast({ severity: IModalSeverity.Error, title: 'File not uploaded' }));
            return;
        } else {
            throw e;
        }
    }
}

function* removeEntryAttachmentSaga(action: ReturnType<typeof removeAttachment.init>) {
    yield* call(expenseApi.deleteSheetEntryAttachment, action.payload.id);
    yield put(removeAttachment.success(action.payload.id));
}

export function* addNewEntryAttachmentWatcher(){
    yield takeEvery(addNewAttachment.initType, addNewEntryAttachmentSaga);
}

export function* removeAttachmentWatcher(){
    yield takeEvery(removeAttachment.initType, removeEntryAttachmentSaga);
}

export default [
    addNewEntryAttachmentWatcher,
    removeAttachmentWatcher,
];
