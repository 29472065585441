import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormikConfig } from 'formik/dist/types';
import { IOfferLetterTemplateEditForm } from 'modules/settings/submodules/offerLetters/model';
import { updateOfferLetterTemplate } from 'modules/settings/submodules/offerLetters/store/action';

type HandleSubmit = FormikConfig<IOfferLetterTemplateEditForm>['onSubmit'];

export function useOfferLetterTemplateUpdateHandler(
    offerLetterTemplateId: string | null,
    postSubmitAction?: () => void,
) {
    const dispatch = useDispatch();
    const handleEditFormSubmit = useCallback<HandleSubmit>(values => {
        if (!offerLetterTemplateId || !values) {
            return;
        }
        const client_ids = (
            values.allClients
                ? undefined
                : values.clients?.map(i => i.id) || []
        );
        dispatch(updateOfferLetterTemplate.init({
            id: offerLetterTemplateId,
            data: {
                all_clients: values.allClients,
                client_ids,
                name: values.name,
                status: values.status,
                description: values.description || undefined,
                filename: values.fileName || undefined,
            },
        }));

        if (postSubmitAction) {
            postSubmitAction();
        }
    }, [dispatch, offerLetterTemplateId, postSubmitAction]);

    return handleEditFormSubmit;
}
