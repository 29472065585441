import React, { FC, useEffect, useRef } from 'react';
import { Button } from '@material-ui/core';

interface IEntryButtonFilterProps {
    isActive?: boolean;
    onClick: () => void;
    buttonClass?: string;
    labelClass?: string;
    text: string;
}

const EntryButtonFilter: FC<IEntryButtonFilterProps> = ({
    isActive,
    onClick,
    buttonClass,
    labelClass,
    text,
}: IEntryButtonFilterProps) => {

    const active = isActive ? 'active' : '';
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.blur();
        }
    }, [isActive]);

    return (
        <Button
            ref={buttonRef}
            onClick={onClick}
            classes={{
                root: `${buttonClass} ${active}`,
                label: labelClass,
            }}>
            {text}
        </Button>
    );
};

export default EntryButtonFilter;
