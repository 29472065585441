import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Box, Button, Typography } from '@material-ui/core';
import { CreationMode } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/model';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import FormRadioButtonGroup from 'shared/components/selects/FormRadioButtonGroup';

interface ISelectCreationModeProps {
    setCreationMode: (mode: CreationMode) => void;
}

export const SelectCreationMode = ({ setCreationMode }: ISelectCreationModeProps) => {
    const onSubmit = useCallback(({ mode }) => {
        setCreationMode(mode);
    }, [setCreationMode]);

    const formikData = useFormik({
        initialValues: {
            // @ts-ignore
            mode: null,
        },
        onSubmit,
    });

    return (
        <Box maxWidth={640}>
            <Typography>
                Would you like to create sub-assignments with all the same values
                (one form only) for all the selected employees,
                or create sub-assignments with all different values (one form per employee)?
            </Typography>

            <FormikProvider value={formikData}>
                <form onSubmit={formikData.handleSubmit}>
                    <EnumFormSelect
                        id="mode"
                        name="mode"
                        values={CreationMode}
                        displayValues={{
                            [CreationMode.SameValues]: 'Same values for all employees (fill out one form)',
                            [CreationMode.DifferentValues]: 'Different values for all employees (fill out one form per person)',
                        }}
                        InputComponent={FormRadioButtonGroup}
                    />

                    <Box mt={7}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >
                            Next
                        </Button>
                    </Box>
                </form>
            </FormikProvider>
        </Box>
    );
};
