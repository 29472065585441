import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { UpperText } from 'modules/settings/submodules/components/HierarchyPage/UpperText/UpperText';
import { SaveHierarchyButton } from 'modules/settings/submodules/components/HierarchyPage/SaveHierarchyButton/SaveHierarchyButton';
import { useHierarchyPageStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientHasAssignments } from 'store/entities/clients/selectors/clientsSelectors';
import { getCustomFields } from 'store/entities/customFields/actions';
import { isDefaultHierarchyPageLoadingSelector } from './store/selectors';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';

interface IHierarchyPageContent {
    children: React.ReactNode;
}

export const HierarchyPageContent = ({ children }: IHierarchyPageContent) => {
    const hierarchyPageClasses = useHierarchyPageStyles();

    const isLoading = useSelector(isDefaultHierarchyPageLoadingSelector);
    const currentClientHasAssignments = useSelector(selectCurrentClientHasAssignments);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCustomFields.init());
    }, [dispatch]);

    return (
        <>
            <Box mb={2}>
                <UpperText/>
            </Box>

            {
                currentClientHasAssignments && (
                    <Box margin={2}>
                        <WarningAlertWithIcon>
                            {'You can modify only anchor on the custom field hierarchy because the client has active employee'}
                        </WarningAlertWithIcon>
                    </Box>
                )
            }

            {
                isLoading
                    ? (
                        <Box className={hierarchyPageClasses.spinnerWrapper}>
                            <CircularProgress/>
                        </Box>
                    )
                    : (
                        <Box>
                            {children}
                            <SaveHierarchyButton/>
                        </Box>
                    )
            }
        </>
    );
};
