import { useMemo } from 'react';
import { ValidationMessages } from 'shared/models/Validation';
import * as yup from 'yup';

export const useEditEndDateValidationSchema = () => {
    return useMemo(() => {
        return yup.object({
            effective_date: yup.string().nullable().required(ValidationMessages.REQUIRED),
        });
    }, []);
};
