import clsx from 'clsx';
import React from 'react';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

interface IPlainTextProps {
    className?: string;
    value?: string | number;
    title?: string;
    onClick?: () => void;
}

export const usePlainTextStyles = makeHighPriorityStyles({
    textEllipsis: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
    clickable: {
        cursor: 'pointer',
    },
});

export default function PlainText({ value = '', title, onClick, className }: IPlainTextProps) {
    const classes = usePlainTextStyles();

    return (
        <div
            className={clsx(className, { [classes.clickable]: Boolean(onClick) })}
            title={title}
            onClick={onClick}
        >
            <span className={classes.textEllipsis}>
                {value}
            </span>
        </div>
    );
}
