import { ILatestPayRatesReportQuery } from 'modules/settings/submodules/clients/reports/store/models';
import baseApi from 'shared/utils/baseApi';
import { withClientId } from 'store/utils/withClientId';

export const reportsApi = {
    async downloadLatestSubassignmentPayRatesReportCsv(
        request: ILatestPayRatesReportQuery,
    ): Promise<BlobPart> {
        const { data } = await baseApi.get<BlobPart>(
            `/client-users/report/latest_subassignments/download_csv`,
            withClientId(request),
            {
                responseType: 'blob',
            },
        );
        return data;
    },
};
