import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { orderBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import FormAutocomplete from 'shared/components/autocomplete/FormAutocomplete';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import { searchUsers, resetSearchUser } from 'store/entities/users/actions';
import { IGetUserRequest } from 'store/entities/users/model';
import SearchIcon from '@material-ui/icons/Search';
import { selectIsUserSearching, selectSearchResultUsers, selectUsersById } from 'store/entities/users/selectors';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useAutocompleteWithSearchStyles } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/styles';
import { debounce } from 'ts-debounce';

interface IUserAutocompleteWithSearchProps extends IFormFieldProps {
    additionalFilter?: Partial<IGetUserRequest>;
    useIdValue?: boolean;
    multiple?: boolean;
}

export const UserAutocompleteWithSearch = ({
    useIdValue = false,
    additionalFilter = {},
    ...props
}: IUserAutocompleteWithSearchProps) => {
    const dispatch = useDispatch();
    const classes = useAutocompleteWithSearchStyles();
    const searchKey = [
        'searchKey',
        ...Object.values(additionalFilter),
    ].join('_');
    const usersByIds = useSelector(selectUsersById);
    const users = useSelector(selectSearchResultUsers(searchKey));
    const isLoading = useSelector(selectIsUserSearching(searchKey));
    const [field,, helper] = useField(props.name);

    const getOptionText = useCallback((user: IUserInfo) => getUserName(user), []);

    const options = useMemo(() => {
        let selectedUsers;
        if (props.multiple) {
            selectedUsers = useIdValue ? field.value.map(id => usersByIds[id]).filter(Boolean) : field.value;
        } else {
            selectedUsers = useIdValue ? [usersByIds[field.value]] : [field.value];
        }

        const filteredUsersIds = users.map(user => user?.id).filter(Boolean);
        return orderBy(
            [...users, ...selectedUsers.filter(user => !filteredUsersIds.includes(user?.id))].filter(Boolean),
            user => getOptionText(user),
        );
    }, [field.value, props.multiple, useIdValue, users, usersByIds, getOptionText]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchUser = useCallback(debounce((value: string) => {
        const minLettersCount = 2;
        if (value && value.length >= minLettersCount) {
            dispatch(searchUsers.init({
                searchKey,
                request: {
                    ...additionalFilter,
                    name: value,
                },
            }));
        }
    }, 300), [dispatch, additionalFilter, searchKey]);

    const onInputChange = useCallback((_: any, value: string) => {
        if (!props.multiple && field.value) {
            const selectedUser = useIdValue ? usersByIds[field.value] : field.value;
            if (selectedUser) {
                if (getUserName(selectedUser) !== value) {
                    helper.setValue(null);
                    dispatch(resetSearchUser(searchKey));
                }
                return;
            }
        }
        searchUser(value);
    }, [dispatch, searchUser, field.value, props.multiple, useIdValue, usersByIds, helper, searchKey]);

    return (
        <FormAutocomplete
            getText={getOptionText}
            // @ts-ignore
            getKey={useIdValue ? (user: IUserInfo) => user.id : null }
            options={options}
            onInputChange={onInputChange}
            isLoading={isLoading}
            popupIcon={<SearchIcon color="secondary" fontSize="default"/>}
            freeSolo
            endAdornment={<SearchIcon color="secondary" fontSize="default"/>}
            classes={{
                inputRoot: classes.inputRoot,
                endAdornment: classes.endAdornment,
            }}
            {...props}
        />
    );
};
