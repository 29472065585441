import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { PaginationCounter } from 'modules/settings/submodules/components/PaginationCounter/PaginationCounter';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useIsMobile } from 'shared/utils/hooks/media';
import { IStore } from 'store/configureStore';
import { ActionCreatorKnownArgs } from 'store/utils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { Box } from '@material-ui/core';
import { useCcpTableStyles } from '../../styles';
import { ICcpTransactionRow } from './store/selectors';
import { selectCcpCheckedTransactions } from '../CcpInvoices/store/selectors';
import { setCcpCheckedItems } from '../../store/actions';
import { selectIsUserHasPermission } from '../../../../store/components/auth/selectors';
import { Permission } from '../../../../store/components/auth/authModels';
import { selectCcpEditMode, selectCcpTransactionsActiveTab } from '../../store/selectors';
import { allCcpSlug, CcpStatusSlug, invoicedCcpSlug } from '../../models/ccpModel';
import { useCcpCheckedItemsStore } from './UseCcpCheckedItems';
import { getCcpCheckboxCell } from './CcpCheckBoxHelper';

export interface ICcpInfinityTablePureProps {
    infinityTableSelector: ReturnType<typeof getInfinityScrollSelectors>,
    rowsSelector: (state: IStore) => ICcpTransactionRow[];
    initialLoadAction: ActionCreatorKnownArgs<void, any>;
    getMoreAction: ActionCreatorKnownArgs<void, any>;
    cells: ICellInfo<ICcpTransactionRow>[];
}

const containerId = 'ccp_InfinityContainerId';

export function CcpInfinityTablePure({
    infinityTableSelector,
    rowsSelector,
    initialLoadAction,
    getMoreAction,
    cells,
}: ICcpInfinityTablePureProps) {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const tableClasses = useCcpTableStyles();

    const rows = useSelector(rowsSelector) as ICcpTransactionRow[];
    const hasMoreRows = useSelector(infinityTableSelector.selectHasMore);
    const total = useSelector(infinityTableSelector.selectTotalItems);
    const isLoading = useSelector(infinityTableSelector.isLoading);
    const selectedTab = useSelector(selectCcpTransactionsActiveTab);
    const hasInvoicePermission = useSelector(selectIsUserHasPermission(Permission.CanWorkWithCcpInvoices),
        shallowEqual);
    const hasCanViewCcpAllPermission = useSelector(selectIsUserHasPermission(Permission.CanViewCcpAll), shallowEqual);
    const isEditMode = useSelector(selectCcpEditMode, shallowEqual);

    const ccpTableStyle = (hasCanViewCcpAllPermission && (selectedTab === CcpStatusSlug.Reconciled
        || selectedTab === CcpStatusSlug.Unreconciled))
        ? tableClasses.maxHeightTableReduced : tableClasses.maxHeightTable;
    const tableStyle = selectedTab === invoicedCcpSlug || (!hasCanViewCcpAllPermission && selectedTab === allCcpSlug)
        ? tableClasses.maxHeightTableExtended : ccpTableStyle;

    const checkItemsActions = useCcpCheckedItemsStore(
        rows,
        selectCcpCheckedTransactions,
        setCcpCheckedItems,
    );

    const checkboxCell = useMemo(() => {
        return getCcpCheckboxCell(
            checkItemsActions.checkedItems,
            checkItemsActions.onCheck,
            checkItemsActions.checkedAll,
            checkItemsActions.onCheckAll,
            tableClasses,
            isMobile,
            row => row.id,
            [],
        );
    }, [checkItemsActions, isMobile, tableClasses]);

    const currentCells = useMemo(() => {
        return hasInvoicePermission && (selectedTab === CcpStatusSlug.Reconciled) ? [checkboxCell, ...cells] : cells;
    }, [checkboxCell, cells, hasInvoicePermission, selectedTab]);

    useWithClientEffect(() => {
        dispatch(initialLoadAction());
    }, [dispatch, initialLoadAction]);
    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows && !isEditMode){
            dispatch(getMoreAction());
        }
    }, [dispatch, getMoreAction, isEditMode, hasMoreRows]);

    return (
        <Box>
            <Box
                id={containerId}
                className={tableStyle}
            >
                <InfinityGridTable
                    rowData={rows}
                    getKey={row => row.id}
                    cells={currentCells}
                    headerCellClassName={tableClasses.headCell}
                    bodyCellClassName={tableClasses.bodyCell}
                    className={tableClasses.tableMain}
                    hideHeader={isMobile}
                    stickyHeader
                    isLoading={isLoading}
                    onLoadMore={onLoadMoreRows}
                    hasMore={hasMoreRows}
                    infiniteScrollProps={{
                        scrollableTarget: containerId,
                    }}
                />
            </Box>
            <Box className={tableClasses.totalsContainer}>
                <PaginationCounter
                    shown={rows.length}
                    total={total}
                />
            </Box>
        </Box>
    );
}
