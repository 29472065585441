import { OutlinedInputProps } from '@material-ui/core/OutlinedInput/OutlinedInput';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import ChipInput from 'material-ui-chip-input';
import { useField } from 'formik';

import { inputParams } from 'shared/styles/constants';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { IFormFieldProps } from './models';

export interface ITextTagFieldProps extends IFormFieldProps, Partial<OutlinedInputProps> {
    name: string;
    label?: string;
    defaultInputProps?: boolean;
}

export const TextTagField = ({
    name,
    label,
    outerLabel,
    id = String(name),
    className,
    disabled = false,
    defaultInputProps = true,
    ...props
}: ITextTagFieldProps) => {
    const [field, meta, helper] = useField(name);
    const inputProps = defaultInputProps ? inputParams : {};
    const formHelperTextClasses = useFormHelperTextStyles();

    const handleAdd = useCallback((chip: string) => {
        const prevValue = Array.isArray(field.value) ? field.value : [];
        helper.setValue([...prevValue, chip]);
    }, [field.value, helper]);

    const handleDelete = useCallback((chip: string) => {
        const prevValue = Array.isArray(field.value) ? field.value : [];
        helper.setValue(prevValue.filter(item => item !== chip));
    }, [field.value, helper]);

    const hasError = Boolean(meta.error && meta.touched);
    return (
        <ChipInput
            InputProps={{
                inputProps: {
                    autocomplete: 'off',
                    ...inputProps,
                    ...props.inputProps,
                },
                multiline: false,
            }}
            FormHelperTextProps={{
                className: clsx({ [formHelperTextClasses.chipInputFormHelperTextActive]: hasError }),
            }}
            blurBehavior="add"
            className={className}
            id={id}
            variant="outlined"
            value={field.value || []}
            onAdd={handleAdd}
            onDelete={handleDelete}
            disabled={disabled}
            error={hasError}
            helperText={hasError ? meta.error : undefined}
            key={id}
            allowDuplicates={false}
            alwaysShowPlaceholder={false}
            label={label}
        />
    );
};
