import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { useSheetRejectStyles } from 'shared/components/sidebars/SheetReject/styles';
import { useStyles } from 'shared/components/auth/AuthStyles';
import clsx from 'clsx';
import { PayRateType } from 'shared/models/PayType';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { selectIsCustomFieldsApplied } from 'store/entities/clients/selectors/configurationSelectors';
import { getOfferLabel } from '../../store/utils';
import { IOfferLetter, RescissionReasons } from '../../store/model';
import FormField from 'shared/components/formFields/FormField';
import FormSelect from 'shared/components/selects/FormSelect';
import { getDisplayCustomFieldsValues } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { useSelector } from 'react-redux';
import {
    selectCustomFieldValuesByIds,
    selectOrderedOfferLetterCustomFields,
} from 'store/entities/customFields/selectors';

interface IOfferLetterRescissionPanelProps {
    offerLetter: IOfferLetter;
    hasCustomReason: boolean;
}

const getOfferLetterPayRateLabel = (offerLetter: IOfferLetter): string => {
    const payRateTypeLabel = (() => {
        switch (offerLetter.pay_rate_type) {
            case PayRateType.DAILY:
                return 'day';
            case PayRateType.PER_HOUR:
                return 'hr';
            case PayRateType.PER_WEEK:
                return 'week';
            case PayRateType.MONTHLY:
                return 'month';
            case PayRateType.SEMI_MONTHLY:
                return 'semi month';
            case PayRateType.BI_WEEKLY:
                return 'bi week';
        }
    })();
    return `${formatDollars(offerLetter.pay_rate_value)}/${payRateTypeLabel}`;
};

/**
 * @todo Need to be produced as shared component with src/shared/components/sidebars/SheetReject/SheetRejectNotes.tsx
 */
export default function OfferLetterRescissionPanel({ offerLetter, hasCustomReason }: IOfferLetterRescissionPanelProps) {
    const classes = useSheetRejectStyles();
    const sidebarClasses = useSidebarStyles();
    const inputClasses = useStyles();
    const offerLetterFields = useSelector(selectOrderedOfferLetterCustomFields);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const useCustomFields = useSelector(selectIsCustomFieldsApplied);

    const displayCustomFieldValues = getDisplayCustomFieldsValues(
        offerLetterFields,
        customFieldValuesByIds,
        [offerLetter.department_value_id, offerLetter.position_value_id, offerLetter.location_value_id],
    );

    return (
        <section className={clsx(sidebarClasses.sidebarElement, classes.rejectionCard)}>
            <Typography variant="h6" className={classes.nameOverflowed}>{getOfferLabel(offerLetter)}</Typography>
            <Box display="flex" justifyContent="space-between">
                <Box>
                    {useCustomFields ? offerLetterFields.map(field => (
                        <Typography variant="body2" key={field.id}>
                            {displayCustomFieldValues[field.id]}
                        </Typography>
                    )) : (
                        <>
                            <Typography variant="body2">
                                {offerLetter.position?.name}
                            </Typography>
                            <Typography variant="body2">
                                {offerLetter.location?.name}
                            </Typography>
                        </>
                    )}
                </Box>
                <Box>
                    <Typography variant="subtitle1">
                        {getOfferLetterPayRateLabel(offerLetter)}
                    </Typography>
                </Box>
            </Box>
            <section className={classes.notes}>
                <FormSelect
                    className={classes.formInput}
                    name="rescissionReason"
                    label="Reason for rescind"
                    getKey={reason => reason.value}
                    getText={reason => reason.label}
                    options={RescissionReasons}
                    useIdValue
                />
                {hasCustomReason && (
                    <FormField
                        name="rescissionReasonText"
                        label="Reason for rescission"
                        className={clsx(classes.formInput, inputClasses.input)}
                        multiline
                        rows={2}
                        rowsMax={4}
                    />
                )}
            </section>
        </section>
    );
}
