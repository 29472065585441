import { combineReducers } from 'redux';
import { initialPaginationState, IPaginationState } from 'shared/models/IPaginationResponse';
import { ISheetApproval } from 'shared/models/sheet/Sheet';
import { Order, SortingOrder } from 'shared/models/Order';
import {
    defaultInfinityScrollState,
    IInfinityScrollState,
    isLoadingReducer,
    resetStateReducer,
    singleValueReducer,
} from 'store/reducerUtils';
import {
    IGroupedSheetCalculation, IPayrollPayPeriod,
    IPayrollProcessorFilters,
    IPayrollSheetSummary,
    IPostPayroll,
    IPrePayroll, ISheetCalculationBatch,
    PayrollSheetTabs,
} from 'modules/payrollProcessorHub/store/model';
import {
    changeCalculationGroupStatus,
    changeSheetGroupStatus,
    CLEAR_PAYROLL_REPORT,
    getGroupedSheetCalculationPdf,
    getGroupedSheetCalculations, getGroupSheetApprovals,
    getMoreGroupedSheetCalculationBatches,
    getPayrollEditCalculationGroup,
    getPayrollPayPeriods,
    getPrePayrollReport,
    getSheetEditInfo,
    getSheetSummary,
    initSheetGroupPayroll,
    loadGroupedSheets,
    PayrollProcessorActions,
    SET_POST_PAYROLL_REPORT,
    setGroupedCalculationCheckedItems,
    setGroupedTimeExpenseCalculationCheckedItems,
    setPayrollDetailCalculationGroupId,
    setPayrollEditCalculationGroupId,
    setPayrollProcessorFilter,
    setPayrollProcessorSort,
} from 'modules/payrollProcessorHub/store/actions';
import { ActionsReturnTypes } from 'store/utils';
import { extendReducer } from 'store/utils/reducers/extendReducer';
import { downloadSheetPdfLoadingModule, sendReminderLoadingModule } from './loadingModules';
import { uniq } from 'lodash-es';

export const pphFilterDefaultValues: IPayrollProcessorFilters = {
    status: PayrollSheetTabs.APPROVED,
    payPeriodEnd: null,
    assignmentId: null,
    clientId: null,
    dealId: null,
    employeeId: null,
    jobNumber: '',
    payrollProcessorUserId: null,
    managerIds: [],
    custom_fields: null,
    last_editor: null,
};

export const initialState = sendReminderLoadingModule.withInitialValue(
    downloadSheetPdfLoadingModule.withInitialValue({
        filter: pphFilterDefaultValues,
        groupedSheets: {
            isLoading: false,
            pagination: {
                ...initialPaginationState,
                page: 0,
            },
            groups: [],
        },
        sheetSummary: {
            isLoading: false,
            summary: null,
        },
        payRoll: {
            isProcessing: false,
            prePayrollReport: null,
            postPayrollReport: null,
        },
        pdfDownloadingItemsIds: [],
    }),
);

export function pdfDownloadingItemsIds(
    state: Array<string> = initialState.pdfDownloadingItemsIds,
    action: PayrollProcessorActions,
): Array<string> {
    switch (action.type) {
        case getGroupedSheetCalculationPdf.initType: {
            return uniq([...state, action.payload.id]);
        }
        case getGroupedSheetCalculationPdf.successType: {
            return state.filter(i => i !== action.payload);
        }
        case getGroupedSheetCalculationPdf.errorType: {
            return state.filter(i => i !== action.payload.id);
        }
        default: {
            return state;
        }
    }
}

export function filter(
    state: IPayrollProcessorFilters = initialState.filter,
    action: PayrollProcessorActions,
): IPayrollProcessorFilters {
    switch (action.type) {
        case setPayrollProcessorFilter.action:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export function pagination(
    state: IPaginationState = initialState.groupedSheets.pagination,
    action: PayrollProcessorActions,
): IPaginationState {
    switch (action.type) {
        case loadGroupedSheets.action:
        case changeSheetGroupStatus.successType:
        case changeCalculationGroupStatus.successType:
        case getSheetEditInfo.initType:
        case setPayrollProcessorFilter.action:
        case setPayrollProcessorSort.action:
            return initialState.groupedSheets.pagination;
        case getMoreGroupedSheetCalculationBatches.successType:
        case getGroupedSheetCalculations.successType: {
            const { page, page_size, total_items } = action.payload;
            return {
                page: page,
                page_size,
                total_items,
            };
        }
        default:
            return state;
    }
}

export function groups(
    state: IGroupedSheetCalculation[] = initialState.groupedSheets.groups,
    action: PayrollProcessorActions,
): IGroupedSheetCalculation[] {
    switch (action.type) {
        case loadGroupedSheets.action:
        case changeSheetGroupStatus.successType:
        case changeCalculationGroupStatus.successType:
        case setPayrollProcessorFilter.action:
        case setPayrollProcessorSort.action:
            return initialState.groupedSheets.groups;
        case getGroupedSheetCalculations.successType: {
            const { items } = action.payload;
            return [
                ...state,
                ...items,
            ];
        }
        default:
            return state;
    }
}

export function summary(
    state: IPayrollSheetSummary | null = initialState.sheetSummary.summary,
    action: PayrollProcessorActions,
): IPayrollSheetSummary | null {
    switch (action.type) {
        case loadGroupedSheets.action:
        case setPayrollProcessorFilter.action:
            return initialState.sheetSummary.summary;
        case getSheetSummary.successType:
            return action.payload;
        default:
            return state;
    }
}

export function prePayrollReport(
    state: IPrePayroll | null = initialState.payRoll.prePayrollReport,
    action: PayrollProcessorActions,
): IPrePayroll | null {
    switch (action.type) {
        case CLEAR_PAYROLL_REPORT:
        case getPrePayrollReport.initType:
            return initialState.payRoll.prePayrollReport;
        case getPrePayrollReport.successType:
            return action.payload;
        default:
            return state;
    }
}

export function postPayrollReport(
    state: IPostPayroll | null = initialState.payRoll.postPayrollReport,
    action: PayrollProcessorActions,
): IPostPayroll | null {
    switch (action.type) {
        case CLEAR_PAYROLL_REPORT:
        case initSheetGroupPayroll.initType:
        case initSheetGroupPayroll.successType:
            return initialState.payRoll.postPayrollReport;
        case SET_POST_PAYROLL_REPORT:
            return action.payload;
        default:
            return state;
    }
}

const isLoading = extendReducer(
    isLoadingReducer(getGroupedSheetCalculations),
    isLoadingReducer(getMoreGroupedSheetCalculationBatches),
);
const isLoadingSummary = isLoadingReducer(getSheetSummary);
const isLoadingEditSheet = isLoadingReducer(getSheetEditInfo);
const isPayRollLoading = isLoadingReducer(initSheetGroupPayroll);
const isPreInitializeReportLoading = isLoadingReducer(getPrePayrollReport);
const payPeriods = extendReducer(
    singleValueReducer(getPayrollPayPeriods.successType, [] as IPayrollPayPeriod[]),
    resetStateReducer(getPayrollPayPeriods.initType, [] as IPayrollPayPeriod[]),
);
const payPeriodsLoading = isLoadingReducer(getPayrollPayPeriods);

const groupedSheetCalculationTable = (
    state: IInfinityScrollState<ISheetCalculationBatch>,
    action: PayrollProcessorActions,
): IInfinityScrollState<ISheetCalculationBatch> => {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case loadGroupedSheets.action:
        case setPayrollProcessorFilter.action:
        case setPayrollProcessorSort.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreGroupedSheetCalculationBatches.initType:
            return {
                ...state,
                isLoading: true,
            };
        case getMoreGroupedSheetCalculationBatches.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreGroupedSheetCalculationBatches.successType:
            return {
                ...state,
                total: action.payload.total_items,
                items: [...state.items, ...action.payload.items],
                isLoading: false,
                cursor: action.payload.cursor,
            };
        case changeCalculationGroupStatus.successType: {
            return {
                ...state,
                items: [],
                isLoading: false,
                cursor: null,
            };
        }
        default:
            return state;
    }
};

const isLoadingApprovals = isLoadingReducer(getGroupSheetApprovals);
const approvalsByGroupId = (
    state: Record<string, ISheetApproval[]>,
    action: ActionsReturnTypes<typeof getGroupSheetApprovals>,
): Record<string, ISheetApproval[]> => {
    state = state || {};
    switch (action.type) {
        case getGroupSheetApprovals.successType:
            return {
                ...state,
                // @ts-ignore
                [action.payload.groupId]: action.payload.approvals,
            };
        default:
            return state;
    }
};

const checkedTimeExpenseCalculationGroups = singleValueReducer(setGroupedTimeExpenseCalculationCheckedItems.action, {});
const checkedCalculationGroups = singleValueReducer(setGroupedCalculationCheckedItems.action, {});

const detailGroupId = singleValueReducer(setPayrollDetailCalculationGroupId.action, null);
const editGroupId = singleValueReducer(setPayrollEditCalculationGroupId.action, null);
const isLoadingEditGroupInfo = isLoadingReducer(getPayrollEditCalculationGroup);

const payrollProcessorSort = singleValueReducer<SortingOrder>(
    setPayrollProcessorSort.action,
    { pay_period_ends_at: Order.desc },
);

export const payrollProcessorHub = combineReducers(
    sendReminderLoadingModule.withReducer(
        downloadSheetPdfLoadingModule.withReducer({
            filter,
            pdfDownloadingItemsIds,
            groupedSheetCalculationTable,
            detailGroupId,
            editGroupId,
            isLoadingEditGroupInfo,
            checkedTimeExpenseCalculationGroups,
            checkedCalculationGroups,
            groupedSheets: combineReducers({
                isLoading,
                pagination,
                groups,
            }),
            sheetSummary: combineReducers({
                isLoading: isLoadingSummary,
                summary,
            }),
            sheetEditInfo: combineReducers({
                isLoading: isLoadingEditSheet,
            }),
            payRoll: combineReducers({
                isProcessing: isPayRollLoading,
                isPreInitializeReportLoading,
                prePayrollReport,
                postPayrollReport,
            }),
            payPeriods,
            payPeriodsLoading,
            isLoadingApprovals,
            approvalsByGroupId,
            payrollProcessorSort,
        }),
    ),
);
