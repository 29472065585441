import { useCallback } from 'react';
import { IAddressComponents } from 'shared/components/formSpecialFields/addressAutocompleteField/models';

export function useHandleAddressSelect(setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) {
    return useCallback((address: IAddressComponents) => {
        setFieldValue('county', address.county);
        setFieldValue('state', address.stateCode);
        setFieldValue('city', address.city);
        setFieldValue('zip_code', address.zip_code);
        setFieldValue('address', `${address.street_number} ${address.street}`);
    }, [setFieldValue]);
}
