import {
    changeSheetGroupStatus,
    getGroupedSheetPdf,
    getPayrollPayPeriods,
    getPrePayrollReport,
    getSheetEditInfo,
    getSheetSummary,
    initSheetGroupPayroll,
    loadGroupedSheets,
    sendSheetGroupReminder,
    setPostPayrollReports,
    setPayrollProcessorFilter,
    unlockSheet,
    getMoreGroupedSheetCalculationBatches,
    getPayrollEditCalculationGroup,
    setPayrollEditCalculationGroupId,
    changeCalculationGroupStatus,
    setPayrollDetailCalculationGroupId,
    getGroupedSheetCalculationPdf,
    getGroupSheetApprovals,
    getPayrollErrorsCsv,
    setPayrollProcessorSort,
    getCFieldByPrism,
} from 'modules/payrollProcessorHub/store/actions';
import { payrollProcessorHubApi } from 'modules/payrollProcessorHub/store/api';
import { uniq, pick, isEmpty } from 'lodash-es';
import {
    getSheetStatusByTab,
    ICalculationsGroupWithNote,
    IExpenseCalculationEntry,
    IGroupedSheetCalculation,
    IGroupedSheetCalculationRequest,
    IGroupedSheetPayrollRequest,
    IGroupedSheetSummaryRequest,
    IPostPayroll,
    ISheetGroupId,
    ISheetGroupIdRequest,
    ISheetsForPostPayroll,
    ITimeCalculationEntry,
} from 'modules/payrollProcessorHub/store/model';
import {
    selectDetailCalculationGroup,
    selectEditCalculationGroup,
    selectGroupedSheetsPagination,
    selectPayrollFilter, selectPayrollProcessorSort, selectPostPayrollReport,
    selectSheetsGroupById,
    selectSheetsGroupsByIds,
} from 'modules/payrollProcessorHub/store/selectors';
import { fileTimestampFormat } from 'shared/models/Dates';
import { EntryType, IExpenseSheetBackend, ISheetCommonBackend, ITimeSheetBackend } from 'shared/models/sheet/Sheet';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { getLastFirstName } from 'shared/utils/converters/user';
import { getClientFieldsConfiguration, setClientId } from 'store/entities/clients/clientsAction';
import {
    selectAllClientsById,
    selectCurrentClientId,
    selectFieldConfiguration,
} from 'store/entities/clients/selectors/clientsSelectors';
import { selectClientTimeAndPayConfigurationByClientId } from 'store/entities/clients/selectors/timeAndPaySelectors';
import {
    getActivities,
    getAssignments,
    getJobNumbers,
    getProjectsAssignments,
    getSubassignments,
    getSubmittingOrgs,
    loadClientAssignmentsWithLinked,
    searchSubassignments,
} from 'store/entities/configuration/configurationAction';
import { selectSubassignmentsByIds, selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import {
    getCustomFields,
    getCustomFieldsHierarchyNodes,
    getCustomFieldValues,
    queryCustomFieldValues,
} from 'store/entities/customFields/actions';
import { CustomFieldType } from 'store/entities/customFields/model';
import {
    selectAllNotDeletedCustomFieldValues,
    selectCustomFieldsByIds,
    selectCustomFieldValuesByIds,
} from 'store/entities/customFields/selectors';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';
import { timeApi } from 'store/entities/timesheet/api/timeApi';
import {
    ICalculation,
    IExpenseSheetCalculation,
    ITimesheetCalculation,
} from 'store/entities/timesheet/models/Calculation';
import { IUpdateSheetStatus, StatusNames } from 'store/entities/timesheet/models/Status';
import { getUsers } from 'store/entities/users/actions';
import { IUsersById } from 'store/entities/users/reducers';
import { selectUsersById } from 'store/entities/users/selectors';
import { getDownloadCsvSagaWatcher } from 'store/utils/sagas/downloadCsvFileSaga';
import { downloadFileSaga } from 'store/utils/sagas/downloadFileSaga';
import { optionalLoadEntitiesByIdsSaga } from 'store/utils/sagas/optionalLoadEntitiesByIdsSaga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    all, call, put, select, takeLatest,
} from 'typed-redux-saga';
import { navigateWithClientSaga } from 'store/components/router/routerSagas';
import { moment } from 'utils/momentExtensions';
import { notificationApi } from '../../notificationCenter/store/api';
import { decreaseSyncing, increaseSyncing, setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { postPayrollResponseMapper, prePayrollResponseMapper } from './mappers';
import { sheetStatusMessage } from 'shared/utils/formatters/sheetStatus';
import { showField } from 'shared/components/forms/utils';
import { EntrySlug, IClient } from 'store/entities/clients/clientsModel';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import { Order } from 'shared/models/Order';

const getPayPeriodValueOrDefault = (payPeriod: string | null): string | undefined => {
    if (payPeriod) {
        return payPeriod;
    }
    return undefined;
};

function* getRequestFilter() {
    const sort = yield select(selectPayrollProcessorSort);
    const { page, page_size } = yield select(selectGroupedSheetsPagination);
    const {
        clientId,
        employeeId,
        status,
        payPeriodEnd,
        dealId,
        jobNumber,
        managerIds,
        custom_fields,
        last_editor,
    } = yield select(selectPayrollFilter);
    const request: IGroupedSheetCalculationRequest = {
        page_number: page + 1,
        page_size,
        client_id: clientId,
        user_id: employeeId,
        payroll_status: getSheetStatusByTab(status),
        pay_period_ends_at: getPayPeriodValueOrDefault(payPeriodEnd),
        deal_number_id: dealId || undefined,
        job_number: jobNumber?.length ? jobNumber : undefined,
        latest_approver_user_ids: isEmpty(managerIds) ? undefined : managerIds,
        sort: Object.entries(sort)[0].reverse() as string[],
        custom_fields: custom_fields ? [custom_fields as string] : undefined,
        last_editor: last_editor ? last_editor : undefined,
    };
    return request;
}

export function* loadGroupedSheetCalculationBatchesSaga() {
    const request = yield* getRequestFilter();
    const response = yield* call(payrollProcessorHubApi.getGroupedSheetCalculationBatches, request);
    const userIdsSet: Set<string | null> = new Set<string | null>();
    const assignmentIdsSet: Set<string | null> = new Set<string | null>();
    const subassignmentIdsSet: Set<string | null> = new Set<string | null>();
    const fieldValuesIdsSet: Set<string | null> = new Set<string | null>();
    response.items.forEach(group => {
        userIdsSet.add(group.user_id);
        [...group.time_calculations, ...group.expense_calculations].forEach(calculation => {
            userIdsSet.add(calculation.latest_approver_user_id);
            if (calculation.updated_by) {
                userIdsSet.add(calculation.updated_by);
            }
            assignmentIdsSet.add(calculation.assignment_id);
            subassignmentIdsSet.add(calculation.subassignment_id);
            // @ts-ignore
            calculation.entries?.map(
                (entry: IExpenseCalculationEntry | ITimeCalculationEntry) => entry.custom_field_value_ids,
            ).flat().forEach(fieldValuesIdsSet.add, fieldValuesIdsSet);
        });
        group.id = JSON.stringify(
            pick(group, ['user_id', 'client_id', 'payroll_status', 'pay_period_starts_at', 'pay_period_ends_at']),
        );
    });
    yield put(getMoreGroupedSheetCalculationBatches.success(response));
    yield all([
        call(
            // @ts-ignore
            optionalLoadEntitiesByIdsSaga,
            [...userIdsSet],
            selectUsersById,
            getUsers,
            userIds => ({ ids: userIds.join(',') }),
        ),
        call(
            // @ts-ignore
            optionalLoadEntitiesByIdsSaga,
            [...assignmentIdsSet],
            selectAssignmentsById,
            loadClientAssignmentsWithLinked,
        ),
        call(
            // @ts-ignore
            optionalLoadEntitiesByIdsSaga,
            [...subassignmentIdsSet],
            selectSubassignmentsByIds,
            getSubassignments,
        ),
        call(
            // @ts-ignore
            optionalLoadEntitiesByIdsSaga,
            [...fieldValuesIdsSet],
            selectCustomFieldValuesByIds,
            queryCustomFieldValues,
            ids => ({
                custom_field_value_ids: ids,
                field_type: CustomFieldType.Position,
            }),
        ),
    ]);
    const customFieldValues = yield select(selectAllNotDeletedCustomFieldValues);
    // @ts-ignore
    const fieldsIds = new Set(customFieldValues.map(value => value.custom_field_id));
    yield* call(
        // @ts-ignore
        optionalLoadEntitiesByIdsSaga,
        [...fieldsIds],
        selectCustomFieldsByIds,
        getCustomFields,
    );
}

function* loadGroupedSheetCalculationBatchesWatcher() {
    yield takeLatest(
        [
            loadGroupedSheets.action,
            getMoreGroupedSheetCalculationBatches.initType,
            changeSheetGroupStatus.successType,
            changeCalculationGroupStatus.successType,
            setPayrollProcessorSort.action,
        ],
        withBackendErrorHandler(
            loadGroupedSheetCalculationBatchesSaga,
            getMoreGroupedSheetCalculationBatches.error,
            'Unable to load grouped sheets',
        ),
    );
}

export function *setPayrollProcessorFilterSaga({ payload }: ReturnType<typeof setPayrollProcessorFilter>) {
    if (payload.payPeriodEnd) {
        yield* put(setPayrollProcessorSort({ 'user_name': Order.asc }));
    } else {
        yield* put(getMoreGroupedSheetCalculationBatches.init());
    }
}

function* setPayrollProcessorFilterWatcher() {
    yield takeLatest(
        setPayrollProcessorFilter.action,
        setPayrollProcessorFilterSaga,
    );
}

export function* loadGroupedSheetSummarySaga() {
    yield put(getSheetSummary.init());
    const { clientId, employeeId, status, payPeriodEnd } = yield select(selectPayrollFilter);
    const request: IGroupedSheetSummaryRequest = {
        client_id: clientId,
        user_id: employeeId,
        payroll_status: getSheetStatusByTab(status),
        pay_period_ends_at: getPayPeriodValueOrDefault(payPeriodEnd),
    };
    const response = yield* call(payrollProcessorHubApi.getSheetSummary, request);
    yield put(getSheetSummary.success(response));
}

function* loadGroupedSheetSummaryWatcher() {
    yield takeLatest(
        [
            loadGroupedSheets.action,
            setPayrollProcessorFilter.action,
            changeSheetGroupStatus.successType,
            changeCalculationGroupStatus.successType,
        ],
        withBackendErrorHandler(
            loadGroupedSheetSummarySaga,
            getSheetSummary.error,
            'Unable to load sheet summary',
        ),
    );
}

export function* getEditSheetGenworthConfigurationSaga(jobNumberIds?: string[], userId?: string) {
    if (jobNumberIds) {
        yield put(getJobNumbers.init({ ids: jobNumberIds }));
    } else if (userId) {
        yield put(getJobNumbers.init({ user_id: [userId] }));
    }
    yield put(getSubmittingOrgs.init());
}

function* getEditSheetInfoSaga({ payload }: ReturnType<typeof getSheetEditInfo.init>) {
    const { timeSheetId, expenseSheetId } = payload;
    const sheetGroup = yield select(selectSheetsGroupById(payload));
    if (!sheetGroup) {
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
        return;
    }
    yield put(setClientId(sheetGroup?.client_id));
    yield put(searchSubassignments.init({
        client_id: sheetGroup?.client_id,
        employee_user_id: sheetGroup?.user_id,
    }));
    yield put(getActivities.init({
        assignment_ids: sheetGroup.assignment_id,
    }));
    const sheets = yield* all({
        timeSheet: timeSheetId && call(timeApi.getSheetById, timeSheetId),
        expenseSheet: expenseSheetId && call(expenseApi.getSheetById, expenseSheetId),
    });
    const jobNumberId = (sheets.timeSheet as ISheetCommonBackend)?.job_number_id
        || (sheets.expenseSheet as ISheetCommonBackend)?.job_number_id;
    if (jobNumberId) {
        yield* getEditSheetGenworthConfigurationSaga([jobNumberId]);
    } else {
        // If jobnumbers are available then we should load all ones
        const clientId = yield* select(selectCurrentClientId);
        const configuration = yield* select(selectFieldConfiguration(clientId || ''));
        if (configuration && showField(configuration.inputs.time, EntrySlug.JobNumber)) {
            const userId = (sheets.timeSheet as ISheetCommonBackend)?.user_id
                || (sheets.expenseSheet as ISheetCommonBackend)?.user_id;
            yield* getEditSheetGenworthConfigurationSaga(undefined, userId);
        }
    }
    if (sheets.timeSheet) {
        yield* put(loadTimeSheetsWithEntries.success([sheets.timeSheet as ITimeSheetBackend]));
    }
    if (sheets.expenseSheet) {
        yield* put(loadExpenseSheetsWithEntries.success([sheets.expenseSheet as IExpenseSheetBackend]));
    }
    yield put(getProjectsAssignments.init({ assignment_ids: sheetGroup.assignment_id }));

    yield put(getSheetEditInfo.success());
}

function* getEditSheetInfoWatcher() {
    yield takeLatest(
        getSheetEditInfo.initType,
        withBackendErrorHandler(
            getEditSheetInfoSaga,
            getSheetEditInfo.error,
            'Unable to load sheet information',
        ),
    );
}

function* getEditGroupCalculationInfoSaga({ payload }: ReturnType<typeof setPayrollEditCalculationGroupId>) {
    if (!payload) {
        return;
    }
    const calculationGroup = yield select(selectEditCalculationGroup);
    if (!calculationGroup) {
        yield put(setPayrollEditCalculationGroupId(null));
        return;
    }
    yield put(getPayrollEditCalculationGroup.init());
    yield put(setClientId(calculationGroup?.client_id));
    yield put(searchSubassignments.init({
        client_id: calculationGroup?.client_id,
        employee_user_id: calculationGroup?.user_id,
    }));
    const assignmentIds: string[] = uniq([
        ...calculationGroup.time_calculations,
        ...calculationGroup.expense_calculations,
    ].map(
        calculation => calculation.assignment_id,
    ).filter(Boolean));
    yield put(getAssignments.init({ ids: assignmentIds }));
    const assignmentIdsString = assignmentIds.join(',');
    yield put(getActivities.init({
        assignment_ids: assignmentIdsString,
    }));
    const timeSheetsIds = calculationGroup.time_calculations?.map(
        (calculation: ITimesheetCalculation) => calculation.sheet_id,
    );
    let timeSheets = [];
    if (timeSheetsIds?.length) {
        // @ts-ignore
        timeSheets = yield* all(timeSheetsIds.map((id: string) => call(timeApi.getSheetById, id)));
    }

    const expenseSheetsIds = calculationGroup.expense_calculations?.map(
        (calculation: IExpenseSheetCalculation) => calculation.sheet_id,
    );
    let expenseSheets = [];
    if (expenseSheetsIds?.length) {
        // @ts-ignore
        expenseSheets = yield* all(expenseSheetsIds.map((id: string) => call(expenseApi.getSheetById, id)));
    }

    const jobNumberIds = uniq([
        ...calculationGroup.time_calculations,
        ...calculationGroup.expense_calculations,
    ].map(
        calculation => calculation.job_number_id,
    ).filter(Boolean));
    if (jobNumberIds.length) {
        yield* getEditSheetGenworthConfigurationSaga(jobNumberIds);
    } else {
        // If jobnumbers are available then we should load all ones
        const clientId = yield* select(selectCurrentClientId);
        const configuration = yield* select(selectFieldConfiguration(clientId || ''));
        if (configuration && showField(configuration.inputs.time, EntrySlug.JobNumber)) {
            const userId = calculationGroup?.user_id;
            yield* getEditSheetGenworthConfigurationSaga(undefined, userId);
        }
    }
    if (timeSheetsIds.length && timeSheets) {
        yield* put(loadTimeSheetsWithEntries.success(timeSheets));
    }
    if (expenseSheetsIds.length && expenseSheets) {
        yield* put(loadExpenseSheetsWithEntries.success(expenseSheets));
    }
    yield put(getProjectsAssignments.init({ assignment_ids: assignmentIds }));

    yield put(getPayrollEditCalculationGroup.success());
}

function* getEditGroupCalculationInfoWatcher() {
    yield takeLatest(
        setPayrollEditCalculationGroupId.action,
        withBackendErrorHandler(
            getEditGroupCalculationInfoSaga,
            getPayrollEditCalculationGroup.error,
            'Unable to load sheet information',
        ),
    );
}

export function getClientSheetStatusIdByName(
    type: EntryType,
    clientId: string,
    statusName: StatusNames,
): Promise<string | undefined> {
    const api = type === EntryType.TIME ? timeApi : expenseApi;
    return api.getAvailableStatuses({
        client_id: clientId,
    }).then(statuses => statuses.find(status => status.name === statusName)?.id);
}

export function* updateSheetGroupStatusSaga(
    type: EntryType,
    statusName: StatusNames,
    groups: ICalculationsGroupWithNote[],
) {
    const sheetNotesById = groups.reduce(
        (mem, group) => {
            const sheetIds = type === EntryType.TIME ? group.timeSheetIds : group.expenseSheetIds;
            if (sheetIds?.length) {
                sheetIds.forEach(sheetId => {
                    mem[sheetId] = group.note;
                });
            }
            return mem;
        }, {} as Record<string, string | undefined>);
    const clientIds: string[] = uniq(groups.map(group => group.clientId));
    const statusIdByClientId = yield* all(clientIds.reduce(
        (mem: Record<string, any>, clientId: string) => {
            mem[clientId] = call(getClientSheetStatusIdByName, type, clientId, statusName);
            return mem;
        },
        {} as Record<string, any>,
    ));
    const updateStatusesPayload: IUpdateSheetStatus[] = groups.map(group => {
        const sheetIds = type === EntryType.TIME ? group.timeSheetIds : group.expenseSheetIds;
        return sheetIds.map(sheetId => {
            return {
                id: sheetId,
                status_id: statusIdByClientId[group.clientId || ''],
                notes: sheetNotesById[sheetId || ''],
            };
        });
    // @ts-ignore
    }).flat().filter((sheet: IUpdateSheetStatus) => sheet.id && sheet.status_id);

    if (!updateStatusesPayload.length) {
        return;
    }

    const api = type === EntryType.TIME ? timeApi : expenseApi;
    return api.updateSheetsStatuses({
        sheets: updateStatusesPayload,
    });
}

export function* updateTypedSheetStatusSaga(
    type: EntryType,
    statusName: StatusNames,
    groupsIdsWithNote: {
        groupId: ISheetGroupId;
        note?: string;
    }[],
) {
    const groups = yield select(selectSheetsGroupsByIds(groupsIdsWithNote.map(item => item.groupId)));
    yield* updateSheetGroupStatusSaga(
        type,
        statusName,
        groups.map((group: IGroupedSheetCalculation) => {
            const note = groupsIdsWithNote.find(
                groupWithNote => groupWithNote.groupId.timeSheetId === group.time_sheet_id
                    && groupWithNote.groupId.expenseSheetId === group.expense_sheet_id,
            )?.note || undefined;
            return {
                clientId: group.client_id,
                timeSheetIds: group.time_sheet_id ? [group.time_sheet_id] : [],
                expenseSheetIds: group.expense_sheet_id ? [group.expense_sheet_id] : [],
                note,
            };
        }),
    );
}

export function* changeSheetGroupStatusSaga({ payload }: ReturnType<typeof changeSheetGroupStatus.init>) {
    const { status, groups: groupsIdsWithNote } = payload;

    browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
    yield* put(increaseSyncing(SyncingModels.PayrollApprovalSheet));
    try {
        const result = yield* all([
            call(updateTypedSheetStatusSaga, EntryType.TIME, status, groupsIdsWithNote),
            call(updateTypedSheetStatusSaga, EntryType.EXPENSE, status, groupsIdsWithNote),
        ]);
        yield* all(result);
        yield* put(changeSheetGroupStatus.success());
    } finally {
        yield* put(decreaseSyncing(SyncingModels.PayrollApprovalSheet));
    }
}

function* changeSheetGroupStatusSagaWatcher() {
    yield takeLatest(
        changeSheetGroupStatus.initType,
        withBackendErrorHandler(
            changeSheetGroupStatusSaga,
            changeSheetGroupStatus.error,
            'Unable to update sheet status',
        ),
    );
}

function* changeSheetCalculationBatchStatusSaga({ payload }: ReturnType<typeof changeCalculationGroupStatus.init>) {
    const { statusName, groups } = payload;

    yield* put(setPayrollDetailCalculationGroupId(null));
    yield* put(increaseSyncing(SyncingModels.PayrollApprovalSheet));
    try {
        const res = yield* all([
            call(updateSheetGroupStatusSaga, EntryType.TIME, statusName, groups),
            call(updateSheetGroupStatusSaga, EntryType.EXPENSE, statusName, groups),
        ]);
        yield* all(res);
        yield* put(changeCalculationGroupStatus.success());
    } finally {
        yield* put(decreaseSyncing(SyncingModels.PayrollApprovalSheet));
    }
}

function* changeSheetCalculationBatchStatusSagaWatcher() {
    yield takeLatest(
        changeCalculationGroupStatus.initType,
        withBackendErrorHandler(
            changeSheetCalculationBatchStatusSaga,
            changeCalculationGroupStatus.error,
            'Unable to update sheet status',
        ),
    );
}

export function* initSheetGroupPayrollSaga(
    { payload: { sheets, separateTimeExpense } }: ReturnType<typeof initSheetGroupPayroll.init>,
) {
    const params: IGroupedSheetPayrollRequest = {
        sheets_for_payroll: sheets.map(sheet => ({
            pay_date: sheet.payDate,
            client_id: sheet.clientId,
            time_sheet_id: sheet.timeSheetId,
            expense_sheet_id: sheet.expenseSheetId,
        })),
        separate_time_and_expense_batches: separateTimeExpense,
    };

    const response = yield* call(payrollProcessorHubApi.initSheetGroupPayroll, params);
    yield* put(initSheetGroupPayroll.success());
    if (response.status === 201 && response.data) {
        // If payroll returns report then create post process report
        yield* put(setPostPayrollReports(postPayrollResponseMapper(response.data)));
    } else {
        // else update sheet list
        yield* call(navigateWithClientSaga, routes.PAYROLL_PROCESSOR_HUB.SHEETS);
        yield* put(loadGroupedSheets());
    }
}

function* initSheetGroupPayrollSagaWatcher() {
    yield takeLatest(
        initSheetGroupPayroll.initType,
        withBackendErrorHandler(
            initSheetGroupPayrollSaga,
            initSheetGroupPayroll.error,
            'Unable to initialize payroll',
        ),
    );
}

export function* preInitializeReportSaga(
    { payload: { sheets, separateTimeExpense } }: ReturnType<typeof getPrePayrollReport.init>,
) {
    const params: IGroupedSheetPayrollRequest = {
        sheets_for_payroll: sheets.map(sheet => ({
            pay_date: sheet.payDate,
            client_id: sheet.clientId,
            time_sheet_id: sheet.timeSheetId,
            expense_sheet_id: sheet.expenseSheetId,
        })),
        separate_time_and_expense_batches: separateTimeExpense,
    };

    const report = yield* call(payrollProcessorHubApi.getPreInitializeReport, params);
    yield* put(getPrePayrollReport.success(prePayrollResponseMapper(report)));
}

function* preInitializeReportSagaWatcher() {
    yield takeLatest(
        getPrePayrollReport.initType,
        withBackendErrorHandler(
            preInitializeReportSaga,
            getPrePayrollReport.error,
            'Unable to load pre-initialize report',
        ),
    );
}

export function* getGroupedSheetPdfSaga({ payload }: ReturnType<typeof getGroupedSheetPdf.init>) {
    const { timeSheetId, expenseSheetId, fileName } = payload;
    const params: ISheetGroupIdRequest = {
        time_sheet_id: timeSheetId,
        expense_sheet_id: expenseSheetId,
    };
    const result = yield call(payrollProcessorHubApi.getGroupedSheetPdf, params);
    yield call(downloadFileSaga, result, `${fileName}.pdf`);
    yield* put(getGroupedSheetPdf.success(payload));
}

function* getGroupedSheetPdfSagaWatcher() {
    yield takeLatest(
        getGroupedSheetPdf.initType,
        withBackendErrorHandler(
            getGroupedSheetPdfSaga,
            getGroupedSheetPdf.error,
            'Unable to download sheet pdf',
        ),
    );
}

function* getGroupedSheetCalculationPdfSaga({ payload }: ReturnType<typeof getGroupedSheetCalculationPdf.init>) {
    const { id, ...params } = payload;
    const result = yield call(payrollProcessorHubApi.getGroupedSheetCalculationPdf, params);
    yield call(downloadFileSaga, result, 'report.pdf');
    yield* put(getGroupedSheetCalculationPdf.success(id));
}

function* getGroupedSheetCalculationPdfSagaWatcher() {
    yield takeLatest(
        getGroupedSheetCalculationPdf.initType,
        withBackendErrorHandler(
            getGroupedSheetCalculationPdfSaga,
            getGroupedSheetCalculationPdf.error,
            'Unable to download sheet pdf',
        ),
    );
}

export function* sendSheetGroupReminderSaga({ payload }: ReturnType<typeof sendSheetGroupReminder.init>) {
    const { timeSheetId, expenseSheetId } = payload;
    const params: ISheetGroupIdRequest = {
        time_sheet_id: timeSheetId,
        expense_sheet_id: expenseSheetId,
    };
    yield call(notificationApi.sheetReminders, params);
    yield* put(sendSheetGroupReminder.success(payload));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Reminder was successfully sent.',
        autoHideDuration: 5000,
    }));
}

function* sendSheetGroupReminderSagaWatcher() {
    yield takeLatest(
        sendSheetGroupReminder.initType,
        withBackendErrorHandler(
            sendSheetGroupReminderSaga,
            sendSheetGroupReminder.error,
            'Unable to send reminder',
        ),
    );
}

export function* getPayPeriodsSaga({ payload }: ReturnType<typeof getPayrollPayPeriods.init>) {
    const payPeriods = yield call(payrollProcessorHubApi.getPayPeriods, payload || {});
    yield* put(getPayrollPayPeriods.success(payPeriods));
}

function* getPayPeriodsSagaWatcher() {
    yield takeLatest(
        getPayrollPayPeriods.initType,
        withBackendErrorHandler(
            getPayPeriodsSaga,
            getPayrollPayPeriods.error,
            'Unable to get pay periods',
        ),
    );
}

function* loadClientAdditionalDataForFilterSaga({ payload }: ReturnType<typeof setPayrollProcessorFilter>) {
    const { clientId } = payload;
    if (clientId) {
        const clientConfiguration = yield select(selectClientTimeAndPayConfigurationByClientId(clientId));
        if (!clientConfiguration) {
            yield put(getClientFieldsConfiguration.init(clientId));
        }
    }
}

function* loadClientAdditionalDataForFilterWatcher() {
    yield takeLatest(
        setPayrollProcessorFilter.action,
        loadClientAdditionalDataForFilterSaga,
    );
}

function* unlockSheetSaga({ payload: { sheets } }: ReturnType<typeof unlockSheet>) {
    const unlockSagas = [];
    const sheetTypes = {
        [EntryType.TIME]: false,
        [EntryType.EXPENSE]: false,
    };

    const timeSheetsIds = sheets.filter(sheet => sheet.sheetType === EntryType.TIME).map(({ id }) => id);
    const expenseSheetsIds = sheets.filter(sheet => sheet.sheetType === EntryType.EXPENSE).map(({ id }) => id);
    if (timeSheetsIds.length) {
        // @ts-ignore
        unlockSagas.push(call(timeApi.batchUnlockSheets, { sheet_ids: timeSheetsIds }));
        sheetTypes[EntryType.TIME] = true;
    }
    if (expenseSheetsIds.length) {
        // @ts-ignore
        unlockSagas.push(call(expenseApi.batchUnlockSheets, { sheet_ids: expenseSheetsIds }));
        sheetTypes[EntryType.TIME] = true;
    }

    try {
        yield* put(increaseSyncing(SyncingModels.PayrollApprovalSheet));
        yield* all(unlockSagas);
        yield* put(setGlobalToast({
            title: sheetStatusMessage(sheets.length, sheetTypes, 'Unlocked'),
            severity: IModalSeverity.Success,
        }));
    } catch (e) {
        yield* put(setGlobalToast({
            title: sheetStatusMessage(sheets.length, sheetTypes, 'Unlocked', false),
            severity: IModalSeverity.Error,
        }));
    } finally {
        yield* put(decreaseSyncing(SyncingModels.PayrollApprovalSheet));
    }
    yield* put(loadGroupedSheets());
}

function* unlockSheetSagaWatcher() {
    yield takeLatest(
        unlockSheet.action,
        unlockSheetSaga,
    );
}

export function* getGroupSheetApprovalsSaga() {
    const group = yield select(selectDetailCalculationGroup);
    if (!group) {
        return;
    }
    const timeSheetsIds = group.time_calculations.map((calc: ICalculation) => calc.sheet_id);
    const expenseSheetsIds = group.expense_calculations.map((calc: ICalculation) => calc.sheet_id);
    const fetchSagas = [];
    if (timeSheetsIds.length) {
        // @ts-ignore
        fetchSagas.push(call(timeApi.getSheetApprovals, { sheet_ids: timeSheetsIds }));
    }
    if (expenseSheetsIds.length) {
        // @ts-ignore
        fetchSagas.push(call(expenseApi.getSheetApprovals, { sheet_ids: expenseSheetsIds }));
    }
    const approvalsResponse = yield* all(fetchSagas);
    const approvals = approvalsResponse.flat();

    // @ts-ignore
    const approverIds = approvals.map(approve => approve.user_id);
    yield optionalLoadEntitiesByIdsSaga(
        approverIds,
        selectUsersById,
        getUsers,
        userIds => ({ ids: userIds.join(',') }),
    );

    yield* put(getGroupSheetApprovals.success({
        groupId: group.id,
        // @ts-ignore
        approvals,
    }));
}

function* getGroupSheetApprovalsSagaWatcher() {
    yield takeLatest(
        getGroupSheetApprovals.initType,
        withBackendErrorHandler(
            getGroupSheetApprovalsSaga,
            getGroupSheetApprovals.error,
            'Unable to get sheet approvals',
        ),
    );
}

export function* getCustomFieldIfPresetInClientSaga(
    {
        payload: {
            clientId,
            prismFieldId,
        },
    }: ReturnType<typeof getCFieldByPrism>) {
    if (clientId) {
        yield put(getCustomFieldValues.init({ field_type: prismFieldId as CustomFieldType, client_id: clientId }));
        yield put(getCustomFieldsHierarchyNodes.init({ client_id: clientId, cfvs: false }));
    }
}

function* getCustomFieldIfPresetInClientWatcher() {
    yield takeLatest(
        getCFieldByPrism.action,
        getCustomFieldIfPresetInClientSaga,
    );
}

interface IPayrollErrorReport extends ISheetsForPostPayroll {
    employee: string,
    prismId: string,
    client: string,
}
const downloadPostPayrollErrorsSagaWatcher = getDownloadCsvSagaWatcher(
    getPayrollErrorsCsv,
    function* getItems(): Generator<any, IPayrollErrorReport[]> {
        const usersByIds = (yield select(selectUsersById)) as IUsersById;
        const clientByIds = (yield select(selectAllClientsById)) as Record<string, IClient>;
        const report = (yield select(selectPostPayrollReport)) as IPostPayroll;
        const rowsWithErrors = report.sheetsForPayroll.filter(row => row.failedReason);
        return rowsWithErrors.map(row => {
            const employee = usersByIds[row.employeeUserId];
            const client = clientByIds[row.clientId];
            return {
                ...row,
                employee: getLastFirstName(employee),
                prismId: employee?.prism_employee_id,
                client: client?.name,
            };
        });
    },
    function getFields() {
        return [
            {
                label: 'Employee',
                value: 'employee',
            },
            {
                label: 'Prism Id',
                value: 'prismId',
            },
            {
                label: 'Pay period',
                value: 'payPeriod',
            },
            {
                label: 'Deduction period',
                value: 'deductPeriod',
            },
            {
                label: 'Client',
                value: 'client',
            },
            {
                label: 'Total pay',
                value: 'totalDollars',
            },
            {
                label: 'Failed reason',
                value: 'failedReason',
            },
        ];
    },
    function getFilename() {
        return `Payroll fails-${moment().format(fileTimestampFormat)}.csv`;
    },
);

export default [
    loadGroupedSheetCalculationBatchesWatcher,
    loadGroupedSheetSummaryWatcher,
    getEditSheetInfoWatcher,
    changeSheetGroupStatusSagaWatcher,
    initSheetGroupPayrollSagaWatcher,
    getGroupedSheetPdfSagaWatcher,
    sendSheetGroupReminderSagaWatcher,
    preInitializeReportSagaWatcher,
    getPayPeriodsSagaWatcher,
    loadClientAdditionalDataForFilterWatcher,
    unlockSheetSagaWatcher,
    changeSheetCalculationBatchStatusSagaWatcher,
    getGroupedSheetCalculationPdfSagaWatcher,
    getEditGroupCalculationInfoWatcher,
    getGroupSheetApprovalsSagaWatcher,
    downloadPostPayrollErrorsSagaWatcher,
    setPayrollProcessorFilterWatcher,
    getCustomFieldIfPresetInClientWatcher,
];
