import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import {
    createControllingOrg as createControllingOrgAction,
    setCreateControllingOrgModalState,
} from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/store/actions';
import { combineReducers } from 'redux';

const isControllingOrgCreating = isLoadingReducer(createControllingOrgAction);

const isCreateControllingOrgModalOpen = isOpenModalReducer(setCreateControllingOrgModalState.action);

export const createControllingOrg = combineReducers({
    isControllingOrgCreating,
    isCreateControllingOrgModalOpen,
});
