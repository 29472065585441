import { IActivity } from '../configurationModel';

export type ActivitiesByTaskId = Record<string, string[]>;
export const activitiesByTaskIdReducer = (activities: IActivity[], initialValue: ActivitiesByTaskId = {}) => ({
    ...activities.reduce<ActivitiesByTaskId>((tasksById, activity) => {
        if (activity.tasks) {
            activity.tasks.forEach(({ id: taskId }) => {
                if (taskId) {
                    if (tasksById[taskId]) {
                        tasksById[taskId].push(activity.id);
                    } else {
                        (tasksById[taskId] = Array<string>()).push(activity.id);
                    }
                }
            });
        }
        return tasksById;
    }, initialValue),
});
