import { Box, Grid } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import React from 'react';
import NumberField from 'shared/components/formFields/NumberField';

interface IPerFileBillingInfoFormSectionProps {
    path: string;
    titleElement: JSX.Element;
}

export const PerFileBillingInfoFormSection = ({
    path,
    titleElement,
}: IPerFileBillingInfoFormSectionProps) => {
    const classes = useSettingsFormStyles();
    return (
        <Box className={classes.formBlockCard}>
            {titleElement}
            <Grid container spacing={2}>
                <Grid item>
                    <NumberField
                        name={`${path}FileRate`}
                        outerLabel="Per file overtime rate"
                        className={classes.smallInput}
                        inputProps={{
                            allowNegative: false,
                        }}
                        min={0}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
