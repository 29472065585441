import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { UserProfileForm } from 'modules/profile/form/UserProfileForm';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { addUserAvatar, getUserProfile } from './store/profileActions';
import { selectUserProfile, selectUserAvatar } from './store/profileSelector';
import { defaultUserProfileValues, IAvatarUpload } from './store/profileModel';

const ProfileContent = () => {
    const currentUser = useSelector(selectCurrentUser);
    const profile = useSelector(selectUserProfile);
    const avatar = useSelector(selectUserAvatar);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!profile) {
            dispatch(getUserProfile.init(currentUser?.id ?? ''));
        }
    }, [currentUser, dispatch, profile]);

    const [newAvatar, setNewAvatar] = useState<IAvatarUpload>({
        body: '',
        filename: '',
        mimetype: '',
        entry_id: '',
        previewUrl: '',
    });
    const initialValues = {
        ...defaultUserProfileValues,
        id: profile?.id ?? defaultUserProfileValues.id,
        first_name: profile?.first_name ?? defaultUserProfileValues.first_name,
        last_name: profile?.last_name ?? defaultUserProfileValues.last_name,
        address: `${profile?.address1 ?? defaultUserProfileValues.address} ${profile?.address2 ?? defaultUserProfileValues.address}`,
        state: profile?.state ?? defaultUserProfileValues.state,
        city: profile?.city ?? defaultUserProfileValues.city,
        zip_code: profile?.zip_code ?? defaultUserProfileValues.zip_code,
        county: profile?.county ?? defaultUserProfileValues.county,
        phone_number: profile?.cell_phone ?? profile?.home_phone ?? defaultUserProfileValues.phone_number,
        avatarUrl: avatar.url ?? defaultUserProfileValues.avatarUrl,
    };

    const onSubmitUpdateForm = () => {
        if (newAvatar.body !== ''){
            dispatch(addUserAvatar.init(newAvatar));
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmitUpdateForm}
            validateOnBlur={false}
            enableReinitialize
        >
            {props => (
                <>
                    <UserProfileForm
                        {...props}
                        setNewAvatar={setNewAvatar}
                        previewUrl={(newAvatar.previewUrl === undefined || newAvatar.previewUrl === '')
                            ? avatar.url : newAvatar.previewUrl}
                    />
                </>
            )}
        </Formik>

    );
};

export default ProfileContent;
