import { formBasicSpacing, useStyles } from '../styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface IOfferLetterFormSubmitBlockProps {
    onCancel: () => void;
    isLoading?: boolean;
}

export function OfferLetterFormSubmitBlock({
    isLoading,
    onCancel,
}: IOfferLetterFormSubmitBlockProps) {
    const classes = useStyles();

    return (
        <Box m={formBasicSpacing} mt={5}>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                className={clsx(classes.button, classes.green)}
                disabled={isLoading}
                data-testid="offer_submit_button"
            >
                Submit offer
                {isLoading && (
                    <Box ml={1} mb={-0.5}>
                        <CircularProgress color="inherit" size={16}/>
                    </Box>
                )}
            </Button>
            <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={onCancel}
                disabled={isLoading}
            >
                Cancel
            </Button>
        </Box>
    );
}
