import { Box } from '@material-ui/core';
import { omit } from 'lodash-es';
import { IUserFormValues } from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/model';
import UserFormWrapper from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/UserFormWrapper';
import { setEditPlatformUser, updatePlatformUser } from 'modules/settings/submodules/platformUsers/store/actions';
import { selectEditPlatformUserId, selectIsPlatformUserUpdating } from 'modules/settings/submodules/platformUsers/store/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { selectUserById } from 'store/entities/users/selectors';
import { Status } from 'shared/models/Status';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export default function EditUserModal() {
    const dispatch = useDispatch();
    const userId = useSelector(selectEditPlatformUserId);
    const user = useSelector(selectUserById(userId));
    const isLoading = useSelector(selectIsPlatformUserUpdating);

    const onSave = useCallback((values: IUserFormValues) => {
        dispatch(updatePlatformUser.init({
            id: userId,
            // @ts-ignore
            data: {
                ...omit(values, 'address'),
                address1: values.address,
                status: user?.status || Status.active,
            },
        }));
    }, [dispatch, userId, user]);

    const classes = useColoredFormModalStyles();
    const onClose = useCallback(() => {
        dispatch(setEditPlatformUser(null));
    }, [dispatch]);
    return (
        <ModalDialog
            title="Edit Platform User"
            isOpened={Boolean(userId)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <UserFormWrapper
                    onSubmit={onSave}
                    submitTitle="Update"
                    isLoading={isLoading}
                    user={user}
                />
            </Box>
        </ModalDialog>
    );
}
