import { combineReducers } from 'redux';
import { assignmentReportDataTable } from 'modules/settings/submodules/clients/assignmentsReportData/components/AssignmentsReportDataList/store/reducers';
import { editAssignmentMetaData } from 'modules/settings/submodules/clients/assignmentsReportData/components/EditAssignmentsReportData/store/reducers';
import { createAssignmentMetaData } from 'modules/settings/submodules/clients/assignmentsReportData/components/CreateAssignmentReportMetaData/store/reducers';

export const assignmentsReportData = combineReducers({
    assignmentReportDataTable,
    editAssignmentMetaData,
    createAssignmentMetaData,
});
