import { EafType, eafTypeDisplayValues, IEafAssignmentEndData } from 'modules/employeeActionsForm/store/models';
import { selectBulkPayloadV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/selectors';
import { IBulkEditSubassignmentPayloadV2 } from 'modules/subassignmentManagement/store/models';
import { selectSubassignmentManagementState } from 'modules/subassignmentManagement/store/selectors';
import { createSelector } from 'reselect';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { IStore } from 'store/configureStore';

export const selectBulkEndAssignmentState = (state: IStore) =>
    selectSubassignmentManagementState(state).endSubassignments;

export const selectIsOpenModal = (state: IStore) => selectBulkEndAssignmentState(state).isOpenBulkEndModal;

export const selectBulkEndDataOverviewItems = createSelector(
    selectBulkPayloadV2,
    (payload: IBulkEditSubassignmentPayloadV2) => {
        const employeeActionForms = payload?.employee_action_forms || [];
        const assignmentEndEaf = employeeActionForms[0]?.employee_action_form_data as IEafAssignmentEndData;
        if (
            !assignmentEndEaf
            || ![EafType.AssignmentEnded, EafType.Termination].includes(assignmentEndEaf?.employee_action_form_type)
        ) {
            return [];
        }

        return [[
            {
                label: 'Type',
                values: [eafTypeDisplayValues[assignmentEndEaf?.employee_action_form_type]],
            },
            {
                label: 'Reason',
                // @ts-ignore
                values: [assignmentEndEaf?.employee_action_form_reason_text || '--'],
            },
            {
                label: 'Effective Date',
                values: [formatShortDate(assignmentEndEaf?.effective_date)],
            },
            {
                label: 'Eligible for Rehire?',
                values: [assignmentEndEaf?.eligible_for_rehire ? 'Yes' : 'No'],
            },
            {
                label: 'Do not send email',
                values: [assignmentEndEaf?.do_not_send_employee_email ? 'Yes' : 'No'],
            },
        ]];
    },
);
