import { flatten } from 'lodash-es';
import {
    ICustomFieldValueFormValues,
    useDefaultValueByType,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/model';
import { useSelector } from 'react-redux';
import { FormMode } from 'shared/models/FormMode';
import { Status } from 'shared/models/Status';
import {
    CustomFieldType, CustomFieldValueData,
    ICreateRelatedValues,
    ICustomFieldValue,
    IRelatedValues,
} from 'store/entities/customFields/model';
import {
    selectAllNotDeletedCustomFieldValueIds,
    selectAllNotDeletedCustomFieldValues,
} from 'store/entities/customFields/selectors';
import { useSplitCustomFieldValuesIdsByCustomFiledIds } from 'store/entities/customFields/utils';
import { FieldValueRelationType, ICustomFieldRelations, IRelationsByHierarchyType } from '../../store/selectors';

const HierarchyRelationMapToPath: Record<FieldValueRelationType, keyof ICustomFieldValue> = {
    [FieldValueRelationType.Assignment]: 'assignment_hierarchy',
    [FieldValueRelationType.OfferLetter]: 'offer_hierarchy',
};

// @ts-ignore
const getSelectAllFormValues = (selectAllList?: string[] = []): Record<string, boolean> => {
    return selectAllList.reduce((mem, id) => {
        return {
            ...mem,
            [id]: true,
        };
    }, {} as Record<string, boolean>);
};

export const getSelectAllValues = (selectAllValues: Record<string, boolean>): string[] => {
    return Object.entries(selectAllValues).reduce((mem, [key, value]) => {
        if (value) {
            return [...mem, key];
        }
        return mem;
    }, [] as string[]);
};

/**
 * Filter input field value ids with full list of non-deleted field value ids
 */
const useFilterValueIdsByNonDeleted = (valueIds: string[]) => {
    const allNotDeletedValueIds = useSelector(selectAllNotDeletedCustomFieldValueIds);
    return valueIds.filter(valueId => allNotDeletedValueIds.includes(valueId));
};

/**
 * Return child values grouped by field id
 * @param relationType
 * @param fieldValueId
 * @param customFieldId
 */
function useChildrenFieldValueIds(
    relationType: FieldValueRelationType,
    fieldValueId?: string,
    customFieldId?: string,
): Record<string, string[]> {
    const allFieldValues = useSelector(selectAllNotDeletedCustomFieldValues);
    const childrenValueIds = fieldValueId ? allFieldValues.filter(value => {
        const hierarchy = value[HierarchyRelationMapToPath[relationType]] as IRelatedValues;
        // @ts-ignore
        return hierarchy?.all_parent_custom_field_ids?.includes(customFieldId)
            || hierarchy?.parent_custom_field_value_ids?.includes(fieldValueId);
    }).map(value => value.id) : [];
    return useSplitCustomFieldValuesIdsByCustomFiledIds(childrenValueIds);
}

/**
 * Return all child field ids
 */
function useChildrenAllFields(
    childrenValues: Record<string, string[]>,
    relationType: FieldValueRelationType,
    relations?: ICustomFieldRelations | null,
): Record<string, boolean> {
    const allFieldValues = useSelector(selectAllNotDeletedCustomFieldValues);
    const valuesCountByFields = allFieldValues.reduce((mem, value) => {
        return {
            ...mem,
            [value.custom_field_id]: 1 + (mem[value.custom_field_id] || 0),
        };
    }, {} as Record<string, number>);
    const allFieldIds = (relations?.childrenFieldIds || []).filter(fieldId => {
        const count = childrenValues[fieldId]?.length || 0;
        return count === valuesCountByFields[fieldId] && count !== 0;
    });

    return getSelectAllFormValues(allFieldIds);
}

/**
 * Return sibling values grouped by field id
 * @param relationType
 * @param customFieldValue
 */
function useSiblingsValuesByFieldIds(
    relationType: FieldValueRelationType,
    customFieldValue?: ICustomFieldValue,
): Record<string, string[]> {
    const hierarchy = customFieldValue?.[HierarchyRelationMapToPath[relationType]] as IRelatedValues;
    return useSplitCustomFieldValuesIdsByCustomFiledIds(useFilterValueIdsByNonDeleted(
        hierarchy?.sibling_custom_field_value_ids || [],
    ));
}

/**
 * Return sibling all values
 * @param relationType
 * @param mode
 * @param customFieldValue
 * @param relations
 */
function useSiblingsAllValues(
    relationType: FieldValueRelationType,
    mode: FormMode,
    customFieldValue?: ICustomFieldValue,
    relations?: ICustomFieldRelations | null,
): Record<string, boolean> {
    const hierarchy = customFieldValue?.[HierarchyRelationMapToPath[relationType]] as IRelatedValues;
    const fieldIds = (mode === FormMode.New
        ? relations?.siblingFieldIds
        : hierarchy?.all_values_custom_field_ids) || [];
    return getSelectAllFormValues(fieldIds);
}

export const useInitialFormValue = (
    type: CustomFieldType,
    mode: FormMode,
    relations: IRelationsByHierarchyType,
    customFieldId?: string,
    customFieldValue?: ICustomFieldValue,
): ICustomFieldValueFormValues => {
    const defaultValue = useDefaultValueByType(type);

    //Assignments hierarchy
    const assignmentSiblings = useSiblingsValuesByFieldIds(FieldValueRelationType.Assignment, customFieldValue);
    const assignmentSiblingsAllFields = useSiblingsAllValues(
        FieldValueRelationType.Assignment,
        mode,
        customFieldValue,
        relations.assignment,
    );
    const assignmentChildren = useChildrenFieldValueIds(
        FieldValueRelationType.Assignment,
        customFieldValue?.id,
        customFieldId,
    );
    const assignmentChildrenAllFields = useChildrenAllFields(
        assignmentChildren,
        FieldValueRelationType.Assignment,
        relations.assignment,
    );

    //Offer hierarchy
    const offerLetterSiblings = useSiblingsValuesByFieldIds(FieldValueRelationType.OfferLetter, customFieldValue);
    const offerLetterSiblingsAllFields = useSiblingsAllValues(
        FieldValueRelationType.OfferLetter,
        mode,
        customFieldValue,
        relations.offerLetter,
    );
    const offerLetterChildren = useChildrenFieldValueIds(
        FieldValueRelationType.OfferLetter,
        customFieldValue?.id,
        customFieldId,
    );
    const offerLetterChildrenAllFields = useChildrenAllFields(
        offerLetterChildren,
        FieldValueRelationType.OfferLetter,
        relations.offerLetter,
    );

    const assignmentParentFieldId = relations?.assignment?.parentFieldId;
    const offerLetterParentFieldId = relations?.offerLetter?.parentFieldId;

    return {
        ...defaultValue,
        assignmentSiblings,
        assignmentSiblingsAllFields,
        assignmentChildren,
        assignmentChildrenAllFields,
        offerLetterSiblings,
        offerLetterSiblingsAllFields,
        offerLetterChildren,
        offerLetterChildrenAllFields,
        ...(customFieldValue ? {
            assignmentParentIds: customFieldValue?.assignment_hierarchy?.parent_custom_field_value_ids,
            assignmentParentAll:
                customFieldValue?.assignment_hierarchy?.all_parent_custom_field_ids[0] === assignmentParentFieldId,
            offerLetterParentIds: customFieldValue?.offer_hierarchy?.parent_custom_field_value_ids,
            offerLetterParentAll:
                customFieldValue?.offer_hierarchy?.all_parent_custom_field_ids[0] === offerLetterParentFieldId,
            data: normalizeData(customFieldValue.data),
        } : {}),
        status: customFieldValue?.is_active ? Status.active : Status.inactive,
    };
};

function normalizeData(customFieldValueData: CustomFieldValueData): CustomFieldValueData {
    if (customFieldValueData.headway_connect_field === CustomFieldType.Location) {
        return {
            ...customFieldValueData,
            work_comp_state_code: customFieldValueData['work_comp_state_code'] || customFieldValueData['state_code'],
        };
    }
    return customFieldValueData;
}

export function getHierarchyRelationsByFormValues(
    values: ICustomFieldValueFormValues,
    relations: IRelationsByHierarchyType,
    isHierarchiesAreCompatible: boolean,
    enableEditCustomFieldValueChildren: boolean,
): {
        assignment_hierarchy?: ICreateRelatedValues;
        offer_hierarchy?: ICreateRelatedValues;
    } {
    const assignmentParentFieldId = relations?.assignment?.parentFieldId;
    const offerLetterParentFieldId = relations?.offerLetter?.parentFieldId;

    return {
        assignment_hierarchy: relations?.assignment ? {
            parent_custom_field_value_ids: values.assignmentParentIds || null,
            all_parent_custom_field_ids: values.assignmentParentAll && assignmentParentFieldId
                ? [assignmentParentFieldId] : [],
            sibling_custom_field_value_ids: flatten(Object.values(values.assignmentSiblings)),
            all_values_custom_field_ids: getSelectAllValues(values.assignmentSiblingsAllFields),
            children_custom_field_value_ids: enableEditCustomFieldValueChildren
                ? flatten(Object.values(values.assignmentChildren)) : undefined,
        } : undefined,
        offer_hierarchy: isHierarchiesAreCompatible || !relations?.offerLetter ? undefined : {
            parent_custom_field_value_ids: values.offerLetterParentIds || null,
            all_parent_custom_field_ids: values.offerLetterParentAll && offerLetterParentFieldId
                ? [offerLetterParentFieldId] : [],
            sibling_custom_field_value_ids: flatten(Object.values(values.offerLetterSiblings)),
            all_values_custom_field_ids: getSelectAllValues(values.offerLetterSiblingsAllFields),
            children_custom_field_value_ids: enableEditCustomFieldValueChildren
                ? flatten(Object.values(values.offerLetterChildren)) : undefined,
        },
    };
}
