import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { EntryType } from 'shared/models/sheet/Sheet';
import { capitalize } from 'lodash-es';

interface IEntryTypeSelectProps extends IFormFieldProps, IFormSelect {
}

export default function EntryTypeSelect({
    ...props
}: IEntryTypeSelectProps) {
    const sheetTypes = Object.values(EntryType);

    return (
        <FormSelect
            {...props}
            getKey={(type: EntryType) => type}
            getText={(type: EntryType) => capitalize(type.toString())}
            options={sheetTypes}
        />
    );
}
