import React from 'react';
import clsx from 'clsx';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import FormField from 'shared/components/formFields/FormField';
import OverrideWorkGeoCodeSelect
    from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/components/OverrideWorkGeoCodeSelect';
import AddressAutocompleteField
    from 'shared/components/formSpecialFields/addressAutocompleteField/AddressAutocompleteField';
import { IAddressComponents } from 'shared/components/formSpecialFields/addressAutocompleteField/models';
import { FormMode } from 'shared/models/FormMode';

export interface ILocationsFormDataSectionProps {
    mode: FormMode;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    path?: string;
    showReadOnly?: boolean;
    nameTitle?: string;
}

export function LocationsFormDataSection({
    setFieldValue,
    mode,
    path = '',
    showReadOnly = false,
    nameTitle = 'Name',
}: ILocationsFormDataSectionProps) {
    const classes = useSettingsFormStyles();
    const onAddressSelect = (address: IAddressComponents) => {
        setFieldValue(`${path}county`, address.county);
        setFieldValue(`${path}state_code`, address.stateCode);
        setFieldValue(`${path}city`, address.city);
        setFieldValue(`${path}zip_code`, address.zip_code);
        setFieldValue(`${path}address`, `${address.street_number} ${address.street}`);
    };

    return (
        <Box>
            <Box className={clsx(classes.formBlock)}>
                <Grid container>
                    {mode === FormMode.Edit && showReadOnly && (
                        <>
                            <Grid
                                item
                                sm={7}
                                xs={12}
                            >
                                <FormField
                                    name="location_id"
                                    label="Work Location ID"
                                    className={classes.input}
                                    disabled
                                />
                            </Grid>
                            <Grid
                                item
                                sm={5}
                                xs={12}
                            >
                                <FormField
                                    name="client_name"
                                    label="Client ID"
                                    className={classes.input}
                                    disabled
                                />
                            </Grid>
                        </>
                    )}
                    <Grid
                        item
                        sm={7}
                        xs={12}
                    >
                        <FormField
                            name={`${path}name`}
                            label={nameTitle}
                            className={classes.input}
                        />
                    </Grid>
                    {mode === FormMode.New && showReadOnly && (
                        <Grid
                            item
                            sm={5}
                            xs={12}
                        >
                            <FormField
                                name="client_name"
                                label="Client ID"
                                className={classes.input}
                                disabled
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box className={clsx(classes.formBlock)}>
                <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>Work Location Address</Typography>
                <AddressAutocompleteField
                    name={`${path}address`}
                    label="Address"
                    className={classes.input}
                    onAddressSelect={onAddressSelect}
                />

                <FormField
                    name={`${path}address2`}
                    label="Address 2"
                    className={classes.input}
                />

                <Grid container>
                    <Grid
                        item
                        sm={7}
                        xs={12}
                    >
                        <FormField
                            name={`${path}city`}
                            label="City"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        xs={12}
                    >
                        <FormField
                            name={`${path}state_code`}
                            label="State"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid
                        item
                        sm={7}
                        xs={12}
                    >
                        <FormField
                            name={`${path}county`}
                            label="Сounty"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        xs={12}
                    >
                        <FormField
                            name={`${path}zip_code`}
                            label="Zip code"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        sm={7}
                        xs={12}
                    >
                        {mode === FormMode.Edit && (
                            <FormField
                                name={`${path}geo_code`}
                                label="GeoCode"
                                className={classes.input}
                                disabled
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        xs={12}
                    >
                        <OverrideWorkGeoCodeSelect
                            name={`${path}override_work_geo_code`}
                            label="Override Work GeoCode"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
