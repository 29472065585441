import { IHomeDashboardResponse, IHomeFilter } from 'modules/home/store/model';
import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';

export const SET_HOME_FILTER = 'home/SET_HOME_FILTER';
export const setHomeDashboardFilter = createSingleAction<
Partial<IHomeFilter>,
typeof SET_HOME_FILTER
>(
    SET_HOME_FILTER,
);

const GET_HOME_DASHBOARD_STATS = 'home/GET_HOME_DASHBOARD_STATS';
const GET_HOME_DASHBOARD_STATS_SUCCESS = 'home/GET_HOME_DASHBOARD_STATS_SUCCESS';
const GET_HOME_DASHBOARD_STATS_ERROR = 'home/GET_HOME_DASHBOARD_STATS_ERROR';

export const getHomeDashboardStats = createActions<
void,
IHomeDashboardResponse,
any,
    typeof GET_HOME_DASHBOARD_STATS,
    typeof GET_HOME_DASHBOARD_STATS_SUCCESS,
    typeof GET_HOME_DASHBOARD_STATS_ERROR
>(
    GET_HOME_DASHBOARD_STATS, GET_HOME_DASHBOARD_STATS_SUCCESS, GET_HOME_DASHBOARD_STATS_ERROR,
);

export type HomeActions =
    | ActionsReturnTypes<typeof getHomeDashboardStats>
    | ReturnType<typeof setHomeDashboardFilter>;
