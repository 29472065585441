/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { formatBNumber } from 'shared/utils/formatters/bNumber';
import { useIsMobile } from 'shared/utils/hooks/media';

import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { selectFilteredSubmittingOrgs } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgTable/store/filteredSubmittingOrgsSelector';
import { getSubmittingOrgs } from 'store/entities/configuration/configurationAction';
import {
    selectSubmittingOrgLocationsById,
    selectSubmittingOrgsIsLoading,
} from 'store/entities/configuration/configurationSelectors';
import { EditSubmittingOrgButton } from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/EditSubmittingOrgButton';
import EditSubmittingOrgModal
    from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/EditSubmittingOrgModal';

import { UpdateSubmittingOrgStatusSelect } from 'modules/settings/submodules/clients/submittingOrg/components/UpdateStatusSelect/UpdateSubmittingOrgStatusSelect';
import { Status } from 'shared/models/Status';
import { Box } from '@material-ui/core';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';

interface ISubmittingOrgRow extends ISubmittingOrg {
    className?: string;
}

export const SubmittingOrgTable = () => {
    useWithClientEffect(dispatch => dispatch(getSubmittingOrgs.init()));
    const tableClasses = useDefaultTableStyles();
    const submittingOrgs = useSelector(selectFilteredSubmittingOrgs);
    const isMobile = useIsMobile();
    const isLoading = useSelector(selectSubmittingOrgsIsLoading);
    const submittingOrgLocationsById = useSelector(selectSubmittingOrgLocationsById);

    const cells = [
        {
            key: 'CLIENT_SITE',
            title: 'CLIENT SITE',
            render: ({ client_site_name, className }: ISubmittingOrgRow) => (
                <PlainText className={className} value={client_site_name}/>
            ),
        },
        {
            key: 'SUB_ORG',
            title: 'SUB ORG #',
            render: ({ submitting_org_number, className }: ISubmittingOrgRow) => (
                <PlainText className={className} value={formatBNumber(submitting_org_number)}/>
            ),
        },
        {
            key: 'REGION_HUB',
            title: 'REGION/HUB',
            render: ({ className, location_id }: ISubmittingOrgRow) => (
                <PlainText className={className}
                    value={submittingOrgLocationsById[location_id]?.hub || ''}/>
            ),
        },
        {
            key: 'city',
            title: 'city',
            render: ({ className, city }: ISubmittingOrgRow) => (
                <PlainText className={className} value={city}/>
            ),
        },
        {
            key: 'STATE',
            title: 'STATE',
            render: ({ className, state }: ISubmittingOrgRow) => (
                <PlainText className={className} value={state}/>
            ),
        },
        {
            key: 'status',
            title: 'status',
            render: function StatusCell({ id, active, className }: ISubmittingOrgRow) {
                const currentStatus = active ? Status.active : Status.inactive;
                return (
                    <Box className={className}>
                        <UpdateSubmittingOrgStatusSelect id={id} status={currentStatus}
                            className={tableClasses.statusSelect}/>
                    </Box>
                );
            },
        },
        {
            key: 'actions',
            title: '',
            width: '100px',
            render: function ActionsCell({ className, id }: ISubmittingOrgRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <EditSubmittingOrgButton submittingOrgId={id}/>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <GridTable
                rowData={submittingOrgs}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                getKey={client => client.id}
                cells={cells}
                stickyHeader
                hideHeader={isMobile}
                isLoading={isLoading}
            />
            <EditSubmittingOrgModal/>
        </>

    );
};
