import React from 'react';

import { setEditSubmittingOrgId } from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/actions';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';

export interface IEditSubmittingOrgButtonProps {
    submittingOrgId: string;
}

export const EditSubmittingOrgButton = ({ submittingOrgId }: IEditSubmittingOrgButtonProps) => {
    return (
        <EditItemButton id={submittingOrgId} editAction={setEditSubmittingOrgId}/>
    );
};
