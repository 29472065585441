export const clientRoot = '/client/:client_id';

export const routes = {
    HOME: '/',
    RTI: '/rti',
    AUTH: {
        LOGIN: '/auth/login',
        REGISTRATION: '/auth/register',
        EMAIL_CONFIRMATION: '/email/confirmation',
        RESET_PASSWORD: '/auth/forgot',
        NEW_PASSWORD: '/auth/new_password',
        RESET_FAILED: '/auth/reset-failed',
    },
    EMPLOYEE_OFFER_LETTER: {
        ROOT: '/offer-letter',
        DETAIL: '/offer-letter/:id',
    },
    OFFER_PDF_ONLY_VIEW: '/offer-pdf-only/:id',
    END_ASSIGNMENT_PDF_ONLY_VIEW: '/end-of-assignment-pdf-only/:id',
    CLIENT: {
        ROOT: clientRoot,
        REPORTS: `${clientRoot}/reports`,
        DASHBOARD: `${clientRoot}/dashboard`,
        EMPLOYEES: `${clientRoot}/employees`,
        EMPLOYMENT_INFO: `${clientRoot}/employment-info`,
        OFFER_LETTER: {
            ROOT: `${clientRoot}/offer-letter`,
            CREATE: `${clientRoot}/offer-letter/create`,
            DETAILS: `${clientRoot}/offer-letter/:id`,
            EDIT: `${clientRoot}/offer-letter/:id/edit`,
            RESCIND: `${clientRoot}/offer-letter/:id/rescind`,
        },
        ONBOARDING: `${clientRoot}/onboarding`,
        EVALUATIONS: `${clientRoot}/evaluations`,
        TIME_AND_EXPENSE: {
            ROOT: `${clientRoot}/time-and-expense`,
            SHEET_DETAIL: `${clientRoot}/time-and-expense/sheet-detail/:sheetType/:sheetId`,
        },
        TIME_APPROVAL: {
            ROOT: `${clientRoot}/time-approval`,
            SHEET_DETAIL: `${clientRoot}/time-approval/sheet-detail/:sheetId`,
        },
        EXPENSE_APPROVAL: {
            ROOT: `${clientRoot}/expense-approval`,
            SHEET_DETAIL: `${clientRoot}/expense-approval/sheet-detail/:sheetId`,
        },
        TRAVEL_EXPENSES: {
            ROOT: `${clientRoot}/travel-expenses`,
        },
        EMPLOYEE_PORTAL: `${clientRoot}/employee-portal`,
        EMPLOYMENT_MANAGEMENT: {
            ROOT: `${clientRoot}/employment-management/`,
            ASSIGNMENT_MANAGEMENT: `${clientRoot}/employment-management/assignment-management`,
            EAF_APPROVAL: `${clientRoot}/employment-management/eaf-approval`,
        },
        USERS: {
            ROOT: `${clientRoot}/users`,
            EMPLOYEES: `${clientRoot}/users/employees`,
            MANAGERS: `${clientRoot}/users/managers`,
        },
        CCP: {
            ROOT: `${clientRoot}/ccp`,
        },
    },
    PAYROLL_PROCESSOR_HUB: {
        ROOT: '/payroll-hub',
        SHEETS: '/payroll-hub/sheets',
        SHEET_DETAIL: '/payroll-hub/sheets/sheet-detail/:timeSheetId/:expenseSheetId',
        EDIT_SHEET: '/payroll-hub/sheets/sheet-edit/:timeSheetId/:expenseSheetId',
    },
    HUMAN_RESOURCES: {
        ROOT: '/human-resources',
        TERMINATION_REQUESTS: '/human-resources/termination-requests',
    },
    SETTINGS: '/settings',
    USER_PROFILE: '/user-profile',
    KEYCLOAK_CHECK_SSO: '/silent-check-sso.html',
};
