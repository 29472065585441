import { IStore } from 'store/configureStore';
import { selectCcpTransactionsState } from '../../../store/selectors';
import { selectCcpTransactionsById } from '../../CcpTransactionsTable/store/selectors';
import { ICcpTransaction } from '../../../models/CcpTransaction';

export const selectSplitCcpState = (state: IStore) => {
    return selectCcpTransactionsState(state).splitCcpTransactions;
};

export const selectSplitOpenedTransactionId = (state: IStore) =>
    selectSplitCcpState(state).splitSideBarSelectedTransactionId;

export const selectIsOpenCcpSplitSidebar = (state: IStore) => {
    const currentSelectedId = selectSplitCcpState(state).splitSideBarSelectedTransactionId;
    return !!currentSelectedId;
};

export const selectCcpSplitTable = (state: IStore) => {
    const tableState = selectSplitCcpState(state).ccpSplitTable;
    return tableState;
};

export const selectCcpSplitTableItems = (state: IStore) => {
    const tableState = selectCcpSplitTable(state).items;
    return tableState;
};

export const selectCcpSplitAmountTotal = (state: IStore) =>
    selectSplitCcpState(state).ccpSplitAmountTotal;

export const selectIsCcpSplitInProgress = (state: IStore) =>
    selectSplitCcpState(state).isCcpSplitSubmitting;

export const selectChildrenByOpenCcpTransaction = (state: IStore) => {
    const currentSelectedId = selectSplitCcpState(state).splitSideBarSelectedTransactionId;
    const transactionsById = selectCcpTransactionsById(state);
    const transactionItem = currentSelectedId[transactionsById];
    const subItems = [] as ICcpTransaction[];
    if (transactionItem) {
        for (const key in transactionsById) {
            const currentTransaction = transactionsById[key] as ICcpTransaction;
            if (currentTransaction.parent_transaction_id === currentSelectedId) {
                subItems.push(currentTransaction);
            }
        }
    }
    return subItems;
};
