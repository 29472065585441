/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { IClientRoleRow } from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/model';
import { setClientRoleSort, setEditClientRole } from 'modules/settings/submodules/platformUsers/store/actions';
import { selectClientRoleSort } from 'modules/settings/submodules/platformUsers/store/selectors';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo, ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { SortableTableHead } from 'shared/components/table/GridTable/SortableTableHead';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';

export const getSortableHead = (id: string, title: string) => function EffectiveDateTableHead() {
    return (
        <SortableTableHead
            id={id}
            tableSortingSelector={selectClientRoleSort}
            setOrderAction={setClientRoleSort}
        >
            {title}
        </SortableTableHead>
    );
};

export const useClientRolesCells = (): ICellInfo<IClientRoleRow>[] => {
    const tableClasses = useDefaultTableStyles();

    return [
        {
            key: 'name',
            renderTitle: getSortableHead('name', 'Role Name'),
            render: ({ className, name }: ICellProps<IClientRoleRow>) => {
                return (
                    <PlainText className={className} value={name}/>
                );
            },
        },
        {
            key: 'description',
            title: 'Description',
            render: ({ className, description }: ICellProps<IClientRoleRow>) => {
                return (
                    <PlainText className={className} value={description}/>
                );
            },
        },
        {
            key: 'client',
            title: 'Client',
            render: ({ className, all_clients, client_ids = [] }: ICellProps<IClientRoleRow>) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const clientByIds = useSelector(selectAllClientsById);
                const clientsText = all_clients
                    ? 'All'
                    : client_ids.map(clientId => clientByIds[clientId]?.name).filter(Boolean).join(', ');
                return (
                    <PlainText className={className} value={clientsText}/>
                );
            },
        },
        {
            key: 'users',
            renderTitle: getSortableHead('users_count', 'Users'),
            render: ({ className, users_count }: ICellProps<IClientRoleRow>) => {
                return (
                    <PlainText className={className} value={users_count}/>
                );
            },
        },
        {
            key: 'actions',
            title: '',
            width: '54px',
            render: function DetailsCell({ className, id }: ICellProps<IClientRoleRow>) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <EditItemButton id={id} editAction={setEditClientRole}/>
                    </div>
                );
            },
        },
    ];
};
