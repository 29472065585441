import React from 'react';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import FormField from 'shared/components/formFields/FormField';
import { FormMode } from 'shared/models/FormMode';
import { FormSectionFieldWrapper } from '../components/FormSectionFieldWrapper';
import { NameCodeFormDataSection } from '../NameCodeFormDataSection';
import { PositionToOfferLetterDataSection } from './PositionToOfferLetterDataSection';

export interface IPositionCustomFieldDataSectionProps {
    path: string;
    mode: FormMode;
}

export const PositionCustomFieldDataSection = ({
    path,
    mode,
}: IPositionCustomFieldDataSectionProps) => {
    const classes = useSettingsFormStyles();
    const classificationFieldName = `${path || ''}position_class`;

    return (
        <NameCodeFormDataSection
            mode={mode}
            path={path}
            disabled
            extensionFields={(
                <>
                    <FormSectionFieldWrapper>
                        <FormField
                            label="Classification"
                            name={classificationFieldName}
                            className={classes.input}
                            disabled
                        />
                    </FormSectionFieldWrapper>
                    <PositionToOfferLetterDataSection path={path}/>
                </>
            )}
        />
    );
};
