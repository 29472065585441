import {
    EmployeeActionFormTableActions,
    initialLoadEafApprovalItems,
    loadMoreEafApproval,
    patchEafItemsRequest,
    resetEafApprovalItems,
    setEafApprovalActiveTab,
    setEafApprovalFilter,
    setEafApprovalSortOrder,
} from 'modules/eafApproval/store/actions';
import { IEmployeeActionForm } from 'modules/employeeActionsForm/store/models';
import {
    defaultInfinityScrollState,
    IInfinityScrollState,
} from 'store/reducerUtils';

export function table(
    state: IInfinityScrollState<IEmployeeActionForm>,
    action: EmployeeActionFormTableActions,
): IInfinityScrollState<IEmployeeActionForm> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case resetEafApprovalItems.action:
            return defaultInfinityScrollState;
        case setEafApprovalActiveTab.action:
        case setEafApprovalFilter.action:
        case initialLoadEafApprovalItems.action:
        case setEafApprovalSortOrder.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case loadMoreEafApproval.initType:
            return {
                ...state,
                isLoading: true,
            };
        case loadMoreEafApproval.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case loadMoreEafApproval.successType:
            return {
                // @ts-ignore
                total: action.payload.total_items,
                // @ts-ignore
                items: [...state.items, ...action.payload.items],
                isLoading: false,
                // @ts-ignore
                cursor: action.payload.next_cursor,
            };
        case patchEafItemsRequest.successType: {
            // TODO: Adjust behavior
            // Now only updated status so it always affected items in table
            // @ts-ignore
            const updatedIds = action.payload.employee_action_forms.map(item => item.id);
            const items = state.items.filter(item => !updatedIds.includes(item.id));
            return {
                ...state,
                items,
                // @ts-ignore
                total: state.total - action.payload.employee_action_forms.length,
            };
        }
        default:
            return state;
    }
}
