import baseApi from 'shared/utils/baseApi';

import {
    dealEntity,
    gwClientExtensionsService,
} from 'store/entities/configuration/configurationApi';
import { IInvoiceRecipient } from 'shared/models/Deal';

export const invoiceRecipientsApi = {
    async getInvoiceRecipients(controllingOrgId: string, submittingOrgId: string): Promise<IInvoiceRecipient[]> {
        const params = {
            controlling_org_id: controllingOrgId,
            submitting_org_id: submittingOrgId,
        } as IParams;
        const { data } = await baseApi.get<{ recipients: IInvoiceRecipient[] }>(
            `/${gwClientExtensionsService}/${dealEntity}/recipients/prepare`,
            params,
        );
        return data.recipients;
    },
};

interface IParams {
    controlling_org_id: string;
    submitting_org_id: string;
}
