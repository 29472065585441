import baseApi from 'shared/utils/baseApi';
import { IZipCode } from 'store/entities/zipCode/model';

class ZipCodeApi {
    async getZipCodes(starts_with?: string): Promise<Array<IZipCode>> {
        const { data } = await baseApi.get<{ zip_codes: Array<any> }>(
            `/addresses/zip_codes`, { starts_with: starts_with || undefined, limit: 10 },
        );

        return data.zip_codes;
    }
}

export const zipCodeApi = new ZipCodeApi();
