import { createSelector } from 'reselect';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { IStore } from 'store/configureStore';
import { ApproversFromFields, EntrySlug, IPaySettings, ITimeAndPayClientConfiguration } from 'store/entities/clients/clientsModel';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectCurrentClientFieldConfiguration } from 'store/entities/clients/selectors/fieldSelectors';

export const selectPaySettingsByClientIds = (state: IStore) => state.clients.paySettingsByClientId;
export const selectPaySettings = (state: IStore): IPaySettings | undefined => {
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return undefined;
    }
    return selectPaySettingsByClientIds(state)[clientId];
};
export const selectIsPaySettingsLoading = (state: IStore): boolean => {
    return state.clients.isPaySettingsLoading;
};
export const selectClientTimeAndPayConfiguration = (state: IStore) => {
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return undefined;
    }
    return state.clients.timeAndPayConfigurationByClientId[clientId];
};
export const selectClientIsGovernmentContract = (state: IStore): boolean => {
    const clientTimeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    return clientTimeAndPayConfiguration?.isGovernmentContract;
};
export const selectClientTimeTrackingActivityTypes = (state: IStore): QuantityType[] => {
    const clientTimeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    return clientTimeAndPayConfiguration?.timeTracking?.activity_types as QuantityType[] || [];
};
export const selectClientApprovalLevels = (state: IStore) => {
    const clientApproversFrom = selectClientApproversFrom(state);
    if (clientApproversFrom === ApproversFromFields.FromJobNumber) {
        return 1;
    }
    const clientTimeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    return clientTimeAndPayConfiguration?.approvalLevels ?? 1;
};
export const selectClientApproversFrom = (state: IStore): ApproversFromFields | undefined => {
    const clientTimeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    if (!clientTimeAndPayConfiguration) {
        return undefined;
    }
    return clientTimeAndPayConfiguration.approversFrom;
};
export const selectTimeAndPayConfigurationByClientIds = (state: IStore) =>
    state.clients.timeAndPayConfigurationByClientId;
export const selectClientTimeAndPayConfigurationByClientId = (clientId: string) =>
    (state: IStore): ITimeAndPayClientConfiguration | undefined => {
        return selectTimeAndPayConfigurationByClientIds(state)[clientId];
    };
export const selectClientHasJobNumberConfiguration = (clientId: string) => (state: IStore) => {
    return selectClientTimeAndPayConfigurationByClientId(clientId)(state)?.hasJobNumberConfiguration;
};
export const selectCurrentClientHasJobNumberConfiguration = createSelector(
    selectCurrentClientId,
    selectTimeAndPayConfigurationByClientIds,
    (clientId, configurationByIds) => {
        return configurationByIds[clientId || '']?.hasJobNumberConfiguration;
    },
);
/**
 * Has job number field
 */
export const selectIsJobNumberFieldsApplied = createSelector(
    selectCurrentClientFieldConfiguration,
    fieldsConfiguration => {
        const inputConfiguration = fieldsConfiguration?.inputs?.expense || fieldsConfiguration?.inputs?.time;
        return inputConfiguration?.[EntrySlug.JobNumber] || false;
    },
);
