import { attachmentApi } from 'shared/components/attachments/store/api';
import { IAttachmentCreateMetaInfo } from 'shared/components/formFields/AttachmentInput/model';
import { IAttachment } from 'shared/models/Attachments';
import { logger } from 'shared/utils/logging/logger';

export function uploadFile(
    file: File,
    metaInfo: IAttachmentCreateMetaInfo,
): Promise<IAttachment | null> {
    return attachmentApi.uploadAttachment(file, metaInfo)
        .then(backendAttachment => {
            const attachment: IAttachment = {
                id: backendAttachment.id,
                filename: backendAttachment.original_filename,
                mimetype: backendAttachment.mimetype,
                url: attachmentApi.getAttachmentUrl(backendAttachment.id),
            };
            return attachment;
        })
        .catch(error => {
            logger.error(error, { message: 'Unable to upload attachment' });
            return null;
        });
}
