import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import {
    bulkUploadCcpTransactionAction,
    bulkUploadCreateCcpTransactionPreInitializeAction, initialLoadCcpTransactionsPage,
} from '../../../store/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import { ccpApi } from '../../api/ccpApi';
import { withBackendErrorHandler } from '../../../../../store/utils/sagas/withBackendErrorHandler';

export const uploadCcpTransactionsPreCreateReportWatcher = getLoadEntitiesByRequestSagaWatcher(
    bulkUploadCreateCcpTransactionPreInitializeAction,
    ccpApi.uploadCsvBulkValidateTransactions,
    'pre-initialization ccp report',
);

function* bulkUploadCcpTransactionSaga({ payload }: ReturnType<typeof bulkUploadCcpTransactionAction.init>) {
    const items = yield* call(ccpApi.uploadCsvImportTransactions, payload || {});
    yield put(initialLoadCcpTransactionsPage());
    yield put(bulkUploadCcpTransactionAction.success(items));
}

export function* uploadCcpTransactionsImportWatcher() {
    yield takeLatest(bulkUploadCcpTransactionAction.initType,
        withBackendErrorHandler(
            bulkUploadCcpTransactionSaga,
            bulkUploadCcpTransactionAction.error,
            'Unable to import CCP Transactions',
        ),
    );
}
