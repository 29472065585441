import React from 'react';
import moment from 'moment-timezone';
import { Badge, Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { INotification } from 'modules/notificationCenter/store/model';
import { updateNotifications } from 'modules/notificationCenter/store/actions';
import { useStyles } from 'modules/notificationCenter/components/notificationsSidebar/styles';
import { Link } from 'react-router-dom';

export interface INotificationCardProps {
    notification: INotification;
}

export const NotificationCard = ({ notification }: INotificationCardProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const created = moment.utc(notification.created_at).tz(moment.tz.guess()).fromNow();

    const readNotification = () => {
        if (!notification.viewed) {
            dispatch(updateNotifications.init([
                {
                    id: notification.id,
                    viewed: true,
                },
            ]));
        }
    };

    return (
        <Box
            className={classes.notificationContainer}
            onClick={readNotification}
            data-testid="notification-card"
        >
            {notification.link ? (
                <Link to={notification.link}>
                    <Typography
                        variant="subtitle1"
                        className={classes.notificationTitle}
                        data-testid="notification-card-subject"
                    >
                        {notification.subject_line}
                    </Typography>
                </Link>
            ) : (
                <Typography variant="subtitle1" className={classes.notificationTitle}>{notification.subject_line}</Typography>
            )}
            <Badge
                color="secondary"
                variant="dot"
                invisible={notification.viewed}
                classes={{
                    root: classes.unreadBadgeRoot,
                    badge: classes.unreadBadge,
                }}
            >
                <Typography variant="caption" className={classes.notificationBody}>{notification.body}</Typography>
            </Badge>
            <Typography variant="caption" className={classes.notificationTime}>{created}</Typography>
        </Box>
    );
};
