/* eslint-disable react/display-name */
import { orderBy } from 'lodash-es';
import { ClientTableCell } from 'modules/settings/submodules/clients/components/ClientsTable/ClientTableCell';
import { setClientTableSort } from 'modules/settings/submodules/clients/store/actions';
import { selectClientTableSort } from 'modules/settings/submodules/clients/store/selectors';
import moment from 'moment';
import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { SortableTableHead } from 'shared/components/table/GridTable/SortableTableHead';
import { Order } from 'shared/models/Order';
import { IUserInfo } from 'shared/models/User';
import { useSelector } from 'react-redux';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { shortDateFormat } from 'shared/models/Dates';
import { settingsRoutes } from 'modules/settings/routes';
import { getLastFirstName } from 'shared/utils/converters/user';
import { isContainSubstring } from 'shared/utils/helpers/isContainSubstring';
import { IClientInfo } from 'store/entities/clients/clientsModel';
import { selectAvailableClients } from 'store/entities/clients/selectors/clientsSelectors';

// @ts-ignore
export interface IClientRow extends IClientInfo {
    className?: string;
    name: string;
    id: string;
    short_name: string;
    created_at?: string;
    updated_at?: string;
    prism_client_id?: string;
    manager: IUserInfo | null;
}

interface IClientsTableProps {
    search: string;
}

const getSortableHead = (id: string, title: string) => function TableHeadCell() {
    return (
        <SortableTableHead
            id={id}
            tableSortingSelector={selectClientTableSort}
            setOrderAction={setClientTableSort}
        >
            {title}
        </SortableTableHead>
    );
};

export const ClientsTable = ({ search }: IClientsTableProps) => {
    const tableClasses = useDefaultTableStyles();
    const clientById = useSelector(selectAvailableClients);
    const sort = useSelector(selectClientTableSort);
    const clients = useMemo(() => {
        const filtered = Object.values(clientById).filter(client => {
            return isContainSubstring(client.name, search)
                || (client?.prism_client_id && isContainSubstring(client?.prism_client_id, search));
        });
        const [key, order] = Object.entries(sort)[0];
        return orderBy(
            filtered,
            [key],
            [order as Order],
        );
    }, [clientById, search, sort]);

    const clientCells = [
        {
            key: 'id',
            render: ({ prism_client_id, className, id }: IClientRow) => (
                <ClientTableCell
                    // @ts-ignore
                    value={prism_client_id}
                    className={className}
                    id={id}
                />
            ),
            renderTitle: getSortableHead('prism_client_id', 'ID'),
        },
        {
            key: 'client',
            render: ({ name, className, id }: IClientRow) => (
                <ClientTableCell
                    value={name}
                    className={className}
                    id={id}
                />
            ),
            renderTitle: getSortableHead('name', 'Client'),
        },
        {
            key: 'client manager',
            title: 'client manager',
            render: ({ className, manager, id }: IClientRow) => (
                <ClientTableCell
                    value={getLastFirstName(manager)}
                    className={className}
                    id={id}
                />
            ),
        },
        {
            key: 'created',
            render: ({ created_at, className, id }: IClientRow) => (
                <ClientTableCell
                    value={moment(created_at).format(shortDateFormat)}
                    className={className}
                    id={id}
                />
            ),
            renderTitle: getSortableHead('created_at', 'created'),
        },
        {
            key: 'updated',
            title: 'updated',
            render: ({ updated_at, className, id }: IClientRow) => (
                <ClientTableCell
                    value={moment(updated_at).format(shortDateFormat)}
                    className={className}
                    id={id}
                />
            ),
        },
        {
            key: 'status',
            title: 'status',
            width: '100px',
            render: ({ status, className, id }: IClientRow) => (
                <ClientTableCell
                    value={status?.name || ''}
                    className={className}
                    id={id}
                />
            ),
        },
        {
            key: 'state',
            title: 'state',
            render: ({ state, className, id }: IClientRow) => (
                <ClientTableCell
                    value={state}
                    className={className}
                    id={id}
                />
            ),
            renderTitle: getSortableHead('state', 'state'),
        },
        {
            key: 'client contact',
            title: 'client contact',
            render: ({ contact_name, className, id }: IClientRow) => (
                <ClientTableCell
                    value={contact_name}
                    className={className}
                    id={id}
                />
            ),
        },
        {
            key: 'details',
            title: '',
            width: '152px',
            render: function DetailsCell({ className, id }: IClientRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <Link to={generatePath(settingsRoutes.CLIENTS.DASHBOARD, { clientId: id })}>
                            <IconButton data-testid="details-button">
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </Link>
                    </div>
                );
            },
        },
    ];

    return (
        <GridTable
            rowData={clients}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={client => client.id}
            // @ts-ignore
            cells={clientCells}
            stickyHeader
        />
    );
};
