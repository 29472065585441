import { ToastCustomContentKey } from 'shared/components/toasts/customContentToastMapper';

export enum IModalSeverity {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
}

export interface IGlobalModal {
    severity?: IModalSeverity;
    title: string;
    autoHideDuration?: number | null;
    ignoreSidebar?: boolean;
    customComponentKey?: ToastCustomContentKey;
}

export const autoHideDefaultDuration = 4000;
