import { IStore } from 'store/configureStore';
import {
    ITravelExpense,
} from 'modules/travelExpenses/model';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { createSelector } from 'reselect';

import {
    selectTravelExpensesById,
    selectTravelExpensesState,
} from 'modules/travelExpenses/selectors';

const selectTravelExpenseTableState = (state: IStore) => selectTravelExpensesState(state).travelExpenseTable;
export const travelExpenseInfinityTableSelectors = getInfinityScrollSelectors<string>(
    selectTravelExpenseTableState,
    30,
    true);
export const selectTravelExpenseRows = createSelector(
    travelExpenseInfinityTableSelectors.selectItems,
    selectTravelExpensesById,
    (ids, travelExpenseByIds): ITravelExpense[] => {
        return ids.flatMap(id => travelExpenseByIds[id])
            .sort((a, b) => a.entry_date < b.entry_date ? 1 : -1);
    },
);
