import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { IPayCode } from 'modules/settings/submodules/clients/payCodes/store/model';
import { IDealType } from 'shared/models/DealType';

export interface IDealTypePayCodeArBucketForm {
    payCode: IPayCode;
    dealType: IDealType;
    arBucket: string;
}

export default yup.object().shape({
    payCode: yup.object().nullable().required(ValidationMessages.REQUIRED),
    dealType: yup.object().nullable().required(ValidationMessages.REQUIRED),
    arBucket: yup.string().nullable().required(ValidationMessages.REQUIRED),
});
