import { combineReducers } from 'redux';
import { Order } from 'shared/models/Order';
import { IClientUserAssociation } from 'store/entities/users/model';
import {
    createClientUsersAssociation,
    createPlatformUser,
    getMoreUsersActions,

    setCreateUserModalState,
    setPlatformUsersFilter,

    PlatformUsersActions,
    getClientUsersAssociation,
    getMoreClientUsersAssociation,
    setClientUserAssociationFilter,
    setEditPlatformUser,
    updatePlatformUser,
    setPlatformUserStatus,
    clearUsersAndFilter,
    setCreateUserAssociationModalState,
    setClientRoleSort,
    setClientRolesFilter,
    setCreateClientRoleModalState,
    createClientRole,
    setEditClientRole,
    updateClientRole, deleteClientRole,
} from './actions';
import { IPlatformUsersFilter, IUsersInfo } from './models';
import {
    defaultInfinityScrollState,
    IInfinityScrollState,
    isLoadingReducer,
    isOpenModalReducer,
    singleValueReducer,
} from 'store/reducerUtils';

export const initialState = {
    usersInfo: {
        totalUsers: undefined,
        users: [],
        isLoading: false,
    },
    clientUsersAssociations: {
        associations: {
            total: undefined,
            items: [],
            isLoading: false,
        },
    },
};

const isOpenCreateUserModal = isOpenModalReducer(setCreateUserModalState.action);
const isUserCreating = isLoadingReducer(createPlatformUser);
const isClientUserAssociationCreating = isLoadingReducer(createClientUsersAssociation);
const isPlatformUserStatusUpdating = isLoadingReducer(setPlatformUserStatus);
const isUserUpdating = isLoadingReducer(updatePlatformUser);

function editUserId(
    state: string | null,
    action: PlatformUsersActions,
): string | null {
    state = state || null;
    switch (action.type) {
        case setEditPlatformUser.action:
            // @ts-ignore
            return action.payload;
        default:
            return state;
    }
}

function usersInfo(
    state: IUsersInfo = initialState.usersInfo,
    action: PlatformUsersActions,
): IUsersInfo {
    switch (action.type) {
        case setPlatformUsersFilter.action:
            return {
                ...initialState.usersInfo,
                isLoading: true,
            };
        case getMoreUsersActions.initType:
            return {
                ...state,
                isLoading: true,
            };
        case getMoreUsersActions.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreUsersActions.successType:
            return {
                // @ts-ignore
                totalUsers: action.payload.total_users,
                // @ts-ignore
                users: [...state.users, ...action.payload.users],
                isLoading: false,
            };
        case clearUsersAndFilter.action:
            return {
                totalUsers: undefined,
                users: [],
                isLoading: false,
            };
        case setPlatformUserStatus.successType:
        case updatePlatformUser.successType: {
            const updatedUser = action.payload;
            return {
                ...state,
                // @ts-ignore
                users: state.users.map(user => user.id === updatedUser.id ? updatedUser : user),
            };
        }
        default:
            return state;
    }
}

function platformUserFilter(
    state: IPlatformUsersFilter,
    action: ReturnType<typeof setPlatformUsersFilter> | ReturnType<typeof clearUsersAndFilter>,
): IPlatformUsersFilter {
    state = state || {};
    if (action.type === setPlatformUsersFilter.action) {
        return (action as ReturnType<typeof setPlatformUsersFilter>).payload;
    }
    if (action.type === clearUsersAndFilter.action){
        return {};
    }
    return state;
}

export function clientUsersAssociationsTable(
    state: IInfinityScrollState<IClientUserAssociation>,
    action: PlatformUsersActions,
): IInfinityScrollState<IClientUserAssociation> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setClientUserAssociationFilter.action:
        case getClientUsersAssociation.initType:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getClientUsersAssociation.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getClientUsersAssociation.successType:
        case getMoreClientUsersAssociation.successType:
            return {
                // @ts-ignore
                total: action.payload.total_client_user_roles,
                // @ts-ignore
                items: [...state.items, ...action.payload.client_user_roles],
                isLoading: false,
            };
        default:
            return state;
    }
}

const clientUsersAssociationsFilter = singleValueReducer(setClientUserAssociationFilter.action, {});
const isModalOpen = singleValueReducer(setCreateUserAssociationModalState.action, false);

const clientRolesFilter = singleValueReducer(setClientRolesFilter.action, { search: '' });
const clientRolesSort = singleValueReducer(setClientRoleSort.action, { 'name': Order.asc });

const clientRolesCreateModal = singleValueReducer(setCreateClientRoleModalState.action, false);
const clientRolesCreating = isLoadingReducer(createClientRole);

const editClientRoleId = singleValueReducer(setEditClientRole.action, null);
const clientRoleEditing = isLoadingReducer(updateClientRole);

const clientRoleDeleting = isLoadingReducer(deleteClientRole);

export const platformUsers = combineReducers({
    usersInfo,
    platformUserFilter,
    isPlatformUserStatusUpdating,
    createUsers: combineReducers({
        isOpenCreateUserModal,
        isUserCreating,
    }),
    editUsers: combineReducers({
        editUserId,
        isUserUpdating,
    }),
    clientUsersAssociations: combineReducers({
        isClientUserAssociationCreating,
        table: clientUsersAssociationsTable,
        filter: clientUsersAssociationsFilter,
        isModalOpen,
    }),
    clientRoles: combineReducers({
        filter: clientRolesFilter,
        sort: clientRolesSort,
        createModal: clientRolesCreateModal,
        isCreating: clientRolesCreating,
        editId: editClientRoleId,
        isEditing: clientRoleEditing,
        isDeleting: clientRoleDeleting,
    }),
});
