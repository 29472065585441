import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setHomeDashboardFilter } from 'modules/home/store/actions';
import { selectHomeFilter } from 'modules/home/store/selectors';

import { DashboardFilterForm } from './DashboardFilterForm';
import { IDashboardFilters } from 'shared/models/Dashboard';

export const DashboardFilter = () => {
    const dispatch = useDispatch();
    const filterState = useSelector(selectHomeFilter);

    const onChange = useCallback((values: Partial<IDashboardFilters>) => {
        dispatch(setHomeDashboardFilter(values));
    }, [dispatch]);
    return (
        <>
            <Formik
                key={filterState.clientId}
                initialValues={filterState}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={() => {}}
                validateOnBlur={false}
            >
                {props =>
                    (
                        <DashboardFilterForm
                            {...props}
                            onChange={onChange}
                            actualFilterValues={filterState}
                        />
                    )
                }
            </Formik>
        </>
    );
};
