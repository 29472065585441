import { isObject } from 'lodash-es';

/**
 * Check that object and root level property is not empty. Useful for filters
 * @param data
 */
export function isNotEmpty(data: Record<any, any>): boolean {
    return Object.values(data).some(
        item => isObject(item) ? isNotEmpty(item) : Boolean(item),
    );
}
