import { createSelector } from 'reselect';
import {
    selectPreInitializeDeleteReportTableRows,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { ISubAssignmentBulkDeletePayload } from 'modules/subassignmentManagement/store/models';

export const deletePayloadSelector = createSelector(
    selectPreInitializeDeleteReportTableRows,
    rows => {
        return {
            subassignmentsIds: rows.map(row => row.id),
        } as ISubAssignmentBulkDeletePayload;
    },
);
