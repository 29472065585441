import { IZipCodeFull, TExpenseEntryData } from 'shared/models/sheet/Sheet';
import { ICommonEntryFormValues, defaultValues as commonDefaultValues } from './EntryCommonFields';

export interface IExpenseEntryFormValues extends ICommonEntryFormValues {
    data: TExpenseEntryData | null;
    notes: string;
    zipCode?: IZipCodeFull;
}

export const defaultValues: IExpenseEntryFormValues = {
    ...commonDefaultValues,
    data: null,
    notes: '',
    zipCode: undefined,
};
