import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { routes } from 'shared/routes';
import { EntryType } from 'shared/models/sheet/Sheet';
import { browserHistory } from 'shared/utils/browserHistory';
import { setPayrollDetailCalculationGroupId, unlockSheet } from 'modules/payrollProcessorHub/store/actions';

export function useConfirmUnlockHandler(
    timeSheetIds: string[],
    expenseSheetIds: string[],
    closeDialog: () => void,
) {
    const dispatch = useDispatch();

    const handleConfirmUnlock = useCallback(() => {
        dispatch(unlockSheet({
            sheets: [
                ...timeSheetIds.map(id => ({
                    id: id,
                    sheetType: EntryType.TIME,
                })),
                ...expenseSheetIds.map(id => ({
                    id: id,
                    sheetType: EntryType.EXPENSE,
                })),
            ],
        }));
        closeDialog();
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
        dispatch(setPayrollDetailCalculationGroupId(null));
    }, [dispatch, timeSheetIds, expenseSheetIds, closeDialog]);

    return handleConfirmUnlock;
}
