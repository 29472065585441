import React from 'react';
import clsx from 'clsx';
import { StepIconProps } from '@material-ui/core';
import { useStepIconStyles } from 'modules/offerLetter/OfferLetterStyles';

export const OnboardingStepIcon = (props: StepIconProps) => {
    const classes = useStepIconStyles();
    const { active, completed, icon } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icon}
        </div>
    );
};
