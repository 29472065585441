import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useAutocompleteWithSearchStyles = makeHighPriorityStyles({
    inputRoot: {
        paddingRight: '4px !important',
    },
    endAdornment: {
        position: 'relative !important' as 'relative',
        right: '0 !important',
    },
});
