import React from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import {
    selectIsActivitiesLoading,
    selectTravelExpenseActivities,
} from 'store/entities/configuration/configurationSelectors';
import { IActivity } from 'store/entities/configuration/configurationModel';

interface ITravelExpenseTypeSelectProps extends IFormFieldProps, IFormSelect {
}

export function TravelExpenseTypeSelect(props: ITravelExpenseTypeSelectProps) {
    const travelExpenseActivities = useSelector(selectTravelExpenseActivities);
    const isActivitiesLoading = useSelector(selectIsActivitiesLoading);

    return (
        <FormSelect
            {...props}
            getKey={(travelExpenseActivity: IActivity) => travelExpenseActivity.id}
            getText={(travelExpenseActivity: IActivity) => travelExpenseActivity.description}
            options={travelExpenseActivities}
            disabled={!travelExpenseActivities.length}
            isLoading={isActivitiesLoading}
        />
    );
}
