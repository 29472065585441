import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    authWrapper: {
        backgroundImage: `url("${process.env.PUBLIC_URL}/images/background-login.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            background: colors.white,
            alignItems: 'flex-start',
        },
    },
    contentWrapper: {
        maxWidth: '480px',
    },
    logoContainer: {
        display: 'flex',
        height: theme.spacing(7),
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(2),
        justifyContent: 'center',
        '& img': {
            height: '100%',
        },
    },
    block: {
        display: 'flex',
        backgroundColor: colors.white,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(3),
        '& + &': {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            '& + &': {
                marginTop: theme.spacing(0.25),
            },
        },
    },
    form: {
        width: '100%',
    },
    input: {
        width: '100%',
        marginTop: `${theme.spacing(2)}px !important`,
        '& a': {
            color: colors.red,
        },
        '& .MuiInputLabel-outlined': {
            fontSize: 16,
            transform: 'translate(16px, 20px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
        '& .MuiOutlinedInput-input': {
            fontSize: 16,
            minHeight: theme.spacing(7),
            padding: theme.spacing(0, 2),
        },
    },
    userMessage: {
        '&&': {
            ...theme.typography.body2,
            color: colors.green,
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
                margin: theme.spacing(-3),
                padding: theme.spacing(3),
                marginTop: theme.spacing(1),
                boxShadow: 'rgba(0,0,0,0.35) 0px -1px 1px',
            },
        },
        '&.Mui-error': {
            color: colors.red,
        },
    },
    failedMessage: {
        color: colors.red,
    },
    agreement: {
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
        },
    },
    submit: {
        minWidth: theme.spacing(22),
    },
    link: {
        color: colors.blue,
        fontSize: 12,

        '&:visited': {
            color: colors.blue,
        },
    },
    linkLabel: {
        textDecoration: 'none',
    },
}));
