import baseApi from 'shared/utils/baseApi';
import { IPhysicalDemandsTemplate } from 'store/entities/configuration/configurationModel';
import {
    ICreateTemplatePayload,
    ITemplateFormValues,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';
import { withClientId } from 'store/utils/withClientId';

const usersPath = 'client-users';
const usersAdditionalPath = `${usersPath}/additional`;
const physicalDemandsPath = 'physical_demands';

export const physicalDemandsApi = {
    async createPhysicalDemandsTemplate(request: ICreateTemplatePayload): Promise<IPhysicalDemandsTemplate> {
        const { data } = await baseApi.create<ICreateTemplatePayload, IPhysicalDemandsTemplate>(
            usersAdditionalPath, physicalDemandsPath, withClientId(request),
        );
        return data;
    },
    async updatePhysicalDemandsTemplate(
        id: string,
        request: ITemplateFormValues,
    ): Promise<IPhysicalDemandsTemplate> {
        const { data } = await baseApi.patch<ITemplateFormValues, IPhysicalDemandsTemplate>(
            `${usersAdditionalPath}/${physicalDemandsPath}/${id}`, request,
        );
        return data;
    },
    async deletePhysicalDemandsTemplate(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${usersAdditionalPath}/${physicalDemandsPath}`, id,
        );
        return data.status;
    },
};
