import { isAvailableCustomFieldsLoadingSelector } from 'modules/settings/submodules/components/HierarchyPage/AvailableCustomFields/store/selectors';
import { createSelector } from 'reselect';
import { selectCustomFieldValuesByIds, selectIsLoadingCustomFieldsHierarchyNodes } from 'store/entities/customFields/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export const isDefaultHierarchyPageLoadingSelector = createSelector(
    isAvailableCustomFieldsLoadingSelector,
    selectIsLoadingCustomFieldsHierarchyNodes,
    (isAvailableFieldsLoading, isHierarchyNodesLoading) => {
        return isAvailableFieldsLoading || isHierarchyNodesLoading;
    },
);

export const isClientHasCustomFieldValues = createSelector(
    selectCurrentClientId,
    selectCustomFieldValuesByIds,
    (clientId, valuesByIds) => {
        // @ts-ignore
        return clientId && Object.values(valuesByIds).some(value => value.client_id === clientId);
    },
);
