import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import Dashboard from 'shared/components/dashboard/Dashboard';
import { IDashboardCardData } from 'shared/models/Dashboard';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';

import useFilterHeader from './useFilterHeader';
import usePPHStats from './usePPHStats';
import { setPayrollProcessorFilter } from '../store/actions';
import { PayrollSheetTabs } from '../store/model';
import { browserHistory } from 'shared/utils/browserHistory';
import { routes } from 'shared/routes';

const upperFirstLetter = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1);

export const PayrollDashboard = () => {
    useTitleUpdate('Dashboard');
    useFilterHeader();
    const dispatch = useDispatch();

    const dashLink = useCallback((newValue: string) => {
        dispatch(setPayrollProcessorFilter({
            status: upperFirstLetter(newValue) as PayrollSheetTabs,
        }));
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
    }, [dispatch]);
    const [statsData, isLoading] = usePPHStats(dashLink);
    return (
        <Box>
            <Dashboard data={statsData as IDashboardCardData[]} isLoading={isLoading as boolean}/>
        </Box>
    );
};
