import { AnyAction, combineReducers } from 'redux';
import { getOwnConfiguration, SET_ADD_ENTRY_WAS_OPENED } from 'store/components/addEntry/addEntryActions';
import {
    ActivitiesByTaskId,
    activitiesByTaskIdReducer,
} from '../../entities/configuration/helpers/activitiesByTaskIdReducer';

const initialState = {
    wasOpened: false,
    assignmentIds: [],
    taskIds: [],
    activities: [],
};

function wasOpened(state = initialState.wasOpened, action: AnyAction) {
    switch (action.type) {
        case SET_ADD_ENTRY_WAS_OPENED:
            return true;
        default:
            return state;
    }
}

function assignmentIds(
    state: Array<string> = initialState.assignmentIds,
    action: ReturnType<typeof getOwnConfiguration.success>,
): Array<string> {
    switch (action.type) {
        case getOwnConfiguration.successType:
            return action.payload.assignmentIds;
        default:
            return state;
    }
}

function taskIds(
    state: Array<string> = initialState.taskIds,
    action: ReturnType<typeof getOwnConfiguration.success>,
): Array<string> {
    switch (action.type) {
        case getOwnConfiguration.successType:
            return action.payload.taskIds;
        default:
            return state;
    }
}

export function activitiesByTaskId(
    state: ActivitiesByTaskId = {},
    action: ReturnType<typeof getOwnConfiguration.success>,
): ActivitiesByTaskId {
    switch (action.type) {
        case getOwnConfiguration.successType:
            return activitiesByTaskIdReducer(action.payload.activities, state);
        default:
            return state;
    }
}
export const addEntry = combineReducers({
    wasOpened,
    assignmentIds,
    taskIds,
    activitiesByTaskId,
});
