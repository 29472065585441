import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useAddControlsStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddControlsStyles';
import { IBaseCommonEntryFieldsProps } from 'shared/components/forms/entries/CommonEntryInlineFields';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';
import { AddEntryControls } from 'shared/components/table/addEntryControls';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import AddExpenseEntry from './AddExpenseEntry';
import AddTimeEntry from './AddTimeEntry';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/selectors/clientsSelectors';
import EntryTypeButton from './components/EntryTypeButton';
import { setCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/actions';
import { setWorkingEntryTypeFilter } from 'modules/timeAndExpense/components/WorkingPage/store/actions';

interface IAddControlsProps extends IBaseCommonEntryFieldsProps {
    customClasses?: Record<'paper', string>;
    timeSheetId?: string;
    expenseSheetId?: string;
    userId: string;
    entryTypeAllowed?: EntryType[];
    payPeriod: IPayPeriod;
    entryTypeFilter?: EntryType;
    statusName?: StatusNames;
    hasEntries: boolean;
}

export default function AddNewEntry({
    entryTypeAllowed = [EntryType.TIME, EntryType.EXPENSE],
    customClasses,
    timeSheetId,
    expenseSheetId,
    userId,
    payPeriod,
    entryTypeFilter,
    statusName,
    hasEntries,
    ...props
}: IAddControlsProps) {
    const dispatch = useDispatch();
    const classes = useAddControlsStyles();
    const [entryType, setEntryType] = useState(EntryType.TIME);
    const clientId = useSelector(selectCurrentClientId);
    const configuration = useSelector(selectFieldConfiguration(clientId || ''));

    useEffect(() => {
        if (entryTypeFilter) {
            setEntryType(entryTypeFilter);
        }
    }, [entryTypeFilter, setEntryType]);

    useEffect(() => {
        if (!hasEntries && entryTypeFilter) {
            dispatch(setWorkingEntryTypeFilter(null));
        }
    }, [hasEntries, entryTypeFilter, dispatch]);

    const onFocus = () => {
        const selectHtmlElements = document.querySelectorAll('form select, form input, form div[tabindex="0"]') as NodeListOf<HTMLElement>;
        const selectHtmlElementsArray = Array.from(selectHtmlElements);
        const visibleElement = selectHtmlElementsArray.find(element => {
            return Boolean(element.offsetParent);
        });
        if (visibleElement) {
            visibleElement.focus();
        }
    };
    const timeAllowed = entryTypeAllowed.includes(EntryType.TIME);
    const expenseAllowed = entryTypeAllowed.includes(EntryType.EXPENSE);
    let selectedType = entryType;
    if (!timeAllowed && entryType === EntryType.TIME && expenseAllowed) {
        selectedType = EntryType.EXPENSE;
    }
    if (!expenseAllowed && entryType === EntryType.EXPENSE && timeAllowed) {
        selectedType = EntryType.TIME;
    }

    const onChangeCommonValues = useCallback((values: ICommonEntryFormValues) => {
        dispatch(setCommonEntryFormValues(values));
    }, [dispatch]);

    return (
        <AddEntryControls customClasses={`${classes.paper} ${customClasses?.paper}`}>
            <Box className={classes.typeQuantityIconContainer} data-testid="entry-type-switchers-wrapper">
                {!entryTypeFilter && (
                    <>
                        <EntryTypeButton
                            onClick={() => setEntryType(EntryType.TIME)}
                            disabled={!timeAllowed}
                            active={selectedType === EntryType.TIME}
                            IconComponent={AccessTimeIcon}
                        />
                        <EntryTypeButton
                            onClick={() => setEntryType(EntryType.EXPENSE)}
                            disabled={!expenseAllowed}
                            active={selectedType === EntryType.EXPENSE}
                            IconComponent={AttachMoneyIcon}
                        />
                    </>
                )}
            </Box>
            {configuration && (
                <Box className={classes.controlsContainer}>
                    {selectedType === EntryType.TIME && (
                        <AddTimeEntry
                            {...props}
                            payPeriod={payPeriod}
                            sheetId={timeSheetId}
                            statusName={statusName}
                            inputs={configuration.inputs.time}
                            userId={userId}
                            disabled={!timeAllowed}
                            onChangeCommonValues={onChangeCommonValues}
                        />
                    )}
                    {selectedType === EntryType.EXPENSE && (
                        <AddExpenseEntry
                            {...props}
                            payPeriod={payPeriod}
                            sheetId={expenseSheetId}
                            statusName={statusName}
                            inputs={configuration.inputs.expense}
                            userId={userId}
                            disabled={!expenseAllowed}
                            onChangeCommonValues={onChangeCommonValues}
                        />
                    )}
                </Box>
            )}
            <div tabIndex={0} onFocus={onFocus}/>
        </AddEntryControls>
    );
}
