import { all, put, take } from 'typed-redux-saga';
import { uniq } from 'lodash-es';
import { getOwnConfiguration } from 'store/components/addEntry/addEntryActions';
import { IConfigurationByAssignments, ITask } from 'store/entities/configuration/configurationModel';
import {
    getActivities,
    getProjectsAssignments,
    getTasks, TProjectAssignmentAction,
} from 'store/entities/configuration/configurationAction';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { Permission } from 'store/components/auth/authModels';

export function* getOwnConfigurationBaseSaga() {
    yield put(getProjectsAssignments.init({ purpose: Permission.SubmitSheets }));

    const projAssignmentAction = yield* take<TProjectAssignmentAction>(
        [getProjectsAssignments.successType, getProjectsAssignments.errorType],
    );

    if (projAssignmentAction.type !== getProjectsAssignments.successType) {
        return;
    }

    const assignmentIds = (projAssignmentAction as ReturnType<typeof getProjectsAssignments.success>)
        .payload.map(({ assignment }) => assignment.id);
    const uniqAssignmentIds = uniq(assignmentIds) as string[];

    const params: IConfigurationByAssignments = {
        assignment_ids: uniqAssignmentIds.join(','),
    };

    const [,, tasksAction, activitiesAction]: [
        unknown, unknown,
        ReturnType<typeof getTasks.success> | ReturnType<typeof getTasks.error>,
        ReturnType<typeof getActivities.success> | ReturnType<typeof getActivities.error>,
    ] = (yield* all([
        put(getTasks.init(params)),
        put(getActivities.init(params)),
        take([getTasks.successType, getTasks.errorType]),
        take([getActivities.successType, getActivities.errorType]),
    ]) as any);

    if (tasksAction.type === getTasks.successType && activitiesAction.type === getActivities.successType) {
        yield put(getOwnConfiguration.success({
            assignmentIds: uniqAssignmentIds,
            taskIds: (tasksAction as ReturnType<typeof getTasks.success>).payload.map((task: ITask) => task.id),
            activities: (activitiesAction as ReturnType<typeof getActivities.success>).payload,
        }));
    } else {
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: tasksAction.type === getTasks.successType ? 'Failed to fetch project tasks' : 'Failed to fetch activities',
        }));
    }
}
