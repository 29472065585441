import React, { useCallback } from 'react';
import { ScopeForm } from 'modules/settings/submodules/clients/scopes/components/ScopeForm/ScopeForm';
import { createScope, setCreateScopeModalState } from 'modules/settings/submodules/clients/scopes/store/actions';
import { IScopeModifyRequest } from 'modules/settings/submodules/clients/scopes/store/models';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { selectIsOpenCreateScopeModal, selectScopeIsCreating } from 'modules/settings/submodules/clients/scopes/store/selector';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { ScopeType } from 'store/entities/scopes/models';

export default function CreateScopeModal() {
    const dispatch = useDispatch();
    const classes = useColoredFormModalStyles();
    const { type } = useParams<{ type: ScopeType }>();

    const isLoading = useSelector(selectScopeIsCreating);
    const onSave = useCallback((values: IScopeModifyRequest) => {
        dispatch(createScope.init(values));
    }, [dispatch]);
    const isOpen = useSelector(selectIsOpenCreateScopeModal);
    const onClose = useCallback(() => {
        dispatch(setCreateScopeModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Add New Scope"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <ScopeForm
                onSubmit={onSave}
                isLoading={isLoading}
                type={type}
            />
        </ModalDialog>
    );
}
