import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useHeaderFormStyles = makeHighPriorityStyles({
    form: {},
    input: {
        width: `168px`,
        background: colors.white,
        marginRight: `16px`,
    },
});
