import { useField } from 'formik';
import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { ScaZoneSeparatedCommon } from './ScaZoneSeparatedCommon';

interface IScaZoneProps extends IFormFieldProps {
    assignmentId?: string;
    parentScaZoneId: number;
}

export default function ScaZoneSeparated({
    label = '',
    assignmentId,
    parentScaZoneId,
    className = '',
    name,
}: IScaZoneProps) {
    const [field, meta, helper] = useField(name);

    const error = meta.touched ? meta.error : undefined;

    return (
        <ScaZoneSeparatedCommon
            value={field.value}
            customClasses={className}
            label={label}
            assignmentId={assignmentId}
            parentScaZoneId={parentScaZoneId}
            onChange={helper.setValue}
            error={error}
        />
    );
}
