import { IUserInfoClient } from 'shared/models/User';
import { ParsedQuery } from 'query-string';

export enum Permission {
    SubmitSheets = 'submit_sheets',
    ApproveSheets = 'approve_sheets',
    ApproveAllSheets = 'approve_all_sheets',
    AccessAllClients = 'access_all_clients',
    ViewSheetApprovalsOwn = 'view_sheet_approvals_own',
    ViewSheetApprovalsAll = 'view_sheet_approvals_all',
    CreateOffer = 'create_offer',
    ViewMyOffers = 'view_my_offers',
    toBeAssignedAsClientManager = 'to_be_assigned_as_client_manager',
    payrollProcessing = 'payroll_processing',
    createClient = 'create_client',
    modifyClientSettings = 'modify_client_settings',
    deleteClientSettingsEntities = 'delete_client_settings_entities',
    ManageEverything = 'manage_everything',
    ManageOfferLetterTemplates = 'manage_offer_letter_templates',
    DeleteOfferLetterTemplates = 'delete_offer_letter_templates',
    ManageManagerUsers = 'manage_manager_users',
    ManageEmployeeUsers = 'manage_employee_users',
    DeleteEmployeeUsers = 'delete_employee_users',
    ManagePaySettings = 'manage_pay_settings',
    Impersonate = 'impersonate',
    ImpersonateAsEmployee = 'impersonate_as_employee',
    ImpersonateAsManager = 'impersonate_as_manager',
    PPHReadAccess = 'pph_read_access',
    ManageCustomFields = 'manage_custom_fields',
    DeleteCustomFields = 'delete_custom_fields',
    ManageTravelExpenses = 'manage_travel_expenses',
    ManageSubassignments = 'manage_subassignments',
    ManageEaf = 'manage_eaf',
    ViewReports = 'view_reports',
    ApprovePayRate = 'approve_pay_rate',
    PayRateAutoApprove = 'pay_rate_auto_approve',
    SubAssignmentManagementScopeRequired = 'subassignment_management_scope_required',
    ViewCreditCardPortalLink = 'view_credit_cart_portal_link',
    ManageTerminationRequests = 'manage_termination_requests',
    CanAssignGlobalRole = 'can_assign_global_role',
    CanSendPphReminder = 'can_send_pph_reminder',
    CanUnlockPphSheet = 'can_unlock_pph_sheet',
    CanApprovePphSheet = 'can_approve_pph_sheet',
    CanEditPphSheet = 'can_edit_pph_sheet',
    ViewClientOffersAll = 'view_client_offers_all',
    ViewClientOffersOwn = 'view_client_offers_own',
    ManageClientOffersAll = 'manage_client_offers_all',
    ManageClientOffersOwn = 'manage_client_offers_own',
    ViewCcpUnreconciled = 'view_ccp_unreconciled',
    ViewCcpReconciled = 'view_ccp_reconciled',
    CanImportCcp = 'can_import_ccp',
    CanExportCcp = 'can_export_ccp',
    CanEditCcpUnreconciled = 'can_edit_ccp_unreconciled',
    CanEditCcpReconciled = 'can_edit_ccp_reconciled',
    CanViewCcpAll = 'view_ccp_all',
    CanWorkWithCcpInvoices = 'can_manage_ccp_invoiced',
    ViewCcpSubordinate = 'view_ccp_subordinate',
    EditSheetAsApprover = 'edit_sheets_as_approver',
    ManageEcub = 'manage_ecub',
}

export interface IImpersonateInfo {
    sub: string;
}

export interface IUserTokenInfo {
    sub: string;
    given_name: string;
    family_name: string;
    email: string;
    clients: Array<IUserInfoClient>;
    global_permissions?: Array<Permission>;
    act?: IImpersonateInfo;
}

export interface IUserSelfInfo extends Pick<IUserTokenInfo, 'email' | 'clients'> {
    id: string;
    firstName: string;
    lastName: string;
    impersonate?: IImpersonateInfo;
}

export interface IPasswordAuthRequest {
    username: string;
    password: string;
}

export interface IAuthSuccess {
    user: IUserTokenInfo;
}

export interface IRegistrationUserToken {
    first_name?: string;
    last_name?: string;
    email?: string;
}

export interface IClientStatuses{
    id: string;
    name: string;
    prism_status_code: string;
}

export interface IImpersonateResponse {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    not_before_policy: number;
    session_state: number;
    scope: string;
}

export interface IExpiringToken {
    exp: number;
}

export interface IRefreshToken extends IExpiringToken {}

export type SearchParams = ParsedQuery['key'];
