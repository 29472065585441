import React from 'react';
import { Button } from '@material-ui/core';
import { ISubmitBlockProps } from '../EntryEdit/models';
import { useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useAddEntrySaveStyles = makeHighPriorityStyles(() => ({
    submitButtonLabel: {
        textTransform: 'uppercase',
    },
}));

export default function AddEntrySave({ onSubmit }: ISubmitBlockProps) {
    const classes = useAddEntrySaveStyles();
    const { isUploading } = useSelector((state: IStore) => state.sheetEntryAttachments);

    return (
        <Button color="primary" variant="contained"
            fullWidth
            onClick={onSubmit}
            disabled={isUploading}
            classes={{ label: classes.submitButtonLabel }}
        >
            + Add new entry
        </Button>
    );
}
