import { defaultInfinityScrollState, IInfinityScrollState } from 'store/reducerUtils';

import {
    getMoreTravelExpensesAction,
    TravelExpenseActions,
} from 'modules/travelExpenses/components/TravelExpensesTable/actions';
import { setTravelExpenseFilter } from 'modules/travelExpenses/components/TravelExpenseFilter/action';
import { IExpenseEntrySearchResponse } from 'store/entities/timesheet/api/expenseApi';
import { deleteTravelExpense } from 'modules/travelExpenses/actions';

export function travelExpenseTable(
    state: IInfinityScrollState<string>,
    action: TravelExpenseActions,
): IInfinityScrollState<string> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setTravelExpenseFilter.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreTravelExpensesAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case deleteTravelExpense.successType:
            return {
                ...state,
                total: (state?.total || 1) - 1,
                items: state?.items.filter(item => item !== action.payload) || [],
            };
        case getMoreTravelExpensesAction.successType:{
            const payload = action.payload as IExpenseEntrySearchResponse;
            const newItems = [
                ...state.items.filter(itemId => !payload.items.map(entry => entry.id).includes(itemId)),
                ...payload.items.map(entry => entry.id),
            ];
            const maxTotal = payload.total_items > newItems.length //used for update and create
                ? payload.total_items
                : newItems.length;
            return {
                total: maxTotal,
                items: newItems,
                isLoading: false,
                cursor: payload.next_cursor,
            };
        }
        default:
            return state;
    }
}
