export interface IEmployeeManagementFilter {
    employee_user_id?: string | null;
    approver_user_id?: string | null;
    start_date: string;
    end_date: string;
    custom_fields: Record<string, string[]>;
}

export const employeeManagementFilterDefaultValue = {
    employee_user_id: null,
    approver_user_id: null,
    start_date: '',
    end_date: '',
    custom_fields: {},
};
