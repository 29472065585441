import { combineReducers } from 'redux';
import { ClientAction } from 'store/entities/clients/clientsAction';
import {
    AuthAction,
    authByPassword,
    authByToken,
    authTokenUpdate,
    cacheAuthEmail,
    keycloakLogout,
    logout,
    setClientUserPermission,
} from './authActions';
import { IUserSelfInfo, Permission } from 'store/components/auth/authModels';
import { getUserAvatar, ProfileActions } from 'modules/profile/store/profileActions';
import { defaultUserAvatar, IAvatar } from 'modules/profile/store/profileModel';

type SelfUserInfo = IUserSelfInfo | null;

export const initialState = {
    isAuthenticated: false,
    user: null,
    error: null,
    isLoading: false,
    globalPermissions: [],
    permissions: [],
    cacheEmailOnAuth: '',
};

function isLoading(state: boolean = initialState.isLoading, action: AuthAction): boolean {
    switch (action.type) {
        case authByPassword.initType:
        case authByToken.initType:
        case keycloakLogout.action:
            return true;
        case authByToken.successType:
        case authByPassword.successType:
        case authByToken.errorType:
        case authByPassword.errorType:
        case logout.action:
            return false;
        default:
            return state;
    }
}

function isAuthenticated(state: boolean = initialState.isAuthenticated, action: AuthAction): boolean {
    switch (action.type) {
        case authByToken.successType:
        case authByPassword.successType:
        case authTokenUpdate.successType:
            return true;
        case authByToken.errorType:
        case authByPassword.errorType:
        case authTokenUpdate.errorType:
        case logout.action:
            return false;
        default:
            return state;
    }
}

function user(state: SelfUserInfo = initialState.user, action: AuthAction | ClientAction): SelfUserInfo {
    switch (action.type) {
        case authByPassword.successType:
        case authTokenUpdate.successType:
        case authByToken.successType: {
            const { user: selfUser } = action.payload;
            return {
                id: selfUser.sub,
                email: selfUser.email,
                clients: selfUser.clients,
                firstName: selfUser.given_name,
                lastName: selfUser.family_name,
                impersonate: selfUser.act,
            };
        }
        default:
            return state;
    }
}

function globalPermissions(
    state: Permission[] = initialState.globalPermissions,
    action: AuthAction | ClientAction,
): Permission[] {
    switch (action.type) {
        case authByPassword.successType:
        case authTokenUpdate.successType:
        case authByToken.successType: {
            const { user: selfUser } = action.payload;
            return selfUser.global_permissions || [];
        }
        default:
            return state;
    }
}

function permissions(state: any = initialState.permissions, action: AuthAction): Permission[] {
    switch (action.type) {
        case authByPassword.successType:
        case authByToken.successType: {
            const { user: selfUser } = action.payload;
            return selfUser.global_permissions || [];
        }
        case setClientUserPermission.action:
            return action.payload;
        default:
            return state;
    }
}
export function userAvatar(
    state: IAvatar = defaultUserAvatar,
    action: AuthAction | ProfileActions,
): IAvatar {
    switch (action.type) {
        case getUserAvatar.successType:
            return action.payload;
        default:
            return state;
    }
}

function error(state: any = initialState.error, action: AuthAction): any {
    switch (action.type) {
        case authByPassword.initType:
            return initialState.error;
        case authByPassword.errorType:
        case authByToken.errorType:
            return action.payload;
        default:
            return state;
    }
}

function cacheEmailOnAuth(state = initialState.cacheEmailOnAuth, action: AuthAction): string {
    if (action.type === cacheAuthEmail.action) {
        return action.payload;
    }
    return state;
}

export const auth = combineReducers({
    isAuthenticated,
    isLoading,
    error,
    user,
    permissions,
    globalPermissions,
    cacheEmailOnAuth,
});
