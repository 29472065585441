import { GetAppOutlined } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupedSheetPdf } from '../../store/actions';
import { IDownloadSheetProps, IGroupedSheetCalculation } from '../../store/model';
import { selectPayrollProcessorHubState } from '../../store/selectors';
import { downloadSheetPdfLoadingModule } from '../../store/loadingModules';
import { generateGroupRowId } from '../../store/helpers';
import { IStore } from 'store/configureStore';

export const DownloadSheetButton = ({ timeSheetId, expenseSheetId, fileName }: IDownloadSheetProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(downloadSheetPdfLoadingModule.getSelector(
        generateGroupRowId(
            { time_sheet_id: timeSheetId, expense_sheet_id: expenseSheetId } as IGroupedSheetCalculation,
        ),
        (state: IStore) => selectPayrollProcessorHubState(state),
    ));

    const onClick = useCallback(() => {
        dispatch(getGroupedSheetPdf.init({
            timeSheetId,
            expenseSheetId,
            fileName,
        }));
    }, [dispatch, timeSheetId, expenseSheetId, fileName]);

    return (
        <IconButton onClick={onClick} disabled={isLoading}>
            {isLoading ? <CircularProgress color="inherit" size={16}/> : <GetAppOutlined/>}
        </IconButton>
    );
};
