import React from 'react';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { useAvailableCustomFieldsStyles } from './styles';
import { ItemData } from 'react-sortly';
import { DraggableItemRender } from '../DraggableItemRender';
import { ICustomFieldHierarchyRow } from '../../../customFields/store/models';

interface IAvailableCustomFieldListItemProps extends ItemData<ICustomFieldHierarchyRow> {
    disabled?: boolean;
}

export const AvailableCustomFieldListItem = ({ customField, disabled } : IAvailableCustomFieldListItemProps) => {
    const classes = useAvailableCustomFieldsStyles();
    return (
        <DraggableItemRender
            classes={{ root: classes.customField, inner: classes.customFieldInner }}
            depth={0}
            disabled={disabled}
        >
            <OpenWithIcon className={classes.customFieldIcon} fontSize="small"/>
            <span className={classes.customFieldText}>
                {customField?.name}
            </span>
        </DraggableItemRender>
    );
};
