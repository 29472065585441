import {
    resolvePtoMaxUseAvailable,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/helpers/detailItemValueResolvers';
import React, { useMemo } from 'react';
import { uniqBy } from 'lodash-es';
import Total, { TotalTypes } from 'shared/components/toolbar/total/Total';
import { Box, Hidden } from '@material-ui/core';
import { useTotalInfoStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/components/TotalInfo/TotalInfoStyles';
import DottedDivider from 'shared/components/divider/DottedDivider';
import { useCurrentClientMileageRate } from 'shared/models/Miles';
import { IPaidTimeOffResponse } from 'shared/models/User';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { formatHours, formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import TotalInfoTab
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/totalInfoTab/TotalInfoTab';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import {
    totalBreakTime,
    totalFiles,
    totalHolidays,
    totalOverTimeUnits,
    totalTime,
} from 'shared/utils/counters/timeCounter';
import { useSelector } from 'react-redux';
import { ActivityDictionary } from 'shared/models/Activity';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { useModifiedTotalConfigurationBySlug } from 'store/entities/clients/hooks';
import { selectIsClientHasMealBreaks } from 'store/entities/clients/selectors/configurationSelectors';
import { ITotalConfigurationByTotalSlug } from 'store/entities/clients/selectors/fieldSelectors';
import { selectActivitiesById } from 'store/entities/configuration/configurationSelectors';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { ItemsById } from 'shared/models/ItemsById';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { selectCalculationsByTimesheetId } from 'store/entities/timesheet/selectors';
import { AvailableTotalConfiguration, TotalSlug } from 'store/entities/clients/clientsModel';
import {
    selectCurrentClientId,

} from 'store/entities/clients/selectors/clientsSelectors';
import { formatDecimalHoursAsHoursAndMinutes } from 'shared/models/DateTime';
import { useIsMobile } from 'shared/utils/hooks/media';
import { ITotalInfoItem } from './model';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { useUserPto } from 'store/entities/users/hooks';

interface ITotalInfoProps {
    entryFilter?: EntryType;
    entries: IEntry[];
    pto?: IPaidTimeOffResponse;
}

interface ITotalInfoStoreProps {
    activitiesById?: ItemsById<IActivity>;
    fieldsBySlug?: ITotalConfigurationByTotalSlug;
    calculationByTimeSheet?: Record<string, ITimesheetCalculation>
    hasMealBreaks?: boolean,
}

export function TotalInfoPure({
    pto,
    entryFilter,
    entries,
    fieldsBySlug,
    activitiesById = {},
    calculationByTimeSheet = {},
    hasMealBreaks = false,
}: ITotalInfoProps & ITotalInfoStoreProps) {
    const classes = useTotalInfoStyles();

    const hasTime = Boolean(fieldsBySlug?.[TotalSlug.Time]);
    const hasOverTime = Boolean(fieldsBySlug?.[TotalSlug.OverTime]);
    const hasDoubleTime = Boolean(fieldsBySlug?.[TotalSlug.DoubleTime]);
    const hasBreaks = Boolean(fieldsBySlug?.[TotalSlug.Break]);
    const hasHolidays = Boolean(fieldsBySlug?.[TotalSlug.Holidays]);
    const hasPto = Boolean(fieldsBySlug?.[TotalSlug.Pto]);
    const hasPsl = Boolean(fieldsBySlug?.[TotalSlug.Psl]);
    const hasExpense = Boolean(fieldsBySlug?.[TotalSlug.Expense]);
    const hasCompletes = Boolean(fieldsBySlug?.[TotalSlug.Completes]);
    const hasTrailingDoc = Boolean(fieldsBySlug?.[TotalSlug.TrailingDoc]);
    const hasNewLoan = Boolean(fieldsBySlug?.[TotalSlug.NewLoan]);
    const hasFiles = Boolean(fieldsBySlug?.[TotalSlug.Files]);

    const completesAmount = useMemo(() => {
        return hasCompletes
            ? (
                entries.filter(
                    entry => activityHasCompletes(activitiesById[entry.activity_id ?? '']),
                ).reduce(
                    (acc: number, entry) => acc + completesByNotes(entry.notes || ''), 0,
                )
            )
            : 0;
    }, [hasCompletes, activitiesById, entries]);

    const trailingDocAmount = useMemo(() => (
        hasTrailingDoc
            ? entries.filter(entry => (
                activitiesById[entry.activity_id ?? '']?.description === ActivityDictionary.TrailingDoc
            )).length
            : 0
    ), [activitiesById, entries, hasTrailingDoc]);
    const newLoanAmount = useMemo(() => (
        hasNewLoan
            ? entries.filter(entry => (
                activitiesById[entry.activity_id ?? '']?.description === ActivityDictionary.NewLoan
            )).length
            : 0
    ), [activitiesById, entries, hasNewLoan]);

    const hasTimeEntries = useMemo(() => {
        return !!entries.find(entry => entry.entry_type === EntryType.TIME);
    }, [entries]);

    const formattedTime = formatMinutes(totalTime(entries));
    const mileageRate = useCurrentClientMileageRate();
    const formattedAmount = totalDollars(entries, mileageRate);
    const timeBreak = totalBreakTime(entries);
    const holidaysTime = totalHolidays(entries, calculationByTimeSheet);
    const filesAmount = totalFiles(entries);
    const sheets = uniqBy(entries, entry => entry.sheet_id).map(entry => calculationByTimeSheet[entry.sheet_id]);
    const {
        ot: overTimeHours,
        dt: doubleTimeHours,
        rt: regularTimeHours,
        pto: ptoHours,
        psl: pslHours,
    } = totalOverTimeUnits(sheets);
    const isMobile = useIsMobile();

    const prefixItemsGroup = useMemo((): ITotalInfoItem[] => {
        return [
            {
                key: 'trailingDoc',
                type: TotalTypes.Count,
                value: trailingDocAmount.toString(),
                dataTestId: 'total-trailing-doc',
                label: fieldsBySlug?.[TotalSlug.TrailingDoc]?.unitLabel,
                hide: !hasTrailingDoc || isMobile,
            },
            {
                key: 'newLoan',
                type: TotalTypes.Count,
                value: newLoanAmount.toString(),
                dataTestId: 'total-new-loan',
                label: fieldsBySlug?.[TotalSlug.NewLoan]?.unitLabel,
                hide: !hasNewLoan || isMobile,
            },
        ].filter(item => !item.hide);
    }, [hasNewLoan, newLoanAmount, hasTrailingDoc, trailingDocAmount, fieldsBySlug, isMobile]);
    const groupedTotalItems = useMemo((): ITotalInfoItem[][] => {
        const showOnlyExpense = entryFilter === EntryType.EXPENSE;
        const showOnlyTime = entryFilter === EntryType.TIME;
        const hideTimeTotal = showOnlyExpense || !(hasTimeEntries || !hasFiles);
        const filesEntries = hasFiles && filesAmount > 0;

        const breakLabel = hasMealBreaks ? 'total meal breaks' : fieldsBySlug?.[TotalSlug.Break]?.unitLabel;

        const groupedItems = [
            [
                {
                    key: 'files',
                    type: TotalTypes.Break,
                    value: filesAmount.toString(),
                    dataTestId: 'total-files',
                    label: fieldsBySlug?.[TotalSlug.Files]?.unitLabel,
                    hide: hideTimeTotal || !filesEntries,
                },
            ],
            [
                {
                    key: 'completes',
                    type: TotalTypes.Break,
                    value: completesAmount.toString(),
                    dataTestId: 'total-completes',
                    label: fieldsBySlug?.[TotalSlug.Completes]?.unitLabel,
                    hide: hideTimeTotal || filesEntries || !hasCompletes || !completesAmount,
                },
            ],
            [
                {
                    key: 'pto',
                    type: TotalTypes.Time,
                    value: resolvePtoMaxUseAvailable(pto),
                    label: 'Available PTO hours',
                    hide: !pto,
                },
                {
                    key: 'time',
                    type: TotalTypes.Time,
                    value: hasOverTime ? formatHours(regularTimeHours) : formattedTime,
                    dataTestId: 'total-time',
                    label: fieldsBySlug?.[TotalSlug.Time]?.unitLabel,
                    hide: hideTimeTotal || filesEntries || !hasTime,
                },
                {
                    key: 'overTime',
                    type: TotalTypes.Time,
                    value: formatHours(overTimeHours),
                    dataTestId: 'total-overtime',
                    label: fieldsBySlug?.[TotalSlug.OverTime]?.unitLabel,
                    hide: hideTimeTotal || filesEntries || !hasOverTime,
                },
                {
                    key: 'doubleTime',
                    type: TotalTypes.Time,
                    value: formatHours(doubleTimeHours),
                    dataTestId: 'total-doubletime',
                    label: fieldsBySlug?.[TotalSlug.DoubleTime]?.unitLabel,
                    hide: hideTimeTotal || filesEntries || !hasDoubleTime || isMobile,
                },
            ],
            [
                {
                    key: 'break',
                    type: TotalTypes.Break,
                    value: formatMinutes(timeBreak),
                    dataTestId: 'total-break',
                    label: breakLabel,
                    hide: hideTimeTotal || filesEntries || !(hasBreaks || timeBreak),
                },
                {
                    key: 'pto',
                    type: TotalTypes.Break,
                    value: formatHours(ptoHours),
                    dataTestId: 'total-pto',
                    label: fieldsBySlug?.[TotalSlug.Pto]?.unitLabel,
                    hide: hideTimeTotal || filesEntries || !hasPto || !(hasBreaks || holidaysTime || timeBreak)
                        || isMobile,
                },
                {
                    key: 'psl',
                    type: TotalTypes.Break,
                    value: formatHours(pslHours),
                    dataTestId: 'total-psl',
                    label: fieldsBySlug?.[TotalSlug.Psl]?.unitLabel,
                    hide: hideTimeTotal || filesEntries || !hasPsl || !pslHours
                        || !(hasBreaks || holidaysTime || timeBreak) || isMobile,
                },
                {
                    key: 'holidays',
                    type: TotalTypes.Break,
                    value: formatDecimalHoursAsHoursAndMinutes(holidaysTime),
                    dataTestId: 'total-holidays',
                    label: fieldsBySlug?.[TotalSlug.Holidays]?.unitLabel,
                    hide: hideTimeTotal || filesEntries || !hasHolidays || !(hasBreaks || holidaysTime || timeBreak)
                        || isMobile,
                },
            ],
            [
                {
                    key: 'expense',
                    type: TotalTypes.Expense,
                    value: formattedAmount,
                    dataTestId: 'total-expense',
                    label: fieldsBySlug?.[TotalSlug.Expense]?.unitLabel,
                    hide: showOnlyTime || !hasExpense,
                },
            ],
        ];
        // @ts-ignore
        return groupedItems.map(group => group.filter(item => !item.hide)).filter(group => group.length);
    }, [
        hasTimeEntries,
        fieldsBySlug,
        entryFilter,
        completesAmount,
        formattedAmount,
        formattedTime,
        timeBreak,
        hasOverTime,
        hasDoubleTime,
        holidaysTime,
        hasBreaks,
        hasCompletes,
        doubleTimeHours,
        overTimeHours,
        hasExpense,
        hasHolidays,
        hasTime,
        hasFiles,
        filesAmount,
        regularTimeHours,
        isMobile,
        pto,
        hasPto,
        hasPsl,
        ptoHours,
        pslHours,
        hasMealBreaks,
    ]);

    const renderGroup = (group: ITotalInfoItem[]) => (
        <>
            {group.map(totalItem => (
                <Total
                    key={totalItem.key}
                    classes={{ root: classes.childComponent }}
                    {...totalItem}
                />
            ))}
        </>
    );

    return (
        <>
            <Hidden xsDown>
                <Box className={classes.root}>
                    <Box className={classes.sides}>
                        {renderGroup(prefixItemsGroup)}
                    </Box>
                    <Box className={classes.sides}>
                        {groupedTotalItems.map((group, index) => (
                            <React.Fragment key={index}>
                                {renderGroup(group)}
                                {index !== groupedTotalItems.length - 1 && (
                                    <DottedDivider
                                        height={32}
                                        customClasses={classes.childComponent}
                                        data-testid="total-divider"
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>
            </Hidden>
            <Hidden smUp>
                <Box className={classes.root}>
                    <TotalInfoTab
                        groupedItems={groupedTotalItems}
                    />
                </Box>
            </Hidden>
        </>
    );
}

export const TotalInfo = separateLogicDecorator<
ITotalInfoProps,
ITotalInfoStoreProps
>(() => {
    const totalFields = useModifiedTotalConfigurationBySlug(AvailableTotalConfiguration.SheetsTotal);
    const hasMealBreaks = useSelector(selectIsClientHasMealBreaks);
    const user = useSelector(selectCurrentUser);
    const clientId = useSelector(selectCurrentClientId);
    const pto = useUserPto(user?.id, clientId);

    return {
        activitiesById: useSelector(selectActivitiesById),
        fieldsBySlug: totalFields,
        calculationByTimeSheet: useSelector(selectCalculationsByTimesheetId),
        pto,
        hasMealBreaks,
    };
})(TotalInfoPure);
