import React from 'react';

import { useSelector } from 'react-redux';

import { IWorkingConditionsTemplate } from 'store/entities/configuration/configurationModel';
import {
    selectIsWorkingConditionsLoading,
    selectWorkingConditions,
} from 'store/entities/configuration/configurationSelectors';

import {
    TemplatesTable,
    TemplateTableEntityName,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/TemplatesTable';
import {
    removeWorkingConditionsTemplate,
} from 'store/entities/configuration/configurationAction';

import { setEditWorkingConditionsTemplateId } from 'modules/settings/submodules/clients/workingConditions/store/actions';
import EditWorkingConditionsModal
    from 'modules/settings/submodules/clients/workingConditions/EditWorkingConditions/EditWorkingConditionsModal';

export const WorkingConditionsTable = () => {

    const items = useSelector(selectWorkingConditions) as IWorkingConditionsTemplate[];
    const isLoading = useSelector(selectIsWorkingConditionsLoading);

    return (
        <>
            <TemplatesTable
                templates={items}
                isLoading={isLoading}
                entityName={TemplateTableEntityName.WorkingConditions}
                deleteAction={removeWorkingConditionsTemplate.init}
                editAction={setEditWorkingConditionsTemplateId}
            />
            <EditWorkingConditionsModal/>
        </>
    );
};
