import React from 'react';
import OfferLetterPanel, { IOfferLetterPanelRoute } from './OfferLetterPanel';
import { OfferLetterCardContent } from './components/OfferCard/OfferLetterCardContent';

const OfferLetterCard: React.FC<IOfferLetterPanelRoute> = ({
    match: { params: { id } },
}: IOfferLetterPanelRoute) => {
    return (
        <OfferLetterPanel
            id={id}
            getTitle={name => name}
            renderSidebar={({ offerLetter, clientId }) => (
                <OfferLetterCardContent
                    offerLetter={offerLetter}
                    clientId={clientId}
                />
            )}
        />
    );
};

export default OfferLetterCard;
