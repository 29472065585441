import { fade, Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors, desktopContentSpace } from 'shared/styles/constants';

const headerMobileHeight = 9;

const useSheetsInProgressStyles = makeHighPriorityStyles((theme: Theme) => ({
    mainContainer: {
        display: 'flex',
        flexFlow: 'column',

        [theme.breakpoints.up('md')]: {
            height: `calc(100vh - ${theme.spacing(8)}px)`,
        },
        [theme.breakpoints.only('sm')]: {
            height: '150vh',
        },
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: theme.spacing(0, 4, 0, 5),
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    tableMain: {
        position: 'relative',
        flex: 1,
    },
    tableContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        overflowY: 'auto',

        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${theme.spacing(desktopContentSpace * 2)}px)`,
            margin: theme.spacing(0, desktopContentSpace, 0, 5),
        },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },

        '&::-webkit-scrollbar': {
            width: theme.spacing(1),
        },
        '&::-webkit-scrollbar-track': {
            display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
            minHeight: theme.spacing(8),
            borderRadius: theme.spacing(0.8),
            backgroundClip: 'padding-box',
            border: `1px solid ${fade(colors.white, 0.5)}`,
            backgroundColor: fade(colors.black, 0.5),
        },
    },
    tableContainerLoading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflowY: 'hidden',

        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${theme.spacing(desktopContentSpace * 2)}px)`,
            margin: theme.spacing(0, desktopContentSpace, 0, 5),
        },
    },
    tableContainerIsEmpty: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyText: {
        fontWeight: 'normal',
        fontSize: 20,
        color: colors.darkGray,

        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0, 2),
            textAlign: 'center',
        },
    },
    actionBottomBar: {
        display: 'flex',
        margin: theme.spacing(2, desktopContentSpace, 2),
        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(3, 2, headerMobileHeight + 4),
        },
    },
    modifyActions: {
        display: 'flex',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            justifyContent: 'space-between',
        },
    },
    actionButton: {
        fontSize: 14,
    },
    secondaryButton: {
        marginLeft: theme.spacing(2),
        fontWeight: theme.typography.fontWeightLight,

        '&, &:hover': {
            textDecoration: 'underline',
        },
    },
    newAddedEntry: {
        '& > *': {
            backgroundColor: colors.lightGreen,
        },
    },
    addControlsPaper: {
        marginBottom: theme.spacing(2),

    },
    headerTables: {
        margin: theme.spacing(0, 0, 2, 0),
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, desktopContentSpace),
        },

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0, 2, 2),
        },
    },
    filters: {
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2),
        },
    },
    hidden: {
        display: 'none',
    },
    recalledSubmit: {
        backgroundColor: `${fade(colors.orange, 0.3)} !important`,
        padding: theme.spacing(0, 2),
        color: theme.palette.text.primary,
    },
    backButtonWrapper: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2),
        },

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginRight: theme.spacing(8),
            minHeight: theme.spacing(10),
        },

        '& > button': {
            backgroundColor: colors.white,
            [theme.breakpoints.down('xs')]: {
                position: 'absolute',
                zIndex: 1100,
                top: 0,
                left: 0,
                backgroundColor: theme.palette.primary.main,
                color: colors.white,
                padding: '8px 0 8px 10px;',
                minWidth: '10px',
                '& svg': {
                    fontSize: '40px !important',
                },
            },
        },

    },
    durationText: {
        fontWeight: 500,
        marginLeft: theme.spacing(2),
    },
    statusWrapper: {
        // width: 'unset',

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 2),
        },
    },
    headerContentWrapper: {
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'flex-end',
        margin: theme.spacing(0, 2),

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            margin: 0,
            alignItems: 'flex-start',
        },
    },
    aboveTable: {
        display: 'flex',
        justifyContent: 'flex-end',
        minHeight: theme.spacing(4),

        [theme.breakpoints.down('sm')]: {
            minHeight: theme.spacing(2),
        },
    },
}));

export default useSheetsInProgressStyles;
