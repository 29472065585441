import { selectAssignmentsById, selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import { getUsers } from 'store/entities/users/actions';
import { selectUsersById } from 'store/entities/users/selectors';
import { optionalLoadEntitiesByIdsSaga } from 'store/utils/sagas/optionalLoadEntitiesByIdsSaga';
import { put, select, take } from 'typed-redux-saga';
import { ISheet } from 'shared/models/sheet/Sheet';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { ISheetListParams } from 'store/entities/timesheet/models/SheetApi';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { Permission } from 'store/components/auth/authModels';
import { selectExpenseStatusIdByName, selectTimeSheetStatusByName } from 'store/entities/timesheet/selectors';
import {
    getAssignments,
    getSubassignments,
} from 'store/entities/configuration/configurationAction';

type LoadSheetsActions = (
    typeof loadTimeSheetsWithEntries |
    typeof loadExpenseSheetsWithEntries
);

type StatusByNameSelector = (
    typeof selectTimeSheetStatusByName |
    typeof selectExpenseStatusIdByName
);

export function* getReviewerSheetSaga(
    loadSheetsActions: LoadSheetsActions,
    statusSelector: StatusByNameSelector,
    statusName: StatusNames,
    additionalRequestData: Partial<ISheetListParams> = {},
) {
    const status = yield select(statusSelector(statusName));

    yield put(loadSheetsActions.init({
        purpose: Permission.ApproveSheets,
        request: {
            ...additionalRequestData,
            status_id: status?.id || undefined,
        },
    }));

    const result = yield take(loadSheetsActions.finallyTypes);

    if (result.type === loadSheetsActions.successType) {
        yield* loadSheetRelatedData(result.payload);
    }
}

export function* loadSheetRelatedData(sheets: ISheet[]){
    const userIds = sheets.map(sheet => sheet.user_id).filter(Boolean);
    yield* optionalLoadEntitiesByIdsSaga(userIds, selectUsersById, getUsers, ids => ({ ids: ids.join(',') }));

    const assignmentIds = sheets.map(sheet => sheet.assignment_id).filter(Boolean) as string[];
    yield* optionalLoadEntitiesByIdsSaga(assignmentIds, selectAssignmentsById, getAssignments);

    const subassignmentIds = sheets.map(sheet => sheet.subassignment_id).filter(Boolean) as string[];
    yield* optionalLoadEntitiesByIdsSaga(subassignmentIds, selectSubassignmentsByIds, getSubassignments);
}
