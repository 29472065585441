import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const ControllingOrgValidationSchema = yup.object().shape({
    name: yup.string().trim().required(ValidationMessages.REQUIRED),
    b_number: yup.string().trim().required(ValidationMessages.REQUIRED),
    address: yup.string().trim().required(ValidationMessages.REQUIRED),
    city: yup.string().trim().required(ValidationMessages.REQUIRED),
    state: yup.string().trim().required(ValidationMessages.REQUIRED),
    zip_code: yup.string().trim().required(ValidationMessages.REQUIRED),
});
