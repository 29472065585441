import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateStatus } from 'modules/settings/submodules/platformUsers/components/UpdateStatus/UpdateStatus';
import { updateOfferLetterTemplate } from 'modules/settings/submodules/offerLetters/store/action';
import {
    selectDeletingOfferLetterTemplateIds,

    selectUpdatingOfferLetterTemplateIds,
} from 'modules/settings/submodules/offerLetters/store/selectors';
import { Status } from 'shared/models/Status';

interface IUpdateOfferLetterTemplateStatusStatus {
    id: string;
    status: Status;
    className?: string;
}

export const UpdateOfferLetterTemplateStatus = ({ id, status, className }: IUpdateOfferLetterTemplateStatusStatus) => {
    const dispatch = useDispatch();
    const updatingOfferLetterTemplateIds = useSelector(selectUpdatingOfferLetterTemplateIds);
    const deletingOfferLetterTemplateIds = useSelector(selectDeletingOfferLetterTemplateIds);
    const isLoading = (
        updatingOfferLetterTemplateIds.includes(id)
        || deletingOfferLetterTemplateIds.includes(id)
    );
    const handleStatusChange = useCallback((newValue: Status) => {
        dispatch(updateOfferLetterTemplate.init({
            id,
            data: {
                status: newValue,
            },
        }));
    }, [dispatch, id]);
    return (
        <UpdateStatus
            status={status}
            handleStatusChange={handleStatusChange}
            isLoading={isLoading}
            formControlClassName={className}
        />
    );
};
