import { dollarsFormatter, noRoundingDollarsFormatter, moneyToFormattedString } from 'shared/models/Money';

export const formatDollars = (dollars?: string | number | null, rounding = true): string => {
    const value = parseFloat((dollars && dollars.toString()) || '0');
    const formatter = rounding ? dollarsFormatter : noRoundingDollarsFormatter;
    return value ? formatter.format(value) : '$0';
};

export const dollarFormatter = (dollars: number, cents: number) => {
    return moneyToFormattedString(dollars + cents * 0.01);
};
