import React from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import {
    selectDealTypeArBuckets,
    selectDealTypeArBucketsAreLoading,
} from 'store/entities/configuration/configurationSelectors';

interface IArBucketSelectProps extends IFormFieldProps, IFormSelect {
}

export function ArBucketSelect(props: IArBucketSelectProps) {
    const dealTypeArBuckets = useSelector(selectDealTypeArBuckets);
    const dealTypeArBucketsAreLoading = useSelector(selectDealTypeArBucketsAreLoading);

    return (
        <FormSelect
            {...props}
            getKey={arBucket => arBucket}
            getText={arBucket => arBucket}
            options={dealTypeArBuckets}
            disabled={dealTypeArBucketsAreLoading || !dealTypeArBuckets.length}
            isLoading={dealTypeArBucketsAreLoading}
        />
    );
}
