import { profileApi } from './profileApi';
import { ActionsReturnTypes } from 'store/utils';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeEvery } from 'typed-redux-saga';
import { getUserProfile, addUserAvatar, getUserAvatar } from './profileActions';
import { IAvatarUpload } from './profileModel';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';

function* getUserProfileSaga(action: ActionsReturnTypes<typeof getUserProfile>) {
    const result = yield* call(profileApi.getUserById, action.payload);
    yield put(getUserProfile.success(result));
}

export function* getUserProfileWatcher() {
    yield takeEvery(getUserProfile.initType, withBackendErrorHandler(getUserProfileSaga, getUserProfile.error, 'User profile not found'));
}

function* getUserAvatarSaga() {
    try {
        const result = yield* call(profileApi.getUserAvatar);
        yield put(getUserAvatar.success(result));
    } catch (e){
        yield put(getUserAvatar.error(e));
    }
}

export function* getUserAvatarWatcher() {
    yield takeEvery(getUserAvatar.initType, getUserAvatarSaga);
}
function* addNewAvatarSaga(action: ReturnType<typeof addUserAvatar.init>) {
    const payload = action.payload;
    const avatarToUpload: IAvatarUpload = {
        entry_id: payload.entry_id,
        body: payload.body,
        filename: payload.filename,
        mimetype: payload.mimetype,
    };
    try {
        const newAvatar = yield* call(profileApi.createUserAvatar, avatarToUpload);
        yield* put(addUserAvatar.success(newAvatar));
    } catch (e) {
        if (e?.response?.status === 400) {
            yield* put(setGlobalToast({ severity: IModalSeverity.Error, title: 'Avatar not uploaded' }));
            return;
        } else {
            throw e;
        }
    }
}

export function* addNewAvatarWatcher(){
    yield takeEvery(addUserAvatar.initType, withBackendErrorHandler(addNewAvatarSaga, addUserAvatar.error, 'User Avatar not added'));
}

export default [
    getUserProfileWatcher,
    getUserAvatarWatcher,
    addNewAvatarWatcher,
];
