import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

interface IOpenModalButtonProps {
    openAction: (payload: boolean) => AnyAction;
    title?: string;
}

export const OpenModalButton = ({ openAction, title = '+ Create' }: IOpenModalButtonProps) => {
    const dispatch = useDispatch();
    const openModal = useCallback(() => {
        dispatch(openAction(true));
    }, [dispatch, openAction]);

    return (
        <Button
            onClick={openModal}
            title={title}
            color="primary"
            variant="contained"
        >
            {title}
        </Button>
    );
};
