import { useCallback, useState } from 'react';

export function useOpenState(initialState = false) {
    const [isOpened, setIsOpened] = useState<boolean>(initialState);

    const toggleOpened = useCallback(() => {
        setIsOpened(opened => !opened);
    }, []);

    const open = useCallback(() => {
        setIsOpened(true);
    }, []);

    const close = useCallback(() => {
        setIsOpened(false);
    }, []);

    return {
        isOpened,
        open,
        close,
        toggleOpened,
    };
}
