import { ISheetsGridFilters } from 'store/components/workingSheets/workingSheetsModel';
import {
    setSheetsGridFilters,
} from 'store/components/workingSheets/workingSheetsActions';
import { combineReducers } from 'redux';

export const initialState = {
    filters: {
        area_id: '',
        assignment_id: '',
        activity_id: '',
        job_number_id: '',
        task_id: '',
        location_id: '',
        position_id: '',
        customFieldValues: {},
    },
    isWeekLoaded: {},
};

function filters(
    state: ISheetsGridFilters = initialState.filters, action: ReturnType<typeof setSheetsGridFilters>,
): ISheetsGridFilters {
    switch (action.type) {
        case setSheetsGridFilters.action: {
            const newFilters = {
                ...state,
                ...action.payload,
            };

            if (newFilters.area_id === '') {
                newFilters.assignment_id = '';
            }

            if (newFilters.assignment_id === '') {
                newFilters.task_id = '';
            }

            if (newFilters.task_id === '' && newFilters.job_number_id === '') {
                newFilters.activity_id = '';
            }

            return newFilters;
        }
        default:
            return state;
    }
}

export const sheetsGrid = combineReducers({
    filters,
});
