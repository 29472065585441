import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { Box, ListItem } from '@material-ui/core';
import { ISideMenuItem } from 'modules/settings/submodules/components/SideMenu/model';
import { matchPath } from 'react-router';
import { browserHistory } from 'shared/utils/browserHistory';
import { CheckPermissionAndFeature } from 'shared/components/CheckPermissionAndFeature/CheckPermissionAndFeature';
import { useStyles } from './styles';

const isActiveRoute = (pathname: string, path: string) => matchPath(
    pathname,
    {
        path,
        exact: false,
        strict: false,
    },
);

const renderChildren = (items: ISideMenuItem[], classes: ReturnType<typeof useStyles>, pathname: string) => (
    <Box ml={1} mb={2}>
        {items.map(({ title, link, children, ...checkAccess }) => (
            <CheckPermissionAndFeature {...checkAccess} key={title}>
                <NavLink
                    key={title}
                    to={link}
                    activeClassName={classes.childItemActive}
                    className={classes.childItem}
                >
                    {title}
                </NavLink>
                {children && isActiveRoute(pathname, link) && renderChildren(children, classes, pathname)}
            </CheckPermissionAndFeature>
        ))}
    </Box>
);

export const SideMenuItem = ({ title, link, children, ...checkAccessProps }: ISideMenuItem) => {
    const classes = useStyles();
    const { pathname } = browserHistory.location;
    const expand = isActiveRoute(pathname, link);

    return (
        <CheckPermissionAndFeature {...checkAccessProps}>
            <NavLink
                to={link}
                className={clsx(classes.menuItem, { [classes.activeLink]: expand })}
                exact={true}
            >
                <ListItem>
                    {title}
                </ListItem>
            </NavLink>
            {expand && children && (
                <Box className={classes.childContainer}>
                    {renderChildren(children, classes, pathname)}
                </Box>
            )}
        </CheckPermissionAndFeature>
    );
};
