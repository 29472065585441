import { baseStepNumber, BulkEditSubassignmentSteps, editStepTitle } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import { selectEditStep } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export type AdditionalSteps = Partial<Record<BulkEditSubassignmentSteps, string>>;

export const useEditBulkSubassignmentModalTitle = (isOpened: boolean) => {
    const step = useSelector(selectEditStep);
    const [additionalSteps, setAdditionalSteps] = useState<AdditionalSteps>({});
    const additionalStepsCount = Object.values(additionalSteps).filter(Boolean).length;
    const stepCount = baseStepNumber[step] + additionalStepsCount;

    useEffect(() => {
        setAdditionalSteps({});
    }, [isOpened, setAdditionalSteps]);

    const overrideTitle = additionalSteps[step];
    const title = `${stepCount}. ${overrideTitle || editStepTitle[step]}`;
    return {
        title,
        setAdditionalSteps,
    };
};
