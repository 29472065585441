import { Box, Button, Hidden, Typography } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import {
    selectCcpEditMode,
    selectCcpIsLoadingReconciledCsvReport,
    selectCcpIsLoadingUnreconciledCsvReport,
    selectCcpIsLoadingReminder,
    selectCcpTransactionsActiveTab,
    selectIsLoadingEditModeSave,
} from '../../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { CcpStatusSlug, CcpStatusTabNameMap } from '../../models/ccpModel';
import {
    getCsvReconciled,
    getCsvUnreconciled,
    sendReminder,
    setCcpEditMode,
    triggerEditModeSave,
} from '../../store/actions';
import { Print } from '@material-ui/icons';
import useActionButtonStyles
    from '../../../clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import ActionButton from '../../../../shared/components/buttons/ActionButton';
import { setOpenCcpSubmitInvoicesModal } from '../CcpInvoices/store/actions';
import { selectCcpCheckedTransactions } from '../CcpInvoices/store/selectors';
import { CcpFilter } from './CcpFilter';
import { selectIsUserHasPermission } from '../../../../store/components/auth/selectors';
import { Permission } from '../../../../store/components/auth/authModels';
import { ccpTranInfinityTableSelectors, selectCcpFilter } from './store/selectors';

interface ICcpHeaderProps {
    amount?: number;
}

export default function CcpTransactionsTableHeader({ amount } : ICcpHeaderProps) {
    const buttonClasses = useActionButtonStyles();

    const hasCanViewCcpAllPermission = useSelector(selectIsUserHasPermission(Permission.CanViewCcpAll));
    const hasViewCcpSubordinatePermission = useSelector(selectIsUserHasPermission(Permission.ViewCcpSubordinate));
    const hasCanEditCcpReconciled = useSelector(selectIsUserHasPermission(Permission.CanEditCcpReconciled));
    const selectedTab = useSelector(selectCcpTransactionsActiveTab);
    const showFilters = hasCanViewCcpAllPermission || hasViewCcpSubordinatePermission;
    const resultStatusName = CcpStatusTabNameMap[selectedTab];
    const isLoadingReconciledCsvReport = useSelector(selectCcpIsLoadingReconciledCsvReport);
    const isLoadingUnreconciledCsvReport = useSelector(selectCcpIsLoadingUnreconciledCsvReport);
    const isPrintLoading = isLoadingReconciledCsvReport || isLoadingUnreconciledCsvReport;
    const isTableLoading = useSelector(ccpTranInfinityTableSelectors.isLoading);
    const isEditModeLoading = useSelector(selectIsLoadingEditModeSave);
    const selectedItems = useSelector(selectCcpCheckedTransactions);
    const filters = useSelector(selectCcpFilter);
    const isEditMode = useSelector(selectCcpEditMode);
    const isReminderLoading = useSelector(selectCcpIsLoadingReminder);
    const selectedItemsList = useMemo(() => {
        const results = Object.keys(selectedItems).filter(selectedItem => {
            if (!selectedItem.startsWith('_') && !!selectedItems[selectedItem]) {
                return selectedItem;
            }
        });
        return results;
    }, [selectedItems]);
    const dispatch = useDispatch();

    const onPrintClick = useCallback((status: CcpStatusSlug) => {
        switch (status) {
            case CcpStatusSlug.Reconciled:
                dispatch(getCsvReconciled.init());
                return;
            case CcpStatusSlug.Unreconciled:
                dispatch(getCsvUnreconciled.init());
                return;
            default:
                return;
        }
    }, [dispatch]);

    const startSubmitInvoice = useCallback(() => {
        dispatch(setOpenCcpSubmitInvoicesModal(true));
    }, [dispatch]);

    const onSendReminderClick = useCallback(() => {
        dispatch(sendReminder.init());
    }, [dispatch]);

    const onEditModeClick = useCallback(() => {
        dispatch(setCcpEditMode(true));
    }, [dispatch]);

    const onSaveAndExistClick = useCallback(() => {
        dispatch(triggerEditModeSave.init());
    }, [dispatch]);

    return (
        <Box display="flex"
            justifyContent="space-between" alignItems="center"
        >
            <Hidden smDown>
                <Box flexDirection={'column'}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h4">
                            {resultStatusName} {amount && resultStatusName ? `(${amount})` : ''}
                        </Typography>
                    </Box>
                    {showFilters && (
                        <Box marginBottom={'15px'}>
                            <CcpFilter/>
                        </Box>
                    )
                    }
                </Box>
            </Hidden>
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                ml={2}
                mr={2}
                // mt={'auto'}
                marginBottom={'15px'}
            >
                {selectedTab === CcpStatusSlug.Reconciled && hasCanEditCcpReconciled && !isEditMode && (
                    <Box marginRight="23px">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onEditModeClick}
                            disabled={isEditModeLoading || isTableLoading}
                        >
                            EDIT MODE
                        </Button>
                    </Box>
                )}
                {selectedTab === CcpStatusSlug.Reconciled && isEditMode && (
                    <Box marginRight="23px">
                        <ButtonWithLoader
                            variant="contained"
                            color="primary"
                            onClick={onSaveAndExistClick}
                            isLoading={isEditModeLoading}
                            disabled={isEditModeLoading || isTableLoading}
                        >
                            SAVE & EXIT
                        </ButtonWithLoader>
                    </Box>
                )}
                {selectedTab === CcpStatusSlug.Unreconciled && (
                    <Box marginRight="23px">
                        <ButtonWithLoader
                            variant="contained"
                            color="primary"
                            isLoading={isReminderLoading}
                            disabled={isReminderLoading || !filters.user_id}
                            onClick={onSendReminderClick}
                        >
                            SEND REMINDER
                        </ButtonWithLoader>
                    </Box>
                )}
                {selectedTab === CcpStatusSlug.Reconciled && selectedItemsList.length > 0 && (
                    <Box marginRight="23px">
                        <ActionButton
                            variant="contained"
                            color="primary"
                            customType="success"
                            onClick={startSubmitInvoice}
                        >
                            POST INVOICE NUMBER
                        </ActionButton>
                    </Box>
                )}
                {[CcpStatusSlug.Reconciled, CcpStatusSlug.Unreconciled].includes(selectedTab as CcpStatusSlug) && (
                    <ButtonWithLoader
                        classes={{ root: buttonClasses.default }}
                        onClick={() => onPrintClick(selectedTab as CcpStatusSlug) }
                        disabled={isPrintLoading}
                        isLoading={isPrintLoading}
                    >
                        <Print/>
                    </ButtonWithLoader>
                )}
            </Box>
        </Box>);
}
