import { IScopeModifyRequest, IScopeUpdatePayload } from 'modules/settings/submodules/clients/scopes/store/models';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

const SET_CREATE_SCOPE_MODAL_STATE = 'settings/SET_CREATE_SCOPE_MODAL_STATE';
export const setCreateScopeModalState = createSingleAction<
boolean,
typeof SET_CREATE_SCOPE_MODAL_STATE
>(
    SET_CREATE_SCOPE_MODAL_STATE,
);

export const createScope = createRequestActions<IScopeModifyRequest, void>(RequestType.Create, 'SCOPE');

const SET_EDIT_SCOPE_ID = 'settings/SET_EDIT_SCOPE_ID';
export const setEditScopeId = createSingleAction<
string | null,
typeof SET_EDIT_SCOPE_ID
>(
    SET_EDIT_SCOPE_ID,
);

export const updateScope = createRequestActions<IScopeUpdatePayload, void>(RequestType.PATCH, 'SCOPE');
