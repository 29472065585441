import { IHasApprovers } from 'modules/subassignmentManagement/types/abstract';
import { IUserInfo } from 'shared/models/User';
import { ValidationMessages } from 'shared/models/Validation';
import * as yup from 'yup';

export function getApproversValidationSchema(numberOfApprovers = 1) {
    return yup.object(
        Array.from(Array(numberOfApprovers).keys()).reduce((mem, i) => ({
            ...mem,
            [i]: yup.object().nullable()
                .required(ValidationMessages.REQUIRED)
                .test({
                    name: 'duplicate-approvers',
                    test: function (value: IUserInfo): boolean {
                        if (!this.parent) {
                            return true;
                        }
                        // @ts-ignore
                        const selectedUsers = Object.values(this.parent).map(approver => approver?.id);
                        return selectedUsers.filter(userId => userId === value?.id).length === 1;
                    },
                    message: 'Duplicated approver',
                }),
        }), {}),
    );
}

export function getApproversValidationSchemaObject(isBulkEdit = false, numberOfApprovers = 1) {
    const approversSchema = getApproversValidationSchema(numberOfApprovers);

    if (isBulkEdit) {
        // @ts-ignore
        return yup.object<IHasApprovers>({
            modifyApprovers: yup.bool().nullable().notRequired(),
            approvers: yup.object().when('modifyApprovers', {
                is: true,
                then: approversSchema,
                otherwise: yup.object().nullable().notRequired(),
            }),
        });
    }

    return yup.object({
        approvers: approversSchema,
    });
}
