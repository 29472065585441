import { IScrollStats } from '../model/IScrollStats';

export function getScrollStats(target: HTMLDivElement): IScrollStats {
    const {
        clientWidth,
        scrollWidth,
        scrollLeft,
        offsetLeft,
        offsetWidth,
    } = target;

    return {
        clientWidth,
        scrollWidth,
        scrollLeft,
        offsetLeft,
        offsetWidth,
    };
}

export function getScrollStatsFromEvent(event: Event): IScrollStats {
    const target = event.target as HTMLDivElement;
    return getScrollStats(target);
}
