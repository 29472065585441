import { useSettingsFormStyles } from '../../../components/sharedStyles/formStyles';
import React, { Fragment, useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { selectEmployeeClientsData } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { IEmployeeClientInfo } from 'store/entities/clients/clientsModel';
import { backendDateTimeFullFormat, shortDateFormat } from 'shared/models/Dates';
import moment from 'moment';
import { selectUserAuthStats } from 'store/entities/users/selectors';

export const MoreInformationSection = () => {
    const classes = useSettingsFormStyles();
    const clientData = useSelector(selectEmployeeClientsData) as IEmployeeClientInfo[];
    const authStatsData = useSelector(selectUserAuthStats);
    const lastPasswordDate = useMemo(() => {
        if (!authStatsData) {
            return '';
        }
        if (authStatsData?.last_password_change) {
            const momentDate = moment(authStatsData?.last_password_change ?? '', backendDateTimeFullFormat);
            return momentDate.format(shortDateFormat);
        }
        return 'N/A';
    }, [authStatsData]);
    const lastLoginDate = useMemo(() => {
        if (!authStatsData) {
            return '';
        }
        if (authStatsData?.last_login) {
            const momentDate = moment(authStatsData?.last_login ?? '', backendDateTimeFullFormat);
            return momentDate.format(shortDateFormat);
        }
        return 'N/A';
    }, [authStatsData]);
    const mfaStatus = useMemo(() => {
        if (!authStatsData) {
            return '';
        }
        return authStatsData?.mfa_credentials_exists ? 'ON' : 'OFF';
    }, [authStatsData]);
    return (
        <Box className={classes.formBlockCard} style={{ minHeight: 208 }}>
            <Typography
                variant="subtitle2"
                className={classes.formBlockCardTitle}
            >
                More Information:
            </Typography>
            <Grid container spacing={2}
                style={{ width: '300px' }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="secondary">Clients:</Typography>
                </Grid>
                <Grid item xs={12}
                    style={{ width: 280 }}>
                    {clientData.map((clientDataItem, counter) => (
                        <Fragment key={clientDataItem?.client?.id}>
                            <Typography variant="body2">
                                {clientDataItem?.has_active_assignments && (
                                    <b>{clientDataItem?.client?.name}</b>
                                )}
                                {!clientDataItem?.has_active_assignments && (
                                    <>{clientDataItem?.client?.name}</>
                                )}
                                {((counter + 1) < clientData.length) && (
                                    <>,&nbsp;</>
                                )}
                            </Typography>
                        </Fragment>
                    ),
                    )}
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="subtitle2" color="secondary">Last Login:</Typography>
                </Grid>
                <Grid item xs={7}
                    style={{ margin: 'auto' }}>
                    <Typography variant="body2">{lastLoginDate}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle2" color="secondary">Last Password Change:</Typography>
                </Grid>
                <Grid item xs={4}
                    style={{ margin: 'auto' }}>
                    <Typography variant="body2">{lastPasswordDate}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="subtitle2" color="secondary">MFA Status:</Typography>
                </Grid>
                <Grid item xs={7}
                    style={{ margin: 'auto' }}>
                    <Typography variant="body2">{mfaStatus}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};
