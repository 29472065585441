import moment from 'moment';
import { backendDateFormat } from 'shared/models/Dates';
import { useMemo } from 'react';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

/**
 * Use today value by default for current week.
 * Return empty value for inline form or first date on week for sidebar form otherwise
 * @param payPeriod - define current pay period
 * @param isSidebar - define expected default value - empty string or first date
 * @return string - default date for current week or empty string
 */
export function useEntryDate(payPeriod: IPayPeriod | null, isSidebar = false): string {
    return useMemo(() => {
        const periodStart = moment(payPeriod?.period_start);
        const periodEnd = moment(payPeriod?.period_end);

        const today = moment();

        if (today.isBetween(periodStart, periodEnd, 'day', '[]')) {
            return today.format(backendDateFormat);
        }

        if (isSidebar) {
            return periodStart.format(backendDateFormat);
        }

        return '';
    }, [payPeriod, isSidebar]);
}
