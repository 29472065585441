import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { useSheetDetailStyles } from './styles';

export interface ISheetInfoItemData {
    title: string;
    value: string;
    additional?: React.ReactNode;
}

export interface ISheetInfoItemProps {
    data: ISheetInfoItemData;
    className?: string;
}

export const SheetInfoItem = ({ data, className }: ISheetInfoItemProps) => {
    const classes = useSheetDetailStyles();
    const lines = data.value
        .replace(/[\r\n]{2,}/g, '\n')
        .split('\n');

    return (
        <ListItem classes={{ root: classes.timesheetInfoItem }} className={className}>
            <Typography variant="subtitle2" className={classes.title}>
                {data.title}:
            </Typography>
            <List className={classes.listWrapper}>
                { lines.map(line => (
                    <ListItem key={ line } classes={{ root: classes.timesheetInfoItem }}>
                        <Typography variant="body2">
                            { line }
                        </Typography>
                    </ListItem>
                )) }
            </List>
            {data.additional}
        </ListItem>
    );
};
