import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@material-ui/core';
import { IPaySettingsModel } from 'modules/settings/submodules/clients/paySettings/store/models';
import { usePaySettingsFormStyles } from 'modules/settings/submodules/clients/paySettings/components/styles';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { FormWithSections } from '../../../../common/components/FormWithSections/FormWithSections';

export interface IPaySettingFormProps extends FormikProps<IPaySettingsModel> {
}

export function PaySettingsForm(_: IPaySettingFormProps) {
    const customClasses = usePaySettingsFormStyles();

    const sections = useMemo(() => ([
        {
            name: 'Pay Group',
            component: (
                <>
                    <Box width="30%">
                        <FormFieldWithSeparateLabel
                            name="pay_group_code"
                            outerLabel="Code"
                            disabled
                        />
                    </Box>
                    <Box width="50%">
                        <FormFieldWithSeparateLabel
                            name="pay_group_description"
                            outerLabel="Description"
                            disabled
                        />
                    </Box>
                </>
            ),
        },
        {
            name: 'Start and End Days',
            component: (
                <>
                    <Box width="45%">
                        <FormFieldWithSeparateLabel
                            name="period_starts"
                            outerLabel="Period starts on day"
                            disabled
                            classes={customClasses}
                        />
                    </Box>
                    <Box width="40%">
                        <FormFieldWithSeparateLabel
                            name="period_ends"
                            outerLabel="and ends on day"
                            disabled
                            classes={customClasses}
                        />
                    </Box>
                </>
            ),
        },
        {
            name: 'Work Week Ends',
            component: (
                <FormFieldWithSeparateLabel
                    name="day_work_week_ends"
                    outerLabel="Day on which work week ends"
                    disabled
                    classes={customClasses}
                />
            ),
        },
        {
            name: 'Pay Day',
            component: (
                <FormFieldWithSeparateLabel
                    name="pay_day"
                    outerLabel="Employees should be paid on"
                    disabled
                    classes={customClasses}
                />
            ),
        },
    ]), [customClasses]);

    return (
        <FormWithSections sections={sections}/>
    );
}
