import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormControl } from '@material-ui/core';
import { useField } from 'formik';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FileInput, { IFileInputProps } from 'shared/components/attachments/FileInput';
import { IAttachment } from 'shared/models/Attachments';
import { useDispatch, useSelector } from 'react-redux';
import { uploadOfferLetterTemplate } from '../../store/action';
import { selectIsOfferLetterTemplateUploading, selectOfferLetterTemplateUploaded } from '../../store/selectors';
import { v4 as uuidv4 } from 'uuid';

export interface IUploadTemplateFieldProps extends IFormFieldProps, Omit<IFileInputProps, 'attachments' | 'onAttachmentChange' | 'onAttachmentRemove'> {
    name: string;
    info?: React.ReactNode;
}

export const UploadTemplateField = ({
    name,
    id = String(name),
    className,
    info,
    ...props
}: IUploadTemplateFieldProps) => {
    const [, meta, helper] = useField(name);
    const fieldHelper = useRef(helper);
    const setUploadedFileLink = useCallback((value: string) => fieldHelper.current.setValue(value), [fieldHelper]);

    const [attachments, setAttachments] = useState<IAttachment[]>([]);
    const dispatch = useDispatch();

    // Clear uploaded files on form detaching
    useEffect(() => {
        return () => {
            dispatch(uploadOfferLetterTemplate.success(''));
        };
    }, [dispatch]);

    const onAttachmentChange = useCallback((files: File[]) => {
        const file = files[0];
        if (file) {
            setAttachments([{
                id: uuidv4(),
                filename: file.name,
                url: '',
                mimetype: file.type,
            }]);
            dispatch(uploadOfferLetterTemplate.init(file));
        }
    }, [dispatch]);
    const onAttachmentRemove = useCallback(() => {
        dispatch(uploadOfferLetterTemplate.success(''));
        setAttachments([]);
    }, [dispatch]);

    const uploadedFile = useSelector(selectOfferLetterTemplateUploaded);
    useEffect(() => {
        if (!uploadedFile) {
            setAttachments([]);
        }
        setUploadedFileLink(uploadedFile);
    }, [uploadedFile, setUploadedFileLink]);

    const isFileUploading = useSelector(selectIsOfferLetterTemplateUploading);

    const error = meta.error && meta.touched ? meta.error : null;
    return (
        <FormControl
            className={className}
            variant="outlined"
            key={id}
        >
            <FileInput
                {...props}
                attachments={attachments}
                onAttachmentChange={onAttachmentChange}
                onAttachmentRemove={onAttachmentRemove}
                error={error}
                multiple={false}
                isLoading={isFileUploading}
            />
            {info}
        </FormControl>
    );
};
