import { call, put, takeLatest } from 'typed-redux-saga';
import { getControllingOrgs } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { api } from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/store/api';
import {
    createControllingOrg,
    setCreateControllingOrgModalState,
} from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/store/actions';
import { ICreateControllingOrgRequest } from 'shared/models/ControllingOrg';
import baseApi from 'shared/utils/baseApi';

function* createControllingOrgSaga(action: ReturnType<typeof createControllingOrg.init>) {
    const request = {
        ...action.payload,
        client_id: baseApi.clientId,
    } as ICreateControllingOrgRequest;
    const result = yield* call(api.createControllingOrg, request);
    yield put(createControllingOrg.success(result));
    yield put(getControllingOrgs.success([result]));
    yield put(setCreateControllingOrgModalState(false));
}

export function* createControllingOrgWatcher() {
    yield* takeLatest(
        createControllingOrg.initType,
        withBackendErrorHandler(
            createControllingOrgSaga,
            createControllingOrg.error,
            'Unable to create Controlling Org.',
        ),
    );
}
