import { IOfferLetterRow } from 'modules/offerLetter/components/OfferLetterTable/cells/cells';
import { OfferLetterCellKey } from 'modules/offerLetter/components/OfferLetterTable/cells/OfferLetterCellKey';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';

type OfferLetterCell = ICellInfo<IOfferLetterRow>
type OfferLetterCells = Array<OfferLetterCell>;

const orderByKey = [
    OfferLetterCellKey.CheckBoxPayRangeApproval,
    OfferLetterCellKey.Employee,
    OfferLetterCellKey.StartDate,
    OfferLetterCellKey.Position,
    OfferLetterCellKey.Location,
    OfferLetterCellKey.PayRange,
    OfferLetterCellKey.PayRate,
    OfferLetterCellKey.Status,
    OfferLetterCellKey.OfferLetterPDF,
    OfferLetterCellKey.OfferEdit,
];

export function orderCellsForPayRangeApprovalTab(cells: OfferLetterCells): OfferLetterCells {
    return orderByKey.map(key => cells.find(cell => cell.key === key) as OfferLetterCell).filter(Boolean);
}
