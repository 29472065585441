import { IStore } from 'store/configureStore';
import { selectSubmittingOrgState } from 'modules/settings/submodules/clients/submittingOrg/store/selectors';

const selectEditSubmittingOrgState = (state: IStore) => {
    const submittingOrgState = selectSubmittingOrgState(state);
    return submittingOrgState.editSubmittingOrg;
};
export const selectEditSubmittingOrgId = (state: IStore) => {
    const editSubmittingOrgState = selectEditSubmittingOrgState(state);
    return editSubmittingOrgState.submittingOrgId;
};
export const selectIsSubmittingOrgUpdating = (state: IStore) => {
    const editSubmittingOrgState = selectEditSubmittingOrgState(state);
    return editSubmittingOrgState.isUpdating;
};
