import React from 'react';
import { Button } from '@material-ui/core';
import { ISheetGroupIdWithClient } from 'modules/payrollProcessorHub/store/model';
import { useSelector } from 'react-redux';

import { useOpenState } from 'shared/hooks/useOpenState';
import { UnlockConfirmationDialogSingleApprover } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/UnlockConfirmationDialogSingleApprover';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export interface IUnlockButtonProps {
    groupIds: ISheetGroupIdWithClient[];
}

export function UnlockButton({ groupIds }: IUnlockButtonProps) {
    const [group] = groupIds;
    const { timeSheetId, expenseSheetId } = group;
    const { isOpened, open, close } = useOpenState();
    const userHasPermission = useSelector(selectIsUserHasPermission(Permission.CanUnlockPphSheet));

    if (!userHasPermission) {
        return null;
    }

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                onClick={open}
            >
                Unlock
            </Button>
            <UnlockConfirmationDialogSingleApprover
                isOpened={isOpened}
                onCancel={close}
                timeSheetIds={timeSheetId ? [timeSheetId] : []}
                expenseSheetIds={expenseSheetId ? [expenseSheetId] : []}
            />
        </>
    );
}
