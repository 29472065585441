import { ClassValue } from 'clsx';
import React from 'react';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { IEntity } from 'shared/models/Entity';

export interface ISettingsTableRow extends IEntity {}

export interface ISettingsTablePureProps<T extends ISettingsTableRow> {
    rows: T[];
    cells: ICellInfo<T>[];
    isLoading: boolean;
    getRowClasses?: (row: T) => ClassValue[];
}

export function SettingsTablePure<T extends ISettingsTableRow>({
    rows,
    cells,
    isLoading,
    getRowClasses,
}: ISettingsTablePureProps<T>) {
    const tableClasses = useDefaultTableStyles();

    return (
        <div>
            <GridTable
                rowData={rows}
                getKey={row => row.id}
                cells={cells}
                isLoading={isLoading}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                getRowClasses={getRowClasses}
                stickyHeader
            />
        </div>
    );
}
