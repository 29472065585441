import React, { ComponentType, useCallback, useEffect } from 'react';
import { intersection } from 'lodash-es';
import { Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import NotPermitted from 'shared/components/common/NotPermitted';
import { HeadwayLoader } from 'shared/components/loader/HeadwayLoader';
import { selectIsAuthenticated, selectIsUserImpersonate, selectIsUserLoading, selectUserPermissions } from 'store/components/auth/selectors';
import { Permission } from 'store/components/auth/authModels';
import { keycloakLogin } from 'store/components/auth/authActions';
import { selectOptimizelyReady } from 'store/entities/appConfig/appConfigSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';

export interface IPrivateRouteProps<ComponentProps> {
    component: ComponentType<ComponentProps>;
    path?: string;
    exact?: boolean;
    permission?: Permission;
    anyPermissionFrom?: Permission[];
    componentProps?: ComponentProps;
    blockImpersonate?: boolean;
    waitOptimizely?: boolean;
    feature?: FeatureSwitches;
}

export default function PrivateRoute<ComponentProps>({
    path,
    component: Node,
    permission,
    anyPermissionFrom = [],
    exact = false,
    blockImpersonate = false,
    componentProps,
    waitOptimizely = false,
    feature,
}: IPrivateRouteProps<ComponentProps>) {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isUserLoading = useSelector(selectIsUserLoading);
    const userPermissions = useSelector(selectUserPermissions);
    const isUserImpersonate = useSelector(selectIsUserImpersonate);
    const isOptimizelyInitialized = useSelector(selectOptimizelyReady);
    const permissions = permission ? [...anyPermissionFrom, permission] : anyPermissionFrom;

    useEffect(() => {
        if (!isUserLoading && !isAuthenticated) {
            dispatch(keycloakLogin());
        }
    }, [isAuthenticated, isUserLoading, dispatch]);

    const { pathname } = useLocation();
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('redirect_url', pathname);
    const isPermitted = (!permissions.length || intersection(userPermissions, permissions).length > 0)
        && (!blockImpersonate || !isUserImpersonate);

    const waitForOptimizelyInitialization = !isOptimizelyInitialized && (waitOptimizely || feature);

    const render = useCallback((props: any) => {
        if (isUserLoading || !isAuthenticated || waitForOptimizelyInitialization) {
            return <HeadwayLoader fullScreen/>;
        }
        if (!isPermitted) {
            return <NotPermitted/>;
        }
        if (feature) {
            return (
                <FeatureSwitch feature={feature}>
                    <Node {...props} {...(componentProps ?? {})}/>
                </FeatureSwitch>
            );
        }
        return <Node {...props} {...(componentProps ?? {})}/>;
    }, [
        Node,
        componentProps,
        feature,
        isAuthenticated,
        isPermitted,
        isUserLoading,
        waitForOptimizelyInitialization,
    ]);

    return (
        <Route
            path={path}
            exact={exact}
            render={render}
        />
    );
}
