import React from 'react';
import { Box } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useStyles = makeHighPriorityStyles(theme => ({
    container: {
        display: 'grid',
        overflow: 'hidden',
        gridTemplateRows: '1fr',

        '& > div': {
            overflow: 'hidden',
            overflowY: 'auto',
            maxHeight: `calc(100vh - ${theme.spacing(20)}px)`,
            height: `calc(100vh - ${theme.spacing(20)}px)`,
        },
    },
    sideAreaContainer: {
        position: 'relative',
        minHeight: '640px',
    },
    mainAreaContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

interface IWorkspaceContainerProps {
    children: React.ReactNode;
    additionalWorkspace?: React.ReactNode;
    mainAreaContainerId?: string;
}

export const WorkspaceContainer = ({
    children,
    additionalWorkspace,
    mainAreaContainerId,
}: IWorkspaceContainerProps) => {
    const classes = useStyles();
    const gridTemplateColumns = additionalWorkspace ? '1fr 1fr' : '1fr';

    return (
        <Box
            className={classes.container}
            gridTemplateColumns={gridTemplateColumns}
        >
            <Box
                className={classes.mainAreaContainer}
                id={mainAreaContainerId}
            >
                {children}
            </Box>
            {additionalWorkspace && (
                <Box className={classes.sideAreaContainer}>
                    {additionalWorkspace}
                </Box>
            )}
        </Box>
    );
};
