import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { getUniqValues } from 'modules/payrollProcessorHub/store/helpers';
import { useSelector } from 'react-redux';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectAssignmentsById, selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';

export const usePayrollSheetGroupSubassignments = (row: IPayrollSheetGroupedRow): ISubassignment[] => {
    const subassignmentsByIds = useSelector(selectSubassignmentsByIds);
    const allCalculations = [...row.time_calculations, ...row.expense_calculations] as Array<ICalculation>;
    const subassignmentIds = getUniqValues(allCalculations, 'subassignment_id');
    return subassignmentIds.map(id => subassignmentsByIds[id]);
};

export const usePayrollSheetGroupAssignments = (row: IPayrollSheetGroupedRow): IAssignment[] => {
    const assignmentsById = useSelector(selectAssignmentsById);
    const allCalculations = [...row.time_calculations, ...row.expense_calculations] as Array<ICalculation>;
    const assignmentIds = getUniqValues(allCalculations, 'assignment_id');
    return assignmentIds.map(id => assignmentsById[id]).filter(Boolean);
};
