import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useOfferLetterEmployeeStyles = makeHighPriorityStyles((theme: Theme) => ({
    wrapper: {
        padding: theme.spacing(3, 10),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 0),
        },
    },
    tableWrapper: {
        [theme.breakpoints.down('xs')]: {
            borderTop: `1px solid ${colors.gray}`,
        },
    },
    bodyCell: {
        '*&&': {
            borderBottom: `1px solid ${colors.lightGray}`,
        },
    },
    title: {
        textTransform: 'none',
        marginBottom: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
        },
    },
    mobileListWrapper: {
        '& > *:nth-child(2) > span': {
            fontSize: 12,
        },
    },
    listItemsAlignEnd: {
        '& > *': {
            justifyContent: 'flex-end',
        },
    },
    statusMobile: {
        paddingRight: theme.spacing(2),
        minWidth: theme.spacing(12),
    },
}));
