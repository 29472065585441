import { Theme } from '@material-ui/core/styles';
import { colors, desktopContentSpace, smallFieldWidth, mediumFieldWidth, largeFieldWidth } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useAddControlsStyles = makeHighPriorityStyles((theme: Theme) => {
    const selectFieldCommonStyles = {
        width: '100%',
        flexGrow: 1,
        marginRight: theme.spacing(2),
    };

    const fixedField = {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(2),
        },
    };

    return {
        paper: {
            display: 'flex',
            alignItems: 'center',
            minHeight: theme.spacing(9),
            marginBottom: theme.spacing(0),
            boxShadow: 'none',
            borderColor: colors.gray,

            [theme.breakpoints.up('md')]: {
                borderRight: 'none',
                borderLeft: 'none',
                paddingRight: theme.spacing(desktopContentSpace),
            },

            [theme.breakpoints.only('sm')]: {
                flexDirection: 'column',
                padding: theme.spacing(0, desktopContentSpace),
            },

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        form: {
            width: '100%',
        },
        flexItem: {
            width: '100%',
            flexGrow: 1,
            '& > *': {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                flexBasis: theme.spacing(12),
                maxWidth: theme.spacing(40),
                marginRight: theme.spacing(2),
            },
        },
        fixedMedium: {
            width: theme.spacing(18),
            minWidth: theme.spacing(18),
            ...fixedField,
        },
        fixedLarge: {
            width: theme.spacing(21),
            minWidth: theme.spacing(21),
            ...fixedField,
        },
        selectField: {
            ...selectFieldCommonStyles,

            [theme.breakpoints.up('md')]: {
                flexBasis: smallFieldWidth,
            },
        },
        selectFieldSmall: {
            ...selectFieldCommonStyles,

            [theme.breakpoints.up('md')]: {
                maxWidth: smallFieldWidth,
            },
        },
        selectFieldMedium: {
            ...selectFieldCommonStyles,

            [theme.breakpoints.up('md')]: {
                maxWidth: mediumFieldWidth,
            },
        },
        selectFieldLarge: {
            ...selectFieldCommonStyles,

            [theme.breakpoints.up('md')]: {
                flexBasis: largeFieldWidth,
                flexGrow: 0,
            },
        },
        timeInputField: {
            marginRight: theme.spacing(2),
            width: theme.spacing(16),
            minWidth: 'auto',
        },
        timeTotalInputField: {
            marginRight: theme.spacing(2),
            width: theme.spacing(8),
            minWidth: 'auto',
        },
        typeQuantityIconContainer: {
            display: 'inline-flex',

            [theme.breakpoints.up('md')]: {
                width: theme.spacing(4),
                flexDirection: 'column',
                margin: theme.spacing(0.5),
                alignSelf: 'flex-start',
            },

            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(2),
            },
        },
        controlsContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            width: '100%',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        aSide: {
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'wrap',

            '& > *': {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        },
        timeFields: {
            width: theme.spacing(6),
            minWidth: 'auto',
        },
        upperButton: {
            [theme.breakpoints.up('md')]: {
                width: theme.spacing(14),
                marginLeft: 'auto',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            [theme.breakpoints.only('sm')]: {
                width: '100%',
                marginBottom: theme.spacing(1),
            },
        },
        activeFilterControl: {
            color: theme.palette.primary.main,
        },
        hidden: {
            visibility: 'hidden',
        },
        notDisplayed: {
            display: 'none',
        },
        addIcon: {
            margin: theme.spacing(0, 0, 0.5, 1),
            fontSize: theme.spacing(2.5),
        },
        loaderIconContainer: {
            margin: theme.spacing(-0.5, 0, -0.5, 1),
            color: 'inherit',
        },
        loaderIcon: {
            color: 'inherit',
            fontSize: theme.spacing(1),
            height: `${theme.spacing(2.5)}px !important`,
            width: `${theme.spacing(2.5)}px !important`,
        },
    };
});

export default useAddControlsStyles;
