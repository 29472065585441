import React from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import {
    formBasicSpacing,
    useSettingsFormStyles,
} from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { useFormWithSectionsStyles } from './styles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';

export interface IFormWithSectionsProps<Values> extends Partial<FormikProps<Values>> {
    sections: IFormWithSectionsSection[];
    onSubmit?: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
    submitTitle?: string;
}

export interface IFormWithSectionsSection {
    name: string;
    component: React.ReactNode,
}

export function FormWithSections<Values>({
    sections,
    onSubmit,
    isDisabled = false,
    isLoading = false,
    submitTitle = 'Submit',
}: IFormWithSectionsProps<Values>) {
    const defaultClasses = useSettingsFormStyles();
    const customClasses = useFormWithSectionsStyles();
    const classes = {
        ...defaultClasses,
        ...customClasses,
    };

    return (
        <form className={classes.form} onSubmit={onSubmit}>
            {sections.map(section => (
                <Box key={section.name} className={clsx(classes.formBlock)}>
                    <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>{section.name}</Typography>
                    <Box display="flex" className={clsx(classes.formBlockContent)}>
                        {section.component}
                    </Box>
                </Box>
            ))}
            {onSubmit && (
                <Box m={formBasicSpacing} mt={5}>
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={isDisabled}
                        isLoading={isLoading}
                        data-testid="submit_button"
                    >
                        {submitTitle}
                    </ButtonWithLoader>
                </Box>
            )}
        </form>
    );
}
