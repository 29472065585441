import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useTotalInfoStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: colors.white,
        border: `1px solid ${colors.lightGray}`,
        paddingLeft: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            border: 'none',
        },
    },
    sides: {
        display: 'flex',
    },
    childComponent: {
        height: theme.spacing(6),
        padding: theme.spacing(1, 2, 1, 0),
    },
    divider: {
        alignSelf: 'center',
        height: theme.spacing(5),
        borderLeft: `1px dotted ${colors.black}`,
        backgroundColor: colors.white,
    },
}));
