import React, { useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { setEafApprovalActiveTab } from 'modules/eafApproval/store/actions';
import { EafApprovalStatusDisplayName } from 'modules/eafApproval/store/model/data';
import { selectEafApprovalActiveTab, selectEafPendingItemsCount } from 'modules/eafApproval/store/selectors';
import { EafStatusSlug } from 'modules/employeeActionsForm/store/models';
import { setHeaderContent } from 'modules/home/header/Header';
import { useSelector } from 'react-redux';
import { HeaderTabs } from 'shared/components/common/headerTabs/HeaderTabs';
import { useTabManagement } from 'shared/hooks/useTabManagement';
import { EafPageTitle } from '../../../eafApproval/store/model/types';

export function useTerminationRequestTabs() {
    const { activeTab, setActiveTab } = useTabManagement<EafStatusSlug>(
        // @ts-ignore
        selectEafApprovalActiveTab,
        setEafApprovalActiveTab,
    );

    const pendingCount = useSelector(selectEafPendingItemsCount);
    const pendingCountText = pendingCount > 0 ? ` (${pendingCount})` : '';

    const terminationRequestTabs = useMemo(() => [
        {
            value: EafStatusSlug.PendingApproval,
            label: `Pending${pendingCountText}`,
        },
        {
            value: EafStatusSlug.Approved,
            label: EafApprovalStatusDisplayName[EafStatusSlug.Approved],
        },
        {
            value: EafStatusSlug.ApprovalRejected,
            label: EafApprovalStatusDisplayName[EafStatusSlug.ApprovalRejected],
        },
    ], [pendingCountText]);

    const headerContent = useMemo(() => (
        <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            width="100%"
        >
            <HeaderTabs
                tabs={terminationRequestTabs}
                mobileHeader={EafPageTitle}
                value={activeTab}
                onChange={setActiveTab}
            />
        </Box>
    ), [activeTab, setActiveTab, terminationRequestTabs]);

    useEffect(() => {
        setHeaderContent(headerContent);
        return () => {
            setHeaderContent(null);
        };
    }, [headerContent]);
}
