import React, { FC } from 'react';
import OutlinedField from './OutlinedField';
import NumberFormat from 'react-number-format';
import { timeFormatter } from './time/timeFormatter';

interface IOutlinedTimeFieldProps {
    label?: string;
    customClasses?: Partial<Record<'input' | 'inputRoot' | 'disabled', string>>;
    onBlur?: any;
    onFocus?: any;
    value?: string;
    disabled?: boolean;
}

const OutlinedTimeField: FC<IOutlinedTimeFieldProps> = ({
    value,
    label,
    customClasses,
    onBlur,
    onFocus,
    ...props
}: IOutlinedTimeFieldProps) => {
    return (
        <OutlinedField
            {...props}
            onBlur={onBlur}
            value={value}
            label={label}
            customClasses={customClasses}
            onFocus={onFocus}
            formatter={timeFormatter}
            numberFormat={NumberFormat as any}
        />
    );
};

export default OutlinedTimeField;
