import React from 'react';
import { Button } from '@material-ui/core';

import { useReduxModal } from 'shared/utils/hooks/useModal';
import CreateDealModal from 'modules/settings/submodules/clients/deals/components/CreateDeal/CreateDealModal';
import { setCreateDealModalState } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/actions';
import { selectIsDealModalOpen } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/selectors';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const CreateDealButton = () => {
    const classes = useColoredFormModalStyles();

    const { isModalOpened,
        openModal,
        closeModal } = useReduxModal(selectIsDealModalOpen, setCreateDealModalState);

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + ADD NEW
            </Button>
            <CreateDealModal
                title="Create New Deal"
                isOpened={isModalOpened}
                onClose={closeModal}
                customClasses={classes}
                showCloseIcon
            />
        </>
    );
};
