import React from 'react';
import { Button } from '@material-ui/core';
import { useSheetRejectStyles } from 'shared/components/sidebars/SheetReject/styles';

interface IRejectGroupsConfirmationFooterProps {
    onConfirmRejection: () => void;
    onCancel: () => void;
}

export const RejectGroupsConfirmationFooter = ({
    onCancel,
    onConfirmRejection,
}: IRejectGroupsConfirmationFooterProps) => {
    const classes = useSheetRejectStyles();
    const confirmButtonClasses = { root: classes.buttonSave, label: classes.buttonTextStyle };
    const cancelButtonClasses = { root: classes.buttonCancel, label: classes.buttonTextStyle };

    return (
        <footer className={classes.footer}>
            <Button
                onClick={onConfirmRejection}
                variant="contained"
                color="primary"
                classes={confirmButtonClasses}
            >
                Confirm
            </Button>
            <Button
                onClick={onCancel}
                variant="contained"
                classes={cancelButtonClasses}
            >
                Cancel
            </Button>
        </footer>
    );
};
