import { put, takeLatest } from 'typed-redux-saga';
import { selectTimeSheetStatusByName } from 'store/entities/timesheet/selectors';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { getTimeReviewerSheets } from 'store/components/timeApproval/timeApprovalActions';
import { getReviewerSheetSaga } from 'store/components/sheetApprovalCommon/sheetApprovalCommonSagas';

function* getTimeReviewerSheetsSaga({ payload: statusName }: ReturnType<typeof getTimeReviewerSheets.init>) {
    yield getReviewerSheetSaga(
        loadTimeSheetsWithEntries,
        selectTimeSheetStatusByName,
        statusName,
    );

    yield put(getTimeReviewerSheets.success());
}

function* getTimeReviewerSheetsWatcher(){
    yield* takeLatest(getTimeReviewerSheets.initType, getTimeReviewerSheetsSaga);
}

export default [
    getTimeReviewerSheetsWatcher,
];
