import { isPayRateApprovalStatus } from 'modules/offerLetter/store/helpers';
import React from 'react';
import { Box } from '@material-ui/core';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { usePayRateType } from 'shared/utils/formatters/payRate';
import { IOfferLetter } from '../../store/model';
import OfferLetterStatus from '../OfferLetterTable/OfferLetterStatus';
import OfferLetterActions from './OfferLetterActions';
import { SummaryBlock } from '../../../payrollProcessorHub/components/SheetSummary/SummaryBlock';
import { OfferLetterTableDetail } from 'modules/offerLetter/components/OfferLetterTableDetail/OfferLetterTableDetail';
import OfferLetterInfo from './OfferLetterInfo';

export interface IOfferLetterCardContentProp {
    offerLetter: IOfferLetter;
    clientId: string | null;
}

export const OfferLetterCardContent = ({ offerLetter, clientId }: IOfferLetterCardContentProp) => {
    const payRateType = usePayRateType(offerLetter.pay_rate_type);

    const isPayApproval = isPayRateApprovalStatus(offerLetter.status.slug);

    return (
        <Box>
            <Box component="section" p={2}>
                <Box display="flex" flexDirection="row">
                    <OfferLetterInfo offerLetter={offerLetter} isPayApproval={isPayApproval}/>
                    <OfferLetterStatus offerLetter={offerLetter}/>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={3}
                >
                    <OfferLetterActions offerLetter={offerLetter} clientId={clientId}/>
                    {!isPayApproval && (
                        <Box
                            display="flex"
                            flexDirection="row"
                            mt={-1}
                        >
                            <SummaryBlock
                                value={formatDollars(offerLetter.pay_rate_value)}
                                title={payRateType}
                                color="green"
                            />
                        </Box>
                    )}
                </Box>
                {isPayApproval && (
                    <OfferLetterTableDetail offerLetter={offerLetter}/>
                )}
            </Box>
        </Box>
    );
};
