import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { IReportOverviewItem } from '../../../store/model';

export interface IReportOverviewClasses {
    row?: string;
    column?: string;
    values?: string[];
    value?: string;
    label?: string;
    valueDelimiter?: string;
}

export interface IReportOverviewProps {
    blocks: IReportOverviewItem[][];
    classes?: IReportOverviewClasses;
}

const ReportOverview = ({ blocks, classes = {} }: IReportOverviewProps) => {

    return (
        <>
            {blocks.map((block, blockIndex) => (
                <Box key={blockIndex} className={classes.column}>
                    {block.map(item => (
                        <Box key={item.label} className={classes.row}>
                            <span className={classes.label}>{item.label}: </span>
                            {item.values?.map((value, index) => {
                                const valueClass = classes.values ? classes.values[index] : classes.value;
                                return (
                                    <>
                                        {index > 0 && (
                                            <span className={classes.valueDelimiter}> | </span>
                                        )}
                                        <span className={clsx(valueClass || classes.value, item.className)}>
                                            {value}
                                        </span>
                                    </>
                                );
                            })}
                        </Box>
                    ))}
                </Box>
            ))}
        </>
    );
};

export default ReportOverview;
