import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TextFilter } from 'shared/components/filters/TextFilter';
import { debounce } from 'ts-debounce';
import { setCostCenterFilter } from 'modules/settings/submodules/clients/costCenters/components/Filter/store/actions';

export const CostCenterFilter = () => {
    const dispatch = useDispatch();
    const debounceTimeout = 50;
    const changeFilter = useMemo(() => debounce(
        (newFilterValue: string) => {
            dispatch(setCostCenterFilter(newFilterValue || ''));
        },
        debounceTimeout,
    ), [dispatch]);
    return (
        <TextFilter onFilterValueChanged={changeFilter}/>
    );
};
