import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { IStore } from 'store/configureStore';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllSheets } from 'store/entities/timesheet/selectors';

export const selectEditSheetIdsForSubmit = (
    entryType: EntryType,
    statusName: StatusNames,
    payPeriod?: IPayPeriod,
) => (
    store: IStore,
): string[] => {
    /**
    * Since on edit recalled sheets page displayed all entries in all sheets for pay period
    * we want to submit all recalled sheets, not just selected in current group
    */
    const allSheets = selectAllSheets(store);
    return allSheets.filter((sheet: ISheet) => {
        return sheet.entry_type === entryType
            && (sheet.status?.name === statusName || sheet.status?.name === StatusNames.WORKING)
            && sheet.period_start === payPeriod?.period_start
            && sheet.period_end === payPeriod?.period_end;
    }).map(sheet => sheet.id).filter(Boolean);
};
