import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { FormMode } from 'shared/models/FormMode';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { selectClientHasCompatibleHierarchy, selectCustomFieldRelations, selectCustomFieldType } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { CustomFieldValueForm } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/CustomFieldValueForm';
import { useCustomFieldValueValidationSchemaByType } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/CustomFieldValueValidationSchema';
import { ICustomFieldValueFormValues } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/model';
import { useInitialFormValue } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/utils';

export interface ICustomFieldFormWrapperProps {
    onSubmit: (values: ICustomFieldValueFormValues) => void;
    submitTitle: string;
    isLoading: boolean;
    mode: FormMode;
    customFieldId: string;
    customFieldValue?: ICustomFieldValue;
}

export const CustomFieldValueFormWrapper = ({
    onSubmit,
    submitTitle,
    isLoading,
    mode,
    customFieldId,
    customFieldValue,
}: ICustomFieldFormWrapperProps) => {
    const type = useSelector(selectCustomFieldType);
    const relations = useSelector(selectCustomFieldRelations);
    const isHierarchiesAreCompatible = useSelector(selectClientHasCompatibleHierarchy);
    const defaultValue = useInitialFormValue(type, mode, relations, customFieldId, customFieldValue);
    const validationSchema = useCustomFieldValueValidationSchemaByType(type);

    return (
        <Formik
            initialValues={defaultValue}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            enableReinitialize
        >
            {props => (
                <CustomFieldValueForm
                    {...props}
                    relations={relations}
                    type={type}
                    mode={mode}
                    isLoading={isLoading}
                    submitTitle={submitTitle}
                    compatible={isHierarchiesAreCompatible}
                />
            )}
        </Formik>
    );
};
