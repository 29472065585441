import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { IStateUS } from 'store/entities/scaZone/models';

export const selectStatesByIds = (store: IStore) => store.scaZone.statesById;
export const selectIsStatesLoading = (store: IStore) => store.scaZone.statesLoading;
export const selectStateCodes = createSelector(
    selectStatesByIds,
    statesByIds => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Object.values(statesByIds).map((state: IStateUS) => state.state_shortname);
    },
);
