import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useSelector } from 'react-redux';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectWorkingConditionsSortedByName } from 'store/entities/configuration/configurationSelectors';
import { IWorkingConditionsTemplate } from 'store/entities/configuration/configurationModel';
import { OfferLetterTemplatedTextField } from './OfferLetterTemplatedTextField';

interface IOfferLetterWorkingConditionsProps extends IFormFieldProps {
    forceValidate?: () => void;
}

interface IOfferLetterWorkingConditionsStoreProps {
    templates?: IWorkingConditionsTemplate[];
}

export function OfferLetterTemplateSelectPure({
    templates,
    ...props
}: IOfferLetterWorkingConditionsProps & IOfferLetterWorkingConditionsStoreProps) {
    return (
        <OfferLetterTemplatedTextField
            labelTemplate="Select Working Conditions Template"
            templates={templates || []}
            {...props}
        />
    );
}

export const OfferLetterWorkingConditions = separateLogicDecorator<
IOfferLetterWorkingConditionsProps,
IOfferLetterWorkingConditionsStoreProps
>(() => ({
    templates: useSelector(selectWorkingConditionsSortedByName),
}))(OfferLetterTemplateSelectPure);
