import React from 'react';
import { Formik } from 'formik';
import { ITemplate } from 'store/entities/configuration/configurationModel';

import {
    defaultTemplateFormValues,
    ITemplateFormValues,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';
import { TemplateValidationSchema } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/TemplateValidationSchema';
import { TemplateForm } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/TemplateForm';

export interface ITemplateFormWrapperProps {
    onSubmit: (values: ITemplateFormValues) => void;
    submitTitle: string;
    templateLabel: string;
    isLoading: boolean;
    template?: ITemplate;
    onCancel?: () => void;
}

const TemplateFormWrapper = ({
    onSubmit,
    isLoading,
    submitTitle,
    templateLabel,
    template,
    onCancel,
}: ITemplateFormWrapperProps) => {
    const initialFormValues = {
        name: template?.name || defaultTemplateFormValues.name,
        template: template?.template || defaultTemplateFormValues.template,
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={TemplateValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <TemplateForm
                    {...props}
                    isLoading={isLoading}
                    submitTitle={submitTitle}
                    onCancel={onCancel}
                    templateLabel={templateLabel}
                />
            )}
        </Formik>
    );
};

export default TemplateFormWrapper;
