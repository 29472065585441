import { call, put, takeLatest } from 'typed-redux-saga';
import { getActivities } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { api } from 'modules/settings/submodules/clients/activities/components/ClientActivitiesTable/components/UpdateStatusSelect/store/api';
import { setActivityStatus } from 'modules/settings/submodules/clients/activities/components/ClientActivitiesTable/components/UpdateStatusSelect/store/actions';
import { Status } from 'shared/models/Status';

function* updateActivityStatusSaga(action: ReturnType<typeof setActivityStatus.init>) {
    const { id, status } = action.payload;
    const isActive = status === Status.active;
    const result = yield* call(api.updateActivityStatus, id, isActive);
    yield put(setActivityStatus.success(result));
    yield put(getActivities.success([result]));
}

export function* updateActivityStatusWatcher() {
    yield* takeLatest(
        setActivityStatus.initType,
        withBackendErrorHandler(
            updateActivityStatusSaga,
            setActivityStatus.error,
            'Unable to update Activity Status.',
        ),
    );
}
