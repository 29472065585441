import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const usePermissionFormStyles = makeHighPriorityStyles((theme: Theme) => ({
    permissionContainer: {
        margin: theme.spacing(2),
        borderTop: `1px solid ${colors.lightGray}`,
    },
    permissionRow: {
        borderBottom: `1px solid ${colors.lightGray}`,
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(6),
    },
    permissionLabel: {
        color: colors.secondary,
        width: '40%',
    },
    permissionSelect: {
        width: '220px',
        display: 'flex',
    },
    permissionCheckbox: {
        marginRight: 0,
    },
}));
