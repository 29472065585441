import { useSelector } from 'react-redux';
import { selectCcpSplitAmountTotal, selectIsCcpSplitInProgress } from './store/selectors';
import React, { useMemo } from 'react';
import ActionButton from '../../../../shared/components/buttons/ActionButton';

export const SplitSaveButtonWrapper = () => {
    const currentTotal = useSelector(selectCcpSplitAmountTotal);
    const isLoading = useSelector(selectIsCcpSplitInProgress);
    const isZeroResult = useMemo(() => {
        return +(Math.round(currentTotal * 100)) !== 0;
    }, [currentTotal]);
    return (
        <ActionButton
            variant="contained"
            color="secondary"
            customType="success"
            type="submit"
            isLoading={isLoading}
            disabled={isZeroResult}
        >
            SAVE & CLOSE
        </ActionButton>
    );
};
