import { ActionsReturnTypes, createActions } from 'store/utils';
import { IAvatar, IAvatarUpload, IUserProfileInfo } from './profileModel';

const GET_USER_PROFILE_BY_ID = 'GET_USER__PROFILE_BY_ID';
const GET_USER_PROFILE_BY_ID_SUCCESS = 'GET_USER_PROFILE_BY_ID_SUCCESS';
const GET_USER_PROFILE_BY_ID_ERROR = 'GET_USER_PROFILE_BY_ID_ERROR';

export const getUserProfile = createActions<
string,
IUserProfileInfo,
any,
typeof GET_USER_PROFILE_BY_ID,
typeof GET_USER_PROFILE_BY_ID_SUCCESS,
typeof GET_USER_PROFILE_BY_ID_ERROR
>(
    GET_USER_PROFILE_BY_ID,
    GET_USER_PROFILE_BY_ID_SUCCESS,
    GET_USER_PROFILE_BY_ID_ERROR,
);
const GET_USER_AVATAR = 'GET_USER_AVATAR';
const GET_USER_AVATAR_SUCCESS = 'GET_USER_AVATAR_SUCCESS';
const GET_USER_AVATAR_ERROR = 'GET_USER_AVATAR_ERROR';

export const getUserAvatar = createActions<
void,
IAvatar,
any,
typeof GET_USER_AVATAR,
typeof GET_USER_AVATAR_SUCCESS,
typeof GET_USER_AVATAR_ERROR
>(
    GET_USER_AVATAR,
    GET_USER_AVATAR_SUCCESS,
    GET_USER_AVATAR_ERROR,
);

const ADD_NEW_AVATAR = 'ADD_NEW_AVATAR';
const ADD_NEW_AVATAR_SUCCESS = 'ADD_NEW_AVATAR_SUCCESS';
const ADD_NEW_AVATAR_ERROR = 'ADD_NEW_AVATAR_ERROR';

export const addUserAvatar = createActions<IAvatarUpload, IAvatar, any,
    typeof ADD_NEW_AVATAR, typeof ADD_NEW_AVATAR_SUCCESS, typeof ADD_NEW_AVATAR_ERROR
>(
    ADD_NEW_AVATAR, ADD_NEW_AVATAR_SUCCESS, ADD_NEW_AVATAR_ERROR,
);

export type ProfileActions = ActionsReturnTypes<typeof getUserProfile>
| ActionsReturnTypes<typeof addUserAvatar>
| ActionsReturnTypes<typeof getUserAvatar>;
