import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IJobNumberFormValues } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/model';
import JobNumberFormWrapper from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/JobNumberFormWrapper';
import { createJobNumberAction } from 'modules/settings/submodules/clients/jobNumber/store/actions';
import { selectIsJobNumberCreating } from 'modules/settings/submodules/clients/jobNumber/components/CreateJobNumber/store/selectors';
import { formValuesToPayload } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/utils';
import { IDeal } from 'shared/models/Deal';

interface ICreateJobNumberModalProps extends Omit<IModalProps, 'title' | 'children'> {
    title: string;
    isOpened: boolean;
    deal?: IDeal | undefined;
    onClose: () => void;
}

export default function CreateJobNumberModal({
    title, isOpened, onClose, deal, ...modalProps
}: ICreateJobNumberModalProps) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsJobNumberCreating);

    const onSave = useCallback((values: IJobNumberFormValues) => {
        const payload = formValuesToPayload(values);
        dispatch(createJobNumberAction.init(payload));
    }, [dispatch]);

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={modalProps}
        >
            <Box>
                <JobNumberFormWrapper
                    onSubmit={onSave}
                    onBack={onClose}
                    submitTitle="Create & Assign a user"
                    isLoading={isLoading}
                    dealReadonly={Boolean(deal)}
                    deal={deal}
                />
            </Box>
        </ModalDialog>
    );
}
