import baseApi from 'shared/utils/baseApi';
import { gwClientExtensionsService } from 'store/entities/configuration/configurationApi';

import { IDealTypePayCodeArBucketForm } from '../model/formModel';
import { IDealTypeArBucket } from 'shared/models/DealTypeArBucket';

interface IDealTypePayCodeArBucketCreateParams {
    deal_type_id: string;
    ar_bucket: string;
    pay_code_id: string;
}

export const api = {
    async associateDealTypePayCodeArBucket(params: IDealTypePayCodeArBucketForm) {
        const { data } = await baseApi.create<IDealTypePayCodeArBucketCreateParams, IDealTypeArBucket>(
            gwClientExtensionsService,
            '/deals/ar_buckets/pay_code_deal_types',
            {
                pay_code_id: params.payCode?.id,
                deal_type_id: params.dealType?.id,
                ar_bucket: params.arBucket,
            },
        );
        return data;
    },
};
