import baseApi from 'shared/utils/baseApi';
import {
    IStatus, ITimeEntry, ITimeSheetBackend, IScaZoneFull, ISheetCommonBackend, ISheetApproval,
} from 'shared/models/sheet/Sheet';
import { IConfigurationByClient } from 'store/entities/configuration/configurationModel';
import {
    ITimeEntryCreate, ITimeEntryUpdate,

    ICreateEntryParams,
} from 'store/entities/timesheet/models/Entry';
import { IWithUserInfo } from 'shared/models/Authentication';

import {
    IUpdateSheetsStatus,
} from '../models/Status';
import {
    IBatchSheetUnlock,
    ISheetBatchUnlockResponse,
    ISheetGroupListParams,
    ISheetGroupResponse,
    ISheetListParams,
    ISheetLogRequest,
    ISheetLogResponse,
} from 'store/entities/timesheet/models/SheetApi';

import { IWithClientId } from 'shared/models/Api';
import { Permission } from 'store/components/auth/authModels';
import {
    ICreateApprovalBody,
    ICreateApprovalResponse, ICreatedApproval, ISheetApprovalRequest,
} from 'store/entities/timesheet/models/SheetApprovals';
import { DateBackend } from 'shared/models/Dates';
import { withClientId } from 'store/utils/withClientId';

const baseRoute = 'time';

export interface ITimeEntryCreateRequest extends ITimeEntryCreate, IWithUserInfo {
    user_id: string;
}
export interface ITimeEntryUpdateRequest extends Omit<ITimeEntryUpdate, 'id'>, IWithUserInfo {
    user_id: string;
}
export interface ITimeEntryPatchEntriesDateSpecificBySheetIdRequest {
    entry_date: DateBackend;
    is_per_diem: boolean;
}
export interface ITimeEntryPatchEntriesDateSpecificBySheetIdResponse {
    sheet: ITimeSheetBackend
}

export function convertScaZoneFullToCreateScaZone(scaZoneFull: IScaZoneFull | null | undefined): IScaZoneFull | null {
    return scaZoneFull ? {
        sca_zone_id: scaZoneFull.sca_zone_id,
        county_id: scaZoneFull.county_id,
        county_name: scaZoneFull.county_name,
        state_id: scaZoneFull.state_id,
        state_short_name: scaZoneFull.state_short_name,
    } : scaZoneFull || null;
}

export const timeApi = {
    async createEntry(entryRequest: ITimeEntryCreateRequest, params: ICreateEntryParams): Promise<ITimeEntry> {
        const entryRequestTransformedSca = entryRequest.sca_zone ? {
            ...entryRequest,
            sca_zone: convertScaZoneFullToCreateScaZone(entryRequest.sca_zone),
        } : entryRequest;

        const { data } = await baseApi.create<
        ITimeEntryCreateRequest & IWithClientId, { sheet_entry: ITimeEntry }
        >(
            baseRoute, 'sheet_entries', withClientId(entryRequestTransformedSca), params,
        );
        return data.sheet_entry;
    },
    async deleteEntry(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(`/${baseRoute}/sheet_entries`, id);
        return data.status;
    },
    async getSheetListByPurpose(purpose: Permission, params: ISheetListParams = {}): Promise<Array<ITimeSheetBackend>> {
        const { data } = await baseApi.get<{ sheets: Array<ITimeSheetBackend> }>(
            `/${baseRoute}/sheets/by-purpose/${purpose}`, withClientId(params),
        );
        return data.sheets;
    },
    async getGroupedSheetsByPurpose(
        purpose: Permission, params: ISheetGroupListParams = {},
    ): Promise<ISheetGroupResponse> {
        const { data } = await baseApi.get<ISheetGroupResponse>(
            `/${baseRoute}/sheets/by-purpose/${purpose}/grouped`, withClientId(params),
        );
        return data;
    },
    async getSimplifiedSheetListByPurpose(
        purpose: Permission,
        params: ISheetListParams = {},
    ): Promise<ISheetCommonBackend[]> {
        const { data } = await baseApi.get<{ sheets: ISheetCommonBackend[] }>(
            `/${baseRoute}/sheets/by-purpose/${purpose}/simple`, withClientId(params),
        );
        return data.sheets;
    },
    async getAvailableStatuses(request?: IConfigurationByClient): Promise<Array<IStatus>>{
        const { data } = await baseApi.get<{ statuses: Array<IStatus> }>(
            `/${baseRoute}/sheet_statuses`, withClientId(request || {}),
        );
        return data.statuses;
    },
    async updateEntry(id: string, entryRequest: ITimeEntryUpdateRequest): Promise<ITimeEntry> {
        const updateEntryRequest = {
            ...entryRequest,
            sca_zone: convertScaZoneFullToCreateScaZone(entryRequest.sca_zone),
        };
        const url = `/${baseRoute}/sheet_entries/${id}`;
        const { data } = await baseApi.patch<
        ITimeEntryUpdateRequest & IWithClientId, { sheet_entry: ITimeEntry }
        >(
            url, withClientId(updateEntryRequest),
        );
        return data.sheet_entry;
    },
    async updateSheetsStatuses(payload: IUpdateSheetsStatus): Promise<Array<ITimeSheetBackend>> {
        const { data } = await baseApi.patch<IUpdateSheetsStatus, { sheets: Array<ITimeSheetBackend> }>(`${baseRoute}/sheets`, payload);
        return data.sheets;
    },

    /**
     * When manager clicks Approve, sheet status may not change status to approved if there is higher manager.
     * So now it will create sheet approvals.
     * @param sheetIds Ids of Sheets that managers wants to approve.
     * @returns promise of created approvals
     */
    async createSheetApprovals( sheetIds: string[]): Promise<Array<ICreatedApproval>> {
        const url = `${baseRoute}/sheet_approvals`;
        const body: ICreateApprovalBody = {
            sheet_approvals: sheetIds.map(sheetId => {
                return {
                    sheet_id: sheetId,
                };
            }),
        };

        const { data } = await baseApi.post<ICreateApprovalBody, ICreateApprovalResponse>(url, body);
        return data.sheet_approvals;
    },
    async getSheetById(id: string): Promise<ITimeSheetBackend> {
        const { data } = await baseApi.get<{ sheet: ITimeSheetBackend }>(
            `/${baseRoute}/sheets/${id}`,
        );
        return data.sheet;
    },
    async patchEntriesDateSpecificBySheetId(
        id: string,
        requestData: ITimeEntryPatchEntriesDateSpecificBySheetIdRequest,
    ): Promise<ITimeSheetBackend> {
        const { data } = await baseApi.patch<
        ITimeEntryPatchEntriesDateSpecificBySheetIdRequest, ITimeEntryPatchEntriesDateSpecificBySheetIdResponse
        >(
            `/${baseRoute}/sheets/${id}/entries_date`,
            requestData,
        );
        return data.sheet;
    },
    async batchUnlockSheets(payload: IBatchSheetUnlock): Promise<ISheetBatchUnlockResponse> {
        const { data } = await baseApi.post(
            `/${baseRoute}/sheets/batch_unlock`,
            payload,
        );
        return data;
    },
    async getSheetApprovals(request: ISheetApprovalRequest): Promise<Array<ISheetApproval>>{
        const { data } = await baseApi.get<{ sheet_approvals: Array<ISheetApproval> }>(
            `/${baseRoute}/sheet_approvals`, request,
        );
        return data.sheet_approvals;
    },
    async getSheetLogs(request: ISheetLogRequest): Promise<Array<ISheetLogResponse>>{
        const { data } = await baseApi.post<ISheetLogRequest, { logs: Array<ISheetLogResponse> }>(
            `/${baseRoute}/sheets/logs/query`, request,
        );
        return data.logs;
    },
};
