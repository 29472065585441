import { setCreateClientModalState } from 'modules/settings/submodules/clients/components/NewClientButton/state/actions';
import { selectCreateClientModalState } from 'modules/settings/submodules/clients/components/NewClientButton/state/selector';
import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NewClientModal from 'modules/settings/submodules/clients/components/NewClientModal/NewClientModal';
import { NoGeocodeWarning } from 'modules/settings/submodules/clients/clientDetails/NoGeocodeWarning';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const NewClientButton = () => {
    const classes = useColoredFormModalStyles();
    const dispatch = useDispatch();
    const isOpen = useSelector(selectCreateClientModalState);

    const openModal = useCallback(() => {
        dispatch(setCreateClientModalState(true));
    }, [dispatch]);
    const closeModal = useCallback(() => {
        dispatch(setCreateClientModalState(false));
    }, [dispatch]);
    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                Add new client
            </Button>
            <NewClientModal title={'Create New Client'}
                isOpened={isOpen}
                onClose={closeModal}
                customClasses={classes}
                showCloseIcon
            />
            <NoGeocodeWarning/>
        </>
    );
};
