import { TextField, TextFieldProps, FormHelperText, Box } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldHelperProps, FieldMetaProps, useField } from 'formik';
import { IFormFieldProps } from 'shared/components/formFields/models';
import IntervalDataFieldNote, { IIntervalDataFieldNoteProps } from './IntervalDataFieldNote';
import { QuantityType } from 'shared/models/sheet/Sheet';
import clsx from 'clsx';

export type IntervalInputProps<ValueType> = Omit<TextFieldProps, 'onChange' | 'classes'> & IFormFieldProps & {
    meta?: Partial<FieldMetaProps<ValueType>>;
    helpers?: Partial<FieldHelperProps<ValueType>>;
    onChange: (evt: React.ChangeEvent<HTMLInputElement> | ValueType) => void;
    onBlur?: (evt?: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IIntervalDataFieldClasses {
    inputs?: string;
    firstInput?: string;
    secondInput?: string;
}

export interface IIntervalDataFieldProps<ValueType, ReturnValue> extends IFormFieldProps {
    showTotalInput?: boolean;
    showNote?: boolean;
    showDivider?: boolean;
    calculate?: (startValue: ValueType, endValue: ValueType) => ReturnValue;
    Note?: React.ComponentType<IIntervalDataFieldNoteProps>;
    Divider?: React.ComponentType;
    Input: React.ComponentType<IntervalInputProps<ValueType>>;
    inputs: IFormFieldProps[];
    convert: (value: string) => ValueType;
    compare?: (first: ValueType, second: ValueType) => number;
    classes?: IIntervalDataFieldClasses;
    isFixingEnabled?: boolean;
    entryType: QuantityType;
    hideCommonError?: boolean;
    totalRef?: React.Ref<any>;
    totalAmountClassName?: string;
}

function IntervalDataField<ValueType, ReturnValue>({
    name,
    label,
    inputs,
    className,
    disabled = false,
    showTotalInput = false,
    showNote = false,
    showDivider = false,
    calculate,
    isFixingEnabled = false,
    compare,
    convert,
    Note = IntervalDataFieldNote,
    Divider = () => null,
    Input,
    classes,
    entryType,
    hideCommonError = false,
    totalRef,
    totalAmountClassName,
}: IIntervalDataFieldProps<ValueType, ReturnValue>) {
    const [startInput, endInput] = inputs;
    const startKey = startInput.name;
    const endKey = endInput.name;

    const startLabel = startInput.label;
    const endLabel = endInput.label;

    const [fieldStart, metaFieldStart, helperFieldStart] = useField(`${startKey}`);
    const [fieldEnd, metaFieldEnd, helperFieldEnd] = useField(`${endKey}`);
    const [field, meta, helper] = useField(name);
    const fieldHelperRef = useRef(helper);

    const startInitialValue = field.value ? field.value[startKey] : null;
    const endInitialValue = field.value ? field.value[endKey] : null;
    const [startValue, setStartValue] = useState<ValueType>(startInitialValue);
    const [endValue, setEndValue] = useState<ValueType>(endInitialValue);

    useEffect(() => {
        setStartValue(startInitialValue);
    }, [startInitialValue]);
    useEffect(() => {
        setEndValue(endInitialValue);
    }, [endInitialValue]);

    const onStartValueChange = useCallback((evt: React.ChangeEvent<HTMLInputElement> | ValueType) => {
        const typedValue = convert(typeof evt === 'string' ? evt : (evt as React.ChangeEvent<HTMLInputElement>).target.value);
        if (isFixingEnabled && typeof compare === 'function' && endValue && compare(typedValue, endValue) > 0) {
            return setStartValue(endValue);
        }
        setStartValue(typedValue);
        fieldStart.onChange(typedValue);
    }, [compare, isFixingEnabled, convert, endValue, fieldStart]);
    const onEndValueChange = useCallback((evt: React.ChangeEvent<HTMLInputElement> | ValueType) => {
        const typedValue = convert(typeof evt === 'string' ? evt : (evt as React.ChangeEvent<HTMLInputElement>).target.value);
        if (isFixingEnabled && typeof compare === 'function' && startValue && compare(typedValue, startValue) > 0) {
            return setEndValue(startValue);
        }
        setEndValue(typedValue);
        fieldEnd.onChange(typedValue);
    }, [compare, isFixingEnabled, convert, startValue, fieldEnd]);

    const IsChangeFieldNeeded = useRef(false);
    useEffect(() => {
        if (
            IsChangeFieldNeeded.current
            && !(field.value === null && startValue === null && endValue === null)
        ) {
            fieldHelperRef.current.setValue({
                ...(field.value ? field.value : {}),
                entry_type: entryType,
                [startKey]: startValue,
                [endKey]: endValue,
            });
        } else {
            IsChangeFieldNeeded.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entryType, startKey, endKey, startValue, endValue, fieldHelperRef]);

    const resultValue = useMemo(() => {
        if (showTotalInput) {
            if (typeof calculate === 'function') {
                return calculate(startValue, endValue);
            }
        }
        return '';
    }, [showTotalInput, calculate, startValue, endValue]);
    const hasError = meta.touched && typeof meta.error === 'string';

    useEffect(() => {
        const error = meta.error;

        if (error && typeof error !== 'string') {
            // map error object to the particular subfield error
            helperFieldStart.setError(error[startKey] || null);
            helperFieldEnd.setError(error[endKey] || null);
        } else {
            // if error is string - it will be shown by itself with FormHelperText down below
            helperFieldStart.setError(null);
            helperFieldEnd.setError(null);
        }

        if (meta.touched) { // this touched value is set only after trying to submit
            // perform touched mapping to the particular subfield
            helperFieldStart.setTouched(true);
            helperFieldEnd.setTouched(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta.error, meta.touched]);

    const onFocusStart = useCallback(() => {
        helperFieldStart.setError(null);
    }, [helperFieldStart]);
    const onFocusEnd = useCallback(() => {
        helper.setError(null);
        helperFieldEnd.setError(null);
    }, [helper, helperFieldEnd]);

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="start">
                <Input
                    name={startKey}
                    label={startLabel}
                    value={startValue}
                    className={clsx(classes?.inputs, classes?.firstInput)}
                    disabled={disabled}
                    meta={{
                        touched: metaFieldStart.touched,
                        error: metaFieldStart.error,
                        initialValue: startInitialValue,
                    }}
                    helpers={{ setTouched: helperFieldStart.setTouched }}
                    onChange={onStartValueChange}
                    onFocus={onFocusStart}
                />
                {showDivider && (
                    <Divider/>
                )}
                <Input
                    name={endKey}
                    label={endLabel}
                    value={endValue}
                    className={clsx(classes?.inputs, classes?.secondInput)}
                    disabled={disabled}
                    meta={{
                        touched: metaFieldEnd.touched,
                        error: metaFieldEnd.error,
                        initialValue: endInitialValue,
                    }}
                    helpers={{ setTouched: helperFieldEnd.setTouched }}
                    onChange={onEndValueChange}
                    onFocus={onFocusEnd}
                />
                {showTotalInput && (
                    <TextField
                        className={totalAmountClassName ? totalAmountClassName : className}
                        value={resultValue}
                        disabled
                        label={label}
                        variant="outlined"
                        ref={totalRef}
                    />
                )}
                {showNote && (
                    <Note value={field.value}/>
                )}
            </Box>
            {hasError && !hideCommonError && (<FormHelperText error={hasError}>{meta.error}</FormHelperText>)}
        </Box>
    );
}

export default IntervalDataField;
