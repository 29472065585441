import { makeStyles } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

export const useStyles = makeStyles(theme => ({
    templateNote: {
        margin: theme.spacing(2),
        fontSize: 12,
        '& a': {
            color: 'inherit',
        },
        '& ul': {
            paddingLeft: theme.spacing(2),
        },
        '& li': {
            paddingLeft: theme.spacing(1),
        },
    },
    redText: {
        color: colors.red,
    },
    templateNoteLoader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexBasis: '100%',
        alignItems: 'center',
    },
}));
