export interface IUserProfileInfo {
    id: string;
    identity_id: string;
    first_name: string;
    last_name: string;
    email: string;
    cell_phone: string;
    home_phone: string;
    zip_code: string;
    city: string;
    state: string;
    county: string;
    address1: string;
    address2: string;
    date_of_birth: string;
}

export interface IUserProfileFormValues {
    id: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    state: string;
    address: string;
    city: string;
    zip_code: string;
    county: string;
    avatarUrl: string;
}

export interface IUserAccountFormValues {
    email: string;
    current_password: string;
    new_password: string;    
}

export const defaultUserAccountValues: IUserAccountFormValues = {
    email: '',
    current_password: '',
    new_password: '',
};

export enum UserProfileTab {
    Profile = 'Profile',
    Account = 'Account',   
}

export interface IAvatarUpload{
    entry_id: string;
    body: string;
    filename: string;
    mimetype: string;
    previewUrl?: string;
}

export interface IAvatar{
    id: string;
    url: string;
    mimetype: string;
    filename: string;
}
export const defaultUserAvatar: IAvatar = {
    url: '',
    filename: '',
    mimetype: '',
    id: '',
};
export const defaultUserAvatarValues: IAvatarUpload = {
    entry_id: '',
    body: '',
    filename: '',
    mimetype: '',
    previewUrl: '',
};

export const defaultUserProfileValues: IUserProfileFormValues = {
    id: ' ',
    first_name: ' ',
    last_name: ' ',
    phone_number: ' ',
    state: ' ',
    address: ' ',
    city: ' ',
    zip_code: ' ',
    county: ' ',
    avatarUrl: '',
};
