import React, { useCallback } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { useFilteredCustomFieldValues, useSelectedFieldIds } from 'shared/components/selects/CustomFieldValuesSelect/hooks';
import FormMultiselect from 'shared/components/selects/FormMultiselect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { getFieldValueId, getFieldValueName } from 'store/entities/customFields/helpers';
import { HierarchyType, ICustomFieldHierarchyNode, ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldById, selectCustomFieldValuesByFieldId } from 'store/entities/customFields/selectors';
import { ScopeAction } from 'store/entities/scopes/models';

export interface ICustomFieldValuesMultiselectProps extends IFormFieldProps, IFormSelect {
    customFieldId: string;
    selectAllFieldName: string;
    maxChips?: number;
    hierarchy?: ICustomFieldHierarchyNode[];
    hierarchyType?: HierarchyType;
    customFieldFormValues?: Record<string, any>;
    scopeActionFilter?: ScopeAction;
    onlyActionable?: boolean;
    getText?: (item: ICustomFieldValue) => string;
}

export const CustomFieldValuesMultiselect = ({
    customFieldId,
    selectAllFieldName,
    maxChips,
    hierarchy,
    customFieldFormValues = {},
    hierarchyType = HierarchyType.Assignment,
    scopeActionFilter,
    onlyActionable = true,
    ...props
}: ICustomFieldValuesMultiselectProps) => {
    const customField = useSelector(selectCustomFieldById(customFieldId));
    const customFieldValues = useSelector(selectCustomFieldValuesByFieldId(customFieldId));

    const [field] = useField(props.name);
    const [selectAllField,, selectAllHelper] = useField(selectAllFieldName);
    const onSetSelectedAll = useCallback((value: boolean) => {
        selectAllHelper.setValue(value);
    }, [selectAllHelper]);

    const selectedIds = useSelectedFieldIds(true, field.value, props.useIdValue);
    const filteredCustomFieldValues = useFilteredCustomFieldValues({
        hierarchy,
        customFieldId,
        customFieldFormValues,
        customFieldValues,
        hierarchyType,
        onlyActionable,
        scopeActionFilter,
        selectedIds,
    });

    return (
        <FormMultiselect
            getKey={getFieldValueId}
            getText={getFieldValueName}
            options={filteredCustomFieldValues}
            outerLabel={customField.name}
            useSelectAll
            onSetSelectedAll={onSetSelectedAll}
            initialAllSelected={selectAllField.value}
            disableSmartSelectAll
            maxChips={maxChips}
            {...props}
        />
    );
};
