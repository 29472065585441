import React from 'react';
import { Button } from '@material-ui/core';
import CreateUserModal from 'modules/settings/submodules/platformUsers/components/CreateUser/CreateUserModal';
import { setCreateUserModalState } from 'modules/settings/submodules/platformUsers/store/actions';
import { selectIsCreateUserModelOpened } from 'modules/settings/submodules/platformUsers/store/selectors';
import { useReduxModal } from 'shared/utils/hooks/useModal';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const CreateUserButton = () => {
    const classes = useColoredFormModalStyles();

    const { isModalOpened,
        openModal,
        closeModal } = useReduxModal(selectIsCreateUserModelOpened, setCreateUserModalState);

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                Add new user
            </Button>
            <CreateUserModal
                title="Create New Platform User"
                isOpened={isModalOpened}
                onClose={closeModal}
                customClasses={classes}
                showCloseIcon
            />
        </>
    );
};
