import React, { ChangeEvent, useCallback } from 'react';
import { StandardTextFieldProps, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useTextFilterStyles } from 'shared/components/filters/TextFilterStyles';

export interface ITextFilterProps extends StandardTextFieldProps {
    onFilterValueChanged: (newValue: string) => void;
}

export const TextFilter = ({ onFilterValueChanged, ...props }: ITextFilterProps) => {
    const classes = useTextFilterStyles();
    const onFilterChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        onFilterValueChanged(value);
    }, [onFilterValueChanged]);
    return (
        <TextField
            {...props}
            variant="outlined"
            className={classes.filter}
            inputProps={{
                placeholder: 'Search',
                style: { cursor: 'pointer' },
            }}
            onChange={onFilterChanged}
            InputProps={{
                endAdornment: <Search/>,
            }}
        />
    );
};
