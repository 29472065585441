import { groupBy, min, sortBy, uniq } from 'lodash-es';
import { selectDetailAssignmentId } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { selectEditSubassignmentId } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/selectors';
import { ISubassignmentRow } from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import { IAssignmentWithSubassignmentLinks } from 'modules/subassignmentManagement/store/models';
import { selectSubassignmentManagementState } from 'modules/subassignmentManagement/store/selectors';
import { createSelector } from 'reselect';
import { ITableGroup } from 'shared/components/table/GridTable/GridTableGroup';
import { ItemsById } from 'shared/models/ItemsById';
import { isAssignmentActive } from 'shared/utils/helpers/assingmentsHelper';
import { IStore } from 'store/configureStore';
import { selectAssignmentsById, selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import { ICustomField, ICustomFieldValue } from 'store/entities/customFields/model';
import {
    selectCustomFieldValuesByIds,
    selectFirstTwoRootCustomFields,
} from 'store/entities/customFields/selectors';
import { IInfinityScrollState } from 'store/reducerUtils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { Nullable } from 'types/types';

export const selectSubassignmentTableState = (state: IStore) =>
    selectSubassignmentManagementState(state).subassignmentTable;

function getTitlesForCustomFieldValues(
    customFieldValues: ICustomFieldValue[],
    fieldId: string,
): string {
    return customFieldValues
        .filter(value => value.custom_field_id === fieldId)
        .map(item => item.data)
        .filter(Boolean)
        .map(data => data.name)
        .filter(Boolean)
        .join(', ');
}

function getCustomFieldValuesIdList(
    customFieldValues: ICustomFieldValue[],
    field: ICustomField,
): string[] {
    return customFieldValues
        .filter(value => value.custom_field_id === field.id)
        .map(value => value.id);
}

export const getDisplayCustomFieldsValues = (
    displayCustomFields: ICustomField[],
    customFieldValuesByIds: ItemsById<ICustomFieldValue>,
    rowCustomFieldValueIds: Nullable<string>[],
    rowCustomFieldIdsWithAllChecked: string[] = [],
): Record<string, string> => {
    const customFieldValues: ICustomFieldValue[] = (
        (rowCustomFieldValueIds || [])
            .filter(Boolean)
            .map(valueId => customFieldValuesByIds[valueId as string])
            .filter(Boolean)
    );

    return displayCustomFields.reduce((memo: Record<string, string>, field: ICustomField) => ({
        ...memo,
        [field.id]: (
            rowCustomFieldIdsWithAllChecked.includes(field.id)
                ? 'All'
                : getTitlesForCustomFieldValues(customFieldValues, field.id)
        ),
    }), {});
};

export const getCustomFieldsDisplayValue = (
    anchorCustomFieldId: string | null | undefined,
    customFieldValuesByIds: ItemsById<ICustomFieldValue>,
    rowCustomFieldValueIds: Nullable<string>[],
    rowCustomFieldIdsWithAllChecked: string[] = [],
): string | null => {
    const customFieldValues: ICustomFieldValue[] = (
        (rowCustomFieldValueIds || [])
            .filter(Boolean)
            .map(valueId => customFieldValuesByIds[valueId as string])
            .filter(Boolean)
    );
    if (!anchorCustomFieldId) {
        return null;
    }
    return rowCustomFieldIdsWithAllChecked.includes(anchorCustomFieldId)
        ? 'All'
        : getTitlesForCustomFieldValues(customFieldValues, anchorCustomFieldId);
};

export const getCustomFieldsValueIds = (
    displayCustomFields: ICustomField[],
    customFieldValuesByIds: ItemsById<ICustomFieldValue>,
    rowCustomFieldValueIds: Nullable<string>[],
): Record<string, string[]> => {
    const customFieldValues: ICustomFieldValue[] = (
        (rowCustomFieldValueIds || [])
            .filter(Boolean)
            .map(valueId => customFieldValuesByIds[valueId as string])
            .filter(Boolean)
    );

    return displayCustomFields.reduce((memo: Record<string, string[]>, field: ICustomField) => ({
        ...memo,
        [field.id]: getCustomFieldValuesIdList(customFieldValues, field),
    }), {});
};

/**
 *  Subassignment table with pagination
 */
export const selectSubassignmentInfinityTableState = (
    state: IStore,
): IInfinityScrollState<IAssignmentWithSubassignmentLinks> => selectSubassignmentTableState(state).table;
export const subassignmentTableStateSelectors = getInfinityScrollSelectors(
    selectSubassignmentInfinityTableState,
    30,
    true,
);
export const selectSubassignmentCheckedItems = (state: IStore) =>
    selectSubassignmentTableState(state).checkedItems;
export const selectSubassignmentCheckedGroups = (state: IStore) =>
    selectSubassignmentTableState(state).checkedGroups;
export const selectCheckedSubassignmentIds = createSelector(
    selectSubassignmentCheckedItems,
    subassignmentCheckedIds => {
        return Object.entries(subassignmentCheckedIds)
            .filter(([, value]) => value)
            .map(([key]) => key);
    });
export const selectCheckedAssignmentIds = createSelector(
    selectSubassignmentCheckedItems,
    subassignmentTableStateSelectors.selectItems,
    selectSubassignmentCheckedGroups,
    (subassignmentCheckedIds, groupedSubassignments, assignmentsCheckedIds) => {
        const assignmentsIds = Object.entries(assignmentsCheckedIds).filter(([, value]) => value).map(([key]) => key);
        const assignmentIdsByCheckedSubassignments = groupedSubassignments
            .filter(group => group.subassignmentIds.some(id => subassignmentCheckedIds[id]))
            .map(group => group.assignment_id);
        return [...new Set([...assignmentsIds, ...assignmentIdsByCheckedSubassignments])];
    },
);

export const selectSubassignmentsGroupedRows = createSelector(
    subassignmentTableStateSelectors.selectItems,
    selectAssignmentsById,
    selectSubassignmentsByIds,
    (
        items,
        assignmentsByIds,
        subassignmentsByIds,
    ): ITableGroup<ISubassignmentRow>[] => {
        return items.map(group => {
            const assignment = assignmentsByIds[group.assignment_id];
            const isAssignmentAlreadyEnded = !isAssignmentActive(assignment);
            if (isAssignmentAlreadyEnded) {
                return {
                    id: group.assignment_id,
                    isGroup: true,
                    rows: [],
                };
            }
            const subassignments = sortBy(
                Object.values(subassignmentsByIds)
                    // @ts-ignore
                    .filter(subassignment => subassignment.assignment_id === group.assignment_id),
                'created_at',
            );
            return {
                id: group.assignment_id,
                isGroup: true,
                rows: subassignments.map((subassignment, rowIndex) => {
                    return {
                        // @ts-ignore
                        ...subassignment,
                        rowIndex,
                    };
                }),
            };
        });
    },
);

export const selectAssignmentsRows = createSelector(
    subassignmentTableStateSelectors.selectItems,
    selectFirstTwoRootCustomFields,
    selectCustomFieldValuesByIds,
    selectAssignmentsById,
    selectSubassignmentsByIds,
    (
        items,
        displayCustomFields,
        customFieldValuesByIds,
        assignmentsByIds,
    ): ISubassignmentRow[] => {
        return items.map(group => {
            return assignmentsByIds[group.assignment_id];
        });
    },
);

export const selectCheckedAssignments = createSelector(
    selectCheckedAssignmentIds,
    selectAssignmentsById,
    (ids, assignmentsByIds) => {
        return ids.map(id => assignmentsByIds[id]);
    },
);
export const selectCheckedSubassignments = createSelector(
    selectCheckedSubassignmentIds,
    selectSubassignmentsByIds,
    (ids, subassignmentsByIds) => {
        return ids.map(id => subassignmentsByIds[id]);
    },
);
export const selectCheckedSubassignmentsAssignments = createSelector(
    selectCheckedSubassignments,
    selectAssignmentsById,
    (subassignments, assignmentsByIds) => {
        const assignmentIds = uniq(subassignments.map(subassignment => subassignment.assignment_id));
        return assignmentIds.map(id => assignmentsByIds[id]).filter(Boolean);
    },
);
export const selectAssignmentIdsWithMultipleCheckedSubassignments = createSelector(
    selectCheckedSubassignments,
    selectedSubassignments => {
        return Object.entries(groupBy(
            selectedSubassignments, subassignment => subassignment?.assignment_id,
        )).filter(([, subassignments]) => subassignments.length > 1).map(([assignmentId]) => assignmentId);
    },
);

export const selectCheckedAssignmentsApprovalLevel = createSelector(
    selectCheckedAssignments,
    assignments => min(assignments.map(assignment => assignment.approval_levels)) || 1,
);

export const selectIsAssignmentManagementSidebarOpened = createSelector(
    selectDetailAssignmentId,
    selectEditSubassignmentId,
    (...args) => args.some(Boolean),
);

export const selectExpandedGroupId = createSelector(
    selectSubassignmentManagementState,
    function (state): string | null {
        return state.subassignmentTable.expandedGroupId;
    },
);

export const selectExpandAllSubassignment = createSelector(
    selectSubassignmentManagementState,
    function (state): boolean {
        return state.subassignmentTable.expandAllSubassignments;
    },
);

export const selectHighlightAssignmentsIds = createSelector(
    selectSubassignmentManagementState,
    function (state): string[] {
        return state.subassignmentTable.highlightAssignmentIds || [];
    },
);
