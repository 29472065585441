import React from 'react';
import { NavLink } from 'react-router-dom';
import { TMenuCategoryChild } from 'shared/models/Global';
import { CheckPermissionAndFeature } from 'shared/components/CheckPermissionAndFeature/CheckPermissionAndFeature';

interface IVerticalMenuChildItemProps {
    menuItem: TMenuCategoryChild;
    className?: string;
}

export const VerticalMenuChildItem = ({
    menuItem,
    className,
}: IVerticalMenuChildItemProps) => {
    return (
        <CheckPermissionAndFeature
            permission={menuItem.permission}
            feature={menuItem.feature}
        >
            <NavLink
                key={menuItem.to}
                // @ts-ignore
                to={menuItem.to}
                className={className}
            >
                {menuItem.title}
            </NavLink>
        </CheckPermissionAndFeature>
    );
};
