import React from 'react';

import { settingsRoutes } from 'modules/settings/routes';
import { Permission } from 'store/components/auth/authModels';
import { SubmodulesDashboard } from 'modules/settings/common/components/SubmodulesDashboard';
import { Box } from '@material-ui/core';

export const SettingsDashboard = () => {
    const items = [
        {
            title: 'Clients',
            description: 'Manage, add and configure client settings.',
            link: settingsRoutes.CLIENTS.ROOT,
            permission: Permission.modifyClientSettings,
        },
        {
            title: 'Platform users',
            description: 'Manage platform users.',
            link: settingsRoutes.PLATFORM_USERS.ROOT,
            permission: Permission.ManageManagerUsers,
        },
        {
            title: 'Employees',
            description: 'Manage employees.',
            link: settingsRoutes.EMPLOYEES.ROOT,
            permission: Permission.ManageEmployeeUsers,
        },
        {
            title: 'Offer Letter Templates',
            description: 'Manage and add offer letter templates.',
            link: settingsRoutes.OFFER_LETTERS.TEMPLATES,
            permission: Permission.ManageOfferLetterTemplates,
        },
        {
            title: 'Assignment Setup',
            description: 'Manage Custom Fields and Hierarchy.',
            link: settingsRoutes.ASSIGNMENT_SETUP.ROOT,
            permission: Permission.ManageCustomFields,
        },
    ];

    return (
        <Box m={3} width="100%">
            <SubmodulesDashboard items={items}/>
        </Box>
    );
};
