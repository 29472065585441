import { ISheetGroupResponsePayload } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/model';
import { EntryType } from 'shared/models/sheet/Sheet';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { SortingOrder } from 'shared/models/Order';

const INITIAL_LOAD_APPROVAL_SHEETS = 'sheetApproval/INITIAL_LOAD_APPROVAL_SHEETS';
export const initialLoadApprovalSheets = createSingleAction<EntryType, typeof INITIAL_LOAD_APPROVAL_SHEETS>(
    INITIAL_LOAD_APPROVAL_SHEETS,
);

export const loadMoreApprovalSheets = createRequestActions<void, ISheetGroupResponsePayload>(
    RequestType.Get,
    'MORE_APPROVAL_SHEETS',
    'sheetApproval',
);

const RESET_APPROVAL_SHEETS = 'sheetApproval/RESET_APPROVAL_SHEETS';
export const resetApprovalSheets = createSingleAction<EntryType, typeof RESET_APPROVAL_SHEETS>(
    RESET_APPROVAL_SHEETS,
);

const SET_SHEET_APPROVAL_TABLE_SROT = 'sheetApproval/SET_SHEET_APPROVAL_TABLE_SROT';
export const setSheetApprovalTableSort = createSingleAction<SortingOrder, typeof SET_SHEET_APPROVAL_TABLE_SROT>(
    SET_SHEET_APPROVAL_TABLE_SROT,
);
