import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLastFirstName } from 'shared/utils/converters/user';
import { selectEmployeeProfilesByIds } from 'modules/offerLetter/store/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { ISubassignmentBase } from 'store/entities/configuration/configurationModel';
import { useCounterStyles } from 'modules/subassignmentManagement/components/BulkOperations/SelectedCounterBox';
import { CreateSubAssignmentForm } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/CreateSubAssignmentFormV2';
import { getSubassignmentFromFormValues } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreateSubAssignmentForm/helpers';
import { selectCheckedAssignmentIds, selectCheckedAssignmentsApprovalLevel } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { selectUserById } from 'store/entities/users/selectors';

interface IBulkCreateWithDifferentValuesProps {
    onSubmit: (subassignments: ISubassignmentBase[]) => void,
}

export const BulkCreateWithDifferentValues = ({ onSubmit }: IBulkCreateWithDifferentValuesProps) => {
    const assignmentsIds = useSelector(selectCheckedAssignmentIds);
    const numberOfApprovers = useSelector(selectCheckedAssignmentsApprovalLevel);
    const clientId = useSelector(selectCurrentClientId);
    const classes = useCounterStyles();

    const [filled, setFilled] = useState<ISubassignmentBase[]>([]);
    const [currentAssignmentId, setCurrentAssignmentId] = useState('');

    const assignment = useSelector(selectAssignmentsById)[currentAssignmentId];
    const user = useSelector(selectUserById(assignment?.user_id));
    const employeeProfile = useSelector(selectEmployeeProfilesByIds)[assignment?.employee_profile?.id || ''];

    const nextAssignmentId = useMemo(() => {
        const filledAssignmentsIds = filled.map(item => item.assignment_id);
        return assignmentsIds.find(id => !filledAssignmentsIds.includes(id) && id !== currentAssignmentId);
    }, [filled, assignmentsIds, currentAssignmentId]);
    const addFilledSubassignment = useCallback(formValues => {
        const subassignment = getSubassignmentFromFormValues(currentAssignmentId, clientId, formValues);
        setFilled([
            ...filled,
            subassignment,
        ]);
        // @ts-ignore
        setCurrentAssignmentId(nextAssignmentId);
    }, [clientId, currentAssignmentId, filled, nextAssignmentId]);
    const handleSubmit = useCallback(formValues => {
        const subassignments = [
            ...filled,
            getSubassignmentFromFormValues(currentAssignmentId, clientId, formValues),
        ];
        onSubmit(subassignments);
    }, [filled, currentAssignmentId, clientId, onSubmit]);
    const onBack = useCallback(() => {
        const newFilled = [...filled];
        const lastItem = newFilled.pop();
        // @ts-ignore
        setCurrentAssignmentId(lastItem.assignment_id);
        setFilled(newFilled);
    }, [filled]);

    useEffect(() => {
        if (!currentAssignmentId && nextAssignmentId) {
            setCurrentAssignmentId(nextAssignmentId);
        }
    }, [currentAssignmentId, nextAssignmentId]);

    return (
        <Box>
            <Box mb={5} className={classes.counterBox}>
                <span className={classes.counter}>{filled.length + 1} / {assignmentsIds.length}</span>
                {' '}
                {user && getLastFirstName(user)}
                {' '}
                {employeeProfile?.prism_employee_id}
            </Box>
            <CreateSubAssignmentForm
                key={currentAssignmentId}
                onSubmit={nextAssignmentId ? addFilledSubassignment : handleSubmit}
                submitButtonTitle={nextAssignmentId ? 'Create & next employee' : 'Create & finish'}
                numberOfApprovers={numberOfApprovers}
                onBack={filled.length === 0 ? undefined : onBack}
            />
        </Box>
    );
};
