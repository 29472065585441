import React from 'react';
import { capitalize } from '@material-ui/core';
import PlainText from 'shared/components/table/Cells/PlainText';
import { IPayrollSheetGroupedRow, IPayrollSheetRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';

interface IPayPeriodInfoCellPureProps {
    className?: string;
    period_type: string;
}

const PayPeriodInfoCellPure = ({
    className,
    period_type,
}: IPayPeriodInfoCellPureProps) => {
    const value = period_type?.length ? period_type[0] : '';
    return (
        <PlainText
            title={capitalize(period_type)}
            className={className}
            value={capitalize(value)}
        />
    );
};

export const PayPeriodInfoCell = separateLogicDecorator<IPayrollSheetRow, IPayPeriodInfoCellPureProps>(
    ({ expense_period_type, time_period_type, ...props }) => {
        return {
            ...props,
            period_type: expense_period_type || time_period_type || '',
        };
    },
)(PayPeriodInfoCellPure);

export const PayPeriodInfoGroupedCell = separateLogicDecorator<IPayrollSheetGroupedRow, IPayPeriodInfoCellPureProps>(
    ({ periodType, ...props }) => {
        return {
            ...props,
            period_type: periodType,
        };
    },
)(PayPeriodInfoCellPure);
