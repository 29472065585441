import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTime12Hours } from './useTime12Hours';
import { TextField, TextFieldProps } from '@material-ui/core';
import { keyHandlerToPreventSubmitOnEnter } from 'shared/components/forms/utils';

const fixValue = (value?: unknown) => value ? `${value}` : '';

export function Time12HoursKeyboardInput({ onChange, value, onBlur, ...rest }: TextFieldProps) {
    const [localValue, setValue] = useState<string>(fixValue(value));
    const onChangeFunc = (changedValue: string) => {
        setValue(changedValue);
    };

    const { onChange: onChangeFromHook, value: valueFromHook } = useTime12Hours({
        value: localValue,
        onChange: (evt: ChangeEvent<HTMLInputElement>) => {
            onChangeFunc(evt.target.value);
        },
    });

    useEffect(() => {
        setValue(fixValue(value));
    }, [value]);

    return (
        <TextField
            {...rest}
            value={valueFromHook}
            onChange={onChangeFromHook}
            onBlur={onBlur}
            onKeyPress={keyHandlerToPreventSubmitOnEnter}
            inputProps={{ 'data-testid': 'time-12-hours-input' }}
        />
    );
}
