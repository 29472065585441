import { uniq } from 'lodash-es';
import { selectCalculationGroupApprovalsByIds } from 'modules/payrollProcessorHub/store/selectors';
import { useSelector } from 'react-redux';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { IPaidTimeOffResponse } from 'shared/models/User';
import { IClient } from 'store/entities/clients/clientsModel';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { IAssignment } from 'store/entities/configuration/configurationModel';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';
import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { selectDepartmentsById } from 'modules/employmentInfo/store/department/selectors';
import { selectAssignmentsById, selectPositionsById } from 'store/entities/configuration/configurationSelectors';
import { useUserPto } from 'store/entities/users/hooks';
import { selectUsersById } from 'store/entities/users/selectors';

import { getAllCalculationItems } from '../helpers/getAllCalculationItems';
import { findUniqFilteredItems } from '../helpers/findUniqFilteredItems';
import { selectSheetLogsById } from 'store/entities/timesheet/selectors';

export function useSheetGroupAdditionalDataBatch(groupCalculation: ISheetCalculationBatch) {
    const calculations: Array<ICalculation> = getAllCalculationItems(groupCalculation);

    const positionsById = useSelector(selectPositionsById);
    const positions: Array<IPosition> = (
        findUniqFilteredItems(calculations, 'position_id')
            // @ts-ignore
            .map(id => positionsById[id])
    );

    const assignmentsById = useSelector(selectAssignmentsById);
    const assignments: Array<IAssignment> = (
        findUniqFilteredItems(calculations, 'assignment_id')
            .map(id => assignmentsById[id])
    );

    const locationsById = useSelector(selectPositionsById);
    const locations: Array<ILocation> = (
        findUniqFilteredItems(assignments, 'location_id')
            // @ts-ignore
            .map(locationId => locationsById[locationId])
    );

    const departmentsById = useSelector(selectDepartmentsById);
    const departments: Array<IDepartment> = (
        findUniqFilteredItems(assignments, 'department_id')
            .map(departmentId => departmentsById[departmentId])
    );

    const clientId = groupCalculation?.client_id || '';
    const userId = groupCalculation?.user_id || '';

    const client: IClient = useSelector(selectAllClientsById)[clientId];
    const pto: IPaidTimeOffResponse | null = useUserPto(userId, clientId);

    const usersByIds = useSelector(selectUsersById);
    const approvalsByGroupIds = useSelector(selectCalculationGroupApprovalsByIds);
    const approvals = approvalsByGroupIds[groupCalculation.id] || [];

    const approvers = uniq(approvals.map(approve => approve.user_id))
        // @ts-ignore
        .map(approverId => usersByIds[approverId]).filter(Boolean);

    const sheetLogsBySheetId = useSelector(selectSheetLogsById);
    const sheetIds = [...new Set(
        calculations.map(calc => calc.sheet_id).filter(Boolean),
    )];
    const sheetLogs = sheetIds.map(id => sheetLogsBySheetId[id] || [])
        .flat()
        .filter(log => log.actorId !== userId);

    return {
        positions,
        locations,
        departments,
        client,
        pto,
        approvers,
        approvals,
        sheetLogs,
    };
}
