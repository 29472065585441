import { IClientUserAssociationRequest, IClientUserAssociationResponse } from 'modules/settings/submodules/platformUsers/store/models';
import {
    IClientRole,
    IClientRoleRequest,
    IGetPaidTimeOffRequest,
    IGetUsersResponse,
    IGlobalRole,
    IGlobalRoleBackend,
    IPaidTimeOffResponse,
    IUserInfo,
} from 'shared/models/User';
import baseApi from 'shared/utils/baseApi';
import {
    IClientUserAssociation,
    IEmailConfirmation,
    IGetUserRequest,
    INewPasswordAfterResetingRequest,
    INewPasswordAfterResetingResponse,
    INewPasswordCodeCheckingRequest,
    IPasswordResetingRequest,
    ISignupUserInfo,
    IUserAuthInfo,
    IUserSignupRequest,
} from 'store/entities/users/model';
import { normalizeRolePermissions } from 'store/entities/users/utils';

class UsersApi {
    async getUsers(request: IGetUserRequest): Promise<IGetUsersResponse> {
        const { data } = await baseApi.get<IGetUsersResponse>(
            `/client-users/users`,
            request,
        );
        return data;
    }
    async getCombinedUsers(request: IGetUserRequest): Promise<IGetUsersResponse> {
        const { data } = await baseApi.get<IGetUsersResponse>(
            `/client-users/offer_letters/search`,
            request,
        );
        return data;
    }
    async getUsersWithoutPagination(request: IGetUserRequest): Promise<IUserInfo[]> {
        // POST used for large requests
        const { data } = await baseApi.post<IGetUserRequest, IGetUsersResponse>(
            `/client-users/get_users`,
            request,
        );
        return data.users;
    }
    async registration(request: IUserSignupRequest): Promise<IUserInfo> {
        const { data } = await baseApi.create<IUserSignupRequest, IUserInfo>(
            'client-users', 'users/signup', request,
        );
        return data;
    }
    async getSignupUserInfoByToken(token: string): Promise<ISignupUserInfo> {
        const { data } = await baseApi.get<ISignupUserInfo>(
            `/client-users/signup_tokens/${token}`,
        );
        return data;
    }
    async emailConfirmation(request: IEmailConfirmation): Promise<string> {
        const { data } = await baseApi.create<IEmailConfirmation, { status: string }>(
            'client-users', 'email/confirmation', request,
        );
        return data.status;
    }
    async passwordResetting(request: IPasswordResetingRequest): Promise<string> {
        const { data } = await baseApi.create<IPasswordResetingRequest, { status: string }>(
            'client-users', 'password/reset', request,
        );
        return data.status;
    }
    async newPasswordAfterResetting(
        request: INewPasswordAfterResetingRequest,
    ): Promise<INewPasswordAfterResetingResponse> {
        const { data } = await baseApi.create<INewPasswordAfterResetingRequest, INewPasswordAfterResetingResponse>(
            'client-users', 'password/change', request,
        );
        return data;
    }
    async newPasswordCodeChecking(request: INewPasswordCodeCheckingRequest): Promise<string> {
        const { email, secret_code } = request;
        const { data } = await baseApi.head<string>(
            `client-users/password/reset/${email}/secret/${secret_code}`,
        );
        return data;
    }
    async getGlobalRoles(): Promise<IGlobalRole[]> {
        const { data } = await baseApi.get<{ global_roles: IGlobalRole[]}>(
            'client-users/global_roles',
        );
        return data.global_roles;
    }
    async getClientRoles(request: IClientRoleRequest): Promise<IClientRole[]> {
        const { data } = await baseApi.get<{ client_roles: IGlobalRoleBackend[]}>(
            'client-users/client_roles', request,
        );
        return data.client_roles.map(normalizeRolePermissions) as IClientRole[];
    }
    async getPaidTimeOff(request: IGetPaidTimeOffRequest): Promise<IPaidTimeOffResponse> {
        const { data } = await baseApi.get<IPaidTimeOffResponse>(
            `prismhr/clients/${request.client_id}/users/${request.user_id}/pto`,
        );
        return data;
    }
    async getClientUserRoles(request: IClientUserAssociationRequest): Promise<IClientUserAssociation[]> {
        const { data } = await baseApi.get<IClientUserAssociationResponse>(
            'client-users/client_user_roles', request,
        );
        return data.client_user_roles;
    }
    async deleteUser(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(`/client-users/users`, id);
        return data.status;
    }
    async getUserAuthStats(userId: string): Promise<IUserAuthInfo> {
        const { data } = await baseApi.get<IUserAuthInfo>(
            `client-users/users/${userId}/auth_stats`,
        );
        return data;
    }
}

export const usersApi = new UsersApi();
