import { DayPickerWithOuterLabel } from 'modules/settings/common/components/DayPickerWithOuterLabel';
import React from 'react';
import { Box } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import { ReasonForClose, ReasonForCloseDisplayValues } from 'shared/models/JobNumber';

export const CloseJobNumberFormSection = () => {
    const classes = useSettingsFormStyles();
    return (
        <Box className={classes.formBlockCard}>
            <Box m={-1}>
                <EnumFormSelect
                    name="reasonForClose"
                    values={ReasonForClose}
                    displayValues={ReasonForCloseDisplayValues}
                    label="--"
                    outerLabel="Reason for Close"
                />
                <Box ml={-2} mr={-2}>
                    <DayPickerWithOuterLabel
                        withKeyboard
                        name="closeOnDate"
                        outerLabel="Close on Date"
                        className={classes.input}
                    />
                </Box>
            </Box>
        </Box>
    );
};
