import ClientsSagas from 'modules/settings/submodules/clients/store/sagas';
import PlatformUsersSagas from 'modules/settings/submodules/platformUsers/store/sagas';
import OfferLetterTemplatesSagas from 'modules/settings/submodules/offerLetters/store/sagas';
import CustomFieldSagas from 'modules/settings/submodules/customFields/store/sagas';
import { customFieldsHierarchySagas } from 'modules/settings/submodules/components/HierarchyPage/store/sagas';
import { employeesSagas } from 'modules/settings/submodules/employees/store/sagas';

export default [
    ...ClientsSagas,
    ...PlatformUsersSagas,
    ...OfferLetterTemplatesSagas,
    ...CustomFieldSagas,
    ...customFieldsHierarchySagas,
    ...employeesSagas,
];
