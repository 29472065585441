import React from 'react';
import CheckboxField from 'shared/components/formFields/CheckboxField';
import { TimesheetSettings } from 'shared/models/JobNumber';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import NumberField from 'shared/components/formFields/NumberField';

interface IPayrollInfoFormSectionProps {
    timesheetSettings?: TimesheetSettings;
    isPerDiem: boolean;
    path: string;
}

export const PayrollInfoFormSection = ({
    isPerDiem,
    path,
    timesheetSettings = TimesheetSettings.Hourly,
}: IPayrollInfoFormSectionProps) => {
    const classes = useSettingsFormStyles();
    return (
        <Box className={classes.formBlockCard}>
            <Typography
                variant="subtitle2"
                className={classes.formBlockCardTitle}
            >
                Payroll Info:
            </Typography>
            <Box
                m={2}
                ml={0}
                fontSize={12}
            >
                <CheckboxField
                    name={`${path}IsPerDiem`}
                    className={classes.smallInput}
                >
                    Per diem (per day)
                </CheckboxField>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    {timesheetSettings === TimesheetSettings.Hourly ? (
                        <NumberField
                            name={`${path}HourlyRate`}
                            outerLabel="Hourly pay rate"
                            className={classes.smallInput}
                            inputProps={{
                                allowNegative: false,
                            }}
                            min={0}
                        />
                    ) : (
                        <NumberField
                            name={`${path}FileRate`}
                            outerLabel="Per file rate"
                            className={classes.smallInput}
                            inputProps={{
                                allowNegative: false,
                            }}
                            min={0}
                        />
                    )}
                </Grid>
                <Grid item xs={5}>
                    <NumberField
                        name={`${path}PerDiemRate`}
                        outerLabel="Per diem rate"
                        className={classes.smallInput}
                        disabled={!isPerDiem}
                        inputProps={{
                            allowNegative: false,
                        }}
                        min={0}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
