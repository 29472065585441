import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const useHeaderCreateButtonStyles = makeHighPriorityStyles((theme: Theme) => ({
    createButton: {
        width: theme.spacing(17),
        height: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(5.5),
            marginRight: theme.spacing(-2),
        },
    },
    createButtonLabel: {
        fontSize: 12,
    },
}));
