import baseApi from 'shared/utils/baseApi';
import { IControllingOrg, ICreateControllingOrgRequest } from 'shared/models/ControllingOrg';
import { controllingOrgEntity, gwClientExtensionsService } from 'store/entities/configuration/configurationApi';

export const api = {
    async createControllingOrg(controllingOrg: ICreateControllingOrgRequest): Promise<IControllingOrg> {
        const { data } = await baseApi.create<ICreateControllingOrgRequest, IControllingOrg>(
            gwClientExtensionsService,
            controllingOrgEntity,
            controllingOrg,
        );

        return data;
    },
};
