import { Box } from '@material-ui/core';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';
import { useEafFormStyles } from 'modules/subassignmentManagement/components/EafFormShared/useEafFormStyles';
import React from 'react';
import DayPickerField, { IDayPickerFieldProps } from 'shared/components/formFields/DayPickerField';

export interface IParticularDateFieldProps extends Partial<IDayPickerFieldProps> {
    label: string;
    name: string;
    disabled?: boolean;
}

export const ParticularDateField = ({ label, name, disabled, ...rest }: IParticularDateFieldProps) => {
    const classes = useEafFormStyles();
    const formClasses = sidebarFormStyles();

    return (
        <Box className={classes.row}>
            <label htmlFor={name} className={formClasses.inlineInputLabel}>
                {label}:
            </label>
            <Box width="150px">
                <DayPickerField
                    name={name}
                    disabled={disabled}
                    className={formClasses.input}
                    {...rest}
                />
            </Box>
        </Box>
    );
};
