import { createSelector } from 'reselect';
import { selectPayPeriods } from 'store/entities/timesheet/selectors';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

export const selectPayPeriodByEnding = createSelector(
    selectPayPeriods,
    clientPayPeriods => {
        return clientPayPeriods.reduce((acc, item) => {
            return ({
                ...acc,
                // @ts-ignore
                [item.period_end]: item,
            });
        }, {} as Record<string, IPayPeriod>);
    },
);
