import { useIsUserHasSheetApprovePermission } from 'modules/sheetApproval/store/hooks';
import React, { useCallback } from 'react';
import { IModalProps } from 'shared/components/modals/Modal';
import SheetApprovalContent from './SheetApprovalContent';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useDispatch } from 'react-redux';

interface ISheetApprovalModalProps extends Omit<IModalProps, 'title' | 'children'> {
    title: string;
    sheetType: EntryType;
    isOpened: boolean;
    onClose: () => void;
    sheets: Array<ISheet>;
    onAction?: () => void;
}

export default function SheetApproval({
    title, sheetType, isOpened, onClose, sheets, onAction, ...modalProps
}: ISheetApprovalModalProps) {
    const dispatch = useDispatch();

    const onSave = useCallback(() => {
        onClose();
        if (onAction) {
            onAction();
        }
        dispatch(updateSheetsStatus.init({
            statusName: StatusNames.APPROVED,
            sheetsEntryTypes: sheets.reduce((acc: Record<string, EntryType>, sheet) => ({
                ...acc,
                [sheet.id]: sheetType,
            }), {}),
        }));
    }, [onClose, sheetType, sheets, onAction, dispatch]);

    const hasPermission = useIsUserHasSheetApprovePermission();
    if (!hasPermission) {
        return null;
    }

    return (
        <ModalDialog title={title} isOpened={isOpened}
            onClose={onClose} modalProps={modalProps}
        >
            <SheetApprovalContent count={sheets.length} sheetType={sheetType}
                onClose={onClose} onConfirm={onSave}
            />
        </ModalDialog>
    );
}
