export const getPasswordStrength = (password: string): number => {
    const maxScore = 100;
    const bonusScore = 10;
    const uniqueSymbols = new Set(password.split(''));
    const score: Record<string, number> = {
        digits: /\d/.test(password) ? bonusScore : 0,
        lower: /[a-z]/.test(password) ? bonusScore : 0,
        upper: /[A-Z]/.test(password) ? bonusScore : 0,
        nonWords: /\W/.test(password) ? bonusScore : 0,
        length: password.length >= 8 ? bonusScore : 0,
        unique: uniqueSymbols.size * bonusScore / 2,
    };

    const resultScore = Object.values(score).reduce((result, item) => result + item, 0);

    return resultScore > maxScore ? maxScore : resultScore;
};
