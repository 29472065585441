/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { Status } from 'shared/models/Status';
import { formatBNumber } from 'shared/utils/formatters/bNumber';
import { useIsMobile } from 'shared/utils/hooks/media';
import {
    selectControllingOrgsIsLoading,
} from 'store/entities/configuration/configurationSelectors';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { EditControllingOrgButton } from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/EditControllingOrgButton';
import EditControllingOrgModal
    from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/EditControllingOrgModal';
import { UpdateControllingOrgStatusSelect } from 'modules/settings/submodules/clients/controllingOrg/components/UpdateStatusSelect/UpdateControllingOrgStatusSelect';
import { getControllingOrgs } from 'store/entities/configuration/configurationAction';
import { selectFilteredControllingOrgs } from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgTable/store/filteredControllingOrgsSelector';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';

interface IControllingOrgRow extends IControllingOrg {
    className?: string;
}

export const ControllingOrgTable = () => {
    useWithClientEffect(dispatch => dispatch(getControllingOrgs.init()));
    const tableClasses = useDefaultTableStyles();
    const controllingOrgs = useSelector(selectFilteredControllingOrgs);
    const isMobile = useIsMobile();
    const isLoading = useSelector(selectControllingOrgsIsLoading);

    const cells = [
        {
            key: 'name',
            title: 'name',
            render: ({ name, className }: IControllingOrgRow) => (
                <PlainText className={className} value={name}/>
            ),
        },
        {
            key: 'b_number',
            title: 'b number',
            render: ({ b_number, className }: IControllingOrgRow) => (
                <PlainText className={className} value={formatBNumber(b_number)}/>
            ),
        },
        {
            key: 'city',
            title: 'city',
            render: ({ className, city }: IControllingOrgRow) => (
                <PlainText className={className} value={city}/>
            ),
        },
        {
            key: 'state',
            title: 'state',
            render: ({ className, state }: IControllingOrgRow) => (
                <PlainText className={className} value={state}/>
            ),
        },
        {
            key: 'zip',
            title: 'zip',
            render: ({ className, zip }: IControllingOrgRow) => (
                <PlainText className={className} value={zip}/>
            ),
        },
        {
            key: 'status',
            title: 'status',
            render: function StatusCell({ id, is_active, className }: IControllingOrgRow) {
                const currentStatus = is_active ? Status.active : Status.inactive;
                return (
                    <Box className={className}>
                        <UpdateControllingOrgStatusSelect
                            id={id}
                            status={currentStatus}
                            className={tableClasses.statusSelect}
                        />
                    </Box>
                );
            },
        },
        {
            key: 'actions',
            title: '',
            width: '100px',
            render: function DetailsCell({ className, id }: IControllingOrgRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <EditControllingOrgButton controllingOrgId={id}/>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <GridTable
                rowData={controllingOrgs}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                getKey={client => client.id}
                cells={cells}
                stickyHeader
                hideHeader={isMobile}
                isLoading={isLoading}
            />
            <EditControllingOrgModal/>
        </>

    );
};
