import { IExpenseSheetCalculation, ISheetCalculationRequest, ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { createRequestActions, RequestType } from 'store/utils';

export const getTimesheetCalculations = createRequestActions<ISheetCalculationRequest, ITimesheetCalculation[]>(
    RequestType.Get,
    'TIMESHEET_CALCULATIONS',
    'sheets',
);

export const getExpenseSheetCalculations = createRequestActions<ISheetCalculationRequest, IExpenseSheetCalculation[]>(
    RequestType.Get,
    'EXPENSE_SHEET_CALCULATIONS',
    'sheets',
);
