import React from 'react';
import { AttachmentPreviewFile } from 'shared/components/attachments/AttachmentPreviewFile';
import { AttachmentPreviewImage } from 'shared/components/attachments/AttachmentPreviewImage';
import { AttachmentPreviewPdf } from 'shared/components/attachments/AttachmentPreviewPdf';
import { IAttachmentPreviewProps } from 'shared/components/attachments/model';

export const AttachmentGateway = ({
    attachment,
    className,
}: IAttachmentPreviewProps) => {
    if (attachment?.mimetype?.includes('image/')) {
        return (
            <AttachmentPreviewImage
                attachment={attachment}
                className={className}
            />
        );
    }
    if (attachment?.mimetype === 'application/pdf') {
        return (
            <AttachmentPreviewPdf
                attachment={attachment}
                className={className}
            />
        );
    }

    return (
        <AttachmentPreviewFile
            attachment={attachment}
            className={className}
        />
    );
};
