import { setEndAssignmentId } from 'modules/employeeActionsForm/components/EndAssignment/store/action';
import { EafActionsType, getAssignmentMaxEntryDate } from 'modules/employeeActionsForm/store/actions';
import { combineReducers } from 'redux';
import { singleValueReducer } from 'store/reducerUtils';

const detailId = singleValueReducer(setEndAssignmentId.action, null);
function maxEntryDateByAssignmentId(state = {}, action: EafActionsType): Record<string, string | null> {
    switch (action.type) {
        case getAssignmentMaxEntryDate.successType:
            return {
                ...state,
                // @ts-ignore
                [action.payload.assignmentId]: action.payload.max_entry_date,
            };
        default:
            return state;
    }
}

export const endAssignment = combineReducers({
    detailId,
    maxEntryDateByAssignmentId,
});
