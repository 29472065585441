import React from 'react';
import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { some } from 'lodash-es';
import {
    IBulkEditSubAssignmentForm,
    SubassignmentEditFormClasses,
} from '../../types';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';

interface IFooterSectionProps {
    classes: SubassignmentEditFormClasses;
    loading: boolean;
    submitButtonTitle: string;
    additionalButtons?: JSX.Element | undefined;
}

export const FooterSection = ({
    additionalButtons,
    classes,
    loading,
    submitButtonTitle,
}: IFooterSectionProps) => {
    const formikContext = useFormikContext<IBulkEditSubAssignmentForm>();
    const isDisabled = !some(Object.values(formikContext.values || {}));

    return (
        <Box mt={7}>
            <ButtonWithLoader
                type="submit"
                variant="contained"
                className={classes.button}
                color="secondary"
                disabled={isDisabled}
                isLoading={loading}
            >
                {submitButtonTitle}
            </ButtonWithLoader>

            {additionalButtons}
        </Box>
    );
};
