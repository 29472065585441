import React, { useCallback, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { ClientMultiselect } from 'modules/settings/common/components/ClientMultiselect';
import { useSettingsFormGridStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import {
    AccessLevel,
    accessLevelDisplayValues,
    IClientRoleFormValues,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleForm/model';
import {
    usePermissionFormStyles,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleForm/style';
import {
    getClientRolePayloadFromFormValues,
    getInitialValues,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleForm/utils';
import {
    clientRoleValidationSchema,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleForm/validationSchema';
import { IClientRoleManagePayload } from 'modules/settings/submodules/platformUsers/store/models';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import CheckboxField from 'shared/components/formFields/CheckboxField';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import FormField from 'shared/components/formFields/FormField';
import { IClientRole } from 'shared/models/User';

interface IClientRoleFormProps {
    onSubmit: (values: IClientRoleManagePayload) => void;
    isLoading: boolean;
    selectUser?: boolean;
    reportMetaData?: IClientRoleFormValues;
    clientRole?: IClientRole;
    submitTitle?: string;
    additionalActions?: React.ReactNode;
}

export const ClientRoleForm = ({
    onSubmit: onSubmitProps,
    isLoading,
    clientRole,
    additionalActions,
    submitTitle = 'Save',
}: IClientRoleFormProps) => {
    const classes = useSettingsFormGridStyles();
    const permissionClasses = usePermissionFormStyles();
    const displayClientRoleNotManagedPermission = false;

    const onSubmit = useCallback((values: IClientRoleFormValues) => {
        onSubmitProps(getClientRolePayloadFromFormValues(values));
    }, [onSubmitProps]);

    const formikData = useFormik<IClientRoleFormValues>({
        initialValues: getInitialValues(clientRole),
        validationSchema: clientRoleValidationSchema,
        onSubmit,
    });
    const { handleSubmit, values, setFieldValue } = formikData;
    const { permission } = values;
    const { approval } = permission;
    const editPermissionDisabled = [AccessLevel.NoAccess, AccessLevel.ReadOnly].includes(approval);

    useEffect(() => {
        if (editPermissionDisabled) {
            setFieldValue('permission.editSheetAsApprover', false);
        }
    }, [editPermissionDisabled, setFieldValue]);

    return (
        <FormikProvider value={formikData}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormField
                            name="name"
                            label="Role Name"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormField
                            name="description"
                            label="Description"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ClientMultiselect
                            name="clientIds"
                            allClientsFieldName="allClients"
                            outerLabel="Client"
                            className={classes.input}
                            useIdValue
                        />
                    </Grid>
                </Grid>

                <Box className={classes.formBlock} mt={2}>
                    <Typography variant="subtitle2" className={classes.formBlockTitle}>Set Permissions</Typography>
                    <Box m={2}>
                        <Box>
                            <strong>Read-only: </strong>
                            Viewing purposes only.
                            The user will only be able to view data from all users within the client.
                        </Box>
                        <Box mt={1}>
                            <strong>Create & View only their own: </strong>
                            The user can view, add, create, approve and delete.
                            They can only see their own data/assignments.
                        </Box>
                        <Box mt={1}>
                            <strong>Create & View all user data: </strong>
                            The user can view, add, create, approve and delete.
                            They can also view other user{'\''}s data within the client.
                        </Box>
                    </Box>

                    <Box className={permissionClasses.permissionContainer}>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Time & Expense Approvals
                            </Box>
                            <Box
                                display="flex"
                                flexGrow={1}
                                alignItems="center"
                            >
                                <EnumFormSelect
                                    name="permission.approval"
                                    values={AccessLevel}
                                    className={permissionClasses.permissionSelect}
                                    displayValues={accessLevelDisplayValues}
                                />
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    ml={2}
                                >
                                    <CheckboxField
                                        disabled={isLoading || editPermissionDisabled}
                                        className={permissionClasses.permissionCheckbox}
                                        name="permission.editSheetAsApprover"
                                        label="Ability to edit time & expense sheets"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Offer Letters
                            </Box>
                            <EnumFormSelect
                                name="permission.offerLetters"
                                values={AccessLevel}
                                className={permissionClasses.permissionSelect}
                                displayValues={accessLevelDisplayValues}
                            />
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Approve offer letter pay rate
                            </Box>
                            <CheckboxField
                                disabled={isLoading}
                                name="permission.approvePayRate"
                            />
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Offer letter pay rates automatically approved
                            </Box>
                            <CheckboxField
                                disabled={isLoading}
                                name="permission.payRateAutoApprove"
                            />
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Assignment Management/EAFs
                            </Box>
                            <CheckboxField
                                disabled={isLoading}
                                name="permission.assignmentManagement"
                            />
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Assignment Management Scope required
                            </Box>
                            <CheckboxField
                                disabled={isLoading}
                                name="permission.assignmentManagementScopeRequired"
                            />
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Employee Impersonation
                            </Box>
                            <CheckboxField
                                disabled={isLoading}
                                name="permission.employeeImpersonation"
                            />
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Manager Impersonation
                            </Box>
                            <CheckboxField
                                disabled={isLoading}
                                name="permission.managerImpersonation"
                            />
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                View Reports
                            </Box>
                            <CheckboxField
                                disabled={isLoading}
                                name="permission.viewReports"
                            />
                        </Box>
                        <Box className={permissionClasses.permissionRow}>
                            <Box className={permissionClasses.permissionLabel}>
                                Travel Expenses
                            </Box>
                            <CheckboxField
                                disabled={isLoading}
                                name="permission.manageTravelExpenses"
                            />
                        </Box>
                        {displayClientRoleNotManagedPermission && permission.notManaged.length > 0 && (
                            <Box className={permissionClasses.permissionRow}>
                                <Box className={permissionClasses.permissionLabel}>
                                    Not managed permission
                                </Box>
                                {permission.notManaged.join(', ')}
                            </Box>
                        )}

                    </Box>
                </Box>

                <Box display="flex">
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        isLoading={isLoading}
                        data-testid="submit_button"
                    >
                        {submitTitle}
                    </ButtonWithLoader>
                    {additionalActions && (
                        <Box mt={4}>
                            {additionalActions}
                        </Box>
                    )}
                </Box>
            </form>
        </FormikProvider>
    );
};
