import {
    IEafApprovalFilter,
    IEafApprovalItemsRequest,
    IEafApprovalItemsResponse,
    IPatchEmployeeActionFormRequest,
    IPatchEmployeeActionFormsResponse,
} from 'modules/eafApproval/store/model/types';
import { EafStatusSlug } from 'modules/employeeActionsForm/store/models';
import { SortingOrder } from 'shared/models/Order';
import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';

const PREFIX = 'EAF_approval';

export const setEafApprovalActiveTab = createSingleAction<EafStatusSlug>(`${PREFIX}/SET_EAF_APPROVAL_ACTIVE_TAB`);

export const setEafApprovalFilter = createSingleAction<Partial<IEafApprovalFilter>>(
    `${PREFIX}/SET_EAF_APPROVAL_FILTER`,
);

export const setEafApprovalSortOrder = createSingleAction<SortingOrder>(
    `${PREFIX}/SET_EAF_APPROVAL_ORDER`,
);

export const setEafApprovalCheckedItemsIds = createSingleAction<string[]>(
    `${PREFIX}/SET_EAF_APPROVAL_CHECKED_ITEMS_IDS`,
);

export const toggleEafApprovalCheckedItemId = createSingleAction<string>(
    `${PREFIX}/TOGGLE_EAF_APPROVAL_CHECKED_ITEM_ID`,
);

export const initialLoadEafApprovalItems = createSingleAction<void>(
    `${PREFIX}/INIT_LOAD_EAF_APPROVAL_ITEMS`,
);

export const loadMoreEafApproval = createRequestActions<IEafApprovalItemsRequest | void, IEafApprovalItemsResponse>(
    RequestType.Get,
    'LOAD_MORE_EAF_APPROVAL',
    PREFIX,
);

export const resetEafApprovalItems = createSingleAction<void>(
    `${PREFIX}/RESET_EAF_APPROVAL_ITEMS`,
);

export const setEafApproveIds = createSingleAction<string[]>(
    `${PREFIX}/SET_EAF_APPROVE_IDS`,
);

export const setEafRejectionIds = createSingleAction<string[]>(
    `${PREFIX}/SET_EAF_REJECT_IDS`,
);

export const setEafDetailId = createSingleAction<string | null>(
    `${PREFIX}/SET_EAF_DETAIL_ID`,
);
export const getEafDetails = createRequestActions<void, void>(
    RequestType.Get,
    'EAF_DETAILS',
    PREFIX,
);

export const patchEafItemsRequest = (
    createRequestActions<IPatchEmployeeActionFormRequest, IPatchEmployeeActionFormsResponse>(
        RequestType.PATCH,
        'EAF_APPROVAL_ITEMS_STATUS_CHANGE',
        PREFIX,
    )
);

export const approveEaf = (
    createRequestActions<string[], void>(
        RequestType.PATCH,
        'EAF_APPROVE',
        PREFIX,
    )
);

export const rejectEaf = (
    createRequestActions<IPatchEmployeeActionFormRequest, void>(
        RequestType.PATCH,
        'EAF_REJECT',
        PREFIX,
    )
);

export const setPendingEafCount = createSingleAction<number>(
    `${PREFIX}/SET_PENDING_EAF_COUNT`,
);

export type EmployeeActionFormTableActions =
    | ReturnType<typeof setEafApprovalActiveTab>
    | ReturnType<typeof setEafApprovalFilter>
    | ReturnType<typeof initialLoadEafApprovalItems>
    | ActionsReturnTypes<typeof loadMoreEafApproval>
    | ReturnType<typeof resetEafApprovalItems>
    | ActionsReturnTypes<typeof patchEafItemsRequest>
