import * as yup from 'yup';
import { CustomizedValidationMessages, CustomValidationMessages, ValidationMessages } from 'shared/models/Validation';
import { QuantityType } from 'shared/models/sheet/Sheet';

export const getActivityValidationsSchema = (activityLength: number, hasActivityLengthLimit: boolean) => (
    yup.object().shape({
        type: yup.string().nullable().required(ValidationMessages.REQUIRED),
        dataType: yup.string().nullable().required(ValidationMessages.REQUIRED),
        payCode: yup.object().nullable().when('dataType', (dataType: QuantityType | undefined) => {
            if (dataType !== QuantityType.TIME_BREAK) {
                return yup.object().nullable().required(ValidationMessages.REQUIRED);
            }
        }),
        activity: yup.string().max(
            hasActivityLengthLimit ? activityLength : 100,
            CustomizedValidationMessages[CustomValidationMessages.MAX_LENGTH](
                hasActivityLengthLimit ? activityLength : 100,
            ),
        ).required(ValidationMessages.REQUIRED),
        shortDescription: yup.string().max(
            activityLength,
            CustomizedValidationMessages[CustomValidationMessages.MAX_LENGTH](activityLength),
        ),
    })
);
