import React, { useCallback } from 'react';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { ValidationMessages } from 'shared/models/Validation';
import * as yup from 'yup';
import { Box, Button, Typography } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

interface ISelectClientWithConfirmationProps {
    open: boolean;
    onClose: () => void;
    modalTitle: string;
    confirmationTitle: string;
    confirmationText: string;
    onSubmit: (clientId: string) => void;
    allowedClientIds?: string[];
}

interface ISelectClientFormValues {
    clientId: string | null;
}

const validationSchema = yup.object({
    clientId: yup.string().nullable().required(ValidationMessages.REQUIRED),
});

const initialValues = {
    clientId: null,
};

export const SelectClientWithConfirmation = ({
    open,
    onClose,
    modalTitle,
    confirmationTitle,
    confirmationText,
    onSubmit,
    allowedClientIds,
}: ISelectClientWithConfirmationProps) => {
    const classes = useColoredFormModalStyles();
    const formClasses = useSettingsFormStyles();

    const handleSubmit = useCallback(({ clientId }) => {
        onSubmit(clientId);
    }, [onSubmit]);

    const formikData = useFormik<ISelectClientFormValues>({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
        validateOnBlur: false,
    });

    return (
        <ModalDialog
            title={modalTitle}
            isOpened={open}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box maxWidth="750px">
                <Typography variant="subtitle2" gutterBottom>{confirmationTitle}</Typography>
                <Typography variant="body1">{confirmationText}</Typography>
                <FormikProvider value={formikData}>
                    <form onSubmit={formikData.handleSubmit}>
                        <Box maxWidth="50%" ml={-2}>
                            <ClientSelect
                                name="clientId"
                                label="Client"
                                useIdValue
                                className={formClasses.input}
                                allowedIds={allowedClientIds}
                                autoSelectSingleVariant
                            />
                        </Box>

                        <Box mt={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className={formClasses.button}
                                data-testid="submit_button"
                            >
                                Confirm
                            </Button>
                        </Box>
                    </form>
                </FormikProvider>
            </Box>
        </ModalDialog>
    );
};
