import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import {
    updateCustomFieldHierarchy,
} from 'modules/settings/submodules/components/HierarchyPage/store/actions';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { selectCurrentClientHasAssignments } from 'store/entities/clients/selectors/clientsSelectors';
import { selectIsCustomFieldsHierarchySaving } from 'store/entities/customFields/selectors';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { isClientHasCustomFieldValues } from 'modules/settings/submodules/components/HierarchyPage/store/selectors';
import { findDescendants, findParent } from 'react-sortly';
import { HierarchyContext } from '../HierarchyListsContext';

export const SaveHierarchyButton = () => {
    const dispatch = useDispatch();
    const { hierarchyList } = useContext(HierarchyContext);
    const clientHasAssignments = useSelector(selectCurrentClientHasAssignments);
    const clientHasCustomFieldValues = useSelector(isClientHasCustomFieldValues);
    const useConfirmation = clientHasCustomFieldValues && !clientHasAssignments;
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

    const openConfirmation = useCallback(() => {
        setIsOpenConfirmation(true);
    }, [setIsOpenConfirmation]);
    const onCancelConfirmation = useCallback(() => {
        setIsOpenConfirmation(false);
    }, [setIsOpenConfirmation]);

    const onSaveHandler = useCallback(() => {
        const nodes = hierarchyList.map((row, rowIndex) => {
            const parent = findParent(hierarchyList, rowIndex);
            const siblingOrder = (() => {
                if (parent) {
                    const parentIndex = hierarchyList.findIndex(item => item.id === parent.id);
                    const siblings = findDescendants(hierarchyList, parentIndex);
                    return siblings.findIndex(sibling => sibling.id === row.id);
                }
                return hierarchyList.filter(item => item.depth === 0).findIndex(item => item.id === row.id);
            })();
            return ({
                ...row.hierarchyNode,
                parent_id: parent?.hierarchyNode?.id || null,
                siblings_order: siblingOrder + 1,
                anchor: Boolean(row.hierarchyNode.anchor),
            });
        });
        dispatch(updateCustomFieldHierarchy.init(nodes));
        setIsOpenConfirmation(false);
    }, [dispatch, setIsOpenConfirmation, hierarchyList]);
    const isCustomFieldsHierarchySaving = useSelector(selectIsCustomFieldsHierarchySaving);
    return (
        <Box margin={2}>
            <ButtonWithLoader
                variant="contained"
                color="secondary"
                isLoading={isCustomFieldsHierarchySaving}
                onClick={useConfirmation ? openConfirmation : onSaveHandler}
            >
                Save
            </ButtonWithLoader>
            <ConfirmationDialog
                open={isOpenConfirmation}
                onConfirm={onSaveHandler}
                onCancel={onCancelConfirmation}
            >
                Changes in the hierarchy can lead to changes in the binding of custom field values.
                Please check the custom field values after saving the hierarchy
            </ConfirmationDialog>
        </Box>
    );
};
