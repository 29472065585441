import { useSheetEntriesCustomFieldsWithValues } from 'shared/utils/hooks/customFieldsWithValuesHooks';
import { IExpenseSheetCalculation, ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { EntryType } from 'shared/models/sheet/Sheet';
import {
    ICalculationEntry,
    IGroupedSheetCalculation,
    ISheetCalculationBatch,
} from 'modules/payrollProcessorHub/store/model';

export const useSheetGroupCustomFieldsWithValues = (sheetGroup: IGroupedSheetCalculation, type: EntryType) => {
    const sheetEntries = type === EntryType.TIME ? sheetGroup.time_entries : sheetGroup.expense_entries;
    return useSheetEntriesCustomFieldsWithValues(sheetEntries, sheetGroup.client_id);
};

type Calculation = ITimesheetCalculation | IExpenseSheetCalculation;

export const useCalcGroupCustomFieldsWithValueBatch = (calcGroup: ISheetCalculationBatch, type: EntryType) => {
    const calculations: Array<Calculation> = (
        type === EntryType.TIME
            ? calcGroup.time_calculations
            : calcGroup.expense_calculations
    );

    const entries: Array<ICalculationEntry> = (
        calculations
            .flatMap((calculation: Calculation) => {
                const calculationEntries: Array<ICalculationEntry> = calculation.entries;
                return calculationEntries;
            })
    );

    return useSheetEntriesCustomFieldsWithValues(entries, calcGroup.client_id);
};
