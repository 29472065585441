import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import {
    usePayrollSheetGroupApprovedBy,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/useSheetGroupApprovers';
import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';

type ApprovedByCellProps = ICellProps<{
    approvedBy: IUserInfo[];
}>

export const ApprovedByCell = ({ className, approvedBy }: ApprovedByCellProps) => {
    const approversNameList = approvedBy.map(approver => getUserName(approver));
    const value = approversNameList.join(', ');
    return (
        <PlainText
            className={className}
            value={value}
        />
    );
};

export const GroupedApprovedByCell = ({ className, ...row }: ICellProps<IPayrollSheetGroupedRow>) => {
    const approvedBy = usePayrollSheetGroupApprovedBy(row);
    return (
        <ApprovedByCell
            className={className}
            approvedBy={approvedBy}
        />
    );
};
