import { DropLocation } from 'modules/settings/submodules/components/HierarchyPage/store/models';
import React, { useCallback, useContext } from 'react';
import Sortly, {
    add,
    DragObject,
    ItemData,
    remove,
} from 'react-sortly';
import { HierarchyRow } from './HierarchyRow';
import { IGridTableRowsProps } from 'shared/components/table/GridTable/GridTableRows';
import { ICustomFieldHierarchyRow } from 'modules/settings/submodules/customFields/store/models';
import { useDrop } from 'react-dnd';
import { HierarchyContext } from '../../HierarchyListsContext';
import { useHierarchyTableStyles } from '../styles';

interface IHierarchyTableSortableBodyProps extends IGridTableRowsProps<ICustomFieldHierarchyRow> {
}

export const HierarchyTableSortableBody = ({ getKey, ...rowProps }:IHierarchyTableSortableBodyProps) => {
    const { hierarchyList, availableList, setHierarchyList, setAvailableList } = useContext(HierarchyContext);

    const [{ hovered, dragItem }, drop] = useDrop({
        accept: DropLocation.HierarchyTableLocation,
        collect: monitor => ({
            hovered: monitor.isOver(),
            dragItem: monitor.getItem(),
        }),
    });

    const handleChange = useCallback((items: ItemData<ICustomFieldHierarchyRow>[]) => {
        setHierarchyList(items);
    }, [setHierarchyList]);

    const handleEnter = useCallback((dragItemInHierarchy: DragObject) => {
        if (hierarchyList.some(hierarchyItem => hierarchyItem.id === dragItemInHierarchy.id)) {
            return;
        }
        const draggedItemIndex = availableList.findIndex(item => item.id === dragItemInHierarchy.id);
        const draggedItem = availableList[draggedItemIndex];
        if (draggedItem) {
            setAvailableList(remove(availableList, draggedItemIndex));
            setHierarchyList(add(hierarchyList, [draggedItem]));
        }
    }, [hierarchyList, availableList, setAvailableList, setHierarchyList]);

    const handleMove = React.useCallback(() => {
        if (!dragItem) {
            return;
        }
        if (hovered) {
            handleEnter(dragItem);
        }
    }, [dragItem, hovered, handleEnter]);

    React.useEffect(() => {
        if (dragItem) {
            handleMove();
        }
    }, [dragItem, hovered, handleMove]);

    const classes = useHierarchyTableStyles();
    return (
        <div ref={drop} className={classes.bodyContainer}>
            <Sortly
                items={hierarchyList}
                onChange={handleChange}
                type={DropLocation.HierarchyTableLocation}
            >
                {props => (
                    <HierarchyRow
                        {...props}
                        row={props.data}
                        key={getKey(props.data)}
                        {...rowProps}
                        bodyCellClassName={props.index === hierarchyList.length - 1 ? classes.noBorder : ''}
                    />
                )}
            </Sortly>
        </div>
    );
};
