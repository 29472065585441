import { Box } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPayrollProcessorFilters } from 'modules/payrollProcessorHub/store/model';
import { Formik } from 'formik';
import { TravelExpenseFilterForm } from 'modules/travelExpenses/components/TravelExpenseFilter/TravelExpenseFilterForm';
import { setTravelExpenseFilter } from 'modules/travelExpenses/components/TravelExpenseFilter/action';
import { selectTravelExpenseFilter } from 'modules/travelExpenses/components/TravelExpenseFilter/selectors';
import { getActivities, getDealTypes } from 'store/entities/configuration/configurationAction';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';

export const TravelExpenseFilter = () => {
    const dispatch = useDispatch();
    const initialValues = useSelector(selectTravelExpenseFilter);
    useWithClientEffect(dispatchWithClient => {
        dispatchWithClient(getDealTypes.init());
        dispatchWithClient(getActivities.init());
    }, []);
    const onChange = useCallback((values: Partial<IPayrollProcessorFilters>) => {
        dispatch(setTravelExpenseFilter(values));
    }, [dispatch]);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onSubmitHandler = useCallback(() => {
    }, []);
    return (
        <Box>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmitHandler}
                validateOnBlur={false}
            >
                {props => (
                    <TravelExpenseFilterForm
                        {...props}
                        onChange={onChange}
                        actualFilterValues={initialValues}
                    />
                )}
            </Formik>
        </Box>
    );
};
