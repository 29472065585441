import {
    downloadInvoicedCcpWatcher,
    downloadReconciledCcpWatcher,
    downloadUnreconciledCcpWatcher,
    filterChangeCcpTransactionsSagaWatcher,
    getMoreCcpInvoicesSagaWatcher,
    getMoreCcpTransactionsSagaWatcher, sendReminderSagaWatcher, submitInvoiceWithCsvDownloadWatcher,
    switchTabCcpTransactionsSagaWatcher,
} from '../components/CcpTransactionsTable/store/sagas';
import {
    uploadCcpTransactionsImportWatcher,
    uploadCcpTransactionsPreCreateReportWatcher,
} from '../components/CcpCreate/store/sagas';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { getTransactionCodes } from './actions';
import { ccpApi } from '../components/api/ccpApi';
import {
    addNewCcpEntryAttachmentWatcher,
    deleteCcpAttachmentWatcher,
    changeTransactionsWatcher,
    submitUnreconciledItemsWatcher,
    triggerEditModeSaveWatcher,
} from '../components/CcpEdit/store/sagas';
import { splitTransactionsWatcher } from '../components/CcpSplit/store/sagas';

const getTransactionCodesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getTransactionCodes,
    ccpApi.getTransactionCodes,
    'transaction codes',
);

export const ccpSagas = [
    getMoreCcpTransactionsSagaWatcher,
    switchTabCcpTransactionsSagaWatcher,
    filterChangeCcpTransactionsSagaWatcher,
    downloadReconciledCcpWatcher,
    downloadUnreconciledCcpWatcher,
    downloadInvoicedCcpWatcher,
    submitInvoiceWithCsvDownloadWatcher,
    getMoreCcpInvoicesSagaWatcher,
    changeTransactionsWatcher,
    addNewCcpEntryAttachmentWatcher,
    submitUnreconciledItemsWatcher,
    splitTransactionsWatcher,
    deleteCcpAttachmentWatcher,
    uploadCcpTransactionsPreCreateReportWatcher,
    uploadCcpTransactionsImportWatcher,
    getTransactionCodesWatcher,
    sendReminderSagaWatcher,
    triggerEditModeSaveWatcher,
];
