import { ClassValue } from 'clsx';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useCustomFieldValueCells } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuesTable/cells';
import { selectCustomFieldType, selectIsLoadingCustomFieldValues } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { ISettingsTablePureProps, SettingsTablePure } from 'modules/settings/common/components/SettingsTablePure/SettingsTablePure';
import { selectCustomFieldValueRows, selectCustomFieldValueShouldHaveParent } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuesTable/selectors';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { CustomFieldValueRowType } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuesTable/model';

export const CustomFieldValuesTable = (
    separateLogicDecorator<object, ISettingsTablePureProps<CustomFieldValueRowType>>(() => {
        const tableClasses = useDefaultTableStyles();
        const fieldType = useSelector(selectCustomFieldType);
        const shouldHaveParent = useSelector(selectCustomFieldValueShouldHaveParent);

        const getRowClasses = useCallback((row: CustomFieldValueRowType): ClassValue[] => {
            return [{
                [tableClasses.warningRow]: Boolean(!row.parentValueIds?.length && shouldHaveParent),
            }];
        }, [tableClasses, shouldHaveParent]);

        return {
            cells: useCustomFieldValueCells(fieldType),
            isLoading: useSelector(selectIsLoadingCustomFieldValues),
            rows: useSelector(selectCustomFieldValueRows),
            getRowClasses,
        };
    })(SettingsTablePure)
);
