import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { OpenModalButton } from 'modules/settings/common/components/OpenModalButton/OpenModalButton';
import { setCreateClientRoleModalState } from 'modules/settings/submodules/platformUsers/store/actions';
import { useDispatch } from 'react-redux';
import MainContent from 'shared/components/common/MainContent';
import { loadClientRoles } from 'store/entities/users/actions';
import { CreateUserModal } from './components/CreateClientRole/CreateUserModal';
import { EditClientRoleModal } from './components/EditClientRole/EditClientRole';
import { ClientRolesTable } from './components/ClientRoleTable/ClientRolesTable';
import { ClientRolesFilter } from './components/ClientRolesFilter';

export const ClientRolesAndPermissionsPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadClientRoles.init());
    }, [dispatch]);

    return (
        <MainContent whiteBackground>
            <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <OpenModalButton openAction={setCreateClientRoleModalState}/>
                <ClientRolesFilter/>
            </Box>
            <ClientRolesTable/>
            <CreateUserModal/>
            <EditClientRoleModal/>
        </MainContent>
    );
};
