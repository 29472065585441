import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import { createSingleAction } from 'store/utils';

const SET_OPEN_BULK_EDIT_SUBASSIGNMENTS_MODAL = 'employment_management/SET_OPEN_BULK_EDIT_SUBASSIGNMENTS_MODAL';
export const setOpenBulkEditSubassignmentModal = createSingleAction<
boolean,
typeof SET_OPEN_BULK_EDIT_SUBASSIGNMENTS_MODAL
>(
    SET_OPEN_BULK_EDIT_SUBASSIGNMENTS_MODAL,
);

const SET_BULK_EDIT_SUBASSIGNMENT_STEP = 'employment_management/SET_BULK_EDIT_SUBASSIGNMENT_STEP';
export const setBulkEditSubassignmentStep = createSingleAction<
BulkEditSubassignmentSteps,
    typeof SET_BULK_EDIT_SUBASSIGNMENT_STEP
>(
    SET_BULK_EDIT_SUBASSIGNMENT_STEP,
);

const SET_EDIT_SUBASSIGNMENT_ID = 'employment_management/SET_EDIT_SUBASSIGNMENT_ID';
export const setEditSubassignmentId = createSingleAction<
string | null,
typeof SET_EDIT_SUBASSIGNMENT_ID
>(
    SET_EDIT_SUBASSIGNMENT_ID,
);
