import {
    EmployeeActionFormTableActions,
    getEafDetails, patchEafItemsRequest,
    resetEafApprovalItems,
    setEafApprovalActiveTab,
    setEafApprovalCheckedItemsIds,
    setEafApprovalFilter, setEafApprovalSortOrder,
    setEafApproveIds,
    setEafDetailId,
    setEafRejectionIds,
    setPendingEafCount,
    toggleEafApprovalCheckedItemId,
} from 'modules/eafApproval/store/actions';
import { EAF_APPROVAL_DEFAULT_TAB_STATUS } from 'modules/eafApproval/store/model/data';
import { IEafApprovalFilter } from 'modules/eafApproval/store/model/types';
import { table } from 'modules/eafApproval/store/tableReducers';
import { EafType } from 'modules/employeeActionsForm/store/models';
import { combineReducers } from 'redux';
import { SortingOrder } from 'shared/models/Order';
import { isLoadingReducer, resetStateReducer, singleValueReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const eafApprovalActiveTab = extendReducer(
    singleValueReducer(setEafApprovalActiveTab.action, EAF_APPROVAL_DEFAULT_TAB_STATUS),
    resetStateReducer(resetEafApprovalItems.action, EAF_APPROVAL_DEFAULT_TAB_STATUS),
);

function toggleCheckedItemIdReducer(
    state: string[] = [],
    action: ReturnType<typeof toggleEafApprovalCheckedItemId>,
): string[] {
    if (action.type === toggleEafApprovalCheckedItemId.action) {
        const id = action.payload;
        if (state.includes(id)) {
            return state.filter(itemId => itemId !== id);
        }
        return [...state, id];
    }
    return state;
}

const eafApprovalCheckedItemsIds = extendReducer(
    singleValueReducer<string[]>(setEafApprovalCheckedItemsIds.action, []),
    toggleCheckedItemIdReducer,
);

// @ts-ignore
const defaultFilterState: IEafApprovalFilter = {
    type: null,
    employeeUserId: null,
    clientId: null,
    defaultTypes: [EafType.Termination],
};
const filter = extendReducer(
    singleValueReducer<IEafApprovalFilter>(setEafApprovalFilter.action, defaultFilterState),
    resetStateReducer<IEafApprovalFilter>(resetEafApprovalItems.action, defaultFilterState),
);

const order = extendReducer(
    singleValueReducer<SortingOrder>(setEafApprovalSortOrder.action, {}),
    resetStateReducer<SortingOrder>(resetEafApprovalItems.action, {}),
);

const approveIds = singleValueReducer(setEafApproveIds.action, []);
const rejectionIds = singleValueReducer(setEafRejectionIds.action, []);
const detailEafId = singleValueReducer(setEafDetailId.action, null);
const isLoadingDetails = isLoadingReducer(getEafDetails);

const pendingCount = extendReducer(
    singleValueReducer(setPendingEafCount.action, 0),
    (
        state = 0,
        action: EmployeeActionFormTableActions,
    ) => {
        switch (action.type) {
            case patchEafItemsRequest.successType:
                // @ts-ignore
                return state - action.payload.employee_action_forms.length;
            default:
                return state;
        }
    },
    resetStateReducer(resetEafApprovalItems.action, 0),
);

export const EafApprovalReducer = combineReducers({
    eafApprovalActiveTab,
    eafApprovalCheckedItemsIds,
    table,
    filter,
    order,
    approveIds,
    rejectionIds,
    detailEafId,
    isLoadingDetails,
    pendingCount,
});
