import { padStart } from 'lodash-es';
import moment, { Moment } from 'moment';
import { backendDateFormat, DateBackend } from 'shared/models/Dates';
import {
    IInOutBreakDataBackend,
    IInOutMealBreakDataBackend,
    InOutEntryData,
    ITimeEntryData,
    QuantityType,
    TimeEntryDataBackend,
    TimeHoursMinutes,
    TimeRFC3339,
} from './sheet/Sheet';

export const TIME_FORMAT = 'H:mm';
export const TIME_FORMAT_FULL = 'HH:mm';
export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const BACKEND_DATE_TIME_FORMAT_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm:ss';
export const CLOCK_TIME_FORMAT = 'h:mmA';

export type DateRfc = string;

interface ITransformTimeToBackendDateTimeProps {
    time: TimeHoursMinutes;
    date: DateBackend;
    timeFormat?: string;
    dateFormat?: string;
}

export const transformTimeToBackendDateTime = ({
    time,
    date,
    timeFormat = TIME_FORMAT,
    dateFormat = backendDateFormat,
}: ITransformTimeToBackendDateTimeProps): TimeRFC3339 => {
    const timeMoment = moment(time, timeFormat);
    const dateMoment = moment(date, dateFormat);
    return dateMoment.hours(timeMoment.hours()).minutes(timeMoment.minutes()).format(BACKEND_DATE_TIME_FORMAT);
};

export const getMomentFromBackendDateTime = (dateTime: TimeRFC3339): Moment => (
    moment(dateTime, BACKEND_DATE_TIME_FORMAT)
);

export const transformBackendDateTimeToTime = (dateTime: TimeRFC3339): string => (
    moment(dateTime, BACKEND_DATE_TIME_FORMAT_WITHOUT_TZ).format(CLOCK_TIME_FORMAT)
);

export const transformClockTimeToTime = (time: string): string => (
    moment(time, CLOCK_TIME_FORMAT).format(TIME_FORMAT_FULL)
);

export const transformTimeToClock = (time: string): string => (
    moment(time, TIME_FORMAT_FULL).format(CLOCK_TIME_FORMAT)
);

export const transformBackendDateTimeToFormTime = (dateTime: TimeRFC3339): string => (
    moment(dateTime, BACKEND_DATE_TIME_FORMAT_WITHOUT_TZ).format(TIME_FORMAT_FULL)
);

export const printMinutes = (minutes: number) => moment().minutes(minutes).format('mm');

export const printTimeByHours = (hours: number, minutes: number, format: string = TIME_FORMAT) =>
    moment().hours(hours).minutes(minutes)
        .format(format);

export const getTimeUnitsByDuration = (duration: moment.Duration) => {
    // unfortunately duration doesn't have format method besides https://github.com/jsmreese/moment-duration-format
    return {
        hours: Math.floor(duration.asHours()),
        minutes: duration.minutes() + (duration.seconds() >= 30 ? 1 : 0),
    };
};

export const printDuration = (duration: moment.Duration | null) => {
    if (duration === null) {
        return '';
    } else {
        const { hours, minutes } = getTimeUnitsByDuration(duration);
        return printTimeByHours(hours, minutes);
    }
};

export const getNormalizedDateTime = (
    time: TimeRFC3339,
    baseTime: TimeRFC3339 | null = null,
    format: string = TIME_FORMAT_FULL,
) => {
    let result = moment(time, format);
    if (moment(baseTime, format).isAfter(result)) {
        result = result.add(moment.duration(1, 'day'));
    }
    return result;
};

export const calculateDurationFromStartEndValue = (
    startValue: TimeHoursMinutes,
    endValue: TimeHoursMinutes,
    format = TIME_FORMAT,
) => {
    const startMoment = moment(startValue, format);
    const endMoment = moment(endValue, format);
    if ([TIME_FORMAT, TIME_FORMAT_FULL].includes(format) && startMoment.isAfter(endMoment)) {
        endMoment.add(1, 'd');
    }
    return moment.duration(endMoment.diff(startMoment));
};

export const getTimeUnitsFromTimeInOut = (startValue: TimeHoursMinutes, endValue: TimeHoursMinutes) => {
    const duration = calculateDurationFromStartEndValue(startValue, endValue, TIME_FORMAT_FULL);
    return getTimeUnitsByDuration(duration);
};

export const getDurationFromInOutEntryData = (data: InOutEntryData) => {
    if (data.time_out === null) {
        return null;
    }
    const duration = calculateDurationFromStartEndValue(data.time_in, data.time_out, BACKEND_DATE_TIME_FORMAT);
    const { break_minutes: breakMinutes } = data as IInOutBreakDataBackend;
    const { break_time_in: breakTimeIn, break_time_out: breakTimeOut } = data as IInOutMealBreakDataBackend;
    if (data.entry_type === QuantityType.TIME_IN_OUT_BREAK && breakMinutes) {
        duration.subtract(breakMinutes, 'minutes');
    }
    if (data.entry_type === QuantityType.TIME_IN_OUT_MEAL_BREAK && breakTimeIn && breakTimeOut) {
        const breakDuration = calculateDurationFromStartEndValue(breakTimeIn, breakTimeOut, BACKEND_DATE_TIME_FORMAT);
        duration.subtract(breakDuration);
    }
    return duration;
};

export const getDurationFromEntryData = (data: TimeEntryDataBackend) => {
    switch (data.entry_type) {
        case QuantityType.TIME:
            return getDurationFromTimeData(data as ITimeEntryData);
        case QuantityType.TIME_IN_OUT:
        case QuantityType.TIME_IN_OUT_BREAK:
        case QuantityType.TIME_IN_OUT_MEAL_BREAK:
            return getDurationFromInOutEntryData(data);
        default:
            return moment.duration();
    }
};

export const getBreakDurationFromEntryData = (data: TimeEntryDataBackend) => {
    switch (data.entry_type) {
        case QuantityType.TIME_BREAK:
            return getDurationFromInOutEntryData(data);
        case QuantityType.TIME_IN_OUT_BREAK: {
            const { break_minutes: breakMinutes } = data;
            if (breakMinutes) {
                return moment.duration(breakMinutes, 'minutes');
            }
            break;
        }
        case QuantityType.TIME_IN_OUT_MEAL_BREAK: {
            const { break_time_in: breakTimeIn, break_time_out: breakTimeOut } = data;
            if (breakTimeIn && breakTimeOut) {
                return calculateDurationFromStartEndValue(breakTimeIn, breakTimeOut, BACKEND_DATE_TIME_FORMAT);
            }
            break;
        }
    }
    return null;
};

export const getDurationFromTimeData = (data: ITimeEntryData) => {
    const { hours, minutes } = data;
    const duration = moment.duration(hours, 'hours');
    duration.add(moment.duration(minutes, 'minutes'));
    return duration;
};

export const printDurationTimeFromInOutEntryData = (data: InOutEntryData) => {
    const duration = getDurationFromInOutEntryData(data);
    return printDuration(duration);
};

export const printDurationFromStartEndValue = (startValue: string, endValue: string) => {
    const duration = calculateDurationFromStartEndValue(startValue, endValue);
    return printDuration(duration);
};

export const compareInOutTimeValue = (first: string, second: string, format: string = TIME_FORMAT) => {
    const firstMoment = moment(first, format);
    const secondMoment = moment(second, format);
    switch (true) {
        case firstMoment.isBefore(secondMoment):
            return 1;
        case firstMoment.isAfter(secondMoment):
            return -1;
        default:
            return 0;
    }
};

export interface ITimeByUnits {
    hours: number;
    minutes: number;
}

/**
 * Parse hours & minutes from string 'h:mm'
 * @param {string} time
 * @return {ITimeByUnits}
 */
export const parseTimeUnitsFromString = (time: TimeHoursMinutes): ITimeByUnits => {
    const [hours = 0, minutes = 0] = time
        ?.split(':')
        .map(x => (parseInt(x, 10))) || [0, 0];
    return { hours, minutes };
};

export const getMinutesByTimeUnits = ({ hours, minutes }: ITimeByUnits) => hours * 60 + minutes;
export const getMinutesByDuration = (duration: moment.Duration) => duration.asMinutes();

export const parseTimeUnitsFromMinutes = (minutes: number): ITimeByUnits => ({
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60,
});

export function formatDecimalHoursStringAsHoursAndMinutes(hoursDecimal: string): string {
    return formatDecimalHoursAsHoursAndMinutes(parseFloat(hoursDecimal));
}

export function formatDecimalHoursAsHoursAndMinutes(hoursDecimal: number): string {
    const duration = moment.duration(hoursDecimal, 'hours');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes() + (duration.seconds() >= 30 ? 1 : 0);
    return `${hours}:${padStart(minutes.toString(), 2, '0')}`;
}

export const emptyFormattedTime = '0:00';
