import React from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { IGlobalRole } from 'shared/models/User';
import { selectGlobalRoles, selectIsGlobalRolesLoading } from 'store/entities/users/selectors';

interface IGlobalRoleSelectProps extends IFormFieldProps, IFormSelect {
    inputLabel?: string;
}

export const GlobalRoleSelect = (props: IGlobalRoleSelectProps) => {
    const globalRoles = useSelector(selectGlobalRoles);
    const isGlobalRolesLoading = useSelector(selectIsGlobalRolesLoading);
    const blockNameList = ['Employee'];
    const options = globalRoles.filter(role => !blockNameList.includes(role.name));

    return (
        <FormSelect
            getKey={(role: IGlobalRole) => role.id}
            getText={(role: IGlobalRole) => role.name}
            options={options}
            isLoading={isGlobalRolesLoading}
            {...props}
        />
    );
};
