import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { Nullable } from '../../../../types/types';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { maxMomentDate, moment } from 'utils/momentExtensions';
import { MomentInput } from 'moment';

const IN_DAYS = 'days';
const INCLUDING_START_AND_END = '[]';

const onlyFittingByDate = (date: MomentInput) => ({ end_date, start_date }: ISubassignment) => (
    moment(date).isBetween(start_date, end_date || maxMomentDate, IN_DAYS, INCLUDING_START_AND_END)
);

const onlyFittingByPayPeriod = (payPeriod: IPayPeriod) => ({ end_date, start_date }: ISubassignment) => {
    const momentPayPeriod = moment.range(
        moment(payPeriod.period_start),
        moment(payPeriod.period_end).endOf('day'),
    );
    const subassignmentPeriod = moment.range(
        moment(start_date),
        end_date ? moment(end_date).endOf('day') : maxMomentDate,
    );
    return subassignmentPeriod.intersect(momentPayPeriod);
};

export function filterBySubassignments(
    customFiledValues: ICustomFieldValue[],
    userSubassignments: ISubassignment[],
    payPeriod?: Nullable<IPayPeriod>,
    date?: Nullable<string>,
) {
    let filteredSubassignments = [...userSubassignments];

    if (date) {
        filteredSubassignments = filteredSubassignments.filter(onlyFittingByDate(date));
    }

    if (payPeriod) {
        filteredSubassignments = filteredSubassignments.filter(onlyFittingByPayPeriod(payPeriod));
    }

    return customFiledValues.filter(value => filteredSubassignments.some(
        subassignment => subassignment.all_values_custom_field_ids?.includes(value.custom_field_id)
            || subassignment.custom_field_value_ids?.includes(value.id)),
    );
}
