import { ICalculationGroupActionsProps } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/ICalculationGroupActionsProps';
import { useTemplatesResolverBatch } from 'modules/payrollProcessorHub/components/SheetStatusAction/hooks/useTemplatesResolverBatch';
import { RejectSheetGroupBatch } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectSheetGroupBatch';
import { useOptionalTimeExpenseReportTemplate } from 'modules/payrollProcessorHub/components/SheetStatusAction/utils';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ActionButton from 'shared/components/buttons/ActionButton';
import { ISheetGroupId } from 'modules/payrollProcessorHub/store/model';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { useModal } from 'shared/utils/hooks/useModal';
import {
    RejectSheetGroups,
} from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectSheetGroups';

export interface IRejectButtonProps {
    groupIds: ISheetGroupId[];
}

interface IRejectButtonCoreProps {
    onClick: () => void;
    opened: boolean;
    onClose: () => void;
    title: string;
    templateA: string;
    templateB: string;
    bodyNode: React.ReactNode;
}

function RejectButtonCore({
    bodyNode,
    onClick,
    onClose,
    opened,
    templateA,
    templateB,
    title,
}: IRejectButtonCoreProps) {
    const sidebarClasses = useSidebarStyles();
    return (
        <>
            <ActionButton
                variant="outlined"
                color="primary"
                customType="danger"
                onClick={onClick}
            >
            Reject
            </ActionButton>
            <Sidebar
                isOpened={opened}
                onClose={onClose}
                anchor="right"
                title={title}
                titleClasses={{
                    root: sidebarClasses.whiteTitle,
                }}
            >
                <Box my={4} mx={7}>
                    <Box>
                        <Typography gutterBottom>
                            You are not on the approver list of this {templateA} report.
                        </Typography>
                        <Typography gutterBottom>
                            Are you sure you want to bypass the regular
                            approval flow and reject {templateB} report?
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        Please enter the reasoning below.
                    </Box>
                </Box>

                {bodyNode}

            </Sidebar>
        </>
    );
}

export const RejectButton = ({ groupIds }: IRejectButtonProps) => {
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const optionalTemplateFunc = useOptionalTimeExpenseReportTemplate(groupIds);
    const title = `Reject Submitted${optionalTemplateFunc('Timesheet', 'Expense', 'Timesheet and Expense')}report`;
    return (
        <RejectButtonCore
            onClick={onModalOpen}
            opened={isModalOpened}
            onClose={onModalClose}
            title={title}
            templateA={optionalTemplateFunc('timesheet', 'expense', 'timesheet and expense')}
            templateB={optionalTemplateFunc('the timesheet', 'the expense', 'both timesheet and expense')}
            bodyNode={(
                <RejectSheetGroups
                    groupIds={groupIds}
                    onCancel={onModalClose}
                />
            )}
        />
    );
};

export const RejectButtonBatch = ({ calculationGroups }: ICalculationGroupActionsProps) => {
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const resolveTemplates = useTemplatesResolverBatch(calculationGroups);
    const title = `Reject Submitted${resolveTemplates('Timesheet', 'Expense', 'Timesheet and Expense')}report`;
    return (
        <RejectButtonCore
            onClick={onModalOpen}
            opened={isModalOpened}
            onClose={onModalClose}
            title={title}
            templateA={resolveTemplates('timesheet', 'expense', 'timesheet and expense')}
            templateB={resolveTemplates('the timesheet', 'the expense', 'both timesheet and expense')}
            bodyNode={(
                <RejectSheetGroupBatch
                    calculationGroups={calculationGroups}
                    onCancel={onModalClose}
                />
            )}
        />
    );
};
