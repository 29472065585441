import React from 'react';
import { useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import {
    useClientRolesCells,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleTable/cells';
import {
    useClientRolesRows,
} from 'modules/settings/submodules/platformUsers/components/ClientRolesAndPermissions/components/ClientRoleTable/utils';
import { selectIsClientRolesLoading } from 'store/entities/users/selectors';

export const ClientRolesTable = () => {
    const tableClasses = useDefaultTableStyles();
    const rows = useClientRolesRows();
    const cells = useClientRolesCells();
    const isLoading = useSelector(selectIsClientRolesLoading);

    return (
        <GridTable
            rowData={rows}
            getKey={row => row.id}
            cells={cells}
            isLoading={isLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            stickyHeader
        />
    );
};
