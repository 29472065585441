import { IJobNumberUserSearch } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/model';
import { IUserInfo } from 'shared/models/User';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

const SET_ASSIGN_USER_MODAL_JOB_NUMBER_ID = 'settings/job_numbers/SET_ASSIGN_USER_MODAL_JOB_NUMBER_ID';
export const setAssignUserModalJobNumberId = createSingleAction<
string | null,
typeof SET_ASSIGN_USER_MODAL_JOB_NUMBER_ID
>(
    SET_ASSIGN_USER_MODAL_JOB_NUMBER_ID,
);

export const searchJobNumbersEmployees = createRequestActions<IJobNumberUserSearch, IUserInfo[]>(
    RequestType.Get,
    'USER_SEARCH',
    'settings/job_numbers',
);
