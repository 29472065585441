import clsx from 'clsx';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSheetSummaryStyles } from 'modules/payrollProcessorHub/components/SheetSummary/styles';

export type SummaryColors = 'blue' | 'green' | 'orange' | 'red';

export interface ISummaryBlockProps {
    value: string;
    title: string;
    color: SummaryColors;
}

export const SummaryBlock = ({ value, title, color }: ISummaryBlockProps) => {
    const classes = useSheetSummaryStyles();
    const colorClasses = {
        'blue': classes.blue,
        'green': classes.green,
        'orange': classes.orange,
        'red': classes.red,
    };
    return (
        <Box className={classes.summaryBlock}>
            <Typography classes={{ root: clsx(classes.value, colorClasses[color]) }}>
                {value}
            </Typography>
            <Typography classes={{ root: classes.valueLabel }}>
                {title}
            </Typography>
        </Box>
    );
};
