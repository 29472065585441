import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { allOfferLetterTabName, IOfferLetter, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { selectOfferLetterActiveTab, selectOfferLetterFilters } from 'modules/offerLetter/store/selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useTabUpdateByOffer = (offerLetter?: IOfferLetter) => {
    const activeTab = useSelector(selectOfferLetterActiveTab);
    const filter = useSelector(selectOfferLetterFilters);
    const dispatch = useDispatch();

    useEffect(() => {
        let statusSlug = offerLetter?.status?.slug;
        if (statusSlug === OfferLetterStatusSlug.RejectedPayRate) {
            // Rejected pay rate displayed on pending tab
            statusSlug = OfferLetterStatusSlug.PendingPayRateApproval;
        }
        if (activeTab !== allOfferLetterTabName && statusSlug && statusSlug !== activeTab) {
            dispatch(setOfferLetterFilters({
                ...filter,
                statusSlug,
            }));
        }
    }, [activeTab, dispatch, filter, offerLetter?.status?.slug]);
};
