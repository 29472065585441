import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { getUniqValues } from 'modules/payrollProcessorHub/store/helpers';
import { useSelector } from 'react-redux';
import { IUserInfo } from 'shared/models/User';
import { IManagerInfo } from 'store/entities/configuration/configurationModel';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';
import { selectUsersById } from 'store/entities/users/selectors';
import {
    usePayrollSheetGroupSubassignments,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/usePayrollSheetGroupSubassignments';

export const usePayrollSheetGroupApprovers = (row: IPayrollSheetGroupedRow): IUserInfo[] => {
    const usersByIds = useSelector(selectUsersById);

    const subassignments = usePayrollSheetGroupSubassignments(row);
    const managerIds = subassignments.map(
        subassignment => subassignment?.managers?.map((manager: IManagerInfo) => manager.user_id),
    ).flat();

    return managerIds?.map(id => usersByIds[id]) || [];
};

export const usePayrollSheetGroupApprovedBy = (row: IPayrollSheetGroupedRow): IUserInfo[] => {
    const usersByIds = useSelector(selectUsersById);

    const allCalculations = [...row.time_calculations, ...row.expense_calculations] as Array<ICalculation>;
    const approvedByIds = getUniqValues(allCalculations, 'latest_approver_user_id');

    return approvedByIds?.map(id => usersByIds[id]) || [];
};
