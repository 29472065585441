import moment from 'moment';
import * as yup from 'yup';

export const formValidationSchema = yup.object().shape({
    employee_user_id: yup.string().nullable(),
    approver_user_id: yup.string().nullable(),
    start_date: yup.string().nullable(),
    end_date: yup.string().nullable().test(
        'EndAfterStart',
        'End Date should be after Start Date',
        function (value) {
            return !this.parent.start_date
                || !value
                || moment(value).isSameOrAfter(this.parent.start_date, 'd');
        },
    ),
});
