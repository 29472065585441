import {
    createPayRangeAction,
    getMorePayRangesAction,
    initialLoadPayRanges,
    PayRangesActions,
    setPayRangesFilter,
    updatePayRangeAction,
} from 'modules/settings/submodules/clients/payRanges/store/actions';
import { managePayRangeApi } from 'modules/settings/submodules/clients/payRanges/store/api';
import {
    payRangesTableSelectors,
    selectPayRangesFilter,
} from 'modules/settings/submodules/clients/payRanges/store/selectors';
import { decreaseSyncing, increaseSyncing } from 'store/entities/appConfig/actions';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import {
    deletePayRange,
    getPayRangeByValue,
    getPayRanges,
} from 'store/entities/configuration/configurationAction';

import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';

function* getMorePayRangesSaga() {
    const filter = yield select(selectPayRangesFilter);
    const range = yield* select(payRangesTableSelectors.selectNextRange);
    const request = {
        range,
        state_code: filter?.stateCode || undefined,
        position_value_id: filter.positionValueId || undefined,
        location_value_id: filter.locationValueId || undefined,
    };
    const result = yield call(managePayRangeApi.getPayRanges, request);
    yield put(getPayRanges.success(result.pay_ranges));
    yield put(getMorePayRangesAction.success(result));
}

function* getMorePayRangesSagaWatcher() {
    yield* takeLatest(
        [
            getMorePayRangesAction.initType,
            initialLoadPayRanges.action,
            setPayRangesFilter.action,
        ],
        withBackendErrorHandler(
            getMorePayRangesSaga,
            getMorePayRangesAction.error,
            'Unable to get pay ranges.',
        ),
    );
}

function* createPayRangeSaga({ payload }: ReturnType<typeof createPayRangeAction.init>) {
    const payRange = yield* call(managePayRangeApi.createPayRange, payload);
    yield put(getPayRanges.success([payRange]));
    yield put(createPayRangeAction.success());
    yield put(initialLoadPayRanges());
}

function* createPayRangeSagaWatcher() {
    yield* takeLatest(
        createPayRangeAction.initType,
        withBackendErrorHandler(
            createPayRangeSaga,
            createPayRangeAction.error,
            'Unable to create pay range.',
        ),
    );
}

function* updatePayRangeSaga({ payload }: ReturnType<typeof updatePayRangeAction.init>) {
    const { id, data } = payload;
    const payRange = yield* call(managePayRangeApi.updatePayRange, id, data);
    yield put(getPayRanges.success([payRange]));
    yield put(updatePayRangeAction.success());
}

function* updatePayRangeSagaWatcher() {
    yield* takeLatest(
        updatePayRangeAction.initType,
        withBackendErrorHandler(
            updatePayRangeSaga,
            updatePayRangeAction.error,
            'Unable to update pay range.',
        ),
    );
}

function* deletePayRangeSaga({ payload }: ReturnType<typeof deletePayRange.init>) {
    yield* call(managePayRangeApi.deletePayRange, payload);
    yield put(deletePayRange.success(payload));
    yield put(initialLoadPayRanges());
}

function* deletePayRangeSagaWatcher() {
    yield* takeLatest(
        deletePayRange.initType,
        withBackendErrorHandler(
            deletePayRangeSaga,
            deletePayRange.error,
            'Unable to delete pay range.',
        ),
    );
}

function* payRangesSyncingSaga(action: PayRangesActions) {
    switch (action.type) {
        case updatePayRangeAction.initType:
        case deletePayRange.initType: {
            yield* put(increaseSyncing(SyncingModels.PayRanges));
            break;
        }
        default: {
            yield* put(decreaseSyncing(SyncingModels.PayRanges));
            break;
        }
    }
}

function* payRangesSyncingSagaWatcher() {
    yield* takeEvery([
        updatePayRangeAction.initType,
        updatePayRangeAction.successType,
        updatePayRangeAction.errorType,
        deletePayRange.initType,
        deletePayRange.successType,
        deletePayRange.errorType,
    ], payRangesSyncingSaga);
}

function* payRangeByValueSaga(action: ReturnType<typeof getPayRangeByValue.init>) {
    const payRange = yield* call(managePayRangeApi.getPayRangesByValues, action.payload);
    yield put(getPayRangeByValue.success([payRange]));
}

export function* payRangeByValueSagaWatcher() {
    yield* takeEvery(
        getPayRangeByValue.initType,
        withBackendErrorHandler(
            payRangeByValueSaga,
            getPayRangeByValue.error,
            'There is no available pay range for current position and location',
            false,
        ),
    );
}

export const payRangesSagas = [
    getMorePayRangesSagaWatcher,
    createPayRangeSagaWatcher,
    updatePayRangeSagaWatcher,
    deletePayRangeSagaWatcher,
    payRangesSyncingSagaWatcher,
    payRangeByValueSagaWatcher,
];
