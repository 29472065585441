import clsx from 'clsx';
import React from 'react';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';

interface IGridHeaderProps<RowData> {
    rowsData: Array<RowData>;
    cells: Array<ICellInfo<RowData>>;
    headerCellClassName?: string;
    classes: ReturnType<typeof useGridTableStyles>;
    stickyHeader?: boolean;
    highlight?: boolean;
    customHeaderRowClassName?: string;
}

export default function GridHeader<RowData>({
    cells,
    headerCellClassName,
    classes,
    rowsData,
    stickyHeader,
    highlight,
    customHeaderRowClassName,
}: IGridHeaderProps<RowData>) {
    return (
        <div
            className={clsx(
                classes.rowContainer,
                classes.headerRow,
                customHeaderRowClassName,
                {
                    [classes.headerCellSticky]: stickyHeader,
                },
            )}
        >
            {cells.map(({ key, renderTitle: TitleComponent, title, headerClassName }) => (
                <div
                    className={clsx(
                        classes.headerCell,
                        headerClassName,
                        headerCellClassName,
                        {
                            [classes.highlightedRow]: highlight,
                            [classes.headerCellSticky]: stickyHeader,
                        },
                    )}
                    key={key}
                >
                    {TitleComponent ? (
                        <TitleComponent rows={rowsData}/>
                    ) : title}
                </div>
            ))}
        </div>
    );
}
