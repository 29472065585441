import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTimesheetCalculations } from 'store/entities/timesheet/actions/calculations';
import { selectTimesheetIdsToUpdateCalculation } from 'store/entities/timesheet/selectors';
import { isEmpty } from 'lodash-es';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

/**
 * Define time sheet IDs that waiting for calculations update
 * e.g. timesheet updated but calculations still pending
 * or timesheet created but calculation not
 *
 * @param timeSheetIds list of timesheet ids for checking
 */
export const useTimesheetIdsCalculationsToUpdate = (timeSheetIds: string[]): string[] => {
    const timesheetIdsToUpdateCalculation = useSelector(selectTimesheetIdsToUpdateCalculation());

    return useMemo(() => (
        timeSheetIds.filter(timeSheetId => timesheetIdsToUpdateCalculation.includes(timeSheetId))
    ), [timesheetIdsToUpdateCalculation, timeSheetIds]);
};

/**
 * Polling API for calculations update
 */
export const useSheetCalculationUpdate = (payPeriod?: IPayPeriod) => {
    const dispatch = useDispatch();
    const timesheetIdsToUpdate = useSelector(selectTimesheetIdsToUpdateCalculation(payPeriod));
    useEffect(() => {
        const pollingTimeout = 3_000;
        let polling: NodeJS.Timeout;
        if (!isEmpty(timesheetIdsToUpdate)) {
            polling = setInterval(() => {
                dispatch(getTimesheetCalculations.init({
                    sheet_ids: timesheetIdsToUpdate,
                }));
            }, pollingTimeout);
        }

        return () => {
            if (polling) {
                clearInterval(polling);
            }
        };
    }, [dispatch, timesheetIdsToUpdate]);
};
