import React from 'react';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { Box, Button } from '@material-ui/core';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';

interface IEafSubmitBlockProps {
    isLoading: boolean;
    onCancel: () => void;
}

export const EafSubmitBlock = ({ isLoading, onCancel }: IEafSubmitBlockProps) => {
    const formClasses = sidebarFormStyles();

    return (
        <Box
            display="flex"
            alignItems="baseline"
            mt={3}
        >
            <ButtonWithLoader
                type="submit"
                variant="contained"
                color="secondary"
                className={formClasses.button}
                isLoading={isLoading}
                data-testid="submit_button"
            >
                Submit
            </ButtonWithLoader>
            <Button
                disabled={isLoading}
                onClick={onCancel}
                variant="outlined"
                color="primary"
            >
                Cancel
            </Button>
        </Box>
    );
};
