import { IGetUsersResponse } from 'shared/models/User';
import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { IEmployeeFilter } from 'modules/users/employees/store/models';

export const INITIAL_LOAD_EMPLOYEES = 'users/employees/INITIAL_LOAD_EMPLOYEES';
export const initialLoadEmployees = createSingleAction<void, typeof INITIAL_LOAD_EMPLOYEES>(
    INITIAL_LOAD_EMPLOYEES,
);
export const REFRESH_EMPLOYEES = 'users/employees/REFRESH_EMPLOYEES';
export const refreshEmployees = createSingleAction<void, typeof REFRESH_EMPLOYEES>(
    REFRESH_EMPLOYEES,
);

export const getMoreEmployeesAction = createRequestActions<void, IGetUsersResponse>(
    RequestType.Get,
    'MORE_EMPLOYEES',
    'users/employees',
);

export const SET_EMPLOYEES_FILTER = 'users/employees/SET_EMPLOYEES_FILTER';
export const setEmployeesFilter = createSingleAction<IEmployeeFilter, typeof SET_EMPLOYEES_FILTER>(
    SET_EMPLOYEES_FILTER,
);

export type EmployeesActions =
    | ActionsReturnTypes<typeof getMoreEmployeesAction>
    | ReturnType<typeof initialLoadEmployees>
    | ReturnType<typeof setEmployeesFilter>;
