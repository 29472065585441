import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';

type PayPeriodCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export const PayPeriodCell = ({
    className,
    pay_period_starts_at,
    pay_period_ends_at,
}: PayPeriodCellProps) => {
    const value: string = getPayPeriodByStartEnd(pay_period_starts_at, pay_period_ends_at);

    return (
        <PlainText
            className={className}
            value={value}
        />
    );
};
