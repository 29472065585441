import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import {
    selectAllowFutureDayEntry,
    selectAllowTimeEntryWithoutEndTime, selectDisallowPastDayEntry,
    selectExpenseReceiptRequiredDollars,
    selectScaEnabled,
    selectSheetGroupingEnabled,
} from 'store/entities/clients/selectors/configurationSelectors';
import {
    selectClientTimeAndPayConfiguration,
} from 'store/entities/clients/selectors/timeAndPaySelectors';
import { AppSettingsForm } from './AppSettingsForm';
import {
    selectCurrentClientId,
    selectIsLoadingClientConfiguration,
} from 'store/entities/clients/selectors/clientsSelectors';
import { updateAppSettings } from './store/actions';
import { selectIsAppSettingsUpdating } from './store/selectors';
import { IPatchAppSettingRequest, ISheetGroupKey, ITimeAndPayClientConfiguration } from 'store/entities/clients/clientsModel';
import { appSettingsValidationsSchema } from './validationScheme';
import { useCurrentClientMileageRate } from 'shared/models/Miles';

const normalizeFormValues = (formValues: Partial<ITimeAndPayClientConfiguration>): Partial<IPatchAppSettingRequest> => {
    return {
        approval_levels: formValues.approvalLevels || 1,
        SCA: typeof formValues.scaEnabled === 'boolean' ? {
            enabled: formValues.scaEnabled,
        } : undefined,
        allow_future_day_entry: formValues.allowFutureDayEntry,
        allow_time_entry_without_end_time: formValues.allowTimeEntryWithoutEndTime,
        expense_receipt_required_dollars: formValues.expenseReceiptRequiredDollars,
        mileage_rate: formValues.mileageRate?.toString(),
        ui_sheet_grouping: typeof formValues.allowTimeSheetGrouping === 'boolean' ? {
            enabled: formValues.allowTimeSheetGrouping,
            approval_grouping_keys: ['period_start', 'period_end', 'status.id'] as Array<keyof ISheetGroupKey>,
        } : undefined,
        time_tracking: formValues.timeTracking,
        enable_offer_letter_employee_number: formValues.enableOfferLetterEmployeeNumber,
    };
};

export const ApplicationSettingsFormWrapper = () => {
    const clientId = useSelector(selectCurrentClientId);
    const appSettings = useSelector(selectClientTimeAndPayConfiguration);
    const isLoading = useSelector(selectIsLoadingClientConfiguration);
    const isUpdating = useSelector(selectIsAppSettingsUpdating);
    const scaEnabled = useSelector(selectScaEnabled);
    const allowFutureDayEntry = useSelector(selectAllowFutureDayEntry);
    const allowTimeEntryWithoutEndTime = useSelector(selectAllowTimeEntryWithoutEndTime);
    const expenseReceiptRequiredDollars = useSelector(selectExpenseReceiptRequiredDollars);
    const mileageRate = useSelector(useCurrentClientMileageRate);
    const groupingEnabled = useSelector(selectSheetGroupingEnabled);
    const disallowTimeExpenseEntryPastDates = useSelector(selectDisallowPastDayEntry);

    const dispatch = useDispatch();
    const onSubmit = useCallback( (formValues: Partial<ITimeAndPayClientConfiguration>) => {
        if (clientId) {
            dispatch(updateAppSettings.init({
                clientId,
                data: normalizeFormValues(formValues),
            }));
        }
    }, [dispatch, clientId]);

    if (isLoading || !appSettings) {
        return (
            <Box ml={3}>
                <CircularProgress/>
            </Box>
        );
    }

    const initialValues = {
        approvalLevels: appSettings?.approvalLevels || 1,
        mileageRate: mileageRate.toNumber(),
        allowTimeSheetGrouping: groupingEnabled,
        disallowTimeExpenseEntryPastDates: disallowTimeExpenseEntryPastDates ?? false,
        timeTracking: appSettings?.timeTracking,
        enableOfferLetterEmployeeNumber: appSettings?.enableOfferLetterEmployeeNumber,
        scaEnabled,
        allowFutureDayEntry,
        allowTimeEntryWithoutEndTime,
        expenseReceiptRequiredDollars,
    };

    return (
        <Formik
            key="PaySettingsForm"
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validationSchema={appSettingsValidationsSchema}
        >
            {props => (
                <AppSettingsForm
                    isLoading={isUpdating}
                    {...props}
                />
            )}
        </Formik>
    );
};
