import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { selectIsEafCreating } from 'modules/employeeActionsForm/store/selectors';
import { AssignmentStartDateChangeForm } from './AssignmentStartDateChangeForm';
import { IChangeAssignmentStartDateFormValues } from './model/types';
import { useChangeStartDateForm } from './hooks/useChangeStartDateForm';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetailAssignment } from '../AssignmentInfo/store/selectors';
import { setDisplayAssignmentStartDateChangeDialog } from './store/actions';
import { selectShowAssignmentStartDateChangeDialog } from './store/selectors';
import { WorkspaceSidebar } from 'shared/components/workspaces/WorkspaceSidebar/WorkspaceSidebar';

export const AssignmentStartDateChangeSidebar: React.FC = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsEafCreating);
    const show = useSelector(selectShowAssignmentStartDateChangeDialog);
    const assignment = useSelector(selectDetailAssignment);
    const isOpened = show && Boolean(assignment);

    const formikProps = useChangeStartDateForm(assignment);

    const handleClose = useCallback(() => {
        dispatch(setDisplayAssignmentStartDateChangeDialog(false));
    }, [dispatch]);

    return (
        <WorkspaceSidebar
            title="Change Start Date"
            isOpened={isOpened}
            onClose={handleClose}
        >
            <Box p={3}>
                <Formik {...formikProps}>
                    {(props: FormikProps<IChangeAssignmentStartDateFormValues>) => (
                        <AssignmentStartDateChangeForm
                            isLoading={isLoading}
                            onCancel={handleClose}
                            {...props}
                        />
                    )}
                </Formik>
            </Box>
        </WorkspaceSidebar>
    );
};
