import { Hidden } from '@material-ui/core';
import { FormikTouched } from 'formik/dist/types';
import useAddControlsStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddControlsStyles';
import { selectApplyAutoSelectSingleValue, selectCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/selectors';
import { DepartmentsByAssignmentSelect } from 'modules/timeAndExpense/components/Selects/DepartmentsByAssignmentSelect';
import { useScopes } from 'modules/timeAndExpense/store/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsJobNumberFieldsApplied } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { v4 as uuidv4 } from 'uuid';
import { CustomFieldsValuesSelectGateway } from 'shared/components/forms/entries/CustomFieldsValuesSelectGateway';
import { IExpenseEntryFormValues } from 'shared/components/forms/entries/ExpenseEntryModel';
import { ITimeEntryFormValues } from 'shared/components/forms/entries/TimeEntryModel';
import { ActivitySelect } from 'shared/components/selects/ActivitySelect/ActivitySelect';

import DaySelect from 'shared/components/selects/day/DaySelect';
import { JobNumbers, useJobNumbersForUser } from 'shared/components/selects/JobNumbers';
import { EntryType } from 'shared/models/sheet/Sheet';
import {
    normalizeCommonValuesForState,
    useDayUpdateOnChangePayPeriod,
    useDisplayCustomFields, useFilteredSubassignments,
    useNestedCustomFieldValuesUpdate,
} from 'shared/utils/helpers/entries';
import { focusNextField } from 'shared/utils/helpers/focusNextField';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { HierarchyNodeOperation } from 'store/entities/customFields/model';

import { selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { showField } from '../utils';
import { ICommonEntryFormValues } from './EntryCommonFields';
import { useFilteredActivities } from './helpers/commonEntryFormHelpers';

export interface IBaseCommonEntryFieldsProps {
    supervisorId?: string;
    userId: string;
}

export interface ICommonEntryFieldsProps extends IBaseCommonEntryFieldsProps {
    payPeriod: IPayPeriod;
    areaId?: number;
    setFieldValue: (fieldName: string, value: any) => void;
    setTouched: (touched: FormikTouched<IExpenseEntryFormValues | ITimeEntryFormValues>,
        shouldValidate?: boolean) => void;
    values: ICommonEntryFormValues;
    onChangeCommonValues: (values: ICommonEntryFormValues) => void;
    entryType: EntryType;
    inputs: InputFields;
    disabled?: boolean;
}

const selectorsPanelFields: (keyof ICommonEntryFormValues)[] = [
    'position',
    'location',
    'jobNumber',
    'projectAssignment',
    'taskId',
];

export function CommonEntryInlineFields({
    payPeriod,
    setFieldValue,
    setTouched,
    values,
    onChangeCommonValues,
    entryType,
    inputs,
    userId,
    disabled = false,
}: ICommonEntryFieldsProps) {
    const classes = useAddControlsStyles();
    const {
        jobNumber,
        activity,
        customFieldValues,
    } = values;

    // synthetic id define common fields update
    const [commonFieldsVersion, setCommonFieldsVersion] = useState(uuidv4());
    const updateFieldsVersion = useCallback(() => {
        setCommonFieldsVersion(uuidv4());
    }, [setCommonFieldsVersion]);

    const commonEntryStoreValues = useSelector(selectCommonEntryFormValues);
    const hasJobNumberField = useSelector(selectIsJobNumberFieldsApplied);
    // We want allow to remove some selected values, but fill form after field selection
    const applyAutoSelect = useSelector(selectApplyAutoSelectSingleValue);
    const customFieldNodes = useSelector(selectOrderedCustomFieldAssignmentNodes);
    const displayCustomFieldValues = useDisplayCustomFields();
    const subassignments = useFilteredSubassignments(
        userId,
        payPeriod,
        customFieldValues,
    );
    const scopes = useScopes(customFieldValues);

    useEffect(() => {
        selectorsPanelFields.forEach(field => {
            if (!commonEntryStoreValues[field]) {
                // it shouldn't call useEffect on values change
                // eslint-disable-next-line
                if (values[field]){
                    setFieldValue(field, commonEntryStoreValues[field]);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonEntryStoreValues, setFieldValue]);

    useNestedCustomFieldValuesUpdate(customFieldValues, setFieldValue, subassignments, updateFieldsVersion);

    const activityId = activity?.id;
    useEffect(() => {
        setFieldValue('data', null);
        setTouched({
            'data': false,
        }, false);
    }, [activityId, setFieldValue, setTouched]);

    useEffect(() => {
        onChangeCommonValues(normalizeCommonValuesForState(values));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChangeCommonValues, commonFieldsVersion]);

    useDayUpdateOnChangePayPeriod(payPeriod, onChangeCommonValues);

    const jobNumbers = useJobNumbersForUser(userId, payPeriod);
    const filteredActivities = useFilteredActivities(
        entryType,
        jobNumber,
        scopes,
    );
    useEffect(() => {
        if (filteredActivities.length === 1 && !activity){
            const single = filteredActivities[0];
            setFieldValue('activity', single);
        }
    }, [filteredActivities, setFieldValue, activity]);

    useEffect(() => {
        if (jobNumbers.length === 1){
            const single = jobNumbers[0];
            setFieldValue('jobNumber', single);
            updateFieldsVersion();
        }
    }, [jobNumbers, setFieldValue, updateFieldsVersion]);

    useEffect(() => {
        if (jobNumber && !jobNumbers.some(jn => jn.id === jobNumber?.id)){
            setFieldValue('jobNumber', null);
            updateFieldsVersion();
        }
    }, [jobNumbers, jobNumber, setFieldValue, updateFieldsVersion]);

    const jobNumberInSelectorsPanel = commonEntryStoreValues.jobNumber;
    useEffect(() => {
        if ( jobNumberInSelectorsPanel ){
            focusNextField();
        }
    }, [jobNumberInSelectorsPanel]);

    return (
        <>
            {showField(inputs, EntrySlug.JobNumber) && (
                <JobNumbers
                    name="jobNumber"
                    label={inputs[EntrySlug.JobNumber].placeholder}
                    className={commonEntryStoreValues.jobNumber ? classes.notDisplayed : classes.flexItem}
                    disabled={disabled}
                    userId={userId}
                    payPeriod={payPeriod}
                    onConfirmSelection={updateFieldsVersion}
                />
            )}

            {showField(inputs, EntrySlug.Department) && (
                <DepartmentsByAssignmentSelect
                    name="department"
                    label={inputs.department.placeholder}
                    className={classes.flexItem}
                    userId={userId}
                    onConfirmSelection={updateFieldsVersion}
                />
            )}

            {!hasJobNumberField && customFieldNodes.map(customFieldNode => (
                <CustomFieldsValuesSelectGateway
                    // @ts-ignore
                    customFieldNode={customFieldNode}
                    key={customFieldNode.custom_field_id}
                    customFieldId={customFieldNode.custom_field_id}
                    name={`customFieldValues[${customFieldNode.custom_field_id}]`}
                    className={displayCustomFieldValues[customFieldNode.custom_field_id]
                        ? classes.flexItem : classes.notDisplayed}
                    useIdValue
                    useLabel
                    useSubassignments
                    autoSelectSingleVariant={applyAutoSelect}
                    disabled={customFieldNode.operation === HierarchyNodeOperation.ReadOnly}
                    hierarchy={customFieldNodes}
                    customFieldFormValues={customFieldValues}
                    userId={userId}
                    date={values.entry_date}
                    payPeriod={payPeriod}
                    hidden={hasJobNumberField}
                    onConfirmSelection={updateFieldsVersion}
                />
            ))}

            {showField(inputs, EntrySlug.Day) && (
                <Hidden xsDown>
                    <DaySelect
                        name="entry_date"
                        label={inputs.day.placeholder}
                        className={classes.fixedMedium}
                        payPeriod={payPeriod}
                        jobNumber={jobNumber}
                        subassignments={subassignments}
                        disabled={disabled}
                    />
                </Hidden>
            )}
            <ActivitySelect
                name="activity"
                label={inputs.activity.placeholder}
                disabled={disabled}
                jobNumber={jobNumber}
                className={classes.fixedMedium}
                entryType={entryType}
                scopes={scopes}
            />
        </>
    );
}
