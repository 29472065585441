import { IDepartment } from 'modules/employmentInfo/models/Department';
import {
    IGroupedSheetCalculation,
    IGroupedSheetCalculationCommon,
    ISheetCalculationBatch,
} from 'modules/payrollProcessorHub/store/model';
import { ILocation } from 'shared/models/Location';
import { PayType } from 'shared/models/PayType';
import { IPosition } from 'shared/models/Position';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IPaidTimeOffResponse } from 'shared/models/User';

import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { IClient } from 'store/entities/clients/clientsModel';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';
import { concatBatchProps } from './concatBatchProps';
import { getAllCalculationItems } from './getAllCalculationItems';
import { Nullable } from 'types/types';
import { toStringWithLeadingZeros } from 'shared/utils/helpers/hoursCalculations';

export const resolveClient = (client?: IClient) => client?.name || '';

// Time Sheet ID value resolvers
export const resolveTimeSheetId = (sheetGroup?: IGroupedSheetCalculation) => sheetGroup?.time_sheet_id || '';
export const resolveTimeSheetIdBatch = (calculationGroup?: ISheetCalculationBatch) =>
    concatBatchProps(calculationGroup?.time_calculations, 'sheet_id');

// Expense Sheet ID value resolvers
export const resolveExpenseSheetId = (sheetGroup?: IGroupedSheetCalculation) => sheetGroup?.expense_sheet_id || '';
export const resolveExpenseSheetIdBatch = (calculationGroup?: ISheetCalculationBatch) =>
    concatBatchProps(calculationGroup?.expense_calculations, 'sheet_id');

// Prism batch number value resolvers
export const resolvePrismBatchNumber = (type?: EntryType, sheetGroup?: IGroupedSheetCalculation) => (
    type === EntryType.TIME
        ? sheetGroup?.time_prism_batch_number
        : sheetGroup?.expense_prism_batch_number
) || '';
export const resolvePrismBatchNumberList = (type?: EntryType, calculationGroup?: ISheetCalculationBatch) => {
    const abstractCalculations: Array<ICalculation> = (
        type === EntryType.TIME
            ? calculationGroup?.time_calculations
            : calculationGroup?.expense_calculations
    ) || [];
    return concatBatchProps(abstractCalculations, 'prism_batch_number');
};

// Pay Type value resolver (Polymorphic)
export const resolvePayType = (abstractSheetCalculation?: IGroupedSheetCalculation | ISheetCalculationBatch) => (
    abstractSheetCalculation?.time_is_salary
        ? PayType.Salaried
        : PayType.Hourly
);

// Location value resolver
export const resolveLocation = (...locations: Array<ILocation>) => concatBatchProps(locations, 'name') || '';

// Pay Period value resolver (Polymorphic)
export const resolvePayPeriod = (abstractSheetCalculation: IGroupedSheetCalculationCommon) => (
    getPayPeriodByStartEnd(
        abstractSheetCalculation.pay_period_starts_at,
        abstractSheetCalculation.pay_period_ends_at,
    )
);

// Deal Number value resolvers
export const resolveDealNumber = (sheetGroup?: IGroupedSheetCalculation) => sheetGroup?.deal_number || '';
export const resolveDealNumberBatch = (calculationGroup?: ISheetCalculationBatch) => {
    const calculations = getAllCalculationItems(calculationGroup);
    return concatBatchProps(calculations, 'deal_number');
};

// Job Number value resolvers
export const resolveJobNumber = (sheetGroup?: IGroupedSheetCalculation) => sheetGroup?.job_number || '';
export const resolveJobNumberBatch = (calculationGroup?: ISheetCalculationBatch) => {
    const calculations = getAllCalculationItems(calculationGroup);
    return concatBatchProps(calculations, 'job_number');
};

// Position resolvers
export const resolvePosition = (sheetGroup?: IGroupedSheetCalculation, position?: IPosition) => (
    sheetGroup?.job_number_user_type_name || position?.name || ''
);
export const resolvePositionBatch = (
    calculationGroup?: ISheetCalculationBatch,
    positions: ICustomFieldValue[] = [],
) => {
    const calculations = getAllCalculationItems(calculationGroup);
    const positionsData = positions.map(position => position.data);
    return concatBatchProps(calculations, 'job_number_user_type_name') || concatBatchProps(positionsData, 'name');
};
export const resolveLocationBatch = (locations: ICustomFieldValue[] = []): string => {
    const locationsData = locations.map(location => location.data);
    return concatBatchProps(locationsData, 'name');
};

export const resolveDepartment = (...departments: Array<IDepartment>) => concatBatchProps(departments, 'name');

export const resolvePto = (pto?: Nullable<IPaidTimeOffResponse>) => {
    if (!pto?.pto_total) {
        return '';
    }

    return resolveDecimalHoursToRegular(pto.pto_total);
};

export const resolvePtoMaxUseAvailable = (pto?: Nullable<IPaidTimeOffResponse>) => {
    if (!pto) {
        return '';
    }
    if (pto.pto_max_use_available === '0.0') {
        const emptyPlans = pto.paid_time_off?.length > 0
            ? pto.paid_time_off.filter(x => x.prism_pto_max_usage_limit === '')
            : [];
        if (emptyPlans?.length === pto.paid_time_off?.length) {
            return resolvePto(pto);
        }
    }
    if (!pto?.pto_max_use_available) {
        return '';
    }
    const raw_max_use_available = +(pto.pto_max_use_available ?? '0');
    const raw_pto_total = +(pto.pto_total ?? '0');
    const valueToShow = raw_max_use_available < raw_pto_total ? pto.pto_max_use_available : pto.pto_total;

    return resolveDecimalHoursToRegular(valueToShow);
};

export const resolveDecimalHoursToRegular = (raw_hours: string) => {
    const PERCENT = 100;
    const MINUTES_IN_HOUR = 60;

    const [hours, hoursDecimalPart] = raw_hours.split('.');
    const hoursDecimalNumber = Number(hoursDecimalPart);
    const minutes = Math.ceil(hoursDecimalNumber / PERCENT * MINUTES_IN_HOUR);
    const minutesStr = toStringWithLeadingZeros(minutes, 2);
    return `${hours}:${minutesStr}`;
};
