import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import FilterAndActionControls from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControls';
import FilterAndActionMobile from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionMobile';
import { useApprovalTableStyles } from 'shared/components/table/TableApprovalStyles';
import ToolbarApproval from 'shared/components/toolbar/ToolbarApproval';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import {
    selectManagerSubmittedSheetsFilters,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';

interface ISheetApprovalContainerProps {
    children: React.ReactNode;
    isLoading?: boolean;
    sheetType: EntryType;
    total: number;
    classes: ReturnType<typeof useApprovalTableStyles>;
    checkedSheets: ISheet[];
    linkedUsers: IUserInfo[];
    withInfinityTable?: boolean;
}

export const SheetApprovalContainer = ({
    children,
    isLoading = false,
    sheetType,
    total,
    classes,
    checkedSheets,
    linkedUsers,
    withInfinityTable,
}: ISheetApprovalContainerProps) => {
    const hasFilters = isNotEmpty(useSelector(selectManagerSubmittedSheetsFilters));
    const title = hasFilters && !isLoading ? `${total} ${pluralize('Result', total)}.` : undefined;

    return (
        <>
            <Hidden xsDown>
                <FilterAndActionControls
                    sheetType={sheetType}
                    linkedUsers={linkedUsers}
                    sheets={checkedSheets}
                    title={title}
                    showActionControls={checkedSheets?.length > 0}
                    withInfinityTable={withInfinityTable}
                />
            </Hidden>
            <Box className={clsx(classes.tableMain, classes.tableWrapper)} mb={4}>
                <ToolbarApproval classes={{ root: classes.toolbar }}>
                    <FilterAndActionMobile
                        sheetType={sheetType}
                        linkedUsers={linkedUsers}
                        sheets={checkedSheets}
                        showActionControls={checkedSheets?.length > 0}
                        title={title}
                        withInfinityTable={withInfinityTable}
                    />
                </ToolbarApproval>
                {children}
            </Box>
        </>
    );
};
