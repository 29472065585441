import { useMemo } from 'react';
import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { getTemplateFunction } from 'modules/payrollProcessorHub/components/SheetStatusAction/utils';

export function useTemplatesResolverBatch(calculationGroups: ISheetCalculationBatch[]) {
    const hasTimeCalculations = calculationGroups.some(
        calculationGroup => calculationGroup?.time_calculations?.length > 0,
    );
    const hasExpenseCalculations = calculationGroups.some(
        calculationGroup => calculationGroup?.expense_calculations?.length > 0,
    );

    const resolveTemplates = useMemo(
        () => getTemplateFunction(hasTimeCalculations, hasExpenseCalculations),
        [hasTimeCalculations, hasExpenseCalculations],
    );

    return resolveTemplates;
}
