import React, { useCallback, useEffect } from 'react';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { setTextEmptyGrid } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/EmptyGrid';
import useSheetsInProgressStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import { useDispatch, useSelector } from 'react-redux';

import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import { timeoutAnimation } from 'shared/styles/constants';
import { sheetsInProgressGridId } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/constants';
import { quickRemoveEntry } from 'store/entities/timesheet/actions/sheetState';
import EntryEdit from 'shared/components/sidebars/EntryEdit/EntryEdit';
import EntriesTable from 'shared/components/table/EntriesTable/EntriesTable';
import { useEmployeeEntriesCells } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/Cells';
import { getEntryRowId } from 'shared/components/table/EntriesTable/utils';
import { selectLastAddedEntry } from 'store/entities/timesheet/selectors';
import { setEditEntryId } from 'modules/timeAndExpense/components/EditEntry/store/actions';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';

interface IEntriesGridProps {
    entryFilter?: EntryType;
    hasEntries: boolean;
    noFilteredEntries: boolean;
    filteredEntriesByDay: IEntry[][];
    isLoaded: boolean;
    isFileEntriesView?: boolean;
    userId: string;
    customEmptyText?: string;
    payPeriod: IPayPeriod;
    statusName?: StatusNames;
    isEntryDisabled?: (entry: IEntry) => boolean;
}

export default function EntriesGrid({
    filteredEntriesByDay,
    hasEntries,
    noFilteredEntries,
    entryFilter,
    isLoaded,
    userId,
    customEmptyText,
    isFileEntriesView = false,
    payPeriod,
    isEntryDisabled,
    statusName,
}: IEntriesGridProps) {
    const classes = useSheetsInProgressStyles();
    const dispatch = useDispatch();
    const lastAddedEntry = useSelector(selectLastAddedEntry);

    useEffect(() => {
        const element = document.getElementById(sheetsInProgressGridId);
        if (element) {
            element.scrollTop = 0;
        }
    }, [isLoaded]);

    useEffect(() => {
        if (lastAddedEntry) {
            // Queue microtask
            Promise.resolve().then(() => {
                // TODO: Find element by type & id
                const element = document.getElementById(getEntryRowId(lastAddedEntry.id));
                if (element === null) {
                    return;
                }
                if (element.children[0]) {
                    // This is provided by utils/scrollIntoViewIfNeeded.js
                    (element.children[0] as any).scrollIntoViewIfNeeded(true);
                }
                element.classList.add(classes.newAddedEntry);
                setTimeout(() => {
                    element.classList.remove(classes.newAddedEntry);
                }, timeoutAnimation);
            });
        }
    }, [lastAddedEntry, classes]);

    const onEditEntry = useCallback((entryId: string) => {
        dispatch(setEditEntryId(entryId));
    }, [dispatch]);
    const onQuickRemove = useCallback((entryId: string) => {
        dispatch(quickRemoveEntry.init(entryId));
    }, [dispatch]);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    const cells = useEmployeeEntriesCells(onEditEntry, onQuickRemove, isMobile, entryFilter);

    return (
        <Box className={classes.tableMain}>
            <Box id={sheetsInProgressGridId} className={
                isLoaded
                    ? noFilteredEntries ? classes.tableContainerIsEmpty : classes.tableContainer
                    : classes.tableContainerLoading
            }>
                {(!isLoaded || !noFilteredEntries) ? (
                    filteredEntriesByDay.map((dayEntries, index) => (
                        <EntriesTable
                            entryFilter={entryFilter}
                            entries={dayEntries}
                            date={dayEntries[0]?.entry_date || ''}
                            cells={cells}
                            isLoading={!isLoaded}
                            key={dayEntries[0]?.entry_date || `date${ index }`}
                            hideHeader={false}
                            userId={userId}
                            isFileEntriesView={isFileEntriesView}
                            headerActionsEnabled
                            isCollapsible
                            isEntryDisabled={isEntryDisabled}
                        />
                    ))
                ) : (
                    <Grid>
                        <Typography classes={{ root: classes.emptyText }}>
                            {customEmptyText || setTextEmptyGrid(hasEntries, entryFilter)}
                        </Typography>
                    </Grid>
                )}
            </Box>
            <EntryEdit
                userId={userId}
                payPeriod={payPeriod}
                statusName={statusName}
            />
        </Box>
    );

}
