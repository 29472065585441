/* eslint-disable react/display-name */
import { Delete, Edit } from '@material-ui/icons';
import { debounce } from 'lodash-es';
import clsx from 'clsx';
import { entryCellDictionary } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/EntryCellDictionary';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import { EntriesGridCellsTitles, IEntryRow } from 'shared/components/table/EntriesTable/model';
import { getEntryIdByRowId, getSheetTypeDependentTitles, useExtendedConfiguration } from 'shared/components/table/EntriesTable/utils';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import ActionIcon from 'shared/components/table/tableEntryCells/ActionIcon/ActionIcon';
import EntryDetails from 'shared/components/table/tableEntryCells/EntryDetails';
import { EntryType } from 'shared/models/sheet/Sheet';
import { colors } from 'shared/styles/constants';
import {
    AvailableTableConfiguration,
    EntryColumnSlug,
} from 'store/entities/clients/clientsModel';
import { getColumnsByConfiguration } from 'store/utils/tables';

export function useEmployeeEntriesCells(
    onEdit: (entryId: string) => void,
    onQuickRemove: (entryId: string) => void,
    isMobile: boolean,
    entryFilter?: EntryType,
): Array<ICellInfo<IEntryRow>> {
    const classes = useEntriesTableStyles();
    const { hoursAmountTitle } = getSheetTypeDependentTitles(entryFilter);

    const option = entryFilter
        ? entryFilter === EntryType.TIME ? AvailableTableConfiguration.Time : AvailableTableConfiguration.Expense
        : AvailableTableConfiguration.TimeExpense;

    const configuration = useExtendedConfiguration(option);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedDelete = useCallback(
        debounce(rowId => onQuickRemove(getEntryIdByRowId(rowId)), 400),
        [onQuickRemove],
    );

    const handleDelete = useCallback((event: MouseEvent) => {
        const rowId = event.currentTarget.closest('[role="row"]')?.id;
        if (rowId) {
            debouncedDelete(rowId);
        }
    }, [debouncedDelete]);

    const handleEdit = useCallback((event: MouseEvent) => {
        const rowId = event.currentTarget.closest('[role="row"]')?.id;
        if (rowId) {
            onEdit(getEntryIdByRowId(rowId));
        }
    }, [onEdit]);

    return useMemo(() => {
        const actionsColumn: ICellInfo<IEntryRow> = {
            key: EntriesGridCellsTitles.Actions,
            width: '80px',
            render: ({ className, disabled, entry }: IEntryRow) => (
                <div className={clsx(className, classes.iconCell, classes.firstActionIcon)}>
                    {entry.created_at && (
                        <>
                            <ActionIcon
                                disabled={disabled}
                                onClick={handleDelete}
                                htmlColor={colors.secondary}
                                Icon={Delete}
                            />
                            <ActionIcon
                                disabled={disabled}
                                onClick={handleEdit}
                                Icon={Edit}
                            />
                        </>
                    )}
                </div>
            ),
        };

        let cells;

        if (isMobile) {
            cells = [
                {
                    key: 'entryInfo',
                    title: 'Activity',
                    render: (row: IEntryRow) => (
                        <EntryDetails showIcon={true} configuration={configuration}
                            iconClass={classes.textIcon}
                            {...row}
                        />
                    ),
                },
                entryCellDictionary[EntryColumnSlug.Amount](hoursAmountTitle, classes),
                actionsColumn,
            ];
        } else {
            cells = getColumnsByConfiguration(configuration, entryCellDictionary, classes);
            cells = cells.concat([
                actionsColumn,
            ]);
        }

        return cells;
    }, [classes, isMobile, handleEdit, handleDelete, hoursAmountTitle, configuration]);
}
