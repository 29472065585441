import React from 'react';
import { useSelector } from 'react-redux';
import { usePayrollReportStyle } from 'modules/payrollProcessorHub/components/PayrollModal/reports/PayrollStyle';
import ReportOverview from 'modules/payrollProcessorHub/components/PayrollModal/reports/ReportOverview';
import { BulkEditReportV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/BulkEditReportV2';
import { selectBulkEditV2RowsChanges } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/selectors';
import { useBulkPostEditOverviewItems } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/hooks';

interface IBulkEditPostInitializeV2ReportProps {
    onClose: () => void;
}

export const BulkEditPostInitializeV2Report = ({ onClose }: IBulkEditPostInitializeV2ReportProps) => {
    const overviewItems = useBulkPostEditOverviewItems();
    const editChanges = useSelector(selectBulkEditV2RowsChanges);

    const overviewClasses = usePayrollReportStyle();
    const additionalOverview = (
        <>
            <ReportOverview
                blocks={[[
                    {
                        label: 'Edits',
                        values: [editChanges],
                    },
                ]]}
                classes={
                    {
                        column: overviewClasses.SummaryInfoColumn,
                        row: overviewClasses.SummaryInfoRow,
                    }
                }
            />
        </>
    );

    return (
        <BulkEditReportV2
            handleAction={onClose}
            title="Post-Initialize Roll Up"
            buttonTitle="Close"
            overviewItems={overviewItems}
            additionalOverview={additionalOverview}
        />
    );
};
