import React, { RefObject } from 'react';

import { PayrollGroupedCalculationsTable } from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollGroupedCalculationsTable';

export interface IPayrollSheetTableComponentProps {
    tableRef?: RefObject<HTMLDivElement>;
    tableClassName?: string;
    setTableRef?: (instance: HTMLDivElement | null) => void;
}

export const PayrollSheetTable = (props: IPayrollSheetTableComponentProps) => {
    return (<PayrollGroupedCalculationsTable {...props}/>);
};
