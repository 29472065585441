import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { getCustomFields, getCustomFieldsMappings } from 'store/entities/customFields/actions';
import { CustomFieldsTable } from 'modules/settings/submodules/customFields/components/CustomFieldsTable/CustomFieldsTable';
import { CreateCustomFieldButton } from 'modules/settings/submodules/customFields/components/CreateCustomField/CreateCustomFieldButton';
import { EditCustomFieldModal } from 'modules/settings/submodules/customFields/components/EditCustomField/EditCustomFieldModal';

export const CustomFieldsListPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomFields.init());
        dispatch(getCustomFieldsMappings.init());
    }, [dispatch]);

    return (
        <>
            <Box mb={2}>
                <CreateCustomFieldButton/>
            </Box>
            <CustomFieldsTable/>
            <EditCustomFieldModal/>
        </>
    );
};
