import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import useTotalHoursStyles from './totalNumStyles';

interface ITotalNumProps {
    value: string;
    label?: string;
    classes?: {
        value?: string;
        label?: string;
        root?: string;
    };
}

export default function TotalNum({ classes, value, label }: ITotalNumProps) {
    const baseClasses = useTotalHoursStyles();
    return (
        <Box className={clsx(baseClasses.totalWrapper, classes?.root)}>
            <Typography className={clsx(baseClasses.totalNum, classes?.value)}>{value}</Typography>
            <Typography className={clsx(baseClasses.totalNumLabel, classes?.label)}>{label}</Typography>
        </Box>
    );
}
