import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';
import {
    selectSubmittingOrgGenworthBranches,
    selectSubmittingOrgGenworthBranchesIsLoading,
} from 'store/entities/configuration/configurationSelectors';
import { ISubmittingOrgGenworthBranch } from 'shared/models/SubmittingOrgGenworthBranch';

interface IGenworthBranchesSelectProps extends IFormFieldProps, IFormSelect {
}

const prepareVisibleBranchName = (code: string, name: string) => {
    if (name) {
        return `${code} - ${name}`;
    }
    return code;
};

export const GenworthBranchesSelect = (props: IGenworthBranchesSelectProps) => {
    const submittingOrgGenworthBranches = useSelector(selectSubmittingOrgGenworthBranches);
    const isSubmittingOrgGenworthBranches = useSelector(selectSubmittingOrgGenworthBranchesIsLoading);
    return (
        <FormSelect
            getKey={(branch: ISubmittingOrgGenworthBranch) => branch?.id || ''}
            getText={(branch: ISubmittingOrgGenworthBranch) => prepareVisibleBranchName(branch?.code || '', branch?.name || '')}
            options={submittingOrgGenworthBranches}
            useIdValue
            isLoading={isSubmittingOrgGenworthBranches}
            {...props}
        />
    );
};
