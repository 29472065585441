import { call, put, takeLatest } from 'typed-redux-saga';
import { getSubmittingOrgs } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { api } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/api';
import {
    createSubmittingOrg,
    setCreateSubmittingOrgModalState,
} from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/actions';
import baseApi from 'shared/utils/baseApi';
import { ICreateSubmittingOrgRequest } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/models';

function* createSubmittingOrgSaga(action: ReturnType<typeof createSubmittingOrg.init>) {
    const request = {
        ...action.payload,
        client_id: baseApi.clientId,
    } as ICreateSubmittingOrgRequest;
    const result = yield* call(api.createSubmittingOrg, request);
    yield put(createSubmittingOrg.success(result));
    yield put(getSubmittingOrgs.success([result]));
    yield put(setCreateSubmittingOrgModalState(false));
}

export function* createSubmittingOrgWatcher() {
    yield* takeLatest(
        createSubmittingOrg.initType,
        withBackendErrorHandler(
            createSubmittingOrgSaga,
            createSubmittingOrg.error,
            'Unable to create Submitting Org.',
        ),
    );
}
