import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateStatus } from 'modules/settings/submodules/platformUsers/components/UpdateStatus/UpdateStatus';
import { setSubmittingOrgStatus } from 'modules/settings/submodules/clients/submittingOrg/components/UpdateStatusSelect/store/actions';
import { selectIsSubmittingOrgStatusUpdating } from 'modules/settings/submodules/clients/submittingOrg/components/UpdateStatusSelect/store/selectors';
import { Status } from 'shared/models/Status';

export interface IUpdateSubmittingOrgStatusSelectProps {
    id: string;
    status: Status;
    className?: string;
}

export const UpdateSubmittingOrgStatusSelect = (props: IUpdateSubmittingOrgStatusSelectProps) => {
    const { id, status, className } = props;
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsSubmittingOrgStatusUpdating);
    const handleStatusChange = useCallback((newValue: Status) => {
        dispatch(setSubmittingOrgStatus.init({
            id,
            status: newValue,
        }));
    }, [dispatch, id]);

    return (
        <UpdateStatus
            status={status}
            handleStatusChange={handleStatusChange}
            isLoading={isLoading}
            formControlClassName={className}
        />
    );
};
