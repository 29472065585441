import { IJobNumberFilter } from 'modules/settings/submodules/clients/jobNumber/store/models';
import { IDeal } from 'shared/models/Deal';
import { IStore } from 'store/configureStore';
import { selectControllingOrgById, selectSubmittingOrgById } from 'store/entities/configuration/configurationSelectors';
import { IInfinityScrollState } from 'store/reducerUtils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';

export const selectJobNumbersState = (state: IStore) => state.modules.settings.clientsSettings.clients.jobNumbers;
export const selectJobNumbersTableState = (state: IStore): IInfinityScrollState<string> =>
    selectJobNumbersState(state).jobNumbersTable;
export const jobNumbersTableStateSelectors = getInfinityScrollSelectors(selectJobNumbersTableState);
export const selectJobNumbersFilter = (state: IStore): IJobNumberFilter =>
    selectJobNumbersState(state).jobNumbersFilter;
export const selectContractClientDisplayValue = (deal?: IDeal | null) => (state: IStore): string => {
    const recipientSubmittingOrg = selectSubmittingOrgById(deal?.recipient?.recipient_id)(state);
    const recipientControllingOrg = selectControllingOrgById(deal?.recipient?.recipient_id)(state);
    return recipientControllingOrg?.name || recipientSubmittingOrg?.client_site_name || '';
};
