import React from 'react';
import { Box } from '@material-ui/core';
import { SummaryBlock } from '../../../payrollProcessorHub/components/SheetSummary/SummaryBlock';
import { formatDollars } from '../../../../shared/utils/formatters/dollarFormatter';

export interface ICcpTotalsProps {
    balance: number;
}

export const CcpTotalsBlock = ({
    balance,
}: ICcpTotalsProps) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="end"
            mt={-1}
        >
            { (+Math.round(balance * 100) >= 0) && (
                <SummaryBlock
                    key={'balance'}
                    value={formatDollars(balance)}
                    title={'balance'}
                    color={'green'}
                />
            )
            }
            { (+Math.round(balance * 100) < 0) && (
                <SummaryBlock
                    key={'balance'}
                    value={formatDollars(balance)}
                    title={'balance'}
                    color={'red'}
                />
            )}
        </Box>
    );
};
