import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IBulkCreateCcpTransactionChangeResponse, ITransactionValidationResponse } from '../../models/ccpModel';
import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { colors } from '../../../../shared/styles/constants';
import CloseIcon from '@material-ui/icons/Close';

const processErrorValues = (values: string | string[]): string => {
    if (Array.isArray(values)) {
        return values.join(', ');
    }
    const errorString = values.replace('[', '').replace(']', '');
    return errorString;
};

const ccpCellDictionary = {
    account_number: {
        key: 'account_number',
        title: 'ACCOUNT NUMBER',
        render: function AccountNumCell({ account_number, className }: ICellProps<ITransactionValidationResponse>){
            return (
                <PlainText className={className} value={account_number}/>
            );
        },
    },
    cardholder_name: {
        key: 'cardholder_name',
        title: 'CARDHOLDER NAME',
        render: function CardholderNameCell(
            {
                cardholder_name,
                className,
            }: ICellProps<ITransactionValidationResponse>) {
            return (
                <PlainText className={className} value={cardholder_name}/>
            );
        },
    },
    tran_date: {
        key: 'tran_date',
        title: 'TRAN DATE',
        render: function TransactionDateCell({ tran_date, className }: ICellProps<ITransactionValidationResponse>) {
            return (
                <PlainText className={className} value={tran_date}/>
            );
        },
    },
    post_date: {
        key: 'post_date',
        title: 'POST DATE',
        render: function PostDateCell({ post_date, className }: ICellProps<ITransactionValidationResponse>) {
            return (
                <PlainText className={className} value={post_date}/>
            );
        },
    },
    merchant_name: {
        key: 'merchant_name',
        title: 'MERCHANT NAME',
        render: function MerchantNameCell({ merchant_name, className }: ICellProps<ITransactionValidationResponse>) {
            return (
                <PlainText className={className} value={merchant_name}/>
            );
        },
    },
    amount: {
        key: 'amount',
        title: 'AMOUNT',
        render: function AmountCell({ amount, className }: ICellProps<ITransactionValidationResponse>) {

            return (
                <PlainText className={className} value={formatDollars(amount)}/>
            );
        },
    },
    ref_number: {
        key: 'ref_number',
        title: 'REF NUMBER',
        render: function RefNumCell({ ref_number, className }: ICellProps<ITransactionValidationResponse>) {
            return (
                <PlainText className={className} value={ref_number}/>
            );
        },
    },
    transaction_type_name: {
        key: 'transaction_type_name',
        title: 'TRANS TYPE',
        render: function TranTypeNameCell(
            {
                transaction_type_name,
                className,
            }: ICellProps<ITransactionValidationResponse>) {
            return (
                <PlainText className={className} value={transaction_type_name}/>
            );
        },
    },
    mcc_code: {
        key: 'mcc_code',
        title: 'MCC CODE',
        render: function MccCodeCell({ mcc_code, className }: ICellProps<ITransactionValidationResponse>) {
            return (
                <PlainText className={className} value={mcc_code}/>
            );
        },
    },
    merchant_desc: {
        key: 'merchant_desc',
        title: 'MCC DESC.',
        render: function MccDescCell({ merchant_desc, className }: ICellProps<ITransactionValidationResponse>) {
            return (
                <PlainText className={className} value={merchant_desc}/>
            );
        },
    },
    error: {
        key: 'errors',
        title: 'ERRORS',
        render: function ErrorsCell({ errors, className }: ICellProps<IBulkCreateCcpTransactionChangeResponse>) {
            return (
                <PlainText className={className} value={processErrorValues(errors)}/>
            );
        },
    },
    status: {
        key: 'status',
        title: '',
        render: function StatusCell({ errors, className }: ICellProps<IBulkCreateCcpTransactionChangeResponse>) {
            const failed = processErrorValues(errors).length > 0;
            return (
                <Box className={className}>
                    {
                        !failed
                            ? (
                                <CheckIcon component="svg" htmlColor={colors.green}/>
                            )
                            : (
                                <CloseIcon component="svg" htmlColor={colors.red}/>
                            )
                    }
                </Box>
            );
        },
    },
};

export const getCcpBulkCreationReportCells = () => {
    return [
        ccpCellDictionary.account_number,
        ccpCellDictionary.cardholder_name,
        ccpCellDictionary.tran_date,
        ccpCellDictionary.post_date,
        ccpCellDictionary.merchant_name,
        ccpCellDictionary.amount,
        ccpCellDictionary.ref_number,
        ccpCellDictionary.transaction_type_name,
        ccpCellDictionary.mcc_code,
        ccpCellDictionary.merchant_desc,
        ccpCellDictionary.error,
        ccpCellDictionary.status,
    ];
};
