import { EMPLOYMENT_MANAGEMENT_PREFIX } from 'modules/subassignmentManagement/store/actions';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

const SHOW_ASSIGNMENT_CHANGE_START_DATE_DIALOG = (
    `${EMPLOYMENT_MANAGEMENT_PREFIX}/SHOW_ASSIGNMENT_CHANGE_START_DATE_DIALOG`
);
export const setDisplayAssignmentStartDateChangeDialog = (
    createSingleAction<boolean, typeof SHOW_ASSIGNMENT_CHANGE_START_DATE_DIALOG>(
        SHOW_ASSIGNMENT_CHANGE_START_DATE_DIALOG,
    )
);

export const requestUpdateAssignmentStartDate = createRequestActions(
    RequestType.Update,
    'ASSIGNMENT_START_DATE',
    EMPLOYMENT_MANAGEMENT_PREFIX,
);
