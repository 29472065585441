import { pick } from 'lodash-es';
import { selectSheetApprovalState } from 'modules/sheetApproval/store/selectors';
import { SheetGroup } from 'modules/timeAndExpense/store/model';
import {
    getApproversBySheetId,
    getCheckedSheets,
    groupedSheetRowsSelector, selectCheckedExpenseSheetGroups, selectCheckedTimeSheetGroups,
} from 'modules/timeAndExpense/store/selectors';
import { createSelector } from 'reselect';
import { ItemsById } from 'shared/models/ItemsById';
import { IJobNumber } from 'shared/models/JobNumber';
import { IEntry, ISheet } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { CheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { IStore } from 'store/configureStore';
import { selectClientTimeAndPayConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import {
    selectAssignmentsById,
    selectJobNumbersById,
    selectSubassignmentsByIds,
} from 'store/entities/configuration/configurationSelectors';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { ISheetGroup } from 'store/entities/timesheet/models/SheetApi';
import {
    selectAllEntries,
    selectCalculationsByTimesheetId,
    selectExpensesSheetsByIds,
    selectTimeSheetsByIds,
} from 'store/entities/timesheet/selectors';
import { selectUsersById } from 'store/entities/users/selectors';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';

const sheetApprovalTableCommonStateSelector = createSelector(
    selectSheetApprovalState,
    approvalState => approvalState.approvalTable,
);

export const approvalTableSelectors = getInfinityScrollSelectors<ISheetGroup<string>>(
    state => sheetApprovalTableCommonStateSelector(state).table,
    30,
    true,
);
export const approvalTableEntityType = createSelector(
    sheetApprovalTableCommonStateSelector,
    state => state.entryType,
);

export function getGroupKey(
    group: ISheetGroup<string>,
): string {
    return JSON.stringify({
        ...pick(group, ['user_id', 'status_id', 'period_start', 'period_end']),
        sheetIds: group.sheets,
    });
}

const groupedSheetsSelector = (
    groups: ISheetGroup<string>[],
    sheetsByIds: ItemsById<ISheet>,
    usersById: ItemsById<IUserInfo>,
    jobNumbersById: ItemsById<IJobNumber>,
    calculationsByTimesheetId: Record<string, ITimesheetCalculation>,
    approversBySheetId: Record<string, string[]>,
    entries: IEntry[],
    customFieldValuesByIds: ItemsById<ICustomFieldValue>,
): SheetGroup[] => {
    return groups.map(group => {
        const groupSheets = group.sheets.map(id => sheetsByIds[id]).filter(Boolean);
        const groupKey = getGroupKey(group);
        return new SheetGroup(
            groupKey,
            groupSheets,
            {
                jobNumbersById,
                usersById,
                calculationsByTimesheetId,
                approversBySheetId,
                entries,
                customFieldValuesByIds,
            },
        );
    }).filter(Boolean);
};

export const getTimeAndExpenseSheetsSelectors = (
    baseSheetSelector: (state: IStore) => ItemsById<ISheet>,
    selectCheckedGroupedSheets: (state: IStore) => CheckedItems,
) => {
    const selectApproversBySheetId = createSelector(
        state => Object.values(baseSheetSelector(state)),
        selectAssignmentsById,
        selectSubassignmentsByIds,
        selectJobNumbersById,
        selectClientTimeAndPayConfiguration,
        getApproversBySheetId,
    );
    const selectGroupedSheets = createSelector(
        approvalTableSelectors.selectItems,
        baseSheetSelector,
        selectUsersById,
        selectJobNumbersById,
        selectCalculationsByTimesheetId,
        selectApproversBySheetId,
        selectAllEntries,
        selectCustomFieldValuesByIds,
        groupedSheetsSelector,
    );
    const selectGroupedSheetRows = createSelector(
        selectGroupedSheets,
        groupedSheetRowsSelector,
    );
    const selectCheckedSheetsIds = createSelector(
        () => ({}),
        selectCheckedGroupedSheets,
        selectGroupedSheets,
        getCheckedSheets,
    );
    const selectCheckedSheets = createSelector(
        selectCheckedSheetsIds,
        baseSheetSelector,
        (ids, sheetByIds) => ids.map(id => sheetByIds[id]).filter(Boolean),
    );
    const selectSheetGroupById = (id: string) => (state: IStore): SheetGroup | undefined =>
        (selectGroupedSheets(state) || []).find(group => group.id === id);

    return {
        selectApproversBySheetId,
        selectGroupedSheets,
        selectGroupedSheetRows,
        selectCheckedSheetsIds,
        selectCheckedSheets,
        selectSheetGroupById,
    };
};

export const timeApprovalInfinityTableSelectors = getTimeAndExpenseSheetsSelectors(
    selectTimeSheetsByIds,
    selectCheckedTimeSheetGroups,
);
export const expenseApprovalInfinityTableSelectors = getTimeAndExpenseSheetsSelectors(
    selectExpensesSheetsByIds,
    selectCheckedExpenseSheetGroups,
);

export const selecSheetApprovalTableSort = (state: IStore) =>
    sheetApprovalTableCommonStateSelector(state).sheetApprovalTableSort;
