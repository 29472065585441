import React from 'react';
import { CommonPrePostInitializeReportPure } from '../../../subassignmentManagement/components/PreAndPostInitializeReports/CommonPrePostInitializeReport';
import { useSelector } from 'react-redux';
import { selectCcpImportRows } from './store/selectors';
import { getCcpBulkCreationReportCells } from './cells';

export interface ICcpReportOverviewItem {
    label: string;
    values: string[];
    className?: string;
}

interface IBulkCcpImportReportProps {
    onBack?: () => void;
    title: string;
    handleAction: () => void;
    buttonTitle?: string;
    isLoading?: boolean;
    overviewItems: ICcpReportOverviewItem[],
}

export function BulkCcpImportReport({
    title = 'Pre-Initialize Roll Up',
    buttonTitle = 'Create',
    onBack,
    handleAction,
    isLoading,
    overviewItems,
} : IBulkCcpImportReportProps) {
    const rows = useSelector(selectCcpImportRows);
    const cells = getCcpBulkCreationReportCells();
    return (
        <CommonPrePostInitializeReportPure
            title={title}
            buttonTitle={buttonTitle}
            buttonHandler={handleAction}
            isButtonLoading={isLoading}
            backButtonHandler={onBack}
            hideBackButton={!onBack}
            overviewItems={overviewItems}
            rows={rows}
            cells={cells}
            getRowId={row => row.ref_number}
        />
    );
}
