import React, { useCallback } from 'react';
import { selectDetailAssignmentId } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { ISubassignmentRow } from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import { setDetailAssignmentId } from 'modules/subassignmentManagement/store/actions';
import {
    AssignmentGroupHeader,
} from 'modules/subassignmentManagement/components/SubassignmentTable/AssignmentGroupHeader';
import {
    setAssignmentExpandedGroupId,
} from 'modules/subassignmentManagement/components/SubassignmentTable/store/actions';
import {
    selectEditSubassignmentId,
} from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ITableGroupHeaderProps } from 'shared/components/table/VirtualizedGridTable/model';
import { selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';

export const SubassignmentTableGroupHeader = ({ group, style }: ITableGroupHeaderProps<ISubassignmentRow>) => {
    const { id, rows } = group;
    const dispatch = useDispatch();
    const assignmentDetailId = useSelector(selectDetailAssignmentId);
    const subassignmentDetailId = useSelector(selectEditSubassignmentId);
    const subassignmentsById = useSelector(selectSubassignmentsByIds);

    const checkGroupIsOpened = useCallback((rowId: string) => {
        if (assignmentDetailId === rowId) {
            return true;
        }
        if (subassignmentDetailId) {
            const subassignment = subassignmentsById[subassignmentDetailId];
            return subassignment?.assignment_id === rowId;
        }
        return false;
    }, [assignmentDetailId, subassignmentDetailId, subassignmentsById]);
    const handleOpen = useCallback(() => {
        const isAssignmentOpened = id === assignmentDetailId;
        dispatch(setDetailAssignmentId(isAssignmentOpened ? null : id));
        dispatch(setAssignmentExpandedGroupId(id));
    }, [dispatch, id, assignmentDetailId]);

    return (
        <AssignmentGroupHeader
            assignmentId={id}
            isOpened={checkGroupIsOpened(id)}
            onOpen={handleOpen}
            rows={rows}
            style={style}
        />
    );
};
