import { List } from '@material-ui/core';
import React from 'react';
import { TransactionPropertyListItem } from './TransactionPropertyListItem';
import moment from 'moment';
import { shortDateFormat } from '../../../../shared/models/Dates';
import { useCcpMainStyles } from '../../styles';

export interface ICcpTransactionInfoProps {
    purchase_date: string;
    merchant_name: string;
    merchant_desc: string;
    transaction_type_name: string;
}

export const TransactionPropertyList = ({
    purchase_date,
    merchant_name,
    merchant_desc,
    transaction_type_name,
}: ICcpTransactionInfoProps) => {

    const sheetDetailClasses = useCcpMainStyles();

    return (
        <List className={sheetDetailClasses.listWrapper}>
            <TransactionPropertyListItem
                name={'Purchase Date:'}
                data={moment(purchase_date).format(shortDateFormat)}
            />
            <TransactionPropertyListItem
                name={'Merchant:'}
                data={merchant_name}
            />
            <TransactionPropertyListItem
                name={'Merchant Description:'}
                data={merchant_desc}
            />
            <TransactionPropertyListItem
                name={'Transaction Type:'}
                data={transaction_type_name}
            />
        </List>
    );
};
