import { Chip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React, { useCallback, useMemo } from 'react';
import { useFiltersChipsStyles } from 'shared/components/filters/FiltersChipsStyles';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';

interface IDateRangeFilterChipProps {
    startName: string;
    endName: string;
    label: string;
    startValue: string | null;
    endValue: string | null;
    onDelete: (key: string, keyEnd: string) => void;
    classes?: Record<'root' | 'deleteIcon', string>;
}

export default function DateRangeFilterChip({
    startName,
    endName,
    startValue,
    endValue,
    onDelete,
    label,
    classes: propClasses,
}: IDateRangeFilterChipProps) {
    const defaultClasses = useFiltersChipsStyles();
    const classes = propClasses || defaultClasses;
    const handleDelete = useCallback(() => {
        onDelete(startName, endName);
    }, [startName, endName, onDelete]);
    const displayValue = useMemo(() => {
        if (startValue && endValue) {
            return `${label} ${formatShortDate(startValue)} - ${formatShortDate(endValue)}`;
        }
        if (startValue) {
            return `${label} after ${formatShortDate(startValue)}`;
        }
        if (endValue) {
            return `${label} before ${formatShortDate(endValue)}`;
        }
        return null;
    }, [label, endValue, startValue]);

    if (!displayValue) {
        return null;
    }

    return (
        <Chip
            clickable
            classes={classes}
            label={displayValue}
            onDelete={handleDelete}
            onClick={handleDelete}
            deleteIcon={<Clear/>}
        />
    );
}
