import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useSkeletonStyles } from './GridTableStyles';

interface IGridTableSkeletonProps {
    hasHeader?: boolean;
}

export default function GridTableSkeleton({ hasHeader = false }: IGridTableSkeletonProps) {
    const classes = useSkeletonStyles();

    return (
        <Box
            className={classes.skeletonWrapper}
            data-testid="table-skeleton-loader"
        >
            {hasHeader && (
                <Skeleton
                    className={classes.skeletonHeader}
                    animation="wave"
                    variant="rect"
                    height={53}
                />
            )}
            <Box className={classes.skeletonRows}>
                <Skeleton
                    className={classes.skeletonLine}
                    animation="wave"
                    height={24}/>
                <Skeleton
                    className={classes.skeletonLine}
                    animation="wave"
                    height={24}/>
            </Box>
        </Box>
    );
}
