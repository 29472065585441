import { createSelector } from 'reselect';
import {
    selectPreInitializeDeleteReportState,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { IBulkDeleteSubassignmentsResponse } from 'modules/subassignmentManagement/store/models';

export const deletePreInitializeReportOverviewItemsSelector = createSelector(
    selectPreInitializeDeleteReportState,
    (preinitializeReportState: IBulkDeleteSubassignmentsResponse) => {
        const numberOfFailed = preinitializeReportState?.number_of_subassignments_failed;
        const numberOfSucceded = preinitializeReportState?.number_of_subassignments;
        return [
            {
                label: '# of sub-assignments being deleted',
                values: [String(numberOfSucceded)],
            },
            {
                label: '# of sub-assignments that will fail',
                values: [String(numberOfFailed)],
            },
        ];
    },
);
