import React from 'react';
import { EafStatusSlug } from 'modules/employeeActionsForm/store/models';
import { setEafApprovalActiveTab } from 'modules/eafApproval/store/actions';
import { EafTabs } from 'modules/eafApproval/store/model/data';
import { selectEafApprovalActiveTab } from 'modules/eafApproval/store/selectors';
import { HeaderTabs } from 'shared/components/common/headerTabs/HeaderTabs';
import { useTabManagement } from 'shared/hooks/useTabManagement';
import { EafPageTitle } from '../store/model/types';

export const EafApprovalTabs: React.FC = () => {
    const { activeTab, setActiveTab } = useTabManagement<EafStatusSlug>(
        // @ts-ignore
        selectEafApprovalActiveTab,
        setEafApprovalActiveTab,
    );

    return (
        <HeaderTabs
            tabs={EafTabs}
            mobileHeader={EafPageTitle}
            value={activeTab}
            onChange={setActiveTab}
        />
    );
};
