/* eslint-disable react/display-name */
import clsx from 'clsx';
import { IDetailTableRow } from 'modules/offerLetter/components/OfferLetterTableDetail/model';
import { useDetailTableStyles } from 'modules/offerLetter/components/OfferLetterTableDetail/styles';
import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';

export const useOfferLetterDetailCells = () => {
    const classes = useDetailTableStyles();
    return [
        {
            key: 'title',
            render: ({ className, title }: ICellProps<IDetailTableRow>) => (
                <PlainText className={clsx(className, classes.title)} value={title}/>
            ),
        },
        {
            key: 'title',
            width: '2fr',
            render: ({ className, value, customClassName }: ICellProps<IDetailTableRow>) => (
                <PlainText className={clsx(className, customClassName)} value={value}/>
            ),
        },
    ];
};
