export const timeFormatter = (value: string) => {
    if (!value.includes(':')) {
        if (value.length > 4) {
            value = value.substring(0, 4);
        }
        if (value.length >= 3) {
            const hoursLength = value.length === 3 ? 1 : 2;
            const hours = value.substring(0, hoursLength);
            const minutes = value.substring(hoursLength, hoursLength + 2);
            if (parseInt(minutes) > 60) {
                return value;
            }
            const result = `${hours}:${minutes}`;
            return result;
        } else {
            return value;
        }
    }
    return value;
};
