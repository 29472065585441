import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserName } from 'shared/utils/converters/user';
import { IEmployeeUserInfo } from 'shared/models/User';
import { selectUsersById } from 'store/entities/users/selectors';
import OfferLetterFormWrapper from 'modules/offerLetter/components/OfferLetterForm/OfferLetterFormWrapper';
import { selectIsOfferEditing } from 'modules/offerLetter/components/EditOffer/store/selectors';
import { editOffer } from '../../store/actions';
import { IOfferLetter } from '../../store/model';

export interface IEditOfferSidebarProps {
    onCancel: () => void;
    offerLetter: IOfferLetter;
}

const EditOfferSidebar = ({ onCancel, offerLetter }: IEditOfferSidebarProps) => {
    const { first_name, last_name, email, cell_phone, id } = offerLetter;
    const user: IEmployeeUserInfo = {
        first_name,
        last_name,
        email,
        cell_phone: cell_phone,
    };
    const usersById = useSelector(selectUsersById);
    const managers = offerLetter.managers.map(m => usersById[m.user_id]);

    const dispatch = useDispatch();
    const onSubmit = useCallback(
        offerData => dispatch(editOffer.init({ id, values: offerData })),
        [id, dispatch],
    );
    const isLoading = useSelector(selectIsOfferEditing);

    return (
        <OfferLetterFormWrapper
            onCancel={onCancel}
            onSubmit={onSubmit}
            selectedUser={user}
            offer={offerLetter}
            managers={managers}
            isLoading={isLoading}
            confirmMessage={offerLetterData => `Are you sure you want to send ${getUserName(offerLetterData)} a new offer letter? Old offer will be rejected`}
        />
    );
};

export default EditOfferSidebar;
