import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEditSheetGroup } from 'modules/sheetApproval/store/actions';
import { selectEditSheetGroup } from 'modules/sheetApproval/store/selectors';
import { EditSheetView } from 'modules/timeAndExpense/components/EditSheet/EditSheetView';
import { EntryType, IExpenseSheet, ITimeSheet } from 'shared/models/sheet/Sheet';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentUser, selectIsUserHasPermission } from 'store/components/auth/selectors';
import { getSubassignments } from 'store/entities/configuration/configurationAction';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import {
    selectExpensesSheets,
    selectIsSheetStatusUpdating,
    selectSheet,
    selectTimeSheets,
} from 'store/entities/timesheet/selectors';

export const EditSheetPage = () => {
    const dispatch = useDispatch();
    const editSheetGroup = useSelector(selectEditSheetGroup);

    const { sheetIds, entryType } = editSheetGroup || { sheetIds: [], entryType: EntryType.TIME };

    const onClose = useCallback(() => {
        dispatch(setEditSheetGroup(null));
    }, [dispatch]);

    const sheet = useSelector(selectSheet(sheetIds[0]));

    const [userId] = useState<string | null>(sheet?.user_id || null);
    const [assignmentId] = useState<string | null>(sheet?.assignment_id || null);
    const [payPeriod] = useState<IPayPeriod | null>(sheet ? {
        period_start: sheet?.period_start,
        period_end: sheet?.period_end,
    } : null);
    const [statusName] = useState<StatusNames>(sheet?.status.name || StatusNames.SUBMITTED);
    const [sheetGroupId] = useState<string | undefined>(sheet?.sheet_group_id);

    const currentUser = useSelector(selectCurrentUser);
    const currentUserCanApproveAllSheets = useSelector(selectIsUserHasPermission(Permission.ApproveAllSheets));
    // tmp fix until admin don't have an access to HR
    const isAdmin = useSelector(selectIsUserHasPermission(Permission.ManageEverything));
    useEffect(() => {
        if (assignmentId) {
            dispatch(getSubassignments.init({
                assignment_ids: [assignmentId],
                approver_user_id: currentUserCanApproveAllSheets || isAdmin ? undefined : currentUser?.id,
            }));
        }
    }, [dispatch, assignmentId, currentUserCanApproveAllSheets, isAdmin, currentUser]);

    const allSheets = useSelector<Array<IExpenseSheet | ITimeSheet>>(
        entryType === EntryType.TIME ? selectTimeSheets : selectExpensesSheets,
    ) as Array<IExpenseSheet | ITimeSheet>;
    const sheetsForSubmission = useMemo(() => {
        return allSheets.filter(userSheet => (
            userSheet.sheet_group_id === sheetGroupId
            && sheetGroupId
            && userSheet.status.name === statusName
        ));
    }, [allSheets, sheetGroupId, statusName]);
    const onSheetSubmit = useCallback(() => {
        dispatch(updateSheetsStatus.init({
            statusName: StatusNames.APPROVED,
            sheetsEntryTypes: sheetsForSubmission.reduce((acc: Record<string, EntryType>, userSheet) => ({
                ...acc,
                [userSheet.id]: entryType,
            }), {}),
        }));
    }, [dispatch, sheetsForSubmission, entryType]);
    const isSubmitInProgress = useSelector(selectIsSheetStatusUpdating);

    if (!userId || !payPeriod) {
        onClose();
        return null;
    }

    return (
        <EditSheetView
            onSheetSubmit={onSheetSubmit}
            onClose={onClose}
            timeSheetIds={entryType === EntryType.TIME ? sheetIds : null}
            expenseSheetIds={entryType === EntryType.EXPENSE ? sheetIds : null}
            statusName={statusName}
            showAllEntriesForPeriod={payPeriod}
            isSubmitInProgress={isSubmitInProgress}
            isPayroll
        />
    );
};
