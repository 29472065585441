import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useSelector } from 'react-redux';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectPhysicalDemandsSortedByName } from 'store/entities/configuration/configurationSelectors';
import { IPhysicalDemandsTemplate } from 'store/entities/configuration/configurationModel';
import { OfferLetterTemplatedTextField } from './OfferLetterTemplatedTextField';

interface IOfferLetterPhysicalDemandsProps extends IFormFieldProps {
    forceValidate?: () => void;
}

interface IOfferLetterPhysicalDemandsStoreProps {
    templates?: IPhysicalDemandsTemplate[];
}

export function OfferLetterPhysicalDemandsPure({
    templates,
    ...props
}: IOfferLetterPhysicalDemandsStoreProps & IOfferLetterPhysicalDemandsProps) {
    return (
        <OfferLetterTemplatedTextField
            labelTemplate="Select Physical Demand Template"
            templates={templates || []}
            {...props}
        />
    );
}

export const OfferLetterPhysicalDemands = separateLogicDecorator<
IOfferLetterPhysicalDemandsProps,
IOfferLetterPhysicalDemandsStoreProps
>(() => ({
    templates: useSelector(selectPhysicalDemandsSortedByName),
}))(OfferLetterPhysicalDemandsPure);
