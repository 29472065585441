import React, { useCallback } from 'react';
import { Chip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { useFiltersChipsStyles } from 'shared/components/filters/FiltersChipsStyles';

interface IFilterChipProps<NameType> {
    name: NameType;
    label: string;
    onDelete: (key: NameType) => void;
    classes?: Record<'root' | 'deleteIcon', string>;
}

export default function FilterChip<NameType>({
    label,
    onDelete,
    name,
    classes: propClasses,
}: IFilterChipProps<NameType>) {
    const defaultClasses = useFiltersChipsStyles();
    const classes = propClasses || defaultClasses;
    const handleDelete = useCallback(() => onDelete(name), [name, onDelete]);

    return (
        <Chip clickable
            classes={classes}
            label={label}
            onDelete={handleDelete}
            onClick={handleDelete}
            deleteIcon={<Clear/>}
        />
    );
}
