import { createSingleAction } from 'store/utils';

const SET_OPEN_BULK_CREATE_CCP_UPLOAD_MODAL = 'ccp/SET_OPEN_BULK_CREATE_CCP_TRANSACTIONS_UPLOAD_MODAL';
export const setOpenBulkCreateCcpUploadModal = createSingleAction<
boolean, typeof SET_OPEN_BULK_CREATE_CCP_UPLOAD_MODAL>(SET_OPEN_BULK_CREATE_CCP_UPLOAD_MODAL);

export enum BulkCreateCcpTransactionsSteps {
    Form,
    Verify,
    PostReport,
}

const SET_BULK_IMPORT_CCP_TRANSACTIONS_STEP = 'ccp/SET_BULK_IMPORT_CCP_TRANSACTIONS_STEP';

export const SetBulkCcpStep = createSingleAction<
BulkCreateCcpTransactionsSteps, typeof SET_BULK_IMPORT_CCP_TRANSACTIONS_STEP>(SET_BULK_IMPORT_CCP_TRANSACTIONS_STEP);
