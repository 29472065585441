import { Skeleton } from '@material-ui/lab';
import { SheetIdsInfo } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/batch/SheetIdsInfo';
import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { ISheetInfoItemData, SheetInfoItem } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { useSheetDetailStyles } from './styles';

export interface ISheetInfoPureProps {
    data: ISheetInfoItemData[];
    isLoading?: boolean;
}

function renderInfoItem(item: ISheetInfoItemData) {
    if (!item.value) {
        return null;
    }
    if (item.value.split(',').length > 2) {
        return <SheetIdsInfo key={item.title} data={item}/>;
    }
    return <SheetInfoItem key={item.title} data={item}/>;
}

export const SheetInfoPure = ({ data, isLoading = false }: ISheetInfoPureProps) => {
    const classes = useSheetDetailStyles();

    return (
        <List className={classes.listWrapper}>
            {data.map(item => renderInfoItem(item))}
            {isLoading && Array(3).fill(0).map((_, i) => (
                <ListItem classes={{ root: classes.timesheetInfoItem }} key={i}>
                    <Skeleton width="100%"/>
                </ListItem>
            ))}
        </List>
    );
};
