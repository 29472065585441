import { selectCurrentClientInputsConfiguration } from 'store/entities/clients/selectors/fieldSelectors';
import { selectIsJobNumberFieldsApplied } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { Nullable } from 'types/types';
import { pick } from 'lodash-es';
import { useUserDefaultDepartment } from 'modules/employmentInfo/store/department/utils';

import { selectCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/selectors';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';
import { defaultValues as defaultExpenseValues, IExpenseEntryFormValues } from 'shared/components/forms/entries/ExpenseEntryModel';
import { defaultValues as defaultTimeValues, ITimeEntryFormValues } from 'shared/components/forms/entries/TimeEntryModel';
import { isDateInPayPeriod, isDateInPeriod } from 'shared/models/Dates';
import { ItemsById } from 'shared/models/ItemsById';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { useEntryDate } from 'shared/utils/hooks/useEntryDate';
import { logger } from 'shared/utils/logging/logger';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { InputFields } from 'store/entities/clients/clientsModel';

import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { IAssignment, IProjectWithAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { ProjectIdsByAssignmentId } from 'store/entities/configuration/configurationReducer';
import { selectActivitiesById, selectAssignmentProjectIds, selectAssignmentsById, selectSubassignmentsByIds, selectSubassignmentsByUserId } from 'store/entities/configuration/configurationSelectors';
import { HierarchyNodeOperation, ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldHierarchyNodesByIds, selectCustomFieldValuesByIds, selectOrderedCustomFieldAssignmentNodes, selectOrderedCustomFieldIds } from 'store/entities/customFields/selectors';
import { CommonEntryBackend } from 'store/entities/timesheet/models/Entry';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { maxMomentDate, moment } from 'utils/momentExtensions';

/**
 * Build common backend model for entry from form value
 * @param userId - employee user id
 * @param values - form value
 * @param defaultTask - default task value
 * @param assignmentsById - all available assignments by id
 * @param assignmentProjectId - Map all project ids by assignment id
 * @param clientId - current client id
 * @param useCustomFields - use custom fields values in payload
 * @param subassignmentsByIds - available subassignments
 * @param customFieldValuesByIds - custom field values
 * @param nonActionableCustomFields - non actionable fields list
 */
const getCommonEntryModel = (
    userId: string,
    values: ICommonEntryFormValues,
    defaultTask: Nullable<string>,
    assignmentsById: ItemsById<IAssignment>,
    assignmentProjectId: ProjectIdsByAssignmentId,
    clientId: string,
    useCustomFields: boolean,
    subassignmentsByIds: ItemsById<ISubassignment> = {},
    customFieldValuesByIds: ItemsById<ICustomFieldValue> = {},
    nonActionableCustomFields: string[] = [],
): CommonEntryBackend | null => {
    const taskId = entryFormGetTask(values, defaultTask);
    const { assignmentId, projectId } = entryFormGetAssignment(
        userId,
        values,
        assignmentsById,
        assignmentProjectId,
        clientId,
        subassignmentsByIds,
        customFieldValuesByIds,
    );
    if (useCustomFields && !nonActionableFieldsIsFilled(
        values,
        customFieldValuesByIds,
        nonActionableCustomFields,
    )) {
        logger.warning(new Error('Non actionable fields is not filled'), values);
    }
    if (!assignmentId) {
        logger.error(new Error('Unable to find appropriate assignment for entry'), values);
        return null;
    }
    const jobNumberId = values.jobNumber?.id;
    return {
        assignment_id: assignmentId,
        project_id: projectId || undefined,
        job_number_id: jobNumberId || undefined,
        activity_id: values.activity?.id || undefined,
        task_id: taskId,
        position_id: values.position?.id || null,
        location_id: values.location?.id || null,
        department_id: values.department?.id || undefined,
        entry_date: values.entry_date,
        notes: values.notes,
        custom_field_value_ids: useCustomFields
            ? Object.values(values.customFieldValues || {}).filter(Boolean)
            : undefined,
    } as CommonEntryBackend;
};

export const useGetCommonEntryModel = (
    userId: string,
    defaultTask: Nullable<string> = null,
) => {
    const assignmentProjectId = useSelector(selectAssignmentProjectIds);
    const assignmentsById = useSelector(selectAssignmentsById);
    const subassignmentsByIds = useSelector(selectSubassignmentsByIds);
    const clientId = useSelector(selectCurrentClientId);
    const jobNumberField = useSelector(selectIsJobNumberFieldsApplied);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const [nonActionableCustomFields] = useActionableCustomFieldValue();

    return useCallback((values: ICommonEntryFormValues) => {
        return getCommonEntryModel(
            userId,
            values,
            defaultTask,
            assignmentsById,
            assignmentProjectId,
            clientId,
            !jobNumberField,
            subassignmentsByIds,
            customFieldValuesByIds,
            nonActionableCustomFields,
        );
    }, [
        userId,
        assignmentProjectId,
        assignmentsById,
        clientId,
        customFieldValuesByIds,
        defaultTask,
        subassignmentsByIds,
        jobNumberField,
        nonActionableCustomFields,
    ]);
};

interface IProjectAndAssignmentIds {
    assignmentId: Nullable<string>,
    projectId: Nullable<string>,
}

export function getJobNumberAssignmentAndProject(
    jobNumberUserId: string | undefined,
    clientId: string,
    assignmentsById: ItemsById<IAssignment>,
    projectIdsByAssignmentId: ProjectIdsByAssignmentId,
    entryDate: string,
){
    const currentAssignment = Object.values(assignmentsById).find(
        assignment => jobNumberUserId === assignment.user_id
            && assignment.client_id === clientId
            // @ts-ignore
            && isDateInPeriod(assignment.hire_date, assignment.end_date, entryDate),
    ) || null;
    return {
        assignmentId: currentAssignment?.id,
        projectId: (projectIdsByAssignmentId[currentAssignment?.id || ''] || [])[0], // default project
    };
}

export function nonActionableFieldsIsFilled(
    values: ICommonEntryFormValues,
    customFieldValuesByIds: ItemsById<ICustomFieldValue> = {},
    nonActionableCustomFields: string[] = [],
): boolean {
    if (nonActionableCustomFields.length > 0) {
        const filledFieldIds = Object.values(values.customFieldValues || {})
            .map(cfvId => customFieldValuesByIds[cfvId])
            .map(fieldValue => fieldValue?.custom_field_id)
            .filter(Boolean);
        nonActionableCustomFields.forEach(fieldId => {
            if (!filledFieldIds.includes(fieldId)) {
                return false;
            }
        });
    }
    return true;
}

export function entryFormGetAssignment(
    userId: string,
    values: ICommonEntryFormValues,
    assignmentsById: ItemsById<IAssignment>,
    projectIdsByAssignmentId: ProjectIdsByAssignmentId,
    clientId: string,
    subassignmentsByIds: ItemsById<ISubassignment>,
    customFieldValuesByIds: ItemsById<ICustomFieldValue> = {},
): IProjectAndAssignmentIds {
    if (values.position && values.location) {
        const assignment = Object.values(assignmentsById).find(
            ({ location_id, position_id }) => location_id === values.location?.id
                && position_id === values.position?.id,
        ) || null;
        return {
            assignmentId: assignment?.id,
            projectId: (projectIdsByAssignmentId[assignment?.id || ''] || [])[0], // default project
        };
    }
    if (values.jobNumber) {
        return getJobNumberAssignmentAndProject(
            values.jobNumber?.user_id,
            clientId,
            assignmentsById,
            projectIdsByAssignmentId,
            values.entry_date,
        );
    }
    if (isNotEmpty(values.customFieldValues || {})) {
        const customFieldValueIds = Object.values(values.customFieldValues || {}).filter(Boolean);
        const subassignment = Object.values(subassignmentsByIds)
            .find(item => {
                const assignment = assignmentsById[item.assignment_id];
                if (!assignment || assignment.user_id !== userId) {
                    return false;
                }
                return customFieldValueIds.every(id => {
                    const customFieldValue = customFieldValuesByIds[id];
                    return item.custom_field_value_ids?.includes(id)
                        || item.all_values_custom_field_ids?.includes(customFieldValue?.custom_field_id);
                }) && isDateInPeriod(item.start_date, item.end_date, values.entry_date);
            });
        return {
            assignmentId: subassignment?.assignment_id,
            projectId: (projectIdsByAssignmentId[subassignment?.assignment_id || ''] || [])[0], // default project
        };
    }

    const { projectAssignment } = values;
    return {
        assignmentId: projectAssignment?.assignment.id,
        projectId: projectAssignment?.project_id,
    };
}

export function entryFormGetTask(values: ICommonEntryFormValues, defaultTask: Nullable<string>) {
    // Task Id value is not saved when set as default value
    if (defaultTask) {
        return defaultTask;
    }
    return values.taskId;
}

export function useDefaultActivity(defaultValues: ICommonEntryFormValues, inputs: InputFields) {
    const activitiesById = useSelector(selectActivitiesById);

    if (inputs.activity && inputs.activity.default_value) {
        defaultValues.activity = activitiesById[inputs.activity.default_value];
        return activitiesById[inputs.activity.default_value];
    }
}

export const normalizeCommonValuesForState = (formValues: ICommonEntryFormValues) => {
    return pick(
        formValues,
        [
            'location',
            'position',
            'assignment',
            'department',
            'entry_date',
            'projectAssignment',
            'taskId',
            'jobNumber',
            'customFieldValues',
        ],
    ) as ICommonEntryFormValues;
};

function useCustomFieldDefaultValues() {
    const customFieldsIds = useSelector(selectOrderedCustomFieldIds);
    return useMemo(() => {
        return customFieldsIds.reduce((mem, fieldId) => {
            return {
                ...mem,
                [fieldId]: null,
            };
        }, {});
    }, [customFieldsIds]);
}

export function useExpenseEntryDefaultValues(
    userId?: string | null,
): IExpenseEntryFormValues {
    const commonValues = useSelector(selectCommonEntryFormValues);
    const inputsConfiguration = useSelector(selectCurrentClientInputsConfiguration);
    const defaultActivity = useDefaultActivity(defaultExpenseValues, inputsConfiguration?.expense || {} as InputFields);
    const defaultDepartment = useUserDefaultDepartment(userId);
    const customFieldValues = useCustomFieldDefaultValues();
    return useMemo(() => ({
        ...defaultExpenseValues,
        activity: defaultActivity ?? null,
        department: defaultDepartment ?? null,
        ...commonValues,
        customFieldValues: {
            ...customFieldValues,
            ...commonValues.customFieldValues,
        },
    }), [commonValues, customFieldValues, defaultActivity, defaultDepartment]);
}

export function useTimeEntryDefaultValues(
    userId?: string | null,
): ITimeEntryFormValues {
    const commonValues = useSelector(selectCommonEntryFormValues);
    const inputsConfiguration = useSelector(selectCurrentClientInputsConfiguration);
    const defaultActivity = useDefaultActivity(defaultTimeValues, inputsConfiguration?.time || {} as InputFields);
    const defaultDepartment = useUserDefaultDepartment(userId);
    const customFieldValues = useCustomFieldDefaultValues();
    return useMemo(() => ({
        ...defaultTimeValues,
        activity: defaultActivity ?? null,
        department: defaultDepartment ?? null,
        ...commonValues,
        customFieldValues: {
            ...customFieldValues,
            ...commonValues.customFieldValues,
        },
    }), [commonValues, customFieldValues, defaultActivity, defaultDepartment]);
}

/**
 * Update selected day on change pay period
 * @param payPeriod - selected pay period
 * @param onChangeCommonValues - callback for change common values
 */
export function useDayUpdateOnChangePayPeriod(
    payPeriod: IPayPeriod,
    onChangeCommonValues: (values: ICommonEntryFormValues) => void,
) {
    const commonValues = useSelector(selectCommonEntryFormValues);
    const defaultEntryDateValue = useEntryDate(payPeriod);
    const previousDefaultDate = useRef('');
    useEffect(() => {
        if (previousDefaultDate.current !== defaultEntryDateValue) {
            // Set a new day on change current pay period
            previousDefaultDate.current = defaultEntryDateValue;
            onChangeCommonValues({
                ...commonValues,
                entry_date: defaultEntryDateValue,
            });
        }
    }, [defaultEntryDateValue, commonValues, onChangeCommonValues, previousDefaultDate]);
}

export function getProjectAssignmentByProjectAndAssignmentIds(
    projectAssignments: IProjectWithAssignment[],
    projectId?: string,
    assignmentId?: string,
) {
    return projectAssignments.find(
        item => item.project_id === projectId && item.assignment?.id === assignmentId,
    ) || null;
}

export function useActionableCustomFieldValue(): [string[], string[]] {
    const hierarchy = useSelector(selectOrderedCustomFieldAssignmentNodes);
    return useMemo(() => {
        const actionable: string[] = [];
        const nonActionable: string[] = [];
        hierarchy.forEach(node => {
            if (node.operation === HierarchyNodeOperation.Actionable) {
                actionable.push(node.custom_field_id);
            } else {
                nonActionable.push(node.custom_field_id);
            }
        });
        return [actionable, nonActionable];
    }, [hierarchy]);
}

/**
 * Set value & reset for related non actionable fields
 * Selection based on selected actionable custom field values and subassignment custom field values
 *
 * @param customFieldValues values selected on form
 * @param setFieldValue callback for set new value
 * @param subassignments
 * @param updateCallback
 */
export function useNestedCustomFieldValuesUpdate(
    customFieldValues: Record<string, string> | undefined,
    setFieldValue: (fieldName: string, value: any) => void,
    subassignments: ISubassignment[],
    updateCallback?: () => void,
) {
    const storedValuesRef = useRef<Record<string, string>>(customFieldValues || {});
    const valuesByIds = useSelector(selectCustomFieldValuesByIds);
    const commonEntryStoreValues = useSelector(selectCommonEntryFormValues);

    const [actionableCustomFields, nonActionableCustomFields] = useActionableCustomFieldValue();

    useEffect(() => {
        if (customFieldValues) {
            let hasChanges = false;
            const selectedActionableValues = pick(customFieldValues, actionableCustomFields);
            const allActionableFieldsAreSelected = Object.values(selectedActionableValues).filter(Boolean).length
                === actionableCustomFields.length;
            Object.keys(selectedActionableValues).forEach(customFieldId => {
                if (customFieldValues[customFieldId] !== storedValuesRef.current[customFieldId]) {
                    hasChanges = true;
                }
            });
            if (hasChanges) {
                storedValuesRef.current = { ...customFieldValues };

                if (subassignments.length === 1 || (allActionableFieldsAreSelected && subassignments.length >= 1)) {
                    // fill non actionable values by subassignment values
                    // @ts-ignore
                    const subassignmentNonActionableValues = subassignments[0]?.custom_field_value_ids
                        .map(valueId => valuesByIds[valueId])
                        .filter(value => value && nonActionableCustomFields.includes(value.custom_field_id)) || [];
                    subassignmentNonActionableValues.forEach(fieldValue => {
                        setFieldValue(
                            `customFieldValues[${fieldValue.custom_field_id}]`,
                            fieldValue.id,
                        );
                    });
                    updateCallback && updateCallback();
                } else {
                    // clean all non actionable fields
                    nonActionableCustomFields.forEach(customFieldId => {
                        setFieldValue(
                            `customFieldValues[${customFieldId}]`,
                            null,
                        );
                    });
                    updateCallback && updateCallback();
                }
            }
        }
    }, [
        customFieldValues,
        storedValuesRef,
        setFieldValue,
        valuesByIds,
        commonEntryStoreValues,
        actionableCustomFields,
        subassignments,
        nonActionableCustomFields,
        updateCallback,
    ]);
}

/**
 * We should display only actionable fields on max hierarchy level that don't have selected value.
 * Also we should check that parent is actionable
 *
 * @return Record<{custom_field_id}, boolean> record that describe should we display input on the inline entry form
 */
export const useDisplayCustomFields = (): Record<string, boolean> => {
    const commonEntryStoreValues = useSelector(selectCommonEntryFormValues);
    const customFieldNodes = useSelector(selectOrderedCustomFieldAssignmentNodes);
    const nodesByIds = useSelector(selectCustomFieldHierarchyNodesByIds);

    return useMemo(() => {
        return customFieldNodes.reduce((mem, node) => {
            const parentNode = nodesByIds[node.parent_id || ''];
            const display = node.operation === HierarchyNodeOperation.Actionable
                && !commonEntryStoreValues.customFieldValues[node.custom_field_id]
                && (!parentNode
                    || commonEntryStoreValues.customFieldValues[parentNode.custom_field_id]
                    || parentNode?.operation !== HierarchyNodeOperation.Actionable);
            return {
                ...mem,
                [node.custom_field_id]: display,
            };
        }, {});
    }, [commonEntryStoreValues, customFieldNodes, nodesByIds]);
};

/**
 * Get subassignments filtered by payPeriod and selected custom file value list of subassignments
 */
export function useFilteredSubassignments(
    userId?: string,
    payPeriod?: IPayPeriod,
    selectedCustomValueIds: Record<string, string> = {},
    entryDate?: string,
): ISubassignment[] {
    const user = useSelector(selectCurrentUser);
    const userSubassignments = useSelector(selectSubassignmentsByUserId(userId || user?.id));
    const [actionableCustomFields] = useActionableCustomFieldValue();
    const customFieldValueByIds = useSelector(selectCustomFieldValuesByIds);

    const subassignmentsUpdateKey = JSON.stringify(userSubassignments);
    return useMemo(() => {
        let filteredSubassignments = [...userSubassignments];

        if (payPeriod) {
            const momentPayPeriod = moment.range(
                moment(payPeriod.period_start),
                moment(payPeriod.period_end).endOf('day'),
            );
            filteredSubassignments = filteredSubassignments.filter(subassignment => {
                const subassignmentPeriod = moment.range(
                    moment(subassignment.start_date),
                    subassignment.end_date ? moment(subassignment.end_date).endOf('day') : maxMomentDate,
                );
                return subassignmentPeriod.intersect(momentPayPeriod);
            });
        }
        if (entryDate) {
            filteredSubassignments = filteredSubassignments.filter(subassignment => {
                return isDateInPayPeriod({
                    period_start: subassignment.start_date,
                    period_end: subassignment.end_date,
                }, entryDate);
            });
        }

        // consider only actionable custom fields
        const selectedCustomValueIdsList = Object.values(
            pick(selectedCustomValueIds, actionableCustomFields),
        ).filter(Boolean) as string[];
        filteredSubassignments = filteredSubassignments
            .filter(subassignment => selectedCustomValueIdsList.every(
                selectedValueId => {
                    const value = customFieldValueByIds[selectedValueId];
                    // @ts-ignore
                    return subassignment.all_values_custom_field_ids.includes(value?.custom_field_id)
                        // @ts-ignore
                        || subassignment.custom_field_value_ids.includes(selectedValueId);
                },
            ));

        return filteredSubassignments;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        subassignmentsUpdateKey,
        payPeriod,
        selectedCustomValueIds,
        entryDate,
        actionableCustomFields,
        customFieldValueByIds,
    ]);
}
