import { useMemo } from 'react';
import { ValidationMessages } from 'shared/models/Validation';
import * as yup from 'yup';

export const useEndAssignmentValidationSchema = (requiredRehire: boolean, requireTerminationReason: boolean) => {
    return useMemo(() => {
        let validationSchema = yup.object({
            employee_action_form_type: yup.string().nullable().required(ValidationMessages.REQUIRED),
            effective_date: yup.string().nullable().required(ValidationMessages.REQUIRED),
        });

        if (requiredRehire || requireTerminationReason) {
            validationSchema = validationSchema.concat(yup.object({
                eligibleForRehire: yup.string().nullable().required(ValidationMessages.REQUIRED),
            }));
        }
        if (requireTerminationReason) {
            validationSchema = validationSchema.concat(yup.object({
                employee_action_form_reason: yup.string().nullable().required(ValidationMessages.REQUIRED),
            }));
        }

        return validationSchema;
    }, [requiredRehire, requireTerminationReason]);
};
