import React from 'react';
import { SyncButton } from 'modules/settings/common/components/SyncButton/SyncButton';
import { syncClientPaySettings } from 'modules/settings/submodules/clients/paySettings/components/SyncPaySettings/store/actions';
import { selectIsSyncPaySettingsInProgress } from 'modules/settings/submodules/clients/paySettings/components/SyncPaySettings/store/selectors';

export const SyncPaySettingsButton = () => {
    return (
        <SyncButton syncAction={syncClientPaySettings.init}
            isLoadingSelector={selectIsSyncPaySettingsInProgress}
            titleEntity={'Pay Settings'}/>
    );
};
