import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';

interface ICellWrapperProps {
    className: string;
    children: React.ReactChild;
}

export const CellWrapper = ({
    children,
    className,
}: ICellWrapperProps) => {
    const tableClasses = useDefaultTableStyles();
    const parentRef = useRef<HTMLDivElement | null>(null);
    const childRef = useRef<HTMLDivElement | null>(null);
    const [hasScroll, setHasScroll] = useState<boolean>(false);

    const close = useCallback(() => setHasScroll(false), [setHasScroll]);
    const handleHover = useCallback(() => {
        const scrollable = parentRef.current
            && childRef.current
            && parentRef.current?.clientHeight < childRef.current.clientHeight;
        setHasScroll(Boolean(scrollable));
    }, []);

    return (
        <div
            ref={parentRef}
            className={clsx(tableClasses.bodyCellExpandableWrapper, className)}
        >
            <div
                onMouseEnter={handleHover}
                onMouseLeave={close}
                className={clsx(
                    tableClasses.bodyCellWrapper,
                    {
                        [tableClasses.bodyCellExpandable]: hasScroll,
                    },
                )}
                ref={childRef}
            >
                {children}
            </div>
        </div>
    );
};
