import { useCallback, useState } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';

export function useModal(defaultState = false) {
    const [isModalOpened, setIsOpened] = useState(defaultState);

    const onModalOpen = useCallback(() => {
        setIsOpened(true);
    }, [setIsOpened]);

    const onModalClose = useCallback(() => {
        setIsOpened(false);
    }, [setIsOpened]);

    return {
        isModalOpened,
        onModalOpen,
        onModalClose,
    };
}

export function useReduxModal(
    modalSelector: (payload: IStore) => boolean,
    changeModalStateAction: (payload: boolean) => AnyAction) {

    const dispatch = useDispatch();

    const isModalOpened = useSelector(modalSelector);

    const openModal = useCallback(() => {
        dispatch(changeModalStateAction(true));
    }, [dispatch, changeModalStateAction]);

    const closeModal = useCallback(() => {
        dispatch(changeModalStateAction(false));
    }, [dispatch, changeModalStateAction]);

    return {
        isModalOpened,
        openModal,
        closeModal,
    };
}
