import { ICreatedUpdated, IEntity } from 'shared/models/Entity';

export enum ScopeType {
    Activity = 'activity',
    SubassignmentManagement = 'subassignment_management',
}

export const ScopeTypeDisplayNames: Record<ScopeType, string> = {
    [ScopeType.Activity]: 'Activity',
    [ScopeType.SubassignmentManagement]: 'Offer & Assignment Management',
};

export enum ScopeAction {
    Entry = 'entry',
    OfferLetters = 'offer_letters',
    Assignment = 'assignments',
    PromotionDemotion = 'promotion_demotion',
}

export const ScopeActionDisplayNames: Record<ScopeAction, string> = {
    [ScopeAction.Entry]: 'Entry',
    [ScopeAction.OfferLetters]: 'Offer Letters',
    [ScopeAction.Assignment]: 'Assignment',
    [ScopeAction.PromotionDemotion]: 'Promotion And Demotion',
};

export interface IScope extends IEntity, ICreatedUpdated {
    client_id: string;
    name: string;
    custom_field_value_ids: string[];
    activity_ids?: string[];
    user_ids?: string[];
    type: ScopeType;
    actions?: ScopeAction[];
    client_role_ids?: string[];
}

export interface IActivityScope extends IScope {
    type: ScopeType.Activity;
    activity_ids: string[];
}

export interface IUserScope extends IScope {
    type: ScopeType.SubassignmentManagement;
    user_ids: string[];
}

export interface IGetScopeRequest {
    scope_types?: ScopeType[];
    client_ids?: string[];
}

export interface IGetScopeCustomFieldRestrictionRequest extends IGetScopeRequest {
    mine?: boolean;
}

export interface IGroupedScopeCustomFieldValuesRequest {
    client_ids?: string[];
    scope_types?: ScopeType[];
    respect_relations?: boolean;
    actions?: ScopeAction[];
    client_role_ids?: string[];
}
export interface IGroupedScopeCustomFieldValues {
    group: {
        client_id: string;
        custom_field_id: string;
        action: ScopeAction;
    },
    custom_field_value_ids: string[];
}

export interface IGetUsersMatchingUsersScopesRequest {
    client_id: string,
    user_ids?: string[],
    exc_cf_names_query?: string,
    limit_cf_names?: string[],
}

export interface IUserMatchItem {
    user_id: string,
    matched_ids?: string[],
}

export interface IUserMatchItemResult extends IUserMatchItem, IEntity {
}

export interface IUserMatchResponse {
    user_matches?: IUserMatchItem[],
}

export type CustomFieldScopeRestrictions = Record<string, string[]>
export type CustomFieldScopeRestrictionsByAction = Partial<Record<ScopeAction, CustomFieldScopeRestrictions>>
