import {
    ICreateCustomField,
    IUpdateCustomFieldPayload,
} from 'modules/settings/submodules/customFields/store/models';
import { ICustomField } from 'store/entities/customFields/model';
import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

export const createCustomField = createRequestActions<ICreateCustomField, ICustomField>(
    RequestType.Create,
    'CUSTOM_FIELDS',
);

export const updateCustomField = createRequestActions<IUpdateCustomFieldPayload, ICustomField>(
    RequestType.Update,
    'CUSTOM_FIELDS',
);

const SET_CREATE_CUSTOM_FIELD_MODAL_STATE = 'SET_CREATE_CUSTOM_FIELD_MODAL_STATE';
export const setCreateCustomFieldModalState = createSingleAction<boolean, typeof SET_CREATE_CUSTOM_FIELD_MODAL_STATE>(
    SET_CREATE_CUSTOM_FIELD_MODAL_STATE,
);

const SET_EDIT_CUSTOM_FIELD_ID = 'SET_EDIT_CUSTOM_FIELD_ID';
export const setEditCustomFieldId = createSingleAction<string | null, typeof SET_EDIT_CUSTOM_FIELD_ID>(
    SET_EDIT_CUSTOM_FIELD_ID,
);
