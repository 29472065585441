import { useGroupedSheetTableStyles } from 'modules/payrollProcessorHub/components/PayrollSheetTable/styles/useGroupedSheetTableStyles';
import { useStickyCellsStyles } from 'modules/payrollProcessorHub/components/PayrollSheetTable/styles/useStickyCellsStyles';
import { IGroupedSheetCalculation, ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { IEntity } from 'shared/models/Entity';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { IUserInfo } from 'shared/models/User';
import { IClient } from 'store/entities/clients/clientsModel';
import { IAssignment } from 'store/entities/configuration/configurationModel';

export interface IPayrollSheetRow extends IGroupedSheetCalculation, IEntity {
    employee?: IUserInfo;
    position?: IPosition;
    assignment?: IAssignment;
    approvers: IUserInfo[];
    approvedBy: IUserInfo[];
    client?: IClient;
    location?: ILocation;
    time_approved_level: number | null;
    expense_approved_level: number | null;
}

export type TableClasses = ReturnType<typeof useDefaultTableStyles>;
export type CellClasses = ReturnType<typeof useGroupedSheetTableStyles>;
export type StickyCellClasses = ReturnType<typeof useStickyCellsStyles>;

export enum PayrollCells {
    Details = 'details',
    OverdueDetails = 'overdueDetails',
    Type = 'type',
    Employee = 'employee',
    PayPeriod = 'payPeriod',
    PayPeriodInfo = 'payPeriodInfo',
    Client = 'client',
    ApprovedLevel = 'approvedLevel',
    JobNumber = 'jobNumber',
    DealNumber = 'dealNumber',
    Files = 'files',
    RegularHours = 'regularHours',
    OvertimeHours = 'overtimeHours',
    DoubleTimeHours = 'doubleTimeHours',
    PTO = 'pto',
    PSL = 'psl',
    Expenses = 'expenses',
    Miles = 'miles',
    TotalPay = 'totalPay',
    Status = 'status',
    ApprovedByCell = 'approvedByCell',
    EditedByCell = 'editedByCell',
    Approvers = 'approvers',
    PayType = 'payType',
}

export interface IPayrollSheetGroupedRow extends ISheetCalculationBatch {
    employee?: IUserInfo;
    client?: IClient;
    approvedLevel: number;
    userTypes: string[],
    periodType: string;
    totalCount?: number;
}
