import React, { useCallback, useEffect } from 'react';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { Box } from '@material-ui/core';
import { PaginationCounter } from 'modules/settings/submodules/components/PaginationCounter/PaginationCounter';
import { employeesSelectors, selectEmployeeTableRows } from 'modules/users/employees/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useIsMobile } from 'shared/utils/hooks/media';
import {
    initialLoadEmployees,
    getMoreEmployeesAction,
} from 'modules/users/employees/store/actions';
import { IUserRow } from 'modules/settings/submodules/components/SharedTableRows/UsersRows';

interface IEmployeeTableProps {
    cells?: ICellInfo<IUserRow>[];
    hideTotal?: boolean;
}

export const EmployeeTable = ({ cells, hideTotal = false }: IEmployeeTableProps) => {
    const tableClasses = useDefaultTableStyles();
    const users = useSelector(selectEmployeeTableRows);
    const hasMoreRows = useSelector(employeesSelectors.selectHasMore);
    const total = useSelector(employeesSelectors.selectTotalItems);
    const isLoading = useSelector(employeesSelectors.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initialLoadEmployees());
    }, [dispatch]);

    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows){
            dispatch(getMoreEmployeesAction.init());
        }
    }, [dispatch, hasMoreRows]);

    const isMobile = useIsMobile();

    return (
        <Box mt={2} mb={2}>
            <InfinityGridTable
                rowData={users}
                getKey={row => row.id}
                // @ts-ignore
                cells={cells}
                isLoading={isLoading}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                hideHeader={isMobile}
                stickyHeader
                onLoadMore={onLoadMoreRows}
                hasMore={hasMoreRows}
            />
            {!hideTotal && (
                <PaginationCounter
                    shown={users.length}
                    total={total}
                />
            )}
        </Box>
    );
};
