import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { ICustomFieldValue, IPositionCustomField } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { Nullable } from '../../../../../types/types';
import PlainText from 'shared/components/table/Cells/PlainText';

export interface IPositionWithPayRateCellProps {
    className?: string;
    positionCustomFieldValueId: string;
    payRate: number;
    isLoading?: boolean;
}

export const PositionWithPayRateCell: React.FC<IPositionWithPayRateCellProps> = ({
    positionCustomFieldValueId,
    payRate,
    className,
    isLoading = false,
}: IPositionWithPayRateCellProps) => {
    const customFieldsValuesById = useSelector(selectCustomFieldValuesByIds);
    const position: Nullable<ICustomFieldValue> = customFieldsValuesById[positionCustomFieldValueId];
    const data = position?.data as IPositionCustomField;
    const money = formatDollars(payRate);
    const value = [data?.name, money].filter(Boolean).join(' – ');

    if (isLoading) {
        return (
            <Box className={className}>
                <Skeleton/>
            </Box>
        );
    }

    return <PlainText value={value} className={className}/>;
};
