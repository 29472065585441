import { IStore } from 'store/configureStore';
import { selectCurrentClient } from 'store/entities/clients/selectors/clientsSelectors';

export const selectClientsSettingsState = (state: IStore) => {
    return state.modules.settings.clientsSettings;
};
export const selectClientStatuses = (state: IStore) => {
    return selectClientsSettingsState(state).clientStatuses;
};
export const selectIsClientStatusesLoading = (state: IStore) =>
    selectClientsSettingsState(state).isClientStatusesLoading;
export const selectSettingClientsState = (state: IStore) => {
    return selectClientsSettingsState(state).clients;
};
export const selectClientActiveStatus = (state: IStore) => {
    return selectClientStatuses(state).find(status => status.name === 'Active');
};
export const selectIsNoGeocodeWarningPopupShown = (state: IStore) => {
    return selectSettingClientsState(state).isNoGeocodeWarningShown;
};

export const selectCurrentClientHasNoGeocode = (state: IStore) => {
    const client = selectCurrentClient(state);
    return client && !Number(client?.main_location?.geo_code);
};

export const selectClientTableSort = (state: IStore) => selectSettingClientsState(state).clientTableSort;
