import { IStore } from 'store/configureStore';
import { selectSubmittingOrgState } from 'modules/settings/submodules/clients/submittingOrg/store/selectors';

export const selectCreateSubmittingOrg = (state: IStore) => {
    const submittingOrgState = selectSubmittingOrgState(state);
    return submittingOrgState.createSubmittingOrg;
};

export const selectIsSubmittingOrgCreating = (state: IStore) => {
    const createSubmittingOrgState = selectCreateSubmittingOrg(state);
    return createSubmittingOrgState.isSubmittingOrgCreating;
};

export const selectIsSubmittingOrgModalOpen = (state: IStore) => {
    const createSubmittingOrgState = selectCreateSubmittingOrg(state);
    return createSubmittingOrgState.isCreateSubmittingOrgModalOpen;
};
