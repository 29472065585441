import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useHandleAddressSelect } from 'modules/settings/submodules/components/AddressFormBlock/useHandleAddressSelect';
import React from 'react';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import FormField from 'shared/components/formFields/FormField';
import AddressAutocompleteField from 'shared/components/formSpecialFields/addressAutocompleteField/AddressAutocompleteField';

export interface IAddressFormBlockProps {
    title: string;
    customClasses?: Partial<Record<'form' | 'input' | 'button' | 'formBlock' | 'formBlockTitle', string>>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    hideAddress2?: boolean;
    hideCounty?: boolean;
    eachFieldOnNewLine?: boolean;
}

export const AddressFormBlock = (
    {
        title, customClasses, setFieldValue,
        hideCounty = false,
        hideAddress2 = false,
        eachFieldOnNewLine = false,
    }: IAddressFormBlockProps,
) => {

    const defaultClasses = useSettingsFormStyles();
    const classes = {
        ...defaultClasses,
        ...customClasses,
    };
    const handleAddressSelect = useHandleAddressSelect(setFieldValue);
    return (
        <Box className={clsx(classes.formBlock)}>
            <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>{title}</Typography>
            <AddressAutocompleteField
                name="address"
                label="Address"
                className={classes.input}
                onAddressSelect={handleAddressSelect}
            />
            {
                !hideAddress2 && (
                    <FormField
                        name="address2"
                        label="Address 2"
                        className={classes.input}
                    />
                )
            }
            {
                eachFieldOnNewLine ? (
                    <>
                        <Box width="50%">
                            <FormField
                                name="city"
                                label="City"
                                className={ classes.input }
                            />
                        </Box>
                        <Box width="50%">
                            <FormField
                                name="state"
                                label="State/Region"
                                className={ classes.input }
                            />
                        </Box>
                        {
                            !hideCounty && (
                                <Box width="50%">
                                    <FormField
                                        name="county"
                                        label="County"
                                        className={ classes.input }
                                    />
                                </Box>
                            )
                        }
                        <Box width="50%">
                            <FormField
                                name="zip_code"
                                label="Zip code"
                                className={ classes.input }
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box display="flex">
                            <Box width="60%">
                                <FormField
                                    name="city"
                                    label="City"
                                    className={ classes.input }
                                />
                            </Box>
                            <Box width="40%">
                                <FormField
                                    name="state"
                                    label="State/Region"
                                    className={ classes.input }
                                />
                            </Box>
                        </Box>

                        <Box display="flex">
                            {
                                !hideCounty && (
                                    <Box width="60%">
                                        <FormField
                                            name="county"
                                            label="County"
                                            className={ classes.input }
                                        />
                                    </Box>
                                )
                            }

                            <Box width={`${!hideCounty ? 40 : 60}%`}>
                                <FormField
                                    name="zip_code"
                                    label="Zip code"
                                    className={ classes.input }
                                />
                            </Box>
                        </Box>
                    </>
                )
            }
        </Box>
    );
};
