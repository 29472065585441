import React from 'react';
import { Box, BoxProps } from '@material-ui/core';

export interface ILabelWithValueClasses {
    label?: string,
    value?: string,
    root?: string,
}

interface ILabelWithValueProps extends BoxProps {
    label: string;
    value?: string;
    classes?: ILabelWithValueClasses;
}

export const LabelWithValue = ({ label, value, classes, ...boxProps }: ILabelWithValueProps) => {
    return (
        <Box
            className={classes?.root}
            {...boxProps}
            fontWeight="fontWeightMedium"
            mt={1}
        >
            <Box
                component="span"
                color="primary.main"
                className={classes?.label}
            >
                {label}:
            </Box>
            <Box
                component="span"
                ml={1}
                className={classes?.value}>
                {value}
            </Box>
        </Box>
    );
};
