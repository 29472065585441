import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectSelectorsPanelEntries } from 'modules/timeAndExpense/components/AddEntry/store/selectors';
import React from 'react';
import { useStyles } from 'modules/timeAndExpense/components/AddEntry/components/SelectorsPanel/styles';
import { SelectorsPanelBlock } from 'modules/timeAndExpense/components/AddEntry/components/SelectorsPanel/SelectorPanelBlock';

export function SelectorsPanel(){
    const selectorPanelEntries = useSelector(selectSelectorsPanelEntries);
    const classes = useStyles();
    return (
        <Box className={classes.panel}>
            {
                selectorPanelEntries.map(entry => {
                    return (
                        <SelectorsPanelBlock key={entry.key} entry={entry}/>
                    );
                })
            }
        </Box>
    );
}
