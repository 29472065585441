import { Typography } from '@material-ui/core';
import { InsertDriveFile } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { useUploadStyles } from 'shared/components/attachments/styles';
import PdfSVG from 'shared/components/icons/PdfSVG';
import { IAttachment } from 'shared/models/Attachments';

interface IAttachmentPreviewProps {
    attachment: IAttachment
}

export const AttachmentPreview = ({
    attachment,
}: IAttachmentPreviewProps) => {
    const classes = useUploadStyles();
    if (attachment?.mimetype?.includes('image/')) {
        return (
            <img
                className={classes.img}
                alt=""
                src={attachment.url}
                key={attachment.id}
            />
        );
    }
    if (attachment?.mimetype === 'application/pdf') {
        return (
            <div className={clsx(classes.img, classes.previewImg)}>
                <PdfSVG customClasses={classes.pdfPreview}/>
                <Typography variant="subtitle2" align="center">
                    {attachment.filename}
                </Typography>
            </div>
        );
    }
    return (
        <div className={classes.previewFile}>
            <InsertDriveFile className={classes.filePreviewIcon}/>
            <Typography variant="subtitle2" align="center">
                {attachment.filename}
            </Typography>
        </div>
    );
};
