import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import * as qs from 'query-string';
import { requestInterceptor, requestRejectionInterceptor } from 'shared/utils/axios/interceptors';
import { baseUrl } from 'shared/utils/baseUrl';

// @ts-ignore
export const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        common: {
            'Ocp-Apim-Subscription-Key':
            process.env.REACT_APP_OCI_APIM_SUBSCRIPTION_KEY,
        },
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
});

axiosRetry(axiosInstance, {
    retries: 6,
    retryDelay: axiosRetry.exponentialDelay,
});

axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    requestRejectionInterceptor,
);

export function isNetworkError(err: AxiosError) {
    return Boolean(err.isAxiosError) && !err.response;
}
