import EditAndResubmitButton from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/EditAndResubmitButton';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import RecallButton from 'shared/components/sheetsSubmitted/filterAndActionControls/RecallButton';
import SheetDetail, { ISheetDetailWrapperProps } from 'shared/components/sidebars/SheetDetail/SheetDetail';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useDetailTitle } from 'shared/utils/formatters/sheetDetail';
import { Permission } from 'store/components/auth/authModels';
import { AvailableDetailConfiguration } from 'store/entities/clients/clientsModel';
import { selectDetailConfiguration } from 'store/entities/clients/selectors/fieldSelectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectTypedSheet } from 'store/entities/timesheet/selectors';
import { useDisallowPastDayEntry } from '../../../../../store/entities/clients/hooks';

export function EmployeeSheetDetails({
    sheetId, entryType, onClose, ...sheetDetailProps
}: ISheetDetailWrapperProps) {
    const sheet = useSelector(selectTypedSheet(sheetId, entryType));
    const disallowPastDayEntry = useDisallowPastDayEntry();

    const sheetAvailableActions: JSX.Element | null = useMemo(() => {
        if (sheet && sheet.status.name === StatusNames.SUBMITTED && !disallowPastDayEntry) {
            return (
                <RecallButton sheetsEntryTypes={{ [sheet.id]: sheet.entry_type }} onAction={onClose}/>
            );
        }
        if (sheet && !disallowPastDayEntry && sheet.status.name === StatusNames.REJECTED) {
            return (
                <EditAndResubmitButton
                    sheetIds={[sheet.id]}
                    entryType={sheet.entry_type}
                    onClose={onClose}
                />
            );
        }
        return null;
    }, [sheet, onClose, disallowPastDayEntry]);
    const configuration = useSelector(selectDetailConfiguration(
        entryType === EntryType.TIME
            ? AvailableDetailConfiguration.EmployeeTimeSheet : AvailableDetailConfiguration.EmployeeExpenseSheet,
    ));
    const title = useDetailTitle(sheet, configuration.title);
    return (
        <SheetDetail
            sheetId={sheetId}
            entryType={entryType}
            title={title}
            permission={Permission.SubmitSheets}
            onClose={onClose}
            sheetAvailableActions={sheetAvailableActions}
            configuration={configuration.info}
            {...sheetDetailProps}
        />
    );
}
