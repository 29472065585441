import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreatedOffer } from 'modules/offerLetter/components/CreateOffer/store/selectors';
import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { selectOfferLetterFilters } from 'modules/offerLetter/store/selectors';
import { generatePath } from 'react-router-dom';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

export const CreateOfferListener = () => {
    const dispatch = useDispatch();
    const createdOffer = useSelector(selectCreatedOffer);
    const createdOfferRef = useRef(createdOffer);
    const clientId = useSelector(selectCurrentClientId);
    const offerLetterFilter = useSelector(selectOfferLetterFilters);

    useEffect(() => {
        if (createdOffer && createdOffer !== createdOfferRef.current) {
            clientId && browserHistory.push(generatePath(routes.CLIENT.OFFER_LETTER.ROOT, { client_id: clientId }));
            dispatch(setOfferLetterFilters({
                ...offerLetterFilter,
                statusSlug: OfferLetterStatusSlug.Outstanding,
            }));
            createdOfferRef.current = createdOffer;
        }
    }, [clientId, createdOffer, createdOfferRef, dispatch, offerLetterFilter]);
    return (<></>);
};
