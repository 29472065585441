export enum HeadwayServices {

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_client_users_api
     */
    ClientUsers = 'client-users',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_payroll_api
     */
    Payroll = 'payroll',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_prismhr_api
     */
    Prism = 'prismhr',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_expense_api
     */
    Expense = 'expense',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_time_api
     */
    Time = 'time',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_client_configurations_api
     */
    ClientConfiguration = 'client-configurations',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_documents_api
     */
    Documents = 'documents',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_notifications_api
     */
    Notifications = 'notifications',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_addresses_api
     */
    Addresses = 'addresses',

    /**
     * @api-ref https://app.swaggerhub.com/apis/Headway247/dev_headway_genworth_clientextensions_api
     */
    GenworthClientExtensions = 'gw-clientextensions',

}
