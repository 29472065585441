import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgress, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { uniqBy } from 'lodash-es';
import { debounce } from 'ts-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import useOutlinedTextFieldStyled from 'shared/components/textField/CustomOutlinedTextFieldStyles';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { getZipCodes } from 'store/entities/zipCode/actions';
import { IZipCodeFull } from 'shared/models/sheet/Sheet';
import { IZipCode } from 'store/entities/zipCode/model';
import { inputParams } from 'shared/styles/constants';

interface IZipCodeCommonProps {
    label: string;
    customClasses?: string;
    error?: string;
    onChange: (value: IZipCodeFull | null) => void;
    value?: IZipCodeFull;
}

export function ZipCodeCommon({ label, value, onChange, error, customClasses = '' }: IZipCodeCommonProps) {
    const outlinedClasses = useOutlinedTextFieldStyled();
    const formHelperTextClasses = useFormHelperTextStyles();

    const [search, setSearch] = useState('');

    const scaZipCodesState = useSelector(
        (state: IStore) => state.zipCodes.zipCodesSearch.map(id => state.zipCodes.zipCodesById[id]),
    );
    let scaZipCodes = scaZipCodesState.map((code: IZipCode): IZipCodeFull => ({
        zip_code: code.zip_code,
        state_id: code.county.state.id,
        state_short_name: code.county.state.state_shortname,
    }));
    if (value) {
        scaZipCodes = uniqBy([...scaZipCodes, value], 'zip_code');
    }

    const zipCodesLoading = useSelector((state: IStore) => state.zipCodes.zipCodesLoading);

    const debouncedSearch = useRef(debounce(setSearch, 500));

    const onInputChange = useCallback((_, newValue: string) => {
        debouncedSearch.current(newValue);
    }, [debouncedSearch]);

    const onSelect = useCallback((_, newValue: IZipCodeFull | null) => {
        onChange(newValue);
    }, [onChange]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!value) {
            dispatch(getZipCodes.init(search));
        }
    }, [dispatch, search, value]);

    return (
        <FormControl error={!!error} className={customClasses}>
            <Autocomplete
                loading={zipCodesLoading}
                onChange={onSelect}
                onInputChange={onInputChange}
                multiple={false}
                value={value}
                getOptionSelected={option => option.zip_code === value?.zip_code}
                getOptionLabel={option => option.zip_code}
                options={scaZipCodes ?? []}
                renderInput={params => (
                    <TextField {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: value ? `${value.state_short_name} - ` : null,
                            endAdornment: (
                                <React.Fragment>
                                    {zipCodesLoading ? <CircularProgress color="primary" size={24}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        inputProps={{
                            ...params.inputProps,
                            ...inputParams,
                        }}
                        error={!!error}
                        InputLabelProps={{
                            classes: {
                                root: outlinedClasses.label,
                                shrink: outlinedClasses.labelFilled,
                            },
                        }}
                        label={label}
                        variant="outlined"
                    />
                )}
            />
            {error && (
                <FormHelperText classes={formHelperTextClasses}>
                    {error}
                </FormHelperText>
            )}
        </FormControl>
    );
}
