import { generatePath } from 'react-router-dom';
import { browserHistory } from 'shared/utils/browserHistory';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { select } from 'typed-redux-saga';

export function* navigateWithClientSaga(path: string) {
    const clientId = yield select(selectCurrentClientId);
    yield browserHistory.push(generatePath(path, { client_id: clientId }));
}

export function* navigateSaga(path: string, params: any = {}) {
    yield browserHistory.push(generatePath(path, params));
}
