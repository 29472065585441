import React from 'react';
import { Box } from '@material-ui/core';
import { SideMenuItem } from 'modules/settings/submodules/components/SideMenu/SideMenuItem';
import { ISideMenuItem } from 'modules/settings/submodules/components/SideMenu/model';
import { useStyles } from './styles';

export interface ISideMenuProps {
    items: ISideMenuItem[];
}

export const SideMenu = ({ items }: ISideMenuProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.sideMenu}>
            {items.map(item => (
                <SideMenuItem key={item.title} {...item}/>
            ))}
        </Box>
    );
};
