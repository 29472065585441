import { endAssignment } from 'modules/employeeActionsForm/components/EndAssignment/store/reducers';
import { createEmployeeActionForm, getEafReasons } from 'modules/employeeActionsForm/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const reasons = singleValueReducer(getEafReasons.successType, []);
const isCreating = isLoadingReducer(createEmployeeActionForm);

export const employeeActonForm = combineReducers({
    reasons,
    endAssignment,
    isCreating,
});
