import { setDealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/store/actions';
import { DealsActions, getMoreDealsAction, initialLoadDealsPage } from 'modules/settings/submodules/clients/deals/store/actions';
import { defaultInfinityScrollState, IInfinityScrollState } from 'store/reducerUtils';
import { createDeal } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/actions';
import { IDealResponse } from 'store/entities/configuration/configurationModel';

export function dealsTable(
    state: IInfinityScrollState<string>,
    action: DealsActions,
): IInfinityScrollState<string> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setDealFilter.action:
        case initialLoadDealsPage.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreDealsAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreDealsAction.successType:{
            const payload = action.payload as IDealResponse;
            const newItems = [
                ...state.items.filter(itemId => !payload.deals.map(entry => entry.id).includes(itemId)),
                ...payload.deals.map(entry => entry.id),
            ];
            return {
                total: payload.total_items,
                items: newItems,
                isLoading: false,
            };
        }
        case createDeal.successType:
            return {
                ...state,
                // @ts-ignore
                items: [...state.items, action.payload.id],
                total: (state.total || 0) + 1,
            };
        default:
            return state;
    }
}
