import { jobNumbersApi } from 'modules/settings/submodules/clients/jobNumber/store/api';
import { autocompleteSearchSagaWrapper } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/store/utils';
import { autocompleteSearchJobNumber } from 'shared/components/autocomplete/jobNumbers/actions';
import { getJobNumbers } from 'store/entities/configuration/configurationAction';
import { call, put, takeLatest } from 'typed-redux-saga';

function* searchJobNumberSaga({ payload }: ReturnType<typeof autocompleteSearchJobNumber>) {
    const jobNumbersResponse = yield* call(jobNumbersApi.getJobNumbers, payload.request);
    yield put(getJobNumbers.success(jobNumbersResponse.job_numbers));
    return jobNumbersResponse.job_numbers.map(job => job.id);
}

export function* jobNumberSearchSagaWatcher() {
    yield takeLatest(autocompleteSearchJobNumber.action, autocompleteSearchSagaWrapper(searchJobNumberSaga));
}
