import baseApi from 'shared/utils/baseApi';
import { clientConfigurationsService } from 'store/entities/configuration/configurationApi';
import { IPayRange, IPayRangeByValuesRequest } from 'store/entities/configuration/configurationModel';
import { withClientId } from 'store/utils/withClientId';
import {
    ICreatePayRangePayload,
    IPayRangeRequest,
    IPayRangeResponse,
    IUpdatePayRangeRequest,
} from 'modules/settings/submodules/clients/payRanges/store/models';

const payRangeRoute = 'pay_ranges';

export const managePayRangeApi = {
    async getPayRanges(request: IPayRangeRequest): Promise<IPayRangeResponse> {
        const url = `/${clientConfigurationsService}/${payRangeRoute}`;
        const { data } = await baseApi.get<IPayRangeResponse>(url, withClientId(request));
        return data;
    },

    async getPayRangesByValues(request: IPayRangeByValuesRequest): Promise<IPayRange> {
        const url = `/${clientConfigurationsService}/pay_range/by_values`;
        const { data } = await baseApi.get<IPayRange>(url, withClientId(request));
        return data;
    },

    async createPayRange(requestPayload: ICreatePayRangePayload): Promise<IPayRange> {
        const { data } = await baseApi.create<ICreatePayRangePayload, IPayRange>(
            clientConfigurationsService,
            payRangeRoute,
            withClientId(requestPayload),
        );
        return data;
    },

    async updatePayRange(payRangeId: string, requestPayload: IUpdatePayRangeRequest): Promise<IPayRange> {
        const { data } = await baseApi.patch<IUpdatePayRangeRequest, IPayRange>(
            `/${clientConfigurationsService}/${payRangeRoute}/${payRangeId}`,
            requestPayload,
        );
        return data;
    },

    async deletePayRange(payRangeId: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${clientConfigurationsService}/${payRangeRoute}`, payRangeId,
        );
        return data.status;
    },
};
