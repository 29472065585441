import { CheckedItems, OnCheck } from '../../../../shared/utils/hooks/useCheckedItems';
import { ICellInfo } from '../../../../shared/components/table/GridTable/GridTableModel';
import clsx from 'clsx';
import React from 'react';
import {
    CheckBoxCellPure,
    CheckBoxTitlePure,
    ICheckboxClasses,
} from '../../../../shared/components/table/Cells/checkBoxHelper';
import { ICcpTransactionRow } from './store/selectors';
import PlainText from '../../../../shared/components/table/Cells/PlainText';

export function getCcpCheckboxCell(
    checkedItems: CheckedItems,
    onCheck: OnCheck,
    checkedAll: boolean,
    onCheckAll: (checked: boolean) => void,
    classes: ICheckboxClasses,
    isMobile: boolean,
    getId: (row: ICcpTransactionRow) => string,
    customHeaderClasses: Array<string> = [],
    customClasses: Array<string> = [],
): ICellInfo<ICcpTransactionRow> {
    return {
        key: 'checkbox',
        title: '',
        width: isMobile ? '40px' : '60px',
        headerClassName: clsx(...customHeaderClasses, classes.iconCell, classes.checkbox),
        renderTitle: function CheckboxCellHeader() {
            return (
                <CheckBoxTitlePure
                    checkedAll={checkedAll}
                    onCheckAll={onCheckAll}
                    classes={classes}
                />
            );
        },
        render: function CheckboxCell(row: ICcpTransactionRow) {
            if (row.parent_transaction_id) {
                return (
                    <PlainText className={row.className} value={''}/>
                );
            }
            return (
                <CheckBoxCellPure
                    customClasses={customClasses}
                    classes={classes}
                    checkedItems={checkedItems}
                    onCheck={onCheck}
                    rowClassName={row.className}
                    rowId={getId(row)}
                />
            );
        },
    };
}
