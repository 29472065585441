import { createSelector } from 'reselect';
import {
    selectDeleteReportState,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { IBulkDeleteSubassignmentsResponse } from 'modules/subassignmentManagement/store/models';

export const deletePostInitializeReportOverviewItemsSelector = createSelector(
    selectDeleteReportState,
    (deleteReportState: IBulkDeleteSubassignmentsResponse) => {
        const numberOfFailed = deleteReportState?.number_of_subassignments_failed;
        const numberOfSucceded = deleteReportState?.number_of_subassignments;
        return [
            {
                label: '# of sub-assignments delete',
                values: [String(numberOfSucceded)],
            },
            {
                label: '# of sub-assignments failed',
                values: [String(numberOfFailed)],
            },
        ];
    },
);
