import React from 'react';
import { Box } from '@material-ui/core';
import { CreateControllingOrgButton } from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/CreateControllingOrgButton';
import { ControllingOrgTable } from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgTable/ControllingOrgTable';
import { ControllingOrgFilter } from 'modules/settings/submodules/clients/controllingOrg/components/Filter/ControllingOrgFilter';

export function ControllingOrgPage() {
    return (
        <>
            <Box display="flex" justifyContent="space-between"
                mb={2}>
                <CreateControllingOrgButton/>
                <ControllingOrgFilter/>
            </Box>
            <ControllingOrgTable/>
        </>
    );
}
