import React from 'react';
import { setEditControllingOrgId } from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/actions';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';

export interface IEditControllingOrgButtonProps {
    controllingOrgId: string;
}

export const EditControllingOrgButton = ({ controllingOrgId }: IEditControllingOrgButtonProps) => {
    return (
        <EditItemButton id={controllingOrgId} editAction={setEditControllingOrgId}/>
    );
};
