import React, { useCallback } from 'react';
import { updateControllingOrg } from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/actions';
import { selectIsControllingOrgUpdating } from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/selectors';
import { UpdateStatus } from 'modules/settings/submodules/platformUsers/components/UpdateStatus/UpdateStatus';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from 'shared/models/Status';

export interface IUpdateControllingOrgStatusSelectProps {
    id: string;
    status: Status;
    className?: string;
}

export const UpdateControllingOrgStatusSelect = (props: IUpdateControllingOrgStatusSelectProps) => {
    const { id, status, className } = props;
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsControllingOrgUpdating);
    const handleStatusChange = useCallback((newValue: Status) => {
        dispatch(updateControllingOrg.init({
            id,
            data: {
                is_active: newValue === Status.active,
            },
        }));
    }, [dispatch, id]);

    return (
        <UpdateStatus
            status={status}
            handleStatusChange={handleStatusChange}
            isLoading={isLoading}
            formControlClassName={className}
        />
    );
};
