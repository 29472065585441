import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';

export const getTotalHours = (hoursComponents: Array<string | null>): string => {
    const totalHours = hoursComponents.reduce((result: number, hours: string | null) => {
        if (hours) {
            return result + parseFloat(hours);
        }
        return result;
    }, 0);
    return formatDecimalHoursStringAsHoursAndMinutes(totalHours.toFixed(2).toString());
};

export const toStringWithLeadingZeros = (num: number, size: number): string => {
    const str = '000000000' + num.toString();
    return str.substring(str.length - size, str.length);
};
