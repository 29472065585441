import { IActionsCreatorCommon } from 'store/utils';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeEvery, takeLatest } from 'typed-redux-saga';

interface IRequestSagaConfiguration<ResultType, ActionRequestPayload> {
    every?: boolean;
    handleSuccessSaga?: (result: ResultType) => Generator;
    preconditionSaga?: (payload: ActionRequestPayload) => Generator;
}

export function requestSagaWatcher<EntityType, ActionRequestPayload>(
    action: IActionsCreatorCommon<any, any, any, any, any, any>,
    api: (request: ActionRequestPayload) => Promise<EntityType[] | EntityType>,
    errorMessage: string,
    configuration = {},
) {
    const {
        every = false,
        handleSuccessSaga,
        preconditionSaga,
    } = configuration as IRequestSagaConfiguration<EntityType[] | EntityType, ActionRequestPayload>;

    function* getEntitiesSaga({ payload }: ReturnType<typeof action.init>) {
        if (preconditionSaga) {
            yield* preconditionSaga(payload);
        }
        const result = yield* call(api, payload || {});
        if (handleSuccessSaga) {
            yield* handleSuccessSaga(result);
        }
        yield put(action.success(result));
    }

    const takeAction = every ? takeEvery : takeLatest;
    return function* getEntitiesWatcher() {
        yield takeAction(
            action.initType,
            withBackendErrorHandler(
                getEntitiesSaga,
                action.error,
                errorMessage,
            ),
        );
    };
}
