import baseApi from 'shared/utils/baseApi';
import {
    ISubmittingOrg,
} from 'shared/models/SubmittingOrg';
import {
    gwClientExtensionsService,
    submittingOrgEntity,
} from 'store/entities/configuration/configurationApi';
import { IUpdateSubmittingOrgRequest } from 'modules/settings/submodules/clients/submittingOrg/components/EditSubmittingOrg/store/models';

export const api = {
    async updateSubmittingOrg(id: string, body: IUpdateSubmittingOrgRequest): Promise<ISubmittingOrg> {
        const { data } = await baseApi.updateById<IUpdateSubmittingOrgRequest, ISubmittingOrg>(
            gwClientExtensionsService,
            submittingOrgEntity,
            id,
            body,
        );

        return data;
    },
};
