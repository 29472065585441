import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import { combineReducers } from 'redux';
import { setCreateDealModalState, createDeal as createDealAction } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/actions';

const isCreateDealModalOpen = isOpenModalReducer(setCreateDealModalState.action);
const isCreating = isLoadingReducer(createDealAction);

export const createDeal = combineReducers({
    isCreateDealModalOpen,
    isCreating,
});
