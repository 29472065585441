import React from 'react';
import Popover from '@material-ui/core/Popover';

export interface IPopOverProps {
    isOpened: boolean;
    onClose: () => void;
    anchorEl: HTMLElement | null;
    children: React.ReactNode;
}

export default function PopOver({ isOpened, onClose, anchorEl, children, ...htmlProps }: IPopOverProps) {
    return (
        <div>
            <Popover
                open={isOpened}
                onClose={onClose}
                anchorEl = {anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                {...htmlProps}
            >
                {children}
            </Popover>
        </div>
    );
}
