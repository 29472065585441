import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bulkEditSubAssignmentPreInitializeActionV2 } from 'modules/subassignmentManagement/store/actions';
import { EafType, EmployeeActionFormData, ICreateEaf } from 'modules/employeeActionsForm/store/models';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { IAssignment } from 'store/entities/configuration/configurationModel';
import { BulkEditSubAssignmentFormV2Common, IBulkEditSubAssignmentFormV2CommonProps } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSubAssignmentForm/BulkEditSubAssignmentFormV2Common';
import { selectCheckedAssignments } from '../../SubassignmentTable/store/selectors';
import { selectIsLoadingEditPreInitialize } from '../store/selectors';
import { IBulkEditSubAssignmentFormV2Props, IBulkEditSubAssignmentFormValuesV2 } from './types';

export const BulkEditAssignmentFormV2 = separateLogicDecorator<
IBulkEditSubAssignmentFormV2Props,
IBulkEditSubAssignmentFormV2CommonProps
>(
    (props: IBulkEditSubAssignmentFormV2Props) => {
        const dispatch = useDispatch();

        const assignments = useSelector(selectCheckedAssignments);
        const isLoading = useSelector(selectIsLoadingEditPreInitialize);
        const payRateType = assignments[0]?.pay_rate_type;
        const payType = assignments[0]?.pay_type;

        const onSubmit = useCallback((
            formValues: IBulkEditSubAssignmentFormValuesV2,
            additionalEafData?: EmployeeActionFormData,
        ) => {
            let employeeActionForms = additionalEafData ? assignments.map(
                (assignment: IAssignment): ICreateEaf => ({
                    assignment_id: assignment.id,
                    employee_action_form_data: additionalEafData,
                }),
            ) : [];
            const hasStartDateChange = Boolean(formValues.startDate);
            if (hasStartDateChange) {
                employeeActionForms = employeeActionForms.concat(
                    assignments.map(
                        (assignment: IAssignment): ICreateEaf => ({
                            assignment_id: assignment.id,
                            // @ts-ignore
                            employee_action_form_data: {
                                employee_action_form_type: EafType.StartDateChange,
                                applies_to_subassignments: true,
                                new_start_date: formValues.startDate,
                            },
                        }),
                    ),
                );
            }
            dispatch(bulkEditSubAssignmentPreInitializeActionV2.init({
                employee_action_forms: employeeActionForms,
                subassignments: [],
            }));
        }, [dispatch, assignments]);

        return {
            onSubmit,
            isLoading,
            payRateType,
            payType,
            selectedCount: assignments.length,
            selectedEntityName: 'employee',
            assignmentsOnly: true,
            ...props,
        };
    },
)(BulkEditSubAssignmentFormV2Common);
