import React from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box, Button } from '@material-ui/core';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';
import { formBasicSpacing, useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { ITemplateFormValues } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';

export interface ITemplateFormProps extends FormikProps<ITemplateFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    templateLabel: string;
    onCancel?: () => void;
}

export function TemplateForm({
    handleSubmit,
    onCancel,
    submitTitle,
    templateLabel,
    isLoading = false,
    isDisabled = false,
}: ITemplateFormProps) {
    const classes = useSettingsFormStyles();

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
            <Box className={classes.formBlock}>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="name"
                            label="Name"
                            className={classes.input}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="template"
                            label={ templateLabel }
                            multiline
                            rows={2}
                            rowsMax={6}
                            className={classes.input}
                        />
                    </Box>
                </Box>
            </Box>
            <Box m={formBasicSpacing}>
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    data-testid="submit_update_button"
                >
                    {submitTitle}
                </ButtonWithLoader>
                {onCancel && (
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={onCancel}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                )}
            </Box>
        </form>
    );
}
