import React, { useMemo } from 'react';
import moment from 'moment';
import { FormikProvider, useFormik } from 'formik';
import { Box, Button } from '@material-ui/core';
import { useEafFormStyles } from 'modules/subassignmentManagement/components/EafFormShared/useEafFormStyles';
import { WarningAlertWithIcon } from 'shared/components/alerts/WarningAlertWithIcon';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import { shortDateFormat } from 'shared/models/Dates';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { sidebarFormStyles } from 'modules/employeeActionsForm/components/EndAssignment/styles';
import { useEditEndDateValidationSchema } from './validationSchema';
import { IEmployeeActionFormDataModel } from '../EndAssignment/EndAssignmentForm';

export interface IAssignmentEditDateFormProps {
    isLoading: boolean,
    onCancel: () => void,
    relatedSubassignments?: ISubassignment[];
    relatedAssignments?: IAssignment[],
    assignmentMaxEntryDate?: string;
    assignment?: IAssignment;
    initialValues: IEmployeeActionFormDataModel,
    onSubmit: (values: IEmployeeActionFormDataModel) => void,
}

export const AssignmentEditDateForm = ({
    isLoading,
    onCancel,
    relatedSubassignments,
    relatedAssignments,
    assignmentMaxEntryDate,
    assignment,
    initialValues,
    onSubmit,
}: IAssignmentEditDateFormProps) => {
    const classes = useEafFormStyles();
    const formClasses = sidebarFormStyles();

    const validationSchema = useEditEndDateValidationSchema();

    const formikData = useFormik<IEmployeeActionFormDataModel>({
        initialValues,
        validationSchema,
        validateOnBlur: false,
        onSubmit,
    });
    const { handleSubmit } = formikData;

    const minEffectiveDate = useMemo(() => {
        // Return next day after latest assignment entry or assignment hire date
        const hireDate = assignment?.hire_date ? moment(assignment?.hire_date) : undefined;
        if (!assignmentMaxEntryDate) {
            return hireDate;
        } else {
            const maxEntryDate = moment(assignmentMaxEntryDate).add(1, 'days');
            return maxEntryDate.isAfter(hireDate) ? maxEntryDate : hireDate;
        }
    }, [assignment?.hire_date, assignmentMaxEntryDate]);

    const maxEffectiveDate = useMemo(() => {
        if (relatedAssignments && relatedAssignments.length > 0) {
            const assignmentEndDate = moment(assignment?.end_date);
            const assignmentsAfterEndDate = relatedAssignments
                .filter(x => moment(x.hire_date).isAfter(assignmentEndDate));
            if (assignmentsAfterEndDate.length > 0) {
                const minNextAssignmentDate = moment
                    .min(relatedAssignments.map(x => moment(x.hire_date))).add(-1, 'day');
                return minNextAssignmentDate;
            }
        }
        return undefined;
    }, [assignment?.end_date, relatedAssignments]);

    const countSubassignmentToEdit = useMemo(() => {
        const assignmentEndDate = assignment?.end_date;
        return relatedSubassignments?.filter(subasssignment => {
            return assignmentEndDate === subasssignment.end_date;
        }).length || 0;
    }, [assignment?.end_date, relatedSubassignments]);

    return (
        <FormikProvider value={formikData}>
            <form
                onSubmit={handleSubmit}
                className={formClasses.form}
            >
                {countSubassignmentToEdit !== 0 && (
                    <Box mb={2}>
                        <WarningAlertWithIcon>
                            This will change dates for {countSubassignmentToEdit} sub-assignments
                            associated with this main assignment.
                        </WarningAlertWithIcon>
                    </Box>
                )}

                <Box className={classes.row}>
                    <label htmlFor="effective_date" className={formClasses.inlineInputLabel}>Effective Date</label>
                    <Box width="160px">
                        <DayPickerField
                            id="effective_date"
                            name="effective_date"
                            format={shortDateFormat}
                            className={formClasses.input}
                            disableToolbar
                            withKeyboard
                            minDate={minEffectiveDate}
                            maxDate={maxEffectiveDate}
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    alignItems="baseline"
                >
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={formClasses.button}
                        isLoading={isLoading}
                        data-testid="submit_button"
                    >
                        Submit
                    </ButtonWithLoader>
                    <Button
                        disabled={isLoading}
                        onClick={onCancel}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Box>
            </form>
        </FormikProvider>
    );
};
