import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { CreateOfferListener } from 'modules/offerLetter/components/CreateOffer/CreateOfferListener';
import { selectCreateSidebarOpened } from 'modules/offerLetter/components/CreateOffer/store/selectors';
import LookupResult from 'modules/offerLetter/components/lookup/LookupResult';
import CreateOfferSidebar from 'modules/offerLetter/components/CreateOffer/CreateOfferSidebar';
import { resetEmployeeLookup, setCreateSidebarOpened, setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { selectOfferLetterFilters } from 'modules/offerLetter/store/selectors';
import { selectLookupEmployee, selectLookupEmployeeHash } from 'modules/offerLetter/components/lookup/data/selectors';
import { generatePath } from 'react-router';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { getUserCustomFieldRestrictions } from 'store/entities/scopes/actions';
import { ScopeType } from 'store/entities/scopes/models';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';
import { useIsMobile } from 'shared/utils/hooks/media';
import LookupFormWrapper from 'modules/offerLetter/components/lookup/forms/LookupFormWrapper';
import { IUserInfo } from 'shared/models/User';
import { Nullable } from '../../../types/types';
import { ILookupFormClasses } from './lookup/forms/LookupForm';

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    title: {
        textAlign: 'center',
    },
    lookupFormContainer: {
        borderTop: `2px solid ${colors.lightGray}`,
        borderBottom: `2px solid ${colors.lightGray}`,
        backgroundColor: colors.white,
        padding: theme.spacing(2, 0),
    },
    formField: {
        margin: theme.spacing(1),
        width: theme.spacing(20),
    },
    formButton: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(4),
        width: theme.spacing(16.5),
    },
}));

const useLookupResultStyles = makeHighPriorityStyles((theme: Theme) => ({
    inviteButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
    tableContainer: {
        marginBottom: theme.spacing(9),
    },
}));

const CreateOfferPage = () => {
    const classes = useStyles();
    const lookupResultClasses = useLookupResultStyles();
    const createSidebarOpened = useSelector(selectCreateSidebarOpened);
    const clientId = useSelector(selectCurrentClientId);
    const dispatch = useDispatch();

    const offerLetterFilter = useSelector(selectOfferLetterFilters);
    const employeesFromSelect = useSelector(selectLookupEmployee);
    const employeeLookupHash = useSelector(selectLookupEmployeeHash);
    // TODO: rethink hook dependencies linting
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const employees = useMemo<Nullable<IUserInfo[]>>(() => employeesFromSelect, [employeeLookupHash]);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile) {
            browserHistory.push(generatePath(routes.CLIENT.OFFER_LETTER.ROOT, { client_id: clientId }));
        } else if (offerLetterFilter.statusSlug) {
            dispatch(setOfferLetterFilters({
                ...offerLetterFilter,
                statusSlug: null,
            }));
        }
    }, [dispatch, offerLetterFilter, isMobile, clientId]);

    useWithClientEffect(() => {
        dispatch(getUserCustomFieldRestrictions.init({
            scope_types: [ScopeType.SubassignmentManagement],
        }));
    }, [dispatch]);

    const handleSidebarOpen = useCallback(() => {
        dispatch(setCreateSidebarOpened(true));
    }, [dispatch]);

    const onBack = useCallback(() => {
        dispatch(setCreateSidebarOpened(false));
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetEmployeeLookup());
        };
    }, [dispatch]);

    const lookupFormWrapperClasses = useMemo<ILookupFormClasses>(() => ({
        input: classes.formField,
        button: classes.formButton,
    }), [classes]);

    return (
        <Box mt={7.5} mb={3}>
            <Typography variant="subtitle1" className={classes.title}>Invite or Lookup Employee</Typography>
            <Box
                className={classes.lookupFormContainer}
                mt={2.5}
                display="flex"
                justifyContent="center"
            >
                <LookupFormWrapper classes={lookupFormWrapperClasses}/>
            </Box>
            <Box
                mt={8}
                mr={10}
                ml={10}
            >
                <LookupResult
                    employees={employees}
                    onInvite={handleSidebarOpen}
                    classes={lookupResultClasses}
                />
            </Box>
            <CreateOfferSidebar open={createSidebarOpened} onBack={onBack}/>
            <CreateOfferListener/>
        </Box>
    );
};

export default React.memo(CreateOfferPage);
