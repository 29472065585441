import { sortBy } from 'lodash-es';
import { createSelector } from 'reselect';
import { ItemsById } from 'shared/models/ItemsById';
import {
    EntryType,
    IEntry,
    IExpenseEntry,
    IExpenseSheet,
    ISheet,
    IStatus,
    ITimeEntry,
    ITimeSheet,
} from 'shared/models/sheet/Sheet';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { SheetStatusSlug } from 'store/entities/timesheet/models/Status';
import { IStore } from '../../configureStore';
import { isCalculationOutdated } from './helpers';
import { ITimesheetCalculation } from './models/Calculation';
import { isSamePayPeriod } from './reducers/time';

/**
 * Time sheets
 */
export const selectTimeSheetsByIds = (state: IStore): ItemsById<ITimeSheet> => state.sheets.time.sheetsById;
export const selectTimeSheetById = (id: string) =>
    (state: IStore): ITimeSheet | undefined => selectTimeSheetsByIds(state)[id];
export const selectTimeSheets = createSelector(
    selectTimeSheetsByIds,
    sheetsByIds => Object.values(sheetsByIds),
);
export const selectTimeSheetIdsByGroupId = createSelector(
    selectTimeSheets,
    sheets => sheets.reduce((sheetsByGroupId, sheet) => {
        const sheetGroupId = sheet.sheet_group_id;
        sheetsByGroupId.set(sheetGroupId, [
            ...(sheetsByGroupId.get(sheet.sheet_group_id) || []),
            sheet.id,
        ]);
        return sheetsByGroupId;
    }, new Map<string, string[]>()),
);
/**
 * Expense sheets
 */
export const selectExpensesSheetsByIds = (state: IStore): ItemsById<IExpenseSheet> => state.sheets.expenses.sheetsById;
export const selectExpensesSheets = createSelector(
    selectExpensesSheetsByIds,
    sheetsByIds => Object.values(sheetsByIds),
);

export const selectAllSheets = createSelector(
    selectTimeSheets,
    selectExpensesSheets,
    (timeSheets, expensesSheets) => ([
        ...timeSheets,
        ...expensesSheets,
    ]),
);
/**
 * Select all sheets which are represented in FE only
 */
export const selectTemporarySheets = createSelector(
    selectTimeSheets,
    selectExpensesSheets,
    (timeSheets, expensesSheets) => ([
        ...timeSheets,
        ...expensesSheets,
    ].filter(sheet => !sheet.sheet_group_id)),
);

/**
 * Select working sheets by type
 */
export const selectWorkingSheets = (entryType: EntryType) => (store: IStore): ISheet[] => {
    const selector = entryType === EntryType.TIME ? selectTimeSheets : selectExpensesSheets;
    const sheets = selector(store) as ISheet[];
    return sheets.filter(sheet => sheet?.status?.slug === SheetStatusSlug.WORKING);
};

/**
 * Time entries
 */
export const selectTimeEntriesByIds = (state: IStore) => state.sheets.time.entriesById;
export const selectTimeEntries = createSelector(
    selectTimeEntriesByIds,
    (entriesById): ITimeEntry[] => Object.values(entriesById),
);
/**
 * Expense entries
 */
export const selectExpenseEntriesByIds = (state: IStore) => state.sheets.expenses.entriesById;
export const selectExpenseEntries = createSelector(
    selectExpenseEntriesByIds,
    (entriesById): IExpenseEntry[] => Object.values(entriesById),
);

export const selectAllEntries = createSelector(
    selectTimeEntries,
    selectExpenseEntries,
    (timeEntries, expenseEntries): IEntry[] => ([
        ...timeEntries,
        ...expenseEntries,
    ]),
);

//TODO: Change interface for receive type
export const selectSheet = (sheetId?: string | null) =>
    (state: IStore): ISheet | null => sheetId
        ? state.sheets.time.sheetsById[sheetId] || state.sheets.expenses.sheetsById[sheetId]
        : null;
export const selectEntry = (entryId?: string | null) =>
    (state: IStore): IEntry | null => entryId
        ? state.sheets.time.entriesById[entryId] || state.sheets.expenses.entriesById[entryId]
        : null;
export const selectExpenseEntry = (entryId?: string | null) =>
    (state: IStore): IExpenseEntry | null => entryId
        ? state.sheets.expenses.entriesById[entryId]
        : null;
export const selectTimeEntry = (entryId?: string | null) =>
    (state: IStore): ITimeEntry | null => entryId
        ? state.sheets.time.entriesById[entryId]
        : null;
export const selectTimeEntriesWithSheet = createSelector(
    selectTimeEntries,
    selectTimeSheetsByIds,
    (entries, sheetsById) => {
        return entries.map(entry => ({
            ...entry,
            sheet: sheetsById[entry.sheet_id],
        }));
    },
);
export const selectExpenseEntriesWithSheet = createSelector(
    selectExpenseEntries,
    selectExpensesSheetsByIds,
    (entries, sheetsById) => {
        return entries.map(entry => ({
            ...entry,
            sheet: sheetsById[entry.sheet_id],
        }));
    },
);
export const selectAllEntriesWithSheet = createSelector(
    selectTimeEntriesWithSheet,
    selectExpenseEntriesWithSheet,
    (timeEntries, expenseEntries) => ([
        ...timeEntries,
        ...expenseEntries,
    ]),
);

export const selectPatchingSheetsByIds = (state: IStore) => state.sheets.time.timeSheetIsPatching;
export const selectLastAddedEntry = (state: IStore) => state.sheets.lastAddedEntry;
export const selectSheetsIsLoading = (state: IStore) => state.sheets.time.isSheetsLoading
    || state.sheets.expenses.isSheetsLoading;

const findSheetStatusByName = (statusName: string) => (status: IStatus) => status.name === statusName;
const findSheetStatusById = (statusId: string) => (status: IStatus) => status.id === statusId;

/* Time Sheet Statuses Selectors */
export const selectTimeSheetStatuses = (state: IStore) => state.sheets.time.statuses;
export const selectTimeSheetStatusByName = (statusName: string) =>
    (state: IStore) => selectTimeSheetStatuses(state).find(findSheetStatusByName(statusName));
export const selectTimeSheetStatusById = (statusId: string) =>
    (state: IStore) => selectTimeSheetStatuses(state).find(findSheetStatusById(statusId));

/* Expense Sheet Statuses Selectors */
export const selectExpenseSheetStatuses = (state: IStore) => state.sheets.expenses.statuses;
export const selectExpenseSheetStatusByName = (statusName: string) =>
    (state: IStore) => selectExpenseSheetStatuses(state).find(findSheetStatusByName(statusName));
export const selectExpenseSheetStatusById = (statusId: string) =>
    (state: IStore) => selectExpenseSheetStatuses(state).find(findSheetStatusById(statusId));

export const selectSheetStatusesIsLoading = createSelector(
    selectTimeSheetStatuses,
    selectExpenseSheetStatuses,
    (timeStatuses, expenseStatuses) => [...timeStatuses, ...expenseStatuses].length === 0,
);

// Entries Related Selectors
export const selectEntryAttachmentsState = (state: IStore) => state.sheetEntryAttachments;
export const selectTempEntryAttachments = (state: IStore) => selectEntryAttachmentsState(state).attachments;

export const selectTypedEntries = (entryType: EntryType) => function (state: IStore): Array<IEntry> {
    return entryType === EntryType.TIME ? selectTimeEntries(state) : selectExpenseEntries(state);
};

export const selectTypedSheet = (sheetId: string, entryType: EntryType) => function (state: IStore): ISheet {
    return entryType === EntryType.TIME
        ? selectTimeSheetsByIds(state)[sheetId] : selectExpensesSheetsByIds(state)[sheetId];
};

export const selectTimeStatusIdByName = (statusName: string) => (state: IStore) =>
    state.sheets.time.statuses.find((status: IStatus) => status?.name === statusName)?.id;

export const selectExpenseStatusByName = (statusName: string) => (state: IStore) =>
    state.sheets.expenses.statuses.find((status: IStatus) => status?.name === statusName);

export const selectExpenseStatusIdByName = (statusName: string) => (state: IStore) =>
    selectExpenseStatusByName(statusName)(state)?.id;

export const isReceiptUploading = (state: IStore) => {
    return state.sheetEntryAttachments.isUploading;
};

export const isFileReading = (state: IStore) => state.sheetEntryAttachments.setFileRead;

/**
 * Calculations
 */
export const selectCalculationsByTimesheetId = (state: IStore) => state.sheets.calculations.byTimesheetId;
export const selectCalculationByTimesheetId = (id?: string) =>
    (state: IStore) => selectCalculationsByTimesheetId(state)[id || ''];

/**
 * Pay periods
 */
export const selectPayPeriods = (state: IStore): IPayPeriod[] => state.sheets.payPeriods;
export const selectOrderedPayPeriods = createSelector(
    selectPayPeriods,
    payPeriods => sortBy(payPeriods, period => period.period_end),
);
export const selectPayPeriodsIsLoading = (state: IStore) => state.sheets.payPeriodsIsLoading;
export const selectPayPeriodsBySheets = createSelector(
    selectAllSheets,
    sheets => {
        const sheetPayPeriods: IPayPeriod[] = [];
        sheets.forEach(sheet => {
            const sheetPayPeriod: IPayPeriod = {
                period_start: sheet.period_start,
                period_end: sheet.period_end,
            };
            const existPeriod = sheetPayPeriods.find(
                period => period.period_start === sheetPayPeriod.period_start
                    && period.period_end === sheetPayPeriod.period_end,
            );
            if (!existPeriod) {
                sheetPayPeriods.push(sheetPayPeriod);
            }
        });
        return sheetPayPeriods;
    },
);

/**
 * Statuses
 */
export const selectIsSheetStatusUpdating = (state: IStore) => state.sheets.isSheetStatusUpdating;

/**
 * Select time sheet IDs that waiting for calculations update
 * e.g. timesheet updated but calculations still pending
 * or timesheet created but calculation not
 */
export const selectTimesheetIdsToUpdateCalculation = (payPeriod?: IPayPeriod) => createSelector(
    selectCalculationsByTimesheetId,
    selectTimeSheetsByIds,
    selectTimeEntries,
    (
        timeSheetCalculationsMap: Record<string, ITimesheetCalculation>,
        timeSheetsMap: ItemsById<ITimeSheet>,
        timeEntries: Array<ITimeEntry>,
    ) => {
        const timesheets = Object.values(timeSheetsMap);
        const outdatedSheets = timesheets.filter(sheet => {
            if (payPeriod && !isSamePayPeriod(sheet, payPeriod)) {
                return false;
            }
            const calculation = timeSheetCalculationsMap[sheet.id];
            if (!calculation) {
                return true;
            }
            const sheetEntries = timeEntries.filter(item => item.sheet_id === sheet.id);
            return isCalculationOutdated(calculation, sheet, sheetEntries);
        });
        return outdatedSheets.map(sheet => sheet.id);
    },
);

export const selectSheetLogsById = (state: IStore) => state.sheets.sheetLogsById;

export const selectCaliforniaSubmissionWithPeriod = (state: IStore) => state.sheets.sheetCaliforniaSubmission;
