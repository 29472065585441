import React, { useCallback } from 'react';
import { usePayrollReportStyle } from 'modules/payrollProcessorHub/components/PayrollModal/reports/PayrollStyle';
import ReportOverview from 'modules/payrollProcessorHub/components/PayrollModal/reports/ReportOverview';
import { useDispatch, useSelector } from 'react-redux';
import { setBulkEditSubassignmentStep } from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import { bulkEditSubAssignmentActionV2, getBulkEditSubAssignmentPreInitializePdfReport } from 'modules/subassignmentManagement/store/actions';
import { BulkEditReportV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/BulkEditReportV2';
import { selectBulkEditV2RowsChanges, selectBulkPayloadV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/selectors';
import { useBulkPreEditOverviewItems } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/hooks';
import { selectIsBulkBeingUpdated, selectIsLoadingEditPreInitializePdf } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { PrintButton } from 'shared/components/buttons/PrintButton';

export const BulkEditPreInitializeV2Report = () => {
    const dispatch = useDispatch();

    const onBack = useCallback(() => {
        dispatch(setBulkEditSubassignmentStep(BulkEditSubassignmentSteps.Form));
    }, [dispatch]);
    const bulkPayload = useSelector(selectBulkPayloadV2);
    const handleAction = useCallback(() => {
        dispatch(bulkEditSubAssignmentActionV2.init(bulkPayload));
    }, [dispatch, bulkPayload]);
    const handleDownload = useCallback(() => {
        dispatch(getBulkEditSubAssignmentPreInitializePdfReport.init(bulkPayload));
    }, [dispatch, bulkPayload]);
    const overviewItems = useBulkPreEditOverviewItems();
    const isLoading = useSelector(selectIsBulkBeingUpdated);
    const isLoadingPdf = useSelector(selectIsLoadingEditPreInitializePdf);
    const editChanges = useSelector(selectBulkEditV2RowsChanges);

    const overviewClasses = usePayrollReportStyle();
    const additionalOverview = (
        <>
            <ReportOverview
                blocks={[[
                    {
                        label: 'Edits',
                        values: [editChanges],
                    },
                ]]}
                classes={
                    {
                        column: overviewClasses.SummaryInfoColumn,
                        row: overviewClasses.SummaryInfoRow,
                    }
                }
            />
            <PrintButton
                onClick={handleDownload}
                disabled={isLoadingPdf}
            />
        </>
    );

    return (
        <BulkEditReportV2
            onBack={onBack}
            handleAction={handleAction}
            isLoading={isLoading}
            title="Pre-Initialize Roll Up"
            buttonTitle="Update"
            overviewItems={overviewItems}
            additionalOverview={additionalOverview}
        />
    );
};
