import { Box } from '@material-ui/core';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { FormSwitchField } from 'modules/settings/submodules/clients/appConfiguration/components/FormSwitchField';
import React, { useEffect } from 'react';
import { PayTypeSelect } from 'modules/offerLetter/components/OfferLetterForm/steps/PayTypeSelect';
import { useIsPayRangesAppliedForClient } from 'store/entities/clients/hooks';
import { selectIsPayRangesEnabledForClient } from 'store/entities/clients/selectors/configurationSelectors';
import { loadClientRoles, loadGlobalRoles } from 'store/entities/users/actions';
import { FormSectionFieldWrapper } from '../components/FormSectionFieldWrapper';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import ClientRoleSelect
    from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/ClientRoleSelect';

interface IPositionToOfferLetterDataSectionProps {
    path: string;
}

export const PositionToOfferLetterDataSection = ({ path }: IPositionToOfferLetterDataSectionProps) => {
    const dispatch = useDispatch();
    const classes = useSettingsFormStyles();
    const payRangesAvailableForClient = useSelector(selectIsPayRangesEnabledForClient);
    const payRangesApplied = useIsPayRangesAppliedForClient();
    const allowPayRangeData = payRangesAvailableForClient;
    const clientId = useSelector(selectCurrentClientId);

    useEffect(() => {
        if (allowPayRangeData) {
            dispatch(loadClientRoles.init());
            dispatch(loadGlobalRoles.init());
        }
    }, [allowPayRangeData, dispatch]);

    if (!allowPayRangeData) {
        return null;
    }

    return (
        <>
            <FormSectionFieldWrapper>
                <ClientRoleSelect
                    clientId={clientId}
                    name={`${path}additional_employee_role_ids`}
                    blockNameList={['Employee']}
                    label="Offer Letter Role Assign"
                    className={classes.input}
                    useIdValue
                    multiple
                />
            </FormSectionFieldWrapper>

            <FormSectionFieldWrapper>
                <PayTypeSelect
                    name={`${path}pay_type`}
                    label="Offer Letter Pay Type"
                    className={classes.input}
                />
            </FormSectionFieldWrapper>

            {payRangesApplied && (
                <Box
                    ml={2}
                    mr={2}
                    mt={1}
                >
                    <FormSectionFieldWrapper>
                        <FormFieldWithSeparateLabel
                            name={`${path}pay_range_available`}
                            outerLabel="Allow set pay range"
                            FieldComponent={FormSwitchField}
                            // @ts-ignore
                            className={classes.input}
                        />
                    </FormSectionFieldWrapper>
                </Box>
            )}
        </>
    );
};
