import { uniq } from 'lodash-es';
import { ICustomFieldWithValues } from 'modules/settings/submodules/clients/customFieldValues/store/models';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';

function extractFieldValues(fieldWithValues: ICustomFieldWithValues): Array<string> {
    const fieldValues: Array<string> = (
        (fieldWithValues?.custom_field_values || [])
            .filter(Boolean)
            .map(fieldValue => fieldValue?.data?.name)
            .filter(Boolean)
    );
    return uniq(fieldValues);
}

export const getCustomFieldsSheetInfoItems = (customFieldsWithValues: ICustomFieldWithValues[]) => {
    const items: Array<ISheetInfoItemData> = (
        customFieldsWithValues
            .filter(Boolean)
            .map(fieldWithValues => {
                const fieldValues = extractFieldValues(fieldWithValues);
                const item: ISheetInfoItemData = {
                    title: fieldWithValues?.custom_field?.name || 'Unknown custom field',
                    value: fieldValues.join(', '),
                };
                return item;
            })
    );

    return items;
};
