import { PdfViewWithStatusCheck } from 'modules/offerLetter/OfferLetterEmployee/components/PdfViewWithStatusCheck';
import { useDispatch, useSelector } from 'react-redux';
import { IPdfViewProps } from 'shared/components/PdfViewer/PdfView';
import React, { useEffect } from 'react';
import {
    selectOfferLetterPdfIsAvailableById,
    selectOfferLetterPdfIsCheckingById,
} from '../store/selectors';
import { checkOfferLetterPdf } from '../store/actions';

interface IOfferLetterPdf extends IPdfViewProps {
    errorRender: React.ReactElement;
    id: string;
}

function OfferLetterPdf({ id, ...props }: IOfferLetterPdf) {
    const isPdfChecking = useSelector(selectOfferLetterPdfIsCheckingById(id));
    const isPdfAvailable = useSelector(selectOfferLetterPdfIsAvailableById(id));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(checkOfferLetterPdf.init({ id }));
    }, [dispatch, id]);

    return (
        <PdfViewWithStatusCheck
            isPdfChecking={isPdfChecking}
            isPdfAvailable={isPdfAvailable}
            {...props}
        />
    );
}

export default OfferLetterPdf;
