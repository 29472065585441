import React from 'react';
import { ClickAwayListener, Box, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useOpenState } from 'shared/hooks/useOpenState';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';

interface IScaWithTooltipProps {
    customFieldValueId: string;
}

export const ScaWithTooltip = ({ customFieldValueId }: IScaWithTooltipProps) => {
    const customFieldValue = useSelector(selectCustomFieldValuesByIds)[customFieldValueId];
    const { isOpened, open, close } = useOpenState();

    const tooltip = customFieldValue?.data?.counties ? `Counties: ${customFieldValue?.data?.counties?.join(', ')}` : '';

    return (
        <>
            {customFieldValue && (
                <ClickAwayListener onClickAway={close}>
                    <Tooltip
                        title={tooltip}
                        open={isOpened}
                    >
                        <Box
                            onClick={open}
                            onMouseEnter={open}
                            onMouseLeave={close}
                            textOverflow="ellipsis"
                            overflow="hidden"
                        >
                            {customFieldValue?.data?.name}
                        </Box>
                    </Tooltip>
                </ClickAwayListener>
            )}
        </>
    );
};
