import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik/dist/types';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import { createDealType } from '../store/actions';
import { selectIsDealTypeCreating } from '../store/selectors';
import FormField from 'shared/components/formFields/FormField';
import { ICreateDealTypeRequest, validationScheme } from '../store/models';

export const CreateDealType = () => {
    const classes = useInlineFormStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsDealTypeCreating);
    const onSubmitForm = (
        { name }: Partial<ICreateDealTypeRequest>,
        { resetForm }: FormikHelpers<Partial<ICreateDealTypeRequest>>,
    ) => {
        if (name) {
            dispatch(createDealType.init({ name }));
            resetForm();
        }
    };

    const formikData = useFormik<Partial<ICreateDealTypeRequest>>({
        initialValues: {
            name: '',
        },
        validationSchema: validationScheme,
        onSubmit: onSubmitForm,
    });
    const { handleSubmit } = formikData;

    return (
        <FormikProvider value={formikData}>
            <form
                onSubmit={handleSubmit}
                className={classes.form}
            >
                <FormField
                    name="name"
                    label="Deal type name"
                    className={classes.input}
                />
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    isLoading={isLoading}
                    data-testid="submit_button"
                >
                    Add
                </ButtonWithLoader>
            </form>
        </FormikProvider>
    );
};
