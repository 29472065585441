import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import { AssignmentsMetaDataForm } from 'modules/settings/submodules/clients/assignmentsReportData/components/AssignmentsMetaDataForm/AssignmentsMetaDataForm';
import { IAssignmentsMetaDataFormValue } from 'modules/settings/submodules/clients/assignmentsReportData/components/AssignmentsMetaDataForm/model';
import { selectEditAssignmentMetaData, selectEditMetaDataUser, selectIsUpdatingMetaData } from 'modules/settings/submodules/clients/assignmentsReportData/components/EditAssignmentsReportData/store/selectors';
import { setEditAssignmentEditDataId, updateAssignmentReportMetaDataAction } from 'modules/settings/submodules/clients/assignmentsReportData/store/actions';
import { getUserName } from 'shared/utils/converters/user';

export const EditAssignmentsReportDataModal = () => {
    const classes = useColoredFormModalStyles();
    const dispatch = useDispatch();
    const editAssignmentMetaData = useSelector(selectEditAssignmentMetaData);
    const user = useSelector(selectEditMetaDataUser);
    const isLoading = useSelector(selectIsUpdatingMetaData);

    const onClose = useCallback(() => {
        dispatch(setEditAssignmentEditDataId(null));
    }, [dispatch]);
    const onSave = useCallback((values: IAssignmentsMetaDataFormValue) => {
        dispatch(updateAssignmentReportMetaDataAction.init({
            id: editAssignmentMetaData?.id || '',
            request: {
                uw_number: values.uwNumber.toString(),
                sso_id: values.ssoId.toString(),
            },
        }));
    }, [dispatch, editAssignmentMetaData]);

    return (
        <ModalDialog
            title={`Edit Assignment Metadata - ${getUserName(user)}`}
            isOpened={Boolean(editAssignmentMetaData)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <AssignmentsMetaDataForm
                    onSubmit={onSave}
                    isLoading={isLoading}
                    reportMetaData={editAssignmentMetaData}
                />
            </Box>
        </ModalDialog>
    );
};
