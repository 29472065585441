import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useDetailTableStyles = makeHighPriorityStyles(theme => ({
    container: {
        margin: theme.spacing(2, -2, 0),
    },
    header: {
        display: 'flex',
        minHeight: theme.spacing(5),
        padding: theme.spacing(2),
        backgroundColor: colors.lightGray,
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightMedium,
        alignItems: 'center',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${colors.darkGray}`,
        borderTop: `1px solid ${colors.darkGray}`,
    },
    table: {
    },
    title: {
        fontWeight: theme.typography.fontWeightBold,
    },
    red: {
        color: colors.red,
    },
    cell: {
        minHeight: theme.spacing(7),
        boxSizing: 'border-box',
    },
}));
