import React from 'react';
import { Box } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import FormField from 'shared/components/formFields/FormField';

export function NameValueDataSection() {
    const classes = useSettingsFormStyles();

    return (
        <Box className={classes.formBlock}>
            <Box display="flex">
                <Box width="60%">
                    <FormField
                        name="data.name"
                        label="Name"
                        className={classes.input}
                    />
                </Box>
            </Box>
            <Box display="flex">
                <Box width="60%">
                    <FormField
                        name="data.value"
                        label="Value"
                        className={classes.input}
                    />
                </Box>
            </Box>
        </Box>
    );
}
