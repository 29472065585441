import React from 'react';
import { useField } from 'formik';
import { IDateComponentProps } from '../types';
import { shortDateFormat } from 'shared/models/Dates';
import DayPickerField from 'shared/components/formFields/DayPickerField';

export const StartDateComponent = ({ classes, disabled, assignmentStartDate }: IDateComponentProps) => {
    const [endDate] = useField<string>('endDate');
    const maxDate = endDate.value || undefined;

    return (
        <DayPickerField
            name="startDate"
            format={shortDateFormat}
            placeholder={shortDateFormat}
            outerLabel="Start Date"
            className={classes.input}
            withKeyboard
            maxDate={maxDate}
            minDate={assignmentStartDate}
            disabled={disabled}
        />
    );
};
