import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const usePayrollModalStyle = makeStyles((theme: Theme) =>
    createStyles({
        ContentWrapper: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        DateLabel: {
            fontFamily: `Roboto`,
            fontSize: `16px`,
            textDecoration: `none solid rgb(0, 0, 0)`,
            marginBottom: theme.spacing(2),
        },
        DateWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        DatePickerRow: {
            alignItems: 'baseline',
            display: 'grid',
            gridTemplateColumns: 'auto 200px',
            marginBottom: theme.spacing(2),
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        ButtonWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        SecondaryButton: {
            marginLeft: 8,
            textDecoration: 'underline',
        },
    }));
