import { setSheetApprovalLoadingState } from 'store/components/sheetApprovalCommon/sheetApprovalCommonActions';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { combineReducers } from 'redux';
import {
    getTimeReviewerSheets,

    TimeApprovalActions,
} from 'store/components/timeApproval/timeApprovalActions';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const initialState = {
    isLoaded: false,
    isLoading: true,
};

const isLoading = extendReducer(
    singleValueReducer(setSheetApprovalLoadingState.action, initialState.isLoading),
    isLoadingReducer(getTimeReviewerSheets, initialState.isLoading),
);

function isLoaded(state = initialState.isLoaded, action: TimeApprovalActions): boolean {
    switch (action.type) {
        case getTimeReviewerSheets.successType:
            return true;
        case getTimeReviewerSheets.initType:
            return false;
        default:
            return state;
    }
}

export const timeApproval = combineReducers({
    isLoading,
    isLoaded,
});
