import { AnyAction, combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';
import {
    SET_EDIT_CONTROLLING_ORG_ID,
    updateControllingOrg,
} from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/actions';

const controllingOrgId = (state = null, action: AnyAction): string | null => {
    switch (action.type) {
        case SET_EDIT_CONTROLLING_ORG_ID:
            return action.payload;
        case updateControllingOrg.successType:
            return null;
        default:
            return state;
    }
};

const isUpdating = isLoadingReducer(updateControllingOrg);

export const editControllingOrg = combineReducers({
    controllingOrgId,
    isUpdating,
});
