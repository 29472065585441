import { call, put } from 'typed-redux-saga';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { ActionCreatorKnownArgs } from 'store/utils';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';

export function withErrorHandler<FunctionType extends(...args: any) => any>(
    originalSaga: FunctionType, errorAction: ActionCreatorKnownArgs<any, any>, errorMessage?: string,
) {
    return function* (...args: Parameters<FunctionType>) {
        try {
            yield* call(originalSaga, ...args);
        } catch (e) {
            if (errorMessage) {
                yield* put(setGlobalToast({ severity: IModalSeverity.Error, title: errorMessage }));
            }
            logErrorWithCustomMessage(e, errorMessage);
            yield* put(errorAction(e));
        }
    };
}
