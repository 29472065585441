import React, { FC } from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useFilterAndActionCOntrolsStyles from '../FilterAndActionControlsStyles';

const Time: FC = () => {
    const classes = useFilterAndActionCOntrolsStyles();
    return (
        <AccessTimeIcon
            className={classes.clockIcon}
        >
        </AccessTimeIcon>
    );
};

export default Time;
