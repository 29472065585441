import React, { useMemo } from 'react';
import { selectUsers } from '../../../store/entities/users/selectors';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from '../formFields/models';
import { IFormSelect } from './model';
import FormSelect from './FormSelect';

interface IUserStaticProps extends IFormFieldProps, IFormSelect {
    userIds: string[];
}

export default function UserStaticSelect({ userIds, ...props }: IUserStaticProps) {
    const users = useSelector(selectUsers);
    const options = useMemo(() => {
        return users.filter(u => userIds ? userIds.includes(u.id) : true);
    }, [userIds, users]);
    return (
        <FormSelect
            getKey={user => user.id}
            getText={userData => String([userData?.last_name, userData?.first_name].join(', '))}
            options={options}
            {...props}
        />
    );
}
