import { useCallback } from 'react';
import { readFileContent } from 'shared/components/sidebars/EntryEdit/models';
import { addNewAttachment, removeAttachment,
    updateEntryAttachments, setFileRead } from 'store/entities/timesheet/actions/entryAttachments';
import { IFileUpload, IEntryAttachment, IPreviewTypes } from 'shared/models/Attachments';
import { useDispatch } from 'react-redux';

export type UploadedFiles = IPreviewTypes[];

export function useUploadedFiles() {
    const dispatch = useDispatch();
    const onAttachmentRemove = useCallback(
        (file: IEntryAttachment, entryId: string) => {
            dispatch(removeAttachment.init(file));
            dispatch(updateEntryAttachments({
                attachmentId: file.id,
                entryId: entryId,
            }));
        },
        [dispatch],
    );

    const onAttachmentChange = useCallback(
        (uploadFiles: IPreviewTypes[], entryId?: string ) => {
            uploadFiles.forEach(file => {
                dispatch(setFileRead(true));
                readFileContent(file).then(function (data){
                    const attachment: IFileUpload = {
                        body: data.body.split(';base64,')[1],
                        filename: data.filename,
                        mimetype: data.mimetype,
                        entry_id: entryId,
                    };
                    dispatch(addNewAttachment.init(attachment));
                });
                dispatch(setFileRead(false));
            });
        },
        [dispatch],
    );

    return {
        onAttachmentChange,
        onAttachmentRemove,
    };
}
