import { autocompleteActionPrefix, IAutocompleteSearchPayload } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/store/actions';
import { IJobNumberRequest } from 'store/entities/configuration/configurationModel';
import { createSingleAction } from 'store/utils';

interface IJobNumberSearchRequest extends IAutocompleteSearchPayload {
    request: IJobNumberRequest;
}
const searchJobNumberAction = `${autocompleteActionPrefix}/JOB_NUMBER`;
export const autocompleteSearchJobNumber = createSingleAction<IJobNumberSearchRequest, typeof searchJobNumberAction>(
    searchJobNumberAction,
);
