import * as yup from 'yup';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { moment, maxMomentDate } from 'utils/momentExtensions';
import { intersection, pick } from 'lodash-es';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import { getCustomFieldValueGroupedByCustomFieldId } from 'store/entities/customFields/helpers';
import { HierarchyNodeOperation } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds, selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';
import { ValidationMessages } from 'shared/models/Validation';
import { IBulkEditSubAssignmentForm } from './types';
import { getApproversValidationSchema, getApproversValidationSchemaObject } from 'modules/subassignmentManagement/hooks/getApproversValidationSchemaObject';
import { useSubassignmentCustomFieldsValidationSchema } from 'modules/subassignmentManagement/components/EditSubAssignment/hooks/useSubassignmentCustomFieldsValidationSchema';

const useActionableCustomFieldIds = () => {
    const hierarchy = useSelector(selectOrderedCustomFieldAssignmentNodes);
    return useMemo(
        () => hierarchy.filter(node => node.operation === HierarchyNodeOperation.Actionable)
            .map(node => node.custom_field_id),
        [hierarchy],
    );
};

const useExistSubassignments = (assignmentIds: string[], ignoreSubassignmentsIds: string[]) => {
    const subassignmentsByIds = useSelector(selectSubassignmentsByIds);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const actionableFieldIds = useActionableCustomFieldIds();

    return useMemo(() => {
        const subassignments = Object.values(subassignmentsByIds).filter((subassignment: ISubassignment) => {
            return assignmentIds.includes(subassignment.assignment_id)
                && !ignoreSubassignmentsIds?.includes(subassignment.id);
        });

        return subassignments.map((subassignment: ISubassignment) => {
            const valuesByFieldIds = pick(getCustomFieldValueGroupedByCustomFieldId(
                subassignment?.custom_field_value_ids || [],
                customFieldValuesByIds,
            ), actionableFieldIds);
            return {
                ...subassignment,
                valuesByFieldIds,
                actionableAllFieldIds: intersection(subassignment.all_values_custom_field_ids, actionableFieldIds),
            };
        });
    }, [subassignmentsByIds, assignmentIds, ignoreSubassignmentsIds, actionableFieldIds, customFieldValuesByIds]);
};

const isSubassignmentPeriodIntersect = (subassignment: ISubassignment, startDate: string, endDate?: string) => {
    const subassignmentPeriod = moment.range(
        moment(subassignment.start_date),
        subassignment.end_date ? moment(subassignment.end_date).endOf('day') : maxMomentDate,
    );
    const newSubassignmentPeriod = moment.range(
        moment(startDate),
        endDate ? moment(endDate).endOf('day') : maxMomentDate,
    );
    return subassignmentPeriod.intersect(newSubassignmentPeriod);
};

const startDateValidationSchemaBase = yup.string()
    .trim()
    .when(['endDate'], (endDate: string, schema: yup.StringSchema) => {
        const endDateMoment = moment(endDate || maxMomentDate);
        return schema.test('max-date', 'Start date should not be after the end date', ((value: string) => {
            if (!value) {
                return true;
            }
            return moment(value).isSameOrBefore(endDateMoment);
        }));
    });

export const datesSchema = yup.object().shape({
    startDate: startDateValidationSchemaBase.required(ValidationMessages.REQUIRED),
    endDate: yup.string().nullable(),
});

export const useEditSubAssignmentValidationSchema = (
    isBulkEdit = false,
    numberOfApprovers = 1,
    assignmentId?: string,
    subassignmentId?: string,
    short = false,
) => {
    const customFieldValuesSchema = useSubassignmentCustomFieldsValidationSchema(true);
    const customFieldValueSchema = useSubassignmentCustomFieldsValidationSchema(false);
    const approversSchema = getApproversValidationSchemaObject(isBulkEdit, numberOfApprovers);
    const existSubassignments = useExistSubassignments(
        assignmentId ? [assignmentId] : [],
        subassignmentId ? [subassignmentId] : [],
    );
    const actionableNodes = useActionableCustomFieldIds();

    if (short) {
        return datesSchema.concat(approversSchema);
    }

    const baseSchema = yup.object().shape<Partial<IBulkEditSubAssignmentForm>>({
        overrideRate: yup.number().nullable().required(ValidationMessages.REQUIRED),
        // @ts-ignore
        customFieldValues: isBulkEdit ? yup.object().when('modifyCustomFields', {
            is: true,
            then: customFieldValuesSchema,
            otherwise: yup.object().nullable().notRequired(),
        }) : customFieldValuesSchema,
        customFieldValue: isBulkEdit ? yup.object().when('modifyCustomFields', {
            is: true,
            then: customFieldValueSchema,
            otherwise: yup.object().nullable().notRequired(),
        }) : customFieldValueSchema,
    }).concat(datesSchema)
        .concat(approversSchema);

    if (!subassignmentId) {
        return baseSchema;
    }

    return baseSchema.test({
        name: 'uniqSubassignmentActionableFields',
        message: 'Subassignment with these actionable fields already exist',
        test: function (values) {
            const isValid = !existSubassignments.some(existSubassignment => {
                return actionableNodes.every(fieldId => {
                    const formFieldValue = [
                        ...values.customFieldValues[fieldId] || [],
                        values.customFieldValue[fieldId],
                    ].filter(Boolean);
                    return isSubassignmentPeriodIntersect(existSubassignment, values.startDate, values.endDate)
                        && (
                            values.customFieldValuesAllFields[fieldId]
                            // @ts-ignore
                            || existSubassignment.all_values_custom_field_ids.includes(fieldId)
                            || intersection(formFieldValue, existSubassignment.valuesByFieldIds[fieldId]).length
                        );
                });
            });
            return isValid ? isValid : this.createError({
                path: 'combinationOfActionableFieldsAlreadyUsed',
                message: 'Subassignment with these actionable fields already exist',
            });
        },
    });
};

export const useEditSubAssignmentValidationSchemaV2 = (
    numberOfApprovers = 1,
) => {
    const approversSchema = getApproversValidationSchema(numberOfApprovers);

    return yup.object().shape<Partial<IBulkEditSubAssignmentForm>>({
        overrideRate: yup.number().nullable(),
        // @ts-ignore
        approvers: approversSchema.notRequired().nullable(),
        startDate: startDateValidationSchemaBase,
    });
};
