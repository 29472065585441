import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ICustomFieldsValuesSelectGatewayProps } from 'shared/components/forms/entries/CustomFieldsValuesSelectGateway';
import { useAutoselectSingleVariant, useFieldResetIfSelectedValueFiltered, useFilteredCustomFieldValues } from 'shared/components/selects/CustomFieldValuesSelect/hooks';
import { useRenderInput } from 'shared/components/selects/SCACustomFieldValueSelect/helpers';
import { scaFieldValueByStateAndCounty, useScaHelperData } from 'shared/components/selects/SCACustomFieldValueSelect/hooks';
import { useStyles } from 'shared/components/selects/SCACustomFieldValueSelect/styles';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { getFieldValueId, getFieldValueName } from 'store/entities/customFields/helpers';
import { HierarchyType } from 'store/entities/customFields/model';
import { selectCustomFieldById, selectCustomFieldValuesByFieldId, selectIsCustomFieldValuesLoading } from 'store/entities/customFields/selectors';

export interface ISCACustomFieldValueSelectProps extends ICustomFieldsValuesSelectGatewayProps {
    tooltip?: string;
}

export const SCACustomFieldValueSelect = ({
    customFieldId,
    hierarchy,
    customFieldFormValues = {},
    userId,
    date,
    valuesToShow,
    payPeriod,
    useSubassignments = false,
    autoSelectSingleVariant = false,
    hierarchyType = HierarchyType.Assignment,
    name,
    className,
    disabled = false,
    id = name,
    tooltip = '',
    outerLabel,
    onlyActionable = true,
    onConfirmSelection,
    scopeActionFilter,
    filterInactive = true,
    ...props
}: ISCACustomFieldValueSelectProps) => {
    const [field, meta, helper] = useField(name);
    const hasError = Boolean(meta.error && meta.touched);
    const customField = useSelector(selectCustomFieldById(customFieldId));
    const formHelperTextClasses = useFormHelperTextStyles();
    const classes = useStyles();

    const customFieldValues = useSelector(selectCustomFieldValuesByFieldId(customFieldId));
    const isLoading = useSelector(selectIsCustomFieldValuesLoading);

    const filteredCustomFieldValues = useFilteredCustomFieldValues({
        hierarchy,
        customFieldId,
        customFieldFormValues,
        customFieldValues,
        useSubassignments,
        valuesToShow,
        hierarchyType,
        userId,
        date,
        onlyActionable,
        scopeActionFilter,
        payPeriod,
        filterInactive,
    });

    useAutoselectSingleVariant({
        autoSelectSingleVariant,
        filteredCustomFieldValues,
        helper,
        selectedValue: field.value,
        useIdValue: props.useIdValue,
        // @ts-ignore
        callback: props.onConfirmSelection,
    });
    useFieldResetIfSelectedValueFiltered({
        customFieldValues,
        filteredCustomFieldValues,
        helper,
        selectedValue: field.value,
        useIdValue: props.useIdValue,
        // @ts-ignore
        callback: props.onConfirmSelection,
    });

    const { states, countiesByState } = useScaHelperData(filteredCustomFieldValues);

    const [stateValue, setStateValue] = useState<string | null>(null);
    const [countyValue, setCountyValue] = useState<string | null>(null);
    const [innerValue, setInnerValue] = useState(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const getValue = useCallback((newValue: any) => {
        return customFieldValues.find(i => getFieldValueId(i) === newValue) || null;
    }, [customFieldValues]);

    useEffect(() => {
        const value = getValue(field.value);
        // @ts-ignore
        setInnerValue(value);
    }, [field.value, setInnerValue, getValue]);

    useEffect(() => {
        if (stateValue && !countyValue) {
            inputRef.current?.focus();
        }
    }, [stateValue, countyValue, inputRef]);

    const renderInput = useRenderInput({
        error: hasError && meta.error,
        isLoading,
        classes,
        inputRef,
    });

    const autocompleteProps = useMemo(() => {
        const setNewSca = (_: any, newAutocompleteValue: any) => {
            const newValue = newAutocompleteValue
                ? getFieldValueId(newAutocompleteValue) : newAutocompleteValue;
            helper.setValue(newValue);
            helper.setTouched(false);
            setStateValue(null);
            setCountyValue(null);
        };

        if (field.value) {
            // render custom field value
            return {
                value: innerValue,
                options: filteredCustomFieldValues,
                getOptionLabel: getFieldValueName,
                onChange: setNewSca,
                onBlur: () => {
                    helper.setTouched(false);
                },
                renderInput: renderInput(customField.name),
                type: 'search',
            };
        }
        const filterOptions = (options, state) => options.filter(
            option => option.toLowerCase().startsWith(state.inputValue.toLowerCase()),
        );
        if (stateValue) {
            // render county autocomplete

            const deselectState = () => {
                setStateValue(null);
            };

            return {
                value: countyValue,
                options: countiesByState[stateValue],
                getOptionLabel: (option: string) => option,
                onChange: (_: any, newValue: string | null) => {
                    setCountyValue(newValue);
                    const selectedScas = scaFieldValueByStateAndCounty(stateValue, newValue, filteredCustomFieldValues);
                    setNewSca(null, selectedScas[0]);
                },
                onClose: () => {
                    onConfirmSelection && onConfirmSelection();
                },
                open: true,
                type: 'search',
                renderInput: renderInput(
                    'Select county',
                    <Box ml={1}><InputAdornment position="start">{stateValue}</InputAdornment></Box>,
                    <Box mr={-8} zIndex={1}>
                        <InputAdornment position="end">
                            <IconButton onClick={deselectState}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        </InputAdornment>
                    </Box>,
                ),
                filterOptions,
            };
        }
        // render state autocomplete
        return {
            value: stateValue,
            options: states,
            getOptionLabel: (option: string) => option,
            onChange: (_: any, newValue: string | null) => {
                setStateValue(newValue);
            },
            renderInput: renderInput('Select state'),
            filterOptions,
        };
    }, [
        countiesByState,
        countyValue,
        customField.name,
        filteredCustomFieldValues,
        field.value,
        helper,
        innerValue,
        renderInput,
        stateValue,
        states,
        onConfirmSelection,
    ]);

    if (useSubassignments && filteredCustomFieldValues.length === 0) {
        return null;
    }

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            {outerLabel && (
                <label
                    htmlFor={id}
                    className={formHelperTextClasses.outerLabel}
                >
                    {outerLabel}
                </label>
            )}
            <Tooltip title={tooltip}>
                {
                    <Autocomplete
                        {...props}
                        {...field}
                        disabled={disabled}
                        id={id}
                        multiple={false}
                        {...autocompleteProps}
                        key={`${stateValue}-${countyValue}-${innerValue}`}
                        popupIcon={<ArrowDropDownIcon fontSize="small"/>}
                        classes={{
                            popupIndicator: classes.popupIndicator,
                        }}
                    />
                }
            </Tooltip>
            {hasError && (
                <FormHelperText classes={formHelperTextClasses}>
                    {meta.error}
                </FormHelperText>
            )}
        </FormControl>
    );
};
