import clsx from 'clsx';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { colors } from 'shared/styles/constants';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { activityGridView } from 'shared/utils/formatters/activityFormatter';
import React from 'react';
import { IEntryRow } from 'shared/components/table/EntriesTable/model';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';

export default function ActivityWithIcon({ className, entry, activity }: IEntryRow) {
    const classes = useEntriesTableStyles();

    return (
        <div className={clsx(className)}>
            {(() => {
                if (entry.entry_type === EntryType.TIME) {
                    const color = entry.data.entry_type === QuantityType.TIME_BREAK ? colors.orange : colors.blue;
                    return (
                        <AccessTimeIcon className={classes.textIcon} htmlColor={color}/>
                    );
                }
                return (
                    <AttachMoneyIcon className={classes.textIcon} htmlColor={colors.green}/>
                );
            })()}
            <span className={classes.activityText}>
                {activityGridView(entry, activity)}
            </span>
        </div>
    );
}
