import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { maxMomentDate, moment } from 'utils/momentExtensions';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { selectOrderedPayPeriods } from 'store/entities/timesheet/selectors';

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];

export const monthNamesShort = monthNames.map(x => {
    return x.substring(0, 3);
});

export const dayOfWeekNames = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
];

export const dayOfWeekNamesShort = [
    'Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat',
];

export const monthTextDateFormat = 'MMM D, YYYY';
export const shortDateFormat = 'MM/DD/YYYY';
export const extraShortDateFormat = 'M/DD/YY';
export const backendDateFormat = 'YYYY-MM-DD';
export const fileTimestampFormat = `${backendDateFormat}-HH.mm.ss`;
export const backendDateTimeFullFormat = 'YYYY-MM-DDTHH:mm:ss.SSSSS';
export const DATE_FORMAT = {
    YYYY_MM_DD: 'YYYY-MM-DD',
    MM_DD_YYYY: 'MMM DD, YYYY',
};
export const dateTimeWithZoneFormat = 'MM/DD/YY @ hh:mm A z';

export function isDateInPeriod(startDate: string, endDate: string | null, day?: moment.MomentInput) {
    return moment(day).isBetween(startDate, endDate || maxMomentDate, 'days', '[]');
}
export function isDateInPayPeriod(payPeriod: IPayPeriod, day?: moment.MomentInput) {
    return isDateInPeriod(payPeriod.period_start, payPeriod.period_end, day);
}

export function usePayPeriod(day?: string): IPayPeriod | undefined {
    const payPeriods = useSelector(selectOrderedPayPeriods);
    return useMemo(() => {
        // Return pay period for current week or latest available
        return payPeriods.find(period => {
            return isDateInPayPeriod(period, day);
        }) || payPeriods[payPeriods.length - 1];
    }, [day, payPeriods]);
}

export const getFormattedPayPeriod = (payPeriod: IPayPeriod, dateFormat = shortDateFormat): string => {
    const periodStart = moment(payPeriod.period_start);
    const periodEnd = moment(payPeriod.period_end);
    return `${periodStart.format(dateFormat)} - ${periodEnd.format(dateFormat)}`;
};

export function formatUtcWithEstZone(datetimeStr: string): string {
    return moment.utc(datetimeStr).tz('EST').format(dateTimeWithZoneFormat);
}

export type DateBackend = string; // TODO: implement YYYY-MM-DD
