import { selectEcubReportPauseInProgress } from 'modules/settings/submodules/clients/reports/Ecub/store/selectors';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Send, PauseCircleOutline, PauseCircleFilled } from '@material-ui/icons';
import { IconActionButton } from 'modules/settings/common/components/IconActionButton/IconActionButton';
import {
    donwloadEcubReport,
    pauseEcubReport,
    sendEcubReport,
} from 'modules/settings/submodules/clients/reports/Ecub/store/actions';
import { IEcubItem } from 'modules/settings/submodules/clients/reports/Ecub/store/models';
import { useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';

export function ActionCell({ className, name, is_sent, is_paused, able_to_send_automatically }: ICellProps<IEcubItem>){
    const tableClasses = useDefaultTableStyles();
    const payload = useMemo(() => ({
        filename: name,
    }), [name]);
    const pausePayload = useMemo(() => ({
        ...payload,
        is_paused: !is_paused,
    }), [payload, is_paused]);
    const isPauseInProgress = useSelector(selectEcubReportPauseInProgress);
    return (
        <Box
            className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon, tableClasses.pullRight)}
        >
            {able_to_send_automatically && (
                <IconActionButton
                    payload={pausePayload}
                    action={pauseEcubReport.init}
                    Icon={is_paused ? PauseCircleFilled : PauseCircleOutline}
                    title={is_paused ? 'paused' : 'pause'}
                    confirmation={
                        is_paused
                            ? 'Are you sure you want to enable automatic sending for this report?'
                            : 'Are you sure you want to disable automatic sending for this report?'
                    }
                    confirmText={is_paused ? 'Unpause' : 'Pause'}
                    isLoading={isPauseInProgress}
                />
            )}
            <IconActionButton
                payload={payload}
                action={donwloadEcubReport.init}
                Icon={GetAppIcon}
                title="download"
            />
            {!is_sent && (
                <IconActionButton
                    payload={payload}
                    action={sendEcubReport.init}
                    Icon={Send}
                    title="send"
                    confirmation="Are you sure that you want to send eCub file?"
                    confirmText="Send"
                />
            )}
        </Box>
    );
}
