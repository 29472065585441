import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useOfferPdfOnlyStyles } from 'modules/offerLetter/OfferLetterEmployee/components/useOfferPdfOnlyStyles';
import { PdfViewWithStatusCheck } from 'modules/offerLetter/OfferLetterEmployee/components/PdfViewWithStatusCheck';
import { checkEndOfAssignmentPdfStatus } from 'modules/offerLetter/store/actions';
import { selectEndOfAssignmentPdfCheckStatusById } from 'modules/offerLetter/store/selectors';
import { baseUrl } from 'shared/utils/baseUrl';
import { DefaultPdfFallback } from './components/DefaultPdfFallback';

const basePath = `${baseUrl}/documents/end_of_assignment`;

export const EndOfAssignmentPdfOnly = () => {
    const { id } = useParams<{ id: string }>();
    const classes = useOfferPdfOnlyStyles();

    const checkStatus = useSelector(selectEndOfAssignmentPdfCheckStatusById(id));
    const isPdfChecking = Boolean(checkStatus.isLoading);
    const isPdfAvailable = Boolean(!checkStatus.result?.isError);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(checkEndOfAssignmentPdfStatus.init({ id }));
    }, [dispatch, id]);

    return (
        <Box className={classes.wrapper}>
            <PdfViewWithStatusCheck
                isPdfChecking={isPdfChecking}
                isPdfAvailable={isPdfAvailable}
                errorRender={(<DefaultPdfFallback/>)}
                filePath={`${basePath}/${id}`}
                title="End of Assignment"
            />
        </Box>
    );
};
