import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectOfferLetterTemplatesById } from 'modules/offerLetter/store/templates/selectors';
import { IOfferLetterTemplateEditForm } from 'modules/settings/submodules/offerLetters/model';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { Status } from 'shared/models/Status';

export function useOfferLetterTemplateEditInitialState(
    offerLetterTemplateId: string | null,
): IOfferLetterTemplateEditForm {
    const clientsById = useSelector(selectAllClientsById);
    const offerLetterTemplatesById = useSelector(selectOfferLetterTemplatesById);
    const currentOfferLetterTemplate = offerLetterTemplatesById[offerLetterTemplateId || ''];

    const initialState = useMemo<IOfferLetterTemplateEditForm>(() => {
        if (!currentOfferLetterTemplate) {
            return {
                name: '',
                description: '',
                clients: [],
                allClients: false,
                fileName: '',
                status: Status.active,
            };
        }
        return {
            name: currentOfferLetterTemplate.name,
            description: currentOfferLetterTemplate.description,
            clients: currentOfferLetterTemplate.client_ids?.map(id => clientsById[id]).filter(Boolean) || [],
            allClients: currentOfferLetterTemplate.all_clients,
            fileName: currentOfferLetterTemplate.filename,
            status: currentOfferLetterTemplate.status,
        };
    }, [currentOfferLetterTemplate, clientsById]);

    return initialState;
}
