import { AxiosRequestConfig } from 'axios';
import baseApi from 'shared/utils/baseApi';
import {
    IStatus,
    IExpenseSheetBackend,
    IZipCodeFull,
    IExpenseEntry,
    ISheetCommonBackend,
    IExpenseEntryBackend,
    ISheetApproval,
} from 'shared/models/sheet/Sheet';
import { IFileUpload, IEntryAttachment } from 'shared/models/Attachments';
import { IConfigurationByClient, ISheetSearchRequest } from 'store/entities/configuration/configurationModel';
import {
    IExpenseEntryCreate, IExpenseEntryUpdate,
    ICreateEntryParams,
} from 'store/entities/timesheet/models/Entry';
import { IWithUserInfo } from 'shared/models/Authentication';
import { IUpdateSheetsStatus } from '../models/Status';
import {
    IBatchSheetUnlock,
    ISheetBatchUnlockResponse,
    ISheetGroupListParams,
    ISheetGroupResponse,
    ISheetListParams,
    ISheetLogRequest,
    ISheetLogResponse,
} from 'store/entities/timesheet/models/SheetApi';

import { IWithClientId } from 'shared/models/Api';
import { Permission } from 'store/components/auth/authModels';
import {
    ICreateApprovalBody,
    ICreateApprovalResponse,
    ICreatedApproval, ISheetApprovalRequest,
} from 'store/entities/timesheet/models/SheetApprovals';
import { withClientId } from 'store/utils/withClientId';

const baseRoute = 'expense';

export interface IExpenseEntryCreateRequest extends IExpenseEntryCreate, IWithUserInfo {
    zip_code: IZipCodeFull;
    user_id: string;
}
export interface IExpenseEntryUpdateRequest extends Omit<IExpenseEntryUpdate, 'id'>, IWithUserInfo {
    user_id: string;
}

export interface IExpenseEntrySearchRequest {
    client_id?: string,
    status_id?: string,
    user_id?: string,
    period_start?: string,
    period_end?: string,
    period_ends?: string[],
    sheet_ids?: string[],
    is_travel?: true,
    entry_ids?: string[],
    job_number_ids?: string[],
    activity_ids?: string[],
    page_size?: number,
    cursor?: string,
}

export interface IExpenseEntrySearchResponse{
    total_items: number,
    next_cursor: string,
    linked: {
        sheets: ISheetCommonBackend[],
    },
    items: IExpenseEntryBackend[],
}

export const expenseApi = {
    async createEntry(entryRequest: IExpenseEntryCreateRequest, params: ICreateEntryParams):
    Promise<IExpenseEntry>{
        const { data } = await baseApi.create<
        IExpenseEntryCreateRequest & IWithClientId, { sheet_entry: IExpenseEntry }
        >(
            baseRoute, 'sheet_entries', withClientId(entryRequest), params,
        );
        return data.sheet_entry;
    },
    async updateEntry(id: string, entryRequest: IExpenseEntryUpdateRequest): Promise<IExpenseEntry> {
        const url = `/${baseRoute}/sheet_entries/${id}`;
        const { data } = await baseApi.patch<
        IExpenseEntryUpdateRequest & IWithClientId, { sheet_entry: IExpenseEntry }
        >(
            url, withClientId(entryRequest),
        );
        return data.sheet_entry;
    },
    async searchEntries(request: IExpenseEntrySearchRequest): Promise<IExpenseEntrySearchResponse> {
        const url = `/${baseRoute}/sheet_entries/search`;
        const { data } = await baseApi.post<
        IExpenseEntrySearchRequest, IExpenseEntrySearchResponse
        >(
            url, withClientId(request),
        );
        return data;
    },
    async deleteEntry(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(`/${baseRoute}/sheet_entries`, id);
        return data.status;
    },

    async createSheetEntryAttachment(entryToUpload: IFileUpload): Promise<IEntryAttachment> {
        const response = await baseApi.create<IFileUpload, { sheet_entry_attachment: IEntryAttachment }>(
            baseRoute, 'sheet_entry_attachments', entryToUpload,
        );
        return response.data.sheet_entry_attachment;
    },
    async deleteSheetEntryAttachment(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(`/${baseRoute}/sheet_entry_attachments`, id);
        return data.status;
    },
    async getSheets(
        params: ISheetSearchRequest = {},
    ): Promise<ISheetSearchResponse> {
        const { data } = await baseApi.post<ISheetSearchRequest, ISheetSearchResponse>(
            `/${baseRoute}/sheets/search`,
            withClientId(params),
        );
        return data;
    },
    async getSheetListByPurpose(
        purpose: Permission, params: ISheetListParams = {},
    ): Promise<Array<IExpenseSheetBackend>> {
        const { data } = await baseApi.get<{ sheets: Array<IExpenseSheetBackend> }>(
            `/${baseRoute}/sheets/by-purpose/${purpose}`, withClientId(params),
        );
        return data.sheets;
    },
    async getGroupedSheetsByPurpose(
        purpose: Permission, params: ISheetGroupListParams = {},
    ): Promise<ISheetGroupResponse> {
        const { data } = await baseApi.get<ISheetGroupResponse>(
            `/${baseRoute}/sheets/by-purpose/${purpose}/grouped`, withClientId(params),
        );
        return data;
    },
    async getSimplifiedSheetListByPurpose(
        purpose: Permission, params: ISheetListParams = {},
    ): Promise<ISheetCommonBackend[]> {
        const { data } = await baseApi.get<{ sheets: ISheetCommonBackend[] }>(
            `/${baseRoute}/sheets/by-purpose/${purpose}/simple`, withClientId(params),
        );
        return data.sheets;
    },
    async getAvailableStatuses(request?: IConfigurationByClient): Promise<Array<IStatus>>{
        const { data } = await baseApi.get<{ statuses: Array<IStatus> }>(
            `/${baseRoute}/sheet_statuses`, withClientId(request || {}),
        );
        return data.statuses;
    },
    async updateSheetsStatuses(payload: IUpdateSheetsStatus): Promise<Array<IExpenseSheetBackend>> {
        const { data } = await baseApi.patch<IUpdateSheetsStatus, { sheets: Array<IExpenseSheetBackend> }>(`${baseRoute}/sheets`, payload);
        return data.sheets;
    },
    /**
     * When manager clicks Approve, sheet status may not change status to approved if there is higher manager.
     * So now it will create sheet approvals.
     * @param sheetIds Ids of Sheets that managers wants to approve.
     * @returns promise of created approvals
     */
    async createSheetApprovals( sheetIds: string[]): Promise<Array<ICreatedApproval>> {
        const url = `${baseRoute}/sheet_approvals`;
        const body: ICreateApprovalBody = {
            sheet_approvals: sheetIds.map(sheetId => {
                return {
                    sheet_id: sheetId,
                };
            }),
        };

        const { data } = await baseApi.post<ICreateApprovalBody, ICreateApprovalResponse>(url, body);
        return data.sheet_approvals;
    },
    async getSheetById(id: string): Promise<IExpenseSheetBackend> {
        const { data } = await baseApi.get<{ sheet: IExpenseSheetBackend }>(
            `/${baseRoute}/sheets/${id}`,
        );
        return data.sheet;
    },
    async batchUnlockSheets(payload: IBatchSheetUnlock): Promise<ISheetBatchUnlockResponse> {
        const { data } = await baseApi.post(
            `/${baseRoute}/sheets/batch_unlock`,
            payload,
        );
        return data;
    },
    async getSheetApprovals(request: ISheetApprovalRequest): Promise<Array<ISheetApproval>>{
        const { data } = await baseApi.get<{ sheet_approvals: Array<ISheetApproval> }>(
            `/${baseRoute}/sheet_approvals`, request,
        );
        return data.sheet_approvals;
    },
    async getEntryAttachment(attachmentId: string): Promise<BlobPart>{
        const config: AxiosRequestConfig = { responseType: 'blob' };
        const { data } = await baseApi.get<BlobPart>(
            `/${baseRoute}/sheet_entry_attachments/${attachmentId}`, {}, config,
        );
        return data;
    },
    async getSheetLogs(request: ISheetLogRequest): Promise<Array<ISheetLogResponse>>{
        const { data } = await baseApi.post<ISheetLogRequest, { logs: Array<ISheetLogResponse> }>(
            `/${baseRoute}/sheets/logs/query`, request,
        );
        return data.logs;
    },
};

export interface ISheetSearchResponse {
    total_items: number,
    next_cursor: string,
    items: IExpenseSheetBackend[],
}
