import { EafStatusSlug, EafType, IEmployeeActionForm } from 'modules/employeeActionsForm/store/models';
import { ICellInfo, IClickCellProps } from 'shared/components/table/GridTable/GridTableModel';

export enum EafApprovalGridCellKey {
    Checkbox = 'EAF_Grid_Cell_Checkbox',
    Employee = 'EAF_Grid_Cell_Employee',
    Client = 'EAF_Grid_Cell_Client',
    ChangeType = 'EAF_Grid_Cell_Change_Type',
    OriginalValue = 'EAF_Grid_Cell_Original_Value',
    NewValue = 'EAF_Grid_Cell_New_Value',
    Manager = 'EAF_Grid_Cell_Manager',
    CreationDate = 'EAF_Grid_Cell_Created_At',
    EffectiveDate = 'EAF_Grid_Cell_Effective_Date',
    Actions = 'EAF_Grid_Cell_Actions',
    Status = 'EAF_Grid_Cell_Status',
    Reason = 'EAF_Grid_Cell_Reason',
    Details = 'EAF_Grid_Cell_Details',
    Docs = 'EAF_Grid_Cell_Docs',
    Subassignment = 'EAF_Grid_Cell_Subassignment',
}

export interface IEafApprovalFilter {
    type: EafType | null;
    employeeUserId: string | null;
    defaultTypes?: EafType[];
    isRetro: boolean;
    clientId: string | null;
    assignmentId?: string;
    allStatuses?: boolean;
}

export type EafApprovalCell = ICellInfo<IEmployeeActionForm>;
export type EafApprovalCellProps = IClickCellProps<IEmployeeActionForm>;
export type EafApprovalTitleCellProps = {
    rows: IEmployeeActionForm[];
};

export interface IEafApprovalItemsRequest {
    end_date?: string;
    page_size?: number;
    location_value_id?: string;
    start_date?: string;
    department_value_id?: string;
    client_id?: string;
    status_slug?: EafStatusSlug;
    eaf_type?: EafType;
    eaf_types?: EafType[];
    employee_user_id?: string;
    position_value_id?: string;
    approver_user_id?: string;
    is_retro?: boolean;
    sort?: string[]; // Comma-delimited tuple of sort field and order
    cursor: string | null;
}

export interface IEafApprovalItemsResponse {
    items: Array<IEmployeeActionForm>;
    current_cursor: string | null;
    next_cursor: string | null;
    total_items: number;
}

export interface IEafStatusChangePayload {
    status_slug: EafStatusSlug;
    approval_rejection_reason?: string;
    employee_action_form_id: string;
}

export interface IPatchEmployeeActionFormRequest {
    employee_action_forms: Array<IEafStatusChangePayload>;
}

export interface IPatchEmployeeActionFormsResponse {
    employee_action_forms: IEmployeeActionForm[];
}

export const EafPageTitle = 'Employee Action Forms';
