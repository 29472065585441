export interface ITimeInputValue {
    hours: number;
    minutes: number;
}

export const timeParserToInputValue = (value: string): ITimeInputValue | null => {
    const minutesInHour = 60;
    const hoursInDay = 24;
    const plainParsed24h = 2400;
    const borderForMinutesAndHours = 100;
    const changedValue = value;
    const regexpFull = new RegExp(/((\d{0,2})?:(\d{0,2})?)|(\d{0,4})?/g);
    const [, , hours, minutes, plain] = regexpFull.exec(changedValue);
    let currentValuePlain = '';

    if (plain !== undefined && plain.length > 0) {
        currentValuePlain = plain;
    } else if (hours !== undefined && minutes !== undefined) {
        currentValuePlain = `${hours}${minutes}`;
    } else {
        return null;
    }

    const currentValue = parseInt(currentValuePlain);
    let parsedHours = 0;
    let parsedMinutes = 0;
    if (currentValue < borderForMinutesAndHours || currentValue > plainParsed24h) {
        parsedHours = Math.trunc(currentValue / minutesInHour);
        parsedMinutes = currentValue - (parsedHours * minutesInHour);
    } else {
        parsedHours = Math.trunc(currentValue / borderForMinutesAndHours);
        parsedMinutes = currentValue - (parsedHours * borderForMinutesAndHours);
    }
    if (parsedHours > hoursInDay
        || (parsedHours === hoursInDay && parsedMinutes > 0)) {
        parsedHours = hoursInDay;
        parsedMinutes = 0;
    }
    return {
        hours: parsedHours,
        minutes: parsedMinutes,
    };
};
