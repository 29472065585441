import React from 'react';
import { Box } from '@material-ui/core';
import { SyncingModels } from 'store/entities/appConfig/syncing/models';
import PayRangesFilter from 'modules/settings/submodules/clients/payRanges/components/payRangesFilter/PayRangesFilter';
import { PayRangesTable } from 'modules/settings/submodules/clients/payRanges/components/payRangesTable/PayRangesTable';
import { PayRangeCreationForm } from 'modules/settings/submodules/clients/payRanges/components/payRangeCreationForm/PayRangeCreationForm';
import { PayRangeFilterChips } from 'modules/settings/submodules/clients/payRanges/components/PayRangeFilterChips/PayRangeFilterChips';
import { SyncMessage } from 'shared/components/toolbar/SyncMessage';

export const PayRangesPage = () => {
    return (
        <>
            <Box
                mr={-4}
                display="flex"
                justifyContent="flex-end"
            >
                <SyncMessage syncingKey={SyncingModels.PayRanges}/>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                mb={2}
                alignItems="baseline"
            >
                <PayRangeCreationForm/>
                <PayRangesFilter/>
            </Box>
            <PayRangeFilterChips/>
            <PayRangesTable/>
        </>
    );
};
