import React from 'react';
import { EntryType } from 'shared/models/sheet/Sheet';
import SheetFilterButton from 'shared/components/filters/FilterButtonAndForm/SheetFilterButton';
import {
    selectWorkingSheetFiltersConfig,
    selectWorkingSheetsFilters,
} from 'store/components/workingSheets/workingSheetsSelectors';
import { setSheetsGridFilters } from 'store/components/workingSheets/workingSheetsActions';
import { useSelector } from 'react-redux';
import { selectPayPeriodWorkingEntries } from 'modules/timeAndExpense/components/WorkingPage/store/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { useSheetEntriesCustomFieldsWithValuesById } from 'shared/utils/hooks/customFieldsWithValuesHooks';

interface IFilterButtonProps {
    activeEntryType?: EntryType;
    className?: string;
}

export function SheetsInProgressFilters({ activeEntryType, className }: IFilterButtonProps) {
    const entries = useSelector(selectPayPeriodWorkingEntries);
    const clientId = useSelector(selectCurrentClientId);
    const tableFieldsWithValuesById = useSheetEntriesCustomFieldsWithValuesById(entries, clientId);

    return (
        <SheetFilterButton
            className={className}
            entryType={activeEntryType}
            selectFilters={selectWorkingSheetsFilters}
            selectFiltersConfig={selectWorkingSheetFiltersConfig}
            submitFilters={setSheetsGridFilters}
            tableFieldsWithValuesById={tableFieldsWithValuesById}
        />
    );
}
