import { createSelector } from 'reselect';
import { ItemsById } from 'shared/models/ItemsById';
import { INamedEntity } from 'shared/models/Entity';
import { Selector } from 'react-redux';
import { IStore } from '../../configureStore';

export default <T extends INamedEntity>(selectorById: Selector<IStore, ItemsById<T>>) => (
    createSelector<IStore, ItemsById<T>, T[]>(
        selectorById,
        entitiesById => Object.values(entitiesById).sort(
            (first, second) => first.name?.localeCompare(second.name),
        ),
    )
);
