import { useSelector } from 'react-redux';
import {
    selectAssignments,
    selectControllingOrgsById,
    selectCostCenterById,
    selectDealById,
    selectDealTypeById,
    selectJobNumberById,
    selectSubmittingOrgById,
    selectSubmittingOrgsById,
} from 'store/entities/configuration/configurationSelectors';
import { getRecipientName } from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/selectors';
import { useMemo } from 'react';
import { IAssignment } from 'store/entities/configuration/configurationModel';
import { selectPayPeriods } from 'store/entities/timesheet/selectors';

export interface IJobNumberDetails{
    dealNumber?: string,
    dealTypeName?: string,
    submittingOrgName?: string,
    invoiceRecipientName?: string,
    costCenterName?: string,
}
export const useJobNumberDetails = (jobNumberId: string) => {
    const jobNumber = useSelector(selectJobNumberById(jobNumberId));
    const deal = useSelector(selectDealById(jobNumber?.deal_id || ''));
    const dealType = useSelector(selectDealTypeById(deal?.type_id || ''));
    const costCenter = useSelector(selectCostCenterById(deal?.cost_center_id || ''));
    const submittingOrg = useSelector(selectSubmittingOrgById(deal?.submitting_org_id || ''));
    const controllingOrgsById = useSelector(selectControllingOrgsById);
    const submittingOrgsById = useSelector(selectSubmittingOrgsById);
    const invoiceRecipientName = getRecipientName(deal?.recipient, controllingOrgsById, submittingOrgsById);
    return {
        dealNumber: deal?.deal_number ?? '',
        dealTypeName: dealType?.name ?? '',
        costCenterName: costCenter ? `${costCenter.number} - ${costCenter.description}` : '',
        submittingOrgName: submittingOrg?.client_site_name,
        invoiceRecipientName: invoiceRecipientName,
    } as IJobNumberDetails;
};

export const useUserAssignments = (userId: string) => {
    const assignments = useSelector(selectAssignments);
    return useMemo(() => {
        return assignments.filter((assignment: IAssignment) => {
            return assignment.user_id === userId;
        });
    }, [assignments, userId]);
};

export const useTravelDatePayPeriod = (travelDate: string) => {
    const payPeriods = useSelector(selectPayPeriods);
    return useMemo(() => {
        return payPeriods.find(payPeriod => {
            // @ts-ignore
            return payPeriod.period_start <= travelDate && travelDate <= payPeriod.period_end;
        });
    }, [travelDate, payPeriods]);
};
