import { createSelector } from 'reselect';
import { formatBNumber } from 'shared/utils/formatters/bNumber';
import { isContainSubstring } from 'shared/utils/helpers/isContainSubstring';
import { selectSubmittingOrgs } from 'store/entities/configuration/configurationSelectors';
import { selectSubmittingOrgFilter } from 'modules/settings/submodules/clients/submittingOrg/components/Filter/store/selector';

export const selectFilteredSubmittingOrgs = (
    createSelector(
        selectSubmittingOrgs,
        selectSubmittingOrgFilter,
        (submittingOrgs, submittingOrgFilter) => {
            if (!submittingOrgFilter){
                return submittingOrgs;
            }
            return submittingOrgs.filter(org => {
                return isContainSubstring(org.client_site_name, submittingOrgFilter)
                    || isContainSubstring(formatBNumber(org.submitting_org_number), submittingOrgFilter);
            });
        },
    )
);
