import moment from 'moment';
import React, { useMemo } from 'react';
import { capitalize } from 'lodash-es';
import { PayrollSheetStatuses } from 'modules/payrollProcessorHub/store/model';
import StatusCell from 'shared/components/table/Cells/StatusCell';
import { useStatusStyles } from 'shared/components/table/Cells/StatusStyles';
import { StatusNames } from 'store/entities/timesheet/models/Status';

export interface IPayrollStatusProps {
    status: PayrollSheetStatuses;
    payPeriodEnd?: string | null;
    className?: string;
}

export const PayrollStatus = ({ status, payPeriodEnd, className }: IPayrollStatusProps) => {
    const statusClasses = useStatusStyles();
    const statusToColor: Record<string, string> = useMemo(() => ({
        [PayrollSheetStatuses.APPROVED]: statusClasses.lightGreen,
        [PayrollSheetStatuses.SUBMITTED]: statusClasses.blue,
        [PayrollSheetStatuses.MISSING]: statusClasses.orange,
        [PayrollSheetStatuses.OVERDUE]: statusClasses.red,
        [PayrollSheetStatuses.CLOSED]: statusClasses.brown,
    }), [statusClasses]);

    const displayStatus = useMemo((): string => {
        if (status === PayrollSheetStatuses.MISSING && payPeriodEnd && moment().isBefore(payPeriodEnd)) {
            return StatusNames.WORKING;
        }
        return status;
    }, [payPeriodEnd, status]);

    return (
        <StatusCell
            className={className}
            text={capitalize(displayStatus)}
            statusClassName={statusToColor[status]}
        />
    );
};
