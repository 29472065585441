import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { AuthenticationError } from 'shared/models/Authentication';
import { getAuthApi } from 'shared/utils/authApi';
import { axiosInstance, isNetworkError } from 'shared/utils/axios/axios';
import { shouldIgnoreRequestError } from 'shared/utils/axios/shouldIgnoreRequestError';
import { EmptyTokenError, TokenRefreshedRecently } from 'shared/utils/error/TokenRefreshedRecently';
import { logger } from 'shared/utils/logging/logger';
import { getRequestDetails } from 'shared/utils/logging/requestModel';

export const requestInterceptor = (config: AxiosRequestConfig) => {
    return getAuthApi()
        .getAccessToken()
        .then(validToken => {
            if (validToken) {
                // @ts-ignore
                config.headers['Authorization'] = 'Bearer ' + validToken;
            }
            return config;
        })
        .catch(() => {
            return config;
        });
};

export const requestRejectionInterceptor = (error: AxiosError) => {
    const statusCode = error.response?.status;
    if (statusCode) {
        if (statusCode === 401) {
            // @ts-ignore
            if (error.config.tokenRecentlyRefreshed) {
                const err = new TokenRefreshedRecently();
                logger.info(err, getRequestDetails(error));
                return Promise.reject(err);
            }
            return getAuthApi().refreshTokens()
                .then(() => getAuthApi().getAccessToken())
                .then(validToken => {
                    if (validToken) {
                        // @ts-ignore
                        error.config.headers['Authorization'] = 'Bearer ' + validToken;
                    } else {
                        logger.info(new EmptyTokenError(), getRequestDetails(error));
                    }
                    // @ts-ignore
                    error.config.tokenRecentlyRefreshed = true;
                    return axiosInstance.request(error.config);
                })
                .catch(err => {
                    if (!(err instanceof AuthenticationError)) {
                        logger.info(err);
                    }
                    return getAuthApi().logout(true);
                });
        } else {
            if (statusCode >= 400) {
                const shouldIgnore = shouldIgnoreRequestError(error);
                const requestDetails = getRequestDetails(error);
                if (!shouldIgnore){
                    if (statusCode < 500) {
                        logger.info(error, requestDetails);
                    } else {
                        logger.error(error, requestDetails);
                    }
                }
            }

            return Promise.reject(error);
        }
    }
    if (!(isNetworkError(error) || axios.isCancel(error))) {
        return logger.error(error);
    }
    return Promise.reject(error);
};
