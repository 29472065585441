import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';
import {
    IAssignUserJobNumberRequest,
    ICreateJobNumberRequest,
    IJobNumberFilter,
    IJobNumberPagePayload,
    IUpdateJobNumberRequest,
} from 'modules/settings/submodules/clients/jobNumber/store/models';
import { IJobNumber } from 'shared/models/JobNumber';

const SET_EDIT_JOB_NUMBER_ID = 'SET_EDIT_JOB_NUMBER_ID';
export const setEditJobNumberId = createSingleAction<string | null, typeof SET_EDIT_JOB_NUMBER_ID>(
    SET_EDIT_JOB_NUMBER_ID,
);

export const INITIAL_LOAD_JOB_NUMBERS = 'settings/job_numbers/INITIAL_LOAD_JOB_NUMBERS';
export const initialLoadJobNumbers = createSingleAction<void, typeof INITIAL_LOAD_JOB_NUMBERS>(
    INITIAL_LOAD_JOB_NUMBERS,
);

export const getMoreJobNumbersAction = createRequestActions<void, IJobNumberPagePayload>(
    RequestType.Get,
    'MORE_JOB_NUMBERS',
    'settings/job_numbers/',
);

export const SET_JOB_NUMBER_FILTER = 'settings/job_numbers/SET_JOB_NUMBER_FILTER';
export const setJobNumberFilter = createSingleAction<IJobNumberFilter, typeof SET_JOB_NUMBER_FILTER>(
    SET_JOB_NUMBER_FILTER,
);

export const createJobNumberAction = createRequestActions<ICreateJobNumberRequest, IJobNumber>(
    RequestType.Create,
    'JOB_NUMBER',
    'settings',
);

export const assignUserToJobNumber = createRequestActions<IAssignUserJobNumberRequest, void>(
    RequestType.Update,
    'JOB_NUMBER_USER_ASSIGN',
    'settings',
);

export const updateJobNumber = createRequestActions<IUpdateJobNumberRequest, IJobNumber>(
    RequestType.Update,
    'JOB_NUMBER',
    'settings',
);

export const SET_CREATE_JOB_NUMBER_MODAL_STATE = 'settings/job_numbers/SET_CREATE_JOB_NUMBER_MODAL_STATE';
export const setCreateJobNumberModalState = createSingleAction<boolean, typeof SET_CREATE_JOB_NUMBER_MODAL_STATE>(
    SET_CREATE_JOB_NUMBER_MODAL_STATE,
);

export type JobNumbersSettingsActions =
    | ActionsReturnTypes<typeof getMoreJobNumbersAction>
    | ActionsReturnTypes<typeof createJobNumberAction>
    | ReturnType<typeof initialLoadJobNumbers>
    | ReturnType<typeof setJobNumberFilter>
    | ReturnType<typeof setEditJobNumberId>
    | ReturnType<typeof setCreateJobNumberModalState>
