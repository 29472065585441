import React from 'react';
import SheetsSubmittedActions
    from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedActions';
import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';

export default function SheetsSubmittedPage() {
    return (
        <SheetsSubmittedView
            availableActions={SheetsSubmittedActions}
        />
    );
}
