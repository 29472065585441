import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import {
    defaultActivityValues,
    IActivityFormValues,
} from 'modules/settings/submodules/clients/activities/components/CreateActivity/model';
import DataTypeSelect from 'modules/settings/submodules/clients/activities/components/selects/DataTypeSelect';
import EntryTypeSelect from 'modules/settings/submodules/clients/activities/components/selects/EntryTypeSelect';
import PayCodeSelect from 'modules/settings/common/components/PayCodeSelect';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { TaskSelect } from 'modules/settings/submodules/clients/activities/components/CreateActivity/TaskSelect';
import { ActivityTag } from 'store/entities/configuration/configurationModel';
import ActivityTagSelect from 'modules/settings/submodules/clients/activities/components/CreateActivity/ActivityTagSelect';

export interface IActivityFormProps extends FormikProps<IActivityFormValues> {
    isLoading?: boolean;
    activityLength: number;
    showNonProductionTag?: boolean;
}

export const ActivityForm = ({
    handleSubmit,
    isLoading = false,
    values,
    setFieldValue,
    activityLength,
    showNonProductionTag = false,
}: IActivityFormProps) => {
    const classes = useInlineFormStyles();
    const { type, dataType } = values;

    useEffect(() => {
        setFieldValue('dataType', null);
        setFieldValue('payCode', null);
        setFieldValue('nonProduction', defaultActivityValues.nonProduction);
    }, [setFieldValue, type]);
    useEffect(() => {
        setFieldValue('payCode', null);
    }, [setFieldValue, dataType]);

    const payCodeDisabled = dataType === QuantityType.TIME_BREAK;

    return (
        <form
            onSubmit={handleSubmit}
            className={classes.form}
        >
            <EntryTypeSelect
                name="type"
                label="Type"
                className={classes.shortInput}
            />
            <DataTypeSelect
                name="dataType"
                label="Data type"
                className={classes.input}
                disabled={!type}
                entryType={type || EntryType.TIME}
            />
            <PayCodeSelect
                name="payCode"
                label="Pay code"
                className={classes.input}
                disabled={!type || payCodeDisabled}
                entryType={type || EntryType.TIME}
            />
            <TaskSelect
                name="task"
                label="Task"
                className={classes.input}
            />
            <ActivityTagSelect
                name="nonProduction"
                className={classes.shortInput}
                label="Production"
                tagLabel="Non production"
                tag={ActivityTag.NonProduction}
                show={type === EntryType.TIME && showNonProductionTag}
            />
            <ActivityTagSelect
                name="default"
                className={classes.shortInput}
                label="Non default"
                tagLabel="Default"
                tag={ActivityTag.Default}
            />
            <ActivityTagSelect
                name="travel"
                className={classes.shortInput}
                label="Non travel"
                tagLabel="Travel"
                tag={ActivityTag.Travel}
            />
            <FormField
                name="activity"
                label="Activity"
                className={classes.input}
            />
            <FormField
                name="shortDescription"
                label={`Short name (max ${ activityLength } characters)`}
                className={classes.input}
            />
            <ButtonWithLoader
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                isLoading={isLoading}
                data-testid="submit_button"
            >
                Create activity
            </ButtonWithLoader>
        </form>
    );
};
