import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { StatusSelect } from 'modules/settings/common/components/StatusSelect/StatusSelect';
import { useFilterStyles } from 'modules/settings/submodules/components/sharedStyles/filterStyles';
import FilterSVG from 'shared/components/icons/FilterSVG';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { useModal } from 'shared/utils/hooks/useModal';
import { setEmployeesFilter } from 'modules/users/employees/store/actions';
import { IEmployeeFilter } from 'modules/users/employees/store/models';
import { selectEmployeeFilter } from 'modules/users/employees/store/selectors';
import PopOver from 'shared/components/popover/PopOver';
import useActionButtonStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import {
    FiltersTestIds,
} from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import { selectCurrentClient } from 'store/entities/clients/selectors/clientsSelectors';

interface IEmployeeFilterProps {
    useClientContext?: boolean;
}

export default function EmployeeFilter({ useClientContext = false }: IEmployeeFilterProps) {
    const buttonClasses = useActionButtonStyles();
    const classes = useFilterStyles();
    const dispatch = useDispatch();
    const client = useSelector(selectCurrentClient);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { isModalOpened: isOpened, onModalOpen, onModalClose: onClose } = useModal();
    const filter = useSelector(selectEmployeeFilter);

    useEffect(() => {
        if (useClientContext && !filter.client) {
            dispatch(setEmployeesFilter({
                ...filter,
                client,
            }));
        }
    }, [dispatch, client, useClientContext, filter]);

    const onFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onModalOpen();
    };

    const onApplyFilter = (
        values: IEmployeeFilter,
    ) => {
        dispatch(setEmployeesFilter(values));
        onClose();
    };

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            ml={2}
            mt={0.5}
        >
            <Button
                onClick={onFilterClick}
                classes={{ root: buttonClasses.default }}
                data-testid={FiltersTestIds.Button}
            >
                <FilterSVG/>
            </Button>

            <PopOver
                isOpened={isOpened}
                anchorEl={anchorEl}
                onClose={onClose}
                data-testid={FiltersTestIds.Wrapper}
            >
                <Formik
                    initialValues={filter}
                    onSubmit={onApplyFilter}
                >
                    {(props: FormikProps<IEmployeeFilter>) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className={classes.form}
                        >
                            {!useClientContext && (
                                <ClientSelect
                                    name="client"
                                    label="Client"
                                    className={classes.input}
                                />
                            )}
                            <StatusSelect
                                name="status"
                                label="Status"
                                className={classes.input}
                            />
                            <Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    data-testid="submit_button"
                                >
                                    Apply
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </PopOver>
        </Box>
    );
}
