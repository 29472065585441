import { useField } from 'formik';
import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import DayPicker from 'shared/components/sidebars/EntryEdit/DayPicker';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

interface IDayPickerFieldProps extends IFormFieldProps {
    payPeriod: IPayPeriod;
    subassignments?: ISubassignment[];
}

export default function SidebarEntryDayPickerField({
    name,
    payPeriod,
    subassignments,
}: IDayPickerFieldProps) {
    const [field,, helper] = useField(name);

    return (
        <DayPicker
            payPeriod={payPeriod}
            onChange={helper.setValue}
            value={field.value}
            subassignments={subassignments}
        />
    );
}
