import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { makeStyles } from '@material-ui/core/styles';

export const useBigSelectStyles = makeHighPriorityStyles(() => ({
    select: {
        minHeight: '41px',
    },
}));

export const useDealStatusFormControlStyles = makeStyles(() => ({
    root: {
        marginRight: '12px',
    },
}));
