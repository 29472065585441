import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useOfferLetterPayRangesStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1, 3),
        fontSize: theme.spacing(1.75) + 'px',
        fontStyle: 'italic',
        color: colors.red,
    },
}));
