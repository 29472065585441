import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { employeeManagementFilterDefaultValue, IEmployeeManagementFilter } from 'modules/employmentManagement/components/store/model';
import { EmployeeFilterForm } from 'modules/employmentManagement/components/EmployeeFilterForm/EmployeeFilterForm';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { ICustomFieldHierarchyNode } from 'store/entities/customFields/model';
import { getSpecialUsersAction } from 'store/entities/users/actions';
import { formValidationSchema } from 'modules/employmentManagement/components/EmployeeFilterForm/validationSchema';

export interface IEmployeeFilterProps {
    isLoading: boolean;
    onApplyFilter: (values: IEmployeeManagementFilter) => void;
    hierarchy: ICustomFieldHierarchyNode[];
    useAdvancedSearch: boolean;
    initialValues: IEmployeeManagementFilter;
}

export const EmployeeFilter = ({ onApplyFilter, initialValues, ...formProps }: IEmployeeFilterProps) => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);

    const onReset = useCallback(() => {
        onApplyFilter(employeeManagementFilterDefaultValue);
    }, [onApplyFilter]);
    useEffect(() => {
        dispatch(getSpecialUsersAction.init({
            purpose: Permission.ApproveSheets,
            client_id: clientId || undefined,
        }));
    }, [dispatch, clientId]);

    return (
        <Box>
            <Typography variant="h4">Search Employees</Typography>
            <Formik
                initialValues={initialValues}
                onSubmit={onApplyFilter}
                validationSchema={formValidationSchema}
                enableReinitialize
            >
                {props => (
                    <EmployeeFilterForm
                        {...props}
                        {...formProps}
                        onReset={onReset}
                    />
                )}
            </Formik>
        </Box>
    );
};
