import { compact, uniq, uniqBy } from 'lodash-es';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { getDepartments } from 'modules/employmentInfo/store/department/actions';
import { managePayRangeApi } from 'modules/settings/submodules/clients/payRanges/store/api';
import { subassignmentsApi } from 'modules/subassignmentManagement/store/api';
import { IAssignmentWithSubassignments } from 'modules/subassignmentManagement/store/models';
import { IDeal } from 'shared/models/Deal';
import { IJobNumber, IJobNumberBackend } from 'shared/models/JobNumber';
import { convertFromBackendToLocation, ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { IUserInfo } from 'shared/models/User';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { getPaySettings, setTenantSuccess } from 'store/entities/clients/clientsAction';
import {
    getActivities,
    getAreas,
    getAssignments,
    getBackgroundCheckTemplates,
    getControllingOrgs,
    getCostCenters,
    getDeals,
    getDealSegments,
    getDealTypeArBuckets,
    getDealTypePayCodeArBuckets,
    getDealTypes,
    getJobNumbers,
    getLocations,
    getPayRanges,
    getPhysicalDemands,
    getPositions,
    getProjects,
    getProjectsAssignments,
    getSubassignments,
    getSubmittingOrgGenworthBranches,
    getSubmittingOrgLocations,
    getSubmittingOrgs,
    getTasks,
    getUserTypesAction,
    getWorkingConditions,
    loadClientAssignmentsWithLinked,
    searchSubassignments,
} from 'store/entities/configuration/configurationAction';
import { configurationApi } from 'store/entities/configuration/configurationApi';
import {
    IArea,
    IAssignment,
    IDealRequest,
    IManagerInfoWithUser,
    IProject,
    IProjectWithAssignmentBackend,
    ISubassignment,
} from 'store/entities/configuration/configurationModel';
import {
    getClientCustomFieldConfiguration,
    getCustomFieldValues,
} from 'store/entities/customFields/actions';
import { loadExpenseSheets, loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { getSheetsPayPeriod } from 'store/entities/timesheet/actions/sheets';
import { loadTimeSheets, loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { getUsers } from 'store/entities/users/actions';
import { selectUsersById } from 'store/entities/users/selectors';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { optionalLoadEntitiesByIdsSaga } from 'store/utils/sagas/optionalLoadEntitiesByIdsSaga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    all, call, put, take, takeEvery, takeLatest,
} from 'typed-redux-saga';

const getProjectsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getProjects,
    configurationApi.getProjects,
    'projects',
);

function* normalizeProjectsAssignmentsSaga(projectAssignments: IProjectWithAssignmentBackend[]) {
    const assignments: IAssignment[] = [];
    const areas: IArea[] = [];
    const projects: IProject[] = [];

    projectAssignments.forEach(projectAssignment => {
        const { project, area, assignment } = projectAssignment;
        if (assignment) {
            assignments.push(assignment);
        }
        if (area) {
            areas.push(area);
        }
        if (project) {
            projects.push(project);
        }
    });

    yield put(getAssignments.success(assignments));
    yield put(getAreas.success(areas));
    yield put(getProjects.success(projects));
    yield put(getProjectsAssignments.success(projectAssignments));
}

function* getProjectsAssignmentsSaga({ payload }: ReturnType<typeof getProjectsAssignments.init>) {
    const projectAssignments = yield* call(configurationApi.getProjectsWithAssignments, payload || {});
    yield* normalizeProjectsAssignmentsSaga(projectAssignments);
}

export function* getProjectsAssignmentsWatcher() {
    yield takeEvery(
        getProjectsAssignments.initType,
        withBackendErrorHandler(
            getProjectsAssignmentsSaga,
            getProjectsAssignments.error,
            `Unable to fetch project assignments`,
        ),
    );
}

const getAreasWatcher = getLoadEntitiesByRequestSagaWatcher(
    getAreas,
    configurationApi.getAreas,
    'areas',
);

const getActivitiesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getActivities,
    configurationApi.getActivities,
    'activities',
);

const getTasksWatcher = getLoadEntitiesByRequestSagaWatcher(
    getTasks,
    configurationApi.getTasks,
    'tasks',
);

const getPositionsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getPositions,
    configurationApi.getPositions,
    'positions',
);

const getAssignmentsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getAssignments,
    configurationApi.getAssignments,
    'assignments',
);

const getControllingOrgsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getControllingOrgs,
    configurationApi.getControllingOrgs,
    'controlling orgs',
);

const getCostCentersWatcher = getLoadEntitiesByRequestSagaWatcher(
    getCostCenters,
    configurationApi.getCostCenters,
    'cost centers',
);

const getSubmittingOrgsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getSubmittingOrgs,
    configurationApi.getSubmittingOrgs,
    'submitting orgs',
);

const getSubmittingOrgLocationsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getSubmittingOrgLocations,
    configurationApi.getSubmittingOrgLocations,
    'submitting org locations',
);

const getSubmittingOrgGenworthBranchesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getSubmittingOrgGenworthBranches,
    configurationApi.getSubmittingOrgGenworthBranches,
    'submitting org genworth branches',
);

const getLocationsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getLocations,
    configurationApi.getLocations,
    'locations',
);

const getWorkingConditionsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getWorkingConditions,
    configurationApi.getWorkingConditions,
    'working conditions',
);

const getPhysicalDemandsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getPhysicalDemands,
    configurationApi.getPhysicalDemands,
    'physical demands',
);

const getBackgroundCheckTemplatesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getBackgroundCheckTemplates,
    configurationApi.getBackgroundCheckTemplates,
    'background check templates',
);

export function* normalizeJobNumbersSaga(jobNumbersBackend: IJobNumberBackend[]) {
    const deals: IDeal[] = [];
    const jobNumbers: IJobNumber[] = [];
    jobNumbersBackend.forEach(jobNumberFull => {
        const { deal, ...jobNumber } = jobNumberFull;
        deals.push(deal);
        jobNumbers.push(jobNumber);
    });
    yield put(getJobNumbers.success(jobNumbers));
    yield put(getDeals.success(deals));
}

function* getJobNumbersSaga({ payload }: ReturnType<typeof getJobNumbers.init>) {
    const jobNumbers = yield* call(configurationApi.getJobNumbers, payload || {});
    yield* normalizeJobNumbersSaga(jobNumbers);
}

export function* getJobNumbersWatcher() {
    yield takeEvery(
        getJobNumbers.initType,
        withBackendErrorHandler(
            getJobNumbersSaga,
            getJobNumbers.error,
            `Unable to fetch job numbers`,
        ),
    );
}

const getJobNumberUserTypesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getUserTypesAction,
    configurationApi.getUserTypes,
    'user types',
);

const getDealsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getDeals,
    async (request: IDealRequest): Promise<IDeal[]> => {
        const data = await configurationApi.getDeals(request);
        return data.deals;
    },
    'deals',
);

const getDealTypesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getDealTypes,
    configurationApi.getDealTypes,
    'deal types',
);

const getDealSegmentsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getDealSegments,
    configurationApi.getDealSegments,
    'deal segments',
);

const getDealTypeArBucketsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getDealTypeArBuckets,
    configurationApi.getDealTypeArBuckets,
    'deal type ar_buckets',
);

const getDealTypePayCodeArBucketsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getDealTypePayCodeArBuckets,
    configurationApi.getDealTypePayCodeArBuckets,
    'deal type, pay code and ar_buckets association',
);

function* loadClientAssignmentsWithLinkedSaga({ payload }: ReturnType<typeof loadClientAssignmentsWithLinked.init>) {
    const assignmentsWithLinked = yield* call(configurationApi.getAssignmentsWithLinked, payload);
    const assignments: IAssignment[] = [];
    const locations: ILocation[] = [];
    const positions: IPosition[] = [];
    const users: IUserInfo[] = [];
    const departments: IDepartment[] = [];
    const areas: IArea[] = [];
    const customFieldValueIds: string[] = [];
    assignmentsWithLinked.forEach(assignmentWithLinked => {
        const {
            location,
            position,
            user,
            department,
            area,
            managers,
            ...assignment
        } = assignmentWithLinked;
        assignments.push({
            location_id: location?.id,
            position_id: position?.id,
            user_id: user?.id,
            department_id: department?.id,
            managers: managers.map(manager => ({
                user_id: manager.user_id,
                manager_level: manager.manager_level,
            })),
            ...assignment,
        });
        locations.push(convertFromBackendToLocation(location));
        positions.push(position);
        users.push(user);
        users.push(...(managers.map((item: IManagerInfoWithUser) => item.user)));
        departments.push(department);
        areas.push(area);
        customFieldValueIds.push(...assignment.custom_field_value_ids);
    });
    yield put(getAssignments.success(assignments));
    yield put(getLocations.success(compact(locations)));
    yield put(getPositions.success(compact(positions)));
    yield put(getUsers.success(compact(users)));
    yield put(getDepartments.success(compact(departments)));
    yield put(getAreas.success(compact(areas)));
    yield put(getCustomFieldValues.init({ custom_field_value_ids: customFieldValueIds.filter(Boolean) }));

    if (payload.purpose) {
        yield put(getProjectsAssignments.init({ purpose: payload.purpose }));
    }
    yield put(loadClientAssignmentsWithLinked.success());
}

function* loadClientAssignmentsWithLinkedWatcher() {
    yield takeEvery(
        loadClientAssignmentsWithLinked.initType,
        withBackendErrorHandler(
            loadClientAssignmentsWithLinkedSaga,
            loadClientAssignmentsWithLinked.error,
            'Unable to load client assignments',
        ),
    );
}

function getSubassignmentsCustomFieldValueIds(subassignments: ISubassignment[]): string[] {
    return [... new Set(
        subassignments.reduce((mem: string[], sub: ISubassignment) => {
            return [...mem, ...sub.custom_field_value_ids];
        }, []),
    )].filter(Boolean);
}

interface IClientIdFieldIdPair {
    clientId: string;
    fieldId: string;
}

function getSubassignmentsClientAndAllCustomFieldIdsPairs(
    subassignments: ISubassignment[],
): IClientIdFieldIdPair[] {
    const clientIdFieldIdPairs = subassignments.map(subassignment => {
        const clientId = subassignment.client_id;
        const customFieldIds = subassignment.all_values_custom_field_ids || [];
        return customFieldIds.map(fieldId => ({
            clientId,
            fieldId,
        }));
    }).flat();
    return uniqBy(clientIdFieldIdPairs, pair => [pair.clientId, pair.fieldId].join());
}

function* getSubassignmentsSaga({ payload }: ReturnType<typeof getSubassignments.init>) {
    const subassignments = yield* call(configurationApi.getSubassignment, payload || {});
    yield put(getSubassignments.success(subassignments));
}

function* getSubassignmentsWatcher() {
    yield takeLatest(
        getSubassignments.initType,
        withBackendErrorHandler(
            getSubassignmentsSaga,
            getSubassignments.error,
            'Unable to load sub-assignments',
        ),
    );
}

function* getSubassignmentsCustomFieldSaga({ payload: subassignments }: ReturnType<typeof getSubassignments.success>) {
    const customFieldValueIds = getSubassignmentsCustomFieldValueIds(subassignments);
    yield put(getCustomFieldValues.init({ custom_field_value_ids: customFieldValueIds }));
    const clientIdFieldIdPairs = getSubassignmentsClientAndAllCustomFieldIdsPairs(subassignments);
    yield all(clientIdFieldIdPairs.map(({ clientId, fieldId }: IClientIdFieldIdPair) => {
        return put(getCustomFieldValues.init({
            client_id: clientId,
            custom_field_id: fieldId,
        }));
    }));
}

function* getSubassignmentsCustomFieldsWatcher() {
    yield takeLatest(
        getSubassignments.successType,
        withBackendErrorHandler(
            getSubassignmentsCustomFieldSaga,
            getSubassignments.error,
            'Unable to load sub-assignments custom fields',
        ),
    );
}

function* getSubassignmentsApproversSaga({ payload: subassignments }: ReturnType<typeof getSubassignments.success>) {
    const approverIds = uniq(
        subassignments.map(
            subassignment => subassignment.managers?.map(manager => manager.user_id),
        ).flat(),
    );
    yield call(
        optionalLoadEntitiesByIdsSaga,
        approverIds,
        selectUsersById,
        getUsers,
        userIds => ({ ids: userIds.join(',') }),
    );
}

function* getSubassignmentsApproversWatcher() {
    yield takeLatest(
        getSubassignments.successType,
        withBackendErrorHandler(
            getSubassignmentsApproversSaga,
            getSubassignments.error,
            'Unable to load sub-assignments approvers',
        ),
    );
}

function* searchSubassignmentsSaga({ payload }: ReturnType<typeof searchSubassignments.init>) {
    const groupedSubassignments = yield* call(subassignmentsApi.getGroupedSubassignments, payload);
    const subassignments = groupedSubassignments.items.reduce(
        (mem: ISubassignment[], assignment: IAssignmentWithSubassignments) => {
            return [...mem, ...assignment.subassignments];
        },
        [],
    );
    const customFieldValueIds = getSubassignmentsCustomFieldValueIds(subassignments);
    yield put(getCustomFieldValues.init({ custom_field_value_ids: customFieldValueIds.filter(Boolean) }));
    yield put(getSubassignments.success(subassignments));
    yield put(searchSubassignments.success(subassignments));
}

function* searchSubassignmentsWatcher() {
    yield takeLatest(
        searchSubassignments.initType,
        withBackendErrorHandler(
            searchSubassignmentsSaga,
            searchSubassignments.error,
            'Unable to load sub-assignments',
        ),
    );
}

function* loadSheetsRelatedEntitiesSaga({
    payload: sheets,
}: ReturnType<typeof loadTimeSheets.success> | ReturnType<typeof loadExpenseSheets.success>) {
    const clientId = sheets?.[0]?.client_id;
    const jobNumberIds: string[] = [...sheets.reduce((idsSet, sheet) => {
        if (sheet.job_number_id) {
            idsSet.add(sheet.job_number_id);
        }
        return idsSet;
    }, new Set<string>())];
    const approvals = sheets.map(sheet => sheet.approvals).flat().filter(Boolean);
    if (approvals.length) {
        const approverIds = uniq(approvals.map(approve => approve.user_id));
        yield optionalLoadEntitiesByIdsSaga(
            approverIds,
            selectUsersById,
            getUsers,
            userIds => ({ ids: userIds.join(',') }),
        );
    }
    if (isNotEmpty(jobNumberIds) && clientId) {
        yield put(getSubmittingOrgs.init({ client_id: clientId }));
        yield put(getJobNumbers.init({
            ids: jobNumberIds,
            client_id: clientId,
        }));
        const getJobNumbersAction = yield* take(
            [getJobNumbers.successType, getJobNumbers.errorType],
        );

        if (getJobNumbersAction.type !== getJobNumbers.successType) {
            return;
        }
        const jobNumbers = (getJobNumbersAction as ReturnType<typeof getJobNumbers.success>).payload;
        const userIds = [...jobNumbers.reduce((userIdsSet, {
            manager_id,
            user_id,
        }) => {
            if (manager_id) {
                userIdsSet.add(manager_id);
            }
            if (user_id) {
                userIdsSet.add(user_id);
            }
            return userIdsSet;
        }, new Set<string>())];
        if (userIds.length) {
            yield put(getUsers.init({ ids: userIds.join(',') }));
        }
    }
}

function* loadSheetsRelatedEntitiesSagaWatcher() {
    yield takeEvery(
        [
            loadTimeSheets.successType,
            loadTimeSheetsWithEntries.successType,
            loadExpenseSheets.successType,
            loadExpenseSheetsWithEntries.successType,
        ],
        loadSheetsRelatedEntitiesSaga,
    );
}

function* getConfiguration() {
    while (true) {
        const action = yield* take(setTenantSuccess.action);
        const clientId = (action as ReturnType<typeof setTenantSuccess>).payload;

        if (clientId) {
            yield all([
                put(getProjects.init()),
                put(getTasks.init()),
                put(getSheetsPayPeriod.init()),
                put(getClientCustomFieldConfiguration()),
                put(getPaySettings.init(clientId)),
            ]);
        }
    }
}

function* getPayRangesSaga(action: ReturnType<typeof getPayRanges.init>) {
    const requestData = action.payload;
    const result = yield* call(managePayRangeApi.getPayRanges, requestData);
    yield put(getPayRanges.success(result.pay_ranges));
}

function* getPayRangesSagaWatcher() {
    yield takeEvery(
        getPayRanges.initType,
        withBackendErrorHandler(
            getPayRangesSaga,
            getPayRanges.error,
            'Unable to get pay ranges',
            false,
        ),
    );
}

export default [
    getProjectsWatcher,
    getProjectsAssignmentsWatcher,
    getAreasWatcher,
    getActivitiesWatcher,
    getTasksWatcher,
    getPositionsWatcher,
    getLocationsWatcher,
    getControllingOrgsWatcher,
    getSubmittingOrgsWatcher,
    getSubmittingOrgLocationsWatcher,
    getSubmittingOrgGenworthBranchesWatcher,
    getConfiguration,
    getAssignmentsWatcher,
    getSubassignmentsWatcher,
    searchSubassignmentsWatcher,
    getWorkingConditionsWatcher,
    getPhysicalDemandsWatcher,
    getBackgroundCheckTemplatesWatcher,
    loadClientAssignmentsWithLinkedWatcher,
    getJobNumbersWatcher,
    getJobNumberUserTypesWatcher,
    getCostCentersWatcher,
    getDealsWatcher,
    getDealTypesWatcher,
    getDealSegmentsWatcher,
    loadSheetsRelatedEntitiesSagaWatcher,
    getDealTypeArBucketsWatcher,
    getDealTypePayCodeArBucketsWatcher,
    getPayRangesSagaWatcher,
    getSubassignmentsCustomFieldsWatcher,
    getSubassignmentsApproversWatcher,
];
