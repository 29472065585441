import baseTheme from '../../../baseTheme';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const getClientTheme = (color: string = colors.primary) => {
    return ((theme: Theme) => {
        return createMuiTheme({
            ...theme,
            palette: {
                ...theme.palette,
                primary: {
                    main: color,
                },
            },
        });
    })(baseTheme);
};
