import EditAndResubmitButton from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/EditAndResubmitButton';
import React from 'react';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import { useDisallowPastDayEntry } from '../../../../../store/entities/clients/hooks';

export default function SheetsRejectedActions({ typedSheets, variant }: IAvailableActionsProps) {
    const selectedSheets = Object.keys(typedSheets);
    const disallowPastDayEntry = useDisallowPastDayEntry();
    const sheetId = selectedSheets[0];
    const entryType = sheetId ? typedSheets[sheetId] : undefined;

    return (
        <>
            {!disallowPastDayEntry && (
                <EditAndResubmitButton
                    sheetIds={[sheetId]}
                    entryType={entryType}
                    variant={variant}
                />
            )}
        </>
    );
}
