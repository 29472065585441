import React from 'react';
import { FormikProps } from 'formik';
import FormField from 'shared/components/formFields/FormField';
import { defaultUserAvatarValues, IAvatarUpload, IUserProfileFormValues } from 'modules/profile/store/profileModel';
import { useStyles } from 'modules/profile/styles';
import { Typography, Hidden, Button } from '@material-ui/core';
import UserAvatarField from './UserAvatarField';
import clsx from 'clsx';

export interface IUserProfileFormProps extends FormikProps<IUserProfileFormValues> {
    setNewAvatar(newAvatar: IAvatarUpload): void;
    previewUrl: string;
}

export function UserProfileForm({
    handleSubmit,
    setNewAvatar,
    previewUrl,
    ...props
}: IUserProfileFormProps) {
    const classes = useStyles();
    const { first_name, last_name, id, avatarUrl } = props.values;
    const avatarInitials = `${first_name.substring(0, 1)} ${last_name.substring(0, 1)}`;

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <section className={classes.componentBlock}>
                <Hidden smUp>
                    <UserAvatarField initials={avatarInitials} userId={id}
                        avatarUrl={avatarUrl} setNewAvatar={setNewAvatar}
                        previewUrl={previewUrl}/>
                </Hidden>
                <FormField
                    name="first_name"
                    label="First Name"
                    className={classes.input}
                    disabled
                />
                <FormField
                    name="last_name"
                    label="Last Name"
                    className={classes.input}
                    disabled
                />
                <FormField
                    name="phone_number"
                    label="Phone Number"
                    className={classes.input}
                    disabled
                />                    
                <FormField
                    name="address"
                    label="Address"
                    className={classes.input}
                    disabled
                />    
                <FormField
                    name="city"
                    label="City"
                    className={classes.input}
                    disabled
                /> 
                <FormField
                    name="county"
                    label="County"
                    className={classes.input}
                    disabled
                />        
                <Hidden xsDown>
                    <Typography variant="body2" className={classes.contactInfo}>
                    If you need to change any of this information, please contact..
                    </Typography>
                </Hidden>          
            </section>
            <section className={classes.componentBlock}>
                <Hidden xsDown>
                    <UserAvatarField initials={avatarInitials} userId={id}
                        avatarUrl={avatarUrl} setNewAvatar={setNewAvatar}
                        previewUrl={previewUrl}/>
                </Hidden>
                <FormField
                    name="state"
                    label="State/Region"
                    className={classes.input}
                    disabled
                />                                        
                <FormField
                    name="zip_code"
                    label="Zipcode"
                    className={classes.input}
                    disabled
                />             
                <Hidden smUp>
                    <Typography variant="body2" className={classes.contactInfo}>
                    If you need to change any of this information, please contact..
                    </Typography>
                </Hidden>  
                <section
                    className={classes.footer}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={clsx(classes.button, classes.green)}
                    >
                    Update
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => setNewAvatar(defaultUserAvatarValues)}
                    >
                    Cancel
                    </Button>  
                </section>                      
            </section>
        </form>
    );
}
