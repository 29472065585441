import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IAttachment } from 'shared/models/Attachments';
import { loadExpenseEntryAttachments } from 'store/entities/timesheet/actions/entryAttachments';

export const useLoadSecureSheetAttachments = (attachments: IAttachment[]) => {
    const dispatch = useDispatch();
    const attachmentIds = useMemo(() => {
        return attachments.map(attachment => attachment.id);
    }, [attachments]);
    useEffect(() => {
        dispatch(loadExpenseEntryAttachments(attachmentIds));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachmentIds.join('.'), dispatch]);
};
