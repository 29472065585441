import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useSidebarStyles = makeHighPriorityStyles((theme: Theme) => ({
    sidebarTitle: {
        borderBottom: `1px solid ${colors.lightGray}`,
        backgroundColor: `${colors.white} !important`,
        color: `${colors.black} !important`,
        '& h5': {
            fontSize: 20,
            textTransform: 'uppercase',
            fontWeight: theme.typography.fontWeightBold,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
}));
