import React from 'react';
import { Nullable } from '../../../../types/types';
import { selectPaySettingsFormatted } from 'modules/settings/submodules/clients/paySettings/store/selectors';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PayRateType, PayType } from 'shared/models/PayType';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export interface IShowPayRateType {
    type?: PayRateType;
    payType?: PayType;
}

export const useStyles = makeHighPriorityStyles(() => ({
    type: {
        textTransform: 'lowercase',
    },
}));

const ShowPayRateType = ({ type, payType }: IShowPayRateType) => {
    const classes = useStyles();
    const paySettings = useSelector(selectPaySettingsFormatted);

    let value: Nullable<string> = '';
    if (type && payType) {
        value = payType === PayType.Salaried ? paySettings?.prism_group_description : type;
    }

    return (
        <Typography variant="body2" className={classes.type}>{value}</Typography>
    );
};

export default ShowPayRateType;
