import React from 'react';
import { useClientAssignmentSetupSubModules } from 'modules/settings/submodules/clients/clientAssignmentSetup/useClientAssignmentSetupSubModules';
import { SubmodulesDashboard } from 'modules/settings/common/components/SubmodulesDashboard';

export const ClientAssignmentSetupDashboard = () => {
    const items = useClientAssignmentSetupSubModules();
    return (
        <SubmodulesDashboard items={items}/>
    );
};
