import { Box, Button } from '@material-ui/core';
import EmployeeTable from 'modules/offerLetter/components/lookup/EmployeeTable';
import React from 'react';
import { IUserInfo } from 'shared/models/User';
import { Nullable } from '../../../../types/types';

export type LookupResultClasses = Partial<Record<'inviteButtonContainer' | 'tableContainer' | 'inviteButton', string>>;

export interface IEmployeeResultProps {
    employees: Nullable<IUserInfo[]>;
    onInvite?: () => void;
    classes: LookupResultClasses;
}

const LookupResult = ({ employees, onInvite, classes }: IEmployeeResultProps) => {
    if (!employees) {
        return null;
    }
    const buttonText = employees.length ? 'Or invite new employee' : 'Invite employee';
    return (
        <>
            {employees.length > 0 ? (
                <Box className={classes.tableContainer}>
                    <EmployeeTable employees={employees}/>
                </Box>
            ) : (
                <Box
                    mt={5}
                    display="flex"
                    justifyContent="center"
                >
                    No record of this employee.
                </Box>
            )}
            <Box className={classes.inviteButtonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.inviteButton}
                    onClick={onInvite}
                >
                    {buttonText}
                </Button>
            </Box>
        </>
    );
};

export default React.memo(LookupResult);
