import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import ActionButton from 'shared/components/buttons/ActionButton';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useEditSheetButtonStyles = makeHighPriorityStyles({
    editButton: {
        textDecoration: 'none',
        color: 'inherit',
    },
});

export interface IEditButtonProps {
    sheetGroup: IGroupedSheetCalculation;
}

export function EditButton({ sheetGroup }: IEditButtonProps) {
    const classes = useEditSheetButtonStyles();
    const userHasPermission = useSelector(selectIsUserHasPermission(Permission.CanEditPphSheet));

    if (!userHasPermission) {
        return null;
    }
    return (
        <Link
            to={generatePath(routes.PAYROLL_PROCESSOR_HUB.EDIT_SHEET, {
                timeSheetId: sheetGroup.time_sheet_id || 'null',
                expenseSheetId: sheetGroup.expense_sheet_id || 'null',
            })}
            className={classes.editButton}
        >
            <ActionButton
                variant="outlined"
                color="primary"
                customType="danger"
            >
                Edit
            </ActionButton>
        </Link>
    );
}
