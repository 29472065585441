import { combineReducers } from 'redux';
import { entitiesAutocompleteSearch } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/store/reducers';
import { sheetDetails } from 'store/components/sheetDetails/sheetDetailsReducer';
import { sheetsGrid } from 'store/components/workingSheets/workingSheetsReducer';
import { expensesApproval } from 'store/components/expensesApproval/expensesApprovalReducer';
import { timeApproval } from 'store/components/timeApproval/timeApprovalReducer';
import { addEntry } from 'store/components/addEntry/addEntryReducer';
import { registration } from 'store/components/registration/reducers';
import { employeeSubmittedSheets } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsReducer';
import { managerSubmittedSheets } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsReducer';
import { resettingPassword } from 'store/components/resetPassword/reducers';

export const components = combineReducers({
    sheetDetails,
    sheetsGrid,
    expensesApproval,
    timeApproval,
    addEntry,
    registration,
    employeeSubmittedSheets,
    managerSubmittedSheets,
    resettingPassword,
    entitiesAutocompleteSearch,
});
