import { Nullable } from '../../../types/types';

export function isEntireElementWithinViewport(domElement: Nullable<HTMLElement>): boolean {
    if (!domElement) {
        return false;
    }

    const { height, left, top, width } = domElement.getBoundingClientRect();

    const bottom = top + height;
    const right = left + width;

    return (
        top >= 0
        && left >= 0
        && bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
