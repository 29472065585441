import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme, createStyles } from '@material-ui/core/styles';

export const useToastStyles = makeHighPriorityStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        wrapper: {
            top: theme.spacing(4),
        },
        wrapperWithSidebar: {
            left: `calc(50% + ${theme.spacing(15.5)}px)`,
        },
        alert: {
            minWidth: '40vw',
        },
    }),
);
