import { getClientManagersAction } from 'modules/users/managers/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const isLoading = isLoadingReducer(getClientManagersAction);
const userRoles = singleValueReducer(getClientManagersAction.successType, []);

export const managers = combineReducers({
    isLoading,
    userRoles,
});
