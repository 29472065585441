import { ICustomFieldWithValues } from 'modules/settings/submodules/clients/customFieldValues/store/models';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { getCustomFieldsSheetInfoItems } from '../store/helpers';
import {
    excludePosition,
    onlyNotEmptyItems,
} from './filters';

export function addCustomFieldsSheetInfoItems(
    sheetCustomFieldsWithValues: ICustomFieldWithValues[],
    hasJobNumberUserTypeName: boolean,
    sheetDetailsList: Array<ISheetInfoItemData>,
): Array<ISheetInfoItemData> {
    const customFieldsItems = getCustomFieldsSheetInfoItems(sheetCustomFieldsWithValues);
    let notEmptyCustomFieldsItems = customFieldsItems.filter(onlyNotEmptyItems);

    if (hasJobNumberUserTypeName) {
        // Genworth should display user type instead of position from custom values
        notEmptyCustomFieldsItems = notEmptyCustomFieldsItems.filter(excludePosition);
    }

    const filteredList = sheetDetailsList.filter(item => {
        const hasCustomFieldTitle = (customFieldItem: ISheetInfoItemData) => item.title === customFieldItem.title;
        return !notEmptyCustomFieldsItems.some(hasCustomFieldTitle);
    });

    filteredList.push(...notEmptyCustomFieldsItems);
    return filteredList;
}
