import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setEmployeesFilter } from 'modules/users/employees/store/actions';
import { selectEmployeeFilter } from 'modules/users/employees/store/selectors';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import FilterChip from 'shared/components/filters/FilterChip';
import { IEmployeeFilter } from 'modules/users/employees/store/models';

interface IEmployeeChips {
    ignoreClient?: boolean;
}

export default function EmployeeChips({ ignoreClient = false }: IEmployeeChips) {
    const dispatch = useDispatch();
    const filter = useSelector(selectEmployeeFilter);

    const onDelete = useCallback((name: keyof IEmployeeFilter) => {
        dispatch(setEmployeesFilter({
            ...filter,
            [name]: undefined,
        }));
    }, [dispatch, filter]);

    const hasFilters = filter.client || filter.status;

    return hasFilters ? (
        <Box
            mb={2}
            data-testid={FiltersTestIds.ChipsWrapper}
        >
            {!ignoreClient && filter.client && (
                <FilterChip
                    name="client"
                    label={filter.client.name}
                    onDelete={onDelete}
                />
            )}

            {filter.status && (
                <FilterChip
                    name="status"
                    label={filter.status}
                    onDelete={onDelete}
                />
            )}
        </Box>
    ) : null;
}
