import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { getOfferLetters } from 'modules/offerLetter/store/actions';
import { ResetPasswordButton } from 'modules/settings/submodules/employees/components/EditEmployee/ResetPasswordButton';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { AcceptOfferButton } from 'modules/settings/submodules/employees/components/EditEmployee/AcceptOfferButton';
import { ResetOfferButton } from 'modules/settings/submodules/employees/components/EditEmployee/ResetOfferButton';
import { useButtonStyles } from 'shared/components/buttons/buttonStyles';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';
import {
    getEmployeeClients,
    setEditEmployeeId,
    updateEmployee,
} from 'modules/settings/submodules/employees/store/actions';
import { getUpdateEmployeePayload } from 'modules/settings/submodules/employees/components/EditEmployee/helpers';
import { selectEditEmployeeId, selectIsEmployeeUpdating } from 'modules/settings/submodules/employees/store/selectors';
import { EditEmployeeForm } from 'modules/settings/submodules/employees/components/EditEmployee/EditEmployeeForm';
import { useEmployeeFormInitialValues } from 'modules/settings/submodules/employees/store/hooks';
import { selectUserById } from 'store/entities/users/selectors';
import { DeleteEmployeeButton } from 'modules/settings/submodules/employees/components/EditEmployee/DeleteEmployeeButton';
import {
    useSettingsFormGridStyles,
} from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { MoreInformationSection } from './MoreInformationSection';
import { getUserAuthStats } from 'store/entities/users/actions';

export const EditEmployeeModal = () => {
    const dispatch = useDispatch();
    const classes = useColoredFormModalStyles();
    const formClasses = useSettingsFormGridStyles();
    const buttonClasses = useButtonStyles();

    const editEmployeeId = useSelector(selectEditEmployeeId);
    const isLoading = useSelector(selectIsEmployeeUpdating);
    const onClose = useCallback(() => {
        dispatch(setEditEmployeeId(null));
    }, [dispatch]);
    const initialValues = useEmployeeFormInitialValues(editEmployeeId);
    const handleSubmitEmployee = useCallback(formValues => {
        const updatePayload = getUpdateEmployeePayload(
            editEmployeeId,
            formValues,
            initialValues,
        );
        dispatch(updateEmployee.init(updatePayload));
    }, [dispatch, editEmployeeId, initialValues]);

    const user = useSelector(selectUserById(editEmployeeId));
    useEffect(() => {
        if (user?.email) {
            dispatch(getOfferLetters.init({
                // @ts-ignore
                email: user?.email,
            }));
        }
        if (user?.id) {
            dispatch(getEmployeeClients.init(user?.id));
            dispatch(getUserAuthStats.init(user?.id));
        }
    }, [dispatch, user]);

    const additionalButton = (
        <>
            <DeleteEmployeeButton
                className={formClasses.button}
                employeeId={editEmployeeId}
            />
            <ResetPasswordButton
                className={clsx(formClasses.button, buttonClasses.link)}
                employeeId={editEmployeeId}
            />
        </>
    );

    return (
        <ModalDialog
            title="Edit Employee"
            isOpened={Boolean(editEmployeeId)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    width="70%"
                    mr={2}
                >
                    <EditEmployeeForm
                        isLoading={isLoading}
                        onSubmit={handleSubmitEmployee}
                        initialValues={initialValues}
                        initialEmail={initialValues.email}
                        additionalButton={additionalButton}
                    >
                        <>
                            <Box className={formClasses.formBlock}>
                                <Typography variant="subtitle2" color="secondary">Offer Letter Status</Typography>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    m={2}
                                >
                                    <Box>
                                        <Typography variant="subtitle2">Override Status and Set it to Accepted</Typography>
                                        <Typography variant="body2">
                                            This will override their onboarding in Headway Connect
                                            and set their status to accepted.
                                        </Typography>
                                    </Box>
                                    <AcceptOfferButton userId={editEmployeeId}/>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    m={2}
                                >
                                    <Box>
                                        <Typography variant="subtitle2">Reset Status to Outstanding in Headway Connect</Typography>
                                        <Typography variant="body2">
                                            This will retract their accepted status and return
                                            it back to outstanding in Headway Connect.
                                        </Typography>
                                    </Box>
                                    <ResetOfferButton userId={editEmployeeId}/>
                                </Box>
                            </Box>
                        </>
                    </EditEmployeeForm>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="30%"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <MoreInformationSection/>
                    </Box>
                </Box>
            </Box>
        </ModalDialog>
    );
};
