import { createSelector } from 'reselect';
import { IDeal } from 'shared/models/Deal';
import { IStore } from 'store/configureStore';
import { IAssignmentReportData } from 'modules/settings/submodules/clients/assignmentsReportData/store/model';
import { selectDealsState } from 'modules/settings/submodules/clients/deals/store/selectors';
import { selectDealsById } from 'store/entities/configuration/configurationSelectors';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';

export interface IDealRow extends IDeal {
    className?: string;
}

const selectDealsTableState = (state: IStore) => selectDealsState(state).dealsTable;
export const dealsInfinityTableSelectors = getInfinityScrollSelectors<IAssignmentReportData>(selectDealsTableState);
export const selectDealsRows = createSelector(
    dealsInfinityTableSelectors.selectItems,
    selectDealsById,
    (ids, dealsByIds): IDealRow[] => {
        return ids.map(id => dealsByIds[id])
            .sort((d1, d2) => d2.deal_number - d1.deal_number);
    },
);
