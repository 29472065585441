import {
    hierarchyRowsSelector,
} from 'modules/settings/submodules/components/HierarchyPage/HierarchyTable/store/selectors';
import { getClientFieldsConfiguration } from 'store/entities/clients/clientsAction';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectIsCustomFieldsApplied } from 'store/entities/clients/selectors/configurationSelectors';
import {
    clearLocalClientHierarchyNodes,
    deleteCustomFieldHierarchyNode, exportCustomFieldValues,
    getClientCustomFieldConfiguration,
    getCustomFields,
    getCustomFieldsHierarchyNodes,
    getCustomFieldsMappings,
    getCustomFieldValues,
    queryCustomFieldValues, setFieldValuesIsLoaded,
    setUseSingleHierarchy,
} from 'store/entities/customFields/actions';
import { customFieldsApi } from 'store/entities/customFields/api';
import { HierarchyType, ICustomFieldValuesLoaded, IHierarchyRequest } from 'store/entities/customFields/model';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { optionalLoadEntitiesByIdsSaga } from 'store/utils/sagas/optionalLoadEntitiesByIdsSaga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { selectCustomFieldValuesByIds, selectIdCustomFieldAllValuesIsAlreadyLoaded } from './selectors';
import { uniq } from 'lodash-es';
import {
    getDownloadFileWithNameByRequestSagaWatcher,
} from '../../utils/sagas/getDownloadFileByRequestSagaWatcher';

const getCustomFieldsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getCustomFields,
    customFieldsApi.getCustomFields,
    'custom fields',
);
const getCustomFieldsMappingWatcher = getLoadEntitiesByRequestSagaWatcher(
    getCustomFieldsMappings,
    customFieldsApi.getCustomFieldMappings,
    'custom fields mapping',
);

function* getCustomFieldValuesSaga({ payload }: ReturnType<typeof getCustomFieldValues.init>) {
    const customFieldValueIds = payload.custom_field_value_ids;
    const customFieldId = payload.custom_field_id;
    if (customFieldValueIds !== undefined) {
        yield optionalLoadEntitiesByIdsSaga(
            uniq(customFieldValueIds),
            selectCustomFieldValuesByIds,
            queryCustomFieldValues,
            ids => ({ ...payload, custom_field_value_ids: ids }),
        );
    } else if (customFieldId !== undefined) {
        const customFieldValuesIsAlreadyLoaded = yield select(
            selectIdCustomFieldAllValuesIsAlreadyLoaded(customFieldId),
        );
        if (customFieldValuesIsAlreadyLoaded) {
            return;
        }
        const items = yield* call(customFieldsApi.queryCustomFieldValues, payload);
        yield put(queryCustomFieldValues.success(items));
        yield put(setFieldValuesIsLoaded(payload as ICustomFieldValuesLoaded));
    } else {
        yield* put(queryCustomFieldValues.init(payload));
    }
    yield* put(getCustomFieldValues.success([]));
}

function* getCustomFieldValuesWatcher() {
    yield takeEvery(
        getCustomFieldValues.initType,
        getCustomFieldValuesSaga,
    );
}

const queryCustomFieldValuesWatcher = getLoadEntitiesByRequestSagaWatcher(
    queryCustomFieldValues,
    customFieldsApi.queryCustomFieldValues,
    'custom field values',
    true,
);

export const downloadCustomFieldValuesWatcher = getDownloadFileWithNameByRequestSagaWatcher(
    exportCustomFieldValues,
    customFieldsApi.exportCustomFieldValues,
    function ({ filename }) {
        return filename;
    },
);

function* getCustomFieldsHierarchyNodesSaga(action: ReturnType<typeof getCustomFieldsHierarchyNodes.init>) {
    const { client_id, ...request } = (action?.payload || {}) as IHierarchyRequest;
    const clientId = client_id ? client_id : yield select(selectCurrentClientId);
    let result;
    if (clientId) {
        result = yield call(customFieldsApi.getClientCustomFieldsHierarchyNodesWithLinked, clientId, request);
        if (request.hierarchy_type !== HierarchyType.OfferLetter) {
            yield put(setUseSingleHierarchy(result.is_offer_letter_ready));
        }
    } else {
        result = yield call(customFieldsApi.getGlobalCustomFieldsHierarchyNodes, request);
    }

    if (result) {
        if (result.linked?.custom_field_values !== undefined) {
            yield put(queryCustomFieldValues.success(result.linked?.custom_field_values || []));
        }
        yield put(getCustomFields.success(result.linked?.custom_fields || []));
        yield put(getCustomFieldsHierarchyNodes.success(result?.nodes || []));
    }
}

function* getCustomFieldsHierarchyNodesWatcher() {
    yield* takeEvery(
        getCustomFieldsHierarchyNodes.initType,
        withBackendErrorHandler(
            getCustomFieldsHierarchyNodesSaga,
            getCustomFieldsHierarchyNodes.error,
            'Unable to fetch custom fields hierarchy nodes',
        ),
    );
}

function* clearLocalHierarchyNodesSaga() {
    const hierarchyRows = yield* select(hierarchyRowsSelector);
    for (const hierarchyRow of hierarchyRows) {
        yield put(deleteCustomFieldHierarchyNode.success(hierarchyRow.hierarchyNode?.id || ''));
    }
}

function* clearLocalHierarchyNodesWatcher() {
    yield* takeEvery(
        clearLocalClientHierarchyNodes.action,
        clearLocalHierarchyNodesSaga,
    );
}

function* getClientCustomFieldConfigurationSaga() {
    const clientUsedCustomFields = yield select(selectIsCustomFieldsApplied);
    if (clientUsedCustomFields) {
        yield put(getCustomFieldsHierarchyNodes.init({ cfvs: false }));
    }
}

function* getClientCustomFieldConfigurationWatcher() {
    yield* takeLatest(
        [
            getClientFieldsConfiguration.successType,
            getClientCustomFieldConfiguration.action,
        ],
        getClientCustomFieldConfigurationSaga,
    );
}

export default [
    getCustomFieldsWatcher,
    getCustomFieldsMappingWatcher,
    getCustomFieldsHierarchyNodesWatcher,
    queryCustomFieldValuesWatcher,
    getCustomFieldValuesWatcher,
    clearLocalHierarchyNodesWatcher,
    getClientCustomFieldConfigurationWatcher,
    downloadCustomFieldValuesWatcher,
];
