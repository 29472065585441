import { call, put, takeLatest } from 'typed-redux-saga';
import { getDepartments } from './actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { departmentApi } from './api';

function* getDepartmentsSaga(
    action: ReturnType<typeof getDepartments.init>,
) {
    const departments = yield* call(departmentApi.getAll, action.payload || {});
    yield put(getDepartments.success(departments));
}

function* getDepartmentsWatcher() {
    yield takeLatest(
        getDepartments.initType,
        withBackendErrorHandler(
            getDepartmentsSaga,
            getDepartments.error,
            'Unable to load company departments',
        ),
    );
}

export default [
    getDepartmentsWatcher,
];
