import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import ControllingOrgFormWrapper
    from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgForm/ControllingOrgFormWrapper';
import { IControllingOrgFormValues } from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgForm/model';
import { useDispatch, useSelector } from 'react-redux';
import { createControllingOrg } from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/store/actions';
import { ICreateControllingOrgPayload } from 'shared/models/ControllingOrg';
import { selectIsControllingOrgCreating } from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/store/selectors';

interface INewClientModal extends Omit<IModalProps, 'title' | 'children'> {
    title: string;
    isOpened: boolean;
    onClose: () => void;
}

export default function CreateControllingOrgModal({
    title, isOpened, onClose, ...modalProps
}: INewClientModal) {

    const dispatch = useDispatch();
    const onSave = useCallback((values: IControllingOrgFormValues) => {
        const { zip_code, county, ...valuesCleared } = values;
        const payload = {
            ...valuesCleared,
            zip: zip_code,
        } as ICreateControllingOrgPayload;
        dispatch(createControllingOrg.init(payload));
    }, [dispatch]);

    const isLoading = useSelector(selectIsControllingOrgCreating);

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={modalProps}
        >
            <Box>
                <ControllingOrgFormWrapper
                    onSubmit={onSave}
                    onCancel={onClose}
                    submitTitle="Create"
                    isLoading={isLoading}
                />
            </Box>
        </ModalDialog>
    );
}
