import baseApi from 'shared/utils/baseApi';
import { clientConfigurationsService } from 'store/entities/configuration/configurationApi';
import { ICustomField } from 'store/entities/customFields/model';
import {
    ICreateCustomField,
    ISyncCustomFieldPayload,
    IUpdateCustomField,
} from 'modules/settings/submodules/customFields/store/models';

const customFieldsRoute = 'custom_fields';

export const customFieldsSettingsApi = {
    async createCustomField(request: ICreateCustomField): Promise<ICustomField> {
        const { data } = await baseApi.create<ICreateCustomField, ICustomField>(
            clientConfigurationsService,
            customFieldsRoute,
            request,
        );
        return data;
    },
    async updateCustomField(id: string, update: IUpdateCustomField): Promise<ICustomField> {
        const { data } = await baseApi.patch<IUpdateCustomField, ICustomField>(
            `${clientConfigurationsService}/${customFieldsRoute}/${id}`,
            update,
        );
        return data;
    },
    async deleteCustomField(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${clientConfigurationsService}/${customFieldsRoute}`, id,
        );
        return data.status;
    },
    async syncCustomFieldWithPrism(payload: ISyncCustomFieldPayload): Promise<string> {
        const { data } = await baseApi.post(
            `/prismhr/clients/${payload.client_id}/sync_custom_field_values`,
            { prism_field: payload.field_prism_name });
        return data.status;
    },
};
