import React from 'react';
import { useSelector } from 'react-redux';

import {
    selectTotalUsers,
    selectUsers,
} from 'modules/settings/submodules/platformUsers/store/selectors';

import { PaginationCounter } from 'modules/settings/submodules/components/PaginationCounter/PaginationCounter';

export const PlatformUsersPaginationCounter = () => {

    const users = useSelector(selectUsers);
    const totalUsers = useSelector(selectTotalUsers);

    return (
        <PaginationCounter shown={users?.length} total={totalUsers}/>
    );
};
