import { createActions, createSingleAction } from 'store/utils';
import {
    ICreateTemplatePayload,
    IUpdateTemplatePayload,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';

export const SET_CREATE_BACKGROUND_CHECK_TEMPLATE_MODAL_STATE = 'settings/SET_CREATE_BACKGROUND_CHECK_TEMPLATE_MODAL_STATE';
export const setCreateBackgroundCheckTemplateModalState = createSingleAction<
boolean,
typeof SET_CREATE_BACKGROUND_CHECK_TEMPLATE_MODAL_STATE
>(
    SET_CREATE_BACKGROUND_CHECK_TEMPLATE_MODAL_STATE,
);

export const CREATE_BACKGROUND_CHECK_TEMPLATE = 'settings/CREATE_BACKGROUND_CHECK_TEMPLATE';
export const CREATE_BACKGROUND_CHECK_TEMPLATE_SUCCESS = 'settings/CREATE_BACKGROUND_CHECK_TEMPLATE_SUCCESS';
export const CREATE_BACKGROUND_CHECK_TEMPLATE_ERROR = 'settings/CREATE_BACKGROUND_CHECK_TEMPLATE_ERROR';

export const createBackgroundCheckTemplate = createActions<
ICreateTemplatePayload,
void,
any,
typeof CREATE_BACKGROUND_CHECK_TEMPLATE,
typeof CREATE_BACKGROUND_CHECK_TEMPLATE_SUCCESS,
typeof CREATE_BACKGROUND_CHECK_TEMPLATE_ERROR
>(
    CREATE_BACKGROUND_CHECK_TEMPLATE,
    CREATE_BACKGROUND_CHECK_TEMPLATE_SUCCESS,
    CREATE_BACKGROUND_CHECK_TEMPLATE_ERROR,
);

export const SET_EDIT_BACKGROUND_CHECK_TEMPLATE_ID = 'settings/SET_EDIT_BACKGROUND_CHECK_TEMPLATE_ID';
export const setEditBackgroundCheckTemplateId = createSingleAction<
string | null,
typeof SET_EDIT_BACKGROUND_CHECK_TEMPLATE_ID
>(
    SET_EDIT_BACKGROUND_CHECK_TEMPLATE_ID,
);

export const UPDATE_BACKGROUND_CHECK_TEMPLATE = 'settings/UPDATE_BACKGROUND_CHECK_TEMPLATE';
export const UPDATE_BACKGROUND_CHECK_TEMPLATE_SUCCESS = 'settings/UPDATE_BACKGROUND_CHECK_TEMPLATE_SUCCESS';
export const UPDATE_BACKGROUND_CHECK_TEMPLATE_ERROR = 'settings/UPDATE_BACKGROUND_CHECK_TEMPLATE_ERROR';

export const updateBackgroundCheckTemplate = createActions<
IUpdateTemplatePayload,
void,
any,
typeof UPDATE_BACKGROUND_CHECK_TEMPLATE,
typeof UPDATE_BACKGROUND_CHECK_TEMPLATE_SUCCESS,
typeof UPDATE_BACKGROUND_CHECK_TEMPLATE_ERROR
>(
    UPDATE_BACKGROUND_CHECK_TEMPLATE,
    UPDATE_BACKGROUND_CHECK_TEMPLATE_SUCCESS,
    UPDATE_BACKGROUND_CHECK_TEMPLATE_ERROR,
);
