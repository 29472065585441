import { useDispatch, useSelector } from 'react-redux';
import { selectIsOpenCcpReceiptsSidebar, selectOpenedReceiptTransactionId } from './store/selectors';
import { selectCcpTransactionsById } from '../CcpTransactionsTable/store/selectors';
import { ICcpTransaction } from '../../models/CcpTransaction';
import CcpFilesUploader from './CcpFilesUploader';
import React from 'react';
import {
    setOpenReceiptEditCcpSidebar,
} from './store/actions';
import Sidebar from '../../../../shared/components/sidebars/Sidebar/Sidebar';

export const CcpReceiptEditorSidebar = () => {
    const isOpened = useSelector(selectIsOpenCcpReceiptsSidebar);
    const openedId = useSelector(selectOpenedReceiptTransactionId);
    const transactions = useSelector(selectCcpTransactionsById);
    const transactionItem = transactions[openedId] as ICcpTransaction;
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setOpenReceiptEditCcpSidebar(''));
    };

    return (
        <Sidebar
            title="ADD RECEIPT"
            isOpened={Boolean(isOpened)}
            onClose={handleClose}
            whiteTitle
        >
            <CcpFilesUploader
                entry={transactionItem}
            />
        </Sidebar>
    );
};
