import { loadAttachments } from 'shared/components/attachments/store/actions';
import { combineReducers } from 'redux';
import { itemsByIdReducer } from 'store/reducerUtils';

export const attachmentObjectUrlById = itemsByIdReducer(loadAttachments);

export const attachments = combineReducers({
    attachmentObjectUrlById,
});

export type AttachmentsState = ReturnType<typeof attachments>;
