import { makeStyles } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

export const useEditClientFormStyles = makeStyles(theme => ({
    formBlock: {
        '& + &': {
            borderTop: `1px solid ${colors.gray}`,
        },
        paddingLeft: theme.spacing(20),
        position: 'relative',
        padding: theme.spacing(2, 0, 4, 0),
    },
    formBlockTitle: {
        color: colors.primary,
        position: 'absolute',
        left: theme.spacing(3.5),
        top: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            position: 'block',
        },
    },
}));
