import { createActions } from 'store/utils';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';

import { ISetSubmittingOrgStatusPayload } from 'modules/settings/submodules/clients/submittingOrg/components/UpdateStatusSelect/store/models';

export const SET_SUBMITTING_ORG_STATUS = 'settings/SET_SUBMITTING_ORG_STATUS';
export const SET_SUBMITTING_ORG_STATUS_SUCCESS = 'settings/SET_SUBMITTING_ORG_STATUS_SUCCESS';
export const SET_SUBMITTING_ORG_STATUS_ERROR = 'settings/SET_SUBMITTING_ORG_STATUS_ERROR';

export const setSubmittingOrgStatus = createActions<
ISetSubmittingOrgStatusPayload,
ISubmittingOrg,
any,
    typeof SET_SUBMITTING_ORG_STATUS,
    typeof SET_SUBMITTING_ORG_STATUS_SUCCESS,
    typeof SET_SUBMITTING_ORG_STATUS_ERROR
>(
    SET_SUBMITTING_ORG_STATUS,
    SET_SUBMITTING_ORG_STATUS_SUCCESS,
    SET_SUBMITTING_ORG_STATUS_ERROR,
);
