import { combineReducers } from 'redux';
import { ItemsById } from 'shared/models/ItemsById';
import {
    IStatus,
    EntryType,
    IExpenseEntry,
    IExpenseSheet,
    IExpenseSheetBackend,
    ITimeSheet,
} from 'shared/models/sheet/Sheet';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { isSamePayPeriod } from 'store/entities/timesheet/reducers/time';
import { isLoadingReducer, itemsByIds } from 'store/reducerUtils';
import {
    addExpenseEntry, clearExpenseSheetsByPayPeriod,
    ExpenseSheetActions,
    getExpenseStatuses,
    loadExpenseSheets,
    loadExpenseSheetsWithEntries,
    removeExpenseEntry, removeExpenseSheet,
    updateExpenseEntry,
    updateExpenseSheets, updateTemporaryExpenseEntry,
} from 'store/entities/timesheet/actions/expenseActions';
import { AttachmentActions, UPDATE_ENTRY_ATTACHMENTS } from '../actions/entryAttachments';

const defaultState = {
    sheetsById: {},
    entriesById: {},
    isSheetsLoading: false,
    statuses: [],
};

function transformSheet(item: Omit<IExpenseSheetBackend, 'entries'>): IExpenseSheet {
    return {
        ...item,
        entry_type: EntryType.EXPENSE,
    };
}

export function clearEmptyExpenseSheetsByPayPeriod(sheetsByIds: ItemsById<IExpenseSheet>, payPeriod: IPayPeriod) {
    return itemsByIds(Object.values(sheetsByIds).filter(
        sheet => !isSamePayPeriod(sheet, payPeriod) || parseFloat(sheet.total_dollars),
    ));
}

export function getExpenseEntriesFromSheet(sheet: IExpenseSheetBackend): ItemsById<IExpenseEntry> {
    return sheet.entries?.reduce((entriesWithSheetTypeById: ItemsById<IExpenseEntry>, sheetEntry) => {
        return {
            ...entriesWithSheetTypeById,
            [sheetEntry.id]: {
                ...sheetEntry,
                entry_type: EntryType.EXPENSE,
            },
        };
    }, {}) || {};
}

export const isSheetsLoading = isLoadingReducer(loadExpenseSheetsWithEntries);

export function sheetsById(
    state: ItemsById<IExpenseSheet> = defaultState.sheetsById,
    action: ExpenseSheetActions,
): ItemsById<IExpenseSheet> {
    switch (action.type) {
        case loadExpenseSheets.successType:
        case updateExpenseSheets.successType: {
            return {
                ...state,
                ...itemsByIds(
                    action.payload,
                    transformSheet,
                ),
            };
        }
        case loadExpenseSheetsWithEntries.successType: {
            // We want to save every property but entries info this reducer
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const updatedSheets = action.payload.reduce((
                acc: Record<string, IExpenseSheet>,
                { entries, ...updatedSheet }: IExpenseSheetBackend,
            ) => ({
                ...acc,
                [updatedSheet.id]: transformSheet(updatedSheet),
            }), {});
            return {
                ...state,
                ...updatedSheets,
            };
        }
        case clearExpenseSheetsByPayPeriod.action:
            return clearEmptyExpenseSheetsByPayPeriod(state, action.payload);
        case removeExpenseSheet.action:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
            const { [action.payload]: removedSheet, ...otherSheets } = state;
            return {
                ...otherSheets,
            };
        default:
            return state;
    }
}

export function entriesById(
    state: ItemsById<IExpenseEntry> = defaultState.entriesById,
    action: ExpenseSheetActions | AttachmentActions,
): ItemsById<IExpenseEntry> {
    switch (action.type) {
        case loadExpenseSheetsWithEntries.successType: {
            const entries = action.payload.reduce((acc: Record<string, ITimeSheet>, sheet: IExpenseSheetBackend) => ({
                ...acc,
                ...getExpenseEntriesFromSheet(sheet),
            }), {});

            return {
                ...state,
                ...entries,
            };
        }
        case addExpenseEntry.successType:
        case updateExpenseEntry.successType:
            return {
                ...state,
                [action.payload.id]: {
                    ...action.payload,
                    entry_type: EntryType.EXPENSE,
                },
            };
        case updateTemporaryExpenseEntry.action:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
            const { [action.payload.temporaryId]: temporaryEntry, ...otherEntries } = state;
            return {
                ...otherEntries,
                [action.payload.entry.id]: {
                    ...action.payload.entry,
                    entry_type: EntryType.EXPENSE,
                },
            };
        case UPDATE_ENTRY_ATTACHMENTS:
        {
            const attachmentFilter = state[action.payload.entryId].sheet_entry_attachments
                .filter(file => file.id !== action.payload.attachmentId);
            return {
                ...state,
                [action.payload.entryId]: {
                    ...state[action.payload.entryId],
                    sheet_entry_attachments: attachmentFilter,
                },
            };
        }
        case removeExpenseEntry.successType: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [action.payload]: removedEntry, ...newState } = state;
            return newState;
        }
        default:
            return state;
    }
}

export function statuses(state: Array<IStatus> = defaultState.statuses, action: ExpenseSheetActions): Array<IStatus> {
    switch (action.type) {
        case getExpenseStatuses.successType:
            return action.payload;
        default:
            return state;
    }
}

export const expenses = combineReducers({
    sheetsById,
    entriesById,
    isSheetsLoading,
    statuses,
});
