import { useAssignmentPayRateType } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/store/useAssignmentPayType';
import PlainText from 'shared/components/table/Cells/PlainText';
import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { PayRateType } from 'shared/models/PayType';
import { useFormattedPayRate } from 'shared/utils/formatters/payRate';
import { selectFirstTwoRootCustomFields } from 'store/entities/customFields/selectors';
import { useSelector } from 'react-redux';
import { ICustomField } from 'store/entities/customFields/model';
import { colors } from 'shared/styles/constants';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { getUserName } from 'shared/utils/converters/user';
import { IDeletePrePostInitializeReportRow } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/deleteModels';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';

export const useDeletePrePostInitializeReportTableCells = () => {

    const firstTwoRootCustomFields: ICustomField[] = useSelector(selectFirstTwoRootCustomFields);

    const customFieldCells = firstTwoRootCustomFields.map(customField => {
        return {
            key: customField.name,
            title: customField.description,
            render: function CustomFieldCell(
                { displayCustomFieldValues, className }: IDeletePrePostInitializeReportRow){
                const customFieldValue = displayCustomFieldValues[customField.id];
                return (
                    <PlainText className={className} value={customFieldValue}/>
                );
            },
        };
    });

    return [
        {
            key: 'employee',
            title: 'employee',
            render: function EmployeeCell({ employee, className }: IDeletePrePostInitializeReportRow){
                return (
                    <PlainText className={className} value={getUserName(employee)}/>
                );
            },
        },
        ...customFieldCells,
        {
            key: 'override_rate',
            title: 'override rate',
            render: function OverrideRateCell({ override_rate_value, assignment_id, className }
            : IDeletePrePostInitializeReportRow){
                const assignmentPayRateType = useAssignmentPayRateType(assignment_id);
                const value = useFormattedPayRate({
                    pay_rate_value: override_rate_value,
                    pay_rate_type: assignmentPayRateType || PayRateType.PER_HOUR,
                });
                return (
                    <PlainText className={className} value={value}/>
                );
            },
        },
        {
            key: 'differential',
            title: 'differential',
            render: function DifferentialCell({ difference, className }: IDeletePrePostInitializeReportRow){
                return (
                    <PlainText className={className} value={`${difference > 0 ? '+' : ''}${formatDollars(difference)}`}/>
                );
            },
        },
        {
            key: 'start_date',
            title: 'start date',
            render: function StartDateCell({ start_date, className }: IDeletePrePostInitializeReportRow){
                return (
                    <PlainText className={className} value={formatShortDate(start_date)}/>
                );
            },
        },
        {
            key: 'end_date',
            title: 'end date',
            render: function EndDateCell({ end_date, className }: IDeletePrePostInitializeReportRow){
                return (
                    <PlainText className={className} value={end_date ? formatShortDate(end_date) : ''}/>
                );
            },
        },
        {
            key: 'status',
            title: '',
            render: function StatusCell({ failed_reason, className }: IDeletePrePostInitializeReportRow){
                return (
                    <Box className={className}>
                        {
                            !failed_reason
                                ? (
                                    <CheckIcon component="svg" htmlColor={colors.green}/>
                                )
                                : (
                                    <CloseIcon component="svg" htmlColor={colors.red}/>
                                )
                        }
                    </Box>
                );
            },
        },
        {
            key: 'error_message',
            title: 'error message',
            render: function ErrorMessageCell({ failed_reason, className }: IDeletePrePostInitializeReportRow){
                return (
                    <PlainText className={className} value={failed_reason}/>
                );
            },
        },
    ];
};
