import { IDealType } from 'shared/models/DealType';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export interface ICreateDealTypeRequest extends Omit<
IDealType,
'id' | 'deleted_at' | 'use_default_activity' | 'billing_info_required'
> {
}

export interface ICreateDealTypePayload extends Omit<ICreateDealTypeRequest, 'client_id'> {
}

export const validationScheme = yup.object().shape({
    name: yup.string().nullable().required(ValidationMessages.REQUIRED),
});
