import React from 'react';

import { Button } from '@material-ui/core';

export interface IAddTemplateButtonProps{
    onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}

export const AddTemplateButton = (props: IAddTemplateButtonProps) => {

    const { onClick } = props;

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={onClick}>
                + add template
            </Button>
        </>
    );
};
