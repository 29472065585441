const registeredActions: Array<string> = [];

export type ActionCreatorKnownArgs<ArgsType, ReturnType> = [void] extends [RequestType]
    ? () => ReturnType
    : (payload: ArgsType) => ReturnType;
export interface IActionCreatorWithActionName<ArgsType, ReturnType>
    extends ActionCreatorKnownArgs<ArgsType, ReturnType> {
    action: string;
}
export type ActionPayloadType<PayloadType = void, ActionType extends string = string> = [void] extends [RequestType]
    ? { type: ActionType }
    : { type: ActionType; payload: PayloadType };
export interface IErrorActionPayload {
    // TODO replace any with some param as soon as we have error handling strategy
    error: any;
}

export function createSingleAction<PayloadType = void, ActionType extends string = string>(
    actionType: ActionType,
): IActionCreatorWithActionName<PayloadType, ActionPayloadType<PayloadType, ActionType>> {
    // Prevent multiple actions with the same name from occuring in the application
    if (process.env.NODE_ENV === 'development') {
        if (registeredActions.includes(actionType)) {
            throw new Error(`Action ${actionType} is already registered somewhere else`);
        } else {
            registeredActions.push(actionType);
        }
    }
    const action = (payload?: PayloadType) => ({
        type: actionType,
        payload,
    }) as unknown as ActionPayloadType<PayloadType, ActionType>;
    action.action = actionType;
    return action;
}

export interface IActionsCreatorCommon<
    RequestType,
    SuccessType,
    ErrorType,
    RequestActionType extends string,
    SuccessActionType extends string,
    ErrorActionType extends string,
> {
    init: ActionCreatorKnownArgs<RequestType, ActionPayloadType<RequestType, RequestActionType>>;
    initType: string;
    success: ActionCreatorKnownArgs<SuccessType, ActionPayloadType<SuccessType, SuccessActionType>>;
    successType: string;
    error: ActionCreatorKnownArgs<ErrorType, ActionPayloadType<ErrorType, ErrorActionType>>;
    errorType: string;
    allTypes: [string, string, string];
    finallyTypes: [string, string];
}

export function createActions<
    RequestType,
    SuccessType,
    ErrorType,
    RequestActionType extends string,
    SuccessActionType extends string,
    ErrorActionType extends string,
>(
    requestType: RequestActionType,
    successType: SuccessActionType,
    errorType: ErrorActionType,
): IActionsCreatorCommon<RequestType, SuccessType, ErrorType, RequestActionType, SuccessActionType, ErrorActionType> {
    return {
        init: createSingleAction<RequestType, RequestActionType>(requestType),
        initType: requestType,
        success: createSingleAction<SuccessType, SuccessActionType>(successType),
        successType: successType,
        error: createSingleAction<ErrorType, ErrorActionType>(errorType),
        errorType: errorType,
        allTypes: [requestType, successType, errorType],
        finallyTypes: [successType, errorType],
    };
}

export enum RequestType{
    Get = 'GET',
    Create = 'CREATE',
    Update = 'UPDATE',
    Delete = 'DELETE',
    PATCH = 'PATCH',
}

export function createRequestActions<TInitPayload, TSuccessPayload, TErrorPayload = void>(
    requestType: RequestType,
    entityName: string,
    prefix = '') {

    const entityToUpper = entityName.toUpperCase();
    const ACTION_ENTITY = `${prefix}/${requestType}_${entityToUpper}`;
    const ACTION_ENTITY_SUCCESS = `${prefix}/${requestType}_${entityToUpper}_SUCCESS`;
    const ACTION_ENTITY_ERROR = `${prefix}/${requestType}_${entityToUpper}_ERROR`;

    return createActions<
    TInitPayload,
    TSuccessPayload,
    TErrorPayload,
        typeof ACTION_ENTITY,
        typeof ACTION_ENTITY_SUCCESS,
        typeof ACTION_ENTITY_ERROR
    >(
        ACTION_ENTITY,
        ACTION_ENTITY_SUCCESS,
        ACTION_ENTITY_ERROR,
    );

}

export type ActionsReturnTypes<T extends IActionsCreatorCommon<any, any, any, any, any, any>> =
    ReturnType<T['init']> | ReturnType<T['success']> | ReturnType<T['error']>;
