import { searchEntity } from 'shared/components/autocomplete/EntityAutocompleteWithSearch/store/actions';
import { put } from 'typed-redux-saga';

export function autocompleteSearchSagaWrapper(searchSaga: (action: any) => Generator) {
    return function*(action: ReturnType<typeof searchEntity.init>) {
        const { payload } = action;
        yield put(searchEntity.init(payload));
        try {
            const results = yield* searchSaga(action);
            yield put(searchEntity.success({
                ...payload,
                results,
            }));
        } catch (error) {
            // @ts-ignore
            yield put(searchEntity.error({
                ...payload,
                error,
            }));
        }
    };
}
