import { combineReducers } from 'redux';

import {
    SET_EDIT_DEAL_ID,
    updateDeal,
} from 'modules/settings/submodules/clients/deals/components/EditDeal/store/actions';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const dealId = singleValueReducer(SET_EDIT_DEAL_ID, null as string | null);
const isUpdating = isLoadingReducer(updateDeal);

export const editDeal = combineReducers({
    dealId,
    isUpdating,
});
