import React, { useCallback } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Menu, MenuItem, Divider, Box } from '@material-ui/core';
import { routes } from 'shared/routes';
import { colors } from 'shared/styles/constants';
import { impersonateSwitchBack } from 'store/components/auth/authActions';
import { IStore } from 'store/configureStore';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectIsCurrentUserPrismId } from 'store/entities/users/selectors';
import { useUserHeaderStyles } from './headerUserStyles';
import { selectCurrentUser, selectIsManager, selectIsUserImpersonate } from 'store/components/auth/selectors';
import { useLogoutAction } from 'shared/utils/hooks/useLogoutAction';

interface IHeaderUserMenu {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}

const HeaderUserMenu = ({ anchorEl, handleClose }: IHeaderUserMenu) => {
    const { isAuthenticated } = useSelector((state: IStore) => state.auth);
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const isManager = useSelector(selectIsManager);
    const isUserImpersonate = useSelector(selectIsUserImpersonate);
    const logout = useLogoutAction();

    const handleLogout = useCallback(() => {
        dispatch(logout());
        handleClose();
    }, [dispatch, handleClose, logout]);
    const handleImpersonateSwitchBack = useCallback(() => {
        dispatch(impersonateSwitchBack.init());
        handleClose();
    }, [dispatch, handleClose]);
    const headerClasses = useUserHeaderStyles();
    const currentUser = useSelector(selectCurrentUser);
    const prismUserId = useSelector(selectIsCurrentUserPrismId);

    return (
        <Menu
            id="header-user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            classes={{ paper: headerClasses.profileMenu }}
        >
            {isAuthenticated
                && (
                    <Box>
                        <section className={headerClasses.Title}>
                            {currentUser?.firstName} {currentUser?.lastName}
                        </section>
                        <Divider
                            classes={{ root: `${headerClasses.divider}` }}
                            orientation="horizontal"
                        />
                        {!isUserImpersonate && (
                            <MenuItem
                                component={NavLink}
                                to={routes.USER_PROFILE}
                                onClick={handleClose}>
                                User Profile
                            </MenuItem>
                        )}
                        {!isManager && !isUserImpersonate && clientId && prismUserId && (
                            <MenuItem
                                component={NavLink}
                                to={generatePath(routes.CLIENT.EMPLOYEE_PORTAL, { client_id: clientId })}
                                onClick={handleClose}
                            >
                                Employee Portal
                            </MenuItem>
                        )}
                        {isUserImpersonate && (
                            <MenuItem onClick={handleImpersonateSwitchBack} style={{ color: colors.darkBlue }}>
                                Switch Back
                            </MenuItem>
                        )}
                        {/*
                            TODO: Implement Notification Setting, bun now this inactive menu item can confuse user
                            <MenuItem>Notification Settings</MenuItem>
                        */}
                        <MenuItem onClick={handleLogout}>Log out</MenuItem>
                    </Box>
                )
            }
            {!isAuthenticated && <MenuItem><Link href={routes.AUTH.LOGIN}>Login</Link></MenuItem>}
        </Menu>
    );
};

export default HeaderUserMenu;
