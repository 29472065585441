import { Box } from '@material-ui/core';
import React, { useCallback } from 'react';
import { VirtualizedGroupedGridTable } from 'shared/components/table/VirtualizedGridTable/VirtualizedGroupedGridTable';
import { useTableStyles } from 'shared/styles/tableStyles';
import {
    ISubassignmentRow,
    ISubassignmentTablePureProps,
} from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import {
    SubassignmentTableGroupHeader,
} from 'modules/subassignmentManagement/components/SubassignmentTable/SubassignmentTableGroupHeader';

export const SubassignmentVirtualInfinityTablePure = ({
    selectedSubassignment,
    expandedGroupId,
    expandAll,
    ...props
}: ISubassignmentTablePureProps) => {
    const tableClasses = useTableStyles();

    const getRowClasses = useCallback((row: ISubassignmentRow) => {
        if (selectedSubassignment?.id === row.id) {
            return [tableClasses.highlightedRow];
        }
        return [];
    }, [tableClasses, selectedSubassignment?.id]);

    const isGroupOpen = useCallback(group => {
        if (expandAll) {
            return expandAll;
        }
        return group.id === expandedGroupId;
    }, [expandAll, expandedGroupId]);
    const getKey = useCallback(row => row.id, []);

    return (
        <Box
            display="flex"
            flexGrow={1}
            overflow="hidden"
        >
            <VirtualizedGroupedGridTable
                {...props}
                RenderGroupHeader={SubassignmentTableGroupHeader}
                getKey={getKey}
                isGroupOpen={isGroupOpen}
                headerCellClassName={tableClasses.headerLightGrayCell}
                getRowClasses={getRowClasses}
            />
        </Box>
    );
};
