import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useFormWithSectionsStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '900px',
        boxSizing: 'border-box',
        padding: theme.spacing(0, 2, 2, 2),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3, 2, 0),
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    formBlockTitle: {
        color: colors.primary,
        paddingLeft: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            position: 'block',
        },
    },
    formBlockContent: {
        paddingLeft: theme.spacing(4),
        display: 'block',
    },
}));
