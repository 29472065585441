/* eslint-disable react/display-name */
import { get } from 'lodash-es';
import { IPayrollRowData } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/common/IPayrollRowData';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { CalculationEntryManagerCell } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/common/CalculationEntryManagerCell';
import {
    IExpenseCalculationEntry,
    ITimeCalculationEntry,
} from 'modules/payrollProcessorHub/store/model';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ReceiptCellCommon } from 'shared/components/table/EntriesTable/cellsComponents/ReceiptCellCommon';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import DayOfWeekDate from 'shared/components/toolbar/dayOfWeekDate/dayOfWeekDate';
import ToolbarEntry from 'shared/components/toolbar/ToolbarEntry';
import TotalNum from 'shared/components/toolbar/totalNum/totalNum';
import { formatDecimalHoursStringAsHoursAndMinutes, transformBackendDateTimeToTime } from 'shared/models/DateTime';
import { PayRateType, PayType } from 'shared/models/PayType';
import { EntryType } from 'shared/models/sheet/Sheet';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { useFormattedPayRate } from 'shared/utils/formatters/payRate';
import { formatFiles } from 'shared/utils/formatters/timePaymentFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { NoteCell } from 'shared/components/table/EntriesTable/cells';

export interface ISheetEntryTableProp {
    type: EntryType;
    entriesByDay: IPayrollExpenseRowGroup[];
}

interface IPayrollTimeRow extends ITimeCalculationEntry, IPayrollRowData {}
interface IPayrollExpenseRow extends IExpenseCalculationEntry, IPayrollRowData {}

export type IPayrollTimeExpenseRow = IPayrollTimeRow | IPayrollExpenseRow;

export interface IPayrollExpenseRowGroup {
    entryDate: moment.Moment;
    totalHours: string;
    totalAmount: string;
    items: IPayrollTimeExpenseRow[];
}

const useIsRowsHasProperty = (rows: IPayrollTimeExpenseRow[], property: string) => {
    return useMemo(() => (
        Boolean(rows.find(row => Boolean(get(row, property))))
    ), [property, rows]);
};

const useCells = (type: EntryType, entriesByDay: IPayrollExpenseRowGroup[]) => {
    const classes = useEntriesTableStyles();

    const rows = useMemo(() => (
        entriesByDay.reduce(
            (acc, dayRows) => {
                return acc.concat(dayRows.items);
            },
            [] as IPayrollTimeExpenseRow[],
        )
    ), [entriesByDay]);

    const hasTimeInOut = useIsRowsHasProperty(rows, 'time_in');
    const hasBreak = useIsRowsHasProperty(rows, 'break_minutes');
    const hasMealBreak = useIsRowsHasProperty(rows, 'break_time_in');
    const hasJobNumber = useIsRowsHasProperty(rows, 'jobNumber');
    const hasFiles = useIsRowsHasProperty(rows, 'files');
    const hasNotes = useIsRowsHasProperty(rows, 'notes');

    const cells: ICellInfo<IPayrollTimeExpenseRow>[] = [
        {
            key: 'activity',
            title: 'activity',
            render: ({ className, activity }: IPayrollTimeExpenseRow) =>
                <PlainText className={className} value={activity?.description}/>,
        },
        {
            key: 'jobNumber',
            title: 'job #',
            render: ({ className, jobNumber }: IPayrollTimeExpenseRow) =>
                <PlainText className={className} value={jobNumber || ''}/>,
            hide: !hasJobNumber,
        },
        {
            key: 'manager',
            title: 'manager',
            render: CalculationEntryManagerCell,
        },
        {
            key: 'paycode',
            title: 'paycode',
            render: ({ className, payCode }: IPayrollTimeExpenseRow) =>
                <PlainText className={className} value={payCode?.description}/>,
        },
    ];

    if (type === EntryType.TIME) {
        cells.push(
            {
                key: 'payRate',
                title: 'pay rate',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, pay_rate, assignment }: IPayrollTimeExpenseRow) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const payRate = useFormattedPayRate({
                        pay_rate_value: Number(pay_rate),
                        pay_rate_type: assignment?.pay_rate_type || PayRateType.PER_HOUR,
                    });
                    const value = assignment?.pay_type === PayType.Salaried ? '' : payRate;
                    return (
                        <PlainText
                            className={clsx(className, classes.amountBodyCell)}
                            value={value}
                        />
                    );
                },
            },
            {
                key: 'files',
                title: 'files',
                width: '100px',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, ...props }: IPayrollTimeExpenseRow) => {
                    const { files } = props as IPayrollTimeRow;
                    return (
                        <PlainText
                            className={clsx(className, classes.amountBodyCell)}
                            value={formatFiles(files)}
                        />
                    );
                },
                hide: !hasFiles,
            },
            {
                key: 'time',
                title: 'time',
                width: '140px',
                render: ({ className, ...props }: IPayrollTimeExpenseRow) => {
                    const { time_in: timeIn, time_out: timeOut } = props as IPayrollTimeRow;
                    if (timeIn && timeOut) {
                        const startTime = transformBackendDateTimeToTime(timeIn);
                        const endTime = transformBackendDateTimeToTime(timeOut);
                        const value = `${startTime} - ${endTime}`;
                        return (
                            <PlainText className={className} value={value}/>
                        );
                    }
                    return (
                        <PlainText className={className}/>
                    );
                },
                hide: !hasTimeInOut,
            },
            {
                key: 'break',
                title: hasMealBreak ? 'meal break' : 'break',
                width: hasMealBreak ? '140px' : '100px',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, ...props }: IPayrollTimeExpenseRow) => {
                    const {
                        break_minutes: breakMinutes,
                        break_time_in: timeIn,
                        break_time_out: timeOut,
                    } = props as IPayrollTimeRow;
                    if (breakMinutes) {
                        return (
                            <PlainText
                                className={clsx(className, classes.amountBodyCell)}
                                value={formatMinutes(breakMinutes)}
                            />
                        );
                    }
                    if (timeIn && timeOut) {
                        const startTime = transformBackendDateTimeToTime(timeIn);
                        const endTime = transformBackendDateTimeToTime(timeOut);
                        const value = `${startTime} - ${endTime}`;
                        return (
                            <PlainText className={className} value={value}/>
                        );
                    }
                    return (
                        <PlainText className={className}/>
                    );
                },
                hide: !(hasBreak || hasMealBreak),
            },
            {
                key: 'hours',
                title: 'hours',
                width: '100px',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, hours }: IPayrollTimeExpenseRow) => (
                    <PlainText
                        className={clsx(className, classes.amountBodyCell)}
                        value={formatDecimalHoursStringAsHoursAndMinutes(hours)}
                    />
                ),
            },
        );
    } else {
        cells.push(
            {
                key: 'receipt',
                title: 'receipt',
                width: hasNotes ? '1fr' : '74px',
                render: ({ className, ...props }: IPayrollTimeExpenseRow) => {
                    const { sheet_entry_attachments } = props as IPayrollExpenseRow;
                    return (
                        <Box className={className}>
                            <ReceiptCellCommon files={sheet_entry_attachments || []}/>
                        </Box>
                    );
                },
            },
        );
        if (hasNotes) {
            cells.push(
                {
                    key: 'notes',
                    title: 'notes',
                    width: '74px',
                    render: ({ className, ...props }: IPayrollTimeExpenseRow) => {
                        const { notes } = props as IPayrollExpenseRow;
                        return <NoteCell notes={notes} className={className}/>;
                    },
                },
            );
        }
        cells.push(
            {
                key: 'amount',
                title: 'amount',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, amount }: IPayrollTimeExpenseRow) =>
                    <PlainText className={clsx(className, classes.amountBodyCell)} value={formatDollars(amount)}/>,
            },
        );
    }

    return cells.filter(cell => !cell.hide);
};

export const SheetEntryTable = ({
    entriesByDay,
    type,
}: ISheetEntryTableProp) => {
    const classes = useEntriesTableStyles();

    const cells = useCells(type, entriesByDay);

    return (
        <Box>
            {entriesByDay.map(group => (
                <Box key={`${type}-${group.entryDate}`}>
                    <ToolbarEntry classes={{ root: classes.toolbar }}>
                        <DayOfWeekDate date={group.entryDate}/>
                        {type === EntryType.TIME ? (
                            <TotalNum
                                value={group.totalHours}
                                label={'hrs'}
                            />
                        ) : (
                            <TotalNum value={formatDollars(group.totalAmount)}/>
                        )}
                    </ToolbarEntry>
                    <GridTable
                        getKey={row => row.sheet_entry_id || '' }
                        headerCellClassName={classes.headerCellClassName}
                        rowData={group.items}
                        cells={cells}
                        stickyHeader
                    />
                </Box>
            ))}
        </Box>
    );
};
