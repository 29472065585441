import { Theme, fade } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors, getIncreasedSidebarWidth } from 'shared/styles/constants';

export const useSheetRejectStyles = makeHighPriorityStyles((theme: Theme) => ({
    sheetInfoItem: {
        padding: theme.spacing(1),
        '& > *': {
            lineHeight: 1.2,
            '&:not(:first-child)': {
                marginLeft: theme.spacing(0.5),
                letterSpacing: -1,
            },
        },
    },
    formInput: {
        width: '100%',
    },
    buttonTextStyle: {
        textTransform: 'uppercase',
        fontSize: 14,
    },
    nameOverflowed: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buttonSave: {
        padding: theme.spacing(1, 2.5),
        height: 'fit-content',
        margin: theme.spacing(1),
        minWidth: 'auto',
        boxShadow: `${fade(colors.black, 0.14)} 0px 1px 1px 0px, ${fade(colors.black, 0.12)} 0px 2px 1px -1px, ${fade(colors.black, 0.2)} 0px 1px 3px 0px`,
        '&:hover': {
            backgroundColor: colors.green,
        },
    },
    buttonCancel: {
        boxShadow: 'none',
        textDecoration: 'underline',
        backgroundColor: 'inherit',
        padding: theme.spacing(1, 2.5),
        height: 'fit-content',
        margin: theme.spacing(1),
        color: theme.palette.primary.main,
        minWidth: 'auto',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: colors.whiteSecondary,
        },
    },
    rejectionCard: {
        boxShadow: `${fade(colors.black, 0.14)} 0px 1px 1px 0px, ${fade(colors.black, 0.12)} 0px 2px 1px -1px, ${fade(colors.black, 0.2)} 0px 1px 3px 0px`,
        backgroundColor: colors.white,
        margin: theme.spacing(2),
        padding: theme.spacing(2, 4),
        borderRadius: theme.spacing(2),
    },
    notes: {
        margin: theme.spacing(2, 0),
    },
    notesInput: {
        width: '70%',
    },
    weekendPullLeft: {
        display: 'flex',
        flexGrow: 1,
    },
    hoursPullRight: {
        float: 'right',
    },
    sheetDisplay: {
        display: 'flex',
    },
    sheetInfo: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(3),
    },
    footer: {
        padding: theme.spacing(2),
        marginTop: 'auto',
    },
    form: {
        marginBottom: theme.spacing(2),
    },
    paper: {
        ...getIncreasedSidebarWidth(theme),
    },
}));
