import { createEmployeeActionForm } from 'modules/employeeActionsForm/store/actions';
import { EafType, ICreateEaf, IEafStartDateChangeData } from 'modules/employeeActionsForm/store/models';
import { selectEmployeeProfileById } from 'modules/offerLetter/store/selectors';
import { IChangeAssignmentStartDateFormValues } from 'modules/subassignmentManagement/components/AssignmentStartDateChange/model/types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from 'shared/utils/logging/logger';
import { HeadwayError } from 'utils/errors';
import { Nullable } from '../../../../../types/types';
import { IAssignment } from 'store/entities/configuration/configurationModel';
import * as yup from 'yup';

type SubmitHandler = (values: IChangeAssignmentStartDateFormValues) => void;
const ERROR_MESSAGE_START = 'Failed to change start date of an assignment';

export function useChangeStartDateForm(assignment: Nullable<IAssignment>) {
    const dispatch = useDispatch();
    const employeeProfile = useSelector(selectEmployeeProfileById(assignment?.employee_profile?.id));

    const initialValues = useMemo<IChangeAssignmentStartDateFormValues>(() => ({
        assignmentId: assignment?.id || null,
        currentStartDate: assignment?.hire_date || null,
        prismStartDate: employeeProfile?.hire_date || null,
        newStartDate: null,
        shouldApplyToSubassignments: true,
    }), [assignment?.hire_date, assignment?.id, employeeProfile?.hire_date]);

    const onSubmit = useCallback<SubmitHandler>(values => {
        const { assignmentId, newStartDate, shouldApplyToSubassignments } = values;
        if (!assignmentId) {
            logger.error(
                new HeadwayError(`${ERROR_MESSAGE_START} – the ID of the assignments is absent`),
            );
            return;
        }
        if (!newStartDate) {
            logger.error(
                new HeadwayError(`${ERROR_MESSAGE_START} – new start date value is absent`),
            );
            return;
        }
        // @ts-ignore
        const data: IEafStartDateChangeData = {
            employee_action_form_type: EafType.StartDateChange,
            applies_to_subassignments: shouldApplyToSubassignments,
            new_start_date: newStartDate,
        };
        const payload: ICreateEaf = {
            assignment_id: assignmentId,
            employee_action_form_data: data,
        };
        dispatch(createEmployeeActionForm.init(payload));
    }, [dispatch]);

    const validationSchema = useMemo(() => yup.object().shape<IChangeAssignmentStartDateFormValues>({
        assignmentId: yup.string().nullable(),
        prismStartDate: yup.string().nullable(),
        currentStartDate: yup.string().nullable(),
        shouldApplyToSubassignments: yup.boolean(),
        newStartDate: (
            yup
                .string()
                .trim()
                .nullable()
                .required('New Start Date is required')
        ),
    }), []);

    return {
        initialValues,
        onSubmit,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: true,
    };
}
