import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { createRequestActions, RequestType } from 'store/utils';

interface IGetSheetsPayPeriodParams {
    employeeId?: string;
}
export const getSheetsPayPeriod = createRequestActions<IGetSheetsPayPeriodParams | void, IPayPeriod[]>(
    RequestType.Get,
    'SHEETS_PAY_PERIODS',
    'sheets',
);

export const getClientPayPeriod = createRequestActions<void, IPayPeriod[]>(
    RequestType.Get,
    'CLIENT_PAY_PERIODS',
    'sheets',
);
