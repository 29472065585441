import { uniq } from 'lodash-es';
import { api } from 'modules/settings/submodules/platformUsers/store/api';
import { IClientUserAssociationRequest } from 'modules/settings/submodules/platformUsers/store/models';
import { IClientRole } from 'shared/models/User';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { getUsers, loadClientRoles } from 'store/entities/users/actions';
import { usersApi } from 'store/entities/users/api';
import { UserType } from 'store/entities/users/model';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, take, takeLatest, select } from 'typed-redux-saga';
import { getClientManagersAction } from './actions';

export function* getClientManagersSaga() {
    const clientId = yield select(selectCurrentClientId);

    yield put(loadClientRoles.init({
        client_id: clientId,
    }));
    const clientRolesAction = yield take([loadClientRoles.successType, loadClientRoles.errorType]);

    if (clientRolesAction.type === loadClientRoles.errorType) {
        throw new Error('Unable to fetch client roles');
    }

    const managersRoles = clientRolesAction.payload as Array<IClientRole>;

    const request: IClientUserAssociationRequest = {
        client_id: clientId,
        client_role_ids: managersRoles.map(role => role.id),
        page_size: 1000,
        user_type: UserType.Manager,
    };
    const response = yield* call(api.getClientUserRoles, request);

    const userIds = uniq(response?.client_user_roles?.map(item => item?.user_id).filter(Boolean) || []);
    if (userIds.length) {
        const users = yield* call(usersApi.getUsersWithoutPagination, { ids: userIds.join(',') });
        yield put(getUsers.success(users));
    }
    yield put(getClientManagersAction.success(response.client_user_roles));
}

function* getClientManagersSagaWatcher() {
    yield* takeLatest(
        getClientManagersAction.initType,
        withBackendErrorHandler(
            getClientManagersSaga,
            getClientManagersAction.error,
            'Unable to get managers.',
        ),
    );
}

export default [
    getClientManagersSagaWatcher,
];
