import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { ActivityForm } from 'modules/settings/submodules/clients/activities/components/CreateActivity/ActivityForm';
import { defaultActivityValues, IActivityFormValues } from 'modules/settings/submodules/clients/activities/components/CreateActivity/model';
import { getActivityValidationsSchema } from 'modules/settings/submodules/clients/activities/components/CreateActivity/ValidationsSchema';
import { selectIsActivitiesCreating } from 'modules/settings/submodules/clients/activities/store/selectors';
import { createActivity } from 'modules/settings/submodules/clients/store/actions';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectCurrentClientHasJobNumberConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { ActivityTag, ITask } from 'store/entities/configuration/configurationModel';
import { selectTasks } from 'store/entities/configuration/configurationSelectors';
import { pick } from 'lodash-es';

const ACTIVITY_NAME_LENGTH = 15;

const CreateActivity = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const isLoading = useSelector(selectIsActivitiesCreating);
    const hasJobNumber = useSelector(selectCurrentClientHasJobNumberConfiguration);
    const tasks = useSelector(selectTasks);
    const onSubmitForm = (
        values: IActivityFormValues,
    ) => {
        const tags = Object.values(
            pick(values, ['showZipCode', 'nonProduction', 'default', 'travel']),
        ).filter(tag => tag) as ActivityTag[];
        dispatch(createActivity.init({
            client_id: clientId || '',
            sheet_type: values.type || EntryType.TIME,
            data_type: values.dataType || QuantityType.TIME,
            description: values.activity,
            short_description: values.shortDescription || undefined,
            pay_code_id: values.payCode?.id || null,
            task_id: values.task?.id,
            show_zip_code: Boolean(values.showZipCode) || false,
            tags,
        }));
    };

    const validationScheme = useMemo(() => (
        getActivityValidationsSchema(ACTIVITY_NAME_LENGTH, false)
    ), []);

    return (
        <Formik
            initialValues={{
                ...defaultActivityValues,
                task: (tasks.length === 1 ? tasks[0] : defaultActivityValues.task) as ITask,
            }}
            validationSchema={validationScheme}
            onSubmit={onSubmitForm}
            validateOnBlur={false}
            enableReinitialize
        >
            {(props: FormikProps<IActivityFormValues>) => (
                <ActivityForm
                    {...props}
                    isLoading={isLoading}
                    activityLength={ACTIVITY_NAME_LENGTH}
                    showNonProductionTag={hasJobNumber}
                />
            )}
        </Formik>
    );
};

export default CreateActivity;
