import { SortingOrder } from 'shared/models/Order';
import { IClientRole, ICreatePlatformUser, IUpdatePlatformUser, IUserInfo } from 'shared/models/User';
import { ICreateClientUserAssociation } from 'store/entities/users/model';
import { ActionsReturnTypes, createRequestActions, createSingleAction, RequestType } from 'store/utils';
import {
    IClientRoleFilter,
    IClientUserAssociationFilter,
    IClientUserAssociationRequest,
    IClientUserAssociationResponse, IClientRoleManagePayload,
    IGetMoreUsersResponse,
    IPlatformUsersFilter,
    IUpdateUserStatus, IUpdateClientRole,
} from './models';

export const SET_CREATE_USER_MODAL_STATE = 'settings/SET_CREATE_USER_MODAL_STATE';
export const setCreateUserModalState = createSingleAction<boolean, typeof SET_CREATE_USER_MODAL_STATE>(
    SET_CREATE_USER_MODAL_STATE,
);

export const createPlatformUser = createRequestActions<ICreatePlatformUser, IUserInfo>(
    RequestType.Create,
    'PLATFORM_USER',
    'settings',
);

export const SET_EDIT_PLATFORM_USER = 'settings/SET_EDIT_PLATFORM_USER';
export const setEditPlatformUser = createSingleAction<string | null, typeof SET_EDIT_PLATFORM_USER>(
    SET_EDIT_PLATFORM_USER,
);

export const updatePlatformUser = createRequestActions<IUpdatePlatformUser, IUserInfo>(
    RequestType.Update,
    'PLATFORM_USER',
    'settings',
);

export const setPlatformUserStatus = createRequestActions<IUpdateUserStatus, IUserInfo>(
    RequestType.Update,
    'PLATFORM_USER_STATUS',
    'settings',
);

export const getMoreUsersActions = createRequestActions<void, IGetMoreUsersResponse>(
    RequestType.Get,
    'MORE_PLATFORM_USER',
    'settings',
);

export const createClientUsersAssociation = createRequestActions<ICreateClientUserAssociation, any>(
    RequestType.Create,
    'CLIENT_USER_ASSOCIATION',
    'settings',
);

export const getClientUsersAssociation = createRequestActions<
void | IClientUserAssociationRequest,
IClientUserAssociationResponse
>(
    RequestType.Get,
    'CLIENT_USER_ASSOCIATION',
    'settings',
);

export const getMoreClientUsersAssociation = createRequestActions<void, IClientUserAssociationResponse>(
    RequestType.Get,
    'MORE_CLIENT_USER_ASSOCIATION',
    'settings',
);

export const CLEAR_USERS_AND_FILTER = 'settings/platformUsers/CLEAR_USERS_AND_FILTER';
export const clearUsersAndFilter = createSingleAction<void, typeof CLEAR_USERS_AND_FILTER>(
    CLEAR_USERS_AND_FILTER,
);

export const SET_PLATFORM_USERS_FILTER = 'settings/platformUsers/SET_PLATFORM_USERS_FILTER';
export const setPlatformUsersFilter = createSingleAction<IPlatformUsersFilter, typeof SET_PLATFORM_USERS_FILTER>(
    SET_PLATFORM_USERS_FILTER,
);

export const removeClientUserAssociation = createRequestActions<string, string>(
    RequestType.Delete,
    'CLIENT_USER_ASSOCIATION',
    'settings',
);

export const SET_CLIENT_USER_ASSOCIATION_FILTER = 'settings/SET_CLIENT_USER_ASSOCIATION_FILTER';
export const setClientUserAssociationFilter = createSingleAction<
IClientUserAssociationFilter, typeof SET_CLIENT_USER_ASSOCIATION_FILTER
>(
    SET_CLIENT_USER_ASSOCIATION_FILTER,
);

export const SET_CREATE_USER_ASSOCIATION_MODAL_STATE = 'settings/SET_CREATE_USER_ASSOCIATION_MODAL_STATE';
export const setCreateUserAssociationModalState = createSingleAction<
boolean,
typeof SET_CREATE_USER_ASSOCIATION_MODAL_STATE
>(
    SET_CREATE_USER_ASSOCIATION_MODAL_STATE,
);

export const SET_CLIENT_ROLES_FILTER = 'settings/SET_CLIENT_ROLES_FILTER';
export const setClientRolesFilter = createSingleAction<
IClientRoleFilter, typeof SET_CLIENT_ROLES_FILTER
>(
    SET_CLIENT_ROLES_FILTER,
);
export const SET_CLIENT_ROLES_SORT = 'settings/SET_SET_CLIENT_ROLES_SORT';
export const setClientRoleSort = createSingleAction<
SortingOrder, typeof SET_CLIENT_ROLES_SORT
>(
    SET_CLIENT_ROLES_SORT,
);

const SET_CREATE_CLIENT_ROLE_MODAL_STATE = 'settings/SET_CREATE_CLIENT_ROLE_MODAL_STATE';
export const setCreateClientRoleModalState = createSingleAction<boolean, typeof SET_CREATE_CLIENT_ROLE_MODAL_STATE>(
    SET_CREATE_CLIENT_ROLE_MODAL_STATE,
);

export const createClientRole = createRequestActions<IClientRoleManagePayload, IClientRole>(
    RequestType.Create,
    'CLIENT_ROLE',
    'settings',
);

const SET_EDIT_PLATFORM_CLIENT_ROLE = 'settings/SET_EDIT_CLIENT_ROLE';
export const setEditClientRole = createSingleAction<string | null, typeof SET_EDIT_PLATFORM_CLIENT_ROLE>(
    SET_EDIT_PLATFORM_CLIENT_ROLE,
);

export const updateClientRole = createRequestActions<IUpdateClientRole, IClientRole>(
    RequestType.Update,
    'CLIENT_ROLE',
    'settings',
);

export const deleteClientRole = createRequestActions<string, string>(
    RequestType.Delete,
    'CLIENT_ROLE',
    'settings',
);

export type PlatformUsersActions =
    | ActionsReturnTypes<typeof getMoreUsersActions>
    | ActionsReturnTypes<typeof getMoreClientUsersAssociation>
    | ActionsReturnTypes<typeof getClientUsersAssociation>
    | ActionsReturnTypes<typeof removeClientUserAssociation>
    | ActionsReturnTypes<typeof updatePlatformUser>
    | ActionsReturnTypes<typeof setPlatformUserStatus>
    | ReturnType<typeof clearUsersAndFilter>
    | ReturnType<typeof setClientUserAssociationFilter>
    | ReturnType<typeof setCreateUserAssociationModalState>
    | ReturnType<typeof setCreateUserModalState>;
