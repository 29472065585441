import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateOfferLetterTemplateModalState } from 'modules/settings/submodules/offerLetters/store/action';
import { selectOfferLetterTemplateIdForEdit } from 'modules/settings/submodules/offerLetters/store/selectors';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { EditOfferLetterModalBody } from './EditOfferLetterModalBody';

export const EditOfferLetterModal: React.FC = () => {
    const dispatch = useDispatch();
    const offerLetterTemplateId: string | null = useSelector(selectOfferLetterTemplateIdForEdit);
    const hasId = Boolean(offerLetterTemplateId);

    const handleClose = useCallback(() => {
        dispatch(setUpdateOfferLetterTemplateModalState(null));
    }, [dispatch]);

    const modalProps = useMemo<Partial<IModalProps>>(() => ({
        showCloseIcon: true,
        keepMounted: true,
    }), []);

    return (
        <ModalDialog
            isOpened={hasId}
            title="Edit offer letter template"
            onClose={handleClose}
            modalProps={modalProps}
            colored
        >
            <EditOfferLetterModalBody
                offerLetterTemplateId={offerLetterTemplateId}
                closeDialog={handleClose}
            />
        </ModalDialog>
    );
};
