import React, { useEffect, useMemo } from 'react';
import { BulkCreateSubAssignmentModalV2 } from 'modules/subassignmentManagement/components/CreateSubAssignment/BulkCreateSubAssignmentModalV2';
import { Box, Divider } from '@material-ui/core';
import { getEafReasons } from 'modules/employeeActionsForm/store/actions';
import { selectEditSubassignmentId } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/selectors';
import { SubassignmentTableGateway } from 'modules/subassignmentManagement/components/SubassignmentTable/SubassignmentTableGateway';
import { resetSubassignmentsTable } from 'modules/subassignmentManagement/store/actions';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { AssignmentInfoSidebar } from 'modules/subassignmentManagement/components/AssignmentInfo/AssignmentInfoSidebar';
import { SubassignmentFilter } from 'modules/subassignmentManagement/components/SubassignmentFilter/SubassignmentFilter';
import { selectDetailAssignmentId } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceContainer } from 'shared/components/workspaces/WorkspaceContainer/WorkspaceContainer';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { getPaySettings } from 'store/entities/clients/clientsAction';
import { selectClientHideSubassignments } from 'store/entities/clients/selectors/configurationSelectors';
import { getCustomFieldsHierarchyNodes } from 'store/entities/customFields/actions';
import { getUserCustomFieldRestrictions } from 'store/entities/scopes/actions';
import { BulkEndAssignmentModal } from 'modules/subassignmentManagement/components/BulkEndAssignment/EndAssignmentModal';
import { BulkEditAssignmentModal } from 'modules/subassignmentManagement/components/EditSubAssignment/BulkEditAssignmentModal';
import { ScopeType } from 'store/entities/scopes/models';
import { BulkEditSubAssignmentModal } from 'modules/subassignmentManagement/components/EditSubAssignment/BulkEditSubAssignmentModal';
import { SubassignmentTableActions } from 'modules/subassignmentManagement/components/SubassignmentTableActions/SubassignmentTableActions';
import { EditSingleSubassignmentSidebar } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/EditSingleSubassignmentSidebar';
import { BulkCreateSubassignmentUploadFile } from 'modules/subassignmentManagement/components/CreateSubAssignment/BulkCreateSubassignmentUploadFile';
import { getUsers } from 'store/entities/users/actions';
import { UserType } from 'store/entities/users/model';

const mainAreaContainerId = 'mainAreaContainerId';

export const AssignmentManagementPage = () => {
    useTitleUpdate('Assignment Management');
    useWithClientEffect((dispatch, clientId) => {
        dispatch(getCustomFieldsHierarchyNodes.init());
        dispatch(getEafReasons.init());
        dispatch(getUserCustomFieldRestrictions.init({
            scope_types: [ScopeType.SubassignmentManagement],
        }));
        // for detail pages
        dispatch(getUsers.init({
            user_type: UserType.Manager,
            client_id: clientId,
        }));
        if (clientId) {
            dispatch(getPaySettings.init(clientId));
        }
    }, []);
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(resetSubassignmentsTable());
        };
    }, [dispatch]);
    const assignmentId = useSelector(selectDetailAssignmentId);
    const subassignmentId = useSelector(selectEditSubassignmentId);
    const clientHideSubassignments = useSelector(selectClientHideSubassignments);

    const additionalWorkspace = useMemo(() => {
        if (subassignmentId) {
            return <EditSingleSubassignmentSidebar/>;
        }
        if (assignmentId) {
            return <AssignmentInfoSidebar/>;
        }
        return null;
    }, [assignmentId, subassignmentId]);

    return (
        <>
            <Box m={4}>
                <SubassignmentFilter/>
            </Box>
            <Divider/>

            <Box
                m={4}
                mt={5}
                display="flex"
                flexDirection="column"
                flexGrow={1}
            >
                <SubassignmentTableActions/>
                <WorkspaceContainer
                    additionalWorkspace={additionalWorkspace}
                    mainAreaContainerId={mainAreaContainerId}
                >
                    <SubassignmentTableGateway containerId={mainAreaContainerId}/>
                </WorkspaceContainer>
            </Box>
            {clientHideSubassignments ? <BulkEditAssignmentModal/> : <BulkEditSubAssignmentModal/>}
            <BulkCreateSubAssignmentModalV2/>
            <BulkCreateSubassignmentUploadFile/>
            <BulkEndAssignmentModal/>
        </>
    );
};
