import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';
import {
    selectActiveControllingOrgs, selectControllingOrgsIsLoading,
} from 'store/entities/configuration/configurationSelectors';
import { IControllingOrg } from 'shared/models/ControllingOrg';

interface IControllingOrgSelectProps extends IFormFieldProps, IFormSelect {
}

export const ControllingOrgSelect = (props: IControllingOrgSelectProps) => {
    const controllingOrgs = useSelector(selectActiveControllingOrgs);
    const isControllingOrgsLoading = useSelector(selectControllingOrgsIsLoading);
    return (
        <FormSelect
            getKey={(controllingOrg: IControllingOrg) => controllingOrg?.id || ''}
            getText={(controllingOrg: IControllingOrg) => controllingOrg?.name || ''}
            options={controllingOrgs}
            useIdValue
            isLoading={isControllingOrgsLoading}
            {...props}
        />
    );
};
