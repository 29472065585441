import { selectCurrentPayPeriod } from 'modules/timeAndExpense/components/WorkingPage/store/selectors';
import React from 'react';
import moment from 'moment';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import {
    useGetDisplaySheetStatus,
} from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/useGetDisplaySheetStatus';
import { useSelector } from 'react-redux';
import SubmitStatus from 'shared/components/submitStatus/SubmitStatus';
import { DATE_FORMAT } from 'shared/models/Dates';

export const WeeklySheetsStatus = () => {
    const classes = useSheetsInProgressStyles();
    const payPeriod = useSelector(selectCurrentPayPeriod);
    const displaySheetStatus = useGetDisplaySheetStatus();
    const submitStatusDue = moment(payPeriod?.period_end).add(1, 'day')
        .format(DATE_FORMAT.MM_DD_YYYY);

    return (
        <SubmitStatus
            customClasses={classes.statusWrapper}
            dueDate={submitStatusDue}
            status={displaySheetStatus}
        />
    );
};
