import { useCallback, useState } from 'react';
import { EntryType } from 'shared/models/sheet/Sheet';

export function useEntryTypeTabs(hasTimeSheet: boolean) {
    const initialTab = hasTimeSheet ? EntryType.TIME : EntryType.EXPENSE;
    const [activeTab, setActiveTab] = useState<EntryType>(initialTab);

    const switchToTimeTab = useCallback(() => {
        setActiveTab(EntryType.TIME);
    }, []);

    const switchToExpenseTab = useCallback(() => {
        setActiveTab(EntryType.EXPENSE);
    }, []);

    return {
        activeTab,
        switchToTimeTab,
        switchToExpenseTab,
    };
}
