import { Button, Box } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(5, 3, 5),
    },
    green: {
        '&:hover': {
            backgroundColor: colors.green,
        },
    },
    button: {
        padding: theme.spacing(.5, 3, .5),
    },
}));

export interface IOfferLetterFormNextButtonProps extends React.HTMLProps<HTMLButtonElement> {

}

export function OfferLetterFormNextButton({
    disabled,
    onClick,
    className,
}: IOfferLetterFormNextButtonProps) {
    const classes = useStyles();
    return (
        <Box className={clsx(className, classes.root)}>
            <Button
                onClick={onClick}
                type="button"
                variant="contained"
                color="primary"
                disabled={disabled}
                data-testid="offer-submit-next-button"
                className={clsx(classes.button, classes.green)}
            >
                Next
            </Button>
        </Box>
    );
}
