import { IDeal } from 'shared/models/Deal';
import { useIsOnlyDefaultActivities } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/store/useIsOnlyDefaultActivities';
import { useSelector } from 'react-redux';
import { selectDefaultTimeActivities, selectTimeActivities } from 'store/entities/configuration/configurationSelectors';

export const useAdditionalValidityErrorText = (deal?: IDeal | null) => {
    const onlyDefaultActivities = useIsOnlyDefaultActivities(deal);
    const defaultTimeActivities = useSelector(selectDefaultTimeActivities);
    const timeActivities = useSelector(selectTimeActivities);
    if (onlyDefaultActivities){
        if (defaultTimeActivities.length < 1){
            return 'Please add default activity before proceeding';
        }
    } else {
        if (timeActivities.length < 1){
            return 'Please add activities before proceeding';
        }
    }
    return '';
};
