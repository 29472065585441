import { useEffect, useState } from 'react';
import { stringifyEquality } from 'store/utils/selectors/stringifyEquality';

export function useStoredValue<T>(value: T): T {
    const [state, setState] = useState<T>(value);
    useEffect(() => {
        if (!stringifyEquality(state, value)) {
            setState(value);
        }
    }, [state, value]);
    return state;
}
