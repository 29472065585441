import { dealsInfinityTableSelectors } from 'modules/settings/submodules/clients/deals/components/DealsTable/store/selectors';
import { setDealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/store/actions';
import { selectDealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/store/selector';
import { getDeals } from 'store/entities/configuration/configurationAction';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getMoreDealsAction, initialLoadDealsPage } from 'modules/settings/submodules/clients/deals/store/actions';
import { configurationApi } from 'store/entities/configuration/configurationApi';
import { IDealRequest } from 'store/entities/configuration/configurationModel';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';

export function* getMoreDealsSaga() {
    const range = yield* select(dealsInfinityTableSelectors.selectNextRange);
    const filter = yield select(selectDealFilter);

    const request: IDealRequest = {
        ...filter,
        range: range,
    };
    const response = yield* call(configurationApi.getDeals, request);

    yield put(getDeals.success(response.deals));
    yield put(getMoreDealsAction.success(response));
}

export function* getMoreDealsSagaWatcher() {
    yield* takeLatest(
        [
            getMoreDealsAction.initType,
            initialLoadDealsPage.action,
            setDealFilter.action,
        ],
        withBackendErrorHandler(
            getMoreDealsSaga,
            getMoreDealsAction.error,
            'Unable to get deals.',
        ),
    );
}
