import React, { useMemo } from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { EntryType, QuantityType, QuantityTypeLabelMap } from 'shared/models/sheet/Sheet';
import { useSelector } from 'react-redux';
import { selectIsLoadingClientConfiguration } from 'store/entities/clients/selectors/clientsSelectors';

interface IDataTypeSelectProps extends IFormFieldProps, IFormSelect {
    entryType: EntryType;
}

export default function DataTypeSelect({
    entryType,
    ...props
}: IDataTypeSelectProps) {
    const dataTypes = useMemo(() => {
        if (entryType === EntryType.TIME) {
            return [
                QuantityType.TIME,
                QuantityType.TIME_BREAK,
                QuantityType.TIME_IN_OUT,
                QuantityType.TIME_IN_OUT_BREAK,
                QuantityType.TIME_IN_OUT_MEAL_BREAK,
            ];
        }

        return [
            QuantityType.MONEY,
            QuantityType.MILES,
            QuantityType.ODOMETER,
        ];
    }, [entryType]);
    const isDataTypesLoading = useSelector(selectIsLoadingClientConfiguration);

    return (
        <FormSelect
            {...props}
            getKey={(type: QuantityType) => type}
            getText={(type: QuantityType) => QuantityTypeLabelMap[type]}
            options={dataTypes}
            isLoading={isDataTypesLoading}
        />
    );
}
