import React, { useCallback, useState } from 'react';
import { ButtonProps, IconButton, IconButtonProps } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '@material-ui/icons';
import { AnyAction } from 'redux';
import ConfirmationDialog, { IConfirmationDialogCustomClasses } from 'shared/components/modals/ConfirmationDialog';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export interface IDeleteItemButtonProps {
    confirmation: string;
    deleteAction: (payload: string) => AnyAction;
    id: string;
    disabled?: boolean;
    confirmationTitle?: string;
    permission?: Permission;
    confirmationCustomClasses?: IConfirmationDialogCustomClasses;
    DeleteButtonComponent?: (props: ButtonProps) => JSX.Element;
}

const DeleteButton = (props: ButtonProps) => {
    return (
        <IconButton
            {...props as IconButtonProps}
        >
            <Delete fontSize="small"/>
        </IconButton>
    );
};

export const DeleteItemButton = ({
    confirmation,
    deleteAction,
    id,
    disabled,
    permission,
    confirmationTitle,
    confirmationCustomClasses,
    DeleteButtonComponent = DeleteButton,
}: IDeleteItemButtonProps) => {
    const dispatch = useDispatch();
    const [isOpenConfirmation, setOpenConfirmation] = useState(false);
    const userHasPermission = useSelector(selectIsUserHasPermission(permission));

    const onCancel = useCallback(() => {
        setOpenConfirmation(false);
    }, [setOpenConfirmation]);
    const onConfirm = useCallback(() => {
        dispatch(deleteAction(id));
        onCancel();
    }, [dispatch, deleteAction, id, onCancel]);

    if (permission && !userHasPermission) {
        return null;
    }

    return (
        <>
            <DeleteButtonComponent
                onClick={() => setOpenConfirmation(true)}
                color="secondary"
                data-testid="delete-button"
                disabled={disabled}
            />
            <ConfirmationDialog
                open={isOpenConfirmation}
                onConfirm={onConfirm}
                onCancel={onCancel}
                customClasses={confirmationCustomClasses}
                dialogTitle={confirmationTitle}
                confirmText="Delete"
            >
                {confirmation}
            </ConfirmationDialog>
        </>
    );
};
