import { downloadFileSaga } from 'store/utils/sagas/downloadFileSaga';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { ccpApi } from 'modules/ccp/components/api/ccpApi';
import {
    CcpStatusIdMap, CcpStatusSlug, ICcpInvoicesRequest,
    ICcpReminderRequest,
    ICcpTransactionRequest,
    invoicedCcpSlug,
} from 'modules/ccp/models/ccpModel';
import {
    getCcpInvoices,
    getCcpTransactions, getCsvInvoicedItem,
    getCsvReconciled,
    getCsvUnreconciled,
    getMoreCcpInvoicesAction,
    getMoreCcpTransactionsAction,
    initialLoadCcpTransactionsPage, sendReminder, setCcpEditMode, setCcpFilter,
    setCcpTransactionsActiveTab, submitReconciledInvoiceWithCsvResult,
    triggerLoadCcpTransactionsPage,
    updateCcpAvailableUserIdsList,
} from 'modules/ccp/store/actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectCcpTransactionsActiveTab } from '../../../store/selectors';
import { ccpTranInfinityTableSelectors, selectCcpFilter } from './selectors';
import { selectUsers } from 'store/entities/users/selectors';
import { IUserInfo } from 'shared/models/User';
import { getUsers } from 'store/entities/users/actions';
import {
    initialLoadCcpInvoicesPage,
    setOpenCcpSubmitInvoicesModal,
    triggerLoadCcpInvoicesPage,
} from '../../CcpInvoices/store/actions';
import { ccpInvoiceInfinityTableSelectors } from '../../CcpInvoices/store/selectors';
import { getDownloadFileByRequestSagaWatcher } from 'store/utils/sagas/getDownloadFileByRequestSagaWatcher';
import moment from 'moment';
import { backendDateFormat } from 'shared/models/Dates';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { getUsersMatchingScopes } from 'store/entities/scopes/actions';
import {
    selectClientCreditCardPortalPageRegionalDirectorLimitFields,
} from '../../../../../store/entities/clients/selectors/configurationSelectors';

const fileTimestampFormat = `${backendDateFormat}-HH.mm.ss`;

export function* getMoreCcpInvoicesSaga() {
    const clientId = yield* select(selectCurrentClientId);
    const items = yield* select(ccpInvoiceInfinityTableSelectors.selectItems);
    const filter = yield* select(selectCcpFilter);
    const skip = items.length;

    const request: ICcpInvoicesRequest = {
        client_id: clientId,
        skip: skip,
        submitted_date: filter.submitted_date,
    };
    const response = yield* call(ccpApi.getCcpInvoices, request);

    yield put(getCcpInvoices.success(response.items));
    yield put(getMoreCcpInvoicesAction.success(response));
}

export function* getMoreCcpTransactionsSaga() {
    const clientId = yield* select(selectCurrentClientId);
    const tabSlug = yield* select(selectCcpTransactionsActiveTab);
    const transactionStatusId = CcpStatusIdMap[tabSlug];
    const items = yield* select(ccpTranInfinityTableSelectors.selectItems);
    const filter = yield* select(selectCcpFilter);
    const skip = items.length;

    const request: ICcpTransactionRequest = {
        client_id: clientId,
        transaction_status_id: transactionStatusId,
        skip: skip,
        user_id: filter.user_id,
        submitted_date: tabSlug === CcpStatusSlug.Reconciled ? filter.submitted_date : null,
        department_id: filter.department_id,
        regional_manager_id: filter.regional_manager_id,
    };
    const response = yield* call(ccpApi.getCcpTransactions, request);

    const usersCurrent = yield* select(selectUsers);
    const receivedUserIds = response.user_ids;
    const missingUserDataRaw = receivedUserIds.filter((x: string) => {
        return !usersCurrent.find((u: IUserInfo) => u.id === x);
    });
    const missingUserData = [...new Set(missingUserDataRaw)];
    if (missingUserData.length > 0) {
        yield put(getUsers.init({ ids: missingUserData.join(',') }));
    }

    const fieldListForLimiting = yield* select(selectClientCreditCardPortalPageRegionalDirectorLimitFields);
    yield put(getUsersMatchingScopes.init({
        client_id: clientId,
        user_ids: receivedUserIds,
        limit_cf_names: fieldListForLimiting,
    }));

    yield put(getCcpTransactions.success(
        response.items.map(item => {
            return {
                ...item,
                is_dirty: false,
                is_unsaved_split: false,
            };
        }),
    ));
    yield put(updateCcpAvailableUserIdsList(response.user_ids));
    yield put(getMoreCcpTransactionsAction.success(response));
}

export function* sendReminderSaga() {
    const clientId = yield* select(selectCurrentClientId);
    const filter = yield* select(selectCcpFilter);
    if (!(clientId && filter.user_id)) {
        return;
    }
    const request: ICcpReminderRequest = {
        client_id: clientId,
        user_id: filter.user_id,
    };
    const response = yield* call(ccpApi.sendReminder, request);
    if (response === 201) {
        yield put(setGlobalToast({
            severity: IModalSeverity.Success,
            title: 'Reminder was successfully sent.',
            autoHideDuration: 5000,
        }));
        yield put(sendReminder.success());
    } else {
        yield put(sendReminder.error());
    }
}

export function* tabChangeSaga() {
    const tabSlug = yield* select(selectCcpTransactionsActiveTab);
    yield put(setCcpEditMode(false));
    if (tabSlug === invoicedCcpSlug) {
        yield put(triggerLoadCcpInvoicesPage());
    } else {
        yield put(triggerLoadCcpTransactionsPage());
    }
}

export function* getMoreCcpInvoicesSagaWatcher() {
    yield* takeLatest([
        getMoreCcpInvoicesAction.initType,
        initialLoadCcpInvoicesPage.action,
        triggerLoadCcpInvoicesPage.action,
    ],
    withBackendErrorHandler(
        getMoreCcpInvoicesSaga,
        getMoreCcpInvoicesAction.error,
        'Unable to get Transactions.',
    ),
    );
}

export function* sendReminderSagaWatcher() {
    yield takeLatest(
        sendReminder.initType,
        withBackendErrorHandler(
            sendReminderSaga,
            sendReminder.error,
            `Unable to send reminder`,
        ),
    );
}

export function* getMoreCcpTransactionsSagaWatcher() {
    yield* takeLatest([
        getMoreCcpTransactionsAction.initType,
        initialLoadCcpTransactionsPage.action,
        triggerLoadCcpTransactionsPage.action,
    ],
    withBackendErrorHandler(
        getMoreCcpTransactionsSaga,
        getMoreCcpTransactionsAction.error,
        'Unable to get Transactions.',
    ),
    );
}

export function* switchTabCcpTransactionsSagaWatcher() {
    yield takeEvery(setCcpTransactionsActiveTab.action, tabChangeSaga);
}

export function* filterChangeSaga() {
    const tabSlug = yield* select(selectCcpTransactionsActiveTab);
    if (tabSlug === invoicedCcpSlug) {
        yield put(initialLoadCcpInvoicesPage());
    } else {
        yield put(initialLoadCcpTransactionsPage());
    }
}

export function* filterChangeCcpTransactionsSagaWatcher() {
    yield takeEvery(setCcpFilter.action, filterChangeSaga);
}

export const downloadReconciledCcpWatcher = getDownloadFileByRequestSagaWatcher(
    getCsvReconciled,
    ccpApi.downloadReconciledCcpReportCsv,
    () => {
        return `Reconciled Report ${moment().format(fileTimestampFormat)}.csv`;
    },
);

export const downloadUnreconciledCcpWatcher = getDownloadFileByRequestSagaWatcher(
    getCsvUnreconciled,
    ccpApi.downloadUnreconciledCcpReportCsv,
    () => {
        return `Unreconciled Report ${moment().format(fileTimestampFormat)}.csv`;
    },
);

export const downloadInvoicedCcpWatcher = getDownloadFileByRequestSagaWatcher(
    getCsvInvoicedItem,
    ccpApi.downloadInvoicedCcpReportCsv,
    () => {
        return `CCP Invoice ${moment().format(fileTimestampFormat)}.csv`;
    },
);

function* submitCcpInvoiceItemsSaga({ payload }: ReturnType<typeof submitReconciledInvoiceWithCsvResult.init>) {
    const filename = `CCP Invoiced Transactions ${moment().format(fileTimestampFormat)}.csv`;
    const result = yield call(ccpApi.submitReconciledTransactions, payload || {});
    yield call(downloadFileSaga, result, filename);
    yield put(submitReconciledInvoiceWithCsvResult.success());
    yield put(setOpenCcpSubmitInvoicesModal(false));
    yield put(initialLoadCcpTransactionsPage());
}

export function* submitInvoiceWithCsvDownloadWatcher() {
    yield takeLatest(
        submitReconciledInvoiceWithCsvResult.initType,
        withBackendErrorHandler(
            submitCcpInvoiceItemsSaga,
            submitReconciledInvoiceWithCsvResult.error,
            `Unable to submit transactions invoice`,
        ),
    );
}
