import React, { ChangeEvent, useCallback } from 'react';
import { capitalize } from 'lodash-es';
import { FormControl, Select } from '@material-ui/core';
import { useCellSelectStyles } from 'modules/settings/submodules/components/sharedStyles/tableCellStyles';
import { Status } from 'shared/models/Status';

interface IUpdateStatus {
    status?: Status;
    handleStatusChange: (status: Status) => void;
    isLoading?: boolean;
    formControlClassName?: string;
    label?: string;
    selectClasses?: Record<'select', string>;
}

export const UpdateStatus = (
    {
        status, handleStatusChange, isLoading = false,
        formControlClassName, selectClasses, label,
    }: IUpdateStatus) => {
    const defaultClasses = useCellSelectStyles();
    const classes = selectClasses || defaultClasses;
    const values = Object.keys(Status).map(item => ({
        id: item,
        description: capitalize(item),
    }));
    const onChange = useCallback(({ target }: ChangeEvent<{value: any}>) => {
        handleStatusChange(target.value);
    }, [handleStatusChange]);
    return (
        <FormControl
            variant="outlined"
            size="small"
            className={formControlClassName}
        >
            <Select
                classes={classes}
                native
                onChange={onChange}
                value={status}
                disabled={isLoading}
            >
                {label && (
                    <option value="">
                        {label}
                    </option>
                )}
                {values.map(optionValue => (
                    <option key={optionValue.id} value={optionValue.id}>
                        {optionValue.description}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};
