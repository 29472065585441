import React from 'react';
import FilterChip from 'shared/components/filters/FilterChip';
import { get } from 'lodash-es';

interface IFilterChipProps<NameType, ValueType> {
    values: Record<string, ValueType>;
    name: NameType;
    id?: string;
    onDelete: (key: NameType) => void;
    getText: (value: ValueType) => string;
    classes?: Record<'root' | 'deleteIcon', string>;
}

export default function EntityFilterChip<NameType, ValueType>({
    values, id, onDelete, name, getText, classes,
}: IFilterChipProps<NameType, ValueType>) {
    // @ts-ignore
    const value = get(values, id);
    return value ? (
        <FilterChip
            name={name}
            label={getText(value)}
            onDelete={onDelete}
            classes={classes}
        />
    ) : null;
}
