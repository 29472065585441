import React from 'react';
import { useSelector } from 'react-redux';
import { Theme, Typography } from '@material-ui/core';
import { useActivePayrollSheetStatus } from 'modules/payrollProcessorHub/store/helpers';
import { selectGroupedSheetsTotalCount } from 'modules/payrollProcessorHub/store/selectors';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    title: {
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2),
        },
    },
}));

export const SheetTabTitle = () => {
    const classes = useStyles();
    const activeStatus = useActivePayrollSheetStatus();
    const totalCounts = useSelector(selectGroupedSheetsTotalCount);

    return (
        <Typography variant="h4" className={classes.title}>{activeStatus} ({totalCounts})</Typography>
    );
};
