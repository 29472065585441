import React, { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import PrintSVG from 'shared/components/icons/PrintSVG';
import useActionButtonStyles from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';

interface IPrintButtonProps extends ButtonProps {
    customClasses?: string;
    onClick?: () => void;
    disabled?: boolean;
}

export const PrintButton: FC<IPrintButtonProps> = (props: IPrintButtonProps) => {
    const classes = useActionButtonStyles();
    return (
        <Button
            {...props}
            classes={{ root: classes.actionButton }}
        >
            <PrintSVG/>
        </Button>
    );
};
