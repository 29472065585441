import ManagerSheetDetailPage from 'modules/clients/content/timeApproval/ManagerSheetDetailPage';
import { useSheetApprovalTabs } from 'modules/clients/header/useSheetApprovalTabs';
import { EditSheetPage } from 'modules/sheetApproval/components/EditSheet/EditSheetPage';
import { SheetApproval } from 'modules/sheetApproval/components/SheetApproval/SheetApproval';
import { selectEditSheetGroup } from 'modules/sheetApproval/store/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { EntryType } from 'shared/models/sheet/Sheet';
import { routes } from 'shared/routes';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { getActivities } from 'store/entities/configuration/configurationAction';

export default function ExpenseApproval() {
    useTitleUpdate('Expense Approval', 'Expense Approval');
    useSheetApprovalTabs(EntryType.EXPENSE);

    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    useEffect(() => {
        if (clientId) {
            dispatch(getActivities.init());
        }
    }, [dispatch, clientId]);

    const editSheet = useSelector(selectEditSheetGroup);
    if (editSheet) {
        return (<EditSheetPage/>);
    }

    return (
        <>
            <SheetApproval sheetType={EntryType.EXPENSE}/>
            <Route path={routes.CLIENT.EXPENSE_APPROVAL.SHEET_DETAIL}>
                <ManagerSheetDetailPage entryType={EntryType.EXPENSE}/>
            </Route>
        </>
    );
}
