import React, { useCallback } from 'react';
import { SelectClientWithConfirmation } from 'modules/settings/submodules/employees/components/EditEmployee/SelectClientWithConfirmation';
import { syncEmployeeProfilePrism } from 'modules/settings/submodules/employees/store/actions';
import { selectEditEmployeeClientsIds, selectEditEmployeeId, selectIsEmployeeSyncing } from 'modules/settings/submodules/employees/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getUserName } from 'shared/utils/converters/user';
import { useModal } from 'shared/utils/hooks/useModal';
import { selectUserById } from 'store/entities/users/selectors';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';

const confirmationText = `
To continue, please select one of their clients below.
This is for syncing purposes only, and it will affect all of their clients regardless.
`;

export const SyncEmployeePrismProfileButton = () => {
    const dispatch = useDispatch();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();

    const userId = useSelector(selectEditEmployeeId);
    const user = useSelector(selectUserById(userId));
    const isLoading = useSelector(selectIsEmployeeSyncing);
    const clientIds = useSelector(selectEditEmployeeClientsIds);

    const handleSubmit = useCallback(clientId => {
        dispatch(syncEmployeeProfilePrism.init({
            userId: user?.id,
            data: {
                prism_employee_id: user?.prism_employee_id,
                client_id: clientId,
            },
        }));
        onModalClose();
    }, [dispatch, onModalClose, user]);

    const confirmationTitle = `Are you sure you want to sync ${getUserName(user)}'s Headway Connect profile to Prism?`;

    return (
        <>
            <ButtonWithLoader
                variant="contained"
                color="secondary"
                onClick={onModalOpen}
                disabled={clientIds.length === 0 || !user?.prism_employee_id}
                isLoading={isLoading}
            >
                Sync Prism Profile
            </ButtonWithLoader>
            <SelectClientWithConfirmation
                open={isModalOpened}
                onClose={onModalClose}
                onSubmit={handleSubmit}
                modalTitle="Sync Prism Profile"
                confirmationTitle={confirmationTitle}
                confirmationText={confirmationText}
                allowedClientIds={clientIds}
            />
        </>
    );
};
