import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { Typography, FormHelperText, CircularProgress, Box } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { AttachmentsPreview } from 'shared/components/attachments/AttachmentsPreview';
import { IAttachment } from 'shared/models/Attachments';
import { useUploadStyles } from './styles';

export interface IFileInputProps {
    text?: string;
    note?: string;
    rejectMessage?: string | null;
    fileTypes?: string;
    error?: string | null;
    maxSizeMb: number;
    customClasses?: string;
    attachments: IAttachment[];
    onAttachmentChange: (files: File[]) => void;
    onAttachmentRemove: (file: IAttachment) => void;
    multiple?: boolean;
    isLoading?: boolean,
    showModalFromCount?: number;
}

export default function FileInput({
    text = 'Upload',
    note = '',
    rejectMessage = 'File type is not allowed.',
    fileTypes,
    error,
    maxSizeMb,
    multiple = true,
    customClasses,
    attachments = [],
    onAttachmentChange,
    onAttachmentRemove,
    isLoading,
    showModalFromCount,
}: IFileInputProps) {
    const classes = useUploadStyles();
    const maxSize = maxSizeMb * 1024 * 1024;

    const onDrop = useCallback(acceptedFiles => {
        onAttachmentChange(acceptedFiles);
    }, [onAttachmentChange]);

    const {
        getRootProps, getInputProps, isDragReject, rejectedFiles,
    } = useDropzone({
        accept: fileTypes,
        onDrop,
        minSize: 0,
        maxSize,
        multiple,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles.some(file => file.size > maxSize);
    const canUploadMore = multiple || attachments.length === 0;

    return (
        <>
            <AttachmentsPreview
                attachments={attachments}
                onAttachmentRemove={onAttachmentRemove}
                showModalFromCount={
                    (showModalFromCount === undefined ? 1 : showModalFromCount)
                }
            />

            {canUploadMore && (
                <section className={clsx(classes.fileUpload, customClasses)}>
                    {isLoading && (
                        <Box className={classes.loader}>
                            <CircularProgress/>
                        </Box>
                    )}
                    <div
                        {...getRootProps()}
                        className={clsx(classes.fileDropzone, { [classes.error]: Boolean(error) })}
                    >
                        <div className={classes.uploadHeader}>
                            <Publish fontSize="small" classes={{ root: classes.uploadIcon }}/>
                            <Typography className={classes.uploadHeaderTitle}
                                color="primary"
                                variant="subtitle2"
                            >
                                {text}
                            </Typography>
                        </div>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                            className={classes.note}
                        >
                            {note}
                        </Typography>
                        {isDragReject && rejectMessage && (
                            <Typography className={classes.uploadError}
                                color="primary"
                                variant="body1"
                            >
                                {rejectMessage}
                            </Typography>
                        )}
                        {isFileTooLarge && (
                            <Typography className={classes.uploadError}
                                color="primary"
                                variant="body1"
                            >
                                File is too large.
                            </Typography>
                        )}
                    </div>
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </section>
            )}
            <input {...getInputProps()}/>
        </>
    );
}
