import { customFieldValueCreate } from 'modules/settings/submodules/clients/customFieldValues/components/CreateCustomFieldValue/store/reducers';
import { editCustomFieldValue } from 'modules/settings/submodules/clients/customFieldValues/components/EditCustomFieldValue/store/reducer';
import { getProjectClasses, setCustomFieldId } from 'modules/settings/submodules/clients/customFieldValues/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { customFieldValueSyncPrism } from '../components/SyncCustomFieldValue/store/reducer';
import { exportCustomFieldValues } from 'store/entities/customFields/actions';

const customFieldId = singleValueReducer(setCustomFieldId.action, null);
const projectClasses = singleValueReducer(getProjectClasses.successType, []);
const projectClassesLoading = isLoadingReducer(getProjectClasses);

const isExportingCustomFieldValues = isLoadingReducer(exportCustomFieldValues);

export const customFieldValues = combineReducers({
    customFieldId,
    customFieldValueCreate,
    customFieldValueSyncPrism,
    editCustomFieldValue,
    projectClasses,
    projectClassesLoading,
    isExportingCustomFieldValues,
});
