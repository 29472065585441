import React, { useEffect, useRef, useState } from 'react';
import { useFeature } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { intersection } from 'lodash-es';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { VerticalMenuChildItems } from 'shared/components/verticalMenu/VerticalMenuChildItems';
import { TMenuCategoryChild } from 'shared/models/Global';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import { selectUserPermissions } from 'store/components/auth/selectors';
import { MenuIconClient } from 'shared/components/verticalMenu/Icon';

export interface IMenuItemProps {
    menuItem: TMenuCategoryChild;
    isMinimized: boolean,
}

export const VerticalMenuItem = ({ menuItem, isMinimized }: IMenuItemProps) => {
    const {
        title,
        to,
        href,
        target,
        isExact,
        Icon,
        iconUrl,
        feature,
        permission,
        children,
        anyPermissionFrom = [],
    } = menuItem;

    const parentItemRef = useRef<HTMLDivElement | null>(null);
    const userPermissions = useSelector(selectUserPermissions) || [];
    const permissions = permission ? [...anyPermissionFrom, permission] : anyPermissionFrom;
    const isMatchedRoute = useRouteMatch({
        path: to,
        exact: false,
    });
    const [isOpened, setIsOpened] = useState<boolean>(Boolean(isMatchedRoute) && !isMinimized);
    const classes = useMenuStyles();
    let isEnabled = true;
    if (feature) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isFeatureEnabled] = useFeature(feature);
        isEnabled = isFeatureEnabled;
    }
    isEnabled = isEnabled && (!permissions?.length || intersection(userPermissions, permissions).length > 0);

    const hasChildren = Boolean(children);
    const navProps = {
        component: NavLink,
        to: to || { pathname: href },
        exact: isExact,
        target,
    };
    const closeMenu = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        setIsOpened(!isOpened);
    };
    const toggleButtonProps = {
        onClick: closeMenu,
    };

    useEffect(() => {
        // close nested menu on collapse side menu
        if (isMinimized) {
            setIsOpened(false);
        }
    }, [isMinimized, setIsOpened]);

    return (
        <>
            {isEnabled && (
                <>
                    {/*
                    // @ts-ignore */}
                    <ListItem
                        {...(hasChildren ? toggleButtonProps : navProps)}
                        className={classes.item}
                        ref={parentItemRef}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            minHeight={32}
                        >
                            {iconUrl
                                ? <MenuIconClient icon_url={iconUrl}/>
                                // @ts-ignore
                                : <ListItemIcon className={classes.itemIcon}><Icon/></ListItemIcon>}
                            <ListItemText
                                classes={{
                                    root: classes.itemPrimaryRoot,
                                    primary: classes.itemPrimary,
                                }}
                            >
                                {title}
                            </ListItemText>
                            {hasChildren && (
                                <>
                                    {isOpened ? (
                                        <ExpandLess
                                            className={classes.expandButton}
                                        />
                                    ) : (
                                        <ExpandMore
                                            className={classes.expandButton}
                                        />
                                    )}
                                </>
                            )}
                        </Box>

                    </ListItem>
                    {children && (
                        <VerticalMenuChildItems
                            menuItems={children}
                            isMinimized={isMinimized}
                            isOpened={isOpened}
                            onClose={closeMenu}
                            parentRef={parentItemRef}
                        />
                    )}
                </>
            )}
        </>
    );
};
