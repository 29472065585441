import { BulkCreateSubassignmentSteps } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/actions';
import { getDisplayCustomFieldsValues } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import {
    IBulkCreateSubassignmentsResponse,
    ICreateSubassignmentReport,
} from 'modules/subassignmentManagement/store/models';
import { selectSubassignmentManagementState } from 'modules/subassignmentManagement/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { selectCustomFieldValuesByIds, selectFirstTwoRootCustomFields } from 'store/entities/customFields/selectors';
import { selectUsersById } from 'store/entities/users/selectors';

export const selectCreateSubAssignmentState = (state: IStore) =>
    selectSubassignmentManagementState(state).createSubassignments;

export const selectIsOpenModal = (state: IStore) => selectCreateSubAssignmentState(state).isOpenBulkCreationModal;
export const selectIsOpenUploadCreationModal = (state: IStore) =>
    selectCreateSubAssignmentState(state).isOpenBulkCreationUploadModal;
export const selectCreationStep = (state: IStore): BulkCreateSubassignmentSteps =>
    selectCreateSubAssignmentState(state).step;
export const selectIsLoadingPreInitialize = (state: IStore) =>
    selectCreateSubAssignmentState(state).isLoadingPreInitializeReport;
export const selectIsLoadingCreationPdf = (state: IStore) => selectCreateSubAssignmentState(state).isLoadingPdf;
export const selectIsDownloadingFailedCsv = (state: IStore) => selectCreateSubAssignmentState(state).isLoadingFailedCsv;
export const selectIsLoadingCsvSample = (state: IStore) => selectCreateSubAssignmentState(state).isLoadingSample;

export const getReportRowsSelector = (baseReportSelector: (state: IStore) => IBulkCreateSubassignmentsResponse) => {
    return createSelector(
        baseReportSelector,
        selectFirstTwoRootCustomFields,
        selectCustomFieldValuesByIds,
        selectAssignmentsById,
        selectUsersById,
        (report, displayCustomFields, customFieldValuesByIds, assignmentsByIds, usersByIds) => {
            return (report?.subassignments || []).map((subassignment: ICreateSubassignmentReport) => {
                const { custom_field_value_ids, all_values_custom_field_ids } = subassignment;

                const displayCustomFieldValues = getDisplayCustomFieldsValues(
                    displayCustomFields,
                    customFieldValuesByIds,
                    custom_field_value_ids || [],
                    all_values_custom_field_ids || [],
                );
                const assignment = assignmentsByIds[subassignment.assignment_id];
                const difference = parseFloat(subassignment?.override_rate_value) - assignment?.pay_rate_value || 0;
                const employee = usersByIds[assignment?.user_id];
                return {
                    ...subassignment,
                    displayCustomFieldValues,
                    difference,
                    employee,
                };
            });
        },
    );
};

export const selectIsBulkBeingCreated = (state: IStore) =>
    selectCreateSubAssignmentState(state).isBulkCreating;
export const selectPreInitializeReportState = (state: IStore) =>
    selectCreateSubAssignmentState(state).preInitializeReport;
export const selectCreatingReportState = (state: IStore) =>
    selectCreateSubAssignmentState(state).creationReport;
export const creationPayloadSelector = (state: IStore) =>
    selectCreateSubAssignmentState(state).creationPayload;

export const selectPreInitializeReportTableRows = getReportRowsSelector(selectPreInitializeReportState);
export const selectCreationReportTableRows = getReportRowsSelector(selectCreatingReportState);

export const selectCreationReportV2State = (state: IStore) =>
    selectCreateSubAssignmentState(state).reportV2;
export const selectCreationReportV2Rows = (state: IStore) =>
    selectCreationReportV2State(state)?.creates;
