import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const useAddNewEntryDialogStyles = makeHighPriorityStyles((theme: Theme) => ({
    container: {
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: theme.zIndex.appBar,
        background: colors.white,
        padding: theme.spacing(2),
        borderTop: `1px solid ${colors.gray}`,
    },
    button: {
        position: 'absolute',
        zIndex: theme.zIndex.appBar,
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    mobileFiltersButton: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
}));

export default useAddNewEntryDialogStyles;
