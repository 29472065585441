import React, { useCallback } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Time12HoursKeyboardInput } from 'shared/components/inputs/Time12HoursKeyboardInput/Time12HoursKeyboardInput';

function TimeInOutQuickEditInput({
    label,
    name,
    value,
    className,
    inputProps,
    onChange,
    onFocus,
    onBlur,
    error,
    ...rest
}: TextFieldProps) {

    const onFocusHandler = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onFocus){
            onFocus(event);
        }
    }, [onFocus]) ;

    const onBlurHandler = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onBlur){
            onBlur(event);
        }
    }, [onBlur]);

    return (
        <Time12HoursKeyboardInput
            {...rest}
            name={name}
            label={label}
            value={value}
            variant="outlined"
            className={className}
            inputProps={{
                step: 60,
                ...inputProps,
            }}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onChange={onChange}
            error={error}
        />
    );
}

export default TimeInOutQuickEditInput;
