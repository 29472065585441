import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const OfferLetterTemplateValidationSchema = yup.object().shape({
    name: yup.string().trim().required(ValidationMessages.REQUIRED),
    filename: yup.string().trim().required('Please upload PDF template'),
    client_ids: yup.array().of(yup.string().required())
        .label('Client')
        .when('all_clients', {
            is: false,
            then: yup.array().of(yup.string().required()).required(),
        }),
    all_clients: yup.boolean().required(),
});
