import { IEmployeeManagementFilter } from 'modules/employmentManagement/components/store/model';
import { IStore } from 'store/configureStore';

export const selectSubassignmentManagementState = (state: IStore) =>
    state.modules.subassignmentManagement;

/**
 *  Subassignment filter
 */
export const selectSubassignmentFilter = (state: IStore): IEmployeeManagementFilter =>
    selectSubassignmentManagementState(state).subassignmentFilter;
