import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { resetEafApprovalItems, setEafApprovalFilter } from 'modules/eafApproval/store/actions';
import { getEafReasons } from 'modules/employeeActionsForm/store/actions';
import { EafType } from 'modules/employeeActionsForm/store/models';
import { TerminationRequestsHeader } from 'modules/humanResources/components/TerminationRequests/TerminationRequestsHeader';
import { ApproveEafConfirmation } from 'modules/eafApproval/components/Approve/ApproveEafConfirmation';
import { EafApprovalGrid } from 'modules/eafApproval/components/EafApprovalGrid';
import { EafDetailSidebar } from 'modules/eafApproval/components/EafDetail/EafDetailSidebar';
import { EafRejectionStateSidebar } from 'modules/eafApproval/components/Rejection/EafRejectionStateSidebar';
import { useTerminationRequestsCells } from 'modules/humanResources/components/TerminationRequests/useTerminationRequestsCells';
import { useTerminationRequestTabs } from 'modules/humanResources/components/TerminationRequests/useTerminationRequestTabs';

export const TerminationRequests = () => {
    useTitleUpdate('Termination Requests');
    useTerminationRequestTabs();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEafReasons.init());
        dispatch(setEafApprovalFilter({
            defaultTypes: [EafType.Termination],
        }));
        return function cleanUp() {
            dispatch(resetEafApprovalItems());
        };
    }, [dispatch]);

    const cells = useTerminationRequestsCells();

    return (
        <Box p={4}>
            <TerminationRequestsHeader/>
            <EafApprovalGrid cells={cells}/>

            <ApproveEafConfirmation/>
            <EafRejectionStateSidebar/>
            <EafDetailSidebar/>
        </Box>
    );
};
