/**
 * Create pipeline of reducers
 *
 * @param args - reducer functions
 * @return function - result reducer
 */
export const extendReducer = <ActionType, StateType>(
    ...args: ((state: StateType, action: ActionType) => StateType)[]
): (state: StateType, action: ActionType) => StateType => {
    return (state: StateType, action: ActionType): StateType => {
        return [...args].reduce((resultState, reducer) => {
            return reducer(resultState, action);
        }, state);
    };
};
