import { memoize, uniq } from 'lodash-es';
import moment from 'moment/moment';
import { IDeal } from 'shared/models/Deal';
import { selectCurrentClientHasJobNumberConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { selectDealsById } from 'store/entities/configuration/configurationSelectors';
import { select } from 'typed-redux-saga';
import { FieldInfo } from 'json2csv';
import { approvalTableSelectorsByType } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/helper';
import { exportCsvExpenseApproval, exportCsvTimeApproval } from 'modules/sheetApproval/store/actions';
import { ISheetGroupRow, SheetGroup } from 'modules/timeAndExpense/store/model';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import { fileTimestampFormat, shortDateFormat } from 'shared/models/Dates';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getLastFirstName, getUserName } from 'shared/utils/converters/user';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { rejectedReasonFormatter } from 'shared/utils/formatters/rejectedReasonFormatter';
import { ICustomField } from 'store/entities/customFields/model';
import { selectCustomFieldsByIds, selectOrderedCustomFieldIds } from 'store/entities/customFields/selectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { getDownloadCsvSagaWatcher, ICsvExportItem } from 'store/utils/sagas/downloadCsvFileSaga';

export interface ISheetCsvExportItem extends ICsvExportItem {
    group: SheetGroup,
    employee: string,
    prismId: string,
    payPeriod: string,
    approver: string,
    submittedDate: string,
    dueDate: string,
    approvedDate: string,
    rejectedReason: string,
    status: string,
}

interface ITimeSheetCsvExportItem extends ISheetCsvExportItem {
    regularHours: string,
    overtimeHours: string,
    doubletimeHours: string,
    holidayHours: string,
    ptoHours: string,
}

interface IExpenseSheetCsvExportItem extends ISheetCsvExportItem {
    amount: string,
}

export const csvFieldsDictionary: Record<string, FieldInfo<ISheetCsvExportItem>> = {
    employee: {
        label: 'employee',
        value: 'employee',
    },
    prismId: {
        label: 'prism ID',
        value: 'prismId',
    },
    payPeriod: {
        label: 'pay period',
        value: 'payPeriod',
    },
    regularHours: {
        label: 'reg hours',
        value: 'regularHours',
    },
    overtimeHours: {
        label: 'OT hours',
        value: 'overtimeHours',
    },
    doubletimeHours: {
        label: 'DT hours',
        value: 'doubletimeHours',
    },
    holidayHours: {
        label: 'holiday hours',
        value: 'holidayHours',
    },
    ptoHours: {
        label: 'PTO hours',
        value: 'ptoHours',
    },
    approver: {
        label: 'approver',
        value: 'approver',
    },
    submittedDate: {
        label: 'submitted date',
        value: 'submittedDate',
    },
    amount: {
        label: 'amount',
        value: 'amount',
    },
    dueDate: {
        label: 'due date',
        value: 'dueDate',
    },
    approvedDate: {
        label: 'approved date',
        value: 'approvedDate',
    },
    rejectedReason: {
        label: 'rejected reason',
        value: 'rejectedReason',
    },
    status: {
        label: 'status',
        value: 'status',
    },
};

export const formatDate = (date?: string | null): string => date ? moment(date).format(shortDateFormat) : '';

const formatCommonFields = (row: ISheetGroupRow): ISheetCsvExportItem => {
    const note = rejectedReasonFormatter(row.group.notes)[0];
    return {
        group: row.group,
        employee: getLastFirstName(row.group.employee),
        prismId: row.group.employee?.prism_employee_id,
        payPeriod: getPayPeriodByStartEnd(row.group.payPeriod.period_start, row.group.payPeriod.period_end),
        approver: row.group.approvers?.map(approver => getUserName(approver)).join(', '),
        submittedDate: formatDate(row.group.submittedAt),
        dueDate: formatDate(row.group.dueDate),
        approvedDate: formatDate(row.group.approvals?.find(Boolean)?.created_at),
        rejectedReason: note?.notes || '',
        status: row.group.status.name,
    };
};

const formatExportObject = (
    row: ISheetGroupRow,
    sheetType: EntryType,
): ITimeSheetCsvExportItem | IExpenseSheetCsvExportItem => {
    if (sheetType === EntryType.TIME) {
        return {
            ...formatCommonFields(row),
            regularHours: row.group.regularHours,
            overtimeHours: row.group.overtimeHours,
            doubletimeHours: row.group.doubletimeHours,
            holidayHours: row.group.holidayHours,
            ptoHours: row.group.ptoHours,
        };
    }
    return {
        ...formatCommonFields(row),
        amount: row.group.totalDollars,
    };
};

export const getCustomFieldColumnValue = memoize(
    (fieldId: string) => (row: ISheetCsvExportItem) => row.group.getCustomFieldValuesAsStringById(fieldId),
);

export function* getCustomFieldsColumnsSaga(): Generator<any, FieldInfo<ISheetCsvExportItem>[]> {
    const hasJobNumber = yield select(selectCurrentClientHasJobNumberConfiguration);
    if (hasJobNumber) {
        const dealNumbersById = (yield select(selectDealsById)) as Record<string, IDeal>;
        return [
            {
                label: 'Job Number',
                value: (row: ISheetCsvExportItem) => row.group.jobNumbersString,
            },
            {
                label: 'Deal Number',
                value: (row: ISheetCsvExportItem) => {
                    return uniq(row.group?.jobNumbers
                        .map(jobNumber => dealNumbersById[jobNumber?.deal_id]?.deal_number)
                        .filter(Boolean))
                        .join(', ');
                },
            },
        ];
    }
    const customFieldsByIds = (yield select(selectCustomFieldsByIds)) as Record<string, ICustomField>;
    const customFieldIds = (yield select(selectOrderedCustomFieldIds)) as string[];
    return customFieldIds.map(fieldId => {
        const field = customFieldsByIds[fieldId];
        return {
            label: field.name,
            value: getCustomFieldColumnValue(fieldId),
        };
    });
}

export function getItemsSaga(sheetType: EntryType) {
    return function* () {
        const tableSelectors = approvalTableSelectorsByType(sheetType);
        const baseItems = (yield select(tableSelectors.selectGroupedSheetRows)) as ISheetGroupRow[];
        return baseItems.map(item => formatExportObject(item, sheetType));
    };
}

export function getFieldsSaga(sheetType: EntryType) {
    return function* () {
        const activeStatus = yield select(selectTimeAndExpenseActiveStatus);
        const sheetTypeSpecificColumns = sheetType === EntryType.TIME ? [
            csvFieldsDictionary.regularHours,
            csvFieldsDictionary.overtimeHours,
            csvFieldsDictionary.doubletimeHours,
            csvFieldsDictionary.holidayHours,
            csvFieldsDictionary.ptoHours,
        ] : [
            csvFieldsDictionary.amount,
        ];
        let statusSpecificColumns: FieldInfo<ISheetCsvExportItem>[] = [];
        switch (activeStatus) {
            case StatusNames.SUBMITTED:
                statusSpecificColumns = [
                    csvFieldsDictionary.submittedDate,
                ];
                break;
            case StatusNames.WORKING:
                statusSpecificColumns = [
                    csvFieldsDictionary.dueDate,
                ];
                break;
            case StatusNames.APPROVED:
                statusSpecificColumns = [
                    csvFieldsDictionary.approvedDate,
                ];
                break;
            case StatusNames.REJECTED:
                statusSpecificColumns = [
                    csvFieldsDictionary.rejectedReason,
                ];
                break;
            case StatusNames.ALL:
                statusSpecificColumns = [
                    csvFieldsDictionary.status,
                ];
                break;
        }
        const customFieldsColumns = yield* getCustomFieldsColumnsSaga();
        return [
            csvFieldsDictionary.employee,
            csvFieldsDictionary.prismId,
            csvFieldsDictionary.payPeriod,
            ...customFieldsColumns,
            ...sheetTypeSpecificColumns,
            csvFieldsDictionary.approver,
            ...statusSpecificColumns,
        ];
    };
}

export function getFilenameSaga(sheetType: EntryType) {
    return function* () {
        const activeStatus = yield select(selectTimeAndExpenseActiveStatus);
        const reportTitle = sheetType === EntryType.TIME ? 'Time' : 'Expense';
        return `${reportTitle} Approval_${activeStatus}_Report-${moment().format(fileTimestampFormat)}.csv`;
    };
}

const donwloadTimeApprovalSagaWatcher = getDownloadCsvSagaWatcher(
    exportCsvTimeApproval,
    getItemsSaga(EntryType.TIME),
    getFieldsSaga(EntryType.TIME),
    getFilenameSaga(EntryType.TIME),
);

const donwloadExpenseApprovalSagaWatcher = getDownloadCsvSagaWatcher(
    exportCsvExpenseApproval,
    getItemsSaga(EntryType.EXPENSE),
    getFieldsSaga(EntryType.EXPENSE),
    getFilenameSaga(EntryType.EXPENSE),
);

export const exportApprovalCsvSagas = [
    donwloadTimeApprovalSagaWatcher,
    donwloadExpenseApprovalSagaWatcher,
];
