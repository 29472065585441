import { BulkEditSubassignmentSteps } from 'modules/subassignmentManagement/components/EditSubAssignment/store/model';
import React, { useCallback } from 'react';

import { CommonPrePostInitializeReportPure } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/CommonPrePostInitializeReport';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBulkEditSubassignmentStep,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import {
    selectIsBulkBeingDeleted,
    selectPreInitializeDeleteReportTableRows,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';

import { deletePayloadSelector } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/store/DeletePayloadSelector';
import { bulkDeleteSubAssignmentAction } from 'modules/subassignmentManagement/store/actions';
import { useDeletePrePostInitializeReportTableCells } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/store/useDeletePrePostInitializeReportTableCells';
import { deletePreInitializeReportOverviewItemsSelector } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/store/DeletePreInitializeReportOverviewItemsSelector';

export function DeletePreInitializeReport(){
    const dispatch = useDispatch();
    const onBack = useCallback(() => {
        dispatch(setBulkEditSubassignmentStep(BulkEditSubassignmentSteps.Form));
    }, [dispatch]);

    const deletePayload = useSelector(deletePayloadSelector);

    const mainButtonHandler = useCallback(() => {
        dispatch(bulkDeleteSubAssignmentAction.init(deletePayload));
    }, [dispatch, deletePayload]);

    const cells = useDeletePrePostInitializeReportTableCells();

    const rows = useSelector(selectPreInitializeDeleteReportTableRows);
    const overviewItems = useSelector(deletePreInitializeReportOverviewItemsSelector);
    const isLoading = useSelector(selectIsBulkBeingDeleted);
    return (
        <CommonPrePostInitializeReportPure title="Pre-Initialize Delete"
            buttonTitle={'Delete'}
            buttonHandler={mainButtonHandler}
            isButtonLoading={isLoading}
            backButtonHandler={onBack}
            overviewItems={overviewItems}
            rows={rows}
            cells={cells}
            getRowId={row => row.id}/>
    );
}
