import { IStore } from 'store/configureStore';
import { ITask } from 'store/entities/configuration/configurationModel';

export const selectAssignmentsToSubmit = (state: IStore) => state.components.addEntry.assignmentIds.map(
    // @ts-ignore
    id => state.configuration.assignmentsById[id],
);

export const selectTasksToSubmit = (state: IStore) => {
    return state.components.addEntry.taskIds.map(
        // @ts-ignore
        id => state.configuration.tasksById[id || ''],
    ).filter((task: ITask) => task);
};

export const selectActivitiesByTaskIdByAssignment = (state: IStore) => state.components.addEntry.activitiesByTaskId;
