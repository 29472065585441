import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { setEditCustomFieldValueId, updateCustomFieldValue } from 'modules/settings/submodules/clients/customFieldValues/store/actions';

const editId = singleValueReducer<string | null>(setEditCustomFieldValueId.action, null);
const isUpdating = isLoadingReducer(updateCustomFieldValue);

export const editCustomFieldValue = combineReducers({
    editId,
    isUpdating,
});
