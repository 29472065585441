import { IPayRangeRow } from 'modules/settings/submodules/clients/payRanges/components/payRangesTable/model';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { selectSettingClientsState } from 'modules/settings/submodules/clients/store/selectors';
import { selectPayRangesByIds } from 'store/entities/configuration/configurationSelectors';

const selectPayRangesState = (state: IStore) => selectSettingClientsState(state).payRanges;
export const selectPayRangesFilter = (state: IStore) => selectPayRangesState(state).payRangesFilter;
const selectTableState = (state: IStore) => selectPayRangesState(state).payRangesTable;
export const payRangesTableSelectors = getInfinityScrollSelectors<IPayRangeRow>(selectTableState);
export const selectPayRangesTableRows = createSelector(
    payRangesTableSelectors.selectItems,
    selectPayRangesByIds,
    selectCustomFieldValuesByIds,
    (displayPayRangesIds, payRangesByIds, customFieldValuesByIds) => {
        return displayPayRangesIds.map(payRangeId => {
            const payRange = payRangesByIds[payRangeId];
            return {
                ...payRange,
                position: customFieldValuesByIds[payRange?.position_value_id],
                location: customFieldValuesByIds[payRange?.location_value_id],
            };
        });
    },
);

export const selectIsCreatingPayRange = (state: IStore) => selectPayRangesState(state).isCreating;
