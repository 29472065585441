import { ICcpTransaction } from 'modules/ccp/models/CcpTransaction';
import { IStore } from 'store/configureStore';
import { selectCcpTransactionsState } from 'modules/ccp/store/selectors';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { createSelector } from 'reselect';
import { groupBy } from 'lodash-es';

export interface ICcpTransactionRow extends ICcpTransaction {
    className?: string;
}
export const selectCcpTransactionsById = (state: IStore) => selectCcpTransactionsState(state).ccpTransactionsById;
const selectCcpTransactionsTableState = (state: IStore) => selectCcpTransactionsState(state).ccpTransactionsTable;
export const ccpTranInfinityTableSelectors = getInfinityScrollSelectors<ICcpTransaction>(
    selectCcpTransactionsTableState);

export const selectCcpFilter = (state: IStore) => selectCcpTransactionsState(state).filter;

export const selectCcpEditCcpTransactions = (state: IStore) => selectCcpTransactionsState(state).editCcpTransactions;
export const selectCcpAttachmentsCurrent = (state: IStore) => selectCcpEditCcpTransactions(state).ccpAttachments;

export const selectCcpTransactionsRows = createSelector(
    ccpTranInfinityTableSelectors.selectItems,
    selectCcpTransactionsById,
    (ids, ccpByIds): ICcpTransactionRow[] => {
        return ids.map(id => ccpByIds[id]);
    },
);

export const selectCcpChildTransactions = createSelector(
    selectCcpTransactionsById,
    ccpByIds => {
        return groupBy(Object.values(ccpByIds), 'parent_transaction_id');
    },
);
