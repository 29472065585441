import { OfferLetterLayout } from 'modules/offerLetter/OfferLetterLayout';
import { getOfferLetters } from 'modules/offerLetter/store/actions';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'shared/routes';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { selectIsPayRangesEnabledForClient } from 'store/entities/clients/selectors/configurationSelectors';
import {
    getBackgroundCheckTemplates,
    getPhysicalDemands,
    getWorkingConditions,
} from 'store/entities/configuration/configurationAction';
import { getCustomFieldsHierarchyNodes } from 'store/entities/customFields/actions';
import { HierarchyType } from 'store/entities/customFields/model';
import { getClientUserRoles, loadClientRoles } from 'store/entities/users/actions';
import CreateOfferPage from './components/CreateOfferPage';
import OfferLetterCard from './OfferLetterCard';
import OfferLetterEditPage from './OfferLetterEdit';
import OfferLetterRescission from './OfferLetterRescission';
import OfferLetters from './OfferLetters';

const OfferLetterModule = () => {
    useWithClientEffect((dispatchWithClient, clientId) => {
        dispatchWithClient(getCustomFieldsHierarchyNodes.init({ hierarchy_type: HierarchyType.OfferLetter }));
        dispatchWithClient(loadClientRoles.init({ client_id: clientId }));
        dispatchWithClient(getClientUserRoles.init({ client_id: clientId }));
        dispatchWithClient(getBackgroundCheckTemplates.init());
        dispatchWithClient(getWorkingConditions.init());
        dispatchWithClient(getPhysicalDemands.init());
    });

    const isPayRangeEnabledForClient = useSelector(selectIsPayRangesEnabledForClient);
    const userCanApprovePayRate = useSelector(selectIsUserHasPermission(Permission.ApprovePayRate));
    useWithClientEffect(dispatchWithClient => {
        if (isPayRangeEnabledForClient) {
            const status = userCanApprovePayRate ? OfferLetterStatusSlug.PendingPayRateApproval
                : OfferLetterStatusSlug.RejectedPayRate;
            //Check that users has offers that required actions
            dispatchWithClient(getOfferLetters.init({
                status,
                range: '[0,1]',
            }));
        }
    }, [isPayRangeEnabledForClient]);

    return (
        <div style={{ height: '100%' }} className="layout-content">
            {/*
            // @ts-ignore */}
            <OfferLetterLayout>
                <Switch>
                    <Route
                        path={routes.CLIENT.OFFER_LETTER.CREATE}
                        component={CreateOfferPage}
                        exact
                    />
                    <OfferLetters>
                        <Route
                            path={routes.CLIENT.OFFER_LETTER.DETAILS}
                            component={OfferLetterCard}
                            exact
                        />
                        <Route
                            path={routes.CLIENT.OFFER_LETTER.EDIT}
                            component={OfferLetterEditPage}
                            exact
                        />
                        <Route
                            path={routes.CLIENT.OFFER_LETTER.RESCIND}
                            component={OfferLetterRescission}
                            exact
                        />
                    </OfferLetters>
                </Switch>
            </OfferLetterLayout>
        </div>
    );
};

export default OfferLetterModule;
