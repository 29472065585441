import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    Typography,
} from '@material-ui/core';
import { DescriptionOutlined, PersonOutlined, WorkOutlined } from '@material-ui/icons';
import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import { OnboardingStepIcon } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingStepIcon';
import {
    useOfferLetterStyles,
    useStepConnectorStyles,
    useStepLabelStyles,
} from 'modules/offerLetter/OfferLetterStyles';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { setOnboardingStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';

export interface IOnboardingStepperProps {
    step: OnboardingSteps;
    unlocked: number;
}

export const OnboardingStepper = ({ step, unlocked }: IOnboardingStepperProps) => {
    const classes = useOfferLetterStyles();
    const stepLabelClasses = useStepLabelStyles();
    const stepConnectorClasses = useStepConnectorStyles();
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const setStep = (newStep: OnboardingSteps) => {
        dispatch(setOnboardingStep(newStep));
    };

    const steps = [
        {
            label: 'Offer Letter',
            icon: <DescriptionOutlined/>,
            key: OnboardingSteps.ViewPdf,
        },
        {
            label: 'Profile',
            icon: <PersonOutlined/>,
            key: OnboardingSteps.EmployeeProfile,
        },
        {
            label: 'Onboarding',
            icon: <WorkOutlined/>,
            key: OnboardingSteps.PrismOnboarding,
        },
    ];

    return (
        <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            className={classes.acceptanceStepsWrapper}
        >
            <Typography variant="body2">
                Welcome to Headway Workforce Solutions<br/>
                {currentUser?.firstName}! Let&apos;s get started.
            </Typography>
            <Stepper
                orientation="vertical"
                activeStep={step}
                className={classes.stepper}
                connector={(<StepConnector classes={stepConnectorClasses}/>)}
            >
                {steps.map(item => (
                    <Step
                        key={item.label}
                        className={classes.step}
                        active={item.key === step}
                        completed={item.key < unlocked}
                        onClick={item.key <= unlocked ? () => setStep(item.key) : undefined}
                    >
                        <StepLabel
                            classes={stepLabelClasses}
                            icon={item.key === step ? item.icon : item.key + 1}
                            StepIconComponent={OnboardingStepIcon}
                        >
                            {item.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};
