import React, { useCallback } from 'react';
import { approveOfferPayRange } from 'modules/offerLetter/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectOfferLetter, isOfferPayRateApproving, isOfferStatusOperationInProgress } from 'modules/offerLetter/store/selectors';
import ActionButton from 'shared/components/buttons/ActionButton';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { getUserName } from 'shared/utils/converters/user';
import { useModal } from 'shared/utils/hooks/useModal';

export interface IApproveOfferButtonProps {
    offerLetterIds: string[];
}

export const ApproveOfferButton = ({ offerLetterIds }: IApproveOfferButtonProps) => {
    const { isModalOpened, onModalOpen, onModalClose } = useModal();

    const isLoading = useSelector(isOfferPayRateApproving);
    const disabled = useSelector(isOfferStatusOperationInProgress);
    const dispatch = useDispatch();
    const onConfirm = useCallback(() => {
        dispatch(approveOfferPayRange.init(offerLetterIds));
        onModalClose();
    }, [offerLetterIds, dispatch, onModalClose]);

    const isSingleOffer = offerLetterIds.length === 1;
    const offerLetter = useSelector(selectOfferLetter(offerLetterIds[0]));

    return (
        <>
            <ActionButton
                variant="contained"
                color="primary"
                customType="success"
                onClick={onModalOpen}
                isLoading={isLoading}
                disabled={disabled}
            >
                Accept
            </ActionButton>

            <ConfirmationDialog
                open={isModalOpened}
                onConfirm={onConfirm}
                onCancel={onModalClose}
                confirmText="Accept"
            >
                Are you sure you want to resend
                {isSingleOffer && offerLetter
                    ? ` ${getUserName(offerLetter)}'s offer letter?` : ' these offer letters?'}
            </ConfirmationDialog>
        </>
    );
};
