import React from 'react';
import { ISheet, EntryType } from 'shared/models/sheet/Sheet';
import SheetReject from 'shared/components/sidebars/SheetReject/SheetReject';
import SheetApproval from 'shared/components/modals/SheetApproval/SheetApproval';
import { IUserInfo } from 'shared/models/User';

interface ISheetQuickActionProps {
    sheetType: EntryType;
    sheetApproval?: ISheet;
    sheets?: ISheet[];
    isApprove: boolean;
    linkedUsers: IUserInfo[];
    isOpened: boolean;
    onClose: () => void;
}

export default function SheetQuickAction({
    sheetType, sheetApproval, isApprove, linkedUsers, isOpened, onClose, sheets: initialSheets = [],
}: ISheetQuickActionProps) {
    const sheets = sheetApproval ? [sheetApproval] : initialSheets;
    return (
        <>
            {isApprove ? (
                <SheetApproval
                    title={`Approve Submitted ${sheetType === EntryType.TIME ? 'Timesheets' : 'Expense Sheets'}`}
                    isOpened={isOpened}
                    sheetType={sheetType}
                    onClose={onClose}
                    sheets={sheets}
                />
            ) : (
                <SheetReject
                    sheets={sheets}
                    users={linkedUsers}
                    anchor={'right'} isOpened={isOpened}
                    sheetType={sheetType}
                    onClose={onClose}
                />
            )}
        </>
    );
}
