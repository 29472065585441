import { Box, Collapse, Fade } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useOfferLetterPayRangesStyles } from 'modules/offerLetter/components/OfferLetterForm/components/OfferLetterPayRanges/useOfferLetterPayRangesStyles';
import { usePayRanges } from 'modules/offerLetter/components/OfferLetterForm/hooks/usePayRanges';
import { IOfferLetterFormValues } from 'modules/offerLetter/components/OfferLetterForm/model';
import React, { useMemo } from 'react';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';

const FADE_TIMEOUT_MS = 300;

export const OfferLetterPayRangesCore: React.FC = () => {
    const classes = useOfferLetterPayRangesStyles();
    const formikContext = useFormikContext<IOfferLetterFormValues>();
    const positionId = formikContext.values.positionValueId || null;
    const locationId = formikContext.values.locationValueId || null;
    const { payRange, isLoading } = usePayRanges(positionId, locationId);
    const showPayRange = Boolean(payRange) && !isLoading;
    const { min_value: payRangeMin = 0, max_value: payRangeMax = 0 } = payRange || {};

    const animationProps = useMemo(() => ({
        in: showPayRange,
        timeout: FADE_TIMEOUT_MS,
    }), [showPayRange]);

    return (
        <Fade {...animationProps}>
            <Collapse {...animationProps}>
                <Box className={classes.root}>
                    This location&apos;s pay range is from&nbsp;
                    {showPayRange && (
                        <strong>
                            {formatDollars(payRangeMin)} – {formatDollars(payRangeMax)}
                        </strong>
                    )},
                    if you go outside of this range,
                    it has to be approved by the Regional Manager.
                </Box>
            </Collapse>
        </Fade>
    );
};
