import { setExpenseSheetGroupsChecked, setExpenseSheetsChecked, setTimeSheetGroupsChecked, setTimeSheetsChecked } from 'modules/timeAndExpense/store/actions';
import { combineReducers } from 'redux';
import { editEntry } from 'modules/timeAndExpense/components/EditEntry/store/reducer';
import { addEntry } from 'modules/timeAndExpense/components/AddEntry/store/reducer';
import { workingPage } from 'modules/timeAndExpense/components/WorkingPage/store/reducer';
import { singleValueReducer } from 'store/reducerUtils';
const timeSheetsChecked = singleValueReducer(setTimeSheetsChecked.action, {});
const expenseSheetsChecked = singleValueReducer(setExpenseSheetsChecked.action, {});
const timeSheetGroupsChecked = singleValueReducer(setTimeSheetGroupsChecked.action, {});
const expenseSheetGroupsChecked = singleValueReducer(setExpenseSheetGroupsChecked.action, {});

export const timeAndExpense = combineReducers({
    editEntry,
    addEntry,
    workingPage,
    timeSheetsChecked,
    expenseSheetsChecked,
    timeSheetGroupsChecked,
    expenseSheetGroupsChecked,
});
