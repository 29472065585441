import React, { FC } from 'react';
import AttachMoney from '@material-ui/icons/AttachMoney';
import useFilterAndActionCOntrolsStyles from '../FilterAndActionControlsStyles';

const Expense: FC = () => {
    const classes = useFilterAndActionCOntrolsStyles();

    return (
        <AttachMoney
            className={classes.MoneyIcon}
        >
        </AttachMoney>
    );
};

export default Expense;
