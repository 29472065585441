import { EafApprovalCellClient } from 'modules/eafApproval/components/cells/EafApprovalCellClient';
import { EafApprovalGridCellCreationDate } from 'modules/eafApproval/components/cells/EafApprovalGridCellCreationDate';
import { EafApprovalCell, EafApprovalGridCellKey } from 'modules/eafApproval/store/model/types';
import { EafStatusSlug } from 'modules/employeeActionsForm/store/models';
import { EafAttachmentsCell } from 'modules/humanResources/components/TerminationRequests/EafAttachmentsCell';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { selectEafApprovalActiveTab } from 'modules/eafApproval/store/selectors';
import { EafApprovalCellTitleMap } from 'modules/eafApproval/store/model/data';
import { EafApprovalGridCellActions } from 'modules/eafApproval/components/cells/EafApprovalGridCellActions';
import { getEafApprovalGridCellCheckbox } from 'modules/eafApproval/components/cells/EafApprovalGridCellCheckbox';
import { EafApprovalGridCellDetails } from 'modules/eafApproval/components/cells/EafApprovalGridCellDetails';
import { EafApprovalGridCellEffectiveDate } from 'modules/eafApproval/components/cells/EafApprovalGridCellEffectiveDate';
import { EafApprovalGridCellEmployee } from 'modules/eafApproval/components/cells/EafApprovalGridCellEmployee';
import { EafApprovalGridCellManager } from 'modules/eafApproval/components/cells/EafApprovalGridCellManager';
import { EafApprovalGridCellReason } from 'modules/eafApproval/components/cells/EafApprovalGridCellReason';
import { getEafApprovalGridTitleCellCheckbox } from 'modules/eafApproval/components/cells/EafApprovalGridTitleCellCheckbox';

export function useTerminationRequestsCells(): Array<EafApprovalCell> {
    const activeTab = useSelector(selectEafApprovalActiveTab);
    const isPendingApproval = activeTab === EafStatusSlug.PendingApproval;
    const isUserHasApprovePermission = useSelector(selectIsUserHasPermission(Permission.ManageTerminationRequests));
    const showCheckboxes = isPendingApproval && isUserHasApprovePermission;
    const showActions = isPendingApproval && isUserHasApprovePermission;

    return useMemo(() => ([
        ...(showCheckboxes ? [{
            key: EafApprovalGridCellKey.Checkbox,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Checkbox],
            render: getEafApprovalGridCellCheckbox(),
            renderTitle: getEafApprovalGridTitleCellCheckbox(),
            width: '60px',
        }] : []),
        {
            key: EafApprovalGridCellKey.Employee,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Employee],
            render: EafApprovalGridCellEmployee,
        },
        {
            key: EafApprovalGridCellKey.Client,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Client],
            render: EafApprovalCellClient,
        },
        {
            key: EafApprovalGridCellKey.Manager,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Manager],
            render: EafApprovalGridCellManager,
        },
        {
            key: EafApprovalGridCellKey.CreationDate,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.CreationDate],
            render: EafApprovalGridCellCreationDate,
            width: '130px',
        },
        {
            key: EafApprovalGridCellKey.Reason,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Reason],
            render: EafApprovalGridCellReason,
        },
        {
            key: EafApprovalGridCellKey.EffectiveDate,
            title: EafApprovalCellTitleMap[EafApprovalGridCellKey.EffectiveDate],
            render: EafApprovalGridCellEffectiveDate,
            width: '130px',
        },
        ...[
            {
                key: EafApprovalGridCellKey.Docs,
                title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Docs],
                render: EafAttachmentsCell,
                width: '100px',
            },
        ],
        ...(showActions ? [
            {
                key: EafApprovalGridCellKey.Actions,
                title: EafApprovalCellTitleMap[EafApprovalGridCellKey.Actions],
                render: EafApprovalGridCellActions,
            },
        ] : []),
        {
            key: EafApprovalGridCellKey.Details,
            render: EafApprovalGridCellDetails,
            width: '80px',
        },
    ]), [showActions, showCheckboxes]);
}
