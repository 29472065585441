import React from 'react';
import { Formik } from 'formik';
import { useZohoAnalyticsSetupFormHelper } from 'modules/settings/submodules/clients/reports/zoho/useZohoAnalyticsSetupFormHelper';
import { zohoAnalyticsPermalinkSetupValidationSchema } from 'modules/settings/submodules/clients/reports/zoho/validation';
import { ZohoAnalyticsPermalinkSetupForm } from 'modules/settings/submodules/clients/reports/zoho/ZohoAnalyticsPermalinkSetupForm';

export const ZohoAnalyticsPermalinkSetupPage: React.FC = () => {
    const zohoPermalinkSetupFormHelpers = useZohoAnalyticsSetupFormHelper();

    return (
        <Formik
            key={zohoPermalinkSetupFormHelpers.initialState.zohoAnalyticsPermalink}
            initialValues={zohoPermalinkSetupFormHelpers.initialState}
            onSubmit={zohoPermalinkSetupFormHelpers.handleUpdatePermalink}
            validationSchema={zohoAnalyticsPermalinkSetupValidationSchema}
        >
            <ZohoAnalyticsPermalinkSetupForm
                zohoPermalinkSetupFormHelpers={zohoPermalinkSetupFormHelpers}
            />
        </Formik>
    );
};
