import { useMemo } from 'react';
import { EntryType, IEntry, ITimeEntryData } from 'shared/models/sheet/Sheet';
import { getMinutesByTimeUnits } from '../../DateTime';

export function useTotalTimeEntriesByDay(entries: Array<IEntry>, ignoreEntryId?: string) {
    const memoKey = JSON.stringify(entries);
    return useMemo(() => {
        const entriesByDayResult: Record<string, number> = {};

        entries.forEach(entry => {
            if (entry.entry_type === EntryType.TIME && entry.id !== ignoreEntryId) {
                const currentDayMinutes = entriesByDayResult[entry.entry_date] || 0;
                const entryData = entry.data as ITimeEntryData;
                entriesByDayResult[entry.entry_date] = currentDayMinutes
                    + getMinutesByTimeUnits({ hours: entryData.hours, minutes: entryData.minutes });
            }
        });

        return entriesByDayResult;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoKey, ignoreEntryId]);
}
