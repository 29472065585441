/* eslint-disable */
import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import { FormButtonBlock } from 'modules/settings/common/components/FormButtonBlock/FormButtonBlock';
import { setEditLogoClientId, updateClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/store/actions';
import { IUpdateLogoRequest } from 'modules/settings/submodules/clients/components/EditClientLogo/store/models';
import { selectEditLogoClientId, selectIsClientLogoUpdating } from 'modules/settings/submodules/clients/components/EditClientLogo/store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { ImageUploadWithCrop } from 'shared/components/formSpecialFields/ImageUpload/ImageUploadWithCrop';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { selectClientById } from 'store/entities/clients/selectors/clientsSelectors';
import { selectClientLogoUrlById } from 'store/entities/clients/selectors/themeSelectors';
import * as yup from 'yup';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

const validationSchema = yup.object().shape({
    icon: yup.string().nullable().required('Please upload an image'),
});

export const EditClientLogo = () => {
    const dispatch = useDispatch();
    const modalClasses = useColoredFormModalStyles();
    const classes = useSettingsFormStyles();

    const clientId = useSelector(selectEditLogoClientId);
    const client = useSelector(selectClientById(clientId));
    const currentLogo = useSelector(selectClientLogoUrlById(clientId));
    const isLoading = useSelector(selectIsClientLogoUpdating);
    const onSave = useCallback((data: IUpdateLogoRequest) => {
        dispatch(updateClientLogo.init({
            clientId,
            data,
        }));
    }, [dispatch, clientId]);
    const onClose = useCallback(() => {
        dispatch(setEditLogoClientId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title={`Edit ${client?.name} logo`}
            isOpened={clientId}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <Formik
                    initialValues={{
                        icon: currentLogo,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSave}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} className={classes.form}>
                            <Box className={clsx(classes.formBlock)}>
                                <Box
                                    display="flex"
                                    width="100%"
                                >
                                    <ImageUploadWithCrop
                                        name="icon"
                                    />
                                </Box>
                            </Box>
                            <FormButtonBlock
                                submitTitle="Update logo"
                                isLoading={isLoading}
                                onCancel={onClose}
                            />
                        </form>
                    )}
                </Formik>
            </Box>
        </ModalDialog>
    );
};
