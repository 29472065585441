import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    TextField,
} from '@material-ui/core';
import { IFormFieldProps } from './models';
import { inputParams } from 'shared/styles/constants';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput/OutlinedInput';

export interface IFormNumberInputProps extends Partial<IFormFieldProps> {
    name?: string;
    initialValue: any;
    inputProps?: Partial<NumberFormatProps>;
    InputProps?: Partial<OutlinedInputProps>;
    error?: string | null;
    touched?: boolean;
    onChange: (value: string, setValue?: React.Dispatch<React.SetStateAction<string>>) => void;
    onBlur?: () => void;
    setTouched?: (value: boolean) => void;
    type?: 'text' | 'tel' | 'password';
    min?: number;
    max?: number;
    onFocus?: (
        event: React.FocusEvent<HTMLInputElement>,
        value: string,
        setValue: React.Dispatch<React.SetStateAction<string>>,
    ) => void;
}

interface INumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function NumberFormatCustom({
    inputRef,
    onChange,
    ...props
}: INumberFormatCustomProps) {
    return (
        <NumberFormat
            {...props}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
}

const NumberInput = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
    inputProps = {},
    initialValue,
    touched,
    error,
    setTouched,
    onChange,
    onFocus,
    onBlur,
    min,
    max,
    type = 'text',
    InputProps = {},
    ...props
}: IFormNumberInputProps) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue, setValue]);

    const errorMessage = typeof error === 'string' ? touched && error : null;
    const handleBlur = useCallback(() => {
        let normalizedValue = value;
        if (max !== undefined && min !== undefined && value) {
            normalizedValue = value < min ? min : value > max ? max : value;
            setValue(normalizedValue);
        }
        onChange(normalizedValue, setValue);
        if (setTouched) {
            setTouched(true);
        }
        if (onBlur) {
            onBlur();
        }
    }, [max, min, onBlur, onChange, setTouched, value]);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }, []);

    const innerOnFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (typeof(onFocus) === 'function') {
            onFocus(event, value, setValue);
            return;
        }
        if (parseFloat(value) === 0) {
            setValue('');
        }
    }, [onFocus, value]);

    return (
        <TextField
            {...props}
            id={id}
            name={name}
            className={className}
            disabled={disabled}
            label={label}
            variant="outlined"
            autoComplete="off"
            onBlur={handleBlur}
            onFocus={innerOnFocus}
            value={value}
            type={type}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            onChange={handleChange}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                    ...inputParams,
                    ...inputProps,
                    onValueChange: handleChange,
                },
                ...InputProps,
            }}
        />
    );
};

export default NumberInput;
