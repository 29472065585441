import { CustomFieldValueRowType } from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValuesTable/model';
import { selectCustomFieldId } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { createSelector } from 'reselect';
import { selectCustomFieldNodeByFieldId, selectAllNotDeletedCustomFieldValues } from 'store/entities/customFields/selectors';
import { selectCurrentClientId } from '../../../../../../../store/entities/clients/selectors/clientsSelectors';

export const selectCustomFieldValueRows = createSelector(
    selectCustomFieldId,
    selectAllNotDeletedCustomFieldValues,
    selectCurrentClientId,
    (customFieldId, allFieldValues, currentClientId): CustomFieldValueRowType[] => {
        return allFieldValues.filter(fieldValue => fieldValue.custom_field_id === customFieldId
            && fieldValue.client_id === (currentClientId ?? ''))
            .map(fieldValue => ({
                id: fieldValue.id,
                parentValueIds: fieldValue.assignment_hierarchy.parent_custom_field_value_ids
                    || fieldValue.offer_hierarchy.parent_custom_field_value_ids,
                ...fieldValue.data,
                is_active: fieldValue.is_active,
                prismFieldId: fieldValue.prism_field_id,
            }));
    },
);

export const selectCustomFieldValueShouldHaveParent = createSelector(
    selectCustomFieldId,
    selectCustomFieldNodeByFieldId,
    (fieldId, nodesByFieldId) => {
        return Boolean(nodesByFieldId[fieldId]?.parent_id);
    },
);
