import {
    setAssignmentExpandedGroupId,
    setSubassignmentCheckedGroups,
    setSubassignmentCheckedItems,
} from 'modules/subassignmentManagement/components/SubassignmentTable/store/actions';
import {
    EmploymentManagementActionsType,
    getMoreSubassignmentsAction,
    initialLoadSubassignments,
    resetSubassignmentsTable,
    setSubassignmentFilters,
    setHighlightedAssignmentIds,
    setExpandAllSubassignments,
} from 'modules/subassignmentManagement/store/actions';
import { IAssignmentWithSubassignmentLinks } from 'modules/subassignmentManagement/store/models';
import { combineReducers } from 'redux';
import { defaultInfinityScrollState, IInfinityScrollState, singleValueReducer } from 'store/reducerUtils';

const checkedItems = singleValueReducer(setSubassignmentCheckedItems.action, {});
const checkedGroups = singleValueReducer(setSubassignmentCheckedGroups.action, {});
const expandedGroupId = singleValueReducer<string | null>(setAssignmentExpandedGroupId.action, null);
const expandAllSubassignments = singleValueReducer<boolean>(setExpandAllSubassignments.action, false);

const highlightAssignmentIds = singleValueReducer(setHighlightedAssignmentIds.action, []);

function table(
    state: IInfinityScrollState<IAssignmentWithSubassignmentLinks>,
    action: EmploymentManagementActionsType,
): IInfinityScrollState<IAssignmentWithSubassignmentLinks> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case resetSubassignmentsTable.action:
            return defaultInfinityScrollState;
        case initialLoadSubassignments.action:
        case setSubassignmentFilters.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
                cursor: null,
            };
        case getMoreSubassignmentsAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreSubassignmentsAction.successType:
            return {
                // @ts-ignore
                total: action.payload.total,
                // @ts-ignore
                items: [...state.items, ...action.payload.assignments],
                isLoading: false,
                // @ts-ignore
                cursor: action.payload.cursor,
            };
        default:
            return state;
    }
}

export const subassignmentTable = combineReducers({
    checkedItems,
    checkedGroups,
    expandedGroupId,
    table,
    highlightAssignmentIds,
    expandAllSubassignments,
});
