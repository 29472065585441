import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { selectClientRoles, selectIsClientRolesLoading } from 'store/entities/users/selectors';

interface IClientRoleSelectProps extends IFormFieldProps, IFormSelect {
    clientId?: string | null;
    blockNameList?: string[];
}

export default function ClientRoleSelect({ clientId, blockNameList = [], ...props }: IClientRoleSelectProps) {
    let roles = Object.values(useSelector(selectClientRoles)).filter(
        // @ts-ignore
        role => (role.client_ids.includes(clientId) || role.all_clients) && !blockNameList?.includes(role.name),
    );
    roles = roles.filter(role => {
        // Override common roles with client related that have the same name
        if (role.all_clients) {
            const alternativeRole = roles.find(
                item => role.id !== item.id
                    && item.name === role.name
                    // @ts-ignore
                    && item.client_ids.includes(clientId),
            );
            return !alternativeRole;
        }
        return true;
    });

    const isRolesLoading = useSelector(selectIsClientRolesLoading);

    return (
        <FormSelect
            getKey={role => role.id}
            getText={role => role.name}
            options={roles}
            isLoading={isRolesLoading}
            {...props}
        />
    );
}
