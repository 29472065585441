import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { registrationRedirect } from 'store/components/auth/authActions';
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';

export function RegistrationRedirect() {
    const location = useLocation();
    const dispatch = useDispatch();
    const searchParams = qs.parse(location.search);

    useEffect(() => {
        dispatch(registrationRedirect(searchParams));
    }, [dispatch, searchParams]);

    return null;
}
