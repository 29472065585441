import {
    INewPasswordAfterResetingRequest,
    INewPasswordCodeCheckingRequest,
    IPasswordResetingRequest,
} from 'store/entities/users/model';
import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';

export const PASSWORD_RESETTING = 'auth/PASSWORD_RESETTING';
export const PASSWORD_RESETTING_SUCCESS = 'auth/PASSWORD_RESETTING_SUCCESS';
export const PASSWORD_RESETTING_ERROR = 'auth/PASSWORD_RESETTING_ERROR';

export const passwordResetting = createActions<
IPasswordResetingRequest,
string,
any,
typeof PASSWORD_RESETTING,
typeof PASSWORD_RESETTING_SUCCESS,
typeof PASSWORD_RESETTING_ERROR
>(
    PASSWORD_RESETTING,
    PASSWORD_RESETTING_SUCCESS,
    PASSWORD_RESETTING_ERROR,
);

export const NEW_PASSWORD_AFTER_RESETTING = 'auth/NEW_PASSWORD_AFTER_RESETTING';
export const NEW_PASSWORD_AFTER_RESETTING_SUCCESS = 'auth/NEW_PASSWORD_AFTER_RESETTING_SUCCESS';
export const NEW_PASSWORD_AFTER_RESETTING_ERROR = 'auth/NEW_PASSWORD_AFTER_RESETTING_ERROR';

export const newPasswordAfterResetting = createActions<
INewPasswordAfterResetingRequest,
void,
any,
typeof NEW_PASSWORD_AFTER_RESETTING,
typeof NEW_PASSWORD_AFTER_RESETTING_SUCCESS,
typeof NEW_PASSWORD_AFTER_RESETTING_ERROR
>(
    NEW_PASSWORD_AFTER_RESETTING,
    NEW_PASSWORD_AFTER_RESETTING_SUCCESS,
    NEW_PASSWORD_AFTER_RESETTING_ERROR,
);

export const NEW_PASSWORD_CODE_CHECKING = 'auth/NEW_PASSWORD_CODE_CHECKING';
export const NEW_PASSWORD_CODE_CHECKING_SUCCESS = 'auth/NEW_PASSWORD_CODE_CHECKING_SUCCESS';
export const NEW_PASSWORD_CODE_CHECKING_ERROR = 'auth/NEW_PASSWORD_CODE_CHECKING_ERROR';

export const newPasswordCodeChecking = createActions<
INewPasswordCodeCheckingRequest,
void,
any,
typeof NEW_PASSWORD_CODE_CHECKING,
typeof NEW_PASSWORD_CODE_CHECKING_SUCCESS,
typeof NEW_PASSWORD_CODE_CHECKING_ERROR
>(
    NEW_PASSWORD_CODE_CHECKING,
    NEW_PASSWORD_CODE_CHECKING_SUCCESS,
    NEW_PASSWORD_CODE_CHECKING_ERROR,
);

export const PASSWORD_RESETTING_CLEAR_MESSAGE = 'auth/PASSWORD_RESETTING_CLEAR_MESSAGE';
export const passwordResettingClearMessage = createSingleAction<void, typeof PASSWORD_RESETTING_CLEAR_MESSAGE>(
    PASSWORD_RESETTING_CLEAR_MESSAGE,
);

export type ResettingPasswordActions =
ActionsReturnTypes<typeof passwordResetting>
| ActionsReturnTypes<typeof newPasswordAfterResetting>
| ActionsReturnTypes<typeof newPasswordCodeChecking>
| ReturnType<typeof passwordResettingClearMessage>
