import React from 'react';
import { Button } from '@material-ui/core';

import { CreateSubmittingOrgModal } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/CreateSubmittingOrgModal';
import { useReduxModal } from 'shared/utils/hooks/useModal';
import { setCreateSubmittingOrgModalState } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/actions';
import { selectIsSubmittingOrgModalOpen } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/selectors';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const CreateSubmittingOrgButton = () => {
    const classes = useColoredFormModalStyles();
    const { isModalOpened,
        openModal,
        closeModal } = useReduxModal(selectIsSubmittingOrgModalOpen, setCreateSubmittingOrgModalState);

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + ADD NEW
            </Button>
            <CreateSubmittingOrgModal
                title="Create New Submitting Org"
                isOpened={isModalOpened}
                onClose={closeModal}
                customClasses={classes}
                showCloseIcon
            />
        </>
    );
};
