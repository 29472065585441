import { useField } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { backendDateFormat } from 'shared/models/Dates';
import { IJobNumber } from 'shared/models/JobNumber';
import { IMenuItemType } from 'shared/models/MenuItemType';
import { isDayInPeriod } from 'shared/utils/helpers/isDayInPeriod';
import { useAllowFutureDayEntry, useDisallowPastDayEntry } from 'store/entities/clients/hooks';
import { ISubassignment } from 'store/entities/configuration/configurationModel';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { moment } from 'utils/momentExtensions';

interface IDayProps extends IFormFieldProps {
    payPeriod: IPayPeriod | null;
    jobNumber?: IJobNumber | null;
    subassignments?: ISubassignment[] | null;
}

export default function DaySelect({ payPeriod, jobNumber, subassignments, ...props }: IDayProps) {
    const allowFutureDayEntry = useAllowFutureDayEntry();
    const disallowPastDayEntry = useDisallowPastDayEntry();
    const [field,, helpers] = useField(props.name);
    const weekDaysMenuTimes: IMenuItemType<string>[] = useMemo(() => {
        const startDate = disallowPastDayEntry ? moment(moment().format('YYYY-MM-DD')).toDate() : moment(payPeriod?.period_start);
        const payPeriodRange = (startDate > moment(payPeriod?.period_end))
            ? null
            : moment.range(startDate, moment(payPeriod?.period_end));

        if (!payPeriodRange) {
            return [];
        }
        return [...payPeriodRange.by('day')]
            .filter(day => {
                let filtered = true;
                if (jobNumber) {
                    filtered = filtered && isDayInPeriod(jobNumber)(day);
                }
                if (subassignments) {
                    filtered = filtered && subassignments.some(subassignment => isDayInPeriod(subassignment)(day));
                }
                if (!allowFutureDayEntry) {
                    filtered = filtered && moment().isSameOrAfter(day);
                }
                return filtered;
            })
            .map(day => ({
                text: day.format('dddd M/D/YY'),
                value: day.format(backendDateFormat),
            }));
    }, [payPeriod?.period_start, payPeriod?.period_end, jobNumber, subassignments, allowFutureDayEntry,
        disallowPastDayEntry]);

    useEffect(() => {
        const availableValues = weekDaysMenuTimes.map(item => item.value);
        if (field.value && availableValues.length && !availableValues.includes(field.value)) {
            helpers.setValue('');
        }
    }, [field.value, weekDaysMenuTimes, helpers]);

    return (
        <FormSelect
            {...props}
            useIdValue
            options={weekDaysMenuTimes}
            getKey={(item: IMenuItemType<string>) => item.value}
            getText={(item: IMenuItemType<string>) => item.text}
            isNullable={false}
        />
    );
}
