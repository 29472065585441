import React, { useMemo } from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';
import { IOfferLetterTemplate } from 'modules/offerLetter/store/templates/models';
import {
    selectActiveOfferLetterTemplatesSortedByName,
    selectOfferLetterTemplatesLoading,
} from 'modules/offerLetter/store/templates/selectors';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';

interface IOfferLetterTemplateSelectProps extends IFormFieldProps, IFormSelect {

}

interface IOfferLetterTemplateSelectStoreProps {
    offerLetterTemplates?: IOfferLetterTemplate[];
    offerLetterTemplatesLoading: boolean;
}

export function OfferLetterTemplateSelectPure({
    offerLetterTemplates,
    offerLetterTemplatesLoading,
    ...props
}: IOfferLetterTemplateSelectProps & IOfferLetterTemplateSelectStoreProps) {
    return (
        <FormSelect
            getKey={(offerLetterTemplate: IOfferLetterTemplate) => offerLetterTemplate.id}
            getText={(offerLetterTemplate: IOfferLetterTemplate) => offerLetterTemplate.name}
            options={offerLetterTemplates || []}
            isLoading={offerLetterTemplatesLoading}
            {...props}
        />
    );
}

export const OfferLetterTemplateSelect = separateLogicDecorator<
IOfferLetterTemplateSelectProps,
IOfferLetterTemplateSelectStoreProps
>(() => {
    const clientId = useSelector(selectCurrentClientId);
    const offerLetterTemplates = useSelector(selectActiveOfferLetterTemplatesSortedByName);

    const filteredOfferLetterTemplates = useMemo(() => {
        return clientId === null ? offerLetterTemplates : offerLetterTemplates.filter(
            template => template.all_clients || template.client_ids.includes(clientId),
        );
    }, [clientId, offerLetterTemplates]);

    return {
        offerLetterTemplates: filteredOfferLetterTemplates,
        offerLetterTemplatesLoading: useSelector(selectOfferLetterTemplatesLoading),
    };
})(OfferLetterTemplateSelectPure);
