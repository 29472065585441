import { useDispatch, useSelector } from 'react-redux';
import { selectDetailAssignment } from '../../../subassignmentManagement/components/AssignmentInfo/store/selectors';
import { isAssignmentActive } from '../../../../shared/utils/helpers/assingmentsHelper';
import { IAssignment } from '../../../../store/entities/configuration/configurationModel';
import { Button, Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { setEndAssignmentId } from '../EndAssignment/store/action';
import { EndAssignmentSidebar, EndOfAssignmentSidebarMode } from '../EndAssignment/EndAssignmentSidebar';

export const AssignmentEditEndDateButtons = () => {
    const dispatch = useDispatch();
    const assignment = useSelector(selectDetailAssignment);
    const [sidebarMode, setSidebarMode] = useState(EndOfAssignmentSidebarMode.EDIT_CLOSED_ASSIGNMENT_END_DATE);
    const [sidebarText, setSidebarText] = useState('Edit Assignment End Date');

    const isActiveAssignment = Boolean(assignment) && isAssignmentActive(assignment as IAssignment);

    const openAssignmentEditEndDateSidebar = useCallback(() => {
        setSidebarMode(EndOfAssignmentSidebarMode.EDIT_CLOSED_ASSIGNMENT_END_DATE);
        setSidebarText('Edit Assignment End Date');
        if (assignment?.id) {
            dispatch(setEndAssignmentId(assignment.id));
        }
    }, [dispatch, assignment]);

    const openReopenSidebar = useCallback(() => {
        setSidebarMode(EndOfAssignmentSidebarMode.REOPEN_ASSIGNMENT);
        setSidebarText('Re-Open Assignment');
        if (assignment?.id) {
            dispatch(setEndAssignmentId(assignment.id));
        }
    }, [dispatch, assignment]);

    return (
        <>
            {!isActiveAssignment && (
                <Grid item>
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={openAssignmentEditEndDateSidebar}>
                        Edit End Date
                    </Button>
                </Grid>
            )}
            {!isActiveAssignment && (
                <Grid item>
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={openReopenSidebar}>
                        Reopen Assignment(s)
                    </Button>
                </Grid>
            )}
            {!isActiveAssignment && (
                <EndAssignmentSidebar
                    sideBarTitle={sidebarText}
                    mode={sidebarMode}
                />
            )}
        </>
    );
};
