import { IStore } from 'store/configureStore';
import { selectTimeAndExpenseState } from 'modules/timeAndExpense/store/selectors';

const selectEditEntryState = (state: IStore) => {
    const timeAndExpenseState = selectTimeAndExpenseState(state);
    return timeAndExpenseState.editEntry;
};

export const selectEditEntryId = (state: IStore) => selectEditEntryState(state).editEntryId;
export const selectIsEntryUpdating = (state: IStore) => selectEditEntryState(state).isEntryUpdating;
export const selectIsEntryRemoving = (state: IStore) => selectEditEntryState(state).isEntryRemoving;
