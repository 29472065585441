import { createScope, setCreateScopeModalState, setEditScopeId, updateScope } from 'modules/settings/submodules/clients/scopes/store/actions';
import { combineReducers } from 'redux';
import { deleteScopes } from 'store/entities/scopes/actions';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

const isDeleting = isLoadingReducer(deleteScopes);
const isCreating = isLoadingReducer(createScope);
const isUpdating = isLoadingReducer(updateScope);

const createModalState = singleValueReducer(setCreateScopeModalState.action, false);
const editScopeId = singleValueReducer(setEditScopeId.action, null);

export const scopes = combineReducers({
    isDeleting,
    isCreating,
    isUpdating,
    createModalState,
    editScopeId,
});
