import { combineReducers } from 'redux';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import { setEditCustomFieldId, updateCustomField } from 'modules/settings/submodules/customFields/store/actions';

const editId = singleValueReducer<string | null>(setEditCustomFieldId.action, null);
const isUpdating = isLoadingReducer(updateCustomField);

export const editCustomField = combineReducers({
    editId,
    isUpdating,
});
