import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';
import { api } from './api';
import { associateDealTypePayCodeArBucket } from './actions';
import { getDealTypePayCodeArBuckets } from 'store/entities/configuration/configurationAction';

function* associateDealTypePayCodeArBucketSaga({ payload }: ReturnType<typeof associateDealTypePayCodeArBucket.init>) {
    const result = yield* call(api.associateDealTypePayCodeArBucket, payload);
    yield put(associateDealTypePayCodeArBucket.success(result));
    yield put(getDealTypePayCodeArBuckets.success([result]));
}

export function* associateDealTypePayCodeArBucketWatcher() {
    yield* takeLatest(
        associateDealTypePayCodeArBucket.initType,
        withBackendErrorHandler(
            associateDealTypePayCodeArBucketSaga,
            associateDealTypePayCodeArBucket.error,
            'Unable to link pay code, deal type and AR bucket',
        ),
    );
}

export const associateDealTypePayCodeArBucketSagas = [
    associateDealTypePayCodeArBucketWatcher,
];
