import { PositionWithPayRateCell } from 'modules/eafApproval/components/cells/common/PositionWithPayRateCell';
import React from 'react';
import { Box } from '@material-ui/core';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import {
    EafType,
    IEafCompensationChangeData, IEafPromotionDemotion,
} from 'modules/employeeActionsForm/store/models';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import PlainText from 'shared/components/table/Cells/PlainText';

export const EafApprovalGridCellNewValue: React.FC<EafApprovalCellProps> = ({
    className,
    employee_action_form_data: data,
}: EafApprovalCellProps) => {
    if (data.employee_action_form_type === EafType.CompensationChange) {
        const { new_base_pay_rate } = data as IEafCompensationChangeData;
        return (
            <PlainText
                value={formatDollars(new_base_pay_rate)}
                className={className}
            />
        );
    }

    if (
        data.employee_action_form_type === EafType.Promotion
        || data.employee_action_form_type === EafType.Demotion
    ) {
        const { new_position_custom_field_value_id, new_base_pay_rate } = data as IEafPromotionDemotion;
        return (
            <PositionWithPayRateCell
                payRate={new_base_pay_rate}
                positionCustomFieldValueId={new_position_custom_field_value_id}
                className={className}
            />
        );
    }

    return (
        <Box className={className}>–</Box>
    );
};
