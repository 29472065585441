import { put, takeLatest } from 'typed-redux-saga';
import { selectExpenseStatusByName } from 'store/entities/timesheet/selectors';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { getExpenseReviewerSheets } from 'store/components/expensesApproval/expensesApprovalActions';
import { getReviewerSheetSaga } from 'store/components/sheetApprovalCommon/sheetApprovalCommonSagas';

function* getExpenseReviewerSheetsSaga({ payload: statusName }: ReturnType<typeof getExpenseReviewerSheets.init>) {
    yield getReviewerSheetSaga(
        loadExpenseSheetsWithEntries,
        selectExpenseStatusByName,
        statusName,
        { is_travel: false },
    );

    yield put(getExpenseReviewerSheets.success());
}

function* getExpenseReviewerSheetsWatcher() {
    yield* takeLatest(getExpenseReviewerSheets.initType, getExpenseReviewerSheetsSaga);
}

export default [
    getExpenseReviewerSheetsWatcher,
];
