import { HeadwayServices } from 'shared/models/Services';
import baseApi from 'shared/utils/baseApi';
import { withClientId } from 'store/utils/withClientId';
import {
    ICreateEaf,
    IEafGroupedResponse,
    IEafReason,
    IEafRequest,
    IEmployeeActionForm,
    IGetMaxEntryDateResponse,
} from 'modules/employeeActionsForm/store/models';

export const eafApi = {
    async getGroupedEmployeeActionForms(
        request: IEafRequest,
    ): Promise<IEafGroupedResponse> {
        const { data } = await baseApi.post<IEafRequest, IEafGroupedResponse>(
            `/client-users/employee_action_forms/grouped/search`,
            withClientId(request),
        );
        return data;
    },
    async getEafReasons(): Promise<IEafReason[]> {
        const { data } = await baseApi.get<{ reasons: IEafReason[] }>(
            `/client-users/employee_action_forms/reasons`,
        );
        return data.reasons;
    },
    async createEmployeeActionForm(
        request: ICreateEaf,
    ): Promise<IEmployeeActionForm> {
        const { data } = await baseApi.post<ICreateEaf, IEmployeeActionForm>(
            `/${HeadwayServices.ClientUsers}/employee_action_forms`,
            request,
        );
        return data;
    },
    async getAssignmentMaxEntryDate(
        assignmentId: string,
    ): Promise<IGetMaxEntryDateResponse> {
        const { data } = await baseApi.get<IGetMaxEntryDateResponse>(
            `/sheets/coordinator/assignments/${assignmentId}/max_entry_date`,
        );
        return data;
    },
};
