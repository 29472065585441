import baseApi from 'shared/utils/baseApi';
import { INotification, IUpdateNotification } from 'modules/notificationCenter/store/model';
import { ISheetGroupIdRequest } from '../../payrollProcessorHub/store/model';

const notificationsServiceUrl = 'notifications';

export const notificationApi = {
    async getNotifications(): Promise<INotification[]> {
        const { data } = await baseApi.get<{ user_dashboard_notifications: INotification[] }>(
            `/${notificationsServiceUrl}/user_dashboard_notifications?sort=["created_at","desc"]&filters={"viewed_at":{"is":null}}`,
        );

        return data.user_dashboard_notifications;
    },
    async updateNotifications(updateNotifications: IUpdateNotification[]): Promise<INotification[]> {
        const params = {
            user_dashboard_notifications: updateNotifications,
        };

        const { data } = await baseApi.patch<
        { user_dashboard_notifications: IUpdateNotification[] },
        { user_dashboard_notifications: INotification[] }
        >(
            `${notificationsServiceUrl}/user_dashboard_notifications`, params,
        );

        return data.user_dashboard_notifications;
    },
    async sheetReminders(groupId: ISheetGroupIdRequest) {
        const { data } = await baseApi.post<ISheetGroupIdRequest, { status: string }>(
            `${notificationsServiceUrl}/sheet_reminders`, groupId,
        );

        return data;
    },
};
