import { selectJobNumbersState } from 'modules/settings/submodules/clients/jobNumber/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectJobNumbersById } from 'store/entities/configuration/configurationSelectors';

const selectEditJobNumberState = (state: IStore) => selectJobNumbersState(state).editJobNumber;

export const selectIsJobNumberUpdating = (state: IStore) => selectEditJobNumberState(state).isUpdating;
export const selectEditJobNumberId = (state: IStore) => selectEditJobNumberState(state).jobNumberId;
export const selectEditedJobNumber = createSelector(
    selectJobNumbersById,
    selectEditJobNumberId,
    (jobNumbersById, editId) => {
        return jobNumbersById[editId || ''];
    },
);
