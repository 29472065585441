import { useEmployeeGroupedSheetCells } from 'modules/timeAndExpense/components/GroupedSheetsTable/employeeCells';
import { ISheetsSubmittedViewPureProps, SheetsSubmittedViewPure } from 'modules/timeAndExpense/components/SheetsSubmittedView/SheetsSubmittedViewPure';
import { expenseSheetsSelectors, selectCheckedSheetWithTypes, timeSheetsSelectors } from 'modules/timeAndExpense/store/selectors';
import { useSelector } from 'react-redux';
import { IEntity } from 'shared/models/Entity';
import { EntryType, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectSheetsIsLoading } from 'store/entities/timesheet/selectors';

interface ISheetsSubmittedGroupedViewProps {
    onDetailsClick?: (sheetInfo: ISheetClickInfo) => void;
}

interface IRow extends IEntity {}

export const SheetsSubmittedGroupedView = separateLogicDecorator<
ISheetsSubmittedGroupedViewProps,
ISheetsSubmittedViewPureProps<IRow>
>(() => {
    const timeRows = useSelector(timeSheetsSelectors.selectGroupedSheetRows);
    const expenseRows = useSelector(expenseSheetsSelectors.selectGroupedSheetRows);
    const timeCells = useEmployeeGroupedSheetCells(EntryType.TIME);
    const expenseCells = useEmployeeGroupedSheetCells(EntryType.EXPENSE);
    const isLoading = useSelector(selectSheetsIsLoading);
    const sheetsEntryTypes = useSelector(selectCheckedSheetWithTypes);

    return {
        timeRows,
        expenseRows,
        timeCells,
        expenseCells,
        isLoading,
        sheetsEntryTypes,
    };
})(SheetsSubmittedViewPure);
