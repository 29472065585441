import { IStore } from 'store/configureStore';
import { FilterSlug, IFiltersConfiguration } from 'store/entities/clients/clientsModel';
import { ActionCreatorKnownArgs } from 'store/utils';
import { EntryType } from 'shared/models/sheet/Sheet';
import { ICustomFieldValue } from 'store/entities/customFields/model';

export enum FiltersNames {
    Area = 'area_id',
    Assignment = 'assignment_id',
    LocationByAssignment = 'location_by_assignment',
    PositionByAssignment = 'position_by_assignment',
    Task = 'task_id',
    Activity = 'activity_id',
    JobNumber = 'job_number_id',
    Location = 'location_id',
    Position = 'position_id',
    User = 'user_id',
    PayPeriod = 'pay_period',
    CustomFieldValues = 'customFieldValues',
}

export interface IFiltersAllOptions {
    [FiltersNames.Activity]: string;
    [FiltersNames.JobNumber]: string;
    [FiltersNames.Area]: string;
    [FiltersNames.Assignment]: string;
    [FiltersNames.Task]: string;
    [FiltersNames.Position]: string;
    [FiltersNames.Location]: string;
    [FiltersNames.User]: string;
    [FiltersNames.PayPeriod]: string;
}

export type SelectFiltersConfiguration = (state: IStore) => Record<FilterSlug, IFiltersConfiguration>;
export type SelectFilters = (state: IStore) => Partial<IFiltersAllOptions>;

export interface IFilterStoreProps {
    selectFiltersConfig: SelectFiltersConfiguration;
    selectFilters: SelectFilters;
    submitFilters: ActionCreatorKnownArgs<
    Partial<IFiltersAllOptions>, { type: string; payload: Partial<IFiltersAllOptions> }
    >;
    entryType?: EntryType;
    tableFieldsWithValuesById?: Record<string, ICustomFieldValue[]>,
    isManager?: boolean;
}
