import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const sidebarFormStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
    },
    input: {
        margin: theme.spacing(1, 0),
        display: 'flex',
        flexGrow: 1,
    },
    button: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
    inlineInputLabel: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(1),
    },
}));
