import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Close, Publish } from '@material-ui/icons';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useUploadStyles } from 'shared/components/attachments/styles';

interface IImageUploadWithCropProps extends IFormFieldProps {
    initialCrop?: Crop;
    maxFileSizeMB?: number; // MB
}

const bytesInMb = 1024 * 1024;

export const ImageUploadWithCrop = ({
    name,
    initialCrop = { unit: '%', width: 100, aspect: 1 },
    maxFileSizeMB = 10,
}: IImageUploadWithCropProps) => {
    const [field, meta, helper] = useField(name);
    const classes = useUploadStyles();
    const [sourceImg, setSourceImg] = useState(field.value);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [crop, setCrop] = useState<Crop>(initialCrop);
    const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
    const maxFileSize = maxFileSizeMB * bytesInMb;

    const onDrop = useCallback((uploadedFiles: File[]) => {
        uploadedFiles.forEach(file => {
            const reader = new FileReader();
            reader.addEventListener('load', () => setSourceImg(reader.result));
            reader.readAsDataURL(file);
        });
    }, [setSourceImg]);

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, [imgRef]);

    const onClear = useCallback(() => {
        setSourceImg(undefined);
        imgRef.current = null;
        setCompletedCrop(null);
        helper.setValue(null);
    }, [setSourceImg, imgRef, setCompletedCrop, helper]);

    useEffect(() => {
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;

        if (completedCrop && image && canvas) {
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const ctx = canvas.getContext('2d');
            const pixelRatio = window.devicePixelRatio;

            // @ts-ignore
            canvas.width = completedCrop.width * pixelRatio;
            // @ts-ignore
            canvas.height = completedCrop.height * pixelRatio;

            if (ctx) {
                ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                ctx.imageSmoothingQuality = 'high';

                ctx.drawImage(
                    image,
                    // @ts-ignore
                    completedCrop.x * scaleX,
                    // @ts-ignore
                    completedCrop.y * scaleY,
                    // @ts-ignore
                    completedCrop.width * scaleX,
                    // @ts-ignore
                    completedCrop.height * scaleY,
                    0,
                    0,
                    // @ts-ignore
                    completedCrop.width,
                    completedCrop.height,
                );
            }
        }
    }, [completedCrop]);

    const {
        getRootProps, getInputProps, isDragReject, rejectedFiles,
    } = useDropzone({
        accept: 'image/*',
        onDrop,
        noDrag: true,
        multiple: false,
        maxSize: maxFileSize,
    });

    useEffect(() => {
        if (previewCanvasRef.current && completedCrop) {
            const imageBase64 = previewCanvasRef.current.toDataURL('image/png');
            if (field.value !== imageBase64) {
                helper.setValue(imageBase64);
            }
        }
    }, [previewCanvasRef, completedCrop, helper, field.value]);
    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles.some(file => file.size > maxFileSize);
    const hasError = Boolean(meta.error && meta.touched) || isDragReject || isFileTooLarge;
    const errorText = isFileTooLarge ? 'File is too large.'
        : isDragReject ? 'Please upload image file.' : meta.error;

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
        >
            {!sourceImg && (
                <div {...getRootProps()} className={classes.fileDropzone}>
                    <div className={classes.uploadHeader}>
                        <Publish fontSize="small" classes={{ root: classes.uploadIcon }}/>
                        <Typography
                            className={classes.uploadHeaderTitle}
                            color="primary"
                            variant="subtitle2"
                        >
                            Upload Image
                        </Typography>
                    </div>
                    <input {...getInputProps() }/>
                    {hasError && (
                        <Typography
                            className={classes.uploadError}
                            color="primary"
                            variant="body1"
                        >
                            {errorText}
                        </Typography>
                    )}
                </div>
            )}
            {sourceImg && (
                <>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            // @ts-ignore
                            classes={{ root: classes.closeIcon }}
                            onClick={onClear}
                        >
                            <Close/>
                        </IconButton>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <ReactCrop
                            src={sourceImg}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={c => setCrop(c)}
                            onComplete={c => setCompletedCrop(c)}
                            crossorigin="anonymous"
                            style={{
                                maxWidth: 350,
                                maxHeight: 350,
                            }}
                        />
                    </Box>
                </>
            )}
            <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                    display: 'none',
                }}
            />
        </Box>
    );
};
