import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from 'store/rootSaga';
import rootReducer from 'store/rootReducer';

export type IStore = ReturnType<typeof rootReducer>

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState?: Partial<IStore>) {
    const middlewares: Middleware[] = [sagaMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeEnhancers(...enhancers);
    const store = createStore(rootReducer, preloadedState, composedEnhancers);
    sagaMiddleware.run(rootSaga);

    return store;
}
