import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { ActivityTag } from 'store/entities/configuration/configurationModel';

interface IActivityTagSelectProps extends IFormFieldProps, IFormSelect {
    label: string;
    tag: ActivityTag;
    tagLabel: string;
    show?: boolean;
}
interface IActivityTagSelectOption {
    key: string;
    text: string;
}

export default function ActivityTagSelect({ label, tag, tagLabel, show = true, ...props }: IActivityTagSelectProps) {
    const options: IActivityTagSelectOption[] = [
        {
            key: tag,
            text: tagLabel,
        },
    ];

    return (
        <>
            {show && (
                <FormSelect
                    {...props}
                    label={label}
                    useIdValue
                    getKey={(option: IActivityTagSelectOption) => option.key}
                    getText={(option: IActivityTagSelectOption) => option.text}
                    options={options}
                />
            )}
        </>
    );
}
