import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useEafFormStyles = makeHighPriorityStyles(theme => ({
    row: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: theme.spacing(1),
        '& > label': {
            display: 'flex',
            width: 130,
        },
    },
    inlineRadio: {
        '& > div': {
            display: 'block',
        },
    },
    fileInputWrapper: {
        width: '100%',
    },
    fileInput: {
        width: '100%',
    },
}));
