import { DateBackend } from 'shared/models/Dates';
import { INamedEntity } from 'shared/models/Entity';
import { IClient } from 'store/entities/clients/clientsModel';
import { Status } from 'shared/models/Status';

export interface IOfferLetterTemplateBackend extends INamedEntity{
    status: Status;
    created_at: DateBackend;
    updated_at: DateBackend;
    filename: string;
    client_ids: Array<string>,
    all_clients: boolean;
    description: string | null;
}

export interface IOfferLetterTemplate extends Omit<IOfferLetterTemplateBackend, 'updated_at' | 'created_at'> {
    createdAt: DateBackend;
}

export interface IOfferLetterTemplateWithClient extends Omit<IOfferLetterTemplate, 'client_ids'> {
    clients: Array<IClient>
}

export const mapOfferLetterTemplateFromBackend = ({
    id,
    name,
    status,
    description,
    ...offerLetterTemplate
}: IOfferLetterTemplateBackend): IOfferLetterTemplate => {
    return {
        id,
        name,
        status,
        client_ids: offerLetterTemplate.client_ids,
        all_clients: offerLetterTemplate.all_clients,
        createdAt: offerLetterTemplate.created_at,
        filename: offerLetterTemplate.filename,
        description: description || null,
    };
};
