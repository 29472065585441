/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectJobNumbersFilter } from 'modules/settings/submodules/clients/jobNumber/store/selectors';
import { setJobNumberFilter } from 'modules/settings/submodules/clients/jobNumber/store/actions';
import { IJobNumberFilter } from 'modules/settings/submodules/clients/jobNumber/store/models';
import { JobNumberFilterForm } from 'modules/settings/submodules/clients/jobNumber/components/Filter/JobNumberFilterForm';

export const JobNumberFilter = () => {
    const dispatch = useDispatch();
    const initialValues = useSelector(selectJobNumbersFilter);
    const onChange = useCallback((values: Partial<IJobNumberFilter>) => {
        // @ts-ignore
        dispatch(setJobNumberFilter(values));
    }, [dispatch]);
    return (
        <Box>
            <Formik
                initialValues={initialValues}
                onSubmit={() => {}}
                validateOnBlur={false}
            >
                {props => (
                    <JobNumberFilterForm
                        {...props}
                        onChange={onChange}
                        actualFilterValues={initialValues}
                    />
                )}
            </Formik>
        </Box>
    );
};
