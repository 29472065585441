import { createRequestActions, RequestType } from 'store/utils';
import { IDealTypeArBucket } from 'shared/models/DealTypeArBucket';
import { IDealTypePayCodeArBucketForm } from '../model/formModel';

export const associateDealTypePayCodeArBucket = createRequestActions<
IDealTypePayCodeArBucketForm,
IDealTypeArBucket
>(
    RequestType.Create,
    'DEAL_TYPES_PAY_CODE_AR_BUCKETS',
);
