import { call, put, takeLatest } from 'typed-redux-saga';
import { getControllingOrgs } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { updateControllingOrg } from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/actions';
import { api } from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/api';

function* updateControllingOrgSaga(action: ReturnType<typeof updateControllingOrg.init>) {
    const { id, data } = action.payload;
    const result = yield* call(api.updateControllingOrg, id, data);
    yield put(updateControllingOrg.success(result));
    yield put(getControllingOrgs.success([result]));
}

export function* updateControllingOrgWatcher() {
    yield* takeLatest(
        updateControllingOrg.initType,
        withBackendErrorHandler(
            updateControllingOrgSaga,
            updateControllingOrg.error,
            'Unable to update Controlling Org.',
        ),
    );
}
