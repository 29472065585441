import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash-es';
import moment from 'moment';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { useLogoutAction } from 'shared/utils/hooks/useLogoutAction';
import { authTokenUpdate } from 'store/components/auth/authActions';
import { selectIsAuthenticated } from 'store/components/auth/selectors';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { millisecondsInMinute, millisecondsInSecond } from 'utils/constants';

const defaultInactiveTimeoutMinutes = 10;
const defaultLogoutTimeoutMinutes = 2;
const pollingTimeout = 5 * millisecondsInSecond;
const warningTimeout = defaultInactiveTimeoutMinutes * millisecondsInMinute;
const logoutTimeout = defaultLogoutTimeoutMinutes * millisecondsInMinute;
const lastActionStorageKey = 'lastUserActionAt';
const coundownIsStartedStorageKey = 'logoutCoundownIsStarted';
const logoutCoundownStorageKey = 'logoutCoundown';
const userShouldLogoutStorageKey = 'userShouldLogout';

/**
 * Detect user inactivity, show popup warning and auto logout
 */
export const InactivityListener = () => {
    const dispatch = useDispatch();
    const enableListener = useSelector(selectIsAuthenticated);
    const [isShowPopup, _setPopupShow] = useState(false);
    const [countDown, _setCountDown] = useState(0);
    const logout = useLogoutAction();

    const setPopupIsShow = useCallback((value: boolean) => {
        _setPopupShow(value);
        localStorage.setItem(coundownIsStartedStorageKey, value.toString());
    }, [_setPopupShow]);
    const setCountDown = useCallback((value: number) => {
        const storageCountDown = Number(localStorage.getItem(logoutCoundownStorageKey)) || logoutTimeout;
        const newValue = Math.min(storageCountDown, value);
        _setCountDown(newValue);
        localStorage.setItem(logoutCoundownStorageKey, newValue.toString());
    }, [_setCountDown]);

    const closePopup = useCallback(() => {
        setPopupIsShow(false);
        dispatch(authTokenUpdate.init());
    }, [setPopupIsShow, dispatch]);
    const setUserShouldLogoutOnAllTabs = useCallback((value: boolean) => {
        localStorage.setItem(userShouldLogoutStorageKey, value.toString());
    }, []);
    const logoutCallback = useCallback(() => {
        setUserShouldLogoutOnAllTabs(true);
        closePopup();
        dispatch(logout());
    }, [dispatch, closePopup, setUserShouldLogoutOnAllTabs, logout]);
    const logoutWithToast = useCallback(() => {
        logoutCallback();
        dispatch(setGlobalToast({
            title: 'For your safety, we logged you out automatically after 10 minutes of inactivity.',
            severity: IModalSeverity.Info,
            autoHideDuration: null,
            ignoreSidebar: true,
        }));
    }, [dispatch, logoutCallback]);

    useEffect(() => {
        /**
         * Count down timer
         */
        let logoutCountdown: NodeJS.Timeout | undefined;
        if (isShowPopup && enableListener) {
            if (countDown > 0) {
                logoutCountdown = setInterval(() => {
                    setCountDown(countDown - millisecondsInSecond);
                }, millisecondsInSecond);
            } else {
                logoutWithToast();
            }
        }

        return () => {
            if (logoutCountdown) {
                clearInterval(logoutCountdown);
            }
        };
    }, [countDown, setCountDown, logoutWithToast, isShowPopup, enableListener]);

    useEffect(() => {
        if (isShowPopup && !enableListener) {
            closePopup();
        }
    }, [isShowPopup, enableListener, closePopup]);

    useEffect(() => {
        setPopupIsShow(false);
        setCountDown(0);
        setUserShouldLogoutOnAllTabs(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        /**
         * Sync count down with other tabs
         * We will assume that the source of truth is the values in the storage
         * because actions on other tabs logged to storage
         */
        const storageIsCountdownStarted = localStorage.getItem(coundownIsStartedStorageKey) === 'true';
        const storageCountdown = Number(localStorage.getItem(logoutCoundownStorageKey)) || 0;
        const userShouldLogoutOnAllTabs = localStorage.getItem(userShouldLogoutStorageKey) === 'true';

        if (isShowPopup !== storageIsCountdownStarted) {
            _setPopupShow(storageIsCountdownStarted);
        }
        if (countDown !== storageCountdown) {
            _setCountDown(storageCountdown);
        }
        if (userShouldLogoutOnAllTabs) {
            logoutCallback();
        }
    }, [isShowPopup, countDown, _setPopupShow, _setCountDown, logoutCallback]);

    /**
     * Log user actions with trottle
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const logUserAction = useCallback(throttle(() => {
        /**
         * Log last user action time
         */
        localStorage.setItem(lastActionStorageKey, moment().toISOString());
    }, millisecondsInSecond), []);
    useEffect(() => {
        /**
         * Log last user action time subscribe
         */
        const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

        if (enableListener) {
            logUserAction();
            events.forEach(eventName => document.addEventListener(eventName, logUserAction, true));
        }

        return () => {
            events.forEach(eventName => document.removeEventListener(eventName, logUserAction));
        };
    }, [logUserAction, enableListener]);

    /**
     * Check user latest user action time
     */
    const checkLastUserActionTime = useCallback(() => {
        const lastAction = localStorage.getItem(lastActionStorageKey);
        if (!isShowPopup && enableListener && (!lastAction
            || moment().diff(lastAction, 'milliseconds') >= warningTimeout)) {
            setCountDown(logoutTimeout);
            setPopupIsShow(true);
        }
    }, [isShowPopup, enableListener, setCountDown, setPopupIsShow]);
    useEffect(() => {
        /**
         * Schedule check last activity time
         */
        let interval: NodeJS.Timeout | null = null;
        if (enableListener) {
            interval = setInterval(checkLastUserActionTime, pollingTimeout);
        }
        return () => {
            interval && clearInterval(interval);
        };
    }, [enableListener, checkLastUserActionTime]);

    const formattedCountDown = useMemo(() => {
        const countDownDuration = moment.duration(countDown);
        return `${countDownDuration.minutes()}:${countDownDuration.seconds().toString().padStart(2, '0')}`;
    }, [countDown]);

    return (
        <>
            {isShowPopup && (
                <Dialog
                    open={isShowPopup}
                    onClose={closePopup}
                    disableBackdropClick
                >
                    <DialogTitle>
                        Your session is about to expire.
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom>
                            For your security, this session will expire in {formattedCountDown} due to inactivity.
                        </Typography>
                        <Typography gutterBottom>
                            If you want to extend your session, please select the &apos;Continue&apos; button.
                            If you select the &apos;Log Out&apos; button or do not respond,
                            your session will automatically close.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={closePopup}
                            variant="contained"
                            color="primary"
                        >
                            Continue
                        </Button>
                        <Button
                            onClick={logoutCallback}
                            variant="contained"
                            color="primary"
                        >
                            Log Out
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
