import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStyles = makeHighPriorityStyles({
    notificationIcon: {
        color: colors.darkGray,
    },
    notificationBadge: {
        backgroundColor: colors.red,
        width: 10,
        height: 10,
        padding: 0,
        minWidth: 'auto',
        top: 16,
        right: 13,
    },
});
