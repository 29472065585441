import React from 'react';
import { useField } from 'formik';
import { IFormFieldProps } from 'shared/components/formFields/models';
import NumberInput from 'shared/components/formFields/NumberInput';
import { QuantityType } from 'shared/models/sheet/Sheet';

export function TimeFileField({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
}: IFormFieldProps) {
    const [, meta, helper] = useField(name);

    const handleChange = (value: string) => {
        helper.setValue({
            entry_type: QuantityType.FILE,
            files: value,
        });
    };
    return (
        <NumberInput
            id={id}
            name={name}
            label={label}
            initialValue=""
            className={className}
            disabled={disabled}
            error={meta.error}
            touched={meta.touched}
            setTouched={helper.setTouched}
            onChange={handleChange}
            inputProps={{
                decimalScale: 0,
            }}
        />
    );
}
