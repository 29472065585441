/* eslint-disable react/display-name */

import clsx from 'clsx';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import PlainText from 'shared/components/table/Cells/PlainText';

import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { shortDateFormat } from 'shared/models/Dates';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

import { ITemplate } from 'store/entities/configuration/configurationModel';

interface ITemplateRow extends ITemplate {
    className?: string;
}

export enum TemplateTableEntityName{
    PhysicalDemands = 'Physical Demands',
    WorkingConditions = 'Working Conditions',
    BackgroundChecks = 'Background Checks',
}

export interface ITemplatesTableProps{
    templates: ITemplate[];
    isLoading: boolean;
    editAction: (payload: string) => AnyAction;
    deleteAction: (payload: string) => AnyAction;
    entityName: TemplateTableEntityName;
}

export const TemplatesTable = (props: ITemplatesTableProps) => {

    const { templates, isLoading, deleteAction, entityName, editAction } = props;

    const tableClasses = useDefaultTableStyles();
    const userHasDeletePermission = useSelector(selectIsUserHasPermission(Permission.deleteClientSettingsEntities));

    const cells = [
        {
            key: 'name',
            title: 'name',
            render: ({ name, className }: ITemplateRow) => (
                <PlainText className={className} value={name}/>
            ),
        },
        {
            key: 'template',
            title: 'template',
            width: '2fr',
            render: ({ template, className }: ITemplateRow) => (
                <PlainText className={className} value={template}/>
            ),
        },
        {
            key: 'added',
            title: 'added',
            render: ({ className, created_at }: ITemplateRow) => (
                <PlainText className={className} value={moment(created_at).format(shortDateFormat)}/>
            ),
        },
        {
            key: 'updated_at',
            title: 'Last edited',
            render: ({ className, updated_at }: ITemplateRow) => (
                <PlainText className={className} value={moment(updated_at).format(shortDateFormat)}/>
            ),
        },
        {
            key: 'actions',
            title: '',
            width: '100px',
            render: ({ className, id }: ITemplateRow) => {
            //Working Conditions actions will be implemented later within another task
                return (
                    <div className={clsx(className, tableClasses.iconCell)}>
                        <EditItemButton
                            id={id}
                            editAction={editAction}/>
                        {userHasDeletePermission && (
                            <DeleteItemButton
                                confirmation={`Are you sure you want to delete ${entityName} Template?`}
                                id={id}
                                deleteAction={deleteAction}
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <GridTable
            rowData={templates}
            cells={cells}
            getKey={item => item.id}
            isLoading={isLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            stickyHeader
        />
    );
};
