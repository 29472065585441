import React, { useCallback, useContext } from 'react';
import { Radio } from '@material-ui/core';
import { HierarchyContext } from 'modules/settings/submodules/components/HierarchyPage/HierarchyListsContext';
import useCheckBoxStyles from 'shared/components/table/tableApprovalCells/CheckBox/CheckBoxStyles';

interface IAnchorRadioProps {
    isChecked: boolean;
    id: string;
}

export function AnchorRadio({ isChecked, id }: IAnchorRadioProps) {
    const initialClasses = useCheckBoxStyles();
    const { hierarchyList, setHierarchyList } = useContext(HierarchyContext);

    const handleCheckChange = useCallback(() => {
        setHierarchyList(hierarchyList.map(item => ({
            ...item,
            hierarchyNode: {
                ...item.hierarchyNode,
                anchor: item.id === id,
            },
        })));
    }, [id, hierarchyList, setHierarchyList]);

    return (
        <Radio
            id={id}
            checked={isChecked}
            size="small"
            onChange={handleCheckChange}
            classes={{ checked: initialClasses.blue }}
        />
    );
}
