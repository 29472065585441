import { makeStyles } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

export const useStyles = makeStyles(theme => ({
    breadcrumbs: {
        minWidth: theme.spacing(55),
        minHeight: theme.spacing(6),
        fontSize: theme.typography?.pxToRem(18),
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
    },
    heading: {
        textTransform: 'uppercase',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
    nonLink: {
        textDecoration: 'none',
        color: colors.black,
    },
}));
