import { INotification, IUpdateNotification } from 'modules/notificationCenter/store/model';
import { ActionsReturnTypes, createActions } from 'store/utils';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';

export const getNotifications = createActions<void, INotification[], any,
    typeof GET_NOTIFICATIONS, typeof GET_NOTIFICATIONS_SUCCESS, typeof GET_NOTIFICATIONS_ERROR
>(
    GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_ERROR,
);

const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
const UPDATE_NOTIFICATIONS_ERROR = 'UPDATE_NOTIFICATIONS_ERROR';

export const updateNotifications = createActions<IUpdateNotification[], INotification[], any,
    typeof UPDATE_NOTIFICATIONS, typeof UPDATE_NOTIFICATIONS_SUCCESS, typeof UPDATE_NOTIFICATIONS_ERROR
>(
    UPDATE_NOTIFICATIONS, UPDATE_NOTIFICATIONS_SUCCESS, UPDATE_NOTIFICATIONS_ERROR,
);

export type NotificationActions =
    | ActionsReturnTypes<typeof getNotifications>
    | ActionsReturnTypes<typeof updateNotifications>;
