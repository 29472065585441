import React from 'react';
import { SheetGroup } from 'modules/timeAndExpense/store/model';
import { Box } from '@material-ui/core';
import { useEntriesCells } from 'modules/clients/content/supervisor/entriesCells';
import { useEntriesByDayArray } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/entriesByDay';
import { SheetGroupTotalBlock } from 'modules/sheetApproval/components/SheetGroupDetailSidebar/ShetGroupTotalBlock';
import { useSelector } from 'react-redux';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { SheetInfoPure } from 'shared/components/sidebars/SheetDetail/SheetInfoPure';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import SheetStatus from 'shared/components/table/Cells/SheetStatus';
import EntriesTable from 'shared/components/table/EntriesTable/EntriesTable';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getLastFirstName } from 'shared/utils/converters/user';
import { useIsMobile } from 'shared/utils/hooks/media';
import { selectTypedEntries } from 'store/entities/timesheet/selectors';

interface ISheetGroupDetailSidebarProps {
    group?: SheetGroup | null;
    detailInfo: ISheetInfoItemData[];
    sheetType: EntryType;
    onClose: () => void;
    availableActions?: React.ReactNode;
}

export const SheetGroupDetailSidebar = ({
    group,
    sheetType,
    detailInfo,
    availableActions,
    onClose,
}: ISheetGroupDetailSidebarProps) => {
    const classes = useSheetDetailStyles();
    const sheetIds = group?.sheets.map(sheet => sheet.id) || [];

    const entries = useSelector(selectTypedEntries(sheetType)).filter(
        entry => sheetIds.includes(entry.sheet_id),
    );
    const filteredEntriesByDay = useEntriesByDayArray(entries);
    const isMobile = useIsMobile();
    const cells = useEntriesCells(isMobile, sheetType);

    return (
        <Sidebar
            title={group ? getLastFirstName(group.employee) : ''}
            isOpened={Boolean(group)}
            customClasses={classes.paper}
            titleClasses={{
                root: classes.paperTitle,
                text: classes.paperTitleText,
                icon: classes.paperTitleIcon,
            }}
            onClose={onClose}
        >
            {group && (
                <>
                    <Box className={classes.infoSection} component="section">
                        <SheetInfoPure data={detailInfo}/>
                        <Box className={classes.statusWrapper}>
                            <SheetStatus sheet={group?.sheets[0]} className={classes.statusDisplay}/>
                        </Box>
                        <Box display="flex" className={classes.actionAndTotalsContainer}>
                            {availableActions}
                            <Box className={classes.totalsWrapper}>
                                <SheetGroupTotalBlock
                                    group={group}
                                    entries={entries}
                                    entryType={sheetType}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {filteredEntriesByDay.map((dayEntries, index) => {
                        if (dayEntries.length > 0) {
                            return (
                                <EntriesTable
                                    key={dayEntries[0]?.entry_date || index}
                                    date={dayEntries[0]?.entry_date || ''}
                                    entries={dayEntries}
                                    cells={cells}
                                    hideHeader={isMobile}
                                    entryFilter={sheetType}
                                    isLoading={false}
                                />
                            );
                        }
                        return null;
                    })}
                </>
            )}
        </Sidebar>
    );
};
