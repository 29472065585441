export enum AssignmentInfoTitles {
    Email = 'Email',
    Phone = 'Phone',
    Address = 'Address',
    StartDate = 'Start Date',
    BaseRate = 'Base Rate',
    EndDate = 'End Date',
    AcceptedDate = 'Accepted Date',
    Manager = 'Manager',
    OTPayRate = 'OT pay rate',
    DTPayRate = 'DT pay rate',
    EmployeeType = 'Employee Type',
    PayPeriod = 'Pay Period',
}
