import { IHomeDashboardRequest, IHomeDashboardResponse } from 'modules/home/store/model';
import baseApi from 'shared/utils/baseApi';

export const homeDashboardApi = {
    async getDashboardStats(role: string, params: IHomeDashboardRequest): Promise<IHomeDashboardResponse> {
        const { data } = await baseApi.get<IHomeDashboardResponse>(
            `/sheets/coordinator/stats/${role}`,
            params,
        );

        return data;
    },
};
