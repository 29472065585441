import React from 'react';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { CircularProgress } from '@material-ui/core';

const useSplashPageStyles = makeHighPriorityStyles({
    loaderWrapper: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default function SplashPage() {
    const classes = useSplashPageStyles();

    return (
        <div className={classes.loaderWrapper}>
            <CircularProgress size={48}/>
        </div>
    );
}
