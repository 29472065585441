import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IClient } from 'store/entities/clients/clientsModel';

type ClientCellProps = ICellProps<{
    client?: IClient
}>;

export const ClientCell = ({ className, client }: ClientCellProps) => (
    <PlainText
        className={className}
        value={client?.name}
    />
);
