import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

const useWeekFilterStyles = makeHighPriorityStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        height: theme.spacing(4),
        border: `1px solid ${colors.gray}`,
        backgroundColor: colors.white,
        boxSizing: 'border-box',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: theme.spacing(5),
        },
    },
    arrowButton: {
        minWidth: 'auto',
        padding: theme.spacing(1, 1.5),
        color: theme.palette.primary.main,
    },
    arrowButtonRight: {
        borderLeft: `1px solid ${colors.lightGray}`,
    },
    arrowButtonLeft: {
        borderRight: `1px solid ${colors.lightGray}`,
    },
    calendarContainer: {
        minWidth: theme.spacing(16),
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
    label: {
        marginLeft: theme.spacing(1),
        fontSize: 12,
    },
}));

export default useWeekFilterStyles;
