import { getClientPayPeriod, getSheetsPayPeriod } from 'store/entities/timesheet/actions/sheets';
import { sheetsApi } from 'store/entities/timesheet/api/sheetsApi';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    all, call, put, select, takeEvery, takeLatest,
} from 'typed-redux-saga';
import { EntryType } from 'shared/models/sheet/Sheet';
import { payrollApi } from 'store/entities/timesheet/api/payrollApi';
import { loadExpenseSheetsWithEntries, removeExpenseEntry } from '../actions/expenseActions';
import { getSimpleSheets, quickRemoveEntry, getSheetLogs } from '../actions/sheetState';
import { loadTimeSheetsWithEntries, removeTimeEntry } from '../actions/timeActions';
import { selectEntry, selectSheetLogsById } from '../selectors';
import { Permission } from 'store/components/auth/authModels';
import { IExpenseSheetCalculation, ITimesheetCalculation, CalculationSheetType, MixedCalculations } from 'store/entities/timesheet/models/Calculation';
import { getExpenseSheetCalculations, getTimesheetCalculations } from 'store/entities/timesheet/actions/calculations';
import { timeApi } from '../api/timeApi';
import { expenseApi } from '../api/expenseApi';
import { ISheetLogResponse } from '../models/SheetApi';
import { selectUsersById } from 'store/entities/users/selectors';
import { getUsers } from 'store/entities/users/actions';
import { optionalLoadEntitiesByIdsSaga } from 'store/utils/sagas/optionalLoadEntitiesByIdsSaga';

function* loadSimpleSheetsSaga() {
    /**
     * Load sheets without entries for T&E submitted/recalled/approved/rejected/all tabs
     */
    yield* all([
        put(loadTimeSheetsWithEntries.init({
            purpose: Permission.SubmitSheets,
            request: {
                is_holiday: false,
            },
        })),

        put(loadExpenseSheetsWithEntries.init({
            purpose: Permission.SubmitSheets,
            request: {
                is_travel: false,
            },
        })),
    ]);
}

export function* loadSimpleSheetsWatcher() {
    yield takeLatest(getSimpleSheets.initType, loadSimpleSheetsSaga);
}

function* removeEntrySaga(action: ReturnType<typeof quickRemoveEntry.init>) {
    const entryId = action.payload;
    const entry = yield* select(selectEntry(entryId));

    if (entry?.entry_type === EntryType.TIME) {
        yield* put(removeTimeEntry.init(entryId));
    }
    if (entry?.entry_type === EntryType.EXPENSE) {
        yield* put(removeExpenseEntry.init(entryId));
    }
}

export function* removeEntryWatcher() {
    yield takeLatest(quickRemoveEntry.initType, removeEntrySaga);
}

function* getSheetCalculationsSaga({ payload }: ReturnType<typeof getTimesheetCalculations.init>) {
    const { calculations } = yield call(payrollApi.getSheetCalculations, payload);
    const timeCalculations: ITimesheetCalculation[] = [];
    const expenseCalculations: IExpenseSheetCalculation[] = [];
    calculations.forEach((calculation: MixedCalculations) => {
        if (calculation.sheet_type === CalculationSheetType.Time) {
            timeCalculations.push(calculation);
        } else {
            expenseCalculations.push(calculation);
        }
    });
    if (timeCalculations.length) {
        yield put(getTimesheetCalculations.success(timeCalculations));
    }
    if (expenseCalculations.length) {
        yield put(getExpenseSheetCalculations.success(expenseCalculations));
    }
}

function* getSheetCalculationsWatcher() {
    yield takeEvery(
        getTimesheetCalculations.initType,
        withBackendErrorHandler(
            getSheetCalculationsSaga,
            getTimesheetCalculations.error,
            `Unable to fetch sheet calculations`,
        ),
    );
}

export const getSheetsPayPeriodsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getSheetsPayPeriod,
    sheetsApi.getSheetsPayPeriods,
    'pay periods',
);

export const getClientPayPeriodsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getClientPayPeriod,
    sheetsApi.getClientPayPeriods,
    'pay periods',
);

export function* getSheetLogsSaga({ payload }: ReturnType<typeof getSheetLogs.init>) {
    let { timeSheetIds, expenseSheetIds } = payload;

    const sheetLogsById = yield select(selectSheetLogsById);
    const sheetLogsByIdKeys = new Set(Object.keys(sheetLogsById));
    const idFilter = sheetId => !sheetLogsByIdKeys.has(sheetId);
    timeSheetIds = timeSheetIds?.filter(idFilter);
    expenseSheetIds = expenseSheetIds?.filter(idFilter);

    const fetchSagas = [];
    if (timeSheetIds?.length) {
        // @ts-ignore
        fetchSagas.push(call(timeApi.getSheetLogs, { sheet_ids: timeSheetIds }));
    }
    if (expenseSheetIds?.length) {
        // @ts-ignore
        fetchSagas.push(call(expenseApi.getSheetLogs, { sheet_ids: expenseSheetIds }));
    }

    if (!fetchSagas.length) {
        yield put(getSheetLogs.success([]));
        return;
    }

    const logsResponse = yield* all(fetchSagas);
    const logsResponseFlattened = logsResponse.flat();
    const logs = logsResponseFlattened.map((log: ISheetLogResponse) => {
        return {
            sheetId: log.sheet_id,
            actorId: log.actor_id,
            timestamp: log.timestamp,
        };
    });

    const userIds = [...new Set(logs.map(log => log.actorId))];
    yield* optionalLoadEntitiesByIdsSaga(userIds, selectUsersById, getUsers, ids => ({ ids: ids.join(',') }));

    yield put(getSheetLogs.success(logs));
}

function* getSheetLogsSagaWatcher() {
    yield takeLatest(
        getSheetLogs.initType,
        withBackendErrorHandler(
            getSheetLogsSaga,
            getSheetLogs.error,
            'Unable to get sheet approvals',
        ),
    );
}

export default [
    loadSimpleSheetsWatcher,
    removeEntryWatcher,
    getSheetCalculationsWatcher,
    getSheetsPayPeriodsWatcher,
    getClientPayPeriodsWatcher,
    getSheetLogsSagaWatcher,
];
