import { getSignupUserInfo, userRegistration } from 'store/components/registration/actions';
import { usersApi } from 'store/entities/users/api';
import { call, put, takeLatest } from 'typed-redux-saga';

function* userRegistrationSaga(action: ReturnType<typeof userRegistration.init>) {
    try {
        const newUser = yield* call(usersApi.registration, action.payload);
        yield put(userRegistration.success(newUser));
    } catch (e) {
        yield put(userRegistration.error(e?.response?.data?.error || e));
    }
}

export function* userRegistrationWatcher() {
    yield takeLatest(userRegistration.initType, userRegistrationSaga);
}

function* getSignupUserInfoSaga(action: ReturnType<typeof getSignupUserInfo.init>) {
    try {
        const userInfo = yield* call(usersApi.getSignupUserInfoByToken, action.payload);
        yield put(getSignupUserInfo.success(userInfo));
    } catch (e) {
        yield put(getSignupUserInfo.error(e));
    }
}

export function* getSignupUserInfoWatcher() {
    yield takeLatest(getSignupUserInfo.initType, getSignupUserInfoSaga);
}

export default [
    userRegistrationWatcher,
    getSignupUserInfoWatcher,
];
