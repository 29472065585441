import { combineReducers } from 'redux';
import { deleteItemByIdReducer, isLoadingReducer, itemsByIdReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';
import { deleteDepartment, getDepartments } from './actions';
import { IDepartment } from '../../models/Department';

const departmentsIsLoading = isLoadingReducer(getDepartments);

const departmentsById = extendReducer(
    itemsByIdReducer<IDepartment, IDepartment>(getDepartments),
    deleteItemByIdReducer<IDepartment>(deleteDepartment),
);

export const departments = combineReducers({
    departmentsIsLoading,
    departmentsById,
});
