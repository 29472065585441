import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';
import {
    selectDealSegments, selectDealSegmentsIsLoading,
} from 'store/entities/configuration/configurationSelectors';

import { IDealSegment } from 'shared/models/DealSegment';
interface IDealSegmentSelectProps extends IFormFieldProps, IFormSelect {
}

export const DealSegmentSelect = (props: IDealSegmentSelectProps) => {
    const dealSegments = useSelector(selectDealSegments);
    const isDealSegmentsLoading = useSelector(selectDealSegmentsIsLoading);
    return (
        <FormSelect
            getKey={(segment: IDealSegment) => segment?.id}
            getText={(segment: IDealSegment) => segment?.name}
            options={dealSegments}
            useIdValue
            isLoading={isDealSegmentsLoading}
            {...props}
        />
    );
};
