import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { IOfferLetterTemplateFormValues } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/model';
import OfferLetterTemplateFormWrapper from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/OfferLetterTemplateFormWrapper';
import { createOfferLetterTemplate, setCreateOfferLetterTemplateModalState } from 'modules/settings/submodules/offerLetters/store/action';
import { selectIsCreateOfferLetterTemplateModalOpened, selectIsOfferLetterTemplateCreating } from 'modules/settings/submodules/offerLetters/store/selectors';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export default function CreateOfferLetterTemplateModal() {
    const dispatch = useDispatch();
    const classes = useColoredFormModalStyles();

    const isLoading = useSelector(selectIsOfferLetterTemplateCreating);
    const onSave = useCallback((values: IOfferLetterTemplateFormValues) => {
        const payload: Parameters<typeof createOfferLetterTemplate.init>[0] = {
            ...values,
            client_ids: values.all_clients ? [] : values.client_ids,
        };
        dispatch(createOfferLetterTemplate.init(payload));
    }, [dispatch]);
    const isOpen = useSelector(selectIsCreateOfferLetterTemplateModalOpened);
    const onClose = useCallback(() => {
        dispatch(setCreateOfferLetterTemplateModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Add New Offer Letter Template"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <OfferLetterTemplateFormWrapper
                onSubmit={onSave}
                submitTitle="Add"
                isLoading={isLoading}
            />
        </ModalDialog>
    );
}
