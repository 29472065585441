import { Theme, fade } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const borderSize = 1;

export interface ITableContainerCssProps {
    rowId?: string;
    color?: string;
}

export const useApprovalTableStyles = makeHighPriorityStyles((theme: Theme) => {
    const sizeOrdinaryBorder = theme.spacing(3) - borderSize;
    const svgSizeInnerBorders = theme.spacing(3) - 3 * borderSize;

    return {
        paper: {
            borderRadius: 0,
            marginBottom: theme.spacing(2),
        },
        headCell: {
            fontSize: 10,
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontWeight: 'normal',
            padding: theme.spacing(1, 0, 1, 2),
            backgroundColor: colors.lightGray,
        },
        bodyCell: {
            fontSize: 12,
            fontWeight: 'normal',
            height: sizeOrdinaryBorder,
            padding: theme.spacing(1, 0, 1, 2),
            borderBottom: `${borderSize}px solid ${colors.lightGray}`,
        },
        toolbar: {
            backgroundColor: colors.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderBottom: `2px solid ${colors.darkGray}`,
        },
        ApprovalTable: {
            borderLeft: `1px solid ${colors.lightGray}`,
            borderRight: `1px solid ${colors.lightGray}`,
            [theme.breakpoints.down('xs')]: {
                border: 0,
            },
        },
        headerStyle: {
            backgroundColor: theme.palette.primary.main,
        },
        header: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        mainContainer: {
            display: 'flex',
            flexFlow: 'column',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0.5, 5.5),
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        tableMain: {
            position: 'relative',
            flex: 1,
        },
        tableContainer: {
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            maxHeight: 500,
            [theme.breakpoints.up('md')]: {
                position: 'relative',
            },
            [theme.breakpoints.only('sm')]: {
                position: 'absolute',
            },
            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(0.5),
                },
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
                minHeight: theme.spacing(8),
                borderRadius: theme.spacing(0.8),
                backgroundClip: 'padding-box',
                border: `1px solid ${fade(colors.white, 0.5)}`,
                backgroundColor: fade(colors.black, 0.5),
            },
        },
        quantity: {
            textAlign: 'right',
            paddingRight: theme.spacing(2),
        },
        actionIconNotFirst: {
            paddingLeft: 0,
        },
        report: {
            width: theme.spacing(8),
        },
        reportHeader: {
            width: theme.spacing(8),

            '& > *': {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        iconNoPadding: {
            '& button': {
                padding: 0,
            },
        },
        checkboxNoPadding: {
            '& > span': {
                padding: 0,
            },
        },
        lastCell: {
            paddingRight: theme.spacing(2),
        },
        svgNoBorders: {
            '& svg': {
                width: sizeOrdinaryBorder,
                height: sizeOrdinaryBorder,
            },
        },
        svgWithBorders: {
            '& svg': {
                height: svgSizeInnerBorders,
                width: svgSizeInnerBorders,
            },
        },
        actionIconCell: {
            padding: theme.spacing(0),
            width: theme.spacing(5),
            textAlign: 'center',
        },
        actionIcon: {
            verticalAlign: 'middle',
            cursor: 'pointer',
            border: `1px solid ${colors.lightGray}`,
            '&:hover': {
                backgroundColor: colors.white,
            },
        },
        approveIcon: {
            fill: colors.green,
        },
        rejectIcon: {
            fill: colors.red,
        },
        approveHover: {
            '&:hover': {
                backgroundColor: fade(colors.green, 0.3),
            },
        },
        rejectHover: {
            '&:hover': {
                backgroundColor: fade(colors.red, 0.2),
            },
        },
        tableWrapper: (props: ITableContainerCssProps) => {
            return props.rowId ? {
                [`& [id='${props.rowId}'] > *`]: {
                    backgroundColor: props.color === '' ? 'inherit' : props.color,
                },
            } : {};
        },
    };
});
