import { makeStyles } from '@material-ui/core/styles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';
import { ClassKeyOfStyles, ClassNameMap, Styles } from '@material-ui/styles/withStyles';

// It is necessary for the custom styles after the build to take precedence over the standard ones
// https://github.com/mui-org/material-ui/issues/18919

export function makeHighPriorityStyles<
    Theme = DefaultTheme,
    ClassKey extends string = string
>(styles: Styles<Theme, {}, ClassKeyOfStyles<Styles<Theme, {}, ClassKey>>>): (styleProps?: any)
=> ClassNameMap<ClassKeyOfStyles<Styles<Theme, {}, ClassKey>>>

export function makeHighPriorityStyles<
    Theme = DefaultTheme,
    Props extends {} = {},
    ClassKey extends string = string
>(styles: Styles<Theme, Props, ClassKeyOfStyles<Styles<Theme, Props, ClassKey>>>): (styleProps: Props)
=> ClassNameMap<ClassKeyOfStyles<Styles<Theme, Props, ClassKey>>>

export function makeHighPriorityStyles(styles: any) {
    return makeStyles(styles, { index: 1 });
}
