import { ICreateCostCenterRequest, IUpdateCostCenterPayload } from 'modules/settings/submodules/clients/costCenters/store/model';
import { ICostCenter } from 'shared/models/CostCenters';
import { createActions } from 'store/utils';

export const CREATE_COST_CENTER = 'settings/CREATE_COST_CENTER';
export const CREATE_COST_CENTER_SUCCESS = 'settings/CREATE_COST_CENTER_SUCCESS';
export const CREATE_COST_CENTER_ERROR = 'settings/CREATE_COST_CENTER_ERROR';

export const createCostCenter = createActions<
ICreateCostCenterRequest,
ICostCenter,
any,
    typeof CREATE_COST_CENTER,
    typeof CREATE_COST_CENTER_SUCCESS,
    typeof CREATE_COST_CENTER_ERROR
>(
    CREATE_COST_CENTER,
    CREATE_COST_CENTER_SUCCESS,
    CREATE_COST_CENTER_ERROR,
);

export const UPDATE_COST_CENTER = 'UPDATE_COST_CENTER';
export const UPDATE_COST_CENTER_SUCCESS = 'UPDATE_COST_CENTER_SUCCESS';
export const UPDATE_COST_CENTER_ERROR = 'UPDATE_COST_CENTER_ERROR';
export const updateCostCenter = createActions<
IUpdateCostCenterPayload,
any,
any,
typeof UPDATE_COST_CENTER,
typeof UPDATE_COST_CENTER_SUCCESS,
typeof UPDATE_COST_CENTER_ERROR
>(
    UPDATE_COST_CENTER,
    UPDATE_COST_CENTER_SUCCESS,
    UPDATE_COST_CENTER_ERROR,
);
