import { useField } from 'formik';
import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { ZipCodeCommon } from './ZipCodeCommon';

export default function ZipCodeField({
    label = '',
    className = '',
    name,
}: IFormFieldProps) {
    const [field, meta, helper] = useField(name);

    const error = meta.touched ? meta.error : undefined;

    return (
        <ZipCodeCommon
            customClasses={className}
            label={label}
            onChange={helper.setValue}
            value={field.value}
            error={error}
        />
    );
}
