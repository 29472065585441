import { Theme, fade } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const useFilterAndActionControlsStyles = makeHighPriorityStyles((theme: Theme) => ({
    rootContainer: {
        display: 'flex',
        minHeight: theme.spacing(7.5),
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    mobileRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        width: `calc(100% - ${theme.spacing(4)}px)`,
        backgroundColor: colors.white,
        padding: theme.spacing(2),
        position: 'fixed',
        bottom: 0,
        zIndex: 3,
    },
    leftContainer: {
        display: 'flex',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            width: '75%',
        },
    },
    rightContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right',
            marginRight: 0,
        },
    },
    centerContainer: {
        width: '100%',
        textAlign: 'center',
    },
    buttonTextStyle: {
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontSize: 14,
    },
    buttonApprove: {
        padding: theme.spacing(1, 2.5),
        height: 'fit-content',
        margin: theme.spacing(1),
        minWidth: 'auto',
        boxShadow: `${fade(colors.black, 0.14)} 0px 1px 1px 0px, ${fade(colors.black, 0.12)} 0px 2px 1px -1px, ${fade(colors.black, 0.2)} 0px 1px 3px 0px`,
        '&:hover': {
            backgroundColor: colors.green,
        },
    },
    actionButton: {
        border: `1px solid ${colors.lightGray}`,
        backgroundColor: colors.white,
        margin: theme.spacing(.5),
        padding: theme.spacing(1),
        height: 'fit-content',
        minWidth: 'auto',
    },
    actionButtonBase: {
        margin: theme.spacing(.5),
        padding: theme.spacing(1, 2.5),
        height: 'fit-content',
        minWidth: 'auto',
    },
    actionButtonNoBackground: {
        border: `1px solid ${colors.lightGray}`,
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        height: 'fit-content',
        minWidth: 80,
    },
    actionButtonOutlined: {
        backgroundColor: colors.white,
    },
    actionButtonToggle: {
        margin: 0,
    },
    secondaryButton: {
        '&:hover': {
            backgroundColor: colors.red,
            color: colors.white,
            border: `1px solid ${colors.red}`,
        },
        padding: theme.spacing(1, 2.5),
    },
    clockIcon: {
        verticalAlign: 'middle',
        fill: colors.blue,
        fontSize: 25,
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    MoneyIcon: {
        verticalAlign: 'middle',
        fill: colors.green,
        fontSize: 25,
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    filtersChips: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
    },
    filtersChipsMobile: {
        marginBottom: theme.spacing(1),
        '& > *': {
            marginBottom: theme.spacing(1),
        },
    },
    mobileTotalRoot: {
        display: 'flex',
    },
    mobileTotal: {
        paddingLeft: theme.spacing(1),
    },
}));

export default useFilterAndActionControlsStyles;
