import { call, put, takeLatest } from 'typed-redux-saga';

import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';

import { getOfferLetterTemplates, removeOfferLetterTemplate } from './actions';
import { offerLetterTemplateApi } from './api';

function* getOfferLetterTemplatesSaga() {
    const offerLetterTemplates = yield* call(offerLetterTemplateApi.getTemplates);
    yield put(getOfferLetterTemplates.success(offerLetterTemplates));
}

function* getOfferLetterTemplatesWatcher() {
    yield* takeLatest(
        getOfferLetterTemplates.initType,
        withBackendErrorHandler(
            getOfferLetterTemplatesSaga,
            getOfferLetterTemplates.error,
            'Unable to fetch offer letter templates',
        ),
    );
}

function* removeOfferLetterTemplateSaga({
    payload: offerLetterTemplateId,
}: ReturnType<typeof removeOfferLetterTemplate.init>) {
    yield* call(offerLetterTemplateApi.removeTemplate, offerLetterTemplateId);
    yield put(removeOfferLetterTemplate.success(offerLetterTemplateId));
}

function* removeOfferLetterTemplatesWatcher() {
    yield* takeLatest(
        removeOfferLetterTemplate.initType,
        withBackendErrorHandler(
            removeOfferLetterTemplateSaga,
            removeOfferLetterTemplate.error,
            'Unable to remove offer letter templates',
        ),
    );
}

export default [
    getOfferLetterTemplatesWatcher,
    removeOfferLetterTemplatesWatcher,
];
