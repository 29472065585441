import React from 'react';
import { IUnlockConfirmationDialogProps } from './model';
import { useConfirmUnlockHandler } from './hooks/useConfirmUnlockHandler';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';

const MESSAGE = `Are you sure you want to UNLOCK this Time report?`;

export const UnlockConfirmationDialogSingleApprover = ({
    timeSheetIds,
    expenseSheetIds,
    onCancel,
    isOpened,
}: IUnlockConfirmationDialogProps) => {
    const onConfirmUnlock = useConfirmUnlockHandler(timeSheetIds, expenseSheetIds, onCancel);
    return (
        <ConfirmationDialog
            open={isOpened}
            onCancel={onCancel}
            onConfirm={onConfirmUnlock}
        >
            {MESSAGE}
        </ConfirmationDialog>
    );
};
