import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThumbUpSVG, WarningSVG, AssignmentLateSVG, CheckSVG } from 'shared/components/dashboard/DashboardIcons';
import { noop } from 'lodash-es';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { IDashboardCardData } from 'shared/models/Dashboard';
import baseApi from 'shared/utils/baseApi';
import { selectPayrollFilter } from 'modules/payrollProcessorHub/store/selectors';
import { logger } from 'shared/utils/logging/logger';
import { setGlobalToast } from 'store/entities/appConfig/actions';

interface IPHHStats {
    count: number;
    status: string;
}

interface IPPHDataResponse {
    stats: IPHHStats[];
}

/**
 * Function for get current dashboard block main parameters - Icon, colors and label
 * @param {string} status - status for getting dashboard block main parameters
 * @param {number} count - dashboard block count
 * @param {func} link -  redirect callback
 * @return {object} color, backgroundColor, Icon, text, count, link  - all parameters for dash block layout
 */
const getDashboardParameters = (
    status: string,
    count: number,
    link: (statusInLink: string) => void,
): IDashboardCardData => {
    switch (status) {
        case `approved`:
            return {
                color: `#2db67d`, backgroundColor: `rgb(45, 182, 125, 0.3)`, Icon: ThumbUpSVG, title: status, count, callback: () => link(status),
            };
        case `missing`:
            return {
                color: `#e89c30`, backgroundColor: `rgb(232, 156, 48, 0.3)`, Icon: WarningSVG, title: status, count, callback: () => link(status),
            };
        case `submitted`:
            return {
                color: `#009be5`, backgroundColor: `rgb(0, 155, 229, 0.3)`, Icon: CheckSVG, title: status, count, callback: () => link(status),
            };
        case `overdue`:
            return {
                color: `#b82601`, backgroundColor: `rgb(184, 38, 1, 0.3)`, Icon: AssignmentLateSVG, title: status, count, callback: () => link(status),
            };
        default:
            return {
                color: ``,
                backgroundColor: ``,
                Icon: ThumbUpSVG,
                count: 0,
                callback: noop,
                title: ``,
                label: ``,
            };
    }

};

/**
 * Function for get payroll process hub statuses
 * @param {function} dashLink -  callback for dashboard links
 * @return {object} {count, status, link} - pph statuses data
 */

const usePPHStats = (dashLink?: (newValue: string) => void) => {
    const dispatch = useDispatch();
    const [phhData, setPhhData] = useState<Array<IDashboardCardData>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { payPeriodEnd, clientId, payrollProcessorUserId } = useSelector(selectPayrollFilter);
    useEffect(() => {
        if (dashLink){
            // TODO: It should be refactored to sagas
            const myAsync = async () => {
                setIsLoading(true);
                let data: IPPHDataResponse = { stats: [] };
                try {
                    const response = await baseApi.get<IPPHDataResponse>(
                        `/payroll/calculations/grouped/stats`,
                        {
                            client_id: clientId,
                            weekends: payPeriodEnd,
                            payroll_processor_user_id: payrollProcessorUserId,
                        },
                    );
                    data = response.data;
                } catch (e) {
                    const message = 'Unable to load payroll processing statistics';
                    logger.error(e, { message });
                    dispatch(setGlobalToast({
                        severity: IModalSeverity.Error,
                        title: 'Unable to load payroll processing statistics',
                    }));
                }

                setIsLoading(false);
                return data;
            };
            myAsync().then(({ stats = [] }) => {
                const pphStatsData = stats.map((item: IPHHStats) => {
                    return getDashboardParameters(item.status, item.count, dashLink);
                });
                setPhhData(pphStatsData);
            });

        }
    }, [dashLink, clientId, payPeriodEnd, payrollProcessorUserId, setIsLoading, dispatch]);
    return [phhData, isLoading];
};

export default usePPHStats;
