import { createSingleAction } from 'store/utils';
import { ITravelExpenseFilters } from 'modules/travelExpenses/components/TravelExpenseFilter/model';

const SET_TRAVEL_EXPENSE_FILTER = 'SET_TRAVEL_EXPENSE_FILTER';
export const setTravelExpenseFilter = createSingleAction<
Partial<ITravelExpenseFilters>,
    typeof SET_TRAVEL_EXPENSE_FILTER
>(
    SET_TRAVEL_EXPENSE_FILTER,
);
