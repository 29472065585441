import React, { FC } from 'react';
import { Divider } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const useDottedDividerStyles = makeHighPriorityStyles(() => ({
    divider: {
        alignSelf: 'center',
        borderLeft: `1px dotted ${colors.gray}`,
        backgroundColor: colors.white,
    },
}));

interface IDottedDividerProps {
    height: number;
    customClasses?: string;
}

const DottedDivider: FC<IDottedDividerProps> = ({ height, customClasses, ...props }: IDottedDividerProps) => {
    const classes = useDottedDividerStyles();
    return (
        <Divider
            classes={{ root: `${classes.divider} ${customClasses}` }}
            orientation="vertical"
            flexItem
            style={{ height: height }}
            {...props}
        />
    );
};

export default DottedDivider;
