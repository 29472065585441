import { EafStatusSlug, EafType } from 'modules/employeeActionsForm/store/models';
import { IHeaderTab } from 'shared/components/common/headerTabs/HeaderTabs';
import { EafApprovalGridCellKey } from './types';

export const ALL_EAF_STATUSES_SLUG = 'all';
export const ALL_EAF_STATUSES = 'All';

export const EafApprovalStatusDisplayName: Record<EafStatusSlug | typeof ALL_EAF_STATUSES_SLUG, string> = {
    [EafStatusSlug.PendingApproval]: 'Pending Approval',
    [EafStatusSlug.ApprovalRejected]: 'Rejected',
    [EafStatusSlug.Approved]: 'Approved',
    [ALL_EAF_STATUSES_SLUG]: ALL_EAF_STATUSES,
};

export const EafApprovalCellTitleMap: Record<EafApprovalGridCellKey, string> = {
    [EafApprovalGridCellKey.Checkbox]: '',
    [EafApprovalGridCellKey.Employee]: 'Employee',
    [EafApprovalGridCellKey.ChangeType]: 'Type',
    [EafApprovalGridCellKey.OriginalValue]: 'Original',
    [EafApprovalGridCellKey.NewValue]: 'New',
    [EafApprovalGridCellKey.Manager]: 'Manager',
    [EafApprovalGridCellKey.CreationDate]: 'Created Date',
    [EafApprovalGridCellKey.EffectiveDate]: 'Effective Date',
    [EafApprovalGridCellKey.Actions]: '',
    [EafApprovalGridCellKey.Status]: 'Status',
    [EafApprovalGridCellKey.Reason]: 'Reason',
    [EafApprovalGridCellKey.Details]: '',
    [EafApprovalGridCellKey.Client]: 'Client',
    [EafApprovalGridCellKey.Docs]: 'Docs',
    [EafApprovalGridCellKey.Subassignment]: 'Subassignment',
};

export const EAF_APPROVAL_DEFAULT_TAB_STATUS = EafStatusSlug.PendingApproval;

export const EafTabs: Array<IHeaderTab> = (
    Object
        .entries(EafApprovalStatusDisplayName)
        .map(([value, label]) => ({ value, label }))
);

export const EAF_APPROVAL_GRID_PAGE_SIZE = 45;

export const EAF_APPROVAL_TYPES = [
    EafType.CompensationChange,
    EafType.Promotion,
    EafType.Demotion,
];
