import { isEmpty } from 'lodash-es';
import { useOfferLetterTemplates } from 'modules/offerLetter/components/OfferLetterForm/hooks/useOfferLetterTemplates';
import React, { useMemo, useState } from 'react';
import {
    defaultOfferLetterValues,
    IOfferLetterFormValues,
    IOfferLetterUser,
} from 'modules/offerLetter/components/OfferLetterForm/model';
import { useSelector } from 'react-redux';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import {
    selectClientApprovalLevels,
    selectClientTimeAndPayConfiguration,
} from 'store/entities/clients/selectors/timeAndPaySelectors';
import { IUserLookupRequest } from 'store/entities/users/model';
import { IOfferLetter } from '../../store/model';
import { OfferLetterMultiStepForm } from './OfferLetterMultiStepForm';
import { IUserInfo } from 'shared/models/User';

export interface ILookupFormWrapperProps {
    onSubmit?: (offer: IOfferLetterFormValues) => void;
    onCancel: () => void;
    selectedUser?: IOfferLetterUser;
    lookupData?: IUserLookupRequest;
    offer?: IOfferLetter;
    managers?: (IUserInfo | null)[];
    confirmMessage: (offer: IOfferLetterFormValues | null) => string;
    isLoading?: boolean;
}

function getOfferLetterFormInitialValues(
    approversCount: number,
    offer?: IOfferLetter,
    selectedUser?: IOfferLetterUser,
    managers?: (IUserInfo | null)[],
    lookupData?: IUserLookupRequest,
    isExternalEmployeeIdEnabled?: boolean,
    templates: Partial<IOfferLetterFormValues> = {},
): IOfferLetterFormValues {
    // @ts-ignore
    let baseValues: IOfferLetterFormValues = {
        ...defaultOfferLetterValues,
        approvers: new Array(approversCount).fill(null),
        ...selectedUser ? {
            userId: selectedUser.id,
            first_name: selectedUser.first_name,
            last_name: selectedUser.last_name,
            email: selectedUser.email,
            phone: selectedUser.cell_phone || '',
        } : {},
        ...offer ? {
            position: {
                ...offer.position,
                pay_rate_value: `${offer.pay_rate_value}`,
                pay_rate_type: offer.pay_rate_type,
            },
            positionValueId: offer.position_value_id,
            locationValueId: offer.location_value_id,
            departmentValueId: offer.department_value_id,
            template: offer.offer_letter_template_id || '',
            employeeType: offer.employment_type || null,
            payType: offer.pay_type || null,
            payRateType: offer.pay_rate_type || null,
            hours: offer.range_of_hours || null,
            locationId: offer.location?.id,
            startDate: offer.start_date,
            approvers: managers || [],
            employee_number: offer.employee_number,
            ...templates,
        } : {},
    };
    if (!isEmpty(lookupData) && !selectedUser) {
        // @ts-ignore
        baseValues = {
            ...baseValues,
            ...lookupData ? {
                first_name: lookupData.firstName,
                last_name: lookupData.lastName,
                email: lookupData.email,
                phone: lookupData.phone || '',
            } : {},
        };
    }
    return baseValues;
}

const OfferLetterFormWrapper = ({
    onSubmit,
    onCancel,
    selectedUser,
    lookupData,
    managers,
    offer,
    confirmMessage,
    isLoading = false,
}: ILookupFormWrapperProps) => {
    const [creatingOfferData, setConfirmOfferData] = useState<IOfferLetterFormValues | null>(null);

    const templates = useOfferLetterTemplates(offer);
    const approversCount = useSelector(selectClientApprovalLevels);
    const appSettings = useSelector(selectClientTimeAndPayConfiguration);
    const isExternalEmployeeIdEnabled = useMemo(() =>
        appSettings?.enableOfferLetterEmployeeNumber ?? false, [appSettings]);
    const initialValues: IOfferLetterFormValues = getOfferLetterFormInitialValues(
        approversCount,
        offer,
        selectedUser,
        managers,
        lookupData,
        isExternalEmployeeIdEnabled,
        templates,
    );

    const onSubmitForm = (values: IOfferLetterFormValues) => {
        setConfirmOfferData(values);
    };

    const onCancelConfirmation = () => {
        setConfirmOfferData(null);
    };

    const onConfirm = () => {
        if (creatingOfferData) {
            if (onSubmit) {
                onSubmit(creatingOfferData);
            }
            onCancelConfirmation();
        }
    };

    return (
        <>
            <OfferLetterMultiStepForm
                onCancel={onCancel}
                initialValues={initialValues}
                onSubmit={onSubmitForm}
                selectedEmployee={selectedUser}
                offer={offer}
                isSubmitting={isLoading}
            />
            <ConfirmationDialog
                open={Boolean(creatingOfferData)}
                onConfirm={onConfirm}
                onCancel={onCancelConfirmation}
            >
                {confirmMessage(creatingOfferData)}
            </ConfirmationDialog>
        </>
    );
};

export default OfferLetterFormWrapper;
