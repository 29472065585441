import { combineReducers } from 'redux';
import { isEmpty, set, isEqual } from 'lodash-es';
import { clearSelectorValue, setCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/actions';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';
import { getAssignments, getSubassignments } from 'store/entities/configuration/configurationAction';
import { getCustomFields } from 'store/entities/customFields/actions';
import { ActionsReturnTypes } from 'store/utils';

const commonEntryFormDefaultValues: ICommonEntryFormValues = {
    taskId: '',
    projectAssignment: null,
    position: null,
    notes: '',
    location: null,
    department: null,
    activity: null,
    entry_date: '',
    jobNumber: null,
    customFieldValues: {},
};

const commonEntryFormValues = (
    state = commonEntryFormDefaultValues,
    action: ReturnType<typeof setCommonEntryFormValues> | ReturnType<typeof clearSelectorValue>) => {

    switch (action.type) {
        case setCommonEntryFormValues.action:
        {
            const { payload } = action as ReturnType<typeof setCommonEntryFormValues>;
            return payload;
        }
        case clearSelectorValue.action: {
            const { payload } = action as ReturnType<typeof clearSelectorValue>;
            return set({ ...state }, payload, null) as ICommonEntryFormValues;
        }
        default:
            return state;
    }
};

type AutoselectActions =
| ReturnType<typeof setCommonEntryFormValues>
| ReturnType<typeof clearSelectorValue>
| ActionsReturnTypes<typeof getAssignments>
| ActionsReturnTypes<typeof getSubassignments>
| ActionsReturnTypes<typeof getCustomFields>;

export const applyAutoSelectSingleValue = (
    state = {
        apply: true,
        values: commonEntryFormDefaultValues,
    },
    action: AutoselectActions,
) => {
    switch (action.type) {
        case getSubassignments.successType:
        case getAssignments.successType:
        case getCustomFields.successType: {
            const values = state;
            return {
                apply: true,
                values,
            };
        }
        case setCommonEntryFormValues.action: {
            // @ts-ignore
            const actualValue = action.payload.customFieldValues;
            const previousValues = state.values.customFieldValues;
            const apply = isEqual(actualValue, previousValues) ? state.apply
                : isEmpty(actualValue) || Object.keys(actualValue).some(key => {
                    return actualValue?.[key] && !previousValues?.[key];
                });
            return {
                apply,
                // @ts-ignore
                values: commonEntryFormValues(state.values, action),
            };
        }
        case clearSelectorValue.action:
            return {
                apply: false,
                // @ts-ignore
                values: commonEntryFormValues(state.values, action),
            };
        default:
            return state;
    }
};

export const addEntry = combineReducers({
    commonEntryFormValues,
    applyAutoSelectSingleValue,
});
