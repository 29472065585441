import { setPayRangesFilter } from 'modules/settings/submodules/clients/payRanges/store/actions';
import { IPayRangeFilter } from 'modules/settings/submodules/clients/payRanges/store/models';
import { selectPayRangesFilter } from 'modules/settings/submodules/clients/payRanges/store/selectors';
import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import FilterChip from 'shared/components/filters/FilterChip';
import { isEmpty } from 'lodash-es';
import { getFieldValueName } from 'store/entities/customFields/helpers';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';

export function PayRangeFilterChips() {
    const dispatch = useDispatch();
    const filter = useSelector(selectPayRangesFilter);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);

    const onDelete = useCallback((name: keyof IPayRangeFilter) => {
        dispatch(setPayRangesFilter({
            ...filter,
            [name]: undefined,
        }));
    }, [dispatch, filter]);

    const hasFilters = !isEmpty(Object.values(filter).filter(Boolean));

    const position = customFieldValuesByIds[filter?.positionValueId];
    const location = customFieldValuesByIds[filter?.locationValueId];

    return hasFilters ? (
        <Box
            mb={2}
            data-testid={FiltersTestIds.ChipsWrapper}
        >
            {filter.stateCode && (
                <FilterChip
                    name="stateCode"
                    label={filter.stateCode}
                    onDelete={onDelete}
                />
            )}

            {filter.positionValueId && (
                <FilterChip
                    name="positionValueId"
                    label={getFieldValueName(position)}
                    onDelete={onDelete}
                />
            )}

            {filter.locationValueId && (
                <FilterChip
                    name="locationValueId"
                    label={getFieldValueName(location)}
                    onDelete={onDelete}
                />
            )}
        </Box>
    ) : null;
}
