import { useMemo } from 'react';
import { AccessTime, DescriptionOutlined, GetAppOutlined, NotInterested } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { IMenuCategory, TMenuCategoryChild } from 'shared/models/Global';
import { routes } from 'shared/routes';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { MenuItemEnum } from 'shared/models/MenuItem';
import { IGetHomeMenuParams } from './typings';

export function useHomeMenu({
    clients,
    clientsThemes,
}: IGetHomeMenuParams): Array<IMenuCategory> {
    const userCanAccessToHR = useSelector(selectIsUserHasPermission(Permission.ManageTerminationRequests));

    return useMemo(() => {
        const menuItems: Array<TMenuCategoryChild> = [
            {
                title: MenuItemEnum.OfferLetter,
                Icon: DescriptionOutlined,
                to: routes.EMPLOYEE_OFFER_LETTER.ROOT,
                permission: Permission.ViewMyOffers,
            },
        ];

        const clientsMenu = clients
            ? clients.map(client => ({
                id: client.id,
                title: client.name,
                iconUrl: clientsThemes?.[client.id]?.theme?.logo_url ?? `/images/horizontal-white-transparent_square.png`,
                to: `/client/${client.id}`,
            }))
            : [];

        return [
            {
                id: 'Common',
                children: menuItems,
            },
            {
                id: 'Clients',
                title: 'Clients',
                subSearch: true,
                subSearchPlaceholder: 'Search client',
                children: clientsMenu,
            },
            {
                id: 'Payroll',
                title: 'Payroll',
                permission: Permission.PPHReadAccess,
                children: [
                    {
                        title: MenuItemEnum.PayrollDashboard,
                        Icon: GetAppOutlined,
                        to: routes.PAYROLL_PROCESSOR_HUB.ROOT,
                        isExact: true,
                    },
                    {
                        title: MenuItemEnum.TimeExpensesManagement,
                        Icon: AccessTime,
                        to: routes.PAYROLL_PROCESSOR_HUB.SHEETS,
                    },
                ],
            },
            ...(userCanAccessToHR ? [
                {
                    id: 'Human resources',
                    title: 'Human resources',
                    children: [
                        {
                            title: MenuItemEnum.TerminationRequests,
                            Icon: NotInterested,
                            to: routes.HUMAN_RESOURCES.TERMINATION_REQUESTS,
                        },
                    ],
                },
            ] : []),
        ];
    }, [clients, clientsThemes, userCanAccessToHR]);
}
