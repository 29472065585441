import { Button } from '@material-ui/core';
import { IOfferLetter } from 'modules/offerLetter/store/model';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import useActionButtonStyles from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import { routes } from 'shared/routes';

interface IEditAndResendButtonProps {
    offerLetter: IOfferLetter;
}

export const EditAndResendButton = ({ offerLetter }: IEditAndResendButtonProps) => {
    const actionButtonClasses = useActionButtonStyles();

    return (
        <Link
            to={generatePath(routes.CLIENT.OFFER_LETTER.EDIT, {
                id: offerLetter.id,
                client_id: offerLetter.client_id,
            })}
            className={actionButtonClasses.buttonTextStyle}
        >
            <Button
                variant="contained"
                color="primary"
                classes={{
                    root: actionButtonClasses.buttonApprove,
                    label: actionButtonClasses.buttonTextStyle,
                }}
            >
                Edit & Resend
            </Button>
        </Link>
    );
};
