import { orderBy, uniqBy } from 'lodash-es';
import { formatUtcWithEstZone } from 'shared/models/Dates';
import { ItemsById } from 'shared/models/ItemsById';
import { ISheetApproval } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { getUserInitials } from 'shared/utils/converters/user';

export function formatUserTimestamp(user: IUserInfo | undefined, timestamp: string): string {
    const time = formatUtcWithEstZone(timestamp);
    const userInitials = user ? `(${getUserInitials(user)})` : '';
    return `${time} ${userInitials}`;
}

export function formatApprovals(approvals: ISheetApproval[], usersByIds: ItemsById<IUserInfo>): string {
    const orderedApprovals = orderBy(approvals, ['level', 'created_at'], ['desc', 'desc']);
    const uniqApprovalsByUser = uniqBy(orderedApprovals, 'user_id');
    const formattedApprovals = uniqApprovalsByUser.map(approval => {
        const approver = usersByIds[approval.user_id];
        return formatUserTimestamp(approver, approval.created_at);
    });
    return formattedApprovals.join(' | ');
}
