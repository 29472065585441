import { IModifiers } from 'shared/components/table/addEntryControls/quantity/model';

export const dollarsFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const noRoundingDollarsFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 20 });

export const moneyModifiers: IModifiers = { min: 0.01, max: 10 ** 4, fractional: 2 };

export const moneyModifiersDisabled: IModifiers = { min: 0.00, max: 10 ** 4, fractional: 2 };

export function moneyToString(money: number | undefined): string {
    if (money === undefined) {
        return '';
    }
    const [moneyIntPart = '0', moneyDecPart = '00'] = money.toString().split('.');
    const localizedMoneyString = moneyIntPart.toLocaleString();

    return `${localizedMoneyString}.${moneyDecPart.padEnd(2, '0')}`;
}

export function moneyToFormattedString(amount: number): string {
    const dollarsString = dollarsFormatter.format(amount);

    return dollarsString.replace('$', '');
}
