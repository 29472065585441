import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const SubmittingOrgValidationSchema = yup.object().shape({
    client_site_name: yup.string().trim().required(ValidationMessages.REQUIRED),
    status: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    controlling_org_id: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    submitting_org_number: yup.string().trim().required(ValidationMessages.REQUIRED),

    address: yup.string().trim().required(ValidationMessages.REQUIRED),
    city: yup.string().trim().required(ValidationMessages.REQUIRED),
    state: yup.string().trim().required(ValidationMessages.REQUIRED),
    zip_code: yup.string().trim().required(ValidationMessages.REQUIRED),

    location_id: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
});
