import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { isEqual } from 'lodash-es';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import ClientRoleSelect
    from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/ClientRoleSelect';
import {
    GlobalRoleSelect,
} from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/GlobalRoleSelect';
import { setClientUserAssociationFilter } from 'modules/settings/submodules/platformUsers/store/actions';
import { IClientUserAssociationFilter } from 'modules/settings/submodules/platformUsers/store/models';
import { selectClientUserAssociationFilter } from 'modules/settings/submodules/platformUsers/store/selectors';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    UserAutocompleteWithSearch,
} from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { UserType } from 'store/entities/users/model';
import { debounce } from 'ts-debounce';
import { textInputDebounceTimeout } from 'utils/constants';

export function ClientUserAssociationFilterInline() {
    const classes = useInlineFormStyles();
    const dispatch = useDispatch();

    const filter = useSelector(selectClientUserAssociationFilter);
    const formikData = useFormik<IClientUserAssociationFilter>({
        initialValues: filter,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit: () => {},
    });

    const onApplyFilter = useCallback((values: IClientUserAssociationFilter) => {
        dispatch(setClientUserAssociationFilter(values));
    }, [dispatch]);
    const onChangeDebounced = useMemo(() => {
        return debounce(onApplyFilter, textInputDebounceTimeout);
    }, [onApplyFilter]);

    const { values } = formikData;
    useEffect(() => {
        if (!isEqual(values, filter)) {
            onChangeDebounced(values);
        }
    }, [filter, values, onChangeDebounced]);

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
        >
            <FormikProvider value={formikData}>
                <form className={clsx(classes.form, classes.flexEnd)}>
                    <GlobalRoleSelect
                        name="global_role"
                        label="Global Role"
                        className={classes.input}
                    />
                    <ClientSelect
                        name="client"
                        label="Client"
                        className={classes.input}
                    />
                    <ClientRoleSelect
                        name="client_role"
                        label="Role"
                        className={classes.input}
                        title={values?.client?.id ? '' : 'Select Client first'}
                        disabled={!values?.client?.id}
                        clientId={values?.client?.id}
                        blockNameList={['Employee']}
                    />
                    <UserAutocompleteWithSearch
                        additionalFilter={{
                            user_type: UserType.Manager,
                            client_id: values?.client?.id || undefined,
                        }}
                        name="user_id"
                        label="Search User"
                        useIdValue
                        className={classes.input}
                    />
                </form>
            </FormikProvider>
        </Box>
    );
}
