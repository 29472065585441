import { createMuiTheme } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

export const createSidebarDarkTheme = (secondaryColor: string = colors.primary) => createMuiTheme({
    palette: {
        primary: {
            main: colors.white,
        },
        secondary: {
            main: secondaryColor,
        },
    },
    typography: {
        h6: {
            textTransform: 'uppercase',
            fontSize: 16,
            marginBottom: 16,
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: colors.gray,
                },
            },
            input: {
                fontSize: 14,
                '-moz-appearance': 'textfield',
                color: colors.white,
            },
            notchedOutline: {
                '&&, $disabled &&': {
                    borderColor: colors.white,
                },
            },
        },
        MuiInputLabel: {
            root: {
                '&&, &&$error, &&$disabled': {
                    color: colors.white,
                },
            },
            shrink: {
                '&$error': {
                    color: colors.error,
                },
            },
        },
    },
});

export const sidebarDarkSectionTheme = createMuiTheme({
    palette: {
        primary: {
            main: colors.white,
        },
    },
    typography: {
        h6: {
            textTransform: 'uppercase',
            fontSize: 16,
            marginBottom: 16,
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: colors.gray,
                },
            },
            input: {
                fontSize: 14,
                '-moz-appearance': 'textfield',
                color: colors.white,
            },
            notchedOutline: {
                '&&, $disabled &&': {
                    borderColor: colors.white,
                },
            },
        },
        MuiInputLabel: {
            root: {
                '&&, &&$error, &&$disabled': {
                    color: colors.white,
                },
            },
            shrink: {
                '&$error': {
                    color: colors.error,
                },
            },
        },
    },
});
