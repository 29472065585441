import React, { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { toggleEafApprovalCheckedItemId } from 'modules/eafApproval/store/actions';
import { EafApprovalCellProps } from 'modules/eafApproval/store/model/types';
import { selectEafApprovalCheckedItemsIds } from 'modules/eafApproval/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBoxCellPure } from 'shared/components/table/Cells/checkBoxHelper';
import { EafStatusSlug, EafType } from 'modules/employeeActionsForm/store/models';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useIsUserHasApprovePermissionByEafId } from 'modules/eafApproval/store/hooks';

const EafApprovalGridCellCheckBoxCore = ({ rowId, className }: { rowId: string, className?: string }) => {
    const tableClasses = useDefaultTableStyles();
    const dispatch = useDispatch();
    const checkedItemsIds = useSelector(selectEafApprovalCheckedItemsIds);
    const checkedItems = useMemo(() => Object.fromEntries(checkedItemsIds.map(id => ([id, true]))), [checkedItemsIds]);

    const handleCheck = useCallback(() => {
        dispatch(toggleEafApprovalCheckedItemId(rowId));
    }, [rowId, dispatch]);

    return (
        <CheckBoxCellPure
            checkedItems={checkedItems}
            onCheck={handleCheck}
            classes={tableClasses}
            rowClassName={className}
            rowId={rowId}
        />
    );
};

export const getEafApprovalGridCellCheckbox = (exceptTypes: EafType[] = []) => function EafApprovalGridCellCheckbox({
    id,
    className,
    status,
    employee_action_form_data,
}: EafApprovalCellProps) {
    const userHasApprovePermission = useIsUserHasApprovePermissionByEafId(id);
    if (
        status?.slug !== EafStatusSlug.PendingApproval
        || !userHasApprovePermission
        || exceptTypes.includes(employee_action_form_data.employee_action_form_type)
    ) {
        return (
            <Box className={className}/>
        );
    }
    return <EafApprovalGridCellCheckBoxCore rowId={id} className={className}/>;
};
