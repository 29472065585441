import React from 'react';
import { Box, Button } from '@material-ui/core';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import {
    JobNumberAutocompleteWithSearch,
} from 'shared/components/autocomplete/jobNumbers/JobNumberAutocompleteWithSearch';
import { useFilterContentStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import { showFilter } from 'shared/components/forms/utils';
import { ActivitySelect } from 'shared/components/selects/ActivitySelect/ActivitySelect';
import { CustomFieldValuesSelect } from 'shared/components/selects/CustomFieldValuesSelect/CustomFieldValuesSelect';
import { JobNumbers } from 'shared/components/selects/JobNumbers';
import { PayPeriod } from 'shared/components/selects/PayPeriod';
import { User } from 'shared/components/selects/User';
import { IFiltersAllOptions, IFilterStoreProps } from 'shared/models/Filters';
import { EntryType } from 'shared/models/sheet/Sheet';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentUser, selectIsUserHasPermission } from 'store/components/auth/selectors';
import { FilterSlug } from 'store/entities/clients/clientsModel';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { selectOrderedNonHiddenCustomFieldNodes } from 'store/entities/customFields/selectors';
import { UserType } from 'store/entities/users/model';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';

interface IFilterFormProps extends FormikProps<Partial<IFiltersAllOptions>>, Pick<IFilterStoreProps, 'selectFiltersConfig'> {
    entryType?: EntryType;
    tableFieldsWithValuesById?: Record<string, ICustomFieldValue[]>,
    withInfinityTable?: boolean;
}

export function FilterForm({
    selectFiltersConfig, values, handleSubmit, entryType, tableFieldsWithValuesById, withInfinityTable = false,
}: IFilterFormProps) {
    const classes = useFilterContentStyles();
    const { job_number_id } = values;

    const filters = useSelector(selectFiltersConfig);

    const user = useSelector(selectCurrentUser);
    const isUserHasApprovePermission = useSelector(selectIsUserHasPermission(Permission.ApproveSheets));
    const customFieldNodes = useSelector(selectOrderedNonHiddenCustomFieldNodes);
    const clientId = useSelector(selectCurrentClientId);

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.formInputsWrapper}>
                {showFilter(filters, FilterSlug.Employee) && (
                    withInfinityTable ? (
                        <UserAutocompleteWithSearch
                            name="user_id"
                            useIdValue
                            className={classes.selectField}
                            label={filters.employee.placeholder}
                            additionalFilter={{
                                user_type: UserType.Employee,
                                client_id: clientId || undefined,
                            }}
                        />
                    ) : (
                        <User
                            name="user_id"
                            useIdValue
                            className={classes.selectField}
                            label={filters.employee.placeholder}
                        />
                    )
                )}

                {showFilter(filters, FilterSlug.PayPeriod) && (
                    <PayPeriod
                        name="pay_period"
                        className={classes.selectField}
                        label={filters.pay_period.placeholder}
                        allPeriods={withInfinityTable}
                    />
                )}

                {showFilter(filters, FilterSlug.JobNumber) && (
                    withInfinityTable ? (
                        <JobNumberAutocompleteWithSearch
                            name="job_number_id"
                            label={filters.job_number.placeholder}
                            additionalFilter={{
                                user_id: isUserHasApprovePermission ? undefined : user?.id,
                                filters: isUserHasApprovePermission
                                    ? JSON.stringify({ 'manager_id': { 'eq': user?.id } })
                                    : undefined,
                            }}
                            className={classes.selectField}
                            useIdValue
                        />
                    ) : (
                        <JobNumbers
                            name="job_number_id"
                            label={filters.job_number.placeholder}
                            userId={isUserHasApprovePermission ? undefined : user?.id}
                            className={classes.selectField}
                            useIdValue
                        />
                    )
                )}

                {showFilter(filters, FilterSlug.Activity) && (
                    <ActivitySelect
                        name="activity_id"
                        label={filters.activity.placeholder}
                        entryType={entryType}
                        className={classes.selectField}
                        disabled={!job_number_id}
                        title={showFilter(filters, FilterSlug.JobNumber) ? 'Select job number first' : ''}
                        useIdValue
                    />
                )}

                {customFieldNodes.map(customFieldNode => {
                    const fieldTableValues = (tableFieldsWithValuesById
                                            && tableFieldsWithValuesById[customFieldNode.custom_field_id]) || [];
                    const valuesToShow = tableFieldsWithValuesById ? fieldTableValues : undefined;
                    return (
                        <>
                            {(!tableFieldsWithValuesById || fieldTableValues.length > 0) && (
                                <CustomFieldValuesSelect
                                    key={ customFieldNode.custom_field_id }
                                    customFieldId={ customFieldNode.custom_field_id }
                                    name={ `customFieldValues[${ customFieldNode.custom_field_id }]` }
                                    useIdValue
                                    useLabel
                                    className={ classes.selectField }
                                    valuesToShow={ valuesToShow }
                                />
                            )}
                        </>
                    );
                })}
            </Box>

            <Button color="primary"
                variant="contained"
                type="submit"
                fullWidth
                classes={{ root: classes.applyButtonRoot, label: classes.applyButtonLabel }}
            >
                Apply
            </Button>
        </form>
    );
}
