import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { routes } from 'shared/routes';
import { EmployeeListPage } from 'modules/users/EmployeeListPage';
import { ManagersListPage } from 'modules/users/ManagersListPage';
import { Permission } from 'store/components/auth/authModels';

export const UsersModuleRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path={routes.CLIENT.USERS.EMPLOYEES}
                component={EmployeeListPage}
                permission={Permission.ImpersonateAsEmployee}
            />
            <PrivateRoute
                path={routes.CLIENT.USERS.MANAGERS}
                component={ManagersListPage}
                permission={Permission.ImpersonateAsManager}
            />
            <Redirect
                exact
                from={routes.CLIENT.USERS.ROOT}
                to={routes.CLIENT.USERS.EMPLOYEES}
            />
        </Switch>
    );
};
