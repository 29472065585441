import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const usePreAndPostInitializeReportStyles = makeStyles(theme => ({
    mainContent: {
        padding: theme.spacing(4),
        border: `solid ${colors.lightGray} 2px`,
        backgroundColor: colors.white,
    },
    tableHeadCell: {
        fontWeight: 'bold',
    },
}));
