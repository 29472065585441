import React from 'react';
import Toolbar, { ToolbarClassKey } from '@material-ui/core/Toolbar';
import { Hidden } from '@material-ui/core';

interface IToolbarApprovalProps {
    classes?: Partial<Record<ToolbarClassKey, string>>;
    children?: JSX.Element;
}

export default function ToolbarApproval({ classes, children }: IToolbarApprovalProps) {
    return (
        <Hidden smUp>
            <Toolbar classes={classes}>
                {children}
            </Toolbar>
        </Hidden>
    );
}
