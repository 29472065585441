import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { getIncreasedSidebarWidth } from 'shared/styles/constants';

export const useRecallStyles = makeHighPriorityStyles((theme: Theme) => ({
    paper: {
        ...getIncreasedSidebarWidth(theme),
    },
    sidebarSection: {
        padding: theme.spacing(6),
    },
    infoText: {
        lineHeight: 'normal',
        letterSpacing: 'normal',

        '& + *': {
            marginTop: theme.spacing(3),
        },
    },
    secondaryButton: {
        marginLeft: theme.spacing(2),
        fontWeight: theme.typography.fontWeightRegular,

        '&, &:hover': {
            textDecoration: 'underline',
        },
    },
}));
