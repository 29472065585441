import { createRequestActions, createSingleAction, RequestType } from 'store/utils';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { ICreateSubmittingOrgPayload } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/models';

export const createSubmittingOrg = createRequestActions<ICreateSubmittingOrgPayload, ISubmittingOrg>(
    RequestType.Create,
    'SUBMITTING_ORG',
    'SETTINGS/');

const SET_CREATE_SUBMITTING_ORG_MODAL_STATE = 'settings/SET_CREATE_SUBMITTING_ORG_MODAL_STATE';
export const setCreateSubmittingOrgModalState = createSingleAction<boolean,
    typeof SET_CREATE_SUBMITTING_ORG_MODAL_STATE>(
    SET_CREATE_SUBMITTING_ORG_MODAL_STATE,
);
