import React, { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { setEafApprovalCheckedItemsIds } from 'modules/eafApproval/store/actions';
import { EafApprovalTitleCellProps } from 'modules/eafApproval/store/model/types';
import { selectEafApprovalCheckedItemsIds } from 'modules/eafApproval/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBoxTitlePure } from 'shared/components/table/Cells/checkBoxHelper';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { EafStatusSlug, EafType } from 'modules/employeeActionsForm/store/models';

export const getEafApprovalGridTitleCellCheckbox = (
    exceptTypes: EafType[] = [],
) => function EafApprovalGridTitleCellCheckbox({ rows }: EafApprovalTitleCellProps) {
    const tableClasses = useDefaultTableStyles();
    const selectedItemsIds = useSelector(selectEafApprovalCheckedItemsIds);
    const dispatch = useDispatch();

    const pendingItems = useMemo(() => {
        return rows.filter(row => row.status?.slug === EafStatusSlug.PendingApproval
            && !exceptTypes.includes(row.employee_action_form_data.employee_action_form_type));
    }, [rows]);

    const checkedAll = useMemo(() => {
        if (!pendingItems.length) {
            return false;
        }
        return pendingItems.every(item => selectedItemsIds.includes(item.id));
    }, [pendingItems, selectedItemsIds]);

    const handleCheckAll = useCallback((unchecked: boolean) => {
        if (unchecked) {
            dispatch(setEafApprovalCheckedItemsIds(pendingItems.map(item => item.id)));
        } else {
            dispatch(setEafApprovalCheckedItemsIds([]));
        }
    }, [dispatch, pendingItems]);

    useEffect(() => {
        return function cleanUp() {
            dispatch(setEafApprovalCheckedItemsIds([]));
        };
    }, [dispatch]);

    const wrapperClassName = clsx(
        tableClasses.iconCell,
        tableClasses.checkbox,
        tableClasses.headerCheckboxWrapper,
    );

    return (
        <Box className={wrapperClassName}>
            <CheckBoxTitlePure
                checkedAll={checkedAll}
                onCheckAll={handleCheckAll}
                classes={tableClasses}
            />
        </Box>
    );
};
