import { useBulkPostEndOverviewItems } from 'modules/subassignmentManagement/components/BulkEndAssignment/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBulkEndDataOverviewItems } from 'modules/subassignmentManagement/components/BulkEndAssignment/store/selectors';
import { usePayrollReportStyle } from 'modules/payrollProcessorHub/components/PayrollModal/reports/PayrollStyle';
import { getBulkEndReportCells } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/cells';
import ReportOverview from 'modules/payrollProcessorHub/components/PayrollModal/reports/ReportOverview';
import { BulkEditReportV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/BulkEditReportV2';

interface IBulkEditPostInitializeV2ReportProps {
    onClose: () => void;
}

export const BulkEndPostInitializeV2Report = ({ onClose }: IBulkEditPostInitializeV2ReportProps) => {
    const overviewItems = useBulkPostEndOverviewItems();
    const dataOverviewItems = useSelector(selectBulkEndDataOverviewItems);

    const overviewClasses = usePayrollReportStyle();
    const additionalOverview = (
        <ReportOverview
            blocks={dataOverviewItems}
            classes={
                {
                    column: overviewClasses.SummaryInfoColumn,
                    row: overviewClasses.SummaryInfoRow,
                }
            }
        />
    );

    return (
        <BulkEditReportV2
            handleAction={onClose}
            title="Post-Initialize Roll Up"
            buttonTitle="OK"
            overviewItems={overviewItems}
            additionalOverview={additionalOverview}
            getCells={getBulkEndReportCells}
        />
    );
};
