import baseApi from 'shared/utils/baseApi';

import { IPaySettings } from 'store/entities/clients/clientsModel';

export const api = {
    async syncPaySettings(prism_client_id: string): Promise<IPaySettings> {
        const { data } = await baseApi.post<{}, IPaySettings>(
            `/prismhr/clients/${prism_client_id}/pay_settings`, {},
        );
        return data;
    },
};
