import { createRequestActions, RequestType } from 'store/utils';

export const autocompleteActionPrefix = 'autocomplete/search';

export interface IAutocompleteSearchPayload {
    searchKey: string;
}

export interface IAutocompleteSearchResultPayload extends IAutocompleteSearchPayload {
    results: string[];
}

/**
 * Search entities
 */
export const searchEntity = createRequestActions<IAutocompleteSearchPayload, IAutocompleteSearchResultPayload>(
    RequestType.Get,
    'ENTITY',
    autocompleteActionPrefix,
);
