/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { TotalBlock } from 'shared/components/sidebars/SheetDetail/TotalBlock';
import { PayType } from 'shared/models/PayType';
import { EntryType, IEntry, ISheet } from 'shared/models/sheet/Sheet';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';
import { selectCalculationByTimesheetId } from 'store/entities/timesheet/selectors';

interface ITotalBlockProps {
    sheet: ISheet;
    entries: IEntry[];
    entryType: EntryType;
}

export function SheetTotalBlock({
    sheet,
    entries,
    entryType,
}: ITotalBlockProps) {
    const assignment = useSelector(selectAssignmentsById)[sheet?.assignment_id];
    const calculation = useSelector(selectCalculationByTimesheetId(sheet?.id));

    return (
        <TotalBlock
            entryType={entryType}
            entries={entries}
            totalReg={formatDecimalHoursStringAsHoursAndMinutes(calculation?.rt_hours || '0')}
            totalOvertime={formatDecimalHoursStringAsHoursAndMinutes(calculation?.ot_hours || '0')}
            totalDoubletime={formatDecimalHoursStringAsHoursAndMinutes(calculation?.dt_hours || '0')}
            totalHolidays={formatDecimalHoursStringAsHoursAndMinutes(calculation?.holiday_hours || '0')}
            totalPto={formatDecimalHoursStringAsHoursAndMinutes(calculation?.pto_hours || '0')}
            isSalaried={assignment?.pay_type === PayType.Salaried}
        />
    );
}
