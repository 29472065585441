interface IFormField extends Element {
    form: any;
}

export const focusNextField = () => { //old school method to change focus
    const activeField = document.activeElement as IFormField;
    //add all elements we want to include in our selection
    const focussableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), select:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

    if (activeField && activeField.form) {
        const focussable = Array.prototype.filter.call(activeField.form.querySelectorAll(focussableElements),
            function (element) {
                //check for visibility while always include the current activeElement
                return element.offsetWidth > 0 || element.offsetHeight > 0 || element === activeField;
            });
        const index = focussable.indexOf(activeField);
        if (index > -1) {
            const nextElement = focussable[index + 1] || focussable[0];
            nextElement.focus();
        }
    }
};
