import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import {
    addNewCcpAttachment,
    CcpAttachmentActions,
    removeCcpAttachment,
    SET_CCP_FILE_READ,
    SET_OPEN_SUBMIT_CCP_MODAL,
    setConfirmSubmitCcpModal,
    setOpenNoteEditCcpSidebar,
    setOpenReceiptEditCcpSidebar,
    setOpenSubmitCcpModal,
    SubmitCcpTransactionsSteps,
} from './actions';
import { AnyAction, combineReducers } from 'redux';
import {
    submitCcpTransactionAction,
    updateCcpAvailableUserIdsList,
} from '../../../store/actions';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const noteSideBarSelectedTransactionId = singleValueReducer(setOpenNoteEditCcpSidebar.action, false);
const receiptSideBarSelectedTransactionId = singleValueReducer(setOpenReceiptEditCcpSidebar.action, false);

const attachmentDefaultState = {
    isUploading: false,
    attachments: [],
};

const isCcpAttachmentUploading = isLoadingReducer(addNewCcpAttachment, attachmentDefaultState.isUploading);
const isCcpAttachmentDeleting = isLoadingReducer(removeCcpAttachment, attachmentDefaultState.isUploading);

function setCcpFileRead(state = attachmentDefaultState.isUploading, action: CcpAttachmentActions): boolean {
    switch (action.type) {
        case SET_CCP_FILE_READ:
            return action.payload;
        case addNewCcpAttachment.successType:
        case removeCcpAttachment.successType:
            return false;
        default:
            return state;
    }
}

const isOpenBulkCcpCreationUploadModal = singleValueReducer(setOpenSubmitCcpModal.action, false);
const isConfirmedCcpSubmit = singleValueReducer(setConfirmSubmitCcpModal.action, false);

const ccpSubmitStep = (
    state = SubmitCcpTransactionsSteps.Confirm,
    action: AnyAction,
): SubmitCcpTransactionsSteps => {
    switch (action.type) {
        case SET_OPEN_SUBMIT_CCP_MODAL:
            return SubmitCcpTransactionsSteps.Confirm;
        case submitCcpTransactionAction.successType:
            return SubmitCcpTransactionsSteps.Results;
        default:
            return state;
    }
};

const availableUserIds = singleValueReducer(updateCcpAvailableUserIdsList.action, []);

const usersCcpDefaultState = {
    usersList: [],
};

export function allAvailableUserIds(
    state: string[] = usersCcpDefaultState.usersList,
    action: AnyAction,
): string[] {
    switch (action.type) {
        case updateCcpAvailableUserIdsList.action:
            return [...action.payload, ...state];
        default:
            return state;
    }
}

const isCcpSubmitting = isLoadingReducer(submitCcpTransactionAction, false);

const postReport = extendReducer(
    singleValueReducer(submitCcpTransactionAction.successType, []),
);

export const editCcpTransactions = combineReducers({
    noteSideBarSelectedTransactionId,
    receiptSideBarSelectedTransactionId,
    isCcpAttachmentUploading,
    isCcpAttachmentDeleting,
    isOpenBulkCcpCreationUploadModal,
    setCcpFileRead,
    ccpSubmitStep,
    allAvailableUserIds,
    isConfirmedCcpSubmit,
    isCcpSubmitting,
    postReport,
    availableUserIds,
});

export type CcpAttachmentsState = ReturnType<typeof editCcpTransactions>;
