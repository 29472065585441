import React, { useEffect } from 'react';

import { PayrollFilter } from 'modules/payrollProcessorHub/components/PayrollFilter/PayrollFilter';
import { setHeaderContent } from 'modules/home/header/Header';
import { Box } from '@material-ui/core';

const useFilterHeader = () => {
    useEffect(() => {
        setHeaderContent(
            <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                width="100%"
            >
                <PayrollFilter isDashboard={true}/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, []);
};

export default useFilterHeader;
