import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const formBasicSpacing = 2;
export const useSettingsFormStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '900px',
        boxSizing: 'border-box',
        padding: theme.spacing(0, 7, 7, 7),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3, 2, 0),
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    smallPadding: {
        padding: theme.spacing(0, 2, 2, 2),
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    noPadding: {
        padding: 0,
    },
    input: {
        margin: theme.spacing(formBasicSpacing, formBasicSpacing, 0),
        display: 'flex',
        flexGrow: 1,
    },
    labelWithValue: {
        margin: theme.spacing(formBasicSpacing, formBasicSpacing, formBasicSpacing),
    },
    button: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            width: '100%',
            height: theme.spacing(7),
        },
    },
    formBlock: {
        position: 'relative',
        padding: theme.spacing(2, 0, 4, 0),
        '& + &': {
            borderTop: `1px solid ${colors.gray}`,
        },
    },
    formBlockTitle: {
        color: colors.primary,
        paddingLeft: theme.spacing(1.5),
        [theme.breakpoints.down('xs')]: {
            position: 'block',
        },
    },
    formBlockLine: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-end',
    },
    wideForm: {
        display: 'flex',
        minWidth: '1000px',
        maxWidth: '1100px',
        width: '1064px',
    },
    formBlockCard: {
        background: colors.whiteSecondary,
        border: `1px solid ${colors.lightGray}`,
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        '& input, & select, & [class*="MuiTextField-root"]': {
            background: colors.white,
        },
        '& input:disabled': {
            background: 'inherit',
        },
    },
    formBlockCardTitle: {
        color: colors.primary,
    },
    smallInput: {
        display: 'flex',
        flexGrow: 1,
        '& input': {
            background: colors.white,
            minHeight: 24,
            fontSize: 12,
        },
        '& label, & span': {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: 12,
        },
    },
    inlineLabel: {
        display: 'block',
        minWidth: 'max-content',
    },
    tabIndicator: {
        backgroundColor: theme.palette.secondary.main,
    },
    sectionHelp: {
        display: 'block',
        marginLeft: theme.spacing(-4),
        marginTop: theme.spacing(1),
    },
    separateLabelWithFixedWidth: {
        marginTop: theme.spacing(1),
        '& label': {
            width: theme.spacing(30),
        },
    },
}));

export const useSettingsFormGridStyles = () => {
    const useOverrideClasses = makeHighPriorityStyles((theme: Theme) => ({
        input: {
            marginTop: theme.spacing(formBasicSpacing),
            display: 'flex',
            flexGrow: 1,
        },
        formBlockTitle: {
            color: colors.primary,
            [theme.breakpoints.down('xs')]: {
                position: 'block',
            },
        },
    }));
    return {
        ...useSettingsFormStyles(),
        ...useOverrideClasses(),
    };
};
