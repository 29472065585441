import React, { useEffect } from 'react';

export const BackspaceListener = () => {
    /*
     * this swallows backspace keys on any non-input element.
     * stops backspace -> back behavior
     */
    useEffect(() => {
        const BackspaceKeyCode = 'Backspace';
        const inputTargetRegex = /INPUT|SELECT|TEXTAREA/i;
        const eventHandler = (e: KeyboardEvent) => {
            if (e.code === BackspaceKeyCode && !inputTargetRegex.test((e.target as HTMLElement).tagName)){
                e.preventDefault();
            }
        };
        document.addEventListener('keydown', eventHandler);
        return () => {
            document.removeEventListener('keydown', eventHandler);
        };
    }, []);
    return (<></>);
};
