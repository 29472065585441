import { call, put, takeLatest } from 'typed-redux-saga';

import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { invoiceRecipientsApi } from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/api';
import { getInvoiceRecipients } from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/actions';

function* getInvoiceRecipientsSaga(action: ReturnType<typeof getInvoiceRecipients.init>) {
    const { controllingOrgId, submittingOrgId } = action.payload;
    const invoiceRecipients = yield* call(invoiceRecipientsApi.getInvoiceRecipients, controllingOrgId, submittingOrgId);
    yield put(getInvoiceRecipients.success(invoiceRecipients));
}

export function* getInvoiceRecipientsWatcher() {
    yield* takeLatest(
        getInvoiceRecipients.initType,
        withBackendErrorHandler(
            getInvoiceRecipientsSaga,
            getInvoiceRecipients.error,
            'Unable to fetch Invoice Recipients',
        ),
    );
}
