import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { lookupUsers } from 'store/entities/users/actions';
import { IUserLookupRequest } from 'store/entities/users/model';
import { selectLookupIsLoading } from 'modules/offerLetter/components/lookup/data/selectors';
import { defaultLookupValues, ILookupFormClasses, LookupForm } from 'modules/offerLetter/components/lookup/forms/LookupForm';

export interface ILookupFormWrapperProps {
    classes?: ILookupFormClasses;
    onSubmit?: () => void;
}

const LookupFormWrapper = ({ classes, onSubmit }: ILookupFormWrapperProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectLookupIsLoading);
    const onSubmitLookup = (values: IUserLookupRequest) => {
        dispatch(lookupUsers.init(values));
        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <Formik
            initialValues={defaultLookupValues}
            onSubmit={onSubmitLookup}
            validateOnBlur={false}
            isInitialValid={false}
        >
            {props => (
                <LookupForm
                    {...props}
                    classes={classes}
                    isLoading={isLoading}
                />
            )}
        </Formik>
    );
};

export default LookupFormWrapper;
