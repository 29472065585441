import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { formBasicSpacing } from 'modules/settings/submodules/components/sharedStyles/formStyles';

export const usePaySettingsFormStyles = makeHighPriorityStyles((theme: Theme) => ({
    input: {
        margin: theme.spacing(formBasicSpacing, formBasicSpacing, 0),
        display: 'flex',
        flexGrow: 0,
    },
}));
