import React from 'react';
import { Button } from '@material-ui/core';
import { ISheetCalculationBatch } from 'modules/payrollProcessorHub/store/model';
import { useManagersList } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/hooks/useManagersList';
import { getUniqValues } from 'modules/payrollProcessorHub/store/helpers';
import { useSelector } from 'react-redux';
import { useOpenState } from 'shared/hooks/useOpenState';
import { UnlockConfirmationDialogSeveralApprovers } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/UnlockConfirmationDialogSeveralApprovers';
import { UnlockConfirmationDialogSingleApprover } from 'modules/payrollProcessorHub/components/PayrollAction/UnlockAction/UnlockConfirmationDialogSingleApprover';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export interface IUnlockGroupButtonProps {
    calculationGroup: ISheetCalculationBatch;
}

export function UnlockGroupButton({ calculationGroup }: IUnlockGroupButtonProps) {
    const approvers = useManagersList(calculationGroup);
    const hasMultipleApprovers = approvers.length > 1;
    const { isOpened, open, close } = useOpenState();
    const timeSheetIds = getUniqValues(calculationGroup.time_calculations, 'sheet_id');
    const expenseSheetIds = getUniqValues(calculationGroup.expense_calculations, 'sheet_id');

    const userHasPermission = useSelector(selectIsUserHasPermission(Permission.CanUnlockPphSheet));
    if (!userHasPermission) {
        return null;
    }

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                onClick={open}
            >
                Unlock
            </Button>

            {hasMultipleApprovers ? (
                <UnlockConfirmationDialogSeveralApprovers
                    onCancel={close}
                    isOpened={isOpened}
                    calculationGroup={calculationGroup}
                />
            ) : (
                <UnlockConfirmationDialogSingleApprover
                    onCancel={close}
                    isOpened={isOpened}
                    timeSheetIds={timeSheetIds}
                    expenseSheetIds={expenseSheetIds}
                />
            )}
        </>
    );
}
