import baseApi from 'shared/utils/baseApi';
import { gwClientExtensionsService } from 'store/entities/configuration/configurationApi';
import {
    IAssignmentReportData,
    IAssignmentReportDataRequest,
    IAssignmentReportDataResponse,
    IAssignmentReportDataUpdateRequest,
    IAssignmentReportDataCreateRequest,
} from 'modules/settings/submodules/clients/assignmentsReportData/store/model';

export const assignmentReportDataApi = {
    async getAssignmentReportsData(request: IAssignmentReportDataRequest): Promise<IAssignmentReportDataResponse> {
        const { data } = await baseApi.get<IAssignmentReportDataResponse>(
            `/${gwClientExtensionsService}/user_meta`,
            request,
        );
        return data;
    },
    async updateAssignmentReportMetaData(
        id: string,
        request: IAssignmentReportDataUpdateRequest,
    ): Promise<IAssignmentReportData> {
        const { data } = await baseApi.patch<IAssignmentReportDataUpdateRequest, IAssignmentReportData>(
            `/${gwClientExtensionsService}/user_meta/${id}`,
            request,
        );
        return data;
    },
    async createAssignmentReportMetaData(
        request: IAssignmentReportDataCreateRequest,
    ): Promise<IAssignmentReportData> {
        const { data } = await baseApi.post<IAssignmentReportDataCreateRequest, IAssignmentReportData>(
            `/${gwClientExtensionsService}/user_meta`,
            request,
        );
        return data;
    },
};
