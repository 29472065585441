import { put } from 'typed-redux-saga';
import {
    getJobNumbers,
    getProjectsAssignments,
    getSubmittingOrgs,
} from 'store/entities/configuration/configurationAction';
import { sagaSelectUserId } from 'store/entities/timesheet/sagas/utils';
import { Permission } from '../../auth/authModels';

export function* getOwnConfigurationGenworthSaga() {
    yield put(getProjectsAssignments.init({ purpose: Permission.SubmitSheets }));
    const userId = yield* sagaSelectUserId();
    yield put(getJobNumbers.init({ user_id: [userId] }));
    yield put(getSubmittingOrgs.init());
}
