import { createRequestActions, createSingleAction, RequestType } from 'store/utils';

import { IUpdateDealPayload } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/models';
import { IDeal } from 'shared/models/Deal';

export const SET_EDIT_DEAL_ID = 'SET_EDIT_DEAL_ID';
export const setEditDealId = createSingleAction<
string | null, typeof SET_EDIT_DEAL_ID>(SET_EDIT_DEAL_ID);

export const updateDeal = createRequestActions<IUpdateDealPayload, IDeal>(
    RequestType.Update,
    'DEAL',
    'SETTINGS/');
