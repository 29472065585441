export interface IEmployeeProfileFormValues {
    first_name: string;
    last_name: string;
    birthday: string;
    ssn: string;
    ssn_confirmation: string;
    county: string;
    state: string;
    address: string;
    address2: string;
    city: string;
    zip_code: string;
}

export const defaultEmployeeProfileValues: IEmployeeProfileFormValues = {
    first_name: '',
    last_name: '',
    birthday: '',
    ssn: '',
    ssn_confirmation: '',
    county: '',
    state: '',
    address: '',
    address2: '',
    city: '',
    zip_code: '',
};
