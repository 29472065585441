import {
    initialLoadApprovalSheets,
    loadMoreApprovalSheets,
    setSheetApprovalTableSort,
} from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/actions';
import { ISheetGroupResponsePayload } from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/model';
import {
    approvalTableEntityType,
    approvalTableSelectors,
    selecSheetApprovalTableSort,
} from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/selectors';
import { selectTimeAndExpenseActiveStatus } from 'modules/timeAndExpense/store/selectors';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import {
    setManagerSubmittedSheetsFilters,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsActions';
import {
    selectManagerSubmittedSheetsFilters,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';
import { loadSheetRelatedData } from 'store/components/sheetApprovalCommon/sheetApprovalCommonSagas';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';
import { timeApi } from 'store/entities/timesheet/api/timeApi';
import { ISheetGroup, ISheetGroupListParams } from 'store/entities/timesheet/models/SheetApi';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { selectExpenseSheetStatusByName, selectTimeSheetStatusByName } from 'store/entities/timesheet/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getJobNumbers } from '../../../../../store/entities/configuration/configurationAction';

export function* getMoreApprovalSheetsSaga() {
    const cursor = yield* select(approvalTableSelectors.selectCursor);
    const pageSize = yield* select(approvalTableSelectors.selectPageSize);
    const filter = yield* select(selectManagerSubmittedSheetsFilters);
    const activeStatus = yield select(selectTimeAndExpenseActiveStatus);
    const entryType = yield select(approvalTableEntityType);

    let statusMixin;
    const statusSelector = entryType === EntryType.TIME
        ? selectTimeSheetStatusByName : selectExpenseSheetStatusByName;
    if (activeStatus === StatusNames.ALL) {
        const status = yield* select(statusSelector(StatusNames.WORKING));
        statusMixin = {
            exclude_status_id: status?.id,
        };
    }
    if (activeStatus === StatusNames.MISSING) {
        statusMixin = {
            is_missing: true,
        };
    } else {
        const status = yield* select(statusSelector(activeStatus));
        statusMixin = {
            status_id: status?.id,
        };
    }

    const sort = yield select(selecSheetApprovalTableSort);
    const filterCustomFieldValues = Object.values(filter.customFieldValues || {});
    const notEmptyFilterCustomFieldValues = filterCustomFieldValues.filter(Boolean).join(',');
    const request: ISheetGroupListParams = {
        ...statusMixin,
        limit: pageSize,
        cursor,
        period_start: filter.pay_period ? filter.pay_period.period_start : undefined,
        period_end: filter.pay_period ? filter.pay_period.period_end : undefined,
        user_id: filter.user_id ? filter.user_id : undefined,
        job_number_id: filter.job_number_id ? filter.job_number_id : undefined,
        custom_field_value_ids: notEmptyFilterCustomFieldValues ? notEmptyFilterCustomFieldValues : undefined,
        sort: Object.entries(sort)[0],
    };
    const api = entryType === EntryType.TIME ? timeApi : expenseApi;
    const groupedSheetsResult = yield* call(api.getGroupedSheetsByPurpose, Permission.ApproveSheets, request);
    const normalizedGroupedSheetsResult: ISheetGroupResponsePayload = {
        ...groupedSheetsResult,
        items: [],
    };
    let sheets: ISheet[] = [];

    for (const groupIndex in groupedSheetsResult.items) {
        const group = groupedSheetsResult.items[groupIndex];
        sheets = sheets.concat(group.sheets);
        const normalizedGroup: ISheetGroup<string> = {
            ...group,
            sheets: group.sheets.map(sheet => sheet.id),
        };
        normalizedGroupedSheetsResult.items.push(normalizedGroup);
    }

    if (sheets.length > 0) {
        if (entryType === EntryType.TIME) {
            // @ts-ignore
            if (activeStatus === StatusNames.MISSING) {
                const userIds = [...new Set(normalizedGroupedSheetsResult.items.map(x => x.user_id))];
                yield put(getJobNumbers.init({ user_id: userIds }));
            }
            // @ts-ignore
            yield* put(loadTimeSheetsWithEntries.success(sheets));
        } else {
            // @ts-ignore
            yield* put(loadExpenseSheetsWithEntries.success(sheets));
        }
    }

    yield put(loadMoreApprovalSheets.success(normalizedGroupedSheetsResult));

    if (sheets.length > 0) {
        yield* loadSheetRelatedData(sheets);
    }
}

function* updateTableSaga() {
    const userHasApprovePermission = yield select(selectIsUserHasPermission(Permission.ApproveSheets));
    if (userHasApprovePermission) {
        const entryType = yield select(approvalTableEntityType);
        yield put(initialLoadApprovalSheets(entryType));
    }
}

export function* getMoreApprovalSheetsSagaWatcher() {
    yield* takeLatest(
        [
            initialLoadApprovalSheets.action,
            loadMoreApprovalSheets.initType,
            setManagerSubmittedSheetsFilters.action,
            setSheetApprovalTableSort.action,
        ],
        withBackendErrorHandler(
            getMoreApprovalSheetsSaga,
            loadMoreApprovalSheets.error,
            'Unable to get sheets.',
        ),
    );
    yield* takeLatest(
        [
            updateSheetsStatus.successType,
        ],
        updateTableSaga,
    );
}
