import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const formBasicSpacing = 3;
export const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        margin: theme.spacing(formBasicSpacing, formBasicSpacing, 0),
        minHeight: theme.spacing(5),
    },
    field: {
        margin: theme.spacing(formBasicSpacing, formBasicSpacing, 0),
        minHeight: theme.spacing(5),
        flexBasis: '50%',
    },
    button: {
        '& + &': {
            marginLeft: theme.spacing(3),
        },
    },
    green: {
        '& .Mui-checked svg': {
            color: colors.green,
        },
        '&[class*="MuiButton-root"]:hover': {
            backgroundColor: colors.green,
        },
    },
    darkSection: {
        backgroundColor: colors.secondary,
        color: colors.white,
        padding: theme.spacing(3, 7, 5),
        margin: theme.spacing(5, 0, 0),
    },
    firstInputBlock: {
        '&&': {
            marginRight: theme.spacing(1.5),
        },
    },
    secondInputBlock: {
        '&&': {
            marginLeft: theme.spacing(1.5),
        },
    },
    employeeFieldBlock: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 50%',
    },
}));
