import React from 'react';
import { useField } from 'formik';

export interface IUneditableFormFieldProps {
    name: string;
    disabled?: boolean;
}

function UneditableFormFieldDecorator<T extends IUneditableFormFieldProps>(Component: React.FC<Omit<T, 'name' | 'disabled'> & IUneditableFormFieldProps>) {
    return function DecoratedWithUneditablity({
        name,
        disabled = false,
        ...props
    }: T) {
        const [, meta] = useField(name);
        const { initialValue } = meta;
        return (
            <Component
                {...props}
                name={name}
                disabled={disabled && Boolean(initialValue)}
            />
        );
    };
}

export default UneditableFormFieldDecorator;
