import { usePayrollReportStyle } from 'modules/payrollProcessorHub/components/PayrollModal/reports/PayrollStyle';
import { IReportOverviewItem } from 'modules/payrollProcessorHub/store/model';
import { selectCreationReportV2State } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/selectors';
import { useSelector } from 'react-redux';

export function useBulkPreCreateOverviewItems(): IReportOverviewItem[] {
    const preInitializeReportState = useSelector(selectCreationReportV2State);
    const classes = usePayrollReportStyle();

    const numberOfFailed = preInitializeReportState?.number_of_subassignments_failed;
    const numberOfSubassignments = preInitializeReportState?.number_of_subassignments;

    return [
        {
            label: '# of sub-assignments being created',
            values: [String(numberOfSubassignments - numberOfFailed)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# of sub-assignments that will fail',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}

export function usePreCreateHasFailed(): boolean {
    const preInitializeReportState = useSelector(selectCreationReportV2State);
    const numberOfFailed = preInitializeReportState?.number_of_subassignments_failed;
    return numberOfFailed !== 0;
}

export function useBulkPostCreateOverviewItems(): IReportOverviewItem[] {
    const preInitializeReportState = useSelector(selectCreationReportV2State);
    const classes = usePayrollReportStyle();

    const numberOfFailed = preInitializeReportState?.number_of_subassignments_failed;
    const numberOfSubassignments = preInitializeReportState?.number_of_subassignments;

    return [
        {
            label: '# of sub-assignments created',
            values: [String(numberOfSubassignments - numberOfFailed)],
            className: classes.SummaryInfoValueValid,
        },
        {
            label: '# of sub-assignments that fail',
            values: [String(numberOfFailed)],
            className: classes.SummaryInfoValueError,
        },
    ];
}
