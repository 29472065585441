import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';
import {
    selectSubmittingOrgLocations, selectSubmittingOrgLocationsIsLoading,
} from 'store/entities/configuration/configurationSelectors';
import { ISubmittingOrgLocation } from 'shared/models/SubmittingOrgLocation';

interface ISubmittingOrgLocationSelectProps extends IFormFieldProps, IFormSelect {
    onChanged?: (newValue: ISubmittingOrgLocation) => void;
}

export const SubmittingOrgLocationSelect = (props: ISubmittingOrgLocationSelectProps) => {
    const submittingOrgLocations = useSelector(selectSubmittingOrgLocations);
    const isSubmittingOrgLocationsLoading = useSelector(selectSubmittingOrgLocationsIsLoading);
    return (
        <FormSelect
            getKey={(location: ISubmittingOrgLocation) => location?.id || ''}
            getText={(location: ISubmittingOrgLocation) => location?.value || ''}
            options={submittingOrgLocations}
            useIdValue
            isLoading={isSubmittingOrgLocationsLoading}
            {...props}
        />
    );
};
