import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import React from 'react';
import clsx from 'clsx';
import { useModalStyles } from 'shared/components/modals/ModalStyles';
import { Close } from '@material-ui/icons';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';

export interface IModalProps extends Partial<DialogProps> {
    title: string;
    isOpened: boolean;
    onClose: () => void;
    children: React.ReactNode;
    showCloseIcon?: boolean;
    customClasses?: Partial<Record<'paper' | 'title' | 'closeIcon' | 'content', string>>;
}

export default function Modal({
    title,
    isOpened,
    onClose,
    showCloseIcon = false,
    customClasses,
    children,
    ...props
}: IModalProps) {
    const classes = useModalStyles();

    return (
        <Dialog
            {...props}
            open={isOpened}
            onClose={onClose}
            classes={{ paper: clsx(customClasses?.paper, classes.paper) }}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
        >
            <DialogTitle
                disableTypography
                className={clsx(customClasses?.title, classes.title)}
                id="form-dialog-title"
            >
                <Typography>{title}</Typography>
                {showCloseIcon && (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        className={clsx(customClasses?.closeIcon, classes.closeIcon)}
                    >
                        <Close/>
                    </IconButton>
                )}
            </DialogTitle>

            <DialogContent className={clsx(customClasses?.content, classes.content)}>
                {children}
            </DialogContent>
        </Dialog>
    );
}
