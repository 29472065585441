import {
    usePayrollSheetGroupAssignments,
} from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/usePayrollSheetGroupSubassignments';
import { IPayrollSheetGroupedRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import {
    selectLocationCustomFieldValuesByIds,
    selectPositionCustomFieldValuesByIds,
} from 'modules/payrollProcessorHub/store/selectors';
import { useSelector } from 'react-redux';
import { IEntry } from 'shared/models/sheet/Sheet';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { ICalculation } from 'store/entities/timesheet/models/Calculation';

const usePayrollSheetGroupFieldValues = (row: IPayrollSheetGroupedRow) => {
    const allCalculations = [...row.time_calculations, ...row.expense_calculations] as Array<ICalculation>;
    const fieldValuesIdsSet: Set<string | null> = new Set<string | null>();
    allCalculations.forEach(calculation => {
        // @ts-ignore
        calculation.entries?.map(
            (entry: IEntry) => entry.custom_field_value_ids,
        ).flat().forEach(fieldValuesIdsSet.add, fieldValuesIdsSet);
    });
    return fieldValuesIdsSet;
};

export const usePayrollSheetGroupPositions = (row: IPayrollSheetGroupedRow): ICustomFieldValue[] => {
    const valuesByIds = useSelector(selectCustomFieldValuesByIds);
    const positionCustomFieldValuesByIds = useSelector(selectPositionCustomFieldValuesByIds);

    const fieldValuesIdsSet = usePayrollSheetGroupFieldValues(row);
    const assignments = usePayrollSheetGroupAssignments(row);

    let positionCustomFieldValues = [...fieldValuesIdsSet]
        .map(valueId => positionCustomFieldValuesByIds[valueId || ''])
        .filter(Boolean);
    if (!positionCustomFieldValues.length) {
        positionCustomFieldValues = assignments?.map(assignment => valuesByIds[assignment?.position_value_id])
            .filter(Boolean);
    }
    return positionCustomFieldValues;
};

export const usePayrollSheetGroupLocations = (row: IPayrollSheetGroupedRow): ICustomFieldValue[] => {
    const valuesByIds = useSelector(selectCustomFieldValuesByIds);
    const locationCustomFieldValuesByIds = useSelector(selectLocationCustomFieldValuesByIds);

    const fieldValuesIdsSet = usePayrollSheetGroupFieldValues(row);
    const assignments = usePayrollSheetGroupAssignments(row);

    let locationCustomFieldValues = [...fieldValuesIdsSet]
        .map(valueId => locationCustomFieldValuesByIds[valueId || ''])
        .filter(Boolean);
    if (!locationCustomFieldValues.length) {
        locationCustomFieldValues = assignments?.map(assignment => valuesByIds[assignment?.location_value_id])
            .filter(Boolean);
    }
    return locationCustomFieldValues;
};
