import {
    createCustomField,
    setCreateCustomFieldModalState,
    setEditCustomFieldId,
    updateCustomField,
} from 'modules/settings/submodules/customFields/store/actions';
import { customFieldsSettingsApi } from 'modules/settings/submodules/customFields/store/api';
import {
    deleteCustomField,
    getCustomFields,
    queryCustomFieldValues,
    syncCustomField,
} from 'store/entities/customFields/actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';

function* createCustomFieldSaga({
    payload,
}: ReturnType<typeof createCustomField.init>) {
    const field = yield* call(customFieldsSettingsApi.createCustomField, payload);
    yield put(createCustomField.success(field));
    yield put(getCustomFields.success([field]));
    yield put(setCreateCustomFieldModalState(false));
}

function* createCustomFieldsWatcher() {
    yield* takeLatest(
        createCustomField.initType,
        withBackendErrorHandler(
            createCustomFieldSaga,
            createCustomField.error,
            'Unable to create custom field',
        ),
    );
}

function* updateCustomFieldSaga({
    payload,
}: ReturnType<typeof updateCustomField.init>) {
    const { id, data } = payload;
    const field = yield* call(customFieldsSettingsApi.updateCustomField, id, data);
    yield put(updateCustomField.success(field));
    yield put(getCustomFields.success([field]));
    yield put(setEditCustomFieldId(null));
}

function* updateCustomFieldsWatcher() {
    yield* takeLatest(
        updateCustomField.initType,
        withBackendErrorHandler(
            updateCustomFieldSaga,
            updateCustomField.error,
            'Unable to update custom field',
        ),
    );
}

function* deleteCustomFieldSaga(
    { payload: id }: ReturnType<typeof deleteCustomField.init>,
) {
    yield* call(customFieldsSettingsApi.deleteCustomField, id);
    yield put(deleteCustomField.success(id));
}

function* deleteCustomFieldWatcher() {
    yield* takeLatest(
        deleteCustomField.initType,
        withBackendErrorHandler(
            deleteCustomFieldSaga,
            deleteCustomField.error,
            'Unable to delete Custom Field',
        ),
    );
}

function* syncCustomFieldWithPrismSaga(
    { payload: payload }: ReturnType<typeof syncCustomField.init>,
) {
    yield* call(customFieldsSettingsApi.syncCustomFieldWithPrism, payload);
    yield put(queryCustomFieldValues.init({ custom_field_id: payload.field_id, client_id: payload.client_id }));
    yield put(syncCustomField.success(payload.field_prism_name));
}

function* syncCustomFieldWithPrismWatcher() {
    yield* takeLatest(
        syncCustomField.initType,
        withBackendErrorHandler(
            syncCustomFieldWithPrismSaga,
            syncCustomField.error,
            'Unable to sync Custom Field with Prism',
        ),
    );
}

export default [
    createCustomFieldsWatcher,
    updateCustomFieldsWatcher,
    deleteCustomFieldWatcher,
    syncCustomFieldWithPrismWatcher,
];
