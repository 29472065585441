import { createSelector } from 'reselect';
import { selectAllClientsById } from 'store/entities/clients/selectors/clientsSelectors';
import { selectCustomFieldsList } from 'store/entities/customFields/selectors';

export const selectAllCustomFieldsRows = createSelector(
    selectCustomFieldsList,
    selectAllClientsById,
    (customFields, clientsByIds) => {
        return customFields.map(field => ({
            ...field,
            clients: field.client_ids.map(clientId => clientsByIds[clientId]).filter(client => client),
        }));
    },
);
