import React, { useCallback, useRef } from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { Nullable } from 'types/types';
import IntervalDataField, { IIntervalDataFieldClasses } from '../intervalField/IntervalDataField';
import { InOutTimeFieldPicker } from './InOutTimeFieldPicker';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { printDurationFromStartEndValue, compareInOutTimeValue } from 'shared/models/DateTime';

export interface IInOutTimeFieldLabels {
    startKey: string;
    startLabel: string;
    endKey: string;
    endLabel: string;
    totalLabel?: string;
}

export interface IInOutTimeFieldProps extends IFormFieldProps {
    showTotalInput?: boolean;
    showNote?: boolean;
    classes?: IIntervalDataFieldClasses;
    entryType: QuantityType;
    fieldLabels?: IInOutTimeFieldLabels;
    hideCommonError?: boolean;
    totalRef?: React.Ref<any>;
}

const getFormattedValue = (startValue: Nullable<string>, endValue: Nullable<string>) => (
    startValue && endValue ? printDurationFromStartEndValue(startValue, endValue) : 'h:mm'
);

function InOutTimeField({
    showTotalInput = true,
    showNote = false,
    fieldLabels = {
        startKey: 'timeIn',
        startLabel: 'Time In',
        endKey: 'timeOut',
        endLabel: 'Time Out',
    },
    ...props
}: IInOutTimeFieldProps) {
    const { current: inputs } = useRef([
        {
            name: fieldLabels?.startKey,
            label: fieldLabels?.startLabel,
        },
        {
            name: fieldLabels?.endKey,
            label: fieldLabels?.endLabel,
        },
    ]);

    const convert = useCallback((value: string) => value, []);
    const compare = useCallback(compareInOutTimeValue, []);

    return (
        <IntervalDataField
            {...props}
            calculate={getFormattedValue}
            Input={InOutTimeFieldPicker}
            inputs={inputs}
            showTotalInput={showTotalInput}
            showNote={showNote}
            convert={convert}
            compare={compare}
        />
    );
}

export default InOutTimeField;
