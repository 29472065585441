import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useFilterSelectClasses = makeHighPriorityStyles((theme: Theme) => ({
    select: {
        height: theme.spacing(3),
        fontSize: 14,
        color: colors.darkGray,
        padding: theme.spacing(0, 1),
    },
}));
