/* eslint-disable */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { IExpenseEntryCreate } from 'store/entities/timesheet/models/Entry';
import { addExpenseEntry } from 'store/entities/timesheet/actions/expenseActions';
import {
    ITravelExpenseSidebarFormValues,
    TravelExpenseSidebarForm,
} from 'modules/travelExpenses/components/SidebarForm/TravelExpenseSidebarForm';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { TSubmitBlock } from 'shared/components/sidebars/EntryEdit/models';
import { InputFields } from 'store/entities/clients/clientsModel';
import { getJobNumberAssignmentAndProject } from 'shared/utils/helpers/entries';
import { selectAssignmentProjectIds, selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/selectors/clientsSelectors';
import { selectPayPeriodByEnding } from 'modules/travelExpenses/components/Create/AddNewTravelExpenseSidebar/selectors';

interface IAddNewTravelExpenseSidebarContentProps {
    SubmitBlock: TSubmitBlock;
    onClose: () => void;
    inputs: InputFields;
}

const validationSchema = yup.object().shape({
    employeeId: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    jobNumberId: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    expenseTypeId: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    travelDate: yup.string().trim().required(ValidationMessages.REQUIRED),
    weekEnding: yup.string().trim().required(ValidationMessages.REQUIRED),
    data: yup.object().nullable().required(ValidationMessages.REQUIRED),
});

const formDefaultValues: ITravelExpenseSidebarFormValues = {
    notes: '',
    employeeId: '',
    jobNumberId: '',
    expenseTypeId: '',
    travelDate: '',
    weekEnding: '',
    data: null,
};

export function AddNewTravelExpenseSidebarContent({
    SubmitBlock,
    onClose,
    inputs,
}: IAddNewTravelExpenseSidebarContentProps) {
    const classes = useEntryEditStyles();
    const dispatch = useDispatch();
    const assignmentProjectId = useSelector(selectAssignmentProjectIds);
    const assignmentsById = useSelector(selectAssignmentsById);
    const clientId = useSelector(selectCurrentClientId);
    const configuration = useSelector(selectFieldConfiguration(clientId || ''));
    const expenseFieldsConfigurationInputs = configuration.inputs.expense;
    const defaultTask = expenseFieldsConfigurationInputs.task && expenseFieldsConfigurationInputs.task.default_value;
    const payPeriodsByEnding = useSelector(selectPayPeriodByEnding);

    const onSubmit = useCallback((
        values: ITravelExpenseSidebarFormValues,
        formikActions: FormikHelpers<ITravelExpenseSidebarFormValues>,
    ) => {
        if (values.data) {
            const assignmentWithProject = getJobNumberAssignmentAndProject(
                values.employeeId,
                clientId,
                assignmentsById,
                assignmentProjectId,
                values.travelDate,
            );
            const payPeriod = payPeriodsByEnding[values.weekEnding];
            const entry: IExpenseEntryCreate = {
                assignment_id: assignmentWithProject.assignmentId || '',
                activity_id: values.expenseTypeId,
                department_id: undefined,
                entry_date: values.travelDate,
                location_id: null,
                notes: values.notes,
                position_id: null,
                project_id: assignmentWithProject.projectId,
                task_id: defaultTask,
                data: values.data,
                job_number_id: values.jobNumberId,
                sheet_entry_attachments: [],
                user_id: values.employeeId,
                period_start: payPeriod.period_start,
                // @ts-ignore
                period_end: payPeriod.period_end,
            };
            dispatch(addExpenseEntry.init(entry));
            onClose();
        }
        formikActions.setSubmitting(false);
    }, [
        dispatch,
        onClose,
        clientId,
        assignmentsById,
        assignmentProjectId,
        defaultTask,
        payPeriodsByEnding,
    ]);

    return (
        <Formik
            initialValues={{
                ...formDefaultValues,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {props => (
                <>
                    <TravelExpenseSidebarForm
                        {...props}
                        inputs={inputs}
                    />
                    <footer className={classes.footer}>
                        <SubmitBlock onSubmit={props.submitForm}/>
                    </footer>
                </>
            )}
        </Formik>
    );
}
