import { useRejectNotes } from 'modules/payrollProcessorHub/components/SheetStatusAction/hooks/useRejectNotes';
import React, { useCallback, useEffect } from 'react';
import { uniq } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { IPayrollSheetRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { RejectCard } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectCard';
import { RejectGroupsConfirmationFooter } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectGroupsConfirmationFooter';
import { changeSheetGroupStatus } from 'modules/payrollProcessorHub/store/actions';
import { getSheetGroupId, useGroupSheetRowsByIds } from 'modules/payrollProcessorHub/store/helpers';
import { ISheetGroupId } from 'modules/payrollProcessorHub/store/model';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { getLastFirstName } from 'shared/utils/converters/user';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { getTotalHours } from 'shared/utils/helpers/hoursCalculations';
import { getLocations } from 'store/entities/configuration/configurationAction';
import { StatusNames } from 'store/entities/timesheet/models/Status';

export interface IRejectSheetGroupsProps {
    groupIds: ISheetGroupId[];
    onCancel: () => void;
}

export const RejectSheetGroups = ({ groupIds, onCancel }: IRejectSheetGroupsProps) => {
    const dispatch = useDispatch();
    const sidebarClasses = useSidebarStyles();
    const groupsWithLinked = useGroupSheetRowsByIds(groupIds);
    const { notesById, onNoteChange } = useRejectNotes();
    const locationIdsAsString = uniq(
        groupsWithLinked
            .map(group => group.assignment?.location_id)
            .filter(id => Boolean(id))
            .map(id => `"${id}"`),
    ).join();

    useEffect(() => {
        dispatch(getLocations.init({
            filters: `{"id":{"in":[${locationIdsAsString}]}}`,
            client_id: undefined,
        }));
    }, [dispatch, locationIdsAsString]);

    const onReject = () => {
        const groups = groupsWithLinked.map(group => ({
            groupId: getSheetGroupId(group),
            note: notesById[group.id],
        }));
        dispatch(changeSheetGroupStatus.init({
            status: StatusNames.REJECTED,
            groups,
        }));
        onCancel();
    };

    const toRejectCard = useCallback((group: IPayrollSheetRow) => {
        const employeeFullName = getLastFirstName(group.employee);
        const employeePosition = group?.job_number_user_type_name || group.position?.name || '';
        const employeeLocation = group.location?.name;
        const payPeriod = getPayPeriodByStartEnd(group.pay_period_starts_at, group.pay_period_ends_at);
        const totalHours = getTotalHours([
            group.time_ot_hours,
            group.time_rt_hours,
            group.time_dt_hours,
        ]);
        return (
            <RejectCard
                key={group.id}
                onChange={({ target }) => onNoteChange(group.id, target.value)}
                totalDollars={group.total_dollars}
                employeeFullName={employeeFullName}
                employeePosition={employeePosition}
                employeeLocation={employeeLocation}
                payPeriod={payPeriod}
                totalHours={totalHours}
                inputValue={notesById[group.id]}
            />
        );
    }, [notesById, onNoteChange]);

    return (
        <>
            <form>
                <section className={sidebarClasses.sidebarElement}>
                    {groupsWithLinked.map(toRejectCard)}
                </section>
            </form>
            <RejectGroupsConfirmationFooter
                onConfirmRejection={onReject}
                onCancel={onCancel}
            />
        </>
    );
};
