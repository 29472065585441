import { useClientUserAssociationRows } from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationTable/utils';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useClientUsersAssociationCells } from 'modules/users/managers/components/UserRolesTable/cells';
import { clientUserAssociationSelectors } from 'modules/settings/submodules/platformUsers/store/selectors';
import { getMoreClientUsersAssociation } from 'modules/settings/submodules/platformUsers/store/actions';

export const UserClientAssociationTable = () => {
    const dispatch = useDispatch();
    const tableClasses = useDefaultTableStyles();
    const rows = useClientUserAssociationRows();
    const cells = useClientUsersAssociationCells();
    const isLoading = useSelector(clientUserAssociationSelectors.isLoading);
    const hasMoreRows = useSelector(clientUserAssociationSelectors.selectHasMore);
    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows) {
            dispatch(getMoreClientUsersAssociation.init());
        }
    }, [dispatch, hasMoreRows]);

    return (
        <InfinityGridTable
            rowData={rows}
            getKey={row => row.id}
            cells={cells}
            isLoading={isLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            stickyHeader
            onLoadMore={onLoadMoreRows}
            hasMore={hasMoreRows}
        />
    );
};
