import { configurationApi } from 'store/entities/configuration/configurationApi';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';
import {
    getClientFieldsConfiguration,
    getClientConfigurationTheme,
    setClientId,
    setPayrollProcessing,
    setClientsInfo,
    getPaySettings,
    refreshClients,
    updateClientConfiguration,
} from 'store/entities/clients/clientsAction';
import {
    authByPassword,
    authByToken,
    authTokenUpdate,
} from 'store/components/auth/authActions';
import { clientsApi } from 'store/entities/clients/clientsApi';
import { withErrorHandler } from 'store/utils/sagas/withErrorHandler';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';

function* getConfigurationThemeSaga() {
    const configurationTheme = yield* call(clientsApi.getConfigurationTheme);
    yield put(getClientConfigurationTheme.success(configurationTheme));
}

function* getConfigurationThemeWatcher() {
    yield takeLatest(
        [
            authByPassword.successType,
            authByToken.successType,
            authTokenUpdate.successType,
            getClientConfigurationTheme.initType,
        ],
        withBackendErrorHandler(
            getConfigurationThemeSaga,
            getClientConfigurationTheme.error,
            'Unable to get client theme',
            false,
        ),
    );
}

function* getPaySettingsSaga(action: ReturnType<typeof getPaySettings.init>) {
    const clientId = action.payload;
    const paySettings = yield* call(clientsApi.getPaySettings, clientId);
    yield put(getPaySettings.success(paySettings));
}

function* getPaySettingsWatcher() {
    yield* takeLatest(
        getPaySettings.initType, withBackendErrorHandler(
            getPaySettingsSaga,
            getPaySettings.error,
            'Unable to get pay settings',
        ),
    );
}

function* loadClientConfigurationSaga(action: ReturnType<typeof setClientId>) {
    if (action.payload) {
        const clientId = action.payload;
        const configuration = yield* call(clientsApi.getFieldsConfiguration, clientId);
        yield put(getClientFieldsConfiguration.success(configuration));
    }
}

function* loadClientConfigurationWatcher() {
    yield* takeLatest(
        [
            setClientId.action,
            getClientFieldsConfiguration.initType,
        ],
        withBackendErrorHandler(
            loadClientConfigurationSaga,
            getClientFieldsConfiguration.error,
            'Failed to get client configuration',
        ),
    );
}

function* startPayrollSaga(action: ReturnType<typeof setPayrollProcessing.init>) {
    if (action.payload) {
        try {
            const result = yield* call(clientsApi.startPayroll, action.payload);
            yield put(setPayrollProcessing.success(!!result));
        } catch (e){
            const errorText = e?.response?.data?.error?.message ?? 'Payroll error';
            const errorMessage = `${errorText.slice(0, 1).toLowerCase()}${errorText.slice(1)}`;
            yield put(setGlobalToast({
                severity: IModalSeverity.Error,
                title: `${errorMessage}`,
                autoHideDuration: autoHideDefaultDuration * 2,
            }));
            logErrorWithCustomMessage(e, errorMessage);
            yield put(setPayrollProcessing.error(e));
        }
    }
}

function* setPayrollWatcher(){
    yield* takeLatest(
        setPayrollProcessing.initType, withErrorHandler(startPayrollSaga, setPayrollProcessing.error),
    );
}

function* getClientsInformationSaga() {
    const clients = yield* call(configurationApi.getClientsInformation);
    yield put(setClientsInfo(clients));
}

function* getClientsInformationWatcher() {
    yield* takeLatest(
        [
            authByToken.successType,
            authByPassword.successType,
            authTokenUpdate.successType,
            refreshClients.action,
        ],
        withBackendErrorHandler(
            getClientsInformationSaga,
            getClientFieldsConfiguration.error,
            'Unable to fetch clients',
        ),
    );
}

function* updateClientConfigurationSaga({ payload }: ReturnType<typeof updateClientConfiguration.init>) {
    const { clientId, ...data } = payload;
    const result = yield* call(clientsApi.updateClientConfiguration, clientId, data);
    yield put(updateClientConfiguration.success(result));
}

function* updateClientConfigurationSagaWatcher() {
    yield* takeLatest(
        updateClientConfiguration.initType,
        withBackendErrorHandler(
            updateClientConfigurationSaga,
            updateClientConfiguration.error,
            'Failed to update client configuration',
        ),
    );
}

export default [
    loadClientConfigurationWatcher,
    getConfigurationThemeWatcher,
    getClientsInformationWatcher,
    setPayrollWatcher,
    getPaySettingsWatcher,
    updateClientConfigurationSagaWatcher,
];
