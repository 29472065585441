import { useField } from 'formik';
import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import ScaZoneCommon from './ScaZoneCommon';

interface IScaZoneProps extends IFormFieldProps {
    assignmentId?: string;
    parentScaZoneId?: number;
}

export default function ScaZone({
    label = '',
    assignmentId,
    parentScaZoneId,
    className = '',
    name,
}: IScaZoneProps) {
    const [, meta, helper] = useField(name);

    const error = meta.touched ? meta.error : undefined;

    return (
        <ScaZoneCommon
            customClasses={className}
            label={label}
            assignmentId={assignmentId}
            parentScaZoneId={parentScaZoneId}
            onChange={helper.setValue}
            error={error}
        />
    );
}
