import { useSelector } from 'react-redux';
import { ValidationMessages } from 'shared/models/Validation';
import { HierarchyNodeOperation } from 'store/entities/customFields/model';
import { selectCustomFieldsByIds, selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';
import { isCustomFieldOptional } from 'store/entities/customFields/utils';
import * as yup from 'yup';

export const useSubassignmentCustomFieldsValidationSchema = (actionable: boolean) => {
    const hierarchy = useSelector(selectOrderedCustomFieldAssignmentNodes);
    const customFieldsByIds = useSelector(selectCustomFieldsByIds);

    return yup.object().shape<Record<string, string>>(
        hierarchy.reduce((mem, node) => {
            const customFieldId = node.custom_field_id;
            const customField = customFieldsByIds[customFieldId];

            if ((actionable && node.operation !== HierarchyNodeOperation.Actionable)
                || (!actionable && node.operation === HierarchyNodeOperation.Actionable)) {
                return mem;
            }

            if (isCustomFieldOptional(customField)) {
                // SCA and department is optional for subassignment
                return {
                    ...mem,
                    [customFieldId]: actionable ? yup.array(yup.string()).nullable() : yup.string().nullable(),
                };
            }
            return {
                ...mem,
                [customFieldId]: actionable ? yup.array(yup.string()).nullable().required(ValidationMessages.REQUIRED)
                    : yup.string().nullable().required(ValidationMessages.REQUIRED),
            };
        }, {}),
    );
};
