import { selectSettingClientsState } from 'modules/settings/submodules/clients/store/selectors';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { CustomFieldType, HierarchyType, ICustomField, ICustomFieldHierarchyNode } from 'store/entities/customFields/model';
import {
    selectCustomFieldsByIds,
    selectCustomFieldsHierarchyNodesList,
    selectCustomFieldsState,
    selectIsLoadingCustomFieldMapping,
    selectIsLoadingCustomFields,
    selectOfferLetterHierarchy,
    selectOrderedCustomFieldAssignmentNodes,
} from 'store/entities/customFields/selectors';

export const selectIsLoadingClientHierarchy = (store: IStore) =>
    selectCustomFieldValuesState(store).isLoadingHierarchy;
export const selectClientAvailableCustomFields = createSelector(
    selectCurrentClientId,
    selectCustomFieldsHierarchyNodesList,
    selectCustomFieldsByIds,
    (clientId, hierarchy, customFieldsByIds): ICustomField[] => {
        return [...new Set(
            hierarchy
                .map(node => (node.hierarchy_type === HierarchyType.OfferLetter || node.client_id === clientId)
                    && customFieldsByIds[node.custom_field_id]).filter(Boolean) as ICustomField[],
        )];
    },
);

/**
 * Custom field values page
 */
export const selectCustomFieldValuesState = (store: IStore) =>
    selectSettingClientsState(store).customFieldValues;
export const selectCustomFieldId = (store: IStore) => selectCustomFieldValuesState(store).customFieldId;
export const selectIsLoadingCustomFieldValues = createSelector(
    selectCustomFieldId,
    selectIsLoadingCustomFieldMapping,
    selectIsLoadingClientHierarchy,
    (id, isLoadingRelations, isLoadingMappings) => !id || isLoadingMappings || isLoadingRelations,
);
export const selectIsLoadingClientCustomFields = createSelector(
    selectIsLoadingCustomFields,
    selectIsLoadingClientHierarchy,
    (isLoadingFields, isLoadingHierarchy) => isLoadingFields || isLoadingHierarchy,
);
export const selectIsLoadingExportCustomFieldValues = (store: IStore) =>
    selectCustomFieldValuesState(store).isExportingCustomFieldValues;

export const selectCustomField = createSelector(
    selectCustomFieldId,
    selectCustomFieldsByIds,
    (id, fieldsByIds) => fieldsByIds[id],
);
export const selectCustomFieldType = createSelector(
    selectCustomField,
    // @ts-ignore
    (field): CustomFieldType => field?.headway_connect_field?.key,
);
export const selectExplicitSyncCustomFieldType = createSelector(
    selectCustomFieldType,
    (type): boolean => type === CustomFieldType.Position,
);
export const selectCustomFieldPrismField = createSelector(
    selectCustomField,
    // @ts-ignore
    (field): string => field?.prism_field?.key,
);
export const selectIsReadonlyCustomFieldType = createSelector(
    selectCustomFieldType,
    (type): boolean => type === CustomFieldType.Position,
);
export const selectProjectClasses = createSelector(
    selectCustomFieldValuesState,
    state => state.projectClasses,
);

export const selectProjectClassesLoading = createSelector(
    selectCustomFieldValuesState,
    state => state.projectClassesLoading,
);

export interface ICustomFieldRelations {
    parentFieldId?: string;
    siblingFieldIds: string[];
    childrenFieldIds: string[];
}

const getFieldHierarchy = (
    selectedCustomFieldId: string,
    nodes: ICustomFieldHierarchyNode[],
): ICustomFieldRelations | null => {
    const currentNode = nodes.find(node => node.custom_field_id === selectedCustomFieldId);
    const parentNode = nodes.find(node => node.id === currentNode?.parent_id);
    const siblings = nodes.filter(node => node.parent_id === currentNode?.parent_id && node.id !== currentNode?.id);
    const children = nodes.filter(node => node.parent_id === currentNode?.id);
    if (!parentNode && !siblings.length) {
        return null;
    }
    return {
        parentFieldId: parentNode?.custom_field_id,
        siblingFieldIds: siblings.map(node => node.custom_field_id),
        childrenFieldIds: children.map(node => node.custom_field_id),
    };
};

export const selectClientHasCompatibleHierarchy = (state: IStore) =>
    selectCustomFieldsState(state).isClientHierarchyCompatibleWithOfferLetter;

export enum FieldValueRelationType {
    Assignment = 'assignment',
    OfferLetter = 'offerLetter',
}

export interface IRelationsByHierarchyType extends Record<FieldValueRelationType, ICustomFieldRelations | null> {}

/*
 * select custom field selectors
 */
export const selectCustomFieldRelations = createSelector(
    selectCustomFieldId,
    selectOrderedCustomFieldAssignmentNodes,
    selectOfferLetterHierarchy,
    selectClientHasCompatibleHierarchy,
    (customFieldId, assignmentNodes, offerLetterNodes, compatible): IRelationsByHierarchyType => {
        return {
            assignment: getFieldHierarchy(customFieldId, assignmentNodes),
            offerLetter: compatible ? null : getFieldHierarchy(customFieldId, offerLetterNodes),
        };
    },
);
