import { Typography } from '@material-ui/core';
import {
    DeleteButtonWithConfirmation,
} from 'modules/settings/submodules/components/Modals/DeleteButtonWithConfirmation';
import { deleteEmployee } from 'modules/settings/submodules/employees/store/actions';
import { selectIsEmployeeDeleting } from 'modules/settings/submodules/employees/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserName } from 'shared/utils/converters/user';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { selectUserById } from 'store/entities/users/selectors';

interface IDeleteEmployeeButtonProps {
    employeeId: string;
    className: string;
}

export const DeleteEmployeeButton = ({ employeeId, className }: IDeleteEmployeeButtonProps) => {
    const user = useSelector(selectUserById(employeeId));
    const userHasPermission = useSelector(selectIsUserHasPermission(Permission.DeleteEmployeeUsers));

    if (!userHasPermission) {
        return null;
    }

    return (
        <DeleteButtonWithConfirmation
            buttonText="Delete Employee"
            modalTitle="Delete Employee"
            modalButtonTitle="I understand the consequences, delete this employee"
            isLoadingSelector={selectIsEmployeeDeleting}
            action={deleteEmployee.init}
            actionPayload={employeeId}
            className={className}
        >
            <Typography variant="subtitle2" gutterBottom>
                Are you absolutely sure you want to delete {getUserName(user)} from Headway Connect?
            </Typography>
            <Typography gutterBottom>
                This action <b>cannot</b> be undone.
                This will permanently delete this employee and they will not be able to onboard again.
                Please type delete below to confirm.
            </Typography>
        </DeleteButtonWithConfirmation>
    );
};
