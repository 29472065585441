import clsx from 'clsx';
import React from 'react';
import ButtonWithLoader, { IButtonWithLoaderProps } from 'shared/components/buttons/ButtonWithLoader';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useButtonStyles = makeHighPriorityStyles({
    success: {
        '&:hover': {
            background: colors.green,
            borderColor: colors.green,
            color: colors.white,
        },
    },
    danger: {
        '&:hover': {
            background: colors.red,
            borderColor: colors.red,
            color: colors.white,
        },
    },
});

interface IActionButtonProps extends IButtonWithLoaderProps {
    customType: 'success' | 'danger';
}

export default function ActionButton({
    customType,
    children,
    className,
    ...props
}: IActionButtonProps) {
    const classes = useButtonStyles();

    return (
        <ButtonWithLoader
            {...props}
            className={clsx(className, classes[customType])}
        >
            {children}
        </ButtonWithLoader>
    );
}
