import { useIsUserHasSheetApprovePermission } from 'modules/sheetApproval/store/hooks';
import React, { FC, useCallback } from 'react';
import { Button, Theme, useMediaQuery } from '@material-ui/core';
import useActionButtonStyles from '../FilterAndActionControlsStyles';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { useModal } from 'shared/utils/hooks/useModal';
import SheetReject from 'shared/components/sidebars/SheetReject/SheetReject';
import clsx from 'clsx';
import { isMobileBreakpoint } from 'shared/styles/constants';
import { IUserInfo } from 'shared/models/User';

interface IRejectButtonProps {
    sheetType: EntryType;
    customClasses?: string;
    sheets: Array<ISheet>;
    users: IUserInfo[];
    onAction?: () => void;
}

const RejectButton: FC<IRejectButtonProps> = ({ sheets, users, sheetType, onAction }: IRejectButtonProps) => {
    const classes = useActionButtonStyles();
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMobileBreakpoint), { noSsr: true });

    const onRejectClick = useCallback(() => {
        onModalOpen();
    }, [onModalOpen]);

    const hasPermission = useIsUserHasSheetApprovePermission();
    if (!hasPermission) {
        return null;
    }

    return (
        <>
            <Button
                variant="contained"
                size="small"
                classes={{ root: clsx(classes.actionButton, classes.secondaryButton), label: classes.buttonTextStyle }}
                onClick={onRejectClick.bind(null, sheets)}>
            Reject
            </Button>

            <SheetReject sheets={sheets} users={users}
                anchor={isMobile ? 'bottom' : 'right'}
                isOpened={isModalOpened} sheetType={sheetType}
                onClose={onModalClose}
                onAction={onAction}
            />
        </>
    );
};

export default RejectButton;
