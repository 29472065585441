import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

export const useIsUserHasSheetApprovePermission = (): boolean => {
    const approve = useSelector(selectIsUserHasPermission(Permission.ApproveSheets));
    const approveAll = useSelector(selectIsUserHasPermission(Permission.ApproveAllSheets));
    return approve || approveAll;
};

export const useIsUserHasSheetEditApprovePermission = (): boolean => {
    const hasApproval = useIsUserHasSheetApprovePermission();
    const hasEditPermission = useSelector(selectIsUserHasPermission(Permission.EditSheetAsApprover));
    return hasApproval && hasEditPermission;
};
