import React, { FC, useCallback } from 'react';
import { IQuickEditEntryComponentProps } from 'shared/components/table/EntriesTable/cellsComponents/quickEdit/withQuickEditEntry';
import { FormikErrors } from 'formik/dist/types';
import { FormikValues } from 'formik';
import { IEntryData } from 'shared/models/sheet/Sheet';

export interface ITimeInOutQuickEditFormProps<Entry extends IEntryData>{
    EditComponent: FC<IQuickEditEntryComponentProps>,
    validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>,
    values: FormikValues,
    value: Entry,
    submitForm: () => Promise<void>,
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,
    getInitialValues: (value: Entry) => FormikValues,
    disabled: boolean | undefined,
}

export function TimeInOutQuickEditForm<Entry extends IEntryData>({
    EditComponent,
    handleSubmit,
    submitForm,
    validateForm,
    values,
    value,
    getInitialValues,
    disabled,
}: ITimeInOutQuickEditFormProps<Entry>){

    const validateAndSubmitForm = useCallback(() => {
        // without that the default formik validation uses the previous values:
        // This is the recommended technic since the programmatic submitting formik form is buggy.
        // It is discussed here https://github.com/formium/formik/issues/1580.
        // todo: remove extra validation when the formik issue is resolved.
        validateForm(values).then(() => {
            return submitForm();
        });
    }, [validateForm, values, submitForm]);

    return (
        <form onSubmit={ handleSubmit }>
            <EditComponent
                value={ getInitialValues(value) }
                disabled={ disabled }
                submitForm={validateAndSubmitForm}
                name="data"
            />
        </form>
    );
}
