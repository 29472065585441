import { ItemsById } from 'shared/models/ItemsById';
import { IEntry } from 'shared/models/sheet/Sheet';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { uniq, groupBy } from 'lodash-es';

export const getCustomFieldValuesByCustomFieldId = (
    sheetIds: string[],
    entries: IEntry[],
    customFieldValueByIds: ItemsById<ICustomFieldValue>,
): Record<string, ICustomFieldValue[]> => {
    const customFieldValues = uniq(entries.filter(entry => sheetIds.includes(entry.sheet_id))
        .map(entry => entry.custom_field_value_ids).flat())
        .map(customFieldValueId => customFieldValueByIds[customFieldValueId]).filter(Boolean);
    return groupBy(customFieldValues, 'custom_field_id');
};
