import { allCcpTab, ccpTabs, invoicedCcpTab } from '../models/ccpModel';
import { useMemo } from 'react';

export function useCcpTabs(hasReconciledPermission: boolean,
    hasUnreconciledPermission: boolean,
    hasCanWorkWithCcpInvoicesPermission: boolean) {
    return useMemo( () => {
        const result = hasCanWorkWithCcpInvoicesPermission ? [...ccpTabs, invoicedCcpTab] : [...ccpTabs];
        if (hasReconciledPermission && hasUnreconciledPermission) {
            return [...result, allCcpTab];
        } else if (hasReconciledPermission) {
            return [ccpTabs[1]];
        } else if (hasUnreconciledPermission) {
            return [ccpTabs[0]];
        }
    }, [hasReconciledPermission, hasUnreconciledPermission, hasCanWorkWithCcpInvoicesPermission]);
}
