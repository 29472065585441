import { pick, sumBy, uniq } from 'lodash-es';
import { getCustomFieldValuesByCustomFieldId } from 'modules/timeAndExpense/store/helpers';
import moment from 'moment/moment';
import { backendDateFormat } from 'shared/models/Dates';
import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';
import { IEntity } from 'shared/models/Entity';
import { ItemsById } from 'shared/models/ItemsById';
import { IJobNumber } from 'shared/models/JobNumber';
import {
    EntryType,
    IEntry,
    INoteBackend,
    ISheet,
    ISheetApproval,
    IStatus,
} from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { formatFiles } from 'shared/utils/formatters/timePaymentFormatter';
import { ISheetGroupKey } from 'store/entities/clients/clientsModel';
import { ICustomFieldValue } from 'store/entities/customFields/model';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

export interface ISheetGroupRelatedEntities {
    jobNumbersById: ItemsById<IJobNumber>;
    usersById: ItemsById<IUserInfo>;
    calculationsByTimesheetId: Record<string, ITimesheetCalculation>;
    approversBySheetId: Record<string, string[]>,
    customFieldValuesByIds: ItemsById<ICustomFieldValue>;
    entries: IEntry[];
}

export class SheetGroup implements IEntity {
    public id: string;
    public groupKey: ISheetGroupKey;
    public sheets: ISheet[];
    public employee: IUserInfo;
    public approvers: IUserInfo[];
    public jobNumbers: IJobNumber[];
    public calculations: ITimesheetCalculation[];
    public customFieldValues: Record<string, ICustomFieldValue[]>;

    static getSheetGroupingKey(
        groupingKeys: Array<keyof ISheetGroupKey>,
        enhancer: ((sheet: ISheet) => object) | null,
        sheet: ISheet,
    ): string {
        return JSON.stringify({
            ...pick(sheet, 'user_id'),
            ...pick(sheet, groupingKeys),
            ...(enhancer ? enhancer(sheet) : {}),
        });
    }

    constructor(
        groupKey: string,
        sheets: ISheet[],
        related: ISheetGroupRelatedEntities,
    ) {
        this.id = groupKey;
        this.groupKey = JSON.parse(groupKey);
        this.sheets = sheets;

        this.employee = related.usersById[sheets[0]?.user_id];
        const jobNumberIds = uniq(sheets.map(sheet => sheet.job_number_id).filter(Boolean));
        // @ts-ignore
        this.jobNumbers = jobNumberIds.map(jobNumberId => related.jobNumbersById[jobNumberId])
            .filter(Boolean) as IJobNumber[];
        this.calculations = sheets.map(sheet => related.calculationsByTimesheetId[sheet.id]).filter(Boolean);
        this.approvers = related.approversBySheetId[sheets[0]?.id]?.map(
            userId => related.usersById[userId],
        ).filter(Boolean);
        this.customFieldValues = getCustomFieldValuesByCustomFieldId(
            sheets.map(sheet => sheet.id),
            related.entries,
            related.customFieldValuesByIds,
        );
    }

    public get payPeriod(): IPayPeriod {
        return this.groupKey;
    }

    public get status(): IStatus {
        return this.sheets[0]?.status;
    }

    public get type(): EntryType {
        return this.sheets[0]?.entry_type;
    }

    public get regularHours(): string {
        return formatDecimalHoursStringAsHoursAndMinutes(this.countTotalByString(this.calculations, 'rt_hours'));
    }

    public get overtimeHours(): string {
        return formatDecimalHoursStringAsHoursAndMinutes(this.countTotalByString(this.calculations, 'ot_hours'));
    }

    public get doubletimeHours(): string {
        return formatDecimalHoursStringAsHoursAndMinutes(this.countTotalByString(this.calculations, 'dt_hours'));
    }

    public get holidayHours(): string {
        return formatDecimalHoursStringAsHoursAndMinutes(this.countTotalByString(this.calculations, 'holiday_hours'));
    }

    public get ptoHours(): string {
        return formatDecimalHoursStringAsHoursAndMinutes(this.countTotalByString(this.calculations, 'pto_hours'));
    }

    public get hasReceipt(): boolean {
        // @ts-ignore
        return this.sheets.some(sheet => sheet.has_receipts);
    }

    public get totalFiles(): string {
        const files = sumBy(this.sheets, 'total_files');
        return files ? formatFiles(sumBy(this.sheets, 'total_files')) : '';
    }

    public get totalMinutes(): string {
        return formatMinutes(sumBy(this.sheets, 'total_minutes'));
    }

    public get totalDollars(): string {
        return formatDollars(sumBy(this.sheets, 'total_dollars'));
    }

    public getCustomFieldValuesAsStringById(id: string): string {
        return this.customFieldValues[id]?.map(customFieldValue => customFieldValue?.data.name).join(', ');
    }

    private countTotalByString(collection: ITimesheetCalculation[], property: keyof ITimesheetCalculation): string {
        return collection
            .map(item => parseFloat(item[property] as string))
            .filter(Boolean)
            .reduce((sum, item) => sum + item, 0)
            .toString();
    }

    public get approvals(): ISheetApproval[] {
        return this.sheets.map(sheet => sheet.approvals || []).flat();
    }

    public get submittedAt(): string | undefined | null {
        return this.sheets.find(sheet => sheet.submitted_at)?.submitted_at;
    }

    public get dueDate(): string {
        return moment(this.payPeriod?.period_end)
            .clone()
            .add(1, 'day')
            .format(backendDateFormat);
    }

    public get notes(): INoteBackend[] {
        return this.sheets.map(sheet => sheet.notes).flat();
    }

    public get jobNumbersString(): string {
        return this.jobNumbers
            .map(jobNumber => jobNumber?.job_number)
            .filter(Boolean)
            .join(', ');
    }
}

export interface ISheetGroupRow {
    id: string;
    group: SheetGroup;
    className?: string;
}
