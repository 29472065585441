import React from 'react';
import { Button } from '@material-ui/core';
import CreateControllingOrgModal
    from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/CreateControllingOrgModal';

import { selectIsControllingOrgModalOpen } from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/store/selectors';
import { setCreateControllingOrgModalState } from 'modules/settings/submodules/clients/controllingOrg/components/CreateControllingOrg/store/actions';
import { useReduxModal } from 'shared/utils/hooks/useModal';
import { useColoredFormModalStyles } from 'shared/components/modals/ModalStyles';

export const CreateControllingOrgButton = () => {
    const classes = useColoredFormModalStyles();

    const { isModalOpened,
        openModal,
        closeModal } = useReduxModal(selectIsControllingOrgModalOpen, setCreateControllingOrgModalState);

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + ADD NEW
            </Button>
            <CreateControllingOrgModal
                title="Create New Controlling Org"
                isOpened={isModalOpened}
                onClose={closeModal}
                customClasses={classes}
                showCloseIcon
            />
        </>
    );
};
