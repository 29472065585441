import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useSubAssignmentTableStyles = makeHighPriorityStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            height: '40px',
        },
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    subassignmentHeaderChekboxCell: {
        paddingLeft: '8px !important',
    },
    subassignmentCheckboxCell: {
        paddingLeft: '8px !important',
    },
    subassignmentCheckboxCellLegacy: {
        paddingLeft: '24px !important',
    },
}));
