import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IPayrollSheetGroupedRow } from '../../../model';
import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { usePayrollSheetGroupEditedBy } from '../../../hooks/useSheetGroupEditors';
import { getUserName } from 'shared/utils/converters/user';

export const GroupedEditedByCell = ({ className, ...row }: ICellProps<IPayrollSheetGroupedRow>) => {
    const editedBy = usePayrollSheetGroupEditedBy(row);
    const editorName = getUserName(editedBy);
    return (
        <PlainText
            className={className}
            value={editorName}
        />
    );
};
