import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

const FilterSVG: FC = () => (
    <SvgIcon fontSize="small">
        <path
            d="M18.65,4.67H5.34C5.06,4.65,4.82,4.82,4.72,5.08C4.6,5.32,4.66,5.63,4.88,5.81l5.12,5.13V16
                c0,0.18,0.07,0.35,0.2,0.47l2.67,2.67c0.12,0.13,0.29,0.2,0.47,0.2c0.09,0,0.18-0.02,0.26-0.05c0.26-0.1,0.42-0.35,0.4-0.63v-7.72
                l5.13-5.13c0.21-0.18,0.28-0.48,0.15-0.73C19.18,4.82,18.93,4.65,18.65,4.67z"
        />
    </SvgIcon>
);

export default FilterSVG;
