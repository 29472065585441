import React, { useEffect, useRef } from 'react';
import {
    EntrySlug,
    FilterSlug,
    IFiltersConfiguration,
    InputFields,
} from '../../../store/entities/clients/clientsModel';

export const useEffectWithSkipInitialChange = (effect: () => void, dependency: any[]) => {
    const initalChange = useRef(true);
    useEffect(
        () => {
            if (!initalChange.current) {
                effect();
            }
            initalChange.current = false;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependency,
    );
};

export interface IWithInputFields {
    inputs: InputFields;
}

export function showField(inputs: InputFields, name: EntrySlug) {
    return inputs[name] && inputs[name].visible;
}

export function showFilter(filters: Record<FilterSlug, IFiltersConfiguration>, name: FilterSlug) {
    return filters && !!filters[name];
}

export function keyHandlerToPreventSubmitOnEnter(keyEvent: React.KeyboardEvent<HTMLElement>) {
    const enterCode = 13;
    if ((keyEvent.charCode || keyEvent.keyCode) === enterCode) {
        keyEvent.preventDefault();
    }
}
