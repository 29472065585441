import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPayRangeLoading, selectPayRanges } from 'store/entities/configuration/configurationSelectors';
import { getPayRangeByValue } from 'store/entities/configuration/configurationAction';

export function usePayRanges(
    positionId: string | null,
    locationId: string | null,
) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectPayRangeLoading);
    const payRanges = useSelector(selectPayRanges);
    const [lastRequestSyntheticToken, setLastRequestSyntheticToken] = useState<string | null>(null);

    const payRange = useMemo(() => {
        const found = (
            payRanges
                .filter(item => item.location_value_id === locationId)
                .find(item => item.position_value_id === positionId)
        );
        return found || null;
    }, [locationId, payRanges, positionId]);

    useEffect(() => {
        const requestSyntheticToken = `${locationId}_${positionId}`;
        const shouldRequest = requestSyntheticToken !== lastRequestSyntheticToken;

        if (positionId && locationId && !isLoading && shouldRequest && !payRange) {
            setLastRequestSyntheticToken(requestSyntheticToken);
            dispatch(getPayRangeByValue.init({
                location_value_id: locationId,
                position_value_id: positionId,
            }));
        }
    }, [positionId, locationId, lastRequestSyntheticToken, isLoading, dispatch, payRange]);

    return {
        payRange,
        isLoading,
    };
}
