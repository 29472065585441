import {
    all,
    call,
    put,
    SagaGenerator,
    spawn,
} from 'typed-redux-saga';
import componentsSagas from 'store/components/sagas';
import sheetsSagas from './entities/timesheet/sagas';
import * as scaZoneSagas from './entities/scaZone/sagas';
import * as zipCodeSagas from './entities/zipCode/sagas';
import usersSagas from './entities/users/sagas';
import usersModuleSagas from 'modules/users/store/sagas';
import offerLetterSagas from 'modules/offerLetter/store/sagas';
import employmentSagas from 'modules/employmentInfo/store/sagas';
import profileSagas from 'modules/profile/store/profileSaga';
import subassignmentManagement from 'modules/subassignmentManagement/store/sagas/sagas';
import employeeActionForm from 'modules/employeeActionsForm/store/sagas';
import eafApprovalSagas from 'modules/eafApproval/store/sagas';
import { ccpSagas } from 'modules/ccp/store/sagas';
import configurationSagas from './entities/configuration/configurationSagas';
import clientsSagas from './entities/clients/clientsSagas';
import customFieldsSagas from './entities/customFields/sagas';
import scopesSagas from './entities/scopes/sagas';
import settingsSagas from 'modules/settings/store/sagas';
import notificationsSagas from 'modules/notificationCenter/store/sagas';
import homeSagas from 'modules/home/store/sagas';
import payrollProcessorHubSagas from 'modules/payrollProcessorHub/store/sagas';
import { sheetApprovalSagas } from 'modules/sheetApproval/store/sagas';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { logger } from 'shared/utils/logging/logger';
import { getRequestDetails } from 'shared/utils/logging/requestModel';

type RunnableSaga = () => Generator<SagaGenerator<any>, void>;

export function* rootSaga() {
    const sagas: Array<RunnableSaga> = [
        ...componentsSagas,
        ...sheetsSagas,
        ...configurationSagas,
        ...clientsSagas,
        ...offerLetterSagas,
        ...employmentSagas,
        ...settingsSagas,
        ...usersSagas,
        ...usersModuleSagas,
        ...notificationsSagas,
        ...payrollProcessorHubSagas,
        ...homeSagas,
        ...profileSagas,
        ...customFieldsSagas,
        ...scopesSagas,
        ...subassignmentManagement,
        ...employeeActionForm,
        ...eafApprovalSagas,
        ...sheetApprovalSagas,
        ...ccpSagas,
        ...Object.values(scaZoneSagas),
        ...Object.values(zipCodeSagas),
    ] as any;

    yield all(sagas.map(saga => spawn(function* () {
        while (true) {
            try {
                yield call(saga);
                break;
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.error(e);
                }
                if (['config', 'request', 'response'].every(key => Object.keys(e).includes(key))) {
                    yield* put(setGlobalToast({
                        severity: IModalSeverity.Error,
                        title: 'Unhandled error happened during a request',
                    }));

                    e.message = `Unhandled network error in root saga: ${e.message}`;
                    logger.error(e, getRequestDetails(e));
                } else {
                    const originalErrorMessage = e.message;
                    e.message = `Unhandled error in root saga: ${originalErrorMessage}`;
                    logger.error(e, { originalErrorMessage });
                }
            }
        }
    })));
}
