import { Box } from '@material-ui/core';
import { LabelWithValue } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/components/LabelWithValue';
import { selectContractClientDisplayValue } from 'modules/settings/submodules/clients/jobNumber/store/selectors';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { DealAutocompleteWithSearch } from 'shared/components/autocomplete/deals/DealAutocompleteWithSearch';
import { IDeal } from 'shared/models/Deal';
import { Status } from 'shared/models/Status';
import { selectDealTypeById, selectSubmittingOrgById } from 'store/entities/configuration/configurationSelectors';

interface IDealFormSectionProps {
    isEdit?: boolean;
    jobNumber?: number;
    dealReadonly?: boolean;
    deal?: IDeal | null;
}

export const DealFormSection = ({
    deal,
    jobNumber,
    isEdit = false,
    dealReadonly = false,
}: IDealFormSectionProps) => {
    const classes = useSettingsFormStyles();
    const submittingOrgs = useSelector(selectSubmittingOrgById(deal?.submitting_org_id));
    const contractClient = useSelector(selectContractClientDisplayValue(deal));
    const dealType = useSelector(selectDealTypeById(deal?.type_id));
    return (
        <Box
            display="flex"
            flexDirection="row"
        >
            <Box width="50%">
                <Box ml={2}>
                    {dealReadonly && (<LabelWithValue label="Deal Number" value={deal?.deal_number?.toString()}/>)}
                    {isEdit && jobNumber && <LabelWithValue label="Job Number" value={jobNumber.toString()}/>}
                    <LabelWithValue label="Contract Client" value={contractClient}/>
                </Box>
                {!dealReadonly && (
                    <DealAutocompleteWithSearch
                        name="deal"
                        outerLabel="Deal Number"
                        className={classes.input}
                        placeholder="Type to search..."
                        additionalFilter={{
                            status: Status.active,
                        }}
                        initialSearch={{
                            range: '[0,10]',
                            status: Status.active,
                        }}
                    />
                )}
            </Box>
            <Box width="50%">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                    ml={2}
                >
                    <LabelWithValue label="Deal Type" value={dealType?.name}/>
                    <LabelWithValue label="Jobsite" value={submittingOrgs?.client_site_name}/>
                </Box>
            </Box>
        </Box>
    );
};
