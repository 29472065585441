import { createActions, createSingleAction } from 'store/utils';
import { IUpdateControllingOrgPayload } from 'shared/models/ControllingOrg';

export const SET_EDIT_CONTROLLING_ORG_ID = 'SET_EDIT_CONTROLLING_ORG_ID';
export const setEditControllingOrgId = createSingleAction<
string | null, typeof SET_EDIT_CONTROLLING_ORG_ID>(SET_EDIT_CONTROLLING_ORG_ID);

export const UPDATE_CONTROLLING_ORG = 'UPDATE_CONTROLLING_ORG';
export const UPDATE_CONTROLLING_ORG_SUCCESS = 'UPDATE_CONTROLLING_ORG_SUCCESS';
export const UPDATE_CONTROLLING_ORG_ERROR = 'UPDATE_CONTROLLING_ORG_ERROR';
export const updateControllingOrg = createActions<
IUpdateControllingOrgPayload,
any,
any,
    typeof UPDATE_CONTROLLING_ORG,
    typeof UPDATE_CONTROLLING_ORG_SUCCESS,
    typeof UPDATE_CONTROLLING_ORG_ERROR
>(
    UPDATE_CONTROLLING_ORG,
    UPDATE_CONTROLLING_ORG_SUCCESS,
    UPDATE_CONTROLLING_ORG_ERROR,
);
