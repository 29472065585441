import React from 'react';
import {
    CommonPrePostInitializeReportPure,
} from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/CommonPrePostInitializeReport';
import { useSelector } from 'react-redux';
import {
    selectDeleteReportTableRows,
} from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { useDeletePrePostInitializeReportTableCells } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/store/useDeletePrePostInitializeReportTableCells';
import { deletePostInitializeReportOverviewItemsSelector } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/store/DeletePostInitializeReportOverviewItemsSelector';

export interface IDeletePostinitializeReportProps{
    mainButtonHandler: () => void,
}

export function DeletePostInitializeReport({ mainButtonHandler }: IDeletePostinitializeReportProps){
    const cells = useDeletePrePostInitializeReportTableCells();
    const rows = useSelector(selectDeleteReportTableRows);
    const overviewItems = useSelector(deletePostInitializeReportOverviewItemsSelector);

    return (
        <CommonPrePostInitializeReportPure title="Post-Initialize Delete"
            buttonTitle={'OK'}
            buttonHandler={mainButtonHandler}
            isButtonLoading={false}
            overviewItems={overviewItems}
            rows={rows}
            cells={cells}
            getRowId={row => row.id}
            hideBackButton/>
    );
}
