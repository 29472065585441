import React, { useCallback } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestSubassignmentsPayRatesReport } from 'modules/settings/submodules/clients/reports/store/actions';
import { selectIsLoadingLatestPayRatesReport } from 'modules/settings/submodules/clients/reports/store/selectors';
import { ValidationMessages } from 'shared/models/Validation';
import {
    LatestPayRatesReportForm,
} from 'modules/settings/submodules/clients/reports/LatestPayRates/LatestPayRatesReportForm';

interface ILatestPayRatesReportFormValues{
    startDateLte: string | null;
    endDateGte: string | null;
}
const validationSchema = yup.object<ILatestPayRatesReportFormValues>({
    startDateLte: yup.string().nullable().required(ValidationMessages.REQUIRED),
    endDateGte: yup.string().nullable().required(ValidationMessages.REQUIRED),
});

export const LatestPayRatesReport = () => {
    const dispatch = useDispatch();
    const onSubmit = useCallback((values: ILatestPayRatesReportFormValues) => {
        // @ts-ignore
        dispatch(getLatestSubassignmentsPayRatesReport.init({
            start_date_before_than: values.startDateLte,
            end_date_later_than: values.endDateGte,
        }));
    }, [dispatch]);
    const isLoading = useSelector(selectIsLoadingLatestPayRatesReport);

    return (
        <Formik
            initialValues={{
                startDateLte: null,
                endDateGte: null,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
        >
            <LatestPayRatesReportForm
                isLoading={isLoading}
            />
        </Formik>
    );
};
