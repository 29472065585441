import React from 'react';
import { OfferLetterPayRangesCore } from './OfferLetterPayRangesCore';

export interface IOfferLetterPayRangesProps {
    enabled: boolean;
}

export const OfferLetterPayRanges = ({ enabled }: IOfferLetterPayRangesProps) => {
    if (!enabled) {
        return null;
    }

    return <OfferLetterPayRangesCore/>;
};
