import { getDepartments } from 'modules/employmentInfo/store/department/actions';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { selectSheetsGroupById } from 'modules/payrollProcessorHub/store/selectors';
import { browserHistory } from 'shared/utils/browserHistory';
import { nullOrValue } from 'shared/utils/converters/urlParams';
import { getLastFirstName } from 'shared/utils/converters/user';
import { getActivities, getLocations } from 'store/entities/configuration/configurationAction';
import { selectUserById } from 'store/entities/users/selectors';
import { getClientPayCodes } from 'modules/settings/submodules/clients/store/actions';
import { SheetGroupDetail } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/components/calculationGroup/single/SheetGroupDetail';
import { getCustomFieldsHierarchyNodes, getCustomFieldValues } from 'store/entities/customFields/actions';
import { getClientFieldsConfiguration } from 'store/entities/clients/clientsAction';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const usePayrollSheetDetailStyles = makeHighPriorityStyles((theme: Theme) => ({
    paper: (wide: boolean) => ({
        width: theme.spacing(wide ? 100 : 80),
    }),
}));

export default function PayrollSheetDetailSidebar() {
    const dispatch = useDispatch();
    const { timeSheetId, expenseSheetId } = useParams<{
        timeSheetId: string;
        expenseSheetId: string;
    }>();
    const detailClasses = useSheetDetailStyles();
    const classes = usePayrollSheetDetailStyles(false);

    const sheetGroup = useSelector(selectSheetsGroupById({
        timeSheetId: nullOrValue(timeSheetId),
        expenseSheetId: nullOrValue(expenseSheetId),
    }));
    const user = useSelector(selectUserById(sheetGroup?.user_id || ''));

    const onClose = () => {
        browserHistory.push('../..');
    };
    useEffect(() => {
        if (sheetGroup) {
            if (sheetGroup.assignment_id) {
                dispatch(getLocations.init({
                    assignment_id: sheetGroup.assignment_id,
                }));
            }
            if (sheetGroup.client_id) {
                dispatch(getClientPayCodes.init({
                    client_id: sheetGroup.client_id,
                }));
                dispatch(getActivities.init({
                    client_id: sheetGroup.client_id,
                }));
                dispatch(getDepartments.init({
                    client_id: sheetGroup.client_id,
                }));

                // @ts-ignore
                const entries = sheetGroup.expense_entries.concat(sheetGroup.time_entries);
                const customFieldValueIds = [... new Set(
                    entries.map(
                        entry => entry.custom_field_value_ids,
                    ).reduce((mem, entryCustomFieldValueIds) => {
                        return [...mem, ...entryCustomFieldValueIds];
                    }, []),
                )];
                dispatch(getCustomFieldValues.init({
                    // @ts-ignore
                    custom_field_value_ids: customFieldValueIds,
                }));
                dispatch(getCustomFieldsHierarchyNodes.init({
                    client_id: sheetGroup.client_id,
                    cfvs: false,
                }));
                dispatch(getClientFieldsConfiguration.init(sheetGroup.client_id));
            }
        }
    }, [dispatch, sheetGroup]);

    return (
        <Sidebar
            isOpened
            title={getLastFirstName(user)}
            customClasses={classes.paper}
            titleClasses={{
                root: detailClasses.paperTitle,
                text: detailClasses.paperTitleText,
                icon: detailClasses.paperTitleIcon,
            }}
            onClose={onClose}
        >
            {sheetGroup ? (
                <SheetGroupDetail sheetGroup={sheetGroup}/>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    pt={5}
                >
                    <CircularProgress color="primary"/>
                </Box>
            )}
        </Sidebar>
    );
}
