import {
    selectProjectClasses,
    selectProjectClassesLoading,
} from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { IProjectClass } from 'store/entities/customFields/model';

interface IProjectClassFieldValuesSelectProps extends IFormFieldProps, IFormSelect {}

export const ProjectClassFieldValuesSelect = (props: IProjectClassFieldValuesSelectProps) => {
    const projectClasses = useSelector(selectProjectClasses);
    const isProjectClassesLoading = useSelector(selectProjectClassesLoading);

    return (
        <FormSelect
            getKey={(value: IProjectClass) => value.project_class}
            getText={(value: IProjectClass) => value.description}
            options={projectClasses}
            isLoading={isProjectClassesLoading}
            {...props}
        />
    );
};
