import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { MultiStepFormValues } from './MultiStepForm';

export interface IMultiStepFormStepWrapperProps<FormValues> extends FormikProps<MultiStepFormValues<FormValues>> {
    children: React.ReactNode;
    stepFieldName: string;
    stepFieldValue: string;
}

export function MultiStepFormStepWrapper<FormValues>({
    children,
    setFieldValue,
    stepFieldName,
    stepFieldValue,
}: IMultiStepFormStepWrapperProps<FormValues>) {
    useEffect(() => {
        setFieldValue(stepFieldName, stepFieldValue);
    }, [setFieldValue, stepFieldName, stepFieldValue]);

    return (
        <>
            {children}
        </>
    );
}
