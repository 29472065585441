import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSubassignmentFilters } from 'modules/subassignmentManagement/store/actions';
import { selectSubassignmentFilter } from 'modules/subassignmentManagement/store/selectors';
import { subassignmentTableStateSelectors } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { EmployeeFilter, IEmployeeFilterProps } from 'modules/employmentManagement/components/EmployeeFilterForm/EmployeeFilter';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { IEmployeeManagementFilter } from 'modules/employmentManagement/components/store/model';
import { selectOrderedCustomFieldAssignmentNodes } from 'store/entities/customFields/selectors';

export const SubassignmentFilter = separateLogicDecorator<{}, IEmployeeFilterProps>(() => {
    const dispatch = useDispatch();
    const isLoading = useSelector(subassignmentTableStateSelectors.isLoading);
    const initialValues = useSelector(selectSubassignmentFilter);
    const onApplyFilter = useCallback((newFilter: IEmployeeManagementFilter) => {
        dispatch(setSubassignmentFilters(newFilter));
    }, [dispatch]);
    const hierarchy = useSelector(selectOrderedCustomFieldAssignmentNodes);

    return {
        isLoading,
        initialValues,
        onApplyFilter,
        useAdvancedSearch: true,
        hierarchy,
    };
})(EmployeeFilter);
